import React, { useCallback } from 'react';
import { Form as AntdForm,  Col, Form, Checkbox,   } from 'antd';
import * as S from './styles';
import Input from '../../../shared/Input';
import Button from '../../../shared/Button';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// import { useMutation } from '@apollo/client';
// import { gqlSchema } from '../../../gql/schema';
import adiLearningHubSrc from '../../../assets/adi-learning-hub.svg';

type Props = RouteComponentProps<null, any, any>;

const BookSignUp = (props: Props) => {


  const onSubmitForm = useCallback(
    (values) => {
      setTimeout(()=>{
      },3000)
    
    },
    []
  );

  return (
    <S.Container>
      <S.RowSP justify="center" align="middle">
        <S.ColSP xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Create an Account</S.Title>
          <Form onFinish={onSubmitForm} layout="vertical">
            <AntdForm.Item label="Organization Name" name="organizationName">
              <Input placeholder="Type organization name here..." />
            </AntdForm.Item>

            <S.SectionTitle>Subscription Details</S.SectionTitle>
            <S.RowForm gutter={16}>
              <Col span={12}>
                <AntdForm.Item label="Subscription Duration" name="subscriptionDuration">
                  <Input />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item label="Licenses" name="licenses">
                  <Input />
                </AntdForm.Item>
              </Col>
            </S.RowForm>
            <S.RowForm gutter={16}>
              <Col span={12}>
                <AntdForm.Item label="Teacher Assistant" name="teacherAssistant">
                  <Input />
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item label="Writers Quantity" name="writersQuantity">
                  <Input />
                </AntdForm.Item>
              </Col>
            </S.RowForm>

            <S.SectionTitle>Permissions</S.SectionTitle>
            <S.RowForm gutter={[16, 16]}>
              <Col span={6}>
                <Checkbox>Trial Subscription</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox>Super TA</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox>Immersive Reader</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox>Reports & Insights</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox>Super Teacher</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox>Custom Investigation</Checkbox>
              </Col>
            </S.RowForm>

            <S.SectionTitle>Subscription Subjects</S.SectionTitle>
            <S.RowForm gutter={16}>
              <Col span={8}>
                <Checkbox>Math</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox>Science</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox>Engineering</Checkbox>
              </Col>
            </S.RowForm>

            <S.SectionTitle>Texas Subscription Subjects</S.SectionTitle>
            <S.RowForm gutter={16}>
              <Col span={24}>
                <Checkbox>Science Texas Edition (2024 Adoption)</Checkbox>
              </Col>
            </S.RowForm>

            <AntdForm.Item label="Standards" name="standards">
              <Input />
            </AntdForm.Item>
            <AntdForm.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address' }]}>
              <Input placeholder="Type your email here..." />
            </AntdForm.Item>
            <AntdForm.Item label="Create A Password" name="password" rules={[{ required: true, message: 'Password is required' }]}>
              <Input type="password" />
            </AntdForm.Item>

            <AntdForm.Item>
              <Button text="Submit" htmlType="submit" loading={false} block />
            </AntdForm.Item>
          </Form>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );
};

export default withRouter(BookSignUp);