import styled from 'styled-components';

export const MessageIcon = styled.div<{ sender: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: ${({ sender }) => (sender === 'user' ? '2px solid #D68E43 ' : '#007AFF')};
  background: ${({ sender }) => (sender === 'user' ? 'transparent' : '#007AFF')};
  color: ${({ sender }) => (sender === 'user' ? ' #D68E43' : '#fff')};
  margin: ${({ sender }) => (sender === 'user' ? '0 8px 0 0' : '0 0 0 8px')};

  svg {
    width: 18px;
    height: 50px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 490px;
  overflow-y: auto;
  border-radius: 10px;
  background-color: #f5f5f5;
  font-family: 'Inter', sans-serif;

  @media (max-width: 1024px) {
    height: 400px;
  }
`;

export const ChatBox = styled.div`
  width: 600px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 350px;
    padding: 12px;
  }
`;

export const Messages = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: auto;
`;

export const Message = styled.div<{ sender: 'bot' | 'user' }>`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ sender }) => (sender === 'bot' ? 'flex-end' : 'flex-start')};
  margin-bottom: 12px;
  padding: 16px;

  @media (max-width: 1024px) {
    padding: 12px;
  }
`;

export const MessageContent = styled.div<{ sender: 'bot' | 'user' }>`
  font-size: 13px;
  max-width: ${({ sender }) => (sender === 'bot' ? '100%' : '50%')};
  text-align: justify;
  border: ${({ sender }) => (sender === 'bot' ? '3px solid #758FFC' : '3px solid #D68E43')};
  background-color: ${({ sender }) => (sender === 'bot' ? ' #758FFC' : ' #D68E43')};
  color: ${({ sender }) => (sender === 'bot' ? '#ffffff' : '#ffffff')};
  padding: 10px 14px;
  border-radius: 12px;
  border-bottom-right-radius: ${({ sender }) => (sender === 'bot' ? '0px' : '12px')};
  border-bottom-left-radius: ${({ sender }) => (sender === 'user' ? '0px' : '12px')};
  font-family: 'Inter', sans-serif;

  span {
    color: ${({ sender }) => (sender === 'bot' ? '#ffffff' : '#ffffff')};
  }

  @media (max-width: 1024px) {
    font-size: 12px;
    padding: 8px 12px;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  gap: 8px;
  margin-bottom: 1rem;

  button {
    font-family: 'Inter', sans-serif;
    border-radius: 40px;
    border: 2px solid #758ffc;

    &:hover {
      background-color: rgb(135, 146, 248);
    }
  }

  @media (max-width: 1024px) {
    padding: 6px;
    gap: 6px;

    button {
      font-size: 12px;
    }
  }
`;

export const InputWrapper = styled.div`
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;

  .ant-input {
    border-radius: 20px;
    height: 50px;
    font-family: 'Inter', sans-serif;
    padding-right: 40px;
  }

  @media (max-width: 1024px) {
    padding: 12px;

    .ant-input {
      height: 40px;
      padding-right: 30px;
    }
  }
`;

export const SendButton = styled.button`
  position: absolute;
  right: 24px;
  background: none;
  border: none;
  border-radius: 50%;
  padding: 16px;
  background-color: #007aff;
  color: #fff;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  transform: rotate(-30deg);

  &:hover {
    opacity: 0.8;
    transform: rotate(0deg);
  }

  @media (max-width: 1024px) {
    right: 16px;
    padding: 12px;
  }
`;