import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Table, Button, Tag, message, Spin } from 'antd';
import { gqlSchema } from '../../../gql/schema';
import { GQL_StudentSubmission, GQL_GoogleClassroomStudents, GQL_GoogleClassroomCourse, GQL_classWork } from '../../../types/googleClassroom';
import GradePopup from './GradePopup';

interface Props {
  courseId: string;
  token: string;
}

const StudentSummaryTable: React.FC<Props> = ({ courseId, token }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<GQL_StudentSubmission | null>(null);
  const [assignmentLink, setAssignmentLink] = useState<string>('');
  const [className, setClassName] = useState<string>('Unknown');
  const [section, setSection] = useState<string>('Unknown');
  const [submissions, setSubmissions] = useState<GQL_StudentSubmission[]>([]);

  // Fetch class data
  const { data: classData } = useQuery<{ getCourseByCourseId: GQL_GoogleClassroomCourse }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseByCourseId,
    {
      variables: {
        token,
        id: courseId,
      },
      onError: () => {
        message.error('Error fetching class data');
      },
    }
  );

  // Update className and section when classData changes
  useEffect(() => {
    if (classData && classData.getCourseByCourseId) {
      setClassName(classData.getCourseByCourseId.name || 'Unknown');
      setSection(classData.getCourseByCourseId.section || 'Unknown');
    }
  }, [classData]);

  // Fetch student submissions
  const { loading: submissionLoading } = useQuery<{ getCourseWorkSubmissionList: GQL_StudentSubmission[] }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionList,
    {
      variables: {
        token,
        courseId,
        courseWorkId: '682924771343', // Replace with your actual course work ID
      },
      onError: () => {
        message.error('Error fetching student submission data');
      },
      onCompleted: (data) => {
        setSubmissions(data?.getCourseWorkSubmissionList || []);
      },
    }
  );

  // Fetch student data
  const { loading: studentsLoading, data: studentsData } = useQuery<{ getCourseStudents: { students: GQL_GoogleClassroomStudents[] } }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudents,
    {
      variables: {
        token,
        courseId,
      },
      onError: () => {
        message.error('Error fetching students data');
      },
    }
  );

  // Fetch course work data
  const { data: workData } = useQuery<{ getCourseWorkById: GQL_classWork }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
    {
      variables: {
        token,
        courseId,
        id: '68292', // Replace with your actual course work ID
      },
      onError: () => {
        message.error('Error fetching course work data');
      },
    }
  );

  const students = studentsData?.getCourseStudents.students || [];
  const courseWork = workData?.getCourseWorkById;

  const updateGradedSubmission = useCallback((submissionId: string, gradedScore: number) => {
    // Update the local state with the graded score
    const updatedSubmissions = submissions.map(submission => {
      if (submission.id === submissionId) {
        return { ...submission, gradedScore: gradedScore };
      } else {
        return submission;
      }
    });
    setSubmissions(updatedSubmissions);
    // Store the graded score in localStorage
    localStorage.setItem(submissionId, gradedScore.toString());
  }, [submissions]);

  // Retrieve graded scores from localStorage on component mount
  useEffect(() => {
    submissions.forEach(submission => {
      const gradedScore = localStorage.getItem(submission.id);
      if (gradedScore !== null) {
        updateGradedSubmission(submission.id, parseInt(gradedScore));
      }
    });
  }, [submissions, updateGradedSubmission]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'userId',
      key: 'userId',
     
      
      render: (userId: string) => {
        if (!userId) return 'Unknown';
        
        const lowercaseUserId = userId.toLowerCase();

        const student = students.find(student => {
          const lowercaseEmailAddress = student.profile.id.toLowerCase();
          return lowercaseEmailAddress === lowercaseUserId;
        });

        return student ? `${student.profile.name.givenName} ${student.profile.name.familyName}` : 'Unknown';
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'creationTime',
      key: 'startDate',
     
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none',  } : {},
      }),
      render: (creationTime: string) => {
        const startDate = new Date(creationTime).toLocaleString();
        return startDate !== 'Invalid Date' ? startDate : '-';
      },
    },
    {
      title: 'End Date',
      dataIndex: 'updateTime',
      key: 'endDate',
      
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      render: (updateTime: string) => {
        const endDate = new Date(updateTime).toLocaleString();
        return endDate !== 'Invalid Date' ? endDate : '-';
      },
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      render: (state: string) => {
        let color = '';
        let text = '';

        if (state === 'TURNED_IN') {
          color = 'green';
          text = 'Completed';
        } else {
          color = 'yellow';
          text = 'Pending';
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Submission',
      key: 'submission',
      width:'85',
      render: (text: any, record: GQL_StudentSubmission) => (
        <Button
          type="primary"
          style={{
            width: '150px',
            color: record.state === 'TURNED_IN' ? 'white' : 'blue',
            backgroundColor: record.state === 'TURNED_IN' ? 'blue' : 'white',
            borderColor: record.state === 'TURNED_IN' ? 'blue' : 'blue',
          }}
          onClick={() => {
            setSelectedSubmission(record);
            const student = students.find(student => student.profile.id === record.userId);
            if (student) {
              setAssignmentLink(student.selfLink);
            }
            setPopupVisible(true);
          }}
        >
          {record.state === 'TURNED_IN' ? 'Graded' : 'Not Graded'}
        </Button>
      ),
    },
  ];

  if (submissionLoading || studentsLoading) {
    return <Spin />;
  }
  const expandedRowRender = (record: any) => {
    return (
      <div>
        {columns.map((column) => {

          if ('dataIndex' in column) {
            return (
              <div key={column.key} style={{ marginBottom: '8px' }}>
                <strong>{column.title}:</strong> {record[column.dataIndex as keyof typeof record]}
              </div>
            );
          }
          return null; 
        })}
      </div>
    );
  };
  return (
    <>
     {!isMobile && (<><Table 
        loading={submissionLoading || studentsLoading} 
        dataSource={submissions} 
        columns={columns} 
        pagination={{ pageSize: 10 }} 
      /></>)} 
      {isMobile && (<><Table 
        loading={submissionLoading || studentsLoading} 
        dataSource={submissions} 
        columns={columns} 
        pagination={{ pageSize: 10 }}
    
        expandable={{
          expandedRowRender,

        }}
      /></>)} 
      {selectedSubmission && (
        <GradePopup
          visible={popupVisible}
          setVisible={setPopupVisible}
          studentName={students.find(student => student.profile.id === selectedSubmission.userId)?.profile.name.fullName || 'Unknown'}
          submission={selectedSubmission.state === 'TURNED_IN' ? 'Graded' : 'Not Graded'}
          assignmentName={courseWork?.title || 'Unknown'}
          assignmentLink={assignmentLink} 
          progress={0} 
          submissionData={selectedSubmission}
          token={token}
          courseId={courseId}
          courseWorkId={'682924771343'}
          className={className}
          section={section}
          updateGradedSubmission={updateGradedSubmission}
        />
      )}
    </>
  );
};

export default StudentSummaryTable;
