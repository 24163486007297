import { Popover, Skeleton } from 'antd';
import React from 'react';

import * as S from '../styles';

interface ITaskWalkthrough {
  currentTip: number;
  popoverContent?: React.ReactNode;
}

const TaskWalkthrough = (props: ITaskWalkthrough) => {
  const { currentTip, popoverContent } = props;

  return (
    <>
      <h1>What do you notice and wonder?</h1>
      <p>
        Animals and plants have adapted (changed) to the ecosystems where they live. They eat specific food and are
        often hunted by a specific predator that lives in the same ecosystem.
      </p>
      <p>
        They also have traits to help them survive. For example, animals who need to hunt at night will often have large
        ears and use sound to hunt their prey since it is hard to see in the dark. Other traits of animals may also make
        them more likely to survive.
      </p>
      <Skeleton.Image />
      <Popover
        content={popoverContent}
        visible={currentTip === 2}
        placement="top"
        getPopupContainer={(node) => (node.parentElement ? node.parentElement : document.body)}
      >
        <S.ProceedPreviewButton
          text="Proceed to Next Step"
          minHeight={45}
          $highlighted={currentTip === 2}
          $visible={currentTip === 2}
          block
        />
      </Popover>
    </>
  );
};

export default TaskWalkthrough;
