import React, { useState } from 'react';
import * as S from './styles';
import { Col, Progress, Row, Spin } from 'antd';
import { GQL_ExpiringSubscriptionTypes } from '../../../../types/charts';
import { themeConfig } from '../../../../utils/theme';
import TeacherInvestigationRateDetail from '../Details';
import Button from '../../../Button';

interface Props {
  type: GQL_ExpiringSubscriptionTypes;
  subscriptions: number;
  title: string;
  loading: boolean;
}

const ExpiringSubscriptionItem = (props: Props) => {
  const { type, title, subscriptions, loading } = props;
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Row>
        <Col span={24}>
          <S.Card>
            {loading ? (
              <Spin data-cy="shared-charts-expiringsubscription-item-spin" size="large" />
            ) : (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <S.Title style={{ marginTop: 10 }}>{title}</S.Title>
                </Col>
                <Col span={24}>
                  <Progress
                    type="dashboard"
                    percent={subscriptions}
                    width={130}
                    format={(percent) => `${subscriptions}`}
                    success={{ percent: subscriptions, strokeColor: themeConfig.primaryColor.background }}
                  />
                </Col>
                <Col span={24}>
                  <Row justify="center">
                    <Col span={12}>
                      <Button
                        data-cy="shared-charts-expiringsubscription-detail-button"
                        block
                        disabled={subscriptions < 1}
                        text="Details"
                        onClick={() => setVisible(true)}
                      />
                    </Col>
                  </Row>
                  <TeacherInvestigationRateDetail type={type} title={title} visible={visible} setVisible={setVisible} />
                </Col>
              </Row>
            )}
          </S.Card>
        </Col>
      </Row>
    </>
  );
};

export default ExpiringSubscriptionItem;
