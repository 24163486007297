import { Col } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { themeConfig } from '../../utils/theme';
import Button from '../Button';
import * as S from './styles';
import logo from '../../assets/logo.svg';
import Spacer from '../Spacer';
import RequestAccount from '../RequestAccount';

const LandingPageHeader = () => {
  const history = useHistory();

  return (
    <S.Header>
      <S.Row align="middle">
        <S.Col $responsiveAlign="start" span={3}>
          <S.LogoImage src={logo} alt="ADI Logo" />
        </S.Col>
        <Col xs={21}>
          <S.ButtonContainer>
            <Button
              theme={themeConfig.primaryNoColor}
              text="Log in"
              onClick={() => {
                history.push('/login');
              }}
            />
            <Spacer axis="horizontal" size={16} />
            <RequestAccount />
          </S.ButtonContainer>
        </Col>
      </S.Row>
    </S.Header>
  );
};

export default LandingPageHeader;
