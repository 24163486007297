import styled from 'styled-components';

export const Container = styled.div`
  padding: 4em 12em;
`;

export const Title = styled.h1`
  text-align: left;
  margin-bottom: 15px;
  font-weight: 600;
`;

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;
