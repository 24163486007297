import Select from '../../../../shared/Select';
import SelectOption from '../../../../shared/Select/Option';
import React from 'react';
import { SelectValue } from 'antd/lib/select';
import { GQL_InvestigationFocus } from '../../../../types/investigationFocus';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { message } from 'antd';

interface AssessmentFocusInterface {
  title: string;
  value?: string;
  onSelect: (value: SelectValue) => void | undefined;
}

const AssessmentFocus = ({ title, value, onSelect }: AssessmentFocusInterface) => {
  const { data: investigationFocuses, loading } = useQuery<{
    getInvestigationFocuses: GQL_InvestigationFocus[];
  }>(gqlSchema.InvestigationFocusSchema.queries.GET.getInvestigationFocuses, {
    onError: (error) => {
      message.error(`There was an error in fetching the focuses - ${error.message || 'Unexpected Error'}`);
    },
  });

  return (
    <>
      <h2>{title}</h2>
      <Select
        placeholder="Focus"
        showArrow
        loading={loading}
        value={value}
        onChange={onSelect}
        data-cy="components-adieditassessmentcore-settings-focus"
      >
        {(investigationFocuses?.getInvestigationFocuses || []).map((focus) => (
          <SelectOption
            value={focus.id}
            label={focus.name}
            key={focus.id}
            style={{
              maxWidth: '400px',
            }}
          >
            {focus.name}
          </SelectOption>
        ))}
      </Select>
    </>
  );
};

export default AssessmentFocus;
