import React, { useState, useMemo } from 'react';
import { Row, Col, Empty, Input } from 'antd';
import startCase from 'lodash/startCase';
import Item from './Item';
import { formatDateTime } from '../../../utils/date';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg'; // Import the AssignmentIcon
import * as S from './styles'; 
interface IFinishedAssignmentsRow {
  loading: boolean;
  finishedAssignments: any[];
  courses: any[];
}

const GFinishedAssignmentsRow: React.FC<IFinishedAssignmentsRow> = ({ loading, finishedAssignments, courses }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredAssignments = useMemo(() => {
    return finishedAssignments.filter((assignment) =>
      assignment.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [finishedAssignments, searchQuery]);

  const renderAssignments = () => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          {[1, 2, 3].map((index) => (
            <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      );
    } else if (!filteredAssignments.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Finished Assignments" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {filteredAssignments.map((item, index) => {
            const course = courses.find((c) => c.id === item.courseId);
            const className = course ? course.name : '';
            const courseName = course ? course.courseName : '';

            const assignmentTitle = item.title ? startCase(item.title.toLowerCase()) : 'Untitled Assignment';

            return (
              <Col key={`${item.id}-${index}`} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
                <Item
                  date={formatDateTime(item.creationTime)}
                  assignmentName={assignmentTitle}
                  assignmentId={item.courseWorkId}
                  courseId={item.courseId}
                  courseWorkId={item.courseWorkId}
                  className={className}
                  courseName={courseName}
                  courseWorkType={item.courseWorkType}
                  icon={<img src={AssignmentIcon} alt="Assignment Icon" style={{ width: 24, height: 24 }} />}
                />
              </Col>
            );
          })}
        </Row>
      );
    }
  };

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col>
        <S.Title>Finished Assignments</S.Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <Input.Search
            placeholder="Search assignments"
            value={searchQuery}
            onChange={handleSearch}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      {renderAssignments()}
    </>
  );
};

export default GFinishedAssignmentsRow;
