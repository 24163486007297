import { Col } from 'antd';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h2 {
    margin: 0;
  }
`;

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SuccesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex-direction: column;
  text-align: center;
  margin: 10px 0 20px 0;
  img {
    margin: 20px 0;
  }
`;

export const TitleTab = styled.p`
  font-weight: 700;
  padding: 0;
  font-size: 1.1em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Container = styled(Col)`
  padding: 25px 30px !important;
  background-color: white;
  border-radius: 6px;
  text-align: left;
  & > div:not(:last-child) {
    margin-bottom: 40px;
  }
`;
