/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Divider, message, Modal, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import { AssessmentDateOrTimePicker } from './DateOrtimePicker';
import { ASSESSMENT_OPTIONS_TIMES } from '../../../../types/assessments';
import Button from '../../../../shared/Button';
import { themeConfig } from '../../../../utils/theme';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { GQL_BulkEditAssessmentPerStudentInput } from '../../../../types/investigation';
import moment from 'moment';

type DatetimePickersModalProps = {
  setVisible: (val: boolean) => void;
  visible: boolean;
  startDate: number | string;
  endDate: number | string;
  enabledOptions: any;
  studentIds: string[];
  classId: string;
  investigationId: string;
  actionCompleted: () => void;
};

export const DatetimePickersModal: React.FC<DatetimePickersModalProps> = (props) => {
  const {
    setVisible,
    visible,
    startDate,
    endDate,
    enabledOptions,
    classId,
    studentIds,
    investigationId,
    actionCompleted,
  } = props;
  const [newStartDate, setNewStartDate] = useState(startDate);
  const [newEndDate, setNewEndDate] = useState(endDate);

  const handleCloseModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const [bulkEditAssessmentPerStudent, { loading: editing }] = useMutation<
    { editInvestigationDuration: GQL_BulkEditAssessmentPerStudentInput },
    { data: GQL_BulkEditAssessmentPerStudentInput }
  >(gqlSchema.InvestigationSchema.mutations.EDIT.bulkEditAssessmentPerStudent, {
    onCompleted: (data) => {
      message.success(`Duration updated successfully`);
      actionCompleted();
    },
    onError: (err) => {
      message.error(`There was an error trying to edit the duration, please try again later`);
    },
  });

  const handleDurationEdit = useCallback(() => {
    const payload: GQL_BulkEditAssessmentPerStudentInput = {
      classInvestigationId: investigationId,
      studentIds,
    };

    if (newStartDate) {
      payload['startDate'] = Number(newStartDate);
    }

    if (newEndDate) {
      payload['endDate'] = Number(newEndDate);
    }

    bulkEditAssessmentPerStudent({
      variables: {
        data: {
          ...payload,
        },
      },
    });
  }, [investigationId, studentIds, newEndDate, newStartDate, bulkEditAssessmentPerStudent]);

  const DATE_FORMAT = 'YYYY-MM-DD hh:mm a';
  const handleDateTimeChanges = (type: ASSESSMENT_OPTIONS_TIMES, value: any) => {
    switch (type) {
      case ASSESSMENT_OPTIONS_TIMES.OPEN_DATE: {
        const baseStartDate = moment(value, 'x').format('YYYY-MM-DD');
        const baseStartTime = moment(newStartDate, 'x').format('hh:mm a');
        const customNewStartDate = moment(`${baseStartDate} ${baseStartTime}`, DATE_FORMAT).format('x');
        setNewStartDate(Number(customNewStartDate));
        return;
      }

      case ASSESSMENT_OPTIONS_TIMES.OPEN_TIME: {
        const baseStartDate = moment(newStartDate, 'x').format('YYYY-MM-DD');
        const baseStartTime = moment(value, 'x').format('hh:mm a');
        const customNewStartTime = moment(`${baseStartDate} ${baseStartTime}`, DATE_FORMAT).format('x');
        setNewStartDate(Number(customNewStartTime));
        return;
      }

      case ASSESSMENT_OPTIONS_TIMES.CLOSE_DATE: {
        const baseEndDate = moment(value, 'x').format('YYYY-MM-DD');
        const baseEndTime = moment(newEndDate, 'x').format('hh:mm a');
        const customNewEndDate = moment(`${baseEndDate} ${baseEndTime}`, DATE_FORMAT).format('x');
        setNewEndDate(Number(customNewEndDate));
        return;
      }

      case ASSESSMENT_OPTIONS_TIMES.CLOSE_TIME: {
        const baseEndDate = moment(newEndDate, 'x').format('YYYY-MM-DD');
        const baseEndTime = moment(value, 'x').format('hh:mm a');
        const customNewEndTime = moment(`${baseEndDate} ${baseEndTime}`, DATE_FORMAT).format('x');
        setNewEndDate(Number(customNewEndTime));
        return;
      }
    }
  };

  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null} closable={!editing}>
      <Row gutter={16}>
        <h3>Bulk edit duration times</h3>
        <Divider
          style={{
            margin: '10px 0',
          }}
        />
      </Row>

      <Row gutter={16} justify={'center'}>
        {enabledOptions[ASSESSMENT_OPTIONS_TIMES.OPEN_DATE] && (
          <Col span={11}>
            <b> Open Date </b>
            <AssessmentDateOrTimePicker
              isTimePicker={false}
              dateValue={newStartDate}
              inputFormat={'MM.DD.YYYY'}
              disabled={editing}
              data={null}
              handleChange={(value: number) => handleDateTimeChanges(ASSESSMENT_OPTIONS_TIMES.OPEN_DATE, value)}
            />
          </Col>
        )}
        {enabledOptions[ASSESSMENT_OPTIONS_TIMES.OPEN_TIME] && (
          <Col span={11}>
            <b>Open Time </b>
            <AssessmentDateOrTimePicker
              isTimePicker={true}
              dateValue={newStartDate}
              inputFormat={'hh:mm a'}
              disabled={editing}
              data={null}
              handleChange={(value: number) => handleDateTimeChanges(ASSESSMENT_OPTIONS_TIMES.OPEN_TIME, value)}
            />
          </Col>
        )}
      </Row>

      {(enabledOptions[ASSESSMENT_OPTIONS_TIMES.OPEN_DATE] || enabledOptions[ASSESSMENT_OPTIONS_TIMES.OPEN_TIME]) && (
        <Divider
          style={{
            margin: '20px 0',
          }}
        />
      )}

      <Row gutter={16} justify={'center'}>
        {enabledOptions[ASSESSMENT_OPTIONS_TIMES.CLOSE_DATE] && (
          <Col span={11}>
            <b> Close Date </b>
            <AssessmentDateOrTimePicker
              isTimePicker={false}
              dateValue={newEndDate}
              inputFormat={'MM.DD.YYYY'}
              disabled={editing}
              data={null}
              handleChange={(value: number) => handleDateTimeChanges(ASSESSMENT_OPTIONS_TIMES.CLOSE_DATE, value)}
            />
          </Col>
        )}

        {enabledOptions[ASSESSMENT_OPTIONS_TIMES.CLOSE_TIME] && (
          <Col span={11}>
            <b>Close Time </b>
            <AssessmentDateOrTimePicker
              isTimePicker={true}
              dateValue={newEndDate}
              inputFormat={'hh:mm a'}
              disabled={editing}
              data={null}
              handleChange={(value: number) => handleDateTimeChanges(ASSESSMENT_OPTIONS_TIMES.CLOSE_TIME, value)}
            />
          </Col>
        )}
      </Row>

      <Divider
        style={{
          margin: '20px 0 10px 0',
        }}
      />

      <Row gutter={16} justify={'center'}>
        <Col span={11}>
          <Button
            style={{ width: '100%' }}
            text={'Go Back'}
            theme={themeConfig.secondaryOutlined}
            onClick={handleCloseModal}
            disabled={editing}
          />
        </Col>
        <Col span={11}>
          <Button
            style={{ width: '100%' }}
            text={'Apply'}
            theme={themeConfig.secondaryColor}
            onClick={handleDurationEdit}
            disabled={editing}
          />
        </Col>
      </Row>
    </Modal>
  );
};
