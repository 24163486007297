import { gql } from '@apollo/client';

const CORRELATION_QUERIES = {
  GET: {
    getCorrelation: gql`
      query GetCorrelation($subject: String!, $gradeBand: String!, $version: String) {
        getCorrelationFileUrl(subject: $subject, gradeBand: $gradeBand, version: $version) {
          id
          subject
          gradeBand
          fileUrl
          version
          teacherManualUrl
        }
      }
    `,
  },
};

export default CORRELATION_QUERIES;
