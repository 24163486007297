import React, { useCallback } from 'react';
import { Row, Col, Progress } from 'antd';
import * as S from './styles';
import { getProgressColor, themeConfig } from '../../../../utils/theme';
import { formatDateTime } from '../../../../utils/date';
import { useHistory } from 'react-router';

interface Props {
  stars: number | string;
  engagement: number | string;
  grade?: number | null;
  progressTitle: string;
  date: number;
  investigationName: string;
  investigationId: string;
}

const Item: React.FC<Props> = (props) => {
  const { grade, date, investigationName, investigationId, progressTitle, stars, engagement } = props;
  const history = useHistory();
  const goToDashboard = useCallback(() => {
    history.push(`/student-investigation/${investigationId}/grade`);
  }, [investigationId, history]);

  return (
    <S.Card>
      <Row align="middle">
        <Col span={18}>
          <S.Title $align="left">{investigationName}</S.Title>
        </Col>
        <Col span={6}>
          <S.Info>{formatDateTime(date)}</S.Info>
        </Col>
        <Col span={24} className="middle">
          <Progress
            strokeLinecap="round"
            strokeWidth={9}
            style={{ marginTop: 30 }}
            strokeColor={getProgressColor(grade || 0)}
            format={(percent) => {
              return (
                <>
                  <S.Info
                    $bold
                    data-cy="shared-studentdashboardrows-finishedinvestigationsrow-percent"
                    data-percentage={percent}
                  >
                    {grade}%
                  </S.Info>
                  <S.InfoProgress>{grade === null ? 'Not Graded' : progressTitle}</S.InfoProgress>
                </>
              );
            }}
            width={145}
            type="circle"
            percent={grade || 0}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '2em' }}>
        <Col span={14} offset={5}>
          <S.ReportButton
            data-cy="shared-studentdashboardrows-finishedinvestigationsrows-viewreportscore"
            text="View Report Score"
            disabled={grade === null}
            theme={themeConfig.primaryOutlined}
            htmlType="button"
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '2em' }}>
        <Col span={12}>
          <S.Title>{stars}/5</S.Title>
          <S.Stars value={+stars} allowHalf={true} disabled={true} />
          <S.Info>Feedback Score</S.Info>
        </Col>
        <Col span={12}>
          <S.Title>{engagement}/5</S.Title>
          <S.Stars value={+engagement} allowHalf={true} disabled={true} />
          <S.Info>Engagement Score</S.Info>
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
