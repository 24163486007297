import { Theme } from '../types/theme';

export const themeConfig: Theme = {
  noColor: {
    background: '#FFFFFF',
    border: '#BBBBBB',
    color: '#767676',
    hover: '#FDFDFD',
    disabled: '#E6E6E6',
  },
  primaryColor: {
    background: '#4367E9',
    color: '#FFFFFF',
    hover: '#4264DE',
    disabled: '#D9E0FA',
  },
  primaryOutlined: {
    background: '#FFFFFF',
    border: '#4367E9',
    color: '#4367E9',
    hover: '#FFFFFF',
    disabled: '#E6E6E6',
  },
  secondaryColor: {
    background: '#7B8CEA',
    color: '#FFFFFF',
    hover: '#6171CA',
    disabled: '#5A67AF',
  },
  secondaryOutlined: {
    background: '#FFFFFF',
    border: '#7B8CEA',
    color: '#7B8CEA',
    hover: '#FFFFFF',
    disabled: '#E6E6E6',
  },
  success: {
    color: '#3EBC89',
    background: '#50c364',
    border: '#50c364',
    hover: '#50c364',
  },
  warning: {
    color: '#F5CD1B',
  },
  error: {
    color: '#FFFFFF',
    background: '#EA3535',
    border: '#EA3535',
    hover: '#EA3535',
  },
  gradient: {
    background: 'linear-gradient(71.74deg, #EAE9E9 0%, #FFFFFF 53.41%, #EAE9E9 100%)',
    border: '#EAE9E9',
    color: '#000000',
    hover: 'linear-gradient(71.74deg, #CECECE 0%, #FFFFFF 53.41%, #CECECE 100%)',
    disabled: '#EAE9E9',
  },
  primaryNoColor: {
    background: 'transparent',
    border: 'transparent',
    color: '#4367E9',
    hover: 'transparent',
    disabled: '#E6E6E6',
    boxShadow: 'none',
  },
  orangeNoColor: {
    background: 'transparent',
    border: 'transparent',
    color: '#BA5C19',
    hover: 'transparent',
    disabled: '#E6E6E6',
    boxShadow: 'none',
  },
  orangePrimary: {
    background: '#BA5C19',
    color: '#FFFFFF',
    hover: '#BA5C19',
    disabled: '#D9E0FA',
  },
  orangeOutlined: {
    background: '#FFFFFF',
    border: '#BA5C19',
    color: '#BA5C19',
    hover: '#FFFFFF',
    disabled: '#E6E6E6',
  },
};

export const getProgressColor = (progress: number) => {
  if (progress < 33) return themeConfig.error.background;

  if (progress >= 33 && progress < 66) return themeConfig.warning.color;

  return themeConfig.success.color;
};

export const getAssessmentProgressColor = (progress: number) => {
  if (progress <= 33) return themeConfig.noColor.disabled;
  if (progress > 33 && progress <= 66) return themeConfig.error.background;
  if (progress > 66 && progress < 100) return themeConfig.warning.color;

  return themeConfig.success.color;
};

export const getAssignmentProgressColor = (progress: number) => {
  if (progress === 0) return themeConfig.noColor.disabled;
  if (progress > 0 && progress <= 70) return themeConfig.success.color;
  if (progress > 70 && progress < 99) return themeConfig.warning.color;

  return themeConfig.error.background;
};

export const palette = [
  '#AB61E5',
  '#4367E9',
  '#7B8CEA',
  '#4EA4BA',
  '#20BC89',
  '#8BC552',
  '#C0C936',
  '#F5CD1A',
  '#FFAE51',
  '#F57243',
  '#EA3535',
];
