import React, { useState } from 'react';
import { Col, Layout, message, Row } from 'antd';

import * as S from './styles';
import InvestigationContent from '../../shared/InvestigationContent';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_Concept, GQL_EvaluationAnswers } from '../../types/investigation';
import InvestigationQuestionnaire from '../StudentInvestigation/InvestigationQuestionnaire';
import Spacer from '../../shared/Spacer';
import { FiArrowLeft } from 'react-icons/fi';
import InvestigationConcept from '../../shared/InvestigationConcept';
import CircularSpin from '../../shared/CircularSpin';

type Props = RouteComponentProps<
  {
    investigationId: string;
    stepId: string;
    studentId: string;
    activityId: string;
    evaluatorId?: string;
  },
  any,
  {
    userId?: string;
    conceptActivityId?: string;
  }
>;

const ConceptEvaluationInvestigationPage: React.FC<Props> = (props) => {
  const { stepId, studentId, activityId, investigationId, evaluatorId } = props.match.params;
  const pathname = props.location.pathname;
  const isConceptRating = pathname?.includes('concept-rating');
  const { conceptActivityId, userId } = props.location.state || {};

  const [hasClickedOnFinish, setHasClickedOnFinish] = useState(false);
  const history = useHistory();

  const defaultAnswers = [
    {
      text: 'No',
      value: 'no',
      tooltip: '',
    },
    {
      text: 'Yes',
      value: 'yes',
      tooltip: '',
    },
  ];

  const { data: conceptData, loading: loadingConceptData } = useQuery<
    { getConcept: GQL_Concept },
    {
      stepId: string;
      evaluatedUserId: string;
      activityId: string;
      evaluatorId?: string;
      conceptActivityId?: string;
    }
  >(gqlSchema.InvestigationSchema.queries.CORE.getConcept, {
    variables: {
      stepId,
      evaluatedUserId: studentId,
      evaluatorId: userId || evaluatorId,
      activityId,
      conceptActivityId,
    },
    onError: (err) => {
      message.error('There was an error loading the concept: ' + err.message || 'Unexpected Error');
    },
  });

  const concept = conceptData?.getConcept;
  const conceptFiles = concept?.files || [];

  const [saveAnswer, { loading: loadingSaveAnswer }] = useMutation<
    { evaluateConcept: GQL_EvaluationAnswers },
    {
      activityId: string;
      conceptActivityId?: string;
      stepId: string;
      studentId: string;
      answerValue: string;
      questionIndex: number;
      evaluatorId?: string;
    }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.evaluateConcept, {
    onError: (err) => {
      message.error(err.message || 'Unexpected Error');
    },
    update: (cache, { data }) => {
      const conceptResponse = cache.readQuery<{ getConcept: GQL_Concept }>({
        query: gqlSchema.InvestigationSchema.queries.CORE.getConcept,
        variables: {
          stepId,
          evaluatedUserId: studentId,
          evaluatorId: userId || evaluatorId,
          activityId,
          conceptActivityId,
        },
      });

      if (conceptResponse?.getConcept) {
        cache.writeQuery({
          query: gqlSchema.InvestigationSchema.queries.CORE.getConcept,
          variables: {
            stepId,
            evaluatedUserId: studentId,
            evaluatorId,
            activityId,
            conceptActivityId,
          },
          data: {
            getConcept: {
              ...conceptResponse?.getConcept,
              evaluation: data?.evaluateConcept,
            },
          },
        });
      }
    },
  });

  const [saveFeedback, { loading: loadingSaveFeedback }] = useMutation<
    { completeConceptEvaluation: GQL_EvaluationAnswers },
    { id: string; feedback?: string; evaluatorId?: string }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.completeConceptEvaluation, {
    onError: (err) => {
      message.error(err.message || 'Unexpected Error');
    },
    onCompleted: () => {
      setHasClickedOnFinish(false);
      message.success('Feedback saved');
      history.goBack();
    },
    refetchQueries: [
      { query: gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById, variables: { id: investigationId } },
      {
        query: gqlSchema.InvestigationSchema.queries.CORE.getConcept,
        variables: {
          stepId,
          evaluatedUserId: studentId,
          evaluatorId: userId || evaluatorId,
          activityId,
          conceptActivityId,
        },
      },
    ],
  });

  const onSelectAnswer = (questionIndex: number, subquestionIndex: number, answerIndex: number) => {
    setHasClickedOnFinish(false);
    saveAnswer({
      variables: {
        activityId: activityId,
        conceptActivityId: conceptActivityId,
        answerValue: isConceptRating ? String(answerIndex) : defaultAnswers[answerIndex].value,
        questionIndex: subquestionIndex,
        stepId,
        studentId,
        evaluatorId: userId || evaluatorId,
      },
    });
  };

  const onSaveFeedback = (questionIndex: number, feedbackList: string[]) => {
    if (concept?.evaluation?.id) {
      saveFeedback({
        variables: {
          id: concept.evaluation.id,
          feedback: feedbackList[questionIndex],
          evaluatorId: userId || evaluatorId,
        },
      });
    }
  };

  const loading = loadingConceptData || loadingSaveAnswer || loadingSaveFeedback;

  return (
    <Layout>
      <InvestigationContent noMargin>
        <Row justify="center">
          <S.Column xl={20} md={23} onClick={() => history.goBack()}>
            <div>
              <FiArrowLeft />
              <h1>{concept?.question?.title || 'Concept'}</h1>
            </div>
          </S.Column>
        </Row>

        <Row justify="center">
          <Col xl={20} md={23}>
            <Row gutter={24}>
              {concept?.question && (
                <Col span={12}>
                  <Spacer size={16} />
                  <InvestigationQuestionnaire
                    paginate
                    editorKey={conceptFiles[0]?.fileUrl + 'conceptEval' + activityId + studentId}
                    hideQuestionNumber
                    onLastQuestion={() => {
                      setHasClickedOnFinish(true);
                    }}
                    onSelectAnswer={onSelectAnswer}
                    saveFeedback={onSaveFeedback}
                    disableAnswers={!!concept?.evaluation?.completedAt}
                    loading={loading}
                    optionsAsRating={isConceptRating}
                    defaultOptions={defaultAnswers}
                    finishButtonText="Finish Evaluation"
                    questionnaire={{
                      questions: concept?.question
                        ? [
                            {
                              ...concept?.question,
                              needsFeedback: concept?.evaluation?.answers?.some((a) => a.answerValue === 'yes'),
                              feedbackTitle:
                                'How could this concept be changed so it will no longer violate any of these constraints?',
                              feedback: concept?.evaluation?.feedback,
                              subquestions: concept?.question?.subquestions?.map((subquestion, index) => ({
                                ...subquestion,
                                isValid:
                                  loading ||
                                  !hasClickedOnFinish ||
                                  !!concept?.evaluation?.answers?.find((answer) => answer.questionIndex === index)
                                    ?.answerValue,
                                answer: isConceptRating
                                  ? Number(
                                      concept?.evaluation?.answers?.find((answer) => answer.questionIndex === index)
                                        ?.answerValue,
                                    )
                                  : defaultAnswers?.findIndex(
                                      (v) =>
                                        v.value ===
                                        concept?.evaluation?.answers?.find((answer) => answer.questionIndex === index)
                                          ?.answerValue,
                                    ),
                              })),
                            },
                          ]
                        : [],
                    }}
                  />
                </Col>
              )}
              <Col span={12}>
                <InvestigationConcept concept={concept} loading={loadingConceptData} />
              </Col>
              {loadingConceptData && (
                <S.LoadingBarrier>
                  <CircularSpin />
                </S.LoadingBarrier>
              )}
            </Row>
          </Col>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(ConceptEvaluationInvestigationPage);
