import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Col, Row, Menu as AntdMenu, Dropdown, Tooltip } from 'antd';
import Input from '../../Input';
import Button from '../../Button';
import * as S from './styles';
import { CgMoreVertical } from 'react-icons/cg';
import { CreateClassFlowStep, GroupConfig } from '../../../types/class';
import { StudentDataProps } from '../../../types/students';
import { themeConfig } from '../../../utils/theme';
const { SubMenu } = AntdMenu;

interface Props {
  students?: StudentDataProps[];
  steps?: CreateClassFlowStep[];
  loading?: boolean;
  removeGroup?: boolean;
  removeStudent?: boolean;
  setStep?: Dispatch<SetStateAction<CreateClassFlowStep>>;
  setNumberOfGroups?: Dispatch<SetStateAction<number>>;
  groups: GroupConfig[];
  onFinishManageGroup: (args?: { sendInvites?: boolean }) => void;
  onRemoveStudent: (students: StudentDataProps) => void | Promise<void>;
  onMoveStudent: (student: StudentDataProps, newGroup: string) => void | Promise<void>;
}

const ManageGroups: React.FC<Props> = (props) => {
  const {
    setStep,
    steps,
    groups,
    students,
    loading,
    onRemoveStudent,
    removeStudent,
    onMoveStudent,
    onFinishManageGroup,
    setNumberOfGroups,
    removeGroup,
  } = props;
  const menu = useCallback(
    (student: StudentDataProps) => {
      const availableGroups = groups.filter((group: GroupConfig) => group.group !== student.group);
      const subMenuItems = availableGroups.map((group: GroupConfig, index) => {
        return (
          <AntdMenu.Item
            key={index}
            onClick={() => {
              onMoveStudent(student, group.group);
            }}
          >
            Move to Group {group.group}
          </AntdMenu.Item>
        );
      });

      return subMenuItems.length > 0 || removeStudent ? (
        <AntdMenu>
          {subMenuItems.length > 0 && (
            <SubMenu data-cy="shared-createclass-managegroups-movestudent-submenu" title="Move Student">
              {subMenuItems}
            </SubMenu>
          )}
          {removeStudent && (
            <>
              <AntdMenu.Divider />
              <AntdMenu.Item
                onClick={() => {
                  onRemoveStudent(student);
                }}
              >
                Remove Student
              </AntdMenu.Item>
            </>
          )}
        </AntdMenu>
      ) : (
        <AntdMenu>
          <AntdMenu.Item>No Actions Allowed</AntdMenu.Item>
        </AntdMenu>
      );
    },
    [groups, onMoveStudent, onRemoveStudent, removeStudent],
  );

  const renderButtons = useCallback(() => {
    if (steps && setStep) {
      return (
        <Row gutter={16} style={{ marginBottom: 30 }}>
          <Col span={12}>
            <Button
              data-cy="components-classes-row-setup-groups-send-invite-later"
              block
              theme={themeConfig.noColor}
              text="Send Invites Later"
              loading={loading}
              htmlType="button"
              onClick={() => onFinishManageGroup()}
            />
          </Col>
          <Col span={12}>
            <Button
              data-cy="components-classes-row-setup-groups-send-invite-now"
              block
              text="Send Invites"
              loading={loading}
              htmlType="button"
              onClick={() => onFinishManageGroup({ sendInvites: true })}
            />
          </Col>
        </Row>
      );
    }
    return (
      <Row gutter={16} style={{ marginBottom: 30 }}>
        <Col span={12}>
          <Button
            data-cy="components-classes-row-setup-groups-backdashboard-button"
            block
            theme={themeConfig.noColor}
            text="Back to Dashboard"
            htmlType="button"
            onClick={() => onFinishManageGroup()}
          />
        </Col>
        <Col span={12}>
          <Button
            data-cy="components-classes-row-setup-groups-create-new-group"
            block
            text="Create Another Group"
            loading={loading}
            htmlType="button"
            disabled={groups.length === 10}
            onClick={() => {
              setNumberOfGroups && setNumberOfGroups(groups.length + 1);
            }}
          />
        </Col>
      </Row>
    );
  }, [steps, setStep, onFinishManageGroup, setNumberOfGroups, groups.length, loading]);

  const renderGroups = useCallback(() => {
    if (students) {
      const rows = [
        <Row key="title" gutter={[16, 8]}>
          <Col span={4}>
            <S.Title>Group Name</S.Title>
          </Col>
          <Col span={9}>
            <S.Title>Student Name</S.Title>
          </Col>
          <Col span={10}>
            <S.Title>Student Email</S.Title>
          </Col>
        </Row>,
      ];

      const studentsUnassigned = students.filter((student: StudentDataProps) => !student.group);
      if (studentsUnassigned && studentsUnassigned.length > 0) {
        rows.push(
          <Row key="unassigned" gutter={[16, 24]}>
            <Col span={4}>
              <S.Group $background="#989898">Unassigned</S.Group>
            </Col>
            <Col span={20}>
              {studentsUnassigned.map((student: StudentDataProps) => {
                return (
                  <Row key={student.email} gutter={[8, 8]} style={{ marginBottom: 8 }}>
                    <Col span={11}>
                      <Input disabled defaultValue={`${student.firstName} ${student.lastName}`} />
                    </Col>
                    <Col span={11}>
                      <Input disabled defaultValue={student.email} />
                    </Col>
                    <Col span={2}>
                      <Dropdown overlay={menu(student)} placement="bottomRight" arrow>
                        <S.MenuIcon
                          icon={
                            <CgMoreVertical
                              data-cy={`shared-createclass-managegroup-menu-icon-unassigned-${student.firstName}`}
                            />
                          }
                        />
                      </Dropdown>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>,
        );
      }

      const data = groups.map((group: GroupConfig, index: number) => {
        const groupStudents = students.filter((student: StudentDataProps) => student.group === group.group);
        return (
          <Row key={index} gutter={[16, 24]}>
            <Col span={4}>
              <S.Group $background={group.color}>Group {group.group} </S.Group>
            </Col>
            {groupStudents && groupStudents.length > 0 ? (
              <Col span={20}>
                {groupStudents.map((student: StudentDataProps) => {
                  return (
                    <Row key={student.email} gutter={[8, 8]} style={{ marginBottom: 8 }}>
                      <Col span={11}>
                        <Input disabled defaultValue={`${student.firstName} ${student.lastName}`} />
                      </Col>
                      <Col span={11}>
                        <Input disabled defaultValue={student.email} />
                      </Col>
                      <Col span={2}>
                        <Dropdown overlay={menu(student)} placement="bottomRight" arrow>
                          <S.MenuIcon
                            icon={
                              <CgMoreVertical data-cy={`shared-createclass-managegroup-menu-icon-${student.email}`} />
                            }
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            ) : (
              <Col span={20}>
                <Row gutter={[8, 8]}>
                  <Col span={22}>
                    <S.EmptyDiv>
                      <S.EmptyGroup>There are no students currently in this group.</S.EmptyGroup>
                      {removeGroup && index === groups.length - 1 && (
                        <Tooltip title="Delete last empty group">
                          <S.DeleteIcon
                            data-cy="shared-createclass-managegroups-delete-button"
                            size={18}
                            onClick={() => {
                              setNumberOfGroups && setNumberOfGroups(groups.length - 1);
                            }}
                          />
                        </Tooltip>
                      )}
                    </S.EmptyDiv>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        );
      });
      return [...rows, ...data];
    }
    return null;
  }, [students, groups, menu, removeGroup, setNumberOfGroups]);

  return (
    <Row gutter={[24, 24]}>
      {steps && steps.length > 0 && <S.BackButton size={24} onClick={() => setStep && steps && setStep(steps[1])} />}
      <Col span={12} offset={6}>
        <S.Info>Manage the groups of the students you</S.Info>
        <S.Info>have enrolled in this class.</S.Info>
      </Col>
      <Col span={22} offset={1} style={{ paddingBottom: 0 }}>
        {renderGroups()}
      </Col>
      <Col span={16} offset={4}>
        {renderButtons()}
      </Col>
    </Row>
  );
};

export default React.memo(ManageGroups);
