import styled from 'styled-components';

export const TitleDragger = styled.p<{
  $color?: string;
}>`
  padding: 0;
  font-size: 0.95em;
  margin: 0;
  text-align: center;
  color: ${(props) => props.$color || props.theme.primaryOutlined.color};
  svg {
    path {
      stroke: ${(props) => props.$color || props.theme.primaryOutlined.color} !important;
    }
  }
`;
