import { useMutation } from '@apollo/client';
import { Col, Modal, Row, message } from 'antd';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { gqlSchema } from '../../../gql/schema';
import { GQL_WhitelistedDomain } from '../../../types/whitelistedDomain';
import Input from '../../../shared/Input';
import Button from '../../../shared/Button';

type WhitelistDomainModalProps = {
  setVisible: (val: boolean) => void;
  visible: boolean;
  actionCompleted: () => void;
};

export const WhitelistDomainModal: React.FC<WhitelistDomainModalProps> = (props) => {
  const { visible, setVisible, actionCompleted } = props;
  const [inputValue, setInputValue] = useState('');

  const handleCloseModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const [addDomain, { loading: addingDomain }] = useMutation(
    gqlSchema.WhitelistedDomainSchema.mutations.addWhitelistedDomain,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: gqlSchema.WhitelistedDomainSchema.queries.getWhitelistedDomains }],
      onCompleted: (result: GQL_WhitelistedDomain) => {
        message.success('Domain Added');
        setInputValue('');
        actionCompleted();
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const handleAddDomain = useCallback(() => {
    addDomain({
      variables: {
        data: {
          domain: inputValue,
        },
      },
    });
  }, [addDomain, inputValue]);

  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null} closable={!addingDomain}>
      <Row justify="center">
        <Col span={16}>
          <h3> Add a domain to be whitelisted</h3>
        </Col>
      </Row>

      <Row gutter={16} style={{ padding: '30px 0 20px 0' }}>
        <Col span={15}>
          <Input
            data-cy="add-domian-input"
            value={inputValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
          />
        </Col>

        <Col span={4}>
          <Button
            data-cy="add-domain-modal-button"
            loading={addingDomain}
            text={'Add domain'}
            disabled={!inputValue}
            onClick={handleAddDomain}
          />
        </Col>
      </Row>
    </Modal>
  );
};
