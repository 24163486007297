import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: 'white';
  text-align: left;
  height: 500px;
  width: 100%;

  @media (max-width: 1024px) {
    height: auto;
    margin-top: 10px;
  }
`;

export const MainHeaderContent = styled.section`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2em;
  margin-top: -3em;
  padding: 0.4em;

  @media (max-width: 1024px) {
    margin-left: 1em;
    margin-top: 0;
    padding: 1em;
  }
`;

export const Title = styled.h1`
  font-size: 16px;
  color: #4367e9;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1em;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const HSubtitle = styled.h1`
  font-size: 44px;
  color:  #222;
  margin-bottom: 20px;
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 26px;
  }
`;

export const MainHeadertitle = styled.div`
  font-size: 15px;
  margin-bottom: 20px;
  text-align: left;
  max-width: 25rem;

  @media (max-width: 1024px) {
    font-size: 13px;
    max-width: 100%;
  }
`;

export const Image = styled.img`
  max-width: 45rem;
  height: auto;
  margin-top: 30px;
  overflow: hidden;

  @media (max-width: 1024px) {
    min-width: 35rem;
  }
`;

export const Button = styled.button`
  background-color: #4367e9;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    background-color: #365ac9;
  }

  @media (max-width: 1024px) {
    padding: 8px 16px;
    font-size: 0.9em;
  }
`;