import { gql } from '@apollo/client';

const TRANSFER_INVITE_MUTATIONS = {
  denyTransferInvite: gql`
    mutation denyTransferInvite($token: String!) {
      denyTransferInvite(token: $token)
    }
  `,
  acceptTransferInvite: gql`
    mutation acceptTransferInvite($token: String!) {
      acceptTransferInvite(token: $token)
    }
  `,
  sendTransferInvites: gql`
    mutation sendTransferInvites($tokens: [String!]!) {
      sendTransferInvites(tokens: $tokens)
    }
  `,
};

export default TRANSFER_INVITE_MUTATIONS;
