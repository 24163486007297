import React, { ChangeEvent, useEffect, useState } from 'react';
import Radio from 'antd/lib/radio';
import { Row } from 'antd';
import { RcFile } from 'antd/lib/upload';

import Input from '../../../../../shared/Input';
import Spacer from '../../../../../shared/Spacer';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  ITextContentValue,
} from '../../../../../types/investigation';
import Editor from '../../../../../shared/Editor';
import UploadInput from '../../../../../shared/UploadInput';
import { IOnChageEditor } from '../../../../../types';
import * as S from './styles';
import Button from '../../../../../shared/Button';
import { themeConfig } from '../../../../../utils/theme';

interface IMultipleChoicePictureQuestionEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const MultipleChoicePictureQuestionEditor = (props: IMultipleChoicePictureQuestionEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const [targetAnswerIndex, setTargetAnswerIndex] = useState<number>();
  const [imageUploaded, setImageUploaded] = useState<boolean>(false); // State to track if image uploaded

  useEffect(() => {
    // Loads the Target answer
    activities.forEach((activity) => {
      if (activity.id !== selectedActivityId) return activity;
      (editingRemote ? activity.content : activity.contentInPerson).forEach((content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;
        const newBlocks = [...content.blocks];


        const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
        selectedBlockValues.forEach((answer, answIdx) => {
          
          if (answer.url) {
            setImageUploaded(true);
          }
          else
            if (answer.file === undefined) {
              setImageUploaded(false);
            }

          if (answer.targetAnswer) {
            setTargetAnswerIndex(answIdx);
          }
        });
      });
    });
  }, [activities, editingRemote, selectedActivityId, selectedBlockIndex, selectedContentIndex]);

  const handleEditQuestion = (data: IOnChageEditor, paragraphIndex: number) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
          selectedBlockValues[paragraphIndex] = {
            ...selectedBlockValues[paragraphIndex],
            text: data.value,
            targetAnswer: i === targetAnswerIndex,
          };

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditAnswers = (
    data: { index?: number; file?: File; fileId?: string; url?: string; alt?: string },
    paragraphIndex: number,
  ) => {

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];

          // Set the chosen Image to the activity block values
          selectedBlockValues[paragraphIndex] = {
            ...selectedBlockValues[paragraphIndex],
            file: data.file,
            fileName: data.file?.name,
            targetAnswer: i === targetAnswerIndex,
            url: data.url,
            alt: data.alt,
          };

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const addPictureOption = () => {
    setTargetAnswerIndex(undefined);

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;

        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          const newOption: ITextContentValue = {
            fileName: '',
            targetAnswer: false,
            url: '',
            optionLabel: '',
            file: undefined,
            text: '',
            alt: '',
            spans: [],
          };

          const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[]), newOption];

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const removePictureOption = (blockIndex: number) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;

        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          let newBlocks = [...content.blocks];

          let selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
          selectedBlockValues.splice(blockIndex, 1);

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const onCheckAnswer = (paragraphIndex: number) => {
    setTargetAnswerIndex(paragraphIndex);
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          let selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
          selectedBlockValues = selectedBlockValues.map((answer, answIdx) => ({
            ...answer,
            targetAnswer: answIdx === paragraphIndex,
          }));

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  // CHECK FOR PICKED UP FILE
  const beforeUploadCanvasImages = (file: RcFile, fileList: RcFile[]) => {
    if (!file.type.includes('image')) return false;

    return true;
  };

  const handleLabel = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
          selectedBlockValues[index] = {
            ...selectedBlockValues[index],
            optionLabel: e.target.value,
          };

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleAltText = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    // Don't update alt text if image is not uploaded
    if (!imageUploaded) return;

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
          selectedBlockValues[index] = {
            ...selectedBlockValues[index],
            alt: e.target.value,
          };
          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const optionLabel = (text: string | undefined, index: number, alt: string | undefined) => {
    return (
      <S.LabelInputContainer>
        <Row>
          <S.DeleteParagraphContainer>
            <h2>Label {index}: </h2>
            {!!index && <Radio value={index}> Target answer</Radio>}
          </S.DeleteParagraphContainer>
        </Row>
        <Input
          placeholder="Add option label"
          backgroundColor="white"
          value={text}
          onChange={(e) => handleLabel(e, index)}
          name="optionTitle"
        />
        <Spacer />
        <Input
          placeholder="Add alt text"
          backgroundColor="white"
          value={alt}
          onChange={(e) => handleAltText(e, index)}
          name="altText"
          disabled={!imageUploaded} // Disable alt text input if image is not uploaded
        />
        <Spacer />
      </S.LabelInputContainer>
    );
  };

  return (
    <>
      <Radio.Group value={targetAnswerIndex} onChange={(e) => onCheckAnswer?.(e.target.value)}>
        {editingBlock?.values?.map((value, index) => (
          <React.Fragment key={index}>
            <Spacer />

            {!index ? <h2>Question</h2> : optionLabel((value as ITextContentValue).optionLabel, index, (value as ITextContentValue).alt)}

            {!index && (
              <Editor
                fontSize={11}
                key={`bI:${selectedBlockIndex}pI:${index}`}
                value={(value as ITextContentValue).text}
                placeholder="Start writing the paragraph content..."
                onChange={(data) => handleEditQuestion(data, index)}
                showAlign
                showLink
              />
            )}

            {!!index && (
              <S.InputContainer>
                <>
                  <UploadInput
                    title={
                      (value as ITextContentValue).fileName ||
                      (value as ITextContentValue).file?.name ||
                      (value as ITextContentValue).url ||
                      'Upload Images from Computer'
                    }
                    accept="image/*"
                    beforeUpload={beforeUploadCanvasImages}
                    defaultFileList={[]}
                    fileList={[]}
                    customRequest={(options) => {
                      const file = options.file as RcFile;
                      handleEditAnswers({ file }, index);
                      setImageUploaded(true); // Set imageUploaded to true when a file is selected for upload
                      return true;
                    }}
                  />
                  <Spacer />
                  <Button
                    theme={themeConfig.orangeOutlined}
                    text="Remove Option"
                    onClick={() => removePictureOption(index)}
                    style={{ fontSize: '12px', padding: '0px 8px', minHeight: '0px' }}
                  />
                  <Spacer />
                </>
              </S.InputContainer>
            )}
          </React.Fragment>
        ))}
      </Radio.Group>
      <Spacer />
      <Spacer />
      <Button
        block
        text="Add Option"
        onClick={addPictureOption}
        theme={themeConfig.secondaryOutlined}
      />
      <Spacer />
      <Spacer />
    </>
  );
};

export default MultipleChoicePictureQuestionEditor;
