import styled from 'styled-components';

export const Container = styled.div<{ padding?: number }>`
  padding: ${(props) => (props.padding ? `${props.padding}em` : '4em')};
@media (max-width: 768px) {
  padding:0px;
  margin-Top:80px;
  /* mobile-specific styles here */ 
}
  
`;
