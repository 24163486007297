import styled from 'styled-components';

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')} !important;
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')} !important;
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;
  padding: 0 15px;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    text-align: center;
    font-size: 0.55em;
    white-space: normal;
    padding: 0 15px;
    font-weight: 500;
  }
`;

export const InfoProgress = styled.p<{ $fontSize?: string }>`
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.5em')} !important;
  margin: 10px 0 0 0;
  white-space: normal;
  padding: 0 15px;
`;

export const ProgressContainer = styled.div`
  color: #000000;
`;
