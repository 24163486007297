import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Form as AntdForm, Col, Row, message, Divider, Tooltip } from 'antd';
import Form from '../../Form';
import Input from '../../Input';
import Button from '../../Button';
import * as S from './styles';
import { useForm } from 'antd/lib/form/Form';
import { GQL_SendInviteResponse } from '../../../types/invites';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import RangePicker from '../../RangePicker';
import { TagResponse } from '../../../types/tags';
import TagInput from '../../TagInput';

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const Facilitator: React.FC<Props> = (props) => {
  const [form] = useForm();

  const [createFacilitator, { loading }] = useMutation<{ createAndSendInvite: GQL_SendInviteResponse }>(
    gqlSchema.InvitesSchema.mutations.SEND.createAndSendInvite,
    {
      onError: (err) => {
        message.error(err.message);
      },
      onCompleted: (data) => {
        if (data?.createAndSendInvite.emailSent) {
          form.resetFields();
          message.success(`Facilitator created successfully`);
          props.setVisible(false);
        }
      },
    },
  );

  const onFinish = useCallback(
    async (data: {
      firstName: string;
      lastName: string;
      email: string;
      subscriptionData: {
        startDate: string;
        endDate: string;
      };
      taCustomPermissions: boolean;
    }) => {
      const { subscriptionData, taCustomPermissions, ...info } = data;
      const startDate = subscriptionData.startDate.includes('.')
        ? subscriptionData.startDate.replace(/\./g, '/')
        : subscriptionData.startDate;
      const endDate = subscriptionData.endDate.includes('.')
        ? subscriptionData.endDate.replace(/\./g, '/')
        : subscriptionData.endDate;
      createFacilitator({
        variables: {
          data: {
            ...info,
            role: 'facilitator',
            subscriptionData: {
              startDate: new Date(startDate).getTime(),
              endDate: new Date(endDate).getTime(),
              taCustomPermissions: taCustomPermissions || false,
            },
          },
        },
      });
    },
    [createFacilitator],
  );

  const handleTagChange = useCallback(
    (tags: TagResponse[]) => {
      form.setFieldsValue({ tags: tags?.map((t) => ({ ...t, __typename: undefined })) });
    },
    [form],
  );

  return (
    <Form onFinish={onFinish} form={form}>
      <Row gutter={[24, 16]} justify="center" style={{ marginTop: 10 }}>
        <Col span={20}>
          <S.SubTitle>User Details</S.SubTitle>
          <Divider style={{ margin: '5px 0' }} />
        </Col>
        <Col span={4} style={{ marginLeft: 1 }}>
          <S.TitleInput>First Name</S.TitleInput>
          <AntdForm.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Input the First Name!',
              },
            ]}
          >
            <Input />
          </AntdForm.Item>
        </Col>
        <Col span={4}>
          <S.TitleInput>Last Name</S.TitleInput>
          <AntdForm.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Input the Last Name!',
              },
            ]}
          >
            <Input />
          </AntdForm.Item>
        </Col>
        <Col span={6}>
          <S.TitleInput>Email</S.TitleInput>
          <AntdForm.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Input the Email!',
              },
              {
                type: 'email',
              },
            ]}
          >
            <Input />
          </AntdForm.Item>
        </Col>
        <Col span={6}>
          <S.TitleInput>Tags</S.TitleInput>
          <AntdForm.Item name="tags">
            <TagInput alwaysEditable backgroundColor="#f8f8f8" maxTagCount={0} onChange={handleTagChange} />
          </AntdForm.Item>
        </Col>
        <Col span={24}>
          <Row justify="center" gutter={[24, 16]}>
            <Col span={20}>
              <S.SubTitle>Subscription Details</S.SubTitle>
              <Divider style={{ margin: '5px 0' }} />
            </Col>
            <Col span={20}>
              <Row gutter={24}>
                <Col span={11}>
                  <S.TitleInput>Duration</S.TitleInput>
                  <AntdForm.Item
                    name="subscriptionData"
                    rules={[
                      {
                        required: true,
                        message: 'Please select the subscription duration!',
                      },
                    ]}
                  >
                    <RangePicker />
                  </AntdForm.Item>
                </Col>
                <AntdForm.Item name="taCustomPermissions" valuePropName="checked" style={{ marginBottom: 0 }}>
                  <S.CCheckbox>
                    <Tooltip title="This allows assistants to grade students' final reports and allow them to chat with students.">
                      <span role="none" > Enhanced Assistant permissions</span>
                    </Tooltip>
                  </S.CCheckbox>
                </AntdForm.Item>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <AntdForm.Item>
            <Button
              data-cy="shared-adduser-facilitator-submit-button"
              block
              loading={loading}
              text="Add Facilitator"
              htmlType="submit"
            />
          </AntdForm.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(Facilitator);
