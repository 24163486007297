import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PageWithTitle from '../../shared/PageWithTitle';
import ClassAssignmentsRow from './ClassAssignmentsRow';

type Props = RouteComponentProps<{ id: string }, any, { className: string }> & {
  onUpdateNumberOfStudents?: (count: number) => void;
};

const GoogleClassroomTeacherAssignmentPage: React.FC<Props> = (props) => {
  return (
    <PageWithTitle title="Assignments">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}></div>
      <ClassAssignmentsRow
        classId={props.match.params.id}
        // className={props.location.state?.className || ''}
        shouldLoad={true}
      />
    </PageWithTitle>
  );
};

export default withRouter(GoogleClassroomTeacherAssignmentPage);
