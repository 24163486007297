import styled from 'styled-components';

export const IframeComponent = styled.iframe`
  max-width: 100%;
  width: 100%;
  border: 0;
  min-height: 600px;
  background: rgb(245, 245, 251);
`;

export const FullscreenLink = styled.span`
  width: 100%;
  cursor: pointer;
  text-align: right;
  color: #1976d2;
`;

export const LoadingBarrier = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
