import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;

  & > span {
    display: flex;
    button {
      margin-right: 16px;
    }
  }
`;

export const MaterialsModalContainer = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125.1%;
    text-align: left;

    color: #767676;
  }

  & > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;

    text-align: center;
  }

  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125.1%;
    width: 280px;
    margin: auto;

    text-align: center;

    color: #767676;
  }
`;

export const DownloadLink = styled.a`
  background-color: ${(props) => props.theme.primaryColor.background};
  color: white;
  border-radius: 3px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 35px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  text-align: left;
  p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    margin: 0;
  }
`;
