import styled from 'styled-components';

export const Heading = styled.h2`
  text-align: center;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 16px;
  margin-bottom: 48px;
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 16px;
  display: block;
`;

export const OptionContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 4px;
`;

export const LabelOptions = styled.label`
  font-size: 14px;
  margin: 15px 0;
  display: block;
  font-weight: 500;
`;
