import { gql } from '@apollo/client';

export const SYNC_SUBSCRIPTIONS = {
  listenForSyncStepsUpdates: gql`
    subscription listenForSyncStepsUpdates($logId: String!) {
      listenForSyncStepsUpdates(logId: $logId) {
        syncLogStepId
        name
        status
        message
        createdAt
        finishedAt
      }
    }
  `,
  listenForSyncUpdates: gql`
    subscription listenForSyncUpdates($logId: String) {
      listenForSyncUpdates(logId: $logId) {
        status
        finishedAt
      }
    }
  `,
};

export default SYNC_SUBSCRIPTIONS;
