import React, { useState, useEffect } from 'react';
import { message, Row, Col, Divider, Input } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import CanvasClassRow from './CanvasClassRow';
import * as S from './styles';
import ActiveAssignments from '../CanvasStudentsClassDashboard/ActiveAssignments';
import FinishedAssignments from '../CanvasStudentsClassDashboard/FinishedAssignments';
import DashboardRow from '../../shared/DashboardRow';

const CanvasStudentsDashboard: React.FC = () => {
  const [courses, setCourses] = useState<any[]>([]);
  const [finishedAssignments, setFinishedAssignments] = useState<any[]>([]);
  const [courseWorkList, setCourseWorkList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [activeAssignmentSearch, setActiveAssignmentSearch] = useState('');
  const [finishedAssignmentSearch, setFinishedAssignmentSearch] = useState('');
  const [currentApiPage, setCurrentApiPage] = useState(-1);

  const itemsPerPage = 3;

  const { loading: coursesLoading, refetch } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasCoursesListByUser,
    {
      variables: { page: String(currentPage) },
      onError: (error) => {
        message.error(
          error.message ||
          'There was an error loading your classes, check your connection and try again later.'
        );
      },
      fetchPolicy: 'network-only',
      skip: currentApiPage === currentPage,
      onCompleted: (data) => {
        const fetchedCourses = data?.getCanvasCoursesListByUser || [];
        if(fetchedCourses.length){
          if(fetchedCourses.length === 3) setHasMorePages(true)
          setCourses(fetchedCourses);
          setCurrentApiPage(currentPage);
        }else{
          if(currentPage !==1){
            message.info(
              'No more pages'
            );
            setCurrentPage(currentApiPage)
          }
          
          setHasMorePages(false)
          
        }
      },
    }
  );

  const [getAssignmentData, { loading: assignmentsLoading }] = useLazyQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentListByTurnedIn,
    {
      onError: (err) => {
        message.error('There was an error loading assignments: ' + (err.message || 'Unexpected Error'));
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setCourseWorkList(data.getCanvasAssignmentListByTurnedIn.active);
        setFinishedAssignments(data.getCanvasAssignmentListByTurnedIn.expired);
      },
    }
  );

  useEffect(() => {
    if (courses.length) {
      getAssignmentData({
        variables: { course_id: courses.map((course) => course.id) },
      });
    }
  }, [courses, getAssignmentData]);

  useEffect(() => {
    if(currentPage !== currentApiPage ){
    refetch({ page: String(currentPage) });
    }
  }, [currentPage, refetch]);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <S.Container padding={2}>
      <Row gutter={[24, 24]}>
        <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24}>
          <CanvasClassRow 
            courses={courses} 
            loading={coursesLoading} 
            currentPage={currentPage} 
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            hasMorePages={hasMorePages}
          />
          <Divider />
          <DashboardRow 
            title="Active Assignments" 
            items={<> 
              <Input.Search
                placeholder="Search assignments"
                value={activeAssignmentSearch}
                onChange={(e) => setActiveAssignmentSearch(e.target.value)}
                style={{ width: 200, marginBottom: 20 }} // Added marginBottom for space
              />
              <ActiveAssignments
                assignments={courseWorkList?.filter(_assignment => activeAssignmentSearch ? _assignment.name?.toLowerCase().includes(activeAssignmentSearch.toLowerCase()) : true) || []}
                loading={assignmentsLoading}
                courseData={courses}
              /> 
            </>} 
          />
          <Divider />
          <DashboardRow 
            title="Finished Assignments"
            items={<> 
              <Input.Search
                placeholder="Search assignments"
                value={finishedAssignmentSearch}
                onChange={(e) => setFinishedAssignmentSearch(e.target.value)}
                style={{ width: 200, marginBottom: 20 }} // Added marginBottom for space
              />
              <FinishedAssignments
                assignments={finishedAssignments?.filter(_assignment => finishedAssignmentSearch ? _assignment.name?.toLowerCase().includes(finishedAssignmentSearch.toLowerCase()) : true) || []}
                loading={assignmentsLoading}
                courseData={courses}
              />
            </>} 
          />
        </Col>
      </Row>
    </S.Container>
  );
};

export default CanvasStudentsDashboard;
