import { Radio, Switch } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react';
import Input from '../../../../../shared/Input';
import Spacer from '../../../../../shared/Spacer';
import UploadInput from '../../../../../shared/UploadInput';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  IMediaContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';

interface IImagesBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const ImagesBlockEditor = (props: IImagesBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const mediaValues = editingBlock.values.map((v) => v as IMediaContentValue);
  const [isUrl, setIsUrl] = useState(true);
  const [imageSize, setImageSize] = useState<'SMALL' | 'LARGE' | 'EXTRALARGE'>(mediaValues[0]?.size || 'LARGE');
  const [altTexts, setAltTexts] = useState<string[]>(Array(editingBlock.columns || 1).fill(''));

  const beforeUploadImage = (file: RcFile, index: number) => {
    if (!file.type.includes('image')) return false;
    handleEditImageAttributes({ file, index: index });
    return true;
  };
  
  

  const handleClearImage = (e: React.MouseEvent<SVGElement, MouseEvent>, index: number) => {
    e.stopPropagation();
    handleEditImageAttributes({ file: undefined, index });
  };

  const handleChangeUploadMethod = () => {
    setIsUrl(!isUrl);
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              return { ...block, values: [] };
            }),
          };
        });
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleChangeImageSize = (size?: string) => {
    let newSize: 'SMALL' | 'LARGE' | 'EXTRALARGE' = 'LARGE';
    if (size === 'SMALL') newSize = 'SMALL';
    else if (size === 'EXTRALARGE') newSize = 'EXTRALARGE';
    setImageSize(newSize);
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              return { ...block, values: block.values.map((v, idx) => ({ ...v, size: newSize })) };
            }),
          };
        });
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditImageAttributes = (payload: { url?: string; alt?: string; columns?: number; index?: number; file?: File }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              if (payload.columns) {
                return {
                  ...block,
                  columns: (payload.columns ?? 0) > 3 ? 3 : payload.columns,
                  values: block.values?.slice(0, payload.columns),
                };
              }
              const valuesCopy = [...block.values];
              
              // const updatedValue = payload.url
              //   ? { url: payload.url, alt: payload.alt || '', size: imageSize }
              //   : { ...valuesCopy[payload.index || 0], alt: payload.alt || '' }; // Ensure alt text is properly updated
              // valuesCopy[payload.index || 0] = updatedValue;
              if (payload.alt !== undefined) {
                if (payload.alt !== '') {
                  valuesCopy[payload.index || 0] = { ...valuesCopy[payload.index || 0], alt: payload.alt };
                } else {
                  if (payload.file || payload.url) {
                    valuesCopy[payload.index || 0] = {
                      alt: '',
                      url: payload.url || '',
                      file: payload.file,
                      size: imageSize,
                    };
                  } else {
                    valuesCopy[payload.index || 0] = { ...valuesCopy[payload.index || 0], alt: payload.alt };
                  }
                }
              } else {
                if (payload.file || payload.url) {
                  valuesCopy[payload.index || 0] = {
                    alt: '',
                    url: payload.url || '',
                    file: payload.file,
                    size: imageSize,
                  };
                }
              }
              return { ...block, values: valuesCopy };
            }),
          };
        });
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };
  
  



  return (
    <>
      <h2>Grid Size</h2>
      <Input
        value={editingBlock.columns ?? 1}
        backgroundColor="white"
        max={3}
        min={1}
        type="number"
        onChange={(e) => handleEditImageAttributes({ columns: Number(e.target.value) })}
      />
      <Spacer size={24} />
      <h2>Images Size</h2>
      <Radio.Group value={imageSize} onChange={(e) => handleChangeImageSize?.(e.target.value)}>
        <Radio.Button value="EXTRALARGE">Extra-Large</Radio.Button>
        <Radio.Button value="LARGE">Large</Radio.Button>
        <Radio.Button value="SMALL">Small</Radio.Button>
      </Radio.Group>
      <Spacer size={24} />
      <S.UploadFromComputerContainer onClick={handleChangeUploadMethod}>
        <h2>Upload from computer</h2>
        <Switch checked={!isUrl} size="small" />
      </S.UploadFromComputerContainer>
      <Spacer />
      {Array(editingBlock.columns || 1)
        .fill(1)
        .map((_, index) => (
          <React.Fragment key={index}>
            <h2>Image URL {index + 1} </h2>
            {isUrl ? (
              <Input
                key={index}
                value={mediaValues[index]?.url}
                backgroundColor="white"
                placeholder="Image URL"
                onChange={(e) => handleEditImageAttributes({ url: e.target.value, alt: altTexts[index], index })}
              />
            ) : (
              <UploadInput
                beforeUpload={(f) => beforeUploadImage(f, index)}
                title={mediaValues[index]?.file?.name || 'Upload image'}
                showClear={!!mediaValues[index]?.file}
                onClear={(e) => handleClearImage(e, index)}
                accept="image/*"
                defaultFileList={[]}
                fileList={[]}
                customRequest={() => {}}
              />
            )}
            <Spacer />
            <h2>Alt Text {index + 1}</h2>
            <Input
              value={mediaValues[index]?.alt||""}
              backgroundColor="white"
              placeholder="Alt Text"
              onChange={(e) => {
                const newAltTexts = [...altTexts];
                newAltTexts[index] = e.target.value;
                setAltTexts(newAltTexts);
                handleEditImageAttributes({ alt: e.target.value, index });
              }}
            />
            <Spacer />
          </React.Fragment>
        ))}
    </>
  );
};

export default ImagesBlockEditor;
