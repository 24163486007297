import styled from 'styled-components';
import CButton from '../../../Button';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5em;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .middle {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .end {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }

  .ant-skeleton-element {
    width: 100%;
  }
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const TimeLeft = styled.p<{
  $warning: boolean;
  $fontSize: string;
}>`
  font-size: 0.87em;
  color: ${(props) => (props.$warning ? '#c10000' : '#000000d9')};
  font-weight: 400;
  text-align: center;
  margin: 0;
  vertical-align: bottom;

  svg {
    margin: 0 15px;
    font-size: 1.35em;
    vertical-align: top;
  }
`;

export const ClassName = styled.span<{
  $color: string;
}>`
  background: ${(props) => props.$color};
  color: white;
  padding: 5px 25px;
  font-weight: 500;
  border-radius: 40px;
`;

export const Icon = styled.p`
  font-size: 3.5em;
  margin-bottom: 0;
  text-align: center;
`;

export const Title = styled.h3<{
  $align?: string;
}>`
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  font-weight: 500;
  margin-bottom: 0;
`;

export const DashboardButton = styled(CButton)`
  font-weight: 500;
`;
