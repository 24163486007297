import styled from 'styled-components';

export const QuestionBlock = styled.p`
  text-align: left;
  align-self: flex-start;
  font-weight: 800 !important;
  padding: 0;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const AnswerBlock = styled.div`
  width: 100%;
  border: 1px solid #cacaca;
  padding: 16px;
  margin-bottom: 8px;
  transition: 0.2s;

  :hover {
    border-color: cadetblue;
  }
`;

export const AnswerHead = styled.div`
  display: flex;
  text-align: left;
  gap: 8px;
  width: 100%;
`;

export const ImageAnswerBlock = styled.div`
  border: 1px solid #cacaca;
  padding: 16px;
  margin-bottom: 8px;
  transition: 0.2s;
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  :hover {
    border-color: cadetblue;
  }
  .ant-image {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

export const AnswersContainer = styled.div`
  width: 100%;
  gap: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
`;
