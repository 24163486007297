import styled from 'styled-components';
import Button from '../../shared/Button';

export const Container = styled.div`
  padding: 4em;
`;

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const SendInviteDisclaimer = styled.div`
  display: inline;
`;

export const ResendInvite = styled.span`
  display: inline;
  margin: 0 4px;
  font-weight: 500;
  cursor: pointer;
`;

export const SearchIcon = styled.span<{
  $searchVisible: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 10px 4px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => (props.$searchVisible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px' : 'unset')};
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 2px;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.95em;
  text-align: left;
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;
