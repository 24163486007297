import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 4em;
  border-radius: 4px;
  background: white;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.82em;
  margin: 0;
  text-align: center;
`;

export const Icon = styled.p`
  font-size: 3.5em;
  margin-bottom: 0;
  text-align: center;
  color: ${(props) => props.theme.success.color};
`;

export const Title = styled.h3`
  font-weight: 500;
  margin-bottom: 0.3em;
`;
