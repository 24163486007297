import styled from 'styled-components';

export const Title = styled.div`
  margin-left: 5px;
  display: inline;
  vertical-align: sub;
  width: 100%;
  font-weight: 500;
  font-size: 1.5em;
`;

export const TitleError = styled.h3`
  font-size: 1.4em;
`;

export const Card = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 170px;
  justify-content: center;
  align-items: center;
  text-align: center;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 129.1%;
    padding: 0 24px;
    text-align: center;
    color: #767676;
  }
`;
