import React from 'react';
import * as S from './styles';
import Image1 from '../../../../../assets/booklanding/learnmore/sense.svg';
import Image2 from '../../../../../assets/booklanding/learnmore/students agency.svg';
import Image3 from '../../../../../assets/booklanding/learnmore/collaborate.svg';
import Image4 from '../../../../../assets/booklanding/learnmore/interative.svg';
import Image5 from '../../../../../assets/booklanding/learnmore/language.svg';
import Image6 from '../../../../../assets/booklanding/learnmore/visible thinking.svg';
//TODO : Make sprites for the svg
const items = [
  {
    image: Image1,
    content1: 'Sense-Making',
    content2: 'Focus on understanding phenomena rather than rote memorization.',
    style: { backgroundColor: 'red', borderRadius: '50%' },
  },
  {
    image: Image2,
    content1: 'Student Agency',
    content2: 'Encourages decision-making in lessons.',
    style: { backgroundColor: '#20BC89', borderRadius: '50%' },
  },
  {
    image: Image3,
    content1: 'Collaboration',
    content2: ' Promotes valuing diverse ideas for collective learning.',
    style: { backgroundColor: '#F89D3E', borderRadius: '50%' },
  },
  {
    image: Image4,
    content1: 'Interactive',
    content2: 'Focus on understanding phenomena rather than rote memorization.',
    style: { backgroundColor: '#F89D3E', borderRadius: '50%' },
  },
  {
    image: Image5,
    content1: 'Language-Rich Environment',
    content2: 'Enhances communication through reading, writing, and  discussion.',
    style: { backgroundColor: 'red', borderRadius: '50%' },
  },
  {
    image: Image6,
    content1: 'Visible Thinking',
    content2: 'Transparency in reasoning for ongoing assessment.',
    style: { backgroundColor: '#20BC89', borderRadius: '50%' },
  },
];

const LearnPurpose: React.FC = () => {
  return (
    <>
      <S.MainWrapper>
        <S.SectionWrapper>
          <S.PTitle>Purpose and Implementation</S.PTitle>
          <S.SubTitle>
            The model guides educators in creating a rigorous, meaningful, and equitable learning environment,
            emphasizing <br /> three-dimensional instruction.
          </S.SubTitle>
          <S.GridContainer>
            {items.map((item, index) => (
              <S.GridItem key={index}>
                <div style={item.style}>
                  <S.RoundImage src={item.image} alt={`Item ${index + 1}`} />
                </div>
                <S.ItemContentHead>{item.content1}</S.ItemContentHead>
                <S.ItemContentBody>{item.content2}</S.ItemContentBody>
              </S.GridItem>
            ))}
          </S.GridContainer>
        </S.SectionWrapper>
      </S.MainWrapper>
    </>
  );
};

export default LearnPurpose;
