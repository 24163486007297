import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { message } from 'antd';

import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import { GQL_ClassResponse } from '../../types/class';
import ClassInfos from './ClassInfos';
import ClassInvestigationsRow from './ClassInvestigationsRow';
import StudentsTable from './StudentsTable';
import ClassAnnouncementsRow from './ClassAnnouncementsRow';
import Spacer from '../../shared/Spacer';
import { useAuth } from '../../hooks/useAuth';

type Props = RouteComponentProps<{ id: string }, any, { className: string }>;

const ClassDashboard: React.FC<Props> = (props) => {
  const { id } = props.match.params;
  const className = props.location.state?.className;
  const { isFacilitator } = useAuth();

  // state of loading different parts of page
  const [classDetailsLoaded, setClassDetailsLoaded] = useState(false);
  const [classAnnouncementsLoaded, setClassAnnouncementsLoaded] = useState(false);
  const [classInvestigationsLoaded, setClassInvestigationsLoaded] = useState(false);

  const { data, loading } = useQuery<{ getClass: GQL_ClassResponse }, { data: { classId: string } }>(
    gqlSchema.ClassSchema.query.CLASS.CLASSES.getClass,
    {
      variables: { data: { classId: id } },
      onCompleted: () => {
        // notify parent of load complete
        setClassDetailsLoaded(true);
      },
      onError: (err) => {
        // notify parent of load complete
        setClassDetailsLoaded(true);

        message.error('There was an error loading class: ' + err.message || 'Unexpected Error');
      },
    },
  );

  return (
    <>
      <PageWithTitle title={`${isFacilitator ? 'Course or Event' : 'Class'} Settings`} backPageUrl="/teacher-dashboard">
        <ClassInfos classInfo={data?.getClass} loading={loading} />
        <ClassAnnouncementsRow
          shouldLoad={classDetailsLoaded}
          loaded={() => setClassAnnouncementsLoaded(true)}
          classId={id}
          className={data?.getClass?.name || className}
          teacherName={data?.getClass?.teacherName}
        />
        <ClassInvestigationsRow
          shouldLoad={classAnnouncementsLoaded}
          loaded={() => setClassInvestigationsLoaded(true)}
          classId={id}
          className={data?.getClass?.name || className}
        />
        <ClassInvestigationsRow
          shouldLoad={classAnnouncementsLoaded}
          loaded={() => setClassInvestigationsLoaded(true)}
          isAssessment={true}
          classId={id}
          className={data?.getClass?.name || className}
        />
        <Spacer />
        <StudentsTable
          shouldLoad={classInvestigationsLoaded}
          classInfo={data?.getClass}
          className={data?.getClass?.name || className}
          loading={loading}
        />
      </PageWithTitle>
    </>
  );
};

export default withRouter(ClassDashboard);
