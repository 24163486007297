import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import { Col, message, Row, Tooltip } from 'antd';
import moment from 'moment';
import { formatDateTime } from '../../../utils/date';
import ActiveUserItem from './ActiveUserItem';
import { useAuth } from '../../../hooks/useAuth';
import { GQL_OrganizationResponse } from '../../../types/organization';
import { gqlSchema } from '../../../gql/schema';
import { useLazyQuery } from '@apollo/client';
import Select from '../../Select';
import SelectOption from '../../Select/Option';
import { FiHelpCircle } from 'react-icons/fi';

const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
};

const ActiveUsers = () => {
  const [durationActiveUsers, setDurationActiveUsers] = useState<Date | null>(new Date(defaultDateRange.startDate));
  const { isOrganizationAdiAdmin } = useAuth();
  const [currentOrganization, setCurrentOrganization] = useState<string>('');

  const disabledDate = useCallback((current: moment.Moment) => {
    return current > moment().endOf('day');
  }, []);

  const [fetchOrganizationData, { data: organizationsData, loading: loadingOrganizations }] = useLazyQuery<{
    getAllOrganizations: GQL_OrganizationResponse[];
  }>(gqlSchema.OrganizationSchema.queries.LIST.getAllOrganizations, {
    onError: (err) => {
      message.error('There was an error loading the organizations: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    if (!isOrganizationAdiAdmin) {
      fetchOrganizationData();
    }
  }, [fetchOrganizationData, isOrganizationAdiAdmin]);

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Active Users</S.Title>
          <Tooltip title="Users who have logged into the platform at least once from the selected date up to now.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Row>
            <Col xxl={5} xl={7} lg={9} md={24}>
              From:{' '}
              <S.DatePicker
                data-cy="shared-charts-activeusers-datepicker-activeusers"
                onChange={(moment: moment.Moment | null, dateString: string) =>
                  setDurationActiveUsers(moment?.toDate() || null)
                }
                inputReadOnly
                disabledDate={disabledDate}
                defaultValue={moment(durationActiveUsers)}
                format={'MM.DD.YYYY'}
              />
            </Col>
            {!isOrganizationAdiAdmin && (
              <Col
                xxl={{ span: 7, offset: 12 }}
                xl={{ span: 12, offset: 5 }}
                lg={{ span: 15, offset: 0 }}
                md={24}
                sm={24}
              >
                <Select
                  data-cy="shared-charts-activeusers-select-organization"
                  placeholder="Select Organization"
                  showSearch
                  loading={loadingOrganizations}
                  value={currentOrganization}
                  onChange={(v) => setCurrentOrganization(v as string)}
                >
                  <SelectOption value={''}>All Organizations</SelectOption>
                  {organizationsData?.getAllOrganizations?.map((organization) => (
                    <SelectOption
                      data-cy={`shared-charts-activeusers-select-organization-option-${organization.id}`}
                      value={organization.id}
                      key={organization.id}
                    >
                      {organization.name}
                    </SelectOption>
                  ))}
                </Select>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={24} lg={6}>
          <ActiveUserItem
            type="teacher"
            title="Teachers"
            startDate={durationActiveUsers}
            organizationId={currentOrganization}
          />
        </Col>
        <Col xs={24} lg={6}>
          <ActiveUserItem
            type="student"
            title="Students"
            startDate={durationActiveUsers}
            organizationId={currentOrganization}
          />
        </Col>
        <Col xs={24} lg={6}>
          <ActiveUserItem
            type="organization_admin"
            title="Org. Admins"
            startDate={durationActiveUsers}
            organizationId={currentOrganization}
          />
        </Col>
        <Col xs={24} lg={6}>
          <ActiveUserItem
            type="teacher_assistant"
            title="TAs"
            startDate={durationActiveUsers}
            organizationId={currentOrganization}
          />
        </Col>
        {!isOrganizationAdiAdmin && (
          <Col xs={24} lg={6}>
            <ActiveUserItem
              type="facilitator"
              title="Facilitators"
              startDate={durationActiveUsers}
              organizationId={currentOrganization}
            />
          </Col>
        )}
        <Col xs={24} lg={6}>
          <ActiveUserItem
            type="clever"
            title="Clever Users"
            startDate={durationActiveUsers}
            organizationId={currentOrganization}
          />
        </Col>
        <Col xs={24} lg={6}>
          <ActiveUserItem
            type="classlink"
            title="ClassLink Users"
            startDate={durationActiveUsers}
            organizationId={currentOrganization}
          />
        </Col>
      </Row>
    </>
  );
};

export default ActiveUsers;
