import { Modal } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import Spacer from '../../../shared/Spacer';
import { InvestigationType, WorkShopTemplate } from '../../../types/investigation';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';

interface INewInvestigationModal {
  open: boolean;
  onConfirm: (
    name: string,
    type: InvestigationType,
    workShopTemplate?: WorkShopTemplate,
    isAssessment?: boolean,
  ) => void;
  onClose: () => void;
  loading?: boolean;
  title: string;
  subject?: string;
  isAssessment?: boolean;
  isCloning: boolean;
}

const NewInvestigationModal = (props: INewInvestigationModal) => {
  const { open, onClose, loading, onConfirm, title, subject, isCloning, isAssessment = false } = props;
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<InvestigationType>('STANDARD');
  const [value, setValue] = useState<string>('STANDARD');
  const [workShopTemplate, setWorkShopTemplate] = useState<WorkShopTemplate>();

  const standardOption = useMemo(() => {
    const standardOptionTexts: Record<string, string> = {
      Math: 'Math Investigation',
      Science: 'Science Investigation',
      MathAndScience: 'Math/Science Investigation',
      Engineering: 'Engineering Investigation',
    };

    const standardText = subject && subject in standardOptionTexts ? standardOptionTexts[subject] : 'Standard';

    return (
      <>
        <SelectOption value="STANDARD">{standardText}</SelectOption>
      </>
    );
  }, [subject]);

  const workshopOptions = useMemo(() => {
    switch (subject) {
      case 'Math':
        return (
          <>
            <SelectOption value="WORKSHOP_MCI">Workshop: Condensed Introduction to ADI in Math</SelectOption>
            <SelectOption value="WORKSHOP_MID1">Workshop: Introduction to ADI in Math Day 1</SelectOption>
            <SelectOption value="WORKSHOP_MID2">Workshop: Introduction to ADI in Math Day 2</SelectOption>
            {!isCloning && <SelectOption value="WORKSHOP_NSW">Workshop: Next Step Workshop</SelectOption>}
            <SelectOption value="WORKSHOP_NSW_RT">Workshop: Next Steps with Rehearsal Teach</SelectOption>
          </>
        );
      case 'Science':
        return (
          <>
            <SelectOption value="WORKSHOP_SCI">Workshop: Condensed Introduction to ADI in Science</SelectOption>
            <SelectOption value="WORKSHOP_SD1">Workshop: Introduction to ADI in Science Day 1</SelectOption>
            <SelectOption value="WORKSHOP_SD2">Workshop: Introduction to ADI in Science Day 2</SelectOption>
            {!isCloning && <SelectOption value="WORKSHOP_NSW">Workshop: Next Step Workshop</SelectOption>}
            <SelectOption value="WORKSHOP_NSW_RT">Workshop: Next Steps with Rehearsal Teach</SelectOption>
          </>
        );
      case 'MathAndScience':
        return (
          <>
            <SelectOption value="WORKSHOP_MCI">Workshop: Condensed Introduction to ADI in Math</SelectOption>
            <SelectOption value="WORKSHOP_MID1">Workshop: Introduction to ADI in Math Day 1</SelectOption>
            <SelectOption value="WORKSHOP_MID2">Workshop: Introduction to ADI in Math Day 2</SelectOption>
            <SelectOption value="WORKSHOP_SCI">Workshop: Condensed Introduction to ADI in Science</SelectOption>
            <SelectOption value="WORKSHOP_SD1">Workshop: Introduction to ADI in Science Day 1</SelectOption>
            <SelectOption value="WORKSHOP_SD2">Workshop: Introduction to ADI in Science Day 2</SelectOption>
            {!isCloning && <SelectOption value="WORKSHOP_NSW">Workshop: Next Step Workshop</SelectOption>}
            <SelectOption value="WORKSHOP_NSW_RT">Workshop: Next Steps with Rehearsal Teach</SelectOption>
          </>
        );
      case 'Engineering':
        return (
          <>
            {!isCloning && <SelectOption value="WORKSHOP_NSW">Workshop: Next Step Workshop</SelectOption>}
            {isCloning && (
              <SelectOption value="WORKSHOP_NSW_RT">Workshop: Next Steps with Rehearsal Teach</SelectOption>
            )}
            {isCloning && <SelectOption value="WORKSHOP_EI">Workshop: Introduction to ADI in Engineering</SelectOption>}
            {isCloning && (
              <SelectOption value="WORKSHOP_ECI">Workshop: Condensed Introduction to ADI in Engineering</SelectOption>
            )}
          </>
        );
      default:
        return null;
    }
  }, [subject, isCloning]);

  const onSelectOption = useCallback(
    (option: InvestigationType | WorkShopTemplate) => {
      if (option === 'STANDARD') {
        setType(option);
      } else {
        setType('WORKSHOP');
        setWorkShopTemplate(option as WorkShopTemplate);
      }
      setValue(option);
    },
    [setType, setWorkShopTemplate, setValue],
  );

  return (
    <Modal visible={open} onCancel={onClose} footer={null}>
      <h2>{title} Name</h2>
      <Input
        data-cy="component-create-new-input-field"
        placeholder={`Type ${title.toLocaleLowerCase()} name`}
        onChange={(e) => setName(e.target.value)}
        value={name}
        backgroundColor="white"
      />
      <Spacer />
      {!isAssessment ? (
        <>
          <Select value={value} onChange={(v) => onSelectOption(v as InvestigationType | WorkShopTemplate)}>
            {standardOption}
            {workshopOptions}
          </Select>
          <Spacer />
        </>
      ) : null}

      <S.ButtonContainer>
        <Button text="Cancel" loading={loading} theme={themeConfig.secondaryOutlined} onClick={onClose} />
        <Spacer axis="horizontal" />
        <Button
          data-cy={`components-${title}-create-page-modal-button`}
          text={`Create ${title}`}
          theme={themeConfig.secondaryColor}
          disabled={!name}
          loading={loading}
          onClick={() => onConfirm(name, type, workShopTemplate, isAssessment)}
        />
      </S.ButtonContainer>
    </Modal>
  );
};

export default NewInvestigationModal;
