/* eslint-disable complexity */
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { message, Rate, Row, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import ExpandableCard from '../../../shared/ExpandableCard';
import Spacer from '../../../shared/Spacer';
import { GQL_EvaluationAnswers, IPeerReviewContentValue } from '../../../types/investigation';
import { GQL_InvestigationSummaryResponse } from '../../../types/teacher';
import { formatDateTime } from '../../../utils/date';
import { ReactComponent as StuckIcon } from '../../../assets/stuck-icon.svg';
import { ReactComponent as StuckIconDisabled } from '../../../assets/stuck-icon-disabled.svg';
import { useStuckStudentButton } from '../../../utils/useStuckStudentButton';
import { GQL_NotificationResponse } from '../../../types/notification';
import Button from '../../../shared/Button';

import * as S from './styles';
import { themeConfig } from '../../../utils/theme';

interface IInvestigationReviews {
  values: IPeerReviewContentValue[];
  dueDate?: number;
  activityId: string;
  type?:
    | 'peer-review'
    | 'peer-review-report'
    | 'concept-evaluation'
    | 'concept-rating'
    | 'best-concept-of-group'
    | 'peer-review-best-concept';
  investigationId?: string;
  userId?: string;
  classId?: string;
  classStepId?: string;
  previousSubmissionActivityId?: string;
  submissionVersion: number;
}

const InvestigationReviews: React.FC<IInvestigationReviews> = (props) => {
  const {
    values,
    activityId,
    dueDate,
    type = 'peer-review',
    investigationId,
    userId,
    classStepId,
    classId,
    previousSubmissionActivityId,
    submissionVersion,
  } = props;
  const history = useHistory();
  const { search } = useLocation();
  const { isStudent, user } = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';
  const [isStuck, setIsStuck] = useStuckStudentButton({
    userId: user.id,
    classId: classId!,
    investigationId: investigationId!,
    stepId: classStepId!,
    activityId,
  });

  const [fetchNotifications, { loading: notificationsLoading }] = useLazyQuery<{
    getNotifications: GQL_NotificationResponse[];
  }>(gqlSchema.NotificationSchema.queries.NOTIFICATIONS.getNotifications, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const notificationFiltered = data.getNotifications.filter(
        (n) =>
          n.type === 'stuck_student_for_student_peer_review_do_nothing' &&
          n.metadata.investigationId === investigationId &&
          n.metadata.classId === props.classId &&
          n.metadata.stepId === props.classStepId &&
          n.metadata.activityId === previousSubmissionActivityId &&
          !!n.metadata.isValid,
      );
      notificationFiltered.length > 0 && setIsStuck(false);
    },
  });

  useSubscription<{ listenForNotifications: GQL_NotificationResponse }>(
    gqlSchema.NotificationSchema.subscriptions.listenForNotifications,
    {
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        if (data?.listenForNotifications?.metadata) {
          try {
            const parsedMetadata = JSON.parse((data.listenForNotifications.metadata as unknown) as string);
            const constIsDoNothingNotification =
              data?.listenForNotifications.type === 'stuck_student_for_student_peer_review_do_nothing' &&
              parsedMetadata.investigationId === investigationId &&
              parsedMetadata.classId === props.classId &&
              parsedMetadata.stepId === props.classStepId &&
              parsedMetadata.activityId === previousSubmissionActivityId &&
              !!parsedMetadata.isValid;

            constIsDoNothingNotification && setIsStuck(false);
          } catch (err) {
            console.error('Error parsing metadata', err);
          }
        }
      },
    },
  );

  const { data: investigationData, refetch } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId || '',
    },
    fetchPolicy: 'cache-only',
  });

  const [fetchEvaluations, { data: evaluationsData }] = useLazyQuery<
    { getConceptEvaluationsToYou: GQL_EvaluationAnswers[] },
    { classStepId: string; activityId: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getConceptEvaluationsToYou);

  const [notifyTeacherAboutStuckUser, { loading: loadingNotify }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.REASSIGNMENT.notifyTeacherAboutStuckUser,
    {
      onError: (err) => message.error(err.message),
      onCompleted: () => {
        message.success('The notification was sent to the teacher.');
        setIsStuck(true);
      },
    },
  );

  useEffect(() => {
    if (type === 'concept-evaluation' && classStepId) {
      fetchEvaluations({
        variables: {
          activityId: activityId,
          classStepId: classStepId,
        },
      });
    }
  }, [type, classStepId, activityId, fetchEvaluations]);

  const handlePeerReviewClick = (peerReview: IPeerReviewContentValue) => {
    if (peerReview.readyToReview) {
      history.push(
        `/student-investigation/${investigationId}/${type}/${peerReview.stepId}/${activityId}/${peerReview.studentId}/${submissionVersion}${search}`,
        {
          userId,
          conceptActivityId: peerReview.activityId,
        },
      );
    }
  };

  const handleReviewEvaluationClick = (review: {
    evaluatorId: string;
    completedAt?: number;
    conceptActivityId?: string;
  }) => {
    if (review.completedAt) {
      history.push(
        `/student-investigation/${investigationId}/${type}/${classStepId}/${activityId}/${user.id}/${review.evaluatorId}${search}`,
        {
          userId,
          conceptActivityId: review.conceptActivityId,
        },
      );
    }
  };

  const studentList = useMemo(() => investigationData?.getInvestigationProgressSummary?.perStudents || [], [
    investigationData,
  ]);
  const student = studentList.find((student) => student.userId === userId);

  const findStudentName = useCallback(
    (id?: string) => {
      const student = studentList.find((student) => student.userId === id);

      if (!student) return 'Student 1';

      return student?.firstName + ' ' + student?.lastName;
    },
    [studentList],
  );

  const name = student ? `${student.firstName} ${student.lastName}` : undefined;
  const title = type.includes('concept') ? 'Concept' : 'Peer Review';
  const deliverable = type === 'concept-evaluation' ? 'concept' : 'file';
  const evaluations = evaluationsData?.getConceptEvaluationsToYou || [];

  const showHelpStuck = (isStudent || isGoogleStudent || isCanvasStudent) && values.every((value) => !value.readyToReview);
  const handleStuckConfirm = (e: React.MouseEvent<HTMLElement> | undefined) => {
    notifyTeacherAboutStuckUser({
      variables: {
        classId: props.classId,
        activityId: previousSubmissionActivityId,
        studentId: user.id,
        activityType: 'PEER_REVIEW',
      },
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <S.Column span={24}>
      <S.TitleRow align="middle" justify="space-between">
        <Row align="middle">
          <h1>{`${name || 'Your' + (type === 'best-concept-of-group' ? ' Group Best' : '')} ${title}s`}</h1>
          <p>{!student && `Due by ${formatDateTime(dueDate)}`}</p>
        </Row>
        {!showHelpStuck ? (
          <p>
            {values.filter((value) => !!value.completedAt).length} / {values.length} Completed
          </p>
        ) : (
          <S.HelpPopConfirm
            title={
              <S.HelpPopConfirmParagraph>
                Your teacher will receive a notification. <br /> Do you <strong>confirm</strong> that you want to report
                that you are stuck?
              </S.HelpPopConfirmParagraph>
            }
            overlayClassName="help-stuck-popconfirm-overlay"
            trigger={isStuck ? '' : 'click'}
            disabled={isStuck}
            onConfirm={handleStuckConfirm}
            okText="Confirm"
            cancelText="Cancel"
            placement="left"
            icon={<></>}
          >
            <Tooltip
              title={
                isStuck ? 'The notification was already sent to the teacher. Wait a little bit.' : "Help, I'm stuck."
              }
              placement="left"
            >
              <S.HelpButton
                loading={loadingNotify}
                $disabled={isStuck || notificationsLoading}
                shape="circle"
                icon={isStuck ? <StuckIconDisabled /> : <StuckIcon />}
              />
            </Tooltip>
          </S.HelpPopConfirm>
        )}
      </S.TitleRow>
      {values?.length ? (
        values.map((value, index) => (
          <ExpandableCard
            data-cy={`components-investigation-reviews`}
            key={(value.stepId ?? '') + (value.studentId ?? '') + (value.activityId ?? '') + (value.completedAt ?? '')}
            title={
              typeof value.rating === 'number' ? (
                <S.TitleContainer>
                  {type === 'best-concept-of-group' ? 'Best Concept' : `${title} ${index + 1}`}
                  <Rate disabled value={value.rating} allowHalf />
                </S.TitleContainer>
              ) : (
                `${title} ${(isStudent || isGoogleStudent || isCanvasStudent) ? index + 1 : 'For ' + findStudentName(value.studentId)}`
              )
            }
            disabled={!value.readyToReview}
            description={
              !value.readyToReview && (isStudent || isGoogleStudent || isCanvasStudent)
                ? `The other student hasn't submitted their ${deliverable} yet`
                : value.completedAt
                ? `${name || 'You'} completed this ${title?.toLocaleLowerCase()} on ${formatDateTime(
                    value.completedAt,
                  )}`
                : `${name || 'You'} ${name ? 'has' : 'have'} not completed this ${title?.toLocaleLowerCase()}.`
            }
            onClick={() => handlePeerReviewClick(value)}
            completed={!!value.completedAt}
          />
        ))
      ) : (
        <></>
      )}
      {type === 'concept-evaluation' &&  (isStudent || isGoogleStudent || isCanvasStudent) && (
        <>
          <Spacer size={48} />
          <S.TitleRow align="middle" justify="space-between">
            <Row align="middle">
              <h1>{`Evaluations of your concept`}</h1>
            </Row>
            <p>
              {evaluations.length} / {values.length} Reviews
            </p>
          </S.TitleRow>
          {[...evaluations, ...new Array(Math.max((values?.length ?? 0) - evaluations.length, 0)).fill({})].map(
            (evaluation: GQL_EvaluationAnswers, index) => (
              <ExpandableCard
                key={evaluation.id}
                title={`${title} ${index + 1}`}
                disabled={!evaluation.completedAt}
                description={
                  evaluation.completedAt
                    ? `This ${title?.toLocaleLowerCase()} was completed on ${formatDateTime(evaluation.completedAt)}`
                    : `This ${title?.toLocaleLowerCase()} wasn't completed yet.`
                }
                onClick={() =>
                  handleReviewEvaluationClick({
                    evaluatorId: evaluation.evaluatorId,
                    completedAt: evaluation.completedAt,
                    conceptActivityId: evaluation.conceptActivityId,
                  })
                }
                completed={!!evaluation.completedAt}
              />
            ),
          )}
        </>
      )}
      <Row>
        <Button
          text={'Refresh'}
          theme={themeConfig.primaryOutlined}
          onClick={() => {
            refetch();
          }}
        />
      </Row>
    </S.Column>
  );
};

export default InvestigationReviews;
