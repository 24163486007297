import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import TeacherInvestigationInsights from './InvestigationInsight';
import TeacherAssessmentInsights from './AssessmentInsight';
import { InsightsType } from './../../constants/enums/InsightsTypes';

type Props = RouteComponentProps<{ type: string }>;

const TeacherInsights: React.FC<Props> = (props) => {
  const { type } = props.match.params;

  if (type === InsightsType.INVESTIGATIONS.toLocaleLowerCase()) {
    return <TeacherInvestigationInsights />;
  } else if (type === InsightsType.ASSESSMENTS.toLocaleLowerCase()) {
    return <TeacherAssessmentInsights />;
  }

  return null;
};

export default withRouter(TeacherInsights);
