/**
 * Generic function to create and download a .csv file
 * @param csvContent Csv conte, it should include headers also
 * @param fileName File name for the download
 */
export const downloadCsv = (csvContent: string, fileName: string) => {
  const encodedDataUrl = encodeURI(`data:text/csv;charset=utf8,${csvContent}`);

  const link = document.createElement('a');
  link.setAttribute('href', encodedDataUrl);
  link.setAttribute('download', fileName);
  link.click();
  link.remove();
};

/**
 * Transforms a data url into file type
 */
export const dataURLToFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime ? mime[1] : '.png' });
};

export const convertToCSV = (objArray: Record<string, any>, headers?: Record<string, any>) => {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  if (headers) {
    array.unshift(headers);
  }
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ',';

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
};
