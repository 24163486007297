import React from 'react';
import { Page, Document, View, Text, StyleSheet, Image, Link, pdf } from '@react-pdf/renderer';
import adiLearningHubSrc from '../../../assets/adi-learning-hub.png';
import { QuoteDataPdf } from '../../../types/shopify';

const styles = StyleSheet.create({
  page: { padding: 40, fontFamily: 'Helvetica' },
  header: { marginBottom: 20, alignItems: 'flex-end' },
  headerSec: { marginTop: 60, alignItems: 'flex-end' },
  companyName: { fontSize: 12, fontWeight: 'bold', color: '#000' },
  address: { fontSize: 10, color: '#333' },
  contact: { fontSize: 10, color: '#333', marginBottom: 10 },
  logoContainer: { alignItems: 'center', marginBottom: 20 },
  logo: { width: 'auto', height: 80 },
  commonFont: {
    fontSize: 12,
  },
  cellGap: {
    marginRight: 100,
    justifyContent: 'flex-start',
  },
  title: { fontSize: 24, fontWeight: 'heavy', color: 'black', marginBottom: 20, textAlign: 'center' },

  blueSection: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  teacherInfo: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginRight: 20 },
  teacherInfoTitle: { fontSize: 12, fontWeight: 'bold', color: '#000', marginBottom: 5 },
  teacherText: { fontSize: 10, color: '#333', marginBottom: 5 },
  teacherInfoTable: { display: 'flex', flexDirection: 'column', marginTop: 100 },
  tableRowref: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 },
  tableCellBold: { fontSize: 10, fontWeight: 'bold', color: '#333' },
  tableCell: { fontSize: 10, color: '#333', flex: 2 },

  sectionTitle: { fontSize: 14, fontWeight: 'bold', color: '#333', marginVertical: 10 },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#333',
    paddingBottom: 5,
    fontWeight: 'bold',
    fontSize: 10,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  lastTableRow: {
    flexDirection: 'row',
    paddingVertical: 5,
  },
  cell: { fontSize: 10, flex: 2 },
  itemDescription: { flex: 2 },
  sku: { textAlign: 'right', flex: 1 },
  quantity: { textAlign: 'right', flex: 1 },
  unitPrice: { textAlign: 'right', flex: 2 },
  total: { textAlign: 'right', flex: 2 },

  tableEndLine: { borderBottomWidth: 1, borderBottomColor: '#333', marginVertical: 10 },

  totalsSection: { marginTop: 20, alignItems: 'flex-end', textAlign: 'left', width: '100%' },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
    fontSize: 10,
    paddingVertical: 3,
  },
  grandTotal: { fontSize: 24, fontWeight: 900, marginTop: 5 },

  footer: {
    marginTop: 20,
    fontSize: 10,
    color: '#555',
  },
  footerText: {
    marginBottom: 5,
    textAlign: 'left',
  },
  footerTextBold: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
  },
  footerTextThin: {
    fontSize: 11,
    fontWeight: 'normal',
    color: 'black',
  },
  footerTextBoldUnder: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#3697c0',
    textDecoration: 'underline',
  },
  footerLink: {
    fontSize: 10,
    color: '#0d3b66',
    textDecoration: 'underline',
  },
});

export const generatePdfAndUpload = async (data: QuoteDataPdf) => {
  const pdfDoc = (
    <Document>
      <Page style={styles.page}>
        {/* Header Section */}
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image source={adiLearningHubSrc} style={styles.logo} />
          </View>
          <Text style={styles.companyName}>Argument-Driven Inquiry</Text>
          <Text style={styles.address}>12707 Nutty Brown Rd., Suite G-100, Austin, TX 78737</Text>
          <Text style={styles.contact}>Phone: (512) 777-4099 | www.argumentdriveninquiry.com</Text>
        </View>

        {/* Title */}
        <Text style={styles.title}>QUOTE</Text>

        {/* Blue Section */}
        <View style={styles.blueSection}>
          <View style={styles.teacherInfo}>
            <Text style={styles.teacherInfoTitle}>Quote created for</Text>
            <Text style={styles.teacherText}>{data.teacherName}</Text>
            <Text style={styles.teacherText}>{data.shippingAddress}</Text>
            <Text style={styles.teacherText}>{data.email}</Text>
          </View>
          <View style={styles.teacherInfoTable}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellBold, styles.commonFont]}>Reference:</Text>
              <Text style={styles.commonFont}>{data.reference || 'No Ref.'}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellBold, styles.commonFont]}>Quote created:</Text>
              <Text style={styles.commonFont}>{data.createdDate}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellBold, styles.commonFont]}>Quote expires:</Text>
              <Text style={styles.commonFont}>{data.expiresDate}</Text>
            </View>
          </View>
        </View>

        {/* Products & Services Table */}
        <Text style={styles.sectionTitle}>Products & Services</Text>
        <View style={styles.tableHeader}>
          <Text style={[styles.cell, styles.commonFont]}>Item & Description</Text>
          <Text style={[styles.sku, styles.commonFont]}>SKU</Text>
          <Text style={[styles.quantity, styles.commonFont]}>Quantity</Text>
          <Text style={[styles.unitPrice, styles.commonFont]}>Unit Price</Text>
          <Text style={[styles.total, styles.commonFont]}>Total</Text>
        </View>
        {data.items.map((item, index) => (
          <View style={index === data.items.length - 1 ? styles.lastTableRow : styles.tableRow} key={index}>
            <Text style={[styles.cell, styles.itemDescription || '']}>{item.title}</Text>
            <Text style={[styles.cell, styles.sku]}>{item.sku || ''}</Text>
            <Text style={[styles.cell, styles.quantity]}>{item.quantity}</Text>
            <Text style={[styles.cell, styles.unitPrice]}>${item.unitPrice.toFixed(2)}</Text>
            <Text style={[styles.cell, styles.total]}>${(item.quantity * item.unitPrice).toFixed(2)}</Text>
          </View>
        ))}

        {/* Totals Section */}
        <View style={styles.tableEndLine} />
        <View style={styles.totalsSection}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCellBold, styles.commonFont, styles.cellGap]}>One-time Subtotal:</Text>
            <Text style={styles.commonFont}>${data.subtotal.toFixed(2)}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCellBold, styles.commonFont, styles.cellGap]}>Shipping:</Text>
            <Text style={styles.commonFont}>
              ${data.shipping.toFixed(2)} {'\n'}10.0% fee
            </Text>
          </View>
          <View style={[styles.tableRow, styles.grandTotal]}>
            <Text style={styles.cellGap}>Total:</Text>
            <Text>${data.total.toFixed(2)}</Text>
          </View>
        </View>
        {/* Footer */}
        <View style={styles.headerSec}>
          <View style={styles.logoContainer}>
            <Image source={adiLearningHubSrc} style={styles.logo} />
          </View>
          <Text style={styles.companyName}>Argument-Driven Inquiry</Text>
          <Text style={styles.address}>12707 Nutty Brown Rd., Suite G-100, Austin, TX 78737</Text>
          <Text style={styles.contact}>Phone: (512) 777-4099 | www.argumentdriveninquiry.com</Text>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>
            <Text style={styles.footerTextBold}>We gladly accept Purchase Orders from all schools.</Text>
            {'\n\n'}
            <Text style={styles.footerTextBold}> How To Order With A School Purchase Order:</Text>
            {'\n\n'}
            <Text style={styles.footerTextThin}>
              We need the following items to process your order and ensure that delivery and billing are done correctly.
              {'\n\n'}
              There is a $50 minimum order requirement for POs. Standard NET 30 days will generally apply to all
              purchase orders; however, we reserve the right to apply pre-paid terms to any purchase order we may
              receive. Net 30 terms will not apply to international purchase order requests, these must be prepaid prior
              to shipment.
              {'\n\n'}
              Please note that we do not accept purchase orders for downloadable items. You can only purchase them
              through our
              <Link
                style={styles.footerTextBoldUnder}
                src="https://www.argumentdriveninquiry.com/learning-hub/learning-hub-subscriptions"
              >
                {' '}
                website{' '}
              </Link>{' '}
              with a credit card.
            </Text>
            {'\n\n'}
            <Text style={styles.footerTextBold}> Shipping Cost Information:</Text> {'\n\n'}
            <Text style={styles.footerTextThin}>
              We require 10% of purchase order totals to be included for shipping costs.
            </Text>
            {'\n\n'}
            <Text style={styles.footerTextThin}>
              After receipt, purchase orders typically take 1-2 weeks (for in-stock items) to process and ship. If you
              wish to receive an email confirmation of the PO shipment, include your email address with your PO.
            </Text>
            {'\n\n'}
            <Link
              style={styles.footerTextBoldUnder}
              src="https://9159866.fs1.hubspotusercontent-na1.net/hubfs/9159866/2024%20w9_scan.pdf"
            >
              Click here for our W9
            </Link>
            {'\n\n'}
            <Text style={styles.footerTextThin}>
              Once you have your PO prepared you can either email, fax it to{' '}
              <Text style={styles.footerTextBold}>512-599-8784</Text> or mail it to:
            </Text>
            {'\n\n'}
            <Text style={styles.footerTextBold}>Argument-Driven Inquiry{'\n'}</Text>
            <Text style={styles.footerTextThin}>
              12707 Nutty Brown Rd. Suite G-100{'\n'}
              Austin, TX 78737
            </Text>
            {'\n\n'}
            <Link style={styles.footerTextBoldUnder} src="https://share.hsforms.com/1G7nKIY5TT1iX6InbAMZmXw5gbsq">
              Apply for a Tax Exempt Account
            </Link>
            {'\n\n\n'}
            <Text style={styles.footerTextBold}>Questions?</Text>{' '}
            <Text style={styles.footerTextThin}>
              We're happy to help. Give us a call Monday - Friday from 8:00 am - 4:30 pm Central at{'\n'}
              512-777-4099 or email us at orders@argumentdriveninquiry.com
            </Text>
            <Text style={styles.footerLink}> orders@argumentdriveninquiry.com </Text>
            {'\n\n'}
            <Link
              style={styles.footerTextBoldUnder}
              src="https://9159866.fs1.hubspotusercontent-na1.net/hubfs/9159866/2024%20w9_scan.pdf"
            >
              Click here for our W9
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );

  const pdfBlob = await pdf(pdfDoc).toBlob();
  return pdfBlob;
};
