import { Col } from 'antd';
import styled from 'styled-components';

export const ContentPagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ManageContent = styled.div<{ disabled?: boolean }>`
  background: ${(props) => (props.disabled ? '#e2e2e2' : '#ffffff')};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.199956);
  border-radius: 4px;
  height: 28px;
  width: 28px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 4px;

  svg {
    height: 100%;
    width: 100%;
    color: ${(props) => props.theme.secondaryColor.background};
  }
`;

export const ContentPage = styled.div<{ selected: boolean }>`
  border-radius: 4px;
  height: 28px;
  width: 28px;
  color: ${(props) => props.theme.secondaryColor.background};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    `
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.199956);
  `}
`;

export const ContentContainer = styled.div<{ selected?: boolean }>`
  width: 100%;
  min-height: 80px;
  border: 2px dashed ${(props) => (props.selected ? props.theme.secondaryColor.background : '#979797')};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;

  svg {
    min-height: 32px;
    min-width: 32px;
    color: #979797;
  }

  p {
    margin-bottom: 0;
  }
`;

export const Column = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;

  & > p,
  & > h1,
  & > h2 {
    text-align: center;
  }

  & > div {
    width: 100%;
  }
`;

export const ContentActionsContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  z-index: 1;

  & > svg {
    color: ${(props) => props.theme.secondaryColor.background};
    background: white;
    border-radius: 4px;
    margin-left: 16px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.199956);
    padding: 6px;
    cursor: pointer;
  }
`;

export const ContentOrderContainer = styled.div`
  position: absolute;
  right: -40px;
  top: 8px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  & > svg {
    color: ${(props) => props.theme.secondaryColor.background};
    background: white;
    border-radius: 4px;
    margin-bottom: 16px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.199956);
    padding: 6px;
    cursor: pointer;
  }
`;

export const BlockTitle = styled.h3`
  position: absolute;
  color: #a2a2a2a2;
  top: 8px;
  left: 8px;
  font-weight: 500;
  font-size: 13px;
`;
