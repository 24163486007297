import React, { useCallback } from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { FiCommand } from 'react-icons/fi';
import { BsBackspace } from 'react-icons/bs';
import { KeyboardContainer } from './styles';

const simpleShortcuts = [
  { key: 'A', caption: 'Arrow' },
  { key: 'D', caption: 'Double-Sided Arrow' },
  { key: 'O', caption: 'Ellipse' },
  { key: 'E', caption: 'Eraser' },
  { key: 'F', caption: 'Free Draw' },
  { key: 'L', caption: 'Line' },
  { key: 'R', caption: 'Rectangle' },
  { key: 'S', caption: 'Selection' },
  { key: 'T', caption: 'Text' },
  { key: 'Z', caption: 'Zoom-In Drawing' },
];

export const KeyboardShortcutsLegend = () => {
  const CtrlOrCmd = useCallback(() => {
    // Tryed other not deprecated propeties, but ESLint is not recognizing
    if (navigator?.appVersion?.indexOf('Mac') !== -1 || !!navigator.platform.match(/^Mac/)) {
      return <FiCommand />;
    }
    return <span role="none" >Ctrl</span>;
  }, []);

  return (
    <Card title="Keyboard Shortcuts" bordered={false} style={{ minWidth: '320px' }} headStyle={{ textAlign: 'center' }}>
      {simpleShortcuts.map(({ key, caption }) => (
        <Row justify="center" align="middle">
          <Col span={15}>
            <Typography.Text>{caption}</Typography.Text>
          </Col>
          <Col span={9}>
            <KeyboardContainer>
              <Typography.Text keyboard>{key}</Typography.Text>
            </KeyboardContainer>
          </Col>
        </Row>
      ))}

      <Row>
        <Col span={24}>
          <Divider />
        </Col>
      </Row>

      <Row>
        <Col span={15}>
          <Typography.Text>Remove selected shape</Typography.Text>
        </Col>
        <Col span={9}>
          <KeyboardContainer>
            <Typography.Text keyboard>Del</Typography.Text>&nbsp;or&nbsp;
            <Typography.Text keyboard>
              <BsBackspace />
            </Typography.Text>
          </KeyboardContainer>
        </Col>
      </Row>
      <Row>
        <Col span={15}>
          <Typography.Text>Undo changes</Typography.Text>
        </Col>
        <Col span={9}>
          <KeyboardContainer>
            <Typography.Text keyboard>
              <CtrlOrCmd />
            </Typography.Text>
            &nbsp;+&nbsp;<Typography.Text keyboard>Z</Typography.Text>
          </KeyboardContainer>
        </Col>
      </Row>
    </Card>
  );
};
