import { gql } from '@apollo/client';

const CORRELATION_MUTATIONS = {
  POST: {
    addCorrelationFile: gql`
      mutation addCorrelationFile($data: AdvancedSearchCorrelationInput!) {
        addCorrelationFile(data: $data) {
          id
          gradeBand
          subject
          fileUrl
          teacherManualUrl
          version
        }
      }
    `,
    addManualFile: gql`
      mutation addManualFile($data: AdvancedSearchCorrelationInput!) {
        addManualFile(data: $data) {
          id
          gradeBand
          subject
          fileUrl
          teacherManualUrl
          version
        }
      }
    `,
  },
};

export default CORRELATION_MUTATIONS;
