import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f0f2f5;

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

export const RowSP = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColSP = styled(Col)`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background: #ffffff;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 30px;
  }
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
`;

export const DetailsRow = styled(Row)`
  margin-top: 20px;
`;

export const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 6px;
    object-fit: cover;
  }
`;

export const DetailsTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 8px;
`;

export const DetailsText = styled.p`
  margin-bottom: 16px;
  color: #595959;
  word-wrap: break-word;
`;
