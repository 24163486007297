/* eslint-disable complexity */
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { GQL_NotificationResponse } from '../../types/notification';
import { AiOutlineMessage, AiOutlineUser } from 'react-icons/ai';
import { Button, Dropdown, Menu as AntdMenu, Row, message, Col } from 'antd';
import { formatDistance } from '../../utils/date';
import { CgLockUnlock, CgMoreVertical } from 'react-icons/cg';
import { gqlSchema } from '../../gql/schema';
import { useMutation } from '@apollo/client';
import { BsClock, BsClockHistory } from 'react-icons/bs';
import { FiUserCheck } from 'react-icons/fi';
import {
  BiLike,
  BiMessageRoundedError,
  BiMessageAltError,
  BiBeenHere,
  BiMessageSquareError,
  BiCommentCheck,
} from 'react-icons/bi';
import { themeConfig } from '../../utils/theme';
import { useAuth } from '../../hooks/useAuth';
import { FaExclamationCircle } from 'react-icons/fa';
import { IoIosRocket } from 'react-icons/io';
import useBetaFeedbackModal from '../../hooks/useBetaFeedbackModal';
import useBetaFeaturesModal from '../../hooks/useBetaFeaturesModal';
import client from '../../gql/api';

interface Props {
  notification: GQL_NotificationResponse;
  width?: string;
  fontSize?: string;
  showDetail?: boolean;
  lastItem?: boolean;
}

const Item: React.FC<Props> = (props) => {
  const history = useHistory();
  const { user } = useAuth();
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const { openModal: openBetaFeedbackModal } = useBetaFeedbackModal();
  const { openModal: openBetaFeatureModal } = useBetaFeaturesModal();
  const icon = useMemo(() => {
    switch (props.notification.type) {
      case 'added_to_class':
        return <FiUserCheck size={30} color={themeConfig.success.color} />;
      case 'class_announcement':
        return <BiMessageAltError size={28} color={themeConfig.primaryColor.background} />;
      case 'comment_liked':
        return <BiLike size={28} color={themeConfig.success.color} />;
      case 'peer_review':
      case 'new_peer_review':
      case 'concept_evaluated':
        return <AiOutlineMessage size={28} color={themeConfig.success.color} />;
      case 'comment_flagged':
        return <BiMessageRoundedError size={28} color={themeConfig.error.background} />;
      case 'investigation_step_deadline_missed_as_student':
      case 'investigation_step_deadline_missed_as_teacher':
        return <BsClock size={28} color={themeConfig.error.background} />;
      case 'clever_sync_complete':
        return <BiBeenHere size={28} color={themeConfig.success.color} />;
      case 'classlink_sync_complete':
        return <BiBeenHere size={28} color={themeConfig.success.color} />;
      case 'class_delete_notice':
        return <BiMessageSquareError size={28} color={themeConfig.success.color} />;
      case 'stuck_student_for_teacher':
      case 'stuck_student_for_student_peer_review_report':
      case 'stuck_student_for_student_peer_review_do_nothing':
      case 'stuck_student_for_student_reflection_extra_work':
      case 'stuck_student_for_student_reflection_do_nothing':
        return <FaExclamationCircle size={28} color={themeConfig.primaryColor.background} />;
      case 'beta_available':
        return <IoIosRocket size={28} color={themeConfig.primaryColor.background} />;
      case 'beta_feedback':
        return <BiCommentCheck size={28} color={themeConfig.primaryColor.background} />;
      case 'new_beta_feature':
        return <IoIosRocket size={28} color={themeConfig.primaryColor.background} />;
      case 'progress_check_unlocked':
        return <CgLockUnlock size={30} color={themeConfig.success.color} />;
      default:
        return <BsClockHistory size={28} color={themeConfig.error.background} />;
    }
  }, [props.notification]);

  const [markNotificationAsRead] = useMutation(
    gqlSchema.NotificationSchema.mutations.NOTIFICATION.markNotificationAsRead,
    {
      onCompleted: ({ markNotificationAsRead }: { markNotificationAsRead: boolean }) => {
        if (markNotificationAsRead && showNotificationMessage) message.success('Notification updated successfully.');
        else if (!markNotificationAsRead) message.error('There was an error updating the notification.');
      },
      update(cache, { data }) {
        if (data?.markNotificationAsRead) {
          const notificationResponse = {
            __typename: 'NotificationResponse',
            id: props.notification.id,
          };
          cache.modify({
            id: cache.identify(notificationResponse),
            fields: {
              seen() {
                return true;
              },
            },
            broadcast: true,
          });
        }
      },
      onError: (e) => {
        message.error('There was an error updating the notification.');
      },
    },
  );

  const [deleteNotification] = useMutation(gqlSchema.NotificationSchema.mutations.NOTIFICATION.deleteNotification, {
    onCompleted: ({ deleteNotification }: { deleteNotification: boolean }) => {
      if (deleteNotification) message.success('Notification deleted successfully.');
      else message.error('There was an error deleting the notification.');
    },
    update(cache, { data }) {
      if (data?.deleteNotification) {
        cache.modify({
          fields: {
            getNotifications(existingNotifications = [], { readField }) {
              return existingNotifications.filter((notif: any) => readField('id', notif) !== props.notification.id);
            },
          },
          broadcast: true,
        });
      }
    },
    onError: (e) => {
      message.error('There was an error updating the notification.');
    },
  });

  const menu = useCallback(
    (notification: GQL_NotificationResponse) => {
      return (
        <AntdMenu>
          {!notification.seen && (
            <>
              <AntdMenu.Item
                onClick={() => {
                  setShowNotificationMessage(true);
                  markNotificationAsRead({ variables: { notificationId: notification.id } });
                }}
              >
                Mark as Read
              </AntdMenu.Item>
              <AntdMenu.Divider />
            </>
          )}
          <AntdMenu.Item
            onClick={() => {
              deleteNotification({ variables: { notificationId: notification.id } });
            }}
          >
            Delete Notification
          </AntdMenu.Item>
        </AntdMenu>
      );
    },
    [markNotificationAsRead, deleteNotification],
  );

  const onClickNotification = useCallback(
    (url?: string, data?: object, notificationId?: string) => {
      setShowNotificationMessage(false);
      url && history.push(url, data);
      notificationId && markNotificationAsRead({ variables: { notificationId: notificationId } });
    },
    [history, markNotificationAsRead],
  );

  const onSyncComplete = useCallback(
    async (url?: string, notificationId?: string) => {
      await client.refetchQueries({
        include: [gqlSchema.ClassSchema.query.CLASS.CLASSES.getClasses],
      });
      setShowNotificationMessage(false);
      url && history.push(url);
      notificationId && markNotificationAsRead({ variables: { notificationId: notificationId } });
    },
    [history, markNotificationAsRead],
  );

  const assignmentLabel = (isAssessment?: boolean) => (isAssessment ? 'Assessment' : 'Investigation');

  const getMetadata = useCallback(
    (notification: GQL_NotificationResponse) => {
      let title: ReactElement = <></>;
      let action = () => {};
      switch (notification.type) {
        case 'added_to_class':
          title = (
            <S.NotificationTitle
              data-cy="shared-notification-item-added_to_class"
              $read={notification.seen}
              $fontSize={props.fontSize}
              onClick={() =>
                onClickNotification(
                  `/student-dashboard/class/${notification.metadata.classId}`,
                  undefined,
                  notification.id,
                )
              }
            >
              You have been added to
              <Button type="link">{notification.metadata.className}</Button>
            </S.NotificationTitle>
          );

          break;
        case 'class_announcement':
          title = (
            <S.NotificationTitle
              $read={notification.seen}
              $fontSize={props.fontSize}
              onClick={() =>
                onClickNotification(
                  `/student-dashboard/class/${notification.metadata.classId}`,
                  undefined,
                  notification.id,
                )
              }
            >
              New Announcement on
              <Button type="link">{notification.metadata.className}</Button>
            </S.NotificationTitle>
          );

          break;
        case 'comment_liked':
          title = (
            <S.NotificationTitle
              $fontSize={props.fontSize}
              $read={notification.seen}
              onClick={() =>
                onClickNotification(
                  `/student-investigation/${notification.metadata.classInvestigationId}`,
                  undefined,
                  notification.id,
                )
              }
            >
              A<Button type="link">comment</Button> has been liked
            </S.NotificationTitle>
          );

          break;
        case 'comment_flagged':
          action = () =>
            onClickNotification(
              `/teacher-dashboard/investigation-details/${notification.metadata.classInvestigationId}`,
              notification.metadata,
              notification.id,
            );
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                A comment has been reported
              </S.NotificationTitle>
            </>
          );
          break;
        case 'investigation_deadline_approaching':
          action = () =>
            onClickNotification(
              `/student-${notification.metadata.isAssessment ? 'assessment' : 'investigation'}/${
                notification.metadata.classInvestigationId
              }`,
              undefined,
              notification.id,
            );
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                You have {formatDistance(notification.metadata.dueDate)} left to finish
                <Button type="link">{`${notification.metadata.investigationTitle}`}</Button>
              </S.NotificationTitle>
            </>
          );
          break;
        case 'investigation_step_deadline_approaching':
          action = () =>
            onClickNotification(
              `/student-${notification.metadata.isAssessment ? 'assessment' : 'investigation'}/${
                notification.metadata.classInvestigationId
              }`,
              undefined,
              notification.id,
            );
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                {notification.metadata.isAssessment ? (
                  <>
                    You have {formatDistance(notification.metadata.dueDate)} left to finish your
                    <Button type="link">{`${notification.metadata.investigationTitle}`}</Button>{' '}
                    {assignmentLabel(notification.metadata.isAssessment)}
                  </>
                ) : (
                  <>
                    You have {formatDistance(notification.metadata.dueDate)} left to finish the
                    <Button type="link">{`${notification.metadata.investigationStep}`}</Button>stage of your
                    <Button type="link">{`${notification.metadata.investigationTitle}`}</Button>{' '}
                    {assignmentLabel(notification.metadata.isAssessment)}
                  </>
                )}
              </S.NotificationTitle>
            </>
          );
          break;
        case 'investigation_step_deadline_missed_as_student':
          action = () =>
            onClickNotification(
              `/student-${notification.metadata.isAssessment ? 'assessment' : 'investigation'}/${
                notification.metadata.classInvestigationId
              }`,
              undefined,
              notification.id,
            );
          title = notification.metadata.isAssessment ? (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                You have missed your <Button type="link">{notification.metadata.investigationTitle}</Button>{' '}
                {assignmentLabel(notification.metadata.isAssessment)}
              </S.NotificationTitle>
            </>
          ) : (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                You have missed
                <Button type="link">{`${notification.metadata.investigationStep}`}</Button>stage of your{' '}
                {assignmentLabel(notification.metadata.isAssessment)}
              </S.NotificationTitle>
            </>
          );
          break;
        case 'stuck_student_for_teacher':
          action = () => {
            const queryParam = notification.metadata.activityType.toLocaleLowerCase().replace('_', '-');
            onClickNotification(
              `/teacher-dashboard?${queryParam}`,
              { ...notification.metadata, notificationId: notification.id },
              notification.id,
            );
          };
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                {notification.metadata.studentName} is stuck on their{' '}
                <Button type="link">{assignmentLabel(notification.metadata.isAssessment)}</Button>
              </S.NotificationTitle>
            </>
          );

          break;
        case 'stuck_student_for_student_peer_review_report':
          action = () => {
            onClickNotification(
              `/student-investigation/${notification.metadata.classInvestigationId}`,
              {
                stepId: notification.metadata.stepId,
                goToComponent: 'PeerReview',
              },
              notification.id,
            );
          };
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                Your teacher has assigned you a <Button type="link">report</Button> to peer review
              </S.NotificationTitle>
            </>
          );
          break;
        case 'stuck_student_for_student_peer_review_do_nothing':
          action = () => onClickNotification(undefined, undefined, notification.id);
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                Please wait a few more minutes for report to be submitted and refresh the page
              </S.NotificationTitle>
            </>
          );

          break;
        case 'stuck_student_for_student_reflection_extra_work':
          action = () =>
            onClickNotification(
              `/student-investigation/${notification.metadata.classInvestigationId}`,
              {
                stepId: notification.metadata.stepId,
                goToComponent: 'Reflection',
              },
              notification.id,
            );
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                Your teacher has assigned an extra <Button type="link">report</Button> for you to peer-review
              </S.NotificationTitle>
            </>
          );
          break;
        case 'stuck_student_for_student_reflection_do_nothing':
          action = () => onClickNotification(undefined, undefined, notification.id);
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                Please wait a few more minutes for peer reviews to be completed and refresh the page
              </S.NotificationTitle>
            </>
          );
          break;
        case 'new_peer_review':
          action = () =>
            onClickNotification(
              `/student-investigation/${notification.metadata.classInvestigationId}`,
              {
                stepId: notification.metadata.stepId,
                goToComponent: 'PeerReview',
              },
              notification.id,
            );
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                You have a new peer-review to make on {notification.metadata.investigationTitle} investigation
              </S.NotificationTitle>
            </>
          );
          break;
        case 'peer_review':
          action = () =>
            onClickNotification(
              `/student-investigation/${notification.metadata.classInvestigationId}`,
              {
                stepId: notification.metadata.stepId,
                goToComponent: 'Reflection',
              },
              notification.id,
            );
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                Your submission in the {notification.metadata.investigationTitle} investigation has been reviewed
              </S.NotificationTitle>
            </>
          );

          break;
        case 'investigation_step_deadline_missed_as_teacher':
          action = () =>
            onClickNotification(
              `/teacher-dashboard/${notification.metadata.isAssessment ? 'assessment' : 'investigation'}-summary/${
                notification.metadata.classInvestigationId
              }`,
              undefined,
              notification.id,
            );
          title = (
            <>
              {notification.metadata.isAssessment ? (
                <>
                  <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                    {notification.metadata.numberOfStudentsThatMissedTheDeadline} students have missed
                    <Button type="link">{`${notification.metadata.investigationTitle}`}</Button>
                    {assignmentLabel(notification.metadata.isAssessment)}
                  </S.NotificationTitle>
                </>
              ) : (
                <>
                  <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen}>
                    {notification.metadata.numberOfStudentsThatMissedTheDeadline} students have missed
                    <Button type="link">{`${notification.metadata.investigationStep}`}</Button>stage of
                    <Button type="link">{`${notification.metadata.investigationTitle}`}</Button>
                    {assignmentLabel(notification.metadata.isAssessment)}
                  </S.NotificationTitle>
                </>
              )}
            </>
          );

          break;
        case 'concept_evaluated':
          action = () =>
            onClickNotification(
              `/student-investigation/${notification.metadata.classInvestigationId}/concept-evaluation/${notification.metadata.stepId}/${notification.metadata.activityId}/${user.id}/${notification.metadata.evaluatorId}`,
              {
                conceptActivityId: notification.metadata.conceptActivityId,
              },
              notification.id,
            );
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen} onClick={action}>
                Your concept has been evaluated
              </S.NotificationTitle>
            </>
          );

          break;
        case 'clever_sync_complete':
          action = () => onSyncComplete(`/adi-users`, notification.id);
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen} onClick={action}>
                Clever sync successful.
              </S.NotificationTitle>
            </>
          );
          break;
        case 'classlink_sync_complete':
          action = () => onSyncComplete(`/adi-users`, notification.id);
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen} onClick={action}>
                Classlink sync successful.
              </S.NotificationTitle>
            </>
          );
          break;
        case 'class_delete_notice':
          action = () => onClickNotification(`/teacher-dashboard`, undefined, notification.id);
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen} onClick={action}>
                You have classes that will be deleted in 15 days, download if you do not want to lose data.
              </S.NotificationTitle>
            </>
          );
          break;
        case 'beta_available':
          action = () => onClickNotification(`/profile`, undefined, notification.id);
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen} onClick={action}>
                You can now enroll as a beta tester and have access to our new features.
              </S.NotificationTitle>
            </>
          );
          break;
        case 'beta_feedback':
          action = () => openBetaFeedbackModal();
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen} onClick={action}>
                We want to hear you! How is the beta feature going?
              </S.NotificationTitle>
            </>
          );
          break;
        case 'new_beta_feature':
          action = () => openBetaFeatureModal();
          title = (
            <>
              <S.NotificationTitle $fontSize={props.fontSize} $read={notification.seen} onClick={action}>
                We have a new beta feature available. Click to see.
              </S.NotificationTitle>
            </>
          );
          break;
        case 'progress_check_unlocked':
          title = (
            <S.NotificationTitle
              data-cy="shared-notification-item-progress_check_unlocked"
              $read={notification.seen}
              $fontSize={props.fontSize}
              onClick={() =>
                onClickNotification(
                  `/student-investigation/${notification.metadata.classInvestigationId}`,
                  undefined,
                  notification.id,
                )
              }
            >
              You can edit your progress check for
              <Button type="link">{notification.metadata.investigationTitle}</Button>
            </S.NotificationTitle>
          );

          break;
      }

      const rowTitle = (
        <Row justify="space-between">
          <S.NotificationTitleWrapper $showDetail={props.showDetail}>{title}</S.NotificationTitleWrapper>
          {props.showDetail && (
            <Dropdown overlay={menu(notification)} placement="bottomRight" arrow trigger={['click']}>
              <S.MenuIcon
                data-cy="shared-notificationitem-menuicon"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                shape="circle"
                icon={<CgMoreVertical />}
              />
            </Dropdown>
          )}
        </Row>
      );
      return {
        id: notification.id,
        avatar: <S.NotificationAvatar size={props.showDetail ? 46 : 'default'} icon={<AiOutlineUser />} />,
        title: rowTitle,
        date: `${formatDistance(+notification.created)} ago`,
        action,
      };
    },
    [menu, onClickNotification, onSyncComplete, props, user.id, openBetaFeatureModal, openBetaFeedbackModal],
  );

  const renderItem = useCallback(
    (notification: GQL_NotificationResponse) => {
      const item = getMetadata(notification);
      return (
        <S.Row $lastItem={props.lastItem} onClick={item.action} gutter={16}>
          <Col
            xxl={props.showDetail ? 1 : 2}
            xl={props.showDetail ? 1 : 3}
            lg={props.showDetail ? 1 : 3}
            md={props.showDetail ? 2 : 4}
          >
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{icon}</div>
          </Col>
          <Col xxl={22} xl={props.showDetail ? 22 : 21} lg={props.showDetail ? 23 : 21} md={props.showDetail ? 22 : 20}>
            <Row gutter={[0, 4]}>
              <Col span={24}>
                <S.NotificationTitle>{item.title}</S.NotificationTitle>
              </Col>
              <Col span={24}>
                <S.NotificationDate>{item.date}</S.NotificationDate>
              </Col>
            </Row>
          </Col>
        </S.Row>
      );
    },
    [getMetadata, icon, props.lastItem, props.showDetail],
  );

  return renderItem(props.notification);
};
export default Item;
