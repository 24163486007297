import React, { useState, useEffect } from 'react';
import { Modal, Progress, Button, Input, message, Spin } from 'antd';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { LinkOutlined } from '@ant-design/icons';
import { getProgressColor } from '../../../../utils/theme';

interface GradePopupProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  studentName: string;
  className: string;
  assignmentDescription: string;
  submissionData: any;
  assignmentName: string;
  assignmentLinks: string;
  max_score: number;
  courseId: string;
  courseWorkId: string;
  submission: string;
  refetchSubmissionData: () => void;
}

const GradePopup: React.FC<GradePopupProps> = ({
  visible,
  setVisible,
  studentName,
  className,
  submissionData,
  assignmentName,
  assignmentLinks,
  courseId,
  assignmentDescription,
  courseWorkId,
  max_score,
  submission,
  refetchSubmissionData,
}) => {
  const [score, setScore] = useState<string>('');
  // const [comment, setComment] = useState<string>('');
  const [percentage, setPercentage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log('Assignment Name:', assignmentName); 
    if (submissionData?.score && score === '') {
      setScore(submissionData.score.toString());
      calculatePercentage(submissionData.score.toString(), max_score);
    }
  }, [submissionData, max_score, assignmentName, score]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    calculatePercentage(score, max_score);
  }, [score, max_score]);

  const [gradeAssignment] = useMutation(
    gqlSchema.CanvasSchema.mutations.CANVASCLASS.GradeCanvasSubmission,
    {
      onCompleted: () => {
        message.success('Submission graded successfully');
        setLoading(false);
        setVisible(false);
        refetchSubmissionData();
      },
      onError: (err) => {
        message.error('Error submitting assignment: ' + (err.message || 'Unexpected Error'));
        setLoading(false);
      },
    }
  );

  const handleSubmission = () => {
    if (score.trim() === '') {
      message.error('Please provide a valid score');
      return;
    }

    const numerator = parseFloat(score);
    const denominator = max_score;

    if (isNaN(numerator) || isNaN(denominator)) {
      message.error('Invalid score or max points');
      return;
    }

    setLoading(true);

    gradeAssignment({
      variables: {
        course_id: courseId,
        assignment_id: courseWorkId,
        user_id: submissionData.user_id,
        data: {
          // comment: { text_comment: comment },
          submission: { posted_grade: score },
        },
      },
    });
  };

  const calculatePercentage = (score: string, maxPointsValue: number) => {
    const numerator = parseFloat(score);
    if (!isNaN(numerator) && !isNaN(maxPointsValue) && maxPointsValue !== 0) {
      let calculatedPercentage = (numerator / maxPointsValue) * 100;
      calculatedPercentage = Math.floor(calculatedPercentage);
      setPercentage(calculatedPercentage);
    } else {
      setPercentage(0);
    }
  };

  // let progressColor = '#f5222d';
  // if (percentage >= 80) {
  //   progressColor = '#52c41a';
  // } else if (percentage >= 50) {
  //   progressColor = '#faad14';
  // }

  const centeredLoaderStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1000,
  };

  if (loading) {
    return (
      <div style={centeredLoaderStyle}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>Submission Summary</div>}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={600}
      bodyStyle={{ padding: isMobile?'30px':'30px 50px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Modal content */}
        <div style={{ marginBottom: '20px', display: isMobile? 'block': 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '60px',marginBottom:isMobile?'10px':'unset', width: '180px' }}>
            <b>Student Name:</b>
            <br />
            {studentName}
          </div>
          <div style={{ width: '180px', marginRight: '60px' }}>
            <b>Submission:</b>
            <br />
            {submission}
          </div>
        </div>
        <div style={{ marginBottom: '20px', display: isMobile? 'block': 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '60px', width: '180px', marginTop:isMobile?'-10px':'unset',marginBottom:isMobile?'10px':'unset' }}>
            <b>Class Name:</b>
            <br />
            {className}
          </div>
          <div style={{  marginLeft: isMobile? 0 :'-60px' }}>
            <b>Assignment Name:</b>
            <br />
            {assignmentName}
          </div>
          <div style={{ width: '180px', opacity: 0 }}>Placeholder</div>
        </div>
        <div style={{ marginBottom: isMobile? '-40px' : '20px', marginTop:isMobile?'-30px':'unset'}}>
          <b>Description:</b>
          <div dangerouslySetInnerHTML={{ __html: assignmentDescription }} />
          <br />
        </div>
        <div style={{ marginTop: '20px' }}>
          <b>Student Submission Link:</b>
          <br />
          {assignmentLinks ? (
            <div>
              {assignmentLinks.split(',').map((link: string, index: number) => (
                <div key={index} style={{ marginBottom: '8px' }}>
                  <LinkOutlined style={{ marginRight: '8px' }} />
                  <a href={link.trim()} target="_blank" rel="noopener noreferrer">{link.trim()}</a>
                </div>
              ))}
            </div>
          ) : (
            'No Links Available'
          )}
        </div>
        <div style={{ marginBottom: '20px', display: isMobile? 'block': 'flex', alignItems: 'center' }}>
          <b>Score:</b>{' '}
          <Input
            value={score}
            onChange={(e) => setScore(e.target.value)}
            style={{ width: '70px', marginRight: '5px' }}
          />
          / {max_score}
        </div>
        {/* <div style={{ marginBottom: '20px', display: isMobile? 'block': 'flex', alignItems: 'center' }}>
          <b>Comment:</b>{' '}
          <Input.TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={3}
            placeholder="Enter your comment here"
          />
        </div> */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Progress
            strokeLinecap="round"
            strokeWidth={9}
            style={{ marginTop: 30 }}
            type="circle"
            percent={percentage}
            width={150}
            format={() => `${percentage}%`}
            strokeColor={getProgressColor(percentage || 0)}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Button style={{ marginRight: '20px' }} onClick={() => setVisible(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmission}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default GradePopup;
