// styles.ts
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  background-color: blue;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const Icon = styled.span`
  margin-right: 5px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  width: 30%; /* Adjust width as needed */
  height: 100%;
  padding: 10px 20px;
  margin: 0 10px 20px 0; /* Adjust margin as needed */
  border-radius: 5px;
  background-color: #f0f0f0;
  color: black;
`;
