/**
 * Role of this hook: fetch a list of available states from backend for state standards if the user is an ADI Admin or Super Admin
 */

import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../gql/schema';
import { GQL_State } from '../types/state';
import message from 'antd/lib/message';
import { useAuth } from './useAuth';

const useFetchStatesData = () => {
  const { isAdiAdmin, isAdiSuperAdmin } = useAuth();

  // query the states from backend for selecting state standards
  const [fetchStates, { data: statesData, loading: statesLoading }] = useLazyQuery<{
    getStates: GQL_State[];
  }>(gqlSchema.StatesSchema.queries.GET.getStates, {
    onError: (error) => {
      message.error(
        `An error occured while fetching the states - ${error.message ? error.message : 'Unexpected Error'}.`,
      );
    },
  });

  useEffect(() => {
    if (isAdiAdmin || isAdiSuperAdmin) {
      fetchStates();
    }
  }, [fetchStates, isAdiAdmin, isAdiSuperAdmin]);

  return {
    statesData: statesData?.getStates || [],
    statesLoading,
  };
};

export default useFetchStatesData;
