import React, { useCallback } from 'react';
import { Form as AntdForm,  Col, Form } from 'antd';
import * as S from './styles';
import Input from '../../../shared/Input';

import Button from '../../../shared/Button';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import adiLearningHubSrc from '../../../assets/adi-learning-hub.svg';

type Props = RouteComponentProps<null, any, any>;

const BookCode = (props: Props) => {
  
  const onSubmitForm = useCallback(
    (values ) => {
      props.history.push(`/bookDetails/${values.bookCode}`);
    },
    [],
  );

  return (
    <S.Container data-cy="components-loginpage-container">
      <S.RowSP justify="center" align="middle">
        <S.ColSP xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Welcome!</S.Title>
          <S.Title>Let's Get You SetUp.</S.Title>
          <S.RowForm gutter={16} align="middle">
            <Col span={10} xs={24} md={24} xl={10} lg={10}>
              <Form onFinish={onSubmitForm} layout="vertical">
                <AntdForm.Item
                  label="Book Code"
                  name="bookCode"
                 
                >
                  <Input placeholder="" type="text" />
                </AntdForm.Item>
                
                <AntdForm.Item>
                  <Button
                    data-cy="components-bookCode-submit-button"
                    text="Submit"
                    htmlType="submit"
                    
                    block
                    style={{
                      width: '50%',
                      margin: '0.5em auto 0',
                      textAlign: 'center',
                      display: 'block',
                    }}
                  />
                </AntdForm.Item>
                <AntdForm.Item>
                  <Link to="/booklib" style={{display:"flex", justifyContent:"center",textAlign: "center"}}>
                  Skip
                  </Link>
                </AntdForm.Item>
              </Form>
            </Col>
          </S.RowForm>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );
};
export default withRouter(BookCode);
