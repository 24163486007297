import { Collapse } from 'antd';
import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import * as S from './styles';

interface ISettingsCollapsable {
  title: string;
  defaultOpen?: boolean;
}

const SettingsCollapsable: React.FC<ISettingsCollapsable> = (props) => {
  const { title, defaultOpen, children } = props;

  return (
    <Collapse
      defaultActiveKey={defaultOpen ? ['1'] : []}
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
    >
      <S.StyledPanel header={<h1 data-cy="components-adieditinvestigationcore-settings-toggle">{title}</h1>} key="1">
        {children}
      </S.StyledPanel>
    </Collapse>
  );
};

export default SettingsCollapsable;
