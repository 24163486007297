import { Col, Row } from 'antd';
import React from 'react';
import { FiMinusCircle, FiPlus } from 'react-icons/fi';
import Input from '../../../../../shared/Input';
import Spacer from '../../../../../shared/Spacer';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  IConceptEvaluationContentValue,
} from '../../../../../types/investigation';

interface IConceptEvaluationBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
  isConceptRating?: boolean;
}

const ConceptEvaluationBlockEditor = (props: IConceptEvaluationBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
    isConceptRating,
  } = props;

  const conceptEvaluationValue = editingBlock.values[0] as IConceptEvaluationContentValue;

  const handleEditConceptEvaluation = (payload: { index?: number; question?: string; title?: string }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            ...content,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;

              if (typeof payload.index === 'undefined') {
                return {
                  ...block,
                  values: [
                    {
                      ...conceptEvaluationValue,
                      question: {
                        ...conceptEvaluationValue.question,
                        title: payload.title,
                      },
                    },
                  ],
                };
              } else {
                return {
                  ...block,
                  values: [
                    {
                      ...conceptEvaluationValue,
                      question: {
                        ...conceptEvaluationValue.question,
                        subquestions: conceptEvaluationValue.question?.subquestions?.map((sq, sqI) =>
                          sqI === payload.index ? { ...sq, text: payload.question } : sq,
                        ),
                      },
                    },
                  ],
                };
              }
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleAddRemoveQuestion = (payload: { removeIndex?: number }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              const addQuestion = typeof payload.removeIndex === 'undefined';
              const subQuestions = conceptEvaluationValue.question?.subquestions || [];

              return {
                ...block,
                values: [
                  {
                    ...conceptEvaluationValue,
                    question: {
                      ...conceptEvaluationValue.question,
                      subquestions: addQuestion
                        ? [...subQuestions, { text: '' }]
                        : [
                            ...subQuestions.slice(0, payload.removeIndex),
                            ...subQuestions?.slice((payload.removeIndex ?? 0) + 1),
                          ],
                    },
                  },
                ],
              };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  return (
    <>
      <h2>{isConceptRating ? 'Rating' : 'Evaluation'} Title</h2>
      <Input
        value={conceptEvaluationValue.question?.title}
        onChange={(e) => handleEditConceptEvaluation({ title: e.target.value })}
        backgroundColor="white"
        placeholder="Add a title to whole evaluation"
      />
      <Spacer />
      <Row
        justify="space-between"
        align="middle"
        onClick={() => handleAddRemoveQuestion({})}
        style={{ cursor: 'pointer' }}
      >
        <h2 style={{ margin: 0 }}>{isConceptRating ? 'Rating Questions' : 'Constraint Questions'}</h2>
        <FiPlus size={20} color="#3EBC89" />
      </Row>
      <Spacer />
      {conceptEvaluationValue.question?.subquestions?.map((value, i) => (
        <Row
          justify="space-between"
          align="middle"
          gutter={[0, 16]}
          key={`${i}${conceptEvaluationValue.question?.subquestions?.length}`}
        >
          <Col span={22}>
            <Input
              value={value.text}
              onChange={(e) => handleEditConceptEvaluation({ index: i, question: e.target.value })}
              backgroundColor="white"
              placeholder="Add value"
            />
          </Col>
          <FiMinusCircle
            onClick={() => handleAddRemoveQuestion({ removeIndex: i })}
            style={{ cursor: 'pointer' }}
            size={18}
          />
        </Row>
      ))}
    </>
  );
};

export default ConceptEvaluationBlockEditor;
