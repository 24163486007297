import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useRemirror } from 'remirror/react';

const ReadOnlyEditor = ({ 'data-cy': dataCy }: { 'data-cy'?: string }) => {
  const { getRootProps } = useRemirror();
  const _id = uuidv4();
  return (
    <div id={_id} data-cy={dataCy}>
      <div {...getRootProps()} />
    </div>
  );
};

export default ReadOnlyEditor;
