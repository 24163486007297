import React from 'react';
import LearnHeader from './LearnHeader';
import LearningHeader from '../LandingHeader';
import LearningFooter from '../LandingFooter';
import LearnPurpose from './LearnPurpose';
import LearnStage from './LearnStage';

const LearnMore: React.FC = () => {
  return (
    <div style={{overflow: 'hidden'}}>
      <LearningHeader />
      <LearnHeader />
      <LearnPurpose />
      <LearnStage />
      <LearningFooter/>
    </div>
  );
};

export default LearnMore;