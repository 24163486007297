import styled from 'styled-components';

export const ContentWrapper = styled.div`
  strong {
    &:first-child {
      font-weight: 800;
      font-size: 1.17em;
    }
  }
`;
