import { RcFile } from 'antd/lib/upload';
import React from 'react';
import { FiArrowLeft, FiTrash2, FiUpload } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Button from '../../../shared/Button';
import Editor from '../../../shared/Editor';
import { IInvestigationBookStage } from '../../../shared/InvestigationBook';
import SettingsCollapsable from '../../../shared/SettingsCollapsable';
import Sider from '../../../shared/Sider';
import Spacer from '../../../shared/Spacer';
import { IOnChageEditor } from '../../../types';
import { themeConfig } from '../../../utils/theme';
import HyperLink from '../../../assets/hyperlink_url_web_icon.svg';
import * as S from './styles';

interface IPreviewSettingsSider {
  investigationDescription?: string;
  loadingEditInvestigation: boolean;
  previewImage?: RcFile;
  imageFromUrl?: string;
  standardsPDF?: RcFile;
  suppliesPDF?: RcFile;
  suppliesUrl?: string;
  letterHomePDF?: RcFile;
  letterHomePdfFromUrl?: string;
  pdfFromUrl?: string;
  stages?: IInvestigationBookStage[];
  setInvestigationDescription?: (value: string) => void;
  setPreviewImage: (file?: RcFile) => void;
  setStandardsPDF: (file?: RcFile) => void;
  setSuppliesPDF: (file?: RcFile) => void;
  setLetterHomePDF: (file?: RcFile) => void;
  updateStages: (stages?: IInvestigationBookStage[]) => void;
  onSaveDraft: () => void;
}

const PreviewSettingsSider = (props: IPreviewSettingsSider) => {
  const {
    investigationDescription,
    setInvestigationDescription = () => null,
    setPreviewImage,
    setStandardsPDF,
    setLetterHomePDF,
    updateStages,
    setSuppliesPDF,
    previewImage,
    standardsPDF,
    suppliesPDF,
    letterHomePDF,
    stages,
    loadingEditInvestigation,
    onSaveDraft,
    imageFromUrl,
    pdfFromUrl,
    suppliesUrl,
    letterHomePdfFromUrl,
  } = props;
  const history = useHistory();

  const beforeUploadImage = (file: RcFile) => {
    if (!file.type.includes('image')) return false;
    setPreviewImage(file);
    return true;
  };

  const beforeUploadPdf = (file: RcFile) => {
    if (!file.type.includes('pdf')) return false;
    setStandardsPDF(file);
    return true;
  };

  const beforeUploadSuppliesPdf = (file: RcFile) => {
    if (!file.type.includes('pdf')) return false;
    setSuppliesPDF(file);
    return true;
  };

  const beforeUploadLetterHomePDF = (file: RcFile) => {
    if (!file.type.includes('pdf')) return false;
    setLetterHomePDF(file);
    return true;
  };

  const handleClearImage = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();

    setPreviewImage(undefined);
  };

  const handleClearPDF = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();

    setStandardsPDF(undefined);
  };

  const handleClearSuppliesPDF = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();

    setSuppliesPDF(undefined);
  };

  const handleClearLetterHomePDF = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();

    setLetterHomePDF(undefined);
  };

  const handleInvestigationDescriptionEdit = (data: IOnChageEditor) => {
    data.isEditorEmpty ? setInvestigationDescription('') : setInvestigationDescription(data.value);
  };

  const handleStageDescriptionEdit = (data: IOnChageEditor, stageName: string) => {
    updateStages(
      stages?.map((stage) => {
        if (stage.name !== stageName) return stage;
        return {
          ...stage,
          previewText: data.isEditorEmpty ? '' : data.value,
        };
      }),
    );
  };
  const handleStageInPersonEdit = (data: IOnChageEditor, stageName: string) => {
    updateStages(
      stages?.map((stage) => {
        if (stage.name !== stageName) return stage;
        return {
          ...stage,
          inPersonPreviewText: data.isEditorEmpty ? '' : data.value,
        };
      }),
    );
  };

  const handleStageRemoteEdit = (data: IOnChageEditor, stageName: string) => {
    updateStages(
      stages?.map((stage) => {
        if (stage.name !== stageName) return stage;
        return {
          ...stage,
          remotePreviewText: data.isEditorEmpty ? '' : data.value,
        };
      }),
    );
  };

  return (
    <Sider
      title={
        <S.SiderTitle onClick={() => history.push('core')}>
          <FiArrowLeft size={22} />
          <Spacer axis="horizontal" />
          Investigation Preview
        </S.SiderTitle>
      }
    >
      <S.SiderContainer>
        <S.SettingsContainer>
          <SettingsCollapsable title="Investigation Subtitle">
            <Editor
              fontSize={11}
              title="Subtitle"
              placeholder="Investigation Description"
              value={investigationDescription}
              onChange={handleInvestigationDescriptionEdit}
              showAlign
              showLink
            />
            <Spacer />
          </SettingsCollapsable>

          <SettingsCollapsable title="Investigation Image">
            <h2>Preview Image</h2>
            <S.UploadOutsider
              accept="image/*"
              beforeUpload={beforeUploadImage}
              defaultFileList={[]}
              fileList={[]}
              customRequest={() => { }}
            >
              <S.UploadContainer>
                <FiUpload />
                <Spacer axis="horizontal" />
                {previewImage ? previewImage.name : `${imageFromUrl ? 'Replace' : 'Add an'} Image`}
                {previewImage && <FiTrash2 onClick={handleClearImage} />}
              </S.UploadContainer>
            </S.UploadOutsider>
          </SettingsCollapsable>

          <SettingsCollapsable title="Investigation Standards PDF">
            <h2>Upload PDF</h2>
            <S.UploadOutsider
              accept=".pdf"
              beforeUpload={beforeUploadPdf}
              defaultFileList={[]}
              fileList={[]}
              customRequest={() => { }}
            >
              <S.UploadContainer>
                <FiUpload />
                <Spacer axis="horizontal" />
                {standardsPDF ? standardsPDF.name : `${pdfFromUrl ? 'Replace' : 'Add a'} PDF`}
                {standardsPDF && <FiTrash2 onClick={handleClearPDF} />}
              </S.UploadContainer>
              {(pdfFromUrl) && (
                <S.UploadedFileContainer>
                  <S.FileLink
                    href={
                      pdfFromUrl

                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: '8px', 
                      textDecoration: 'none',
                    }}
                  >
                    <img 
                    src={HyperLink} 
                    alt="HyperLink Icon" 
                    style={{
                      width: '16px',
                      height: '16px',
                    }}
                  />
                    {pdfFromUrl.split('/').pop()}
                  </S.FileLink>
                </S.UploadedFileContainer>
              )}

            </S.UploadOutsider>
          </SettingsCollapsable>

          <SettingsCollapsable title="Investigation Supplies PDF">
            <h2>Upload PDF</h2>
            <S.UploadOutsider
              accept=".pdf"
              beforeUpload={beforeUploadSuppliesPdf}
              defaultFileList={[]}
              fileList={[]}
              customRequest={() => { }}
            >
              <S.UploadContainer>
                <FiUpload />
                <Spacer axis="horizontal" />
                {suppliesPDF ? suppliesPDF.name : `${suppliesPDF ? 'Replace' : 'Add a'} PDF`}
                {suppliesPDF && <FiTrash2 onClick={handleClearSuppliesPDF} />}
              </S.UploadContainer>
              {suppliesUrl && (
                <S.UploadedFileContainer>
                  <S.FileLink
                    href={suppliesUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: '8px', 
                      textDecoration: 'none',
                    }}
                  >
                    <img 
                    src={HyperLink} 
                    alt="HyperLink Icon" 
                    style={{
                      width: '16px', // Adjust the icon size
                      height: '16px',
                    }}
                  />
                    {suppliesUrl.split('/').pop()}
                  </S.FileLink>
                </S.UploadedFileContainer>
              )}
            </S.UploadOutsider>
          </SettingsCollapsable>

          <SettingsCollapsable title="Letter Home PDF">
            <h2>Upload PDF</h2>
            <S.UploadOutsider
              accept=".pdf"
              beforeUpload={beforeUploadLetterHomePDF}
              defaultFileList={[]}
              fileList={[]}
              customRequest={() => { }}
            >
              <S.UploadContainer>
                <FiUpload />
                <Spacer axis="horizontal" />
                {letterHomePDF ? letterHomePDF.name : `${letterHomePdfFromUrl ? 'Replace' : 'Add a'} PDF`}
                {letterHomePDF && <FiTrash2 onClick={handleClearLetterHomePDF} />}
              </S.UploadContainer>
              {letterHomePdfFromUrl && (
                <S.UploadedFileContainer>
                  <S.FileLink
                    href={letterHomePdfFromUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: '8px', 
                      textDecoration: 'none',
                    }}
                  >
                    <img 
                    src={HyperLink} 
                    alt="HyperLink Icon" 
                    style={{
                      width: '16px', // Adjust the icon size
                      height: '16px',
                    }}
                  />
                    {letterHomePdfFromUrl.split('/').pop()}
                  </S.FileLink>
                </S.UploadedFileContainer>
              )}
            </S.UploadOutsider>
          </SettingsCollapsable>

          {stages?.map((stage, i) => (
            <SettingsCollapsable title={`Stage ${i + 1} - ${stage.name} Description`} key={stage.name}>
              <Editor
                fontSize={11}
                title="Content"
                placeholder="Content Explanation"
                value={stage.previewText}
                onChange={(data) => handleStageDescriptionEdit(data, stage.name || '')}
                showAlign
                showLink
              />
              <Spacer />
              <Editor
                fontSize={11}
                title="In-Person"
                placeholder="In-Person Content Explanation"
                onChange={(data) => handleStageInPersonEdit(data, stage.name || '')}
                value={stage.inPersonPreviewText}
                showAlign
                showLink
              />
              <Spacer />
              <Editor
                fontSize={11}
                title="Remote"
                placeholder="Remote Content Explanation"
                onChange={(data) => handleStageRemoteEdit(data, stage.name || '')}
                value={stage.remotePreviewText}
                showAlign
                showLink
              />
            </SettingsCollapsable>
          ))}
        </S.SettingsContainer>

        <S.ButtonsContainer>
          <Button
            text="Go Back"
            loading={loadingEditInvestigation}
            theme={themeConfig.noColor}
            block
            onClick={() => history.goBack()}
          />
          <Spacer axis="horizontal" size={24} />
          <Button text="Save Settings" block loading={loadingEditInvestigation} onClick={onSaveDraft} />
        </S.ButtonsContainer>
      </S.SiderContainer>
    </Sider>
  );
};

export default PreviewSettingsSider;
