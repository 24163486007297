import { useQuery } from '@apollo/client';
import { Col, message, Row, Table } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useMemo, useRef, useState } from 'react';
import { FiFilter, FiSearch } from 'react-icons/fi';
import { gqlSchema } from '../../gql/schema';
import Button from '../../shared/Button';
import TableSearchBox from '../../shared/TableSearchBox';
import { GQL_WalkthroughCategory, GQL_WalkthroughVideo } from '../../types/walkthrough';
import { centerAlign, Breakpoint, Filter } from '../../utils/antd';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';
import { roles } from '../../utils/roles';
import PageWithTitle from '../../shared/PageWithTitle';
import { useHistory } from 'react-router-dom';
import AddWalkthroughVideo from './AddWalkthroughVideo';

const TableBreakPoint: Breakpoint[] = ['lg'];
const WalkthroughListPage = () => {
  const ref = useRef<HTMLInputElement>(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [rolesVisible, setRolesVisible] = useState(false);
  const [categoryFilterVisible, setCategoryFilterVisible] = useState(false);

  const [addWalkthroughVideoVisible, setAddWalkthroughVideoVisible] = useState(false);

  const history = useHistory();
  const { data, loading } = useQuery<{ getWalkthroughVideos: GQL_WalkthroughVideo[] }>(
    gqlSchema.WalkthroughSchema.queries.VIDEOS.getWalkthroughVideos,
    {
      fetchPolicy: 'network-only',
      onError: (err) => {
        message.error('There was an error loading Walkthrough Videos: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const { data: walkthroughCategories } = useQuery<{
    getWalkthroughCategory: GQL_WalkthroughCategory[];
  }>(gqlSchema.WalkthroughSchema.queries.CATEGORY.getWalkthroughCategory, {
    onError: (err) => {
      message.error('There was an error loading walkthrough categories: ' + err.message || 'Unexpected Error');
    },
  });

  const columns = useMemo(
    () => [
      {
        title: 'Title',
        sorter: (a: GQL_WalkthroughVideo, b: GQL_WalkthroughVideo) => `${a.title}`.localeCompare(`${b.title}`),
        render: (text: string, record: GQL_WalkthroughVideo) => {
          return <span role="none" >{record.title}</span>;
        },
        width: '20%',
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={ref} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_WalkthroughVideo) => {
          if (!value) return true;
          return record.title.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (ref && ref.current) {
                ref.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Role',
        dataIndex: 'roles',
        align: centerAlign,
        width: '25%',
        responsive: TableBreakPoint,
        onFilter: (value: string | number | boolean, record: GQL_WalkthroughVideo) =>
          record.roles.some((d) => d === value.toString()),
        filters: roles,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={rolesVisible}>
            <FiFilter size={18} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setRolesVisible(visible);
        },
        render: (text: string, record: GQL_WalkthroughVideo) => {
          return record.roles.map((y) => {
            const roleItem = roles.find((r: Filter) => r.value === y);
            return (
              <S.TagButton
                text={roleItem?.text || ''}
                display="inline"
                background={roleItem?.color}
                shape="round"
                key={y}
                minHeight={24}
              />
            );
          });
        },
      },
      {
        title: 'Category',
        responsive: TableBreakPoint,
        align: centerAlign,
        sorter: (a: GQL_WalkthroughVideo, b: GQL_WalkthroughVideo) => a.category.title.localeCompare(b.category.title),
        filters: walkthroughCategories?.getWalkthroughCategory.map((c) => ({ text: c.title, value: c.id })) || [],
        onFilter: (value: string | number | boolean, record: GQL_WalkthroughVideo) =>
          record.category.id.includes(value.toString()),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={categoryFilterVisible}>
            <FiFilter size={18} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setCategoryFilterVisible(visible);
        },
        render: (text: string, record: GQL_WalkthroughVideo) => {
          return (
            <S.TagButton
              text={record.category.title}
              background={themeConfig.secondaryColor.background}
              shape="round"
              minHeight={24}
            />
          );
        },
        width: '20%',
      },
      {
        title: 'Actions',
        align: centerAlign,
        render: (text: string, record: GQL_WalkthroughVideo) => {
          return <Button text="View More" onClick={() => history.push(`/walkthrough/${record.id}`)} block />;
        },
        width: '10%',
      },
    ],
    [categoryFilterVisible, rolesVisible, searchVisible, walkthroughCategories, history],
  );

  return (
    <PageWithTitle
      title="Walkthrough Settings"
      extra={
        <Row justify="end">
          <Col
            xxl={{ span: 16 }}
            xl={{ span: 16 }}
            lg={{ span: 16 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Button
              text="Add Walkthrough Video"
              style={{ marginLeft: 'auto' }}
              block
              onClick={() => setAddWalkthroughVideoVisible(true)}
            />
          </Col>
        </Row>
      }
    >
      <Row gutter={[24, 24]}>
        <Col style={{ paddingTop: 0 }} span={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_WalkthroughVideo) => record.code}
              bordered
              dataSource={data?.getWalkthroughVideos}
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <AddWalkthroughVideo visible={addWalkthroughVideoVisible} setVisible={setAddWalkthroughVideoVisible} />
    </PageWithTitle>
  );
};

export default WalkthroughListPage;
