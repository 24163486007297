import { useMutation } from '@apollo/client';
import { Col, message, Row } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { gqlSchema } from '../../../gql/schema';
import ManageGroups from '../../../shared/CreateClass/ManageGroups';
import {
  GQL_ChangeStudentGroupInput,
  GQL_ClassResponse,
  GQL_EditClassInput,
  GQL_RemoveStudentFromClassInput,
  GroupConfig,
} from '../../../types/class';
import { StudentDataProps } from '../../../types/students';
import { getShrinkedGroups } from '../../../utils/class';
import * as S from './styles';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  classInfo?: GQL_ClassResponse;
}
const ManageGroupsModal: React.FC<Props> = (props) => {
  const { visible, setVisible, classInfo } = props;
  const [groups, setGroups] = useState<GroupConfig[]>([]);
  const [numberOfGroups, setNumberOfGroups] = useState(0);
  const [students, setStudents] = useState<GQL_ClassResponse['students'] | undefined>(classInfo?.students);
  const onCloseModal = () => {
    setVisible(false);
  };

  const [submitMoveStudent] = useMutation<{ changeStudentGroup: GQL_ClassResponse }>(
    gqlSchema.ClassSchema.mutation.CLASS.MANAGE_GROUP.changeStudentGroup,
    {
      onCompleted: ({ changeStudentGroup }) => {
        setStudents(changeStudentGroup.students);
      },
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const [submitRemoveStudent] = useMutation<{ removeStudentFromClass: GQL_ClassResponse }>(
    gqlSchema.ClassSchema.mutation.CLASS.MANAGE_GROUP.removeStudentFromClass,
    {
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const [editClass] = useMutation<{ editClass: GQL_ClassResponse }, { data: GQL_EditClassInput }>(
    gqlSchema.ClassSchema.mutation.CLASS.EDIT.editClass,
    {
      onError: (err) => {
        message.error('There was an error trying to edit the class groups, please try again later');
      },
    },
  );

  useEffect(() => {
    if (classInfo) {
      setGroups(getShrinkedGroups(classInfo?.numberOfGroups));
      setNumberOfGroups(classInfo?.numberOfGroups);
      setStudents(classInfo?.students);
    }
  }, [classInfo]);

  useEffect(() => {
    if (classInfo && numberOfGroups > 0) {
      editClass({
        variables: {
          data: {
            id: classInfo.id,
            name: classInfo.name,
            numberOfGroups: numberOfGroups,
            endDate: classInfo.endDate,
            startDate: classInfo.startDate,
          },
        },
      });
      setGroups(getShrinkedGroups(numberOfGroups));
    }
  }, [numberOfGroups, classInfo, editClass]);

  const onRemoveStudent = useCallback(
    async (student: StudentDataProps) => {
      if (classInfo) {
        const data: GQL_RemoveStudentFromClassInput = {
          classId: classInfo.id,
        };

        if (student.userId) data.userId = student.userId;
        else data.inviteId = student.inviteId;

        const response = await submitRemoveStudent({ variables: { data } });
        if (response.data?.removeStudentFromClass) {
          message.success(`Student ${student.firstName} ${student.lastName} removed from class ${classInfo?.name}`);
        }
      }
    },
    [classInfo, submitRemoveStudent],
  );

  const onMoveStudent = useCallback(
    async (student: StudentDataProps, newGroup: string) => {
      if (classInfo) {
        const data: GQL_ChangeStudentGroupInput = {
          classId: classInfo.id,
          newGroup,
        };

        if (student.userId) data.userId = student.userId;
        else data.inviteId = student.inviteId;

        const response = await submitMoveStudent({ variables: { data } });
        if (response.data?.changeStudentGroup) {
          message.success(`Student ${student.firstName} ${student.lastName} moved to group ${newGroup}`);
        }
      }
    },
    [classInfo, submitMoveStudent],
  );

  const onFinishManageGroup = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <S.CModal
      visible={visible}
      title={
        <Row style={{ marginTop: 26 }} justify="center">
          <Col span={24}>
            <S.Title>Manage Groups</S.Title>
          </Col>
        </Row>
      }
      onCancel={onCloseModal}
      width={900}
      footer={null}
    >
      <ManageGroups
        groups={groups}
        students={students}
        onFinishManageGroup={onFinishManageGroup}
        onRemoveStudent={onRemoveStudent}
        onMoveStudent={onMoveStudent}
        removeGroup={true}
        setNumberOfGroups={setNumberOfGroups}
      />
    </S.CModal>
  );
};

export default ManageGroupsModal;
