import React, { useState } from 'react';
import CurrentUsers from './CurrentUsers';
import * as S from './styles';
import { Col, Row, Tabs } from 'antd';
import PendingInvites from './PendingInvites';
import PendingTransferInvites from './PendingTransferInvites';
import { useAuth } from '../../hooks/useAuth';

const { TabPane } = Tabs;
const UserList = () => {
  const [activeKey, setActiveKey] = useState('current');
  const { isOrganizationAdiAdmin } = useAuth();

  return (
    <>
      <S.Container>
        <Row gutter={[24, 24]}>
          <Col xxl={{ span: 18, offset: 3 }} xl={24}>
            <Tabs
              defaultActiveKey={activeKey}
              activeKey={activeKey}
              onChange={(activeKey: string) => setActiveKey(activeKey)}
            >
              <TabPane
                tab={<S.TitleTab data-cy="components-userlist-currentusers-tab">Current Users</S.TitleTab>}
                key="current"
              >
                <CurrentUsers setActiveKey={setActiveKey} />
              </TabPane>
              <TabPane
                tab={<S.TitleTab data-cy="components-userlist-pendinginvites-tab">Pending Invites</S.TitleTab>}
                key="pending"
              >
                <PendingInvites />
              </TabPane>
              {isOrganizationAdiAdmin && (
                <TabPane tab={<S.TitleTab>Pending Transfer Invites</S.TitleTab>} key="transfer-invites">
                  <PendingTransferInvites />
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Row>
      </S.Container>
    </>
  );
};

export default UserList;
