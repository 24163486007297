import { Col, Row, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { centerAlign, Breakpoint } from '../../../../utils/antd';
import * as S from './styles';
import { GQL_SortingInput, SortOptions } from '../../../../types/sorting';
import { GQL_InvAssignmentTrendResponse, GQL_InvAssignmentTrendTeacher } from '../../../../types/charts';
import { Duration } from '../../../../types/investigation';

const TableBreakPoint: Breakpoint[] = ['lg'];

interface Props {
  title: string;
  periodInvestigationAssignedRate: Duration;
  setSorting: Dispatch<SetStateAction<GQL_SortingInput>>;
  loading: boolean;
  pagination?: TablePaginationConfig;
  setPagination?: Dispatch<SetStateAction<TablePaginationConfig | undefined>>;
  data?: GQL_InvAssignmentTrendResponse;
}

const TrendTable = (props: Props) => {
  const { setSorting, loading, data, setPagination, pagination } = props;

  const manageSortings = useCallback(
    (sort: GQL_SortingInput) => {
      setSorting({ ...sort, order: sort.order || SortOptions.DESC });
    },
    [setSorting],
  );

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      const order = sorter.order ? (sorter.order === 'ascend' ? SortOptions.ASC : SortOptions.DESC) : undefined;
      manageSortings({ field: sorter.columnKey, order });

      if (pagination && setPagination) setPagination(pagination);
    },
    [manageSortings, setPagination],
  );

  const columns = useMemo(() => {
    const col: ColumnsType<GQL_InvAssignmentTrendTeacher> = [
      {
        title: 'First Name',
        align: centerAlign,
        dataIndex: 'teacherFirstName',
        width: '15%',
        responsive: TableBreakPoint,
      },
      {
        title: 'Last Name',
        align: centerAlign,
        dataIndex: 'teacherLastName',
        width: '15%',
        responsive: TableBreakPoint,
      },
      {
        title: 'Email',
        align: centerAlign,
        dataIndex: 'teacherEmail',
        width: '20%',
        responsive: TableBreakPoint,
      },
      {
        title: '# of assignments 1',
        align: centerAlign,
        sorter: true,
        dataIndex: 'rateOnFirstPeriod',
        key: 'rate',
        width: '13%',
        responsive: TableBreakPoint,
      },
      {
        title: '# of assignments 2',
        dataIndex: 'rateOnSecondPeriod',
        sorter: true,
        key: 'rateTwo',
        align: centerAlign,
        width: '13%',
        responsive: TableBreakPoint,
      },
      {
        title: 'Change (#)',
        dataIndex: 'rateDiff',
        align: centerAlign,
        width: '12%',
        responsive: TableBreakPoint,
      },
      {
        title: 'Change (%)',
        align: centerAlign,
        width: '12%',
        key: 'rateDiff',
        responsive: TableBreakPoint,
        sorter: true,
        render: (text: string, record: GQL_InvAssignmentTrendTeacher) => {
          const change = parseFloat(
            (((record.rateOnSecondPeriod || 1) * 100) / (record.rateOnFirstPeriod || 1)).toFixed(2),
          );

          return `${
            record.rateOnFirstPeriod === record.rateOnSecondPeriod
              ? ''
              : record.rateOnFirstPeriod < record.rateOnSecondPeriod
              ? '+'
              : '-'
          }${record.rateDiff === 0 ? 0 : change}%`;
        },
      },
    ];

    return col;
  }, []);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_InvAssignmentTrendTeacher) => record.teacherId}
              pagination={{ ...pagination, defaultPageSize: 30, hideOnSinglePage: true }}
              bordered
              dataSource={data?.trends || []}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default TrendTable;
