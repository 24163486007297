import { useMemo } from 'react';
import { GQL_InvestigationStandard } from '../types/investigationStandard';

type Props = {
  stateStandards: GQL_InvestigationStandard[] | undefined;
};

const useStateStandardFilter = (props: Props) => {
  const stateStandardFilterData = useMemo(() => {
    const gradeK = props.stateStandards?.filter((state) => state.grade === 'K');
    const grade01 = props.stateStandards?.filter((state) => state.grade === '01');
    const grade02 = props.stateStandards?.filter((state) => state.grade === '02');
    const grade03 = props.stateStandards?.filter((state) => state.grade === '03');
    const grade04 = props.stateStandards?.filter((state) => state.grade === '04');
    const grade05 = props.stateStandards?.filter((state) => state.grade === '05');
    const grade06 = props.stateStandards?.filter((state) => state.grade === '06');
    const grade07 = props.stateStandards?.filter((state) => state.grade === '07');
    const grade08 = props.stateStandards?.filter((state) => state.grade === '08');
    const grade9To12 = props.stateStandards?.filter((state) => state.grade === '9-12');

    return [
      {
        key: 'Grade K',
        standards: gradeK,
      },
      {
        key: 'Grade 01',
        standards: grade01,
      },
      {
        key: 'Grade 02',
        standards: grade02,
      },
      {
        key: 'Grade 03',
        standards: grade03,
      },
      {
        key: 'Grade 04',
        standards: grade04,
      },
      {
        key: 'Grade 05',
        standards: grade05,
      },
      {
        key: 'Grade 06',
        standards: grade06,
      },
      {
        key: 'Grade 07',
        standards: grade07,
      },
      {
        key: 'Grade 08',
        standards: grade08,
      },
      {
        key: 'Grades 9-12',
        standards: grade9To12,
      },
    ];
  }, [props.stateStandards]);

  return { stateStandardFilterData };
};

export const useNgssStandarFilter = (props: Props) => {
  const ngssStandardFilterData = useMemo(() => {
    const gradeK2 = props.stateStandards?.filter((state) => state.grade.includes('K-2'));
    const grade3To5 = props.stateStandards?.filter((state) => state.grade.includes('3-5'));
    const grade6To8 = props.stateStandards?.filter((state) => state.grade.includes('6-8'));
    const grade9To12 = props.stateStandards?.filter((state) => state.grade.includes('9-12'));

    return [
      {
        key: 'Grade K-2',
        standards: gradeK2,
      },
      {
        key: 'Grade 3-5',
        standards: grade3To5,
      },
      {
        key: 'Grade 6-8',
        standards: grade6To8,
      },
      {
        key: 'Grade 9-12',
        standards: grade9To12,
      },
    ];
  }, [props.stateStandards]);

  return { ngssStandardFilterData };
};

export default useStateStandardFilter;
