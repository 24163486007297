import { gql } from '@apollo/client';

const NOTIFICATION_QUERIES = {
  NOTIFICATIONS: {
    getNotifications: gql`
      query GetNotifications {
        getNotifications {
          id
          created
          type
          seen
          priority
          metadata {
            activityType
            activityId
            classId
            className
            teacherName
            commentId
            dueDate
            classInvestigationId
            investigationId
            investigationStep
            investigationTitle
            stepId
            numberOfStudentsThatMissedTheDeadline
            conceptActivityId
            evaluatorId
            studentName
            studentId
            isValid
            hasDoneNothing
            isAssessment
          }
        }
      }
    `,
  },
};

export default NOTIFICATION_QUERIES;
