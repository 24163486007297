import React from 'react';
import * as S from './styles';
import moment from 'moment';
import { RiArrowDownSLine } from 'react-icons/ri';

interface IPickerInput {
  backgroundColor?: string;
  value?: { startDate: string; endDate: string };
  defaultValue?: { startDate: string; endDate: string };
  disabled?: [boolean, boolean];
  disabledDate?: (currentDate: moment.Moment) => boolean;
  allowClear?: boolean;
  onChange?: ({ startDate, endDate }: { startDate: string; endDate: string }) => void;
}
const dateFormat = 'MM.DD.YYYY';

const RangePicker = (props: IPickerInput) => {
  const { value, onChange, defaultValue, disabled, allowClear, backgroundColor, disabledDate } = props;

  const onSelectDate = (values: any, formatString: [string, string]) => {
    if (onChange) onChange({ startDate: formatString[0], endDate: formatString[1] });
  };
  return (
    <S.RangePicker
      {...props}
      $backgroundColor={backgroundColor}
      format={dateFormat}
      suffixIcon={<RiArrowDownSLine />}
      onChange={onSelectDate}
      disabled={disabled}
      allowClear={allowClear}
      disabledDate={disabledDate}
      defaultValue={
        defaultValue
          ? [moment(defaultValue.startDate, dateFormat), moment(defaultValue.endDate, dateFormat)]
          : undefined
      }
      value={value ? [moment(value.startDate, dateFormat), moment(value.endDate, dateFormat)] : undefined}
    />
  );
};

export default React.memo(RangePicker);
