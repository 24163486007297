import React from 'react';
import * as S from './styles';
import { studentPerformanceLevels, studentPerformanceLevelsByStandards } from '../../../types/studentPerformanceLevels';

interface IStudentsPerformanceLegends {
  standardsMode?: boolean;
}

const StudentsPerformanceLegends: React.FC<IStudentsPerformanceLegends> = ({ standardsMode }) => {
  if (standardsMode) {
    return (
      <S.LegendsContainer>
        {Object.values(studentPerformanceLevelsByStandards).map((performanceLevel) => (
          <S.Legend key={performanceLevel.key}>
            <S.LegendCircle color={performanceLevel.color} />
            <S.LegendText>{performanceLevel.name}</S.LegendText>
          </S.Legend>
        ))}
      </S.LegendsContainer>
    );
  }

  return (
    <S.LegendsContainer>
      {Object.values(studentPerformanceLevels).map((performanceLevel) => (
        <S.Legend key={performanceLevel.key}>
          <S.LegendCircle color={performanceLevel.color} />
          <S.LegendText>{performanceLevel.name}</S.LegendText>
        </S.Legend>
      ))}
    </S.LegendsContainer>
  );
};

export default StudentsPerformanceLegends;
