import { gql } from '@apollo/client';

export const WALKTHROUGH = {
  walkthroughVideo: gql`
    fragment Walkthrough on WalkthroughVideo {
      id
      title
      role
      code
      videoUrl
      isVisible
      category {
        id
        title
      }
    }
  `,
};

export default WALKTHROUGH;
