import styled from 'styled-components';
import { Col } from 'antd';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Column = styled(Col)<{ $presentationMode?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  & > p,
  & > h1,
  & > h2
  & > h3 {
    text-align: center;
  }
  
  & > h3 {
    align-self: flex-start;
  }

  & > h1 {
    margin-bottom ${(props) => (props.$presentationMode ? 0 : 30)}px;
  }

  & > div {
    width: 100%;
  }
`;

export const BlockPreviewContainer = styled.div`
  h1,
  h2,
  h3 {
    font-weight: 400;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }

  pointer-events: none;
`;
