import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, message } from 'antd';
import DashboardRow from '../../DashboardRow';
import { gqlSchema } from '../../../gql/schema';
import { GQL_ClassResponse, GQL_CreateAnnouncementResponse } from '../../../types/class';

import { useQuery } from '@apollo/client';
import DashboardLoadingCard from '../../DashboardLoadingCard';
import AnnouncementCard from '../../AnnouncementCard';

interface ITeacherAnnouncementsRow {
  classId?: string;
}

const TeacherAnnouncementsRow = (props: ITeacherAnnouncementsRow) => {
  const { classId } = props;
  const [announcementList, setAnnouncementList] = useState<GQL_CreateAnnouncementResponse[]>([]);

  const { data, loading: loadingAnnouncements } = useQuery<{
    getClassAnnouncementsForMyClasses: GQL_CreateAnnouncementResponse[];
  }>(gqlSchema.ClassSchema.query.CLASS.ANNOUNCEMENT.getClassAnnouncementsForMyClasses, {
    onError: (error) => {
      message.error(
        error.message || 'There was an error loading your classes, check you connection and try again later.',
      );
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: classData, loading: loadingClasses } = useQuery<{ getClasses: GQL_ClassResponse[] }>(
    gqlSchema.ClassSchema.query.CLASS.CLASSES.getClasses,
    {
      onError: (err) => {
        message.error('There was an error loading classes: ' + err.message || 'Unexpected Error');
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const loading = loadingAnnouncements || loadingClasses;

  useEffect(() => {
    const announcements =
      data?.getClassAnnouncementsForMyClasses?.slice()?.sort((a, b) => b.createdAt - a.createdAt) || [];

    if (classId) {
      setAnnouncementList(announcements?.filter((a) => a.classId === classId));
    } else {
      setAnnouncementList(announcements);
    }
  }, [classId, data]);

  const classes = useMemo(() => {
    if (loading)
      return (
        <Row gutter={[24, 24]}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard cardStyle="class" />
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard cardStyle="class" />
          </Col>
        </Row>
      );

    return (
      <Row gutter={[24, 24]}>
        {announcementList?.slice(0, 2).map((item: GQL_CreateAnnouncementResponse) => {
          const classInfo = classData?.getClasses?.find((c) => c.id === item.classId);

          return (
            <Col
              lg={12}
              md={24}
              sm={24}
              xs={24}
              key={item.id}
              data-cy={`shared-studentdashboardrows-AnnouncementCard-${item.id}`}
            >
              <AnnouncementCard
                className={classInfo?.name || ''}
                teacherName={classInfo?.teacherName || ''}
                teacherAvatar={classInfo?.avatar}
                creation={item.createdAt}
                text={item.text}
                id={item.id}
              />
            </Col>
          );
        })}
      </Row>
    );
  }, [announcementList, loading, classData]);
  const [extraClasses, setExtraClasses] = useState<ReactElement | null>(null);

  const onViewMoreClasses = useCallback(
    (opened: boolean) => {
      if (opened)
        setExtraClasses(
          <Row gutter={[24, 24]}>
            {announcementList?.slice(2, announcementList.length).map((item: GQL_CreateAnnouncementResponse) => {
              const classInfo = classData?.getClasses?.find((c) => c.id === item.classId);

              return (
                <Col lg={12} md={24} sm={24} xs={24} key={item.id}>
                  <AnnouncementCard
                    className={classInfo?.name || ''}
                    teacherName={classInfo?.teacherName || ''}
                    creation={item.createdAt}
                    text={item.text}
                    id={item.id}
                  />
                </Col>
              );
            })}
          </Row>,
        );
      else setExtraClasses(null);
    },
    [announcementList, classData],
  );

  if (!announcementList?.length && !loading) return null;

  return (
    <DashboardRow
      title="Teacher Announcements"
      items={classes}
      extraItems={extraClasses}
      onViewMore={announcementList.length > 2 ? onViewMoreClasses : undefined}
      showViewMore={announcementList.length > 2}
      extraCyTitle="teacher-announcements"
    />
  );
};

export default TeacherAnnouncementsRow;
