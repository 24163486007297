import { gql } from '@apollo/client';

export const CHAT_QUERIES = {
  searchUsers: gql`
    query getChatSuggestions {
      getChatSuggestions {
        userId
        userName
        avatar
      }
    }
  `,
  getChatRooms: gql`
    query getChatRooms {
      getChatRooms {
        chatId
        userId
        userName
        avatar
        senderName
        message
        sent
        read
      }
    }
  `,
  getMessages: gql`
    query getMessages($data: GetMessagesInput!) {
      getMessages(data: $data) @connection(key: "getMessages", filter: ["data", ["chatId"]]) {
        chat {
          chatId
          recipientId
          recipientUserName
          senderId
          senderUserName
          message
          sent
          fileUrl
          MIMEType
          read
          chatMessageId
        }
        pagination {
          page
          size
          totalCount
        }
      }
    }
  `,
  getUnreadMessagesCount: gql`
    query getUnreadMessagesCount {
      getUnreadMessagesCount
    }
  `,
};

export default CHAT_QUERIES;
