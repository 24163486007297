import React, { Dispatch, SetStateAction } from 'react';
import { Tabs } from 'antd';
import * as S from './styles';
import UsersData from './UsersData';
// import UsersCsv from './UsersCsv';
import { FormInstance } from 'antd/lib/form';
import { ClassProps } from '../../types/class';
import { UserDataProps } from '../../types/user';
import UsersCsv from './UsersCsv';

const { TabPane } = Tabs;

interface Props {
  form: FormInstance<ClassProps>;
  viewUsersDetails: boolean;
  setHasUploadedFile: Dispatch<SetStateAction<boolean>>;
  initialInvite: UserDataProps[];
  activeKey: string;
  setActiveKey: Dispatch<SetStateAction<string>>;
  hasErrorFile: boolean;
  setHasErrorFile: Dispatch<SetStateAction<boolean>>;
  userType: string;
  onUserListUpdate?: () => void;
}
const InviteUsers: React.FC<Props> = (props) => {
  const {
    viewUsersDetails,
    setHasUploadedFile,
    form,
    activeKey,
    setActiveKey,
    setHasErrorFile,
    hasErrorFile,
    initialInvite,
    userType,
    onUserListUpdate,
  } = props;
  return (
    <Tabs
      defaultActiveKey={activeKey}
      activeKey={activeKey}
      onChange={(activeKey: string) => setActiveKey(activeKey)}
      centered
    >
      <TabPane tab={<S.TitleTab>Manual</S.TitleTab>} key="manual" style={{ marginBottom: 1 }}>
      {console.log("formData",form)}
        <UsersData
          userType={userType}
          currentUsers={form?.getFieldValue('invites')}
          key={form?.getFieldValue('invites')?.length}
          onUserListUpdate={onUserListUpdate}
        />
      </TabPane>
      <TabPane tab={<S.TitleTab>File Import</S.TitleTab>} key="file" style={{ marginBottom: 30 }}>
        <UsersCsv
          form={form}
          viewUsersDetails={viewUsersDetails}
          setHasUploadedFile={setHasUploadedFile}
          setHasErrorFile={setHasErrorFile}
          hasErrorFile={hasErrorFile}
          initialInvite={initialInvite}
          userType={userType}
          key={form?.getFieldValue('invites')?.length}
          onUserListUpdate={onUserListUpdate}
        />
      </TabPane>
    </Tabs>
  );
};

export default React.memo(InviteUsers);
