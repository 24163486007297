import React from 'react';
import * as S from '../styles';
import Button from '../../../shared/Button';
import { themeConfig } from '../../../utils/theme';
import { RxPlus, RxDash, RxCheck, RxArrowUp, RxArrowDown } from 'react-icons/rx';
import { Popover, Tooltip } from 'antd';

interface IPackageCard {
  ctaLoading?: boolean;
  activePlan?: boolean;
  pillText?: string;
  popOverPillText?: string;
  disableCta?: boolean;
  title: string;
  titleSuffix?: string;
  subtitle?: string;
  content: { type: string; text: string; bgColor: string; color: string }[];
  ctaText?: string;
  action?: () => void;
  downgradePending?: boolean;
}

const PackageCard: React.FC<IPackageCard> = (props) => {
  const renderCTAButton = () => {
    if (props.activePlan) return <></>;

    if (props.downgradePending) {
      return (
        <Tooltip title="You subscription has been downgraded successfully. The downgrade will go into affect when your current subscription ends.">
          <Button
            disabled
            loading={props.ctaLoading}
            text={props.activePlan ? 'Plan Currently Active' : props.ctaText}
            theme={props.activePlan ? themeConfig.primaryOutlined : themeConfig.primaryColor}
            onClick={props.action}
            style={{
              borderRadius: '4px',
              padding: '16px 0px',
              width: '100%',
              fontSize: '18px',
              height: 'fit-content',
            }}
          />
        </Tooltip>
      );
    }
    return (
      <Button
        loading={props.ctaLoading}
        text={props.activePlan ? 'Plan Currently Active' : props.ctaText}
        disabled={props.disableCta}
        theme={props.activePlan ? themeConfig.primaryOutlined : themeConfig.primaryColor}
        onClick={props.action}
        style={{
          borderRadius: '4px',
          padding: '16px 0px',
          width: '100%',
          fontSize: '18px',
          height: 'fit-content',
        }}
      />
    );
  };

  return (
    <S.Card isActive={!!props.activePlan}>
      <div>
        <S.TitleContainer>
          <div>
            <S.CardTitle>
              {props.title}
              {props.titleSuffix ? <S.CardTitleSuffix>{props.titleSuffix}</S.CardTitleSuffix> : null}
            </S.CardTitle>
            <S.CardSubtitle>{props.subtitle}</S.CardSubtitle>
          </div>
          {props.activePlan ? (
            <S.Pill color="blue">Currently Active</S.Pill>
          ) : (
            <>
              {props.pillText ? (
                <Popover
                  trigger={props.disableCta ? 'hover' : ''}
                  content={props.popOverPillText || ''}
                  placement="top"
                >
                  <S.Pill color="purple">{props.pillText}</S.Pill>
                </Popover>
              ) : null}
            </>
          )}
        </S.TitleContainer>

        <S.HRule />

        <S.FeatureList>
          {props.content.map((c) => (
            <S.ListItem id={c.text}>
              <S.ListItemIcon bgColor={c.bgColor} color={c.color}>
                {c.type === 'feature' ? <RxCheck strokeWidth="0.5" /> : null}
                {c.type === 'upgrade' ? <RxArrowUp strokeWidth="0.5" /> : null}
                {c.type === 'downgrade' ? <RxArrowDown strokeWidth="0.5" /> : null}
                {c.type === 'redution' ? <RxDash strokeWidth="0.5" /> : null}
                {c.type === 'addition' ? <RxPlus strokeWidth="0.5" /> : null}
              </S.ListItemIcon>
              <S.ListItemContent>{c.text}</S.ListItemContent>
            </S.ListItem>
          ))}
        </S.FeatureList>
      </div>
      {renderCTAButton()}
    </S.Card>
  );
};

export default PackageCard;
