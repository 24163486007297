import React, { useCallback } from 'react';
import { Row, Col, Badge, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import * as S from './styles'; // Import your styles here
import { themeConfig } from '../../../../utils/theme';
import { BsClock } from 'react-icons/bs';
import { formatDistance } from '../../../../utils/date';

interface Props {
  className: string;
  classColor: string;
  timeLeft: number | null;
  assignmentName: string;
  assignmentId: string;
  courseId: string;
  date?: string;
  timeWarning: boolean;
  icon: React.ReactElement;
  workType: string;
  description: string;
}

const Item: React.FC<Props> = ({
  className,
  assignmentName,
  assignmentId,
  courseId,
  classColor,
  icon,
  timeLeft,
  timeWarning,
  workType,
  description,
}) => {
  const history = useHistory();

  const goToDashboard = useCallback(() => {
    history.push(`/canvas-teacher-dashboard/class/${courseId}/assignment-summary/${assignmentId}`);
  }, [assignmentId, history, courseId]);

  const truncateDescription = (text: string | null | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text || '';
  };

  return (
    <S.Card style={{ marginTop: '8px', padding: '16px', borderColor: classColor }}>
      <Row align="middle">
        <Col span={3} style={{ textAlign: 'left' }}>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </Col>
        <Col span={21}>
          <S.Title $align="left" style={{ color: classColor }}>{className}</S.Title>
        </Col>
        <Col span={24} style={{ textAlign: 'left', marginTop: '8px' }}>
        <Badge
              count={<div style={{ textAlign: 'center' }}>{'Assignment'}</div>}
              style={{ backgroundColor: '#E9A643', borderRadius: '40px', color: '#fff', fontWeight: 500, fontSize: '12px', padding: '8px 16px', textAlign: 'center',display: 'inline-block' }}
            />
        </Col>
        <Col span={24} style={{ marginTop: '8px' }}>
          <S.AssignmentName>
            <span
              style={{
                fontWeight: 500,
                fontSize: '14px',
                color: '#333',
                margin: '0',
                display: 'block',
                textAlign: 'left',
                textTransform: 'capitalize',
              }}
            >
              {assignmentName}
            </span>
          </S.AssignmentName>
          <Tooltip title={<div style={{ whiteSpace: 'normal', maxWidth: '100%', overflow: 'auto', maxHeight: '300px' }} dangerouslySetInnerHTML={{ __html: description }} />}placement="bottom" overlayStyle={{width: '500px' }}>
            <S.Description dangerouslySetInnerHTML={{ __html: truncateDescription(description, 50) }}></S.Description>
          </Tooltip>
        </Col>
        {timeLeft && (
          <Col span={24} style={{ marginTop: '6px' }}>
            <Row align="middle">
              <BsClock style={{ minWidth: 18, minHeight: 18, marginRight: 8 }} />
              <S.TimeLeft $fontSize="0.8em" $warning={timeWarning}>
                {Date.now() - timeLeft > 0 ? 'Finished' : formatDistance(timeLeft)}
              </S.TimeLeft>
            </Row>
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: '0.5em' }} gutter={16} justify="center">
        <Col span={16} style={{ textAlign: 'center' }}>
          <S.DashboardButton
            text={'View Summary'}
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
