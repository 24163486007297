import React, { ChangeEvent, useState } from 'react';
import { Col, message, Row, Tooltip } from 'antd';
import Input from '../../../shared/Input';
import { FiDollarSign, FiMinusCircle, FiPlus } from 'react-icons/fi';
import * as S from './styles';
import Button from '../../../shared/Button';
import { themeConfig } from '../../../utils/theme';
import Spacer from '../../../shared/Spacer';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/store';
import { useAuth } from '../../../hooks/useAuth';
import { IBudgetCalculatorContentValue, GQL_ActivityMaterials } from '../../../types/investigation';
import { GQL_InvestigationSummaryResponse } from '../../../types/teacher';

interface IInvestigationBudgetCalculator {
  currentStepId?: string;
  values: IBudgetCalculatorContentValue[];
  disabled?: boolean;
  fullWidth?: boolean;
  userId?: string;
  investigationId?: string;
}

const InvestigationBudgetCalculator = (props: IInvestigationBudgetCalculator) => {
  const { currentStepId, values, disabled, fullWidth, investigationId, userId } = props;
  const { isStudent, user } = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';
  const currentActivityId = useSelector((state: IStore) => state.investigation.currentActivityId);
  const [materialList, setMaterialList] = useState<GQL_ActivityMaterials[]>(values[0]?.materials || []);

  const { data: investigationData } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId || '',
    },
    fetchPolicy: 'cache-only',
  });

  const studentList = investigationData?.getInvestigationProgressSummary?.perStudents || [];
  const student = studentList?.find((student) => student.userId === userId);
  const studentName = student ? `${student.firstName} ${student.lastName}` : undefined;

  const [submitActivityMaterials, { loading: loadingMaterialSubmission }] = useMutation<
    {},
    { activityId: string; stepId: string; materials: GQL_ActivityMaterials[] }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.submitActivityMaterials, {
    onError: (err) => message.error(err.message),
  });

  const handleSubmitMaterials = () => {
    if (currentActivityId && currentStepId && materialList.length) {
      submitActivityMaterials({
        variables: {
          activityId: currentActivityId,
          stepId: currentStepId,
          materials: materialList,
        },
      });
    }
  };

  const handleMaterialNameChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newMaterialList = [...materialList];
    newMaterialList[index].name = event.target.value;
    setMaterialList(newMaterialList);
  };

  const handleMaterialAmountChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newMaterialList = [...materialList];
    newMaterialList[index].amount = Number(event.target.value);
    setMaterialList(newMaterialList);
  };

  const handleMaterialPriceChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newMaterialList = [...materialList];
    newMaterialList[index].price = Number(event.target.value);
    setMaterialList(newMaterialList);
  };

  const handleAddMaterial = () => {
    setMaterialList([...materialList, {}]);
  };

  const handleRemoveMaterial = (index: number) => {
    setMaterialList([...materialList.slice(0, index), ...materialList.slice(index + 1)]);
  };

  const editable = (isStudent || isGoogleStudent || isCanvasStudent)  && !values[0]?.materials?.length && !disabled;

  return (
    <>
      {studentName && (
        <Row align="bottom">
          <Spacer size={60} />
          <h2>{studentName}</h2>
        </Row>
      )}
      <Col span={24}>
        <Row justify="center" align="middle" gutter={[24, 16]}>
          <Col md={fullWidth ? 12 : 10} xxl={fullWidth ? 16 : 13}>
            <S.TitleInput>Material Name</S.TitleInput>
          </Col>
          <Col md={6} xxl={fullWidth ? 4 : 3}>
            <S.TitleInput>Amount</S.TitleInput>
          </Col>
          <Col md={6} xxl={fullWidth ? 4 : 3}>
            <S.TitleInput>Price</S.TitleInput>
          </Col>
          <Col md={fullWidth ? 0 : 2} xxl={fullWidth ? 0 : 1}></Col>
        </Row>
        <Row justify="center" align="middle" gutter={[24, 16]}>
          {materialList.map((material, i) => (
            <React.Fragment key={i}>
              <Col md={fullWidth ? 12 : 10} xxl={fullWidth ? 16 : 13}>
                <Input
                  placeholder="Material Name"
                  value={material.name}
                  backgroundColor="white"
                  onChange={(e) => handleMaterialNameChange(e, i)}
                  disabled={!editable}
                />
              </Col>
              <Col md={6} xxl={fullWidth ? 4 : 3}>
                <Input
                  placeholder="Material Amount"
                  value={material.amount}
                  type="number"
                  backgroundColor="white"
                  min={1}
                  onChange={(e) => handleMaterialAmountChange(e, i)}
                  disabled={!editable}
                />
              </Col>
              <Col md={6} xxl={fullWidth ? 4 : 3}>
                <Input
                  placeholder="Material Price"
                  value={material.price?.toString()}
                  backgroundColor="white"
                  type="number"
                  min={0}
                  icon={<FiDollarSign color="#767676" />}
                  onChange={(e) => handleMaterialPriceChange(e, i)}
                  disabled={!editable}
                />
              </Col>
              <Col md={fullWidth ? 0 : 2} xxl={fullWidth ? 0 : 1}>
                {editable && (
                  <Tooltip title="Remove material">
                    <FiMinusCircle
                      onClick={() => handleRemoveMaterial(i)}
                      style={{ position: 'relative', top: 2, cursor: 'pointer' }}
                    />
                  </Tooltip>
                )}
              </Col>
            </React.Fragment>
          ))}
        </Row>
        <Spacer />
        <Row justify="center">
          <Col md={24} xxl={fullWidth ? 24 : 20}>
            <Row justify="space-between" align="bottom" gutter={[24, 0]}>
              <Col md={8} xxl={6} style={{ display: 'flex' }}>
                {editable && (
                  <>
                    <Button
                      text="Add Material"
                      onClick={handleAddMaterial}
                      minHeight={40}
                      theme={themeConfig.noColor}
                      icon={<FiPlus />}
                    />
                    <Spacer axis="horizontal" />
                    <Button
                      text="Submit Materials"
                      onClick={handleSubmitMaterials}
                      loading={loadingMaterialSubmission}
                      minHeight={40}
                      theme={themeConfig.secondaryColor}
                      disabled={
                        !materialList.length ||
                        materialList.some(
                          (m) => typeof m.amount === 'undefined' || typeof m.price === 'undefined' || !m.name,
                        )
                      }
                    />
                  </>
                )}
              </Col>
              <Col md={8} lg={8} xxl={6}>
                <Row gutter={[24, 16]} justify="end">
                  <Col md={18} xxl={18}>
                    <S.TitleInput alignRight>Total Cost</S.TitleInput>
                    <Input
                      placeholder="Total Cost"
                      value={materialList.reduce((acc, curr) => acc + (curr.amount ?? 0) * (curr.price ?? 0), 0)}
                      backgroundColor="white"
                      disabled
                      alignRight
                      icon={<FiDollarSign color="#767676" />}
                    />
                  </Col>
                  <Col md={fullWidth ? 0 : 6} xxl={fullWidth ? 0 : 6} />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default InvestigationBudgetCalculator;
