import styled from 'styled-components';
import { Radio } from 'antd';

export const RadioButton = styled(Radio)``;

export const RadioContainer = styled.div<{ showTargetAnswer: boolean; isTargetAnswer: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${({ showTargetAnswer, isTargetAnswer }) => {
    return `background-color: ${
      showTargetAnswer && isTargetAnswer
        ? 'rgba(32, 188, 137, 0.1)'
        : showTargetAnswer && !isTargetAnswer
        ? 'rgba(234, 53, 53, 0.1)'
        : '#fff'
    };
        border: ${
          showTargetAnswer && isTargetAnswer
            ? '1px solid rgba(32, 188, 137, 1)'
            : showTargetAnswer && !isTargetAnswer
            ? '1px solid rgba(234, 53, 53, 0.1)'
            : '1px solid #dee2e6'
        };
        border-radius: 0.25rem;
        padding: 0.75rem 1.25rem;
        margin-bottom: 0.5rem;`;
  }}

  > ${RadioButton} {
    display: flex;

    ${({ showTargetAnswer, isTargetAnswer }) => {
      return `
        .ant-radio-checked .ant-radio-inner, .ant-radio-disabled .ant-radio-inner {
          border-color: ${
            showTargetAnswer && isTargetAnswer
              ? 'rgba(32, 188, 137, 1) !important'
              : showTargetAnswer && !isTargetAnswer
              ? 'rgba(234, 53, 53, 1) !important'
              : '#1890ff !important'
          };
          
          &:after {
            background-color: ${
              showTargetAnswer && isTargetAnswer
                ? 'rgba(32, 188, 137, 1)'
                : showTargetAnswer && !isTargetAnswer
                ? 'rgba(234, 53, 53, 1)'
                : '#1890ff'
            }
          }
        }
      }`;
    }}
`;

export const QuestionBlock = styled.p`
  text-align: left;
  align-self: flex-start;
  font-weight: 800 !important;
  padding: 12px 0;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;
