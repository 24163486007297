import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, message } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import * as S from './styles';
import ClassesRow from './ClassesRow';
import AssignmentRow from './AssignmentRow';
import ActiveInvestigationsRow from './ActiveInvestigationsRow';
import ActiveAssessmentsRow from './ActiveAssessmentsRow';
import FinishedInvestigationsRow from './FinishedInvestigationsRow';
import StuckStudentModal from './StuckStudentModal';
import { gqlSchema } from '../../gql/schema';
import { GQL_InvestigationTeacherEntry } from '../../types/investigation';
import { finishedInvestigationFilter } from '../../utils/investigation';
import FinishedAssessmentsRow from './FinishedAssessmentsRow';
import { useAuth } from '../../hooks/useAuth';
import { useEnabledAssessments } from '../../hooks/useEnabledAssessments';
import { FeatureType } from '../../types/features';

const TeacherDashboard = () => {
  const location = useLocation<
    | { classId: string; activityId: string; studentId: string; notificationId: string; submissionVersion: number }
    | undefined
  >();

  // variable
  const classId = location.state?.classId;
  const activityId = location.state?.activityId;
  const studentId = location.state?.studentId;
  const notificationId = location.state?.notificationId;
  const submissionVersion = location.state?.submissionVersion || 1;
  const [classesLoaded, setClassesLoaded] = useState(false);
  const [assignmentsLoaded, setAssignmentsLoaded] = useState(false);

  const { isSubscriptionUser, isTASubscriptionUser } = useAuth();
  const { featureList } = useEnabledAssessments();
  const [controller, setController] = useState<AbortController | null>(null);
  
  useEffect(() => {
    const abortController = new AbortController();
    setController(abortController);
    return () => {
      abortController.abort(); 
    };
  }, []);
  // queries
  const [getInvestigationsByTeacher, { loading, data }] = useLazyQuery<{
    getInvestigationsByTeacher: GQL_InvestigationTeacherEntry[];
  }>(gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationsByTeacher, {
    onError: (error) => {
      message.error(
        error.message ||
          'There was an error loading your active investigations, check you connection and try again later.',
      );
    },
    context: {
      fetchOptions: {
        signal: controller?.signal,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const shouldLoad = classesLoaded && (isSubscriptionUser ? classesLoaded : true);

    if (shouldLoad) {
      getInvestigationsByTeacher();
    }
  }, [assignmentsLoaded, classesLoaded, isSubscriptionUser, getInvestigationsByTeacher]);

  const activeInvestigations = useMemo(() => {
    return data?.getInvestigationsByTeacher
      ?.filter((investigation) => !investigation.isAssessment)
      ?.filter((i) => !finishedInvestigationFilter(i));
  }, [data]);

  const activeAssessments = useMemo(() => {
    return data?.getInvestigationsByTeacher
      ?.filter((investigation) => investigation.isAssessment)
      ?.filter((i) => !finishedInvestigationFilter(i));
  }, [data]);

  const finishedInvestigations = useMemo(() => {
    return data?.getInvestigationsByTeacher
      ?.filter((investigation) => !investigation.isAssessment)
      ?.filter(finishedInvestigationFilter);
  }, [data]);

  const finishedAssessments = useMemo(() => {
    return data?.getInvestigationsByTeacher
      ?.filter((investigation) => investigation.isAssessment)
      ?.filter(finishedInvestigationFilter);
  }, [data]);

  const showAssessment = useMemo(() => {
    if (!isSubscriptionUser && !isTASubscriptionUser) {
      return true;
    }

    return featureList.includes(FeatureType.ASSESSMENT);
  }, [featureList, isSubscriptionUser, isTASubscriptionUser]);

  const showInvestigation = useMemo(() => {
    if (!isSubscriptionUser) {
      return true;
    }

    return featureList.includes(FeatureType.INVESTIGATION);
  }, [featureList, isSubscriptionUser]);

  return (
    <S.Container padding={isSubscriptionUser ? 2 : 4}>
      <Row gutter={[24, 24]}>
        <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24}>
          {classId && activityId && studentId && (
            <StuckStudentModal
              classId={classId}
              activityId={activityId}
              studentId={studentId}
              notificationId={notificationId || ''}
              submissionVersion={submissionVersion}
              controller={controller}
            />
          )}
          {isSubscriptionUser && <AssignmentRow loaded={() => setAssignmentsLoaded(true)} controller={controller}/>}
          <ClassesRow loaded={() => setClassesLoaded(true)} controller={controller} />
          {showInvestigation && <ActiveInvestigationsRow loading={loading} investigations={activeInvestigations}  />}
          {showAssessment && <ActiveAssessmentsRow loading={loading} assessments={activeAssessments} />}
          {showInvestigation && <FinishedInvestigationsRow investigations={finishedInvestigations} loading={loading} />}
          {showAssessment && <FinishedAssessmentsRow assessments={finishedAssessments} loading={loading} />}
        </Col>
      </Row>
    </S.Container>
  );
};

export default TeacherDashboard;
