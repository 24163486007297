import { gql } from '@apollo/client';
import INVESTIGATION_FRAGMENTS from '../../Investigation/fragment/Investigation';

const STUDENT_QUERIES = {
  INVESTIGATION: {
    getStudentProgressSummary: gql`
      query GetStudentProgressSummary($studentId: String, $classId: String) {
        getStudentProgressSummary(studentId: $studentId, classId: $classId) {
          userId
          firstName
          lastName
          investigations {
            id
            investigationId
            title
            classId
            class
            classColorHex
            completion
            description
            dueDate
            startDate
            onGoing
            status
            discipline {
              ...DisciplineFragment
            }
            steps {
              id
              order
              name
              dueDate
              completed
              status
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getMyProgressSummary: gql`
      query GetMyProgressSummary {
        getMyProgressSummary {
          id
          title
          startDate
          dueDate
          completion
          isOverdue
          status
          isAssessment
          submissionVersion
          discipline {
            ...DisciplineFragment
          }
          steps {
            id
            order
            name
            dueDate
            completed
            status
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
  },
};

export default STUDENT_QUERIES;
