import React, { useMemo } from 'react';
import { Col, Row, Empty } from 'antd';
import Item from './Item';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg';

interface IActiveAssignments {
  assignments: any[];
  loading: boolean;
  courseData: any[];
}

const ActiveAssignments: React.FC<IActiveAssignments> = ({ assignments, loading, courseData }) => {
  const sortedAssignments = useMemo(() => {
    return assignments.slice().sort(() => 0);
  }, [assignments]);

  const activeData = useMemo(() => {
    return (
      <>
        {loading ? (
          <Row gutter={[24, 24]}>
            {[1, 2, 3].map((index) => (
              <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
                <DashboardLoadingCard />
              </Col>
            ))}
          </Row>
        ) : sortedAssignments.length ? (
          <Row gutter={[24, 24]}>
            {sortedAssignments.map((item: any) => {
              const courseName = item.courseName || 'Course Name';
              const classId = item.course_id || 'Class ID';

              return (
                <Col
                  xxl={8}
                  xl={8}
                  lg={12}
                  md={24}
                  sm={24}
                  xs={24}
                  key={item.id}
                >
                  <Item
                    description={item.description || 'Description'}
                    assignment={item}
                    timeWarning={false}
                    progress={0}
                    type="Assignment"
                    classId={classId}
                    courseName={courseName}
                    icon={<img src={AssignmentIcon} alt="Assignment Icon" />}
                    version={1}
                    courseWorkType={item.courseWorkType || 'Type'}
                  />
                </Col>
              );
            })}
          </Row>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Assignments" />
        )}
      </>
    );
  }, [loading, sortedAssignments]);

  return activeData;
};

export default ActiveAssignments;
