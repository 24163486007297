import { createReducer } from '../../utils/redux';

export const constants = {
  SET_CURRENT_MENU: 'SET_CURRENT_MENU',
};

// Action Creators
export const actions = {
  setCurrentMenu: (payload: string) => ({
    type: constants.SET_CURRENT_MENU,
    payload,
  }),
};

// Reducer
const initialState = {};

export interface IMenuStore {
  currentMenu?: string;
}

export default createReducer(initialState, (state: IMenuStore, action) => {
  switch (action.type) {
    case constants.SET_CURRENT_MENU:
      return { ...state, currentMenu: action.payload };
    default:
      return state;
  }
});
