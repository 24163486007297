import React, { useEffect, useMemo, useState } from 'react';
import { Table, message, Tabs } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { GQL_GoogleClassroomStudents, GQL_GoogleClassroomTeachers } from '../../../types/googleClassroom';
import * as S from './styles';
import AddStudents from './AddStudents';
import AddTeachers from './AddTeachers';

const { TabPane } = Tabs;

interface Props {
  classId: string;
  loading: boolean;
  onUpdateNumberOfStudents?: (count: number) => void;
  onUpdateNumberOfTeachers?: (count: number) => void;
}

const StudentsTable: React.FC<Props> = ({ classId, loading, onUpdateNumberOfStudents, onUpdateNumberOfTeachers }) => {
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  const [showAddTeachersModal, setShowAddTeachersModal] = useState(false);

  const [getClassStudents, { data: studentsData }] = useLazyQuery<{
    getCourseStudents: {
      students: GQL_GoogleClassroomStudents[];
    };
  }>(gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudents, {
    onError: (error) => {
      message.error(
        error.message ||
          'There was an error loading the students. Please check your connection and try again later.',
      );
    },
  });

  const [getCourseTeachers, { data: teachersData }] = useLazyQuery<{
    getCourseTeachers: {
      teachers: GQL_GoogleClassroomTeachers[];
    };
  }>(gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseTeachers, {
    onError: (error) => {
      message.error(
        error.message ||
          'There was an error loading the teachers. Please check your connection and try again later.',
      );
    },
  });

  useEffect(() => {
    getClassStudents({ variables: { token: 'your-token-here', courseId: classId } });
    getCourseTeachers({ variables: { token: 'your-token-here', courseId: classId } });
  }, [classId, getClassStudents, getCourseTeachers]);

  const studentColumns = useMemo(() => {
    return [
      {
        title: 'Student Name',
        dataIndex: ['profile', 'name', 'fullName'],
        key: 'name',
        render: (fullName: string) => fullName || '-',
      },
    ];
  }, []);

  const teacherColumns = useMemo(() => {
    return [
      {
        title: 'Teacher Name',
        dataIndex: ['profile', 'name', 'fullName'],
        key: 'name',
        render: (fullName: string) => fullName || '-',
      },
    ];
  }, []);

  const students = studentsData?.getCourseStudents?.students || [];
  const teachers = teachersData?.getCourseTeachers?.teachers || [];
  
  useEffect(() => {
    if (onUpdateNumberOfStudents) {
      onUpdateNumberOfStudents(students.length);
    }
    if (onUpdateNumberOfTeachers) {
      onUpdateNumberOfTeachers(teachers.length);
    }
  }, [students.length, teachers.length, onUpdateNumberOfStudents, onUpdateNumberOfTeachers]);

  return (
    <>
      <S.Title>Users</S.Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Students Details" key="1">
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
            {/* <Button type="primary" onClick={() => setShowAddStudentsModal(true)}>Add Students</Button> */}
          </div>
          <Table
            columns={studentColumns}
            loading={loading}
            dataSource={students.map(student => ({
              ...student,
              key: student
            }))}
            pagination={{
              hideOnSinglePage: false,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40'],
            }}
          />
        </TabPane>
        <TabPane tab="Teacher Details" key="2">
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
            {/* <Button type="primary" onClick={() => setShowAddTeachersModal(true)}>Add Teachers</Button> */}
          </div>
          <Table
            columns={teacherColumns}
            loading={loading}
            dataSource={teachers.map(teacher => ({
              ...teacher,
              key: teacher // Use profile.id as the unique key for each teacher
            }))}
            pagination={{
              hideOnSinglePage: false,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40'],
            }}
          />
        </TabPane>
      </Tabs>
      <AddStudents
        visible={showAddStudentsModal}
        setVisible={setShowAddStudentsModal}
        courseId={classId}
      />
      <AddTeachers
        visible={showAddTeachersModal}
        setVisible={setShowAddTeachersModal}
        courseId={classId}
      />
    </>
  );
};

export default StudentsTable;
