import React from 'react';
import { Form, Col, Row, Tooltip, Empty } from 'antd';
import Input from '../../Input';
import Button from '../../Button';
import { FiMinusCircle } from 'react-icons/fi';
import { AiOutlineExclamationCircle, AiOutlinePlus } from 'react-icons/ai';
import * as S from './styles';
import { themeConfig } from '../../../utils/theme';
import { UserDataProps } from '../../../types/user';
import TagInput from '../../TagInput';
import { TagInput as TagInputType } from '../../../types/tags';
import { startCase } from 'lodash';

interface Props {
  userType: string;
  onTagUpdate?: (tags: TagInputType[], userIndex: number) => void;
  onUserListUpdate?: () => void;
  currentUsers?: any;
}

const UsersData: React.FC<Props> = (props) => {
  const { userType, onTagUpdate, currentUsers, onUserListUpdate } = props;

  return (
    <Row style={{ height: '40vh', overflowX: 'hidden', overflowY: 'auto' }}>
      <Col span={24}>
        <Form.List
          name="invites"
          rules={[
            {
              validator: async (_, invites = []) => {
                const emails = invites.map((user: UserDataProps) => user?.email);
                let invalidaEmails = '';
                emails.forEach((userEmail: string) => {
                  const existEmail = emails.filter((email: string) => userEmail && email === userEmail);

                  if (existEmail.length > 1) invalidaEmails = `Email ${userEmail} is repeated`;
                });
                if (invalidaEmails) {
                  return Promise.reject(new Error(invalidaEmails));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.length > 0 && (
                <Row gutter={24}>
                  <Col span={5}>
                    <S.TitleInput>First Name</S.TitleInput>
                  </Col>
                  <Col span={5}>
                    <S.TitleInput>Last Name</S.TitleInput>
                  </Col>
                  <Col span={8}>
                    <S.TitleInput>
                      Email/Username{' '}
                      <Tooltip
                        title={`Users that does not have an email must be already registered in ADI in order to be invited to a class`}
                      >
                        <AiOutlineExclamationCircle style={{ position: 'sticky', top: 2, cursor: 'pointer' }} />
                      </Tooltip>
                    </S.TitleInput>
                  </Col>
                  <Col span={5}>
                    <S.TitleInput>Tags</S.TitleInput>
                  </Col>
                </Row>
              )}
              {fields.map((field, index) => (
                <Row
                  gutter={16}
                  key={`${index}${field.key}${currentUsers?.length}`}
                  data-cy="component-user-data-student-row"
                >
                  <Col span={5}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'firstName']}
                      fieldKey={[field.fieldKey, 'firstName']}
                      rules={[{ required: true, message: 'Missing first name' }]}
                    >
                      <Input data-cy="shared-inviteusers-userdata-firstName-input" placeholder="Type first name" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'lastName']}
                      fieldKey={[field.fieldKey, 'lastName']}
                      rules={[{ required: true, message: 'Missing last name' }]}
                    >
                      <Input
                        data-cy="shared-inviteusers-userdata-lastName-input"
                        placeholder="Type last name"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingRight: 4 }}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'email']}
                      fieldKey={[field.fieldKey, 'email']}
                      rules={[{ required: true, message: 'Missing email or username' }]}
                    >
                      <Input
                        data-cy="shared-inviteusers-userdata-email-input"
                        placeholder="Type the email or username"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item {...field} name={[field.name, 'tags']} fieldKey={[field.fieldKey, 'tags']}>
                      <TagInput
                        alwaysEditable
                        maxTagCount={0}
                        backgroundColor="#f8f8f8"
                        height={37}
                        existingUserTags={currentUsers?.[index]?.tags}
                        onChange={(tags) => onTagUpdate?.(tags, index)}
                      />
                    </Form.Item>
                  </Col>
                  {
                    <Col span={1} style={{ padding: 0 }}>
                      <Tooltip title={`Remove ${userType}`}>
                        <FiMinusCircle
                          data-cy="component-user-data-student-remove"
                          onClick={() => {
                            remove(field.name);
                            onUserListUpdate?.();
                          }}
                          style={{ position: 'relative', top: 12, cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </Col>
                  }
                </Row>
              ))}
              {!fields.length && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Click below to add ' + userType + 's'} />
              )}
              <Row>
                <Col span={24}>
                  <Form.ErrorList errors={errors} />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={7}>
                  <Button
                    onClick={() => {
                      add();
                      onUserListUpdate?.();
                    }}
                    block
                    theme={themeConfig.noColor}
                    icon={<AiOutlinePlus />}
                    data-cy="component-user-data-add-user-button"
                    text={fields?.length ? 'Add More' : 'Add ' + startCase(userType)}
                  />
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};

export default React.memo(UsersData);
