import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import Input from '../../shared/Input';
import CButton from '../../shared/Button';

export const Container = styled.div`
  /* height: 100vh; */
`;

export const Title = styled.h1`
  text-align: center;
  margin: 20px;
  font-weight: 800;
  font-size: 2em;
  @media (max-width: 1200px) {
    font-size: 1.8em;
  }
`;

export const TitleInput = styled.p`
  font-weight: 600;
  font-size: 0.85em;
  margin: 0;
`;

export const RowSP = styled(Row)`
  height: 100%;
`;

export const SInput = styled(Input)`
  border-radius: 5px;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.85) !important;

  input {
    background-color: #fff !important;
  }
`;

export const LinkButton = styled(Button)`
  color: #444444;
  cursor: pointer;
  font-weight: 500;
  padding: 0;
  span {
    text-decoration: underline;
  }
  &:hover,
  &:active {
    color: #585858;
  }
`;

export const ColSP = styled(Col)`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  background: #ffffff;
  border-radius: 5px;
  flex-direction: column;
  padding: 20px 80px;
  padding-top: 40px;
  align-items: stretch;
  display: flex;

  @media (max-width: 992px) {
    padding: 40px;
    height: 100%;
  }
`;

export const CreateButton = styled(CButton)`
  font-weight: 500;
`;
