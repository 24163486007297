import { gql } from '@apollo/client';

export const Shopify_QUERIES = {
  LIST: {
    getAllOrders: gql`
      query GetAllOrders($page: Int!) {
        getAllOrders(page: $page) {
          order {
            id
            shopifyOrderId
            userId
            totalAmount
            totalItems
            fulfillmentStatus
            paymentStatus
            channel
            createdAt
            orderItems {
              id
              contactEmail
              orderId
              productId
              quantity
              price
              product {
                id
                shopifyProductId
                title
                description
                price
                status
                media
                category
                type
                barcode
                createdAt
                updatedAt
              }
              order {
                id
                shopifyOrderId
                shippingAddress
                userId
                totalAmount
                totalItems
                fulfillmentStatus
                paymentStatus
                channel
                createdAt
              }
            }
          }
          total
        }
      }
    `,
    getOrderById: gql`
      query GetOrderById($id: String!) {
        getOrderById(id: $id) {
        id
        shopifyOrderId
        orderId
        status
        statusNote
        shippingAddress
        fulfillBy
        contactEmail
        contactPhone
        subTotal
        shippingFees
        total
        items {
            name
            quantity
            price
            imageUrl
        }
    }
      }
    `,
    getQuoteData: gql`
      query GetQuoteData {
        getQuoteData {
          teacherName
          email
          reference
          createdDate
          expiresDate
          shippingAddress
          subtotal
          shipping
          feePercent
          total
          items {
            description
            sku
            quantity
            unitPrice
            title
          }
        }
      }
    `,
    getUserCart: gql`
      query GetUserCart {
        getUserCart {
          id
          userId
          cartItem {
            orderId
            productId
            shopifyId
            title
            sku
            quantity
            unitPrice
          }
        }
      }
    `,
    FetchKitsRecommendations: gql`
      query FetchKitsRecommendations($bookId: String!, $page: Int!) {
        fetchKitsRecommendations(id: $bookId, page: $page) {
          totalkits
          kitResponse {
            id
            title
            description
            price
            imageUrl
          }
        }
      }
    `,
    GetShopifyProductByHandle : gql`
    query GetShopifyProductByHandle($handle: String!) {
        getShopifyProductByHandle(handle: $handle) {
            id
            shopifyProductId
            title
            description
            price
            status
            media
            category
            type
            barcode
            createdAt
            updatedAt
        }
    }
`,

    getAllQuote: gql`
      query GetAllQuotes($page:Int) {
        getAllQuotes(page:$page) {
          total
          quotesAll {
            id
            status
            createdAt
            receiptUrl
            totalPrice
            quoteNumber
          }
        }
      }
    `,

    getUserMetaData: gql`
      query GetUserMetaData {
        getUserMetaData {
          city
          state
          country
          zipCode
          jobDescription
          email
          name
        }
      }
    `,
  },
};

export default Shopify_QUERIES;
