import React from 'react';
import Input from '../../../shared/Input';
import { Button, Col, Row } from 'antd';
import CustomButton from '../../../shared/Button';

interface IInvestigationDetailsReviewSearch {
  handleSearch: (text: string) => void;
}

const InvestigationDetailsReviewSearch: React.FC<IInvestigationDetailsReviewSearch> = (props) => {
  const [value, setInputValue] = React.useState('');

  const handleSubmit = (clearSearch?: boolean) => {
    if (!clearSearch && value !== undefined && value !== null && value.length >= 0) {
      props.handleSearch(value);
    } else {
      props.handleSearch('');
    }
  };

  const clearResults = () => {
    setInputValue('');
    handleSubmit(true);
  };

  const submit = () => handleSubmit(false);

  return (
    <Row style={{ padding: '32px' }} gutter={[32, 16]}>
      <Col span="18">
        <Input
          onPressEnter={submit}
          onChange={(e) => setInputValue(e.target.value)}
          value={value}
          placeholder="Search within investigation"
          name="searchText"
        />
      </Col>
      <Col span="6">
        <Row>
          <Button style={{ width: '48%', height: 36, marginRight: 8 }} onClick={clearResults} type="default">
            Clear
          </Button>

          <CustomButton style={{ width: '48%' }} type="primary" text="Search" onClick={submit} />
        </Row>
      </Col>
    </Row>
  );
};

export default InvestigationDetailsReviewSearch;
