import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { constants as errorConstants } from './modules/errors';
import { constants as loadingsConstants } from './modules/loadings';
import history from '../history';
import reducers from './modules';
import rootSaga from './sagas';
import { IInvestigationStore } from './modules/investigation';
import { INotificationStore } from './modules/notifications';
import { IPeerCommentStore } from './modules/peerComments';
import { IMenuStore } from './modules/menu';

export interface IStore {
  errors: any;
  loadings: any;
  investigation: IInvestigationStore;
  notifications: INotificationStore;
  peerComments: IPeerCommentStore;
  menu: IMenuStore;
}

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Declares Redux Devtools type for TS
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Initialize the Redux set up
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  combineReducers({ ...reducers }),
  loadState(),
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

// Run the saga now
sagaMiddleware.run(rootSaga);

history.listen((location, action) => {
  if (action === 'PUSH') {
    store.dispatch({ type: errorConstants.ERRORS_CLEANUP });
    store.dispatch({ type: loadingsConstants.LOADINGS_CLEANUP });
  }
});

export default store;
