import { gql } from '@apollo/client';

export const CHAT = {
  newChatListResponse: gql`
    fragment NewChatListResponse on ChatListResponse {
      chatId
      userId
      userName
      senderName
      message
      sent
      avatar
    }
  `,
  updateReadMessage: gql`
    fragment UpdateReadMessage on MessagesListResponse {
      read
    }
  `,
};

export default CHAT;
