import React from 'react';
import { GQL_InvestigationBlock, IProcessDragDropAnswer } from '../../../../types/investigation';

import AssessmentCategoryBoard from '../../../StudentInvestigation/AssessmentCategoryBoard';

interface IMultipleChoiceQuestionPreview {
  block: GQL_InvestigationBlock;
  isImage?: boolean;
}

const CategoryDragDropQuestionPreview = (props: IMultipleChoiceQuestionPreview) => {
  const { block, isImage } = props;

  return (
    <>
      {block.values.map((value, i) => (
        <AssessmentCategoryBoard
          presentationMode={false}
          key={`category-drag-drop-preview-${i}`}
          value={value as IProcessDragDropAnswer}
          onBoardSorted={() => console.log('board sorted')}
          currentStepId={''}
          investigationId={''}
          activityId={''}
          userId={block.userId}
          isLocked={false}
          isImageVersion={isImage}
        />
      ))}
    </>
  );
};

export default CategoryDragDropQuestionPreview;
