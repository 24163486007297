import styled from 'styled-components';
import { DatePicker as Picker, Menu } from 'antd';
import { BsDot } from 'react-icons/bs';

export const Title = styled.h3`
  font-size: 1.4em;
  display: inline;
`;

export const SliderWrapper = styled.div`
  aside{
    height: 100% !important;
  }
`;

export const DatePicker = styled(Picker)<{
  $backgroundColor?: string;
}>`
  border-radius: 6px;
  background: ${(props) => props.$backgroundColor || '#f8f8f8'};

  input {
    background: ${(props) => props.$backgroundColor || '#f8f8f8'};
    padding: 4px 11px 4px;
    cursor: pointer;
  }
`;
export const FilterContainer = styled.div`
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  max-width: fit-content;
  color: #3f3e38;

  & > span {
    padding-left: 10px;
    padding-top: 2px;
    margin-right: 8px;
    font-size: 15px;
    font-weight: normal;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  & > svg {
    min-width: 18px;
    min-height: 18px;
    @media only screen and (max-width: 1200px) {
      margin-right: 8px;
    }
  }
`;

export const FilterChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7b8cea;
  color: white;
  border-radius: 6px;
  padding: 2px 8px;
  margin-right: 8px;
  max-width: 100%;

  svg {
    margin-left: 8px;
    min-width: 16px;
    min-height: 16px;
  }

  span {
    font-size: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: calc(100% - 160px); */
      height: unset;
    max-height: 75vh;
   overflow: scroll;
    position: fixed;
    width: 100%;
    left: 30px;
    max-width: 320px;
    top: 140px;

  .ant-select-selector {
    font-size: 14px;
    color: #3f3e38;
    font-weight: 500;
  }
`;

export const MItem = styled(Menu.Item)<{
  $disabled: boolean;
}>`
  color: ${(props) => (props.$disabled ? props.theme.primaryOutlined.color : props.theme.success.color)} !important;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.2;
  & > span {
    position: absolute;
    left: 55px;
  }
  &:after {
    border-right: none !important;
  }
  background-color: ${(props) => (!props.$disabled ? '#e6f7ff' : '#ffffff')} !important;
  border-radius: 50px 50px 50px 50px;
`;

export const Dot = styled(BsDot)`
  font-size: 26px !important;
  margin: 0;
  position: absolute;
  bottom: 8px;
  left: 10px;

  & > path {
    font-size: 26px;
  }
`;

export const SidebarTitle = styled.h2`
  font-size: 1.25em;
  color: ${(props) => props.theme.primaryOutlined.color};
  font-weight: bold;
  position:fixed;
  top:90px;
`;
