import type { Identifier } from 'dnd-core';
import type { FC } from 'react';
import React from 'react';
import { useDrop } from 'react-dnd';

import { ItemTypes } from '../types';
import * as S from '../styles';

export interface IDroppableContainerProps {
  index: number;
  title?: string;
  onDropElement: (item: DragItem, targetIndex: number) => void;
  showSuccess: boolean;
  isCorrectAnswer: boolean;
  children: React.ReactNode;
}

export interface DragItem {
  index: number;
  id: number;
  type: string;
  text: string;
  isAnswer: boolean;
}

const DroppableContainer: FC<IDroppableContainerProps> = ({
  index,
  title,
  onDropElement,
  showSuccess,
  isCorrectAnswer,
  children,
}) => {
  const [{ handlerId, isOver }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null; isOver: boolean }>({
    accept: ItemTypes.CARD,
    drop: (item) => onDropElement(item, index),
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
  });

  return (
    <S.TargetColumn
      ref={drop}
      data-handler-id={handlerId}
      isOver={isOver}
      showSuccess={showSuccess}
      isCorrectAnswer={isCorrectAnswer}
    >
      <S.DragTitle>{title}</S.DragTitle>
      <div className={'cards'}>{children}</div>
    </S.TargetColumn>
  );
};

export default DroppableContainer;
