import React, { useEffect, useState } from 'react';
import * as S from './styles';
import InvestigationCard from '../InvestigationCard';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

const BookInvestigationPage: React.FC = () => {
  const history = useHistory();
  const { bookId } = useParams<{ bookId: string }>();
  const [activeCard, setActiveCard] = useState<null | number>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [wishlistData, setWishlistData] = useState<any>(null);


  const { data: wishlist,refetch } = useQuery(gqlSchema.BookSchema.queries.FetchWishlist, {
    variables: {
      page: 1,
      limit: 20, 
    },
    onError: (error) => {
      message.error(`Error in fetching wishlist details - ${error.message || 'Unexpected Error'}`);
    },
  });
  const [removeFromWishlist] = useMutation(gqlSchema.BookSchema.mutations.RemoveWishlist, {
    onCompleted: () => {
      message.success('Investigation removing to wishlist');
      refetch()
    },
    onError: (error) => {
      message.error(`Error adding to wishlist - ${error.message || 'Unexpected Error'}`);
    },
  });

  useEffect(() => {
    if (wishlist) {
      setWishlistData(wishlist?.getWishlist?.wishlist?.map((i:any)=>i.investigationId));
    }
  }, [wishlist]);

  const { data } = useQuery(gqlSchema.BookSchema.queries.FetchBookById, {
    variables: {
      id: bookId,
    },
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });

  const [addToWishlist] = useMutation(gqlSchema.BookSchema.mutations.AddWishlist, {
    onCompleted: () => {
      message.success('Investigation added to wishlist');
      refetch()
    },
    onError: (error) => {
      message.error(`Error adding to wishlist - ${error.message || 'Unexpected Error'}`);
    },
  });

  const handleAddToWishlist = (investigationId: string) => {
    addToWishlist({ variables: { investigationId } });
  };
  const handleRemoveFromWishlist = (investigationId: string) => {
    removeFromWishlist({ variables: { investigationId } });
  };
  const bookData = data?.fetchBookById;

  const filteredInvestigations = bookData?.investigations?.filter((investigation: any) =>
    investigation.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <S.PageContainer>
       <FiArrowLeft
              onClick={() => history.goBack()}
              style={{ cursor: 'pointer', color: 'black', fontSize: '24px', margin: '10px' }}
            />
      <S.Header>
        <S.SearchInput
          placeholder="Search investigation"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <S.FavoriteButton onClick={() => history.push('/bookWishList')} type="primary">My Favorite</S.FavoriteButton>
      </S.Header>

      <S.CardGrid className={activeCard !== null ? 'fullview' : ''}>
        {filteredInvestigations?.map((investigation: any) => {
          return activeCard ? (
            activeCard === investigation.id && (
              <InvestigationCard
                key={investigation.id}
                isActive={activeCard === investigation.id}
                setFullScreen={() => setActiveCard(investigation.id)}
                closeFullScreen={() => setActiveCard(null)}
                data={investigation}
                addToWishlist={() => handleAddToWishlist(investigation.id)}
                handleRemoveFromWishlist={() => handleRemoveFromWishlist(investigation.id)}
                isFavorite={wishlistData?.includes(investigation.id)}
              />
            )
          ) : (
            <InvestigationCard
              key={investigation.id}
              isActive={activeCard === investigation.id}
              setFullScreen={() => setActiveCard(investigation.id)}
              closeFullScreen={() => setActiveCard(null)}
              data={investigation}
              addToWishlist={() => handleAddToWishlist(investigation.id)}
              handleRemoveFromWishlist={() => handleRemoveFromWishlist(investigation.id)}
              isFavorite={wishlistData?.includes(investigation.id)}
            />
          );
        })}
      </S.CardGrid>
    </S.PageContainer>
  );
};

export default BookInvestigationPage;
