import { Col, Divider } from 'antd';
import styled from 'styled-components';
import Input from '../../shared/Input';

export const Line = styled(Col)`
  .ant-form-item {
    margin: 0;
  }
`;
export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 5px;
  min-height: 120px;
`;
export const CDivider = styled(Divider)`
  margin: 30px 0 0 0;
`;

export const TitleInput = styled.div<{
  $padding?: string;
}>`
  font-weight: 600;
  padding: ${(props) => props.$padding || '0 11px'};
  font-size: 1.1em;
  margin: 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  align-items: center;
  .ant-progress {
    flex: 1;
    margin-left: 16px;
  }
`;

export const SInput = styled(Input)<{
  $bold?: boolean;
}>`
  font-size: 0.95em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  font-weight: ${(props) => (props.$bold ? '500' : 'unset')};
  .ant-input-suffix {
    display: none;
  }
`;

export const Skeleton = styled.div`
  margin: 0.1em 0;
  height: calc(100vh - 80px - 8em);

  .ant-skeleton-input {
    width: 250px;
    margin-bottom: 16px;
    border-radius: 4px;
  }

  .ant-skeleton-input-sm {
    width: 150px;
  }

  .ant-skeleton-input-lg {
    width: 200px;
    margin-top: 16px;
  }

  .ant-skeleton-button {
    margin: 0 32px;
    width: 128px;
    height: 40px;
  }

  .ant-skeleton-image {
    display: inline-flex;
  }
`;
