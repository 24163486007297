import { Modal } from 'antd';
import styled from 'styled-components';
import Select from '../Select';

export const TransferModalSelect = styled(Select)`
  left: 11px !important;
  background: red !important;
`;

export const TransferModal = styled(Modal)`
  width: 90% !important;
  max-width: 800px;
  .ant-modal-body {
    margin: auto;
    width: 70%;
    padding-bottom: 80px;
    padding-top: 64px;
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      margin-bottom: 16px;
    }
    h2 {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 125.1%;
      width: 240px;
      margin: auto;
      color: #767676;
      margin-bottom: 40px;
    }
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 125.1%;
      color: #767676;
    }
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  margin-bottom: 5px;
`;

export const Info = styled.p`
  font-weight: 400;
  margin: 0;
  text-align: center;
`;
