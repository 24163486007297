import { Col, message, Row, TablePaginationConfig, Tabs, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import {
  GQL_InvestigationStagesCompletionChartInput,
  GQL_InvestigationStageCompletionChartResponse,
  GQL_InvestigationStageCompletion,
  GQL_InvestigationStageCompletionPerTeacher,
} from '../../../types/charts';
import { Duration, FilterData } from '../../../types/investigation';
import Filter from '../Filter';
import { FiHelpCircle } from 'react-icons/fi';
import { formatDateTime } from '../../../utils/date';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import DetailTeachers from './DetailTeachers';
import Button from '../../Button';
import { downloadCsv } from '../../../utils/files';
import Papa from 'papaparse';
import { startCase } from 'lodash';
import DetailStages from './DetailStages';
import ChartStages from './ChartStages';

const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};
const { TabPane } = Tabs;

const StageCompletion = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const [period, setPeriod] = useState<Duration>(defaultDateRange);
  const [activeKey, setActiveKey] = useState('chart');
  const [filterData, setFilterData] = useState<FilterData>({ organizations: false, teachers: false });

  const [getInvestigationStageCompletionChart, { data, loading }] = useLazyQuery<
    { getInvestigationStageCompletionChart: GQL_InvestigationStageCompletionChartResponse },
    { data: GQL_InvestigationStagesCompletionChartInput }
  >(gqlSchema.ChartSchema.queries.CHARTS.getInvestigationStageCompletionChart, {
    onError: (err) => {
      message.error('There was an error loading investigation assignment rate: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    const { organizations, teachers, ...data } = filterData;
    getInvestigationStageCompletionChart({
      variables: {
        data: {
          startDate: period.startDate,
          endDate: period.endDate,
          tagIds: data.tagIds,
          disciplineIds: data.disciplineIds,
        },
      },
    });
  }, [getInvestigationStageCompletionChart, filterData, period]);

  const onChangeFilterData = useCallback((data: FilterData) => {
    setFilterData(data);
  }, []);

  const downloadTeacherDetails = useCallback(() => {
    const stages =
      data?.getInvestigationStageCompletionChart?.perTeacher?.reduce(
        (ret: string[], cur: GQL_InvestigationStageCompletionPerTeacher) => {
          cur.stages.forEach((st) => {
            if (!ret.some((d) => d === st.name || !st.name)) ret.push(st.name);
          });
          return ret;
        },
        [],
      ) || [];

    const headers = [['First Name', 'Last Name', 'Email', 'Tags', ...stages]];
    const csvData =
      data?.getInvestigationStageCompletionChart?.perTeacher?.map((row: GQL_InvestigationStageCompletionPerTeacher) => {
        return [
          row.teacherFirstName,
          row.teacherLastName,
          row.teacherEmail,
          row.tags.map((t) => `${t.tag}`),
          ...stages.map((st) => {
            const stage = row.stages.find((t) => t.name === st);
            if (stage) return `${((stage?.completedUsers * 100) / stage?.totalUsers).toFixed(2) || 0} %`;
            return '0 %';
          }),
        ];
      }) || [];
    downloadCsv(Papa.unparse([...headers, ...csvData]), `Completed Stages (By Teachers).csv`);
  }, [data]);

  const downloadStagesDetails = useCallback(() => {
    const headers = [['Order', 'Stage', 'Total Students', 'Total Students Completed', 'Completion Average']];
    const csvData =
      data?.getInvestigationStageCompletionChart?.perStage
        ?.filter((d) => d.order > 0)
        .map((row: GQL_InvestigationStageCompletion) => {
          return [
            row.order,
            row.name,
            row.totalUsers || 0,
            row.completedUsers,
            `${((row.completedUsers * 100) / row.totalUsers).toFixed(2)} %`,
          ];
        }) || [];
    downloadCsv(Papa.unparse([...headers, ...csvData]), `Completed Stages (By Tags).csv`);
  }, [data]);

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Stage Completion Rate</S.Title>
          <Tooltip title="Stages were completed across the assigned investigations by teacher from the selected date range.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Filter
            showGradeFilter={false}
            showSubjectFilter={false}
            showDateRangeFilter={true}
            showDisciplineFilter={true}
            filterUsers={false}
            onChangeFilterData={onChangeFilterData}
            setDaterange={setPeriod}
          />
        </Col>
        <Col span={24}>
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={(activeKey: string) => setActiveKey(activeKey)}
            tabBarExtraContent={
              <Button
                text={`Download ${startCase(activeKey)} Details`}
                block
                disabled={
                  activeKey === 'teachers'
                    ? (data?.getInvestigationStageCompletionChart?.perTeacher?.length || 0) === 0
                    : (data?.getInvestigationStageCompletionChart?.perStage?.length || 0) === 0
                }
                onClick={activeKey === 'teachers' ? downloadTeacherDetails : downloadStagesDetails}
              />
            }
          >
            <TabPane tab={<S.TitleTab> Chart</S.TitleTab>} key="chart">
              <ChartStages loading={loading} data={data?.getInvestigationStageCompletionChart?.perStage || []} />
            </TabPane>
            <TabPane tab={<S.TitleTab>Stages Details</S.TitleTab>} key="stages">
              <DetailStages
                loading={loading}
                pagination={{ ...pagination, hideOnSinglePage: true }}
                setPagination={setPagination}
                data={data?.getInvestigationStageCompletionChart?.perStage || []}
              />
            </TabPane>
            <TabPane tab={<S.TitleTab>Teachers Details</S.TitleTab>} key="teachers">
              <DetailTeachers
                loading={loading}
                pagination={{ ...pagination, hideOnSinglePage: true }}
                setPagination={setPagination}
                data={data?.getInvestigationStageCompletionChart?.perTeacher || []}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default StageCompletion;
