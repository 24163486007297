import React, { useCallback, useState } from 'react';
import { Row } from 'antd';
import { FiCheck, FiChevronDown, FiChevronUp, FiX } from 'react-icons/fi';

import * as S from './styles';

interface IExpandableCard {
  title: React.ReactNode;
  description: React.ReactNode;
  completed?: boolean;
  onClick?: VoidFunction;
  expandable?: boolean;
  expandComponent?: React.ReactNode;
  shrinkComponent?: React.ReactNode;
  defaultExpanded?: boolean;
  disabled?: boolean;
  'data-cy'?: string;
}

const ExpandableCard: React.FC<IExpandableCard> = (props) => {
  const {
    completed,
    onClick,
    title,
    description,
    expandable,
    expandComponent,
    shrinkComponent,
    disabled,
    defaultExpanded = false,
  } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [completedPrev, setCompletedPrev] = useState(completed);
  const hasAction = !!onClick || expandable;

  const getRightComponent = () => {
    if (completed) {
      return (
        <S.IconContainer completed={completed}>
          <FiCheck size={16} />
        </S.IconContainer>
      );
    } else if (expandable && !expanded) {
      return expandComponent || <FiChevronDown size={16} />;
    } else if (expandable && expanded) {
      return shrinkComponent || <FiChevronUp size={16} />;
    } else {
      return (
        <S.IconContainer completed={completed}>
          <FiX size={16} />
        </S.IconContainer>
      );
    }
  };

  const handleContainerClick = useCallback(() => {
    if (expandable && !disabled) setExpanded(!expanded);
    else if (onClick && !disabled) onClick();
  }, [expandable, expanded, onClick, disabled]);

  if (completed === true && completedPrev === false) {
    setExpanded(false);
  }

  if (completedPrev !== completed) setCompletedPrev(completed);

  return (
    <S.Container
      completed={completed}
      hasAction={hasAction}
      expanded={expanded}
      disabled={disabled}
      data-cy={props['data-cy']}
    >
      <Row align="middle" justify="space-between" onClick={handleContainerClick}>
        <S.Wrapper>
          <h1>{title}</h1>
          <p>{description}</p>
        </S.Wrapper>
        {getRightComponent()}
      </Row>
      <S.AnimatedDiv expanded={expanded}>{props.children}</S.AnimatedDiv>
    </S.Container>
  );
};

export default ExpandableCard;
