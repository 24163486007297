import styled from 'styled-components';
import { Modal } from 'antd';

export const CModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 5px;
    max-height: 75vh;
    overflow-y: auto;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  margin-top: 24px;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.85em;
  margin: 0;
  text-align: center;
`;

export const TitleInput = styled.p`
  font-weight: 400;
  font-size: 0.85em;
  margin: 0 0 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const TitleInputBold = styled.span`
  font-weight: bold;
  font-size: 1.05em;
`;
