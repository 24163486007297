import React, { useRef, useCallback, useState, useEffect, RefObject } from 'react';
import { Col, Row, Input as AntdInput, message, Button, Select } from 'antd';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft, FiSearch } from 'react-icons/fi';
import { startCase } from 'lodash';
import { IInvestigationLibraryFilter } from '..';
import useStateStandardFilter, { useNgssStandarFilter } from '../../../hooks/useStateStandardFilter';
import Input from '../../../shared/Input';
import { cleanCoreIdeaCode, sortTexasStandardsByCode } from '../../../utils/utils';
import { gqlSchema } from '../../../gql/schema';
import { GQL_InvestigationFocus } from '../../../types/investigationFocus';
import { GQL_InvestigationStandard } from '../../../types/investigationStandard';
// import { gradesFilterList, gradesFilterListScienceTexasEdition } from '../../../utils/constants';
import { gradesFilterListAllBands } from '../../../utils/constants';
import { useLibraryFilters } from '../../../hooks/useLibraryFliters';
import * as S from './styles';
// import { SUBJECT_TYPES } from '../../../types/subjects';

const { Option, OptGroup } = Select;

interface IInvestigationLibraryHeader {
  backUrl?: string;
  updateSearchText: (text: string) => void;
  refreshMethod: () => void;
  filters: IInvestigationLibraryFilter[];
  setFilters: (filters: IInvestigationLibraryFilter[]) => void;
  stateStandards?: GQL_InvestigationStandard[] | undefined;
  ngssStandards?: GQL_InvestigationStandard[];
  subjectType?: string;
}

interface KeywordFilterProps {
  inputRef: RefObject<AntdInput>;
  selectedKeyword: string;
  setSelectedKeyword: (value: string) => void;
  handleInputSearch: () => void;
}
interface FocusFilterProps {
  investigationFocuses: any;
  filtersBeforeFetch: any;
  setNewFilter: any;
}

interface GradeFilterProps {
  filtersBeforeFetch: any;
  setNewFilter: any;
  subjectType: any;
}
interface DisciplineFilterProps {
  filteredDisciplines: any;
  filtersBeforeFetch: any;
  setNewFilter: any;
}
interface CoreIdeaFilterProps {
  filteredCoreIdeas: any;
  filtersBeforeFetch: any;
  setNewFilter: any;
}

interface StandardFilterProps {
  getFilteredNGSSStandards: any;
  getFilteredTexasStandards: any;
  filtersBeforeFetch: any;
  setNewFilter: any;
}

const InvestigationLibraryHeader:React.FC<IInvestigationLibraryHeader> = ({
  backUrl,
  updateSearchText,
  refreshMethod,
  filters,
  setFilters,
  stateStandards,
  ngssStandards,
  subjectType,
}) => {
  const history = useHistory();
  const inputRef = useRef<AntdInput>(null);

  const { stateStandardFilterData } = useStateStandardFilter({ stateStandards });
  const { ngssStandardFilterData } = useNgssStandarFilter({ stateStandards: ngssStandards });
  
  const { data: investigationFocuses, loading: focusesLoading } = useQuery<{ getInvestigationFocuses: GQL_InvestigationFocus[] }>(
    gqlSchema.InvestigationFocusSchema.queries.GET.getInvestigationFocuses,
    {
      onError: (error) => message.error(`There was an error in fetching the focuses - ${error.message || 'Unexpected Error'}`),
    }
  );

  const { coreIdeas, disciplines } = useLibraryFilters();
  const defaultFilters: IInvestigationLibraryFilter[] = [
    { field: "coreIdea", value: "" },
    { field: "grade", value: "" },
    { field: "discipline", value: "" },
    { field: "subject", value: startCase(subjectType) || "Science" },
    { field: "standard", value: "" },
    { field: "focus", value: "" },
    { field: "firstPublishedAt", value: "" }
  ];
  const [filtersBeforeFetch, setFiltersBeforeFetch] = useState<IInvestigationLibraryFilter[]>(filters.length > 0 ? filters : defaultFilters);
  const [filteredDisciplines, setFilteredDisciplines] = useState<any[]>([]);
  const [filteredCoreIdeas, setFilteredCoreIdeas] = useState<any[]>([]);
  const [selectedKeyword, setSelectedKeyword] = useState<string>('');

  const handleInputSearch = useCallback(() => {
    updateSearchText(inputRef?.current?.state?.value);
  }, [updateSearchText]);

  const filterObjectByField = (object: any, field: string) => object?.[field] ? { [field]: object[field] } : {};

  const filterAndConvertObject = useCallback((array: any, type: string, value: string) => {
    const filteredRaw = filterObjectByField(array[type], value);
    return Object.values(filteredRaw).flat();
  },[]);

  const setFilteredOptions = useCallback(() => {
    const subject = startCase(subjectType) || "Science";
    const grade = filtersBeforeFetch.find((f) => f.field === "grade")?.value || "";

    if (subject && grade) {
      setFilteredDisciplines(filterAndConvertObject(disciplines, subject, grade));
      setFilteredCoreIdeas(filterAndConvertObject(coreIdeas, subject, grade));
    }
  }, [subjectType, filtersBeforeFetch, disciplines, coreIdeas, filterAndConvertObject]);
  
  useEffect(() => {
    if (filtersBeforeFetch.length > 0) {
      setFilteredOptions();
    }
  }, [filtersBeforeFetch, disciplines, coreIdeas, setFilteredOptions]);

  const setNewFilter = (filter: IInvestigationLibraryFilter) => {
    const updatedFilters = filtersBeforeFetch.filter((f) => f.field !== filter.field);
    
    if (filter.field === 'grade') {
      const subject = subjectType || "Science" ;
      setFilteredDisciplines(filterAndConvertObject(disciplines, subject, filter.value));
      setFilteredCoreIdeas(filterAndConvertObject(coreIdeas, subject, filter.value));
      setFiltersBeforeFetch([
        ...updatedFilters.filter((f) => !['coreIdea', 'discipline', 'standard'].includes(f.field)),
        { field: "discipline", value: "" },
        { field: "coreIdea", value: "" },
        { field: "standard", value: "" },
        filter,
      ]);
    } else {
      setFiltersBeforeFetch([...updatedFilters, filter]);
    }
  };

  const handleClearFilters = () => {
    setFiltersBeforeFetch(defaultFilters);
    setSelectedKeyword("");
  };

  const handleSearchAction = () => {
    setFilters(filtersBeforeFetch);
    updateSearchText(inputRef?.current?.state?.value);
  };

  const getFilteredTexasStandards = () => {
    const selectedGrade = filtersBeforeFetch.find((f) => f.field === 'grade')?.value.replace('K', '0') || "";
    return stateStandardFilterData.filter((grade) => {
      if (selectedGrade.includes("-")) {
        const [lowerBound, upperBound] = selectedGrade.split("-").map((g:any) => g.trim());
        const gradeKey = parseInt(grade.key.replace('Grade 0','').replace('K', '0').replace('Grade', ''));
        return (gradeKey >= parseInt(lowerBound) && gradeKey <= parseInt(upperBound)) || grade.standards?.some((standard) => standard.grade.replace('0', '') === selectedGrade);
      } else {
        return grade.key.replace('Grade 0','') === selectedGrade || grade.standards?.some((standard) => standard.grade.replace('0', '') === selectedGrade);
      }
    });
  };

  const getFilteredNGSSStandards = () => {
    const selectedGrade = filtersBeforeFetch.find((f) => f.field === 'grade')?.value || "";
    if (selectedGrade.includes("-")) {
      return ngssStandardFilterData.filter((gradeBand) => gradeBand.key.includes(selectedGrade) || gradeBand.standards?.some((standard) => standard.code.startsWith(selectedGrade.split("-")[0])));
    } else {
      return ngssStandardFilterData.filter((gradeBand) => {
        const [lowerBound, upperBound] = gradeBand.key.replace("Grade ", "").split("-");
        const transformedGrade = (selectedGrade === 'K') ? '0' : selectedGrade;
        return (transformedGrade >= lowerBound && transformedGrade <= upperBound) || gradeBand.standards?.some((standard) => standard.code.startsWith(selectedGrade));
      });
    }
  };

  return !focusesLoading ? (
    <S.HeaderContainer onClick={(e) => e.stopPropagation()} gutter={24}>
      <Col xs={1}>
        <S.BackIconButton onClick={() => (backUrl) ? history.push(backUrl) : history.goBack()}>
          <FiArrowLeft />
        </S.BackIconButton>
      </Col>
      <Col xs={23}>
        <Row gutter={[16, 16]} align="stretch" id="assessment-list-header">
          <KeywordFilter 
            inputRef={inputRef} 
            selectedKeyword={selectedKeyword} 
            setSelectedKeyword={setSelectedKeyword} 
            handleInputSearch={handleInputSearch} 
          />
          <FocusFilter 
            investigationFocuses={investigationFocuses} 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter}
          />
          <GradeFilter 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
            subjectType={subjectType}
          />
          <DisciplineFilter 
            filteredDisciplines={filteredDisciplines} 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <CoreIdeaFilter 
            filteredCoreIdeas={filteredCoreIdeas} 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <StandardFilter 
            getFilteredNGSSStandards={getFilteredNGSSStandards()} 
            getFilteredTexasStandards={getFilteredTexasStandards()} 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <Col md={6} xs={3} sm={4} style={{ marginLeft: '50%' }}>
            <Button
              type="primary"
              onClick={handleSearchAction}
              style={{ width: '100%' }}
            >
              Search
            </Button>
          </Col>
          <Col md={6} xs={3} sm={4}>
            <Button
              onClick={handleClearFilters}
              style={{ width: '100%' }}
            >
              Clear All Filters
            </Button>
          </Col>
        </Row>
      </Col>
    </S.HeaderContainer>
  ) : <></>;
};
const KeywordFilter: React.FC<KeywordFilterProps> = ({ inputRef, selectedKeyword, setSelectedKeyword, handleInputSearch }) => (
  <Col md={6} xs={12} sm={12}>
    <Input
      ref={inputRef}
      value={selectedKeyword}
      onChange={(e) => setSelectedKeyword(e.target.value)}
      placeholder="Search"
      icon={<FiSearch />}
      onPressEnter={handleInputSearch}
      style={{ height: '32px', width: '100%', borderRadius: '0', marginTop: '3px' }}
    />
  </Col>
);

const FocusFilter: React.FC<FocusFilterProps> = ({ investigationFocuses, filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Focus"
      onChange={(value) => setNewFilter({ field: 'focus', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'focus')?.value || undefined}
      style={{ width: '100%' }}
    >
      {investigationFocuses?.getInvestigationFocuses.map((focus:any, i:number) => (
        <Option key={`focus-${i}`} value={focus.name}>{focus.name}</Option>
      ))}
    </Select>
  </Col>
);

const GradeFilter: React.FC<GradeFilterProps> = ({ filtersBeforeFetch, setNewFilter, subjectType }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Grade"
      onChange={(value) => setNewFilter({ field: 'grade', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'grade')?.value || undefined}
      style={{ width: '100%' }}
      disabled={!subjectType}
    >
      {gradesFilterListAllBands.map((grade:any, i:number) => (
        <Option key={`${grade}-${i}`} value={grade}>{`Grade ${grade}`}</Option>
      ))}
    </Select>
  </Col>
);

const DisciplineFilter: React.FC<DisciplineFilterProps> = ({ filteredDisciplines, filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Discipline"
      onChange={(value) => setNewFilter({ field: 'discipline', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'discipline')?.value || undefined}
      style={{ width: '100%' }}
      disabled={!(filtersBeforeFetch.filter((f:any) => f.field === 'grade')[0].value)}
    >
      <OptGroup label="Discipline">
        {filteredDisciplines.map((discipline:any) => (
          <Option
            key={`discipline-${discipline.name}`}
            value={discipline.name}>{startCase(discipline.name)}
          </Option>
        ))}
      </OptGroup>
    </Select>
  </Col>
);

const CoreIdeaFilter: React.FC<CoreIdeaFilterProps> = ({ filteredCoreIdeas, filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Core Idea"
      onChange={(value) => setNewFilter({ field: 'coreIdea', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'coreIdea')?.value || undefined}
      style={{ width: '100%' }}
      disabled={!(filtersBeforeFetch.filter((f:any) => f.field === 'grade')[0].value)}
    >
      <OptGroup label="Core Idea">
        {filteredCoreIdeas.sort(sortTexasStandardsByCode).map((coreIdea:any) => (
          <Option key={`core-idea-${coreIdea.code}`} value={coreIdea.code}>{startCase(coreIdea.text)} {cleanCoreIdeaCode(coreIdea.code)}</Option>
        ))}
      </OptGroup>
    </Select>
  </Col>
);

const StandardFilter: React.FC<StandardFilterProps> = ({ getFilteredNGSSStandards, getFilteredTexasStandards, filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Standard"
      onChange={(value) => setNewFilter({ field: 'standard', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'standard')?.value || undefined}
      style={{ width: '100%' }}
      disabled={!(filtersBeforeFetch.filter((f:any) => f.field === 'grade')[0].value)}
    >
      <OptGroup key={`NGSS Standards`} label="NGSS Standards">
        {getFilteredNGSSStandards.map((ngss:any, i:number) => (
          // <OptGroup key={`ngss-gradeBands-${i}`} label={ngss.key}>
          ngss.standards
            .filter((standard: any) => standard.code.startsWith(filtersBeforeFetch.filter((f:any) => f.field === 'grade')[0].value.split("-")[0]))
            .map((standard: any, j:number) => (
              <Option
                key={standard.id}
                value={standard.id}
                disabled={filtersBeforeFetch.some((filter:any) => filter.value === standard.id)}
              >
                {`${standard.code} - ${standard.abbreviatedStatement}`}
              </Option>
            ))
          
        // </OptGroup>
        ))}
      </OptGroup>
      <OptGroup key={`Texas Standards`} label="Texas Standards">
        {getFilteredTexasStandards.map((texas:any, i:number) => (
          // <OptGroup key={`texas-grade-${i}`} label={texas.key}>
          texas.standards?.sort(sortTexasStandardsByCode).map((standard:any) => (
            <Option key={standard.id} value={standard.id}>{`${standard.code} - ${standard.abbreviatedStatement}`}</Option>
          ))
          // </OptGroup>
        ))}
      </OptGroup>
    </Select>
  </Col>
);

export default InvestigationLibraryHeader;


