import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Form as AntdForm, Col, Row, message, Result } from 'antd';
import * as S from './styles';
import Input from '../../../shared/Input';
import Button from '../../../shared/Button';
import Form from '../../../shared/Form';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { GQL_AssignOrInviteTAResponse } from '../../../types/teacher';
import { useAuth } from '../../../hooks/useAuth';

interface Props {
  currentTeachers: number;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onInvited: () => void;
}

const TeacherAssistant: React.FC<Props> = (props) => {
  const { visible, setVisible, onInvited, currentTeachers } = props;
  const [form] = AntdForm.useForm();
  const { user } = useAuth();
  const onCloseModal = () => {
    setVisible(false);
  };

  const [submitTeacherAssistant, { loading }] = useMutation(
    gqlSchema.TeacherSchema.mutations.ASSISTANT.assignOrInviteTeacherAssistant,
    {
      onCompleted: ({
        assignOrInviteTeacherAssistant,
      }: {
        assignOrInviteTeacherAssistant: GQL_AssignOrInviteTAResponse;
      }) => {
        form.resetFields();
        setVisible(false);
        onInvited();
        message.success('Assistant invited successfully');
      },
      onError: (error) => {
        message.error(error.message);
      },
      refetchQueries: ['GetInvites'],
      awaitRefetchQueries: true,
    },
  );

  const onSubmitForm = useCallback(
    (values: { firstName: string; lastName: string; email: string }) => {
      submitTeacherAssistant({
        variables: { firstName: values.firstName, lastName: values.lastName, email: values.email },
      });
    },
    [submitTeacherAssistant],
  );
  return (
    <S.CModal
      visible={visible}
      maskClosable={false}
      title={
        <Row>
          <Col span={24}>
            <S.Title>Add Teacher Assistant</S.Title>
          </Col>
        </Row>
      }
      onCancel={onCloseModal}
      width={800}
      footer={null}
    >
      <Row justify="center" style={{ paddingBottom: 25 }}>
        <Col span={16}>
          <S.Info>
            You can add up to <S.TitleBold>{user.assistantsAllowed} Teacher Assistants</S.TitleBold>
          </S.Info>
          <S.Info>from your teacher subscription</S.Info>
        </Col>
        {currentTeachers >= user.assistantsAllowed && (
          <Result
            status="error"
            subTitle={`You already have ${user.assistantsAllowed} Teacher Assistant${
              user.assistantsAllowed > 1 ? 's' : ''
            }`}
          />
        )}
      </Row>
      {currentTeachers < user.assistantsAllowed && (
        <Form onFinish={onSubmitForm} form={form}>
          <Row gutter={16} justify="center" style={{ margin: '35px 0 50px 0' }}>
            <Col span={7}>
              <S.TitleInput>First Name</S.TitleInput>
              <AntdForm.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Input the First Name!',
                  },
                ]}
              >
                <Input />
              </AntdForm.Item>
            </Col>
            <Col span={7}>
              <S.TitleInput>Last Name</S.TitleInput>
              <AntdForm.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Input the Last Name!',
                  },
                ]}
              >
                <Input />
              </AntdForm.Item>
            </Col>
            <Col span={10}>
              <S.TitleInput>Email</S.TitleInput>
              <AntdForm.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Input the Email!',
                  },
                  {
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </AntdForm.Item>
            </Col>
            <Col span={8}>
              <AntdForm.Item>
                <Button block loading={loading} text="Send Invite" htmlType="submit" style={{ position: 'absolute' }} />
              </AntdForm.Item>
            </Col>
          </Row>
        </Form>
      )}
    </S.CModal>
  );
};

export default TeacherAssistant;
