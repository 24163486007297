import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Col, Modal, Row, message } from 'antd';
import ExcelJS, { Borders } from 'exceljs';
import { saveAs } from 'file-saver';

import { Duration } from '../../../types/investigation';
import AssessmentsTable from '../AssessmentsTable';
import { gqlSchema } from '../../../gql/schema';
import { GQL_ClassDataResponse, GQL_ClassResponse, GQL_QueryClassDataInput } from '../../../types/class';
import {
  GQL_AssessmentsScoresResponse,
  GQL_ComparisonByIdResponse,
  GQL_ComparisonByStandardsResponse,
  PerfomanceLevel,
} from '../../../types/gradeBooking';
import { InsightsType } from './../../../constants/enums/InsightsTypes';
import AssessmentsInsightsFilters from '../AssessmentsInsightsFilters';
import { FilterFields } from '../../AdiAssessmentListPage';
import { useInvestigationFilters } from '../../../hooks/useInvestigationFilters';
import StudentsPerformanceBarMultiple from '../StudentsPerformanceBarMultiple';
import * as S from '../styles';
import AverageAssessmentPercent from '../AverageAssessmentPercent';
import StudentsPerformanceBar from '../StudentsPerformanceBar';
import useFetchStateStandards from '../../../hooks/useFetchStateStandards';
import AssessmentComparisonByStandardChart from '../../AssessmentComparisonByStandardChart';
import { useAuth } from '../../../hooks/useAuth';
import { useEnabledAssessments } from '../../../hooks/useEnabledAssessments';
import { INVESTIGATION_STANDARDS } from '../../../types/standards';

interface ITeacherAssessmentsScores {
  classId?: string;
  studentIds?: string[];
  duration?: {
    startDate: number;
    endDate: number;
  };
  endDate: number;
}

interface ComparisonByIdInput {
  classId?: string;
  assessmentsIds: string[];
}

interface ComparisonByStandardsInput {
  classId: string;
  standardIds: string[];
  standardType?: string;
}

const TeacherAssessmentInsights = () => {
  const history = useHistory();
  const { dismissedInsightsTutorial } = useAuth();
  const [selectedAssessmentsIds, setSelectedAssessmentsIds] = useState<string[]>([]);
  const [selectedClassId, setSelectedClassId] = useState<string | undefined>();
  const [selectedStudentId, setSelectedStudentId] = useState<string | undefined>();
  const [comparingAssessments, setComparingAssessments] = useState<boolean>(false);
  const [filters, setFilters] = useInvestigationFilters<FilterFields>(history);
  const [duration, setDuration] = useState<Duration | undefined>();
  const [selectedStateStandards, setSelectedStateStandards] = useState<string[]>([]);
  const [insightsTutorialModalOpen, setInsightsTutorialModalOpen] = useState(false);
  const { texasEditionEnabled } = useEnabledAssessments();

  useEffect(() => {
    if (!dismissedInsightsTutorial) {
      setInsightsTutorialModalOpen(true);
    }
  }, [dismissedInsightsTutorial]);

  const [dismissInsightsTutorial] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.dismissInsightsTutorial,
    {
      refetchQueries: [gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.me],
      awaitRefetchQueries: true,
      onError: (e: any) => {
        message.error('Error while dismissing onboarding tooltips: ' + e.message);
        setInsightsTutorialModalOpen(true);
      },
    },
  );

  const dismissInsightsTutorialModal = () => {
    setInsightsTutorialModalOpen(false);

    // make the API call
    dismissInsightsTutorial();
  };

  const insightType = InsightsType.ASSESSMENTS;

  const { data: classesData, loading: loadingClasses } = useQuery<{ getClasses: GQL_ClassResponse[] }>(
    gqlSchema.ClassSchema.query.CLASS.CLASSES.getClasses,
  );

  const [getClass, { data: classStudentsData, loading: loadingStudents }] = useLazyQuery<{
    getClass: GQL_ClassResponse;
  }>(gqlSchema.ClassSchema.query.CLASS.CLASSES.getClass);

  const studentsData = useMemo(() => classStudentsData?.getClass?.students?.filter((s) => s.userId), [
    classStudentsData,
  ]);

  const [getTeacherAssessmentsScores, { data: scoresData }] = useLazyQuery<
    { getTeacherAssessmentsScores: GQL_AssessmentsScoresResponse[] },
    { data: ITeacherAssessmentsScores }
  >(gqlSchema.InvestigationSchema.queries.GRADE_BOOK.getTeacherAssessmentsScores, {
    fetchPolicy: 'cache-and-network',
  });

  const [getAssessmentsComparisonById, { data: comparisonData }] = useLazyQuery<
    { getAssessmentsComparisonById: GQL_ComparisonByIdResponse[] },
    { data: ComparisonByIdInput }
  >(gqlSchema.InvestigationSchema.queries.GRADE_BOOK.getAssessmentsComparisonById, {
    fetchPolicy: 'cache-and-network',
  });

  const [getAssessmentComparisonByStandards, { data: comparisonDataByStandards }] = useLazyQuery<
    { getAssessmentsComparisonByStandards: GQL_ComparisonByStandardsResponse[] },
    { data: ComparisonByStandardsInput }
  >(gqlSchema.InvestigationSchema.queries.GRADE_BOOK.getAssessmentsComparisonByStandards, {
    fetchPolicy: 'cache-and-network',
  });

  const insightsData = useMemo(
    () => [...(scoresData?.getTeacherAssessmentsScores || [])]?.sort((a, b) => a.startDate - b.startDate),
    [scoresData],
  );

  const handleClassChange = useCallback((id?: string) => {
    setSelectedClassId(id);
    setSelectedStudentId(undefined);
    setSelectedAssessmentsIds([]);
  }, []);

  const { stateStandardsData, stateStandardsLoading } = useFetchStateStandards(null, true);

  const filteredAssessment = useMemo(() => {
    if (!scoresData?.getTeacherAssessmentsScores) return [];

    let assessmentList = scoresData?.getTeacherAssessmentsScores;
    if (!filters) return assessmentList;

    filters.forEach((filter) => {
      assessmentList = assessmentList.filter((assessment) => {
        switch (filter.field) {
          case 'focus':
            return assessment.focus?.name === filter.value;
          case 'subject':
            return assessment.discipline?.subject === filter.value;
          case 'grade':
            return assessment.discipline?.gradeBand === filter.value;
          case 'discipline':
            return assessment.discipline?.name === filter.value;
          case 'coreIdea':
            return assessment.coreIdeas?.some((ci) => ci.code === filter.value);
          case 'practice':
            return assessment.practices?.some((p) => p.code === filter.value);
          case 'standard':
            return assessment.standards?.some((s) => s.id === filter.value);
          default:
            return true;
        }
      });
    });

    // if standards are selected in the dropdown
    if (selectedStateStandards?.length > 0) {
      assessmentList = assessmentList.filter((assessment) => {
        return assessment.standards?.some((standard) => selectedStateStandards?.includes(standard.id));
      });
    }

    return assessmentList;
  }, [scoresData, filters, selectedStateStandards]);

  const handleSetDuration = useCallback(
    (duration: Duration) => {
      if (!duration.startDate && !duration.endDate) {
        setDuration(undefined);
      } else {
        setDuration(duration);
      }
    },
    [setDuration],
  );

  useEffect(() => {
    if (comparingAssessments && selectedAssessmentsIds?.length < 2 && selectedStateStandards?.length < 2) {
      setComparingAssessments(false);
    }
    if (comparingAssessments && selectedAssessmentsIds?.length === 2) {
      getAssessmentsComparisonById({
        variables: {
          data: {
            classId: selectedClassId,
            assessmentsIds: selectedAssessmentsIds,
          },
        },
      });
    } else if (comparingAssessments && selectedClassId && selectedStateStandards?.length > 1) {
      getAssessmentComparisonByStandards({
        variables: {
          data: {
            classId: selectedClassId,
            standardIds: selectedStateStandards,
            standardType: texasEditionEnabled ? INVESTIGATION_STANDARDS.TEXAS : INVESTIGATION_STANDARDS.NGSS,
          },
        },
      });
    }
  }, [
    selectedAssessmentsIds,
    selectedClassId,
    comparingAssessments,
    getAssessmentsComparisonById,
    getAssessmentComparisonByStandards,
    selectedStateStandards,
    texasEditionEnabled,
  ]);

  useEffect(() => {
    if (selectedClassId) {
      getClass({ variables: { data: { classId: selectedClassId } } });
      getTeacherAssessmentsScores({
        variables: {
          data: {
            classId: selectedClassId,
            studentIds: selectedStudentId ? [selectedStudentId] : undefined,
            duration: duration
              ? {
                  startDate: new Date(duration.startDate).getTime(),
                  endDate: new Date(duration.endDate).getTime(),
                }
              : undefined,
            endDate: new Date().getTime(),
          },
        },
      });
    }
  }, [selectedClassId, getClass, getTeacherAssessmentsScores, selectedStudentId, duration]);

  const [getClassData] = useLazyQuery<{ getClassData: GQL_ClassDataResponse[] }, { data: GQL_QueryClassDataInput }>(
    gqlSchema.InvestigationSchema.queries.CLASS.getClassData,
    {
      onCompleted: (data) => {
        saveAsExcel(data.getClassData);
      },
      onError: (err) => {
        message.error('There was an error loading the class data: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const onExportClassData = useCallback(() => {
    const data: GQL_QueryClassDataInput = { classId: classStudentsData?.getClass.id || '', isAssessment: true };
    if (selectedStudentId) data.studentId = selectedStudentId;
    getClassData({ variables: { data } });
  }, [classStudentsData, getClassData, selectedStudentId]);

  const performanceLevelString = useCallback((gradesAverage: number) => {
    if (gradesAverage < 66) {
      return PerfomanceLevel.NEEDS_IMPROVEMENT;
    } else if (gradesAverage >= 66 && gradesAverage <= 99) {
      return PerfomanceLevel.APPROACHING_EXPECTATON;
    }

    return PerfomanceLevel.MEETS_EXPECTATION;
  }, []);

  const saveAsExcel = useCallback(
    async (dataset: GQL_ClassDataResponse[]) => {
      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet();
      const borderStyle = { style: 'thin', color: 'black' };
      const bordersStyle = {
        top: borderStyle,
        left: borderStyle,
        right: borderStyle,
        bottom: borderStyle,
        diagonal: borderStyle,
      } as Borders;
      const headerStyle = { font: { bold: true }, border: bordersStyle };
      const bodyStyle = { font: { bold: false } };
      ws.columns = [
        { header: 'First Name', key: 'firstName', width: 20 },
        { header: 'Last Name', key: 'lastName', width: 20 },
        { header: 'Email', key: 'email', width: 35 },
        { header: 'Assessment', key: 'investigation', width: 45 },
        { header: 'Assessment Score (out of 9 per student)', key: 'reportScore', width: 15 },
        { header: 'Assessment Percent', key: 'reportAverage', width: 15 },
        { header: 'Performance Level', key: 'engagementScore', width: 20 },
      ];
      ws.getCell(`A1`).style = headerStyle;
      ws.getCell(`B1`).style = headerStyle;
      ws.getCell(`C1`).style = headerStyle;
      ws.getCell(`D1`).style = headerStyle;
      ws.getCell(`E1`).style = headerStyle;
      ws.getCell(`F1`).style = headerStyle;
      ws.getCell(`G1`).style = headerStyle;

      let currentRow = 2;

      dataset.forEach((row) => {
        const excelRow = ws.addRow([
          row.firstName,
          row.lastName,
          row.email,
          'Assessment',
          'Assessment Score (out of 9 per student)',
          'Assessment Percent',
          'Performance Level',
        ]);

        excelRow.alignment = { vertical: 'middle', horizontal: 'left' };
        excelRow.font = bodyStyle.font;

        const mergeLength = currentRow + (row.scores?.length || 0) - 1;
        ws.mergeCells(`A${currentRow}:A${mergeLength}`);
        ws.mergeCells(`B${currentRow}:B${mergeLength}`);
        ws.mergeCells(`C${currentRow}:C${mergeLength}`);
        ws.getCell(`A${currentRow}`).border = bordersStyle;
        ws.getCell(`B${currentRow}`).border = bordersStyle;
        ws.getCell(`C${currentRow}`).border = bordersStyle;
        let subRow = currentRow;
        row.scores?.forEach((inv) => {
          const rowAssessmentName = ws.getCell(`D${subRow}`);
          const rowReportScore = ws.getCell(`E${subRow}`);
          const rowReportAverage = ws.getCell(`F${subRow}`);
          const rowPerformanceLevel = ws.getCell(`G${subRow}`);
          // Putting value to subrows
          rowAssessmentName.value = inv.title;
          rowReportScore.value = inv.reportScore?.toFixed(2);
          rowReportAverage.value = Math.round(inv.reportAverage * 100) + '%';
          rowPerformanceLevel.value = performanceLevelString(inv.reportAverage * 100);
          // Putting borders to subrows
          rowAssessmentName.border = bordersStyle;
          rowReportScore.border = bordersStyle;
          rowReportAverage.border = bordersStyle;
          rowPerformanceLevel.border = bordersStyle;
          // Putting row font
          rowAssessmentName.font = bodyStyle.font;
          rowReportScore.font = bodyStyle.font;
          rowReportAverage.font = bodyStyle.font;
          rowPerformanceLevel.font = bodyStyle.font;
          subRow += 1;
        });
        currentRow = mergeLength + 1;
      });
      const buf = await wb.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${classStudentsData?.getClass.name} AssessmentData.xlsx`);
    },
    [classStudentsData, performanceLevelString],
  );

  const handleAssessmentSelection = useCallback(
    (ids: string[]) => {
      setSelectedAssessmentsIds(ids);
    },
    [setSelectedAssessmentsIds],
  );

  const handleStateStandardSelection = useCallback(
    (ids: string[]) => {
      setSelectedStateStandards(ids);
    },
    [setSelectedStateStandards],
  );

  const insightsDataForFilter = useMemo(() => {
    const uniqueAssessments: string[] = [];
    const filteredInsightsData: GQL_AssessmentsScoresResponse[] = [];

    for (const iD of insightsData) {
      if (uniqueAssessments.includes(iD.assessmentId)) continue;

      uniqueAssessments.push(iD.assessmentId);
      filteredInsightsData.push(iD);
    }

    return filteredInsightsData;
  }, [insightsData]);

  return (
    <S.Container>
      <Row gutter={[32, 32]} style={{ width: '100%' }}>
        <Col span={24}>
          <AssessmentsInsightsFilters
            insightType={insightType}
            classesData={classesData?.getClasses}
            loadingClasses={loadingClasses}
            loadingStudents={loadingStudents}
            studentsData={studentsData}
            setSelectedClassId={handleClassChange}
            selectedClassId={selectedClassId}
            setSelectedStudentId={setSelectedStudentId}
            selectedStudentId={selectedStudentId}
            filters={filters}
            setFilters={setFilters}
            duration={duration}
            setDuration={handleSetDuration}
            insightsData={insightsDataForFilter}
            comparingAssessments={comparingAssessments}
            setComparingAssessments={setComparingAssessments}
            canCompareAssessments={selectedAssessmentsIds?.length > 1}
            onExportTempranoData={onExportClassData}
            assessments={scoresData?.getTeacherAssessmentsScores}
            selectedAssessmentIds={selectedAssessmentsIds}
            setSelectedAssessmentIds={handleAssessmentSelection}
            stateStandardsData={stateStandardsData || []}
            stateStandardsLoading={stateStandardsLoading}
            setSelectedStateStandards={handleStateStandardSelection}
            canCompareByStandards={selectedStateStandards?.length >= 1}
          />
        </Col>
        <Col span={24}>
          <AssessmentsTable data={filteredAssessment} />
        </Col>
        <Row style={{ width: '100%' }} gutter={[24, 24]}>
          <Col span={comparingAssessments ? 24 : 18}>
            {comparingAssessments && selectedAssessmentsIds.length === 2 && (
              <StudentsPerformanceBarMultiple loading={true} data={comparisonData?.getAssessmentsComparisonById} />
            )}

            {comparingAssessments && selectedStateStandards?.length > 1 && (
              <AssessmentComparisonByStandardChart
                data={comparisonDataByStandards?.getAssessmentsComparisonByStandards}
                loading={false}
              />
            )}
            {!comparingAssessments && <StudentsPerformanceBar data={filteredAssessment} />}
          </Col>
          {!comparingAssessments && (
            <Col span={6}>
              <AverageAssessmentPercent data={filteredAssessment} />
            </Col>
          )}
        </Row>
      </Row>
      <Modal
        closable
        visible={insightsTutorialModalOpen}
        footer={null}
        width={728}
        onCancel={dismissInsightsTutorialModal}
      >
        <S.TutorialModalContainer data-cy="components-assessment-insights-tutorial-modal">
          <S.TutorialModalTitle>See what's new in Insights!</S.TutorialModalTitle>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/0BvXI6Yzk24?si=Ov1bqyoB_Xo29jXS"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </S.TutorialModalContainer>
      </Modal>
    </S.Container>
  );
};

export default TeacherAssessmentInsights;
