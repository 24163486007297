/* eslint-disable complexity */
import { useMutation } from '@apollo/client';
import { message, Popover, Tooltip } from 'antd';
import React, { useState } from 'react';
import { FiFlag, FiMessageCircle, FiThumbsUp } from 'react-icons/fi';
import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';
import { GQL_Comment, GQL_DeleteCommentResponse } from '../../types/investigation';
import { GQL_NotificationMetadata } from '../../types/notification';
import { themeConfig } from '../../utils/theme';
import Button from '../Button';
import DeletePopover from '../DeletePopover';

import * as S from './styles';

interface ICommentActions {
  ownComment?: boolean;
  commentId: string;
  showComment?: boolean;
  showLike?: boolean;
  showFlag?: boolean;
  setActiveComment?: (commentId: string) => void;
  commentSelected?: boolean;
  comments?: number;
  compact?: boolean;
  onFlag?: () => void;
  likes?: {
    count: number;
    liked: boolean;
  };
  flags?: {
    count: number;
    flagged: boolean;
  };
  submissionVersion: number;
}

const CommentActions = (props: ICommentActions) => {
  const {
    ownComment,
    flags,
    likes,
    commentId,
    comments,
    commentSelected,
    showComment = true,
    showLike = true,
    showFlag = true,
    compact = false,
    setActiveComment,
    onFlag = () => {},
    submissionVersion,
  } = props;

  const [flagModalVisible, setFlagModalVisible] = useState(false);
  const { isStudent, user } = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';

  const [deleteComment, { loading: loadingDeleteComment }] = useMutation<
    { deleteComment: GQL_DeleteCommentResponse },
    { commentId: string }
  >(gqlSchema.InvestigationSchema.mutations.COMMENTS.deleteComment, {
    onError: (err) => {
      message.error('There was an error deleting the comment: ' + err.message);
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          getNotifications(existingNotificationsRefs = [], { readField }) {
            const newNotifications = existingNotificationsRefs.filter((notif: any) => {
              const metadata = readField<GQL_NotificationMetadata>('metadata', notif);
              return data?.deleteComment.commentId !== metadata?.commentId;
            });
            return [...newNotifications];
          },
        },
      });
    },
    refetchQueries: ['getCommentByActivityId'],
    awaitRefetchQueries: true,
  });

  const [likeComment, { loading: loadingComment }] = useMutation<{ likeComment: GQL_Comment }, { commentId: string }>(
    gqlSchema.InvestigationSchema.mutations.COMMENTS.likeComment,
    {
      onError: (err) => {
        message.error('There was an error liking comment: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const [flagComment, { loading: loadingFlag }] = useMutation<
    { flagComment: GQL_Comment },
    { commentId: string; submissionVersion: number }
  >(gqlSchema.InvestigationSchema.mutations.COMMENTS.flagComment, {
    onCompleted: () => {
      setFlagModalVisible(false);
      onFlag();
    },
    onError: (err) => {
      message.error('There was an error flagging comment: ' + err.message || 'Unexpected Error');
    },
  });

  const handleLikeComment = () => {
    if (!loadingComment && !ownComment) {
      likeComment({
        variables: {
          commentId,
        },
      });
    }
  };

  const handleFlagComment = () => {
    if (!loadingFlag) {
      flagComment({
        variables: {
          commentId,
          submissionVersion,
        },
      });
    }
  };

  const handleCommentSelection = () => {
    if (!commentSelected && setActiveComment) {
      setActiveComment(commentId);
    } else if (setActiveComment) {
      setActiveComment('');
    }
  };

  const flagConfirmationModalContent = () => {
    return (
      <S.FlagConfirmationContainer>
        <p>
          Your teacher will receive a notification.
          <br />
          Do you <strong>confirm</strong> that you want to report this comment?
        </p>
        <div>
          <Button
            theme={themeConfig.noColor}
            text="Cancel"
            onClick={() => setFlagModalVisible(false)}
            loading={loadingFlag}
          />
          <Button text="Confirm" onClick={handleFlagComment} loading={loadingFlag} />
        </div>
      </S.FlagConfirmationContainer>
    );
  };

  const handleFlagVisibilityChange = (visible: boolean) => {
    if (!visible) setFlagModalVisible(false);
    else if (flags?.flagged) handleFlagComment();
    else setFlagModalVisible(true);
  };

  return (
    <S.ActionsRow>
      {showFlag && (
        <Popover
          trigger="click"
          visible={!ownComment ? flagModalVisible : false}
          onVisibleChange={handleFlagVisibilityChange}
          content={flagConfirmationModalContent}
        >
          <Tooltip
            placement="top"
            title={`${(flags?.count ?? 0) || 'No'} student${(flags?.count ?? 0) > 1 ? 's' : ''} flagged this comment`}
          >
            <S.FlagContainer
              $flagged={flags?.flagged || ((flags?.count ?? 0) > 0 && !(isStudent && isGoogleStudent && isCanvasStudent))}
              $ownComment={ownComment}
            >
              <FiFlag size={21} />
              {flags?.count ?? 0}
              {!compact && ` ${(flags?.count || 0) === 1 ? 'Report' : 'Reports'}`}
            </S.FlagContainer>
          </Tooltip>
        </Popover>
      )}
      {showComment && (
        <Tooltip placement="top" title="Discuss about this comment">
          <S.CommentContainer $selected={commentSelected} onClick={handleCommentSelection}>
            <FiMessageCircle size={21} />
            {comments}
            {!compact && ` ${(comments || 0) === 1 ? 'Comment' : 'Comments'}`}
          </S.CommentContainer>
        </Tooltip>
      )}
      {showLike && (
        <Tooltip
          placement="top"
          title={`${(likes?.count ?? 0) || 'No'} student${(likes?.count ?? 0) > 1 ? 's' : ''} agreed with this comment`}
        >
          <S.LikeContainer onClick={handleLikeComment} $liked={likes?.liked} $ownComment={ownComment}>
            <FiThumbsUp size={21} />
            {likes?.count ?? 0}
            {!compact && ` ${(likes?.count || 0) === 1 ? 'Like' : 'Likes'}`}
          </S.LikeContainer>
        </Tooltip>
      )}
      {!isStudent && !isGoogleStudent && !isCanvasStudent && (
        <DeletePopover
          text="Are you sure you want to delete this comment?"
          handleDelete={() => deleteComment({ variables: { commentId: commentId } })}
          loading={loadingDeleteComment}
          iconColor={themeConfig.secondaryColor.background}
        />
      )}
    </S.ActionsRow>
  );
};

export default CommentActions;
