import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

export const StyledInput = styled(AntdInput)<{
  $backgroundColor?: string;
  $alignRight?: boolean;
  $disabled?: boolean;
}>`
  border-radius: 6px;
  ${(props) => !props.$disabled && props.$backgroundColor && 'background: ' + props.$backgroundColor + ';'}
  color: black !important;
  font-size: 1em;
  ${(props) => props.$alignRight && 'text-align: right !important;'}
  input {
    ${(props) => props.$alignRight && 'text-align: right !important;'}
    background: ${(props) => props.$backgroundColor};
  }

  .ant-input {
    color: black;
  }
`;

export const InputPassword = styled(AntdInput.Password)<{
  $backgroundColor: string;
}>`
  border-radius: 6px;
  background: ${(props) => props.$backgroundColor};

  input {
    background: ${(props) => props.$backgroundColor};
  }
`;
