import { useLazyQuery } from '@apollo/client';
import { message, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { gqlSchema } from '../../../../gql/schema';
import {
  GQL_InvestigationAssignmentRateDetails,
  GQL_InvestigationAssignmentRateForTeacherResponse,
  GQL_TeacherInvestigationRateDetail,
} from '../../../../types/charts';
import { centerAlign } from '../../../../utils/antd';
import { formatDateTime } from '../../../../utils/date';
import * as S from './styles';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  startDate: string;
  endDate: string;
  workshopOnly?: boolean;
  teacherId?: string;
  title: string;
}

const TeacherInvestigationRateDetail = (props: Props) => {
  const { visible, setVisible, startDate, endDate, workshopOnly, teacherId, title } = props;
  const [dataset, setDataset] = useState<GQL_TeacherInvestigationRateDetail[]>([]);
  const [fetchInvestigationAssignmentPerTeacherChart, { loading }] = useLazyQuery<{
    getInvestigationAssignmentPerTeacherChart: GQL_InvestigationAssignmentRateForTeacherResponse;
  }>(gqlSchema.ChartSchema.queries.CHARTS.getInvestigationAssignmentPerTeacherChart, {
    onCompleted: (data) => {
      const ret: GQL_TeacherInvestigationRateDetail[] = [];
      data.getInvestigationAssignmentPerTeacherChart.investigationAssignmentRatePerClass.forEach((element) => {
        element.investigations.forEach((inv) => {
          ret.push({
            className: element.className,
            ...inv,
          });
        });
      });
      setDataset(ret);
    },
    onError: (err) => {
      message.error('There was an error loading investigation assignment rate: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    if (teacherId) {
      fetchInvestigationAssignmentPerTeacherChart({
        variables: {
          data: {
            teacherId,
            startDate: startDate,
            endDate: endDate,
            workshopOnly,
          },
        },
      });
    }
  }, [workshopOnly, fetchInvestigationAssignmentPerTeacherChart, startDate, endDate, teacherId]);

  const columns: ColumnsType<GQL_TeacherInvestigationRateDetail> = useMemo(() => {
    return [
      {
        title: workshopOnly ? 'Course' : 'Class',
        dataIndex: 'className',
        width: '30%',
        render: (text: string, record: GQL_TeacherInvestigationRateDetail, index: number) => {
          return record.className;
        },
      },
      {
        title: workshopOnly ? 'Workshop' : 'Investigation',
        dataIndex: 'investigation',
        width: '40%',
        align: centerAlign,
        render: (text: string, record: GQL_TeacherInvestigationRateDetail, index: number) => {
          return record.investigationTitle;
        },
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        width: '15%',
        align: centerAlign,
        render: (text: string, record: GQL_InvestigationAssignmentRateDetails, index: number) => {
          return formatDateTime(record.startDate);
        },
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        width: '15%',
        align: centerAlign,
        render: (text: string, record: GQL_InvestigationAssignmentRateDetails, index: number) => {
          return formatDateTime(record.endDate);
        },
      },
    ];
  }, [workshopOnly]);

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(!visible);
        setDataset([]);
      }}
      footer={null}
      width={900}
    >
      <S.Title>{title} Details</S.Title>
      <S.TableWrapper>
        <Table
          columns={columns}
          bordered
          loading={loading}
          dataSource={dataset}
          scroll={{ x: 'max-content' }}
          rowKey={(record) => record.investigationId}
          sticky
        />
      </S.TableWrapper>
    </Modal>
  );
};

export default TeacherInvestigationRateDetail;
