import styled from 'styled-components';
// import { EditOutlined } from '@ant-design/icons';
import { BiEdit } from 'react-icons/bi';

export const Container = styled.div`
  padding: 10px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #fff;

`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  gap: 1em;
  margin-left: 11em;
  margin-bottom: 30px;
`;


export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;

`;

export const BackButton = styled.button`
  font-size: 1.5em;
  font-weight: 450;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 7px;  
`;

export const Section = styled.div`
  margin-bottom: 24px;
  padding: 20px;
  border-radius: 8px;

`;

export const SectionTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
  text-decoration: underline; 
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
  margin-top: 2em;
 
`;

export const DetailItem = styled.div`
  position: relative;
  padding: 1em;

  input {
    border: none;
    background-color:transparent;
    margin-left: 0;
    padding: 0;
    margin-top: 1em;
    font-size: 18px;  
    &:focus {
      outline: none;
      border: none;
    }
  }
 
`;
export const Label = styled.label`
  display: absolute;
  font-size: 20px;
  font-weight: 500;
  color: black;
  margin-bottom: 4px;
`;

export const EditIcon = styled(BiEdit)`
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-80%);
  font-size: 32px;
  color: #ffff;
  cursor: pointer;
  background-color: #4367E9;
  border-radius: 50%;
  padding: 5px;
`;