import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';
import { FormProps, useForm } from 'antd/lib/form/Form';

const validateMessages = {
  required: 'Please, input your ${name}',
  types: {
    email: 'This is not a valid email',
  },
  string: {
    len: 'Provided ${name} must have 6 characters',
  },
};

interface IForm extends FormProps {}

const Form: React.FC<IForm> = (props) => {
  const { children, form: propsForm, ...rest } = props;
  const hooksForm = useForm()[0];
  const form = propsForm || hooksForm;

  const clearErrors = useCallback(
    (field: any) => {
      // Field has basically a type of { email: value },
      // so this gets the name of first key
      const name = Object.keys(field)[0];
      form.setFields([{ name, errors: [] }]);
    },
    [form],
  );

  return (
    <AntdForm
      validateMessages={validateMessages}
      validateTrigger="onSubmit"
      form={form}
      onValuesChange={clearErrors}
      {...rest}
    >
      {props.children}
    </AntdForm>
  );
};

export default Form;
