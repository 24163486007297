import { gql } from '@apollo/client';

const IMMERSIVE_READER = {
  canAccessReader: gql`
    query canAccessReader($investigationId: String!) {
      canAccessReader(investigationId: $investigationId)
    }
  `,
};

export default IMMERSIVE_READER;
