import React, { useState } from 'react';
import { Modal } from 'antd';

import BookCode from './BookCode';
import BookDetails from './BookDetails';


interface BookModalProps {
  isModalVisible: boolean;
  closeModal: (refetch?:Boolean) => void;
}

const AddBookPopUp: React.FC<BookModalProps> = ({ isModalVisible, closeModal }) => {
  const [bookCode, setBookCode] = useState<string>('');
  
  return (
    <Modal
      title="Add New Book"
      visible={isModalVisible}
      onCancel={()=>closeModal()}
      footer={null}
      width={1000}
      style={{justifyContent:'center', fontSize: '1.5rem'}}
      
    >
      {bookCode ?<BookDetails bookCode={bookCode} onClose={(refetch?:Boolean)=>closeModal(refetch)}/>:<BookCode onSubmit={(val)=>setBookCode(val)}/>}
    </Modal>
  );
};

export default AddBookPopUp;