import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, message, Table } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import Button from '../../shared/Button';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';
import { centerAlign, Breakpoint } from '../../utils/antd';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import TableSearchBox from '../../shared/TableSearchBox';
import { GQL_SyncLogResponse, GQL_SyncLogResponsePaginated } from '../../types/sync';
import { formatDateTime } from '../../utils/date';
import { useAuth } from '../../hooks/useAuth';

const TableBreakPoint: Breakpoint[] = ['lg'];

const SyncList = () => {
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchOrgVisible, setSearchOrgVisible] = useState(false);
  const { activeRole } = useAuth();
  const refName = useRef<HTMLInputElement>(null);
  const refOrg = useRef<HTMLInputElement>(null);
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 10 });
  const history = useHistory();
  const [loadingState, setLoadingState] = useState(false);
  const { data, loading, refetch } = useQuery<{
    getSyncsList: GQL_SyncLogResponsePaginated;
  }>(gqlSchema.SyncSchema.queries.LIST.getSyncsList, {
    variables: { data: { pagination: { page: pagination?.current, size: pagination?.pageSize } } },
    onCompleted: (data) => {
      setPagination({
        ...pagination,
        total: data.getSyncsList.pagination?.totalCount,
      });
    },
    onError: (err) => {
      message.error('There was an error loading the Sync History: ' + err.message || 'Unexpected Error');
    },
  });
  const [googleSync] = useMutation(gqlSchema.GoogleClassroomSchema.mutations.GCLASS.Sync, {
    onCompleted: (dataNew) => {
      const { addCourse } = dataNew;

      console.log('Sync Status:', addCourse);
      message.success('Class sync successful!');
      setLoadingState(false); // Turn off loading indicator on success
      refetch(); // Refetch the data after a successful sync
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      message.error('Error syncing class: ' + error?.message);
      setLoadingState(false); // Turn off loading indicator on error
    },
  });
  const [canvasSync] = useMutation(gqlSchema.CanvasSchema.mutations.CANVASCLASS.CanvasSync, {
    onCompleted: (data) => {
      const { addCourse } = data;

      console.log('Sync Status:', addCourse);
      message.success('Class sync successful!');
      setLoadingState(false); // Turn off loading indicator on success
      refetch();
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      message.error('Error syncing class: ' + error?.message);
      setLoadingState(false); // Turn off loading indicator on error
    },
  });
  const handleSyncClick = useCallback(() => {
     setLoadingState(true);
    if (activeRole === 'google_teacher') {
      googleSync();

    } else
      if (activeRole === 'canvas_teacher') {
        canvasSync();
      }
  }, [refetch]);




  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig) => {
      setPagination(pagination);
      refetch();
    },
    [refetch],
  );

  const columns = useMemo(
    () => [
      {
        title: 'User Name',
        align: centerAlign,
        dataIndex: 'userName',
        width: '15%',
        responsive: TableBreakPoint,
        sorter: (a: GQL_SyncLogResponse, b: GQL_SyncLogResponse) => a.userName.localeCompare(b.userName),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_SyncLogResponse) => {
          if (!value) return true;
          return record.userName.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Organization',
        align: centerAlign,
        dataIndex: 'organizationName',
        width: '22%',
        responsive: TableBreakPoint,
        sorter: (a: GQL_SyncLogResponse, b: GQL_SyncLogResponse) =>
          a.organizationName.localeCompare(b.organizationName),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refOrg} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchOrgVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_SyncLogResponse) => {
          if (!value) return true;
          return record.organizationName.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchOrgVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refOrg && refOrg.current) {
                refOrg.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Sync Type',
        align: centerAlign,
        sorter: (a: GQL_SyncLogResponse, b: GQL_SyncLogResponse) => a.type.localeCompare(b.type),
        dataIndex: 'type',
        width: '15%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_SyncLogResponse) => {
          return record.type.toLocaleUpperCase().replaceAll('_', ' ').replace('SYNC', '');
        },
      },
      {
        title: 'Status',
        align: centerAlign,
        sorter: (a: GQL_SyncLogResponse, b: GQL_SyncLogResponse) => a.status.localeCompare(b.status),
        dataIndex: 'status',
        width: '18%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_SyncLogResponse) => {
          return record.status.toLocaleUpperCase().replaceAll('_', ' ');
        },
      },
      {
        title: 'Created At',
        align: centerAlign,
        sorter: (a: GQL_SyncLogResponse, b: GQL_SyncLogResponse) => a.createdAt - b.createdAt,
        dataIndex: 'createdAt',
        width: '15%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_SyncLogResponse) => {
          return formatDateTime(record.createdAt, 'MM.dd.yyyy HH:mm aa');
        },
      },
      {
        title: 'Finished At',
        align: centerAlign,
        dataIndex: 'finishedAt',
        width: '15%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_SyncLogResponse) => {
          return formatDateTime(record.finishedAt, 'MM.dd.yyyy HH:mm aa');
        },
      },
      {
        title: 'Actions',
        align: centerAlign,
        render: (text: string, record: GQL_SyncLogResponse) => {
          return (
            <Button
              text="See Details"
              onClick={() => history.push(`/sync/${record.id}`)}
              block
              theme={themeConfig.primaryColor}
            />
          );
        },
        width: '15%',
      },
    ],
    [searchNameVisible, searchOrgVisible, history],
  );


  return (
    <S.Container>
      <Row gutter={[24, 24]}>
        <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24}>
          <S.ButtonWrapper>
          {(activeRole === 'google_teacher' || activeRole === 'canvas_teacher') && (
            <Button
              text="Sync"
              onClick={() => handleSyncClick()} // Pass the function reference instead of calling it
              theme={themeConfig.primaryColor}
              loading={loadingState} // Display a loading indicator while the mutation is in progress
              disabled={loadingState}
            />
          )}
          </S.ButtonWrapper>

          <S.TableWrapper>
            <Table
              columns={columns}  // Ensure your columns have the 'Actions' column set properly
              loading={loading}
              rowKey={(record: GQL_SyncLogResponse) => record.id}
              bordered
              pagination={pagination}
              dataSource={data?.getSyncsList.syncLogs}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </S.Container>
  );

};

export default withRouter(SyncList);
