import styled from 'styled-components';

export const Container = styled.div`
  padding: 4em;
`;

export const TutorialModalContainer = styled.div`
  display: flex;
  padding: 30px 60px 54px;
  width: 680px;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

export const TutorialModalTitle = styled.h2`
  text-align: center;
  margin-bottom: 40px;
  font-size: 32px;
`;
