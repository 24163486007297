import React, { useCallback, useEffect } from 'react';
import { Divider, Modal, Skeleton, message } from 'antd';

import * as S from './styles';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';

interface PaymentMethodProps {
  visible?: boolean;
  setVisible: (v: boolean) => void;
}

export const PaymentMethodModal: React.FC<PaymentMethodProps> = (props) => {
  const { data: gqlCustomerPortal, loading } = useQuery<{ getStripeCustomerPortal: { url: string } }, any>(
    gqlSchema.PaymentsSchema.queries.LIST.getStripeCustomerPortal,
    {
      onError: () => {
        message.error('There was an error loading the product data, please check your connection and try again later');
      },
    },
  );

  const customerPortalData = gqlCustomerPortal?.getStripeCustomerPortal;
  useEffect(() => {
    if (customerPortalData?.url) {
      window.location.replace(customerPortalData.url);
    }
  }, [customerPortalData]);

  const { setVisible, visible } = props;

  const handleCloseModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null} width={500}>
      <S.ModalTitle>Redirecting to payment method change page</S.ModalTitle>

      <Divider style={{ margin: '10px 0' }} />

      {loading && (
        <S.ModalLoading>
          <Skeleton.Input active size="large" />
        </S.ModalLoading>
      )}
    </Modal>
  );
};
