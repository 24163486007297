import React, { useEffect, useState } from 'react';
import { Form as AntdForm, message } from 'antd';
import { useMutation } from '@apollo/client';

import { gqlSchema } from '../../../../gql/schema';
import Spacer from '../../../../shared/Spacer';
import { GQL_InvestigationBlock, ITextContentValue } from '../../../../types/investigation';
import Editor from '../../../../shared/Editor';
import * as S from './styles';
import InvestigationMultipleChoicePictureQuestion from '../../../StudentInvestigation/InvestigationMultipleChoicePictureQuestion';
import { useAuth } from '../../../../hooks/useAuth';

interface IMultipleChoicePictureQuestionPreview {
  block: GQL_InvestigationBlock;
  stepId?: string;
  activityId?: string;
  canvasIndex?: number;
  userId?: string;
  investigationId?: string;
  onSelected?: (selected: boolean) => void;
  presentationMode?: boolean;
}

const MultipleChoicePictureQuestionPreview = (props: IMultipleChoicePictureQuestionPreview) => {
  const { onSelected } = props;
  const { user } = useAuth();
  /*
  The first object is the Question so I'm extracting here
  to give different treatment to answers and question.
  */
  const question = (props.block.values[0] as ITextContentValue).text;
  const readOnly = props.userId !== user?.id && props.userId !== undefined;
  const answers = props.block?.values.slice(1);
  const selectedAnswer = answers?.findIndex((item) => (item as ITextContentValue)?.selectedAnswer);

  const [answer, setAnswer] = useState<number>(selectedAnswer);

  useEffect(() => {
    setAnswer(selectedAnswer);
    if (selectedAnswer !== -1) {
      onSelected?.(false);
    }
  }, [selectedAnswer, onSelected]);

  const [submitMultipleChoicePicture] = useMutation<
    { submitTextSubmission: { id: string } },
    { activityId: string; stepId: string; text: string }
  >(gqlSchema.InvestigationSchema.mutations.MULTIPLE_CHOICE.submitMultipleChoicePicture, {
    onCompleted: () => {
      onSelected?.(true);
    },
    onError: (err) => message.error('There was an error submitting the comment: ' + err.message),
  });

  const handleAnswerSubmit = (e: number) => {
    setAnswer(e);
    if (props.activityId && props.stepId && !readOnly) {
      submitMultipleChoicePicture({
        variables: {
          activityId: props.activityId,
          text: `${e + 1}`,
          stepId: props.stepId,
        },
      });
    }
  };

  return (
    <>
      <S.QuestionBlock>Questions</S.QuestionBlock>
      <Editor editable={false} listenToValue value={question} key={question} />
      <Spacer />
      <AntdForm.Item name="answer" valuePropName="checked">
        <S.AnswersContainer>
          {answers.map((value, i) => (
            <InvestigationMultipleChoicePictureQuestion
              key={i}
              index={i}
              value={value as ITextContentValue}
              onSelectItem={handleAnswerSubmit}
              answer={answer}
              readOnly={readOnly}
              presentationMode={props.presentationMode}
            />
          ))}
        </S.AnswersContainer>
      </AntdForm.Item>
    </>
  );
};

export default MultipleChoicePictureQuestionPreview;
