import React, { useCallback } from 'react';
import { Col, Row, Tooltip } from 'antd';
import * as S from './styles';
import { themeConfig } from '../../../../utils/theme';
import { formatDateTime } from '../../../../utils/date';
import { useHistory } from 'react-router';
import AssessmentGrade from '../../../AssessmentGrade';
import { GQL_InvestigationDashboard, GQL_ScoreType } from '../../../../types/investigation';
import { last } from 'lodash';

interface Props {
  grade?: number | null;
  scoreType?: GQL_ScoreType;
  parts?: number;
  progressTitle: string;
  date: number;
  investigationName: string;
  investigationId: string;
  isClosed: boolean;
  isStarted: boolean;
  isFinished: boolean;
  submittedAt?: number | null;
  debug?: boolean;
  debugItem?: GQL_InvestigationDashboard;
  version?: string | number;
}

const Item: React.FC<Props> = (props) => {
  const {
    grade,
    date,
    investigationName,
    investigationId,
    isFinished,
    isClosed,
    isStarted,
    submittedAt,
    scoreType,
    debug,
    debugItem,
    parts,
    version,
  } = props;
  const history = useHistory();
  const goToDashboard = useCallback(() => {
    history.push(`/student-assessment/${investigationId}/grade`);
  }, [investigationId, history]);

  const goToSubmission = useCallback(() => {
    history.push(`/student-investigation/${investigationId}`);
  }, [investigationId, history]);

  const incompleteAssessment = !isFinished && isClosed && isStarted;
  const notStartedAssessment = !isFinished && isClosed && !isStarted;

  return (
    <S.Card data-cy="components-finished-assessments-row-item">
      <Row align="middle">
        <Col span={18}>
          <Tooltip title={investigationName}>
            <S.Title $align="left">
              {investigationName}
              {version ? ` v${version}` : null}
            </S.Title>
          </Tooltip>
        </Col>
        <Col span={6}>
          <S.Info>{formatDateTime(date)}</S.Info>
        </Col>
        <Col span={debug ? 8 : 24} offset={debug ? 3 : 0} className="middle">
          <AssessmentGrade
            stepCount={parts}
            noScore={scoreType === GQL_ScoreType.FEEDBACK_ONLY}
            grade={grade}
            incompleteAssessment={incompleteAssessment}
            notStartedAssessment={notStartedAssessment}
          />
        </Col>
        {debug && (
          <S.Debug>
            <Col span={12} style={{ maxWidth: '100%' }}>
              <S.DebugRow>
                <S.DebugTitle>isFinished: </S.DebugTitle>
                <S.DebugInfo>{isFinished ? 'true' : 'false'}</S.DebugInfo>
              </S.DebugRow>
              <S.DebugRow>
                <S.DebugTitle>Completion: </S.DebugTitle>
                <S.DebugInfo>{debugItem?.completion.toFixed(2)}</S.DebugInfo>
              </S.DebugRow>
              <S.DebugRow>
                <S.DebugTitle>last step completed:</S.DebugTitle>
                <S.DebugInfo>{debugItem?.steps && last(debugItem?.steps)?.completed ? 'true' : 'false'}</S.DebugInfo>
              </S.DebugRow>
              <S.DebugRow>
                <S.DebugTitle>is locked:</S.DebugTitle>
                <S.DebugInfo>{debugItem?.classStudent?.isLocked ? 'true' : 'false'}</S.DebugInfo>
              </S.DebugRow>
              <S.DebugRow>
                <S.DebugTitle>Student end date:</S.DebugTitle>
                <S.DebugInfo>{formatDateTime(debugItem?.classStudent?.endDate)}</S.DebugInfo>
              </S.DebugRow>
              <S.DebugRow>
                <S.DebugTitle>Student start date:</S.DebugTitle>
                <S.DebugInfo>{formatDateTime(debugItem?.classStudent?.startDate)}</S.DebugInfo>
              </S.DebugRow>
              <S.DebugRow>
                <S.DebugTitle>assessment end date:</S.DebugTitle>
                <S.DebugInfo>{formatDateTime(debugItem?.dueDate)}</S.DebugInfo>
              </S.DebugRow>
              <S.DebugRow>
                <S.DebugTitle>assessment start date:</S.DebugTitle>
                <S.DebugInfo>{formatDateTime(debugItem?.startDate)}</S.DebugInfo>
              </S.DebugRow>
            </Col>
          </S.Debug>
        )}
      </Row>
      <Row style={{ marginTop: '2em' }} gutter={5}>
        <Col span={8}>
          <S.ReportButton
            data-cy="components-finished-assessments-row-item-viewcurrentsubmission"
            text="View current submission"
            disabled={notStartedAssessment}
            theme={themeConfig.primaryColor}
            htmlType="button"
            onClick={goToSubmission}
            block
          />
        </Col>
        <Col span={8}>
          <Tooltip title="Not available">
            <S.ReportButton
              data-cy="components-finished-assessments-row-item-requestnewsubmission"
              text="Request new submission"
              disabled={grade === null || true} // TODO: remove true when resubmission is implemented
              theme={themeConfig.primaryColor}
              htmlType="button"
              onClick={goToDashboard}
              block
            />
          </Tooltip>
        </Col>
        <Col span={8}>
          <S.ReportButton
            data-cy="components-finished-assessments-row-item-viewfeedback"
            text="View feedback"
            disabled={grade === null || scoreType === GQL_ScoreType.SCORE_ONLY}
            theme={themeConfig.primaryColor}
            htmlType="button"
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '2em', alignItems: 'center' }}>
        {isFinished ? (
          <>
            <Col span={10} offset={2}>
              <S.Title>Submitted: </S.Title>
            </Col>
            <Col span={9}>
              <S.Info $align={'left'}>
                {submittedAt ? formatDateTime(submittedAt, 'MM.dd.yyyy HH:mm:ss') : 'Not submitted'}
              </S.Info>
            </Col>
          </>
        ) : isClosed ? (
          <>
            <Col span={10} offset={2}>
              <S.Title>Submitted: </S.Title>
            </Col>
            <Col span={6}>
              <S.Info>{isStarted ? formatDateTime(date) : 'Not submitted'}</S.Info>
            </Col>
          </>
        ) : (
          <>
            <S.Empty />
          </>
        )}
      </Row>
    </S.Card>
  );
};

export default Item;
