import styled from 'styled-components';

export const Container = styled.div`
  padding: 35px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 6px;

  .ant-upload {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  & > span {
    margin-bottom: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;

    a {
      height: 26px;
    }

    & > svg {
      color: ${(props) => props.theme.primaryColor.background};
      cursor: pointer;
    }
  }
`;

export const FileContainer = styled.a`
  color: ${(props) => props.theme.primaryColor.background} !important;
  border-color: ${(props) => props.theme.secondaryColor.background} !important;
  background: #fafafa;
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 64px 10px;
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 8px;
  font-size: 16px;

  span {
    margin-top: 8px;
    margin-bottom: 0;
  }

  &:hover {
    color: rgb(56, 160, 249);
    border: 1px dashed rgb(56, 160, 249);
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;

  & > span {
    width: 100%;
  }

  & > svg {
    margin-left: 8px;
    color: ${(props) => props.theme.primaryColor.background};
    cursor: pointer;
  }
`;

export const DriveStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    height: auto !important;
  }

  p {
    margin-bottom: 32px;
    margin-top: 16px;
    color: ${(props) => props.theme.primaryColor.background};
    text-align: center;
    font-size: 16px;
  }
`;

export const IconStatusContainer = styled.div<{ completed?: boolean }>`
  background-color: ${(props) => (props.completed ? '#2ecc71' : '#c4c4c4')};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 16px;
`;
