/* eslint-disable max-statements, max-lines */
import { useMutation, useQuery } from '@apollo/client';
import { Col, Dropdown, Menu, message, Row, Table, Tooltip } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import Papa from 'papaparse';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BsExclamationCircle, BsExclamationCircleFill } from 'react-icons/bs';
import { FiChevronDown, FiDownload, FiFilter, FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import Avatar from '../../../shared/Avatar';
import Button from '../../../shared/Button';
import TableCheckboxGroup from '../../../shared/TableCheckboxGroup/TableCheckboxGroup';
import TableSearchBox from '../../../shared/TableSearchBox';
import TagInput from '../../../shared/TagInput';
import { GQL_InvestigationTeacherEntry } from '../../../types/investigation';
import { GQL_ClassIdAndName, GQL_ClassResponse, GQL_Investigation, GQL_StudentResponse } from '../../../types/class';
import { GQL_SendInviteResponse } from '../../../types/invites';
import { TagResponse } from '../../../types/tags';
import { centerAlign, Filter, Breakpoint } from '../../../utils/antd';
import { downloadCsv } from '../../../utils/files';
import { themeConfig } from '../../../utils/theme';
import AddClassToStudent from '../../ClassDashboard/AddClassToStudent';
import AddStudents from '../../ClassDashboard/AddStudents';
import ManageGroups from '../../ClassDashboard/Groups';

import * as S from './styles';

const TableBreakPoint: Breakpoint[] = ['lg'];
const Current = () => {
  // refs / hooks
  const refName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const history = useHistory();

  // variables
  const existingParams = new URLSearchParams(history.location.search);

  // states
  const [disclaimerHover, setDisclaimerHover] = useState(-1);

  // name filter
  const [nameToFilter, setNameToFilter] = useState(existingParams.get('name') || '');
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [nameToFilterInternal, setNameToFilterInternal] = useState(nameToFilter);
  const [nameToFilterEmpty, setNameToFilterEmpty] = useState<boolean>(false);

  // email filter
  const [emailToFilter, setEmailToFilter] = useState(existingParams.get('email') || '');
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const [emailToFilterInternal, setEmailToFilterInternal] = useState(emailToFilter);
  const [emailToFilterEmpty, setEmailToFilterEmpty] = useState<boolean>(false);

  // prettier-ignore
  const [assignmentsToFilter, setAssignmentsToFilter] = useState<string[]>(existingParams.get('assignments')?.split(',') || []);
  const [searchAssignmentsVisible, setSearchAssignmentsVisible] = useState(false);
  const [assignmentsToFilterInternal, setAssignmentsToFilterInternal] = useState(assignmentsToFilter);
  const [assignmentsToFilterEmpty, setAssignmentsToFilterEmpty] = useState<boolean>(false);
  const [classes, setClasses] = useState<Filter[]>([]);

  // investigation filter
  const [invToFilter, setInvToFilter] = useState<string[]>(existingParams.get('investigations')?.split(',') || []);
  const [searchInvVisible, setSearchInvVisible] = useState(false);
  const [invToFilterInternal, setInvToFilterInternal] = useState(invToFilter);
  const [invToFilterEmpty, setInvToFilterEmpty] = useState<boolean>(false);
  const [investigations, setInvestigations] = useState<Filter[]>([]);

  // tag filter
  const [searchTagsVisible, setSearchTagsVisible] = useState(false);
  const [tagsToFilter, setTagsToFilter] = useState<string[]>(existingParams.get('tags')?.split(',') || []);
  const [tagsToFilterInternal, setTagsToFilterInternal] = useState(tagsToFilter);
  const [tagsToFilterEmpty, setTagsToFilterEmpty] = useState<boolean>(false);
  const [tags, setTags] = useState<Filter[]>([]);

  // Add student
  const [addClassVisible, setAddClassVisible] = useState(false);
  const [addStudentsVisible, setAddStudentsVisible] = useState(false);
  const [manageGroupsVisible, setManageGroupsVisible] = useState(false);
  const [classInfo, setClassInfo] = useState<GQL_ClassResponse>();
  const [classesList, setClassesList] = useState<GQL_ClassResponse[]>([]);

  useQuery(gqlSchema.ClassSchema.query.CLASS.CLASSES.getClasses, {
    onCompleted: ({ getClasses }: { getClasses: GQL_ClassResponse[] }) => {
      const classes = [...getClasses].sort((a, b) => (a.endDate > b.endDate ? -1 : 1));
      setClassesList(classes);
    },
    onError: (error) => {
      message.error(
        error.message || 'There was an error loading your classes, check you connection and try again later.',
      );
    },
  });

  const { data, loading } = useQuery(gqlSchema.TeacherSchema.queries.STUDENTS.getStudents, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      message.error('There was an error loading your students: ' + err.message || 'Unexpected Error');
    },
    onCompleted: ({ getStudents }: { getStudents: GQL_StudentResponse[] }) => {
      setClasses(
        getStudents
          .reduce((result: Filter[], student: GQL_StudentResponse) => {
            student.classes.forEach((cls: GQL_ClassIdAndName) => {
              if (!result.some((r) => r.value === cls.classId))
                result.push({ text: `${cls.className}`, value: cls.classId });
            });

            return result;
          }, [])
          .sort((a: Filter, b: Filter) => (a.value > b.value ? 1 : -1)),
      );
      setInvestigations(
        getStudents
          .reduce((result: Filter[], student: GQL_StudentResponse) => {
            student.classes.forEach((cls: GQL_ClassIdAndName) => {
              cls.investigations.forEach((investigation: GQL_Investigation) => {
                if (!result.some((r) => r.value === investigation.id))
                  result.push({ text: `${investigation.title}`, value: investigation.id });
              });
            });
            return result;
          }, [])
          .sort((a: Filter, b: Filter) => (a.value > b.value ? 1 : -1)),
      );
    },
  });

  const { data: inv } = useQuery<{ getInvestigationsByTeacher: GQL_InvestigationTeacherEntry[] }>(
    gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationsByTeacher,
    {
      onError: (error) => {
        message.error(
          error.message ||
            'There was an error loading your active investigations, check you connection and try again later.',
        );
      },
    },
  );

  useQuery<{ getTags: TagResponse[] }>(gqlSchema.TagsSchema.queries.getTags, {
    onCompleted: (data) => {
      setTags(data?.getTags?.map((t) => ({ text: t.tag, value: t.id, color: t.color })));
    },
    onError: (err) => {
      message.error('There was an error loading tags: ' + err.message || 'Unexpected Error');
    },
  });

  const exportStudents = useCallback(() => {
    const headers = [['First Name', 'Last Name', 'Email', 'Tags', 'Classes']];
    const csvData =
      data?.getStudents.map((student: GQL_StudentResponse) => {
        return [
          student.firstName,
          student.lastName,
          student.email,
          student.tags?.map((t) => t.tag),
          student.classes.reduce((result: string, cls: GQL_ClassIdAndName, index: number) => {
            result += `${index > 0 ? ' ' : ''} ${cls.className}`;
            return result;
          }, '') || 'No Class Assigned',
        ];
      }) || [];

    downloadCsv(Papa.unparse([...headers, ...csvData]), `Students.csv`);
  }, [data]);

  const exportMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={exportStudents}>
          .csv
        </Menu.Item>
      </Menu>
    ),
    [exportStudents],
  );

  const getKey = useCallback((record: GQL_StudentResponse) => {
    return record.userId || record.inviteId || Date.now().toString();
  }, []);

  const [sendInvites] = useMutation<{ sendInvites: GQL_SendInviteResponse[] }>(
    gqlSchema.InvitesSchema.mutations.SEND.sendInvites,
    {
      onError: (err) => {
        message.error('There was an error trying to send the invite, please try again later');
      },
      update(cache, { data }) {
        if (data?.sendInvites) {
          const detailedNotification = data.sendInvites.length < 3;
          if (!detailedNotification) message.success(`Invites sent successfully`);

          data.sendInvites.forEach((std: GQL_SendInviteResponse) => {
            const student = {
              __typename: 'StudentResponse',
              inviteId: std.token,
              userId: null,
            };

            cache.modify({
              id: cache.identify(student),
              fields: {
                emailSent(cachedEmailSent, { readField }) {
                  if (std.emailSent && detailedNotification)
                    message.success(`Invite sent to ${readField('firstName')} ${readField('lastName')}`);
                  return std.emailSent;
                },
              },
            });
          });
        }
      },
    },
  );

  const onViewStudent = useCallback(
    (student: GQL_StudentResponse) => {
      history.push({ pathname: `/teacher-dashboard/student/${student.userId}`, search: history.location.search });
    },
    [history],
  );

  const onResendInvite = useCallback(
    async (tokens: string[]) => {
      await sendInvites({
        variables: {
          data: {
            tokens,
          },
        },
      });
    },
    [sendInvites],
  );

  const resendInviteTooltip = useCallback(
    (record: GQL_StudentResponse, index: number) =>
      !record.userId ? (
        <S.SendInviteDisclaimer
          onMouseEnter={() => setDisclaimerHover(index)}
          onMouseLeave={() => setDisclaimerHover(-1)}
        >
          <Tooltip
            title={
              <>
                {record.emailSent
                  ? `${record.firstName} ${record.lastName} hasn’t accepted the invite yet.`
                  : `The invitation has not been sent to ${record.firstName} ${record.lastName} yet.`}
                <S.ResendInvite onClick={() => onResendInvite([record.inviteId || ''])}>
                  {record.emailSent ? 'Resend Invite' : 'Send Invite'}
                </S.ResendInvite>
              </>
            }
            placement="bottom"
          >
            {disclaimerHover === index ? (
              <BsExclamationCircleFill size={24} style={{ cursor: 'pointer', float: 'right' }} />
            ) : (
              <BsExclamationCircle size={24} style={{ cursor: 'pointer', float: 'right' }} />
            )}
          </Tooltip>
        </S.SendInviteDisclaimer>
      ) : null,
    [disclaimerHover, onResendInvite],
  );

  const activeFilter = (investigation: GQL_InvestigationTeacherEntry, type: string) => {
    const isAssessment = type === 'Assessments';

    return (
      investigation.completion !== 1 &&
      investigation.dueDate > Date.now() &&
      investigation.isAssessment === isAssessment
    );
  };

  const activeInvestigations = useCallback(
    (record: GQL_StudentResponse) =>
      inv?.getInvestigationsByTeacher
        ?.filter((f) => record.classes.find((cl) => cl.classId === f.classId))
        .filter((investigation) => activeFilter(investigation, 'Investigations')) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classInfo, inv],
  );

  const activeAssessments = useCallback(
    (record: GQL_StudentResponse) =>
      inv?.getInvestigationsByTeacher
        ?.filter((f) => record.classes.find((cl) => cl.classId === f.classId))
        .filter((investigation) => activeFilter(investigation, 'Assessments')) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classInfo, inv],
  );

  const buildActiveTags = useCallback(
    (type: string, color: string, record: GQL_StudentResponse) => {
      let data: GQL_InvestigationTeacherEntry[];
      if (type === 'Investigations') {
        data = activeInvestigations(record);
      } else {
        data = activeAssessments(record);
      }

      const firstInvestigation = data[0];
      const totalInvestigations = data.length;

      return totalInvestigations > 0 ? (
        <>
          <S.TagButton
            text={`${firstInvestigation?.title}`}
            display="inline"
            background={color}
            shape="round"
            key={`${record.userId || record.inviteId}${firstInvestigation?.id}`}
            minHeight={24}
          />
          {totalInvestigations > 1 && (
            <Tooltip
              title={data
                .filter((i) => i.id !== firstInvestigation.id)
                .map((i) => (
                  <p style={{ marginBottom: 5 }} key={i.id}>
                    {i.title}
                  </p>
                ))}
            >
              <S.TagButton text={`+${totalInvestigations - 1}`} shape="round" minHeight={24} background={color} />
            </Tooltip>
          )}
        </>
      ) : (
        <S.TagButton text={`No active ${type}`} background="#c4c4c4" shape="round" minHeight={24} />
      );
    },
    [activeAssessments, activeInvestigations],
  );

  const investigationColumn = useCallback(
    (type: 'Investigations' | 'Assessments', color: string = '#4367E9') => {
      return {
        title: `Active ${type}`,
        responsive: TableBreakPoint,
        align: centerAlign,
        filters: investigations,
        filteredValue: invToFilter ?? [],
        filterDropdownVisible: searchInvVisible,
        hidden: type === 'Assessments',
        filterDropdown: () => (
          <TableCheckboxGroup
            value={invToFilterInternal}
            dataset={investigations}
            onCheckboxGroupChange={(values) => {
              const unique = values?.filter((item, pos, self) => self.indexOf(item) === pos) || [];
              setInvToFilterEmpty(false);
              setInvToFilterInternal(unique as string[]);
            }}
            onCheckboxGroupReset={() => {
              setInvToFilterEmpty(true);
              setInvToFilterInternal([]);
              setInvToFilter([]);
              setTimeout(() => setSearchInvVisible(false), 100);
            }}
            onCheckboxGroupOk={() => {
              setInvToFilterEmpty(invToFilterInternal.length === 0);
              setInvToFilter(invToFilterInternal);
              setTimeout(() => setSearchInvVisible(false), 100);
            }}
          />
        ),
        onFilter: (value: string | number | boolean, record: GQL_StudentResponse): boolean => {
          const inv = record.classes.filter((cls: GQL_ClassIdAndName) =>
            cls.investigations.some((r) => r.id === value.toString()),
          );
          return inv.length > 0;
        },
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchInvVisible}>
            <FiFilter size={18} style={{ color: filtered ? color : undefined }} />
          </S.SearchIcon>
        ),
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchInvVisible(visible);
        },
        render: (text: string, record: GQL_StudentResponse) => {
          return buildActiveTags(type, color, record);
        },
      };
    },
    [searchInvVisible, invToFilter, invToFilterInternal, investigations, buildActiveTags],
  );

  const columns = useMemo(
    () =>
      [
        {
          title: 'Student Name',
          fixed: 'left',
          sorter: (a: GQL_StudentResponse, b: GQL_StudentResponse) =>
            `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
          render: (text: string, record: GQL_StudentResponse, index: number) => {
            return (
              <S.CenteredTableCell>
                <Avatar backgroundColor="#FFFFFF" margin="0 5px" size={45} src={record?.avatar} />
                <span role="none" >
                  {record.firstName} {record.lastName}
                </span>
                {resendInviteTooltip(record, index)}
              </S.CenteredTableCell>
            );
          },
          width: '280px',
          filteredValue: nameToFilter ? [nameToFilter] : [],
          filterDropdown: (filterProps: FilterDropdownProps) => (
            <TableSearchBox
              {...filterProps}
              ref={refName}
              onClearFilters={() => {
                setNameToFilterEmpty(true);
                setNameToFilterInternal('');
                setNameToFilter('');
              }}
              updateSearch={() => {
                setNameToFilterEmpty(!!nameToFilterInternal);
                setNameToFilter(nameToFilterInternal);
              }}
              selectedKeys={[nameToFilterInternal]}
              setSelectedKeys={(value) => setNameToFilterInternal(value[0] as string)}
            />
          ),
          filterIcon: (filtered: boolean) => (
            <S.SearchIcon $searchVisible={searchNameVisible}>
              <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
            </S.SearchIcon>
          ),
          onFilter: (value: string | number | boolean, record: GQL_StudentResponse) => {
            if (!value) return true;
            return (
              record.firstName.toLowerCase().includes(value.toString().toLowerCase()) ||
              record.lastName.toLowerCase().includes(value.toString().toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible: boolean) => {
            setSearchNameVisible(visible);
            if (visible) {
              setTimeout(() => {
                if (refName && refName.current) {
                  refName.current.select();
                }
              }, 100);
            }
          },
        },
        {
          title: 'Student Email',
          align: centerAlign,
          dataIndex: 'email',
          width: '20%',
          responsive: TableBreakPoint,
          sorter: (a: GQL_StudentResponse, b: GQL_StudentResponse) => a.email.localeCompare(b.email),
          filteredValue: emailToFilter ? [emailToFilter] : [],
          filterDropdown: (filterProps: FilterDropdownProps) => (
            <TableSearchBox
              {...filterProps}
              ref={refName}
              onClearFilters={() => {
                setEmailToFilterEmpty(true);
                setEmailToFilterInternal('');
                setEmailToFilter('');
              }}
              updateSearch={() => {
                setEmailToFilterEmpty(!!emailToFilterInternal);
                setEmailToFilter(emailToFilterInternal);
              }}
              selectedKeys={[emailToFilterInternal]}
              setSelectedKeys={(value) => setEmailToFilterInternal(value[0] as string)}
            />
          ),
          filterIcon: (filtered: boolean) => (
            <S.SearchIcon $searchVisible={searchEmailVisible}>
              <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
            </S.SearchIcon>
          ),
          onFilter: (value: string | number | boolean, record: GQL_StudentResponse) => {
            if (!value) return true;
            return record.email.toLowerCase().includes(value.toString().toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible: boolean) => {
            setSearchEmailVisible(visible);
            if (visible) {
              setTimeout(() => {
                if (refEmail && refEmail.current) {
                  refEmail.current.select();
                }
              }, 100);
            }
          },
        },
        {
          title: 'Student Assignment',
          align: centerAlign,
          width: '20%',
          responsive: TableBreakPoint,
          filters: classes,
          filteredValue: assignmentsToFilter ?? [],
          filterDropdownVisible: searchAssignmentsVisible,
          filterDropdown: () => (
            <TableCheckboxGroup
              value={assignmentsToFilterInternal}
              dataset={classes}
              onCheckboxGroupChange={(values) => {
                const unique = values?.filter((item, pos, self) => self.indexOf(item) === pos) || [];
                setAssignmentsToFilterEmpty(false);
                setAssignmentsToFilterInternal(unique as string[]);
              }}
              onCheckboxGroupReset={() => {
                setAssignmentsToFilterEmpty(true);
                setAssignmentsToFilterInternal([]);
                setAssignmentsToFilter([]);
                setTimeout(() => setSearchAssignmentsVisible(false), 100);
              }}
              onCheckboxGroupOk={() => {
                setAssignmentsToFilterEmpty(assignmentsToFilterInternal.length === 0);
                setAssignmentsToFilter(assignmentsToFilterInternal);
                setTimeout(() => setSearchAssignmentsVisible(false), 100);
              }}
            />
          ),
          onFilter: (value: string | number | boolean, record: GQL_StudentResponse) => {
            if (record.classes.some((r: GQL_ClassIdAndName) => r.classId === value.toString())) return true;
            return false;
          },
          filterIcon: (filtered: boolean) => (
            <S.SearchIcon $searchVisible={searchAssignmentsVisible}>
              <FiFilter size={18} style={{ color: filtered ? '#1890ff' : undefined }} />
            </S.SearchIcon>
          ),
          onFilterDropdownVisibleChange: (visible: boolean) => {
            setSearchAssignmentsVisible(visible);
          },
          render: (text: string, record: GQL_StudentResponse) => {
            return record.classes.map((cls: GQL_ClassIdAndName) => (
              <S.TagButton
                text={`${cls.className}`}
                display="inline"
                background={cls.classColorHex}
                shape="round"
                key={`${record.userId || record.inviteId}${cls.classId}`}
                minHeight={24}
              />
            ));
          },
        },
        investigationColumn('Investigations'),
        investigationColumn('Assessments', '#e06500'),
        {
          title: 'Tags',
          dataIndex: 'tags',
          align: centerAlign,
          width: '200px',
          responsive: TableBreakPoint,
          filters: tags,
          filteredValue: tagsToFilter ?? [],
          filterDropdownVisible: searchTagsVisible,
          filterDropdown: () => (
            <TableCheckboxGroup
              value={tagsToFilterInternal}
              dataset={tags}
              onCheckboxGroupChange={(values) => {
                const unique = values?.filter((item, pos, self) => self.indexOf(item) === pos) || [];
                setTagsToFilterEmpty(false);
                setTagsToFilterInternal(unique as string[]);
              }}
              onCheckboxGroupReset={() => {
                setTagsToFilterEmpty(true);
                setTagsToFilterInternal([]);
                setTagsToFilter([]);
                setTimeout(() => setSearchTagsVisible(false), 100);
              }}
              onCheckboxGroupOk={() => {
                setTagsToFilterEmpty(tagsToFilterInternal.length === 0);
                setTagsToFilter(tagsToFilterInternal);
                setTimeout(() => setSearchTagsVisible(false), 100);
              }}
            />
          ),
          onFilter: (value: string | number | boolean, record: GQL_StudentResponse): boolean => {
            return !!record.tags?.map((t: TagResponse) => t.id)?.includes(value?.toString());
          },
          filterIcon: (filtered: boolean) => {
            const existingParams = new URLSearchParams(history.location.search);
            return (
              <S.SearchIcon $searchVisible={searchTagsVisible}>
                <FiFilter
                  size={18}
                  style={{
                    color: filtered || tagsToFilter.length > 0 || existingParams.get('tags')! ? '#1890ff' : undefined,
                  }}
                />
              </S.SearchIcon>
            );
          },
          onFilterDropdownVisibleChange: (visible: boolean) => {
            setSearchTagsVisible(visible);
          },
          render: (text: string, record: { tags?: TagResponse[] }) => {
            return <TagInput existingUserTags={record.tags} editable={false} />;
          },
        },
        {
          title: 'Student Details',
          align: centerAlign,
          fixed: 'right',
          render: (text: string, record: GQL_StudentResponse) => {
            return (
              <>
                {record.userId ? (
                  <Button text="View More" onClick={() => onViewStudent(record)} block />
                ) : (
                  <S.TagButton
                    text="No Details Available"
                    theme={themeConfig.noColor}
                    disabled
                    shape="round"
                    minHeight={24}
                  />
                )}
              </>
            );
          },
          width: '160px',
        },
      ].filter((item: any) => !item['hidden']),
    [
      nameToFilter,
      emailToFilter,
      classes,
      assignmentsToFilter,
      searchAssignmentsVisible,
      tags,
      tagsToFilter,
      searchTagsVisible,
      resendInviteTooltip,
      nameToFilterInternal,
      searchNameVisible,
      emailToFilterInternal,
      searchEmailVisible,
      assignmentsToFilterInternal,
      tagsToFilterInternal,
      history.location.search,
      onViewStudent,
      investigationColumn,
    ],
  );

  // side effects
  useEffect(() => {
    const existingParams = new URLSearchParams(history.location.search);

    if (nameToFilter) {
      existingParams.set('name', nameToFilter || existingParams.get('name')!);
    } else if (nameToFilterEmpty) {
      existingParams.delete('name');
    }

    if (emailToFilter) {
      existingParams.set('email', emailToFilter || existingParams.get('email')!);
    } else if (emailToFilterEmpty) {
      existingParams.delete('email');
    }

    if (assignmentsToFilter.length > 0) {
      existingParams.set('assignments', assignmentsToFilter.toString() || existingParams.get('assignments')!);
    } else if (assignmentsToFilterEmpty) {
      existingParams.delete('assignments');
    }

    if (invToFilter.length > 0) {
      existingParams.set('investigations', invToFilter.toString() || existingParams.get('investigations')!);
    } else if (invToFilterEmpty) {
      existingParams.delete('investigations');
    }

    if (tagsToFilter.length > 0) {
      existingParams.set('tags', tagsToFilter.toString() || existingParams.get('tags')!);
    } else if (tagsToFilterEmpty) {
      existingParams.delete('tags');
    }

    history.replace({ search: existingParams.toString() });
  }, [
    assignmentsToFilter,
    assignmentsToFilterEmpty,
    emailToFilter,
    emailToFilterEmpty,
    history,
    invToFilter,
    invToFilterEmpty,
    nameToFilter,
    nameToFilterEmpty,
    tagsToFilter,
    tagsToFilterEmpty,
  ]);

  // render
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={{ span: 3 }} xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 12 }}>
          <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
            <Button
              padding="4px 5px"
              style={{ minWidth: '200px' }}
              text={
                <>
                  Export as
                  <FiChevronDown style={{ marginLeft: 3 }} />
                </>
              }
              theme={themeConfig.noColor}
              icon={<FiDownload />}
              block
            />
          </Dropdown>
        </Col>
        <Col
          xxl={{ span: 4, offset: 17 }}
          xl={{ span: 4, offset: 16 }}
          lg={{ span: 4, offset: 15 }}
          md={{ span: 5, offset: 13 }}
          sm={{ span: 5, offset: 7 }}
        >
          <Button
            text="Add student"
            style={{ float: 'right', minWidth: '200px' }}
            theme={themeConfig.primaryColor}
            onClick={() => setAddClassVisible(true)}
          />
        </Col>
        <Col style={{ paddingTop: 0 }} span={24}>
          <S.TableWrapper>
            <Table
              columns={columns as any}
              loading={loading}
              rowKey={getKey}
              bordered
              dataSource={data?.getStudents}
              scroll={{ x: 'max-content' }}
              sticky
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <AddClassToStudent
        visible={addClassVisible}
        setVisible={setAddClassVisible}
        onSelectClass={(selectedClass?: GQL_ClassResponse) => {
          setClassInfo(selectedClass);
          setAddStudentsVisible(true);
        }}
        classesList={classesList}
      />
      <AddStudents
        visible={addStudentsVisible}
        setVisible={setAddStudentsVisible}
        openGroupManagment={setManageGroupsVisible}
        classInfo={classInfo}
        updateClassInfo={(e: GQL_ClassResponse) => setClassInfo(e)}
      />
      <ManageGroups visible={manageGroupsVisible} setVisible={setManageGroupsVisible} classInfo={classInfo} />
    </>
  );
};

export default Current;
