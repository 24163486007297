import styled from 'styled-components';
import {Col} from 'antd';
import { getInvestigationBackgroundColor } from '../../utils/investigationLogos';

export const CardContainer = styled.div<{ hasBanner?: boolean; hasIcon?: boolean }>`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;
  padding: 32px 40px;
  text-align center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: white;
  position: relative;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1750px) {
    padding: 32px 20px;
  }

  .iconsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 16px;
    max-width: 40%;

    svg:not(:last-child) {
      margin-right: 8px;
    }
    a:not(:last-child) {
      margin-right: 8px;
    }
  }

  & > span {
    position: absolute;
    ${(props) => (props.hasBanner ? 'right' : 'left')}: 16px;
    top: ${(props) => (props.hasIcon && props.hasBanner ? 40 : 12)}px;
    max-width: 40%;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-align: right;

    color: #767676;
  }

  & > h2 {
    margin-bottom: 12px;
    font-size: 22px;
    line-height: 1.2;
  }

  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 129.1%;

    color: #767676;
    margin-bottom: 16px;
  }

  & > button {
    margin-top: 16px;
  }

  .ant-skeleton-input {
    width: 250px;
    border-radius: 4px;
  }

  .ant-skeleton-input-sm {
    width: 120px;
    height: 16px;
  }

  .ant-skeleton-input-lg {
    width: 280px;
  }

  .ant-skeleton-avatar {
    height: 92px;
    width: 92px;
  }

  .ant-skeleton-button {
    height: 40px;
    width: 180px;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
`;

export const StateIcons = styled.div`
  position: absolute;
  left: 0;
  top: 24px;

  display: inline-flex;
  flex-direction: row;

  & div {
    position: relative;
    overflow: hidden;

    &:hover {
      z-index: 20;
      transition: all 0.2s ease;
      transform: scale(1.2);
    }
  }

  & div:not(:first-child) {
    margin-left: -1.5rem;
  }
`;

export const StateIconContainer = styled.div`
  background-color: white;
  border-radius: 50%;
  border: 1px solid #4367e9;
  padding: 16px;
  height: 47px;
  width: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
    color: transparent;
  }
`;

export const DisciplineIconContainer = styled.div<{ subject?: string }>`
  background: ${(props) => getInvestigationBackgroundColor({ subject: props.subject })};
  border-radius: 50%;
  padding: 16px;
  height: 92px;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  svg {
    width: 60px;
    height: 60px;
    color: transparent;
  }
`;

export const BadgeContainer = styled.div<{ color: string }>`
  display: inline-block;
  border: 2px solid ${(props) => props.color};
  color: ${(props) => props.color};
  padding: 4px 6px;
  flex:auto;
  padding-bottom: 2px;
  border-radius: 6px;
  cursor: default;
  margin: 2px;
  font-size: small;
`;

export const DraftBanner = styled.div<{ deleted?: boolean }>`
  position: absolute;
  top: ${(props) => (props.deleted ? 26 : 22)}px;
  left: ${(props) => (props.deleted ? -34 : -37)}px;
  transform: rotateY(0deg) rotate(-45deg);
  background: ${(props) => props.theme.error.background};
  padding: 0 32px;
  color: white;
  font-size: 12px;
`;

export const RecentlyAddedBanner = styled.div<{ top?: number; left?: number }>`
  position: absolute;
  top: 30px;
  left: -37px;
  transform: rotateY(0deg) rotate(-45deg);
  background: ${(props) => props.theme.secondaryColor.background};
  padding: 0 32px;
  color: white;
  font-size: 12px;
`;

export const PopoverText = styled.p`
  text-align: center;
  font-size: 0.9rem;
  color: ${(props) => props.theme.primaryColor.background};
  text-align: center;
  margin: 0;
  font-weight: 500;

  a {
    font-weight: 700;
    text-decoration-line: underline;
    color: ${(props) => props.theme.primaryColor.background};
  }
`;

export const Line = styled(Col)`
  .ant-form-item {
    margin: 0;
  }
`;

export const TitleInput = styled.div<{
  $padding?: string;
}>`
  font-weight: 600;
  padding: ${(props) => (props.$padding ? props.$padding : ' 0 11px')};
  font-size: 1.1em;
  margin: 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  align-items: center;
  .ant-progress {
    flex: 1;
    margin-left: 16px;
  }
`;
