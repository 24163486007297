import React, { useState } from 'react';
import * as S from './styles';
import Img1 from '../../../../../assets/booklanding/learnmore/task1.jpg';
import Img2 from '../../../../../assets/booklanding/learnmore/task2.png';
import Img3 from '../../../../../assets/booklanding/learnmore/task3.png';
import Img4 from '../../../../../assets/booklanding/learnmore/task4.png';
import Img5 from '../../../../../assets/booklanding/learnmore/task5.jpg';
import Img6 from '../../../../../assets/booklanding/learnmore/task6.png';
const LearnStage: React.FC = () => {
  const [activeProgramTab, setActiveProgramTab] = useState<string>('Task');

  const renderContentForTab = () => {
    switch (activeProgramTab) {
      case 'Task':
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>RESEARCH</S.Title>
              <span>Stage 1: Task</span>
              <p >
                Introduction of a phenomenon, guiding question, and 
                initial group discussions to promote  curiosity and the identification of gaps in current
                explanations.
              </p>
              <a href="http://www.youtube.com/watch?v=ad_MQ2YPaqw " target="_blank" rel="noreferrer">
              <S.Button>Watch now</S.Button></a>
            </S.HeaderContent>
            <S.ImageContainer>
              <S.PImage src={Img1} alt="Classroom Image" />
            </S.ImageContainer>
          </S.Card>
        );
      case 'IDEAS':
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>RESEARCH</S.Title>
              <span>Stage 2: Ideas</span>
              <p>
                Reading disciplinary texts about key ideas, followed by group discussions  to identify the ideas
                that are most useful.
              </p>
              <a href="http://www.youtube.com/watch?v=zKP3X7JLhtI " target="_blank" rel="noreferrer">
              <S.Button>Watch now</S.Button></a>
            </S.HeaderContent>
            <S.ImageContainer>
              <S.PImage src={Img2} alt="Classroom Image" />
            </S.ImageContainer>
          </S.Card>
        );
      case 'PLAN':
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>RESEARCH</S.Title>
              <span>Stage 3: Plan</span>
              <p>
                Students explore tools, create a detailed investigation plan, and receive  feedback to refine
                their approach.
              </p>
              <a href="http://www.youtube.com/watch?v=gfOaZbLNHU8 " target="_blank" rel="noreferrer">
              <S.Button>Watch now</S.Button></a>
            </S.HeaderContent>
            <S.ImageContainer>
              <S.PImage src={Img3} alt="Classroom Image" />
            </S.ImageContainer>
          </S.Card>
        );
      case 'DO':
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>RESEARCH</S.Title>
              <span>Stage 4: Do</span>
              <p>
                Implementation of the plan, data collection, analysis, and formulation of  tentative answers based
                on evidence.
              </p>
              <a href="http://www.youtube.com/watch?v=Un2yiQP8DxY" target="_blank" rel="noreferrer">
              <S.Button>Watch now</S.Button></a>
            </S.HeaderContent>
            <S.ImageContainer>
              <S.PImage src={Img2} alt="Classroom Image" />
            </S.ImageContainer>
          </S.Card>
        );
      case 'SHARE':
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>RESEARCH</S.Title>
              <span>Stage 5: Share</span>
              <p>
                Students create and present evidence-based arguments, engage in peer  review, and revise their
                findings.
              </p>
              <a href="http://www.youtube.com/watch?v=Q15PFmbsWlQ" target="_blank" rel="noreferrer">
              <S.Button>Watch now</S.Button></a>
            </S.HeaderContent>
            <S.ImageContainer>
              <S.PImage src={Img4} alt="Classroom Image" />
            </S.ImageContainer>
          </S.Card>
        );
      case 'REPORT':
        return (
          <S.Card>
            <S.HeaderContent >
              <S.Title>RESEARCH</S.Title>
              <span>Stage 7: Report</span>
              <p>
                Structured report writing on the investigation, peer review for quality,   and final  revisions to
                solidify understanding.
              </p>
              <a href="http://www.youtube.com/watch?v=SmesXh7LyFE" target="_blank" rel="noreferrer">
              <S.Button>Watch now</S.Button></a>
            </S.HeaderContent>
            <S.ImageContainer>
              <S.PImage src={Img5} alt="Classroom Image" />
            </S.ImageContainer>
          </S.Card>
        );
      case 'REFLECT':
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>RESEARCH</S.Title>
              <span>Stage 6: Reflect</span>
              <p>
                Class discussions on key ideas and self-evaluation of disciplinary  practices
                to reinforce learning.
              </p>
              <a href="http://www.youtube.com/watch?v=w0ze6ciXcJU " target="_blank" rel="noreferrer">
              <S.Button>Watch now</S.Button></a>
            </S.HeaderContent>
            <S.ImageContainer>
              <S.PImage src={Img6} alt="Classroom Image" />
            </S.ImageContainer>
          </S.Card>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* Stages of the ADI Model */}
      <S.PTitle>Stages of the ADI Model</S.PTitle>
      <S.PSection>
        <S.Tabs>
          <S.Tab active={activeProgramTab === 'Task'} onClick={() => setActiveProgramTab('Task')}>
            Task
          </S.Tab>
          <S.Tab active={activeProgramTab === 'IDEAS'} onClick={() => setActiveProgramTab('IDEAS')}>
            IDEAS
          </S.Tab>
          <S.Tab active={activeProgramTab === 'PLAN'} onClick={() => setActiveProgramTab('PLAN')}>
            PLAN
          </S.Tab>
          <S.Tab active={activeProgramTab === 'DO'} onClick={() => setActiveProgramTab('DO')}>
            DO
          </S.Tab>
          <S.Tab active={activeProgramTab === 'SHARE'} onClick={() => setActiveProgramTab('SHARE')}>
            SHARE
          </S.Tab>
          <S.Tab active={activeProgramTab === 'REFLECT'} onClick={() => setActiveProgramTab('REFLECT')}>
            REFLECT
          </S.Tab>
          <S.Tab active={activeProgramTab === 'REPORT'} onClick={() => setActiveProgramTab('REPORT')}>
            REPORT
          </S.Tab>
        </S.Tabs>
        {renderContentForTab()}
      </S.PSection>
    </>
  );
};

export default LearnStage;
