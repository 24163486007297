import React, { useCallback, useMemo, useState } from 'react';
import * as S from './styles';
import { withRouter } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table/interface';
import { centerAlign } from '../../utils/antd';
import { Col, Row, Table, Popconfirm, message } from 'antd';
import Button from '../../shared/Button';
import useFetchWhitelistedDomains from '../../hooks/useFetchWhitelistedDomains';
import { GQL_WhitelistedDomain } from '../../types/whitelistedDomain';
import { themeConfig } from '../../utils/theme';
import { gqlSchema } from '../../gql/schema';
import { useMutation } from '@apollo/client';
import { WhitelistDomainModal } from './components';

const WhitelistDomainPage: React.FC = () => {
  const { whitelistedDomains, whitelistedDomainsLoading } = useFetchWhitelistedDomains();
  const [addDomainModalVisible, setAddDomainModalVisible] = useState(false);

  const [removeDomain, { loading: removingDomain }] = useMutation(
    gqlSchema.WhitelistedDomainSchema.mutations.removeWhitelistedDomain,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: gqlSchema.WhitelistedDomainSchema.queries.getWhitelistedDomains }],
      onCompleted: (result: GQL_WhitelistedDomain) => {
        message.success('Domain removed');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const handleRemoveDomain = useCallback(
    (domainId: string) => {
      removeDomain({
        variables: {
          data: {
            id: domainId,
          },
        },
      });
    },
    [removeDomain],
  );

  const columns: ColumnsType<any> = useMemo(() => {
    return [
      {
        dataIndex: 'domain',
        fixed: 'left',
        align: 'left',
        title: 'Domain',
        render: (text: string, record: GQL_WhitelistedDomain, index: number) => {
          return <>{record.domain}</>;
        },
      },
      {
        dataIndex: 'action',
        fixed: 'left',
        width: '100px',
        align: centerAlign,
        title: '',
        render: (text: string, record: GQL_WhitelistedDomain, index: number) => {
          return (
            <Popconfirm
              placement="topLeft"
              title={`Are you sure to remove domain ${record.domain}`}
              onConfirm={() => handleRemoveDomain(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button text={'Remove'} theme={themeConfig.primaryOutlined} disabled={removingDomain} block />
            </Popconfirm>
          );
        },
      },
    ];
  }, [handleRemoveDomain, removingDomain]);

  return (
    <>
      <S.Container>
        <S.Title>Domains</S.Title>

        <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
          <Col span={18}>
            <h3>List of whitelisted domains</h3>
          </Col>
          <Col span={6}>
            <Button
              text={'Add domain to the list'}
              minWidth={120}
              disabled={removingDomain}
              onClick={() => setAddDomainModalVisible(true)}
              block
            />
          </Col>
        </Row>
        <S.TableWrapper>
          <Table
            columns={columns}
            pagination={false}
            bordered
            className="remove-horizontal-scroll"
            loading={whitelistedDomainsLoading}
            dataSource={whitelistedDomains || []}
            rowKey={(record) => record.id}
          />
        </S.TableWrapper>
      </S.Container>

      <WhitelistDomainModal
        visible={addDomainModalVisible}
        setVisible={setAddDomainModalVisible}
        actionCompleted={() => {
          setAddDomainModalVisible(false);
        }}
      />
    </>
  );
};

export default withRouter(WhitelistDomainPage);
