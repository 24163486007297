import styled from 'styled-components';

export const Title = styled.div`
  margin-left: 5px;
  display: inline-block;
  vertical-align: sub;
  width: 100%;
  font-weight: 400;
  font-size: 1.05em;
`;
