import styled from 'styled-components';

export const TitleInput = styled.p`
  font-weight: 600;
  font-size: 0.95em;
  margin: 0 0 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const DownloadTemplate = styled.div`
  display: table-cell;
  vertical-align: middle;
  position: absolute;
  top: 50%;
`;
