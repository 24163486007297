import React, { useMemo } from 'react';
import { Row, Col, Empty } from 'antd';
import startCase from 'lodash/startCase';
import DashboardRow from '../../DashboardRow';
import Item from './Item';

import { GQL_InvestigationDashboard } from '../../../types/investigation';
import DashboardLoadingCard from '../../DashboardLoadingCard';
import { isAssessmentClosed, isAssessmentFinished, isAssessmentStarted } from '../../../utils/investigation';
import { last } from 'lodash';

interface IFinishedAssessmentsRow {
  assessments?: GQL_InvestigationDashboard[];
  loading: boolean;
  error?: string;
}

const FinishedAssessmentsRow = (props: IFinishedAssessmentsRow) => {
  const { assessments, loading, error } = props;
  const finishedAssessments = useMemo(() => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
        </Row>
      );
    } else if (!assessments?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Finished Assessments" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {assessments.map((item) => {
            const isClosed = isAssessmentClosed(item);
            const isStarted = isAssessmentStarted(item);
            const isFinished = isAssessmentFinished(item);
            const activities = item.steps[0]?.activities;
            return (
              <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
                <Item
                  grade={item?.grade}
                  scoreType={item?.scoreType}
                  parts={item.steps[0]?.activities?.length}
                  progressTitle="Report Score"
                  submittedAt={activities && Array.isArray(activities) ? last(activities)?.completedAt : null}
                  date={item.classStudent?.endDate || item.dueDate}
                  investigationName={`${startCase(item.discipline?.name)}: ${item.title}`}
                  investigationId={item.id}
                  isClosed={isClosed}
                  isStarted={isStarted}
                  isFinished={isFinished}
                  version={item.submissionVersion}
                />
              </Col>
            );
          })}
        </Row>
      );
    }
  }, [assessments, loading]);

  return <DashboardRow title="Finished Assessments" items={finishedAssessments} error={error} />;
};

export default FinishedAssessmentsRow;
