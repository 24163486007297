import { gql } from '@apollo/client';

export const PAYMENTS_MUTATIONS = {
  CHECKOUT: {
    paymentIntent: gql`
      mutation createCheckout($priceId: String!) {
        createCheckout(priceId: $priceId) {
          id
          url
          updated
          pendingDowngrade
        }
      }
    `,
    cancelUserSubscription: gql`
      mutation cancelUserSubscription {
        cancelUserSubscription {
          success
          pendingDowngrade
        }
      }
    `,
  },
};

export default PAYMENTS_MUTATIONS;
