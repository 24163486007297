import { GQL_TeacherInvestigationFormat } from './teacher';

export interface GQL_AssessmentsScoresResponsePartsStandards {
  step: string;
  order: number;
  nextGenerationStandards: InvestigationStandard[];
  texasStandards: InvestigationStandard[];
}
export interface GQL_AssessmentsScoresResponse {
  assessmentId: string;
  assessmentTitle: string;
  startDate: number;
  standards: InvestigationStandard[];
  nextStandards: InvestigationStandard[];
  gradedStudents: number;
  unfinishedStudents: number;
  assessmentScore: number;
  maxAssessmentScore: number;
  assessmentPercent: number;
  performanceLevel: string;
  coreIdeas: CodeTextPair[];
  practices: CodeTextPair[];
  discipline: Discipline;
  baseInvestigationId: string;
  format: GQL_TeacherInvestigationFormat;
  focus: GQL_TeacherInvestigationFormat;
  graphData: GraphData;
  parts: GQL_AssessmentsScoresResponsePartsStandards[];
}

export interface GQL_ComparisonByIdResponse {
  typeTitle: string;
  assessmentId: string;
  baseInvestigationId: string;
  items: AssessmentComparisonItems[];
  submissionVersion?: number;
}

export interface GQL_ComparisonByStandardsResponse {
  standardCode: string;
  needsImprovement: number;
  approaching: number;
  meets: number;
  notAvailable: boolean;
}

interface AssessmentComparisonItems {
  index: number;
  notSubmittedOrGraded: number;
  below: number;
  approaching: number;
  meets: number;
  aboveBarText: string;
}

export enum PerfomanceLevel {
  NEEDS_IMPROVEMENT = 'Needs Improvement',
  APPROACHING_EXPECTATON = 'Approaching Expectation',
  MEETS_EXPECTATION = 'Meets Expectation',
}

export interface InvestigationStandard {
  id: string;
  abbreviatedStatement: string;
  fullStatement: string;
  code: string;
  grade: string;
  state: string;
  lastChangeDateTime: string;
}

interface CodeTextPair {
  code: string;
  text: string;
}

interface Discipline {
  name: string;
  gradeBand: string;
  subject: string;
}

interface GraphData {
  notSubmittedOrGraded: number;
  below: number;
  approaching: number;
  meets: number;
}
