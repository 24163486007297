import { gql } from '@apollo/client';

export const NOTIFICATION_MUTATIONS = {
  NOTIFICATION: {
    markNotificationsAsRead: gql`
      mutation MarkNotificationsAsRead($notificationIds: [String]!) {
        markNotificationsAsRead(notificationIds: $notificationIds)
      }
    `,
    markNotificationAsRead: gql`
      mutation MarkNotificationAsRead($notificationId: String!) {
        markNotificationAsRead(notificationId: $notificationId)
      }
    `,
    deleteNotification: gql`
      mutation DeleteNotification($notificationId: String!) {
        deleteNotification(notificationId: $notificationId)
      }
    `,
  },
};

export default NOTIFICATION_MUTATIONS;
