import { Badge, Col, Modal, Progress, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { getProgressColor } from '../../../../utils/theme';
import AssignmentSummary from '../../../CanvasStudentsDashboardPage/AssignmentSummary';
import * as S from './styles';
import { themeConfig } from '../../../../utils/theme';

interface Props {
  date: string;
  assignmentName: string;
  assignment: any;
  courseId: string;
  courseWorkId: string;
  className: React.ReactNode;
  courseWorkType: string;
  icon: React.ReactNode;
  assignmentId: string;
  courseData: any;
}

const Item: React.FC<Props> = ({
  date,
  assignmentName,
  assignment,
  className,
  assignmentId,
  courseId,
  courseWorkId,
  courseWorkType,
  icon,
  courseData,
}) => {
  const { data, loading, error } = useQuery(gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasSubmissionById, {
    variables: {
      assignment_id: assignmentId,
      course_id: courseId,
    },
  });

  const [progress, setProgress] = useState<number>(0);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const maxPoints = useMemo(() => {
    const points = assignment?.points_possible ?? 0;
    console.log('Course Data:', courseData);
    console.log('Max Points:', points);
    return points;
  }, [assignment?.points_possible, courseData]);

  const assignedGrade = useMemo(() => {
    const grade = data?.getCanvasSubmissionById?.grade ? parseFloat(data.getCanvasSubmissionById.grade) : 0;
    console.log('Assigned Grade:', grade);
    return grade;
  }, [data]);

  useEffect(() => {
    if (maxPoints > 0 && assignedGrade !== undefined) {
      const percentage = (assignedGrade / maxPoints) * 100;
      setProgress(percentage);
    }
  }, [maxPoints, assignedGrade]);

  const handleViewSummaryClick = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const handleClosePopup = useCallback(() => {
    setPopupVisible(false);
  }, []);

  // const capitalizeFirstLetter = (str: string) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  // };

  return (
    <S.Card>
      <Row align="middle" gutter={16}>
        <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </Col>
        <Col span={16} xxl={16} lg={16}  xs={12} xl={16}>
          <S.ClassCourseInfo>
            <S.Info style={{ fontSize: '1.2em',marginTop:isMobile?'-10px':'unset',marginLeft:isMobile?'-10px':'unset',fontWeight: 'bold', textAlign: 'left' }}>
              {className}
            </S.Info>
          </S.ClassCourseInfo>
        </Col>
        <Col span={4} style={{ position: 'relative' }}>
          <S.BadgeWrapper>
          <Badge
              count={<div style={{ textAlign: 'center' }}>{'Assignment'}</div>}
              style={{ backgroundColor: '#E9A643', borderRadius: '40px', color: '#fff', fontWeight: 500, fontSize: '12px', padding: '8px 16px', textAlign: 'center',display: 'inline-block' }}
            />
          </S.BadgeWrapper>
        </Col>
      </Row>
      <Row style={{ marginTop: '1em' }}>
        <Col span={24}>
          <S.AssignmentName>
            <span style={{
              fontWeight: 500,
              fontSize: '14px',
              color: '#333',
              margin: '0',
              display: 'block',
              textAlign: 'left',
              textTransform: 'capitalize',
            }}>
              {assignmentName}
            </span>
          </S.AssignmentName>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="middle">
          <Progress
            strokeLinecap="round"
            strokeWidth={9}
            style={{ marginTop: 30 }}
            strokeColor={getProgressColor(progress)}
            format={(percent) => (
              <>
                <S.Info $bold>{percent !== undefined ? Math.round(percent) : 0}%</S.Info>
                <S.InfoProgress>Total Score: {maxPoints}</S.InfoProgress>
              </>
            )}
            width={145}
            type="circle"
            percent={progress}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '3em' }}>
        <Col span={14} offset={5}>
          <S.DashboardButton
            theme={themeConfig.primaryOutlined}
            text="View Summary"
            htmlType="button"
            onClick={handleViewSummaryClick}
            block
          />
        </Col>
      </Row>
      {isPopupVisible && (
        <Spin spinning={loading}> {/* Show loading spinner while fetching data */}
          <Modal visible={isPopupVisible} onCancel={() => setPopupVisible(false)} footer={null} width={800}>
            <AssignmentSummary
              courseId={courseId}
              assignmentId={assignmentId}
              assignment={assignment}
              visible={true}
              onClose={handleClosePopup}
              isFinished={true}
              score={progress ?? 0}
              courseWorkData={data?.getCanvasSubmissionById}
              courseWorkError={error}
              courseWorkLoading={loading} />
          </Modal>
        </Spin>
      )}
    </S.Card>
  );
};

export default Item;
