import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { Form as AntdForm, Col, Row, message } from 'antd';
import Form from '../../shared/Form';
import * as S from './styles';
import { IChangePasswordProps } from '../../types/profile';
import Input from '../Input';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
const ChangePassword: React.FC<Props> = (props) => {
  const [form] = AntdForm.useForm();

  const [submitChangePassword, { loading }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.changePassword,
    {
      onCompleted: (response) => {
        if (response) {
          message.success('Your password was updated successfully');
          props.setVisible(false);
          form.resetFields();
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const onFinish = useCallback(
    async (values: IChangePasswordProps) => {
      const { confirm, ...data } = values;
      submitChangePassword({
        variables: {
          ...data,
        },
      });
    },
    [submitChangePassword],
  );

  return (
    <S.CModal
      visible={props.visible}
      width={800}
      onCancel={() => props.setVisible(false)}
      title={<S.Title data-cy="shared-changepassword-title">Change Your Password</S.Title>}
      footer={null}
    >
      <Form onFinish={onFinish} form={form}>
        <Row>
          <Col span={18} offset={3}>
            <S.TitleInput>CURRENT PASSWORD</S.TitleInput>
            <AntdForm.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: 'Please type your current password!',
                },
              ]}
            >
              <Input
                data-cy="shared-changepassword-field-currentpassword"
                placeholder="Type your current password here..."
                password
              />
            </AntdForm.Item>
          </Col>
          <Col span={18} offset={3}>
            <S.TitleInput>NEW PASSWORD</S.TitleInput>
            <AntdForm.Item
              name="newPassword"
              extra="Your password should contain at least 1 uppercase letter, 1 lowercase letter and 1 special character (@, #, $, !)"
              rules={[
                {
                  required: true,
                  message: 'Please type your new password!',
                },
                {
                  min: 12,
                  message: '- The minimum password length is 12.',
                },
                {
                  pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[$@!#%.:,()])\\S{12,50}$'),
                  message:
                    '- Please include at least one uppercase letter, one lowercase letter, and one special character ($@!#%.:,)',
                },
              ]}
            >
              <Input
                data-cy="shared-changepassword-field-newpassword"
                placeholder="Type your new password here..."
                password
              />
            </AntdForm.Item>
          </Col>
          <Col span={18} offset={3}>
            <S.TitleInput>CONFIRM PASSWORD</S.TitleInput>
            <AntdForm.Item
              name="confirm"
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your new password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input
                data-cy="shared-changepassword-field-confirmpassword"
                placeholder="Confirm your new password here..."
                password
              />
            </AntdForm.Item>
          </Col>
          <Col span={18} offset={3}>
            <AntdForm.Item>
              <S.SubmitButton
                data-cy="shared-changepassword-button-change"
                text="Change Password"
                htmlType="submit"
                loading={loading}
                block
              />
            </AntdForm.Item>
          </Col>
        </Row>
      </Form>
    </S.CModal>
  );
};

export default ChangePassword;
