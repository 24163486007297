import styled from 'styled-components';

export const QuestionBlock = styled.div`
  font-weight: 400;
  text-align: left;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid #cacaca;
  padding: 8px;
`;
