import React, { useCallback, useMemo, useRef, useState } from 'react';
import * as S from './styles';
import { Col, Layout, List, message, Pagination, Progress, Row, Skeleton, Steps } from 'antd';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_EngagementQuestion,
  GQL_EngagementQuestionInput,
  GQL_FinalReportQuestionnaire,
  GQL_FinalReportQuestionnaireInput,
  GQL_FinalReportQuestionnaireQuestion,
  GQL_FinalReportQuestionnaireQuestionInput,
  GQL_GradedEngagementQuestions,
  GQL_GradedFinalReportQuestionnaire,
  GQL_InvestigationDisplay,
  IUploadContentValue,
} from '../../types/investigation';
import InvestigationQuestionnaire from '../StudentInvestigation/InvestigationQuestionnaire';
import { useAuth } from '../../hooks/useAuth';
import { GQL_InvestigationSummaryResponse } from '../../types/teacher';
import { gradingEngagementAnswers, gradingFinalReportAnswers } from '../../utils/investigation';
import { getProgressColor } from '../../utils/theme';
import InvestigationContent from '../../shared/InvestigationContent';
import { FiArrowLeft } from 'react-icons/fi';
import Button from '../../shared/Button';
import InvestigationFileDisplay from '../../shared/InvestigationFileDisplay';
import Spacer from '../../shared/Spacer';

const { Step } = Steps;

type Props = RouteComponentProps<{
  investigationId: string;
  studentId: string;
}>;

const InvestigationGradingPage: React.FC<Props> = (props) => {
  const { investigationId, studentId } = props.match.params;
  const history = useHistory();
  const { isStudent, user, isTeacherOrFacilitator} = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';
  const [current, setCurrent] = useState(0);
  const [gradeFinalReportAnswers, setGradeFinalReportAnswers] = useState<GQL_FinalReportQuestionnaireInput[]>([]);
  const [gradeEngagementAnswers, setGradeEngagementAnwers] = useState<GQL_EngagementQuestionInput[]>([]);
  const [finalReportAverage, setFinalReportAverage] = useState(-1);
  const [engagementAverage, setEngagementAverage] = useState(-1);
  const [finalReportFile, setFinalReportFile] = useState('');
  const [finalReportMimeType, setFinalReportMimeType] = useState('');
  const [numPages, setNumPages] = useState(1);
  const pdfRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { loading: loadingInvestigation } = useQuery<
    { getInvestigationByIdForTeacher: GQL_InvestigationDisplay },
    { investigationId: string; classId: string } | { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationByIdForTeacher, {
    variables: { id: investigationId },
    onCompleted: (data) => {
      if (data && data.getInvestigationByIdForTeacher) {
        const lastStep =
          data.getInvestigationByIdForTeacher.steps[data.getInvestigationByIdForTeacher.steps.length - 1];
        const lastActivity = lastStep.activities[lastStep.activities.length - 1];
        const uploadReportContent = lastActivity.content.find((r) =>
          r.blocks.some((d) => d.type === 'UploadFinalReport'),
        );
        const uploadReportBlock = uploadReportContent?.blocks.find(
          (t) => t.type === 'UploadFinalReport' && t.userId === studentId,
        );
        const values = uploadReportBlock?.values as IUploadContentValue[];
        const finalReportUrl = values?.find((r) => r.url);
        setFinalReportMimeType(finalReportUrl?.MIMEtype || '');
        setFinalReportFile(finalReportUrl?.url || '');
      }
    },
    onError: (err) => {
      message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
    },
  });

  const { data: investigationProgressSummary } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId,
    },
    onError: () => {
      message.error(
        'There was an error loading the investigation details, please check your connection and try again later',
      );
    },
  });

  const { data: dataFinalReportGrading, loading } = useQuery<
    { getFinalReportGrading: GQL_GradedFinalReportQuestionnaire },
    { investigationId: string; userId: string }
  >(gqlSchema.InvestigationSchema.queries.GRADING.getFinalReportGrading, {
    variables: {
      investigationId,
      userId: studentId,
    },
    onCompleted: (data) => {
      setFinalReportAverage(data.getFinalReportGrading.grade);
      setGradeFinalReportAnswers(
        data.getFinalReportGrading.questionnaire.map((q: GQL_FinalReportQuestionnaire) => ({
          index: q.index,
          feedback: q.feedback,
          questions: q.questions.map((s: GQL_FinalReportQuestionnaireQuestion) => ({
            index: s.index,
            answer: s.answer,
            isValid: true,
          })),
        })),
      );
    },
    onError: (err) => {
      message.error('There was an error loading the grading: ' + err.message || 'Unexpected Error');
    },
  });

  const { data: dataEngagementGrading } = useQuery<
    { getEngagementGrading: GQL_GradedEngagementQuestions },
    { investigationId: string; userId: string }
  >(gqlSchema.InvestigationSchema.queries.GRADING.getEngagementGrading, {
    variables: {
      investigationId,
      userId: studentId,
    },
    onCompleted: (data) => {
      setEngagementAverage(parseFloat((data.getEngagementGrading.grade / 20).toFixed(1)));
      setGradeEngagementAnwers(
        data.getEngagementGrading.questions.map((q: GQL_EngagementQuestion) => ({
          index: q.index,
          answer: q.answer,
          isValid: true,
        })),
      );
    },
    onError: (err) => {
      message.error('There was an error loading the engagement grading: ' + err.message || 'Unexpected Error');
    },
  });

  const onSelectAnswer = useCallback(
    (questionIndex: number, subquestionIndex: number, answerIndex: number) => {
      const newGradeAnswers = gradeFinalReportAnswers.map((q: GQL_FinalReportQuestionnaireInput) => {
        if (q.index === questionIndex) {
          return {
            ...q,
            questions: q.questions.map((s: GQL_FinalReportQuestionnaireQuestionInput) => {
              if (subquestionIndex === s.index) {
                return {
                  ...s,
                  answer: answerIndex,
                  isValid: true,
                };
              }
              return { ...s };
            }),
          };
        }
        return { ...q };
      });
      setGradeFinalReportAnswers(newGradeAnswers);
    },
    [gradeFinalReportAnswers],
  );

  const onSelectEngagementAnswer = useCallback(
    (questionIndex: number, subquestionIndex: number, answerIndex: number) => {
      const newGradeAnswers = gradeEngagementAnswers.map((q: GQL_EngagementQuestionInput) => {
        if (subquestionIndex === q.index)
          return {
            ...q,
            answer: answerIndex,
            isValid: true,
          };
        return { ...q };
      });
      setGradeEngagementAnwers(newGradeAnswers);
    },
    [gradeEngagementAnswers],
  );
  const onLoadSuccess = (pdf: any) => {
    if (pdf) setNumPages(pdf?.numPages);
  };

  const onSaveFeedback = useCallback(
    (questionIndex: number, feedback: string) => {
      if (gradeFinalReportAnswers.length > 0) {
        const newGradeAnswers = gradeFinalReportAnswers.map((q: GQL_FinalReportQuestionnaireInput) => {
          if (q.index === questionIndex) {
            return {
              ...q,
              feedback: feedback,
            };
          }
          return { ...q };
        });

        setGradeFinalReportAnswers(newGradeAnswers);
      }
    },
    [gradeFinalReportAnswers],
  );

  const validateFinalReportAnswers = useCallback(() => {
    let isValid = true;
    if (gradeFinalReportAnswers.length > 0) {
      const newGradeAnswers = gradeFinalReportAnswers.map((q: GQL_FinalReportQuestionnaireInput) => {
        return {
          ...q,
          questions: q.questions.map((s: GQL_FinalReportQuestionnaireQuestionInput) => {
            const valid = s.answer !== null;

            if (!valid) isValid = false;
            return { ...s, isValid: valid };
          }),
        };
      });
      setGradeFinalReportAnswers(newGradeAnswers);
    }
    return isValid;
  }, [gradeFinalReportAnswers]);

  const validateEngagementAnswers = useCallback(() => {
    let isValid = true;
    if (gradeEngagementAnswers.length > 0) {
      const newGradeAnswers = gradeEngagementAnswers.map((q: GQL_EngagementQuestionInput) => {
        const valid = q.answer !== null;
        if (!valid) isValid = false;
        return {
          ...q,
          isValid: valid,
        };
      });
      setGradeEngagementAnwers(newGradeAnswers);
    }
    return isValid;
  }, [gradeEngagementAnswers]);

  const steps = useMemo(
    () => [
      {
        title: 'Grading Final Report',
        content: (
          <InvestigationQuestionnaire
            hidePopoverGradeSystem={false}
            onSelectAnswer={onSelectAnswer}
            onFeedbackChange={onSaveFeedback}
            disableAnswers={(isStudent || isGoogleStudent || isCanvasStudent) || !user.isAllowedGrading}
            questionnaire={{
              questions:
                dataFinalReportGrading?.getFinalReportGrading?.questionnaire.map((q: GQL_FinalReportQuestionnaire) => {
                  const ans = gradeFinalReportAnswers.find(
                    (ans: GQL_FinalReportQuestionnaireInput) => ans.index === q.index,
                  );
                  return {
                    title: q.title,
                    needsFeedback: true,
                    feedback: ans?.feedback || q.feedback,
                    subquestions: q.questions.map((subquestion: GQL_FinalReportQuestionnaireQuestion, i: number) => {
                      const subQuestion = ans?.questions.find(
                        (r: GQL_FinalReportQuestionnaireQuestionInput) => r.index === i,
                      );
                      return {
                        text: subquestion.question,
                        answer: subQuestion?.answer,
                        isValid: subQuestion?.isValid || false,
                        options: gradingFinalReportAnswers,
                      };
                    }),
                  };
                }) || [],
            }}
          />
        ),
      },
      {
        title: 'Grading Engagement',
        content: (
          <InvestigationQuestionnaire
            hidePopoverGradeSystem={false}
            onSelectAnswer={onSelectEngagementAnswer}
            disableAnswers={(isStudent || isGoogleStudent || isCanvasStudent) || !user.isAllowedGrading}
            questionnaire={{
              questions: [
                {
                  title: 'Take a look at the engagement. Do you think the student has:',
                  needsFeedback: false,
                  subquestions:
                    dataEngagementGrading?.getEngagementGrading?.questions.map((q: GQL_EngagementQuestion) => {
                      const ans = gradeEngagementAnswers.find(
                        (ans: GQL_EngagementQuestionInput) => ans.index === q.index,
                      );
                      return {
                        text: q.question,
                        options: gradingEngagementAnswers,
                        answer: ans?.answer,
                        isValid: ans?.isValid || false,
                      };
                    }) || [],
                },
              ],
            }}
          />
        ),
      },
      {
        title: 'Done',
        content: (
          <S.SuccesContainer>
            <h1>
              You have graded the investigation successfully.
              <br />
              {finalReportAverage > -1 && (
                <Progress
                  strokeLinecap="round"
                  strokeWidth={9}
                  style={{ marginTop: 30 }}
                  strokeColor={getProgressColor(finalReportAverage || 0)}
                  format={(percent) => {
                    return (
                      <>
                        <S.Info $bold $fontSize="0.88em">
                          {percent}%
                        </S.Info>
                        <S.InfoProgress>Score</S.InfoProgress>
                      </>
                    );
                  }}
                  width={145}
                  type="circle"
                  percent={finalReportAverage || 0}
                />
              )}
              {engagementAverage > -1 && (
                <Row style={{ marginTop: '2em' }}>
                  <Col span={24}>
                    <S.Title>{engagementAverage}/5</S.Title>
                    <S.Stars value={engagementAverage} allowHalf={true} disabled={true} />
                    <S.Info>Engagement Rating</S.Info>
                  </Col>
                </Row>
              )}
            </h1>
          </S.SuccesContainer>
        ),
      },
    ],
    [
      onSelectAnswer,
      onSaveFeedback,
      isStudent,
      isGoogleStudent,
      isCanvasStudent,
      dataFinalReportGrading,
      onSelectEngagementAnswer,
      dataEngagementGrading,
      gradeFinalReportAnswers,
      gradeEngagementAnswers,
      finalReportAverage,
      engagementAverage,
      user.isAllowedGrading,
    ],
  );

  const loadingPlaceholder = useMemo(() => {
    const skeletons = [];
    for (let i = 0; i < 4; i++) {
      skeletons.push(
        <Skeleton key={i} loading active>
          <List.Item.Meta />
        </Skeleton>,
      );
    }
    return <S.Container>{skeletons}</S.Container>;
  }, []);

  const next = useCallback(() => {
    setCurrent(current + 1);
  }, [current]);

  const onChangeStep = useCallback(
    (current: number) => {
      if (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) {
        if (current === 0 || (current === 1 && validateFinalReportAnswers())) setCurrent(current);
      } else {
        setCurrent(current);
      }
    },
    [validateFinalReportAnswers, isTeacherOrFacilitator, isGoogleTeacher, isCanvasTeacher],
  );
  const [submitGradeFinalReport, { loading: loadingGradeFinalReport }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.GRADING.gradeFinalReport,
    {
      onCompleted: ({ gradeFinalReport }: { gradeFinalReport: number }) => {
        setFinalReportAverage(gradeFinalReport);
        next();
      },
      onError: (err) => {
        message.error('There was an error submiting grade: ' + err.message || 'Unexpected Error');
      },
      refetchQueries: ['GetFinalReportGrading', 'getInvestigationProgressSummary'],
    },
  );

  const [submitGradeEngagement, { loading: loadingGradeEngagement }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.GRADING.gradeEngagement,
    {
      onCompleted: ({ gradeEngagement }: { gradeEngagement: number }) => {
        setEngagementAverage(parseFloat((gradeEngagement / 20).toFixed(1)));
        next();
      },
      onError: (err) => {
        message.error('There was an error submiting engagement grade: ' + err.message || 'Unexpected Error');
      },
      refetchQueries: ['GetEngagementGrading', 'getInvestigationProgressSummary'],
    },
  );

  const gradeReport = useCallback(() => {
    if (validateFinalReportAnswers()) {
      submitGradeFinalReport({
        variables: {
          investigationId,
          userId: studentId,
          gradingData: gradeFinalReportAnswers.map((v: GQL_FinalReportQuestionnaireInput) => ({
            ...v,
            questions: v.questions.map((a: GQL_FinalReportQuestionnaireQuestionInput) => ({
              index: a.index,
              answer: a.answer,
            })),
          })),
        },
      });
    }
  }, [submitGradeFinalReport, gradeFinalReportAnswers, validateFinalReportAnswers, investigationId, studentId]);

  const gradeEngagement = useCallback(() => {
    if (validateEngagementAnswers()) {
      submitGradeEngagement({
        variables: {
          investigationId,
          userId: studentId,
          engagementGradingData: gradeEngagementAnswers.map((v: GQL_EngagementQuestionInput) => ({
            index: v.index,
            answer: v.answer,
          })),
        },
      });
    }
  }, [submitGradeEngagement, gradeEngagementAnswers, validateEngagementAnswers, investigationId, studentId]);

  const student = useMemo(() => {
    return investigationProgressSummary?.getInvestigationProgressSummary.perStudents.find(
      (s) => s.userId === studentId,
    );
  }, [investigationProgressSummary, studentId]);

  const hasFinalReportFile = finalReportFile && finalReportFile !== '/';

  return (
    <Layout>
      <InvestigationContent noMargin>
        <Row justify="center">
          <S.Column xl={22} md={23} onClick={() => history.goBack()}>
            <div>
              <FiArrowLeft />
              <S.TitleContainer>
                {!loading && student && investigationProgressSummary
                  ? `Grading: ${investigationProgressSummary?.getInvestigationProgressSummary.title} for student ${student?.firstName} ${student?.lastName}`
                  : 'Loading...'}
              </S.TitleContainer>
            </div>
          </S.Column>
        </Row>
        <Row justify="center">
          <Col xl={22} md={23}>
            <Row gutter={24}>
              <Col span={12}>
                <Spacer size={13} />

                {hasFinalReportFile ? (
                  <>
                    <Row justify="center">
                      <Pagination
                        style={{
                          zIndex: 1,
                        }}
                        size="small"
                        total={numPages}
                        pageSize={1}
                        current={pageNumber}
                        onChange={setPageNumber}
                      />
                    </Row>
                    <InvestigationFileDisplay
                      file={finalReportFile || ''}
                      page={pageNumber}
                      mimeType={finalReportMimeType || ''}
                      onLoadSuccess={onLoadSuccess}
                      containerRef={pdfRef}
                      videoRef={videoRef}
                    />
                  </>
                ) : (
                  <Row justify="center">
                    {loadingInvestigation ? 'Loading...' : "Student hasn't submitted a final report"}
                  </Row>
                )}
              </Col>
              <Col span={12}>
                <Steps current={current} onChange={onChangeStep}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
                {loading ? (
                  loadingPlaceholder
                ) : (
                  <Row justify="center" style={{ marginTop: 20 }}>
                    <Col span={24}>{steps[current].content}</Col>
                  </Row>
                )}
                <Row justify="end" style={{ margin: '10px 0' }}>
                  <Col span={24}>
                    {(isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher || user.isAllowedGrading) && current === 0 && (
                      <Button
                        block
                        size="large"
                        onClick={gradeReport}
                        loading={loadingGradeFinalReport}
                        text="Grade Final Report"
                      />
                    )}
                    {(isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher || user.isAllowedGrading) && current === 1 && (
                      <Button
                        block
                        size="large"
                        onClick={gradeEngagement}
                        loading={loadingGradeEngagement}
                        text="Grade Engagement"
                      />
                    )}
                    {current === 2 && (
                      <Button block size="large" onClick={() => history.goBack()} text="Go to Investigation" />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(InvestigationGradingPage);
