import React, { useState } from 'react';
import InvestigationBudgetCalculator from '../../components/StudentInvestigation/InvestigationBudgetCalculator';
import { GQL_Concept } from '../../types/investigation';
import InvestigationFileDisplay from '../InvestigationFileDisplay';
import Spacer from '../Spacer';

interface IInvestigationConcept {
  loading?: boolean;
  concept?: GQL_Concept;
  onLoadFinish?: () => void;
}

const InvestigationConcept = (props: IInvestigationConcept) => {
  const { concept, loading, onLoadFinish = () => null } = props;
  const conceptFiles = concept?.files || [];
  const [loads, setLoads] = useState(0);

  const handleLoadFinish = () => {
    if (loads === 1) {
      onLoadFinish();
    }
    setLoads(loads + 1);
  };

  return (
    <>
      {conceptFiles[0]?.fileUrl && (
        <InvestigationFileDisplay
          file={conceptFiles[0]?.fileUrl || ''}
          mimeType={conceptFiles[0]?.fileMIMEtype || ''}
          onLoadSuccess={handleLoadFinish}
          page={0}
        />
      )}
      {!conceptFiles[0]?.fileUrl && !loading && (
        <>
          <Spacer size={80} />
          <p style={{ textAlign: 'center' }}>This file was hidden by your teacher and will be reviewed</p>
        </>
      )}
      <Spacer size={24} />
      <InvestigationBudgetCalculator
        values={concept ? [concept] : []}
        key={concept?.materials?.length}
        fullWidth
        disabled={loading}
      />
      {conceptFiles[1]?.fileUrl && (
        <InvestigationFileDisplay
          file={conceptFiles[1]?.fileUrl || ''}
          mimeType={conceptFiles[1]?.fileMIMEtype || ''}
          page={0}
          onLoadSuccess={handleLoadFinish}
        />
      )}
    </>
  );
};

export default InvestigationConcept;
