import { Row } from 'antd';
import styled from 'styled-components';
import CommentBox from '../../../shared/CommentBox';

export const CommentWarning = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125.1%;

  text-align: right;

  color: #767676;
`;

export const ButtonsRow = styled(Row)`
  margin-top: 16px;
`;

export const Comment = styled(CommentBox)`
  margin-top: 24px;
`;

export const SpinningContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Divider = styled.div`
  height: 1px;
  background: #c4c4c4;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const SubCommentContainer = styled.div`
  margin-bottom: 24px;
`;
