import React, { useRef, useState } from 'react';
import { Layout, Pagination, Row } from 'antd';

import * as S from './styles';
import CommentsSider from './CommentsSider';
import InvestigationContent from '../../shared/InvestigationContent';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { IReflectionComment } from '../../types/investigation';
import Button from '../../shared/Button';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import InvestigationFileDisplay from '../../shared/InvestigationFileDisplay';
import Spacer from '../../shared/Spacer';

type Props = RouteComponentProps<
  {
    activityId: string;
    investigationId: string;
    submissionVersion?: string;
  },
  any,
  {
    comments: IReflectionComment[];
    fileUrl: string;
    fileMIMEtype?: string;
  }
>;

const CommentsInvestigationPage: React.FC<Props> = (props) => {
  const pdfRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { investigationId } = props.match.params;
  const [displayComments, setDisplayComments] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState<string>();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfHeight, setPdfHeight] = useState<number>();
  const [pdfWidth, setPdfWidth] = useState<number>();
  const submissionVersion = props.match.params.submissionVersion ? parseInt(props.match.params.submissionVersion) : 1;

  const { comments, fileUrl, fileMIMEtype } = props.location.state;

  if (!comments) history.push('/student-investigation/' + investigationId);

  const onLoadSuccess = (pdf: any) => {
    if (pdf) setNumPages(pdf?.numPages);

    setTimeout(() => {
      setPdfHeight(pdfRef?.current?.clientHeight);
      setPdfWidth(pdfRef?.current?.clientWidth);
      setDisplayComments(true);
    });
  };

  return (
    <Layout>
      <CommentsSider
        submissionVersion={submissionVersion}
        comments={comments}
        highlightedCommentId={selectedCommentId}
        investigationId={investigationId}
      />
      <InvestigationContent>
        <Row justify="center">
          <S.Column span={12}>
            <h1>Peer Review Investigation Plan</h1>
            <h2>
              Give people in your group some ideas about what they can do to make their plans better by adding a comment
              to the submission below. Be sure to post at least 2 comments. Comments can be suggestions or something you
              liked about the proposal.
            </h2>
          </S.Column>
        </Row>

        <Row justify="center">
          <S.Column span={20}>
            {fileUrl && (
              <S.BarRow align="middle" justify="center" style={{ width: '100%' }}>
                <Pagination size="small" total={numPages} pageSize={1} current={pageNumber} onChange={setPageNumber} />
                <Button
                  style={{ position: 'absolute', right: 0 }}
                  text={displayComments ? 'Hide Comments' : 'Show Comments'}
                  icon={displayComments ? <FiEyeOff /> : <FiEye />}
                  onClick={() => setDisplayComments(!displayComments)}
                  loading={!pdfHeight}
                />
              </S.BarRow>
            )}

            {fileUrl ? (
              <InvestigationFileDisplay
                containerRef={pdfRef}
                page={pageNumber}
                file={fileUrl}
                mimeType={fileMIMEtype || ''}
                onLoadSuccess={onLoadSuccess}
              >
                {displayComments &&
                  comments
                    .filter((comment) => (comment?.coordinates?.page ?? 1) === pageNumber)
                    ?.map((comment, i) => {
                      return (
                        <S.CommentBalloon
                          key={comment.id}
                          selected={comment.id === selectedCommentId}
                          xPos={(comment?.coordinates?.x ?? 0) * (pdfHeight ?? 0) + 17.5}
                          yPos={(comment?.coordinates?.y ?? 0) * (pdfWidth ?? 0)}
                          onClick={() => setSelectedCommentId(comment.id === selectedCommentId ? '' : comment.id)}
                        >
                          {i + 1}
                        </S.CommentBalloon>
                      );
                    })}
              </InvestigationFileDisplay>
            ) : (
              <>
                <Spacer size={48} />
                <p>This file was hidden by your teacher and will be reviewed</p>
              </>
            )}
          </S.Column>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(CommentsInvestigationPage);
