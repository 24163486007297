import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { createRef, useEffect } from 'react';
import { Arrow, Transformer } from 'react-konva';

export interface IArrowShapeProps {
  points: number[];
  color: string;
  strokeWidth: number;
  id: string;
  double?: boolean;
}

interface IArrow {
  shapeProps: IArrowShapeProps;
  isSelected?: boolean;
  onSelect?: (e: KonvaEventObject<Event>) => void;
  onChange?: (newAttributes: IArrowShapeProps) => void;
  draggable?: boolean;
}

export const ArrowShape = (props: IArrow) => {
  const { shapeProps, isSelected, onSelect, onChange, draggable } = props;
  const shapeRef = createRef<Konva.Arrow>();
  const trRef = createRef<Konva.Transformer>();

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      if (shapeRef?.current && trRef?.current) {
        trRef?.current?.nodes([shapeRef?.current]);
        trRef.current.getLayer()?.batchDraw();
      }
    }
  }, [isSelected, trRef, shapeRef]);

  return (
    <>
      <Arrow
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        pointerAtBeginning={shapeProps.double}
        stroke={shapeProps.color}
        draggable={draggable}
        onDragStart={onSelect}
        onTransformEnd={() => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          if (node) {
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange?.({
              ...shapeProps,
              points: node.points().map((p, i) => (i % 2 ? p * scaleY : p * scaleX)),
            });
          }
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};
