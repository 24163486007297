import React, { useCallback } from 'react';
import { Form, Input, Button } from 'antd';
import adiLearningHubSrc from '../../../../../assets/adi-learning-hub.svg';
import * as S from './styles';

interface BookCodeProps {
  onSubmit: (bookCode: string) => void;
  onClose?: () => void;
}

const BookCode: React.FC<BookCodeProps> = ({ onSubmit }) => {
  const onSubmitForm = useCallback(
    (values) => {
      onSubmit(values.bookCode);
    },
    [onSubmit]
  );

  return (
    <S.Container>
      <S.StyledRow justify="center" align="middle">
        <S.StyledCol xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Enter Book Code</S.Title>
          <Form onFinish={onSubmitForm} layout="vertical" style={{ maxWidth: '400px', margin: '0 auto' }}>
            <Form.Item label="Book Code" name="bookCode" style={{ fontWeight:'bold'}}rules={[{ required: true, message: 'Please input your book code!' }]}>
              <Input placeholder="Enter book code" style={{ width: '20rem' }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block style={{ width:'5rem', marginLeft:'1rem',marginTop:'1rem'}} >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </S.StyledCol>
      </S.StyledRow>
    </S.Container>
  );
};

export default BookCode;