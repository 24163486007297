import styled from 'styled-components';
import Button from '../../shared/Button';

export const Container = styled.div`
  padding: 4em;
`;

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const SearchIcon = styled.span<{
  $searchVisible: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 10px 4px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => (props.$searchVisible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px' : 'unset')};
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 2px;
`;

export const RoleTitle = styled.span<{
  $color?: string;
}>`
  font-size: 0.95em;
  font-weight: 500;
  color: ${(props) => props.$color};
`;
