import React from 'react';

import * as S from './styles';

interface GenericCardButtonProps {
  icon?: React.ReactNode;
  title: string;
  infoLineOne?: string;
  infoLineTwo?: string;
  iconColor?: string;
  primary?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  width?: string;
}

const GenericCardButton = (props: GenericCardButtonProps) => {
  const { title, icon, infoLineOne, infoLineTwo, onClick, width } = props;

  return (
    <S.Card $primary={!!props.primary} onClick={onClick} $width={width}>
      <S.Icon $disabled={!!props.disabled} $primary={!!props.primary} $color={props.iconColor}>
        {icon}
      </S.Icon>
      <S.Title $disabled={!!props.disabled} $primary={!!props.primary}>
        {title}
      </S.Title>
      <S.Info $disabled={!!props.disabled} $primary={!!props.primary}>
        {infoLineOne}
      </S.Info>
      <S.Info $disabled={!!props.disabled} $primary={!!props.primary}>
        {infoLineTwo}
      </S.Info>
    </S.Card>
  );
};

export default GenericCardButton;
