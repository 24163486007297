import { useMutation } from '@apollo/client';
import {
  Col,
  Modal,
  Alert,
  Checkbox,
  Space,
  Table,
  Button,
  Progress,
  Row,
  Skeleton,
  message,
} from 'antd';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';

import { gqlSchema } from '../../../gql/schema';
import { GQL_ClassInvestigationSummary } from '../../../types/class';
import { GQL_investigationTeacherSummary_PerStudent } from '../../../types/teacher';
import * as S from './styles';
import { centerAlign } from '../../../utils/antd';
import { getProgressColor } from '../../../utils/theme';
import { formatDateTime } from '../../../utils/date';
import Form from '../../../shared/Form';
import Switch from '../../../shared/Switch';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import Spacer from '../../../shared/Spacer';
import { AssessmentTypesLabel, StepMode } from '../../../types/assessments';
import { cleanCoreIdeaCode } from '../../../utils/utils';

type Props = {
  assessmentData: any;
  loading: boolean;
  type: string;
  isFacilitator: boolean;
};

export const AssessmentSummary: React.FC<Props> = (props) => {
  const { assessmentData, loading, type, isFacilitator } = props;
  const [form] = useForm();
  const [editAfterSubmission, setEditAfterSubmission] = useState(false);
  const [openResubmissionModal, setOpenResubmissionModal] = useState(false);
  const [checkBoxes, setCheckBoxes] = useState({ allStudents: false, overdueStudents: false });
  const [selectedResubmission, setSelectedResubmission] = useState<number>(0);

  const classTitle = useMemo(() => (isFacilitator ? 'Course or Event' : 'Class'), [isFacilitator]);
  const anyStudentCloseDateHasPass = useMemo(() => assessmentData?.studentMinCloseDate < Date.now(), [assessmentData]);

  const [editClass] = useMutation<
    { editClassInvestigation: GQL_ClassInvestigationSummary },
    { data: GQL_ClassInvestigationSummary }
  >(gqlSchema.ClassSchema.mutation.CLASS.EDIT.editClassInvestigation, {
    onError: () => {
      message.error('There was an error trying to edit the class summary, please try again later');
    },
    refetchQueries: [gqlSchema.InvestigationSchema.queries.CLASS.getAssessmentSummary],
    awaitRefetchQueries: true,
  });

  const handleSubmissionLimits = useCallback(
    (value: number) => {
      setSelectedResubmission(value);
      setEditAfterSubmission(value > 0);
      if (assessmentData?.studentMaxSubmission === value || anyStudentCloseDateHasPass) {
        setOpenResubmissionModal(true);
      } else {
        editClass({
          variables: {
            data: {
              id: assessmentData.id,
              resubmissionsLimit: value,
            },
          },
        });
      }
    },
    [setSelectedResubmission, setOpenResubmissionModal, editClass, anyStudentCloseDateHasPass, assessmentData],
  );

  useEffect(() => {
    if (assessmentData?.resubmissionsLimit) {
      setEditAfterSubmission(assessmentData?.resubmissionsLimit > 0);
      setSelectedResubmission(assessmentData?.resubmissionsLimit);
    }
  }, [assessmentData, setEditAfterSubmission, setSelectedResubmission]);

  const AssessmentProgresss = useCallback(
    () => (
      <Progress
        strokeLinecap="round"
        strokeWidth={9}
        strokeColor={getProgressColor((assessmentData?.completion || 0) * 100)}
        format={(percent) => {
          return (
            <>
              <S.Info $bold>{Math.round(percent ?? 0)}%</S.Info>
              <S.InfoProgress>Submitted</S.InfoProgress>
            </>
          );
        }}
        width={145}
        type="circle"
        percent={(assessmentData?.completion || 0) * 100}
      />
    ),
    [assessmentData],
  );

  const SummaryColumnContent = useCallback(
    ({ title, content }) => (
      <div>
        <h3>{title}</h3>
        {loading ? <Skeleton.Input active size="small" /> : content}
      </div>
    ),
    [loading],
  );

  const handleModalOk = useCallback(() => {
    setOpenResubmissionModal(false);
    editClass({
      variables: {
        data: {
          id: assessmentData.id,
          resubmissionsLimit: selectedResubmission,
          allStudents: checkBoxes.allStudents,
          overdueStudents: checkBoxes.overdueStudents,
        },
      },
    });
    setCheckBoxes({ allStudents: false, overdueStudents: false });
  }, [setOpenResubmissionModal, setCheckBoxes, editClass, selectedResubmission, checkBoxes, assessmentData]);

  const handleModalCancel = useCallback(() => {
    setSelectedResubmission(assessmentData?.resubmissionsLimit!);
    setOpenResubmissionModal(false);
  }, [setSelectedResubmission, setOpenResubmissionModal, assessmentData]);

  const resubmissionOptions = useCallback(() => {
    return Array(6)
      .fill(0)
      .map((each, index) => {
        const optionDisabled = index < assessmentData?.studentMaxSubmission!;

        return (
          <SelectOption disabled={optionDisabled} value={index} key={index} name={`resubmissionOption-${index}`}>
            {!optionDisabled ? (
              index
            ) : (
              <S.SelectOptionContainer>
                {index}
                {optionDisabled && <S.DisabledText>not allowed</S.DisabledText>}
              </S.SelectOptionContainer>
            )}
          </SelectOption>
        );
      });
  }, [assessmentData]);

  const columns: any = useMemo(() => {
    return [
      {
        title: 'Student Name',
        dataIndex: 'firstName',
        sorter: {
          compare: (a: any, b: any) => `${a.firstName}`.localeCompare(`${b.firstName}`),
          multiple: 2,
        },
        render: (text: string, record: GQL_investigationTeacherSummary_PerStudent, index: number) => {
          return (
            <S.TableNameContainer>
              <p>
                {record.firstName} {record.lastName}
              </p>
            </S.TableNameContainer>
          );
        },
      },
      {
        title: 'Close Date',
        dataIndex: 'closeDate',
        fixed: 'center',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return <div className={record?.endDate < Date.now() ? 'overdue' : ''}>{formatDateTime(record?.endDate)}</div>;
        },
      },
      {
        title: 'Close Time',
        dataIndex: 'closeTime',
        fixed: 'center',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return (
            <div className={record?.endDate < Date.now() ? 'overdue' : ''}>
              {formatDateTime(record?.endDate, 'hh:mm a')}
            </div>
          );
        },
      },
    ];
  }, []);

  const confirmButtonDisabled = useCallback(() => {
    if (anyStudentCloseDateHasPass) {
      return !(checkBoxes.allStudents || checkBoxes.overdueStudents);
    }

    return false;
  }, [anyStudentCloseDateHasPass, checkBoxes]);

  const modeLabel =
    assessmentData?.perStudents?.[0]?.steps?.[0]?.mode === StepMode.ALL
      ? 'Both Paper and Paperless'
      : assessmentData?.perStudents?.[0]?.steps?.[0]?.mode === StepMode.REMOTE
      ? AssessmentTypesLabel.PAPERLESS
      : AssessmentTypesLabel.PAPER;

  return (
    <>
      <Row>
        <S.Body span={24}>
          <AssessmentProgresss />
          <S.InfoMainHolder>
            <Row>
              <SummaryColumnContent
                title={`${type} Owner`}
                content={<p>{`${assessmentData?.teacherName} - ${assessmentData?.teacherEmail}`}</p>}
              />
            </Row>
            <Row>
              <SummaryColumnContent
                title={`Investigation ${classTitle}`}
                content={
                  <S.ClassChip background={assessmentData?.classColorHex || ''}>{assessmentData?.class}</S.ClassChip>
                }
              />
            </Row>
          </S.InfoMainHolder>

          <S.InfosContainer>
            <Row gutter={[32, 24]}>
              <Col span={6}>
                <SummaryColumnContent
                  title={'Number of Students'}
                  content={<p>{assessmentData?.perStudents?.length} students</p>}
                />
              </Col>
              <Col span={7}>
                <SummaryColumnContent
                  title={'Subject'}
                  content={
                    <S.ColouredSummaryText color="#4367E9">
                      {assessmentData?.discipline?.subject?.replaceAll('_', ' ') || '---'}
                    </S.ColouredSummaryText>
                  }
                />
              </Col>
              <Col span={6}>
                <SummaryColumnContent title={'Focus'} content={<p>{assessmentData?.focus?.name || '---'}</p>} />
              </Col>
              <Col span={5}>
                <SummaryColumnContent title={'Parts'} 
                  content={
                    <S.ChipsColumns style={{ justifyContent: 'flex-start', gap: '8px' }}>
                      {assessmentData?.parts?.length
                        ? assessmentData?.parts?.map((part: any, index: number) => (
                            <S.Chip key={index}>{cleanCoreIdeaCode(part?.name)}</S.Chip>
                          ))
                        : '-----'}
                    </S.ChipsColumns>
                  }
                />
              </Col>
            </Row>
            <S.Divider />
            <Form form={form}>
              <Row gutter={[32, 24]}>
                <Col span={6}>
                  <SummaryColumnContent
                    title={'Discipline'}
                    content={<p>{assessmentData?.discipline?.name || '---'}</p>}
                  />
                </Col>
                <Col span={7}>
                  <SummaryColumnContent
                    title={'Cross cutting Concepts'}
                    content={
                      <S.ChipsColumns style={{ justifyContent: 'flex-start', gap: '8px' }}>
                        {assessmentData?.crosscuttingConcepts?.length
                          ? assessmentData?.crosscuttingConcepts?.map((concept: any, index: number) => (
                              <S.Chip key={index}>{cleanCoreIdeaCode(concept?.code)}</S.Chip>
                            ))
                          : '-----'}
                      </S.ChipsColumns>
                    }
                  />
                </Col>
                <Col span={3}>
                  <SummaryColumnContent
                    title={'Core Idea'}
                    content={
                      <S.ChipsColumns>
                        {assessmentData?.coreIdeas?.length
                          ? assessmentData?.coreIdeas?.map((codeIdea: any, index: number) => (
                              <S.Chip key={index}>{cleanCoreIdeaCode(codeIdea?.code)}</S.Chip>
                            ))
                          : '-----'}
                      </S.ChipsColumns>
                    }
                  />
                </Col>
                <Col span={3}>
                  <SummaryColumnContent
                    title={'Practice'}
                    content={
                      <S.ChipsColumns>
                        {assessmentData?.practices?.length
                          ? assessmentData?.practices?.map((practice: any, index: number) => (
                              <S.Chip key={index}>{practice?.code}</S.Chip>
                            ))
                          : '-----'}
                      </S.ChipsColumns>
                    }
                  />
                </Col>
                <Col span={3}>
                  <SummaryColumnContent
                    title={'Standards'}
                    content={
                      <S.ChipsColumns>
                        {assessmentData?.standards?.length
                          ? assessmentData?.standards?.map((standard: any, index: number) => (
                              <S.Chip key={index}>{cleanCoreIdeaCode(standard?.code)}</S.Chip>
                            ))
                          : '-----'}
                      </S.ChipsColumns>
                    }
                  />
                </Col>
              </Row>
            </Form>
            <S.Divider />
            <Row gutter={[32, 24]}>
              <Col span={8}>
                <SummaryColumnContent
                  title={'Editable after submission'}
                  content={
                    <Switch
                      checked={editAfterSubmission}
                      value={editAfterSubmission}
                      type="text"
                      titleAlign="right"
                      title={''}
                      onClick={() => setEditAfterSubmission(!editAfterSubmission)}
                    />
                  }
                />
              </Col>
              <Col span={8}>
                <SummaryColumnContent
                  title={'Number of resubmissions'}
                  content={
                    <div style={{ display: 'flex' }}>
                      <Select
                        data-cy="number-of-resubmissions"
                        placeholder="Select resubmission amount"
                        value={selectedResubmission}
                        onChange={(v) => handleSubmissionLimits(v as number)}
                        disabled={!editAfterSubmission}
                      >
                        {resubmissionOptions()}
                      </Select>
                    </div>
                  }
                />
              </Col>
              <Col span={8}>
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', minWidth: '205px' }}>
                    <SummaryColumnContent
                      title={'Assessment Mode'}
                      content={<div style={{ display: 'flex', minHeight: '32px' }}>{modeLabel}</div>}
                    />
                  </div>
              </Col>
            </Row>
          </S.InfosContainer>
        </S.Body>
      </Row>
      <Modal
        visible={openResubmissionModal}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={700}
        footer={[
          <Button key="back" style={{ borderRadius: '6px' }} onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            style={{ borderRadius: '6px' }}
            type="primary"
            disabled={confirmButtonDisabled()}
            onClick={handleModalOk}
          >
            Confirm
          </Button>,
        ]}
      >
        <S.MaterialsModalContainer>
          <h1>Resubmission setup modal</h1>
          <S.CDivider />
          {assessmentData?.studentMaxSubmission === selectedResubmission && (
            <>
              <Alert
                message={`Attention: 1 or more students in this class already has "${selectedResubmission}" resubmission, their assessment will be locked if you decide to continue.`}
                type="warning"
              />
              <Spacer />
            </>
          )}
          {anyStudentCloseDateHasPass && (
            <>
              <h3> Students </h3>
              <S.CDivider />
              <div>
                <h2> Quick actions </h2>
                <Alert
                  message="You can always edit students close date/time in bulk or individually in the table if you prefer"
                  type="info"
                  closable
                />
                <Spacer />
                <Space direction="horizontal" size="middle">
                  <Checkbox
                    data-cy="add-time-to-all-students"
                    checked={checkBoxes.allStudents}
                    onChange={(value) => setCheckBoxes({ overdueStudents: false, allStudents: value.target.checked })}
                  >
                    Add 24 hours to all students
                  </Checkbox>
                  <Checkbox
                    data-cy="add-time-to-overdue-students"
                    checked={checkBoxes.overdueStudents}
                    disabled={checkBoxes.allStudents}
                    onChange={(value) => setCheckBoxes({ ...checkBoxes, overdueStudents: value.target.checked })}
                  >
                    Add 24 hours only to overdue students
                  </Checkbox>
                </Space>
              </div>
              <S.CDivider />
              <S.TableWrapper>
                <Table
                  columns={columns}
                  bordered
                  className="remove-horizontal-scroll"
                  loading={loading}
                  dataSource={assessmentData?.perStudents || []}
                  scroll={{ x: 'max-content' }}
                  rowKey={(record) => record.userId}
                  sticky
                />
              </S.TableWrapper>
            </>
          )}
        </S.MaterialsModalContainer>
      </Modal>
    </>
  );
};
