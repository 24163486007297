import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface ICircularSpin {
  size?: number;
  color?: string;
}

const CircularSpin = (props: ICircularSpin) => {
  const { size = 24, color } = props;

  return <Spin indicator={<LoadingOutlined width={10} style={{ fontSize: size, color }} spin />} />;
};

export default CircularSpin;
