import styled from 'styled-components';
import { FiTrash2 } from 'react-icons/fi';
import { Upload as AntdUpload } from 'antd';
const { Dragger: AntdDragger } = AntdUpload;

export const Title = styled.p`
  color: ${(props) => props.theme.secondaryColor.background} !important;
  font-size: 16px;
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.secondaryColor.background} !important;
  font-size: 13px;
`;

export const IconContainer = styled.p`
  color: ${(props) => props.theme.secondaryColor.background} !important;
`;

export const Dragger = styled(AntdDragger)`
  border-radius: 6px !important;
  border-color: ${(props) => props.theme.secondaryColor.background} !important;

  &:hover {
    border-color: ${(props) => props.theme.primaryColor.background} !important;
  }
`;

export const DeleteFileIcon = styled(FiTrash2)<{
  $color: string;
}>`
  float: right;
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${(props) => props.$color || props.theme.primaryOutlined.color} !important;
`;
