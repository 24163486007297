import styled from 'styled-components';
import { Row } from 'antd';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  z-index: 1000;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); 
  top: 0;
  left: 0;
`;

export const ModalWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 960px; /* Updated width for better alignment */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  padding: 20px; /* Add padding for internal spacing */
`;

export const ContentRow = styled(Row)`
  display: flex;
  gap: 20px; /* Add spacing between content blocks */
  margin-top: 16px;
  align-items: flex-start; /* Align items to the top */
`;

export const LeftSection = styled.div`
  flex: 1; /* Left section (image) takes up space proportionally */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  img {
    width: auto;
    height: auto; /* Fixed dimensions for the image */
    object-fit: cover;
    border-radius: 8px;
    border: 2px solid #f0f0f0;
  }
`;

export const RightSection = styled.div`
  flex: 2;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr; /* Equal width columns */
  column-gap: 16px; /* Space between title and value */
  row-gap: 12px; /* Space between rows */
  align-items: start; /* Align items to the top of each cell */
`;

export const BookDteailsWrapper = styled.div`
  display: flex; /* Ensure the grid structure is maintained */
  flex-direction: column;
`;

export const DetailsTitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #333;
`;

export const DetailsText = styled.p`
  font-size: 14px;
  color: #595959;
  margin: 0;
  word-wrap: break-word; /* Handle long text */
`;
export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-checkbox-wrapper {
    margin-bottom: 16px; /* Space between checkbox and button */
  }

  button {
    width: 100%;
    max-width: 200px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    background: #4c6ef5;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: #3b5bdb;
    }
  }
`;
