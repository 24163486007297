import React, { ReactElement, useCallback } from 'react';
import { Row, Col, Progress } from 'antd';
import * as S from './styles';
import { BsClock } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { getProgressColor, themeConfig } from '../../../../utils/theme';

interface Props {
  description: string;
  progress: number;
  parts: string;
  partsCompleted?: number;
  timeLeft: string;
  timeToOpen?: string;
  assessmentName: string;
  assessmentId: string;
  timeWarning: boolean;
  icon: ReactElement;
  type: 'Assessment';
  disabled: boolean;
  version?: string | number;
}

const Item: React.FC<Props> = (props) => {
  const {
    progress,
    timeLeft,
    timeToOpen,
    parts,
    partsCompleted,
    assessmentName,
    assessmentId,
    timeWarning,
    description,
    icon,
    type,
    disabled,
    version,
  } = props;
  const history = useHistory();

  const goToDashboard = useCallback(() => {
    history.push('/student-assessment/' + assessmentId);
  }, [assessmentId, history]);

  const action = partsCompleted ? 'Resume ' : 'Start ';

  return (
    <S.Card data-cy="components-active-assessments-row-item" disabled={disabled}>
      {/* show a message "Opens At" */}
      {timeToOpen ? (
        <S.HoverCard>
          <S.Info $bold $align="left">
            Opens in {timeToOpen}
          </S.Info>
        </S.HoverCard>
      ) : null}

      <Row align="middle">
        <Col span={5} style={{ textAlign: 'left' }}>
          {icon}
        </Col>
        <Col span={14}>
          <S.Info
            $bold
            data-cy="shared-studentdashboardrows-activeassessmentsrow-item-parts"
            data-parts-completed={partsCompleted}
          >
            {parts} Parts Completed
          </S.Info>
        </Col>
        <Col span={5} style={{ textAlign: 'left' }}>
          <Progress
            strokeLinecap="round"
            strokeWidth={10}
            strokeColor={getProgressColor(progress * 100)}
            format={(percent) => {
              return (
                <S.Info
                  data-cy="shared-studentdashboardrows-activeassessmentsrow-item-progress"
                  data-percentage={percent}
                  $bold
                >
                  {percent}%
                </S.Info>
              );
            }}
            type="circle"
            width={65}
            percent={Math.round(progress * 100)}
          />
        </Col>
        <Col span={24}>
          <S.Title $align="left">{assessmentName}</S.Title>
          {version ? <S.Subtitle>VERSION {version}</S.Subtitle> : null}
        </Col>
        <Col span={24}>
          <S.Info $align="left" dangerouslySetInnerHTML={{ __html: description || '' }} />
        </Col>
      </Row>
      <Row style={{ marginTop: '3em' }}>
        <Col span={12}>
          <S.DashboardButton
            data-cy="shared-studentdashboardrows-activeassessment-resume-button"
            text={action + type}
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            disabled={disabled}
          />
        </Col>
        {!disabled ? (
          <Col span={12} className="end">
            <S.TimeLeft $fontSize="0.8em" $warning={timeWarning}>
              <BsClock />
              {timeLeft}
            </S.TimeLeft>
          </Col>
        ) : null}
      </Row>
    </S.Card>
  );
};

export default Item;
