/* eslint-disable max-lines */
/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Anchor, Divider, message, Row, Tooltip } from 'antd';

import {
  GQL_CodeTextPair,
  GQL_InvestigationActivity,
  GQL_InvestigationBlock,
  GQL_InvestigationContent,
  GQL_InvestigationDisplay,
  GQL_InvestigationStep,
  GQL_Mode,
  IBudgetCalculatorContentValue,
  IButtonContentValue,
  ICanvasContentValue,
  IMediaContentValue,
  IOListContentValue,
  ISortingBoardContentValue,
  ITextContentValue,
  ITextSubmitContentValue,
} from '../../types/investigation';
import { gqlSchema } from '../../gql/schema';
import CircularSpin from '../../shared/CircularSpin';
import * as S from './styles';
import Editor from '../../shared/Editor';
import InvestigationButton from '../StudentInvestigation/InvestigationButton';
import InvestigationText from '../StudentInvestigation/InvestigationText';
import InvestigationImage from '../StudentInvestigation/InvestigationImage';
import InvestigationVideo from '../StudentInvestigation/InvestigationVideo';
import Spacer from '../../shared/Spacer';
import InvestigationTextImage from '../StudentInvestigation/InvestigationTextImage';
import MultipleChoiceQuestionPreview from '../AdiTemplatesPage/PreviewAssessmentModal/MultipleChoiceQuestionPreview';
import MultipleChoicePictureQuestionPreview from '../AdiTemplatesPage/PreviewAssessmentModal/MultipleChoicePictureQuestionPreview';
import InvestigationOList from '../StudentInvestigation/InvestigationOList';
import InvestigationSimulation from '../StudentInvestigation/InvestigationSimulation';
import InvestigationBudgetCalculator from '../StudentInvestigation/InvestigationBudgetCalculator';
import { InvestigationCanvas } from '../StudentInvestigation/InvestigationCanvas';
import InvestigationSortingBoard from '../StudentInvestigation/InvestigationSortingBoard';
import { DataType } from '../GradeDataReviewPage';
import InvestigationDetailsReviewSearch from './InvestigationDetailsReviewSearch';
import { BANNED_FILTER_WORDS } from '../../utils/string';
import { GQL_InvestigationStandard } from '../../types/investigationStandard';
import ProcessDragAndDropAnswerPreview from '../AdiTemplatesPage/PreviewAssessmentModal/ProcessDragAndDropAnswerPreview';

interface IInvestigationDetailsReviewPage {}

const InvestigationDetailsReviewPage = (props: IInvestigationDetailsReviewPage) => {
  const { type, investigationId } = useParams<{ type: DataType; investigationId: string }>();
  const match = useRouteMatch();
  const { hash } = useLocation();
  const [searchText, setSearchText] = useState('');
  const [searchKeywords, setSearchKeywords] = useState<RegExp[]>([]);

  useEffect(() => {
    setSearchKeywords(
      searchText.length > 0
        ? searchText
            .split(' ')
            .filter((w) => BANNED_FILTER_WORDS.indexOf(w.toLowerCase()) === -1)
            .map((w) => new RegExp(w, 'i'))
        : [],
    );
  }, [searchText]);

  const handleSearch = (text: string) => {
    setSearchText(text);
  };

  const showFilteredComponent = useCallback(
    (value: string | undefined, component: React.ReactChild) => {
      if (searchKeywords.length === 0 || value === undefined || value.length === 0) return component;

      for (const keyword of searchKeywords) {
        if (value.match(keyword)) {
          return component;
        }
      }

      return null;
    },
    [searchKeywords],
  );

  const doesValueContainSearchKeywords = useCallback(
    (value: string | string[] | null | undefined): boolean => {
      if (searchKeywords.length === 0) return true;

      if (value === undefined || value === null || value.length === 0) {
        return false;
      }

      // return true if the value contains atleast 1 keyword
      if (typeof value === 'string' && searchKeywords.some((keyword) => value.match(keyword))) {
        return true;
      } else if (Array.isArray(value) && searchKeywords.some((keyword) => value.some((v) => v.match(keyword)))) {
        return true;
      } else {
        return false;
      }
    },
    [searchKeywords],
  );

  const doValuesContainSearchKeywords = useCallback(
    (values: (string | null | undefined)[] | undefined | null) => {
      if (searchKeywords.length === 0) return true;

      if (values === undefined || values === null || values.length === 0) {
        return false;
      }

      return values.some((value) => doesValueContainSearchKeywords(value));
    },
    [doesValueContainSearchKeywords, searchKeywords],
  );

  const doPropertiesInValuesContainSearchKeywords = useCallback(
    (values: any, propertyName: string) => {
      if (searchKeywords.length === 0) return true;

      if (values === undefined || values === null) {
        return false;
      }

      return values.some((value: any) => {
        if (value === undefined || value === null) {
          return false;
        }

        return doesValueContainSearchKeywords(value[propertyName]);
      });
    },
    [doesValueContainSearchKeywords, searchKeywords],
  );

  const showFilteredCoreIdeas = useCallback(
    (coreIdeas: GQL_CodeTextPair[] | null | undefined, component: React.ReactChild) => {
      if (
        doPropertiesInValuesContainSearchKeywords(coreIdeas, 'code') ||
        doPropertiesInValuesContainSearchKeywords(coreIdeas, 'text')
      )
        return component;
      return null;
    },
    [doPropertiesInValuesContainSearchKeywords],
  );

  const showFilteredStateStandards = useCallback(
    (standards: GQL_InvestigationStandard[] | undefined) => {
      if (standards === undefined || standards === null) return false;

      return (
        doPropertiesInValuesContainSearchKeywords(standards, 'fullStatement') ||
        doPropertiesInValuesContainSearchKeywords(standards, 'code')
      );
    },
    [doPropertiesInValuesContainSearchKeywords],
  );

  const { data: investigationData, loading } = useQuery<
    { getInvestigationCatalogById: GQL_InvestigationDisplay },
    { investigationId: string; isAssessment: boolean }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalogById, {
    variables: {
      investigationId,
      isAssessment: type !== DataType.INVESTIGATIONS,
    },
    onCompleted: () => {
      const id = hash.replace('#', '');
      if (id.length) {
        setTimeout(() => {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ block: 'start' });
          }
        }, 500);
      }
    },
    onError: (err) => {
      message.error('There was an error loading the investigation/assessment: ' + err.message || 'Unexpected Error');
    },
  });

  const headerWithAnchorPoint = useCallback(
    (id: string, title: string) => {
      return (
        <S.TitleContainer id={id}>
          <Divider orientation="left">
            <>
              {title}
              <Tooltip title="Copy anchorpoint to clipboard">
                <LinkOutlined
                  style={{ marginLeft: '.5rem' }}
                  onClick={() => {
                    navigator?.clipboard.writeText(`${window.location.origin}${match.url}#${id}`).then(() => {
                      message.success('Anchorpoint copied to clipboard!');
                    });
                  }}
                />
              </Tooltip>
            </>
          </Divider>
        </S.TitleContainer>
      );
    },
    [match],
  );

  const contentText = useCallback((text?: string) => {
    return <Editor editable={false} value={text || '-- NO DATA --'} />;
  }, []);

  const buildContentBlock = useCallback(
    (
      block: GQL_InvestigationBlock,
      currentContent: GQL_InvestigationContent,
      currentContentIndex: number,
      currentActivity: GQL_InvestigationActivity,
      currentStep: GQL_InvestigationStep,
    ) => {
      const span = block.columns && block.type !== 'Canvas' ? 24 / block.columns : 24;
      const currentStepId = currentStep.id;
      const currentActivityId = currentActivity.id;

      // filter out the blocks that do not contain search keywords
      if (searchKeywords.length > 0) {
        // show the block only if the block's values contain the search keywords
        const shouldContinue = block.values?.some((value) => {
          const anyValue = value as any;

          const shouldContinue =
            doesValueContainSearchKeywords(anyValue.text) ||
            doesValueContainSearchKeywords(anyValue.alt) ||
            doesValueContainSearchKeywords(anyValue.title) ||
            doesValueContainSearchKeywords(anyValue.placeholder) ||
            doValuesContainSearchKeywords(anyValue.titles) ||
            doPropertiesInValuesContainSearchKeywords(anyValue.materials, 'name') ||
            doPropertiesInValuesContainSearchKeywords(anyValue.targets, 'title') ||
            doPropertiesInValuesContainSearchKeywords(anyValue.targets, 'values');

          return shouldContinue;
        });

        if (!shouldContinue) return null;
      }

      switch (block.type) {
        case 'Text': {
          return block.values.map((value, i) => (
            <S.Col span={span} key={`${currentContentIndex}${i}`}>
              <InvestigationText value={value as ITextContentValue} />
            </S.Col>
          ));
        }
        case 'Image': {
          return block.values.map((value, i) => (
            <S.Col span={span} key={`${(value as IMediaContentValue).url}${i}`}>
              <InvestigationImage value={value as IMediaContentValue} />
            </S.Col>
          ));
        }
        case 'Video': {
          return block.values.map((value, i) => (
            <S.Col span={span} key={`${currentContentIndex}${i}`}>
              <InvestigationVideo value={value as IMediaContentValue} />
            </S.Col>
          ));
        }
        case 'TextSubmit': {
          return (block.values as ITextSubmitContentValue[]).map((value, i) => (
            <S.Col span={span} key={`${value.title}${i}`}>
              <Spacer />
              <h3>{value.title}</h3>
            </S.Col>
          ));
        }
        case 'TextImage':
          return block.values.map((value, i) => (
            <S.Col
              span={Math.round(((value as ITextContentValue).ratio || 0.5) * 24)}
              style={{ justifyContent: 'center' }}
              key={`image-${i}`}
            >
              <InvestigationTextImage value={value as ITextContentValue | IMediaContentValue} />
            </S.Col>
          ));
        case 'MultipleChoiceQuestion':
          return (
            <S.Col span={span} key={`MultipleChoiceQuestion`}>
              <MultipleChoiceQuestionPreview block={block} />
            </S.Col>
          );
        case 'MultipleChoicePictureQuestion':
          return (
            <S.Col span={span} key={`MultipleChoicePictureQuestion`}>
              <MultipleChoicePictureQuestionPreview block={block} />
            </S.Col>
          );
        case 'ProcessDragDropAnswer':
          return (
            <S.Col span={span} key={`ProcessDragDropAnswer`}>
              <ProcessDragAndDropAnswerPreview block={block} />
            </S.Col>
          );
        case 'ProcessDragDropPictureAnswer':
          return (
            <S.Col span={span} key={`ProcessDragDropPictureAnswer`}>
              <ProcessDragAndDropAnswerPreview block={block} isImage={true} />
            </S.Col>
          );
        case 'O-List': {
          return <S.Col span={span}>{<InvestigationOList values={block.values as IOListContentValue[]} />}</S.Col>;
        }
        case 'Simulation': {
          return block.values.map((value, i) => (
            <S.Col span={span} key={`${currentContentIndex}${i}`}>
              {<InvestigationSimulation value={value as IMediaContentValue} />}
            </S.Col>
          ));
        }
        case 'Button': {
          return (
            <Row gutter={[15, 15]} style={{ marginBottom: 8 }}>
              {block.values.map((value, i) => (
                <S.Col span={span} key={`${currentContentIndex}${i}`}>
                  {<InvestigationButton value={value as IButtonContentValue} />}
                </S.Col>
              ))}
            </Row>
          );
        }
        case 'BestConceptReflection':
        case 'Reflection': {
          return <p>Reflection component</p>;
        }
        case 'ReflectionQuestionnaire': {
          return <p>ReflectionQuestionnaire component</p>;
        }
        case 'PeerReview': {
          return <p>Peer review component</p>;
        }
        case 'PeerReviewQuestionnaire': {
          return <p>Peer review component</p>;
        }
        case 'Comment': {
          return null;
        }
        case 'BudgetCalculator': {
          return (
            <InvestigationBudgetCalculator
              key={currentActivityId || currentActivity?.id}
              values={block.values as IBudgetCalculatorContentValue[]}
              currentStepId={currentStepId}
              userId={block.userId}
              investigationId={investigationId}
              fullWidth
            />
          );
        }
        case 'Canvas': {
          return block.values.map((value, i) => (
            <S.Col span={span} key={`${currentContentIndex}${i}`}>
              <InvestigationCanvas
                key={currentActivityId || currentActivity?.id}
                value={value as ICanvasContentValue}
                stepId={currentStepId}
                activityId={currentActivityId || currentActivity?.id || ''}
                investigationId={investigationId}
                userId={block.userId}
              />
            </S.Col>
          ));
        }
        case 'SortingBoard': {
          return block.values.map((value, i) => (
            <S.Col span={span} key={`${currentContentIndex}${i}`}>
              <InvestigationSortingBoard
                key={currentActivityId || currentActivity?.id}
                value={value as ISortingBoardContentValue}
                onBoardSorted={() => {}}
              />
            </S.Col>
          ));
        }
        case 'GroupBestConcept':
        case 'BestConceptPeerReview':
        case 'ConceptRating':
        case 'ConceptEvaluation': {
          return <p>Review Component</p>;
        }
        default:
          return <></>;
      }
    },
    [
      doesValueContainSearchKeywords,
      investigationId,
      searchKeywords,
      doPropertiesInValuesContainSearchKeywords,
      doValuesContainSearchKeywords,
    ],
  );

  const buildActivityContent = useCallback(
    (activity: GQL_InvestigationActivity, step: GQL_InvestigationStep, content: keyof GQL_InvestigationActivity) => {
      const activityContent = (activity?.[content] as GQL_InvestigationContent[]).map((content, contentIndex) => {
        const blocksContent = content.blocks.map((block, index) => {
          const blockContent = buildContentBlock(block, content, contentIndex, activity, step);

          if (blockContent === null) return null;

          return (
            <div key={`block-${index}`}>
              <Divider orientation="left" key={index} />
              {blockContent}
            </div>
          );
        });

        if (blocksContent.every((item) => item === null)) return null;

        return (
          <div key={`activity-${contentIndex}`}>
            <Divider orientation="left" key={contentIndex}>{`${content.title} - Page ${contentIndex + 1}`}</Divider>
            {blocksContent}
          </div>
        );
      });

      if (activityContent.every((item) => item === null)) return null;

      return activityContent;
    },
    [buildContentBlock],
  );

  const fileLink = useCallback((link, filename = null) => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {filename || link}
      </a>
    );
  }, []);

  const stepData = useCallback(
    (anchorPoint, title, value, isFile = false) => {
      return !value
        ? null
        : showFilteredComponent(
            value,
            <Row gutter={[16, 4]} justify="center">
              <S.Col span={23}>{headerWithAnchorPoint(anchorPoint, title)}</S.Col>
              {!isFile ? (
                <S.Col span={23}>
                  <S.DataContent>{contentText(value)}</S.DataContent>
                </S.Col>
              ) : (
                <S.Col span={23}>
                  <S.DataContent>{fileLink(value)}</S.DataContent>
                </S.Col>
              )}
            </Row>,
          );
    },
    [headerWithAnchorPoint, fileLink, contentText, showFilteredComponent],
  );

  const stepDataActivity = useCallback(
    // eslint-disable-next-line
    (step, activity, value, name, title, isVideo = false) => {
      return !value || !doesValueContainSearchKeywords(title) || !doesValueContainSearchKeywords(value) ? null : (
        <Row gutter={[16, 4]} justify="center">
          <S.Col span={23}>
            {headerWithAnchorPoint(`ap-step-${step.id}-activities-${activity.id}-${name}`, title)}
          </S.Col>
          {!isVideo ? (
            <S.Col span={23}>
              <S.DataContent>{contentText(value)}</S.DataContent>
            </S.Col>
          ) : (
            <S.Col span={23}>
              <S.DataContent>
                <InvestigationVideo value={{ url: value } as IMediaContentValue} />
              </S.DataContent>
            </S.Col>
          )}
        </Row>
      );
    },
    [headerWithAnchorPoint, contentText, doesValueContainSearchKeywords],
  );

  const labelType = useMemo(() => {
    return type === DataType.INVESTIGATIONS ? 'Investigation ' : 'Assessment ';
  }, [type]);

  if (loading)
    return (
      <S.LoadingContainer>
        <CircularSpin size={20} color="black" />
      </S.LoadingContainer>
    );

  const modeMap: Record<GQL_Mode, string> = {
    ALL: type === DataType.INVESTIGATIONS ? 'Both In Person and Remote' : 'Both Paper and Paperless',
    INPERSON: type === DataType.INVESTIGATIONS ? 'In person mode' : 'Paper',
    REMOTE: type === DataType.INVESTIGATIONS ? 'Remote mode' : 'Paperless',
  };

  const populateActivitySteps = (
    step: GQL_InvestigationStep,
    showRemoteContent: boolean,
    showInPersonContent: boolean,
  ) => {
    return step.activities.map((activity, activityIndex) => {
      const content = buildActivityContent(activity, step, 'content');
      const contentInPerson = buildActivityContent(activity, step, 'contentInPerson');
      const title = stepDataActivity(step, activity, activity.name, 'title', 'Title');
      const studentHelpVideo = stepDataActivity(
        step,
        activity,
        activity.helpVideoUrl,
        'student-help-view-url',
        'Student help video url',
        true,
      );

      const teacherHelpVideo = stepDataActivity(
        step,
        activity,
        activity.teacherHelpVideoUrl,
        'teacher-help-view-url',
        'Teacher help video url',
        true,
      );

      const remoteTeacherNotes = stepDataActivity(
        step,
        activity,
        activity.remoteTeacher,
        'remoteTeacher',
        'Remote teacher notes',
      );

      const inPersonTeacherNotes = stepDataActivity(
        step,
        activity,
        activity.inPersonTeacher,
        'inPersonTeacher',
        'In-Person teacher notes',
      );

      const inPersonTeacherTips = stepDataActivity(
        step,
        activity,
        activity.inPersonTeacherTips,
        'inPersonTeacherTips',
        'In-Person teacher Tips',
      );

      const safetyNotes = stepDataActivity(step, activity, activity.safety, 'safety', 'Safety Notes');

      const studentHelpNotes = stepDataActivity(step, activity, activity.help, 'help', 'Student Help notes');

      if (
        [
          showRemoteContent ? content : null,
          showInPersonContent ? contentInPerson : null,
          title,
          studentHelpVideo,
          teacherHelpVideo,
          showRemoteContent ? remoteTeacherNotes : null,
          showInPersonContent ? inPersonTeacherNotes : null,
          showInPersonContent ? inPersonTeacherTips : null,
          safetyNotes,
          studentHelpNotes,
        ].every((item) => item === null)
      )
        return null;

      return (
        <div key={`activity-${activityIndex}`}>
          <Row gutter={[16, 4]} justify="center">
            <S.Col span={23}>
              {headerWithAnchorPoint(`ap-step-${step.id}-activities-${activity.id}`, `Activity ${activityIndex + 1}`)}
            </S.Col>
          </Row>
          {title}
          {studentHelpVideo}
          {teacherHelpVideo}
          {showRemoteContent ? remoteTeacherNotes : null}
          {showInPersonContent ? inPersonTeacherNotes : null}
          {showInPersonContent ? inPersonTeacherTips : null}
          {safetyNotes}
          {studentHelpNotes}
          {content !== null && showRemoteContent && (
            <Row gutter={[16, 4]} justify="center">
              <S.Col span={23}>
                {headerWithAnchorPoint(`ap-step-${step.id}-activities-${activity.id}-content`, `Content`)}
              </S.Col>
              <S.Col span={23}>
                <S.Content>{content}</S.Content>
              </S.Col>
            </Row>
          )}
          {contentInPerson !== null && showInPersonContent && (
            <Row gutter={[16, 4]} justify="center">
              <S.Col span={23}>
                {headerWithAnchorPoint(
                  `ap-step-${step.id}-activities-${activity.id}-content-in-person`,
                  `Content in Person`,
                )}
              </S.Col>
              <S.Col span={23}>
                <S.Content>{contentInPerson}</S.Content>
              </S.Col>
            </Row>
          )}
        </div>
      );
    });
  };

  return (
    <Anchor
      affix={false}
      style={{ maxWidth: '100%', maxHeight: '100%', overflowX: 'hidden', paddingTop: '10px', paddingBottom: '20px' }}
    >
      <InvestigationDetailsReviewSearch handleSearch={handleSearch} />
      {stepData('ap-title', `${labelType}Name`, investigationData?.getInvestigationCatalogById?.title)}
      {stepData('ap-kitUrl', `${labelType}Kit Url`, investigationData?.getInvestigationCatalogById?.kitUrl)}
      {stepData(
        'ap-description',
        `${labelType}Description`,
        investigationData?.getInvestigationCatalogById?.description,
      )}
      {investigationData?.getInvestigationCatalogById?.imageUrl &&
        showFilteredComponent(
          investigationData?.getInvestigationCatalogById?.imageUrl,
          <Row gutter={[16, 4]} justify="center">
            <S.Col span={23}>{headerWithAnchorPoint('ap-imageUrl', `${labelType}Image`)}</S.Col>
            <S.Col span={23}>
              <S.DataContent>
                <InvestigationImage
                  value={
                    {
                      url: investigationData?.getInvestigationCatalogById?.imageUrl,
                      alt: 'investigation-image-rw-site',
                    } as IMediaContentValue
                  }
                />
              </S.DataContent>
            </S.Col>
          </Row>,
        )}
      {stepData('ap-letterHomeUrl', 'Letter Home', investigationData?.getInvestigationCatalogById?.letterHomeUrl, true)}
      {stepData(
        'ap-standardsUrl',
        `${labelType}Standards PDF`,
        investigationData?.getInvestigationCatalogById?.standardsUrl,
        true,
      )}
      {stepData(
        'ap-suppliesUrl',
        `${labelType}Supplies PDF`,
        investigationData?.getInvestigationCatalogById?.suppliesUrl,
        true,
      )}
      {stepData(
        'ap-type',
        `${labelType}Type`,
        investigationData?.getInvestigationCatalogById?.discipline?.subject?.replaceAll('_', ' '),
      )}
      {stepData('ap-grade', `${labelType}Grade`, investigationData?.getInvestigationCatalogById?.discipline?.gradeBand)}
      {stepData(
        'ap-discipline',
        `${labelType}Discipline`,
        investigationData?.getInvestigationCatalogById?.discipline?.name,
      )}
      {investigationData?.getInvestigationCatalogById?.coreIdeas &&
        showFilteredCoreIdeas(
          investigationData?.getInvestigationCatalogById?.coreIdeas,
          <Row gutter={[16, 4]} justify="center">
            <S.Col span={23}>{headerWithAnchorPoint('ap-core-ideas', `${labelType}Core Ideas`)}</S.Col>
            <S.Col span={23}>
              <S.DataContent>
                <ul>
                  {investigationData?.getInvestigationCatalogById?.coreIdeas?.map((idea, i) => (
                    <li key={`coreIdea-${i}`}>{contentText(idea.text)}</li>
                  ))}
                </ul>
              </S.DataContent>
            </S.Col>
          </Row>,
        )}
      {investigationData?.getInvestigationCatalogById?.practices &&
        doPropertiesInValuesContainSearchKeywords(
          investigationData?.getInvestigationCatalogById?.practices,
          'text',
        ) && (
          <Row gutter={[16, 4]} justify="center">
            <S.Col span={23}>{headerWithAnchorPoint('ap-practices', `${labelType}Practices`)}</S.Col>
            <S.Col span={23}>
              <S.DataContent>
                <ul>
                  {investigationData?.getInvestigationCatalogById?.practices?.map((practice, i) => (
                    <li key={`practices-${i}`}>{contentText(practice.text)}</li>
                  ))}
                </ul>
              </S.DataContent>
            </S.Col>
          </Row>
        )}
      {stepData('ap-format', 'Assessment Format', investigationData?.getInvestigationCatalogById?.format?.name)}
      {stepData('ap-focus', 'Assessment Focus', investigationData?.getInvestigationCatalogById?.focus?.name)}
      {investigationData?.getInvestigationCatalogById?.time &&
        showFilteredComponent(
          investigationData?.getInvestigationCatalogById?.time?.toString(),
          <Row gutter={[16, 4]} justify="center">
            <S.Col span={23}>{headerWithAnchorPoint('ap-time-to-complete', 'Assessment Time to complete')}</S.Col>
            <S.Col span={23}>
              <S.DataContent>{`${investigationData?.getInvestigationCatalogById?.time} minutes`}</S.DataContent>
            </S.Col>
          </Row>,
        )}
      {investigationData?.getInvestigationCatalogById?.crosscuttingConcepts &&
        doPropertiesInValuesContainSearchKeywords(
          investigationData?.getInvestigationCatalogById?.crosscuttingConcepts,
          'text',
        ) && (
          <Row gutter={[16, 4]} justify="center">
            <S.Col span={23}>
              {headerWithAnchorPoint('ap-crosscutting-concepts', `${labelType}Crosscutting Concepts`)}
            </S.Col>
            <S.Col span={23}>
              <S.DataContent>
                <ul>
                  {investigationData?.getInvestigationCatalogById?.crosscuttingConcepts?.map((concept, i) => (
                    <li key={`corssConcepts-${i}`}>{contentText(concept.text)}</li>
                  ))}
                </ul>
              </S.DataContent>
            </S.Col>
          </Row>
        )}
      {showFilteredStateStandards(investigationData?.getInvestigationCatalogById?.standards) && (
        <Row gutter={[16, 4]} justify="center">
          <S.Col span={23}>{headerWithAnchorPoint('ap-standards', `${labelType}Standards`)}</S.Col>
          <S.Col span={23}>
            <S.DataContent>
              <ul>
                {investigationData?.getInvestigationCatalogById?.standards?.map((standard, i) => (
                  <li key={`standards-${i}`}>{contentText(`${standard.code}: ${standard.fullStatement}`)}</li>
                ))}
              </ul>
            </S.DataContent>
          </S.Col>
        </Row>
      )}
      <Row gutter={[0, 4]} justify="center">
        <S.Col span={24}>
          {investigationData?.getInvestigationCatalogById.steps.map((step, index) => {
            const showRemoteContent = (step.mode && ['ALL', 'REMOTE'].includes(step.mode)) || !step.mode;
            const showInPersonContent = (step.mode && ['ALL', 'INPERSON'].includes(step.mode)) || !step.mode;
            const activitySteps = populateActivitySteps(step, showRemoteContent, showInPersonContent);

            const previewText = stepData(`ap-step-${step.id}-previewText`, 'Preview text', step.previewText);
            const inPersonPreviewText = stepData(
              `ap-step-${step.id}-inPersonPreviewText`,
              'In-Person preview text',
              step.inPersonPreviewText,
            );
            const remotePreviewText = stepData(
              `ap-step-${step.id}-remotePreviewText`,
              'Remote preview text',
              step.remotePreviewText,
            );

            const mode = stepData(`ap-step-${step.id}-mode`, 'Mode', step.mode ? modeMap[step.mode] : null);

            if (
              [
                ...activitySteps,
                mode,
                previewText,
                showInPersonContent ? inPersonPreviewText : null,
                showRemoteContent ? remotePreviewText : null,
              ].every((item) => item === null)
            )
              return null;

            return (
              <div key={`steps-${index}`}>
                <Row gutter={[16, 4]} justify="center">
                  <S.Col span={23}>
                    {headerWithAnchorPoint(`ap-step-${step.id}`, `Investigation Stage ${index + 1}(${step.name})`)}
                  </S.Col>
                </Row>
                {mode}
                {previewText}
                {showInPersonContent ? inPersonPreviewText : null}
                {showRemoteContent ? remotePreviewText : null}
                <Row gutter={[16, 4]} justify="center">
                  <S.Col span={23}>{headerWithAnchorPoint(`ap-step-${step.id}-activities`, `Activities`)}</S.Col>
                </Row>
                {searchKeywords.length === 0 &&
                  step.materials?.map((material, index) => {
                    return (
                      <Row gutter={[16, 4]} justify="center" key={`material-${index}`}>
                        <S.Col span={23}>
                          {headerWithAnchorPoint(`ap-material-${index}`, `Material ${index + 1} PDF`)}
                        </S.Col>
                        <S.Col span={23}>
                          <S.DataContent>{fileLink(material.url, material.filename)}</S.DataContent>
                        </S.Col>
                      </Row>
                    );
                  })}
                {activitySteps}
              </div>
            );
          })}
        </S.Col>
      </Row>
    </Anchor>
  );
};

export default InvestigationDetailsReviewPage;
