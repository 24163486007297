import styled from 'styled-components';
import { Layout } from 'antd';

const { Content: AntdContent } = Layout;

const contentSizeMultiplier = (contentSize?: string) => {
  if (contentSize === 'large') return 1.2;
  else if (contentSize === 'small') return 0.8;
  else return 1;
};

export const Content = styled(AntdContent)<{
  $noMargin?: boolean;
  $fullscreen?: boolean;
  $presentationMode?: boolean;
  $contentSize?: string;
}>`
  margin-left: ${(props) => (props.$noMargin || props.$fullscreen ? '0' : '0px')};
  padding-top: ${(props) => (props.$presentationMode && props.$fullscreen ? '60' : '40')}px;
  padding-bottom: ${(props) => (props.$presentationMode && props.$fullscreen ? '20' : '40')}px;
  overflow-x: hidden;
  position: relative;
  background: rgb(245, 245, 251);
  min-height: 100%;
  padding-left:30px;
  ${(props) =>
    props.$fullscreen
      ? `
      height: 100%;
      width: 100%;
  `
      : ''}

  h1 {
    color: #000000;
    font-weight: bold;
    font-size: ${(props) =>
      props.$presentationMode ? 5.2 * contentSizeMultiplier(props.$contentSize) + 'vh' : '26px'};
  }

  h2 {
    color: #767676;
    font-weight: bold;
    font-size: ${(props) =>
      props.$presentationMode ? 3.8 * contentSizeMultiplier(props.$contentSize) + 'vh' : '16px'};
  }

  p {
    font-size: ${(props) =>
      props.$presentationMode ? 4.2 * contentSizeMultiplier(props.$contentSize) + 'vh' : '18px'};
    color: #767676;
    font-weight: ${(props) => (props.$presentationMode ? '500' : 'normal')};
    margin-bottom: 16px;
    margin-top: 16px;
    ${(props) => props.$presentationMode && 'line-height: 1.4'};
  }

  li {
    color: #767676;
    font-size: ${(props) =>
      props.$presentationMode ? 3.8 * contentSizeMultiplier(props.$contentSize) + 'vh' : '16px'};
  }

  ul > .ant-rate-star {
    color: rgb(250, 219, 20);
  }

  .ant-skeleton-element {
    width: 100%;
  }

  .ant-skeleton-input {
    border-radius: 2px;
    height: 28px;
  }

  .ant-skeleton-input-sm {
    height: 18px;
  }

  .ant-skeleton-input-lg {
    height: 40px;
  }

  @media (max-width: 768px) {
    margin-left: ${(props) => (props.$noMargin || props.$fullscreen ? '0' : '240px')};
  }
`;

export const IconContainer = styled.span<{ right?: number }>`
  position: absolute;
  top: 23px;
  right: ${(props) => props.right ?? 60}px;
  min-width: 22px;
  min-height: 22px;
  max-width: 22px;
  max-height: 22px;
  cursor: pointer;
  color: #767676;

  svg {
    height: 100%;
    width: 100%;
  }
`;
