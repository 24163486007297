import React from 'react';
import { Avatar as AntdAvatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import { FiUser } from 'react-icons/fi';
import { themeConfig } from '../../utils/theme';

interface Props {
  src?: string;
  margin?: string;
  backgroundColor?: string;
  size?: AvatarSize;
  color?: string;
}

const Avatar = ({
  src,
  margin = 'unset',
  color = themeConfig.noColor.color,
  backgroundColor = '#CCC',
  size,
  ...rest
}: Props) => {
  return (
    <AntdAvatar
      {...rest}
      src={src}
      size={size}
      icon={!src && <FiUser />}
      style={{
        margin,
        border: `2px solid ${src ? themeConfig.primaryColor.background : themeConfig.noColor.color}`,
        backgroundColor,
        color,
        cursor: 'pointer',
      }}
    />
  );
};

export default Avatar;
