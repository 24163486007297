/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, message, Table, Tooltip, Checkbox } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { FaLock, FaUnlockAlt } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import { BiCheck, BiEdit } from 'react-icons/bi';

import * as S from './styles';
import { GQL_investigationTeacherSummary_PerStudent } from '../../../types/teacher';
import Button from '../../../shared/Button';
import { centerAlign, Filter } from '../../../utils/antd';
import { formatDateTime } from '../../../utils/date';
import { ASSESSMENT_STATUS_TYPE, ASSESSMENT_STATUS_TYPE_TEXT } from '../../../types/assessments';

import { gqlSchema } from '../../../gql/schema';
import { GQL_EditAssessmentPerStudentInput, GQL_InvestigationDashboard } from '../../../types/investigation';
import { useHistory } from 'react-router-dom';
import TagInput from '../../../shared/TagInput';
import { useAuth } from '../../../hooks/useAuth';
import TableCheckboxGroup from '../../../shared/TableCheckboxGroup/TableCheckboxGroup';
import { FiFilter } from 'react-icons/fi';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PickerElement, SelectallColHeader, DatetimePickersModal } from './components';

type Props = {
  assessmentId: string;
  assessmentData: any;
  loading: boolean;
  handleOnUpdate: () => {};
  submissionVersion: number;
};

export const AssessmentSummaryTable: React.FC<Props> = (props) => {
  const { assessmentId, assessmentData, loading, handleOnUpdate, submissionVersion } = props;

  const { isTeacherOrFacilitator, isTeacherAssistant, user } = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedUsers, setSelectedUsers]: any = useState(new Map());
  const [bulkEditOptions, setBulkEditOptions] = useState(null);
  const [bulkdModalVisible, setBulkModalVisible] = useState(false);
  const history = useHistory();

  const statusToText = (completion: number, status: ASSESSMENT_STATUS_TYPE) => {
    if (completion === 1) {
      return ASSESSMENT_STATUS_TYPE_TEXT[ASSESSMENT_STATUS_TYPE.COMPLETED] || '';
    }
    return ASSESSMENT_STATUS_TYPE_TEXT[status] || '';
  };

  // query params variables
  const existingParams = new URLSearchParams(history.location.search);

  // tag filter
  const [searchTagsVisible, setSearchTagsVisible] = useState(false);
  const [tagsToFilter, setTagsToFilter] = useState<string[]>(existingParams.get('tags')?.split(',') || []);
  const [tagsToFilterInternal, setTagsToFilterInternal] = useState(tagsToFilter);
  const [tagsToFilterEmpty, setTagsToFilterEmpty] = useState<boolean>(false);
  const [tags, setTags] = useState<Filter[]>([]);

  useEffect(() => {
    const existingParams = new URLSearchParams(history.location.search);

    if (tagsToFilter.length > 0) {
      existingParams.set('tags', tagsToFilter.toString() || existingParams.get('tags')!);
    } else if (tagsToFilterEmpty) {
      existingParams.delete('tags');
    }

    history.replace({ search: existingParams.toString() });
  }, [history, tagsToFilter, tagsToFilterEmpty]);

  useEffect(() => {
    if (assessmentData?.perStudents && assessmentData?.perStudents?.length) {
      const studentsTags = assessmentData.perStudents.map((data: any) => data?.tags || []);
      const flatTags = [].concat(...studentsTags).map((data: any) => {
        return {
          text: data?.tag,
          color: data?.color,
          value: data?.tag,
        };
      });
      const uniqueTags = [...new Map(flatTags.map((item: any) => [item['value'], item])).values()];

      setTags(uniqueTags);
    }
  }, [assessmentData]);

  const [editInvestigationDuration, { loading: editing }] = useMutation<
    { editInvestigationDuration: GQL_InvestigationDashboard },
    { data: GQL_EditAssessmentPerStudentInput }
  >(gqlSchema.InvestigationSchema.mutations.EDIT.editAssessmentPerStudent, {
    onCompleted: (data) => {
      message.success(`Duration updated successfully`);
      handleOnUpdate();
    },
    onError: (err) => {
      message.error(`There was an error trying to edit the duration, please try again later`);
      handleOnUpdate();
    },
  });

  const [editInvestigationLockDuration, { loading: lockEditing }] = useMutation<
    { editInvestigationDuration: GQL_InvestigationDashboard },
    { data: GQL_EditAssessmentPerStudentInput }
  >(gqlSchema.InvestigationSchema.mutations.EDIT.editAssessmentLockPerStudent, {
    onCompleted: (data) => {
      message.success(`Assessment lock updated successfully`);
      handleOnUpdate();
    },
    onError: (err) => {
      message.error(`There was an error trying to edit the lock, please try again later`);
      handleOnUpdate();
    },
  });

  const isOverdue = (record: any) => {
    return record && record.endDate < Date.now();
  };

  const overdueLock = useMemo(() => {
    return (
      <Tooltip title="Update close date and time to the future in order to unlock assessment">
        <FaLock color="#ffb1b1" />
      </Tooltip>
    );
  }, []);

  const handleDurationEdit = useCallback(
    (value: number, key: string, record: any) => {
      editInvestigationDuration({
        variables: {
          data: {
            submissionVersion,
            key,
            value,
            classId: assessmentData.classId,
            investigationId: assessmentId,
            studentId: record?.userId,
          },
        },
      });
    },
    [assessmentData, editInvestigationDuration, assessmentId, submissionVersion],
  );

  const handleUnlock = useCallback(
    (record: GQL_investigationTeacherSummary_PerStudent) => {
      if (record.isLocked || lockEditing) {
        editInvestigationLockDuration({
          variables: {
            data: {
              classId: assessmentData.classId,
              investigationId: assessmentData.investigationId,
              studentId: record?.userId,
              submissionVersion: submissionVersion,
            },
          },
        });
      }
    },
    [editInvestigationLockDuration, lockEditing, assessmentData, submissionVersion],
  );

  const handleCheckOnChange = useCallback(
    (e: CheckboxChangeEvent, student: GQL_investigationTeacherSummary_PerStudent) => {
      const checked = e.target.checked;

      /**
       * React does a comparison of new state and old state and in this case, it compares the references of new Map to * old Map which share the same value. So, React bails out without triggering an update.
       * What we need to pass to setSelectedUsers is a clone of the map instead of a copy of the reference.
       * A simple change to the setSelectedUsers will do the trick
       */
      const newRef = new Map(selectedUsers.set(student.userId, checked));
      setSelectedUsers(newRef);

      // disable selected all state when a single element is de-selected
      if (selectedAll && !checked) {
        setSelectedAll(false);
      }
    },
    [setSelectedUsers, selectedUsers, setSelectedAll, selectedAll],
  );

  const handleSelectedAll = useCallback(
    (e: CheckboxChangeEvent) => {
      const checked = e.target.checked;

      setSelectedAll(checked);
      const students = assessmentData?.perStudents || [];
      for (let index = 0; index < students.length; index++) {
        const student = students[index];
        const newRef = new Map(selectedUsers.set(student.userId, checked));
        setSelectedUsers(newRef);
      }
    },
    [assessmentData, setSelectedUsers, selectedUsers],
  );

  const handleEditItemsSelections = (selected: any) => {
    setBulkEditOptions(selected);

    if (selected) {
      setBulkModalVisible(true);
    }
  };

  const studentIds = useMemo(() => {
    const selectedStudents: string[] = [];
    selectedUsers.forEach(function (value: boolean, key: string) {
      if (value) {
        selectedStudents.push(key);
      }
    });
    return selectedStudents;
  }, [selectedUsers]);

  const defaultAssessmentColumns: any = useMemo(() => {
    return [
      {
        dataIndex: 'checked',
        fixed: 'left',
        width: '130px',
        align: centerAlign,
        title: () => {
          return (
            <SelectallColHeader
              selectedAll={selectedAll}
              disabled={![...selectedUsers.values()].some((val) => val)}
              handleSelectedAll={handleSelectedAll}
              triggerEditElements={handleEditItemsSelections}
            />
          );
        },
        render: (text: string, record: GQL_investigationTeacherSummary_PerStudent, index: number) => {
          return (
            <Checkbox
              key={`user-selection-${record.userId}`}
              checked={selectedUsers.get(record.userId)}
              disabled={loading}
              onChange={(e) => handleCheckOnChange(e, record)}
            ></Checkbox>
          );
        },
      },
      {
        title: 'Student Name',
        dataIndex: 'firstName',
        fixed: 'left',
        sorter: {
          compare: (a: any, b: any) => `${a.firstName}`.localeCompare(`${b.firstName}`),
          multiple: 2,
        },
        render: (text: string, record: GQL_investigationTeacherSummary_PerStudent, index: number) => {
          return (
            <S.TableNameContainer>
              <p>
                {record.firstName} {record.lastName}
              </p>
            </S.TableNameContainer>
          );
        },
      },
      {
        title: 'Tag',
        dataIndex: 'tag',
        width: '50px',
        align: centerAlign,
        sorter: {
          compare: (a: any, b: any) => `${a.tags}`.localeCompare(`${b.tags}`),
          multiple: 2,
        },
        filters: tags,
        filteredValue: tagsToFilter ?? [],
        filterDropdownVisible: searchTagsVisible,
        filterDropdown: () => (
          <TableCheckboxGroup
            value={tagsToFilterInternal}
            dataset={tags}
            onCheckboxGroupChange={(values) => {
              const unique = values?.filter((item, pos, self) => self.indexOf(item) === pos) || [];
              setTagsToFilterEmpty(false);
              setTagsToFilterInternal(unique as string[]);
            }}
            onCheckboxGroupReset={() => {
              setTagsToFilterEmpty(true);
              setTagsToFilterInternal([]);
              setTagsToFilter([]);
              setTimeout(() => setSearchTagsVisible(false), 100);
            }}
            onCheckboxGroupOk={() => {
              setTagsToFilterEmpty(tagsToFilterInternal.length === 0);
              setTagsToFilter(tagsToFilterInternal);
              setTimeout(() => setSearchTagsVisible(false), 100);
            }}
          />
        ),
        onFilter: (value: string | number | boolean, record: any): boolean => {
          return !!record.tags?.map((t: any) => t.tag)?.includes(value?.toString());
        },
        filterIcon: (filtered: boolean) => {
          const existingParams = new URLSearchParams(history.location.search);
          return (
            <S.SearchIcon $searchVisible={searchTagsVisible}>
              <FiFilter
                size={18}
                style={{
                  color: filtered || tagsToFilter.length > 0 || existingParams.get('tags')! ? '#1890ff' : undefined,
                }}
              />
            </S.SearchIcon>
          );
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchTagsVisible(visible);
        },
        render: (text: string, record: any, index: number) => {
          return <TagInput existingUserTags={record.tags} editable={false} key={`${record.userId}-tag-${index}`} />;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return (
            <span role="none" >
              {statusToText(record.completion, record?.status || ASSESSMENT_STATUS_TYPE.COMPLETED)}
              &nbsp;
              {record?.isLocked ? (
                isOverdue(record) ? (
                  overdueLock
                ) : (
                  <FaLock color="#f14c4c" cursor="pointer" onClick={() => handleUnlock(record)} />
                )
              ) : isOverdue(record) ? (
                overdueLock
              ) : (
                <FaUnlockAlt color="#3ebc89" />
              )}
            </span>
          );
        },
      },
      {
        title: 'Open Date',
        dataIndex: 'openDate',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return (
            <PickerElement
              disabled={loading || editing}
              dateValue={record?.startDate || assessmentData?.startDate}
              data={{ ...record, ...assessmentData, index }}
              handleChange={(value: number) => handleDurationEdit(value, 'startDate', record)}
            />
          );
        },
      },
      {
        title: 'Open Time',
        dataIndex: 'openTime',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return (
            <PickerElement
              isTimePicker
              disabled={loading || editing}
              dateValue={record?.startDate || assessmentData?.startDate}
              format={'hh:mm a'}
              inputFormat={'hh:mm a'}
              data={{ ...record, ...assessmentData, index }}
              handleChange={(value: number) => handleDurationEdit(value, 'startDate', record)}
            />
          );
        },
      },
      {
        title: 'Close Date',
        dataIndex: 'closeDate',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return (
            <PickerElement
              disabled={loading || editing}
              dateValue={record?.endDate || assessmentData?.endDate}
              data={{ ...record, ...assessmentData, index }}
              handleChange={(value: number) => handleDurationEdit(value, 'endDate', record)}
            />
          );
        },
      },
      {
        title: 'Close Time',
        dataIndex: 'closeTime',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return (
            <PickerElement
              isTimePicker
              disabled={loading || editing}
              dateValue={record?.endDate || assessmentData?.endDate}
              format={'hh:mm a'}
              inputFormat={'hh:mm a'}
              data={{ ...record, ...assessmentData, index }}
              handleChange={(value: number) => handleDurationEdit(value, 'endDate', record)}
            />
          );
        },
      },

      {
        title: 'Response',
        width: 150,
        align: centerAlign,
        render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) => {
          const isReadyToGrade = record.steps[0].completed;
          const allowedToGrade =
            isReadyToGrade && (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher || (isTeacherAssistant && user.isAllowedGrading));
          const isGraded = record.isGraded;
          return (
            <Button
              text={ isGraded ? 'Graded' : allowedToGrade ? 'Grade' : 'View'}
              onClick={() =>
                history.push(
                  `/teacher-dashboard/assessment-details/${assessmentId}/${allowedToGrade ? 'grade/' : ''}${
                    record.userId
                  }`,
                )
              }
              block
            />
          );
        },
      },
    ];
  }, [
    assessmentData,
    loading,
    editing,
    history,
    assessmentId,
    handleUnlock,
    handleDurationEdit,
    isTeacherOrFacilitator,
    isTeacherAssistant,
    user.isAllowedGrading,
    tags,
    tagsToFilter,
    searchTagsVisible,
    tagsToFilterInternal,
    overdueLock,
    selectedAll,
    handleCheckOnChange,
    handleSelectedAll,
    selectedUsers,
  ]);

  const columns: ColumnsType<any> = useMemo(() => {
    return defaultAssessmentColumns;
  }, [defaultAssessmentColumns]);

  return (
    <>
      <S.TableWrapper>
        <Table
          columns={columns}
          bordered
          className="remove-horizontal-scroll"
          loading={loading}
          dataSource={assessmentData?.perStudents || []}
          scroll={{ x: 'max-content' }}
          rowKey={(record) => record.userId}
          sticky
        />
      </S.TableWrapper>

      <DatetimePickersModal
        visible={bulkdModalVisible}
        setVisible={setBulkModalVisible}
        startDate={assessmentData?.startDate}
        endDate={assessmentData?.endDate}
        enabledOptions={bulkEditOptions || {}}
        studentIds={studentIds}
        classId={assessmentData?.classId}
        investigationId={assessmentId}
        actionCompleted={() => {
          setBulkModalVisible(false);
          handleOnUpdate();
        }}
      />
    </>
  );
};
