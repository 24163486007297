import { gql } from '@apollo/client';

export const ORGANIZATION = {
  newOrganizationAdmin: gql`
    fragment NewOrganizationAdmin on OrganizationAdminList {
      userId
      name
      email
      roles
    }
  `,
};

export default ORGANIZATION;
