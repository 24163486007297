export enum ASSESSMENT_STATUS_TYPE {
  IN_PROGRESS = 'IN_PROGRESS',
  READY_FOR_GRADING = 'READY_FOR_GRADING',
  OVERDUE = 'OVERDUE',
  COMPLETED = 'COMPLETED',
  NOT_STARTED = 'NOT_STARTED',
}

export enum ASSESSMENT_STATUS_TYPE_TEXT {
  IN_PROGRESS = 'In Progress',
  READY_FOR_GRADING = 'Ready for Grading',
  OVERDUE = 'Overdue',
  COMPLETED = 'Completed',
  NOT_STARTED = 'Not Started',
}

export enum ASSESSMENT_OPTIONS_TIMES {
  OPEN_DATE = 'OPEN_DATE',
  CLOSE_DATE = 'CLOSE_DATE',
  OPEN_TIME = 'OPEN_TIME',
  CLOSE_TIME = 'CLOSE_TIME',
}

export enum AssessmentTypes {
  PAPER = 'paper',
  PAPERLESS = 'paperless',
}

export enum AssessmentTypesLabel {
  PAPER = 'Paper',
  PAPERLESS = 'Paperless',
}

export const assessmentTypes = [
  {
    value: AssessmentTypes.PAPER,
    name: AssessmentTypesLabel.PAPER,
  },
  {
    value: AssessmentTypes.PAPERLESS,
    name: AssessmentTypesLabel.PAPERLESS,
  },
];

export enum StepMode {
  INPERSON = 'INPERSON',
  REMOTE = 'REMOTE',
  ALL = 'ALL',
}
