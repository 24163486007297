import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Table, message } from 'antd';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiSearch, FiTrash2 } from 'react-icons/fi';
import { withRouter } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import Button from '../../../shared/Button';
import TableSearchBox from '../../../shared/TableSearchBox';
import { centerAlign } from '../../../utils/antd';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';
import { useAuth } from '../../../hooks/useAuth';
import {
  GQL_QueryInviteFilterInput,
  GQL_QueryTransferInviteResponse,
  GQL_SendInviteResponse,
} from '../../../types/invites';
import { GQL_SortingInput, SortOptions } from '../../../types/sorting';
import ModalConfirm from '../../../shared/ModalConfirm';
import { formatDateTime } from '../../../utils/date';

const PendingTransferInvites = () => {
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const [filteredInvites, setFilteredInvites] = useState<GQL_QueryTransferInviteResponse[]>([]);
  const [nameToFilter, setNameToFilter] = useState('');
  const [emailToFilter, setEmailToFilter] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.ReactText[]>([]);
  const [deleteInvitesVisible, setDeleteInvitesVisible] = useState(false);
  const user = useAuth();
  const [sorting, setSorting] = useState<GQL_SortingInput[]>([]);
  const refName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);

  const { data: invitesData, loading } = useQuery<
    { pendingTransferInvites: GQL_QueryTransferInviteResponse[] },
    { data: GQL_QueryInviteFilterInput }
  >(gqlSchema.TransferInviteSchema.queries.pendingTransferInvites, {
    onError: (err) => {
      message.error('There was an error loading invites: ' + err.message || 'Unexpected Error');
    },
    onCompleted: ({ pendingTransferInvites }) => {
      setFilteredInvites(pendingTransferInvites);
    },
  });

  const [deleteInvites, { loading: loadingDeleteInvites }] = useMutation(
    gqlSchema.UserSchema.mutations.EDIT.deleteUsersInvites,
    {
      refetchQueries: ['GetInvites'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        message.success('Invites deleted successfully');
        setDeleteInvitesVisible(false);
        setSelectedRowKeys([]);
      },
      onError: (err) => {
        message.error('Error deleting invites: ' + err.message);
      },
    },
  );

  const handleDeleteUsers = () => {
    deleteInvites({
      variables: {
        inviteIds: selectedRowKeys,
      },
    });
  };

  useEffect(() => {
    const originalData = invitesData?.pendingTransferInvites || [];
    const filteredData = originalData.filter(
      (d) => d.userEmail?.includes(emailToFilter) && d.userName?.includes(nameToFilter),
    );

    const sortedData = filteredData.sort((a, b) => {
      for (const sort of sorting) {
        if (sort.field === 'userName') {
          const compare = a.userName.localeCompare(b.userName);
          if (compare !== 0) return sort.order === 'ASC' ? compare : compare * -1;
        } else if (sort.field === 'userEmail') {
          const compare = a.userEmail.localeCompare(b.userEmail);
          if (compare !== 0) return sort.order === 'ASC' ? compare : compare * -1;
        } else {
          const compare = a.sendDate - b.sendDate;
          if (compare !== 0) return sort.order === 'ASC' ? compare : compare * -1;
        }
      }
      return 0;
    });

    setFilteredInvites(sortedData);
  }, [nameToFilter, emailToFilter, invitesData, user, sorting]);

  const manageSortings = useCallback(
    (sort: GQL_SortingInput) => {
      const sorts = sorting.filter((s) => s.field !== sort.field) || [];
      sorts.push(sort);
      setSorting(sorts);
    },
    [sorting],
  );

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      const order = sorter.order ? (sorter.order === 'ascend' ? SortOptions.ASC : SortOptions.DESC) : undefined;
      manageSortings({ field: sorter.columnKey, order });
    },
    [manageSortings],
  );

  const [sendInvites] = useMutation<{ sendInvites: GQL_SendInviteResponse[] }>(
    gqlSchema.TransferInviteSchema.mutations.sendTransferInvites,
    {
      onCompleted: (data) => {
        message.success('Invite sent successfully');
      },
      onError: (err) => {
        message.error('There was an error trying to send the invite, please try again later');
      },
    },
  );

  const onResendInvite = useCallback(
    (userRow: GQL_QueryTransferInviteResponse) => {
      sendInvites({
        variables: {
          tokens: [userRow.id],
        },
      });
    },
    [sendInvites],
  );

  const columns = useMemo(
    () => [
      {
        title: 'User Email',
        align: centerAlign,
        dataIndex: 'userEmail',
        key: 'userEmail',
        width: '30%',
        sorter: true,
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox onClearFilters={() => setEmailToFilter('')} ref={refEmail} {...filterProps} />
        ),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean) => {
          if (!value) setEmailToFilter('');
          else setEmailToFilter(value.toString());
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Name',
        sorter: true,
        align: centerAlign,
        dataIndex: 'userName',
        key: 'userName',
        width: '30%',
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox ref={refName} onClearFilters={() => setNameToFilter('')} {...filterProps} />
        ),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_QueryTransferInviteResponse) => {
          if (!value) setNameToFilter('');
          else setNameToFilter(value.toString());
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Send Date',
        dataIndex: 'sendAt',
        sorter: true,
        align: centerAlign,
        width: '25%',
        render: (text: string, record: GQL_QueryTransferInviteResponse) => {
          return formatDateTime(record.sendDate, 'MM.dd.yyyy HH:mm:ss');
        },
      },
      {
        title: 'Actions',
        align: centerAlign,
        render: (text: string, record: GQL_QueryTransferInviteResponse) => {
          return <Button text="Resend Invite" onClick={() => onResendInvite(record)} block />;
        },
        width: '15%',
      },
    ],
    [onResendInvite, searchEmailVisible, searchNameVisible],
  );

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={3} xl={4} lg={5} md={24} sm={24}>
          {user.isAdiSuperAdmin && !!selectedRowKeys?.length && (
            <Button
              text={'Delete Invites'}
              onClick={() => setDeleteInvitesVisible(true)}
              theme={themeConfig.primaryOutlined}
              block
              icon={<FiTrash2 />}
            />
          )}
        </Col>
        <Col sm={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_QueryTransferInviteResponse) => record.id}
              rowSelection={
                user.isAdiSuperAdmin
                  ? {
                      selectedRowKeys,
                      onChange: (k) => setSelectedRowKeys(k),
                    }
                  : undefined
              }
              bordered
              pagination={{ hideOnSinglePage: true }}
              dataSource={filteredInvites}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <ModalConfirm
        visible={deleteInvitesVisible}
        title={`Delete selected invites`}
        lineInfo1={`Are you sure you want to delete ${selectedRowKeys.length} invites? All users data`}
        lineInfo2={`will be permanently deleted. This action is irreversible`}
        deleteButtonTitle="Delete invites"
        nameToCheck={`delete ${selectedRowKeys.length} invites`}
        errorMessage="Provided text is wrong"
        setVisible={setDeleteInvitesVisible}
        loading={loadingDeleteInvites}
        deleteFn={handleDeleteUsers}
      ></ModalConfirm>
    </>
  );
};

export default withRouter(PendingTransferInvites);
