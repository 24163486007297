import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { message, Modal, Button, Input, Form as AntdForm } from 'antd';

import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../../gql/schema';
import { generatePdfAndUpload } from '../../QuotePdf';
import ShopifyBuyButton from '../../BookPage/ShopifyBuyButton';

interface ShopifyWrapperProps {
    productId: string;
    
}

const ShopifyWrapper: React.FC<ShopifyWrapperProps> = ({ productId   }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = AntdForm.useForm();

    const { data: quoteData } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getQuoteData, {
        onError: (error) => {
            message.error(`Error in fetching quote details - ${error.message || 'Unexpected Error'}`);
        },
    });
    // TODO: Move the code to fetch user cart and user data to a more appropriate place
const { data: userCart, refetch: refetchCart } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getUserCart, {
    onError: (error) => {
        message.error(`Error fetching user cart - ${error.message || 'Unexpected Error'}`);
    },
});

const { data: userMetaData } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getUserMetaData, {
    onError: (error) => {
        message.error(`Error fetching user data - ${error.message || 'Unexpected Error'}`);
    },
});

    const [addToCart] = useMutation(gqlSchema.ShopifySchema.mutations.ADD_TO_CART, {
        onCompleted: () => {
            refetchCart();
        },
        onError: (error) => {
            message.error(`Error adding product to cart - ${error.message || 'Unexpected Error'}`);
        },
    });

    const [updateCartItem] = useMutation(gqlSchema.ShopifySchema.mutations.UPDATE_CART_ITEM, {
        onCompleted: () => {
            message.success('Cart item updated successfully!');
            refetchCart();
        },
        onError: (error) => {
            message.error(`Error updating cart item - ${error.message || 'Unexpected Error'}`);
        },
    });

    const [sendQuoteEmail] = useMutation<{ sendQuoteEmail: { pdfUrl: string, metadata: string } }>(
        gqlSchema.ShopifySchema.mutations.SEND_QUOTE_EMAIL,
        {
            onCompleted: () => {
                message.success('Quote request sent successfully!');
                setTimeout(() => {
                    history.push('/bookquoteList');
                }, 5000);
            },
            onError: (error) => {
                message.error(`Error sending quote request - ${error.message || 'Unexpected Error'}`);
            },
        },
    );

    const [saveQuoteToS3] = useMutation(gqlSchema.ShopifySchema.mutations.UPLOAD_QUOTE, {
        onCompleted: (data) => {
            console.log('File uploaded successfully:', data);
        },
        onError: (err) => {
            message.error('There was an error uploading your file: ' + err.message);
        },
    });

    const handleRequestQuoteButtonClick = () => {
        setIsModalVisible(true);
    };

    const handleRequestQuote = async (formData: any) => {
        setLoading(true);  // Show loading state
    
        try {
            let quoteDetails = quoteData?.getQuoteData;
    
          if (!quoteDetails) {
            throw new Error('Quote details are not available.');
          }
    
          const metadata = `${formData.city || "N/A"} 
                ${formData.state || "N/A"} ${formData.country || "N/A"} ${formData.zipcode || "N/A"} `
          quoteDetails = {
            ...quoteDetails,
            teacherName: formData?.name,
            email: formData?.email,
            shippingAddress: metadata,
          }
    
          const pdfBlob = await generatePdfAndUpload({ ...quoteDetails, userCart });
    
          if (!(pdfBlob instanceof Blob)) {
            throw new Error('Generated PDF is not a Blob.');
          }
    
          const file = new File([pdfBlob], 'quote.pdf', { type: 'application/pdf' });
          
    
          const link = await saveQuoteToS3({ variables: { file } });
          const pdfUrl = link?.data?.saveQuoteToS3;
    
          await sendQuoteEmail({ variables: { pdfUrl, metadata: JSON.stringify(formData) } });
          message.success('Quote request sent successfully!');
          setIsModalVisible(false);  // Close the modal after submission
        } catch (err) {
          console.error('Error sending quote request:', err);
          message.error(`Error sending quote request: ${err || 'Unexpected Error'}`);
        } finally {
          setLoading(false);
        }
      };

    const addToCartCb = () => {
        addToCart({
            variables: {
                productId,
                quantity: (userCart?.cartItem.find((item: any) => item.productId === productId)?.quantity || 0) + 1,
            },
        });
    };

    const handleUpdateCartItem = (shopifyProductId: string, quantity: number) => {
        if (shopifyProductId && quantity >= 0) {
            updateCartItem({ variables: { productId: shopifyProductId, quantity } });
        }
    };

    const handleRemoveCartItem = (shopifyProductId: string) => {
        updateCartItem({ variables: { productId: shopifyProductId, quantity: 0 } });
    };

    const increaseQuantity = (shopifyProductId: string) => {
        const item = userCart.cartItem.find((item: any) => item.shopifyId === shopifyProductId);
        if (item) {
            handleUpdateCartItem(shopifyProductId, item.quantity + 1);
        }
    };

    const decreaseQuantity = (shopifyProductId: string) => {
        const item = userCart.cartItem.find((item: any) => item.shopifyId === shopifyProductId);
        if (item && item.quantity > 1) {
            handleUpdateCartItem(shopifyProductId, item.quantity - 1);
        } else if (item && item.quantity === 1) {
            handleRemoveCartItem(shopifyProductId);
        } else {
            message.error('Quantity cannot be less than 1');
        }
    };

    useEffect(() => {
        if (userMetaData) {
            form.setFieldsValue({
                name: userMetaData?.getUserMetaData.name || '',
                email: userMetaData?.getUserMetaData.email,
                jobDescription: userMetaData?.getUserMetaData.jobDescription || '',
                state: userMetaData?.getUserMetaData.state || '',
                country: userMetaData?.getUserMetaData.country || '',
                city: userMetaData?.getUserMetaData.city || '',
                zipCode: userMetaData?.getUserMetaData.zipCode || '',
            });
        }
    }, [userMetaData, form]);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1em' }}>
            <ShopifyBuyButton
                productId={productId}
                addToCart={addToCartCb}
                cartData={userCart}
                decreaseQuantity={decreaseQuantity}
                increaseQuantity={increaseQuantity}
                handleRequestQuote={handleRequestQuoteButtonClick}
                handleUpdateCartItem={handleUpdateCartItem}
            />
            <Button onClick={handleRequestQuoteButtonClick} 
            style={{
                fontSize:'14px'
            }}
            disabled={loading}>
                Request A Quote
            </Button>
            </div>
            <Modal
                title="Update Shipping Address"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <AntdForm form={form} onFinish={handleRequestQuote} layout="vertical">
                    <AntdForm.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
                        <Input placeholder="Enter your name" type="text" />
                    </AntdForm.Item>
                    <AntdForm.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },
                        ]}
                    >
                        <Input placeholder="Type your email here..." disabled />
                    </AntdForm.Item>
                    <AntdForm.Item label="Job Description" name="jobDescription">
                        <Input placeholder="Enter your job description" type="text" />
                    </AntdForm.Item>
                    <AntdForm.Item label="State" name="state">
                        <Input placeholder="Enter your state" type="text" />
                    </AntdForm.Item>
                    <AntdForm.Item label="Country" name="country">
                        <Input placeholder="Enter your country" type="text" />
                    </AntdForm.Item>
                    <AntdForm.Item label="City" name="city">
                        <Input placeholder="Enter your city" type="text" />
                    </AntdForm.Item>
                    <AntdForm.Item label="ZipCode" name="zipCode">
                        <Input placeholder="Enter your zip code" type="number" />
                    </AntdForm.Item>
                    <AntdForm.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            block
                            style={{
                                width: '50%',
                                margin: '0.5em auto 0',
                                textAlign: 'center',
                                display: 'block',
                            }}
                        >
                            Submit
                        </Button>
                    </AntdForm.Item>
                </AntdForm>
            </Modal>
        </div>
    );
};

export default ShopifyWrapper;
