/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { createRef, useCallback, useMemo, useRef, useState } from 'react';
import { Layer, Line, Stage as StageComponent, Text } from 'react-konva';
import { ArrowShape, IArrowShapeProps } from './ArrowShape';
import { EllipseShape, IEllipseShapeProps } from './EllipseShape';
import { ILineShapeProps, LineShape } from './LineShape';
import { IRectShapeProps, RectangleShape } from './RectangleShape';
import { ITextShapeProps, TextShape } from './TextShape';
import { IZoomInShapeProps, ZoomInShape } from './ZoomInShape';
import { KeyboardShortcutsLegend } from './KeyboardShortcutsLegend';
import { SketchPicker } from 'react-color';
import { Popconfirm, Popover, Row, Spin, Tooltip } from 'antd';
import { DrawingShape, IDrawingShapeProps } from './DrawingShape';
import { EraserShape, IEraserShapeProps } from './EraserShape';
import { FiCircle, FiDownload, FiFile, FiPenTool, FiSquare, FiType, FiUploadCloud, FiHelpCircle } from 'react-icons/fi';
import * as S from './styles';
import { AiOutlineLine } from 'react-icons/ai';
import { CgArrowLongRight, CgArrowsHAlt, CgErase, CgZoomIn } from 'react-icons/cg';
import { HiCursorClick } from 'react-icons/hi';
import { BsSlashSquare, BsSlashSquareFill } from 'react-icons/bs';
import { GQL_InvestigationActivity, ICanvasContentValue } from '../../../types/investigation';
import Spacer from '../../../shared/Spacer';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { dataURLToFile } from '../../../utils/files';
import { ImageShape, IImageShapeProps } from './ImageShape';
import Button from '../../../shared/Button';
import { themeConfig } from '../../../utils/theme';
import { useAuth } from '../../../hooks/useAuth';
import { GQL_InvestigationSummaryResponse } from '../../../types/teacher';

type ToolTypes =
  | 'pencil'
  | 'rectangle'
  | 'selection'
  | 'eraser'
  | 'ellipse'
  | 'line'
  | 'arrow'
  | 'double-arrow'
  | 'text'
  | 'zoom-in';

type ShapeTypes =
  | { type: 'pencil'; data: IDrawingShapeProps }
  | { type: 'rectangle'; data: IRectShapeProps }
  | { type: 'eraser'; data: IEraserShapeProps }
  | { type: 'ellipse'; data: IEllipseShapeProps }
  | { type: 'zoom-in'; data: IZoomInShapeProps }
  | { type: 'line'; data: ILineShapeProps }
  | { type: 'arrow' | 'double-arrow'; data: IArrowShapeProps }
  | { type: 'text'; data: ITextShapeProps };

interface IInvestigationCanvas {
  value?: ICanvasContentValue;
  stepId?: string;
  activityId?: string;
  canvasIndex?: number;
  userId?: string;
  investigationId?: string;
}

const CANVAS_HEIGHT = 600;

export const InvestigationCanvas: React.FC<IInvestigationCanvas> = (props) => {
  const { value, activityId, stepId, canvasIndex = -1, investigationId, userId } = props;
  const [selectedTool, setSelectedTool] = useState<ToolTypes>(value?.imageUrls?.length ? 'selection' : 'pencil');
  const [shouldFill, setShouldFill] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>('#000000');
  const [shapes, setShapes] = useState<ShapeTypes[]>([]);
  const [previousShapes, setPreviousShapes] = useState<ShapeTypes[][]>([[]]);
  const [images, setImages] = useState<IImageShapeProps[]>([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedShape, setSelectedShape] = useState<string | null>(null);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const stageRef = createRef<Konva.Stage>();
  const { isStudent, user } = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';
  const containerRef = useRef<HTMLDivElement>(null);
  const imageUrl = value?.url;
  const containerRefWidth = containerRef.current?.offsetWidth;
  const titles = value?.titles;
  const count = value?.count ?? 1;
  const valueWidth = value?.width ?? 0;

  const { data: investigationData } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId || '',
    },
    fetchPolicy: 'cache-only',
  });

  const studentList = investigationData?.getInvestigationProgressSummary?.perStudents || [];
  const student = studentList?.find((student) => student.userId === userId);
  const studentName = student ? `${student.firstName} ${student.lastName}` : undefined;

  const [submitCanvasImage, { loading: loadingSubmit }] = useMutation<
    { submitInvestigationPlanFile: GQL_InvestigationActivity },
    { file: File; stepId: string; activityId: string; submissionIndex?: number }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.submitCanvasImage);

  React.useLayoutEffect(() => {
    setCanvasWidth(Math.max(containerRef.current?.offsetWidth ?? 0, valueWidth));
  }, [containerRefWidth, valueWidth]);

  React.useEffect(() => {
    const imageShapes =
      value?.imageUrls?.map((url, i) => ({
        id: Date.now() + i.toString(),
        src: url,
        x: 25 + 225 * Math.floor(i / 8),
        y: 25 + 50 * (i % 8),
        width: 75,
      })) ?? [];
    const imageShapesFromFile =
      value?.files?.map((file, i) => ({
        id: Date.now() + i.toString(),
        src: URL.createObjectURL(file),
        x: 25 + 225 * Math.floor(i / 8),
        y: 25 + 50 * (i % 8),
      })) ?? [];

    setImages([...imageShapes, ...imageShapesFromFile]);
  }, [value]);

  const handleToolSelection = (tool: ToolTypes) => {
    setIsDrawing(false);
    setSelectedShape(null);
    setSelectedTool(tool);
  };

  const shortcutsHandlers = useMemo<Record<string, () => void>>(
    () => ({
      f: () => handleToolSelection('pencil'),
      r: () => handleToolSelection('rectangle'),
      o: () => handleToolSelection('ellipse'),
      l: () => handleToolSelection('line'),
      t: () => handleToolSelection('text'),
      a: () => handleToolSelection('arrow'),
      d: () => handleToolSelection('double-arrow'),
      z: () => handleToolSelection('zoom-in'),
      s: () => handleToolSelection('selection'),
      e: () => handleToolSelection('eraser'),
    }),
    [],
  );

  // Allow deleting shape with del or backspace key press
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.key === 'Delete' || event.key === 'Backspace') && selectedShape) {
        setShapes((shapes) =>
          shapes.filter(
            (shape) => !(shape.data as IRectShapeProps)?.id || (shape.data as IRectShapeProps)?.id !== selectedShape,
          ),
        );
        setSelectedShape(null);
      } else if (event.key.toLowerCase() === 'z' && (event.ctrlKey || event.metaKey) && previousShapes.length >= 2) {
        setShapes(previousShapes[previousShapes.length - 2]);
        setPreviousShapes(previousShapes.slice(0, previousShapes.length - 1));
      } else if (
        (!isDrawing || selectedTool !== 'text') &&
        !event.shiftKey &&
        !event.ctrlKey &&
        !event.metaKey &&
        !event.altKey
      ) {
        if (event.key.toLowerCase() in shortcutsHandlers) {
          shortcutsHandlers[event.key.toLowerCase()]();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedShape, previousShapes, isDrawing, selectedTool, shortcutsHandlers]);

  const checkDeselect = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedShape(null);
    }
  };
  const handleMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    // Prevents scrolling on tablets
    e.evt.preventDefault();
    const mouseEvent = e.evt as MouseEvent;
    if (selectedTool === 'selection') {
      checkDeselect(e);
      return;
    }

    // allows only left clicks
    if (mouseEvent.button !== undefined && mouseEvent.button !== 0) return;

    const pos = e.target.getStage()?.getPointerPosition();
    const id = Date.now().toString();
    if (!pos) return;
    setIsDrawing(true);

    let newShape: ShapeTypes | null = null;
    if (selectedTool === 'pencil') {
      newShape = { type: selectedTool, data: { points: [pos.x, pos.y], color: selectedColor, strokeWidth: 2 } };
    } else if (selectedTool === 'line') {
      newShape = { type: selectedTool, data: { points: [pos.x, pos.y], color: selectedColor, strokeWidth: 2, id } };
    } else if (selectedTool === 'eraser') {
      newShape = { type: selectedTool, data: { points: [pos.x, pos.y], color: 'white', strokeWidth: 8 } };
    } else if (selectedTool === 'arrow' || selectedTool === 'double-arrow') {
      newShape = {
        type: selectedTool,
        data: {
          points: [pos.x, pos.y],
          color: selectedColor,
          strokeWidth: 2,
          id,
          double: selectedTool === 'double-arrow',
        },
      };
    } else if (selectedTool === 'rectangle') {
      newShape = {
        type: selectedTool,
        data: {
          x: pos?.x ?? 0,
          y: pos?.y ?? 0,
          width: 0,
          height: 0,
          stroke: selectedColor,
          id: id,
          fillShape: shouldFill,
        },
      };
    } else if (selectedTool === 'ellipse') {
      newShape = {
        type: selectedTool,
        data: {
          x: pos?.x ?? 0,
          y: pos?.y ?? 0,
          width: 0,
          height: 0,
          stroke: selectedColor,
          id: id,
          fillShape: shouldFill,
        },
      };
    } else if (selectedTool === 'zoom-in') {
      newShape = {
        type: selectedTool,
        data: {
          x1: pos?.x ?? 0,
          y1: pos?.y ?? 0,
          width: 75,
          height: 75,
          stroke: selectedColor,
          id: id,
          fillShape: shouldFill,
        },
      };
    } else if (selectedTool === 'text') {
      newShape = {
        type: selectedTool,
        data: {
          x: pos?.x ?? 0,
          y: pos?.y ?? 0,
          text: '',
          width: 100,
          fontSize: 15,
          color: selectedColor,
          id: id,
          initialEvent: e,
        },
      };
    }

    if (newShape) setShapes([...shapes, newShape]);
  };

  const handleMouseMove = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const stage = e.target.getStage();
    const point = stage?.getPointerPosition();
    if (!point) return;

    const lastShape = shapes[shapes.length - 1];
    if (!isDrawing || !lastShape?.data) return;

    if (lastShape.type === 'pencil' || lastShape.type === 'eraser') {
      // add point
      lastShape.data.points = lastShape.data.points.concat([point.x, point.y]);
    } else if (lastShape.type === 'rectangle') {
      lastShape.data = {
        ...lastShape.data,
        width: (point.x ?? 0) - lastShape.data.x,
        height: (point.y ?? 0) - lastShape.data.y,
      };
    } else if (lastShape.type === 'ellipse') {
      const newWidth = (point.x ?? 0) - lastShape.data.x;
      const newHeight = (point.y ?? 0) - lastShape.data.y;
      lastShape.data = {
        ...lastShape.data,
        width: newWidth < 0 ? lastShape.data.x - (point.x ?? 0) : newWidth,
        height: newHeight < 0 ? lastShape.data.y - (point.y ?? 0) : newHeight,
      };
    } else if (lastShape.type === 'zoom-in') {
      const { x1, y1 } = lastShape.data;
      lastShape.data = {
        ...lastShape.data,
        x2: point.x ?? x1,
        y2: point.y ?? y1,
      };
    } else if (lastShape.type === 'line' || lastShape.type === 'arrow' || lastShape.type === 'double-arrow') {
      const [x1, y1] = lastShape.data.points;
      lastShape.data = {
        ...lastShape.data,
        points: [x1, y1, point.x, point.y],
      };
    }

    shapes.splice(shapes.length - 1, 1, lastShape);
    setShapes(shapes.concat());
  };

  const handleMouseUp = () => {
    if (isDrawing) {
      if (previousShapes.length < 30) {
        setPreviousShapes([...previousShapes, shapes]);
      } else {
        setPreviousShapes([...previousShapes, shapes].slice(1, 31));
      }
    }

    if (selectedTool !== 'text') {
      setIsDrawing(false);
    }
  };

  const handleTextShapeEdit = useCallback(
    (isEditing: boolean) => {
      if (selectedTool === 'text') setIsDrawing(isEditing);
    },
    [selectedTool, setIsDrawing],
  );

  const handleDownloadCanvas = () => {
    const dataURL = stageRef?.current?.toDataURL({ pixelRatio: 3 }) || imageUrl;

    if (dataURL) {
      const link = document.createElement('a');
      link.download = 'stage.png';
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleUploadCanvas = () => {
    const dataURL = stageRef?.current?.toDataURL({ pixelRatio: 3 });

    if (activityId && stepId && dataURL) {
      submitCanvasImage({
        variables: {
          activityId,
          file: dataURLToFile(dataURL, 'canvas.png'),
          stepId,
          submissionIndex: canvasIndex > 0 ? canvasIndex : undefined,
        },
      });
    }
  };

  const buildedShapes = useMemo(() => {
    return shapes.map((shape, i) => {
      const handleShapeChange = (
        newAttrs:
          | IRectShapeProps
          | IEraserShapeProps
          | IEllipseShapeProps
          | ILineShapeProps
          | IArrowShapeProps
          | ITextShapeProps
          | IZoomInShapeProps,
      ) => {
        if (selectedTool === 'selection' || selectedTool === 'text') {
          const shapesCopy = shapes.slice();
          shapesCopy[i].data = newAttrs;
          setShapes(shapesCopy);
        }
      };

      const handleShapeSelection = () => {
        if (selectedTool === 'selection' && shape.type !== 'pencil' && shape.type !== 'eraser') {
          setSelectedShape(shape.data.id);
        }
      };

      if (shape.type === 'arrow' || shape.type === 'double-arrow') {
        return (
          <ArrowShape
            key={i}
            shapeProps={shape.data}
            isSelected={shape.data.id === selectedShape}
            draggable={selectedTool === 'selection'}
            onSelect={handleShapeSelection}
            onChange={handleShapeChange}
          />
        );
      } else if (shape.type === 'ellipse') {
        return (
          <EllipseShape
            key={i}
            shapeProps={shape.data}
            isSelected={shape.data.id === selectedShape}
            draggable={selectedTool === 'selection'}
            onSelect={handleShapeSelection}
            onChange={handleShapeChange}
          />
        );
      } else if (shape.type === 'zoom-in') {
        return (
          <ZoomInShape
            key={i}
            shapeProps={shape.data}
            isSelected={shape.data.id === selectedShape}
            draggable={selectedTool === 'selection'}
            onSelect={handleShapeSelection}
            onChange={handleShapeChange}
          />
        );
      } else if (shape.type === 'eraser') {
        return <EraserShape key={i} shapeProps={shape.data} />;
      } else if (shape.type === 'line') {
        return (
          <LineShape
            key={i}
            shapeProps={shape.data}
            isSelected={shape.data.id === selectedShape}
            draggable={selectedTool === 'selection'}
            onSelect={handleShapeSelection}
            onChange={handleShapeChange}
          />
        );
      } else if (shape.type === 'pencil') {
        return <DrawingShape key={i} shapeProps={shape.data} />;
      } else if (shape.type === 'rectangle') {
        return (
          <RectangleShape
            key={i}
            shapeProps={shape.data}
            isSelected={shape.data.id === selectedShape}
            draggable={selectedTool === 'selection'}
            onSelect={handleShapeSelection}
            onChange={handleShapeChange}
          />
        );
      } else if (shape.type === 'text') {
        return (
          <TextShape
            key={i}
            shapeProps={shape.data}
            isSelected={shape.data.id === selectedShape}
            draggable={selectedTool === 'selection'}
            onChange={handleShapeChange}
            onSelect={handleShapeSelection}
            onEdit={handleTextShapeEdit}
          />
        );
      }

      return <span role="none"  />;
    });
  }, [selectedShape, selectedTool, shapes, handleTextShapeEdit]);

  const dashedBackground = useMemo(
    () => (
      <>
        {canvasWidth > 0 ? (
          Array(Math.round(canvasWidth / 20))
            .fill('')
            .map((_, i) => (
              <Line
                key={i}
                points={[(i + 1) * 20, 0, 20 * (i + 1), 600]}
                lineCap="round"
                strokeWidth={0.1}
                stroke="#000"
              />
            ))
        ) : (
          <></>
        )}
        {canvasWidth > 0 ? (
          Array(Math.round(600 / 20))
            .fill('')
            .map((_, i) => (
              <Line
                key={i}
                points={[0, (i + 1) * 20, canvasWidth, 20 * (i + 1)]}
                lineCap="round"
                strokeWidth={0.1}
                stroke="#000"
              />
            ))
        ) : (
          <></>
        )}
      </>
    ),
    [canvasWidth],
  );

  const bottomLeftGraphLines = useMemo(
    () => (
      <>
        {canvasWidth > 0 ? (
          <ArrowShape
            shapeProps={{
              color: 'black',
              id: 'vertical-axis',
              strokeWidth: 2.5,
              points: [20, 580, 20, 5],
            }}
          />
        ) : (
          <></>
        )}
        {canvasWidth > 0 ? (
          <ArrowShape
            shapeProps={{
              color: 'black',
              id: 'horizontal-axis',
              strokeWidth: 2.5,
              points: [20, 580, canvasWidth - 5, 580],
            }}
          />
        ) : (
          <></>
        )}
      </>
    ),
    [canvasWidth],
  );

  const centerGraphLines = useMemo(
    () => (
      <>
        {canvasWidth > 0 ? (
          <ArrowShape
            shapeProps={{
              color: 'black',
              id: 'horizontal-axis-2',
              strokeWidth: 2.5,
              points: [5, 300, canvasWidth - 5, 300],
              double: true,
            }}
          />
        ) : (
          <></>
        )}
        {canvasWidth > 0 ? (
          <ArrowShape
            shapeProps={{
              color: 'black',
              id: 'vertical-axis-2',
              strokeWidth: 2.5,
              points: [canvasWidth / 2 - ((canvasWidth / 2) % 20), 595, canvasWidth / 2 - ((canvasWidth / 2) % 20), 5],
              double: true,
            }}
          />
        ) : (
          <></>
        )}
      </>
    ),
    [canvasWidth],
  );

  const hasDrawing = !!shapes?.length;

  return (
    <div style={{ width: '100%' }} ref={containerRef}>
      {studentName && (
        <>
          <Spacer size={24} />
          <h2>{studentName}</h2>
        </>
      )}
      {loadingSubmit && (
        <S.LoadingPanel>
          <Spin />
        </S.LoadingPanel>
      )}
      {imageUrl ? (
        <S.ScrollableContent>
          <img src={imageUrl} alt="Concept generated by student" style={{ height: CANVAS_HEIGHT }} />
        </S.ScrollableContent>
      ) : (
        <>
          <S.ToolsRow>
            <Tooltip title="Free Draw">
              <S.ToolContainer onClick={() => handleToolSelection('pencil')} selected={selectedTool === 'pencil'}>
                <FiPenTool />
              </S.ToolContainer>
            </Tooltip>
            <Tooltip title="Rectangle">
              <S.ToolContainer onClick={() => handleToolSelection('rectangle')} selected={selectedTool === 'rectangle'}>
                <FiSquare />
              </S.ToolContainer>
            </Tooltip>
            <Tooltip title="Ellipse">
              <S.ToolContainer onClick={() => handleToolSelection('ellipse')} selected={selectedTool === 'ellipse'}>
                <FiCircle />
              </S.ToolContainer>
            </Tooltip>
            <Tooltip title="Line">
              <S.ToolContainer onClick={() => handleToolSelection('line')} selected={selectedTool === 'line'}>
                <AiOutlineLine />
              </S.ToolContainer>
            </Tooltip>
            <Tooltip title="Text">
              <S.ToolContainer onClick={() => handleToolSelection('text')} selected={selectedTool === 'text'}>
                <FiType />
              </S.ToolContainer>
            </Tooltip>
            <Tooltip title="Arrow">
              <S.ToolContainer onClick={() => handleToolSelection('arrow')} selected={selectedTool === 'arrow'}>
                <CgArrowLongRight />
              </S.ToolContainer>
            </Tooltip>
            <Tooltip title="Double-Sided Arrow">
              <S.ToolContainer
                onClick={() => handleToolSelection('double-arrow')}
                selected={selectedTool === 'double-arrow'}
              >
                <CgArrowsHAlt />
              </S.ToolContainer>
            </Tooltip>
            <Tooltip title="Zoom-In Drawing">
              <S.ToolContainer onClick={() => handleToolSelection('zoom-in')} selected={selectedTool === 'zoom-in'}>
                <CgZoomIn />
              </S.ToolContainer>
            </Tooltip>
            <Tooltip title="Selection">
              <S.ToolContainer onClick={() => handleToolSelection('selection')} selected={selectedTool === 'selection'}>
                <HiCursorClick />
              </S.ToolContainer>
            </Tooltip>
            <Tooltip title="Eraser">
              <S.ToolContainer onClick={() => handleToolSelection('eraser')} selected={selectedTool === 'eraser'}>
                <CgErase />
              </S.ToolContainer>
            </Tooltip>
            <Popconfirm
              title="Delete current drawing and start a new one?"
              onConfirm={() => {
                setShapes([]);
                setPreviousShapes([[]]);
              }}
            >
              <Tooltip title="New Drawing">
                <S.ToolContainer>
                  <FiFile />
                </S.ToolContainer>
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title="Upload current drawing? You won't be able to edit it after that"
              onConfirm={handleUploadCanvas}
            >
              <Tooltip title="Upload drawing">
                <S.ToolContainer>
                  <FiUploadCloud onClick={() => setSelectedShape(null)} />
                </S.ToolContainer>
              </Tooltip>
            </Popconfirm>
            <Tooltip title="Download Drawing">
              <S.ToolContainer onClick={handleDownloadCanvas}>
                <FiDownload />
              </S.ToolContainer>
            </Tooltip>
          </S.ToolsRow>
          <S.SecondaryToolContainer>
            <S.ColorPickerContainer>
              <S.ToolsRow>
                <Popover
                  title={null}
                  trigger="click"
                  placement="bottomLeft"
                  overlayClassName="ant-popover-no-padding"
                  content={
                    <SketchPicker
                      color={selectedColor}
                      onChangeComplete={(color) => setSelectedColor(color.hex)}
                      onChange={(color) => setSelectedColor(color.hex)}
                      disableAlpha
                    />
                  }
                >
                  <S.SelectedColorContainer color={selectedColor} />
                </Popover>
                {(selectedTool === 'ellipse' || selectedTool === 'rectangle') && (
                  <Tooltip title="Fill Form">
                    <S.ToolContainer onClick={() => setShouldFill(!shouldFill)} removeMargin>
                      {shouldFill ? <BsSlashSquareFill /> : <BsSlashSquare />}
                    </S.ToolContainer>
                  </Tooltip>
                )}
              </S.ToolsRow>
            </S.ColorPickerContainer>
            <Popover
              title={null}
              trigger="hover"
              placement="bottomRight"
              overlayClassName="ant-popover-no-padding"
              content={<KeyboardShortcutsLegend />}
            >
              <FiHelpCircle />
            </Popover>
          </S.SecondaryToolContainer>
          <Spacer axis="vertical" size={8} />
          <S.ScrollableContent>
            <StageComponent
              ref={stageRef}
              height={CANVAS_HEIGHT}
              width={canvasWidth}
              style={{ backgroundColor: 'white' }}
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
              onTouchMove={handleMouseMove}
              onMousemove={handleMouseMove}
              onMouseup={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              <Layer>
                {buildedShapes}
                {images.map((image) => (
                  <ImageShape
                    key={image.id}
                    onChange={(newAttr) =>
                      setImages(images.map((img) => (img.id === newAttr.id ? { ...img, ...newAttr } : img)))
                    }
                    shapeProps={image}
                    onSelect={() => selectedTool === 'selection' && setSelectedShape(image.id)}
                    isSelected={selectedShape === image.id}
                    draggable={selectedTool === 'selection'}
                  />
                ))}
                {value?.templateId === 1 ? dashedBackground : <></>}
                {value?.templateId === 2 ? (
                  <>
                    {dashedBackground}
                    {bottomLeftGraphLines}
                  </>
                ) : (
                  <></>
                )}
                {value?.templateId === 3 ? (
                  <>
                    {dashedBackground}
                    {centerGraphLines}
                  </>
                ) : (
                  <></>
                )}
                {titles?.map((title, i) => (
                  <Text
                    key={i}
                    text={title}
                    fontSize={28}
                    width={(canvasWidth ?? 0) / count}
                    x={(i * (canvasWidth ?? 0)) / count}
                    align="center"
                    y={16}
                    stroke="white"
                    fill="black"
                    strokeWidth={0.5}
                  />
                ))}

                {count ? (
                  Array(count - 1)
                    .fill('')
                    .map((_, i) =>
                      canvasWidth ? (
                        <Line
                          key={i}
                          points={[(canvasWidth / count) * (i + 1), 25, (canvasWidth / count) * (i + 1), 575]}
                          lineCap="round"
                          strokeWidth={2.5}
                          stroke="#000"
                        />
                      ) : (
                        <></>
                      ),
                    )
                ) : (
                  <></>
                )}
              </Layer>
            </StageComponent>
          </S.ScrollableContent>
        </>
      )}
      <Spacer />
      <Row justify="space-between">
        <Button
          text="Download Drawing"
          theme={themeConfig.primaryOutlined}
          minWidth={180}
          onClick={handleDownloadCanvas}
        />
        {!imageUrl && (
          <Popconfirm
            title="Upload current drawing? You won't be able to edit it after that"
            onConfirm={handleUploadCanvas}
          >
            <Button
              text="Upload Drawing"
              minWidth={180}
              disabled={(!isStudent && !isGoogleStudent && !isCanvasStudent) || !hasDrawing}
              loading={loadingSubmit}
              onClick={() => setSelectedShape(null)}
            />
          </Popconfirm>
        )}
      </Row>
    </div>
  );
};
