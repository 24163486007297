// AddAssignmentButton.tsx
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './styles';

const AddAssignmentButton = () => {
  const history = useHistory();
  const location = useLocation();

  const navigateToNewAssignmentPage = useCallback(() => {
    history.push(location.pathname + '/create-assignment');
  }, [history, location.pathname]);

  return (
    <S.ButtonContainer>
      <S.Button onClick={navigateToNewAssignmentPage} data-cy="components-add-assignment-button">
       + Create Assignment
      </S.Button>
    </S.ButtonContainer>
  );
};

export default AddAssignmentButton;
