import React, { ChangeEvent, useCallback } from 'react';
import Button from '../Button';
import { Col, Row } from 'antd';
import { FiSearch } from 'react-icons/fi';
import * as S from './styles';
import Input from '../Input';
import { themeConfig } from '../../utils/theme';

interface Props {
  setSelectedKeys: (selectedKeys: React.ReactText[]) => void;
  updateSearch?: (search: string) => void;
  selectedKeys: React.Key[];
  confirm: () => void;
  clearFilters?: () => void;
  onClearFilters?: () => void;
}

const TableSearchBox = React.forwardRef((props: Props, ref) => {
  const { setSelectedKeys, selectedKeys, confirm, clearFilters, onClearFilters, updateSearch } = props;
  const handleReset = () => {
    if (clearFilters) clearFilters();
    if (onClearFilters) onClearFilters();
  };

  const handleSearch = () => {
    updateSearch?.(selectedKeys[0]?.toString());
    confirm();
  };

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedKeys(e.target.value ? [e.target.value] : []);
    },
    [setSelectedKeys],
  );

  return (
    <S.Wrapper>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Input value={selectedKeys && selectedKeys[0]} onChange={onChange} onPressEnter={handleSearch} ref={ref} />
        </Col>
        <Col span={12}>
          <Button type="primary" onClick={handleSearch} icon={<FiSearch />} size="small" block text="Search" />
        </Col>
        <Col span={12}>
          <Button onClick={handleReset} text="Cancel" size="small" theme={themeConfig.noColor} block />
        </Col>
      </Row>
    </S.Wrapper>
  );
});

export default TableSearchBox;
