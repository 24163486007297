import React, { ReactElement, useCallback } from 'react';
import { Row, Col, Progress } from 'antd';
import * as S from './styles';
import { BsClock } from 'react-icons/bs';
import RoundedTag from '../../../../shared/RoundedTag';
import { getProgressColor, themeConfig } from '../../../../utils/theme';
import { useHistory } from 'react-router-dom';

interface Props {
  className: string;
  progress: number;
  classColor: string;
  timeLeft: string;
  assessmentName: string;
  assessmentId: string;
  classId?: string;
  timeWarning: boolean;
  icon: ReactElement;
  type?: 'Assessment';
  version?: number | string;
}

const Item: React.FC<Props> = (props) => {
  const {
    progress,
    timeLeft,
    className,
    assessmentName,
    assessmentId,
    timeWarning,
    classColor,
    icon,
    type,
    classId,
    version,
  } = props;

  const history = useHistory();

  const goToDashboard = useCallback(() => {
    history.push(`/teacher-dashboard/assessment-summary/${assessmentId}${version ? `/${version}` : ''}`);
  }, [assessmentId, history, version]);

  const goToClassDashboard = useCallback(() => {
    if (classId) {
      history.push(`/teacher-dashboard/class/${classId}`);
    }
  }, [classId, history]);

  return (
    <S.Card>
      <Row align="middle">
        <Col span={5} style={{ textAlign: 'left' }}>
          {icon}
        </Col>
        <Col span={14}>
          <S.Info $bold>Average Student Progress</S.Info>
        </Col>
        <Col span={5} style={{ textAlign: 'left' }}>
          <Progress
            strokeLinecap="round"
            strokeWidth={10}
            strokeColor={getProgressColor(progress)}
            format={(percent) => {
              return <S.Info $bold>{percent}%</S.Info>;
            }}
            type="circle"
            width={65}
            percent={Math.round(progress)}
          />
        </Col>
        <Col span={24}>
          <S.Title $align="left">{assessmentName}</S.Title>
          <S.Subtitle>Version {version}</S.Subtitle>
        </Col>
        <Row></Row>
        <Col xxl={24} lg={24} sm={24} xs={24} style={{ margin: '16px 0 8px 0', textAlign: 'left' }}>
          <RoundedTag color={classColor} text={className} onClick={goToClassDashboard} />
        </Col>
      </Row>
      <Row style={{ marginTop: '3em' }} gutter={16}>
        <Col span={16}>
          <S.DashboardButton
            text={type + ' Dashboard'}
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
        <Col span={8} className="middle">
          <BsClock style={{ minWidth: 18, minHeight: 18, marginRight: 8 }} />
          <S.TimeLeft $fontSize="0.8em" $warning={timeWarning}>
            {timeLeft}
          </S.TimeLeft>
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
