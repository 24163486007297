import React, { useState } from 'react';
import { Typography, Row, Col, Form, Checkbox, message, Spin } from 'antd';
import * as S from './styles';
import Button from '../../../shared/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';

const BookDetails = () => {
  const history = useHistory();
  const param = useParams<{ bookCode: string }>();
  const { loading, error, data } = useQuery(gqlSchema.BookSchema.queries.FetchBookByBookCode, {
    variables: { id: param.bookCode },
    onError: (error) => {
      message.error(`Error fetching book details: ${error.message || 'Unexpected Error'}`);
    },
  });

  const [isAgree, setIsAgree] = useState(false);

  const [addBookUser] = useMutation(gqlSchema.BookSchema.mutations.AddBookUser, {
    onCompleted: () => {
      message.success("Book added successfully!");
      history.push('/booklib');
    },
    onError: (error) => {
      message.error(<Typography.Text>{error.message}</Typography.Text>);
    },
  });

  const onProceed = () => {
    if (!data?.fetchBookByBookCode?.id) return;
    addBookUser({ variables: { bookCode: param.bookCode } });
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <div>Error loading book details.</div>;
  }

  const book = data.fetchBookByBookCode;

  return (
    <S.Container>
      <S.RowSP justify="center" align="middle">
        <S.ColSP>
          <S.CloseIcon onClick={() => history.goBack()}>×</S.CloseIcon>
          <Typography.Title level={2}>Book Details</Typography.Title>

          <S.DetailsRow gutter={16}>
            <Col xs={24} md={8}>
              <S.ImageWrapper>
                <img
                  src={book.imageUrl[0] || '/placeholder-image.png'}
                  alt={book.title || 'Book Image'}
                />
              </S.ImageWrapper>
            </Col>
            <Col xs={24} md={16}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <S.DetailsTitle>Book Name</S.DetailsTitle>
                  <S.DetailsText>{book.title || 'N/A'}</S.DetailsText>
                </Col>
                <Col span={12}>
                  <S.DetailsTitle>Grade</S.DetailsTitle>
                  <S.DetailsText>{book.standard || 'N/A'}</S.DetailsText>
                </Col>
                <Col span={12}>
                  <S.DetailsTitle>Investigation</S.DetailsTitle>
                  <S.DetailsText>{book.investigation || 'N/A'}</S.DetailsText>
                </Col>
                <Col span={12}>
                  <S.DetailsTitle>Publisher</S.DetailsTitle>
                  <S.DetailsText>
                    {book.publisher || 'N/A'} | Published: {book.publishedDate || 'N/A'}
                  </S.DetailsText>
                </Col>
                <Col span={12}>
                  <S.DetailsTitle>Subject</S.DetailsTitle>
                  <S.DetailsText>{book.subject || 'N/A'}</S.DetailsText>
                </Col>
              </Row>
            </Col>
          </S.DetailsRow>

          <Form>
            <Form.Item>
              <Checkbox checked={isAgree} onChange={(e) => setIsAgree(e.target.checked)}>
                Once the code is entered, the order cannot be canceled.
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                text="Proceed"
                onClick={onProceed}
                disabled={!isAgree}
                block
                style={{
                  width: '50%',
                  margin: '0.5em auto 0',
                  textAlign: 'center',
                }}
              />
            </Form.Item>
          </Form>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );
};

export default BookDetails;
