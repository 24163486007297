import styled from 'styled-components';
import { Button } from 'antd';
import { Row } from 'antd';

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.4em;
  margin: 0.7em 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const ViewMore = styled(Button)`
  font-weight: 700;
  font-size: 0.89em;
  color: ${(props) => props.theme.primaryOutlined.color} !important;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const RowSP = styled(Row)`
  .extra-items-enter {
    opacity: 0;
  }
  .extra-items-enter-active {
    opacity: 1;
    transition: opacity 400ms, transform 400ms;
  }
  .extra-items-exit {
    opacity: 1;
  }
  .extra-items-exit-active {
    opacity: 0;
    transition: opacity 400ms, transform 400ms;
  }
  .view-more {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
`;
