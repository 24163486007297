import { ApolloError, useQuery } from '@apollo/client';
import { gqlSchema } from '../gql/schema';
import { GQL_WhitelistedDomain } from '../types/whitelistedDomain';

const useFetchWhitelistedDomains = () => {
  const { data, loading } = useQuery<{
    getWhitelistedDomains: GQL_WhitelistedDomain[];
  }>(gqlSchema.WhitelistedDomainSchema.queries.getWhitelistedDomains, {
    onError: (error: ApolloError) => {
      console.error(error);
      throw error;
    },
  });

  return {
    whitelistedDomains: data?.getWhitelistedDomains || [],
    whitelistedDomainsLoading: loading,
  };
};

export default useFetchWhitelistedDomains;
