import React, { useState } from 'react';
// import { BsPlus } from 'react-icons/bs';
// import { useAuth } from '../../../../hooks/useAuth';
import GJoinClass from '../../../../shared/GJoinClass'; // Assuming GJoinClass is in the same directory
import * as S from './styles';

const AddClassButton = () => {
  const [visible, setVisible] = useState(false);
  // const { isFacilitator } = useAuth();
  return (  
    <>
      <S.Card onClick={() => setVisible(true)} data-cy="components-classes-row-create-class-button">
        <S.Icon>
        </S.Icon>
        <S.Title> {'+ Join Class'}</S.Title>
      </S.Card>
      <GJoinClass visible={visible} setVisible={setVisible} />
    </>
  );
};

export default AddClassButton;
