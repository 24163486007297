import React, { useState, useEffect, useCallback } from 'react';
import { message, Row, Col, Divider } from 'antd';
import { useQuery, useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import GClassRow from './GClassRow';
import GActiveAssignmentsRow from './GActiveAssignmentsRow';
import GFinishedAssignmentsRow from './GFinishedAssignmentsRow';
import * as S from './styles';

const GoogleClassroomTeachersDashboardPage: React.FC = () => {
  const [courses, setCourses] = useState<any[]>([]);
  const [finishedAssignments, setFinishedAssignments] = useState<any[]>([]);
  const [courseWorkList, setCourseWorkList] = useState<any[]>([]);
  const [currentCourses, setCurrentCourses] = useState<any[]>([]);

  const { loading: coursesLoading } = useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCoursesList,
    {
      onError: (error) => {
        message.error(
          error.message ||
          'There was an error loading your classes, check your connection and try again later.'
        );
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        const fetchedCourses = data?.getCoursesList || [];
        setCourses(fetchedCourses);
      },
    }
  );

  const [getCourseWorkListByDueDate, { loading: cwLoading }] = useLazyQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkListByDueDate,
    {
      onError: (error) => {
        console.error('Error fetching course work list:', error);
      },
      onCompleted: (data) => {
        const activeCourseWork = data?.getCourseWorkListByDueDate?.activeCourseWork || [];
        const expiredCourseWork = data?.getCourseWorkListByDueDate?.expiredCourseWork || [];
        setCourseWorkList(activeCourseWork);
        setFinishedAssignments(expiredCourseWork);
      },
    }
  );

  const handlePageChange = useCallback((currentCourses: any[]) => {
    setCurrentCourses(currentCourses);
  }, []);

  useEffect(() => {
    if (!coursesLoading && currentCourses.length > 0) {
      const courseIds = currentCourses.map((course: any) => course.id);
      getCourseWorkListByDueDate({
        variables: {
          courseId: courseIds,
          data: "PUBLISHED",
        },
      });
    }
  }, [coursesLoading, currentCourses, getCourseWorkListByDueDate]);

  return (
    <S.Container padding={2}>
      <Row gutter={[24, 24]}>
        <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24}>
          <GClassRow
            courses={courses}
            loading={coursesLoading}
            onPageChange={handlePageChange}
          />
          <Divider />
          <GActiveAssignmentsRow loading={cwLoading} assignmentData={courseWorkList} courses={currentCourses} />
          <Divider />
          <GFinishedAssignmentsRow
            loading={coursesLoading || cwLoading}
            finishedAssignments={finishedAssignments}
            courses={currentCourses}
          />
        </Col>
      </Row>
    </S.Container>
  );
};

export default GoogleClassroomTeachersDashboardPage;
