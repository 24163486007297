import React from 'react';
import {
  GQL_InvestigationActivity,
  GQL_InvestigationBlock,
  ITextContentValue,
  ITextSubmitContentValue,
} from '../../../../types/investigation';

interface Props {
  part: GQL_InvestigationActivity;
  item: GQL_InvestigationBlock;
}

export const MultipleChoiceSummary = ({ part, item }: Props) => {
  const percentageFormatter = (value: number) => (value * 100).toFixed(0);

  /* Filter how many selectedAnswer we have for each answer index */
  const countSelectedAnswers = (MCPQuestions: GQL_InvestigationBlock[], index: number) =>
    MCPQuestions?.filter((v) => (v?.values[index] as ITextContentValue)?.selectedAnswer).length;

  const MCPQuestions = part.content[0].blocks.filter(
    (item) => item.type === 'MultipleChoicePictureQuestion' || item.type === 'MultipleChoiceQuestion',
  );
  const textSubmissions = part.content[0].blocks.filter((item) => item.type === 'TextSubmit');

  return (
    <>
      <p className="question-label">Question</p>
      <p
        className="question-title"
        dangerouslySetInnerHTML={{ __html: (item.values?.[0] as ITextContentValue).text }}
      />
      <div className="answers-row">
        {item.values.slice(1).map((item, index: number) => {
          const option = item as ITextContentValue;
          return (
            <div
              className="answers-block"
              style={{ borderColor: option.targetAnswer ? '#20BC89' : '#EA3535' }}
              key={index}
            >
              <div>
                <p
                  style={{ marginBottom: 12, color: option.targetAnswer ? '#20BC89' : '#EA3535' }}
                  dangerouslySetInnerHTML={{ __html: option.optionLabel || option.text }}
                />
                {option?.url && <img className="answer-image" src={option.url} alt="Assessment" />}
              </div>
              <div className="answers-percentage" style={{ color: option.targetAnswer ? '#20BC89' : '#EA3535' }}>
                Selected by {percentageFormatter(countSelectedAnswers(MCPQuestions, index + 1) / MCPQuestions.length)}
                {'%'}
              </div>

              {textSubmissions.map((submission) => {
                const selectedAns = MCPQuestions?.filter((item) => item.userId === submission.userId)[0];
                const selectedAnswerIndex = selectedAns?.values?.findIndex(
                  (item) => (item as ITextContentValue)?.selectedAnswer,
                );
                /* 
                    Show the Reason why block only if the Selected Index of 
                    the chosen answers is equal to the MC Picture answer index
                */
                if (selectedAnswerIndex === index + 1) {
                  return (
                    <div className="answers-reason">
                      <p className="student">{submission.userName}</p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: (submission.values[0] as ITextSubmitContentValue)?.text || '',
                        }}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};
