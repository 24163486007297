import React, { useState } from 'react';
import { Col, Row } from 'antd';
import Button from '../Button';
import ModalUpdateAvatar from './ModalUpdateAvatar';
import { useAuth } from '../../hooks/useAuth';
import Avatar from '../Avatar';

const UpdateAvatar = () => {
  const [updatePictureVisible, setUpdatePictureVisible] = useState(false);
  const { user } = useAuth();

  return (
    <>
      <Row justify="center">
        <Col xxl={20} xl={20} lg={20} md={22} sm={24} xs={24}>
          <Row justify="center">
            <Col xxl={13} xl={13} lg={15} md={10} sm={4} xs={5}>
              <Avatar
                margin="25px 0 0 0"
                size={{ xs: 90, sm: 90, md: 90, lg: 90, xl: 90, xxl: 100 }}
                src={user.avatar}
              />
            </Col>
            <Col xxl={20} xl={22} lg={24} md={18} sm={24} xs={24}>
              <Row justify="center" style={{ marginTop: 20 }}>
                <Col xxl={24} xl={24} lg={24} md={24} sm={8} xs={10}>
                  <Button
                    data-cy="shared-updateavatar-button"
                    text="Update Picture"
                    block
                    onClick={() => setUpdatePictureVisible(true)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalUpdateAvatar visible={updatePictureVisible} setVisible={setUpdatePictureVisible} />
    </>
  );
};

export default UpdateAvatar;
