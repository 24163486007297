import { Row } from 'antd';
import styled from 'styled-components';

export const ModalTitle = styled.h1`
  text-align: left;
  margin-bottom: 15px;
  font-weight: 600;
`;

export const ModalText = styled.p`
  text-align: left;
  font-weight: 500;
  font-size: 14px;
`;

export const ModalLoading = styled.div`
  width: 100%;
  display: inline-grid;
  height: 50px;
`;

export const InvoiceRow = styled(Row)`
  width: 100%;
  margin-bottom: 15px;
  font-size: 13px;
  color: black;
  font-weight: 500;

  .price-element {
    color: #9b9b9b;
    text-align: right;
  }
`;
