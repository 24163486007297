import { useQuery } from '@apollo/client';
import { Col, message, Row, Table } from 'antd';
import { ColumnsType, FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useMemo, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import Button from '../../shared/Button';
import PageWithTitle from '../../shared/PageWithTitle';
import TableSearchBox from '../../shared/TableSearchBox';
import {
  GQL_InvestigationPerStudentTeacherSummary,
  GQL_InvestigationPerStudentTeacherSummary_Investigations,
  GQL_InvestigationTeacherSummary_PerStudent_Steps,
} from '../../types/students';
import { formatDateTime } from '../../utils/date';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';

type Props = RouteComponentProps<{
  studentId: string;
  classId: string;
}>;

const StudentInvestigationDetails: React.FC<Props> = (props) => {
  const { studentId, classId } = props.match.params;
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [stages, setStages] = useState<GQL_InvestigationTeacherSummary_PerStudent_Steps[]>();
  const { data: studentData, loading } = useQuery<
    { getStudentProgressSummary: GQL_InvestigationPerStudentTeacherSummary },
    { studentId: string; classId: string }
  >(gqlSchema.StudentSchema.queries.INVESTIGATION.getStudentProgressSummary, {
    variables: {
      studentId,
      classId,
    },
    onCompleted: (data) => {
      if (data.getStudentProgressSummary.investigations.length > 0) {
        setStages(
          data.getStudentProgressSummary.investigations[0].steps.map(
            (step: GQL_InvestigationTeacherSummary_PerStudent_Steps) => {
              return step;
            },
          ),
        );
      }
    },
    onError: () => {
      message.error(
        'There was an error loading the student investigation summary, please check your connection and try again later',
      );
    },
  });

  const statusToText = (
    status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED',
    dueDate: number,
  ) => {
    if (status === 'COMPLETED') return 'Completed';
    else if (status === 'IN_PROGRESS') return 'In Progress';
    else if (status === 'NOT_STARTED') return formatDateTime(dueDate);
    else if (status === 'OVERDUE') return formatDateTime(dueDate);
    else if (status === 'READY_FOR_GRADING') return 'Ready for Grading';
  };

  const columns: ColumnsType<GQL_InvestigationPerStudentTeacherSummary_Investigations> = useMemo(() => {
    return [
      {
        title: 'Student Investigations',
        dataIndex: 'title',
        width: 275,
        fixed: 'left',
        sorter: (
          a: GQL_InvestigationPerStudentTeacherSummary_Investigations,
          b: GQL_InvestigationPerStudentTeacherSummary_Investigations,
        ) => `${a.discipline?.name}: ${a.title}`.localeCompare(`${b.discipline?.name}: ${b.title}`),
        render: (text: string, record: GQL_InvestigationPerStudentTeacherSummary_Investigations, index: number) => {
          return (
            <S.TableTitleContainer>
              <S.StudentInvestigationStatus status={record.status} />
              <p>
                {record.discipline?.name}: {record.title}
              </p>
            </S.TableTitleContainer>
          );
        },
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={ref} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (
          value: string | number | boolean,
          record: GQL_InvestigationPerStudentTeacherSummary_Investigations,
        ) => {
          if (!value) return true;
          return (
            record.discipline?.name.toLowerCase().includes(value.toString().toLowerCase()) ||
            record.title.toLowerCase().includes(value.toString().toLowerCase())
          );
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (ref && ref.current) {
                ref.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Investigation Status',
        width: 175,
        render: (text: string, record: GQL_InvestigationPerStudentTeacherSummary_Investigations) => (
          <S.StatusChip status={record.status}>{statusToText(record.status, record?.dueDate || 0)}</S.StatusChip>
        ),
      },
      ...((stages?.length &&
        stages
          .slice()
          .sort((a, b) => a.order - b.order)
          .map((step, i) => ({
            title: step.name,
            key: i,
            width: 150,
            render: (text: string, record: GQL_InvestigationPerStudentTeacherSummary_Investigations) => {
              const recordStep = record.steps.find((s) => s.name === step.name);
              return (
                <S.StatusChip status={recordStep?.status || 'COMPLETED'}>
                  {statusToText(recordStep?.status || 'COMPLETED', recordStep?.dueDate || record.dueDate || 0)}
                </S.StatusChip>
              );
            },
          }))) ||
        []),
    ];
  }, [searchVisible, stages]);

  return (
    <PageWithTitle
      title={
        !studentData?.getStudentProgressSummary ? (
          <S.TitleContainer>Loading Info...</S.TitleContainer>
        ) : (
          <S.TitleContainer>
            {`${studentData?.getStudentProgressSummary.firstName} ${studentData?.getStudentProgressSummary.lastName}`}
          </S.TitleContainer>
        )
      }
      extra={
        <Button
          text="Student Settings"
          style={{ float: 'right' }}
          theme={themeConfig.secondaryColor}
          onClick={() =>
            history.push(`teacher-dashboard/class/${classId}/student/${studentData?.getStudentProgressSummary.userId}`)
          }
        />
      }
    >
      <Row>
        <Col span={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              bordered
              dataSource={studentData?.getStudentProgressSummary?.investigations}
              scroll={{ x: 'max-content' }}
              rowKey={(record) => record.id}
              sticky
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </PageWithTitle>
  );
};

export default withRouter(StudentInvestigationDetails);
