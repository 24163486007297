import React from 'react';
import { useRemirror } from 'remirror/react';
import MenuBar from './MenuBar';
import * as S from './styles';

interface Props {
  isEditorEmpty: boolean;
  title?: string;
  showAlign?: boolean;
  showLink?: boolean;
  'data-cy'?: string;
}
const EditableEditor: React.FC<Props> = (props) => {
  const { getRootProps } = useRemirror();

  return (
    <>
      <MenuBar title={props.title} showAlign={props.showAlign} showLink={props.showLink} />
      <S.EditableEditor $height={15} data-cy={props['data-cy']}>
        <div {...getRootProps()} />
      </S.EditableEditor>
    </>
  );
};

export default EditableEditor;
