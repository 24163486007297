import { Col, message, Pagination, Result, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

import PageWithTitle from '../../shared/PageWithTitle';
import AssessmentListHeader from './AssessmentListHeader';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationCatalogEntryPaginated,
  INVESTIGATION_TABS,
  InvestigationCatalogPaginatedParams,
} from '../../types/investigation';
import Spacer from '../../shared/Spacer';
import { useAuth } from '../../hooks/useAuth';
import Button from '../../shared/Button';
import { useInvestigationFilters } from '../../hooks/useInvestigationFilters';
import { GQL_InvestigationStandard } from '../../types/investigationStandard';
import CustomInvestigationLoading from '../../shared/CustomInvestigationLoading';
import AssessmentCard from '../../shared/AssessmentCard';
import { INVESTIGATION_STANDARDS } from '../../types/standards';

export type FilterFields =
  | 'focus'
  | 'subject'
  | 'grade'
  | 'discipline'
  | 'coreIdea'
  | 'practice'
  | 'standard'
  | 'firstPublishedAt'
  | 'crossCuttingConcept';

export interface IInvestigationListFilters {
  field: FilterFields;
  value: any | undefined;
}

const PAGE_SIZE = 10;

const AdiAssessmentListPage = () => {
  const history = useHistory();
  const [filters, setFilters] = useInvestigationFilters<FilterFields>(history);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>();

  const { user, isOrganizationAdiAdmin, isAdiSuperAdmin, isAdiAdmin, isWriter } = useAuth();

  const createPayload = () => {
    const payload = {
      isAssessment: true,
      page: currentPage,
      pageSize: PAGE_SIZE,
      investigationType: INVESTIGATION_TABS.STANDARD.toUpperCase(),
    } as InvestigationCatalogPaginatedParams | any;

    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index] as any;

      if (filter.field === 'tab') {
        continue;
      }

      const key = filter.field as any;
      payload[key] = filter.value;
    }

    if (searchText) {
      payload['searchText'] = searchText;
    }

    return payload;
  };

  const { data: publishedInvestigationData, loading: loadingPublished, refetch: refetchAssessments } = useQuery<
    {
      getInvestigationCatalogPaginated: GQL_InvestigationCatalogEntryPaginated;
    },
    InvestigationCatalogPaginatedParams
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalogPaginated, {
    variables: createPayload(),
    onError: (err) => {
      message.error('There was an error loading published investigation data: ' + err.message || 'Unexpected Error');
    },
    fetchPolicy: 'network-only',
  });

  const { data: stateStandardsData } = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: 'TX',
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: ngssStandardsData} = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: INVESTIGATION_STANDARDS.NGSS,
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const loading = useMemo(() => loadingPublished, [loadingPublished]);

  const isOrganizationLibrary = useCallback(
    (assessment: GQL_InvestigationCatalogEntry) =>
      (isOrganizationAdiAdmin || isWriter) && assessment.organization?.id !== user.subscription?.organizationId,
    [isOrganizationAdiAdmin, isWriter, user],
  );

  const investigationCardActionText = useCallback(
    (assessment: GQL_InvestigationCatalogEntry) => {
      if (isOrganizationLibrary(assessment)) return 'Preview the Assessment';
      return 'Edit the Assessment';
    },
    [isOrganizationLibrary],
  );

  const getSupportedStateStandard = useCallback(
    (assessment: GQL_InvestigationCatalogEntry) => {
      const standards = user?.organizationAllowedStates?.length
        ? user.organizationAllowedStates
        : user?.userAllowedStates;
      const normalizedStandards = standards?.map((s) => s.postalCode) as string[];

      const stepsStandards = [];

      // get standards from activities
      for (const step of assessment?.steps || []) {
        const activities = step?.activities || [];
        for (const activity of activities) {
          stepsStandards.push(...(activity.texasStandards || []));
          stepsStandards.push(...(activity.nextGenerationStandards || []));
        }
      }

      const baseStandards = [
        ...(assessment.nextGenerationStandards || []),
        ...(assessment.standards || []),
        ...(stepsStandards || []),
      ];

      const standardsInv =
        isAdiAdmin || isAdiSuperAdmin
          ? baseStandards
          : baseStandards?.filter((invStandard) => normalizedStandards?.includes(invStandard.state));

      const uniqueStandards = [...new Map(standardsInv.map((item) => [item['id'], item])).values()];

      const normalizedStandardsTooltip = uniqueStandards?.reduce(
        (acc: { state: string; statements: string[] }[], d: GQL_InvestigationStandard) => {
          const found = acc.find((a) => a.state === d.state);
          if (!found) {
            acc.push({ state: d.state, statements: [`${d.code}: ${d.fullStatement}`] });
          } else {
            found.statements.push(`${d.code}: ${d.fullStatement}`);
          }
          return acc;
        },
        [],
      );
      normalizedStandardsTooltip.forEach((state) => {
        state.statements.sort();
      });

      normalizedStandardsTooltip.sort((a, b) => a.state.localeCompare(b.state));

      return normalizedStandardsTooltip;
    },
    [isAdiAdmin, isAdiSuperAdmin, user],
  );

  const notFound = useMemo(
    () => (
      <Col offset={8} lg={8} md={12} xs={24}>
        <Result
          status="warning"
          subTitle="There are not assessments listed."
          extra={
            <Row justify="center">
              <Col span={24} style={{ display: 'table' }}>
                <Button
                  style={{ display: 'table-cell', textAlign: 'center' }}
                  type="primary"
                  text="Create a New Assessment Using a Template"
                  onClick={() => history.push(`/adi-assessments/templates`)}
                />
              </Col>
            </Row>
          }
        />
      </Col>
    ),
    [history],
  );

  const skeleton = useMemo(() => <CustomInvestigationLoading isAssessment={true} />, []);

  const page = useMemo(() => {
    return (
      <PageWithTitle
        title={
          <AssessmentListHeader
            refreshMethod={refetchAssessments}
            filters={filters}
            setFilters={setFilters}
            updateSearchText={setSearchText}
            stateStandards={stateStandardsData?.getStateStandards}
            ngssStandards={ngssStandardsData?.getStateStandards}
          />
        }
      >
        <Row gutter={[24, 24]}>
          {publishedInvestigationData?.getInvestigationCatalogPaginated?.list?.length === 0 && !loading && notFound}
          {publishedInvestigationData?.getInvestigationCatalogPaginated?.list?.map((assessment) => (
            <Col md={8} xs={24} sm={12} key={assessment.id}>
              <AssessmentCard
                action={() =>
                  isOrganizationLibrary(assessment)
                    ? history.push(`/investigation-presentation/${assessment.id}`, {
                        backUrl: history.location.pathname + history.location.search,
                      })
                    : history.push(`/adi-assessments/edit/${assessment.id}/core`, { id: assessment.id })
                }
                supportedStates={getSupportedStateStandard(assessment)}
                investigation={assessment}
                loading={loading}
                actionText={investigationCardActionText(assessment)}
                cloneText={'Copy the Assessment'}
              />
            </Col>
          ))}
          {loading && skeleton}
        </Row>
        <Spacer />
        <Row justify="center">
          <Pagination
            total={publishedInvestigationData?.getInvestigationCatalogPaginated?.total}
            pageSize={PAGE_SIZE}
            onChange={(page) => {
              setCurrentPage(page);
              setTimeout(() => {
                const element = document.getElementById('assessment-list-header');
                if (element) {
                  element.scrollIntoView({ block: 'start' });
                }
                refetchAssessments();
              }, 100);
            }}
            current={currentPage}
            showSizeChanger={false}
          />
        </Row>
      </PageWithTitle>
    );
  }, [
    filters,
    setFilters,
    stateStandardsData,
    ngssStandardsData,
    publishedInvestigationData,
    loading,
    notFound,
    skeleton,
    currentPage,
    getSupportedStateStandard,
    investigationCardActionText,
    isOrganizationLibrary,
    history,
    refetchAssessments,
  ]);

  return (isAdiAdmin || isAdiSuperAdmin) && page;
};

export default AdiAssessmentListPage;
