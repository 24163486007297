import React from 'react';
import Spacer from '../../../../shared/Spacer';
import { GQL_InvestigationBlock, ITextContentValue } from '../../../../types/investigation';
import Editor from '../../../../shared/Editor';
import * as S from './styles';

interface IMultipleChoiceQuestionPreview {
  block: GQL_InvestigationBlock;
  isImage?: boolean;
}

interface ProcessDragAndDropAnswerProps {
  contentKey: string;
  value: any;
  isImage?: boolean;
}

const ContentTitlesValues: any = {
  directions: 'Questions',
  scenarios: '',
  targets: 'Steps',
};

const TitleContainer = ({ title }: { title: string }) => {
  return (
    <>
      <h3 style={{ fontWeight: 800 }}>{title}</h3>
    </>
  );
};

const TasksContainer = ({ value, isImage = false }: any) => {
  return value?.map((val: any, index: number) => {
    return (
      <>
        <S.TaskContainerTitle>
          {index + 1}. {val?.title}
        </S.TaskContainerTitle>
        <Spacer />
        <S.TasksContainer>
          <b> Steps </b>
          <b> Process </b>

          <S.TasksContainer>
            <S.TasksCol>
              {val?.values?.map((val: any) => {
                return isImage ? (
                  <img
                    key={`image-drag-${index}`}
                    src={val}
                    alt={`Dragable Option ${index + 1}`}
                    style={{ marginBottom: '10px', height: '100%', width: '150px', objectFit: 'contain' }}
                  />
                ) : (
                  <div>
                    {val} <Spacer />
                  </div>
                );
              })}
            </S.TasksCol>

            <S.TasksCol>
              {val?.values?.map((val: any) => {
                return <S.TaskTarget isImage={isImage}></S.TaskTarget>;
              })}
            </S.TasksCol>
          </S.TasksContainer>
        </S.TasksContainer>
      </>
    );
  });
};

const ContentContainer = ({ contentKey, value, isImage }: ProcessDragAndDropAnswerProps) => {
  let component = null;
  switch (contentKey) {
    case 'directions':
    case 'scenarios':
      component = <Editor editable={false} listenToValue value={value} key={value} />;
      break;
    case 'targets':
      component = <TasksContainer value={value} isImage={isImage} />;
      break;

    default:
      component = null;
      break;
  }

  return component;
};

const ProcessDragAndDropContent = ({ contentKey, value, isImage = false }: ProcessDragAndDropAnswerProps) => {
  const title = ContentTitlesValues[contentKey];

  if (contentKey === 'scenarios') return null;

  return (
    <>
      <Spacer />
      <TitleContainer title={title} />
      <Spacer />
      <ContentContainer contentKey={contentKey} value={value[contentKey]} isImage={isImage} />
    </>
  );
};

const ProcessDragAndDropAnswerPreview = (props: IMultipleChoiceQuestionPreview) => {
  const { block, isImage = false } = props;

  return (
    <>
      <Spacer />
      {(block?.values as ITextContentValue[])?.map((value, index) => {
        const keys = Object.keys(value);
        return (
          <S.ProcessDragDropContainer>
            {keys.map((key) => (
              <ProcessDragAndDropContent contentKey={key} value={value} isImage={isImage} />
            ))}
          </S.ProcessDragDropContainer>
        );
      })}
    </>
  );
};

export default ProcessDragAndDropAnswerPreview;
