import { gql } from '@apollo/client';

export const TEACHER_MUTATIONS = {
  ASSISTANT: {
    assignOrInviteTeacherAssistant: gql`
      mutation AssignOrInviteTeacherAssistant($firstName: String!, $lastName: String!, $email: String!) {
        assignOrInviteTeacherAssistant(firstName: $firstName, lastName: $lastName, email: $email) {
          inviteId
          assistantId
        }
      }
    `,
    removeMyTeacherAssistant: gql`
      mutation RemoveMyTeacherAssistant($assistantId: String!) {
        removeMyTeacherAssistant(assistantId: $assistantId)
      }
    `,
    toggleAssistantGradePermission: gql`
      mutation ToggleTAGradingPermission($data: ToggleTAGradingPermissionInput!) {
        toggleTAGradingPermission(data: $data)
      }
    `,
    toggleAssistantChatPermission: gql`
      mutation ToggleTAChatPermission($data: ToggleTAChatPermissionInput!) {
        toggleTAChatPermission(data: $data)
      }
    `,
  },
};

export default TEACHER_MUTATIONS;
