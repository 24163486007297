import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, message, Table, Input, Row, Col } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AssigneeMode, GQL_GoogleClassroomStudents, GQL_ModifyAssigneeCourseWorkResponse } from '../../../types/googleClassroom';
import { gqlSchema } from '../../../gql/schema';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedStudents: string[]) => void;
  courseId: string;
  courseWorkId?: string; // Make courseWorkId optional
  assigneeMode: string;
  setAssigneeMode: (mode: AssigneeMode) => void;
  individualStudents?: string[] | undefined;
  setIndividualStudents: (students: string[] | undefined) => void;
  hasAllStudents: boolean;
}

const SelectStudentsPopup: React.FC<Props> = ({
  visible,
  onCancel,
  onSave,
  courseId,
  courseWorkId,
  assigneeMode,
  setAssigneeMode,
  individualStudents,
  setIndividualStudents,
  hasAllStudents
}) => {
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
  const [initialSelectedStudents, setInitialSelectedStudents] = useState<string[]>([]);
  const [searchName, setSearchName] = useState<string>('');
  const [searchEmail, setSearchEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [getClassStudents, { data, loading: queryLoading, error }] = useLazyQuery<{
    getCourseStudents: {
      students: GQL_GoogleClassroomStudents[];
    };
  }>(gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudents, {
    onError: (error) => {
      message.error(
        error.message ||
          'There was an error loading the students. Please check your connection and try again later.'
      );
    },
    variables: { courseId },
  });

  const [modifyAssigneeMutation] = useMutation<GQL_ModifyAssigneeCourseWorkResponse>(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.ModifyAssigneeCourseWork,
    {
      onCompleted: (data) => {
        console.log('Assignees Modified!', data);
        if (data.modifyAssigneeCourseWork) {
          onSave(selectedStudents);
        } else {
          message.error('Failed to modify assignees. The server returned an unexpected response.');
        }
        setLoading(false);
      },
      onError: (error) => {
        console.error('Error modifying assignees:', error);
        setLoading(false);
        message.error('Failed to modify assignees. Please try again.');
      },
    }
  );

  useEffect(() => {
    if (visible) {
      getClassStudents();
      if (individualStudents) {
        setInitialSelectedStudents(individualStudents);
        setSelectedStudents(individualStudents);
      } else {
        setSelectedStudents([]);
        setInitialSelectedStudents([]);
      }
    }
  }, [visible, getClassStudents, individualStudents]);

  useEffect(() => {
    if (data) {
      const allStudentIds = data.getCourseStudents.students.map(student => student.profile.id);
      setSelectedStudents(prev => prev.filter(id => allStudentIds.includes(id)));
    }
  }, [data]);

  useEffect(() => {
    if (individualStudents) {
      setInitialSelectedStudents(individualStudents);
      setSelectedStudents(individualStudents);
    } else {
      setSelectedStudents([]);
      setInitialSelectedStudents([]);
    }
  }, [individualStudents]);

  const handleSave = () => {
    if (selectedStudents.length === 0) {
      message.warning('Please select at least one student.');
      return;
    }

    setLoading(true);

    const addStudentIds = selectedStudents.filter(id => !initialSelectedStudents.includes(id));
    const removeStudentIds = initialSelectedStudents.filter(id => !selectedStudents.includes(id));

    if (courseWorkId) {
      if (addStudentIds.length > 0 || removeStudentIds.length > 0) {
        modifyAssigneeMutation({
          variables: {
            courseId,
            id: courseWorkId,
            data: {
              assigneeMode,
              modifyIndividualStudentsOptions: {
                addStudentIds,
                removeStudentIds,
              },
            },
          },
        })
          .then(() => {
            setAssigneeMode(AssigneeMode.INDIVIDUAL_STUDENTS);
            setLoading(false);
            setIndividualStudents(selectedStudents);
          })
          .catch((error) => {
            console.error('Mutation Error:', error);
            message.error('Failed to modify assignees. Please try again.');
            setLoading(false);
          });
      } else {
        setLoading(false);
        onSave(selectedStudents);
      }
    } else {
      setAssigneeMode(AssigneeMode.INDIVIDUAL_STUDENTS);
      setIndividualStudents(selectedStudents);
      setLoading(false);
      onSave(selectedStudents);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent, studentId: string) => {
    const isChecked = e.target.checked;

    setSelectedStudents(prevSelectedStudents => {
      if (isChecked) {
        if (!prevSelectedStudents.includes(studentId)) {
          return [...prevSelectedStudents, studentId];
        }
      } else {
        return prevSelectedStudents.filter(id => id !== studentId);
      }
      return prevSelectedStudents;
    });
  };

  const filteredData = data?.getCourseStudents.students.filter((student) => {
    const name = `${student.profile.name.givenName} ${student.profile.name.familyName}`.toLowerCase();
    const email = student.profile.emailAddress.toLowerCase();
    return name.includes(searchName.toLowerCase()) && email.includes(searchEmail.toLowerCase());
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    console.log('Resize detected:', window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const columns = [
    {
      key: 'select',
    
      render: (record: GQL_GoogleClassroomStudents) => (
        <Checkbox
          onChange={(e) => handleCheckboxChange(e, record.profile.id)}
          checked={selectedStudents.includes(record.profile.id)}
        />
      ),
      width: isMobile? '10%':'5%',
    },
    {
      title: 'First Name',
      dataIndex: ['profile', 'name', 'givenName'],
      key: 'firstName',
      width: isMobile? '40%' : '25%',
    },
    {
      title: 'Last Name',
      dataIndex: ['profile', 'name', 'familyName'],
      key: 'lastName',
      width: isMobile? '40%' :'25%',
    },
    {
      title: 'Email',
      dataIndex: ['profile', 'emailAddress'],
      key: 'email',
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none',  } : {},
      }),
    },
  ];
  const getNestedValue = (dataIndex: (string | number)[], record: any) => {
    return dataIndex.reduce((acc, key) => {
      if (acc && acc[key] !== undefined) {
        return acc[key];
      }
      return null;
    }, record);
  };
  
  const expandedRowRender = (record: any) => {
    return (
      <div>
        {columns
          .filter((column: any) => column.key !== 'select') // Filter out the 'select' column
          .map((column: any) => {
            // If the column has a dataIndex and it's an array, extract the value
            const value = Array.isArray(column.dataIndex)
              ? getNestedValue(column.dataIndex, record)
              : record[column.dataIndex];
    
            return (
              <div key={column.key} style={{ marginBottom: '8px' }}>
                <strong>{column.title}:</strong> {value || 'N/A'}
              </div>
            );
          })}
      </div>
    );
  };
  
  return (
    <Modal
      title="Manage Students"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSave}
      okText="Save"
      cancelText="Cancel"
      width={800}
      style={{ textAlign: 'center' }}
      confirmLoading={loading || queryLoading}
    >
      <Row gutter={[16, 16]}>
        <Col span={12} xl={12} lg={12} md={12} xs={24}>
          <Input
            placeholder="Search by name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </Col>
        <Col span={12} xl={12} lg={12} md={12} xs={24}>
          <Input
            placeholder="Search by email address"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
        </Col>
      </Row>
   {isMobile && (<><Table
        dataSource={filteredData?.map((student) => ({
          ...student,
          email: student?.profile?.emailAddress,
        }))}
        columns={columns}
        rowKey={(record) => record.profile.id}
        scroll={{ y: 400 }}
        pagination={false}
        style={{ marginTop: 16 }}
        loading={queryLoading}
        expandable={{
          expandedRowRender,

        }}
      /></>)}   
       {!isMobile && (<><Table
        dataSource={filteredData?.map((student) => ({
          ...student,
          email: student?.profile?.emailAddress,
        }))}
        columns={columns}
        rowKey={(record) => record.profile.id}
        scroll={{ y: 400 }}
        pagination={false}
        style={{ marginTop: 16 }}
        loading={queryLoading}
      /></>)} 
      {error && (
        <div style={{ color: 'red', marginTop: 16 }}>
          There was an error loading the students: {error.message}
        </div>
      )}
    </Modal>
  );
};

export default SelectStudentsPopup;
