import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Col, message, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useHistory } from 'react-router';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import Avatar from '../../../shared/Avatar';
import Button from '../../../shared/Button';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import Sider from '../../../shared/Sider';
import Spacer from '../../../shared/Spacer';
import { GQL_InvestigationDisplay } from '../../../types/investigation';
import { GQL_InvestigationSummaryResponse } from '../../../types/teacher';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';

interface IInvestigationDetailsSider {
  investigation?: GQL_InvestigationDisplay;
  investigationId: string;
  currentStepIndex?: number;
  currentActivityIndex?: number;
  onFinishSettings?: () => void;
  setCurrentStepByIndex?: (index: number) => void;
  setCurrentActivityByIndex?: (index: number) => void;
  selectedStudentId?: string;
  setSelectedStudentId?: (v: string) => void;
  firstSetting?: boolean;
  backUrl?: string;
  showBackButton?: boolean;
}

const InvestigationDetailsSider = (props: IInvestigationDetailsSider) => {
  const {
    investigation,
    currentStepIndex,
    currentActivityIndex,
    setCurrentStepByIndex,
    setCurrentActivityByIndex,
    firstSetting,
    onFinishSettings,
    backUrl,
    selectedStudentId,
    investigationId,
    setSelectedStudentId = () => null,
    showBackButton,
  } = props;

  const isAssessment = useMemo(() => {
    return investigation?.isAssessment;
  }, [investigation]);
  const lastSetting = (currentStepIndex ?? 0) + 1 === investigation?.steps?.length;
  const currentStep = investigation?.steps[currentStepIndex ?? 0];
  const [showGradeButton, setShowGradeButton] = useState(false);
  const { isTeacherAssistant, isTeacherOrFacilitator, user } = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const history = useHistory();

  const { data: investigationData } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId,
    },
    onError: () => {
      message.error(
        'There was an error loading the investigation summary, please check your connection and try again later',
      );
    },
  });

  const handleSelectStage = (value: string) => {
    if (setCurrentStepByIndex) {
      const stepIndex = investigation?.steps?.findIndex((step) => step.id === value) ?? 0;
      setCurrentStepByIndex(stepIndex);
    }
  };

  const handleNextStage = () => {
    if (lastSetting && onFinishSettings) {
      onFinishSettings();
    } else if (setCurrentStepByIndex) {
      setCurrentStepByIndex((currentStepIndex ?? 0) + 1);
    }
  };

  const handlePreviousStage = () => {
    if (setCurrentStepByIndex) {
      setCurrentStepByIndex((currentStepIndex ?? 1) - 1);
    }
  };

  useEffect(() => {
    if (selectedStudentId) {
      const student = investigationData?.getInvestigationProgressSummary?.perStudents?.find(
        (student) => student.userId === selectedStudentId,
      );
      setShowGradeButton(
        student?.completion === 1 && (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher || (isTeacherAssistant && user.isAllowedGrading)),
      );
    } else {
      setShowGradeButton(false);
    }
  }, [investigationData, selectedStudentId, isTeacherAssistant, isTeacherOrFacilitator, isGoogleTeacher, isCanvasTeacher, user]);

  const onSelectStudent = useCallback(
    (v: SelectValue) => {
      setSelectedStudentId(v as string);
    },
    [setSelectedStudentId],
  );

  return (
    <Sider title={investigation?.title} backUrl={backUrl} showBackButton={showBackButton}>
      <S.SidebarContainer>
        <S.ContentContainer>
          <S.Divider />
          <Select
            placeholder="Loading..."
            value={selectedStudentId}
            height={52}
            defaultValue=""
            onChange={onSelectStudent}
          >
            <SelectOption value={''}>
              <Avatar backgroundColor="#FFFFFF" size={40} />
              <Spacer axis="horizontal" display="inline-block" />
              All students
            </SelectOption>
            {investigationData?.getInvestigationProgressSummary?.perStudents?.map((student) => (
              <SelectOption value={student.userId} key={student.userId}>
                <S.SelectOptionContainer>
                  <Avatar backgroundColor="#FFFFFF" size={40} src={student?.avatar} />
                  <Spacer axis="horizontal" display="inline-block" />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h1 style={{ lineHeight: 1.2, margin: 0 }}>
                      {student.firstName} {student.lastName}
                    </h1>
                    <h2 style={{ lineHeight: 1.2, margin: 0 }}>{Math.round(student.completion * 100)}% Completed</h2>
                  </div>
                </S.SelectOptionContainer>
              </SelectOption>
            ))}
          </Select>
          <Spacer size={24} />
          <S.Divider />

          {!isAssessment && (
            <>
              <Select onChange={(v) => handleSelectStage(v as string)} placeholder="Loading..." value={currentStep?.id}>
                {investigation?.steps?.map((step, index) => (
                  <SelectOption value={step.id} key={step.id}>
                    Stage {index + 1}: {step.name}
                  </SelectOption>
                ))}
              </Select>

              <Spacer />
            </>
          )}

          <Select
            onChange={(v) => setCurrentActivityByIndex && setCurrentActivityByIndex(v as number)}
            placeholder="Loading..."
            value={currentActivityIndex}
          >
            {currentStep?.activities?.map((activity, index) => (
              <SelectOption value={index} key={activity.id}>
                {activity.name}
              </SelectOption>
            ))}
          </Select>
          <Spacer size={24} />
          <S.Divider />
          <div style={{ height: '100%' }} />
        </S.ContentContainer>
        <Row gutter={[20, 10]}>
          <Col lg={12} md={{ span: 24, order: 2 }}>
            <Button
              text="Previous Stage"
              theme={themeConfig.secondaryOutlined}
              disabled={firstSetting}
              minHeight={40}
              block
              onClick={handlePreviousStage}
            />
          </Col>
          <Col lg={12} md={{ span: 24, order: 2 }}>
            <Button
              text={lastSetting ? 'Finish' : 'Next Stage'}
              minHeight={40}
              block
              onClick={lastSetting ? () => history.goBack() : handleNextStage}
              theme={themeConfig.secondaryColor}
            />
          </Col>
        </Row>
        <Spacer />
        {showGradeButton && (
          <Button
            text={isAssessment ? 'Grade Assessment' : 'Grade Investigation'}
            onClick={() =>
              isAssessment 
                ? history.push(`/teacher-dashboard/assessment-details/${investigationId}/grade/${selectedStudentId}`) 
                : history.push(`/teacher-dashboard/investigation-details/${investigationId}/grade/${selectedStudentId}`)
            }
            block
            minHeight={40}
          />
        )}
      </S.SidebarContainer>
    </Sider>
  );
};

export default InvestigationDetailsSider;
