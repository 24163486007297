// styles.ts
import styled from 'styled-components';
import { Button } from 'antd';

export const ViewMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

export const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #d9d9d9;
  transition: width 0.3s ease;
`;

export const StyledButton = styled(Button)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

export const LogoImage = styled.img`
  height: 100%;
  padding: 8px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px; /* Add margin-bottom to ensure space between pagination and cards */
`;

export const ArrowButton = styled(Button)`
  border: 1px solid #d9d9d9;
  background-color: #fff;
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 8px;  /* Add margin to create space between buttons */
  &:hover {
    color: #1890ff;
    border-color: #1890ff;
  }
  &:disabled {
    color: #d9d9d9;
    border-color: #d9d9d9;
  }
`;
