import { Col, message, Pagination, Result, Row, Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PageWithTitle from '../../shared/PageWithTitle';
import InvestigationListHeader from './InvestigationListHeader';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationCatalogEntryPaginated,
  INVESTIGATION_TABS,
  InvestigationCatalogPaginatedParams,
  InvestigationType,
} from '../../types/investigation';
import InvestigationCard from '../../shared/InvestigationCard';
import { useHistory } from 'react-router-dom';
import Spacer from '../../shared/Spacer';
import uniqBy from 'lodash/uniqBy';
import { useCallback } from 'react';
import { useAuth } from '../../hooks/useAuth';
import * as S from './styles';
import Button from '../../shared/Button';
import { useInvestigationFilters } from '../../hooks/useInvestigationFilters';
import { upsertFieldToQueryString } from '../../utils/querystring';
import { GQL_InvestigationStandard } from '../../types/investigationStandard';
import { INVESTIGATION_STANDARDS } from '../../types/standards';
import CustomInvestigationLoading from '../../shared/CustomInvestigationLoading';

type FilterFields =
  | 'subject'
  | 'status'
  | 'grade'
  | 'discipline'
  | 'coreIdea'
  | 'mode'
  | 'type'
  | 'trial'
  | 'teacherFavorite'
  | 'state';

export interface IInvestigationListFilters {
  field: FilterFields;
  value: string;
}

const PAGE_SIZE = 30;
const { TabPane } = Tabs;

const AdiInvestigationListPage = () => {
  const history = useHistory();
  const [filters, setFilters] = useInvestigationFilters<FilterFields>(history);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>();

  const { user, isOrganizationAdiAdmin, isAdiSuperAdmin, isAdiAdmin, isWriter } = useAuth();
  const initialAdminActiveKey = new URLSearchParams(history.location.search).get('tab') || 'standard';
  const initialOrgAdminActiveKey = new URLSearchParams(history.location.search).get('tab') || 'organization-library';
  const [activeKey, setActiveKey] = useState(isOrganizationAdiAdmin ? initialOrgAdminActiveKey : initialAdminActiveKey);

  const createPayload = () => {
    const payload = {
      isAssessment: false,
      page: currentPage,
      pageSize: PAGE_SIZE,
    } as InvestigationCatalogPaginatedParams | any;

    if (activeKey === INVESTIGATION_TABS.WORKSHOP || activeKey === INVESTIGATION_TABS.STANDARD) {
      payload.investigationType = activeKey.toUpperCase() as InvestigationType;
    }

    if (activeKey === INVESTIGATION_TABS.ORG_LIBRARY) {
      payload.organizationId = user.subscription?.organizationId;
    }

    if (activeKey === INVESTIGATION_TABS.ADI_LIBRARY) {
      payload.organizationId = null;
    }

    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index] as any;

      if (filter.field === 'tab') {
        continue;
      }

      const key = filter.field as any;
      payload[key] = filter.value;
    }

    if (searchText) {
      payload['searchText'] = searchText;
    }

    return payload;
  };

  const { data: publishedInvestigationData, loading: loadingPublished, refetch: refetchInvestigation } = useQuery<
    {
      getInvestigationCatalogPaginated: GQL_InvestigationCatalogEntryPaginated;
    },
    InvestigationCatalogPaginatedParams
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalogPaginated, {
    variables: createPayload(),
    onError: (err) => {
      message.error('There was an error loading published investigation data: ' + err.message || 'Unexpected Error');
    },
    fetchPolicy: 'network-only',
  });

  const { data: stateStandardsData } = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: INVESTIGATION_STANDARDS.TEXAS,
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: ngssStandardsData} = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: INVESTIGATION_STANDARDS.NGSS,
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const publishedInvestigation = useMemo(() => {
    let investigations = publishedInvestigationData?.getInvestigationCatalogPaginated?.list || [];
    return investigations;
  }, [publishedInvestigationData]);

  const mergedInvestigations = useMemo(() => uniqBy([...publishedInvestigation], 'id'), [publishedInvestigation]);

  const loading = useMemo(() => loadingPublished, [loadingPublished]);

  const isOrganizationLibrary = useCallback(
    (investigation: GQL_InvestigationCatalogEntry) =>
      (isOrganizationAdiAdmin || isWriter) && investigation.organization?.id !== user.subscription?.organizationId,
    [isOrganizationAdiAdmin, isWriter, user],
  );

  const investigationCardActionText = useCallback(
    (investigation: GQL_InvestigationCatalogEntry) => {
      if (isOrganizationLibrary(investigation)) return 'Preview Investigation';
      return 'Edit Investigation';
    },
    [isOrganizationLibrary],
  );

  const getSupportedStateStandard = useCallback(
    (investigation: GQL_InvestigationCatalogEntry) => {
      const standards = user?.organizationAllowedStates?.length
        ? user.organizationAllowedStates
        : user?.userAllowedStates;
      const normalizedStandards = standards?.map((s) => s.postalCode) as string[];

      const baseStandards = [...(investigation.nextGenerationStandards || []), ...(investigation.standards || [])];
      const standardsInv =
        isAdiAdmin || isAdiSuperAdmin
          ? baseStandards
          : baseStandards?.filter((invStandard) => normalizedStandards?.includes(invStandard.state));

      const normalizedStandardsTooltip = standardsInv?.reduce(
        (acc: { state: string; statements: string[] }[], d: GQL_InvestigationStandard) => {
          const found = acc.find((a) => a.state === d.state);
          if (!found) {
            acc.push({ state: d.state, statements: [`${d.code}: ${d.fullStatement}`] });
          } else {
            found.statements.push(`${d.code}: ${d.fullStatement}`);
          }
          return acc;
        },
        [],
      );
      normalizedStandardsTooltip.forEach((state) => {
        state.statements.sort();
      });
      
      normalizedStandardsTooltip.sort((a, b) => a.state.localeCompare(b.state));

      return normalizedStandardsTooltip;
    },
    [isAdiAdmin, isAdiSuperAdmin, user],
  );

  const skeleton = useMemo(() => <CustomInvestigationLoading />, []);

  const renderCards = useCallback(
    (investigations: GQL_InvestigationCatalogEntry[], showCloneButton: boolean) => (
      <>
        <Row gutter={[24, 24]}>
          {!loading &&
            investigations?.map((investigation) => (
              <Col md={8} xs={24} sm={12} key={investigation.id}>
                <InvestigationCard
                  action={() =>
                    isOrganizationLibrary(investigation)
                      ? history.push(`/investigation-presentation/${investigation.id}`, {
                          backUrl: history.location.pathname + history.location.search,
                        })
                      : history.push(`/adi-investigations/edit/${investigation.id}/core`, { id: investigation.id })
                  }
                  supportedStates={getSupportedStateStandard(investigation)}
                  investigation={investigation}
                  loading={loading}
                  actionText={investigationCardActionText(investigation)}
                  cloneText={showCloneButton ? 'Copy Investigation' : undefined}
                />
              </Col>
            ))}
        </Row>
        <Spacer />
        <Row justify="center">
          <Pagination
            total={publishedInvestigationData?.getInvestigationCatalogPaginated?.total}
            pageSize={PAGE_SIZE}
            onChange={(res) => {
              setCurrentPage(res);
              setTimeout(() => {
                refetchInvestigation();
              }, 0);
            }}
            current={currentPage}
            showSizeChanger={false}
          />
        </Row>
      </>
    ),
    [
      loading,
      currentPage,
      getSupportedStateStandard,
      investigationCardActionText,
      isOrganizationLibrary,
      history,
      publishedInvestigationData,
      refetchInvestigation,
    ],
  );

  const notFound = useMemo(
    () => (
      <Result
        status="warning"
        subTitle="There are not investigations listed."
        extra={
          <Row justify="center">
            <Col span={24} style={{ display: 'table' }}>
              <Button
                style={{ display: 'table-cell', textAlign: 'center' }}
                type="primary"
                text="Create a New Investigation Using a Template"
                onClick={() => history.push(`/adi-investigations/templates`)}
              />
            </Col>
          </Row>
        }
      />
    ),
    [history],
  );

  const investigationList = useCallback(
    (investigations: GQL_InvestigationCatalogEntry[], showCloneButton: boolean) =>
      loading ? skeleton : investigations.length > 0 ? renderCards(investigations, showCloneButton) : notFound,
    [loading, notFound, renderCards, skeleton],
  );

  const page = useMemo(() => {
    return (
      <PageWithTitle
        hideBack
        title={
          <InvestigationListHeader
            refreshMethod={refetchInvestigation}
            filters={filters}
            setFilters={setFilters}
            updateSearchText={setSearchText}
            stateStandards={stateStandardsData?.getStateStandards}
            ngssStateStandards={ngssStandardsData?.getStateStandards}
          />
        }
      >
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={(activeKey: string) => {
            setActiveKey(activeKey);
            upsertFieldToQueryString({
              field: 'tab',
              value: activeKey,
              history: history,
            });
          }}
        >
          <TabPane tab={<S.TitleTab>Standard Investigations</S.TitleTab>} key={INVESTIGATION_TABS.STANDARD}>
            {investigationList(mergedInvestigations, true)}
          </TabPane>
          <TabPane tab={<S.TitleTab>Workshop Investigations</S.TitleTab>} key={INVESTIGATION_TABS.WORKSHOP}>
            {investigationList(mergedInvestigations, true)}
          </TabPane>
        </Tabs>
      </PageWithTitle>
    );
  }, [
    filters,
    mergedInvestigations,
    activeKey,
    stateStandardsData,
    ngssStandardsData,
    investigationList,
    setFilters,
    refetchInvestigation,
    history,
  ]);

  const orgLibrary = useMemo(() => {
    return (
      <PageWithTitle
        hideBack
        backPageUrl="/adi-investigations"
        title={
          <InvestigationListHeader
            refreshMethod={refetchInvestigation}
            filters={filters}
            setFilters={setFilters}
            updateSearchText={setSearchText}
            stateStandards={stateStandardsData?.getStateStandards}
            ngssStateStandards={ngssStandardsData?.getStateStandards}
          />
        }
      >
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={(activeKey: string) => {
            setActiveKey(activeKey);
            upsertFieldToQueryString({
              field: 'tab',
              value: activeKey,
              history: history,
            });
          }}
        >
          <TabPane tab={<S.TitleTab>Organization Library</S.TitleTab>} key={INVESTIGATION_TABS.ORG_LIBRARY}>
            {investigationList(mergedInvestigations, false)}
          </TabPane>
          <TabPane tab={<S.TitleTab>ADI Library</S.TitleTab>} key={INVESTIGATION_TABS.ADI_LIBRARY}>
            {investigationList(mergedInvestigations || [], true)}
          </TabPane>
        </Tabs>
      </PageWithTitle>
    );
  }, [
    filters,
    activeKey,
    stateStandardsData,
    ngssStandardsData,
    history,
    investigationList,
    setFilters,
    refetchInvestigation,
    mergedInvestigations,
  ]);

  useEffect(() => setCurrentPage(1), [activeKey]);

  return (isOrganizationAdiAdmin || isWriter) && user.subscription?.customLibrary ? orgLibrary : page;
};

export default AdiInvestigationListPage;
