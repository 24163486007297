import React, { useCallback } from 'react';
import { useRemirror } from 'remirror/react';
import EquationEditor from 'equation-editor-react';
import * as S from './styles';

const Formula = (props: any) => {
  const { commands } = useRemirror();
  const { formula } = props.node.attrs;

  const editable = props?.view?.editable;
  const updateNodeAttributes = useCallback(
    (attrs: { formula: string }) => {
      const { getPosition, node } = props;
      const pos = getPosition();
      const attributes = {
        ...node.attrs,
        ...attrs,
      };
      commands.updateNodeAttributes(pos, attributes);
    },
    [commands, props],
  );

  const onChange = useCallback(
    (newFormula: string) => {
      if (editable) {
        updateNodeAttributes({
          formula: newFormula
            .replace('<->', '\\leftrightarrow ')
            .replace('<--', '\\leftarrow ')
            .replace('-->', '\\rightarrow ')
            .replace('dollar', '$ '),
        });
      }
    },
    [editable, updateNodeAttributes],
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <S.Formula $value={formula} $editable={props?.view?.editable} onKeyDown={onKeyDown}>
      <EquationEditor
        value={formula}
        onChange={onChange}
        autoCommands="pi theta sqrt sum prod alpha beta gamma rho pm ge le Omega Sigma mu epsilon sigma theta omega Delta beta infty wedge dollar neq integral"
        autoOperatorNames="sin cos tan"
      />
    </S.Formula>
  );
};

export default Formula;
