import styled, { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';
import { Calendar } from 'antd';
export const GlobalStyle = createGlobalStyle`
  body {
    scroll-behavior: smooth;
  }
`;
export const Container = styled.div`
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  padding: 0 4em;
  scroll-behavior: smooth;
  @media (max-width: 1024px) {
    padding: 0 2em;
}
`;
export const StyledCalendar = styled(Calendar)`
  width: 22rem;
  .ant-picker-calendar-header {
    background-color: rgb(97, 123, 218);
    border-radius: 8px 8px 0px 0px;
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      background: none;
      outline: none;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }

  .ant-picker-calendar-header .ant-picker-calendar-year-select,
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    color: white;
  }

  .ant-picker-calendar-header .ant-picker-calendar-prev-icon,
  .ant-picker-calendar-header .ant-picker-calendar-next-icon {
    color: white;
  }
  .ant-picker-cell {
    margin-top: 3px;
    border: 1px solid #d9d9d9; // Add border to create grid
  }
`;
export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: 'white';
  text-align: left;
  height: 500px;
  width: 100%;
  min-width:1200px
  @media (max-width: 1024px) {
    padding: 1em;
} 
`;
export const PSection = styled.section`
  background: 'white';
  text-align: left;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  padding: 2em;
`;
export const PSectionTwo = styled.section`
  background: 'white';
  text-align: left;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 2em;
`;
export const PSec = styled.section`
  text-align: left;
  width: 100%;
  border-radius: 4px;
  padding: 2em;
  p {
    font-size: 18px;
  }
`;

export const PSecTwo = styled.section`
  text-align: left;
  width: 100%;
  border-radius: 4px;
  padding: 2em;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
`;

export const Divider = styled.div`
  width: 10px;
  height: auto;
  background-color: rgb(14, 24, 216);
  margin: 20px 0;
`;
export const TDivider = styled.div`
  width: 10px;
  height: 20rem;
  background-color: rgb(14, 24, 216);
  margin: 20px 0;
`;

export const HeaderContent = styled.section`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 4em;
  width: 100rem;
  padding: 0.4em;
  p {
    font-size: 16px;
    font-weight: lighter;
    text-align: justify;
  }
  @media (max-width: 1024px) {
    width: 60rem;
    margin-left: 2em;
    padding: 1em;
    p{
      font-size: 14px;
    }
}
`;
export const MainHeaderContent = styled.section`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.4em;
  @media (max-width: 1024px) {
    padding: 1em;
    margin-top: 1em;
} 
`;

export const ChatHeaderContent = styled.section`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 4em;
  padding: 0.4em;
`;

export const Title = styled.h1`
  font-size: 16px;
  color: #4367e9;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1em;
`;
export const PTitle = styled.h1`
  font-size: 44px;
  color: ' #222';
  font-weight: 600;
  margin-bottom: 1em;
  align-items: center;
  margin-top: 1em;
  justify-content: center;
  display: flex;
`;

export const Maintitle = styled.div`
  font-size: 30px;
  color: #222;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: left;
`;
export const MainHeadertitle = styled.div`
  font-size: 15px;
  color: #555;
  margin-bottom: 20px;
  text-align: left;
`;
export const Subtitle = styled.h1`
  font-size: 28px;
  color: #222;
  margin-bottom: 20px;
  font-weight: 700;
  max-width:25rem;
`;
export const HSubtitle = styled.h1`
  font-size: 44px;
  color:  #222;
  margin-bottom: 20px;
  font-weight: 700;
`;

export const Button = styled.button`
  background-color: #4367e9;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  a {
    color: white;
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    padding: 5px 10px;
}
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
`;

export const Image = styled.img`
  width: 50rem;
  height: auto;
  margin-top: 30px;
  overflow: hidden;
  margin-right: -55px;
  @media (max-width: 1024px) {
    width: 35rem;
    margin-right: -50px;
    overflow: hidden;
}
`;
export const PImage = styled.img`
  border-radius: 20px;
  object-fit: contain;
  width: auto;
  height: 250px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  text-align: left;
  gap: 2rem;
  @media (max-width: 1024px) {
    padding: 1em;
}
`;
export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 7em;

  @media (max-width: 1024px) {
    gap: 3em;
  }
`;

export const Tab = styled.div<{ active?: boolean }>`
  cursor: pointer;
  margin-top: 20px;
  color: ${({ active }) => (active ? '#007BFF' : 'rgba(58, 58, 60, 0.8)')};
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ active }) => (active ? '#007BFF' : 'transparent')};
  }

  @media (max-width: 1024px) {
    font-size: 13px;
  }
`;
export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80rem;
  @media (max-width: 1024px) {
  width: 70rem;
  }
`;
export const ChatContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 9rem;
  gap: 10px;
`;

export const MoreKeyFeatures = styled.div`
  font-size: 16px;
  color: rgb(25, 25, 26);
  text-transform: uppercase;
  cursor: pointer;
`;
