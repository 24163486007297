import React from 'react';
import { Line } from 'react-konva';

export interface IEraserShapeProps {
  points: number[];
  color: string;
  strokeWidth: number;
}

interface IEraserShape {
  shapeProps: IEraserShapeProps;
}

export const EraserShape = (props: IEraserShape) => {
  const { shapeProps } = props;

  return (
    <Line
      points={shapeProps.points}
      stroke={shapeProps.color}
      strokeWidth={shapeProps.strokeWidth}
      tension={0.5}
      lineCap="round"
      globalCompositeOperation="destination-out"
    />
  );
};
