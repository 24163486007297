import { Card, Col } from 'antd';
import React, { useCallback, useState } from 'react';
import Button from '../../../shared/Button';
import * as S from './styles';
import { FcDownload } from 'react-icons/all';
import { GQL_Invoices } from '../../../types/payments';
import moment from 'moment';
import { InvoicesModal } from '../AllInvoicesModal';

type PaymentMethodProps = {
  loading: boolean;
  invoicesData: GQL_Invoices[];
};

export const BillingInvoices: React.FC<PaymentMethodProps> = (props) => {
  const { loading = false, invoicesData } = props;
  const [showInvoicesModal, setShowInvoicesModal] = useState(false);
  const CardTitle = useCallback(() => <S.CardTitle>Invoices</S.CardTitle>, []);

  const CardExtra = useCallback(
    () => (
      <Button
        size="small"
        text={'View all'}
        onClick={() => {
          setShowInvoicesModal(true);
        }}
      />
    ),
    [],
  );

  function formatCurrency(price: number) {
    const priceLabel = '$' + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return priceLabel;
  }

  return (
    <>
      <Card
        title={<CardTitle />}
        extra={<CardExtra />}
        bordered={false}
        hoverable={!loading}
        loading={loading}
        headStyle={{ border: 0 }}
        bodyStyle={{ paddingTop: '10px' }}
        style={{ backgroundColor: '#e8ebed', borderRadius: 0 }}
        key={'billing-invoices'}
      >
        {invoicesData?.map((invoice) => (
          <S.InvoiceRow key={invoice?.created}>
            <Col span={12} className="title-element">
              {moment(invoice?.created, 'X').format('MMMM DD, YYYY')}
            </Col>
            <Col span={12} className="price-element">
              {formatCurrency(invoice?.amount_due / 100)}
              <FcDownload
                style={{ marginLeft: '5px', cursor: 'pointer' }}
                onClick={() => window.open(invoice?.invoice_pdf, '__blank__')}
                key={invoice?.invoice_pdf}
              />
            </Col>
          </S.InvoiceRow>
        ))}
      </Card>

      {showInvoicesModal && <InvoicesModal visible={showInvoicesModal} setVisible={setShowInvoicesModal} />}
    </>
  );
};
