import React, { useCallback, useState } from 'react';
import TeacherProfile from './Profile';
import * as S from './styles';
import { Col, Row, Tabs } from 'antd';
import MailPreferences from '../../shared/MailPreferences';

const { TabPane } = Tabs;
const TeacherProfilePage = () => {
  const [activeKey, setActiveKey] = useState('profile');
  const handleTabChange = useCallback((activeKey: string) => setActiveKey(activeKey), []);

  return (
    <>
      <S.Container>
        <Row gutter={[24, 24]}>
          <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Tabs defaultActiveKey={activeKey} activeKey={activeKey} onChange={handleTabChange}>
              <TabPane tab={<S.TitleTab>Profile</S.TitleTab>} key="profile">
                <TeacherProfile />
              </TabPane>
              <TabPane tab={<S.TitleTab>Mail Preferences</S.TitleTab>} key="mail_preferences">
                <MailPreferences />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </S.Container>
    </>
  );
};

export default TeacherProfilePage;
