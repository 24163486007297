import { gql } from '@apollo/client';

export const CLASSLINK_MUTATIONS = {
  ROSTER: {
    syncCleverRoster: gql`
      mutation syncCleverRoster($orgId: String) {
        syncCleverRoster(orgId: $orgId)
      }
    `,
    syncCleverClasses: gql`
      mutation syncCleverClasses {
        syncCleverClasses
      }
    `,
  },
};

export default CLASSLINK_MUTATIONS;
