import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BiologyLogo } from '../../assets/discipline-icons/science/biology.svg';
import logo from '../../assets/logo.svg';
import Button from '../../shared/Button';
import PageWithTitle from '../../shared/PageWithTitle';
import Spacer from '../../shared/Spacer';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';
import useFetchCorrelationFileUrl from '../../hooks/useFetchCorrelationFileUrl';
import { SUBJECT_TYPES } from '../../types/subjects';
import { FiArrowLeft, FiDownload } from 'react-icons/fi';

const GradeReviewPage = () => {
  const { version, grade } = useParams<{ version: string; grade: string }>();
  const history = useHistory();

  const parsedVersion = version.slice(1);

  const { fileUrl, teacherManualUrl } = useFetchCorrelationFileUrl({
    gradeBand: grade,
    subject: SUBJECT_TYPES.SCIENCE_TEXAS_EDITION,
    version: parseInt(parsedVersion) ? parsedVersion : null,
  });

  const downloadFile = (url: string) => {
    if (url.length) {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  const navigateToInvestigations = () => {
    history.push(`/advanced-search/${version}/${grade}/contentType/investigations`);
  };

  const navigateToAssessments = () => {
    history.push(`/advanced-search/${version}/${grade}/contentType/assessments`);
  };

  const gradeFormat = useMemo(() => {
    switch (grade) {
      case '3':
        return '3rd';
      case '4':
        return '4th';
      case '5':
        return '5th';
      default:
        return grade;
    }
  }, [grade]);

  const handleBackButtonClick = () => {
    history.goBack();
  };

  return (
    <PageWithTitle>
      <Row gutter={[24, 24]} justify="center">
        <Col xs={24} md={18} lg={18} xl={14}>
          <S.Card>
            <S.TitleRow>
              <S.BackButtonContainer onClick={handleBackButtonClick}>
                <FiArrowLeft />
              </S.BackButtonContainer>
              <S.LogoImage data-cy="components-gradereview-logo" src={logo} alt="ADI Logo" />
              <S.EmptyBox />
            </S.TitleRow>
            <Spacer />
            <S.IconContainer>
              <BiologyLogo />
            </S.IconContainer>
            <Spacer />
            <h1>{gradeFormat} Grade Science</h1>
            <h1>2024 Texas Adoption Review</h1>
            <Spacer />
            <Row style={{ width: '100%', marginBottom: '24px' }} gutter={[24, 24]}>
              <Col span={12}>
                <Button
                  block
                  data-cy="components-gradereview-investigation-button"
                  text="View Investigations"
                  theme={themeConfig.primaryColor}
                  minHeight={40}
                  onClick={navigateToInvestigations}
                />
              </Col>
              <Col span={12}>
                <Button
                  block
                  data-cy="components-gradereview-assessment-button"
                  text="View Assessments"
                  theme={themeConfig.primaryColor}
                  minHeight={40}
                  onClick={navigateToAssessments}
                />
              </Col>
            </Row>
            <Row style={{ width: '100%', justifyContent: 'center' }} gutter={[24, 24]}>
              <Col span={12}>
                <Button
                  block
                  data-cy="components-gradereview-download-button"
                  text="Download Correlations"
                  theme={themeConfig.primaryOutlined}
                  minHeight={40}
                  onClick={() => downloadFile(fileUrl)}
                  disabled={fileUrl.length === 0}
                  icon={<FiDownload />}
                />
              </Col>

              <Col span={12}>
                <Button
                  block
                  data-cy="components-gradereview-teacher-manual-download-button"
                  text="Teacher Manual"
                  theme={themeConfig.primaryOutlined}
                  minHeight={40}
                  onClick={() => downloadFile(teacherManualUrl)}
                  disabled={teacherManualUrl.length === 0}
                  icon={<FiDownload />}
                />
              </Col>
            </Row>
          </S.Card>
        </Col>
      </Row>
    </PageWithTitle>
  );
};

export default GradeReviewPage;
