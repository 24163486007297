import React, { useCallback } from 'react';
import { Col, Divider, Modal, Skeleton, message } from 'antd';
import * as S from './styles';
import { FcDownload } from 'react-icons/fc';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GQL_Invoices } from '../../../types/payments';
import { gqlSchema } from '../../../gql/schema';

interface AllInvoicesModalProps {
  visible?: boolean;
  setVisible: (v: boolean) => void;
}

export const InvoicesModal: React.FC<AllInvoicesModalProps> = (props) => {
  const { setVisible, visible } = props;

  const { data: gqlInvoicesData, loading } = useQuery<{ getAllInvoices: GQL_Invoices[] }, any>(
    gqlSchema.PaymentsSchema.queries.LIST.getAllInvoices,
    {
      onError: () => {
        message.error('There was an error loading the invoices data, please check your connection and try again later');
      },
    },
  );

  const invoices = gqlInvoicesData?.getAllInvoices;

  function formatCurrency(price: number) {
    const priceLabel = '$' + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return priceLabel;
  }

  const handleCloseModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null} width={500}>
      <S.ModalTitle>Invoices</S.ModalTitle>

      <S.ModalText>Click below to download your invoice</S.ModalText>
      <Divider />

      {loading && (
        <S.ModalLoading>
          <Skeleton.Input active size="large" />
        </S.ModalLoading>
      )}

      {invoices?.map((invoice) => (
        <S.InvoiceRow>
          <Col span={12} className="title-element">
            {moment(invoice?.created, 'X').format('MMMM DD, YYYY')}
          </Col>
          <Col span={12} className="price-element">
            {formatCurrency(invoice?.amount_due / 100)}
            <FcDownload
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => window.open(invoice?.invoice_pdf, '__blank__')}
            />
          </Col>
        </S.InvoiceRow>
      ))}
    </Modal>
  );
};
