import React from 'react';

import { Col, Row, Typography } from 'antd';
import TeacherAssistants from '../TeacherAssistants';
import TeacherStudents from '../TeacherStudents';

import * as S from './styles';

const TeacherUsers: React.FC = () => {
  return (
    <Row>
      <Col span={24}>
        <S.Container>
          <Typography.Title level={3} style={{ marginBottom: 0, paddingBottom: 0, marginLeft: '-0.5em' }}>
            Students
          </Typography.Title>
        </S.Container>
      </Col>
      <Col span={24}>
        <TeacherStudents />
      </Col>
      <Col span={24}>
        <S.Container>
          <Typography.Title level={3} style={{ marginBottom: 0, paddingBottom: 0, marginLeft: '-0.5em' }}>
            Teacher Assistants
          </Typography.Title>
        </S.Container>
      </Col>
      <Col span={24}>
        <TeacherAssistants />
      </Col>
    </Row>
  );
};

export default TeacherUsers;
