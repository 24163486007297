import { Col, Row, Pagination, message, Result } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { startCase } from 'lodash';

import * as S from './styles';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_InvestigationCatalog,
  GQL_InvestigationCatalogEntryPaginated,
  InvestigationCatalogPaginatedParams,
} from '../../types/investigation';
import Spacer from '../../shared/Spacer';
import { useAuth } from '../../hooks/useAuth';
import { useInvestigationFilters } from '../../hooks/useInvestigationFilters';
import { GQL_SubscriptionResponse } from '../../types/subscription';
import { GQL_UserAssignLimitsResponse } from '../../types/user';
import { GQL_InvestigationStandard } from '../../types/investigationStandard';
import InvestigationListHeader from './InvestigationLibraryHeader';
import AssessmentAssignmentPopup from '../AssessmentAssignmentPopup/AssessmentAssignmentPopup';
import { GQL_ClassResponse } from '../../types/class';
import CustomInvestigationLoading from '../../shared/CustomInvestigationLoading';
import AssessmentCard from '../../shared/AssessmentCard';
import { INVESTIGATION_STANDARDS } from '../../types/standards';

type Props = RouteComponentProps<{
  type: string;
}>;

const PAGE_SIZE = 9;

type FilterFields =
  | 'focus'
  | 'subject'
  | 'grade'
  | 'discipline'
  | 'coreIdea'
  | 'practice'
  | 'standard'
  | 'firstPublishedAt'
  | 'crossCuttingConcept';
export interface IInvestigationLibraryFilter {
  field: FilterFields;
  value: string;
}

const AssessmentLibraryPage: React.FC<Props> = (props) => {
  const { type } = props.match.params;
  const history = useHistory();
  const { user, isAdiAdmin, isAdiSuperAdmin, isSubscriptionUser } = useAuth();
  const [searchText, setSearchText] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useInvestigationFilters<FilterFields>(history);
  const [assignAssessmentData, setAssignAssessmentData] = useState<GQL_InvestigationCatalog | null>();

  const createPayload = () => {
    const payload = {
      isAssessment: true,
      page: currentPage,
      organizationId: user?.subscription?.organizationId,
      pageSize: PAGE_SIZE,
      type: type === 'scienceTexasEdition' ? 'SCIENCE_TEXAS_EDITION' : startCase(type),
    } as InvestigationCatalogPaginatedParams | any;

    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index] as any;

      if (filter.field === 'tab') {
        continue;
      }

      const key = filter.field as any;
      payload[key] = filter.value;
    }

    if (searchText) {
      payload['searchText'] = searchText;
    }

    return payload;
  };

  const { data: userAssignLimits } = useQuery<{
    getUserAssigmentsLimits: GQL_UserAssignLimitsResponse;
  }>(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.getUserAssigmentsLimits, {
    onError: (error) => {
      message.error(`There was an error in fetching user assignment limits: ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: stateStandardsData } = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: 'TX',
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: ngssStandardsData} = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: INVESTIGATION_STANDARDS.NGSS,
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: classesData, loading: loadingClasses } = useQuery<{
    getClasses: GQL_ClassResponse[];
  }>(gqlSchema.ClassSchema.query.CLASS.CLASSES.getClasses, {
    onError: (error) => {
      message.error(
        error.message || 'There was an error loading your classes, check you connection and try again later.',
      );
    },
  });

  const assessmentInvalidReason = useCallback(
    (a: GQL_InvestigationCatalog, subscription?: GQL_SubscriptionResponse) => {
      if (!subscription) return '';
      if (subscription.isTrial && !a.availableForTrial) return 'trial';
      if (
        subscription.allowedDisciplines?.length &&
        a.discipline?.id &&
        !subscription.allowedDisciplines.includes(Number(a.discipline.id))
      )
        return 'disciplines';
      if (
        subscription.allowedGradeBands?.length &&
        a.discipline?.gradeBand &&
        !subscription.allowedGradeBands.includes(a.discipline.gradeBand)
      )
        return 'gradeBands';
      if (
        subscription.allowedSubjects?.length &&
        a.discipline?.subject &&
        !subscription.allowedSubjects.includes(a.discipline.subject)
      )
        return 'subjects';

      return '';
    },
    [],
  );

  const backUrl = '/teacher-assessment';

  // Ensures that a valid url param was passed
  if (type !== 'math' && type !== 'science' && type !== 'engineering' && type !== 'scienceTexasEdition')
    history.push(backUrl);

  const { data, loading, refetch: refetchAssessments} = useQuery<
    {
      getInvestigationCatalogPaginated: GQL_InvestigationCatalogEntryPaginated;
    },
    InvestigationCatalogPaginatedParams
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalogPaginated, {
    variables: createPayload(),
    onError: (err) => {
      message.error('There was an error loading the assessment catalog: ' + err.message || 'Unexpected Error');
    },
    fetchPolicy: 'network-only',
  });

  const getSupportedStateStandard = useCallback(
    (assessment: GQL_InvestigationCatalog) => {
      const standards = user?.organizationAllowedStates?.length
        ? user.organizationAllowedStates
        : user?.userAllowedStates;
      const normalizedStandards = standards?.map((s) => s.postalCode) as string[];

      const baseStandards = [...(assessment.nextGenerationStandards || []), ...(assessment.standards || [])];

      const standardsInv =
        isAdiAdmin || isAdiSuperAdmin
          ? baseStandards
          : baseStandards?.filter((invStandard) => normalizedStandards?.includes(invStandard.state));

      const normalizedStandardsTooltip = standardsInv?.reduce(
        (acc: { state: string; statements: string[] }[], d: GQL_InvestigationStandard) => {
          const found = acc.find((a) => a.state === d.state);
          if (!found) {
            acc.push({ state: d.state, statements: [`${d.code}: ${d.fullStatement}`] });
          } else {
            found.statements.push(`${d.code}: ${d.fullStatement}`);
          }
          return acc;
        },
        [],
      );

      return normalizedStandardsTooltip;
    },
    [isAdiAdmin, isAdiSuperAdmin, user],
  );

  const notFound = useMemo(
    () => (
      <Col offset={8} lg={8} md={12} xs={24}>
        <Result status="warning" subTitle="There are not assessments listed." />
      </Col>
    ),
    [],
  );

  const openAssignAssessmentPopup = (assessment: GQL_InvestigationCatalog): void => {
    setAssignAssessmentData(assessment);
  };

  const isActionDisabled = useCallback(
    (investigationId: string) => {
      if (isSubscriptionUser) {
        // If it's already assigned before (shouldn't count as a new one)
        if (userAssignLimits?.getUserAssigmentsLimits.assessmentsIds.includes(investigationId)) {
          return false;
        }
        // Or if user has unlimited Assessment
        if (userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxAssessments === -1) {
          return false;
        }
      }

      if (
        isSubscriptionUser &&
        userAssignLimits?.getUserAssigmentsLimits.totalAssignes.assessmentsAssigned! >=
          userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxAssessments!
      ) {
        return true;
      }

      return false;
    },
    [isSubscriptionUser, userAssignLimits],
  );

  const skeleton = useMemo(() => <CustomInvestigationLoading isAssessment={true} />, []);

  return (
    <S.PageContainer>
      <Row gutter={[24, 24]}>
        <Col style={{ paddingTop: 0 }} xxl={{ span: 18, offset: 3 }} sm={24}>
          <InvestigationListHeader
            refreshMethod={refetchAssessments}
            backUrl={backUrl}
            filters={filters}
            setFilters={(filters) => {
              setFilters(filters);
              setTimeout(() => {
                refetchAssessments();
              }, 0);
            }}
            updateSearchText={setSearchText}
            stateStandards={stateStandardsData?.getStateStandards}
            ngssStandards={ngssStandardsData?.getStateStandards}
            subjectType={type}
          />
          <Row gutter={[20, 20]}>
            {data?.getInvestigationCatalogPaginated?.list?.length === 0 && !loading && notFound}
            {data?.getInvestigationCatalogPaginated?.list?.map((assessment) => (
              <Col lg={8} md={12} xs={24} key={assessment.id}>
                <AssessmentCard
                  supportedStates={getSupportedStateStandard(assessment)}
                  investigation={assessment}
                  action={() => openAssignAssessmentPopup(assessment)}
                  actionText="Assign"
                  actionDisabled={isActionDisabled(assessment.id)}
                  available={!assessmentInvalidReason(assessment, user?.subscription)}
                  showPreview={true}
                />
              </Col>
            ))}
            {loading && skeleton}
          </Row>
          <Spacer />
          <Row justify="center">
            <Pagination
              total={data?.getInvestigationCatalogPaginated.total}
              pageSize={PAGE_SIZE}
              onChange={(page) => {
                setCurrentPage(page);
                setTimeout(() => {
                  refetchAssessments();
                }, 0);
              }}
              current={currentPage}
              showSizeChanger={false}
            />
          </Row>
        </Col>
      </Row>
      {!loadingClasses && (
        <AssessmentAssignmentPopup
          assessment={assignAssessmentData}
          hidePopup={() => setAssignAssessmentData(null)}
          classes={classesData?.getClasses || []}
        />
      )}
    </S.PageContainer>
  );
};

export default withRouter(AssessmentLibraryPage);
