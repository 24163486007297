import { Popconfirm, Row, Button } from 'antd';
import styled from 'styled-components';
import CommentBox from '../../../shared/CommentBox';

export const CommentWarning = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125.1%;

  text-align: right;

  color: #767676;
  margin-right: 8px;
`;

export const ButtonsRow = styled(Row)`
  margin-top: 16px;
`;

export const Comment = styled(CommentBox)`
  margin-top: 24px;
`;

export const SpinningContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Divider = styled.div`
  height: 1px;
  background: #c4c4c4;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const SubCommentContainer = styled.div`
  margin-bottom: 24px;
`;

export const UnlockPopConfirm = styled(Popconfirm)`
  & .ant-popover-buttons {
    text-align: center !important;
  }
`;

export const UnlockPopConfirmParagraph = styled.p`
  margin: 0;
  text-align: center !important;
`;

export const UnlockSubmissionContainer = styled.span`
  display: inline-flex;
`;

export const UnlockSubmissionButton = styled(Button)<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    color: ${(props) => (props.$disabled ? props.theme.primaryColor.disabled : props.theme.primaryColor.background)};
    cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  }
`;
