import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0 0 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: 32px 40px;

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 129.1%;
    padding: 0 24px;
    text-align: center;
    color: #767676;
  }
`;

export const LogoImage = styled.img`
  height: 100%;
  padding: 8px;
`;
