import React, {  useMemo, } from 'react';
import { Table,  Tabs } from 'antd';
import * as S from './styles';

const { TabPane } = Tabs;
interface Teacher {
  id: number;
  name: string;
  created_at: string;
  sortable_name: string;
  short_name: string;
  sis_user_id: string | null;
  integration_id: string | null;
  sis_import_id: string | null;
  login_id: string;
}
interface Student {
  id: number;
  name: string;
  created_at: string;
  sortable_name: string;
  short_name: string;
  sis_user_id: string | null;
  integration_id: string | null;
  sis_import_id: string | null;
  login_id: string;
}

const StudentsTable = ({ studentsData, teachersData, loading }: { studentsData: any; teachersData: any; loading: boolean }) => {

  const studentColumns = useMemo(() => {
    return [
      {
        title: 'Student Name',
        dataIndex: 'name',
        key: 'name',
      },
    ];
  }, []);

  const teacherColumns = useMemo(() => {
    return [
      {
        title: 'Teacher Name',
        dataIndex: 'name',
        key: 'name',
      },
    ];
  }, []);

  const students = studentsData?.students || [];
  const teachers = teachersData?.teachers || [];
  
  return (
    <>
      <S.Title>Users</S.Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Students Details" key="1">
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
            {/* <Button type="primary" onClick={() => setShowAddStudentsModal(true)}>Add Students</Button> */}
          </div>
          <Table
            columns={studentColumns}
            loading={loading}
            dataSource={students.map((student:Student) => ({
              ...student,
              key: student
            }))}
            pagination={{
              hideOnSinglePage: false,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40'],
            }}
          />
        </TabPane>
        <TabPane tab="Teacher Details" key="2">
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
            {/* <Button type="primary" onClick={() => setShowAddTeachersModal(true)}>Add Teachers</Button> */}
          </div>
          <Table
            columns={teacherColumns}
            loading={loading}
            dataSource={teachers.map((teacher:Teacher) => ({
              ...teacher,
              key: teacher // Use profile.id as the unique key for each teacher
            }))}
            pagination={{
              hideOnSinglePage: false,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40'],
            }}
          />
        </TabPane>
      </Tabs>
      
    </>
  );
};

export default StudentsTable;
