import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import { Col, message, Row, Form as AntdForm, Upload, Popconfirm, Tooltip } from 'antd';
import {
  GQL_UploadWalkthroughVideoResponse,
  GQL_WalkthroughCategory,
  GQL_WalkthroughVideo,
} from '../../types/walkthrough';
import Form from '../../shared/Form';
import { useForm } from 'antd/lib/form/Form';
import * as S from './styles';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { MdCancel, MdOndemandVideo } from 'react-icons/md';
import { Filter } from '../../utils/antd';
import { roles } from '../../utils/roles';
import { themeConfig } from '../../utils/theme';
import { useMemo } from 'react';
import Spacer from '../../shared/Spacer';
import Button from '../../shared/Button';
import { RcFile } from 'antd/lib/upload';
import Switch from '../../shared/Switch';
import Select from '../../shared/Select';
import SelectOption from '../../shared/Select/Option';
import { SelectValue } from 'antd/lib/select';

type RouterProps = RouteComponentProps<{
  id: string;
}>;

const WalkthroughDetailsPage = (props: RouterProps) => {
  const { id } = props.match.params;
  const [form] = useForm();
  const [changeTitleVisible, setChangeTitleVisible] = useState(false);
  const [changeVideoUrlVisible, setChangeVideoUrlVisible] = useState(false);
  const [changeCategoryVisible, setChangeCategoryVisible] = useState(false);
  const [changeRoleVisible, setChangeRoleVisible] = useState(false);
  const history = useHistory();
  const [isVisible, setVisible] = useState(false);
  const [currentRole, setCurrentRole] = useState<string[]>();
  const [currentCategory, setCurrentCategory] = useState('');

  const { data } = useQuery<{ getWalkthroughVideoById: GQL_WalkthroughVideo }, { id: string }>(
    gqlSchema.WalkthroughSchema.queries.VIDEOS.getWalkthroughVideoById,
    {
      variables: { id },
      onError: (err) => {
        message.error('There was an error loading user details: ' + err.message || 'Unexpected Error');
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: categories } = useQuery<{ getWalkthroughCategory: GQL_WalkthroughCategory[] }>(
    gqlSchema.WalkthroughSchema.queries.CATEGORY.getWalkthroughCategory,
    {
      onError: (err) => {
        message.error('There was an error loading user walkthrough categories: ' + err.message || 'Unexpected Error');
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  const handleChangeCategory = useCallback((value: SelectValue) => {
    setCurrentCategory(value as string);
  }, []);
  const handleChangeRole = useCallback((value: SelectValue) => {
    const codes = value as string[];
    setCurrentRole(codes);
  }, []);

  const [submitEditWalkthrough] = useMutation<{ editWalkthroughVideo: GQL_WalkthroughVideo }>(
    gqlSchema.WalkthroughSchema.mutations.EDIT.editWalkthroughVideo,
    {
      onCompleted: () => {
        message.success(`Walkthrough updated successfully`);
      },
      onError: (error) => {
        message.error(error.message);
      },
      update(cache, { data }) {
        const wtv = {
          __typename: 'WalkthroughVideo',
          id: data?.editWalkthroughVideo.id,
        };
        cache.modify({
          id: cache.identify(wtv),
          fields: {
            title() {
              return data?.editWalkthroughVideo.title;
            },
            videoUrl() {
              return data?.editWalkthroughVideo.videoUrl;
            },
            roles() {
              return data?.editWalkthroughVideo.roles;
            },
          },
        });
      },
    },
  );

  const [submitDeleteWalkthrough] = useMutation<{ deleteWalkthroughVideo: boolean }>(
    gqlSchema.WalkthroughSchema.mutations.DELETE.deleteWalkthroughVideo,
    {
      onCompleted: () => {
        message.success(`Walkthrough deleted successfully`);
        history.push('/walkthrough');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitFile, { loading: loadingFile }] = useMutation<
    { uploadWalkthroughVideo: GQL_UploadWalkthroughVideoResponse },
    { file: File; id: string }
  >(gqlSchema.WalkthroughSchema.mutations.EDIT.uploadWalkthroughVideo, {
    onCompleted: (data) => {
      message.success(`Walkthrough video updated successfully`);
    },
    onError: (err) => {
      message.error('There was an error uploading the video: ' + err.message);
    },
    update(cache, { data }) {
      const wtv = {
        __typename: 'WalkthroughVideo',
        id: data?.uploadWalkthroughVideo.id,
      };
      cache.modify({
        id: cache.identify(wtv),
        fields: {
          videoUrl() {
            return data?.uploadWalkthroughVideo.videoUrl;
          },
        },
      });
    },
  });

  const beforeUpload = useCallback(
    (file: RcFile) => {
      if (file) submitFile({ variables: { file, id } });
      return false;
    },
    [submitFile, id],
  );

  const submitUpdate = useCallback(
    (visible: boolean) => {
      const values = form.getFieldsValue();
      submitEditWalkthrough({
        variables: {
          data: {
            id,
            title: values.title,
            videoUrl: values.videoUrl,
            categoryId: currentCategory,
            roles: currentRole,
            isVisible: visible,
          },
        },
      });
    },
    [form, id, submitEditWalkthrough, currentCategory, currentRole],
  );

  const handleEditingButton = useCallback(
    (visibility: boolean, setVisibility?: Dispatch<SetStateAction<boolean>>) => {
      if (visibility) submitUpdate(isVisible);
      if (setVisibility) setVisibility(!visibility);
    },
    [submitUpdate, isVisible],
  );

  const renderRole = useMemo(() => {
    if (!data?.getWalkthroughVideoById?.roles) return null;
    const roleItem = roles.find((f: Filter) => f.value === data?.getWalkthroughVideoById?.roles[0]);
    return (
      <>
        <S.TagButton
          text={roleItem?.text || ''}
          display="inline"
          background={roleItem?.color}
          shape="round"
          key={roleItem?.value}
          minHeight={24}
        />
        {data?.getWalkthroughVideoById?.roles.length > 1 && (
          <Tooltip
            title={data?.getWalkthroughVideoById?.roles
              .filter((i) => i !== roleItem?.value)
              .map((i) => {
                const r = roles.find((f: Filter) => f.value === i);
                return (
                  <p style={{ marginBottom: 5 }} key={r?.value}>
                    {r?.text}
                  </p>
                );
              })}
          >
            <S.TagButton text={`+${data?.getWalkthroughVideoById?.roles.length - 1}`} shape="round" minHeight={24} />
          </Tooltip>
        )}
      </>
    );
  }, [data]);

  useEffect(() => {
    if (data?.getWalkthroughVideoById) {
      form.setFieldsValue({
        title: data?.getWalkthroughVideoById.title,
        videoUrl: data?.getWalkthroughVideoById.videoUrl,
      });
      setCurrentCategory(data?.getWalkthroughVideoById.category.id);
      setCurrentRole(data?.getWalkthroughVideoById?.roles.map((p) => p));
      setVisible(data?.getWalkthroughVideoById.isVisible);
    }
  }, [form, data]);

  const handleAcceptDeleteVideo = useCallback(() => {
    submitDeleteWalkthrough({ variables: { id: data?.getWalkthroughVideoById.id } });
  }, [data, submitDeleteWalkthrough]);

  return (
    <PageWithTitle
      title={data?.getWalkthroughVideoById ? `Walkthrough: ${data.getWalkthroughVideoById.title}` : 'Loading...'}
      extra={
        <Row justify="end">
          <Col
            xxl={{ span: 16 }}
            xl={{ span: 16 }}
            lg={{ span: 14 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Popconfirm
              title="Are you sure you want to delete this video?"
              placement="bottom"
              onConfirm={handleAcceptDeleteVideo}
            >
              <Button
                text="Delete Video"
                style={{ marginLeft: 'auto' }}
                block
                background={themeConfig.error.background}
              />
            </Popconfirm>
          </Col>
        </Row>
      }
    >
      <S.Body span={24}>
        <Form form={form}>
          <Row gutter={24} style={{ padding: 20 }}>
            <S.Line md={24} lg={7}>
              <S.TitleInput>Title</S.TitleInput>
              <Row gutter={8}>
                <Col span={18}>
                  <AntdForm.Item name="title">
                    <S.SInput disabled={!changeTitleVisible} bordered={changeTitleVisible} />
                  </AntdForm.Item>
                </Col>
                <Col span={changeTitleVisible ? 3 : 6}>
                  <S.EditButton
                    style={{ float: 'right' }}
                    type="primary"
                    shape="circle"
                    icon={changeTitleVisible ? <BiCheck /> : <BiEdit />}
                    onClick={() => handleEditingButton(changeTitleVisible, setChangeTitleVisible)}
                  />
                </Col>
                {changeTitleVisible && (
                  <Col span={3}>
                    <S.EditButton
                      style={{ float: 'right' }}
                      type="primary"
                      shape="circle"
                      icon={<MdCancel />}
                      onClick={() => setChangeTitleVisible(false)}
                    />
                  </Col>
                )}
              </Row>
            </S.Line>
            <S.Line md={24} lg={7}>
              <S.TitleInput>Role</S.TitleInput>
              <Row gutter={8}>
                <Col span={18}>
                  {!changeRoleVisible ? (
                    renderRole
                  ) : (
                    <Select
                      placeholder="Roles"
                      optionLabelProp="label"
                      showSearch={true}
                      dropdownMatchSelectWidth={false}
                      maxTagTextLength={1}
                      mode="multiple"
                      showArrow
                      value={currentRole}
                      onChange={handleChangeRole}
                    >
                      {roles.map((roleItem) => {
                        return (
                          <SelectOption value={roleItem?.value || ''} label={roleItem?.text} key={roleItem?.value}>
                            {roleItem?.text}
                          </SelectOption>
                        );
                      })}
                    </Select>
                  )}
                </Col>
                <Col span={changeRoleVisible ? 3 : 6}>
                  <S.EditButton
                    style={{ float: 'right' }}
                    type="primary"
                    shape="circle"
                    icon={changeRoleVisible ? <BiCheck /> : <BiEdit />}
                    onClick={() => handleEditingButton(changeRoleVisible, setChangeRoleVisible)}
                  />
                </Col>
                {changeRoleVisible && (
                  <Col span={3}>
                    <S.EditButton
                      style={{ float: 'right' }}
                      type="primary"
                      shape="circle"
                      icon={<MdCancel />}
                      onClick={() => setChangeRoleVisible(false)}
                    />
                  </Col>
                )}
              </Row>
            </S.Line>
            <S.Line md={24} lg={7}>
              <S.TitleInput>Category</S.TitleInput>
              <Row gutter={8}>
                <Col span={18}>
                  {!changeCategoryVisible ? (
                    <S.TagButton
                      text={data?.getWalkthroughVideoById?.category.title || ''}
                      display="inline"
                      background={themeConfig.secondaryColor.background}
                      shape="round"
                      minHeight={24}
                    />
                  ) : (
                    <Select
                      placeholder="Select a category"
                      value={currentCategory}
                      dropdownMatchSelectWidth={false}
                      onChange={handleChangeCategory}
                    >
                      {categories?.getWalkthroughCategory.map((category) => (
                        <SelectOption value={category.id} key={category.id}>
                          {category.title}
                        </SelectOption>
                      ))}
                    </Select>
                  )}
                </Col>
                <Col span={changeCategoryVisible ? 3 : 6}>
                  <S.EditButton
                    style={{ float: 'right' }}
                    type="primary"
                    shape="circle"
                    icon={changeCategoryVisible ? <BiCheck /> : <BiEdit />}
                    onClick={() => handleEditingButton(changeCategoryVisible, setChangeCategoryVisible)}
                  />
                </Col>
                {changeCategoryVisible && (
                  <Col span={3}>
                    <S.EditButton
                      style={{ float: 'right' }}
                      type="primary"
                      shape="circle"
                      icon={<MdCancel />}
                      onClick={() => setChangeCategoryVisible(false)}
                    />
                  </Col>
                )}
              </Row>
            </S.Line>
            <S.Line md={24} lg={3}>
              <Switch
                type="grid"
                title={<S.TitleInput $padding="0">Visible</S.TitleInput>}
                checked={isVisible}
                value={isVisible}
                onChange={() => {
                  submitUpdate(!isVisible);
                  setVisible(!isVisible);
                }}
                titleAlign="left"
              />
            </S.Line>

            <S.CDivider />
          </Row>
          <Row gutter={24} style={{ padding: '0 20px' }}>
            <S.Line span={24}>
              <S.TitleInput>Video Url</S.TitleInput>
              <Row gutter={7} align="middle">
                <Col span={22}>
                  <AntdForm.Item name="videoUrl">
                    <S.SInput disabled={!changeVideoUrlVisible} bordered={changeVideoUrlVisible} />
                  </AntdForm.Item>
                </Col>
                <Col span={changeVideoUrlVisible ? 1 : 2}>
                  <S.EditButton
                    style={{ float: 'right' }}
                    type="primary"
                    shape="circle"
                    icon={changeVideoUrlVisible ? <BiCheck /> : <BiEdit />}
                    onClick={() => handleEditingButton(changeVideoUrlVisible, setChangeVideoUrlVisible)}
                  />
                </Col>
                {changeVideoUrlVisible && (
                  <Col span={1}>
                    <S.EditButton
                      style={{ float: 'right' }}
                      type="primary"
                      shape="circle"
                      icon={<MdCancel />}
                      onClick={() => setChangeVideoUrlVisible(false)}
                    />
                  </Col>
                )}
              </Row>
            </S.Line>
          </Row>
        </Form>
      </S.Body>
      <Spacer />
      <S.Body span={24}>
        <Row justify="center" style={{ padding: 20 }}>
          <Col span={14}>
            <video src={data?.getWalkthroughVideoById.videoUrl} style={{ width: '100%' }} controls />
          </Col>
        </Row>
        <Row justify="center" style={{ padding: '20px 0' }}>
          <Col xxl={4} xl={4} lg={4} md={6} sm={9} xs={13}>
            <Upload beforeUpload={beforeUpload} accept="video/*" listType="text" showUploadList={false}>
              <Button text="Upload Different Video" loading={loadingFile} block icon={<MdOndemandVideo />} />
            </Upload>
          </Col>
        </Row>
      </S.Body>
    </PageWithTitle>
  );
};

export default withRouter(WalkthroughDetailsPage);
