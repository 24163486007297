import styled from 'styled-components';
import { Col as ColAntd, Row } from 'antd';

export const ContainerSortingBoard = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const TitleInput = styled.p<{ alignRight?: boolean }>`
  font-weight: 800 !important;
  padding: 0;
  ${(props) => props.alignRight && 'text-align: right;'}
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const ContainerDragObject = styled.div<{ beingDragged: boolean; $hasTarget: boolean; isImage?: boolean }>`
  ${(props) =>
    props.$hasTarget &&
    `
      opacity: 0;
  `}

  opacity: ${(props) => (props.beingDragged || props.$hasTarget ? '0' : '1')};
  z-index: ${(props) => (props.beingDragged ? '1' : '0')};
  height: ${(props) => (props.isImage ? '150px' : 'auto')};
  min-height: ${(props) => (props.isImage ? '150px' : '36px')};
  width: 80%;
  border-radius: 4px;
  background: white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px auto;
  cursor: ${(props) => (props.beingDragged ? 'grabbing' : 'grab')};

  & span {
    pointer-events: none;
    user-select: none;
  }

  img {
    max-width: 200px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const ContainerDragTarget = styled.div<{
  isOver: boolean;
  $hasTarget: boolean;
  beingDragged: boolean;
  isImage?: boolean;
}>`
  color: ${(props) => (props.beingDragged ? 'red' : 'black')};
  opacity: ${(props) => (props.isOver ? '0.5' : '1')};
  z-index: ${(props) => (props.isOver ? '1' : '0')};
  height: ${(props) => (props.isImage ? '150px' : '36px')};
  width: 100%;
  border-radius: 4px;
  background: ${(props) => (props.$hasTarget ? 'rgba(0, 0, 0, 0.1)' : 'white')};
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
  white-space: initial;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 8px 5px 0;
  cursor: ${(props) => (props.$hasTarget ? 'pointer' : props.isOver ? 'grabbing' : 'grab')};

  & span {
    pointer-events: none;
    user-select: none;
  }

  & img {
    width: 100%;
    height: 100%;
  }
`;

export const ProcessTasksTargetContainer = styled.div<{
  showSuccess?: boolean;
  isCorrectAnswer?: boolean;
  isImage?: boolean;
}>`
  position: relative;

  > ${ContainerDragTarget} {
    z-index: 0;
    height: ${(props) => (props.isImage ? '150px' : 'auto')};
    min-height: ${(props) => (props.isImage ? '150px' : '36px')};
    width: 80%;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    padding: 8px;
    white-space: initial;
    text-align: left;
    cursor: grab;
    margin: 5px auto;
    ${(props) =>
      props.showSuccess
        ? props.isCorrectAnswer
          ? 'background-color: rgb(212, 248, 221); border: 1px solid rgba(32, 188, 137, 1);'
          : 'background-color: rgba(234, 53, 53, 0.1); border: 1px solid rgba(234, 53, 53, 1);'
        : 'background: white;'}
  }

  > ${ContainerDragObject} {
    width: 80%;
    height: 36px;
  }

  &:after {
    border-right: solid 5px transparent;
    border-left: solid 5px transparent;
    border-top: solid 5px grey;
    transform: translateX(-36%);
    content: '';
    top: ${(props) => (props.isImage ? '158px' : '45px')};
    left: 50%;
    height: 0;
    width: 0;
    position: absolute;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const ProcessDragDropContainer = styled.div<{
  $minHeightOptions: number;
  highlighted?: boolean;
  highlightReport?: boolean;
}>`
  min-height: ${(props) => props.$minHeightOptions}px;
  display: flex;
  flex-direction: row;
  align-items: inherit;
`;

export const Container = styled.div<{ highlighted?: boolean; highlightReport?: boolean }>`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;

  ${(props) =>
    props.highlighted
      ? 'box-shadow: 0px 0px 12px rgba(54, 107, 223, 0.79)'
      : 'box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07)'};
  ${(props) => props.highlightReport && 'box-shadow: 0px 0px 12px rgba(234, 53, 53, 0.3);'}
  border-radius: 6px;
  padding: 20px;
  width: 100%;

  & > p {
    font-size: 13px;
    line-height: 1.3;
    margin: 0;
  }
`;

export const ProcessDragDropTaskTitle = styled.h2`
  padding: 0;
  margin: 0 !important;
`;

export const ProcessDragDropContainerTitles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: inherit;
  justify-content: space-around;
  font-size: 0.75rem;
  padding: 0;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const ProcessTasksCol = styled.div<{ showSuccess?: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 50%;
  text-align: center;
  cursor: pointer;
  ${(props) => (props.showSuccess ? (props.success ? 'background: #e6ffe6;' : 'background: #ffe6e6;') : '')}
`;

export const Col = styled(ColAntd)<{ align?: string; justify?: string }>`
  ${(props) =>
    props.align &&
    `
      display: flex;
      justify-content: ${props.align};
  `}

  ${(props) =>
    props.justify &&
    `
      display: flex;
      align-items: ${props.justify};
  `}
`;

export const HeaderContainer = styled(Row)`
  margin: 1.5rem 0;
  width: 100%;

  > ${Col} {
    height: 100%;
  }
`;

export const CommentWarning = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125.1%;

  text-align: right;

  color: #767676;
  margin-right: 8px;
`;

export const ButtonsRow = styled(Row)`
  margin-top: 16px;
`;
