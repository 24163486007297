import { Checkbox } from 'antd';
import React from 'react';
import Editor from '../../../shared/Editor';
import Spacer from '../../../shared/Spacer';
import { ITextContentValue } from '../../../types/investigation';
import { applySpanToContentValueText } from '../../../utils/investigation';
import { QuestionBlock, AnswerBlock, AnswerHead } from './styles';

interface IInvestigationMultipleChoicePictureQuestion {
  value: ITextContentValue;
  isAlreadyHtml?: boolean;
  index?: number;
}

const InvestigationMultipleChoicePictureQuestion = (props: IInvestigationMultipleChoicePictureQuestion) => {
  const { value, isAlreadyHtml, index } = props;
  const text = isAlreadyHtml ? value.text : applySpanToContentValueText(value);

  if (index) {
    return (
      <AnswerBlock>
        <AnswerHead>
          <Checkbox checked={value.targetAnswer} />
          <QuestionBlock>Answer {index}</QuestionBlock>
        </AnswerHead>
        <Editor editable={false} listenToValue value={text} key={text} />
      </AnswerBlock>
    );
  }
  return (
    <>
      <QuestionBlock>Questions</QuestionBlock>
      <Editor editable={false} listenToValue value={text} key={text} />
      <Spacer />
    </>
  );
};

export default InvestigationMultipleChoicePictureQuestion;
