import React, { useEffect, useState } from 'react';
import { Col, Form, message, Modal,  Form as AntdForm } from 'antd';
import * as S from '../styles';
import { gqlSchema } from '../../../../gql/schema';
import { useMutation, useQuery } from '@apollo/client';
import * as SModal from './styles';
import Input from '../../../../shared/Input';
import Button from '../../../../shared/Button';

const UpComingEvent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [screenType,setScreenType] = useState<string>('calender');
  const [eventData, setEventData] = useState<any>(null);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date();

  const { data } = useQuery(gqlSchema.HomeSchema.queries.getEventDetails, {
    
    onError: (error) => {
      console.error(error);
      setScreenType('Error screen');
    },
  });

  const [sendReminder, { loading }] = useMutation(gqlSchema.InvitesSchema.mutations.SEND.sendReminder, {
    onError: (err) => {
      setIsModalVisible(false);

      console.error(`Unexpected Error`);
    },
    onCompleted: () => {
      setIsModalVisible(false);
      message.success(`Event Reminder Subscribed Successfully`);
    },
  });

  useEffect(() => {
    if(data){
        let edata = data.getEventDetails;
        setScreenType('calender');
        edata = edata.sort((a:any, b:any) => Number(a.eventDate) - Number(b.eventDate));
        setSelectedEvent(edata[0]);
        setEventData(edata);

    }
  }, [data ])

  
  
  const handleSendReminder = (eventId: string, userEmail: string, userName: string, userPhone: string) => {
    sendReminder({
      variables: {
        data: {
          eventId:selectedEvent.id,
          userEmail,
          userName,
          userPhone,
        },
      },
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleNextEventMonth = () => {
    if (eventData && eventData.length > 0) {
      const nextEvent = eventData.find((event: any) => {
        const eventDate = new Date(event.eventDate);
        return eventDate > currentMonth && (eventDate.getMonth() !== currentMonth.getMonth() || eventDate.getFullYear() !== currentMonth.getFullYear());
      });
      if (nextEvent) {
        setCurrentMonth(new Date(nextEvent.eventDate));
      }
    }
  };


  console.log('Here Event Details', eventData, loading, setSelectedEvent);
  if(screenType === 'No upcoming events'){
    return (
    <>
    No Upcoming Events
    </>)
  }
  if(screenType === 'Error screen'){
    return (<>
    Some Error Occured. Please Retry.
    
    </>)
  }
  return (
    <S.Card>
      <S.ImageContainer>
        <S.StyledCalendar
          fullscreen={false}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const monthName = value.toDate().toLocaleString('default', { month: 'long' });
            const year = value.year();
            return (
              <div className="ant-picker-calendar-header" style={{ backgroundColor: '#4367E9' }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', gap: '12rem' }}>
                  <span style={{ display: 'flex', flexWrap: 'nowrap', gap: '4px' }}>
                    {' '}
                    <span>{monthName}</span> <span>{year}</span>{' '}
                  </span>
                  <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '4px' }}>
                    <button
                      type="button"
                      onClick={() => onChange(value.clone().subtract(1, 'month'))}
                      className="ant-picker-calendar-prev-icon"
                    >
                      &lt;
                    </button>
                    <button
                      type="button"
                      onClick={() => onChange(value.clone().add(1, 'month'))}
                      className="ant-picker-calendar-next-icon"
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </S.ImageContainer>
      <S.HeaderContent>
        <S.Title>{selectedEvent?.title}</S.Title>
        <S.Subtitle>Cast {currentYear}</S.Subtitle>
        <p>
          {selectedEvent?.description}
        </p>
          <S.Button onClick={() => setIsModalVisible(true)}>Send me a Reminder</S.Button>
      </S.HeaderContent>
      {
        <UserDetailModal
          visible={isModalVisible}
          handleCloseModal={() => setIsModalVisible(false)}
          handleSendReminder={(data: any) =>
            handleSendReminder(
              selectedEvent || 'd0182495-905d-4c94-abba-51924ab4ce4b',
              data.userEmail,
              data.userName,
              data.userPhone,
            )
          }
        />
      }
    </S.Card>
  );
};
interface UserDetailModalInterface {
  visible: boolean;
  handleCloseModal: () => void;
  handleSendReminder: (data: any) => void;
}
const UserDetailModal: React.FC<UserDetailModalInterface> = ({ visible, handleCloseModal, handleSendReminder }) => {
  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null} width={500}>
      <SModal.Container data-cy="components-loginpage-container">
        <SModal.RowSP justify="center" align="middle">
          <SModal.ColSP xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
            <SModal.Title>Tell Us about yourself</SModal.Title>
            <SModal.RowForm gutter={16} align="middle">
              <Col span={10} xs={24} md={24} xl={10} lg={10}>
                <Form onFinish={(val) => handleSendReminder(val)} layout="vertical">
                  <AntdForm.Item
                    label="Email"
                    name="userEmail"
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: 'Please enter a valid email address',
                      },
                    ]}
                  >
                    <Input placeholder="Type your email here..." />
                  </AntdForm.Item>
                  <AntdForm.Item label="Name" name="userName">
                    <Input placeholder="Type your name here..." type="text" />
                  </AntdForm.Item>
                  <AntdForm.Item label="Phone Number" name="userPhone">
                    <Input placeholder="Type your phone number here..." type="text" />
                  </AntdForm.Item>

                  <AntdForm.Item>
                    <Button
                      data-cy="components-loginpage-login-button"
                      text="Submit"
                      htmlType="submit"
                      block
                      style={{
                        width: '100%',
                        margin: '0.5em auto 0',
                        textAlign: 'center',
                        display: 'block',
                      }}
                    />
                  </AntdForm.Item>
                </Form>
              </Col>
            </SModal.RowForm>
          </SModal.ColSP>
        </SModal.RowSP>
      </SModal.Container>
    </Modal>
  );
};

export default UpComingEvent;
function setCurrentMonth(arg0: Date) {
  throw new Error('Function not implemented.');
}

