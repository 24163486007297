import { Col, Radio, Rate, Row, Tooltip } from 'antd';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import { HiOutlineInformationCircle } from 'react-icons/hi';

import { IQuestionnaire } from '../../../redux/modules/investigation';
import Button from '../../../shared/Button';
import Editor from '../../../shared/Editor';
import Spacer from '../../../shared/Spacer';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';

interface IInvestigationQuestionnaire {
  questionnaire: IQuestionnaire;
  hidePopoverGradeSystem?: boolean;
  paginate?: boolean;
  disableAnswers?: boolean;
  hideExtraFieldIfBlank?: boolean;
  onLastQuestion?: () => void;
  onSelectAnswer?: (questionIndex: number, subquestionIndex: number, answerIndex: number) => void;
  saveFeedback?: (questionIndex: number, feedbackList: string[], rating?: number) => void;
  onFeedbackChange?: (questionIndex: number, feedback: string) => void;
  finishButtonText?: string;
  loading?: boolean;
  editorKey?: string;
  hideQuestionNumber?: boolean;
  optionsAsRating?: boolean;
  defaultOptions?: {
    value: string;
    text: string;
    tooltip: string;
  }[];
}

const InvestigationQuestionnaire = (props: IInvestigationQuestionnaire) => {
  const {
    questionnaire,
    editorKey,
    paginate = false,
    onLastQuestion,
    finishButtonText,
    disableAnswers,
    onSelectAnswer,
    hideExtraFieldIfBlank,
    loading,
    defaultOptions,
    saveFeedback = () => null,
    onFeedbackChange = () => null,
    hideQuestionNumber = false,
    hidePopoverGradeSystem = true,
    optionsAsRating,
  } = props;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [feedbackList, setFeedbackList] = useState<string[]>([]);
  const [rating, setRating] = useState<number | undefined>(questionnaire.rating);

  // List of optimistic answers
  const [newAnswers, setNewAnswers] = useState<number[]>([]);

  const defaultFeedbackTitle =
    'Give the author some advice about what they can do to improve this part of their report.';

  const isOnLastQuestion = currentQuestionIndex === questionnaire.questions.length - 1;

  const handleQuestionChange = () => {
    if (!disableAnswers) {
      saveFeedback(currentQuestionIndex, feedbackList, rating);
      setNewAnswers([]);
    }
  };

  const handleNextQuestion = () => {
    handleQuestionChange();
    if (!isOnLastQuestion) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (onLastQuestion) {
      onLastQuestion();
    }
  };

  const handlePreviousQuestion = () => {
    handleQuestionChange();
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleSelectRadioOption = (questionIndex: number, subquestionIndex: number, answerIndex: number) => {
    if (onSelectAnswer) {
      const currentAnswers = [...newAnswers];
      currentAnswers[subquestionIndex] = answerIndex;
      onSelectAnswer(questionIndex, subquestionIndex, answerIndex);
    }
  };

  const defaultQuestions = defaultOptions || [
    {
      value: 'no',
      text: 'No',
      tooltip: '',
    },
    {
      value: 'somewhat',
      text: 'Somewhat',
      tooltip: '',
    },
    {
      value: 'yes',
      text: 'Yes',
      tooltip: '',
    },
  ];

  const currentQuestionnaire = questionnaire.questions.slice(
    paginate ? currentQuestionIndex : 0,
    paginate ? currentQuestionIndex + 1 : questionnaire.questions.length,
  );

  const changeFeedback = debounce((v, questionIndex) => {
    let newFeedback = [...feedbackList];
    newFeedback[questionIndex] = v.isEditorEmpty ? '' : v.value;
    setFeedbackList(newFeedback);
    onFeedbackChange(questionIndex, v.isEditorEmpty ? '' : v.value);
  }, 300);

  return (
    <>
      <S.Column span={24}>
        {currentQuestionnaire.map((question, i) => {
          return (
            <S.QuestionContainer key={i}>
              <Row align="middle" justify="space-between" style={{ marginBottom: 4 }}>
                {!hideQuestionNumber && (
                  <S.QuestionTitle>Question {paginate ? currentQuestionIndex + 1 : i + 1}</S.QuestionTitle>
                )}

                {!hidePopoverGradeSystem && (
                  <S.GradeSystemPopover
                    placement="left"
                    trigger="hover"
                    title="How our grading system works"
                    content={
                      <Row>
                        <S.GradeSystemUL>
                          <S.GradeSystemLI>
                            <HiOutlineInformationCircle size="18px" />
                            Grade 0 = Missing (no credit or 0/2 points)
                          </S.GradeSystemLI>
                          <S.GradeSystemLI>
                            <HiOutlineInformationCircle size="18px" />
                            Grade 1 = Approaching performance expectation (partial credit or 1/2 points)
                          </S.GradeSystemLI>
                          <S.GradeSystemLI>
                            <HiOutlineInformationCircle size="18px" />
                            Grade 2 - Meets performance expectation (full credit or 2/2 points)
                          </S.GradeSystemLI>
                          <S.GradeSystemLI>
                            <HiOutlineInformationCircle size="18px" />
                            Grade 3 = Exceeds performance expectation (extra credit or 3/2 points)
                          </S.GradeSystemLI>
                        </S.GradeSystemUL>
                      </Row>
                    }
                  >
                    <Row align="middle" style={{ cursor: 'pointer' }}>
                      <FiInfo size="20px" />
                      <S.GradeSystemText>How our grading system works</S.GradeSystemText>
                    </Row>
                  </S.GradeSystemPopover>
                )}
              </Row>
              <S.QuestionTitle className="mb-20">{question.title}</S.QuestionTitle>
              {question.subquestions.map((subquestion, subQuestionIndex) => {
                const answers = subquestion.options || defaultQuestions;
                const optimisticIndex = newAnswers[subQuestionIndex];
                const optimisticAnswer = typeof optimisticIndex === 'number' ? answers[optimisticIndex]?.value : null;

                return (
                  <S.RowSubQuestion $isValid={subquestion.isValid} key={subquestion.text + subQuestionIndex}>
                    <Col span={14}>
                      <S.SubQuestionText>{subquestion.text}</S.SubQuestionText>
                    </Col>
                    <S.RadioColumn span={10}>
                      {!optionsAsRating ? (
                        <Radio.Group
                          value={
                            optimisticAnswer ??
                            (typeof subquestion.answer === 'number' && answers[subquestion.answer]?.value)
                          }
                          disabled={disableAnswers || loading}
                          onChange={(e) =>
                            handleSelectRadioOption(
                              paginate ? currentQuestionIndex : i,
                              subQuestionIndex,
                              answers.findIndex((q) => q.value === e.target.value),
                            )
                          }
                        >
                          {answers.map((option, optionIndex) => (
                            <Tooltip
                              key={`${editorKey}${currentQuestionIndex}ov:${option.value}oI:${optionIndex}`}
                              title={option.tooltip}
                            >
                              <Radio
                                data-cy={`studentinvestigation-questionnaire-radio-${i}`}
                                value={option.value}
                                key={option.value}
                              >
                                {option.text}
                              </Radio>
                            </Tooltip>
                          ))}
                        </Radio.Group>
                      ) : (
                        <Rate
                          disabled={disableAnswers || loading}
                          value={subquestion.answer}
                          onChange={(e) =>
                            handleSelectRadioOption(paginate ? currentQuestionIndex : i, subQuestionIndex, e)
                          }
                          allowClear={false}
                        />
                      )}
                    </S.RadioColumn>
                  </S.RowSubQuestion>
                );
              })}
              {((question.needsFeedback && !hideExtraFieldIfBlank) || (hideExtraFieldIfBlank && question.feedback)) && (
                <>
                  {question.subquestions.length > 0 && (
                    <S.QuestionTitle className="mt-15 mb-20">
                      {question.feedbackTitle === undefined ? defaultFeedbackTitle : question.feedbackTitle}
                    </S.QuestionTitle>
                  )}
                  <Editor
                    key={`${editorKey}${currentQuestionIndex}`}
                    placeholder="Write Your Comment Here..."
                    value={question.feedback}
                    onChange={(v) => {
                      changeFeedback(v, paginate ? currentQuestionIndex : i);
                    }}
                    editable={!disableAnswers}
                  />
                </>
              )}
              {question.includeRating && (
                <>
                  <Spacer />
                  <S.QuestionTitle>Rate the quality or usefulness of the review you received</S.QuestionTitle>
                  <Rate
                    disabled={disableAnswers}
                    value={questionnaire.rating || rating}
                    onChange={setRating}
                    allowClear={false}
                  />
                </>
              )}
            </S.QuestionContainer>
          );
        })}
      </S.Column>
      {paginate && (
        <>
          <Spacer />
          <S.ButtonsContainer>
            {currentQuestionIndex > 0 && (
              <>
                <Button
                  text="Previous Question"
                  onClick={handlePreviousQuestion}
                  theme={themeConfig.secondaryOutlined}
                  block
                  minHeight={40}
                />
                <Spacer axis="horizontal" size={32} />
              </>
            )}
            {(!isOnLastQuestion || (isOnLastQuestion && onLastQuestion)) && (
              <Button
                text={isOnLastQuestion ? finishButtonText || 'Finish Questionnaire' : 'Next Question'}
                disabled={
                  loading ||
                  (isOnLastQuestion && disableAnswers) ||
                  (!disableAnswers &&
                    currentQuestionnaire?.some((question) =>
                      question.subquestions.some((subQuestion) => typeof subQuestion.answer !== 'number'),
                    )) ||
                  (currentQuestionnaire[paginate ? 0 : currentQuestionIndex]?.includeRating &&
                    typeof rating !== 'number')
                }
                onClick={handleNextQuestion}
                block
                minHeight={40}
              />
            )}
          </S.ButtonsContainer>
        </>
      )}
    </>
  );
};

export default InvestigationQuestionnaire;
