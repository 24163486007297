import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 4em;

  .ant-skeleton-input {
    width: 250px;
    border-radius: 4px;
  }

  .ant-skeleton-input-sm {
    width: 120px;
    height: 16px;
  }

  .ant-skeleton-input-lg {
    width: 280px;
  }

  .ant-skeleton-avatar {
    height: 92px;
    width: 92px;
  }

  .ant-skeleton-button {
    height: 40px;
    width: 180px;
  }
`;

export const CardContainer = styled.div`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;
  padding: 32px 40px;
  text-align center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: white;
  position: relative;
  height: 100%;

  & > span {
    position: absolute;
    right: 16px;
    top: 12px;
    max-width: 40%;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-align: right;

    color: #767676;
  }

  & > h2 {
    margin-bottom: 12px;
    font-size: 22px;
    line-height: 1.2;
  }

  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 129.1%;

    color: #767676;
    margin-bottom: 16px;
  }

  & > button {
    margin-top: 16px;
  }
`;
