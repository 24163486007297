import { Col, Dropdown, Menu, Row, Input as AntdInput, Button } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { startCase } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { FiChevronDown, FiFilter, FiSearch, FiX } from 'react-icons/fi';
import { ITemplateFilters } from '..';
import Input from '../../../shared/Input';

import * as S from './styles';

interface ITemplatesHeader {
  updateSearchText: (text: string) => void;
  refreshMethod: () => void;
  filters: ITemplateFilters[];
  setFilters: (filters: ITemplateFilters[]) => void;
  title: string;
}

const TemplatesHeader = (props: ITemplatesHeader) => {
  const { filters, setFilters, title, refreshMethod } = props;
  const inputRef = useRef<AntdInput>();
  const [loading, setLoading] = useState(false);

  const handleInputSearch = useCallback(() => {
    props.updateSearchText(inputRef?.current?.state?.value);
  }, [inputRef, props]);

  const setNewFilter = (filter: ITemplateFilters) => {
    setFilters([...filters.filter((f) => f.field !== filter.field), filter]);
  };

  const removeFilterChip = (filter: ITemplateFilters) => {
    setFilters(filters.filter((f) => f.value !== filter.value));
  };

  // const handleSearchAction = () => {
  //   setFilters(filtersBeforeFetch)
  // }
  const handleRefresh = async () => {
    try {
      setLoading(true);
      if (refreshMethod) {
        await refreshMethod();
      }
    } catch (error) {
      console.error('Error while refetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <S.HeaderContainer onClick={(e) => e.stopPropagation()} gutter={24}>
      <Col span={10}>
        <Input
          ref={inputRef}
          icon={<FiSearch />}
          placeholder={`Search ${title}`}
          backgroundColor="#FFFFFF"
          onPressEnter={handleInputSearch}
        />
      </Col>
      <Col span={10}>
        <Dropdown
          overlay={
            <Menu>
              <SubMenu title="Subject">
                <Menu.Item
                  disabled={filters.some((filter) => filter.value === 'Math')}
                  onClick={() =>
                    setNewFilter({
                      field: 'subject',
                      value: 'Math',
                    })
                  }
                >
                  Math
                </Menu.Item>
                <Menu.Item
                  disabled={filters.some((filter) => filter.value === 'Science')}
                  onClick={() =>
                    setNewFilter({
                      field: 'subject',
                      value: 'Science',
                    })
                  }
                >
                  Science
                </Menu.Item>
                <Menu.Item
                  disabled={filters.some((filter) => filter.value === 'Engineering')}
                  onClick={() =>
                    setNewFilter({
                      field: 'subject',
                      value: 'Engineering',
                    })
                  }
                >
                  Engineering
                </Menu.Item>
                <Menu.Item
                  disabled={filters.some((filter) => filter.value === 'Science:_Texas_Edition_(2024_Adoption)')}
                  onClick={() =>
                    setNewFilter({
                      field: 'subject',
                      value: 'Science:_Texas_Edition_(2024_Adoption)',
                    })
                  }
                >
                  Science: Texas Edition (2024 Adoption)
                </Menu.Item>
              </SubMenu>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <S.FilterContainer>
            <FiFilter />
            <span role="none" >Filter By</span>
            <Row>
              {filters.map((filter) => (
                <Col sm={12} md={24 / filters.length} key={filter.value}>
                  <S.FilterChip
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFilterChip(filter);
                    }}
                  >
                    <span role="none" >{startCase(filter.value)}</span>
                    <FiX />
                  </S.FilterChip>
                </Col>
              ))}
            </Row>
            <FiChevronDown />
          </S.FilterContainer>
        </Dropdown>
      </Col>
      <Col md={4} sm={3} style={{ borderRadius: "1,4", display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
        {/* <Button type="primary" onClick={handleSearchAction}>Search</Button> */}
        <Button type='primary' loading={loading} onClick={handleRefresh}>Refresh</Button>
      </Col>
    </S.HeaderContainer>
  );
};

export default TemplatesHeader;
