import { gql } from '@apollo/client';

export const WALKTHROUGH_MUTATIONS = {
  ADD: {
    createWalkthroughVideo: gql`
      mutation CreateWalkthroughVideo($file: Upload, $data: EditWalkthroughInput!) {
        createWalkthroughVideo(file: $file, data: $data) {
          id
          title
          roles
          code
          videoUrl
          isVisible
          category {
            id
            title
          }
        }
      }
    `,
  },
  EDIT: {
    editWalkthroughVideo: gql`
      mutation EditWalkthroughVideo($data: EditWalkthroughInput!) {
        editWalkthroughVideo(data: $data) {
          id
          title
          code
          videoUrl
          isVisible
          category {
            id
            title
          }
        }
      }
    `,
    uploadWalkthroughVideo: gql`
      mutation UploadWalkthroughVideo($file: Upload!, $id: String!) {
        uploadWalkthroughVideo(file: $file, id: $id) {
          id
          videoUrl
        }
      }
    `,
  },
  DELETE: {
    deleteWalkthroughVideo: gql`
      mutation DeleteWalkthroughVideo($id: String!) {
        deleteWalkthroughVideo(id: $id)
      }
    `,
  },
};

export default WALKTHROUGH_MUTATIONS;
