import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Row } from 'antd';
import Button from '../Button';
import * as S from './styles';
import TagInput from '../TagInput';
import { TagResponse } from '../../types/tags';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  title: string;
  lineInfo1: string;
  lineInfo2?: string;
  deleteButtonTitle: string;
  loading: boolean;
  inputTitle?: string;
  actionFn: (payload: { tags: TagResponse[] }) => void;
}

const TagsModal: React.FC<Props> = (props) => {
  const { visible, setVisible, title, lineInfo1, lineInfo2, deleteButtonTitle, loading, inputTitle, actionFn } = props;
  const [tagsValues, setTagValues] = useState<TagResponse[]>([]);
  const onCloseModal = () => {
    setVisible(false);
  };

  const onAddTag = () => {
    actionFn({ tags: tagsValues });
  };

  return (
    <S.CModal
      visible={visible}
      maskClosable={false}
      destroyOnClose
      title={
        <Row>
          <Col span={24}>
            <S.Title>{title} </S.Title>
          </Col>
        </Row>
      }
      onCancel={onCloseModal}
      width={800}
      footer={null}
    >
      <Row justify="center">
        <Col span={16}>
          <S.Info>{lineInfo1}</S.Info>
          {lineInfo2 && <S.Info>{lineInfo2}</S.Info>}
        </Col>
      </Row>
      <Row gutter={16} justify="center" style={{ margin: '50px 0' }}>
        <Col span={11}>
          <S.TitleInput>{inputTitle}</S.TitleInput>
          <TagInput alwaysEditable onChange={setTagValues} />
        </Col>
        <Col span={5}>
          <Button
            block
            loading={loading}
            text={deleteButtonTitle}
            disabled={!tagsValues?.length}
            onClick={onAddTag}
            style={{ bottom: 1, position: 'absolute' }}
          />
        </Col>
      </Row>
    </S.CModal>
  );
};

export default TagsModal;
