import { Row, Col, Divider as antDivider, Input, Button } from 'antd';
import styled from 'styled-components';

export const Container = styled(Row)`
  marging: 4em;
`;

export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 6px;
  padding: 40px;
  display: flex;
  align-items: center;
  .ant-skeleton-element {
    display: block !important;
  }
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const InfoProgress = styled.p`
  font-size: 0.45em;
  margin: 10px 0 0 0;
`;

export const InfoMainHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
  padding: 0 25px;

  h3 {
    white-space: nowrap;
    font-size: 15px;
  }
`;

export const SelectOptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  svg {
    margin-top: 1px;
  }
`;

export const TableNameContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 13px;
  }
`;

export const DisabledText = styled.span`
  color: #ddd;
  display: inline-block;
  width: 50%;
  font-style: italic;
`;

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
  & .ant-table {
    max-height: 250px;
    overflow-y: auto !important;
  }
  & .overdue {
    background: #f44336bf;
  }
`;

export const CDivider = styled(antDivider)`
  margin: 15px 0;
`;

export const MaterialsModalContainer = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125.1%;
    text-align: left;

    color: #767676;
  }

  & > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;

    text-align: center;
  }

  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125.1%;
    width: 280px;
    margin: auto;

    text-align: center;

    color: #767676;
  }
`;

export const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 35px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  text-align: left;
  p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    margin: 0;
  }
`;

export const InfosContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 15px;
  margin-right: 15px;

  p {
    color: #3f3e38;
    font-size: 14px;
  }

  h3 {
    white-space: nowrap;
    font-size: 15px;
  }
`;

export const ColouredSummaryText = styled.p<{ color: string }>`
  font-weight: bold;
  color: ${(props) => props.color} !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Divider = styled.span`
  height: 1px;
  width: 100%;
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ClassChip = styled.span<{ background: string }>`
  background: ${(props) => props.background};
  color: white;
  border-radius: 40px;
  padding: 6px 16px;
`;

export const Chip = styled.span`
  background: #7b8cea;
  color: white;
  order-radius: 5px;
  padding: 5px 9px;
  margin-bottom: 2px;
`;

export const ChipsColumns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const SInput = styled(Input)<{
  $bold?: boolean;
}>`
  font-size: 0.95em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  font-weight: ${(props) => (props.$bold ? '500' : 'unset')};
  .ant-input-suffix {
    display: none;
  }
`;

export const EditButton = styled(Button)`
  background: ${(props) => props.theme.primaryColor.background};
  border-color: ${(props) => props.theme.primaryColor.background};
  line-height: 0;
  display: inline;
  margin: 0 3px;
  min-height: 32px;
  svg {
    font-size: 20px;
  }
  &:hover,
  &:active,
  &:focus {
    background: ${(props) => props.theme.primaryColor.background};
    border-color: ${(props) => props.theme.primaryColor.background};
  }
`;

export const CancelButton = styled(EditButton)``;
