import React, { useCallback } from 'react';
import { Form as AntdForm, message, Typography, Col, Form } from 'antd';
import * as S from './styles';
import Input from '../../../shared/Input';

import Button from '../../../shared/Button';
import { Link, RouteComponentProps,  useHistory,  withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
// import { useAuth } from '../../../hooks/useAuth';
import adiLearningHubSrc from '../../../assets/adi-learning-hub.svg';

type Props = RouteComponentProps<null, any, any>;

const BookSignUp = (props: Props) => {
  const history  = useHistory()
  
  // const { setSignedIn } = useAuth();

  const [createBookUser, { loading }] = useMutation(gqlSchema.BookSchema.mutations.CreateBookUser, {
    onCompleted: () => {
      // setSignedIn(true);
      props.history.push('/bookLogin');
      message.success("User Signed up successfully!");
        setTimeout(()=>{
          history.push('/bookLogin')
          
        },2000);
      
    },
    onError: (error) => {
      console.error(error);
      message.error(<Typography.Text data-cy="components-booksignup-error-text">{error.message}</Typography.Text>);
    },
  });

  const onSubmitForm = useCallback(
    (values) => {
      const payload = {
        firstName : values.name.split(" ")[0],
        lastName :values.name.split(" ")[1] || '',
        email: values.email,
        password: values.password,
      };
     
      const metadata = {...values};
      delete metadata.password;
      delete metadata.email;
      delete metadata.name;

      createBookUser({
        variables: {
          data: {
          ...payload,
          metadata: JSON.stringify(metadata),
          //TODO add existing metadata
          },
        },
      });
    },
    [createBookUser]
  );

  return (
    <S.Container data-cy="components-booksignup-container">
      <S.RowSP justify="center" align="middle">
        <S.ColSP xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          {/* <S.Title>Thank you for Book Purchase</S.Title> */}
          <S.Title>Create An Account</S.Title>
          <S.RowForm gutter={16} align="middle">
            <Col span={10} xs={24} md={24} xl={10} lg={10}>
              <Form onFinish={onSubmitForm} layout="vertical">
                <AntdForm.Item
                  label="Name"
                  name="name"
                  // rules={[{ required: true, message: '-- is required' }]}
                >
                  <Input placeholder="" type="text" />
                </AntdForm.Item>
                <AntdForm.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                  ]}
                >
                  <Input placeholder="Type your email here..." />
                </AntdForm.Item>
                <AntdForm.Item
                  label="Job Description"
                  name="jobDescription"
                >
                  <Input placeholder="" type="text" />
                </AntdForm.Item>
                <AntdForm.Item
                  label="State"
                  name="state"
                >
                  <Input placeholder="" type="text" />
                </AntdForm.Item>
                <AntdForm.Item
                  label="Country"
                  name="country"
                >
                  <Input placeholder="" type="text" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Create An Password"
                  name="password"
                  rules={[{ required: true, message: 'Password is required' }]}
                >
                  <Input placeholder="Type your password here..." type="password" />
                </AntdForm.Item>
                <AntdForm.Item
                  label="City"
                  name="city"
                >
                  <Input
                    type="text"
                  />
                </AntdForm.Item>
                <AntdForm.Item
                  label="ZipCode"
                  name="zipCode"
                >
                  <Input
                    type="number"
                  />
                </AntdForm.Item>
                <AntdForm.Item>
                  <Button
                    data-cy="components-booksignuppage-signup-button"
                    text="Submit"
                    htmlType="submit"
                    loading={loading}
                    block
                    style={{
                      width: '50%',
                      margin: '0.5em auto 0',
                      textAlign: 'center',
                      display: 'block',
                    }}
                  />
                </AntdForm.Item>
                <Typography.Text>
                  Already have an account? <Link to="/bookLogin">Login</Link>
                </Typography.Text>
              </Form>
            </Col>
          </S.RowForm>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );
};

export default withRouter(BookSignUp);
