import React, { useMemo } from 'react';
import { Row, Col, Empty } from 'antd';
import startCase from 'lodash/startCase';
import DashboardRow from '../../DashboardRow';
import Item from './Item';
import { GQL_InvestigationDashboard } from '../../../types/investigation';
import { getDateDiff } from '../../../utils/date';
import AssessmentIcon from '../../InvestigationIcon';
import DashboardLoadingCard from '../../DashboardLoadingCard';
import { isAssessmentNotYetOpened } from '../../../utils/investigation';

interface IActiveAssessmentsRow {
  assessments?: GQL_InvestigationDashboard[];
  loading: boolean;
  error?: string;
}

const ActiveAssessmentsRow = (props: IActiveAssessmentsRow) => {
  const { assessments, loading, error } = props;

  const formatTimeLeftString = (date: number): string => {
    const diff = getDateDiff(date, Date.now());
    if (diff.days === 1) return '1 day';
    else if (diff.days === 0) {
      if (diff.hours > 0) return diff.hours + (diff.hours === 1 ? ' hour' : ' hours');
      else {
        return diff.minutes + (diff.minutes === 1 ? ' minute' : ' minutes');
      }
    } else {
      return diff.days + ' days';
    }
  };

  const activeAssessments = useMemo(() => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
        </Row>
      );
    } else if (!assessments?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Assessments" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {assessments.map((item) => {
            const notYetOpen = isAssessmentNotYetOpened(item);
            const dueDate = item.classStudent?.endDate || item.dueDate;
            return (
              <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
                <Item
                  description={item.description}
                  progress={item.completion}
                  parts={`${item.steps[0].activities?.filter((a) => a.completed).length}/${
                    item.steps[0].activities?.length
                  }`}
                  partsCompleted={item.steps[0].activities?.filter((a) => a.completed).length}
                  timeLeft={formatTimeLeftString(dueDate)}
                  assessmentName={`${startCase(item.discipline?.name?.toLowerCase())}: ${item.title}`}
                  type={'Assessment'}
                  assessmentId={item.id}
                  timeWarning={getDateDiff(dueDate, Date.now()).days === 0}
                  icon={
                    <AssessmentIcon subject={item.discipline?.subject || ''} discipline={item.discipline?.name || ''} />
                  }
                  disabled={notYetOpen}
                  timeToOpen={
                    notYetOpen && item.classStudent?.startDate
                      ? formatTimeLeftString(item.classStudent?.startDate)
                      : undefined
                  }
                  version={item.submissionVersion}
                />
              </Col>
            );
          })}
        </Row>
      );
    }
  }, [assessments, loading]);

  return <DashboardRow title="Current Assessments" items={activeAssessments} error={error} />;
};

export default ActiveAssessmentsRow;
