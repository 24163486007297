import { Modal } from 'antd';
import styled from 'styled-components';
import Button from '../../../shared/Button';

export const Container = styled.div`
  padding: 4em;
`;

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const SearchIcon = styled.span<{
  $searchVisible: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 10px 4px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => (props.$searchVisible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px' : 'unset')};
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 2px;
`;

export const RoleTitle = styled.span<{
  $color?: string;
}>`
  font-size: 0.95em;
  font-weight: 500;
  color: ${(props) => props.$color};
  margin: 0 5px;
`;

export const CModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 5px;
    max-height: 75vh;
    overflow-y: auto;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  margin-top: 24px;
`;
