import React, { useCallback, useState, useEffect } from 'react';
import { message, Row, Col, Badge, Tooltip, Spin, Modal } from 'antd';
import { BsClock } from 'react-icons/bs';
import { useLazyQuery } from '@apollo/client';
import * as S from './styles';
import { calculateTimeLeft } from '../../../../utils/date';
import { gqlSchema } from '../../../../gql/schema';
import { themeConfig } from '../../../../utils/theme';
import AssignmentSummary from '../../../CanvasStudentsDashboardPage/AssignmentSummary';

interface Props {
  timeWarning: boolean;
  icon: React.ReactNode | null;
  description: string;
  classId: string;
  progress: number;
  type: 'Assignment' | 'Design Challenge';
  version?: number;
  courseWorkType: string;
  courseName: string;
  assignment: any;
}

const Item: React.FC<Props> = (props) => {
  const {
    icon,
    classId,
    // courseWorkType,
    courseName,
    assignment,
    description,
  } = props;
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [timeLeftState, setTimeLeftState] = useState<string>('');

  // Ensure course name is passed correctly
  useEffect(() => {
    console.log("Course Name Prop: ", courseName);
    setTimeLeftState(calculateTimeLeft(assignment.due_at));
  }, [courseName, assignment.due_at]);

  const [fetchCanvasCourse, { data: courseData, loading: assignmentsLoading }] = useLazyQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasCoursesById,
    {
      variables: { course_id: assignment.id },
      onError: (error) => {
        message.error(error.message || '');
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    fetchCanvasCourse();
  }, [fetchCanvasCourse]);

  const handleResumeClick = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const handleClosePopup = useCallback(() => {
    setPopupVisible(false);
  }, []);

  const truncateDescription = (text: string | null | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text || '';
  };

  // const capitalizeFirstLetter = (str: string) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  // };

  return (
    <S.Card>
      <Row align="middle">
        <Col span={3} style={{ textAlign: 'left' }}>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </Col>
        <Col span={14}>
          <S.Title $align="left">
            {courseName ? courseName : 'Course Name'}
          </S.Title>
        </Col>
        <Col span={24} style={{ textAlign: 'left', marginTop: '8px' }}>
        <Badge
              count={<div style={{ textAlign: 'center' }}>{'Assignment'}</div>}
              style={{ backgroundColor: '#E9A643', borderRadius: '40px', color: '#fff', fontWeight: 500, fontSize: '12px', padding: '8px 16px', textAlign: 'center',display: 'inline-block' }}
            />
        </Col>
        <Col span={24} style={{ marginTop: '8px' }}>
          <S.AssignmentName>
            <span style={{
              fontWeight: 500,
              fontSize: '14px',
              color: '#333',
              margin: '0',
              display: 'block',
              textAlign: 'left',
              textTransform: 'capitalize',
            }}>
              {assignment.assignment_name || 'Assignment Title'}
            </span>
          </S.AssignmentName>
        </Col>
        <Col span={24}>
          <Tooltip title={<div style={{ whiteSpace: 'normal', maxWidth: '100%', overflow: 'auto', maxHeight: '300px' }} dangerouslySetInnerHTML={{ __html: description }} />} placement="bottom" overlayStyle={{width: '500px' }}>
            <S.Description dangerouslySetInnerHTML={{ __html: truncateDescription(description, 50) }}></S.Description>
          </Tooltip>
        </Col>
        {timeLeftState && (
          <Col span={24} style={{ textAlign: 'left', marginTop: '8px' }}>
            <Row align="middle">
              <BsClock style={{ minWidth: 18, minHeight: 18, marginRight: 8 }} />
              <S.TimeLeft $fontSize="0.8em" $warning={timeLeftState === 'Finished'}>
                {timeLeftState}
              </S.TimeLeft>
            </Row>
          </Col>
        )}
      </Row>
      <Row style={description ? { marginTop: '1em' } : { marginTop: '3em' }} gutter={16}>
        <Col span={16}>
          <S.DashboardButton
            text={'Resume Assignment'}
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={handleResumeClick}
            block
          />
        </Col>
      </Row>
      {isPopupVisible && (
        <Spin spinning={false}> {/* Placeholder for loading state */}
          <Modal visible={isPopupVisible} onCancel={handleClosePopup} footer={null} width={800}>
            <AssignmentSummary
              courseId={classId}
              assignmentId={assignment.assignment_id}
              assignment={assignment}
              visible={true}
              onClose={handleClosePopup}
              isFinished={false}
              score={0}
              courseWorkData={courseData?.getCanvasCoursesById}
              courseWorkError={null}
              courseWorkLoading={assignmentsLoading}
            />
          </Modal>
        </Spin>
      )}
    </S.Card>
  );
};

export default Item;
