import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Row, Col, message } from 'antd';

import { gqlSchema } from '../../../gql/schema';
import { GQL_UserAssignLimitsResponse } from '../../../types/user';
import { useAuth } from '../../../hooks/useAuth';
import DashboardRow from '../../../shared/DashboardRow';
import Button from '../../../shared/Button';
import TeacherAssignmentMeter from '../../../shared/TeacherAssignmentMeter';
import { SubscriptionPackageType } from '../../../constants/enums/SubscriptionPackageType';
import * as S from './styles';

interface IAssignmentRow {
  loaded: () => void;
  controller:any;
}

const AssignmentRow: React.FC<IAssignmentRow> = ({ loaded,controller }) => {
  const history = useHistory();
  const { subscriptionPackageType } = useAuth();
  const notPremium = subscriptionPackageType !== SubscriptionPackageType.PREMIUM;

  const { data: userAssignLimits } = useQuery<{
    getUserAssigmentsLimits: GQL_UserAssignLimitsResponse;
  }>(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.getUserAssigmentsLimits, {
    onCompleted: () => {
      // notify parent of load complete
      loaded();
    },
    onError: (error) => {
      // notify parent of load complete
      loaded();

      message.error(`There was an error in fetching user assignment limits: ${error.message || 'Unexpected Error'}`);
    },
    context: {
      fetchOptions: {
        signal: controller?.signal,
      },
    },
  });

  const handleUpgrade = useCallback(() => {
    history.push('/manage-subscription');
  }, [history]);

  const assignments = useCallback(() => {
    return (
      <Row gutter={[24, 24]}>
        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
          <S.Card data-cy="components-meter-current-plan-card">
            <S.Title>My Current Plan</S.Title>
            <S.Description>
              <S.Info> {subscriptionPackageType} </S.Info>
              {notPremium && (
                <Button
                  data-cy="components-managetagspage-new-button"
                  text={<p style={{ margin: 0 }}>Upgrade Now</p>}
                  minHeight={40}
                  onClick={() => handleUpgrade()}
                />
              )}
            </S.Description>
          </S.Card>
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
          <TeacherAssignmentMeter
            type="classes"
            currentValue={userAssignLimits?.getUserAssigmentsLimits.totalAssignes.classesAssigned || 0}
            maxValue={userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxClasses || 0}
          />
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
          <TeacherAssignmentMeter
            type="assessments"
            currentValue={userAssignLimits?.getUserAssigmentsLimits.totalAssignes.assessmentsAssigned || 0}
            maxValue={userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxAssessments || 0}
          />
        </Col>
        <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
          <TeacherAssignmentMeter
            type="investigations"
            currentValue={userAssignLimits?.getUserAssigmentsLimits.totalAssignes.investigationsAssigned || 0}
            maxValue={userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxInvestigations || 0}
          />
        </Col>
      </Row>
    );
  }, [userAssignLimits, subscriptionPackageType, notPremium, handleUpgrade]);

  return <DashboardRow title={'My Subscription'} items={assignments()} extraCyTitle="subscription" />;
};

export default AssignmentRow;
