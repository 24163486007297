import { Col, Divider } from 'antd';
import styled from 'styled-components';
import Button from '../../shared/Button';
import Input from '../../shared/Input';

export const Line = styled(Col)`
  .ant-form-item {
    margin: 0;
  }
`;
export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 5px;
  min-height: 30vh;
`;
export const CDivider = styled(Divider)`
  margin: 30px 0 0 0;
`;

export const TitleInput = styled.div<{
  $padding?: string;
}>`
  font-weight: 600;
  padding: ${(props) => props.$padding || '0 11px'};
  font-size: 1.1em;
  margin: 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  align-items: center;
  .ant-progress {
    flex: 1;
    margin-left: 16px;
  }
`;

export const SInput = styled(Input)<{
  $bold?: boolean;
}>`
  font-size: 0.95em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  font-weight: ${(props) => (props.$bold ? '500' : 'unset')};
  .ant-input-suffix {
    display: none;
  }
`;

export const EditButton = styled(Button)`
  background: ${(props) => props.theme.primaryColor.background};
  border-color: ${(props) => props.theme.primaryColor.background};
  line-height: 0;
  min-height: 33px;
  svg {
    font-size: 20px;
  }
  &:hover,
  &:active,
  &:focus {
    background: ${(props) => props.theme.primaryColor.background};
    border-color: ${(props) => props.theme.primaryColor.background};
  }
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 2px;
`;
