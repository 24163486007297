/* eslint-disable complexity */
import { Popover } from 'antd';
import React, { useCallback, useState } from 'react';
import { BsExclamationTriangle, BsExclamationTriangleFill, BsQuestion } from 'react-icons/bs';
import { FaLightbulb } from 'react-icons/fa';
import { AiFillPushpin } from 'react-icons/ai';

import { GQL_Mode } from '../../types/investigation';
import * as S from './styles';
import Editor from '../Editor';

interface IInvestigationNotes {
  remoteTeacher?: string;
  inPersonTeacher?: string;
  inPersonTeacherTips?: string;
  help?: string;
  safety?: string;
  helpVideoUrl?: string;
  teacherHelpVideoUrl?: string;
  zIndex?: number;
  mode?: GQL_Mode;
}

const InvestigationNotes = (props: IInvestigationNotes) => {
  const {
    remoteTeacher,
    inPersonTeacher,
    inPersonTeacherTips,
    safety,
    help,
    zIndex,
    mode,
    helpVideoUrl,
    teacherHelpVideoUrl,
  } = props;
  const [hoveringSafetyNotes, setHoveringSafetyNotes] = useState(false);
  const getCentralizedTitle = useCallback((title: string) => {
    return `<p class=\\"editor-align-center\\"><strong>${title}</strong></p><p></p>`;
  }, []);
  const showRemoteTeacherNote = !mode || mode !== 'INPERSON';
  const showInPersonTeacherNote = !mode || mode === 'INPERSON';

  return (
    <>
      <S.NotesContainer zIndex={zIndex}>
        {(remoteTeacher || inPersonTeacher || inPersonTeacherTips) && (
          <S.TeacherNotesContainer>
            {remoteTeacher && showRemoteTeacherNote && (
              <Popover
                key={remoteTeacher}
                placement="rightTop"
                content={
                  <S.PopoverContent>
                    <Editor editable={false} value={remoteTeacher} />
                  </S.PopoverContent>
                }
              >
                <S.TeacherNote>
                  <AiFillPushpin size={20} />
                </S.TeacherNote>
              </Popover>
            )}

            {inPersonTeacher && showInPersonTeacherNote && (
              <Popover
                key={inPersonTeacher}
                placement="rightTop"
                content={
                  <S.PopoverContent>
                    <Editor editable={false} value={inPersonTeacher} />
                  </S.PopoverContent>
                }
              >
                <S.TeacherNote>
                  <AiFillPushpin size={20} />
                </S.TeacherNote>
              </Popover>
            )}
            {inPersonTeacherTips && showInPersonTeacherNote && (
              <Popover
                key={inPersonTeacherTips}
                placement="rightTop"
                content={
                  <S.PopoverContent>
                    <Editor editable={false} value={inPersonTeacherTips} />
                  </S.PopoverContent>
                }
              >
                <S.TeacherNote>
                  <FaLightbulb size={20} />
                </S.TeacherNote>
              </Popover>
            )}
          </S.TeacherNotesContainer>
        )}

        {(help || helpVideoUrl) && (
          <Popover
            placement="rightTop"
            key={help || helpVideoUrl}
            content={
              <S.PopoverContent width={640}>
                {helpVideoUrl && <Editor editable={false} value={getCentralizedTitle('Student Help Video')} />}
                <Editor editable={false} value={help} />
                {helpVideoUrl && <video src={helpVideoUrl} controls height={360} width={640} />}
              </S.PopoverContent>
            }
          >
            <S.TeacherNote>
              <BsQuestion size={32} />
            </S.TeacherNote>
          </Popover>
        )}
        {teacherHelpVideoUrl && (
          <Popover
            placement="rightTop"
            content={
              <S.PopoverContent width={640}>
                <Editor editable={false} value={getCentralizedTitle('Teacher Help Video')} />
                {teacherHelpVideoUrl && <video src={teacherHelpVideoUrl} controls height={360} width={640} />}
              </S.PopoverContent>
            }
          >
            <S.TeacherNote>
              <BsQuestion size={32} />
            </S.TeacherNote>
          </Popover>
        )}
        {safety && (
          <Popover
            key={'safety-note'}
            arrowPointAtCenter={false}
            placement="rightTop"
            content={
              <S.PopoverContent>
                <Editor editable={false} value={safety} />
              </S.PopoverContent>
            }
          >
            <S.SafetyNote
              onMouseEnter={() => setHoveringSafetyNotes(true)}
              onMouseLeave={() => setHoveringSafetyNotes(false)}
            >
              {hoveringSafetyNotes ? <BsExclamationTriangleFill size={32} /> : <BsExclamationTriangle size={32} />}
            </S.SafetyNote>
          </Popover>
        )}
      </S.NotesContainer>
    </>
  );
};

export default InvestigationNotes;
