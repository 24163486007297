import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form as AntdForm, Col, Row, Tooltip, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FiFileText, FiMail } from 'react-icons/fi';
import { gqlSchema } from '../../../gql/schema'; // Ensure this import is correct
import Button from '../../../shared/Button';
import Form from '../../../shared/Form';
import GInviteUsers from '../../../shared/GInviteUsers';
import { GQL_Invitation } from '../../../types/googleClassroom';
import { StudentDataProps } from '../../../types/students';
import { onDownloadStudentTemplate } from '../../../utils/class';
import * as S from './styles';
import { themeConfig } from '../../../utils/theme';
import { GQL_ClassResponse } from '../../../types/class';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  openGroupManagment?: ()=>void;
  updateClassInfo?: (e: GQL_Invitation[]) => void;
  courseId: string;
  classInfo?: GQL_ClassResponse;
}

const initialInvite: StudentDataProps[] = [{ firstName: '', lastName: '', email: '',tags:[] }];

const  AddStudents: React.FC<Props> = ({ visible, setVisible,openGroupManagment, updateClassInfo, courseId,classInfo }) => {
  const [activeKey, setActiveKey] = useState('manual');
  const [hasUploadedFile, setHasUploadedFile] = useState(false);
  const [viewStudentsDetails, setViewStudentsDetails] = useState(false);
  const [hasErrorFile, setHasErrorFile] = useState(false);
  const [form] = useForm();

  const handleCancel = () => {
    setVisible(false);
    setActiveKey('manual');
    setHasUploadedFile(false);
    setViewStudentsDetails(false);
    form.resetFields();
  };

  const [createInvitation, { loading }] = useMutation(gqlSchema.GoogleClassroomSchema.mutations.GCLASS.CreateInviatation, {
    onCompleted: (data) => {
      console.log("invite Data",data);
      if (updateClassInfo) {
        const invitations = data.createInvitation as GQL_Invitation[];
        updateClassInfo(invitations);
      }
      console.log("Class Info: ",classInfo);
      if(openGroupManagment) openGroupManagment();
      message.success('Invites sent successfully');
      setVisible(false); // Close the modal after successful completion
      
    },
    onError: (e) => {
      message.error(`Error trying to add new students: ${e.message}`);
    },
  });

  const onFinish = useCallback(
    async ({ invites }: { invites: StudentDataProps[] }) => {
      console.log("invites", invites);
  
      // Build the array of invitation data
      const inviteDataArray = invites.map((invite) => ({
        courseId,
        userId: invite.email,
        role: 'STUDENT',
        firstName: invite.firstName,
        lastName: invite.lastName,
        tags: invite.tags,
      }));
  
      console.log("inviteDataArray", inviteDataArray);
  
      // Send the entire array in one request
      await createInvitation({
        variables: {
          data: inviteDataArray, // Send the array of invites
        },
      });
    },
    [createInvitation, courseId]
  );
  

  const onReimport = useCallback(() => {
    setViewStudentsDetails(false);
    setHasUploadedFile(false);
  }, []);

  const onImportEmails = useCallback(() => {
    setViewStudentsDetails(true);
    setHasUploadedFile(false);
  }, []);

  const titleInfo = useMemo(
    () => (
      <Row style={{ marginTop: 26 }} justify="center">
        <Col span={1}>
          <FiMail color="#106cfa" size={32} />
        </Col>
        <Col span={24}>
          <S.Title>Invite by Email</S.Title>
        </Col>
      </Row>
    ),
    []
  );

  const saveButton = useMemo(
    () => (
      <AntdForm.Item>
        <Button
          data-cy="components-classdashboard-addstudents-send-button"
          block
          loading={loading}
          text="Send Invites"
          htmlType="submit"
        />
      </AntdForm.Item>
    ),
    [loading]
  );

  const renderButtons = useCallback(() => {
    let buttonBar = (
      <Col span={12} offset={6}>
        {saveButton}
      </Col>
    );
    if (activeKey === 'file') {
      if (viewStudentsDetails) {
        buttonBar = (
          <>
            <Col span={12}>
              <Button block text="Import Another File" htmlType="button" onClick={onReimport} />
            </Col>
            <Col span={12}>{saveButton}</Col>
          </>
        );
      } else if (hasUploadedFile) {
        buttonBar = (
          <Col span={12} offset={6} style={{ marginBottom: 24 }}>
            <Button
              block
              disabled={hasErrorFile}
              text="Import Emails"
              htmlType="button"
              onClick={onImportEmails}
            />
          </Col>
        );
      }
    }
    return <Row gutter={16}>{buttonBar}</Row>;
  }, [activeKey, hasErrorFile, hasUploadedFile, onImportEmails, onReimport, saveButton, viewStudentsDetails]);

  return (
    <S.CModal visible={visible} title={titleInfo} onCancel={handleCancel} footer={null} width={800}>
      <Form onFinish={onFinish} form={form} initialValues={{ invites: initialInvite }}>
        <Row gutter={24}>
          <Col span={20} offset={2}>
            <GInviteUsers
              form={form}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              setHasUploadedFile={setHasUploadedFile}
              viewUsersDetails={viewStudentsDetails}
              setHasErrorFile={setHasErrorFile}
              hasErrorFile={hasErrorFile}
              initialInvite={initialInvite}
              userType="student"
            />
          </Col>
          {activeKey === 'file' && (
            <Col span={1}>
              <S.DownloadTemplate>
                <Tooltip title="Click here to download a template to import your class/data." placement="bottom">
                  <FiFileText
                    size={24}
                    onClick={onDownloadStudentTemplate}
                    style={{ cursor: 'pointer' }}
                    color={themeConfig.secondaryColor.background}
                  />
                </Tooltip>
              </S.DownloadTemplate>
            </Col>
          )}
          <Col span={16} offset={4}>
            {renderButtons()}
          </Col>
        </Row>
      </Form>
    </S.CModal>
  );
};


export default AddStudents;