import { Empty, Row } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../../shared/Button';
import CommentActions from '../../../shared/CommentActions';
import CommentBox from '../../../shared/CommentBox';
import Sider from '../../../shared/Sider';
import { GQL_Comment } from '../../../types/investigation';
import { getDateDiff } from '../../../utils/date';
import { formatCommentText } from '../../../utils/investigation';
import * as S from './styles';

interface IPeerReviewSider {
  investigationId: string;
  comments?: GQL_Comment[];
  loading?: boolean;
  submissionVersion: number;
}

const PeerReviewSider = (props: IPeerReviewSider) => {
  const history = useHistory();
  const { search } = useLocation();
  const { comments, investigationId, loading, submissionVersion } = props;
  const { isStudent , user} = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';

  const backToInvestigation = useCallback(() => {
    const backUrl = (isStudent || isGoogleStudent || isCanvasStudent)
      ? `/student-investigation/${investigationId}${search}`
      : `/teacher-dashboard/investigation-details/${investigationId}${search}`;

    history.push(backUrl);
  }, [history, investigationId, isStudent, search]);

  const formatPostedDate = (date: number) => {
    const diff = getDateDiff(Date.now(), date);
    if (diff.days > 0) {
      if (diff.days > 1) {
        return `posted ${diff.days} days ago`;
      } else {
        return `posted ${diff.days} day ago`;
      }
    } else if (diff.hours > 0) {
      if (diff.hours > 1) {
        return `posted ${diff.hours} hours ago`;
      } else {
        return `posted ${diff.hours} hour ago`;
      }
    } else if (diff.minutes > 0) {
      if (diff.minutes > 1) {
        return `posted ${diff.minutes} minutes ago`;
      } else {
        return `posted ${diff.minutes} minute ago`;
      }
    } else {
      return `posted just now`;
    }
  };

  return (
    <Sider loading={loading} title="Investigation Comments">
      <Row justify="center">
        {comments
          ?.slice()
          ?.sort((a, b) => b.createdAt - a.createdAt)
          ?.map((comment) => (
            <CommentBox
              key={comment.id}
              style={{ marginBottom: 15 }}
              author={comment.author}
              avatar={comment.avatar}
              data-cy="components-peer-review-sider-comment-box"
              actions={
                comment.author === 'Me' ? (
                  <>
                    <S.CalendarIcon size={20} />
                    <S.ActionText>{formatPostedDate(comment.createdAt)}</S.ActionText>
                  </>
                ) : (
                  <CommentActions
                    submissionVersion={submissionVersion}
                    commentId={comment.id}
                    showComment={false}
                    compact
                  />
                )
              }
            >
              {formatCommentText({
                text: comment.text,
                timestamp: comment.coordinates?.ts,
              })}
            </CommentBox>
          ))}
        {!comments?.length && <Empty description="No comments yet" data-cy="components-peer-review-sider-empty" />}
        <div style={{ height: 30, width: '100%' }} />
        <Button
          text="Back to Investigation"
          onClick={backToInvestigation}
          data-cy="components-peer-review-sider-button-back"
        />
        <div style={{ height: 30, width: '100%' }} />
      </Row>
    </Sider>
  );
};

export default PeerReviewSider;
