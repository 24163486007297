import React, { useCallback } from 'react';
import { getAssessmentProgressColor, themeConfig } from '../../utils/theme';
import * as S from './styles';
import { Progress } from 'antd';

interface IAssessmentGradeProps {
  grade?: number | null;
  size?: 'small' | 'large';
  incompleteAssessment?: boolean;
  notStartedAssessment?: boolean;
  noScore?: boolean;
  stepCount?: number;
}

const SubtitleMap = {
  0: 'Not there yet',
  1: 'Not there yet',
  2: 'Approaching expectations',
  3: 'Meets expectations',
};

type SubtitleMapKeys = keyof typeof SubtitleMap;

const AssessmentGrade = ({
  grade,
  size = 'small',
  incompleteAssessment,
  notStartedAssessment,
  noScore = false,
  stepCount = 3,
}: IAssessmentGradeProps) => {
  const maxScore = stepCount * 3;
  const score = grade || 0;
  const averageScorePerStep = Math.floor(score / stepCount);
  const gradePercentage = (score / maxScore) * 100;

  const infoContent = useCallback(
    (percent?: number) => {
      return (
        <S.ProgressContainer>
          <S.Info
            $bold
            data-cy="shared-studentdashboardrows-finishedinvestigationsrow-percent"
            data-percentage={percent}
          >
            {noScore ? (
              <span role="none" >
                <p>No Score</p>
                <p>Feedback only</p>
              </span>
            ) : incompleteAssessment ? (
              'Incomplete'
            ) : notStartedAssessment ? (
              'Not Started'
            ) : grade === null ? (
              `-/${maxScore}`
            ) : (
              `${score}/${maxScore}`
            )}
          </S.Info>
          {!noScore && !incompleteAssessment && !notStartedAssessment && (
            <S.InfoProgress $fontSize={size === 'small' ? '0.5em' : '0.55em'}>
              {grade === null ? 'Not viewed yet' : SubtitleMap[averageScorePerStep as SubtitleMapKeys]}
            </S.InfoProgress>
          )}
        </S.ProgressContainer>
      );
    },
    [noScore, incompleteAssessment, notStartedAssessment, grade, score, maxScore, size, averageScorePerStep],
  );

  return (
    <Progress
      strokeLinecap="round"
      strokeWidth={9}
      style={{ marginTop: '1rem' }}
      strokeColor={gradePercentage < 1 / 3 ? themeConfig.noColor.disabled : getAssessmentProgressColor(gradePercentage)}
      format={infoContent}
      width={size === 'small' ? 145 : 200}
      type="circle"
      percent={gradePercentage}
    />
  );
};

export default AssessmentGrade;
