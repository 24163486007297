import { useMutation, useQuery } from '@apollo/client';
import { Layout, message, Popconfirm } from 'antd';
import React from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import Button from '../../shared/Button';
import Spacer from '../../shared/Spacer';
import { themeConfig } from '../../utils/theme';
import adiLearningHubSrc from '../../assets/adi-learning-hub.svg';
import * as S from './styles';

type Props = RouteComponentProps<{ token: string }>;

const ManageTeacherTransferPage: React.FC<Props> = (props) => {
  const { token } = props.match.params;
  const history = useHistory();

  const { data } = useQuery<{ transferInvite: { userName: string; organizationName: string } }>(
    gqlSchema.TransferInviteSchema.queries.transferInvite,
    {
      variables: { token },
      onError: (err) => {
        if (err.message.includes('Not Found')) {
          message.warning('Token not valid anymore');
        } else {
          message.error('Unexpected error, try again later');
        }
        history.push('/');
      },
    },
  );

  const [denyTransferInvite, { loading: loadingDeny }] = useMutation(
    gqlSchema.TransferInviteSchema.mutations.denyTransferInvite,
    {
      onCompleted: () => {
        message.success('Invite declined');
        history.push('/');
      },
    },
  );
  const [acceptTransferInvite, { loading: loadingAccept }] = useMutation(
    gqlSchema.TransferInviteSchema.mutations.acceptTransferInvite,
    {
      onCompleted: () => {
        message.success('Transferred Successfully');
        history.push('/');
      },
    },
  );

  const handleDenyTransferInvite = () => {
    denyTransferInvite({ variables: { token } });
  };

  const handleAcceptTransferInvite = () => {
    acceptTransferInvite({ variables: { token } });
  };

  const loading = loadingDeny || loadingAccept;

  return (
    <Layout>
      <S.ManageTransferPageContent>
        <S.ManageTransferContainer>
          <S.LogoImage src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Confirm transfer to {data?.transferInvite?.organizationName}</S.Title>
          <p>
            You have been invited to join your district organization. Your individual subscription to the ADI Learning
            Hub will end and you will be added to this organization when you accept this invitation. At this point, your
            subscription will be managed by the organization administrator but none of the classes that you have set up
            in the ADI Learning Hub will be lost. To join the organization, press the accept button below.
          </p>
          <Spacer size={24} />
          <p>
            <b>User:</b> {data?.transferInvite?.userName}
          </p>
          <Spacer size={24} />
          <Popconfirm title="Are you sure?" onConfirm={handleAcceptTransferInvite}>
            <Button text="Accept" minWidth={240} loading={loading} />
          </Popconfirm>
          <Spacer />
          <Popconfirm title="Are you sure?" onConfirm={handleDenyTransferInvite}>
            <Button text="Deny" theme={themeConfig.primaryOutlined} minHeight={40} minWidth={240} loading={loading} />
          </Popconfirm>
        </S.ManageTransferContainer>
      </S.ManageTransferPageContent>
    </Layout>
  );
};

export default withRouter(ManageTeacherTransferPage);
