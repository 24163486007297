import React, { useState } from 'react';
import Current from './Current';
import * as S from './styles';
import { Col, Row, Tabs } from 'antd';
import PendingInvites from './PendingInvites';

const { TabPane } = Tabs;
const TeacherAssistants = () => {
  const [activeKey, setActiveKey] = useState('current');

  return (
    <>
      <S.Container>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Tabs
              defaultActiveKey={activeKey}
              activeKey={activeKey}
              onChange={(activeKey: string) => setActiveKey(activeKey)}
            >
              <TabPane tab={<S.TitleTab>Current Users</S.TitleTab>} key="current">
                <Current />
              </TabPane>
              <TabPane tab={<S.TitleTab>Pending Invites</S.TitleTab>} key="pending">
                <PendingInvites />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </S.Container>
    </>
  );
};

export default TeacherAssistants;
