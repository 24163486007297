import { gql } from '@apollo/client';

const WHITELISTED_DOMAIN_MUTATIONS = {
  removeWhitelistedDomain: gql`
    mutation removeWhitelistedDomain($data: RemoveWhitelistedDomainInput!) {
      removeWhitelistedDomain(data: $data) {
        id
        domain
      }
    }
  `,
  addWhitelistedDomain: gql`
    mutation addWhitelistedDomain($data: AddWhitelistedDomainInput!) {
      addWhitelistedDomain(data: $data) {
        id
        domain
      }
    }
  `,
};

export default WHITELISTED_DOMAIN_MUTATIONS;
