import styled from 'styled-components';
import { Col } from 'antd';

export const Card = styled.div<{
  $chartHeight: string;
  $withLegend: boolean;
}>`
  width: 100%;
  height: ${(props) => `calc(${props.$chartHeight} ${props.$withLegend ? '+ 200px' : '100px'})`};
  padding: 0;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border: 1px solid #c4c4c4;
  .middle {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  g > g > text {
    font-weight: 700 !important;
    font-size: 13px !important;
  }
  g > g > text[dominant-baseline='central'] {
    font-size: 11px !important;
    font-weight: 200 !important;
  }

  g > g > text[dominant-baseline='text-before-edge'] {
    font-size: 13px !important;
    font-weight: 200 !important;
  }
`;
export const CardCol = styled(Col)`
  padding: 1.5em;
`;
export const Title = styled.h2<{
  $fontWeight?: string;
  $fontSize?: string;
  $color?: string;
  $align?: string;
  $marginLeft?: string;
}>`
  font-style: normal;
  font-weight: ${(props) => `${props.$fontWeight || '400'}`};
  font-size: ${(props) => `${props.$fontSize || '1.5em'}`};
  line-height: 26px;
  text-align: ${(props) => `${props.$align || 'left'}`};
  margin-left: ${(props) => `${props.$marginLeft || '0'}`};
  color: ${(props) => `${props.$color || '#000000'}`};
  margin-bottom: 0;
`;

export const DotLegend = styled.div<{
  $background: string;
}>`
  border-radius: 50%;
  background: ${(props) => props.$background};
  width: 15px;
  height: 15px;
  margin-top: -9px;
`;

export const TootipWraper = styled.div`
  padding: 6px;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 5px 5px 5px 5px rgb(22 22 24 / 12%);
`;
