import React, { useState } from 'react';
import { Form as AntdForm, Row, Col } from 'antd';
import Form from '../../../shared/Form';
import { useAuth } from '../../../hooks/useAuth';
import ChangePassword from '../../../shared/ChangePassword';
import { formatDateTime } from '../../../utils/date';
import UpdateAvatar from '../../../shared/UpdateAvatar';
import ChangePasswordButton from '../../../shared/ChangePasswordButton';
import * as S from './styles';

const StudentProfile = () => {
  const { user } = useAuth();
  const [changePassVisible, setChangePassVisible] = useState(false);
  return (
    <>
      <Row>
        <S.Body span={24}>
          <Form>
            <Row>
              <Col xxl={4} xl={4} lg={4} md={8} sm={24} xs={24}>
                <UpdateAvatar />
              </Col>
              <Col xxl={20} xl={20} lg={20} md={16} sm={24}>
                <Row style={{ padding: 20 }}>
                  <S.Line md={24} lg={8}>
                    <S.TitleInput>Name</S.TitleInput>
                    <AntdForm.Item name="name">
                      <S.SInput disabled bordered={false} defaultValue={user?.name} />
                    </AntdForm.Item>
                  </S.Line>
                  <S.Line md={24} lg={8}>
                    <S.TitleInput>Email/Username</S.TitleInput>
                    <AntdForm.Item name="email">
                      <S.SInput disabled bordered={false} defaultValue={user?.username} />
                    </AntdForm.Item>
                  </S.Line>
                  <S.Line md={24} lg={8}>
                    <S.TitleInput>Your Role</S.TitleInput>
                    <AntdForm.Item name="role">
                      <S.SInput disabled bordered={false} defaultValue="Student" />
                    </AntdForm.Item>
                  </S.Line>
                  <S.CDivider />
                </Row>
                <Row style={{ padding: 20 }}>
                  <S.Line md={24} lg={8}>
                    <S.TitleInput>Organization</S.TitleInput>
                    <AntdForm.Item name="organization">
                      <S.SInput
                        disabled
                        bordered={false}
                        defaultValue={user?.organizationName || '-- NO ORGANIZATION --'}
                      />
                    </AntdForm.Item>
                  </S.Line>
                  <S.Line md={24} lg={8}>
                    <S.TitleInput>Enrollment Date</S.TitleInput>
                    <AntdForm.Item name="enrollment-date">
                      <S.SInput
                        disabled
                        bordered={false}
                        defaultValue={
                          user && formatDateTime(user.enrollmentDate, "MM.dd.yyyy, hh:mm aaaaa'm'").toUpperCase()
                        }
                      />
                    </AntdForm.Item>
                  </S.Line>
                  <S.Line md={24} lg={8}>
                    <S.TitleInput>Account Status</S.TitleInput>
                    <AntdForm.Item name="status">
                      <S.Status $status="active">Active</S.Status>
                    </AntdForm.Item>
                  </S.Line>
                  <S.CDivider />
                </Row>
              </Col>
            </Row>
            <Row style={{ padding: 20 }}>
              <Col xxl={4} xl={4} lg={4} md={8} sm={24}>
                <S.TitleInput>Password</S.TitleInput>
                <Row>
                  <Col span={18}>
                    <AntdForm.Item name="password">
                      <S.SInput password disabled bordered={false} defaultValue="password" />
                    </AntdForm.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: 'center' }}>
                    <ChangePasswordButton
                      userSource={user.source}
                      handleChangePassword={() => setChangePassVisible(true)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xxl={20} xl={20} lg={20} md={16} sm={24}>
                <Row>
                  <S.Line md={24} sm={24} lg={8}></S.Line>
                </Row>
              </Col>
            </Row>
          </Form>
        </S.Body>
      </Row>
      <ChangePassword visible={changePassVisible} setVisible={setChangePassVisible} />
    </>
  );
};

export default StudentProfile;
