import { gql } from '@apollo/client';

const SYNC_QUERIES = {
  LIST: {
    getSyncsList: gql`
      query getSyncsList($data: SyncLogInput!) {
        getSyncsList(data: $data) {
          syncLogs {
            id
            userName
            organizationName
            type
            status
            createdAt
            finishedAt
          }
          pagination {
            page
            size
            totalCount
          }
        }
      }
    `,
  },
  DETAILS: {
    getSyncById: gql`
      query getSyncById($logId: String!) {
        getSyncById(logId: $logId) {
          id
          userName
          organizationName
          type
          status
          steps {
            id
            name
            status
            message
            createdAt
            finishedAt
          }
          createdAt
          finishedAt
        }
      }
    `,
  },
};

export default SYNC_QUERIES;
