import { History } from 'history';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useInvestigationFilters<FilterFields extends string>(history: History) {
  interface IInvestigationFilters {
    field: FilterFields;
    value: string;
  }

  const params = new URLSearchParams(history.location.search);
  const initialFilters = Array.from(params?.keys())?.map((key) => ({
    field: key as FilterFields,
    value: params.get(key) as string,
  }));
  const [filters, setFilters] = useState<IInvestigationFilters[]>(initialFilters || []);

  // Update query params to persist
  useEffect(() => {
    const existingParams = new URLSearchParams(history.location.search);
    const currentTab = existingParams.get('tab');
    const newParams = new URLSearchParams();
    if (currentTab) newParams.set('tab', currentTab);

    for (const filter of filters) {
      const { field, value } = filter;
      newParams.has(field) ? newParams.set(field, value) : newParams.append(field, value);
    }

    // using replace instead of push as push creates a lot of history entries on filter change
    // this results in user being forced to click back or forward button repeatedly and cycle through filters
    history.replace({ search: newParams.toString() });
  }, [filters, history]);

  return [filters, setFilters] as [IInvestigationFilters[], Dispatch<SetStateAction<IInvestigationFilters[]>>];
}
