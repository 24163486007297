export class FunctionProxyManager {
  proxyCache: WeakMap<Function, Function>;
  hookRegistry: WeakMap<Function, { before: Set<Function>; after: Set<Function>; onError: Set<Function> }>;
  originalFunctionCache: WeakMap<Function, Function>;
  cleanupFunctions: Map<Function, Function>;
  FUNCTION_PROXY_MANAGER_PROXY: symbol;

  constructor() {
    // Cache to store proxied functions
    this.proxyCache = new WeakMap();

    // Cache to original function
    this.originalFunctionCache = new WeakMap();

    // Cache cleanup functions
    this.cleanupFunctions = new Map();

    // Detailed hook registry
    this.hookRegistry = new WeakMap();

    this.FUNCTION_PROXY_MANAGER_PROXY = Symbol('FunctionProxyManager.Proxy');
  }

  /**
   * Create or retrieve a cached proxy for a function
   * @param {Function} targetFunction - The original function to be proxied
   * @returns {Proxy} - A cached and proxied version of the original function
   */
  createProxy(targetFunction: Function, cleanup?: (targetFn: Function) => void) {
    // Check if targetFunction is already a proxy
    // @ts-ignore
    if (targetFunction[this.FUNCTION_PROXY_MANAGER_PROXY]) {
      return targetFunction;
    }
    // Check if proxy already exists
    if (this.proxyCache.has(targetFunction)) {
      return this.proxyCache.get(targetFunction);
    }

    // Initialize hook registry for this function
    this.hookRegistry.set(targetFunction, {
      before: new Set(),
      after: new Set(),
      onError: new Set(),
    });

    // Create proxy
    const proxy = this._createProxyWithMetadata(targetFunction);

    // Cache the proxy
    this.proxyCache.set(targetFunction, proxy);

    // Cache the original function
    this.originalFunctionCache.set(proxy, targetFunction);

    // Cache cleanup function
    if (cleanup) {
      this.cleanupFunctions.set(proxy, cleanup);
    }

    return proxy;
  }

  /**
   * Add a before hook to a specific function
   * @param {Function} targetFunction - The function to add hook to
   * @param {Function} hook - The hook function to add
   * @returns {Function} - Unsubscribe method
   */
  addBeforeHook(targetFunction: Function, hook: Function, cleanup?: (targetFn: Function) => void): Function {
    // Ensure proxy exists
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const proxy = this.createProxy(targetFunction);

    // Get or create hook registry
    const registry = this.hookRegistry.get(targetFunction);

    // Add hook
    registry?.before.add(hook);

    // Cache cleanup function
    if (cleanup && proxy) {
      this.cleanupFunctions.set(proxy, cleanup);
    }

    // Return unsubscribe function
    return () => {
      registry?.before.delete(hook);

      if (proxy) {
        // Get the original function
        const targetFunction = this.originalFunctionCache.get(proxy);
        if (targetFunction) cleanup?.(targetFunction);
      }
    };
  }

  /**
   * Add an after hook to a specific function
   * @param {Function} targetFunction - The function to add hook to
   * @param {Function} hook - The hook function to add
   * @returns {Function} - Unsubscribe method
   */
  addAfterHook(targetFunction: Function, hook: Function, cleanup?: (targetFn: Function) => void): Function {
    // Ensure proxy exists
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const proxy = this.createProxy(targetFunction);

    // Get or create hook registry
    const registry = this.hookRegistry.get(targetFunction);

    // Add hook
    registry?.after.add(hook);

    // Cache cleanup function
    if (cleanup && proxy) {
      this.cleanupFunctions.set(proxy, cleanup);
    }

    // Return unsubscribe function
    return () => {
      registry?.after.delete(hook);
      if (proxy) {
        // Get the original function
        const targetFunction = this.originalFunctionCache.get(proxy);
        if (targetFunction) cleanup?.(targetFunction);
      }
    };
  }

  /**
   * Add an error hook to a specific function
   * @param {Function} targetFunction - The function to add hook to
   * @param {Function} hook - The hook function to add
   * @returns {Function} - Unsubscribe method
   */
  addErrorHook(targetFunction: Function, hook: Function, cleanup?: (targetFn: Function) => void): Function {
    // Ensure proxy exists
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const proxy = this.createProxy(targetFunction);

    // Get or create hook registry
    const registry = this.hookRegistry.get(targetFunction);

    // Add hook
    registry?.onError.add(hook);

    // Cache cleanup function
    if (cleanup && proxy) {
      this.cleanupFunctions.set(proxy, cleanup);
    }

    // Return unsubscribe method
    return () => {
      registry?.onError.delete(hook);

      if (proxy) {
        // Get the original function
        const targetFunction = this.originalFunctionCache.get(proxy);
        if (targetFunction) cleanup?.(targetFunction);
      }
    };
  }

  /**
   * Create a proxy with comprehensive hook management
   * @param {Function} targetFunction - The original function
   * @returns {Proxy} - Proxied function
   */
  _createProxyWithMetadata(targetFunction: Function): Function {
    const self = this;
    // Proxy handler with comprehensive interception
    const proxyHandler: ProxyHandler<Function> = {
      get(target, key) {
        if (key === self.FUNCTION_PROXY_MANAGER_PROXY) return true;
        return Reflect.get(target, key);
      },
      // Preserve other function properties
      ...this._createPropertyPreservationHandlers(targetFunction),
      apply: (target, thisArg, argumentsList) => {
        const boundContext = thisArg;

        // Retrieve hooks for this specific function
        const registry = this.hookRegistry.get(targetFunction);
        if (registry) {
          // Execute before hooks
          try {
            for (const hook of registry.before) {
              hook.call(boundContext, argumentsList);
            }
          } catch (beforeError) {
            this._handleHookErrors(registry.onError, beforeError as Error, 'before', boundContext);
          }

          // Execute original function
          let result;
          try {
            result = Reflect.apply(target, boundContext, argumentsList);
          } catch (functionError) {
            if (registry) {
              this._handleHookErrors(registry.onError, functionError as Error, 'execute', boundContext);
            }
            throw functionError;
          }

          // Execute after hooks
          try {
            for (const hook of registry.after) {
              hook.call(boundContext, result, argumentsList);
            }
          } catch (afterError) {
            this._handleHookErrors(registry.onError, afterError as Error, 'after', boundContext);
          }

          return result;
        } else {
          target.apply(boundContext, argumentsList);
        }
      },
    };

    // Create and return proxy
    return new Proxy(targetFunction, proxyHandler);
  }

  /**
   * Create handlers to preserve function properties
   * @param {Function} targetFunction - Original function
   * @returns {Object} - Property preservation handlers
   */
  _createPropertyPreservationHandlers(targetFunction: Function) {
    return {
      construct: (target: any, argumentsList: any, newTarget: any) => {
        return Reflect.construct(target, argumentsList, newTarget);
      },
      getPrototypeOf: (target: any) => {
        return Reflect.getPrototypeOf(target);
      },
      // Preserve static properties
      ...Object.getOwnPropertyDescriptors(targetFunction),
    };
  }

  /**
   * Handle hook errors with centralized error management
   * @param {Set} errorHooks - Error handling hooks
   * @param {Error} error - The caught error
   * @param {string} stage - Error stage (before, execute, after)
   * @param {Object} context - Execution context
   */
  _handleHookErrors(errorHooks: Set<Function>, error: Error, stage: string, context: Object) {
    for (const errorHook of errorHooks) {
      try {
        errorHook.call(context, error, stage);
      } catch (handlerError) {
        console.error('Error in error handler:', handlerError);
      }
    }
  }

  /**
   * Remove all hooks from a specific function
   * @param {Function} targetFunction - The function to remove hooks from
   */
  clearHooks(targetFunction: Function) {
    const registry = this.hookRegistry.get(targetFunction);
    if (registry) {
      registry.before.clear();
      registry.after.clear();
      registry.onError.clear();
    }
  }

  /**
   * Get current hooks for a specific function
   * @param {Function} targetFunction - The function to get hooks for
   * @returns {Object} - Current hooks
   */
  getHooks(targetFunction: Function) {
    const registry = this.hookRegistry.get(targetFunction);
    return registry
      ? {
          before: [...registry.before],
          after: [...registry.after],
          onError: [...registry.onError],
        }
      : null;
  }

  /**
   * Returns the original function from a proxy
   * @param {Function | undefined} proxy
   * @returns {Function | undefined} - original function
   */
  getOriginalFunction(proxy: Function | undefined): Function | undefined {
    if (proxy === undefined) return undefined;

    return this.originalFunctionCache.get(proxy);
  }

  clearAllHooks() {
    // Run all cleanup functions
    this.cleanupFunctions.forEach((cleanup, proxy) => {
      const targetFunction = this.originalFunctionCache.get(proxy);
      if (targetFunction) cleanup(targetFunction);
    });

    this.cleanupFunctions = new Map();
    this.hookRegistry = new WeakMap();
    this.originalFunctionCache = new WeakMap();
  }
}
