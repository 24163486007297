import React, { useMemo } from 'react';
import { Row, Col, Empty } from 'antd';
import startCase from 'lodash/startCase';
import DashboardRow from '../../DashboardRow';
import Item from './Item';
import { GQL_InvestigationDashboard } from '../../../types/investigation';
import { getDateDiff } from '../../../utils/date';
import InvestigationIcon from '../../InvestigationIcon';
import DashboardLoadingCard from '../../DashboardLoadingCard';

interface IActiveInvestigationsRow {
  investigations?: GQL_InvestigationDashboard[];
  loading: boolean;
  error?: string;
}

const ActiveInvestigationsRow = (props: IActiveInvestigationsRow) => {
  const { investigations, loading, error } = props;

  const formatTimeLeftString = (date: number): string => {
    const diff = getDateDiff(date, Date.now());
    if (diff.days === 1) return '1 day';
    else if (diff.days === 0) {
      if (diff.hours > 0) return diff.hours + (diff.hours === 1 ? ' hour' : ' hours');
      else {
        return diff.minutes + (diff.minutes === 1 ? ' minute' : ' minutes');
      }
    } else {
      return diff.days + ' days';
    }
  };

  const activeInvestigations = useMemo(() => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
        </Row>
      );
    } else if (!investigations?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Investigations" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {investigations.map((item) => (
            <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
              <Item
                description={item.description}
                progress={item.completion}
                stages={`${item.steps.filter((s) => s.completed).length}/${item.steps.length}`}
                stagesCompleted={item.steps.filter((s) => s.completed).length}
                timeLeft={formatTimeLeftString(item.dueDate)}
                investigationName={`${startCase(item.discipline?.name?.toLowerCase())}: ${item.title}`}
                type={item.discipline?.subject === 'Engineering' ? 'Design Challenge' : 'Investigation'}
                investigationId={item.id}
                timeWarning={getDateDiff(item.dueDate, Date.now()).days === 0}
                icon={
                  <InvestigationIcon
                    subject={item.discipline?.subject || ''}
                    discipline={item.discipline?.name || ''}
                  />
                }
              />
            </Col>
          ))}
        </Row>
      );
    }
  }, [investigations, loading]);

  return <DashboardRow title="Current Investigations" items={activeInvestigations} error={error} />;
};

export default ActiveInvestigationsRow;
