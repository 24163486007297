import query from './query/Chat';
import mutation from './mutation/Chat';
import subscription from './subscription/Chat';
import fragment from './fragment/Chat';

export const ChatSchema = {
  query,
  mutation,
  subscription,
  fragment,
};
