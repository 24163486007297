import React, { useEffect, useMemo } from 'react';
import { Row, Col, message } from 'antd';
import { useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { useLocation } from 'react-router-dom';

import * as S from './styles';
import ActiveInvestigationsRow from '../../shared/StudentDashboardRows/ActiveInvestigationsRow';
import ActiveAssessmentsRow from '../../shared/StudentDashboardRows/ActiveAssessmentsRow';
import FinishedInvestigationsRow from '../../shared/StudentDashboardRows/FinishedInvestigationsRow';
import FinishedAssessmentsRow from '../../shared/StudentDashboardRows/FinishedAssessmentsRow';
import { gqlSchema } from '../../gql/schema';
import { GQL_InvestigationDashboard } from '../../types/investigation';
import ClassesRow from '../../shared/StudentDashboardRows/ClassesRow';
import TeacherAnnouncementsRow from '../../shared/StudentDashboardRows/TeacherAnnouncementsRow';
import { finishedInvestigationFilter, finishedAssessmentFilter } from '../../utils/investigation';

const StudentDashboard = () => {
  const { pathname, hash, key } = useLocation();

  const { data, loading, error } = useQuery<{
    getInvestigationsByUser: GQL_InvestigationDashboard[];
  }>(gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationsByUser, {
    onError: (error) => {
      message.error(
        error.message || 'There was an error loading your investigations, check you connection and try again later.',
      );
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const activeInvestigations = useMemo(() => {
    return data?.getInvestigationsByUser
      ?.filter((investigation) => !investigation.isAssessment)
      ?.filter((i) => !finishedInvestigationFilter(i));
  }, [data]);

  const activeAssessments = useMemo(() => {
    return data?.getInvestigationsByUser
      ?.filter((investigation) => investigation.isAssessment)
      ?.filter((i) => !finishedAssessmentFilter(i));
  }, [data]);

  const finishedInvestigations = useMemo(() => {
    return data?.getInvestigationsByUser
      ?.filter((investigation) => !investigation.isAssessment)
      ?.filter(finishedInvestigationFilter);
  }, [data]);

  const finishedAssessments = useMemo(() => {
    return data?.getInvestigationsByUser
      ?.filter((investigation) => investigation.isAssessment)
      ?.filter(finishedAssessmentFilter);
  }, [data]);

  return (
    <>
      <S.Container>
        <Row gutter={[24, 24]}>
          <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24}>
            <TeacherAnnouncementsRow />
            <ClassesRow />
            <ActiveInvestigationsRow
              loading={loading}
              investigations={sortBy(activeInvestigations, 'dueDate')}
              error={error?.message}
            />
            <ActiveAssessmentsRow
              loading={loading}
              assessments={sortBy(activeAssessments, 'dueDate')}
              error={error?.message}
            />
            <FinishedInvestigationsRow
              loading={loading}
              investigations={finishedInvestigations?.sort((a, b) => b.dueDate - a.dueDate)}
              error={error?.message}
            />
            <div id="finished-assessments-row">
              <FinishedAssessmentsRow
                loading={loading}
                assessments={finishedAssessments?.sort((a, b) => b.dueDate - a.dueDate)}
                error={error?.message}
              />
            </div>
          </Col>
        </Row>
      </S.Container>
    </>
  );
};

export default StudentDashboard;
