import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { CalendarOutlined, TeamOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import * as S from './styles';
import { themeConfig } from '../../../../utils/theme';

interface Props {
  classObj: any;
  icon: React.ReactNode;
}

// Function to format date
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
};

// Function to map workflow_state to display text and styles
const getWorkflowStateText = (state: string) => {
  switch (state) {
    case 'available':
      return { text: 'Ongoing', isBold: true, color: '#3EBC89' };
    case 'completed':
      return { text: 'Completed', isBold: false, color: 'inherit' };
    default:
      return { text: state, isBold: false, color: 'inherit' };
  }
};

const Item: React.FC<Props> = ({ classObj, icon }) => {
  const history = useHistory();
  const [numberOfStudents, setNumberOfStudents] = useState<number | undefined>(undefined);

  const goToDashboard = useCallback(() => {
    history.push('/canvas-teacher-dashboard/class/' + classObj.id, {
      className: classObj.name,
    });
  }, [classObj, history]);

  // Query to fetch student count for the specific class
  const { loading, error, data } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasStudentList,
    {
      variables: { course_id: classObj.id, enrollment_type: 'student' },
      onCompleted: (data) => {
        const studentCount = data?.getCanvasStudentList?.count || 0;
        setNumberOfStudents(studentCount);
      },
      onError: (error) => {
        console.error('Failed to fetch student count:', error);
      },
    }
  );

  useEffect(() => {
    if (data) {
      const studentCount = data.getCanvasStudentList?.count || 0;
      setNumberOfStudents(studentCount);
    }
  }, [data]);

  const workflowState = getWorkflowStateText(classObj.workflow_state);

  return (
    <S.ClassCard>
      <Row gutter={[16, 16]} align="middle">
        <Col span={24}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '16px' }}>
            <S.MainIconWrapper style={{ marginRight: '8px' }}>{icon}</S.MainIconWrapper>
            <S.Title style={{ flexGrow: 1, marginBottom: '8px', marginRight: '8px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {classObj.name}
            </S.Title>
            <S.Info isBold={workflowState.isBold} color={workflowState.color}>
              {workflowState.text}
            </S.Info>
          </div>
        </Col>
      </Row>

      <div style={{ height: '16px' }} />

      <Row justify="center" gutter={[16, 16]}>
        <Col span={8} xl={8} lg={8} sm={8} xs={8}>
          <S.Info>
            <TeamOutlined style={{ marginRight: '8px' }} />
            {loading ? 'Loading...' : error ? 'Error' : `${numberOfStudents} Students`}
          </S.Info>
        </Col>
        <Col span={8} xl={8} lg={8} sm={8} xs={9}>
          <S.Info>
            <CalendarOutlined style={{ marginRight: '8px' }} />
            {formatDate(classObj.created_at)}
          </S.Info>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '3em' }}>
        <Col span={16}>
          <S.DashboardButton
            text="Class Dashboard"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.ClassCard>
  );
};

export default Item;
