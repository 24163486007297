import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Row, Col, message, Popconfirm } from 'antd';

import { gqlSchema } from '../../gql/schema';
import * as S from './styles';
import Button from '../../shared/Button';
import CSVFileUploadField from './CSVFileUploadField';

const AdvancedSearchUploadCSVPage = () => {
  const [cloneAllInvestigations, { loading: loadingCloning }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.DRAFT.generateDuplicationNewVersion,
    {
      onError: (err) => {
        message.error(err.message || 'Unexpected error');
      },
      onCompleted: (generateDuplicationNewVersion) => {
        if (generateDuplicationNewVersion) {
          message.success('New version successfully generated');
        } else {
          message.error('Error generating new version');
        }
      },
      refetchQueries: ['getLatestReviewSiteVersion'],
    },
  );

  const { data: latestReviewVersion, loading: loadingReviewVersion } = useQuery<{
    getLatestReviewSiteVersion: string;
  }>(gqlSchema.InvestigationSchema.queries.REVIEW_SITE.getLatestReviewSiteVersion, {
    onError: () => {
      message.error('Error loading latest review site version');
    },
  });

  const handleNewReviewSiteVersion = useCallback(() => {
    message.info('Be aware: this may take some time to finish');

    cloneAllInvestigations();
  }, [cloneAllInvestigations]);

  return (
    <S.PageContainer>
      <Row gutter={[16, 16]}>
        <Col span={18}>
          <Row>
            <h1>Upload CSVs for Science: Texas Edition (2024 Edition)</h1>
          </Row>

          <Row gutter={[0, 40]}>
            <CSVFileUploadField currentVersion={latestReviewVersion?.getLatestReviewSiteVersion} grade="3" />
            <CSVFileUploadField currentVersion={latestReviewVersion?.getLatestReviewSiteVersion} grade="4" />
            <CSVFileUploadField currentVersion={latestReviewVersion?.getLatestReviewSiteVersion} grade="5" />
          </Row>
        </Col>

        <Col span={6}>
          <Row>
            <h1>Review site latest version: {latestReviewVersion?.getLatestReviewSiteVersion || 'none'}</h1>
          </Row>
          <Popconfirm
            placement="topLeft"
            title="Confirm generating a new review site version?"
            onConfirm={() => handleNewReviewSiteVersion()}
            okText="Yes"
            cancelText="No"
          >
            <Button disabled={loadingCloning || loadingReviewVersion} text="Generate new version" />
          </Popconfirm>
        </Col>
      </Row>
    </S.PageContainer>
  );
};

export default AdvancedSearchUploadCSVPage;
