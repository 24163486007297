import React, { useEffect, useState } from 'react';
import { Select, Button, Switch, Layout, Card, InputNumber, Spin, DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import SelectStudentsPopup from '../SelectStudentsPopup';
import { AssigneeMode, CourseWorkType } from '../../../types/googleClassroom';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';


const { Option } = Select;
const { Content } = Layout;

interface Props {
  courseId: string;
  courseWorkId: string;
  assignmentName: string;
  assignmentDescription: string;
  assignmentType: CourseWorkType | undefined;
  maxPoints: number | undefined;
  dueDate: moment.Moment | null;
  dueTime: moment.Moment | null;
  onSaveAssignment: () => void;
  setAssignmentType: (type: CourseWorkType | undefined) => void;
  setMaxPoints: (points: number | undefined) => void;
  setDueDate: (date: moment.Moment | null) => void;
  setDueTime: (time: moment.Moment | null) => void;
  loading: boolean;
  isEditMode: boolean;
  assigneeMode: string;
  setAssigneeMode: (mode: AssigneeMode) => void;
  individualStudents: string[] | undefined;
  setIndividualStudents: (students: string[] | undefined) => void;
  setIsEditMode: (isEditMode: boolean) => void;
  links: string[];
  formLinks: string[];
  setLinks: (links: string[]) => void;
  setFormLinks: (formLinks: string[]) => void;
}

const CoreSettingsSider: React.FC<Props> = ({
  courseId,
  courseWorkId,
  assignmentName,
  assignmentDescription,
  assignmentType,
  maxPoints,
  dueDate,
  dueTime,
  onSaveAssignment,
  setAssignmentType,
  setMaxPoints,
  setDueDate,
  setDueTime,
  loading,
  isEditMode,
  setIsEditMode,
  links,
  formLinks,
  setLinks,
  setFormLinks,
  assigneeMode,
  setAssigneeMode,
  individualStudents,
  setIndividualStudents,
}) => {
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [timePickerFocused, setTimePickerFocused] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const history = useHistory();

  const handleAssignmentTypeChange = (value: CourseWorkType) => {
    console.log('Assignment type changed:', value);
    setAssignmentType(value);
  };

  const handleCancel = () => {
    console.log('Cancel clicked');
    setCancelLoading(true);
    setTimeout(() => {
      history.push({
        pathname: `/googleclassroom-teacher-dashboard/class/${courseId}`,
        state: { refetch: true },
      });
    }, 500); // Adjust the delay as needed
  };

  const togglePopupVisibility = () => {
    setPopupVisible(!popupVisible);
  };

  const handleSaveStudents = (selectedStudents: string[]) => {
    console.log('Selected students:', selectedStudents);
    setIndividualStudents(selectedStudents);
    togglePopupVisibility();
  };

  const handleAssignToChange = (value: string) => {
    console.log('Assign to changed:', value);
    if (value === AssigneeMode.INDIVIDUAL_STUDENTS) {
      setAssigneeMode(AssigneeMode.INDIVIDUAL_STUDENTS);
      setPopupVisible(true);
    } else {
      setAssigneeMode(AssigneeMode.ALL_STUDENTS);
      setPopupVisible(false);
    }
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    console.log('Resize detected:', window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Layout style={{ width: '100%', height: isMobile? '85%' : '100vh' }}>
      {(loading || cancelLoading) && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
          <Spin size="large" />
        </div>
      )}
      <Card style={{ height: '100%' }}>
        <Content>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
    <ArrowLeftOutlined 
      style={{ fontSize: '1.2rem', cursor: 'pointer', marginRight: '10px', display:isMobile? 'none': 'unset' }} 
      onClick={() => history.push(`/googleclassroom-teacher-dashboard/class/${courseId}`)} 
    />
    <h1 style={{ fontSize: '1.5rem', margin: 0, display:isMobile? 'none': 'unset' }}>Assignments</h1>
  </div>
          <Select
            style={{ width: '100%' }}
            placeholder="Select Assignment Type"
            onChange={handleAssignmentTypeChange}
            value={assignmentType}
          >
            <Option value={CourseWorkType.ASSIGNMENT}>Assignment</Option>
            {/* <Option value={CourseWorkType.QUIZ}>Quiz</Option>
            <Option value={CourseWorkType.MATERIAL}>Material</Option>
            <Option value={CourseWorkType.TEST}>Test</Option> */}
          </Select>
          <InputNumber
            min={0}
            style={{ width: '100%', marginTop: '10px' }}
            placeholder="Enter Points"
            onChange={(value) => {
              console.log('Max points changed:', value);
              setMaxPoints(value || 0);
            }}
            value={maxPoints}
          />
          <DatePicker
            style={{ width: '100%', marginTop: '10px' }}
            placeholder="Select Due Date"
            onChange={(date) => {
              console.log('Due date changed:', date);
              setDueDate(date);
            }}
            value={dueDate}
          />
          <TimePicker
            style={{ width: '100%', marginTop: '10px', ...(timePickerFocused && { borderColor: '#1890ff', boxShadow: '0 0 0 2px #1890ff' }) }}
            placeholder="Select Due Time"
            onFocus={() => setTimePickerFocused(true)}
            onBlur={() => setTimePickerFocused(false)}
            onChange={(time) => {
              console.log('Due time changed:', time);
              setDueTime(time);
            }}
            value={dueTime}
          />
          <Select
            style={{ width: '100%', marginTop: '10px' }}
            placeholder="Assign To"
            onSelect={handleAssignToChange}
            value={assigneeMode}
          >
            <Option value={AssigneeMode.ALL_STUDENTS}>All Students</Option>
            <Option value={AssigneeMode.INDIVIDUAL_STUDENTS}>Select Students</Option>
          </Select>
          <h3 style={{ fontSize: '1rem', marginTop: '10px' }}>Published Assignments</h3>
          <Switch checked={true} disabled={true} />
        </Content>
        <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
          <Button type="primary" onClick={onSaveAssignment} style={{ backgroundColor: '#4367E9', borderColor: '#4367E9', flex: 1 }} >{isEditMode ? 'Edit' : 'Save'}</Button>
          <Button type="default" onClick={handleCancel} style={{ marginLeft: '10px', flex: 1 }}>Cancel</Button>
        </div>
      </Card>
      <SelectStudentsPopup
        visible={popupVisible}
        onCancel={togglePopupVisibility}
        onSave={handleSaveStudents}
        courseId={courseId}
        courseWorkId={courseWorkId}
        assigneeMode={assigneeMode}
        hasAllStudents={assigneeMode === AssigneeMode.ALL_STUDENTS}
        setAssigneeMode={setAssigneeMode}
        individualStudents={individualStudents}
        setIndividualStudents={setIndividualStudents}
      />
    </Layout>
  );
};

export default CoreSettingsSider;
