import React, { useCallback, useState } from 'react';
import { Row, Col, Progress, Spin, Badge } from 'antd';
import { getProgressColor, themeConfig } from '../../../../utils/theme';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { gqlSchema } from '../../../../gql/schema';
import { useQuery } from '@apollo/client';

interface Props {
  progress?: number | null;
  date: string;
  assignmentName: string;
  assignmentId: string;
  courseId: string;
  courseWorkId: string;
  className: React.ReactNode;
  courseName: string;
  icon: React.ReactNode;
  workType: string; // Added workType prop
}

const Item: React.FC<Props> = (props) => {
  const { assignmentName, assignmentId, className, courseId, icon, workType } = props;
  const history = useHistory();
  const [progress, setProgress] = useState<number>(0);

  const { loading } = useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudentsTurnedIn,
    {
      variables: { expiredCourseWork: [{ id: assignmentId, courseId }] },
      onError: (error) => {
        console.error('Error fetching students turned in data:', error);
      },
      onCompleted: (data) => {
        const turnedIn = data.getCourseStudentsTurnedIn.find((item: any) => item.id === assignmentId);
        if (turnedIn) {
          setProgress(turnedIn.complete);
        }
      },
    }
  );

  const goToDashboard = useCallback(() => {
    history.push(`/googleclassroom-teacher-dashboard/class/${courseId}/assignment-summary/${assignmentId}`);
  }, [assignmentId, history, courseId]);

  // Function to capitalize only the first letter of a string
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <S.Card style={{ padding: '10px', overflow: 'hidden' }}>
      <Row align="middle" gutter={16}>
        <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
          <S.IconWrapper>{icon}</S.IconWrapper>
          <S.ClassCourseInfo style={{ flex: 1, marginLeft: '16px' }}>
            <S.Info
              style={{
                fontSize: '1.2em',
                textAlign: 'left',
                fontWeight: 500,
                overflowWrap: 'break-word', // Ensure long text wraps
                wordBreak: 'break-word', // Break long words if necessary
                whiteSpace: 'normal', // Allow text to wrap and break
              }}
            >
              {className}
            </S.Info>
          </S.ClassCourseInfo>
        </Col>
        <Col
          span={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative',
          }}
        >
          <Badge
            style={{
              backgroundColor: '#E9A643',
              borderRadius: '40px',
              color: '#fff',
              fontWeight: 500,
              fontSize: '12px',
              padding: '8px 16px',
              textAlign: 'center',
              position: 'absolute',
              right: '0',
              zIndex: 1, // Ensure badge is on top
            }}
            count={<div style={{ textAlign: 'center' }}>{capitalizeFirstLetter(workType)}</div>}
          />
        </Col>
      </Row>
      {/* Place assignmentName below the class icon */}
      <Row style={{ marginTop: '1em' }}>
        <Col span={24}>
          <S.AssignmentName>
            <span
              style={{
                fontWeight: 500,
                fontSize: '14px',
                color: '#333',
                margin: '0',
                display: 'block',
                textAlign: 'left',
                textTransform: 'capitalize',
                overflowWrap: 'break-word', // Ensure long text wraps
                wordBreak: 'break-word', // Break long words if necessary
                whiteSpace: 'normal', // Allow text to wrap and break
              }}
            >
              {assignmentName}
            </span>
          </S.AssignmentName>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="middle">
          {loading ? (
            <Spin />
          ) : (
            <Progress
              strokeLinecap="round"
              strokeWidth={9}
              style={{ marginTop: 30 }}
              strokeColor={getProgressColor(progress || 0)}
              format={(percent) => (
                <>
                  <S.Info $bold>{percent !== undefined ? Math.round(percent) : 0}%</S.Info>
                  <S.InfoProgress>Total Submissions</S.InfoProgress>
                </>
              )}
              width={145}
              type="circle"
              percent={progress || 0}
            />
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: '3em' }}>
        <Col span={14} offset={5}>
          <S.SummarydButton
            text="View Summary"
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
