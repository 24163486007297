import styled from 'styled-components';

export const Wrapper = styled.div<{
  $selected: boolean;
}>`
  border-radius: 50%;
  background-color: ${(props) => (props.$selected ? props.theme.success.color : props.theme.primaryColor.background)};
  display: inline;
  position: absolute;
  width: 27px;
  top: 5px;
  height: 27px;
  & > svg {
    color: #ffffff;
    margin: 5px;
  }
`;
