import styled from 'styled-components';
import InvestigationIcon from '../../shared/InvestigationIcon';

export const TemplateCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  width: 100%;
  height: 100%;

  text-align: center;
  background: white;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;

  h1 {
    font-size: 18px;
    margin-top: 120px;
  }

  h2 {
    font-size: 14px;
    font-weight: normal;
    color: #767676;
    line-height: 1.2;
  }
`;

export const AssessmentIconTemplate = styled(InvestigationIcon)<{ $science?: boolean }>`
  position: absolute;
  clip-path: polygon(${(props) => (props.$science ? '0 0, 100% 100%, 0 100%' : '')});
`;
