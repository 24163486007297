import styled from 'styled-components';

export const TemplateCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  width: 100%;
  height: 100%;

  text-align: center;
  background: white;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 14px;
    font-weight: normal;
    color: #767676;
    line-height: 1.2;
  }
`;

export const Container = styled.div`
  padding: 4em;
`;

export const TitleTab = styled.p`
  font-weight: 700;
  padding: 0;
  font-size: 1.2em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const LoadingLibrary = styled.div`
  position: absolute;
  width: 100%;
  font-size 2.3em;
  font-weight: 600;
  z-index: 10;
  text-align: center;
  background-color: rgb(0 0 0 / 12%);
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
