/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@apollo/client';
import { message, Row } from 'antd';
import React, { useMemo } from 'react';
import { FiDownload } from 'react-icons/fi';
import { gqlSchema } from '../../../../gql/schema';
import Button from '../../../../shared/Button';
import Editor from '../../../../shared/Editor';
import Input from '../../../../shared/Input';
import Spacer from '../../../../shared/Spacer';
import UploadInput from '../../../../shared/UploadInput';
import { GQL_InvestigationCatalogActivityEntry, GQL_InvestigationCatalogEntry } from '../../../../types/investigation';
import { themeConfig } from '../../../../utils/theme';
import * as S from '../styles';
import { NextGenerationStandardsSelectPartSetting } from './ngssPartSelector';
import { GQL_InvestigationStandard } from '../../../../types/investigationStandard';
import { TexasStandardsSelectPartSetting } from './texasPartSelector';
import { useEnabledAssessments } from '../../../../hooks/useEnabledAssessments';

interface ISettingsTab {
  setNotes: (v?: {
    remoteTeacher?: string;
    inPersonTeacher?: string;
    inPersonTeacherTips?: string;
    safety?: string;
    help?: string;
  }) => void;
  notes?: {
    remoteTeacher?: string;
    inPersonTeacher?: string;
    inPersonTeacherTips?: string;
    safety?: string;
    help?: string;
  };
  loading?: boolean;
  selectedActivityId?: string;
  saveActivity?: () => void;
  updateSelectedActivityField: (fieldName: string, value: any) => void;
  selectedActivity?: GQL_InvestigationCatalogActivityEntry;
  handleGoBack: (e: React.MouseEvent) => void;
  isAssessment: boolean;
  assessment?: GQL_InvestigationCatalogEntry;
  setTexasStandards?: (standards: GQL_InvestigationStandard[]) => void;
  setNgssStandards?: (standards: GQL_InvestigationStandard[]) => void;
}

const SettingsTab = (props: ISettingsTab) => {
  const {
    updateSelectedActivityField,
    selectedActivityId,
    selectedActivity,
    saveActivity,
    setNotes,
    notes,
    loading,
    handleGoBack,
    isAssessment,
    assessment,
    setNgssStandards,
    setTexasStandards,
  } = props;

  const inPersonModeTitle = isAssessment ? 'Paper' : 'In-Person';
  const remoteModeTitle = isAssessment ? 'Paperless' : 'Remote';

  const [updateHelpFileOrUrl, { loading: loadingFile }] = useMutation<
    { uploadActivityHelpVideoFile: { id: string; helpVideoUrl?: string; teacherHelpVideoUrl?: string } },
    { helpVideoUrl?: string; helpVideoFile?: File; activityId: string; teacher: boolean }
  >(gqlSchema.InvestigationSchema.mutations.SETTINGS.uploadActivityHelpVideoFile, {
    onCompleted: ({ uploadActivityHelpVideoFile }) => {
      updateSelectedActivityField('helpVideoUrl', uploadActivityHelpVideoFile?.helpVideoUrl);
      updateSelectedActivityField('teacherHelpVideoUrl', uploadActivityHelpVideoFile?.teacherHelpVideoUrl);
    },
    onError: (err) => {
      message.error('There was an error updating video file: ' + err.message || 'Unexpected Error');
    },
  });

  const { texasEditionEnabled, ngssStandardsEnabled } = useEnabledAssessments();

  const handleSaveActivity = () => {
    if (selectedActivityId) {
      updateHelpFileOrUrl({
        variables: {
          activityId: selectedActivityId,
          helpVideoUrl: selectedActivity?.helpVideoUrl,
          teacher: false,
        },
      });
      updateHelpFileOrUrl({
        variables: {
          activityId: selectedActivityId,
          helpVideoUrl: selectedActivity?.teacherHelpVideoUrl,
          teacher: true,
        },
      });
    }

    saveActivity && saveActivity();
  };

  const beforeUploadVideo = (file: File) => {
    return file.type.includes('video');
  };

  const onStandardsChange = (standards: GQL_InvestigationStandard[]) => {
    if (setNgssStandards) setNgssStandards(standards);
  };

  const onTexasStandardsChange = (standards: GQL_InvestigationStandard[]) => {
    if (setTexasStandards) setTexasStandards(standards);
  };

  const currentTexasStandards = useMemo(() => {
    if (selectedActivity?.texasStandards && selectedActivity?.texasStandards?.length) {
      return selectedActivity.texasStandards;
    }

    if (assessment?.standards && assessment?.standards?.length) {
      return assessment.standards;
    }

    return [];
  }, [assessment, selectedActivity]);

  const currentNgssStandards = useMemo(() => {
    if (selectedActivity?.nextGenerationStandards && selectedActivity?.nextGenerationStandards?.length) {
      return selectedActivity.nextGenerationStandards;
    }

    if (assessment?.nextGenerationStandards && assessment?.nextGenerationStandards?.length) {
      return assessment.nextGenerationStandards;
    }

    return [];
  }, [assessment, selectedActivity]);

  return (
    <>
      <h2>Activity Name</h2>
      <Input
        backgroundColor="white"
        placeholder="Activity Name"
        value={selectedActivity?.name}
        onChange={(e) => updateSelectedActivityField('name', e.target.value)}
      />

      {isAssessment && (
        <>
          {ngssStandardsEnabled && (
            <NextGenerationStandardsSelectPartSetting
              assessment={assessment || ({} as GQL_InvestigationCatalogEntry)}
              onStandardsChange={onStandardsChange}
              currentStandards={currentNgssStandards}
            />
          )}
          {texasEditionEnabled && (
            <TexasStandardsSelectPartSetting
              assessment={assessment || ({} as GQL_InvestigationCatalogEntry)}
              onStandardsChange={onTexasStandardsChange}
              currentStandards={currentTexasStandards}
            />
          )}
        </>
      )}

      <Spacer size={32} />
      <h2>{`${remoteModeTitle} Teacher Notes`}</h2>
      <Editor
        placeholder={`Start writing to add ${remoteModeTitle.toLowerCase()} teacher notes...`}
        fontSize={11}
        value={selectedActivity?.remoteTeacher}
        onChange={(data) => setNotes({ ...notes, remoteTeacher: data.isEditorEmpty ? '' : data.value })}
        showAlign
        showLink
        listenToValue
      />
      <Spacer />
      <h2>{`${inPersonModeTitle} Teacher Notes`}</h2>
      <Editor
        placeholder={`Start writing to add ${inPersonModeTitle.toLowerCase()} teacher notes...`}
        fontSize={11}
        value={selectedActivity?.inPersonTeacher}
        onChange={(data) => setNotes({ ...notes, inPersonTeacher: data.isEditorEmpty ? '' : data.value })}
        showAlign
        showLink
        listenToValue
      />
      <Spacer />
      <h2>{`${inPersonModeTitle} Teacher Tips`}</h2>
      <Editor
        placeholder={`Start writing to add ${inPersonModeTitle.toLowerCase()} teacher tips...`}
        fontSize={11}
        value={selectedActivity?.inPersonTeacherTips}
        onChange={(data) => setNotes({ ...notes, inPersonTeacherTips: data.isEditorEmpty ? '' : data.value })}
        showAlign
        showLink
        listenToValue
      />
      <Spacer />
      <h2>Safety Notes</h2>
      <Editor
        placeholder="Start writing to add safety notes..."
        fontSize={11}
        value={selectedActivity?.safety}
        onChange={(data) => setNotes({ ...notes, safety: data.isEditorEmpty ? '' : data.value })}
        showAlign
        showLink
        listenToValue
      />
      <Spacer />
      <h2>Student Help Notes</h2>
      <Editor
        placeholder="Start writing to add student help notes..."
        fontSize={11}
        value={selectedActivity?.help}
        onChange={(data) => setNotes({ ...notes, help: data.isEditorEmpty ? '' : data.value })}
        showAlign
        showLink
        listenToValue
      />
      <Spacer />
      <Row justify="space-between">
        <h2>Student Help Video</h2>
        {selectedActivity?.helpVideoUrl && (
          <a href={selectedActivity?.helpVideoUrl}>
            <FiDownload size={16} />
          </a>
        )}
      </Row>
      <UploadInput
        beforeUpload={(f) => beforeUploadVideo(f)}
        title={'Upload new video'}
        loading={loadingFile}
        accept="video/*"
        defaultFileList={[]}
        fileList={[]}
        customRequest={({ file }) => {
          if (selectedActivityId && file instanceof File) {
            updateHelpFileOrUrl({
              variables: {
                activityId: selectedActivityId,
                helpVideoFile: file,
                teacher: false,
              },
            });
          }
        }}
      />
      <Spacer size={8} />
      <Input
        value={selectedActivity?.helpVideoUrl}
        backgroundColor="white"
        placeholder="Video URL"
        onChange={(e) => updateSelectedActivityField('helpVideoUrl', e.target.value)}
      />
      <Spacer />
      <Row justify="space-between">
        <h2>Teacher Help Video</h2>
        {selectedActivity?.teacherHelpVideoUrl && (
          <a href={selectedActivity?.teacherHelpVideoUrl}>
            <FiDownload size={16} />
          </a>
        )}
      </Row>
      <UploadInput
        beforeUpload={(f) => beforeUploadVideo(f)}
        title={'Upload new video'}
        loading={loadingFile}
        accept="video/*"
        defaultFileList={[]}
        fileList={[]}
        customRequest={({ file }) => {
          if (selectedActivityId && file instanceof File) {
            updateHelpFileOrUrl({
              variables: {
                activityId: selectedActivityId,
                helpVideoFile: file,
                teacher: true,
              },
            });
          }
        }}
      />
      <Spacer size={8} />
      <Input
        value={selectedActivity?.teacherHelpVideoUrl}
        backgroundColor="white"
        placeholder="Video URL"
        onChange={(e) => updateSelectedActivityField('teacherHelpVideoUrl', e.target.value)}
      />
      <Spacer />
      <S.ButtonsContainer>
        <Button text="Go Back" theme={themeConfig.noColor} block onClick={handleGoBack} />
        <Spacer axis="horizontal" />
        <Button text="Save Settings" block loading={loading} onClick={handleSaveActivity} />
      </S.ButtonsContainer>
    </>
  );
};

export default SettingsTab;
