import styled from 'styled-components';

export const HorizontalDivider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: #ccc;
  margin-top: 32px;
  margin-bottom: 8px;
`;
