import { Radio, Switch } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react';
import Editor from '../../../../../shared/Editor';
import Input from '../../../../../shared/Input';
import Select from '../../../../../shared/Select';
import SelectOption from '../../../../../shared/Select/Option';
import Spacer from '../../../../../shared/Spacer';
import UploadInput from '../../../../../shared/UploadInput';
import { IOnChageEditor } from '../../../../../types';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  IMediaContentValue,
  ITextContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';

interface ITextImageBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const TextImageBlockEditor = (props: ITextImageBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const mediaValues = editingBlock.values.map((v) => v as IMediaContentValue);
  const [isUrl, setIsUrl] = useState(true);
  const [isReversed, setIsReversed] = useState(!!(mediaValues[1] as ITextContentValue)?.type);
  const [ratio, setRatio] = useState(mediaValues[0]?.ratio || mediaValues[1]?.ratio || 0.5);
  const [imageSize, setImageSize] = useState<'SMALL' | 'LARGE' | 'EXTRALARGE'>(
    mediaValues[1]?.size || mediaValues[0]?.size || 'LARGE',
  );
  const imageIndex = isReversed ? 0 : 1;
  const textIndex = isReversed ? 1 : 0;

  const handleReverse = () => {
    setIsReversed(!isReversed);

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              const tempBlock = block.values[imageIndex];
              block.values[imageIndex] = block.values[textIndex];
              block.values[textIndex] = tempBlock;

              return { ...block };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const beforeUploadImage = (file: RcFile) => {
    if (!file.type.includes('image')) return false;
    handleEditImageAttributes({ file });
    return true;
  };

  const handleClearImage = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();

    handleEditImageAttributes({ file: undefined });
  };

  const handleChangeUploadMethod = () => {
    setIsUrl(!isUrl);

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              block.values[imageIndex] = {};

              return { ...block };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditImageAttributes = (payload: { url?: string; file?: File; alt?: string }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;

              const valuesCopy = [...block.values];
              if (payload.alt !== undefined) {
                if (payload.alt !== '') {
                  valuesCopy[imageIndex || 0] = { ...valuesCopy[imageIndex || 0], alt: payload.alt };
                } else {
                  if (payload.file || payload.url) {
                    valuesCopy[imageIndex || 0] = {
                      alt: '',
                      url: payload.url || '',
                      file: payload.file,
                      size: imageSize,
                    };
                  } else {
                    valuesCopy[imageIndex || 0] = { ...valuesCopy[imageIndex || 0], alt: payload.alt };
                  }
                }
              } else {
                if (payload.file || payload.url) {
                  valuesCopy[imageIndex || 0] = {
                    alt: '',
                    url: payload.url || '',
                    file: payload.file,
                    size: imageSize,
                  };
                }
              }
             // valuesCopy[imageIndex] = { url: payload.url || '', file: payload.file, alt: payload.alt || '' };
              return { ...block, values: valuesCopy };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditParagraph = (data: IOnChageEditor) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
          selectedBlockValues[textIndex] = { ...selectedBlockValues[textIndex], text: data.value, type: 'paragraph' };

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleRatioChange = (ratio: number) => {
    setRatio(ratio);
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              (block.values[imageIndex] as IMediaContentValue).ratio = ratio;
              (block.values[textIndex] as ITextContentValue).ratio = 1 - ratio;             
              return { ...block };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleChangeImageSize = (size?: string) => {
    let newSize: 'SMALL' | 'LARGE' | 'EXTRALARGE' = 'LARGE';
    if (size === 'SMALL') newSize = 'SMALL';
    else if (size === 'EXTRALARGE') newSize = 'EXTRALARGE';
    setImageSize(newSize);

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              (block.values[imageIndex] as IMediaContentValue).size = newSize;

              return { ...block };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  return (
    <>
      <h2>Image-Text Ratio</h2>
      <Select placeholder="Image-Text Ratio" value={ratio} onChange={(v) => handleRatioChange(v as number)}>
        <SelectOption value={0.5}>50% - 50%</SelectOption>
        <SelectOption value={0.333}>33% - 67%</SelectOption>
        <SelectOption value={0.667}>67% - 33%</SelectOption>
      </Select>
      <Spacer />
      <h2>Image Size</h2>
      <Radio.Group value={imageSize} onChange={(e) => handleChangeImageSize?.(e.target.value)}>
        <Radio.Button value="EXTRALARGE">Extra-Large</Radio.Button>
        <Radio.Button value="LARGE">Large</Radio.Button>
        <Radio.Button value="SMALL">Small</Radio.Button>
      </Radio.Group>
      <Spacer />
      <S.UploadFromComputerContainer onClick={handleReverse}>
        <h2>Text on the right</h2>
        <Switch checked={isReversed} size="small" />
      </S.UploadFromComputerContainer>
      <Spacer />
      <S.UploadFromComputerContainer onClick={handleChangeUploadMethod}>
        <h2>Upload image from computer</h2>
        <Switch checked={!isUrl} size="small" />
      </S.UploadFromComputerContainer>
      <Spacer />

      <React.Fragment key={+isReversed}>
        <h2>Image URL </h2>
        {isUrl ? (
          <Input
            value={mediaValues[imageIndex]?.url}
            backgroundColor="white"
            placeholder="Image URL"
            onChange={(e) => handleEditImageAttributes({ url: e.target.value })}
          />
        ) : (
          <UploadInput
            beforeUpload={(f) => beforeUploadImage(f)}
            title={mediaValues[imageIndex]?.file?.name || 'Upload image'}
            showClear={!!mediaValues[imageIndex]?.file}
            onClear={(e) => handleClearImage(e)}
            accept="image/*"
            defaultFileList={[]}
            fileList={[]}
            customRequest={() => {}}
          />
        )}
        <Spacer />
        <h2>Alt Text</h2>
        <Input
          value={mediaValues[imageIndex]?.alt || ''} // Set initial value to current alt text value
          backgroundColor="white"
          placeholder="Alt Text"
          onChange={(e) => {
            const altText = e.target.value;
            handleEditImageAttributes({ alt: altText }); // Update alt text
          }}
        />
        <Spacer />
        <Spacer />
      </React.Fragment>

      <Spacer size={24} />

      <h2>Text Content</h2>
      <React.Fragment key={`text-content-${textIndex}`}>
        <Editor
          fontSize={11}
          key={`bI:${selectedBlockIndex}pI:0${isReversed}`}
          value={(mediaValues?.[textIndex] as ITextContentValue)?.text}
          placeholder="Start writing the paragraph content..."
          onChange={(data) => handleEditParagraph(data)}
          showAlign
          showLink
        />
      </React.Fragment>
    </>
  );
};

export default TextImageBlockEditor;