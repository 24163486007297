import styled from 'styled-components';
import { Button as ButtonAntd } from 'antd';

export const Notification = styled.div`
  position: absolute;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  z-index: -1;
  padding-top: 10px;
  cursor: pointer;
  top: 63px;
  background-color: white;
  border-radius: 0px 0px 5px 5px;
  max-height: 36vh;
  min-height: 8vh;
  width: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  left: -15vw;
  @media (max-width: 900px) {
    left: -31vw;
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    left: -23vw;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    left: -20vw;
  }
`;

export const Button = styled(ButtonAntd)`
  transition: all 0.5s ease;
  margin-bottom: 8px;

  & u:hover {
    color: #185a90;
  }
`;
