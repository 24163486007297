import styled from 'styled-components';

export const ToolContainer = styled.div<{ selected?: boolean; removeMargin?: boolean }>`
  cursor: pointer;
  height: 28px;
  width: 28px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: ${(props) => (props.removeMargin ? 0 : 8)}px;
  background: ${(props) => (props.selected ? props.theme.primaryColor.background : '#fff')};
  svg {
    width: 80% !important;
    height: 80% !important;
    max-width: 80% !important;
    max-height: 80% !important;
    min-width: 80% !important;
    min-height: 80% !important;
    color: ${(props) => (props.selected ? '#fff' : '#000')} !important;
  }
`;

export const SelectedColorContainer = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  height: 28px;
  width: 28px;
  min-width: 28px;
  border-radius: 4px;
  cursor: pointer;
`;

export const ToolsRow = styled.div`
  display: flex;

  flex-wrap: wrap;
  & > div {
    margin-right: 8px;
  }
`;

export const LoadingPanel = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000066;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background-color: ${(props) => props.theme.primaryColor.background};
  }
`;

export const ScrollableContent = styled.div`
  width: 100%;
  overflow-x: scroll;
  background: white;
`;

export const SecondaryToolContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
`;
