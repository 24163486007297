import { useQuery, useSubscription } from '@apollo/client';
import { Col, Divider, List, message, Row, Skeleton, Space, Spin } from 'antd';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import {
  GQL_SyncLogResponse,
  GQL_SyncLogSteps,
  GQL_SyncStepSubscriptionResponse,
  GQL_SyncSubscriptionResponse,
} from '../../types/sync';
import { formatDateTime } from '../../utils/date';
import * as S from './styles';
import { orderBy } from 'lodash';
type RouterProps = RouteComponentProps<{
  id: string;
}>;
const SyncDetailsPage = (props: RouterProps) => {
  const { id } = props.match.params;
  const [logSteps, setLogSteps] = useState<GQL_SyncLogSteps[]>([]);

  const { data, loading } = useQuery<{
    getSyncById: GQL_SyncLogResponse;
  }>(gqlSchema.SyncSchema.queries.DETAILS.getSyncById, {
    variables: { logId: id },
    onCompleted: (data) => {
      setLogSteps(data.getSyncById.steps || []);
    },
    onError: (err) => {
      message.error('There was an error loading the Sync Details: ' + err.message || 'Unexpected Error');
    },
  });

  useSubscription<{ listenForSyncStepsUpdates: GQL_SyncStepSubscriptionResponse }>(
    gqlSchema.SyncSchema.subscriptions.listenForSyncStepsUpdates,
    {
      variables: { logId: id },
      onSubscriptionData: ({ subscriptionData: { data }, client }) => {
        if (data?.listenForSyncStepsUpdates) {
          const existingData = client?.readQuery<{ getSyncById: GQL_SyncLogResponse }>({
            query: gqlSchema.SyncSchema.queries.DETAILS.getSyncById,
            variables: { logId: id },
          });

          if (existingData) {
            const newItem = data?.listenForSyncStepsUpdates;
            let isNew = true;
            const newSteps = [...existingData?.getSyncById?.steps].map((st) => {
              const step = { ...st };
              if (step.id === newItem.syncLogStepId) {
                step.status = newItem.status;
                isNew = false;
              }
              return step;
            });

            if (isNew) {
              newSteps.push({
                id: newItem.syncLogStepId,
                createdAt: newItem.createdAt,
                finishedAt: newItem.finishedAt,
                name: newItem.name,
                status: newItem.status,
                message: newItem.message,
              });
            }

            setLogSteps(newSteps);
            client.writeQuery({
              query: gqlSchema.SyncSchema.queries.DETAILS.getSyncById,
              variables: { logId: id },
              data: {
                getSyncById: {
                  ...existingData?.getSyncById,
                  steps: newSteps,
                },
              },
            });
          }
        }
      },
    },
  );

  useSubscription<{ listenForSyncUpdates: GQL_SyncSubscriptionResponse }>(
    gqlSchema.SyncSchema.subscriptions.listenForSyncUpdates,
    {
      variables: { logId: id },
      onSubscriptionData: ({ subscriptionData: { data }, client }) => {
        if (data?.listenForSyncUpdates) {
          const existingData = client?.readQuery<{ getSyncById: GQL_SyncLogResponse }>({
            query: gqlSchema.SyncSchema.queries.DETAILS.getSyncById,
            variables: { logId: id },
          });

          if (existingData) {
            client.writeQuery({
              query: gqlSchema.SyncSchema.queries.DETAILS.getSyncById,
              variables: { logId: id },
              data: {
                getSyncById: {
                  ...existingData?.getSyncById,
                  status: data?.listenForSyncUpdates.status,
                  finishedAt: data?.listenForSyncUpdates.finishedAt,
                },
              },
            });
          }
        }
      },
    },
  );

  return (
    <PageWithTitle title={loading ? 'Loading sync details...' : `Sync Details `} backPageUrl="/sync-list">
      <S.Body span={24}>
        <Row gutter={24} style={{ padding: 20 }}>
          <S.Line md={24} lg={5}>
            <S.TitleInput>User</S.TitleInput>
            <Skeleton.Input active={loading} size="large" />
            <S.SInput disabled={true} bordered={false} value={data?.getSyncById.userName} />
          </S.Line>
          <S.Line md={24} lg={5}>
            <S.TitleInput>Organization</S.TitleInput>
            <S.SInput disabled={true} bordered={false} value={data?.getSyncById.organizationName} />
          </S.Line>
          <S.Line md={24} lg={3}>
            <S.TitleInput>Sync Type</S.TitleInput>
            <S.SInput
              disabled={true}
              bordered={false}
              value={data?.getSyncById.type.toUpperCase().replaceAll('_', ' ').replace('SYNC', '')}
            />
          </S.Line>
          <S.Line md={24} lg={2}>
            <S.TitleInput>Status</S.TitleInput>
            <S.SInput disabled={true} bordered={false} value={data?.getSyncById.status.toUpperCase()} />
          </S.Line>
          <S.Line md={24} lg={4}>
            <S.TitleInput>Created At</S.TitleInput>
            <S.SInput
              disabled={true}
              bordered={false}
              value={formatDateTime(data?.getSyncById.createdAt, 'MM.dd.yyyy HH:mm aa')}
            />
          </S.Line>
          <S.Line md={24} lg={4}>
            <S.TitleInput>Finished At</S.TitleInput>
            <S.SInput
              disabled={true}
              bordered={false}
              value={formatDateTime(data?.getSyncById.finishedAt, 'MM.dd.yyyy HH:mm aa')}
            />
          </S.Line>
        </Row>
      </S.Body>
      <Divider orientation="left">Logs</Divider>
      <S.Body span={24}>
        <Row gutter={24} style={{ padding: '20px 0' }}>
          <S.Line span={24}>
            <List
              size="large"
              style={{ margin: 10 }}
              bordered
              dataSource={orderBy(logSteps, ['createdAt'], ['desc'])}
              renderItem={(item) => (
                <List.Item
                  key={`${item.id}`}
                  actions={[
                    <Space>Status: {item.status.toUpperCase()}</Space>,
                    <Space>Created At: {formatDateTime(item.createdAt, 'MM.dd.yyyy HH:mm:ss aa')}</Space>,
                  ]}
                >
                  <List.Item.Meta
                    style={{ width: '100%' }}
                    title={item.name || 'Step'}
                    description={item.message || 'Without details'}
                  />
                </List.Item>
              )}
            />
          </S.Line>
          {data?.getSyncById.status.toUpperCase() === 'PENDING' && (
            <S.Line span={24}>
              <Row justify="center" style={{ marginTop: 15 }}>
                <Col span={1}>
                  <Spin size="large" />
                </Col>
              </Row>
            </S.Line>
          )}
        </Row>
      </S.Body>
    </PageWithTitle>
  );
};

export default withRouter(SyncDetailsPage);
