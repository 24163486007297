import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 100%;
  margin-top: 16px;

  & > button:first-child {
    margin-right: 24px;
  }
`;
