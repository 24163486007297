import styled from 'styled-components';
import { Avatar, Button, Row as RowAntd } from 'antd';

export const NotificationTitle = styled.span<{
  $fontSize?: string;
  $read?: boolean;
}>`
  font-size: ${(props) => props.$fontSize || ' 0.95em'};
  margin-bottom: 0;
  font-weight: ${(props) => (props.$read ? 400 : 500)};
  cursor: pointer;
  line-height: 1.2;
  text-align: start;
  button {
    font-size: 1em;
    padding: 2px;
    line-height: 0;
    font-weight: ${(props) => (props.$read ? 400 : 500)};
    height: unset;
  }
`;

export const NotificationTitleWrapper = styled.div<{
  $showDetail?: boolean;
}>`
  margin-top: ${(props) => (props.$showDetail ? '5px' : 0)};
  width: ${(props) => (props.$showDetail ? '91%' : '100%')};
  overflow: hidden;
`;

export const MenuIcon = styled(Button)`
  min-width: 27px;
  width: 27px;
  max-height: 27px;
  padding: 4px 4px;
  float: right;
  z-index: 2;
  line-height: 0;
  color: black;
  position: relative;
  &:hover,
  &:focus {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px 1px;
    color: white;
    border-color: #d9d9d9;
    background: ${(props) => `${props.theme.primaryColor.background || 'blue'}`};
  }
`;

export const NotificationAvatar = styled(Avatar)`
  border: 1px solid #3d80f9;
`;

export const NotificationDate = styled.span`
  color: #00000073;
  font-size: 0.85em;
`;

export const Row = styled(RowAntd)<{
  $lastItem?: boolean;
}>`
  transition: all 0.2s ease;
  margin-top: 10px;
  padding: 7px 15px;
  border-bottom: ${(props) => (props.$lastItem ? '1px solid #dcdbdb' : 'none')};

  &:hover {
    background-color: #fafafa;
  }
`;
