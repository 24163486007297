import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { FiHelpCircle } from 'react-icons/fi';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import Button from '../../shared/Button';
import PageWithTitle from '../../shared/PageWithTitle';
import * as S from './styles';
import { getInvestigationLogo } from '../../utils/investigationLogos';
import { useEnabledAssessments } from '../../hooks/useEnabledAssessments';
import { SUBJECT_TYPES, SUBJECT_TYPES_ROUTES } from '../../types/subjects';
import { useAuth } from '../../hooks/useAuth';

type Props = RouteComponentProps<{
  classId: string;
}>;

const AssessmentTypePage: React.FC<Props> = (props) => {
  const { isSubjectEnabled } = useEnabledAssessments();

  const { classId } = props.match.params;
  const history = useHistory();
  const backUrl = classId ? `/teacher-dashboard/class/${classId}` : undefined;
  const pageTitle = classId ? 'Assign a New Assessment' : undefined;
  const { isScienceTexasEditionEnabledWithGrades, isSubscriptionUser } = useAuth();

  const TooltipElement = ({ subject }: { subject: SUBJECT_TYPES }) => {
    return (
      <>
        {!isSubscriptionUser && !isSubjectEnabled(subject) && (
          <Tooltip
            overlayInnerStyle={{ backgroundColor: 'black' }}
            title={
              <>
                Upgrade your subscription{' '}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://share.hsforms.com/1WIzZgPCwR7Snqj6opPDvTQ5gbsq"
                >
                  here
                </a>{' '}
                to unlock these assessments.
              </>
            }
          >
            <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <>
      <PageWithTitle title={pageTitle} padding={isSubscriptionUser ? 2 : 4} backPageUrl={backUrl}>
        <Row gutter={[40, 40]} align="stretch">
          <Col lg={12} md={24}>
            <S.CardContainer subject={SUBJECT_TYPES.MATH}>
              <div>
                <Icon
                  component={getInvestigationLogo({
                    discipline: '',
                    subject: 'Math',
                  })}
                />
              </div>
              <h2>Math Assessment</h2>
              <p>See all Math assessments that can be assigned to your classes.</p>
              <Row className="button-row" align="middle">
                <Button
                  disabled={isSubscriptionUser ? false : !isSubjectEnabled(SUBJECT_TYPES.MATH)}
                  minHeight={40}
                  data-cy="page-investigation-type-page-assign-math-button"
                  text="Assign a Math Assessment"
                  onClick={() => history.push(`${history.location.pathname}/${SUBJECT_TYPES_ROUTES.MATH}`)}
                />
                <TooltipElement subject={SUBJECT_TYPES.MATH} />
              </Row>
            </S.CardContainer>
          </Col>
          <Col lg={12} md={24}>
            <S.CardContainer subject={SUBJECT_TYPES.SCIENCE}>
              <div>
                <Icon
                  component={getInvestigationLogo({
                    discipline: '',
                    subject: 'Science',
                  })}
                />
              </div>
              <h2>Science Assessment</h2>
              <p>See all Science assessments that can be assigned to your classes.</p>
              <Row className="button-row" align="middle">
                <Button
                  disabled={isSubscriptionUser ? false : !isSubjectEnabled(SUBJECT_TYPES.SCIENCE)}
                  minHeight={40}
                  text="Assign a Science Assessment"
                  onClick={() => history.push(`${history.location.pathname}/${SUBJECT_TYPES_ROUTES.SCIENCE}`)}
                />
                <TooltipElement subject={SUBJECT_TYPES.SCIENCE} />
              </Row>
            </S.CardContainer>
          </Col>
          <Col lg={12} md={24}>
            <S.CardContainer subject={SUBJECT_TYPES.ENGINEERING}>
              <div>
                <Icon
                  component={getInvestigationLogo({
                    discipline: 'mechanical',
                    subject: 'Engineering',
                  })}
                />
              </div>
              <h2>Engineering Assessment</h2>
              <p>See all Engineering assessments that can be assigned to your classes.</p>
              <Row className="button-row" align="middle">
                <Button
                  disabled={isSubscriptionUser ? false : !isSubjectEnabled(SUBJECT_TYPES.ENGINEERING)}
                  minHeight={40}
                  text="Assign an Engineering Assessment"
                  onClick={() => history.push(`${history.location.pathname}/${SUBJECT_TYPES_ROUTES.ENGINEERING}`)}
                />
                <TooltipElement subject={SUBJECT_TYPES.ENGINEERING} />
              </Row>
            </S.CardContainer>
          </Col>
          {isScienceTexasEditionEnabledWithGrades ? (
            <Col lg={12} md={24}>
              <S.CardContainer subject={SUBJECT_TYPES.SCIENCE_TEXAS_EDITION}>
                <div>
                  <Icon
                    component={getInvestigationLogo({
                      discipline: '',
                      subject: 'Science',
                    })}
                  />
                </div>
                <h2>Science Assessment: Texas Edition (2024 Adoption)</h2>
                <p>See all Science: Texas Edition (2024 Adoption) assessments that can be assigned to your classes.</p>

                <Row className="button-row" align="middle">
                  <Button
                    data-cy="components-adiTexasAssessmentList-button"
                    disabled={isSubscriptionUser ? false : !isSubjectEnabled(SUBJECT_TYPES.SCIENCE_TEXAS_EDITION)}
                    minHeight={40}
                    text="Assign a Science: Texas Edition (2024 Adoption) Assessment"
                    onClick={() =>
                      history.push(`${history.location.pathname}/${SUBJECT_TYPES_ROUTES.SCIENCE_TEXAS_EDITION}`)
                    }
                  />
                  <TooltipElement subject={SUBJECT_TYPES.SCIENCE_TEXAS_EDITION} />
                </Row>
              </S.CardContainer>
            </Col>
          ) : null}
        </Row>
      </PageWithTitle>
    </>
  );
};

export default withRouter(AssessmentTypePage);
