import React, { useEffect, useMemo, useState } from 'react';
import { Table, message, Button, Tabs } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { GQL_GoogleClassroomStudents, GQL_GoogleClassroomTeachers } from '../../../types/googleClassroom';
import * as S from './styles';
import AddStudents from '../AddStudents';
// import AddTeachers from '../AddTeachers'; 
import { GQL_ClassResponse } from '../../../types/class';
import ManageGroups from '../../GroupsExternal';

const { TabPane } = Tabs;

interface Props {
  classId: string;
  loading: boolean;
  onUpdateNumberOfStudents?: (count: number) => void;
  onUpdateNumberOfTeachers?: (count: number) => void;
}

const StudentsTable: React.FC<Props> = ({ classId, loading, onUpdateNumberOfStudents, onUpdateNumberOfTeachers }) => {
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  // const [showAddTeachersModal, setShowAddTeachersModal] = useState(false);
  const [manageGroupsVisible, setManageGroupsVisible] = useState(false);
  const [classInfo, setClassInfo] = useState<GQL_ClassResponse>();

  const [getClassStudents, { data: studentsData }] = useLazyQuery<{
    getCourseStudents: {
      students: GQL_GoogleClassroomStudents[];
    };
  }>(gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudents, {
    onError: (error) => {
      message.error(
        error.message ||
          'There was an error loading the students. Please check your connection and try again later.',
      );
    },
  });

  const [getCourseTeachers, { data: teachersData }] = useLazyQuery<{
    getCourseTeachers: {
      teachers: GQL_GoogleClassroomTeachers[];
    };
  }>(gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseTeachers, {
    onError: (error) => {
      message.error(
        error.message ||
          'There was an error loading the teachers. Please check your connection and try again later.',
      );
    },
  });

  const [getClass] = useLazyQuery<{ getClassByGoogleClassroomId: GQL_ClassResponse }, { data: { googleClassroomClassId: string } }>(
    gqlSchema.ClassSchema.query.CLASS.CLASSES.getClassByGoogleClassroom,
    {
      onCompleted: (data) => {
        setClassInfo(data.getClassByGoogleClassroomId);
      },
      onError: (err) => {
        message.error('There was an error loading class: ' + (err.message || 'Unexpected Error'));
      },
    },
  );
  
  
 
  

  useEffect(() => {
    getClassStudents({ variables: { token: 'your-token-here', courseId: classId } });
    console.log("classId",classId);
    getCourseTeachers({ variables: { token: 'your-token-here', courseId: classId } });
    getClass({ variables: { data: { googleClassroomClassId: classId } } });
  }, [classId, getClassStudents, getCourseTeachers]);
  console.log("Manage ",manageGroupsVisible);

  const studentColumns = useMemo(() => {
    return [
      {
        title: 'Student Name',
        dataIndex: ['profile', 'name', 'fullName'],
        key: 'name',
        render: (fullName: string) => fullName || '-',
      },
      {
        title: 'Email',
        dataIndex: ['profile', 'emailAddress'],
        key: 'email',
        render: (emailAddress: string) => emailAddress || '-',
      },
    ];
  }, []);

  const teacherColumns = useMemo(() => {
    return [
      {
        title: 'Teacher Name',
        dataIndex: ['profile', 'name', 'fullName'],
        key: 'name',
        render: (fullName: string) => fullName || '-',
      },
      {
        title: 'Email',
        dataIndex: ['profile', 'emailAddress'],
        key: 'email',
        render: (emailAddress: string) => emailAddress || '-',
      },
      // Add other teacher columns here if needed
    ];
  }, []);

  const students = studentsData?.getCourseStudents?.students || [];
  const teachers = teachersData?.getCourseTeachers?.teachers || [];
  
  
  useEffect(() => {
    if (onUpdateNumberOfStudents) {
      onUpdateNumberOfStudents(students.length);
    }
    if (onUpdateNumberOfTeachers) {
      onUpdateNumberOfTeachers(teachers.length);
    }
  }, [students.length, teachers.length, onUpdateNumberOfStudents, onUpdateNumberOfTeachers]);
  console.log("ClassInfo",classInfo);

  return (
    <>
      <S.Title>Users</S.Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Students Details" key="1">
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
            <Button type="primary" style={{ backgroundColor: '#4367E9', borderColor: '#4367E9' }} onClick={() => setShowAddStudentsModal(true)}>Add Students</Button>
          </div>
          <Table
            columns={studentColumns}
            loading={loading}
            dataSource={students.map(student => ({
              ...student,
              key: student
            }))}
            pagination={{
              hideOnSinglePage: false,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40'],
            }}
          />
        </TabPane>
        <TabPane tab="Teacher Details" key="2">
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
            <Button type="primary" style={{ backgroundColor: '#4367E9', borderColor: '#4367E9' }} onClick={() => setShowAddTeachersModal(true)}>Add Teachers</Button>
          </div> */}
          <Table
            columns={teacherColumns}
            loading={loading}
            dataSource={teachers.map(teacher => ({
              ...teacher,
              key: teacher // Use profile.id as the unique key for each teacher
            }))}
            pagination={{
              hideOnSinglePage: false,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40'],
            }}
          />
        </TabPane>
      </Tabs>
      <AddStudents
        visible={showAddStudentsModal}
        setVisible={setShowAddStudentsModal}
        openGroupManagment={()=>setManageGroupsVisible(true)}
        courseId={classId}
      />
      <ManageGroups visible={manageGroupsVisible} setVisible={setManageGroupsVisible} classInfo={classInfo} />
      {/* <AddTeachers
        visible={showAddTeachersModal}
        setVisible={setShowAddTeachersModal}
        courseId={classId}
      /> */}
    </>
  );
};

export default StudentsTable;
