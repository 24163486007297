import { message, Row, Tooltip } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import Editor from '../../../shared/Editor';
import Button from '../../../shared/Button';
import Spacer from '../../../shared/Spacer';
import { ISortingBoardContentValue, ICategoryDragContentValue } from '../../../types/investigation';
import DragTarget from './DragTarget';
import * as S from './styles';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';

interface DraggableOption {
  text?: string;
  targetIndex?: number;
  currentTargetIndex?: number;
  position?: {
    x: number;
    y: number;
  };
}

interface IInvestigationSortingBoardProps {
  value: ISortingBoardContentValue | ICategoryDragContentValue;
  onBoardSorted: (value: boolean) => void;
  isCategoryDrag?: boolean;
  isProcessDrag?: boolean;
  currentStepId?: string;
  activityId?: string;
}

const InvestigationSortingBoard = ({
  value,
  onBoardSorted,
  isCategoryDrag = false,
  isProcessDrag = false,
  currentStepId,
  activityId,
}: IInvestigationSortingBoardProps) => {
  const containerTargetsRef = useRef<HTMLDivElement>(null);
  const [submitJsonSubmission] = useMutation<
    { submitJsonSubmission: { id: string } },
    { activityId: string; stepId: string; json: { data: { value: string[] }[] } }
  >(gqlSchema.InvestigationSchema.mutations.CATEGORY_BOARD.submitCategoryBoard, {
    onError: (err) => message.error('There was an error submitting the Category Drag and Drop answers: ' + err.message),
  });

  const [options, setOptions] = useState<DraggableOption[]>(() => {
    const targetValues = value.targets?.map((target, i) => target.values?.map((v) => ({ text: v, targetIndex: i })));
    if (!targetValues) return [];
    const filtedUndefined = targetValues.flatMap((v) => (v ? v : []));

    return filtedUndefined
      ?.sort(() => Math.random() - 0.5)
      .flat()
      .map((option) => {
        if (isCategoryDrag) {
          const currentTargetIndex = (value as ICategoryDragContentValue).answers?.findIndex((answer) =>
            answer.value.includes(option.text),
          );
          if (currentTargetIndex !== -1 && typeof currentTargetIndex !== 'undefined') {
            return {
              ...option,
              currentTargetIndex,
            };
          }
          return {
            ...option,
            currentTargetIndex:
              currentTargetIndex === -1 || typeof currentTargetIndex === 'undefined' ? undefined : currentTargetIndex,
          };
        }
        return option;
      });
  });

  const [currentDragged, setCurrentDragged] = useState<number | undefined>();

  const optionRowsAmount = Math.ceil(options.length / 4);
  const minHeightOptions = optionRowsAmount * 36 + optionRowsAmount * 8;

  const handleDraggedOverTarget = useCallback(
    (draggedIndex: number, target: number | undefined, currentPosition: { x: number; y: number }) => {
      setOptions((options) => {
        const heightTitle = 84;
        const heightCardWithMargin = 44;
        const targetContainerWidth = containerTargetsRef?.current?.offsetWidth || 0;
        const possibleTargetAmount = value.targets?.length || 0;
        const leftMarginTargetPlacementFirstColumn = 10;
        const leftMarginTargetPlacementSecondColumn = targetContainerWidth / (possibleTargetAmount * 2) + 10;
        const maxTargetsInColumn = Math.floor(
          ((containerTargetsRef?.current?.offsetHeight || 0) - heightTitle) / heightCardWithMargin,
        );

        const oldTarget = options[draggedIndex].currentTargetIndex;
        options[draggedIndex].currentTargetIndex = target;

        const getOptionPosition = (targetArg: number, sameTargetAmount: number) => {
          const firstColumn =
            leftMarginTargetPlacementFirstColumn + targetArg * (targetContainerWidth / possibleTargetAmount);
          const secondColumn =
            leftMarginTargetPlacementSecondColumn + targetArg * (targetContainerWidth / possibleTargetAmount);

          return {
            x: sameTargetAmount <= maxTargetsInColumn ? firstColumn : secondColumn,
            y: heightTitle + minHeightOptions + heightCardWithMargin * ((sameTargetAmount - 1) % maxTargetsInColumn),
          };
        };

        if (typeof target === 'number') {
          const optionsAmountSameTarget = options.filter((option) => option.currentTargetIndex === target).length;
          const optionPosition = getOptionPosition(target, optionsAmountSameTarget);
          options[draggedIndex].position = optionPosition;
        } else {
          options[draggedIndex].position = currentPosition;

          options.forEach((option, index) => {
            if (typeof oldTarget === 'number' && option.currentTargetIndex === oldTarget) {
              const optionsAmountSameTarget = options
                .slice(0, index + 1)
                .filter((option) => option.currentTargetIndex === oldTarget).length;

              const newOptionPosition = getOptionPosition(oldTarget, optionsAmountSameTarget);

              option.position = newOptionPosition;
            }
          });
        }

        return options;
      });
    },
    [minHeightOptions, value],
  );

  const notifyDragTarget = useCallback(
    (_: DraggableEvent, data: DraggableData) => {
      // move element to background and see which element we dropped this on from position
      data.node.style.zIndex = '-1';
      const draggableRect = data.node.getBoundingClientRect();
      const target = document.elementFromPoint(
        draggableRect.x + draggableRect.width / 2,
        draggableRect.y + draggableRect.height / 2,
      );
      const topLeftTarget = document.elementFromPoint(draggableRect.x - 1, draggableRect.y - 1);
      const bottomRightTarget = document.elementFromPoint(
        draggableRect.x + draggableRect.width + 1,
        draggableRect.y + draggableRect.height + 1,
      );

      // move element back to foreground
      data.node.style.zIndex = '';
      if (!target) return;

      if (currentDragged !== undefined) {
        const oldPosition = options[currentDragged].position;
        handleDraggedOverTarget(currentDragged, undefined, { x: 0, y: 0 });
        const dragDroppedEvent = new CustomEvent('DraggableTargetDropped', {
          bubbles: true,
          cancelable: true,
          detail: {
            currentDragged,
            currentPosition: oldPosition,
          },
        });
        topLeftTarget?.dispatchEvent(dragDroppedEvent);
        bottomRightTarget?.dispatchEvent(dragDroppedEvent);
        target.dispatchEvent(dragDroppedEvent);
      }
    },
    [currentDragged, options, handleDraggedOverTarget],
  );

  const handleVerifyAnswers = () => {
    const hasCorrectAnswers = options.every(
      ({ currentTargetIndex, targetIndex }) => currentTargetIndex === targetIndex,
    );
    onBoardSorted(hasCorrectAnswers);

    if (hasCorrectAnswers) {
      message.success('All your answers are correct');
    } else {
      message.warning('Some of the answers are in the wrong board');
    }
  };

  const submitAnswers = useCallback(() => {
    if (activityId && currentStepId && value.targets) {
      let initAnswers: string[][] = [];
      for (let i = 0; i < value.targets?.length; i++) {
        initAnswers.push([]);
      }

      const answers = options.reduce((acc, option) => {
        if (option.currentTargetIndex !== undefined) {
          if (acc[option.currentTargetIndex]) {
            acc[option.currentTargetIndex].push(option.text!);
          } else {
            acc[option.currentTargetIndex] = [option.text!];
          }
        }
        return acc;
      }, initAnswers);

      submitJsonSubmission({
        variables: {
          activityId: activityId,
          json: {
            data: answers.map((answer) => {
              return { value: answer };
            }),
          },
          stepId: currentStepId,
        },
      });

      if (options.every((a) => typeof a.currentTargetIndex !== 'undefined')) {
        onBoardSorted(true);
      }
    }
  }, [activityId, currentStepId, onBoardSorted, options, submitJsonSubmission, value.targets]);

  return (
    <S.ContainerSortingBoard id="sorting-board-wrapper">
      {isCategoryDrag && (
        <>
          <S.TitleInput>Questions</S.TitleInput>
          <Editor
            editable={false}
            value={(value as ICategoryDragContentValue).questionTitle}
            data-cy="student-view-sorting-board-title"
          />
        </>
      )}

      {!isProcessDrag && (
        <>
          <S.ObjectContainers $minHeightOptions={minHeightOptions + 10}>
            {options.map((option, idx) => (
              <Draggable
                position={option.position}
                onDrag={(_, data) => {
                  options[idx].position = { x: data.x, y: data.y };
                  setOptions(options);
                }}
                onStop={(e, d) => {
                  notifyDragTarget(e, d);
                  setCurrentDragged(undefined);
                  submitAnswers();
                }}
                key={idx}
                onStart={() => setCurrentDragged(idx)}
              >
                <S.ContainerDragObject
                  $hasTarget={typeof option.currentTargetIndex === 'number'}
                  beingDragged={currentDragged === idx}
                  key={idx}
                >
                  <Tooltip key={idx} title={option.text} arrowPointAtCenter={false} placement="topLeft">
                    {option.text}
                  </Tooltip>
                </S.ContainerDragObject>
              </Draggable>
            ))}
          </S.ObjectContainers>
          <S.ContainerTargets ref={containerTargetsRef}>
            {value.targets?.map((target, targetIndex) => (
              <DragTarget
                key={targetIndex}
                onElementIsDragged={(idx, pos) => handleDraggedOverTarget(idx, targetIndex, pos)}
              >
                <S.DragTitle>{target.title}</S.DragTitle>
              </DragTarget>
            ))}
          </S.ContainerTargets>
        </>
      )}
      <Spacer />
      {!isCategoryDrag && !isProcessDrag && (
        <Row justify="end">
          <Button text="Verify Answers" onClick={handleVerifyAnswers} />
        </Row>
      )}
    </S.ContainerSortingBoard>
  );
};

export default InvestigationSortingBoard;
