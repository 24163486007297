import React, { useMemo } from 'react';
import { Row, Col, Empty } from 'antd';
import startCase from 'lodash/startCase';
import DashboardRow from '../../../shared/DashboardRow';
import Item from './Item';
import { GQL_InvestigationTeacherEntry } from '../../../types/investigation';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import { formatDateTime } from '../../../utils/date';
import { useAuth } from '../../../hooks/useAuth';

interface IFinishedInvestigationsRow {
  investigations?: GQL_InvestigationTeacherEntry[];
  loading: boolean;
}

const FinishedInvestigationsRow = (props: IFinishedInvestigationsRow) => {
  const { investigations, loading } = props;
  const { isFacilitator } = useAuth();

  const finishedInvestigations = useMemo(() => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
        </Row>
      );
    } else if (!investigations?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Finished Investigations" />;
    } else
      return (
        <Row gutter={[24, 24]}>
          {investigations?.slice(0, 99)?.map((item: GQL_InvestigationTeacherEntry, index) => {
            return (
              <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={`${item.id}-${index}`}>
                <Item
                  progress={item.grade}
                  date={formatDateTime(item.dueDate)}
                  investigationName={`${startCase(item.discipline?.name?.toLowerCase())}: ${item.title}`}
                  investigationId={item.id}
                />
              </Col>
            );
          })}
        </Row>
      );
  }, [investigations, loading]);

  return (
    <DashboardRow title={`Finished ${isFacilitator ? 'Workshop' : ''} Investigations`} items={finishedInvestigations} />
  );
};

export default FinishedInvestigationsRow;
