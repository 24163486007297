import { Col, Progress, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { ConfigColor } from '../../../types/charts';
import * as S from './styles';

interface Props {
  maxValue: number;
  configColor: ConfigColor[];
  title: string;
  value: number;
}

const StatusBar = (props: Props) => {
  const { title, configColor, maxValue, value } = props;
  const [config, setConfig] = useState<ConfigColor | undefined>();
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    setConfig(configColor.find((c) => value >= c.from && value <= c.to));
    setPercent((value * 100) / maxValue);
  }, [configColor, maxValue, value]);
  return (
    <>
      <S.Title>{title}</S.Title>
      <Row gutter={[16, 16]}>
        <Col span={22}>
          <Progress
            percent={percent}
            format={(percent) => (
              <span role="none"  className="ant-progress-text" style={{ color: 'black', marginLeft: 0 }} title="37.5 Days">
                {value === 0 ? 'No Data' : `${value} Days`}
              </span>
            )}
            strokeColor={percent < 100 ? config?.color : configColor[configColor.length - 1].color}
          />
        </Col>
      </Row>
    </>
  );
};

export default StatusBar;
