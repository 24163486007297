import { useMutation, useQuery } from '@apollo/client';
import { Popconfirm, Table } from 'antd';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import Button from '../../shared/Button';
import PageWithTitle from '../../shared/PageWithTitle';
import { GQL_UserDetailsResponse } from '../../types/profile';
import { TagResponse } from '../../types/tags';
import { centerAlign } from '../../utils/antd';
import * as S from './styles';

type Props = RouteComponentProps<{ tagId: string }>;

const TagDetailsPage = (props: Props) => {
  const { tagId } = props.match.params || {};

  const { data: tagDetails } = useQuery<{ getTag: TagResponse }>(gqlSchema.TagsSchema.queries.getTag, {
    variables: { id: tagId },
  });
  const { data, loading } = useQuery<{ getUsersWithTags: GQL_UserDetailsResponse[] }>(
    gqlSchema.TagsSchema.queries.getUsersWithTags,
    {
      variables: {
        tagIds: [tagId],
      },
    },
  );
  const [toggleTagToUser] = useMutation(gqlSchema.TagsSchema.mutations.toggleTagToUser, {
    update: (cache, { data: { toggleTagToUser } }) => {
      const response = cache.readQuery<{ getUsersWithTags: GQL_UserDetailsResponse[] }>({
        query: gqlSchema.TagsSchema.queries.getUsersWithTags,
        variables: {
          tagIds: [tagId],
        },
      });
      cache.writeQuery({
        query: gqlSchema.TagsSchema.queries.getUsersWithTags,
        variables: {
          tagIds: [tagId],
        },
        data: {
          getUsersWithTags: response?.getUsersWithTags?.filter((u) => u.id !== toggleTagToUser?.[0]?.userId) || [],
        },
      });
    },
  });

  const columns = [
    {
      title: 'User Name',
      render: (_: string, record: GQL_UserDetailsResponse) => {
        return record.firstName + ' ' + record.lastName;
      },
      width: '90%',
    },
    {
      title: 'Actions',
      align: centerAlign,
      render: (_: string, record: GQL_UserDetailsResponse) => {
        return (
          <Popconfirm
            placement="topRight"
            title={
              <div style={{ textAlign: 'center' }}>
                <S.Info>Remove User {record.firstName}?</S.Info>
              </div>
            }
            onConfirm={() => {
              toggleTagToUser({ variables: { data: { id: tagId }, userId: record.id } });
            }}
            okText="Confirm"
            cancelText="Cancel"
          >
            <Button text="Remove User" block />
          </Popconfirm>
        );
      },
      width: '10%',
    },
  ];

  return (
    <PageWithTitle title={`Tag Details: ${tagDetails?.getTag?.tag || 'Loading...'}`} fullHeight>
      <Table columns={columns} dataSource={data?.getUsersWithTags} loading={loading} />
    </PageWithTitle>
  );
};

export default withRouter(TagDetailsPage);
