type AlignType = 'left' | 'center' | 'right';
export type Breakpoint = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';

export const centerAlign: AlignType = 'center';

export interface Filter {
  text: string;
  value: string;
  color?: string;
}
