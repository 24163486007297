import { Popover } from 'antd';
import React from 'react';
import { questionnaireMock } from '../../../utils/mock';
import InvestigationQuestionnaire from '../../StudentInvestigation/InvestigationQuestionnaire';

interface IReportWalkthrough {
  currentTip: number;
  popoverContent?: React.ReactNode;
}

const ReportWalkthrough = (props: IReportWalkthrough) => {
  const { currentTip, popoverContent } = props;

  return (
    <>
      <h1>{questionnaireMock.title}</h1>
      <Popover content={popoverContent} visible={currentTip === 5} placement="leftTop" overlayStyle={{ zIndex: 1001 }}>
        <InvestigationQuestionnaire questionnaire={questionnaireMock} />
      </Popover>
    </>
  );
};

export default ReportWalkthrough;
