import { IAction } from '../types';

/**
 * Creates a reducer by either returning a new state, or by returning the current state.
 *
 * Used by the individual reducers per module.
 */
export const createReducer = (initialState: any, reducer: (state: any, action: IAction) => any) => (
  state = initialState,
  action: IAction,
) => reducer(state, action) || state;

/**
 * Internal function to create async saga action types.
 *
 * @param  String type Type to create
 * @return Object      Containing ACTION|SUCCESS|FAILED properties
 */
export const createSagaAction = (type: string) => {
  return {
    ACTION: `${type}.ACTION`,
    SUCCESS: `${type}.SUCCESS`,
    FAILED: `${type}.FAILED`,
    LOADING: `${type}.LOADING`,
  };
};
