import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import map from 'lodash/map';
import * as S from './styles';
import { GQL_NotificationResponse } from '../../types/notification';
import { Col, Divider, message, Row, Spin } from 'antd';
import Item from '../NotificationItem';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';

interface Props {
  visible: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  notifications?: GQL_NotificationResponse[];
  loading: boolean;
}
const Notification: React.FC<Props> = (props) => {
  const history = useHistory();
  const { notifications, loading } = props;

  const [markNotificationsAsRead] = useMutation(
    gqlSchema.NotificationSchema.mutations.NOTIFICATION.markNotificationsAsRead,
    {
      onCompleted: () => {
        props.setVisible?.(false);
      },
      update(cache, { data }) {
        if (data?.markNotificationsAsRead) {
          notifications?.forEach((noti: GQL_NotificationResponse) => {
            const notificationResponse = {
              __typename: 'NotificationResponse',
              id: noti.id,
            };
            cache.modify({
              id: cache.identify(notificationResponse),
              fields: {
                seen() {
                  return true;
                },
              },
              broadcast: true,
            });
          });
        }
      },
      onError: (e) => {
        message.error('There was an error updating the notifications.');
      },
    },
  );

  const viewAll = useCallback(() => {
    history.push('/notifications');
    props.setVisible?.(false);
  }, [history, props]);

  const markAllAsRead = useCallback(() => {
    markNotificationsAsRead({
      variables: { notificationIds: notifications?.map((noti: GQL_NotificationResponse) => noti.id) },
    });
  }, [markNotificationsAsRead, notifications]);

  return (
    <>
      {props.children}
      {props.visible && (
        <S.Notification>
          {loading ? (
            <Spin style={{ marginTop: '20px', width: '100%' }} />
          ) : (
            <>
              {notifications && (
                <>
                  <Row justify="space-between">
                    <Col span={12} style={{ textAlign: 'left' }}>
                      <S.Button
                        data-cy="shared-notification-viewall-button"
                        type="link"
                        style={{ fontSize: '1em', fontWeight: 500 }}
                        onClick={viewAll}
                      >
                        <u>View All</u>
                      </S.Button>
                    </Col>

                    <Col span={12} style={{ textAlign: 'end' }}>
                      <S.Button
                        data-cy="shared-notification-markallread-button"
                        type="link"
                        style={{ fontSize: '1em', fontWeight: 500 }}
                        onClick={markAllAsRead}
                      >
                        <u>Mark All as Read</u>
                      </S.Button>
                    </Col>
                  </Row>

                  <Divider style={{ margin: 0 }} />
                </>
              )}

              {map(notifications, (notification: GQL_NotificationResponse, index) => (
                <div key={index}>
                  <Item notification={notification} />
                </div>
              ))}
            </>
          )}
        </S.Notification>
      )}
    </>
  );
};
export default Notification;
