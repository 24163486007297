import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

export type ReactMediaRecorderRenderProps = {
  error: string;
  muteAudio: () => void;
  unMuteAudio: () => void;
  startRecording: () => Promise<void>;
  pauseRecording: () => void;
  resumeRecording: () => void;
  stopRecording: () => void;
  getMediaStream: () => Promise<void>;
  mediaBlobUrl: null | string;
  mediaBlob: null | Blob;
  status: StatusMessages;
  isAudioMuted: boolean;
  previewStream: MediaStream | null;
  clearBlobUrl: () => void;
  clearBlob: () => void;
  isScreenRecordingDisabled: boolean;
  disabledReason?: string;
};

export type ReactMediaRecorderHookProps = {
  audio?: boolean | MediaTrackConstraints;
  video?: boolean | MediaTrackConstraints;
  onStopSharing?: () => void;
  screen?: boolean;
  onStop?: (blobUrl: string, blob: Blob) => void;
  blobPropertyBag?: BlobPropertyBag;
  mediaRecorderOptions?: MediaRecorderOptions | null;
};
export type ReactMediaRecorderProps = ReactMediaRecorderHookProps & {
  render: (props: ReactMediaRecorderRenderProps) => ReactElement;
};

export type StatusMessages =
  | 'media_aborted'
  | 'permission_denied'
  | 'no_specified_media_found'
  | 'media_in_use'
  | 'invalid_media_constraints'
  | 'no_constraints'
  | 'recorder_error'
  | 'idle'
  | 'acquiring_media'
  | 'delayed_start'
  | 'recording'
  | 'stopping'
  | 'stopped';

export enum RecorderErrors {
  AbortError = 'media_aborted',
  NotAllowedError = 'permission_denied',
  NotFoundError = 'no_specified_media_found',
  NotReadableError = 'media_in_use',
  OverconstrainedError = 'invalid_media_constraints',
  TypeError = 'no_constraints',
  NONE = '',
  NO_RECORDER = 'recorder_error',
}

export function useReactMediaRecorder({
  audio = true,
  video = false,
  onStop = () => null,
  blobPropertyBag,
  screen = false,
  mediaRecorderOptions = null,
  onStopSharing = () => null,
}: ReactMediaRecorderHookProps): ReactMediaRecorderRenderProps {
  const [isScreenRecordingDisabled, setIsScreenRecordingDisabled] = useState(false);
  const [disabledReason, setDisabledReason] = useState<string>();
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const [status, setStatus] = useState<StatusMessages>('idle');
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
  const [mediaBlob, setMediaBlob] = useState<Blob | null>(null);
  const [error, setError] = useState<keyof typeof RecorderErrors>('NONE');

  const getMediaStream = useCallback(async () => {
    setStatus('acquiring_media');
    const requiredMedia: MediaStreamConstraints = {
      audio: typeof audio === 'boolean' ? !!audio : audio,
      video: typeof video === 'boolean' ? !!video : video,
    };
    try {
      if (screen) {
        mediaStream?.current?.getTracks()?.forEach((track) => track.stop());
        // @ts-ignore
        const stream = (await window.navigator.mediaDevices.getDisplayMedia({
          video: video || true,
        })) as MediaStream;
        mediaStream.current = stream;
        if (audio) {
          const audioStream = await window.navigator.mediaDevices.getUserMedia({
            audio,
          });

          audioStream.getAudioTracks().forEach((audioTrack) => stream.addTrack(audioTrack));
        }
        stream.getVideoTracks()[0].onended = function () {
          onStopSharing();
          stopRecording();
        };
      } else {
        const stream = await window.navigator.mediaDevices.getUserMedia(requiredMedia);
        mediaStream.current = stream;
      }

      setStatus('idle');
    } catch (error) {
      mediaRecorder?.current && mediaRecorder.current.stop();
      mediaStream.current && mediaStream.current.getTracks().forEach((track) => track.stop());
      setError(error.name);
      setStatus('idle');

      throw error;
    }
  }, [audio, video, screen, onStopSharing]);

  useEffect(() => {
    if (!window.MediaRecorder) {
      setIsScreenRecordingDisabled(true);
      setDisabledReason('Unsupported Browser');
      console.error('Unsupported Browser');
    }
    if (screen) {
      //@ts-ignore
      if (!window.navigator.mediaDevices?.getDisplayMedia) {
        setIsScreenRecordingDisabled(true);
        setDisabledReason("This browser doesn't support screen capturing");
        console.error("This browser doesn't support screen capturing");
      }
    }
    const checkConstraints = (mediaType: MediaTrackConstraints) => {
      const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
      const unSupportedConstraints = Object.keys(mediaType).filter(
        (constraint) => !(supportedMediaConstraints as { [key: string]: any })[constraint],
      );
      if (unSupportedConstraints.length > 0) {
        console.error(
          `The constraints ${unSupportedConstraints.join(
            ',',
          )} doesn't support on this browser. Please check your ReactMediaRecorder component.`,
        );
      }
    };
    if (typeof audio === 'object') {
      checkConstraints(audio);
    }
    if (typeof video === 'object') {
      checkConstraints(video);
    }
    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        console.error(`The specified MIME type you supplied for MediaRecorder doesn't support this browser`);
      }
    }
  }, [audio, screen, video, getMediaStream, mediaRecorderOptions]);

  // Media Recorder Handlers

  const startRecording = async () => {
    setError('NONE');
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      const isStreamEnded = mediaStream.current.getTracks().some((track) => track.readyState === 'ended');
      if (isStreamEnded) {
        await getMediaStream();
      }
      mediaRecorder.current = new MediaRecorder(mediaStream.current);
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      mediaRecorder.current.onerror = () => {
        setError('NO_RECORDER');
        setStatus('idle');
      };
      mediaRecorder.current.start();
      setStatus('recording');
    }
  };

  const onRecordingActive = ({ data }: BlobEvent) => {
    mediaChunks.current.push(data);
  };

  const onRecordingStop = () => {
    const [chunk] = mediaChunks.current;
    const blobProperty: BlobPropertyBag = Object.assign(
      { type: chunk.type },
      blobPropertyBag || (video ? { type: 'video/mp4' } : { type: 'audio/wav' }),
    );
    const blob = new Blob(mediaChunks.current, blobProperty);
    const url = URL.createObjectURL(blob);
    setStatus('stopped');
    setMediaBlobUrl(url);
    setMediaBlob(blob);
    onStop(url, blob);
  };

  const muteAudio = (mute: boolean) => {
    setIsAudioMuted(mute);
    if (mediaStream.current) {
      mediaStream.current.getAudioTracks().forEach((audioTrack) => (audioTrack.enabled = !mute));
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.pause();
    }
  };
  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume();
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== 'inactive') {
        setStatus('stopping');
        mediaRecorder.current.stop();
        mediaStream.current && mediaStream.current.getTracks().forEach((track) => track.stop());
        mediaChunks.current = [];
      }
    }
  };

  return {
    error: RecorderErrors[error],
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    getMediaStream,
    mediaBlobUrl,
    mediaBlob,
    status,
    isAudioMuted,
    previewStream: mediaStream.current ? new MediaStream(mediaStream.current.getVideoTracks()) : null,
    clearBlobUrl: () => setMediaBlobUrl(null),
    clearBlob: () => setMediaBlob(null),
    isScreenRecordingDisabled,
    disabledReason,
  };
}

export const ReactMediaRecorder = (props: ReactMediaRecorderProps) => props.render(useReactMediaRecorder(props));
