import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const BackButton = styled.button`
  font-size: 18px;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
`;

export const TableContainer = styled.div`
  .ant-table-thead > tr > th {
    background-color: #4367E9; 
    color: white;
    font-weight: bold;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

interface StatusTagProps {
  status: string;
}

export const StatusTag = styled.span<StatusTagProps>`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 800;
  color: ${({ status }) => (status === 'Requested' ? '#12A017' : '#F78F18')};
  background-color: ${({ status }) =>
    status === 'Requested' ? '#94FEC4' : '#F5DDBD'};
  text-align: center;
`;
