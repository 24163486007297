import styled from 'styled-components';

export const ContainerCategoryBoard = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const ObjectContainers = styled.div<{ $minHeightOptions: number }>`
  height: auto;
  // min-height: ${(props) => props.$minHeightOptions}px;
  position: relative;
  margin-bottom: 8px;
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(4, 1fr);

  &:has(img) {
    // min-height: calc(${(props) => props.$minHeightOptions}px + 80px);
  }
`;

export const TargetColumn = styled.div<{ isOver: boolean; showSuccess: boolean; isCorrectAnswer: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  cursor: pointer;
  flex: 1;
  background-color: white;
  padding: 10px 10px;

  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.showSuccess
      ? props.isCorrectAnswer
        ? 'background-color: rgb(212, 248, 221); '
        : 'background-color: rgba(234, 53, 53, 0.1); '
      : props.isOver
      ? 'background: #f0f0f0;'
      : 'white;'}

  & .cards {
    width: 100%;
    min-height: 180px;
  }
`;

export const ContainerTargets = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 24px;
  padding: 0 5px
  & > div:not(:last-child) {
    border-right: 1px solid #ccc;
  }
`;

export const DragTitle = styled.h1`
  text-align: center;
  font-size: 24px !important;
  font-weight: bold;
  margin-top: 16px;
  user-select: none;
`;

export const TitleInput = styled.p<{ alignRight?: boolean }>`
  font-weight: 800 !important;
  padding: 0;
  ${(props) => props.alignRight && 'text-align: right;'}
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const ContainerDragObject = styled.div<{ beingDragged: boolean; $hasTarget: boolean; isImage?: boolean }>`
  ${(props) =>
    props.$hasTarget &&
    `
      opacity: 0;
  `}

  opacity: ${(props) => (props.beingDragged || props.$hasTarget ? '0' : '1')};
  z-index: ${(props) => (props.beingDragged ? '1' : '0')};
  height: auto;
  // min-height: ${(props) => (props.isImage ? '120px' : '36px')};
  width: 100%;
  min-width: 80%;
  border-radius: 4px;
  background: white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
  word-wrap: break-word;
  margin: 5px auto;
  cursor: ${(props) => (props.beingDragged ? 'grabbing' : 'grab')};

  & span {
    pointer-events: none;
    user-select: none;
  }

  & img {
    width: 100%;
    height: 100%;
  }

  ${(props) => (props.isImage ? 'margin: 0 5px 20px 0' : '')}
`;
export const ContainerDragTarget = styled.div<{ beingDragged: boolean; isImage?: boolean }>`
  color: ${(props) => (props.beingDragged ? 'red' : 'black')};
  opacity: 1;
  z-index: 0;
  height: auto;
  // min-height: ${(props) => (props.isImage ? '120px' : '36px')};
  width: 100%;
  border-radius: 4px;
  background: white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
  word-wrap: break-word;
  margin: 5px 8px 5px 0;
  cursor: grab;

  & span {
    pointer-events: none;
    user-select: none;
  }

  & img {
    width: 100%;
    height: 100%;
  }
`;

export const CategoryTargetContainer = styled.div<{
  showSuccess?: boolean;
  isCorrectAnswer?: boolean;
  isImage?: boolean;
}>`
  position: relative;

  > ${ContainerDragTarget} {
    z-index: 0;
    height: ${(props) => (props.isImage ? '100%' : '5%')};
    width: ${(props) => (props.isImage ? '100%' : '100%')};
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    padding: 2px;
    white-space: nowrap;
    cursor: grab;
    margin: 2% auto;
    ${(props) =>
      props.showSuccess
        ? props.isCorrectAnswer
          ? 'background-color: rgb(212, 248, 221); border: 1px solid rgba(32, 188, 137, 1);'
          : 'background-color: rgba(234, 53, 53, 0.1); border: 1px solid rgba(234, 53, 53, 1);'
        : 'background: white;'}
  }

  > ${ContainerDragObject} {
    width: 80%;
    height: 36px;
  }

  &:after {
    border-right: solid 5px transparent;
    border-left: solid 5px transparent;
    border-top: solid 5px grey;
    transform: translateX(-36%);
    content: '';
    top: 45px;
    left: 50%;
    height: 0;
    width: 0;
    position: absolute;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  & img {
    width: 100%;
    height: 100%;
  }
`;
