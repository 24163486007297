import React, { useCallback, useRef, useState } from 'react';
import { Col, Layout, message, Row } from 'antd';
import { FiPlus } from 'react-icons/fi';

import * as S from './styles';
import InvestigationContent from '../../shared/InvestigationContent';
import Button from '../../shared/Button';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_Concept, GQL_PeerReview } from '../../types/investigation';
import { GQL_SubmitCommentVariables } from '../../types/investigation';
import InvestigationConcept from '../../shared/InvestigationConcept';
import DraggableComment from '../../shared/DraggableComment';
import PeerReviewSider from './PeerReviewSider';

type Props = RouteComponentProps<
  {
    investigationId: string;
    stepId: string;
    studentId: string;
    activityId: string;
    evaluatorId?: string;
    submissionVersion?: string;
  },
  any,
  {
    conceptActivityId?: string;
    userId?: string;
  }
>;

const BestConceptPeerReviewInvestigationPage: React.FC<Props> = (props) => {
  const { stepId, studentId, activityId, investigationId, evaluatorId } = props.match.params;
  const { conceptActivityId, userId } = props.location.state || {};
  const conceptContainerRef = useRef<HTMLDivElement>(null);
  const submissionVersion = props.match.params.submissionVersion ? parseInt(props.match.params.submissionVersion) : 1;
  const [addingComment, setAddingComment] = useState(false);
  const history = useHistory();

  const { data: conceptData, loading: loadingConceptData } = useQuery<
    { getConcept: GQL_Concept },
    {
      stepId: string;
      evaluatedUserId: string;
      activityId: string;
      evaluatorId?: string;
      conceptActivityId?: string;
    }
  >(gqlSchema.InvestigationSchema.queries.CORE.getConcept, {
    variables: {
      stepId,
      evaluatedUserId: studentId,
      evaluatorId,
      activityId,
      conceptActivityId,
    },
    onError: (err) => {
      message.error('There was an error loading the concept: ' + err.message || 'Unexpected Error');
      history.goBack();
    },
  });

  const setAddingCommentTrue = useCallback(() => setAddingComment(true), []);
  const setAddingCommentFalse = useCallback(() => setAddingComment(false), []);

  const [submitCommentMutation, { loading: loadingCommentSubmission }] = useMutation<
    { submitPeerReview: GQL_PeerReview },
    {
      conceptActivityId: string;
      conceptClassStepId: string;
      conceptOwnerId: string;
      comment: GQL_SubmitCommentVariables;
      reviewerId?: string;
    }
  >(gqlSchema.InvestigationSchema.mutations.COMMENTS.submitBestConceptPeerReview, {
    onCompleted: () => {
      setAddingComment(false);
    },
    onError: (err) => {
      message.error('There was an error saving comment: ' + err.message || 'Unexpected Error');
    },
    update: (cache, { data }) => {
      const peerReviewResponse = cache.readQuery<{ getBestConceptPeerReview: GQL_PeerReview }>({
        query: gqlSchema.InvestigationSchema.queries.CORE.getBestConceptPeerReview,
        variables: {
          conceptActivityId: conceptActivityId || '',
          conceptClassStepId: stepId,
          conceptOwnerId: studentId,
        },
      });
      if (!peerReviewResponse?.getBestConceptPeerReview) {
        cache.writeQuery({
          query: gqlSchema.InvestigationSchema.queries.CORE.getBestConceptPeerReview,
          variables: {
            conceptActivityId: conceptActivityId || '',
            conceptClassStepId: stepId,
            conceptOwnerId: studentId,
          },
          data: {
            getBestConceptPeerReview: {
              ...data?.submitPeerReview,
            },
          },
          broadcast: true,
        });
      }
    },
    refetchQueries: [
      { query: gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById, variables: { id: investigationId } },
    ],
  });

  const { data, loading: loadingPeerReview } = useQuery<
    { getBestConceptPeerReview: GQL_PeerReview },
    {
      conceptActivityId: string;
      conceptClassStepId: string;
      conceptOwnerId: string;
      reviewerId?: string;
    }
  >(gqlSchema.InvestigationSchema.queries.CORE.getBestConceptPeerReview, {
    variables: {
      conceptActivityId: conceptActivityId || '',
      conceptClassStepId: stepId,
      conceptOwnerId: studentId,
      reviewerId: userId,
    },
    onError: (err) => {
      message.error('There was an error loading the peer-review: ' + err.message || 'Unexpected Error');
    },
  });

  const submitComment = useCallback(
    (
      text: string,
      position: {
        x: number;
        y: number;
      },
    ) => {
      if (conceptActivityId) {
        submitCommentMutation({
          variables: {
            conceptActivityId: conceptActivityId,
            conceptClassStepId: stepId,
            conceptOwnerId: studentId,
            reviewerId: userId,
            comment: {
              text,
              activityId,
              coordinates: {
                page: 0,
                x: position.x / (conceptContainerRef.current?.clientHeight ?? 1),
                y: position.y / (conceptContainerRef.current?.clientWidth ?? 1),
              },
            },
          },
        });
      }
    },
    [stepId, studentId, submitCommentMutation, activityId, conceptContainerRef, conceptActivityId, userId],
  );

  const concept = conceptData?.getConcept;
  const isFileHidden = !concept?.files?.length;
  const loading = loadingConceptData || loadingCommentSubmission || loadingPeerReview;

  return (
    <Layout>
      <PeerReviewSider
        investigationId={investigationId}
        comments={data?.getBestConceptPeerReview?.comments}
        loading={loadingPeerReview}
        submissionVersion={submissionVersion}
      />
      <InvestigationContent investigationId={investigationId}>
        <Row justify="center">
          <S.Column xxl={12} lg={20} sm={23}>
            <h1>Peer Review Concept</h1>
            <h2>
              Give people in other group some ideas about what they can do to make their concepts better by adding a
              comment to the submission below. Be sure to post at least 2 comments. Comments can be suggestions or
              something you liked about the proposal.
            </h2>
            <Button
              text={addingComment ? 'Adding a comment...' : 'Add a Comment'}
              icon={<FiPlus size={20} />}
              onClick={setAddingCommentTrue}
              disabled={addingComment || isFileHidden || loading}
              loading={loading}
            />
          </S.Column>
        </Row>

        <Row justify="center">
          <Col xxl={12} lg={20} sm={23} ref={conceptContainerRef}>
            {addingComment && (
              <DraggableComment
                bounds="parent"
                initialPosition={{ x: 50, y: 50 }}
                onSubmit={submitComment}
                onCancel={setAddingCommentFalse}
                loading={loading}
                disabledOnBlankText
              />
            )}
            <InvestigationConcept loading={loading} concept={concept} />
          </Col>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(BestConceptPeerReviewInvestigationPage);
