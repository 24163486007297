import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Form as AntdForm, Col, Row, Space } from 'antd';
import Button from '../../../shared/Button';
import { GQL_ClassResponse } from '../../../types/class';

import * as S from './styles';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSelectClass: (e?: GQL_ClassResponse) => void;
  classesList?: GQL_ClassResponse[];
}

const AddClassToStudent: React.FC<Props> = (props) => {
  const { visible, setVisible, onSelectClass = () => {}, classesList } = props;
  const [selectedClass, setClass] = useState<GQL_ClassResponse>();

  const onCloseModal = () => {
    setVisible(false);
  };

  const onSubmit = () => {
    onCloseModal();
    onSelectClass(selectedClass);
  };

  const titleInfo = useMemo(
    () => (
      <Row style={{ marginTop: 26 }} justify="center">
        <Col span={24}>
          <S.Title>Select a class for the new student</S.Title>
        </Col>
        <Col span={24}>
          <S.Info>Students will receive a link on their email which</S.Info>
          <S.Info>will guide them to create an account</S.Info>
        </Col>
      </Row>
    ),
    [],
  );

  return (
    <S.CModal visible={visible} title={titleInfo} onCancel={onCloseModal} width={800} footer={null}>
      <Row gutter={24}>
        <Col
          span={20}
          offset={2}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 24, marginTop: 16 }}
        >
          {classesList?.map((item: GQL_ClassResponse, i) => {
            return (
              <S.ClassItems
                key={i}
                onClick={() => setClass(item)}
                className={item.name === selectedClass?.name ? 'selected' : ''}
              >
                {item.name}
              </S.ClassItems>
            );
          })}
        </Col>
        <Space direction="vertical" size={40} />
        <Col span={12} offset={6}>
          <AntdForm.Item>
            <Button block text="Select class" onClick={onSubmit} disabled={!selectedClass} />
          </AntdForm.Item>
        </Col>
      </Row>
    </S.CModal>
  );
};

export default AddClassToStudent;
