import React from 'react';
import Spacer from '../../../shared/Spacer';
import { ITextSubmitContentValue } from '../../../types/investigation';
import { QuestionBlock, Input } from './styles';

interface ITextSubmitProps {
  value: ITextSubmitContentValue;
}

const TextSubmit = (props: ITextSubmitProps) => {
  const { value } = props;
  return (
    <>
      <Spacer />
      <QuestionBlock>{value.title}</QuestionBlock>
      <Spacer />
      <Input className="input" placeholder={value.placeholder} />
    </>
  );
};

export default TextSubmit;
