import { Popover } from 'antd';
import React, { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import Button from '../../../shared/Button';
import Spacer from '../../../shared/Spacer';
import { themeConfig } from '../../../utils/theme';

import * as S from '../StagesSettingsSider/styles';

interface IInvestigationDelete {
  handleDeleteActivity: () => void;
  loading?: boolean;
  title?: string;
}

const InvestigationDelete = (props: IInvestigationDelete) => {
  const { handleDeleteActivity, loading, title = 'Are you sure you want to delete this activity?' } = props;
  const [popoverVisibility, setPopoverVisibility] = useState(false);

  return (
    <Popover
      trigger="click"
      destroyTooltipOnHide
      visible={popoverVisibility}
      onVisibleChange={setPopoverVisibility}
      content={
        <div onClick={(e) => e.stopPropagation()}>
          {title}
          <S.ButtonsContainer>
            <Button
              text="Cancel"
              onClick={() => setPopoverVisibility(false)}
              theme={themeConfig.secondaryColor}
              block
            />
            <Spacer axis="horizontal" />
            <Button
              text="Confirm"
              onClick={handleDeleteActivity}
              theme={themeConfig.primaryOutlined}
              loading={loading}
              block
            />
          </S.ButtonsContainer>
        </div>
      }
    >
      <FiTrash2 size={20} onClick={(e) => e.stopPropagation()} />
    </Popover>
  );
};

export default InvestigationDelete;
