import { Col, message, Row, TablePaginationConfig, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import { SortOptions } from '../../../types/sorting';
import {
  GQL_InvAssignmentRateInput,
  GQL_InvestigationAssignmentRateChartPaginatedResponse,
} from '../../../types/charts';
import { Duration, FilterData } from '../../../types/investigation';
import Filter from '../Filter';
import { FiHelpCircle } from 'react-icons/fi';
import { formatDateTime } from '../../../utils/date';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import RateTable from './RateTable';

interface Props {
  title: string;
  workshopOnly?: boolean;
}

const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};

const InvestigationAssignedRate = (props: Props) => {
  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const [sorting, setSorting] = useState<SortOptions>(SortOptions.DESC);
  const [periodInvestigationAssignedRate, setPeriodInvestigationAssignedRate] = useState<Duration>(defaultDateRange);
  const [filterData, setFilterData] = useState<FilterData>({ organizations: false, teachers: false });

  const { title, workshopOnly } = props;

  const [fetchInvestigationAssignmentRateChart, { data, loading }] = useLazyQuery<
    { getInvestigationAssignmentRateChart: GQL_InvestigationAssignmentRateChartPaginatedResponse },
    { data: GQL_InvAssignmentRateInput }
  >(gqlSchema.ChartSchema.queries.CHARTS.getInvestigationAssignmentRateChart, {
    onError: (err) => {
      message.error('There was an error loading investigation assignment rate: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    const { disciplineIds, ...newFilterData } = filterData;
    const data: GQL_InvAssignmentRateInput = {
      startDate: periodInvestigationAssignedRate.startDate,
      endDate: periodInvestigationAssignedRate.endDate,
      ...newFilterData,
      workshopOnly,
    };
    if (sorting) data.order = sorting;
    fetchInvestigationAssignmentRateChart({
      variables: {
        data,
      },
    });
  }, [fetchInvestigationAssignmentRateChart, periodInvestigationAssignedRate, filterData, workshopOnly, sorting]);

  const onChangeFilterData = useCallback((data: FilterData) => {
    setFilterData(data);
  }, []);

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>{title}</S.Title>
          <Tooltip title="Number of investigations assigned by a teacher.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Filter setDaterange={setPeriodInvestigationAssignedRate} onChangeFilterData={onChangeFilterData} />
        </Col>
        <Col span={24}>
          <RateTable
            workshopOnly={workshopOnly}
            periodInvestigationAssignedRate={periodInvestigationAssignedRate}
            setSorting={setSorting}
            loading={loading}
            pagination={{ ...pagination, hideOnSinglePage: true }}
            setPagination={setPagination}
            data={data?.getInvestigationAssignmentRateChart}
            title={title}
          />
        </Col>
      </Row>
    </>
  );
};

export default InvestigationAssignedRate;
