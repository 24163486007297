import { Col, Layout, Row } from 'antd';
import React from 'react';
import CoreSettingsSider from './CoreSettingsSider';
import InvestigationContent from '../../shared/InvestigationContent';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type Props = RouteComponentProps<{
  investigationId: string;
}>;

const AdiEditInvestigationCorePage: React.FC<Props> = (props) => {
  const { investigationId } = props.match.params;

  return (
    <Layout>
      <CoreSettingsSider investigationId={investigationId} />
      <InvestigationContent>
        <Row justify="center" style={{ height: 'calc(100vh - 80px - 8em)' }}>
          <Col xs={23} md={23} lg={23} xxl={20}></Col>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(AdiEditInvestigationCorePage);
