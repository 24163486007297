import { SUBJECT_TYPES } from '../types/subjects';
import { gradeCleanupRegex } from './gradeBandCompare';
import { sortGradeList } from './utils';

// grade bands to be used with 'Science', 'Math' and 'Engineering' subjects
export const gradeBands: readonly string[] = ['K', '1', '2', '3', '4', '5', 'K-2', '3-5', '6-8', '9-12'] as const;

// grades to be used with 'Science: Texas Edition (2024 Adoption)' subject
export const grades: readonly string[] = ['K', '1', '2', '3', '4', '5', '6', '7', '8', 'tx.9-12'] as const;

// grade bands to be used with 'NGSS Standards' subjects
export const ngssStandardsGradesBands: readonly string[] = ['ngss.K-2', 'ngss.3-5', 'ngss.6-8', 'ngss.9-12'] as const;

// list of grades for use in filters (Only Science, Math and Engineering)
export const gradesFilterList: readonly string[] = Array.from(
  [...gradeBands].map((g) => g.replaceAll(gradeCleanupRegex, '')),
).sort(sortGradeList);

// list of grades for use in filters (Only Science TX Edition)
export const gradesFilterListScienceTexasEdition: readonly string[] = Array.from(
  [...grades].map((g) => g.replaceAll(gradeCleanupRegex, '')),
).sort(sortGradeList);

// list of grades for use in filters (All subjects)
export const gradesFilterListAllBands: readonly string[] = Array.from(
  new Set([...grades, ...gradeBands].map((g) => g.replaceAll(gradeCleanupRegex, ''))),
).sort(sortGradeList);

// list of subjects created from 'SUBJECT_TYPES' enum
export const subjects: readonly string[] = [...Object.values(SUBJECT_TYPES)] as const;

// list of valid URL params for teacher investigation library page
export const teacherInvestigationLibraryPossibleParams: {
  [key: string]: string;
} = {
  math: SUBJECT_TYPES.MATH,
  science: SUBJECT_TYPES.SCIENCE,
  engineering: SUBJECT_TYPES.ENGINEERING,
  workshop: 'Workshop',
  'science-texas-edition': SUBJECT_TYPES.SCIENCE_TEXAS_EDITION,
} as const;

export const teacherInvestigationLibraryPossibleParamsList = Object.keys(teacherInvestigationLibraryPossibleParams);
