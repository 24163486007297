import { Col, Form as AntdForm, message, Row, Tabs, Tag, Button, Input, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  ActionButtons,
  Container,
  MainImage,
  Price,
  RequestButton,
  SideImages,
  TabContent,
  TagWrapper,
  Title,
} from './BookPage.styles';

import { useMutation, useQuery } from '@apollo/client';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import { generatePdfAndUpload } from '../QuotePdf';
import BookRecommendation from './Recommendation/BookRecommendation';
import KitsRecommendation from './Recommendation/KitsRecommendation';
import ShopifyBuyButton from './ShopifyBuyButton';

const { TabPane } = Tabs;

interface metadata {
  city: string;
  name: string;
  email: string;
  state: string;
  country: string;
  zipCode: string;
  jobDescription: string;
}

const BookPage: React.FC = () => {
  const history = useHistory();
  const { bookId } = useParams<{ bookId: string }>();
  const [loading, setLoading] = useState(false);
  const [userCart, setUserCart] = useState<any>(null);
  const [userMetaData, setUserMetaData] = useState<metadata>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = AntdForm.useForm();

  const { data: cartData, refetch: refetchCart } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getUserCart, {
    onError: (error) => {
      message.error(`Error fetching cart data - ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: metadata } = useQuery(
    gqlSchema.ShopifySchema.queries.LIST.getUserMetaData,
    {
      onCompleted: () => {
        setUserMetaData(metadata?.getUserMetaData); // Use the renamed variable
      },
      onError: (error) => {
        message.error(`Error fetching cart data - ${error.message || 'Unexpected Error'}`);
      },
    }
  );



  const [addToCart] = useMutation(gqlSchema.ShopifySchema.mutations.ADD_TO_CART, {
    onCompleted: ({ data }) => {
      // message.success('Product added to cart successfully!',data);
      refetchCart();
    },
    onError: (error) => {
      message.error(`Error adding product to cart - ${error.message || 'Unexpected Error'}`);
    },
  });
  const [updateCartItem] = useMutation(gqlSchema.ShopifySchema.mutations.UPDATE_CART_ITEM, {
    onCompleted: () => {
      message.success('Cart item updated successfully!');
      refetchCart();
    },
    onError: (error) => {
      message.error(`Error updating cart item - ${error.message || 'Unexpected Error'}`);
    },
  });
  const [sendQuoteEmail] = useMutation<{ sendQuoteEmail: { pdfUrl: string, metadata: string } }>(
    gqlSchema.ShopifySchema.mutations.SEND_QUOTE_EMAIL,
    {
      onCompleted: () => {
        message.success('Quote request sent successfully!');
        setTimeout(() => {
          history.push('/bookquoteList');
        }, 5000);
      },
      onError: (error) => {
        message.error(`Error sending quote request - ${error.message || 'Unexpected Error'}`);
      },
    },
  );



  useEffect(() => {
    if (cartData) {
      const cartItem = cartData.getUserCart?.cartItem?.map((item: any) => {
        return { ...item, unitPrice: parseFloat(item.unitPrice), quantity: parseInt(item.quantity) };
      }) || [];
      setUserCart({ ...cartData.getUserCart, cartItem });
    }
  }, [cartData]);

  const { data } = useQuery(gqlSchema.BookSchema.queries.FetchBookById, {
    variables: {
      id: bookId,
    },
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: quoteData } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getQuoteData, {
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });

  const onSubmitForm = async (values: any) => {
    setIsModalVisible(false);
    await handleRequestQuote(values);
  };




  let quoteDetails = quoteData?.getQuoteData;
  const bookData = data?.fetchBookById;


  const [saveQuoteToS3] = useMutation(gqlSchema.ShopifySchema.mutations.UPLOAD_QUOTE, {
    onCompleted: (data) => {
      console.log('File uploaded successfully:', data);
    },
    onError: (err) => {
      message.error('There was an error uploading your file: ' + err.message);
    },
  });

  const handleRequestQuoteButtonClick = () => {
    setIsModalVisible(true);
  };

  // Handle form submission inside the modal
  const handleRequestQuote = async (formData: any) => {
    setLoading(true);  // Show loading state

    try {

      if (!quoteDetails) {
        throw new Error('Quote details are not available.');
      }

      const metadata = `${formData.city || "N/A"} 
            ${formData.state || "N/A"} ${formData.country || "N/A"} ${formData.zipcode || "N/A"} `
      quoteDetails = {
        ...quoteDetails,
        teacherName: formData?.name,
        email: formData?.email,
        shippingAddress: metadata,
      }

      const pdfBlob = await generatePdfAndUpload({ ...quoteDetails, userCart });

      if (!(pdfBlob instanceof Blob)) {
        throw new Error('Generated PDF is not a Blob.');
      }

      const file = new File([pdfBlob], 'quote.pdf', { type: 'application/pdf' });
      

      const link = await saveQuoteToS3({ variables: { file } });
      const pdfUrl = link?.data?.saveQuoteToS3;

      await sendQuoteEmail({ variables: { pdfUrl, metadata: JSON.stringify(formData) } });
      message.success('Quote request sent successfully!');
      setIsModalVisible(false);  // Close the modal after submission
    } catch (err) {
      message.error(`Error sending quote request: ${err || 'Unexpected Error'}`);
    } finally {
      setLoading(false);
    }
  };


  const addToCartCb = () => {
    addToCart({
      variables: {
        productId: bookData.shopifyProductId,
        quantity:
          (cartData?.getUserCart.cartItem.find((item: any) => item.productId === bookData.shopifyProductId)?.quantity ||
            0) + 1,
      },
    });
  };

  const handleUpdateCartItem = (shopifyProductId: string, quantity: number) => {
    // const productId = cartData.getUserCart.cartItem.find((item: any) => item.productId === shopifyProductId)?.id;
    if (shopifyProductId && quantity >= 0) {
      updateCartItem({ variables: { productId: shopifyProductId, quantity } });
    }
  };
  const handleRemoveCartItem = (shopifyProductId: string) => {
    updateCartItem({ variables: { productId: shopifyProductId, quantity: 0 } });
  };

  const increaseQuantity = (shopifyProductId: string) => {

    const item = userCart.cartItem.find((item: any) => item.shopifyId === shopifyProductId);
    if (item) {
      handleUpdateCartItem(shopifyProductId, item.quantity + 1);
    }

  };

  const decreaseQuantity = (shopifyProductId: string) => {
    const item = userCart.cartItem.find((item: any) => item.shopifyId === shopifyProductId);
    if (item && item.quantity > 1) {
      handleUpdateCartItem(shopifyProductId, item.quantity - 1);
    } else if (item && item.quantity === 1) {
      handleRemoveCartItem(shopifyProductId);
    }
    else {
      message.error('Quantity cannot be less than 1');
    }
  };
  useEffect(() => {
    if (userMetaData) {
      form.setFieldsValue({
        name: userMetaData?.name || '',
        email: userMetaData?.email,
        jobDescription: userMetaData?.jobDescription || '',
        state: userMetaData?.state || '',
        country: userMetaData?.country || '',
        city: userMetaData?.city || '',
        zipCode: userMetaData?.zipCode || '',
      });
    }
  }, [userMetaData, form]);

  return (
    <Container>
      <FaArrowLeft onClick={() => history.push('/booklib')} size={16} />
      <Row gutter={24}>
        <Col span={10} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <SideImages>
            <img
              src={
                bookData?.imageUrl?.[1] ||
                'https://cdn.shopify.com/s/files/1/0598/7762/1933/files/1_909d1118-8feb-4e26-8e53-e8675ba61206.png?v=1727369524'
              }
              alt="Side Image 1"
            />
            <img
              src={
                bookData?.imageUrl?.[2] ||
                'https://cdn.shopify.com/s/files/1/0598/7762/1933/files/1_909d1118-8feb-4e26-8e53-e8675ba61206.png?v=1727369524'
              }
              alt="Side Image 2"
            />
          </SideImages>
          <MainImage
            src={
              bookData?.imageUrl?.[0] ||
              'https://cdn.shopify.com/s/files/1/0598/7762/1933/files/1_909d1118-8feb-4e26-8e53-e8675ba61206.png?v=1727369524'
            }
            alt="Main Book Cover"
          />
        </Col>
        <Col span={14} style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', gap: '8px' }}>
          <Title>{bookData?.title}</Title>
          <TagWrapper>
            {bookData?.standard && <Tag
              color="#20BC89"
              style={{
                color: '#20BC89',
                fontWeight: 600,
                borderColor: '#20BC89',
                backgroundColor: 'white',
                borderRadius: '16px',
              }}
            >
              {bookData?.standard}
            </Tag>}
            <Tag
              color="#4367E9"
              style={{
                color: '#4367E9',
                fontWeight: 600,
                borderColor: '#4367E9',
                backgroundColor: 'white',
                borderRadius: '16px',
              }}
            >
              {bookData?.investigations?.length || 0} Investigation
            </Tag>
          </TagWrapper>
          <Price>${bookData?.price}</Price>
          <ActionButtons>
            <div>
              {bookData && bookData?.shopifyProductId && userCart && (
                <ShopifyBuyButton
                  key={bookId}
                  productId={bookData.shopifyProductId}
                  addToCart={addToCartCb}
                  cartData={userCart}
                  decreaseQuantity={decreaseQuantity}
                  increaseQuantity={increaseQuantity}
                  handleRequestQuote={handleRequestQuoteButtonClick}
                  handleUpdateCartItem={handleUpdateCartItem}
                />
              )}
            </div>
            <RequestButton onClick={handleRequestQuoteButtonClick} disabled={loading}>
              Request A Quote
            </RequestButton>
          </ActionButtons>
          <div>
            <Modal
              title="Update Shipping Address"
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={null}
            >
              <AntdForm
                form={form}
                onFinish={onSubmitForm}
                layout="vertical"
              >
                <AntdForm.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input placeholder="Enter your name" type="text" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                  ]}
                >
                  <Input placeholder="Type your email here..." disabled />
                </AntdForm.Item>

                <AntdForm.Item label="Job Description" name="jobDescription">
                  <Input placeholder="Enter your job description" type="text" />
                </AntdForm.Item>

                <AntdForm.Item label="State" name="state">
                  <Input placeholder="Enter your state" type="text" />
                </AntdForm.Item>

                <AntdForm.Item label="Country" name="country">
                  <Input placeholder="Enter your country" type="text" />
                </AntdForm.Item>

                <AntdForm.Item label="City" name="city">
                  <Input placeholder="Enter your city" type="text" />
                </AntdForm.Item>

                <AntdForm.Item label="ZipCode" name="zipCode">
                  <Input placeholder="Enter your zip code" type="number" />
                </AntdForm.Item>

                <AntdForm.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                    style={{
                      width: '50%',
                      margin: '0.5em auto 0',
                      textAlign: 'center',
                      display: 'block',
                    }}
                  >
                    Submit
                  </Button>
                </AntdForm.Item>
              </AntdForm>

            </Modal>
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Description" key="1">
              <TabContent dangerouslySetInnerHTML={{ __html: bookData?.description || '' }} />
            </TabPane>
            <TabPane tab="Investigation List" key="2">
              <TabContent>
                <ul>
                  {bookData?.investigations?.map((investigation: any, idx: number) => (
                    <li key={idx}>{`Investigation ${idx + 1}: ${investigation.title}`}</li>
                  ))}
                </ul>
              </TabContent>
            </TabPane>
          </Tabs>
        </Col>
      </Row>

      <KitsRecommendation productId={bookId} />
      <BookRecommendation bookId={bookId} />
    </Container>
  );

};

export default BookPage;
