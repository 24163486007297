import { Row } from 'antd';
import styled from 'styled-components';

export const HeaderContainer = styled(Row)`
  margin-bottom: 24px;
  min-height: 45px;

  .ant-input-affix-wrapper {
    height: 100%;

    svg {
      min-height: 18px;
      min-width: 18px;
      margin-right: 8px;
      margin-bottom: 2px;
    }
  }
`;

export const FilterChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7b8cea;
  color: white;
  border-radius: 6px;
  padding: 2px 8px;
  margin-right: 8px;
  max-width: 100%;

  svg {
    margin-left: 8px;
    min-width: 16px;
    min-height: 16px;
  }

  span {
    font-size: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const FilterContainer = styled.div`
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  max-width: fit-content;
  color: #3f3e38;

  & > span {
    padding-left: 10px;
    padding-top: 2px;
    margin-right: 8px;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  & > svg {
    min-width: 18px;
    min-height: 18px;
    @media only screen and (max-width: 1200px) {
      margin-right: 8px;
    }
  }
`;

export const BackIconButton = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;
  }
`;
