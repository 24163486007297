import styled from 'styled-components';

export const Skeleton = styled.div`
  margin: 0.1em 0;
  height: calc(100vh - 80px - 8em);

  .ant-skeleton-input {
    width: 250px;
    margin-bottom: 16px;
    border-radius: 4px;
  }

  .ant-skeleton-input-sm {
    width: 150px;
  }

  .ant-skeleton-input-lg {
    width: 200px;
    margin-top: 16px;
  }

  .ant-skeleton-button {
    margin: 0 32px;
    width: 128px;
    height: 40px;
  }

  .ant-skeleton-image {
    display: inline-flex;
  }
`;
