import styled from 'styled-components';
import Button from '../../shared/Button';

export const Container = styled.div<{ size?: 'full' | 'with-navbar' }>`
  padding: 3em;
  ${({ size }) => (size === 'with-navbar' ? 'min-height: calc(100vh - 80px);' : 'min-height: 100vh;')}
  background: linear-gradient(to top right, rgb(233, 233, 233), white, rgb(233, 233, 233));
`;

export const TitleFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  @media screen and (max-width: 670px) {
    flex-flow: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const HiddenButton = styled(Button)`
  opacity: 0;
  pointer-events: none;

  @media screen and (max-width: 670px) {
    display: none;
  }
`;

export const Title = styled.h1<{ noMargin?: boolean }>`
  font-size: 32px;
  text-align: center;
  ${({ noMargin }) => (noMargin ? 'margin: 0;' : 'margin-bottom: 32px;')}
  line-height: 1;
  font-weight: 600;

  @media screen and (max-width: 670px) {
    width: 100%;
  }
`;

export const Card = styled.div<{
  isActive: boolean;
}>`
  padding: 24px;
  border-radius: 8px;
  background: white;
  border: 1px solid ${({ isActive }) => (isActive ? '#4367E9' : 'transparent')};
  box-shadow: ${({ isActive }) =>
    isActive ? '0px 4px 18px 3px rgba(67, 103, 233, 0.2)' : '0px 4px 10px 3px rgba(0, 0, 0, 0.1)'};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100%;
  transition: background 0.25s, border 0.25s;
  min-width: 420px;
  box-sizing: border-box;

  @media screen and (max-width: 450px) {
    min-width: 84vw;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  text-align: left;
  line-height: 100%;
`;

export const CardTitleSuffix = styled.span`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.2px;
  margin-left: 4px;
`;

export const CardSubtitle = styled.p`
  font-size: 18px;
  margin: 0px;
  font-weight: normal;
  color: #777;
  line-height: 100%;
`;

export const Pill = styled.div<{
  color: 'blue' | 'purple';
}>`
  padding: 8px 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ color }) => (color === 'blue' ? '#C5D1FF' : '#AB61E5')};
  color: ${({ color }) => (color === 'blue' ? '#4367E9' : 'white')};
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
`;

export const HRule = styled.span`
  display: block;
  margin: 32px 0px;
  height: 1px;
  width: 100%;
  background: #d9d9d9;
`;

export const FeatureList = styled.ul`
  padding-left: 0.25em;
  list-style-type: none;
  margin-bottom: 2em;
`;

export const ListItem = styled.li`
  font-size: 1.2em;
  margin-bottom: 12px;
  display: flex;
`;

export const ListItemIcon = styled.div<{
  bgColor: string;
  color: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 14px;
  margin-right: 10px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-size: 16px;
`;

export const ListItemContent = styled.span`
  max-width: calc(100% - 38px);
  font-size: 16px;
`;

export const Note = styled.p`
  text-align: right;
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
  font-style: italic;
  color: #777;
`;

export const ModalBody = styled.div`
  padding: 0px 76px 36px;
`;

export const ModalTitle = styled.h2`
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
`;

export const ModalSubtitle = styled.h3`
  font-size: 20px;
  text-align: center;
  color: #767676;
  line-height: 1;
  margin-bottom: 40px;
`;
