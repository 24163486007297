import React from 'react';
import {
  bool,
  Cast,
  ExtensionTag,
  ResolvedPos,
  isElementDomNode,
  ApplySchemaAttributes,
  NodeAttributes,
  NodeExtensionSpec,
  CommandFunction,
  NodeExtension,
} from 'remirror/core';
import Formula from './Formula';

export class MathExtension extends NodeExtension {
  get name() {
    return 'math' as const;
  }
  readonly tags = [ExtensionTag.InlineNode];
  createNodeSpec(extra: ApplySchemaAttributes): NodeExtensionSpec {
    return {
      inline: true,
      group: 'inline',
      attrs: {
        ...extra.defaults(),
        formula: { default: ' ' },
      },
      draggable: true,
      parseDOM: [
        {
          tag: 'formula',
          getAttrs: (element) => (isElementDomNode(element) ? getImageAttributes({ element, parse: extra.parse }) : {}),
        },
      ],
      toDOM: (node) => {
        return ['formula', { ...extra.dom(node), ...node.attrs }];
      },
    };
  }

  createCommands() {
    return {
      insertFormula: (attributes: NodeAttributes<MathExtensionAttributes>): CommandFunction => ({ tr, dispatch }) => {
        const { selection } = tr;
        const position = hasCursor(selection) ? selection.$cursor.pos : (selection as any).$to.pos;
        const node = this.type.create(attributes);

        dispatch?.(tr.insert(position, node));

        return true;
      },
    };
  }

  ReactComponent = (props: any) => <Formula {...props} />;
}

function getImageAttributes({ element, parse }: { element: HTMLElement; parse: ApplySchemaAttributes['parse'] }) {
  return {
    ...parse(element),
    formula: element.getAttribute('formula') ?? null,
  };
}

function hasCursor<T extends object>(argument: T): argument is T & { $cursor: ResolvedPos } {
  return bool(Cast(argument).$cursor);
}

export interface MathExtensionAttributes {
  formula?: string;
}

declare global {
  namespace Remirror {
    interface AllExtensions {
      math: MathExtension;
    }
  }
}
