import React from 'react';
import * as S from './styles';
import { Col, Progress, Row, Spin } from 'antd';

interface CardProps {
  title: string;
  total: number;
  change: number;
  loading: boolean;
}

const Card = (props: CardProps) => {
  const { title, total, change, loading } = props;
  console.log('L14 total ===', total);
  const percentage = total ? (change * 100) / total : 0;
  return (
    <S.Card>
      {loading ? (
        <Spin data-cy="shared-charts-activeusers-item-spin" size="large" />
      ) : (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <S.Title style={{ marginTop: 10 }}>{title}</S.Title>
          </Col>
          <Col span={24}>
            <Progress
              data-cy={`shared-charts-subscriptionitem-${title.toLowerCase()}`}
              data-percent={parseFloat(percentage.toFixed(2))}
              type="dashboard"
              percent={parseFloat(percentage.toFixed(2))}
              width={130}
              format={(percent) => `${percent}%`}
            />
          </Col>
          <Col span={8}>
            <Row className="card--align-center">
              <S.Title>Total</S.Title>
            </Row>
            <Row className="card--align-center">
              <S.Text>{total}</S.Text>
            </Row>
          </Col>
          <Col span={8}>
            <Row className="card--align-center">
              <S.Title>Change</S.Title>
            </Row>
            <Row className="card--align-center">
              <S.Text value={change}>{change}</S.Text>
            </Row>
          </Col>
          <Col span={8}>
            <Row className="card--align-center">
              <S.Title>% Change</S.Title>
            </Row>
            <Row className="card--align-center">
              <S.Text value={change}>{`${percentage.toFixed(2)}%`}</S.Text>
            </Row>
          </Col>
        </Row>
      )}
    </S.Card>
  );
};

export default Card;
