import { useQuery } from '@apollo/client';
import { Col, Row, Dropdown, Menu, message, Tabs } from 'antd';
import Papa from 'papaparse';
import React, { useCallback, useMemo, useState } from 'react';
import { FiChevronDown, FiDownload } from 'react-icons/fi';
import { withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import Button from '../../shared/Button';
import { downloadCsv } from '../../utils/files';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';
import { GQL_SSOOrganizationDetailsResponse } from '../../types/organization';
import OrganizationSyncListTabler from './Table';
const { TabPane } = Tabs;

const OrganizationSyncList = () => {
  const [activeKey, setActiveKey] = useState('clever');
  const [dataCLasslinkList, setDataCLasslinkList] = useState<GQL_SSOOrganizationDetailsResponse[]>([]);
  const [dataCleverList, setDataCleverList] = useState<GQL_SSOOrganizationDetailsResponse[]>([]);

  const { loading: loadingCLasslink } = useQuery<{
    getClasslinkOrganizations: GQL_SSOOrganizationDetailsResponse[];
  }>(gqlSchema.ClassLinkSchema.queries.LIST.getClasslinkOrganizations, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setDataCLasslinkList(data?.getClasslinkOrganizations || []);
    },
    onError: (err) => {
      message.error('There was an error loading the organizations for ClassLink: ' + err.message || 'Unexpected Error');
    },
  });

  const { loading: loadingClever } = useQuery<{
    getCleverOrganizations: GQL_SSOOrganizationDetailsResponse[];
  }>(gqlSchema.CleverSchema.queries.LIST.getCleverOrganizations, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setDataCleverList(data?.getCleverOrganizations || []);
    },
    onError: (err) => {
      message.error('There was an error loading the organizations for Clever: ' + err.message || 'Unexpected Error');
    },
  });

  const exportOrganizations = useCallback(() => {
    const headers = [['Name', 'First Admin', 'Second Admin', 'Sync Config']];
    const data = activeKey === 'classLink' ? dataCLasslinkList : dataCleverList;
    const csvData =
      data?.map((organization: GQL_SSOOrganizationDetailsResponse) => {
        let syncConfig = 'On Demand';
        if (organization.scheduleConfig.daily) syncConfig = 'Daily';
        if (organization.scheduleConfig.weekly) syncConfig = 'Weekly';
        if (organization.scheduleConfig.monthly) syncConfig = 'Monthly';

        return [
          organization.name,
          organization.organizationAdmins[0]?.name,
          organization.organizationAdmins[1]?.name,
          syncConfig,
        ];
      }) || [];

    downloadCsv(Papa.unparse([...headers, ...csvData]), `Organizations.csv`);
  }, [activeKey, dataCLasslinkList, dataCleverList]);

  const exportMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={exportOrganizations}>
          .csv
        </Menu.Item>
      </Menu>
    ),
    [exportOrganizations],
  );

  return (
    <S.Container>
      <Row gutter={[24, 24]}>
        <Col xxl={{ span: 3, offset: 3 }} xl={4} lg={5} md={6} sm={12} xs={12}>
          <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
            <Button
              padding="4px 5px"
              text={
                <>
                  Export as
                  <FiChevronDown style={{ marginLeft: 3 }} />
                </>
              }
              theme={themeConfig.noColor}
              icon={<FiDownload />}
              block
            />
          </Dropdown>
        </Col>
        <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24}>
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={(activeKey: string) => setActiveKey(activeKey)}
          >
            {false && (
              <TabPane tab={<S.TitleTab>ClassLink</S.TitleTab>} key="classLink">
                <OrganizationSyncListTabler
                  data={dataCLasslinkList}
                  loading={loadingCLasslink}
                  type="classlink"
                  setDataCLasslinkList={(data) => setDataCLasslinkList(data)}
                  setDataCleverList={(data) => setDataCleverList(data)}
                />
              </TabPane>
            )}
            <TabPane tab={<S.TitleTab>Clever</S.TitleTab>} key="clever">
              <OrganizationSyncListTabler
                data={dataCleverList}
                loading={loadingClever}
                type="clever"
                setDataCLasslinkList={(data) => setDataCLasslinkList(data)}
                setDataCleverList={(data) => setDataCleverList(data)}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </S.Container>
  );
};

export default withRouter(OrganizationSyncList);
