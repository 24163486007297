import React, { useState } from 'react';

import { Col, Row, Tabs } from 'antd';
import Current from './Current';
import PendingInvites from '../TeacherStudents/PendingInvites';

import * as S from './styles';

const TeacherStudents = () => {
  const [activeKey, setActiveKey] = useState('current');

  // render
  return (
    <S.Container>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={(activeKey: string) => setActiveKey(activeKey)}
          >
            <Tabs.TabPane tab={<S.TitleTab>Current Users</S.TitleTab>} key="current">
              <Current />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<S.TitleTab>Pending Invites</S.TitleTab>} key="pending">
              <PendingInvites />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </S.Container>
  );
};

export default TeacherStudents;
