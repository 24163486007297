import styled from 'styled-components';
import { Modal } from 'antd';

export const Title = styled.h1`
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
`;
export const CModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 5px;
    max-height: 75vh;
    overflow-y: auto;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.82em;
  margin: 0;
  text-align: center;
`;

export const DownloadTemplate = styled.div`
  display: table-cell;
  vertical-align: middle;
  position: absolute;
  top: 50%;
`;

export const ClassItems = styled.div`
  display: flex;
  border: 1px solid gray;
  border-radius: 8px;
  padding: 16px 24px;
  width: 100%;

  &:hover {
    cursor: pointer;
    border: 1px solid #4367e9;
  }
`;
