
import React from 'react';
import * as S from './styles';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { message } from 'antd';

interface RouteParams {
  id: string;
}

const OrderDetails: React.FC = () => {

  const { id } = useParams<RouteParams>();
  const { data, loading, error } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getOrderById, {
    variables: {
      id: id,
      
    },
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading books</p>;


 
  const orderData = data.getOrderById;
  return (
    <S.Container>
      <S.BackButton>&larr; Your Orders</S.BackButton>
      
      <S.OrderInfo>
        <S.OrderId>Order ID: {orderData.shopifyOrderId}</S.OrderId>
        <S.Status>{orderData.status}</S.Status>
        <S.StatusNote>{orderData.statusNote}</S.StatusNote>
      </S.OrderInfo>

      <S.DetailsContainer>
        <S.ShippingInfo>
          <h3>Shipping Address</h3>
          <p>{orderData.shippingAddress}</p>
          <p>{orderData.shippingAddress}</p>
          <h3>Fulfill By</h3>
          <p>{orderData.fulfillBy}</p>
          <h3>Contact Information</h3>
          <p>{orderData.contactEmail}</p>
          <p>p: {orderData.contactPhone||"656565656"}</p>
        </S.ShippingInfo>

        <S.Summary>
          <h3>Order Summary</h3>
          {orderData.items.map((item:any) => (
            <S.Item key={item.id}>
              <S.ItemImage src={item.imageUrl[0]} alt={item.name} />
              <S.ItemDetails>
                <p>{item.name}</p>
                <p>Qty: {item.quantity}</p>
              </S.ItemDetails>
              <S.ItemPrice>{item.price}</S.ItemPrice>
            </S.Item>
          ))} 
          <S.Pricing>
            <S.PricingRow>
              <span>SubTotal</span>
              <span>{orderData.subTotal}</span>
            </S.PricingRow>
            <S.PricingRow>
              <span>Shipping Fees</span>
              <span>{orderData.shippingFees}</span>
            </S.PricingRow>
            <S.PricingRow bold>
              <span>Total</span>
              <span>{orderData.total}</span>
            </S.PricingRow>
          </S.Pricing>
          <S.DownloadButton>Download Invoice</S.DownloadButton>
        </S.Summary>
      </S.DetailsContainer>
    </S.Container>
  );
};


export default OrderDetails;