import React, { useMemo } from 'react';
import { Row, Col, Empty } from 'antd';
import startCase from 'lodash/startCase';
import DashboardRow from '../../DashboardRow';
import Item from './Item';

import { GQL_InvestigationDashboard } from '../../../types/investigation';
import DashboardLoadingCard from '../../DashboardLoadingCard';

interface IFinishedInvestigationsRow {
  investigations?: GQL_InvestigationDashboard[];
  loading: boolean;
  error?: string;
}

const FinishedInvestigationsRow = (props: IFinishedInvestigationsRow) => {
  const { investigations, loading, error } = props;
  const finishedInvestigations = useMemo(() => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
        </Row>
      );
    } else if (!investigations?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Finished Investigations" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {investigations.map((item) => {
            return (
              <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
                <Item
                  grade={item?.grade}
                  engagement={(item?.engagementGrades / 20)?.toPrecision(2) || 0}
                  progressTitle="Report Score"
                  stars={(item.feedbackGrades / 20)?.toPrecision(2) || 0}
                  date={item.dueDate}
                  investigationName={`${startCase(item.discipline?.name)}: ${item.title}`}
                  investigationId={item.id}
                />
              </Col>
            );
          })}
        </Row>
      );
    }
  }, [investigations, loading]);

  return <DashboardRow title="Finished Investigations" items={finishedInvestigations} error={error} />;
};

export default FinishedInvestigationsRow;
