import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { CSSProperties } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Spacer from '../Spacer';

import * as S from './styles';

interface ISider {
  loading?: boolean;
  title?: React.ReactNode;
  highlighted?: boolean;
  backUrl?: string;
  padding?: string;
  marginBottomTitle?: string;
  style?: CSSProperties;
  showBackButton?: boolean;
}

const LoadingSider = () => (
  <>
    <Row justify="center">
      <Col span={20}>
        <Skeleton.Input active />
      </Col>
    </Row>
    <Spacer axis="vertical" size={32} />
    <Row gutter={[20, 20]} align="middle">
      <Col>
        <Skeleton.Avatar active />
      </Col>
      <Col span={14}>
        <Skeleton.Input active />
      </Col>
    </Row>
    <Spacer axis="vertical" />
    <Row gutter={[20, 15]} align="middle">
      <Col>
        <Skeleton.Avatar active />
      </Col>
      <Col span={14}>
        <Skeleton.Input active />
      </Col>
    </Row>
    <Spacer axis="vertical" />
    <Row>
      <Col span={18} offset={3}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
    <Spacer axis="vertical" size={8} />
    <Row>
      <Col span={18} offset={3}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
    <Spacer axis="vertical" size={8} />
    <Row>
      <Col span={18} offset={3}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
  </>
);

const Sider: React.FC<ISider> = (props) => {
  const { loading, title, highlighted, backUrl, padding, marginBottomTitle, showBackButton } = props;
  const history = useHistory();

  const onBack = () => {
    if (backUrl) history.push(backUrl);
    else history.goBack();
  };

  return (
    <S.StyledSider width={340} $highlighted={highlighted} $padding={padding} style={props.style} theme="light">
      {title && (
        <S.Title
          onClick={backUrl || showBackButton ? onBack : () => {}}
          clickable={!!backUrl || showBackButton}
          $mBottom={marginBottomTitle}
          data-cy="components-sider-title"
        >
          {(backUrl || showBackButton) && <FiArrowLeft />}
          {title}
        </S.Title>
      )}
      {loading ? <LoadingSider /> : props.children}
    </S.StyledSider>
  );
};

export default Sider;
