/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { Col, Modal, Row, message, Popconfirm } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { themeConfig } from '../../utils/theme';
import Button from '../Button';
import timesupImage from '../../assets/timesup.svg';
import { GQL_MeResponse } from '../../types/login';
import useRoleSelectionModal from '../../hooks/useRoleSelectionModal';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_ConvertToPaidUserResponse, GQL_ConvertToPaidUserResponseChilds } from '../../types/subscription';

const getUserSubscription = (payload: { user?: GQL_MeResponse; activeRole?: string }) => {
  const { user, activeRole } = payload;
  const subscriptions = [...(user?.subscriptions || [])].sort((a, b) => b.endDate - a.endDate);

  if (activeRole === 'facilitator' || (!activeRole && user?.roles?.includes('facilitator'))) {
    // sort by end date so when we loop through the array, the most recent subscription is at the top
    const orgSubscription = subscriptions.find((subscription) => subscription.organizationId !== null);
    if (orgSubscription && user?.preferredSubscription === 'organization') {
      return orgSubscription;
    }
    return subscriptions.find((s) => s.type === 'facilitator');
  } else {
    const orgSubscription = subscriptions.find((subscription) => subscription.organizationId !== null);
    if (orgSubscription && user?.preferredSubscription === 'organization') {
      return orgSubscription;
    }
    return subscriptions.find((s) => s.type === 'standard');
  }
};

const ExpiredSubscriptionModal = () => {
  const {
    user,
    signOut,
    isOrganizationAdiAdmin,
    isTeacherOrFacilitator,
    activeRole,
    belongsToOrganization,
  } = useAuth();
  const { openModal } = useRoleSelectionModal();
  const userSubscription = getUserSubscription({ user, activeRole });
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';   

  const endDate = useMemo(() => {
    const stripeSubscription = user?.stripeSubscription;

    if (stripeSubscription?.endDate && user?.preferredSubscription !== 'organization') {
      return moment(stripeSubscription?.endDate, 'x').toDate().valueOf();
    }

    return userSubscription?.endDate || null;
  }, [user, userSubscription]);

  const subscriptionExpired = (endDate && endDate < Date.now()) || false;
  const subscriptionNotStarted = (userSubscription?.startDate && userSubscription.startDate > Date.now()) || false;

  const subscriptionActive = !(subscriptionExpired || subscriptionNotStarted);
  const rolesWithSubscription = ['teacher', 'facilitator', 'organization_admin'];
  const showSubscriptionModal =
    user &&
    (activeRole ? rolesWithSubscription.includes(activeRole) : isOrganizationAdiAdmin || isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) &&
    !subscriptionActive;

  const showConvertToPaidUser = useMemo(() => {
    return !belongsToOrganization && !user?.stripeSubscription;
  }, [user, belongsToOrganization]);

  const handleConvertToPaidSubscription = () => {
    const userId = user?.id as string;

    if (userId && showConvertToPaidUser) {
      convertToPaidSubscription({
        variables: {
          userId,
        },
      });
    }

    if (userId && belongsToOrganization) {
      convertOrganizationUserToPaidSubscription({
        variables: {
          userId,
          organizationId: user.subscription?.organizationId,
        },
      });
    }
  };

  const handleSuccessResponse = (response: GQL_ConvertToPaidUserResponse, isOrg = false) => {
    if (!response.metersReStarted && !response.subscriptionUpdated && !response.stripeEnrolled) {
      message.error(`Error while enrolling user ${user?.name}'s into a Starter Account`);
      return;
    }

    if (!isOrg) {
      if (response.metersReStarted && response.subscriptionUpdated && response.stripeEnrolled) {
        message.success(`${user?.name}'s subscription updated successfully`);
        window.location.reload();
        return;
      }
    }

    if (
      response.metersReStarted &&
      response.subscriptionUpdated &&
      response.stripeEnrolled &&
      response.organizationRemoved
    ) {
      message.success(`${user?.name}'s removed from organization and subscription updated successfully`);
      window.location.reload();
      return;
    }
  };

  const [convertToPaidSubscription, { loading: convertToPaidSubscriptionLoading }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.selfConvertToPaidSubscription,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: gqlSchema.UserSchema.queries.DETAILS.getUserDetails, variables: { data: { id: user?.id } } },
      ],
      onCompleted: ({ selfConvertToPaidSubscription }: GQL_ConvertToPaidUserResponseChilds) => {
        handleSuccessResponse(selfConvertToPaidSubscription);
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [
    convertOrganizationUserToPaidSubscription,
    { loading: selfConvertOrganizationUserToPaidSubscriptionLoading },
  ] = useMutation(gqlSchema.UserSchema.mutations.SUBSCRIPTION.selfConvertOrganizationUserToPaidSubscription, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: gqlSchema.UserSchema.queries.DETAILS.getUserDetails, variables: { data: { id: user?.id } } },
    ],
    onCompleted: ({ selfConvertOrganizationUserToPaidSubscription }: GQL_ConvertToPaidUserResponseChilds) => {
      handleSuccessResponse(selfConvertOrganizationUserToPaidSubscription, true);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <Modal title={null} visible={showSubscriptionModal} footer={null} closable={false} width={800} zIndex={1100}>
      <div className="centralized-modal">
        <img src={timesupImage} alt="A clock surrounded by geometric forms" style={{ marginBottom: 16 }} />
        <h1>{subscriptionNotStarted ? "Your subscription hasn't started yet!" : 'Your subscription time is up!'}</h1>
        <p className="modal-text">
          {subscriptionNotStarted
            ? 'Your subscription is set to start on ' + new Date(userSubscription?.startDate || 0).toDateString()
            : ' Please renew your subscription to continue using the ADI Learning Hub.'}
        </p>
        {subscriptionExpired && (
          <div
            style={{
              margin: '25px 0',
              position: 'relative',
              zIndex: 1101,
              justifyContent: 'center',
              display: 'flex',
              width: '100%',
            }}
            id="popconfirm-account-update"
          >
            <Popconfirm
              placement="topLeft"
              title="To update your account to a starter account, all of your current data will be deleted. Are you sure you want to move forward with converting your account?"
              onConfirm={() => handleConvertToPaidSubscription()}
              okText="Yes"
              cancelText="No"
              openClassName={'test-open-class'}
              getPopupContainer={() => {
                const container = document.getElementById('popconfirm-account-update') as HTMLElement;
                return container;
              }}
            >
              <Button
                theme={themeConfig.secondaryOutlined}
                text={'Convert to Starter Account'}
                minHeight={40}
                loading={convertToPaidSubscriptionLoading || selfConvertOrganizationUserToPaidSubscriptionLoading}
                size="large"
              />
            </Popconfirm>
          </div>
        )}
        <Row style={{ marginTop: 24 }} gutter={16}>
          {user?.roles?.length > 1 && (
            <Col>
              <Button text="Change Role" theme={themeConfig.secondaryColor} onClick={() => openModal()} />
            </Col>
          )}

          <Col>
            <Button
              data-cy="shared-expiredsubscriptionmodal-signout-button"
              text="Sign Out"
              theme={themeConfig.primaryOutlined}
              onClick={signOut}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ExpiredSubscriptionModal;
