export interface GQL_GoogleClassroomCourse {
  id: string;
  name: string;
  section: string;
  descriptionHeading: string;
  description: string;
  room: string;
  ownerId: string;
  creationTime: string;
  updateTime: string;
  enrollmentCode: string;
  workType: string;
  courseWorkType: string;
  alternateLink: string;
  teacherGroupEmail: string;
  teacherEmail: string;
  courseGroupEmail: string;
  guardiansEnabled: boolean;
  calenderId: string;
  startDate: string;
  endDate: string;
  dueDate?: {
    year: number;
    month: number;
    day: number;
  };
  numberOfStudents: number;
  alternativeLink: string;
  courseMaterialSets: [];
  calendarId: string;
  courseId: string;
  courseWorkId: string;
  title: string;
  status: string;
  courseState: CourseState;
}

export interface GQL_Invitation {
  id: string;
  userId: string;
  courseId: string;
  role: CourseRole;
  firstName?: string;
  lastName?: string;
}


export interface GradebookSettings {
  calculationType: CalculationType;
  displaySetting: DisplaySetting;
  gradeCategories: [GradeCategory]

}

export enum DisplaySetting {
  DISPLAY_SETTING_UNSPECIFIED = 'DISPLAY_SETTING_UNSPECIFIED',
  SHOW_OVERALL_GRADE = 'SHOW_OVERALL_GRADE',
  HIDE_OVERALL_GRADE = 'HIDE_OVERALL_GRADE',
  SHOW_TEACHERS_ONLY = 'SHOW_TEACHERS_ONLY',
}

export enum CalculationType {
  CALCULATION_TYPE_UNSPECIFIED = 'CALCULATION_TYPE_UNSPECIFIED',
  TOTAL_POINTS = 'TOTAL_POINTS',
  WEIGHTED_CATEGORIES = 'WEIGHTED_CATEGORIES',
}

export interface GQL_GoogleClassroomCourses {
  courseId: string;
  id: string;
  name?: string;
  title?: string;
  section: string;
  description: string;
  descriptionHeading: string
  room: string;
  ownerId: string;
  creationTime: string;
  updateTime: string;
  assignedGrade: number;
  enrollmentCode: string;
  courseState: CourseState;
  alternativeLink: string;
  teacherGroupEmail: string;
  courseGroupEmail: string;
  teacherFolder: DriveFolder[];
  courseMaterialSets: []
  guardiansEnabled?: boolean;
  calendarId: string;
  gradebookSettings: [GradebookSettings];

}

export interface GQL_GoogleCourseWork {
  getCourseWorkById: {
    courseId: string;
    id?: string;
    title: string;
    state: string;
    description: string;
    alternateLink: string;
    creationTime: string;
    updateTime: string;
    scheduledTime?: string;
    maxPoints?: number;
    workType?: string;
    associatedWithDeveloper: boolean;
    assigneeMode: string;
    submissionModificationMode: string;
    creatorUserId: string;
    topicId?: string;
    details?: {
      studentWorkFolder?: {
        id: string;
        title: string;
        alternateLink: string;
        thumbnailUrl?: string;
      };
      choices?: string[];
    };
    materials?: {
      sharedDriveFile?: {
        shareMode: string;
        driveFile: {
          id: string;
          title: string;
          alternateLink: string;
          thumbnailUrl?: string;
        };
      };
      youtubeVideo?: {
        id: string;
        title: string;
        alternateLink: string;
        thumbnailUrl?: string;
      };
      link?: {
        url: string;
        title: string;
        thumbnailUrl?: string;
      };
      form?: {
        formUrl: string;
        title: string;
        thumbnailUrl?: string;
      };
    }[];
    dueDate?: {
      year: number;
      month: number;
      day: number;
    };
    dueTime?: {
      hours: number;
      minutes: number;
      seconds?: number;
      nanos?: number;
    };
    individualStudentsOptions?: {
      studentIds: [string];
    };
    gradeCategory?: {
      id: string;
      name: string;
      weight: number;
      defaultGradeDenominator?: number;
    };
    assignment?: {
      studentWorkFolder?: {
        id: string;
      };
    };
  },
  createCourseWork?: {
    courseId: string;
    id?: string;
    title: string;
    state: string;
    description: string;
    alternateLink: string;
    creationTime: string;
    updateTime: string;
    scheduledTime?: string;
    maxPoints?: number;
    workType?: string;
    associatedWithDeveloper: boolean;
    assigneeMode: string;
    submissionModificationMode: string;
    creatorUserId: string;
    topicId?: string;
    details?: {
      studentWorkFolder?: {
        id: string;
        title: string;
        alternateLink: string;
        thumbnailUrl?: string;
      };
      choices?: string[];
    };
    materials?: {
      sharedDriveFile?: {
        shareMode: string;
        driveFile: {
          id: string;
          title: string;
          alternateLink: string;
          thumbnailUrl?: string;
        };
      };
      youtubeVideo?: {
        id: string;
        title: string;
        alternateLink: string;
        thumbnailUrl?: string;
      };
      link?: {
        url: string;
        title: string;
        thumbnailUrl?: string;
      };
      form?: {
        formUrl: string;
        title: string;
        thumbnailUrl?: string;
      };
    }[];
    dueDate?: {
      year: number;
      month: number;
      day: number;
    };
    dueTime?: {
      hours: number;
      minutes: number;
      seconds?: number;
      nanos?: number;
    };
    individualStudentsOptions?: {
      studentIds: [string];
    };
    gradeCategory?: {
      id: string;
      name: string;
      weight: number;
      defaultGradeDenominator?: number;
    };
    assignment?: {
      studentWorkFolder?: {
        id: string;
      };
    };
  }
}




export interface GQL_GoogleClassroomStudents {
  id: string;
  profile: {
    id: string;
    name: {
      givenName: string;
      familyName: string;
      fullName: string;
    };
    emailAddress: string;
  };
  selfLink: string;
}


export interface GQL_ModifyAssigneeCourseWorkResponse {
  modifyAssigneeCourseWork: {
    courseId: string;
    id: string;
    title: string;
    updateTime: string; // Adjust type as per actual response
    maxPoints: number; // Adjust type as per actual response
    workType: string; // Adjust type as per actual response
    creationTime: string; // Adjust type as per actual response
    submissionModificationMode: string; // Adjust type as per actual response
    assignment: {
      studentWorkFolder: {
        id: string;
      };
    };
    individualStudentsOptions: {
      studentIds: [string]; // Array of strings
    };
  };
}


// export interface IndividualStudentsOptions {
//   studentIds: [string];
// }

export interface GQL_GoogleClassroomStudent {
  getCourseStudents: {
    numberOfStudents: number;
    students: {
      courseId: string;
      userId: string;
      profile: {
        id: string;
        emailAddress: string;
        photoUrl?: string; // Add this line if photoUrl is optional
        permissions?: {
          permission: string;
        }[];
        name: {
          givenName: string;
          familyName: string;
          fullName: string;
        };
      };
      driveFolder: {
        id: string;
        title: string;
        alternateLink: string;
      };
    }[];
  };
}



export interface GQL_GoogleClassroomTeachers {
  teachers: {
    courseId: string;
    userId: string;
    profile: {
      id: string;
      name: {
        givenName: string;
        familyName: string;
        fullName: string;
      };
      emailAddress: string;
      photoUrl: string;
    };
  }[];
  numberOfTeacher: number;
}


export enum CourseRole {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  Admin = 'ADMIN',
  OWNER = 'OWNER',
}



export enum AssigneeMode {
  ASSIGNEE_MODE_UNSPECIFIED = 'ASSIGNEE_MODE_UNSPECIFIED',
  ALL_STUDENTS = 'ALL_STUDENTS',
  INDIVIDUAL_STUDENTS = 'INDIVIDUAL_STUDENTS',
}

export enum CourseState {
  COURSE_STATE_UNSPECIFIED = 'COURSE_STATE_UNSPECIFIED',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  PROVISIONED = 'PROVISIONED',
  DECLINED = 'DECLINED',
  SUSPENDED = 'SUSPENDED',
}

export enum CourseWorkMaterialState {
  COURSEWORK_MATERIAL_STATE_UNSPECIFIED = 'COURSEWORK_MATERIAL_STATE_UNSPECIFIED',
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  DELETED = 'DELETED',

}

export enum CourseWorkType {
  COURSE_WORK_TYPE_UNSPECIFIED = 'COURSE_WORK_TYPE_UNSPECIFIED',
  ASSIGNMENT = 'ASSIGNMENT',
  SHORT_ANSWER_QUESTION = 'SHORT_ANSWER_QUESTION',
  MULTIPLE_CHOICE_QUESTION = 'MULTIPLE_CHOICE_QUESTION',
}

export interface IndividualStudentsOptions {
  studentIds: [string];
}

export interface CourseMaterial {
  driveFile?: [DriveFile];
  youTubeVideo?: [YouTubeVideo];
  link?: Link;
  form?: [Form];

}

export interface Form {
  formUrl: string;
  responseUrl: string;
  title: string;
  thumbnailUrl: string;

}

export interface Link {
  url: string;
  title?: string;
  thumbnailUrl?: string;


}

export interface DriveFile {
  id: string;
  title: string;
  alternateLink: string;
  thumbnailUrl: string;

}

export interface YouTubeVideo {
  id: string;
  title: string;
  alternateLink: string;
  thumbnailUrl: string;

}

export enum SubmissionModificationMode {
  SUBMISSION_MODIFICATION_MODE_UNSPECIFIED = 'SUBMISSION_MODIFICATION_MODE_UNSPECIFIED',
  MODIFIABLE_UNTIL_TURNED_IN = 'MODIFIABLE_UNTIL_TURNED_IN',
  MODIFIABLE = 'MODIFIABLE',
}

export interface GradeCategory {
  id: string,
  name: string,
  weight: number,
  defaultGradeDenominator: number,
}

export interface Assignment {
  studentWorkFolder: [DriveFolder];

}

export interface DriveFolder {
  id: string;
  title: string;
  alternativeLink: string;
}

export interface MultipleChoiceQuestion {
  choices: [string]
}

export interface GQL_classWork {
  courseId: string;
  id: string;
  title: string;
  description?: string;
  materials: [CourseMaterial];
  state: CourseWorkMaterialState;
  alternateLink?: string;
  creationTime: string;
  updateTime: string;
  dueDate?: string;
  dueTime?: string;
  scheduledTime?: string;
  maxPoints: number;
  workType: CourseWorkType;
  associatedWithDeveloper: boolean;
  assigneeMode: AssigneeMode;
  individualStudentsOptions?: IndividualStudentsOptions;
  submissionModificationMode: SubmissionModificationMode;
  creatorUserId: string;
  topicId?: string;
  gradeCategory?: GradeCategory;

  // Union field details can be only one of the following:
  assignment?: Assignment;
  multipleChoiceQuestion?: MultipleChoiceQuestion;

}


export interface GQL_updatecourseWorkData {
  title: string;
  description: string;
  state: string;
  maxPoints?: number;
  creationTime?: string;
  materials?: CourseMaterial[];
  updateTime?: string;
  dueDate?: Date1;
  dueTime?: {
    hours: number;
    minutes: number;
    seconds?: number;
    nanos?: number;
  };
  scheduledTime?: string;

}

export interface GQL_courseWorkData {
  title: string;
  description: string;
  state: string;
  workType?: CourseWorkType;
  maxPoints?: number;
  creationTime?: string;
  updateTime?: string;
  assigneeMode: string;
  materials?: CourseMaterial[];
  individualStudentsOptions?: {
    studentIds: string[];
  };
  dueDate?: Date1;
  dueTime?: TimeOfDay1;
  scheduledTime?: string;

}

export interface Date1 {
  year: number;
  month: number;
  day: number;
}

export interface TimeOfDay1 {
  hours: number;
  minutes: number;
  seconds: number;
  nanos: number;
}


export enum SubmissionState {
  SUBMISSION_STATE_UNSPECIFIED = 'SUBMISSION_STATE_UNSPECIFIED',
  NEW = 'NEW',
  CREATED = 'CREATED',
  TURNED_IN = 'TURNED_IN',
  RECLAIMED_BY_STUDENT = 'RECLAIMED_BY_STUDENT'
}

export interface RubricGrade {
  criterionId: string;
  levelId: string;
  points: string;

}

export interface stateHistory {
  state: CourseState;
  stateTimestamp: string;
  actorUserId: string;

}

export interface SubmissionHistory {
  gradeHistory: gradeHistory;
  stateHistory: stateHistory;
}

export interface gradeHistory {
  pointsEarned: number;
  maxPoints: number;
  gradeTimestamp: string;
  actorUserId: string;
  gradeChangeType: GradeChangeType

}

export enum GradeChangeType {
  UNKNOWN_GRADE_CHANGE_TYPE = 'UNKNOWN_GRADE_CHANGE_TYPE',
  DRAFT_GRADE_POINTS_EARNED_CHANGE = 'DRAFT_GRADE_POINTS_EARNED_CHANGE',
  ASSIGNED_GRADE_POINTS_EARNED_CHANGE = 'ASSIGNED_GRADE_POINTS_EARNED_CHANGE',
  MAX_POINTS_CHANGE = 'MAX_POINTS_CHANGE',
}
export interface GQL_StudentSubmission {
  courseId: string;
  courseWorkId: string;
  id: string;
  userId: string
  creationTime: string;
  updateTime: string;
  state: SubmissionState
  late: boolean;
  draftGrade: number;
  assignedGrade: number;
  rubricId: number;
  userName?: string;
  draftRubricGrades: [RubricGrade];
  assignedRubricGrades: { [key: string]: [RubricGrade] };
  alternateLink: string;
  courseWorkType: CourseWorkType;
  associatedWithDeveloper: boolean;
  submissionHistory: SubmissionHistory[]
  maxPoints: number;

}



