import { Button, Checkbox, Col, Divider, Empty, Row } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React from 'react';
import { Filter } from '../../utils/antd';

export interface ITableCheckboxGroup {
  maxWidth?: number | string;
  maxHeight?: number | string;
  value: string[];
  dataset: Filter[];
  onCheckboxGroupChange: ((checkedValue: CheckboxValueType[]) => void) | undefined;
  onCheckboxGroupReset: React.MouseEventHandler<HTMLElement> | undefined;
  onCheckboxGroupOk: React.MouseEventHandler<HTMLElement> | undefined;
}

const TableCheckboxGroup: React.FC<ITableCheckboxGroup> = ({
  maxWidth = 200,
  maxHeight = '300px',
  value,
  dataset,
  onCheckboxGroupChange,
  onCheckboxGroupReset,
  onCheckboxGroupOk,
}) => {
  return (
    <Row style={{ maxWidth }}>
      <Col span={24} style={{ maxHeight, overflow: 'scroll' }}>
        {dataset.length === 0 && (
          <Empty
            data-cy="shared-tablechecbkoxgroup-nodata"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ width: '100%' }}
          />
        )}

        {dataset.length > 0 && (
          <Checkbox.Group style={{ padding: 8, width: '100%' }} value={value} onChange={onCheckboxGroupChange}>
            {dataset.map((item) => (
              <Row key={item.value} style={{ padding: 8 }}>
                <Col span={24}>
                  <Checkbox data-cy={`shared-tablechecbkoxgroup-checbkox-${item.text}`} value={item.value}>
                    {item.text}
                  </Checkbox>
                </Col>
              </Row>
            ))}
          </Checkbox.Group>
        )}
      </Col>
      <Divider type="horizontal" style={{ margin: 0 }} />
      <Col span={24} style={{ padding: 8 }}>
        <Row justify="space-between">
          <Button
            data-cy="shared-tablechecbkoxgroup-button-reset"
            disabled={value.length === 0}
            type="text"
            onClick={onCheckboxGroupReset}
          >
            Reset
          </Button>
          <Button data-cy="shared-tablechecbkoxgroup-button-ok" type="primary" onClick={onCheckboxGroupOk}>
            OK
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default TableCheckboxGroup;
