import React, { useMemo } from 'react';
import { IMediaContentValue } from '../../../types/investigation';
import * as S from './styles';

interface IInvestigationVideo {
  value: IMediaContentValue;
}

const InvestigationVideo = (props: IInvestigationVideo) => {
  const { value } = props;

  const fileUrl = useMemo(() => {
    if (value?.file) {
      return URL.createObjectURL(value.file);
    } else if (value?.url) {
      return value.url;
    }

    return undefined;
  }, [value]);

  return (
    <>
      <S.VideoComponent src={value?.url || fileUrl} controls loaded />
    </>
  );
};

export default InvestigationVideo;
