import React, { useState } from 'react';
import { Table, Tabs, Pagination, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as S from './styles';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { QuoteData } from '../../../types/shopify';
import { formatTimestampToDateTime } from '../../../utils/date';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';



const QuoteListPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("1");
  const [pageSize] = useState(8);
  const history = useHistory();

  const columns: ColumnsType<QuoteData> = [
    {
      title: 'Request ID',
      dataIndex: 'quoteId',
      key: 'quoteId',
      render: (text, record) => (
        <a href={record.receiptUrl} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    { title: 'Request Date', dataIndex: 'requestDate', key: 'requestDate' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <S.StatusTag status={status}>
          {status}
        </S.StatusTag>
      ),
    },
    { title: 'Total', dataIndex: 'total', key: 'total' },
  ];

  const { data, refetch } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getAllQuote, {
    variables: { page: currentPage },
    onError: (error) => {
      message.error(`Error in fetching quote details - ${error.message || 'Unexpected Error'}`);
    },
  });

  const quoteData = data?.getAllQuotes?.quotesAll || [];

  const formattedData: QuoteData[] = quoteData.map((quote: QuoteData, index: number) => ({
    key: `${index}`,
    quoteId: quote.quoteNumber,
    requestDate: formatTimestampToDateTime(quote.createdAt),
    status: quote.status === 'REQUESTED' ? 'Requested' : 'Expired',
    receiptUrl: quote.receiptUrl,
    total: `$${quote.totalPrice}`,
  })).sort((a: any, b: any) => new Date(b.requestDate).getTime() - new Date(a.requestDate).getTime());

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refetch({ page });
  };

  // Filter the data based on the current tab and pagination
  const paginatedData = formattedData
    .filter((data) => {
      if (currentTab === '3') {
        return data.status === 'EXPIRED';
      } else if (currentTab === '2') {
        return data.status === 'REQUESTED';
      }
      return true;
    });

  return (
    <S.Container>
      <FiArrowLeft
        onClick={() => history.goBack()}
        style={{ cursor: 'pointer', color: 'black', fontSize: '24px', margin: '10px' }}
      />
      <S.Header>
      </S.Header>

      <Tabs onChange={(value) => setCurrentTab(value)} defaultActiveKey="1" centered>
        <Tabs.TabPane tab="All Quotes" key="1" />
        <Tabs.TabPane tab="Requested" key="2" />
        <Tabs.TabPane tab="Expired" key="3" />
      </Tabs>

      <S.TableContainer>
        {paginatedData.length > 0 ? (
          <Table
            columns={columns}
            dataSource={paginatedData}
            pagination={false}
            rowKey="key"
          />
        ) : (
          <p>No quotes available </p>  // Display message if no data
        )}
      </S.TableContainer>

      <S.PaginationContainer>
        {paginatedData.length > 0 && (
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data?.getAllQuotes?.total || 0}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        )}
      </S.PaginationContainer>
    </S.Container>
  );
};


export default QuoteListPage;