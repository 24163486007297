import { Col, Divider, Button as AntdButton, InputProps } from 'antd';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';

export const TitleTab = styled.p`
  font-weight: 700;
  padding: 0;
  font-size: 1.1em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Title = styled.h1`
  text-align: left;
  margin-bottom: 25px;
  font-weight: 600;
`;

export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 5px;
  min-height: 50vh;
`;

export const Line = styled(Col)`
  .ant-form-item {
    margin: 0;
  }
`;

export const CDivider = styled(Divider)`
  margin: 30px 0 0 0;
`;

export const TitleInput = styled.div<{
  $padding?: string;
}>`
  font-weight: 600;
  padding: ${(props) => (props.$padding ? props.$padding : ' 0 11px')};
  font-size: 1.1em;
  margin: 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  align-items: center;
  .ant-progress {
    flex: 1;
    margin-left: 16px;
  }
`;

export const SInput = styled(Input)<
  {
    $bold?: boolean;
  } & InputProps
>`
  font-size: 0.95em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  font-weight: ${(props) => (props.$bold ? '500' : 'unset')};
  .ant-input-suffix {
    display: none;
  }
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 2px;
`;

export const Status = styled.span<{
  $active: boolean;
}>`
  font-size: 0.95em;
  padding: 0 11px;
  font-weight: 800;
  color: ${(props) => (props.$active ? '#50c364' : '#EA3535')};
`;

export const EditButton = styled(Button)`
  background: ${(props) => props.theme.primaryColor.background};
  border-color: ${(props) => props.theme.primaryColor.background};
  line-height: 0;
  min-height: 33px;
  svg {
    font-size: 20px;
  }
  &:hover,
  &:active,
  &:focus {
    background: ${(props) => props.theme.primaryColor.background};
    border-color: ${(props) => props.theme.primaryColor.background};
  }
`;

export const DeactivateButton = styled(AntdButton)<{
  $active: boolean;
}>`
  display: inline-flex;
  min-height: 30px;
  font-weight: 500;
  color: #ffffff;
  background: ${(props) => (props.$active ? props.theme.error.background : props.theme.success.background)} !important;
  border-color: ${(props) => (props.$active ? props.theme.error.border : props.theme.success.border)} !important;
  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background: ${(props) => (props.$active ? props.theme.error.hover : props.theme.success.hover)} !important;
    border-color: ${(props) => (props.$active ? props.theme.error.hover : props.theme.success.hover)} !important;
  }
`;

export const Info = styled.p<{
  $bold?: boolean;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: 0.95em;
  text-align: 'center';
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;
