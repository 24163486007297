import { Upload } from 'antd';
import styled from 'styled-components';

export const UploadContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  padding-right: 32px;
  display: flex;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  color: #3f3e38;
  font-size: 14px;
  align-items: center;
  position: relative;
  word-break: break-all;
  line-height: 1.15;
  min-height: 38px;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg:nth-child(3) {
    position: absolute;
    right: 16px;
  }
`;

export const UploadOutsider = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`;
