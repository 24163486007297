import { createReducer, createSagaAction } from '../../utils/redux';

// Action Types
export const constants = {
  UPDATE_CURRENT_ACTIVITY: 'UPDATE_CURRENT_ACTIVITY',
  INVESTIGATION_LOAD: createSagaAction('INVESTIGATION_LOAD'),
  ACTIVITY_SUBMIT: createSagaAction('INVESTIGATION_ACTIVITY_SUBMIT'),
  ACTIVITY_PREVIOUS: 'INVESTIGATION_ACTIVITY_PREVIOUS',
  REFLECTION_SUBMIT: createSagaAction('REFLECTION_SUBMIT'),
};

// Action Creators
export const actions = {
  updateCurrentActivity: (payload: { id: string }) => ({
    type: constants.UPDATE_CURRENT_ACTIVITY,
    payload,
  }),
};

export interface IQuestionnaireSubQuestion {
  text: string;
  isValid?: boolean;
  answer?: number;
  options?: {
    value: string | number;
    text: string;
    tooltip?: string;
  }[];
}

export interface IQuestionnaireQuestion {
  title: string;
  needsFeedback?: boolean;
  feedback?: string;
  feedbackTitle?: string;
  includeRating?: boolean;
  subquestions: IQuestionnaireSubQuestion[];
}

export interface IQuestionnaire {
  title?: string;
  rating?: number;
  questions: IQuestionnaireQuestion[];
}

export interface IInvestigationStore {
  currentActivityId?: string;
}

// Reducer
const initialState: IInvestigationStore = {};

export default createReducer(initialState, (state: IInvestigationStore, action) => {
  switch (action.type) {
    case constants.UPDATE_CURRENT_ACTIVITY:
      return {
        ...state,
        currentActivityId: action.payload.id,
      };
    default:
      return state;
  }
});
