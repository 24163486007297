import { Col, message, Row, TablePaginationConfig, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import { GQL_AverageReportGainScore, GQL_AverageReportGainScoreInput } from '../../../types/charts';
import { FilterData, Duration } from '../../../types/investigation';
import Filter from '../Filter';
import { FiHelpCircle } from 'react-icons/fi';
import { formatDateTime } from '../../../utils/date';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import Details from './Details';
import Button from '../../Button';
import { downloadCsv } from '../../../utils/files';
import Papa from 'papaparse';

const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};

const ReportGainScore = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const [period, setPeriod] = useState<Duration>(defaultDateRange);
  const [filterData, setFilterData] = useState<FilterData>({ organizations: false, teachers: false });

  const [getAverageReportGainScoreChart, { data, loading }] = useLazyQuery<
    { getAverageReportGainScoreChart: GQL_AverageReportGainScore[] },
    { data: GQL_AverageReportGainScoreInput }
  >(gqlSchema.ChartSchema.queries.CHARTS.getAverageReportGainScoreChart, {
    onError: (err) => {
      message.error('There was an error loading investigation assignment rate: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    const { organizations, teachers, disciplineIds, ...data } = filterData;
    getAverageReportGainScoreChart({
      variables: {
        data: { ...data, startDate: period.startDate, endDate: period.endDate },
      },
    });
  }, [getAverageReportGainScoreChart, filterData, period]);

  const onChangeFilterData = useCallback((data: FilterData) => {
    setFilterData(data);
  }, []);

  const downloadDetails = useCallback(() => {
    const headers = [['First Name', 'Last Name', 'Email', 'Completed Investigations']];
    const csvData =
      data?.getAverageReportGainScoreChart?.map((row: GQL_AverageReportGainScore) => {
        return [row.teacherFirstName, row.teacherLastName, row.teacherEmail, row.gain || 0];
      }) || [];
    downloadCsv(Papa.unparse([...headers, ...csvData]), `Teachers Scores Average.csv`);
  }, [data]);

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Report Gain Score</S.Title>
          <Tooltip title="Average normalized gain score is the average change in the report score (last score - first score or post score - pre score) divided by the maximum possible gain (total score possible - first score or total score possible - pre score).">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col xxl={20} xl={17} lg={15} md={24}>
          <Filter
            showGradeFilter={false}
            showSubjectFilter={false}
            showDateRangeFilter={true}
            showDisciplineFilter
            filterUsers={false}
            onChangeFilterData={onChangeFilterData}
            setDaterange={setPeriod}
          />
        </Col>
        <Col xxl={4} xl={7} lg={9} md={24}>
          <Button
            text="Download  Details"
            block
            disabled={(data?.getAverageReportGainScoreChart?.length || 0) === 0}
            onClick={downloadDetails}
          />
        </Col>
        <Col span={24}>
          <Details
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
            data={data?.getAverageReportGainScoreChart || []}
          />
        </Col>
      </Row>
    </>
  );
};

export default ReportGainScore;
