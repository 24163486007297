import { Col } from 'antd';
import styled from 'styled-components';
import Button from '../Button';

export const CarouselContent = styled.div<{ $height?: number }>`
  ${(props) => props.$height && 'height: ' + props.$height + 'px;'}
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    object-fit: contain;
    width: 100%;
    height: 100% !important;
  }
`;

export const CarouselContainer = styled.div<{
  $backgroundColor: string;
}>`
  border-radius: 6px;
  overflow: hidden;
  background-color: ${(props) => props.$backgroundColor};
  width: 100%;
`;

export const RoundButton = styled(Button)<{ $left?: boolean }>`
  min-height: 40px;
  min-width: 40px;
  padding-${(props) => (props.$left ? 'left' : 'right')}: 2px;
  margin-${(props) => (props.$left ? 'left' : 'right')}: 25px;

  @media (max-width: 768px) {
    display:none;
  }
`;

export const CarouselCol = styled(Col)`
  display: flex;
  align-items: center;

  .ant-carousel .slick-dots li button {
    background: #d8d8d8;
    opacity: 1;
    height: 10px;
    width: 10px;
    max-width: 10px;
    border-radius: 50%;
  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    height: 10px;
    width: 10px;
    max-width: 10px;
    background: ${(props) => props.theme.secondaryColor.background};
  }

  .ant-carousel .slick-dots li.slick-active {
    max-width: 10px;
  }

  .ant-carousel .slick-dots li {
    max-width: 10px;
    margin: 6px;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: 0;
    margin-bottom: 2px;
  }
`;
