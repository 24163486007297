import styled from 'styled-components';
import Button from '../Button';

export const CardContainer = styled.div`
  border-radius: 6px;
  background: white;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  padding: 16px;
  min-height: 100%;
  position: relative;

  p,
  h1,
  h2,
  h3 {
    margin: 0;
    word-break: break-word;
  }
`;

export const MoreButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding-right: 40px;

  & > span {
    & > h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;

      color: #000000;
    }

    & > h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;

      color: #767676;
    }
  }
`;

export const ReadMoreButton = styled.p`
  cursor: pointer;
  text-align: right;
  color: #45aafb;
  margin-top: 8px !important;
`;
