import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { investigationActions } from '../../../redux/modules';
import { IStore } from '../../../redux/store';
import InvestigationSider from '../../../shared/InvestigationSider';
import { GQL_InvestigationDisplay } from '../../../types/investigation';

interface IStudentInvestigationSider {
  investigation: GQL_InvestigationDisplay;
  disabled?: boolean;
  resetContentIndex: () => void;
}

const StudentInvestigationSider = (props: IStudentInvestigationSider) => {
  const { investigation, resetContentIndex, disabled } = props;
  const dispatch = useDispatch();

  const currentActivityId = useSelector((state: IStore) => state.investigation.currentActivityId);

  let lastStepIndex = investigation.steps.findIndex((step) =>
    step.activities.some((activity) => activity.id === currentActivityId),
  );

  if (lastStepIndex === -1) lastStepIndex = investigation.steps.length;

  const setCurrentActivity = (payload?: { stepIndex?: number; activityIndex?: number }) => {
    const stepIndex = payload?.stepIndex ?? 0;
    const activityIndex = payload?.activityIndex ?? 0;

    dispatch(
      investigationActions.updateCurrentActivity({
        id: investigation.steps[stepIndex].activities[activityIndex].id,
      }),
    );
  };

  return (
    <InvestigationSider
      title={investigation.title}
      activityId={currentActivityId}
      resetContentIndex={disabled ? () => {} : resetContentIndex}
      setCurrentActivity={disabled ? () => {} : setCurrentActivity}
      stepIndex={lastStepIndex}
      stepList={investigation.steps}
      stepsLength={investigation.steps.length}
      isAssessment={investigation.isAssessment || false}
    />
  );
};

export default StudentInvestigationSider;
