import { Statistic } from 'antd';
import React from 'react';
import { themeConfig } from '../../../../utils/theme';
import * as S from './styles';
interface Props {
  title: string;
  average: number;
}
const CardAverage = (props: Props) => {
  const { title, average } = props;
  return (
    <S.CCard>
      <Statistic
        title={title}
        value={average}
        precision={2}
        valueStyle={{ color: themeConfig.primaryColor.background }}
      />
    </S.CCard>
  );
};

export default CardAverage;
