import styled from 'styled-components';
export const Title = styled.h3`
  font-size: 1.4em;
  display: inline;
`;
export const Card = styled.div<{
  $height?: string;
}>`
  width: 100%;
  background: white;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 32px 40px;
  height: ${(props) => props.$height || '40vh'};
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 129.1%;
    padding: 0 24px;
    /* or 26px */

    text-align: center;

    color: #767676;
  }
  g > g > text[text-anchor='middle'] {
    font-size: 14px !important;
    fill: #000000 !important;
  }
`;
