import styled from 'styled-components';

export const Formula = styled.span<{
  $value?: string;
  $editable?: boolean;
}>`
  .mq-math-mode .mq-nonSymbola,
  .mq-math-mode .mq-text-mode,
  .mq-math-mode var {
    font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif !important;
    line-height: 0.9;
    color: rgb(118, 118, 118) !important;
    font-style: normal !important;
  }
  ${(props) => {
    if (props.$editable) {
      if (props.$value?.trim()) {
        return `
        .mq-focused {
          border: 1px dashed gray !important;
        }
      `;
      } else {
        return `
        border: 1px dashed gray;
        padding: 3px 0;
        display: inline-flex;
      `;
      }
    }
  }}

  ${(props) =>
    !props.$editable &&
    `
    textarea {
      display: none;
    }

    .mq-cursor {
      display: none;
    }
  `}
`;
