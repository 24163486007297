import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;

export const NotEditableBlock = styled.p`
  text-align: center;
  padding: 64px 16px;
  color: #a2a2a2;
  font-size: 16px;
`;
