import React, { useCallback, useMemo } from 'react';
import { Card, Col } from 'antd';
import * as S from './styles';
import Button from '../../../shared/Button';
import { useHistory } from 'react-router-dom';
import { GQL_BillingInfo, GQL_PaymentProduct } from '../../../types/payments';

type BillingSummaryProps = {
  billingData: GQL_BillingInfo;
  product: GQL_PaymentProduct;
  loading: boolean;
  isStarterPlan: boolean;
  planPending: boolean;
  noSubscription: boolean;
};

const MetadataDetails: any = {
  allowedFeatures: 'Allowed Features',
  maxAssessments: 'Max amount of Assessments',
  maxClasses: 'Max amount of Classes',
  maxStudentsPerClass: 'Max amount of students per class',
  maxInvestigations: 'Max amount of investigations per class',
  subjects: 'Allowed Subjects',
  maxTAs: 'Max amount of Teacher Assistants',
};

export const BillingSummary: React.FC<BillingSummaryProps> = (props) => {
  const history = useHistory();
  const { billingData, loading = false, product, isStarterPlan, planPending, noSubscription } = props;

  const getDetails = useMemo(() => {
    const metadata: any = product?.metadata || {};
    const keys = Object.keys(metadata);
    const details = [];
    for (let index = 0; index < keys.length; index++) {
      const key: string = keys[index];

      if (key === 'allowedFeatures' || key === 'subjects' || key === '__typename') {
        continue;
      }

      details.push({
        label: MetadataDetails[key],
        value: metadata[key],
      });
    }

    return details;
  }, [product]);

  const productInterval = useMemo(() => {
    const intervalLabel =
      billingData?.interval === 'month'
        ? 'monthly'
        : billingData?.interval === 'year'
        ? 'yearly'
        : billingData?.interval;
    return intervalLabel;
  }, [billingData]);

  function formatCurrency(price: number) {
    const priceLabel = '$' + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return priceLabel;
  }

  const inactivePlan = useMemo(() => {
    return isStarterPlan || noSubscription;
  }, [isStarterPlan, noSubscription]);

  const CardTitle = useCallback(
    () => (
      <>
        <S.CardTitle>Your Plan {!inactivePlan ? `(billed ${productInterval || '---'})` : ''}</S.CardTitle>
        <S.PlanTitle> {inactivePlan ? 'Starter' : product?.name}</S.PlanTitle>
      </>
    ),
    [productInterval, product, inactivePlan],
  );

  const CardExtra = useCallback(
    () => (
      <Button
        size="small"
        text={'Update plan'}
        onClick={() => {
          history.push('/manage-subscription');
        }}
      />
    ),
    [history],
  );

  const shouldDiplayPrice = useMemo(() => {
    if (planPending) {
      return false;
    }

    if (isStarterPlan) {
      return false;
    }

    if (noSubscription) {
      return false;
    }

    return true;
  }, [isStarterPlan, planPending, noSubscription]);

  return (
    <>
      <Card
        title={<CardTitle />}
        extra={<CardExtra />}
        bordered={false}
        headStyle={{ border: 0 }}
        bodyStyle={{ paddingTop: '10px' }}
        loading={loading}
        key={'billing-summary'}
      >
        {getDetails?.map((detail, i) => (
          <S.PlanRow key={`billing-summary-${detail?.label}-${i}`}>
            <Col span={12} className="title-element">
              {detail?.label}
            </Col>
            <Col span={12} className="price-element">
              {detail?.value === '-1' ? 'No limit' : detail?.value}
            </Col>
          </S.PlanRow>
        ))}

        {shouldDiplayPrice && (
          <>
            <S.Division />
            <S.PlanRow key={'billing-summary-total'}>
              <Col span={12} className="title-element">
                Total per {billingData?.interval}
              </Col>
              <Col span={12} className="price-element">
                {formatCurrency(billingData?.amount / 100 || 0)}
              </Col>
            </S.PlanRow>
          </>
        )}
      </Card>
    </>
  );
};
