import { Col, Rate } from 'antd';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.4em;
  font-weight: 500;
`;

export const SuccesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex-direction: column;
  text-align: center;
  padding: 25px 30px !important;
  background-color: white;
`;

export const Container = styled(Col)`
  padding: 25px 30px !important;
  background-color: white;
  border-radius: 6px;
  text-align: left;
  & > div:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')} !important;
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')} !important;
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const InfoProgress = styled.p`
  font-size: 0.45em;
  margin: 10px 0 0 0;
`;

export const Title = styled.h3<{
  $align?: string;
}>`
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  font-weight: 500;
  margin-bottom: 0;
`;

export const Stars = styled(Rate)`
  font-size: 0.85em;
`;

export const Column = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  z-index: 1;
  cursor: pointer;
  width: 100%;

  & > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    & > svg {
      min-height: 28px;
      min-width: 28px;
    }

    & > p,
    h1,
    h2 {
      text-align: center;
      width: 100%;
      margin: 0;
    }

    & > button {
      margin-top: 30px;
    }
  }
`;
