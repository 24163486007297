import { gql } from '@apollo/client';
import ORGANIZATION_FRAGMENTS from '../../Organization/fragment/Organization';

const CLASSLINK_QUERIES = {
  LIST: {
    getClasslinkOrganizations: gql`
      query getClasslinkOrganizations {
        getClasslinkOrganizations {
          id
          name
          organizationAdmins {
            ...NewOrganizationAdmin
          }
          autoSync
          scheduleConfig {
            daily
            weekly
            monthly
          }
        }
      }
      ${ORGANIZATION_FRAGMENTS.newOrganizationAdmin}
    `,
  },
};

export default CLASSLINK_QUERIES;
