import React from 'react';
import landingHeaderImage from '../../../../../assets/booklanding/learnmore/header.png';
import * as S from './style';
const LearnHeader: React.FC = () => {
  // const handleDownload = () => {
  //   const link = document.createElement('a');
  //   link.href = 'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/The%20New%20ADI%20Stages%20Flyer.pdf';
  //   link.download = 'About_ADI.pdf'; 
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  return (
    <S.Section>
      <S.MainHeaderContent>
        <S.HSubtitle>Overview of the ADI Model</S.HSubtitle>
        <S.MainHeadertitle>
          <span>
            The ADI model, launched in 2009 with eight stages, was refined over a decade through research, leading to its current seven-stage format by 2020. It aims to enhance learning in science, engineering, and math based on current standards.
            and math based on current standards.
          </span>
        </S.MainHeadertitle>
        <a href="https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/The%20New%20ADI%20Stages%20Flyer.pdf" target="_blank" rel="noopener noreferrer"> <S.Button>Download the ADI Stages Handouts </S.Button></a>
        {/* <S.Button onClick={handleDownload}>Download the ADI Stages Handouts</S.Button> */}
      </S.MainHeaderContent>
      <S.Image  src={landingHeaderImage} alt="Classroom Image" />
    </S.Section>
  );
};

export default LearnHeader;
