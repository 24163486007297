import React, { ReactElement, useCallback, useState } from 'react';
import { RcFile } from 'antd/lib/upload';

import { FiDownload, FiFile } from 'react-icons/fi';

import * as S from './styles';
import { message } from 'antd';

interface IUpload {
  title?: string | ReactElement;
  customTitle?: boolean;
  error?: boolean;
  openFileDialogOnClick?: boolean;
  subtitle?: string;
  name?: string;
  accept?: string;
  onFileUpload?: (success: boolean) => void;
  onLoad?: (file: RcFile) => void;
  onRemoveFile?: () => void;
  disabled?: boolean;
}

const Upload: React.FC<IUpload> = (props) => {
  const [file, setFile] = useState<RcFile | undefined>();
  const {
    title,
    subtitle,
    name,
    error,
    accept,
    onFileUpload,
    onLoad,
    customTitle,
    openFileDialogOnClick,
    onRemoveFile,
  } = props;
  const beforeUpload = (file: RcFile) => {
    setFile(file);
    if (onFileUpload) onFileUpload(true);
    if (onLoad) onLoad(file);

    if (accept) {
      // Gets all accepted types without extra spaces
      const types = accept.split(',').map((type) => type.trim());

      return types.some((type) => {
        if (type.includes('/*')) {
          return file.type.includes(type.replace('/*', ''));
        } else if (type.includes('.')) {
          return file.type.includes(type.replace('.', ''));
        } else {
          return file.type === type;
        }
      });
    } else {
      return true;
    }
  };

  const removeFile = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>) => {
      e.stopPropagation();
      setFile(undefined);
      if (onRemoveFile) onRemoveFile();
    },
    [setFile, onRemoveFile],
  );

  const renderTitle = useCallback(
    (file: RcFile | undefined) => {
      if (!file)
        return (
          <>
            <S.IconContainer>
              <FiDownload size={24} />
            </S.IconContainer>
            <S.Title>{title}</S.Title>
            <S.SubTitle>{subtitle}</S.SubTitle>
          </>
        );

      return (
        <>
          {onRemoveFile && <S.DeleteFileIcon size={24} $color={error ? 'red' : 'blue'} onClick={removeFile} />}
          {customTitle ? (
            title
          ) : (
            <>
              <S.IconContainer>
                <FiFile size={24} />
              </S.IconContainer>
              <S.Title>{file.name}</S.Title>
            </>
          )}
        </>
      );
    },
    [title, subtitle, customTitle, error, removeFile, onRemoveFile],
  );

  return (
    <S.Dragger
      data-cy="shared-upload-dragger"
      name={name}
      showUploadList={false}
      openFileDialogOnClick={openFileDialogOnClick}
      height={180}
      accept={accept}
      disabled={props.disabled}
      beforeUpload={beforeUpload}
      fileList={[]}
      customRequest={() => {}}
      onChange={(info) => {
        if (!info.fileList[0].name) {
          setFile(undefined);
          if (onRemoveFile) onRemoveFile();
          message.error('File type not supported');
        }
      }}
    >
      {renderTitle(file)}
    </S.Dragger>
  );
};

export default Upload;
