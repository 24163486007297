/* eslint-disable max-lines */
import { gql } from '@apollo/client';

const INVESTIGATION_STANDARD_QUERIES = {
  GET: {
    getStateStandards: gql`
      query getStateStandards($state: States!) {
        getStateStandards(state: $state) {
          id
          abbreviatedStatement
          fullStatement
          code
          grade
          state
          lastChangeDateTime
          discipline
        }
      }
    `,
    getStateStandardById: gql`
      query getStateStandardById($id: String) {
        query
        getStateStandardById(id: $id) {
          id
          abbreviatedStatement
          fullStatement
          code
          grade
          state
          lastChangeDateTime
          discipline
        }
      }
    `,
  },
};

export default INVESTIGATION_STANDARD_QUERIES;
