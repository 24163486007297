import React, { useCallback } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { useAuth } from '../../hooks/useAuth';
import logo from '../../assets/logo.svg';

const AdvancedSearchHeader = () => {
  const history = useHistory();
  const { signOutAdvanced } = useAuth();

  const version = history.location.pathname.split('/')[2];
  const handleLogout = useCallback(() => signOutAdvanced(version), [signOutAdvanced, version]);

  return (
    <S.Header>
      <S.Row align="middle">
        <S.Col $defaultAlign="center" $responsiveAlign="start" span={4}>
          <S.LogoImage
            data-cy="shared-authenticatedheader-logo"
            src={logo}
            alt="ADI Logo"
            onClick={() => {
              history.push('/');
            }}
          />
        </S.Col>
        <S.Col $defaultAlign="end" $responsiveAlign="end" span={20}>
          <S.Menu>
            <S.MenuItem
              data-cy="shared-advancedSearchHeader-item-logout"
              icon={<FiLogOut size={18} style={{ marginRight: 12 }} />}
              onClick={handleLogout}
            >
              Logout
            </S.MenuItem>
          </S.Menu>
        </S.Col>
      </S.Row>
    </S.Header>
  );
};

export default AdvancedSearchHeader;
