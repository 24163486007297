import { Tooltip } from 'antd';
import React, { useEffect, useRef } from 'react';
import { BsArrowsFullscreen, BsFullscreenExit, BsBook } from 'react-icons/bs';
import useFullscreenStatus from '../../hooks/useFullscreen';
import useImmersiveReader from '../../hooks/useImmersiveReader';

import * as S from './styles';

interface IInvestigationContent {
  noMargin?: boolean;
  allowFullscreen?: boolean;
  investigationId?: string;
  onContentChange?: (p: { next: boolean }) => void;
  presentationMode?: boolean;
  fullScreenZIndex?: number;
  contentSize?: string;
}

const InvestigationContent: React.FC<IInvestigationContent> = (props) => {
  const {
    noMargin,
    allowFullscreen,
    investigationId,
    onContentChange,
    presentationMode,
    fullScreenZIndex,
    contentSize,
  } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const { status, setFullscreen } = useFullscreenStatus(contentRef);
  const { launchImmersiveReader, canAccessReader } = useImmersiveReader({ investigationId: investigationId ?? '' });

  const launchImmersiveReaderHandler = () => {
    launchImmersiveReader({
      chunks: [
        {
          content: contentRef?.current?.innerHTML ?? '',
          mimeType: 'text/html',
        },
      ],
    });
  };

  useEffect(() => {
    if (!onContentChange) return;

    const handleStepChange = (ev: KeyboardEvent) => {
      if (ev.key === 'ArrowRight' || ev.key === 'PageDown') {
        ev.preventDefault();
        onContentChange({ next: true });
      }

      if (ev.key === 'ArrowLeft' || ev.key === 'PageUp') {
        ev.preventDefault();
        onContentChange({ next: false });
      }
    };

    document.addEventListener('keydown', handleStepChange);

    return () => {
      document.removeEventListener('keydown', handleStepChange);
    };
  }, [onContentChange]);

  return (
    <span role="none"  ref={contentRef} style={{ width: '100%' }}>
      <S.Content
        $noMargin={noMargin}
        $fullscreen={status === 'enabled'}
        $presentationMode={presentationMode}
        $contentSize={contentSize}
      >
        {allowFullscreen && (
          <S.IconContainer onClick={() => setFullscreen(status !== 'enabled')} style={{ zIndex: fullScreenZIndex }}>
            {status === 'disabled' ? <BsArrowsFullscreen /> : <BsFullscreenExit />}
          </S.IconContainer>
        )}
        {canAccessReader && (
          <Tooltip title="Reader">
            <S.IconContainer right={96} onClick={launchImmersiveReaderHandler}>
              {<BsBook />}
            </S.IconContainer>
          </Tooltip>
        )}
        {props.children}
      </S.Content>
    </span>
  );
};

export default InvestigationContent;
