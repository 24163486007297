import React, { useCallback, useMemo, useState } from 'react';
import * as S from './styles';
import { Col, List, message, Row, Skeleton, Steps } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_FinalReportQuestionnaire,
  GQL_GradedFinalReportQuestionnaire,
  GQL_InvestigationActivity,
  GQL_InvestigationDisplay,
} from '../../types/investigation';
import PageWithTitle from '../../shared/PageWithTitle';
import Button from '../../shared/Button';
import AssessmentQuestionnaire, { QuestionnaireForm } from '../StudentInvestigation/AssessmentQuestionnaire';

const { Step } = Steps;

type ParamProps = {
  assessmentId: string;
};

const AssessmentGradeReportPage = () => {
  const { assessmentId } = useParams<ParamProps>();
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const [assessmentPart, setAssessmentActivity] = useState(0);
  const [listenToValueChange, setListenToValueChange] = useState(false);
  const [finalReportAverage, setFinalReportAverage] = useState(-1);
  const [questionnaireForm, setQuestionnaireForm] = useState<QuestionnaireForm>();

  const { data: investigationData, loading: loadingInvestigation } = useQuery<
    { getInvestigationById: GQL_InvestigationDisplay },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById, {
    variables: {
      id: assessmentId,
    },
    onError: () => {
      message.error(
        'There was an error loading the assessment details, please check your connection and try again later',
      );
    },
  });

  const assessmentActivities = useMemo(() => {
    const assessmentStep = investigationData?.getInvestigationById?.steps[0];
    return assessmentStep?.activities || [];
  }, [investigationData]);

  const { loading } = useQuery<
    { getFinalReportGrading: GQL_GradedFinalReportQuestionnaire },
    { investigationId: string }
  >(gqlSchema.InvestigationSchema.queries.GRADING.getFinalReportGrading, {
    variables: {
      investigationId: assessmentId,
    },
    onCompleted: (data) => {
      setQuestionnaireForm({
        activities: data.getFinalReportGrading.questionnaire.map((q: GQL_FinalReportQuestionnaire) => {
          return {
            assessmentScore: q.questions[0].answer,
            reason: q.questions[1].answer,
            feedback: q.feedback,
          };
        }),
        scoreFeedback: data.getFinalReportGrading.scoreType,
      });
      if (data.getFinalReportGrading.grade !== null) {
        setFinalReportAverage(data.getFinalReportGrading.grade);
      }
      setListenToValueChange(true);
      setTimeout(() => {
        setListenToValueChange(false);
      }, 500);
    },
    onError: (err) => {
      message.error('There was an error loading grading: ' + err.message || 'Unexpected Error');
    },
  });

  const onChangeForm = useCallback((e: any) => {
    setQuestionnaireForm(e);
  }, []);

  const onChangeStep = useCallback(
    (current: number) => {
      setAssessmentActivity(current);
      setCurrent(current);
      setListenToValueChange(true);
      setTimeout(() => {
        setListenToValueChange(false);
      }, 500);
    },
    [setListenToValueChange],
  );

  const loadingPlaceholder = useMemo(() => {
    const skeletons = [];
    for (let i = 0; i < 4; i++) {
      skeletons.push(
        <Skeleton key={i} loading active>
          <List.Item.Meta />
        </Skeleton>,
      );
    }
    return <S.Container>{skeletons}</S.Container>;
  }, []);

  const steps = useMemo(() => {
    const questionnaireSteps: { title: string; content: React.ReactNode }[] = [];
    if (assessmentActivities.length > 0) {
      assessmentActivities.forEach((activity: GQL_InvestigationActivity, i: number) => {
        questionnaireSteps.push({
          title: `${activity.name}`,
          content: (
            <AssessmentQuestionnaire
              values={questionnaireForm}
              onChangeForm={onChangeForm}
              activityIndex={assessmentPart}
              formValidation={false}
              listenToValueChange={listenToValueChange}
            />
          ),
        });
      });
    }

    return questionnaireSteps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangeForm, questionnaireForm, finalReportAverage, assessmentPart, assessmentActivities, listenToValueChange]);

  return (
    <PageWithTitle
      title={
        <S.TitleContainer>
          {loadingInvestigation || loading
            ? 'Loading...'
            : `Report Score for ${investigationData?.getInvestigationById.title}`}
        </S.TitleContainer>
      }
    >
      <Row justify="center">
        <Col span={24}>
          {loading ? (
            loadingPlaceholder
          ) : (
            <>
              <Steps current={current} onChange={onChangeStep}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>

              <Row justify="center" style={{ marginTop: 20 }}>
                <Col span={24}>{steps[current]?.content || null}</Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Row justify="end" style={{ margin: '10px 0' }}>
        <Col span={24}>
          {current === assessmentActivities.length - 1 ? (
            <Button block size="large" onClick={() => history.goBack()} text="Go to dashboard" />
          ) : (
            <Button block size="large" onClick={() => onChangeStep(current + 1)} text="Next Part" />
          )}
        </Col>
      </Row>
    </PageWithTitle>
  );
};

export default AssessmentGradeReportPage;
