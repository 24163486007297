import React, { useState } from 'react';
import { IMediaContentValue, InvestigationType } from '../../../types/investigation';
import * as S from './styles';
interface IInvestigationImage {
  value?: IMediaContentValue;
  investigationType?: InvestigationType;
  contentSize?: string;
}

const InvestigationImage = (props: IInvestigationImage) => {
  const { value, investigationType, contentSize } = props;
  const [status, setStatus] = useState<'loading' | 'loaded' | 'error'>('loading');
  const [zoom, setZoom] = useState(false);

  if (!value) return null;

  const fileUrl = value?.file ? URL.createObjectURL(value.file) : undefined;

  return (
    <>
      {status === 'loading' && <S.InvestigationImagePlaceholder style={{}} />}
      {status === 'error' && <p>There was an error loading the image</p>}

      {zoom && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: 5000,
            background: '#000000AA',
            cursor: 'zoom-out',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => setZoom(!zoom)}
          data-cy="components-investigation-image-zoomed"
        >
          <S.ZoomedImage
            imageURL={value?.url || fileUrl || ''}
            onClick={() => setZoom(!zoom)}
            onLoad={() => setStatus('loaded')}
            onLoadStart={() => setStatus('loading')}
            onError={() => setStatus('error')}
          />
        </div>
      )}

      <S.InvestigationImage
        data-cy="components-investigation-image"
        contentSize={contentSize}
        investigationType={investigationType}
        loaded={status === 'loaded'}
        imageURL={value?.url || fileUrl}
        onClick={() => setZoom(!zoom)}
        size={value?.size}
      />

      {/**
       * This is just a dummy image to get the load state.
       * Browser caching should handle image not loading twice
       */}
      <img
        style={{ display: 'none' }}
        alt=""
        src={value?.url || fileUrl}
        onLoad={() => setStatus('loaded')}
        onLoadStart={() => setStatus('loading')}
        onError={() => setStatus('error')}
      />
    </>
  );
};

export default InvestigationImage;
