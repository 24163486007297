import { GQL_InvestigationStandard } from '../types/investigationStandard';

/**
 * Core ideas for certain disciplines have a suffix `-{}-{}` to their code.
 * This suffix shows that the core idea is of a subject which is a state edition of a different subject.
 * This suffix should not be displayed to the user, and this method removes this suffix from the code.
 * @param code The `code` of a core idea
 * @returns The `code` without state or grade-specific suffix
 */
export const cleanCoreIdeaCode = (code: string): string => {
  const codeParts = code.split('-');
  return codeParts.length > 1 ? codeParts[0] : code;
};

/**
 * Comparator function for sorting standards based on their 'code' property.
 *
 * @param {GQL_InvestigationStandard} a - The first standard object to compare.
 * @param {GQL_InvestigationStandard} b - The second standard object to compare.
 * @returns {number} - A negative, zero, or positive number, indicating the sorting order.
 *   - Negative if 'a' should be sorted before 'b'.
 *   - Zero if 'a' and 'b' are considered equal in sorting order.
 *   - Positive if 'a' should be sorted after 'b'.
 *
 * @throws {Error} Will throw an error if 'a' or 'b' is not an object or if 'code' property is missing.
 *
 * @example
 * const data = [
 *   { code: 'b.10.A' },
 *   { code: 'a.5.B' },
 *   { code: 'c.2.A' },
 *   { code: 'd.15' },
 * ];
 * const result = data.sort(sortStandardsByCode);
 * console.log(result);
 */
export const sortTexasStandardsByCode = (a: GQL_InvestigationStandard, b: GQL_InvestigationStandard) => {
  const codeA = a.code.split('.');
  const codeB = b.code.split('.');

  // Compare the first letter
  const letterComparison = codeA[0].localeCompare(codeB[0]);

  if (letterComparison !== 0) {
    return letterComparison;
  }

  // Compare the numeric part as numbers
  const numberA = parseInt(codeA[1], 10);
  const numberB = parseInt(codeB[1], 10);

  if (numberA !== numberB) {
    return numberA - numberB;
  }

  // Compare the second letter (handle undefined)
  const secondLetterA = codeA[2] || '';
  const secondLetterB = codeB[2] || '';

  return secondLetterA.localeCompare(secondLetterB);
};

/**
 * Comparator function for sorting grade values.
 * Grades are compared numerically based on a predefined mapping.
 *
 * @param {string} a - The first grade value to compare.
 * @param {string} b - The second grade value to compare.
 * @returns {number} - A negative, zero, or positive number, indicating the sorting order.
 *   - Negative if 'a' should be sorted before 'b'.
 *   - Zero if 'a' and 'b' are considered equal in sorting order.
 *   - Positive if 'a' should be sorted after 'b'.
 *
 * @throws {Error} Will throw an error if 'a' or 'b' is not a string or if the grade value is not recognized.
 *
 * @example
 * const grades = ['K', '1', '2', '3-5', '6-8', '9-12'];
 * const sortedGrades = grades.sort(sortGradeList);
 * console.log(sortedGrades);
 */
export const sortGradeList = (a: string, b: string) => {
  // Define a mapping for grade values
  const gradeValues: {
    [key: string]: number;
  } = {
    K: 0,
    '1': 1,
    '01': 1,
    '2': 2,
    '02': 2,
    '3': 3,
    '03': 3,
    '4': 4,
    '04': 4,
    '5': 5,
    '05': 5,
    '6': 6,
    '06': 6,
    '7': 7,
    '07': 7,
    '8': 8,
    '08': 8,
    'K-2': 9,
    '3-5': 10,
    '6-8': 11,
    '9-12': 12,
  };

  // Extract numeric values for comparison
  const gradeA = gradeValues[a] || 0;
  const gradeB = gradeValues[b] || 0;

  // Compare grades numerically
  return gradeA - gradeB;
};
