import { message, Modal, Tabs } from 'antd';
import React, { useCallback, useState } from 'react';
import Button from '../../../shared/Button';
import Spacer from '../../../shared/Spacer';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationContent,
  IMediaContentValue,
  ITextContentValue,
  ITextSubmitContentValue,
} from '../../../types/investigation';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import InvestigationText from '../../StudentInvestigation/InvestigationText';
import InvestigationImage from '../../StudentInvestigation/InvestigationImage';
import InvestigationVideo from '../../StudentInvestigation/InvestigationVideo';
import InvestigationTextImage from '../../StudentInvestigation/InvestigationTextImage';
import Editor from '../../../shared/Editor';
import MultipleChoiceQuestionPreview from './MultipleChoiceQuestionPreview';
import MultipleChoicePictureQuestion from './MultipleChoicePictureQuestionPreview';
import ProcessDragAndDropAnswerPreview from './ProcessDragAndDropAnswerPreview';
import CategoryDragDropQuestionPreview from './CategoryDragPreview';
interface IPreviewAssessmentModal {
  open: boolean;
  onClose: () => void;
  loading?: boolean;
  title: string;
  assessmentId: string;
}

const PreviewAssessmentModal = (props: IPreviewAssessmentModal) => {
  const { open, onClose, loading, title, assessmentId } = props;
  const [assessment, setAssessment] = useState<GQL_InvestigationCatalogEntry>();
  const [tab, setTab] = useState<string>('0');
  const handleClose = useCallback(() => {
    setTab('0');
    onClose?.();
  }, [onClose, setTab]);

  useQuery<{ getInvestigationDraftById: GQL_InvestigationCatalogEntry }, { id: string }>(
    gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationDraftById,
    {
      variables: {
        id: assessmentId,
      },
      fetchPolicy: 'cache-first',
      onCompleted: ({ getInvestigationDraftById }) => {
        setAssessment(getInvestigationDraftById);
      },
      onError: (err) => {
        message.error(`There was an error getting the assessment: ` + (err.message || 'Unexpected Error'));
        onClose();
      },
    },
  );

  const buildContentBlock = useCallback(
    (block: GQL_InvestigationBlock, currentContent: GQL_InvestigationContent, currentContentIndex: number) => {
      const span = block.columns && block.type !== 'Canvas' ? 24 / block.columns : 24;
      switch (block.type) {
        case 'Text': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              {<InvestigationText value={value as ITextContentValue} />}
            </S.Column>
          ));
        }
        case 'Image': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${(value as IMediaContentValue).url}${i}`}>
              {<InvestigationImage value={value as IMediaContentValue} />}
            </S.Column>
          ));
        }
        case 'Video': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              {<InvestigationVideo value={value as IMediaContentValue} />}
            </S.Column>
          ));
        }
        case 'TextSubmit': {
          return (block.values as ITextSubmitContentValue[]).map((value, i) => (
            <S.Column span={span} key={`${value.title}${i}`}>
              <Spacer />
              <h3>{value.title}</h3>
              <Spacer />
              <Editor
                data-cy="components-text-submit-editor-editable"
                placeholder={value.placeholder}
                editable={true}
                key="editable"
              />
            </S.Column>
          ));
        }
        case 'TextImage':
          return block.values.map((value, i) => (
            <S.Column
              span={Math.round(((value as ITextContentValue).ratio || 0.5) * 24)}
              style={{ justifyContent: 'center' }}
              key={`image-${i}`}
            >
              {<InvestigationTextImage value={value as ITextContentValue | IMediaContentValue} />}
            </S.Column>
          ));
        case 'MultipleChoiceQuestion':
          return (
            <S.Column span={span} key={`MultipleChoiceQuestion`}>
              <MultipleChoiceQuestionPreview block={block} />
            </S.Column>
          );
        case 'MultipleChoicePictureQuestion':
          return (
            <S.Column span={span} key="MultipleChoicePictureQuestion">
              <MultipleChoicePictureQuestion block={block} />
            </S.Column>
          );

        case 'CategoryDrag':
          return (
            <S.Column span={span} key={`MultipleChoiceQuestion`}>
              <CategoryDragDropQuestionPreview block={block} />
            </S.Column>
          );
        case 'CategoryDragPicture':
          return (
            <S.Column span={span} key={`MultipleChoiceQuestion`}>
              <CategoryDragDropQuestionPreview block={block} isImage={true} />
            </S.Column>
          );
        case 'ProcessDragDropAnswer':
          return (
            <S.Column span={span} key={`ProcessDragDropAnswer`}>
              <ProcessDragAndDropAnswerPreview block={block} />
            </S.Column>
          );
        case 'ProcessDragDropPictureAnswer':
          return (
            <S.Column span={span} key={`ProcessDragDropPictureAnswer`}>
              <ProcessDragAndDropAnswerPreview block={block} isImage={true} />
            </S.Column>
          );
        default:
          return <></>;
      }
    },
    [],
  );

  const parts = (assessment?.steps || []).reduce<GQL_InvestigationCatalogActivityEntry[]>((prompts, step) => {
    prompts.push(...step.activities);
    return prompts;
  }, []);

  const buildPartContent = useCallback(
    (part: GQL_InvestigationCatalogActivityEntry) => {
      return (
        <>
          {part.content.map((content, contentIndex) => {
            return (
              <div key={`part-${contentIndex}`}>
                <>
                  {content.blocks.map((block, blockIndex) => {
                    return (
                      <S.BlockPreviewContainer key={`block-${blockIndex}`}>
                        {buildContentBlock(block, content, contentIndex)}
                      </S.BlockPreviewContainer>
                    );
                  })}
                </>
              </div>
            );
          })}
        </>
      );
    },
    [buildContentBlock],
  );
  return (
    <Modal visible={open} onCancel={handleClose} footer={null}>
      <h2 data-cy="components-preview-assessment-modal">{title}</h2>

      {parts.length > 1 ? (
        <Tabs tabPosition={'top'} activeKey={tab} onChange={(tabIndex) => setTab(tabIndex)}>
          {parts.map((part, index) => {
            return (
              <Tabs.TabPane tab={part.name} key={index}>
                {buildPartContent(part)}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      ) : parts?.[0] ? (
        buildPartContent(parts[0])
      ) : null}

      <Spacer />
      <S.ButtonContainer>
        <Button text="Close" loading={loading} theme={themeConfig.secondaryOutlined} onClick={handleClose} />
        <Spacer axis="horizontal" />
      </S.ButtonContainer>
    </Modal>
  );
};

export default PreviewAssessmentModal;
