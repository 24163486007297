import styled from 'styled-components';

export const SummaryContainer = styled.div`
  padding: 20px;
`;

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const InfoProgress = styled.p`
  font-size: 0.45em;
  margin: 10px 0 0 0;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 768px) {
   display:block;
}

  > div {
    flex: 1 1 30%;
    margin: 10px 0;
  }
`;

export const InfoRow = styled.div`
  margin-bottom: 10px;
`;

export const InfoLabel = styled.div`
  font-weight: bold;
`;

export const InfoValue = styled.div`
  margin-top: 5px;
`;

export const AssignmentLinks = styled.div`
  margin-top: 5px;

  > div {
    margin-bottom: 5px;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button:first-child {
    margin-right: 10px;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const UploadedLinks = styled.div`
  margin-top: 10px;
  a {
    display: block;
    margin-bottom: 5px;
    text-decoration: none;
    color: #1890ff;
    &:hover {
      text-decoration: underline;
    }
  }
`;
