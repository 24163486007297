import { Tooltip } from 'antd';
import React from 'react';
import * as S from './styles';

interface Props {
  color: string;
  text: string;
  onClick?: () => void;
  className?: string;
  maxWidth?: number;
}

const RoundedTag: React.FC<Props> = (props) => {
  const { color, text, onClick, className, maxWidth } = props;
  return (
    <Tooltip overlay={text}>
      <S.Tag $color={color} onClick={onClick} className={className} $maxWidth={maxWidth}>
        {text}
      </S.Tag>
    </Tooltip>
  );
};

export default RoundedTag;
