import { Upload } from 'antd';
import styled from 'styled-components';

export const SiderContainer = styled.div`
  height: calc(100% - 58px);
  display: flex;
  flex-direction: column;
`;

export const SettingsContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const UploadContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  color: #3f3e38;
  font-size: 14px;
  align-items: center;
  position: relative;

  svg:nth-child(3) {
    position: absolute;
    right: 16px;
  }
`;

export const UploadOutsider = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`;
export const UploadedFileContainer = styled.div`
  margin-top: 8px;
`;

export const FileLink = styled.a`
  color: #007bff; /* A blue link color */
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export const SiderTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;
