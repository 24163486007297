import styled from 'styled-components';
import { Tabs, Button, Tag as AntTag } from 'antd';

export const CardContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  overflow:hidden;
  text-align: center;
  max-width:705px;
  position: relative;
  transition: min-width 0.2s ease-in-out;

  &.fullview {
    max-width: 900px;
    min-width: 900px;
  }

  &.hidden {
    display: none;
  }


  .action-btn-container{
    button{
      max-width:231px;
      width:100%;
      border:none;
      font-size:14px;
      padding:12px;
      margin-right:30px;
      margin-bottom:22px;
      border-radius:2px;
      border:1px solid #4367E9;
      background:#fff;
      color:#4367E9;
      &.active{
        background:#4367E9;
        color:#fff;
      }
    }
  }
`;


export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0px;
    display: flex;
    justify-content: space-around;
  }
  .ant-tabs-tab {
    flex: 1;
    text-align: center;
    font-size: 16px;
    display:block !important;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.96px;
    padding:16px 0px;
    border: 1px solid #7B8CEA !important ;


  }

  .ant-tabs-tab:nth-child(1) {
  border-top-left-radius: 8px !important;
}

.ant-tabs-tab:nth-child(4) {
  border-top-right-radius: 8px !important;
}

  .ant-tabs-tab-active {
    font-weight: 700;
    color: #fff !important;
    background:#7B8CEA !important;
  }

  .ant-tabs-tab-active  .ant-tabs-tab-btn{
   color: #fff !important;
  }
  .ant-tabs-ink-bar {
    background-color: #3f78e0;
  }
    .ant-tabs-nav-list{
      justify-content:space-between;
    }

  .ant-tabs-nav-wrap{
    display:block !important;
  }

  .ant-tabs-tab-btn{
  color: #7B8CEA !important;
  }

  .ant-tabs-tab{
    margin:0px !important  
  }
    
`;

export const TabPane = styled(Tabs.TabPane)``;

export const GradeText = styled.div`
  font-size: 18px;
  color: #666666;
  text-align: left;
  padding:30px 30px 0px 30px;
  
`;

export const Icons = styled.div`
display:flex;
justify-content:flex-end;
align-items:center;
gap: 12px;
position: absolute;
right: 0;
margin: 1rem;
`;

export const Icon = styled.div`
  color: #666666;
  max-width: 40px;
  max-height: 40px;
  padding: 0.8rem;
  margin: auto;
  box-shadow: 0px 0px 34px 2px #0000001C;
  border-radius: 100%;
  display: inline-block;

  &:hover {
  color: #7B8CEA !important;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin:auto;
  color: #333333;
 margin-top:20px;
 max-width:360px;
`;

export const Description = styled.p`
  font-size: 14px;
    color: #888888;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 0px;
    max-width: 504px;
    margin-bottom:95px;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin-bottom: 24px;
`;

export const Tag = styled(AntTag)<{ color: string }>`
  border-radius: 16px;
  font-size: 14px;
  padding: 4px 12px;
  background-color: #ffffff;
  // color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
`;

export const ActionButton = styled(Button)`
  background-color: #3f78e0;
  color: #ffffff;
  font-weight: 500;
  border-radius: 8px;
 
  height: 40px;
  margin: 8px 0;
  padding: 0 16px; 

  &:hover {
    background-color: #336ac2;
    color: #ffffff;
  }
`;


export const LockedButton = styled(ActionButton)`
  background-color: transparent;
  color: #3f78e0;
  border: 1px solid #3f78e0;
  &:hover {
    background-color: #f0f2f5;
    color: #3f78e0;
  }
`;

export const StandardTabContainer = styled.div`
  padding: 20px;

  .filters {
    display: flex;
    gap:14px;
    margin-bottom: 20px;

    .ant-select {
      width: 100%;
      max-width: 300px;
    }
  }

  .standards-list {
    margin-top: 20px;
    // background-color: #fafafa;
    // border: 1px solid #e8e8e8;
    // border-radius: 8px;

    .ant-list-item {
      padding: 10px 16px;
      font-size: 14px;
      line-height: 1.5;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .download-button {
    margin-top: 20px;
    text-align: center;

    .ant-btn {
      padding: 10px 20px;
      font-size: 16px;
    }
  }

  .list-container{
    p{
      padding:4px 11px;
      border:1px solid #d9d9d9;
      margin-bottom:20px;
      font-family: Rubik;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;
border-radius:2px;


    }
  }

  .action-btn-container {
    button{
      margin-top:121px;
    }
  
  }


  .ant-select{
    max-width:194px !important;
    width:100% !important;

    .search-input{
      text-alugn:left !important;
          max-width: 194px !important;
    width: 100% !important;
    border: 1px solid #7B8CEA;
    }
  }
`;

export const TableContainer = styled.div`
  width: 65%;
  margin: 20px auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff; 
`;


export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 8px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;

  button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff; 
    color: white; 
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3; 
    }
  }
`;


export const StyledButton = styled.button`
  padding: 12px 24px;
  margin: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:first-child {
    background-color: #007bff;
    color: white;

    &:hover {
      background-color: #0056b3;
    }
  }

  &:last-child {
    background-color: white;
    color: #007bff;
    border: 1px solid #007bff;

    &:hover {
      background-color: #e6f0ff;
    }
  }
`;

export const MaterialsModalContainer = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125.1%;
    text-align: left;

    color: #767676;
  }

  & > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;

    text-align: center;
  }

  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125.1%;
    width: 280px;
    margin: auto;

    text-align: center;

    color: #767676;
  }
`;
export const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 35px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: default;
  p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    margin: 0;
  }
`;
export const DownloadLink = styled.a`
  background-color: ${(props) => props.theme.primaryColor.background};
  color: white;
  border-radius: 3px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoDataMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: gray;
  margin: 20px 0;
`;
export const ImageGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 20px;
`;

export const ImageBlock = styled.div`
  width: 100%;
  max-height: 400px; /* Set the maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    object-fit: contain; /* Ensures the image fits without distortion */
  }
`;


export const ImageCaption = styled.p`
  margin: 10px 0;
  font-size: 14px;
  color: #555;
`;



export const LoadingMessage = styled.div`
  font-size: 16px;
  color: #007bff;
  text-align: center;
`;

export const ErrorMessage = styled.div`
  font-size: 16px;
  color: #ff4d4f;
  text-align: center;
`;


export const ScrollableBlock = styled.div`
width: 100%;
height: 60%; /* Adjust height as necessary */
overflow: auto;
border: 1px solid #ccc; /* Optional: To indicate scrollable area */
padding: 10px; /* Optional: For spacing */
`;


export const ErrorBlock = styled.div`
  width: 100%;
  height: 500px; /* Match the height of simulation or image blocks */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa; /* Optional: Light background for better visibility */
  border: 1px solid #ccc; /* Optional: To indicate consistent layout */
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin:auto;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const OverlayContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;  /* Increase the width to 90% of the viewport */
 height: 100%;
  max-width: 900px;  /* You can increase this to any specific value you prefer */
 
  position: relative;

  iframe {
    border-radius: 8px;
    margin-bottom: 20px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #000;
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  button {
    background-color: #007bff;
    color: white;
    border: none;
    height:100%;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #0056b3;
    }
  }
`;
