import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { withRouter, useParams } from 'react-router-dom';
import { Divider, message, Spin } from 'antd';

import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import ClassInfos from './ClassInfos';
import AssignmentTable from './AssignmentTable';
import { GQL_GoogleClassroomStudent } from '../../types/googleClassroom';

const GoogleTeacherAssignmentSummary = () => {
  const { courseId, courseWorkId } = useParams<{ courseId: string; courseWorkId: string }>();

  const { data: courseWorkData, loading: courseWorkLoading, refetch: refetchCourseWork } = useQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
    {
      variables: { courseId, id: courseWorkId },
      onError: (err) => {
        message.error('There was an error loading class: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const { data: courseData, loading: courseLoading } = useQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseByCourseId,
    {
      variables: { courseId },
      onError: (err) => {
        message.error('There was an error loading course: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const { data: studentsData, loading: studentsLoading, error: studentsError } = useQuery<GQL_GoogleClassroomStudent>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudents,
    {
      variables: { courseId },
      onError: (err) => {
        message.error('There was an error loading students: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const [studentNames, setStudentNames] = useState<{ userId: string; fullName: string }[]>([]);

  React.useEffect(() => {
    if (studentsData?.getCourseStudents?.students) {
      const names = studentsData.getCourseStudents.students.map(student => ({
        userId: student.userId,
        fullName: student.profile.name.fullName,
      }));
      setStudentNames(names);
    }
  }, [studentsData]);

  const handleRefetch = () => {
    refetchCourseWork();
  };

  const dueDate = courseWorkData?.getCourseWorkById?.dueDate;
  const section = courseData?.getCourseByCourseId?.section || '';

  if (courseWorkLoading || studentsLoading || courseLoading) {
    return (
      <PageWithTitle title={`Assignment Details`} backPageUrl="/googleclassroom-teacher-dashboard">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Spin size="large" />
        </div>
      </PageWithTitle>
    );
  }

  if (courseWorkData && studentsData && courseData) {
    const className = courseData.getCourseByCourseId.name || '';
    const assignmentLinks = courseWorkData.getCourseWorkById?.materials
      ?.filter((material: { link?: { url?: string } }) => material.link)
      ?.map((material: { link?: { url?: string } }) => material.link?.url || '') || [];

      const alternateLink = courseWorkData.getCourseWorkById?.alternateLink || '';
      

    return (
      <PageWithTitle title={`Assignment Details`} backPageUrl="/googleclassroom-teacher-dashboard">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <>
            <ClassInfos
              classInfo={courseWorkData?.getCourseWorkById}
              className={className}
              studentNames={studentNames}
              onDeleteSuccess={handleRefetch}
              dueDate={dueDate}
              alternateLink={alternateLink} // Pass the alternateLink here
              assignmentLinks={assignmentLinks}
              
            />
           <Divider />
            <AssignmentTable
              courseId={courseId}
              courseWorkId={courseWorkId}
              className={className}
              section={section}
              assignmentName={courseWorkData?.getCourseWorkById?.title || ''}
              assignmentLinks={assignmentLinks}
              progress={75} // Placeholder, adjust as needed
              courseName={className}
              studentNames={studentNames}
              dueDate={dueDate}
            />
          </>
        </div>
      </PageWithTitle>
    );
  }

  return (
    <PageWithTitle title={`Assignment Details`} backPageUrl="/googleclassroom-teacher-dashboard">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        {studentsError && <p>Error: {studentsError.message}</p>}
      </div>
    </PageWithTitle>
  );
};

export default withRouter(GoogleTeacherAssignmentSummary);
