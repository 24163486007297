import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import styled from 'styled-components';
import { gqlSchema } from '../gql/schema';
import TagInput from '../shared/TagInput';
import { TagResponse } from '../types/tags';
import { Breakpoint, centerAlign } from '../utils/antd';

const TableBreakPoint: Breakpoint[] = ['lg'];
interface IUseTagColumn {
  filterOnBackend?: boolean;
  width?: string;
  className?: string;
}

const SearchIcon = styled.span<{
  $searchVisible: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 10px 4px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => (props.$searchVisible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px' : 'unset')};
`;

function useTagColumn<T extends { tags?: TagResponse[] }>(payload?: IUseTagColumn) {
  const { filterOnBackend, width, className } = payload || {};
  const [investigationFilterVisible, setInvestigationFilterVisible] = useState(false);

  const { data: tagsData, loading } = useQuery<{ getTags: TagResponse[] }>(gqlSchema.TagsSchema.queries.getTags);

  const columnField = {
    title: 'Tags',
    filters: tagsData?.getTags?.map((t) => ({
      text: t.tag,
      value: t.id,
      color: t.color,
    })),
    width: width ?? '200px',
    className,
    dataIndex: 'tags',
    onFilter: (value: string | number | boolean, record: T): boolean => {
      return filterOnBackend || !!record.tags?.map((t) => t.id)?.includes(value?.toString());
    },
    filterIcon: (filtered: boolean) => (
      <SearchIcon $searchVisible={investigationFilterVisible}>
        <FiFilter size={18} style={{ color: filtered ? '#1890ff' : undefined }} />
      </SearchIcon>
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      setInvestigationFilterVisible(visible);
    },
    responsive: TableBreakPoint,
    align: centerAlign,
    render: (text: string, record: T) => {
      return <TagInput existingUserTags={record.tags} editable={false} />;
    },
  };

  return { columnField, loading };
}

export default useTagColumn;
