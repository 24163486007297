import React, { useMemo, useState } from 'react';
import { Table, Button, Tabs, Badge } from 'antd'; // Import Badge
import * as S from './styles';
import AddStudents from './AddStudents';
// import AddTeachers from './AddTeachers';

const { TabPane } = Tabs;

interface Props {
  classId: string;
  loading: boolean;
  students: Array<{ id: string, name: string, login_id: string }>;
  teachers: Array<{ id: string, name: string, login_id: string }>;
  onUpdateNumberOfStudents?: (count: number) => void;
  onUpdateNumberOfTeachers?: (count: number) => void;
}

const CanvasStudentsTable: React.FC<Props> = ({ classId, loading, students, teachers, onUpdateNumberOfStudents, onUpdateNumberOfTeachers }) => {
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  // const [showAddTeachersModal, setShowAddTeachersModal] = useState(false);

  const studentColumns = useMemo(() => {
    return [
      {
        title: 'Student Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: { login_id: string }) => (
          <span>
            {text} 
            {!record.login_id && (
              <Badge
                status="default"
                text="Pending"
                style={{
                  backgroundColor: '#3EBC89',
                  color: 'white',
                  marginLeft: 8,
                  borderRadius: 4,
                  padding: '0 8px',
                  lineHeight: '20px', // Adjust line height to center text vertically
                }}
              />
            )}
          </span>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'login_id',
        key: 'login_id',
        render: (email: string) => email || '-',
      },
    ];
  }, []);

  const teacherColumns = useMemo(() => {
    return [
      {
        title: 'Teacher Name',
        dataIndex: 'name',
        key: 'name',
        render: (fullName: string) => fullName || '-',
      },
      {
        title: 'Email',
        dataIndex: 'login_id',
        key: 'login_id',
        render: (email: string) => email || '-',
      },
    ];
  }, []);

  return (
    <>
      <S.Title>Users</S.Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Students Details" key="1">
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
          <Button type="primary" style={{ backgroundColor: '#4367E9', borderColor: '#4367E9' }}  onClick={() => setShowAddStudentsModal(true)}>Add Students</Button>
          </div>
          <Table
            columns={studentColumns}
            loading={loading}
            dataSource={students}
            pagination={{
              hideOnSinglePage: false,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40'],
            }}
          />
        </TabPane>
        <TabPane tab="Teacher Details" key="2">
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
          <Button type="primary" style={{ backgroundColor: '#4367E9', borderColor: '#4367E9' }}  onClick={() => setShowAddTeachersModal(true)}>Add Teachers</Button>
          </div> */}
          <Table
            columns={teacherColumns}
            loading={loading}
            dataSource={teachers}
            pagination={{
              hideOnSinglePage: false,
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '20', '30', '40'],
            }}
          />
        </TabPane>
      </Tabs>
      <AddStudents
        visible={showAddStudentsModal}
        setVisible={setShowAddStudentsModal}
        courseId={classId}
      />
      
      {/* <AddTeachers
        visible={showAddTeachersModal}
        setVisible={setShowAddTeachersModal}
        courseId={classId}
      /> */}
    </>
  );
};

export default CanvasStudentsTable;
