import { useQuery } from '@apollo/client';
import React from 'react';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import { IUploadContentValue } from '../../../types/investigation';
import { GQL_InvestigationSummaryResponse } from '../../../types/teacher';
import InvestigationRecordScreen from './InvestigationRecordScreen';
import SubmitFile from './SubmitFile';

interface IInvestigationUpload {
  value: IUploadContentValue;
  stepId: string;
  activityId: string;
  investigationId?: string;
  currentContentIndex?: number;
  userId?: string;
  isReport?: boolean;
}

const InvestigationUpload = (props: IInvestigationUpload) => {
  const { value, activityId, stepId, investigationId, currentContentIndex, userId, isReport } = props;
  const hasFile = value.url && value.url !== '/';
  const { isTeacherOrFacilitator, user, isBookUser} = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const { data: investigationData } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId || '',
    },
    fetchPolicy: 'cache-only',
  });

  const student = investigationData?.getInvestigationProgressSummary?.perStudents?.find(
    (student) => student.userId === userId,
  );

  if (!hasFile && (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher || isBookUser)) {
    return <p>No submissions yet</p>;
  }

  return value.type === 'Recording' ? (
    <InvestigationRecordScreen
      stepId={stepId}
      fileUrl={value.url}
      activityId={activityId}
      student={student}
      hidden={value.hidden}
    />
  ) : (
    <SubmitFile
      investigationId={investigationId}
      type={value.type}
      stepId={stepId}
      currentContentIndex={currentContentIndex}
      activityId={activityId}
      fileUrl={value.url}
      student={student}
      mimeType={value.MIMEtype}
      isReport={isReport}
      hidden={value.hidden}
    />
  );
};

export default InvestigationUpload;
