import { Col, Row as AntdRow } from 'antd';
import styled from 'styled-components';

export const Column = styled(Col)`
  padding: 40px !important;
  background-color: white;
  border-radius: 6px;
  text-align: left;
  line-height: 1.3;
  & > div {
    margin-bottom: 16px;
  }
`;

export const TitleRow = styled(AntdRow)`
  p {
    margin: 0;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;

    color: #767676 !important;
  }
  h1 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
    margin-right: 20px;
  }

  font-family: Rubik !important;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-rate {
    margin-left: 25px;
    font-size: 16px;
    margin-bottom: 3px;
    color: #fadb14 !important;
  }
`;
