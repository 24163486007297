import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { gqlSchema } from '../gql/schema';
import { SUBJECT_TYPES } from '../types/subjects';
import { message } from 'antd';

interface IUseFetchCorrelationFileUrl {
  gradeBand: string;
  subject: string;
  version: string | null;
}

const useFetchCorrelationFileUrl = (props: IUseFetchCorrelationFileUrl) => {
  const [fileUrl, setFileUrl] = useState('');
  const [teacherManualUrl, setTeacherManualUrl] = useState('');

  const { data } = useQuery(gqlSchema.AdvancedSearchCorrelationSchema.queries.GET.getCorrelation, {
    variables: {
      subject: SUBJECT_TYPES.SCIENCE_TEXAS_EDITION,
      gradeBand: props.gradeBand,
      version: props.version || null,
    },
    onError: () => {
      message.error('An error occured while fetching the data.').then(() => {
        /* Empty function to suppress warning */
      });
    },
  });

  useEffect(() => {
    setFileUrl(data?.getCorrelationFileUrl?.fileUrl || '');
    setTeacherManualUrl(data?.getCorrelationFileUrl?.teacherManualUrl || '');
  }, [data]);

  return { fileUrl, teacherManualUrl };
};

export default useFetchCorrelationFileUrl;
