import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';
import moment from 'moment';

import { gqlSchema } from '../../gql/schema';
import Button from '../../shared/Button';
import RangePicker from '../../shared/RangePicker';
import { GQL_InvestigationDisplay } from '../../types/investigation';
import * as S from './styles';
import { GQL_ClassInvestigation, GQL_ClassResponse } from '../../types/class';
import Select from '../../shared/Select';
import SelectOption from '../../shared/Select/Option';
import { message } from 'antd';
import { themeConfig } from '../../utils/theme';
import { toDateFormat } from '../../utils/date';
import { useAuth } from '../../hooks/useAuth';
import AssignAssessmentClassDatePicker from '../AssessmentAssignmentPopup/AssignAssessmentClassDatePicker/AssignAssessmentClassDatePicker';
import { AssessmentTypes } from '../../types/assessments';
import useInvestigationMode from '../../hooks/useInvestigationMode';

type Props = RouteComponentProps<{
  classId?: string;
  type?: string;
  investigationId: string;
}>;

const AssignInvestigationPage: React.FC<Props> = (props) => {
  const { investigationId, classId, type } = props.match.params;
  const history = useHistory();
  const location = useLocation();
  const isAssessment = useMemo(() => history.location.pathname.includes('assign-assessment'), [history]);
  const label = useMemo(() => (isAssessment ? 'Assessment' : 'Investigation'), [isAssessment]);
  const [selectedClasses, setSelectedClasses] = useState(classId ? [classId] : []);
  const [selectedDateTimes, setSelectedDateTimes] = useState<{
    [key: string]: [startDate: any, endDate: any];
  }>({});

  const pathParts = location.pathname.split('/');
  if (isAssessment) {
    pathParts.pop();
  }
  pathParts.pop();
  const backUrl = pathParts.join('/');

  const [duration, setDuration] = useState<{
    startDate: string;
    endDate: string;
  }>();

  const { isFacilitator, user } = useAuth();
  const classTitle = useMemo(() => (isFacilitator ? 'Course or Event' : 'Class'), [isFacilitator]);
  const [assignInvestigation, { loading: loadingAssign }] = useMutation<
    {
      assignInvestigationToClasses: GQL_ClassInvestigation[];
    },
    {
      classIds: string[];
      investigationId: string;
      duration: {
        startDate: number;
        endDate: number;
      };
      mode: string | null;
    }
  >(gqlSchema.ClassSchema.mutation.CLASS.EDIT.assignInvestigations, {
    onCompleted: ({ assignInvestigationToClasses }) => {
      if (assignInvestigationToClasses?.length === 1) {
        message.success(`${label} assigned successfully. You can now configure it.`);
        history.push(`/teacher-investigation/${type}/${assignInvestigationToClasses[0].id}/settings`);
      } else {
        message.success(
          `${label} assigned successfully to all classes. Go to respective class dashboards to configure them.`,
        );
        if (user.preferredRole === 'google_teacher') {
          history.push(`/googleclassroom-teacher-dashboard`);
        } else if (user.preferredRole === 'canvas_teacher') {
          history.push(`/canvas-teacher-dashboard`);
        } else {
          history.push('/teacher-dashboard');
        }
      }
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: [
      'GetInvestigationsByTeacher',
      'GetClasses',
      'getInvestigationsByClassId',
      { query: gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.getUserAssigmentsLimits },
    ],
    awaitRefetchQueries: true,
  });

  const [assignAssessments, { loading: loadingAssess }] = useMutation<{
    classIds: string[];
    assessmentId: string;
    duration: {
      startDate: number;
      endDate: number;
    };
  }>(gqlSchema.ClassSchema.mutation.CLASS.EDIT.assignAssessments, {
    onCompleted: () => {
      message.success(`${label} assigned successfully. You can now configure it.`);
      history.push('/teacher-dashboard');
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: [
      'GetInvestigationsByTeacher',
      'GetClasses',
      'getInvestigationsByClassId',
      { query: gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.getUserAssigmentsLimits },
    ],
    awaitRefetchQueries: true,
  });

  const { data: investigationData, loading: loadingInvestigation } = useQuery<
    { getInvestigationCatalogById: GQL_InvestigationDisplay },
    { investigationId: string; isAssessment: boolean }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalogById, {
    variables: {
      investigationId: investigationId,
      isAssessment: isAssessment,
    },
    onError: (err) => {
      message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
    },
  });

  const { data: classesData, loading: loadingClasses } = useQuery<{
    getClasses: GQL_ClassResponse[];
  }>(gqlSchema.ClassSchema.query.CLASS.CLASSES.getClasses, {
    onError: (error) => {
      message.error(
        error.message || 'There was an error loading your classes, check you connection and try again later.',
      );
    },
  });

  const investigation = investigationData?.getInvestigationCatalogById;
  const classes = classesData?.getClasses;

  const [assessmentMode, setAssessmentMode] = useState<AssessmentTypes>(AssessmentTypes.PAPER);
  const investigationMode = investigationData?.getInvestigationCatalogById?.steps?.[0]?.mode;
  const { AssessmentTypeOptions } = useInvestigationMode(investigationMode || '');

  const updateSelectAssessmentMode = (value: AssessmentTypes) => {
    setAssessmentMode(value);
  };

  const disabledDates = (current: moment.Moment) => {
    return current && current < moment().endOf('day').subtract(1, 'day');
  };

  const handleAssignAssessment = async () => {
    if (selectedClasses?.length) {
      // validate if dates are set for all classes
      const doAllClassesHaveDates = selectedClasses.every((cId) => {
        const value = selectedDateTimes[cId];
        const isNotEmpty =
          cId !== undefined && value !== undefined && value !== null && Array.isArray(value) && value.length === 2;
        return isNotEmpty;
      });

      if (!doAllClassesHaveDates) {
        message.error('Please select start and end dates for all classes before submitting.');
        return;
      }

      try {
        for (const cId of selectedClasses) {
          await assignAssessments({
            variables: {
              assessmentId: investigationId,
              classIds: [cId],
              duration: {
                startDate: selectedDateTimes[cId][0].valueOf(),
                endDate: selectedDateTimes[cId][1].valueOf(),
              },
              mode: assessmentMode,
            },
          });
        }
      } catch (error: any) {
        message.error(`An error occured while assigning assessment: ${error.message}`);
      }
    } else {
      message.warn('Please, select a class');
    }
  };

  const handleAssignInvestigation = () => {
    if (selectedClasses?.length && duration) {
      assignInvestigation({
        variables: {
          classIds: selectedClasses,
          investigationId: investigationId,
          duration: {
            startDate: new Date(toDateFormat(duration.startDate)).getTime(),
            endDate: new Date(toDateFormat(duration.endDate)).getTime(),
          },
          mode: isAssessment ? assessmentMode : null,
        },
      });
    } else {
      message.warn('Please, select a class');
    }
  };

  const loading = loadingInvestigation || loadingClasses;
  const typeName = type === 'engineering' ? (isAssessment ? 'Assessment' : 'Design Challenge') : label;

  return (
    <S.PageContainer>
      <S.MainContainer>
        <h1>{investigation?.title}</h1>
        <h2 dangerouslySetInnerHTML={{ __html: investigation?.description || '' }}></h2>
        <S.Divider />

        <S.CenterContainer>
          <h3>{typeName} Duration</h3>
          {isAssessment && classes ? (
            <AssignAssessmentClassDatePicker
              classes={classes}
              selectedClasses={selectedClasses}
              selectedDateTimes={selectedDateTimes}
              setSelectedDateTimes={setSelectedDateTimes}
            />
          ) : (
            <RangePicker value={duration} onChange={setDuration} allowClear={false} disabledDate={disabledDates} />
          )}
          <h3>
            {typeName} {classTitle}
          </h3>
          <Select
            data-cy="pages-assign-investigation-select-class"
            placeholder="Select Class"
            showSearch
            mode="multiple"
            value={selectedClasses}
            menuItemSelectedIcon={<FiCheckSquare />}
            filterOption={(input, option) =>
              option?.name?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
            }
            onChange={(v) => setSelectedClasses(v as string[])}
            loading={loading || loadingClasses}
          >
            {classes?.map((classObj) => (
              <SelectOption value={classObj.id} key={classObj.id} name={classObj.name}>
                {selectedClasses.some((classId) => classId === classObj.id) ? (
                  classObj.name
                ) : (
                  <S.SelectOptionContainer>
                    {classObj.name}
                    <FiSquare />
                  </S.SelectOptionContainer>
                )}
              </SelectOption>
            ))}
          </Select>

          {isAssessment && investigationMode && (
            <>
              <h3>Assessment Mode:</h3>
              <Select
                showArrow
                placeholder="Assessment Mode "
                onChange={(v) => updateSelectAssessmentMode(v as AssessmentTypes)}
              >
                {AssessmentTypeOptions.map((t, index) => {
                  return (
                    <SelectOption key={`assessment-mode-${t.value}-${index}`} value={t.value}>
                      <span role="none" >{t.name}</span>
                    </SelectOption>
                  );
                })}
              </Select>
            </>
          )}
        </S.CenterContainer>

        <S.ButtonsContainer>
          <Button
            text="Go Back"
            theme={themeConfig.noColor}
            minHeight={40}
            block
            loading={loadingAssign || loadingAssess}
            onClick={() => history.push(backUrl)}
            data-cy="pages-assign-investigation-go-back"
          />
          <Button
            data-cy="pages-assign-investigation-assign"
            text={'Assign ' + typeName}
            minHeight={40}
            block
            loading={loadingAssign}
            onClick={isAssessment ? handleAssignAssessment : handleAssignInvestigation}
            disabled={
              isAssessment
                ? !selectedClasses?.length
                : !selectedClasses?.length || !duration?.endDate || !duration?.startDate
            }
          />
        </S.ButtonsContainer>
      </S.MainContainer>
    </S.PageContainer>
  );
};

export default withRouter(AssignInvestigationPage);
