import { Popover, Rate, Row } from 'antd';
import React from 'react';
import Button from '../../../shared/Button';
import Editor from '../../../shared/Editor';
import ExpandableCard from '../../../shared/ExpandableCard';
import Spacer from '../../../shared/Spacer';
import { formatDateTime } from '../../../utils/date';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';

interface IReflectWalkthrough {
  currentTip: number;
  popoverContent?: React.ReactNode;
}

const ReflectWalkthrough = (props: IReflectWalkthrough) => {
  const { currentTip, popoverContent } = props;

  return (
    <>
      <h1>Reflect on what you learnt from the peer review</h1>
      <p>Read over the comments from other students and then answer the questions about what you learned.</p>

      <S.Column span={24}>
        <S.TitleRow align="middle" justify="space-between">
          <Row align="middle">
            <h1>Your Feedback Reflections</h1>
          </Row>
          <p>1 / 3 Completed</p>
        </S.TitleRow>
        <ExpandableCard
          title={
            <S.TitleContainer>
              Feedback 1
              <Rate disabled value={4} />
            </S.TitleContainer>
          }
          description={`You have completed this Feedback Reflection on ${formatDateTime(
            Date.now() - 24 * 60 * 60 * 1000,
          )}`}
          completed
        />
        <ExpandableCard
          title="Feedback 2"
          description="You have not completed this Feedback Reflection."
          expandComponent={<Button text="Reflect" />}
          expandable
        />
        <Popover content={popoverContent} visible={currentTip === 4} placement="leftTop">
          <ExpandableCard
            title="Feedback 3"
            description="You have not completed this Feedback Reflection."
            shrinkComponent={<Button text="Cancel" theme={themeConfig.noColor} />}
            expandable
            defaultExpanded
          >
            <Spacer />
            <span role="none" >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ut labore et dolore mag ut labore et ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Ut enim ad minim veniam, quis nostrud ut labore et dolore mag ut labore et.
            </span>
            <Spacer />
            <Editor />
            <Spacer />
            <Row>
              <Button text="Add Reflection" />
              <Spacer axis="horizontal" />
              <Button text="See Comment" theme={themeConfig.secondaryColor} />
            </Row>
          </ExpandableCard>
        </Popover>
      </S.Column>
    </>
  );
};

export default ReflectWalkthrough;
