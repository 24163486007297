import { useLazyQuery, useQuery } from '@apollo/client';
import { Col, Dropdown, Menu, message, Row } from 'antd';
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { gqlSchema } from '../../../gql/schema';
import { GQL_Discipline, GQL_InvestigationMetadataCodebook, Duration, FilterData } from '../../../types/investigation';
import * as S from './styles';
import { FiChevronDown, FiFilter, FiX } from 'react-icons/fi';
import { startCase } from 'lodash';
import RangePicker from '../../../shared/RangePicker';
import { formatDateTime } from '../../../utils/date';
import Select from '../../Select';
import SelectOption from '../../Select/Option';
import { useAuth } from '../../../hooks/useAuth';
import { GQL_OrganizationResponse } from '../../../types/organization';
import { GQL_UserDetailsResponsePaginated, GQL_UsersListFilterInput } from '../../../types/user';
import { TagResponse } from '../../../types/tags';

interface Props {
  setDaterange?: (duration: Duration) => void;
  refreshFilter?: (filters: IKpiFilters[]) => void;
  onChangeFilterData?: (data: FilterData) => void;
  showGradeFilter?: boolean;
  showSubjectFilter?: boolean;
  showDisciplineFilter?: boolean;
  showDateRangeFilter?: boolean;
  filterUsers?: boolean;
  maxWidth?: string;
  display?: string;
}
export interface IKpiFilters {
  field: 'subject' | 'grade' | 'type' | 'tag' | 'discipline';
  value: string | string[];
}
const { SubMenu } = Menu;
const Filter = (props: Props) => {
  const [filters, setFilters] = useState<IKpiFilters[]>([]);
  const [currentOrganization, setCurrentOrganization] = useState<string | undefined>();
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [currentFreelanceTechaer, setCurrentFreelanceTechaer] = useState<string | undefined>();
  const [showFreelanceTechaerDropdown, setShowFreelanceTechaerDropdown] = useState(false);
  const { isAdiSuperAdmin, isAdiAdmin, user, isOrganizationAdiAdmin } = useAuth();
  const [duration, setDuration] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
    endDate: formatDateTime(new Date().getTime()),
  });
  const {
    setDaterange,
    onChangeFilterData,
    showGradeFilter = true,
    showSubjectFilter = true,
    showDateRangeFilter = true,
    filterUsers = true,
    showDisciplineFilter = false,
    maxWidth,
    display,
  } = props;

  const { data: codebookData } = useQuery<{
    getInvestigationMetadataCodebook: GQL_InvestigationMetadataCodebook;
  }>(gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationMetadataCodebook, {
    onError: (err) => {
      message.error('There was an error loading codes: ' + err.message || 'Unexpected Error');
    },
  });

  const [fetchOrganizationData, { data: organizationsData, loading: loadingOrganizations }] = useLazyQuery<{
    getAllOrganizations: GQL_OrganizationResponse[];
  }>(gqlSchema.OrganizationSchema.queries.LIST.getAllOrganizations, {
    onError: (err) => {
      message.error('There was an error loading the organizations: ' + err.message || 'Unexpected Error');
    },
  });

  const [fetchTeachers, { data: teachersData, loading: loadingTeachers }] = useLazyQuery<
    { getAllUsersByFilter: GQL_UserDetailsResponsePaginated },
    { data: GQL_UsersListFilterInput }
  >(gqlSchema.UserSchema.queries.LIST.getAllUsersByFilter, {
    onError: (err) => {
      message.error('There was an error loading users: ' + err.message || 'Unexpected Error');
    },
  });

  const { data: tagsData, loading: loadingTags } = useQuery<{ getTags: TagResponse[] }>(
    gqlSchema.TagsSchema.queries.getTags,
  );

  const gradeBands = useMemo(
    () =>
      codebookData?.getInvestigationMetadataCodebook?.disciplines.reduce((ret: string[], cur: GQL_Discipline) => {
        if (!ret.some((x) => x === cur.gradeBand)) ret.push(cur.gradeBand);
        return ret;
      }, []) || [],
    [codebookData],
  );

  const disciplines = useMemo(
    () =>
      codebookData?.getInvestigationMetadataCodebook.disciplines.reduce(
        (ret: GQL_Discipline[], cur: GQL_Discipline) => {
          if (!ret.some((d) => d.id === cur.id)) ret.push(cur);
          return ret;
        },
        [],
      ) || [],
    [codebookData],
  );

  const setNewFilter = useCallback(
    (filter: IKpiFilters, multiple?: boolean) => {
      if (multiple) setFilters([...filters, filter]);
      else setFilters([...filters.filter((f) => f.field !== filter.field), filter]);
    },
    [filters],
  );

  const removeFilterChip = useCallback(
    (filter: IKpiFilters, id?: string) => {
      if (filter.field === 'tag' || filter.field === 'discipline') {
        const rows = filters.find((f) => f.field === filter.field)?.value as string[];

        setFilters([
          ...filters.filter((f) => f.field !== filter.field),
          {
            field: filter.field,
            value: [...rows.filter((t) => t !== id)],
          },
        ]);
      } else {
        setFilters(filters.filter((f) => f.value !== filter.value));
      }
    },
    [filters],
  );

  const buildFilterChipText = useCallback(
    (filter: IKpiFilters, key: string) => {
      const lengthStandardFilters = filters.filter((f) => f.field !== 'tag').length || 0;
      const lengthTagFilters = ((filters.find((f) => f.field === 'tag')?.value as string[]) || []).length || 0;
      const lengthDisciplineFilters =
        ((filters.find((f) => f.field === 'discipline')?.value as string[]) || []).length || 0;
      const filtersCount = lengthStandardFilters + lengthTagFilters + lengthDisciplineFilters;
      const lengthTotal = filtersCount > 4 ? 4 : 24 / filtersCount;
      return (
        <Col sm={12} style={{ marginBottom: 3 }} md={lengthTotal} key={key}>
          <S.FilterChip
            onClick={(e) => {
              e.stopPropagation();
              removeFilterChip(filter, key);
            }}
          >
            <span role="none" >{filter.field === 'grade' ? `${filter.value} Grade` : startCase(filter.value as string)}</span>
            <FiX />
          </S.FilterChip>
        </Col>
      );
    },
    [filters, removeFilterChip],
  );

  const buildFilterChip = useCallback(
    (filter: IKpiFilters) => {
      if (filter.field === 'tag') {
        return ((filter.value as string[]) || []).map((t) => {
          return buildFilterChipText(
            {
              ...filter,
              value: (tagsData?.getTags.find((i) => i.id === t)?.tag as string) || '',
            },
            t,
          );
        });
      } else if (filter.field === 'discipline') {
        return ((filter.value as string[]) || []).map((t) => {
          return buildFilterChipText(
            {
              ...filter,
              value: (disciplines.find((i) => i.id === t)?.name as string) || '',
            },
            t,
          );
        });
      } else {
        return buildFilterChipText(filter, filter.field);
      }
    },
    [buildFilterChipText, disciplines, tagsData],
  );

  useEffect(() => {
    const filterOrg = filters.some((f) => f.value === 'Organizations');
    if (filterOrg) {
      if (!organizationsData?.getAllOrganizations) fetchOrganizationData();
      setShowOrgDropdown(true);
    } else {
      setShowOrgDropdown(false);
      setCurrentOrganization(undefined);
    }

    const filterTeacher = filters.some((f) => f.value === 'Freelance Teachers') || isOrganizationAdiAdmin;
    if (filterTeacher) {
      if (!teachersData?.getAllUsersByFilter) {
        const dataFilter: { roles: string[]; organizationId?: string } = {
          roles: ['teacher'],
        };
        if (user.subscription?.organizationId) dataFilter.organizationId = user.subscription?.organizationId;
        fetchTeachers({
          variables: {
            data: dataFilter,
          },
        });
      }
      setShowFreelanceTechaerDropdown(true);
    } else {
      setCurrentFreelanceTechaer(undefined);
      setShowFreelanceTechaerDropdown(false);
    }

    if (onChangeFilterData) {
      onChangeFilterData({
        organizations: filterOrg,
        teachers: isOrganizationAdiAdmin ? false : filterTeacher,
        gradeBand: filters.find((f) => f.field === 'grade')?.value as string,
        disciplineIds: ((filters.find((f) => f.field === 'discipline')?.value as string[]) || []).map((f) => +f),
        investigationType: filters.find((f) => f.field === 'subject')?.value as string,
        tagIds: (filters.find((f) => f.field === 'tag')?.value as string[]) || [],
        teacherId: currentFreelanceTechaer,
        organizationId: isOrganizationAdiAdmin ? undefined : currentOrganization,
      });
    }
  }, [
    fetchOrganizationData,
    fetchTeachers,
    filters,
    organizationsData,
    teachersData,
    user,
    isOrganizationAdiAdmin,
    onChangeFilterData,
    currentFreelanceTechaer,
    currentOrganization,
  ]);

  const getDisciplineItem = useCallback(
    (subjectId: string) => {
      return disciplines
        .filter((f) => f.subject === subjectId)
        .map((discipline) => (
          <Menu.Item
            key={`${discipline.id}`}
            disabled={filters.some((filter) => filter.value === discipline.name)}
            onClick={() => {
              const newDisciplines = filters?.find((f) => f.field === 'discipline');
              const exist = ((newDisciplines?.value as string[]) || []).find((t) => t === discipline.id);
              const currentValues = (newDisciplines?.value as string[]) || [];
              setNewFilter({
                field: 'discipline',
                value: newDisciplines
                  ? exist
                    ? [...currentValues.filter((t) => t !== discipline.id)]
                    : [...currentValues, discipline.id]
                  : [discipline.id],
              });
            }}
          >
            {discipline.name}
          </Menu.Item>
        ));
    },
    [disciplines, filters, setNewFilter],
  );

  const filterByDropdown = useMemo(
    () => (
      <Dropdown
        overlay={
          <S.CMenu>
            {(isAdiSuperAdmin || isAdiAdmin) && (
              <SubMenu title="User Type">
                <Menu.Item
                  disabled={filters?.some((filter) => filter.value === 'Organizations')}
                  onClick={() => {
                    setNewFilter({
                      field: 'type',
                      value: 'Organizations',
                    });
                  }}
                >
                  Organizations
                </Menu.Item>
                <Menu.Item
                  disabled={filters?.some((filter) => filter.value === 'Freelance Teachers')}
                  onClick={() =>
                    setNewFilter({
                      field: 'type',
                      value: 'Freelance Teachers',
                    })
                  }
                >
                  Freelance Teachers
                </Menu.Item>
              </SubMenu>
            )}
            <SubMenu title="Tag" disabled={!tagsData?.getTags?.length}>
              {!loadingTags &&
                tagsData?.getTags.map((i) => (
                  <Menu.Item
                    key={i.id}
                    onClick={() => {
                      const newTags = filters?.find((f) => f.field === 'tag');
                      const exist = ((newTags?.value as string[]) || []).find((t) => t === i.id);
                      const currentValues = (newTags?.value as string[]) || [];
                      setFilters([
                        ...filters.filter((f) => f.field !== 'tag'),
                        {
                          field: 'tag',
                          value: newTags
                            ? exist
                              ? [...currentValues.filter((t) => t !== i.id)]
                              : [...currentValues, i.id]
                            : [i.id],
                        },
                      ]);
                    }}
                  >
                    <S.TagButton
                      text={i.tag}
                      block
                      display="inline"
                      background={i.color}
                      shape="round"
                      minHeight={24}
                    />
                  </Menu.Item>
                ))}
            </SubMenu>
            {showSubjectFilter && (
              <SubMenu title="Subject">
                <Menu.Item
                  onClick={() => {
                    setNewFilter({
                      field: 'subject',
                      value: 'Math',
                    });
                  }}
                >
                  Math
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setNewFilter({
                      field: 'subject',
                      value: 'Science',
                    });
                  }}
                >
                  Science
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setNewFilter({
                      field: 'subject',
                      value: 'Engineering',
                    });
                  }}
                >
                  Engineering
                </Menu.Item>
              </SubMenu>
            )}
            {showGradeFilter && (
              <SubMenu title="Grade">
                {gradeBands.map((gradeItem) => (
                  <Menu.Item
                    key={gradeItem}
                    disabled={filters.some((filter) => filter.value === gradeItem)}
                    onClick={() =>
                      setNewFilter({
                        field: 'grade',
                        value: gradeItem,
                      })
                    }
                  >
                    Grade {gradeItem}
                  </Menu.Item>
                ))}
              </SubMenu>
            )}
            {showDisciplineFilter && (
              <SubMenu title="Discipline">
                <SubMenu title="Math">{getDisciplineItem('Math')}</SubMenu>
                <SubMenu title="Science">{getDisciplineItem('Science')}</SubMenu>
                <SubMenu title="Engineering">{getDisciplineItem('Engineering')}</SubMenu>
              </SubMenu>
            )}
          </S.CMenu>
        }
        placement="bottomRight"
        trigger={['click']}
      >
        <S.FilterContainer $maxWidth={maxWidth}>
          <FiFilter />
          <span role="none" >Filter By</span>
          <Row>{filters.map((filter) => buildFilterChip(filter))}</Row>
          <FiChevronDown />
        </S.FilterContainer>
      </Dropdown>
    ),
    [
      buildFilterChip,
      filters,
      gradeBands,
      isAdiAdmin,
      isAdiSuperAdmin,
      showGradeFilter,
      showSubjectFilter,
      maxWidth,
      loadingTags,
      setNewFilter,
      showDisciplineFilter,
      tagsData,
      getDisciplineItem,
    ],
  );

  const showExtraFilters = useMemo(() => filterUsers && showOrgDropdown, [filterUsers, showOrgDropdown]);
  return (
    <Row gutter={[16, 8]} style={{ display: display || 'flex' }}>
      {showDateRangeFilter && (
        <Col xxl={6} xl={6} lg={12} md={24} sm={24}>
          <RangePicker
            value={duration}
            onChange={(duration: Duration) => {
              setDuration(duration);
              if (setDaterange) setDaterange(duration);
            }}
            allowClear={false}
          />
        </Col>
      )}
      <Col
        xxl={showExtraFilters ? 11 : showDateRangeFilter ? 18 : 24}
        xl={showExtraFilters ? 11 : showDateRangeFilter ? 18 : 24}
        lg={showExtraFilters ? 24 : showDateRangeFilter ? 18 : 24}
        md={24}
        sm={24}
      >
        {filterByDropdown}
      </Col>
      {showOrgDropdown && (
        <Col xxl={7} xl={7} lg={12} md={24} sm={24}>
          <Select
            placeholder="Select Organization"
            showSearch
            loading={loadingOrganizations}
            value={currentOrganization}
            onChange={(v) => setCurrentOrganization(v as string)}
          >
            {organizationsData?.getAllOrganizations?.map((organization) => (
              <SelectOption value={organization.id} key={organization.id}>
                {organization.name}
              </SelectOption>
            ))}
          </Select>
        </Col>
      )}
      {filterUsers && (showFreelanceTechaerDropdown || isOrganizationAdiAdmin) && (
        <Col xxl={7} xl={7} lg={12} md={24} sm={24}>
          <Select
            placeholder="Select Teacher"
            showSearch
            loading={loadingTeachers}
            value={currentFreelanceTechaer}
            onChange={(v) => setCurrentFreelanceTechaer(v as string)}
          >
            {teachersData?.getAllUsersByFilter?.users.map((freelancer) => (
              <SelectOption value={freelancer.id} key={freelancer.id}>
                {freelancer.name}
              </SelectOption>
            ))}
          </Select>
        </Col>
      )}
    </Row>
  );
};

export default Filter;
