import { gql } from '@apollo/client';

export const NOTIFICATION_SUBSCRIPTIONS = {
  listenForNotifications: gql`
    subscription listenForNotifications {
      listenForNotifications {
        id
        created
        type
        seen
        metadata
      }
    }
  `,
};

export default NOTIFICATION_SUBSCRIPTIONS;
