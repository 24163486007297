import styled from 'styled-components';

export const QuestionBlock = styled.div`
  font-weight: 700;
  text-align: left;
  width: 100%;
`;

export const AnswerBlock = styled.div`
  width: 100%;
  border: 1px solid #cacaca;
  padding: 16px;
  margin-bottom: 8px;
  transition: 0.2s;

  :hover {
    border-color: cadetblue;
  }
`;

export const AnswerHead = styled.div`
  display: flex;
  text-align: left;
  gap: 8px;
  width: 100%;
`;

export const ImageAnswerBlock = styled.div<{ showTargetAnswer: boolean; isTargetAnswer: boolean }>`
  border: 1px solid #cacaca;
  padding: 16px;
  margin-bottom: 8px;
  transition: 0.2s;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: initial;

  .ant-image {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  ${({ showTargetAnswer, isTargetAnswer }) => {
    return `
      background-color: ${
        showTargetAnswer && isTargetAnswer
          ? 'rgba(32, 188, 137, 0.1)'
          : showTargetAnswer && !isTargetAnswer
          ? 'rgba(234, 53, 53, 0.1)'
          : '#fff'
      };
      border: ${
        showTargetAnswer && isTargetAnswer
          ? '1px solid rgba(32, 188, 137, 1)'
          : showTargetAnswer && !isTargetAnswer
          ? '1px solid rgba(234, 53, 53, 1)'
          : '1px solid #dee2e6'
      };
      border-radius: 0.25rem;
      padding: 0.75rem 1.25rem;
      margin-bottom: 0.5rem;
      :hover {
        border-color: ${showTargetAnswer ? 'none' : 'cadetblue'};
      }
    `;
  }}

  .ant-checkbox-disabled .ant-checkbox-inner {
    ${({ showTargetAnswer, isTargetAnswer }) => {
      return `
        border-color: ${
          showTargetAnswer && isTargetAnswer
            ? 'rgba(32, 188, 137, 1) !important'
            : showTargetAnswer && !isTargetAnswer
            ? 'rgba(234, 53, 53, 1) !important'
            : '#1890ff !important'
        };
    
        &:after {
          border-color: ${
            showTargetAnswer && isTargetAnswer
              ? 'rgba(32, 188, 137, 1)'
              : showTargetAnswer && !isTargetAnswer
              ? 'rgba(234, 53, 53, 1)'
              : '#1890ff'
          };
        }
        `;
    }}
  }
`;

export const AnswersContainer = styled.div`
  width: 100%;
  gap: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
`;
