import React, { useCallback, useMemo, useState } from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';
import { GQL_WalkthroughVideo } from '../../types/walkthrough';
import WalkthroughVideo from '../WalkthroughVideo';
import { message } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useAuth } from '../../hooks/useAuth';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';

interface Props {
  tutorial?: string;
  top?: string;
  right?: string;
  watchOnClick?: boolean;
  placement?: TooltipPlacement;
  showWhenSeen?: boolean;
  fontColor?: string;
}

const WalkthroughIcon: React.FC<Props> = (props) => {
  const { tutorial, top, right, fontColor = themeConfig.primaryColor.background, watchOnClick = true } = props;
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const { user, updateUser } = useAuth();

  const { data: walkthroughs } = useQuery<{
    getWalkthroughVideos: GQL_WalkthroughVideo[];
  }>(gqlSchema.WalkthroughSchema.queries.VIDEOS.getWalkthroughVideos, {
    onError: (err) => {
      message.error('There was an error loading walkthrough: ' + err.message || 'Unexpected Error');
    },
    fetchPolicy: 'cache-and-network',
  });

  const [submitMarkWalkthrough] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.WALKTRHOUGH.markWalkthroughsComplete,
    {
      update(cache, { data }) {
        const me = {
          __typename: 'Me',
          id: user.id,
        };
        cache.modify({
          id: cache.identify(me),
          fields: {
            walkthrough(existingWalkthrough: string[] = []) {
              return [...existingWalkthrough, tutorial];
            },
          },
        });
        updateUser({ ...user, walkthroughs: [...user.walkthroughs, tutorial || ''] });
      },
      onError: (err) => {},
    },
  );

  const seeWalkthrough = useCallback(
    (mark: boolean) => {
      if (mark && watchOnClick && tutorial) submitMarkWalkthrough({ variables: { types: [tutorial] } });
      if (watchOnClick) setTutorialVisible(true);
    },
    [submitMarkWalkthrough, tutorial, watchOnClick],
  );

  const walkthrough = useMemo(
    () => walkthroughs?.getWalkthroughVideos.find((w: GQL_WalkthroughVideo) => w.code === tutorial),
    [tutorial, walkthroughs],
  );

  const allowed = useMemo(() => {
    return true;
  }, []);

  const walkthroughIcon = useMemo(
    () =>
      allowed ? (
        <S.Emitter $top={top} $right={right} $color={fontColor}>
          <BsQuestionCircle
            data-cy="shared-walkthroughicon-helper-icon"
            onClick={() => {
              seeWalkthrough(false);
            }}
            size={24}
            style={{ position: 'absolute', color: fontColor, right: 0 }}
          />
        </S.Emitter>
      ) : null,
    [allowed, top, right, fontColor, seeWalkthrough],
  );

  return (
    <>
      {walkthroughIcon}
      <WalkthroughVideo
        visible={tutorialVisible}
        setVisible={setTutorialVisible}
        walkthrough={walkthrough}
        walkthroughs={walkthroughs?.getWalkthroughVideos}
      />
    </>
  );
};

export default WalkthroughIcon;
