import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './assets/fonts/TTNorms/TTNorms-Regular.otf';
import './assets/fonts/TTNorms/TTNorms-Medium.otf';

import './index.css';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import store from './redux/store';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ApolloProvider } from '@apollo/client';
import client from './gql/api';
import { Router } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import history from './history';
import { pdfjs } from 'react-pdf';
import UnexpectedErrorPage from './components/UnexpectedErrorPage';

if (process.env.REACT_APP_SENTRY_URL) {
  console.log('Sentry Error Reporting Enabled.');
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

// Required for react-pdf library, since it doesn't work on default worker
// For more info: https://www.npmjs.com/package/react-pdf#standard-browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<UnexpectedErrorPage />}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Router history={history}>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </Router>
        </Provider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
