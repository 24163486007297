import { gql } from '@apollo/client';

const CANVAS_COURSES = {
    CANVASDASHBOARD: {
        GetCanvasCoursesList: gql`
        query GetCanvasCoursesList {
    getCanvasCoursesList{
        id
        name
        uuid
        course_code
        license
        time_zone
        workflow_state
        calendar {
            ics
        }
        created_at     
        default_view
        account_id
    }
}

        `,
        GetCanvasCoursesListByUser: gql`
        query GetCanvasCoursesListByUser($page:String) {
    getCanvasCoursesListByUser(
        page: $page
    ) {
        id
        name
        uuid
        course_code
        license
        time_zone
        workflow_state
        calendar {
            ics
        }
        created_at     
        default_view
        account_id
    }
}

        `,
        GetCanvasCoursesById: gql`
        query GetCanvasCoursesById($course_id:String) {
    getCanvasCoursesById(
        course_id:$course_id
    ) {
        id
        name
        uuid
        course_code
        license
        time_zone
        workflow_state
        calendar {
            ics
        }
        created_at
        default_view
        account_id
    }
}

        `,
        GetCanvasAssignmentList: gql`
        query GetCanvasAssignmentList($course_id:String) {
    getCanvasAssignmentList(
        course_id: $course_id
    )  {
        id
        name
        description
        due_at
        created_at
        points_possible
        submission_types
        group_category_id
        position
        grading_type
        assignment_group_id
        peer_reviews
        locked_for_user
        lock_at
        unlock_at
        updated_at
        use_rubric_for_grading
        grade_group_students_individually
        anonymous_peer_reviews
        group_category_name
        needs_grading_count
        all_override_needs_grading_count
        only_visible_to_overrides
        allowed_extensions
        turnitin_enabled
        section_due_dates {
            id
            due_at
            section_id
            base
        }
        published
        all_dates {
            id
            due_at
            unlock_at
            lock_at
            base
            title
        }
        overrides {
            id
            assignment_id
            student_ids
            group_id
            course_section_id
            title
            due_at
            all_day
            all_day_date
            unlock_at
            lock_at
        }
        rubric_settings {
            id
            points_possible
            hide_points
            url
        }
        submissions_download_url
    }
}

        `,
        GetCanvasSubmissionById: gql`
    query GetCanvasSubmissionById($assignment_id: String, $course_id: String) {
        getCanvasSubmissionById(
            assignment_id: $assignment_id,
            course_id: $course_id,
        ) {
            id
            body
            url
            grade
            score
            submitted_at
            assignment_id
            description
            user_id
            submission_type
            workflow_state
            grade_matches_current_submission
            graded_at
            attempt
            graded_id
            posted_at
            preview_url
            title
        }
    }
`,

        GetCanvasStudentList: gql`
        query GetCanvasStudentList($course_id:String,$enrollment_type:String) {
    getCanvasStudentList(
        course_id: $course_id
        enrollment_type: $enrollment_type
    ) {
        count
        students {
            id
            name
            created_at
            sortable_name
            short_name
            sis_user_id
            integration_id
            sis_import_id
            login_id
        }
    }
}
        `,
        GetCanvasTeacherList: gql`
        query GetCanvasTeacherList($course_id:String,$enrollment_type:String) {
    getCanvasTeacherList(
        course_id: $course_id
        enrollment_type: $enrollment_type
    ) {
        count
        teachers {
            id
            name
            created_at
            sortable_name
            short_name
            sis_user_id
            integration_id
            sis_import_id
            login_id
        }
    }
}
        `,


        GetOverrideCanvasAssignment: gql`
        query GetOverrideCanvasAssignment($course_id: String!, $assignment_id: String!) {
        getOverrideCanvasAssignment(
            course_id: $course_id
            assignment_id: $assignment_id
        ) {
            id
            assignment_id
            title
            unassign_item
            student_ids
        }
    }
`,
        GetOverrideCanvasAssignmentById: gql`
        query GetOverrideCanvasAssignmentById($course_id: String, $assignment_id: String, $id: String) {
        getOverrideCanvasAssignmentById(
            course_id: $course_id
            assignment_id: $assignment_id
            id: $id
        ) {
            id
            assignment_id
            title
            unassign_item
            student_ids
        }
    }
        `,
        GetCanvasAssignmentListByDueDate: gql`
        query GetCanvasAssignmentListByDueDate($course_id: [String]) {
    getCanvasAssignmentListByDueDate(course_id: $course_id) {
      active {
        id
        name
        description
        due_at
        created_at
        points_possible
        submission_types
        group_category_id
        position
        grading_type
        published
        assignment_group_id
        peer_reviews
        locked_for_user
        lock_at
        unlock_at
        courseName
        updated_at
        use_rubric_for_grading
        grade_group_students_individually
        anonymous_peer_reviews
        group_category_name
        needs_grading_count
        all_override_needs_grading_count
        only_visible_to_overrides
        allowed_extensions
        turnitin_enabled
        submissions_download_url
        all_dates {
          id
          due_at
          unlock_at
          lock_at
          base
          title
        }
        overrides {
          id
          assignment_id
          student_ids
          group_id
          course_section_id
          title
          due_at
          all_day
          all_day_date
          unlock_at
          lock_at
        }
      }
      expired {
        id
        name
        description
        due_at
        created_at
        points_possible
        submission_types
        group_category_id
        position
        grading_type
        published
        courseName
        assignment_group_id
        peer_reviews
        locked_for_user
        lock_at
        unlock_at
        updated_at
        use_rubric_for_grading
        grade_group_students_individually
        anonymous_peer_reviews
        group_category_name
        needs_grading_count
        all_override_needs_grading_count
        only_visible_to_overrides
        allowed_extensions
        turnitin_enabled
        submissions_download_url
        all_dates {
          id
          due_at
          unlock_at
          lock_at
          base
          title
        }
        overrides {
          id
          assignment_id
          student_ids
          group_id
          course_section_id
          title
          due_at
          all_day
          all_day_date
          unlock_at
          lock_at
        }
      }
    }
  }
        `,
        GetCanvasAssignmentListByTurnedIn: gql`
        query GetCanvasAssignmentListByTurnedIn($course_id: [String]) {
    getCanvasAssignmentListByTurnedIn(course_id: $course_id) {
 active {
        course_id
        assignment_id
        assignment_name
        workflow_state
        created_at
        updated_at
        courseName
        points_possible
        due_at
        submissions_download_url
        description
    }
    expired {
        course_id
        assignment_id
        assignment_name
        workflow_state
        created_at
        updated_at
        courseName
        points_possible
        due_at
        submissions_download_url
        description
      }
    }
  }
        `,
        GetCanvasCoursesListByUserWithAssignment: gql`
        query GetCanvasCoursesListByUserWithAssignment($page: String) {
    getCanvasCoursesListByUserWithAssignment(page: $page) {
        canvasCourses {
            id
            account_id
            name
            uuid
            start_at
            is_public
            end_at
            created_at
            default_view
            course_code
            license
            time_zone
            workflow_state
        }
        canvasAssignment {
            id
            name
            description
            due_at
            created_at
            points_possible
            submission_types
            group_category_id
            position
            grading_type
            published
            assignment_group_id
            peer_reviews
            locked_for_user
            lock_at
            unlock_at
            updated_at
            use_rubric_for_grading
            grade_group_students_individually
            anonymous_peer_reviews
            group_category_name
            needs_grading_count
            all_override_needs_grading_count
            only_visible_to_overrides
            allowed_extensions
            turnitin_enabled
            submissions_download_url
            courseName
        }
    }
}

        `,
        GetCanvasAssignmentById: gql`
        query GetCanvasAssignmentById($course_id:String,$id:String) {
    getCanvasAssignmentById(
        course_id: $course_id
        id: $id
    ) {
        id
        name
        description
        due_at
        points_possible
        submission_types
        overrides {
            id
            assignment_id
            student_ids
            group_id
            course_section_id
            title
            due_at
            all_day
            all_day_date
            unlock_at
            lock_at
        }
        grade_group_students_individually
        use_rubric_for_grading
        rubric_settings {
            id
            points_possible
            hide_points
            url
        }
        all_override_needs_grading_count
        needs_grading_count
        group_category_name
        only_visible_to_overrides
        lock_at
        locked_for_user
        unlock_at
        updated_at
        position
        group_category_id
        grading_type
        assignment_group_id
        peer_reviews
        all_dates {
            id
            due_at
            unlock_at
            lock_at
            base
            title
        }
        section_due_dates {
            id
            due_at
            section_id
            base
        }
        anonymous_peer_reviews
    }
}
        `,
        GetCanvasAssignmentListBySubmission: gql`
        query GetCanvasAssignmentListBySubmission($data:[CanvasAssignmentListBySubmissionData]) {
    getCanvasAssignmentListBySubmission(
       data:$data
    ) {
        totalStudent
        complete
        canvasAssignment {
            id
            name
        }
        courseId
    }
}


        `,
        GetCanvasSubmissionList: gql`
        query GetCanvasSubmissionList($assignment_id:String,$course_id:String) {
    getCanvasSubmissionList(
     assignment_id: $assignment_id
        course_id: $course_id
    ) {
        length
        canvasSubmission {
            id
            body
            url
            grade
            score
            submitted_at
            assignment_id
            user_id
            submiission_type
            workflow_state
            preview_url
            grade_matches_current_submission
        }
    }
}


        `,

        GetCanvasOrganizationList: gql`
        query GetCanvasOrganizationList {
    getCanvasOrganizationList {
        id
        clientId
        apiDomain
        organization {
            id
            name
            defaultTAQuantity
            customLibrary
            writerQuantity
            blockAdiInvestigations
            cleverOrgId
            classLinkOrgId
            investigationLibraryLimit
        }
    }
}

        `,

    },
};
export default CANVAS_COURSES;