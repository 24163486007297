import { useMutation } from '@apollo/client';
import { Col, Row, message } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useState, useMemo } from 'react';
import { ClassProps, CreateClassFlowStep, GroupConfig } from '../../types/class';
import ClassInfo from './ClassInfo';
import GroupInfo from './GroupInfo';
import ManageGroups from './ManageGroups';
import { gqlSchema } from '../../gql/schema';
import * as S from './styles';
import { useForm } from 'antd/lib/form/Form';
import { StudentDataProps } from '../../types/students';
import { useHistory } from 'react-router-dom';
import { getShrinkedGroups } from '../../utils/class';
import { toDateFormat } from '../../utils/date';
import { useAuth } from '../../hooks/useAuth';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const initialInvite: StudentDataProps[] = [];

const CreateClassGoogleNew: React.FC<Props> = (props) => {
  const { visible, setVisible } = props;
  const [loading, setLoading] = useState(false);
  const { isFacilitator, activeRole } = useAuth();
  const steps: CreateClassFlowStep[] = useMemo(
    () => [
      { title: `Create a ${isFacilitator ? 'Course or Event' : ' Class'}`, step: 'classInfo' },
      { title: 'Create Groups', step: 'groupInfo' },
      { title: 'Manage Groups', step: 'groupManagment' },
    ],
    [isFacilitator],
  );

  const [step, setStep] = useState(steps[0]);
  const [classInfo, setClassInfo] = useState<ClassProps | null>();
  const [groups, setGroups] = useState<GroupConfig[]>([]);
  const [activeKey, setActiveKey] = useState('manual');
  const [hasUploadedFile, setHasUploadedFile] = useState(false);
  const [form] = useForm<ClassProps>();
  const history = useHistory();

  const [createClass] = useMutation(gqlSchema.GoogleClassroomSchema.mutations.GCLASS.AddCourse, {
    onCompleted: (data) => {
      const { addCourse } = data;

      console.log('Class created:', addCourse);
      history.push('/googleclassroom-teacher-dashboard/class/' + addCourse.id, {
        className: addCourse.name,
      });
      setVisible(false);
      setLoading(false); // Turn off loading indicator on success
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      message.error('Error creating class: ' + error?.message);
      setLoading(false); // Turn off loading indicator on error
    },
  });

  const onFinishManageGroup = useCallback(
    (args?: { sendInvites?: boolean; classData?: ClassProps }) => {
      setLoading(true);
      const { sendInvites, classData } = args || {};
      const data = classData || classInfo;

      if (!data?.duration?.startDate || !data?.duration?.endDate) {
        message.error('There was an error setting class dates, please set them up again');
        setStep(steps[0]);
        setLoading(false);
      } else if (data) {
        const { duration, ...rest } = data as ClassProps;
        console.log("daaata befor mutation",data);
        createClass({
          variables: {
            data: {
              ...rest,
              startDate: new Date(toDateFormat(duration.startDate)).getTime(),
              endDate: new Date(toDateFormat(duration.endDate)).getTime(),
              sendInvites,
              numberOfGroups: groups.length,
              role: 'google_teacher',
            },
          },
        });

      }
    },
    [classInfo, createClass, groups, steps, activeRole],
  );

  const onFinishClassInfo = useCallback(
    (data: ClassProps) => {
      if (data?.invites?.length === 0) {
        setClassInfo(data);
        onFinishManageGroup({ classData: data });
      } else {
        setClassInfo(data);
        setActiveKey('manual');
        setStep(steps[1]);
      }
    },
    [steps, onFinishManageGroup],
  );

  const assignGroups = useCallback(
    (shrinkedGroups: GroupConfig[]) => {
      if (classInfo) {
        let nextGroup = 0;
        const classifiedStudents = classInfo.invites.map((invite: StudentDataProps) => {
          if (!invite.group || !shrinkedGroups.some((gr: GroupConfig) => gr.group === invite.group))
            invite.group = shrinkedGroups[nextGroup].group;

          nextGroup = nextGroup === shrinkedGroups.length - 1 ? 0 : nextGroup + 1;
          return invite;
        });
        setClassInfo({ ...classInfo, invites: classifiedStudents });
      }
    },
    [classInfo],
  );

  const onFinishGroup = useCallback(
    (numberOfGroups: number) => {
      const shrinkedGroups = getShrinkedGroups(Math.max(numberOfGroups, 1));
      setGroups(shrinkedGroups);
      assignGroups(shrinkedGroups);
      setStep(steps[2]);
    },
    [assignGroups, steps],
  );

  const onRemoveStudent = useCallback(
    (student: StudentDataProps) => {
      if (classInfo) {
        const invites = classInfo.invites.filter((std: StudentDataProps) => std.email !== student.email);
        setClassInfo({
          ...classInfo,
          invites: invites.length === 0 ? initialInvite : invites,
        });
        if (invites.length === 0) setStep(steps[0]);
      }
    },
    [classInfo, steps],
  );

  const onMoveStudent = useCallback(
    (student: StudentDataProps, newGroup: string) => {
      if (classInfo) {
        const invites = classInfo.invites.map((std: StudentDataProps) => {
          if (std.email === student.email) std.group = newGroup;
          return std;
        });
        setClassInfo({ ...classInfo, invites });
      }
    },
    [classInfo],
  );

  const onCloseModal = () => {
    setVisible(false);
    setClassInfo(null);
    setActiveKey('manual');
    setHasUploadedFile(false);
    setStep(steps[0]);
    form.resetFields();
  };

  const currentStep = useMemo(() => {
    switch (step.step) {
      case 'classInfo':
        return (
          <ClassInfo
            data-cy="components-classes-row-create-class-modal-step-1"
            form={form}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            hasUploadedFile={hasUploadedFile}
            setHasUploadedFile={setHasUploadedFile}
            initialInvite={initialInvite}
            onFinishClass={onFinishClassInfo}
            classInfo={classInfo}
          />
        );
      case 'groupInfo':
        return (
          <GroupInfo
            data-cy="components-classes-row-create-class-modal-step-2"
            onFinishGroup={onFinishGroup}
            students={classInfo?.invites.length}
            loading={loading}
            groups={
              groups.length > 0
                ? groups.length
                : classInfo?.invites.length
                ? Math.ceil(classInfo?.invites.length / 3)
                : 0
            }
            steps={steps}
            setStep={setStep}
          />
        );
      case 'groupManagment':
        return (
          <ManageGroups
            data-cy="components-classes-row-create-class-modal-step-3"
            students={classInfo?.invites}
            onFinishManageGroup={onFinishManageGroup}
            onRemoveStudent={onRemoveStudent}
            onMoveStudent={onMoveStudent}
            groups={groups}
            loading={loading}
            steps={steps}
            setStep={setStep}
          />
        );
    }
  }, [
    activeKey,
    classInfo,
    form,
    hasUploadedFile,
    groups,
    loading,
    onFinishClassInfo,
    onFinishGroup,
    onFinishManageGroup,
    onMoveStudent,
    onRemoveStudent,
    steps,
    setActiveKey,
    setHasUploadedFile,
    setStep,
    step.step,
  ]);

  return (
    <S.CModal
      visible={visible}
      maskClosable={false}
      destroyOnClose
      title={
        <Row data-cy="components-classes-row-create-class-modal">
          <Col span={24}>
            <S.Title>{step.title}</S.Title>
          </Col>
        </Row>
      }
      onCancel={onCloseModal}
      width={800}
      footer={null}
    >
      {currentStep}
    </S.CModal>
  );
};

export default React.memo(CreateClassGoogleNew);
