import React, { useRef, useEffect, useCallback, useMemo,  } from 'react';
import { useScript } from '../../../../hooks/useScript';
import { FunctionProxyManager } from './FunctionProxyManager';
import './index.css';

interface ShopifyBuyButtonProps {
  productId: string;
  cartData: any;
  addToCart: () => void;
  decreaseQuantity: (shopifyProductId: string) => void;
  increaseQuantity: (shopifyProductId: string) => void;
  handleRequestQuote: () => void;
  handleUpdateCartItem: (shopifyProductId: string, quantity: number) => void;
}
declare global {
  interface Window {
    ShopifyBuy: any;
  }
}
type CartItem = {
  __typename?: 'CartItem';
  orderId?: string | null;
  productId: string;
  shopifyId?: string;
  title?: string;
  sku?: string | null;
  quantity?: string;
  unitPrice?: number;
  [key: string]: any; // To allow other fields
};

interface ShopifyButtonStyles {
  product: {
    '@media (min-width: 601px)': {
      maxWidth: string;
      marginLeft: string;
      marginBottom: string;
    };
  };
  button: {
    ':hover': { 'background-color': string };
    'background-color': string;
    ':focus': { 'background-color': string };
    padding: '8px 16px';
    'margin-top': '0px';
  };
  buttonWrapper: {
    'margin-top': '0px';
  };
}

const SHOPIFY_CONFIG = {
  domain: 'adi-learning-hub-store.myshopify.com',
  storefrontAccessToken: process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN || 'a94b7e8ccdff3dd026fdef75de8388e5',
};

const BUTTON_STYLES: ShopifyButtonStyles = {
  product: {
    '@media (min-width: 601px)': {
      maxWidth: 'calc(25% - 20px)',
      marginLeft: '20px',
      marginBottom: '50px',
    },
  },
  button: {
    ':hover': { 'background-color': '#4367E9' },
    'background-color': '#4367E9',
    ':focus': { 'background-color': '#4367E9' },
    padding: '8px 16px',
    'margin-top': '0px',
  },
  buttonWrapper: {
    'margin-top': '0px',
  },
};

const cartTemplates = {
  title: `<div class="{{data.classes.cart.header}}" data-element="cart.header">
<h2 class="{{data.classes.cart.title}}" data-element="cart.title">{{data.text.title}}</h2>
<button class="{{data.classes.cart.close}}" data-element="cart.close">
  <span aria-hidden="true">&times;</span>
  <span class="visuallyhidden">{{data.text.closeAccessibilityLabel}}</span>
 </button>
</div>`,
  lineItems: `<div class="{{data.classes.cart.cartScroll}}{{#data.contents.note}} {{data.classes.cart.cartScrollWithCartNote}}{{/data.contents.note}}{{#data.discounts}} {{data.classes.cart.cartScrollWithDiscounts}}{{/data.discounts}}" data-element="cart.cartScroll">
    {{#data.isEmpty}}<p class="{{data.classes.cart.empty}} {{data.classes.cart.emptyCart}}" data-element="cart.empty">{{data.text.empty}}</p>{{/data.isEmpty}}
    <ul role="list" class="{{data.classes.cart.lineItems}}" data-element="cart.lineItems">{{{data.lineItemsHtml}}}</ul>
  </div>`,
  footer: `{{^data.isEmpty}}
<div class="{{data.classes.cart.footer}}" data-element="cart.footer">
  {{#data.discounts}}
    <div class="{{data.classes.cart.discount}}" data-element="cart.discount">
      <span class="{{data.classes.cart.discountText}}" data-element="cart.discountText">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" class="{{data.classes.cart.discountIcon}}" data-element="cart.discountIcon" aria-hidden="true">
          <path d="M10.001 2.99856C9.80327 2.99856 9.61002 2.93994 9.44565 2.83011C9.28128 2.72029 9.15317 2.56418 9.07752 2.38155C9.00187 2.19891 8.98207 1.99794 9.02064 1.80405C9.05921 1.61016 9.1544 1.43207 9.29419 1.29228C9.43397 1.1525 9.61207 1.0573 9.80596 1.01874C9.99984 0.980171 10.2008 0.999965 10.3834 1.07562C10.5661 1.15127 10.7222 1.27938 10.832 1.44375C10.9418 1.60812 11.0005 1.80136 11.0005 1.99905C11.0005 2.26414 10.8952 2.51837 10.7077 2.70581C10.5203 2.89326 10.266 2.99856 10.001 2.99856ZM10.001 1.67062e-05H7.0024C6.87086 -0.000743818 6.74046 0.024469 6.61868 0.0742095C6.49691 0.12395 6.38614 0.19724 6.29275 0.289876L0.295655 6.28697C0.201972 6.37989 0.127614 6.49044 0.0768697 6.61224C0.0261256 6.73404 0 6.86468 0 6.99663C0 7.12857 0.0261256 7.25922 0.0768697 7.38102C0.127614 7.50282 0.201972 7.61336 0.295655 7.70628L4.29372 11.7043C4.38664 11.798 4.49718 11.8724 4.61898 11.9231C4.74078 11.9739 4.87143 12 5.00337 12C5.13532 12 5.26596 11.9739 5.38776 11.9231C5.50956 11.8724 5.62011 11.798 5.71303 11.7043C5.90294 11.5044 11.5102 5.89716 11.7101 5.70725C11.8028 5.61386 11.876 5.50309 11.9258 5.38132C11.9755 5.25954 12.0007 5.12914 12 4.99759V1.99905C12 1.46887 11.7894 0.96041 11.4145 0.585519C11.0396 0.210628 10.5311 1.67062e-05 10.001 1.67062e-05Z" />
        </svg>
        <span class="visuallyhidden">Discount:</span>
        {{text}}
      </span>
      <span class="{{data.classes.cart.discountAmount}}" data-element="cart.discountAmount">{{amount}}</span>
    </div>
  {{/data.discounts}}
  <p class="{{data.classes.cart.subtotalText}}" data-element="cart.total">{{data.text.total}}</p>
  <p class="{{data.classes.cart.subtotal}}" data-element="cart.subtotal">{{data.formattedTotal}}</p>
  {{#data.contents.note}}
    <div class="{{data.classes.cart.note}}" data-element="cart.note">
      <label for="{{data.cartNoteId}}" class="{{data.classes.cart.noteDescription}}" data-element="cart.noteDescription">{{data.text.noteDescription}}</label>
      <textarea id="{{data.cartNoteId}}" class="{{data.classes.cart.noteTextArea}}" data-element="cart.noteTextArea" rows="3"/>{{data.cartNote}}</textarea>
    </div>
  {{/data.contents.note}}
  <p class="{{data.classes.cart.notice}}" data-element="cart.notice">{{data.text.notice}}</p>
  <div style="display: flex; flex-direction: row;gap: 4px;">
    <button class="{{data.classes.cart.button}}" " onclick="onRequestAQuoteClick();" type="button" data-element="cart.button" style="color: white; border: 1px solid #4367E9; padding: 8px 16px; background-color: #4367E9; border-radius: 4px;flex; 1;" >{{data.text.button}}</button>
    <button class="{{data.classes.cart.buttonSecondary}}" type="button" data-element="cart.buttonSecondary" style="color: #4367E9; border: 1px solid #4367E9; padding: 8px 16px; background-color: transparent; border-radius: 4px;flex: 1;padding: 10px 5px;font-size: 16px;
    " onclick="onRequestAQuoteClick();">Request A Quote</button>
  </div>
</div>
{{/data.isEmpty}}`,
};

const ShopifyBuyButton: React.FC<ShopifyBuyButtonProps> = (prop) => {
  const buyButtonRef = useRef<HTMLDivElement>(null);
  const shopifyClientRef = useRef<null | any>(null);
  const shopifyUIRef = useRef<null | any>(null);
  const productId = prop.productId;

  //@ts-ignore
  window['onRequestAQuoteClick'] = () => {
    prop.handleRequestQuote();
  };

  const manager = useMemo(() => new FunctionProxyManager(), []);

  useEffect(() => {
    return () => {
      manager.clearAllHooks();
    };
  }, []);

  const getShopifyClient = () => {
    if (shopifyUIRef.current) {
      if (shopifyUIRef.current.client) {
        return shopifyUIRef.current.client;
      }
    }
  };

  const getShopifyCart = () => {
    if (shopifyUIRef.current) {
      if (Array.isArray(shopifyUIRef.current.components.cart) && shopifyUIRef.current.components.cart.length > 0) {
        return shopifyUIRef.current.components.cart[0];
      }
    }
  };

  const extractProductId = (productId: string | undefined) => {
    if (productId === undefined) return;
    const match = productId.match(/gid:\/\/shopify\/Product\/(\d+)/);
    if (match && match.length > 1) {
      return match[1];
    }
  };

  const extractProductVariantId = (productVariantId: string | undefined) => {
    if (productVariantId === undefined) return;
    const match = productVariantId.match(/gid:\/\/shopify\/ProductVariant\/(\d+)/);
    if (match && match.length > 1) {
      return match[1];
    }
  };

  const getShopifyIdsFromLineItemId = (lineItemId: string): { productVariantId?: string; productId?: string } => {
    const cart = getShopifyCart();
    const ids: { productVariantId?: string; productId?: string } = {};
    if (lineItemId) {
      const lineItem = cart.lineItems.find((item: any) => item.id === lineItemId);
      if (lineItem) {
        ids.productVariantId = lineItem.variant.id;
        ids.productId = lineItem.variant.product.id;
      }
    }

    return ids;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getShopifyProductVariantId = async (productId: string) => {
    let productVariantId = null;
    const client = getShopifyClient();

    if (client) {
      const product = await client.product.fetch(productId);

      if (Array.isArray(product.variants) && product.variants.length > 0) {
        productVariantId = product.variants[0].id;
      }
    }
    return productVariantId;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addItemsToCart = async (cartItems: any) => {
    const cart = getShopifyCart();
    const items = Object.keys(cartItems);

    if (cart && cart.addVariantToCart && typeof cart.addVariantToCart === 'function') {
      // const originalAddVariantToCart = cart.addVariantToCart;
      const proxiedAddVariantToCart = manager.createProxy(cart.addVariantToCart);
      const originalAddVariantToCart = manager.getOriginalFunction(proxiedAddVariantToCart);
      if (originalAddVariantToCart && proxiedAddVariantToCart) {
        cart.addVariantToCart = proxiedAddVariantToCart;
        const productVariantPromises = [];
        for (let index = 0; index < items.length; index++) {
          const productId = items[index];

          const productVariantId = await getShopifyProductVariantId(`gid://shopify/Product/${productId}`);
          productVariantPromises.push(cart.addVariantToCart({ id: productVariantId }, cartItems[productId]));
          cart.close();
        }
        return await Promise.all(productVariantPromises);
      }
    }

    return Promise.reject('Shopify UI not initialized');
  };

  const hijackTrack = (): Promise<{
    returnValue: undefined;
    args: { type: string; componentObj: any };
    unsubscribe: Function;
  }> => {
    if (shopifyUIRef.current) {
      if (shopifyUIRef.current.tracker?.track && typeof shopifyUIRef.current.tracker.track === 'function') {
        return new Promise((resolve, reject) => {
          const proxiedTrack = manager.createProxy(shopifyUIRef.current.tracker.track);
          const originalTrack = manager.getOriginalFunction(proxiedTrack);
          if (originalTrack) {
            const unsubscribe = manager.addAfterHook(
              originalTrack,
              (returnValue: any, args: any[]) => {
                resolve({ returnValue, args: { type: args[0], componentObj: args[1] }, unsubscribe });
              },
              (targetFn) => {
                shopifyUIRef.current.tracker.track = targetFn;
              },
            );
            shopifyUIRef.current.tracker.track = proxiedTrack;
          }
        });
      }
    }

    return Promise.reject('Shopify UI not initialized');
  };

  const emptyCart = async () => {
    const cart = getShopifyCart();

    const { args, unsubscribe } = await hijackTrack();

    if (args.type === 'Viewed Product') {
      if (cart && !cart.isEmpty) {
        cart.empty();
      }
    }
    unsubscribe();
  };

  const initShopifyUI = useCallback(
    (client: any) => {
      window.ShopifyBuy.UI.onReady(client).then((ui: any) => {
        ui.createComponent('product', {
          id: productId,
          node: buyButtonRef.current,
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
            product: {
              styles: BUTTON_STYLES,
              width: '120px',
              contents: {
                img: false,
                button: true,
                price: false,
                title: false,
              },
              text: { button: 'Add to cart' },
            },
            modalProduct: {
              contents: {
                title: true,

                icon: true,

                count: true,
              },
              // styles: BUTTON_STYLES,
              text: { button: 'Add to cart' },
            },
            cart: {
              iframe: false,
              styles: {
                button: { 'background-color': '#4367E9' },
                cart: { 'background-color': 'white' },
                lineItems: { 'background-color': 'white' },
                footer: { 'background-color': 'white' },
              },
              text: {
                total: 'Subtotal',
                button: 'Checkout',
              },
              templates: cartTemplates,
            },
            toggle: {
              styles: {
                toggle: {
                  ...BUTTON_STYLES.button,
                },
              },
            },
          },
        });
        shopifyUIRef.current = ui;
        let disableAddToCart = false
        emptyCart().then(() => {
          const productIds = {};
          prop?.cartData?.cartItem?.forEach((item: CartItem) => {
            // @ts-ignore
            if (item.shopifyId) productIds[item.shopifyId] = Number(item.quantity);
          });
          
          disableAddToCart= true
          addItemsToCart(productIds).then(() => {
            
            disableAddToCart = false
          });
        });

        if (ui?.components?.cart && Array.isArray(ui?.components?.cart)) {
          if (ui.components.cart.length > 0) {
            ui.components.cart.forEach((cartItem: any) => {
              // First Parameter is either +1 or -1
              // Second Parameter is Pointer Event
              // Third Parameter is button either increment or decrement target that was clicked
              if (cartItem && cartItem.onQuantityIncrement && typeof cartItem.onQuantityIncrement === 'function') {
                const proxiedOnQuantityIncrement = manager.createProxy(cartItem.onQuantityIncrement, (targetFn) => {
                  cartItem.onQuantityIncrement = targetFn;
                });
                const unsubscribe = manager.addAfterHook(
                  cartItem.onQuantityIncrement,
                  (returnValue: any, args: any[]) => {
                    const incrementBtn = args?.[2];
                    if (incrementBtn && incrementBtn instanceof HTMLButtonElement) {
                      const lineItemId = incrementBtn.getAttribute('data-line-item-id') as string;
                      const { productVariantId, productId } = getShopifyIdsFromLineItemId(lineItemId);
                      const _variantId = extractProductVariantId(productVariantId);
                      const _productId = extractProductId(productId);
                      console.log({ returnValue, args, _variantId, _productId });
                      const val = args[0];
                      if (_productId) {
                        if (val === 1) {
                          prop.increaseQuantity(_productId);
                        } else {
                          prop.decreaseQuantity(_productId);
                        }
                      }
                    }
                  },
                );
                console.log({ unsubscribe });
                cartItem.onQuantityIncrement = proxiedOnQuantityIncrement;
              }
              // First Parameter is Focus Eventab
              // Second Parameter is input element target with updated value
              if (cartItem && cartItem.onQuantityBlur && typeof cartItem.onQuantityBlur === 'function') {
                const proxiedOnQuantityBlur = manager.createProxy(cartItem.onQuantityBlur, (targetFn) => {
                  cartItem.onQuantityBlur = targetFn;
                });
                const unsubscribe = manager.addAfterHook(cartItem.onQuantityBlur, (returnValue: any, args: any[]) => {
                  const incrementBtn = args?.[2];
                  if (incrementBtn && incrementBtn instanceof HTMLButtonElement) {
                    const lineItemId = incrementBtn.getAttribute('data-line-item-id') as string;
                    const { productVariantId, productId } = getShopifyIdsFromLineItemId(lineItemId);
                    const _variantId = extractProductVariantId(productVariantId);
                    const _productId = extractProductVariantId(productId);
                    console.log({ returnValue, args, _variantId, _productId });
                  }
                });
                console.log({ unsubscribe });
                cartItem.onQuantityBlur = proxiedOnQuantityBlur;
              }
              if (cartItem && cartItem.addVariantToCart && typeof cartItem.addVariantToCart === 'function') {
                const proxiedAddVariantToCart = manager.createProxy(cartItem.addVariantToCart, (targetFn) => {
                  cartItem.addVariantToCart = targetFn;
                });
                const unsubscribe = manager.addAfterHook(cartItem.addVariantToCart, (returnValue: any, args: any[]) => {
                  if (!disableAddToCart) {
                    prop.addToCart();
                  }

                  console.log('Variant Added to Cart', returnValue, args,disableAddToCart);
                });
                console.log({ unsubscribe });
                cartItem.addVariantToCart = proxiedAddVariantToCart;
              }
            });
          }
        }
      });
    },
    [productId],
  );

  const scriptStatus = useScript('https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js', {
    removeOnUnmount: false,
  });

  useEffect(() => {
    if (scriptStatus === 'ready') {
      const client = window.ShopifyBuy.buildClient(SHOPIFY_CONFIG);
      shopifyClientRef.current = client;
      initShopifyUI(client);

      // shopifyUIRef.current.components.cart[0].lineItems[0].{id,quantity,title,variant}
      // shopifyUIRef.current.components.cart[0].empty()
      // shopifyUIRef.current.components.cart[0].isEmpty
      // shopifyUIRef.current.components.cart[0].isVisible
      // temp1.shopifyUIRef.current.components.cart[0].lineItems[1].id
    }
  }, [scriptStatus]);

  return <div ref={buyButtonRef} id={`product-component-${productId}`} />;
};

export default ShopifyBuyButton;
