import { Col } from 'antd';
import styled from 'styled-components';

export const Column = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-bottom: 24px;
  z-index: 1;

  & > p,
  h1,
  h2 {
    text-align: center;
  }

  & > button {
    margin-top: 30px;
  }
`;

export const FilePlaceholder = styled.div`
  width: calc(100%-160px);
  margin: 0px 80px;
  margin: auto;
  z-index: 0;
  background: white;
  border-radius: 2px;
  margin-top: 32px;
  margin-bottom: 60px;
  text-align: center;
  align-text: center;
  position: relative;
  max-width: 900px;

  user-select: none;
  canvas {
    height: 100% !important;
    width: 100% !important;
  }
  & > img {
    height: 100% !important;
    width: 100% !important;
  }
`;
