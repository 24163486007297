/**
 * Converts a number into its written value, for example
 * 0 will return "Zero". Supports only numbers in the interval [0,10]
 * @param number Number to be converted
 */

export const numberToSpelledOut = (number: number) => {
  if (number > 10 || number < 0) return '';

  const conversion = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];

  return conversion[number];
};

/**
 * Receives a text formatted in HTML styles and return all content inside <p> tags, or undefined
 * if the <p> tag is empty. Created to be used alongside with the Editor component
 *
 * @param text Text to be split, it's expected to be a HTML formatted text with <p> tags
 */
export const splitByParagraph = (text?: string) => {
  // This will match and create an array of all <p> tags
  const matches = text?.match(/<p.*?<\/p>/g);

  if (!matches) return [];

  return matches.map((section) => {
    // This will ensure that the p tag has any content on it,
    // by grouping all matches between brackets and checking if anything was grouped
    const match = section.match(/<p .*?>(.*)<\/p>/);

    if (!match) return undefined;
    if (match?.length < 2) return undefined;
    else if (!match[1]) return undefined;
    else return section;
  });
};

/**
 * Receives a text that will be sliced and the amount of characters to keep.
 * It will count only characters that are not part of a tag. It will also include
 * and ellipsis at the end of the cut text
 */
export const sliceRichText = (text: string, length: number) => {
  let isOnTag = false;
  let count = 0;
  let cutOutText = '';

  for (const letter of text) {
    if (count >= length) continue;

    cutOutText += letter;
    if (letter === '<') {
      isOnTag = true;
    } else if (letter === '>') {
      isOnTag = false;
    } else if (!isOnTag) {
      count++;
      if (count === length) cutOutText += '...';
    }
  }

  return cutOutText;
};

/**
 * These words won't be considered on filters, to make it more useful
 */
export const BANNED_FILTER_WORDS = ['and', 'what', 'for', 'in', 'with'];
