import { gql } from '@apollo/client';

export const BOOK_MUTATIONS = {
    CreateBook: gql`
        mutation CreateBook($data: CreateBookInput , $force: Boolean) {
            createBook(data: $data,force: $force) {
                name
                isbn
                imageUrl
                locked
                createdAt
                updatedAt
            }
        }
    `,
    CreateBookUser: gql`
    mutation CreateBookUser($data: CreateBookUserInput) {
        createBookUser(data: $data) {
            firstName
            lastName 
            email
            password
            metadata
        }
    }
    `,
    UpdateBookUser: gql`
    mutation UpdateBookUser($metadata:String,$email:String) {
    updateBookUser(metadata: $metadata, email: $email) {
        id
        firstName
        lastName
        email
        password
        metadata
    }
}

    `,
    UpdateBook: gql`
        mutation UpdateBook($id: String!, $data: UpdateBookInput!) {
            updateBook(id: $id, data: $data) {
                id
                name
                isbn
                imageUrl
                locked
                createdAt
                updatedAt
            }
        }
    `,
    LockBook: gql`
        mutation LockBook($id: String!) {
            lockBook(id: $id) {
                id
                locked
            }
        }
    `,
    UnlockBook: gql`
        mutation UnlockBook($id: String!) {
            unlockBook(id: $id) {
                id
                locked
            }
        }
    `,
    AddInvestigation: gql`
        mutation AddInvestigation($bookId: String!, $investigationId: String!) {
            addInvestigation(bookId: $bookId, investigationId: $investigationId) {
                id
                investigations {
                    id
                    title
                }
            }
        }
    `,
    GenerateAndSaveCodes: gql`
        mutation GenerateAndSaveCodes($bookId: String!, $numberOfCodes: Int) {
            generateAndSaveCodes(bookId: $bookId, numberOfCodes: $numberOfCodes) {
                id
                code
                used
            }
        }
    `,
    GetUnusedCodes: gql`
        query GetUnusedCodes($bookId: String!, $limit: Int ) {
            getUnusedCodes(bookId: $bookId, limit: $limit) {
                id
                code
                used
            }
        }
    `,
    AddBookUser: gql`
    mutation AddBookUser($bookCode: String!) {
      addBookUser(bookCode: $bookCode)
    }
  `,

  AddWishlist:gql`
mutation AddToWishlist($investigationId:String) {
    addToWishlist(investigationId: $investigationId) {
        id
        
        investigationId
        investigationName
        createdAt
    }
}

`,

RemoveWishlist:gql`
mutation RemoveFromWishlist($investigationId:String) {
    removeFromWishlist(investigationId: $investigationId)
}
`,


AddAltStandards:gql`
mutation AddAllStatesAndStandards($state:String,$investigationId:String,$standard:[String]) {
    addAllStatesAndStandards(
        state: $state
        investigationId: $investigationId
        standard: $standard
    ) {
        investigationId
        state
        altStandard
    }
}


`,
AssignInvestigationKitBundle: gql`
mutation AssignInvestigationKitBundle($bookId: String!, $investigationIds: [String], $kitBundleId: String) {
    assignInvestigationKitBundle(bookId: $bookId, investigationIds: $investigationIds, kitBundleId: $kitBundleId) {
        message
        success
    }
}
`,
AssignCodeToBooks: gql`
mutation AssignCodeToBooks($codes: String!, $shopifyProductId: String!) {
    assignCodeToBooks(codes: $codes, shopifyProductId: $shopifyProductId) {
        message
        success
        created
        updated
    }
}
`,
  
};

export default BOOK_MUTATIONS;