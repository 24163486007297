import { gql } from '@apollo/client';
import TAGS_FRAGMENTS from '../../Tags/fragments/Tags';

const USER_QUERIES = {
  LIST: {
    getAllUsersByFilter: gql`
      query GetAllUsersByFilter($data: UsersListFilterInput) {
        getAllUsersByFilter(data: $data) {
          users {
            id
            name
            email
            organization
            subscription {
              endDate
            }
            roles
            enrollmentDate
            lastLogin
            source
            accountType
            tags {
              ...TagResponseFragment
            }
          }
          pagination {
            page
            size
            totalCount
          }
        }
      }
      ${TAGS_FRAGMENTS.tagResponse}
    `,
    exportUsersByFilter: gql`
      query ExportUsersByFilter($data: UsersListFilterInput) {
        exportUsersByFilter(data: $data)
      }
    `,
  },
  DETAILS: {
    getUserDetails: gql`
      query GetUserDetails($data: UserInput!) {
        getUserDetails(data: $data) {
          id
          name
          firstName
          lastName
          active
          assistantsAllowed
          email
          organization
          roles
          classes {
            classId
            name
            group
          }
          subscription {
            id
            organizationId
            invitesAvailable
            startDate
            endDate
            isTrial
            readerPermissions
            taCustomPermissions
            teachersCanDoReviews
            allowedGradeBands
            allowedSubjects
            allowedDisciplines
            maxClasses
          }
          facilitatorSubscription {
            id
            organizationId
            invitesAvailable
            startDate
            endDate
            isTrial
            readerPermissions
            taCustomPermissions
            teachersCanDoReviews
            allowedGradeBands
            allowedSubjects
            allowedDisciplines
          }
          enrollmentDate
          lastLogin
          tags {
            ...TagResponseFragment
          }
          stripeSubscription {
            type
            endDate
          }
          source
          userAllowedStates {
            id
            name
            postalCode
          }
        }
      }
      ${TAGS_FRAGMENTS.tagResponse}
    `,
  },
};

export default USER_QUERIES;
