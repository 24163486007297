import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Form as AntdForm, Col, Row, message } from 'antd';
import * as S from './styles';
import { useForm } from 'antd/lib/form/Form';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import Button from '../../../shared/Button';
import Form from '../../../shared/Form';
import Select from '../../../shared/Select';
import { GQL_WalkthroughCategory, GQL_WalkthroughVideo } from '../../../types/walkthrough';
import SelectOption from '../../../shared/Select/Option';
import { roles } from '../../../utils/roles';
import { SelectValue } from 'antd/lib/select';
import { RcFile } from 'antd/lib/upload';
import UploadInput from '../../../shared/UploadInput';
import { UploadFile } from 'antd/lib/upload/interface';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const AddWalkthroughVideo: React.FC<Props> = (props) => {
  const [form] = useForm();
  const { visible, setVisible } = props;
  const [fileList, setFileList] = useState<UploadFile<any>[] | undefined>([]);
  const [currentRole, setCurrentRole] = useState<string[]>();
  const [videoFile, setVideoFile] = useState<RcFile | undefined>();
  const [currentCategory, setCurrentCategory] = useState('');

  const { data: categories } = useQuery<{ getWalkthroughCategory: GQL_WalkthroughCategory[] }>(
    gqlSchema.WalkthroughSchema.queries.CATEGORY.getWalkthroughCategory,
    {
      onError: (err) => {
        message.error('There was an error loading user walkthrough categories: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const [addVideo, { loading }] = useMutation<{ createWalkthroughVideo: GQL_WalkthroughVideo }>(
    gqlSchema.WalkthroughSchema.mutations.ADD.createWalkthroughVideo,
    {
      onError: (err) => {
        message.error('There was an error trying to create Walkthrough Video, please try again later');
      },
      onCompleted: (data) => {
        form.resetFields();
        setVideoFile(undefined);
        setFileList([]);
        message.success(`Walkthrough Video created successfully`);
        props.setVisible(false);
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getWalkthroughVideos(existingVideosRefs = []) {
              const newVideoRef = cache.writeFragment({
                data: data?.createWalkthroughVideo,
                fragment: gqlSchema.WalkthroughSchema.fragments.walkthroughVideo,
              });
              return [...existingVideosRefs, newVideoRef];
            },
          },
        });
      },
    },
  );

  const onFinish = useCallback(
    async (data: { title: string; categoryId: string; roles: string; videoUrl: string }) => {
      addVideo({ variables: { file: videoFile, data } });
    },
    [addVideo, videoFile],
  );

  const handleChangeCategory = useCallback((value: SelectValue) => {
    setCurrentCategory(value as string);
  }, []);
  const handleChangeRole = useCallback((value: SelectValue) => {
    const codes = value as string[];
    setCurrentRole(codes);
  }, []);

  const beforeUpload = useCallback((file: RcFile) => {
    if (file) {
      setVideoFile(file);
      setFileList([file]);
    }
    return false;
  }, []);
  return (
    <S.CModal
      visible={visible}
      maskClosable={false}
      title={<S.Title>Add Walkthrough Video</S.Title>}
      onCancel={() => setVisible(false)}
      width={800}
      footer={null}
    >
      <Form onFinish={onFinish} form={form}>
        <Row gutter={[24, 16]} justify="center" style={{ marginTop: 10 }}>
          <Col span={20}>
            <S.TitleInput>Title</S.TitleInput>
            <AntdForm.Item
              name="title"
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Input the Title!',
                },
              ]}
            >
              <S.SInput />
            </AntdForm.Item>
          </Col>

          <Col span={20}>
            <Row gutter={8}>
              <Col span={12}>
                <S.TitleInput>Role</S.TitleInput>
                <AntdForm.Item
                  name="roles"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: 'Select roles!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Roles"
                    optionLabelProp="label"
                    showSearch={true}
                    dropdownMatchSelectWidth={false}
                    maxTagTextLength={1}
                    mode="multiple"
                    showArrow
                    value={currentRole}
                    onChange={handleChangeRole}
                  >
                    {roles.map((roleItem) => {
                      return (
                        <SelectOption value={roleItem?.value || ''} label={roleItem?.text} key={roleItem?.value}>
                          {roleItem?.text}
                        </SelectOption>
                      );
                    })}
                  </Select>
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <S.TitleInput>Category</S.TitleInput>
                <AntdForm.Item
                  name="categoryId"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: 'Select a category!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a category"
                    value={currentCategory}
                    dropdownMatchSelectWidth={false}
                    onChange={handleChangeCategory}
                  >
                    {categories?.getWalkthroughCategory.map((category) => (
                      <SelectOption value={category.id} key={category.id}>
                        {category.title}
                      </SelectOption>
                    ))}
                  </Select>
                </AntdForm.Item>
              </Col>
            </Row>
          </Col>
          <Col span={20}>
            <S.TitleInput>Video Url</S.TitleInput>
            <AntdForm.Item
              name="videoUrl"
              style={{ marginBottom: 5 }}
              rules={[
                {
                  required: !videoFile,
                  message: 'Input the Video Url!',
                },
              ]}
            >
              <S.SInput />
            </AntdForm.Item>
            <UploadInput
              beforeUpload={beforeUpload}
              maxCount={1}
              title={'Upload new video'}
              accept="video/*"
              onRemove={(f) => setVideoFile(undefined)}
              defaultFileList={[]}
              fileList={fileList}
            />
          </Col>
          <Col span={8}>
            <AntdForm.Item>
              <Button block loading={loading} text="Create Walkthrough Video" htmlType="submit" />
            </AntdForm.Item>
          </Col>
        </Row>
      </Form>
    </S.CModal>
  );
};

export default React.memo(AddWalkthroughVideo);
