import styled from 'styled-components';

export const Emitter = styled.div<{
  $top?: string;
  $right?: string;
  $color?: string;
}>`
  position: absolute;
  top: ${(props) => props.$top || '25px'};
  right: ${(props) => props.$right || '25px'};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;

  .sonar-wave {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${(props) => props.$color};
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }

  .sonar-wave1 {
    animation: sonarWave 1s linear infinite;
  }

  @keyframes sonarWave {
    from {
      opacity: 0.8;
    }
    to {
      transform: scale(2.5);
      opacity: 0;
    }
  }
`;
