import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Empty, message, Row, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import AnnouncementCard from '../../../shared/AnnouncementCard';
import Button from '../../../shared/Button';
import DashboardRow from '../../../shared/DashboardRow';
import { GQL_CreateAnnouncementInput, GQL_CreateAnnouncementResponse } from '../../../types/class';
import AnnouncementModal from '../AnnouncementModal';

interface IClassAnnouncementsRow {
  classId: string;
  className?: string;
  teacherName?: string;
  shouldLoad?: boolean;
  loaded: () => void;
}

const ClassAnnouncementsRow = (props: IClassAnnouncementsRow) => {
  const { classId, className, teacherName } = props;
  const [announcementModalVisible, setAnnouncementModalVisible] = useState(false);
  const [shouldSendEmail, setShouldSendEmail] = useState(false);
  const [announcementText, setAnnouncementText] = useState<string>();

  const { isTeacherOrFacilitator, isFacilitator, user } = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const classTitle = useMemo(() => (isFacilitator ? 'Course or Event' : 'Class'), [isFacilitator]);

  const [loadClassAnnouncements, { data }] = useLazyQuery<
    { getClassAnnouncements: GQL_CreateAnnouncementResponse[] },
    { classId: string }
  >(gqlSchema.ClassSchema.query.CLASS.ANNOUNCEMENT.getClassAnnouncements, {
    onError: (err) => {
      // notify parent on load complete
      props.loaded();

      message.error('There was an error loading announcements: ' + err.message || 'Unexpected Error');
    },
    onCompleted: () => {
      // notify parent on load complete
      props.loaded();
    },
  });

  useEffect(() => {
    if (props.shouldLoad) {
      loadClassAnnouncements({
        variables: {
          classId,
        },
      });
    }
  }, [props.shouldLoad, loadClassAnnouncements, classId]);

  const activeInvestigations = useMemo(
    () =>
      data?.getClassAnnouncements?.length ? (
        <Row gutter={[24, 24]}>
          {data?.getClassAnnouncements.slice(0, 3).map((item, index) => {
            return (
              <Col
                xxl={8}
                xl={8}
                lg={12}
                md={24}
                sm={24}
                xs={24}
                key={item.id}
                data-cy={`components-class-announcements-row-${index}`}
              >
                <AnnouncementCard
                  text={item.text}
                  id={item.id}
                  teacherName={teacherName || ''}
                  className={className || ''}
                  creation={item.createdAt}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Announcements" />
      ),
    [data, className, teacherName],
  );

  const extraInvestigation = useMemo(
    () => (
      <Row gutter={[24, 24]}>
        {data?.getClassAnnouncements.slice(3).map((item) => {
          return (
            <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
              <AnnouncementCard
                text={item.text}
                id={item.id}
                teacherName="Leyla Salman"
                className="Class 4"
                creation={item.createdAt}
              />
            </Col>
          );
        })}
      </Row>
    ),
    [data],
  );

  const [createAnnouncement, { loading: loadingAnnouncementCreation }] = useMutation<
    { createAnnouncement: GQL_CreateAnnouncementResponse },
    { data: GQL_CreateAnnouncementInput }
  >(gqlSchema.ClassSchema.mutation.CLASS.ANNOUNCEMENT.createAnnouncement, {
    onCompleted: () => {
      message.success('Announcement Created');
      setAnnouncementModalVisible(false);
      setAnnouncementText(undefined);
      setShouldSendEmail(false);
    },
    onError: (err) =>
      message.error(err.message || 'There was an error creating the announcement, please try again later'),

    update: (cache, { data: cacheData }) => {
      cache.modify({
        fields: {
          getClassAnnouncements: (existingAnnouncements = []) => {
            const newAnnouncementRef = cache.writeFragment({
              data: cacheData?.createAnnouncement,
              fragment: gqlSchema.ClassSchema.fragment.CLASS.announcement,
            });
            return [...existingAnnouncements, newAnnouncementRef];
          },
        },
      });
    },
  });

  const handleCloseModal = () => {
    setAnnouncementModalVisible(false);
    setAnnouncementText(undefined);
    setShouldSendEmail(false);
  };

  const handleCreateAnnouncement = async () => {
    if (announcementText) {
      createAnnouncement({
        variables: {
          data: { classId, sendEmail: shouldSendEmail, text: announcementText },
        },
      });
    }
  };

  return (
    <>
      <DashboardRow
        title={`${classTitle} Announcements`}
        items={activeInvestigations}
        extraItems={extraInvestigation}
        showViewMore={(data?.getClassAnnouncements?.length ?? 0) > 3}
        rightElement={
          (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) ? (
            <Tooltip title="New Announcement">
              <Button
                icon={<FiPlus size={22} />}
                style={{ marginLeft: 'auto', top: 12 }}
                shape="circle"
                minWidth={40}
                minHeight={40}
                onClick={() => setAnnouncementModalVisible(true)}
                data-cy="components-class-announcements-row-add-announcement"
              />
            </Tooltip>
          ) : null
        }
      />
      <AnnouncementModal
        loading={loadingAnnouncementCreation}
        onClose={handleCloseModal}
        onCreate={handleCreateAnnouncement}
        onTextChange={setAnnouncementText}
        visible={announcementModalVisible}
        setShouldSendEmail={setShouldSendEmail}
        shouldSendEmail={shouldSendEmail}
        text={announcementText}
      />
    </>
  );
};

export default ClassAnnouncementsRow;
