import React from 'react';
import { IMediaContentValue, InvestigationType, ITextContentValue } from '../../../types/investigation';
import InvestigationText from '../InvestigationText';
import InvestigationImage from '../InvestigationImage';

interface IInvestigationTextImage {
  value: ITextContentValue | IMediaContentValue;
  isAlreadyHtml?: boolean;
  investigationType?: InvestigationType;
  contentSize?: string;
}

const InvestigationTextImage = (props: IInvestigationTextImage) => {
  const { value, isAlreadyHtml, investigationType, contentSize } = props;
  const isText = !!(value as ITextContentValue).type;

  return isText ? (
    <InvestigationText value={value as ITextContentValue} isAlreadyHtml={isAlreadyHtml} />
  ) : (
    <InvestigationImage
      value={value as IMediaContentValue}
      investigationType={investigationType}
      contentSize={contentSize}
    />
  );
};

export default InvestigationTextImage;
