import { Badge } from 'antd';
import styled from 'styled-components';
export const TitleTab = styled.p`
  font-weight: 700;
  padding: 0;
  font-size: 1.1em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const BadgeWrapper = styled(Badge)`
  width: 100%;
`;
