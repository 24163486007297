import styled from 'styled-components';

import Button from '../../../shared/Button';

export const AssessmentsTableContainer = styled.div`
  .ant-table-thead {
    .ant-table-selection {
      display: none;
    }
  }

  .ant-table-row-disabled {
    background: red;
  }
`;

export const AssessmentsTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 2px;
  padding: 0 5px;
  height: 21px;
`;

export const AssessmentTitleDot = styled.div<{ color: string }>`
  border-radius: 50%;
  background-color: ${({ color }) => color};
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
