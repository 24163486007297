import React, { useState, useEffect, useMemo } from 'react';
import { Table, Input, Button, Spin, message, Modal, Empty } from 'antd';
import { useQuery } from '@apollo/client';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import AssignmentSummary from '../CanvasStudentsDashboardPage/AssignmentSummary';
import { gqlSchema } from '../../gql/schema';

const { Search } = Input;

interface Assignment {
  id: string;
  courseId: string;
  assignment_name: string;
  description: string;
  created_at: string;
  due_at: string;
  points_possible: number;
  workflow_state: string;
}

const CanvasStudentsAssignmentDueDate: React.FC = () => {
  const [courseIds, setCourseIds] = useState<string[]>([]);
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [filteredAssignments, setFilteredAssignments] = useState<Assignment[]>([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMorePages, setHasMorePages] = useState<boolean>(false);
  const [turnedInAssignments, setTurnedInAssignments] = useState<any[]>([]);
  const [courses, setCourses] = useState<any[]>([]);
  // const [previousAssignments, setPreviousAssignments] = useState<Assignment[]>([]);
  const [currentApiPage, setCurrentApiPage] = useState<number>(-1);

  const { loading: coursesLoading, refetch: refetchCourses } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasCoursesListByUser,
    {
      variables: { page: String(currentPage) },
      onCompleted: (data) => {
        const fetchedCourses = data?.getCanvasCoursesListByUser || [];
  
        if (fetchedCourses.length) {
          if (fetchedCourses.length === 3) {
            setHasMorePages(true);
          }
          setCourses(fetchedCourses);
          setCourseIds(fetchedCourses.map((course: any) => course.id));
          setCurrentApiPage(currentPage);
        } else {
          message.info(
            'No more pages'
          );
          setHasMorePages(false);
          setCurrentPage(currentApiPage);
        }
      },
      onError: (error) => {
        message.error(error.message || 'Error loading classes.');
      },
      skip: currentApiPage === currentPage,
      fetchPolicy: 'network-only',
    }
  );
  


  const { loading: assignmentsLoading, refetch: refetchAssignments } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentListByDueDate,
    {
      variables: { course_id: courseIds},
      skip: courseIds.length === 0,
      onCompleted: (data) => {
        const activeAssignments = data?.getCanvasAssignmentListByDueDate?.active || [];
        const sortedAssignments = activeAssignments
          .map((assignment: any) => {
            const turnedInAssignment = turnedInAssignments.find((a: any) => a.assignment_id === assignment.id);
            const course = courses.find((c: any) => c.id === assignment.course_id) || {};
            return {
              id: assignment.id,
              courseId: course.id,
              className: assignment.courseName,
              assignment_name: assignment.name ?? 'No Name',
              description: assignment.description || '',
              created_at: assignment.created_at,
              due_at: assignment.due_at,
              points_possible: assignment.points_possible,
              workflow_state: turnedInAssignment ? turnedInAssignment.workflow_state : 'Not Available',
            };
          })
          .sort((a: Assignment, b: Assignment) => {
            if (!a.due_at) return 1;
            if (!b.due_at) return -1;
            return new Date(a.due_at).getTime() - new Date(b.due_at).getTime();
          });

        setAssignments(sortedAssignments);
        setFilteredAssignments(
          sortedAssignments.filter((assignment: Assignment) =>
            assignment.assignment_name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      },
      onError: (error) => {
        message.error(error.message || 'Error loading assignments.');
      },
    }
  );
  


  const { loading: turnedInLoading, refetch: refetchTurnedInAssignments } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentListByTurnedIn,
    {
      variables: { course_id: courseIds },
      onCompleted: (data) => {
        console.log('Turned-in Assignments Data:', data);

        const expiredAssignments = data?.getCanvasAssignmentListByTurnedIn?.expired || [];
        console.log('Expired Assignments:', expiredAssignments);

        setTurnedInAssignments(expiredAssignments);

        const workflowStates = expiredAssignments.map((assignment: any) => assignment.workflow_state);
        console.log('Workflow States from expired assignments:', workflowStates);
      },
      onError: (err) => {
        console.error('Error loading turned-in assignments:', err);
        message.error('There was an error loading assignments: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const { data: submissionData } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasSubmissionById,
    {
      variables: {
        assignment_id: selectedAssignment?.id,
        course_id: selectedAssignment?.courseId || '', 
      },
      skip: !selectedAssignment || !selectedAssignment.courseId,  // Skip query if no assignment is selected
      onCompleted: (data) => {
        console.log('Submission Data:', data);
      },
      onError: (error) => {
        console.error('Error loading submission data:', error);
        message.error(error.message || 'Error loading submission data.');
      },
    }
  );
  
  
  useEffect(() => {
    console.log('Selected Assignment:', selectedAssignment);
    if (selectedAssignment) {
      console.log('Course ID:', selectedAssignment.courseId);
      console.log('Assignment ID:', selectedAssignment.id);
    }
  }, [selectedAssignment]);
  
  

  useEffect(() => {
    if (courseIds.length > 0) {
      refetchAssignments();
      refetchTurnedInAssignments();
    }
  }, [courseIds, currentPage, refetchAssignments, refetchTurnedInAssignments]);

  useEffect(() => {
    refetchCourses({ page: String(currentPage) });
  }, [currentPage, refetchCourses]);

  useEffect(() => {
    const filtered = assignments?.filter((assignment: Assignment) =>
      assignment.assignment_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredAssignments(filtered);
  }, [searchTerm, assignments]);

  useEffect(() => {
    if (assignments.length > 0 && turnedInAssignments.length > 0) {
      const updatedAssignments = assignments.map((assignment) => {
        const turnedInAssignment = turnedInAssignments.find((a) => a.assignment_id === assignment.id);
        return {
          ...assignment,
          workflow_state: turnedInAssignment ? turnedInAssignment.workflow_state : 'Not Available',
        };
      });
      setAssignments(updatedAssignments);
    }
  }, [turnedInAssignments]);
  

  const handleViewSummaryClick = (assignment: Assignment) => {
    const turnedInAssignment = turnedInAssignments.find((a: any) => a.assignment_id === assignment.id);
    const courseIdFromTurnedIn = turnedInAssignment ? turnedInAssignment.course_id : assignment.courseId;
  
    setSelectedAssignment({
      ...assignment,
      courseId: courseIdFromTurnedIn,
    });
  
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setSelectedAssignment(null);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handlePageChange = (direction: 'prev' | 'next') => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && hasMorePages) {
      setCurrentPage(currentPage + 1);
    }
  };
  

  const columns = [
    {
      title: 'Class Name',
      dataIndex: 'className',
      key: 'className',
    },
    {
      title: 'Assignment Name',
      dataIndex: 'assignment_name',
      key: 'assignment_name',
    },
    {
      title: 'Due Date',
      dataIndex: 'due_at',
      key: 'due_at',
      render: (dueAt: string) => dueAt ? new Date(dueAt).toLocaleDateString() : 'No Due Date',
    },
    {
      title: 'Status',
      dataIndex: 'workflow_state',
      key: 'workflow_state',
      render: (workflow_state: string, record: Assignment) => {
        const isCompleted = ['graded', 'submitted'].includes(workflow_state ?? '');
        const status = isCompleted ? 'Completed' : 'Not Completed';
        
        if (!workflow_state) {
          console.log(`Assignment ${record.assignment_name} has undefined workflow_state.`);
        }
      
        return (
          <Button
            type={isCompleted ? 'primary' : 'default'}
            onClick={() => handleViewSummaryClick(record)}
            style={{ width: '150px' }}
          >
            {status}
          </Button>
        );
      },
    }
  ];

  const renderPagination = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
      <Button
        icon={<LeftOutlined />}
        onClick={() => handlePageChange('prev')}
        disabled={currentPage === 1}
      >
        Previous
      </Button>
      <Button
        icon={<RightOutlined />}
        onClick={() => handlePageChange('next')}
        disabled={!hasMorePages}
      >
        Next
      </Button>
    </div>
  );

  const classes = useMemo(() => {
    if (coursesLoading || assignmentsLoading || turnedInLoading) {
      return <Spin tip="Loading..." />;
    }
  
    if (assignments.length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Assignments" />;
    }
  
    return (
      <>
        <Table columns={columns} dataSource={filteredAssignments} rowKey="id" pagination={false} />
        {renderPagination()}
      </>
    );
  }, [coursesLoading, turnedInLoading, filteredAssignments, columns, currentPage, hasMorePages]);  
  

  return (
    <div style={{ padding: 40 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <Search
          placeholder="Search Assignment Name"
          style={{ width: 200 }}
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      {classes}
      <Modal
        title=""
        visible={isPopupVisible}
        onCancel={handleClosePopup}
        footer={null}
        width={800}
      >
        {selectedAssignment && (
          <AssignmentSummary
          courseId={selectedAssignment.courseId}
          assignmentId={selectedAssignment.id}
          visible={isPopupVisible}
          onClose={handleClosePopup}
          isFinished={['graded', 'submitted'].includes(selectedAssignment?.workflow_state?.toLowerCase())}
          score={submissionData?.getCanvasSubmissionById?.score}
          courseWorkData={submissionData?.getCanvasSubmissionById}
          courseWorkError={null}
          courseWorkLoading={assignmentsLoading}
          assignment={selectedAssignment}
          />
        )}
      </Modal>
    </div>
  );
};

export default CanvasStudentsAssignmentDueDate;
