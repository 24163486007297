import styled from 'styled-components';

export const AddParagraphContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;

  svg {
    min-height: 24px;
    min-width: 24px;
    color: ${(props) => props.theme.success.color};
  }
`;

export const DeleteParagraphContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;

  svg {
    min-height: 20px;
    min-width: 20px;
    color: ${(props) => props.theme.error.background};
  }
`;
