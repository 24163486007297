import { Row, Modal as ModalAntd } from 'antd';
import styled from 'styled-components';

export const Info = styled.h4`
  margin-bottom: 0;
  font-weight: 400;
`;

export const BarRow = styled(Row)`
  position: relative;
  margin-top: 24px;
  z-index: 1;
`;

export const Modal = styled(ModalAntd)`
  .ant-modal-content {
    border-radius: 5px;
    max-height: 75vh;
    overflow-y: auto;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const ModalTitle = styled.h1`
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
`;

export const ModalInfo = styled.p`
  font-weight: 400;
  font-size: 1rem;
  margin-top: 8px;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 24px;
  text-align: center;
  color: #767676;
`;
