import React from 'react';

import * as S from './styles';

interface ISteps {
  finished: boolean;
  onChange?: (current: number) => void;
  current?: number;
}

const Steps: React.FC<ISteps> = (props) => {
  const { finished, onChange, current, children } = props;

  return (
    <S.Steps direction="vertical" current={current} onChange={onChange} $finished={finished}>
      {children}
    </S.Steps>
  );
};

export const Step = S.Step;

export default Steps;
