import styled from 'styled-components';
import Button from '../../../shared/Button';
import { StyledInput } from '../../../shared/Input/styles';

export const ClassInfosContainer = styled.div`
  background: white;
  padding: 24px;
  border-radius: 6px;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  .ant-skeleton-element {
    width: 90%;
  }

  .ant-skeleton-input {
    border-radius: 4px;
  }
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 26px;
  /* identical to box height */

  color: #000000;
`;

export const SInput = styled(StyledInput)`
  font-size: 15px;
  background-color: #ffffff;
  margin-right: 16px;
  width: 90%;

  &:disabled {
    padding-left: 0;
    color: #767676 !important;
  }
`;

export const SubText = styled.p`
  margin: 0;
  font-size: 15px;
  color: #767676 !important;
  height: 31px;
  display: table-cell;
  vertical-align: middle;
`;

export const StatusText = styled.p<{ finished?: boolean }>`
  margin: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 125.1%;
  color: ${(props) => (props.finished ? '#2ecc71' : '#d04835')};
`;

export const EditButton = styled(Button)`
  line-height: 0;
  svg {
    font-size: 20px;
  }
`;

export const MoreButton = styled(Button)`
  position: absolute;
  right: 16px;
  bottom: 16px;
`;
