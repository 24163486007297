import React, { useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { message, Spin, Divider } from 'antd';
import PageWithTitle from '../../shared/PageWithTitle';
import ClassInfos from './ClassInfos';
import AssignmentTable from './AssignmentTable';
import { gqlSchema } from '../../gql/schema';
import { useQuery } from '@apollo/client';

const CanvasTeacherAssignmentSummary = () => {
  const { courseId, assignmentId } = useParams<{ courseId: string; assignmentId: string }>();

  const [assignmentData, setAssignmentData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const { data: classData } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasCoursesById,
    {
      variables: { course_id: courseId },
      onError: (err: any) => {
        message.error('There was an error: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const { data: submissionData } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasSubmissionList,
    {
      variables: {
        course_id: courseId,
        assignment_id: assignmentId,
      },
      onError: (err: any) => {
        message.error('There was an error: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const { data: assignmentListData } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentListBySubmission,
    {
      variables: {
        data: [
          {
            course_id: courseId,
            id: assignmentId,
          },
        ],
      },
      onError: (err: any) => {
        message.error('There was an error: ' + (err.message || 'Unexpected Error'));
      },
      onCompleted: (data) => {
        console.log('Assignment List Data:', data); 
      },
    }
  );

  const { data: studentList } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasStudentList,
    {
      variables: {
        course_id: courseId,
        enrollment_type: 'student',
      },
      onError: (err: any) => {
        message.error('There was an error: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const { loading: assignmentDataLoading} = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentById,
    {
      variables: {
        course_id: courseId,
        id: assignmentId,
      },
      fetchPolicy: 'network-only',
      onError: (err: any) => {
        message.error('There was an error: ' + (err.message || 'Unexpected Error'));
      },
      onCompleted: (data) => {
        console.log('Assignment Data on Completed:', data);
        setAssignmentData(data.getCanvasAssignmentById);
        setLoading(false);
      },
    }
  );

  if (loading || assignmentDataLoading) {
    return (
      <PageWithTitle title="Assignment Details"  locationState={{ courseId, assignmentId }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Spin size="large" />
        </div>
      </PageWithTitle>
    );
  }

  const assignmentName = assignmentData?.name || 'Default Assignment Name';


  const complete = assignmentListData?.getCanvasAssignmentListBySubmission?.[0]?.complete;
  const totalStudent = assignmentListData?.getCanvasAssignmentListBySubmission?.[0]?.totalStudent;

  return (
    <PageWithTitle title="Assignment Details" >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <>
          <ClassInfos
            classInfo={classData?.getCanvasCoursesById}
            assignmentInfo={assignmentData}
            submissionCount={submissionData?.getCanvasSubmissionList?.length || 0}
            studentNames={studentList?.getCanvasStudentList?.students}
            complete={complete}
            totalStudent={totalStudent}
            assignmentName={assignmentName}
            onDeleteSuccess={() => {}}
            description= {assignmentData?.description}
          />
          <Divider/>
          <AssignmentTable
            courseId={courseId}
            courseWorkId={assignmentId}
            courseData={classData?.getCanvasCoursesById}
            studentNames={studentList?.getCanvasStudentList?.students}
            assignmentData={assignmentData}
            submissionData={submissionData?.getCanvasSubmissionList?.canvasSubmission}
            assignmentName={assignmentName}
          />
        </>
      </div>
    </PageWithTitle>
  );
};

export default withRouter(CanvasTeacherAssignmentSummary);
