import React from 'react';
import { useHistory } from 'react-router-dom';

import successImage from '../../../assets/success_image.png';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../../shared/Button';
import * as S from './styles';

const FinishedInvestigation = () => {
  const history = useHistory();
  const { user } = useAuth();
  const backToDashboard = () => {
    if (user.preferredRole === 'google_student') {
      history.push('/googleclassroom-students-dashboard');
    } else if (user.preferredRole === 'canvas_student') {
      history.push('/canvas-students-dashboard');
    } else {
      history.push('/student-dashboard');
    }
  };

  return (
    <S.SuccesContainer data-cy="studentinvestigation-finishedinvestigation-success-container">
      <img src={successImage} alt="Success" />
      <h1>
        Congratulations! You have finished the investigation.
        <br />
        Go back to your dashboard to see your grade
      </h1>
      <Button
        data-cy="studentinvestigation-finishedinvestigation-back-button"
        text="Back to Dashboard"
        onClick={backToDashboard}
      />
    </S.SuccesContainer>
  );
};

export default FinishedInvestigation;
