import styled from 'styled-components';

export const NotesContainer = styled.div<{ zIndex?: number }>`
  position: absolute;
  top: 32px;
  left: 32px;
  display: flex;
  flex-direction: column;
  ${(props) => props.zIndex && `z-index: ${props.zIndex};`}

  & > div:not(:first-child) {
    margin-top: 8px;
  }
`;

export const TeacherNotesContainer = styled.div`
  display: flex;
`;

export const SafetyNote = styled.div`
  height: 32px;
  width: 32px;

  svg {
    height: 100%;
    width: 100%;
    color: #ffbb33;
  }
`;

export const TeacherNote = styled.div`
  height: 32px;
  width: 32px;
  text-align: center;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  border-radius: 50%;
  background: transparent;
  border: 2px solid ${(props) => props.theme.secondaryColor.background};

  color: ${(props) => props.theme.secondaryColor.background};

  &:hover {
    color: white;
    background: ${(props) => props.theme.secondaryColor.background};
  }
`;

export const PopoverContent = styled.div<{ width?: number }>`
  margin-bottom: 0;
  text-align: center;
  max-width: ${(props) => props.width ?? 640}px;
  overflow-wrap: break-word;

  p {
    margin-bottom: 0;
    min-height: 8px;
  }
`;
