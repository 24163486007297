import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Row, Col, Empty } from 'antd';
import startCase from 'lodash/startCase';
import DashboardRow from '../../../shared/DashboardRow';
import Item from './Item';

import { GQL_InvestigationTeacherEntry } from '../../../types/investigation';
import { formatDistance } from '../../../utils/date';
import InvestigationIcon from '../../../shared/InvestigationIcon';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import { useAuth } from '../../../hooks/useAuth';

interface IActiveInvestigationsRow {
  investigations?: GQL_InvestigationTeacherEntry[];
  loading: boolean;
}

const ActiveInvestigationsRow = (props: IActiveInvestigationsRow) => {
  const { investigations, loading } = props;
  const investigationsLength = investigations?.length ?? 0;
  const { isFacilitator } = useAuth();

  const activeInvestigations = useMemo(() => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard />
          </Col>
        </Row>
      );
    } else if (!investigations?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Investigations" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {investigations?.slice(0, 3).map((item: GQL_InvestigationTeacherEntry, index) => {
            return (
              <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={`${item.id}-${index}`}>
                <Item
                  className={item.class}
                  progress={item.completion * 100}
                  classColor={item.classColorHex}
                  timeLeft={`${formatDistance(item.dueDate)} left`}
                  investigationName={`${startCase(item.discipline?.name?.toLowerCase())}: ${item.title}`}
                  investigationId={item.id}
                  timeWarning={false}
                  type={item.discipline?.subject === 'Engineering' ? 'Design Challenge' : 'Investigation'}
                  classId={item.classId}
                  icon={
                    <InvestigationIcon
                      subject={item.discipline?.subject || ''}
                      discipline={item.discipline?.name || ''}
                    />
                  }
                />
              </Col>
            );
          })}
        </Row>
      );
    }
  }, [investigations, loading]);

  const [extraActiveInvestigations, setActiveInvestigations] = useState<ReactElement | null>(null);

  const onViewMoreActiveInvestigations = useCallback(
    (opened: boolean) => {
      if (opened)
        setActiveInvestigations(
          <Row gutter={[24, 24]}>
            {investigations?.slice(3, investigationsLength)?.map((item: GQL_InvestigationTeacherEntry, index) => {
              return (
                <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={`${item.id}-${index}`}>
                  <Item
                    className={item.class}
                    progress={item.completion * 100}
                    classColor={item.classColorHex}
                    timeLeft={`${formatDistance(item.dueDate)} left`}
                    investigationName={`${startCase(item.discipline?.name?.toLowerCase())}: ${item.title}`}
                    investigationId={item.id}
                    timeWarning={false}
                    type={item.discipline?.subject === 'Engineering' ? 'Design Challenge' : 'Investigation'}
                    classId={item.classId}
                    icon={
                      <InvestigationIcon
                        subject={item.discipline?.subject || ''}
                        discipline={item.discipline?.name || ''}
                      />
                    }
                  />
                </Col>
              );
            })}
          </Row>,
        );
      else setActiveInvestigations(null);
    },
    [investigations, investigationsLength],
  );

  return (
    <DashboardRow
      title={`Active ${isFacilitator ? 'Workshop' : ''} Investigations`}
      items={activeInvestigations}
      extraItems={extraActiveInvestigations}
      onViewMore={onViewMoreActiveInvestigations}
      showViewMore={investigationsLength > 3}
    />
  );
};

export default ActiveInvestigationsRow;
