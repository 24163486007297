import React from 'react';
import { Col, Row } from 'antd';
import { FiEdit, FiFilePlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import Button from '../../shared/Button';
import PageWithTitle from '../../shared/PageWithTitle';
import Spacer from '../../shared/Spacer';
import { themeConfig } from '../../utils/theme';

const AdiAssessmentPage = () => {
  const history = useHistory();

  const navigateToTemplates = () => {
    history.push('/adi-assessments/templates');
  };

  const navigateToExistingAssessments = () => {
    history.push('/adi-assessments/assessments-list');
  };

  return (
    <PageWithTitle>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <S.Card>
            <S.IconContainer newAssessment>
              <FiFilePlus />
            </S.IconContainer>
            <Spacer />
            <h1>Create a New Assessment Using a Template</h1>
            <h2>
              Create a Science or Math assessment using templates that will pre-fill pages based on different item
              types.
            </h2>
            <Spacer />
            <Button
              data-cy="components-adiassessment-create-new-select-button"
              text="Select Assessment Template"
              theme={themeConfig.primaryOutlined}
              minHeight={40}
              onClick={navigateToTemplates}
            />
          </S.Card>
        </Col>
        <Col xs={24} md={12}>
          <S.Card>
            <S.IconContainer>
              <FiEdit />
            </S.IconContainer>
            <Spacer />
            <h1>Manage Existing Assessments</h1>
            <h2>See and edit all unpublished, published and pending to delete Assessments.</h2>
            <Spacer />
            <Button
              text="Manage Assessments"
              data-cy="components-adiassessment-manage-page-select-button"
              theme={themeConfig.primaryOutlined}
              minHeight={40}
              onClick={navigateToExistingAssessments}
            />
          </S.Card>
        </Col>
      </Row>
    </PageWithTitle>
  );
};

export default AdiAssessmentPage;
