import React, { useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { GQL_ClassResponse } from '../../../types/class';
import { Col, DatePicker, TimePicker, Row } from 'antd';
import * as S from './styles';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

interface IAssignAssessmentClassDatePicker {
  classes: GQL_ClassResponse[];
  selectedClasses: string[];
  selectedDateTimes: {
    [key: string]: [startDate: any, endDate: any];
  };
  setSelectedDateTimes: React.Dispatch<
    React.SetStateAction<{
      [key: string]: [startDate: any, endDate: any];
    }>
  >;
}

const timeFormat = 'hh:mm a';

const AssignAssessmentClassDatePicker: React.FC<IAssignAssessmentClassDatePicker> = (props) => {
  const [currentClassIndex, setCurrentClassIndex] = useState(0);

  const viewNextState = () => {
    setCurrentClassIndex((currentState) => Math.min(currentState + 1, props.selectedClasses.length - 1));
  };

  const viewPreviousState = () => {
    setCurrentClassIndex((currentState) => Math.max(currentState - 1, 0));
  };

  const currentClass = useMemo(() => {
    const currentClass = props.classes.find((c) => c.id === props.selectedClasses[currentClassIndex]);
    return currentClass;
  }, [currentClassIndex, props]);

  const handleDateRangeChange = (value: any) => {
    props.setSelectedDateTimes((currentValue) => ({
      ...currentValue,
      [props.selectedClasses[currentClassIndex]]: value,
    }));
  };

  // values used in render
  const isFirstIndex = useMemo(() => currentClassIndex === 0, [currentClassIndex]);
  const isLastIndex = useMemo(() => currentClassIndex === props.selectedClasses.length - 1, [currentClassIndex, props]);
  const currentClassSelectedRange = useMemo(() => props.selectedDateTimes[props.selectedClasses[currentClassIndex]], [
    props,
    currentClassIndex,
  ]);

  const calculateTime = (value: Moment | null, currentClassSelectedRange: Moment) => {
    let tempDate = moment(currentClassSelectedRange);
    const [hours, minutes] = moment(value).format('HH:mm').split(':');
    tempDate.set('hour', parseInt(hours));
    tempDate.set('minute', parseInt(minutes));

    return tempDate;
  };

  const handleStartTime = (value: Moment | null) => {
    const currentStartRange = (currentClassSelectedRange && currentClassSelectedRange[0]) || null;
    const currentEndRange = (currentClassSelectedRange && currentClassSelectedRange[1]) || null;
    let newTime: Moment | null;

    if (currentStartRange) {
      newTime = calculateTime(value, currentStartRange);
    } else {
      newTime = value;
    }

    props.setSelectedDateTimes((currentValue) => ({
      ...currentValue,
      [props.selectedClasses[currentClassIndex]]: [newTime, currentEndRange],
    }));
  };

  const handleEndTime = (value: Moment | null) => {
    const currentStartRange = (currentClassSelectedRange && currentClassSelectedRange[0]) || null;
    const currentEndRange = (currentClassSelectedRange && currentClassSelectedRange[1]) || null;
    let newTime: Moment | null;

    if (currentEndRange) {
      newTime = calculateTime(value, currentEndRange);
    } else {
      newTime = value;
    }

    props.setSelectedDateTimes((currentValue) => ({
      ...currentValue,
      [props.selectedClasses[currentClassIndex]]: [currentStartRange, newTime],
    }));
  };

  return (
    <div>
      <Row>
        <Col span={4}>
          {!isFirstIndex && (
            <S.TitleButton onClick={viewPreviousState}>
              <FiArrowLeft />
            </S.TitleButton>
          )}
        </Col>
        <Col span={16}>
          <S.Title>
            Assigning for{' '}
            <S.ClassNamePill background={currentClass?.colorHex || 'blue'}>{currentClass?.name}</S.ClassNamePill>
          </S.Title>
        </Col>
        <Col span={4}>
          {!isLastIndex && (
            <S.TitleButton onClick={viewNextState}>
              <FiArrowRight />
            </S.TitleButton>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DatePicker.RangePicker
            onChange={handleDateRangeChange}
            value={currentClassSelectedRange}
            allowClear
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Col span={12} style={{ paddingRight: 8 }}>
          <TimePicker
            format={timeFormat}
            placeholder="Start time"
            value={currentClassSelectedRange && currentClassSelectedRange[0]}
            onSelect={handleStartTime}
          />
        </Col>
        <Col span={12} style={{ paddingLeft: 8 }}>
          <TimePicker
            format={timeFormat}
            placeholder="End time"
            value={currentClassSelectedRange && currentClassSelectedRange[1]}
            onSelect={handleEndTime}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AssignAssessmentClassDatePicker;
