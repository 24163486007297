import React, { useMemo } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { Col, Modal, Row } from 'antd';

import { SubscriptionPackageType } from '../../../constants/enums/SubscriptionPackageType';
import PackageCard from '../../SubscriptionPackageSelectionPage/PackageCard';
import {
  packageUpgradeFeatures,
  packageDowngradeFeatures,
} from '../../SubscriptionPackageSelectionPage/packageFeatures';
import * as S from './styles';

type Props = {
  currentPlan: string;
  newPlan: string;
  onClose: () => void;
};

export const UpgradeModal = (props: Props) => {
  const { currentPlan, newPlan, onClose } = props;

  const isUpgrade = useMemo(() => {
    if (currentPlan === SubscriptionPackageType.PREMIUM) {
      return false;
    }
    if (currentPlan === SubscriptionPackageType.PRO && newPlan === SubscriptionPackageType.STARTER) {
      return false;
    }
    if (currentPlan === SubscriptionPackageType.PRO && newPlan === SubscriptionPackageType.PREMIUM) {
      return true;
    }
    if (currentPlan === SubscriptionPackageType.STARTER) {
      return true;
    }
  }, [currentPlan, newPlan]);

  const starterPlan = isUpgrade
    ? packageUpgradeFeatures(currentPlan.toLowerCase()).starter
    : packageDowngradeFeatures(newPlan.toLowerCase()).starter;
  const proPlan = isUpgrade
    ? packageUpgradeFeatures(currentPlan.toLowerCase()).pro
    : packageDowngradeFeatures(newPlan.toLowerCase()).pro;
  const premiumPlan = isUpgrade
    ? packageUpgradeFeatures(currentPlan.toLowerCase()).premium
    : packageDowngradeFeatures(newPlan.toLowerCase()).premium;

  const currentPlanCard = useMemo(() => {
    let localCurrentPlan;
    switch (currentPlan) {
      case SubscriptionPackageType.PRO:
        localCurrentPlan = proPlan;
        break;
      case SubscriptionPackageType.PREMIUM:
        localCurrentPlan = premiumPlan;
        break;
      default:
        localCurrentPlan = starterPlan;
        break;
    }

    if (isUpgrade) {
      localCurrentPlan.features = localCurrentPlan.features.map((each) => {
        return {
          text: each.text,
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
        };
      });
    }

    return (
      <PackageCard
        title={currentPlan}
        subtitle={localCurrentPlan.subtitle}
        activePlan={true}
        content={localCurrentPlan.features}
      />
    );
  }, [currentPlan, starterPlan, proPlan, premiumPlan, isUpgrade]);

  const newPlanCard = useMemo(() => {
    let localNewPlan;
    let pillText;
    switch (newPlan) {
      case SubscriptionPackageType.PRO:
        localNewPlan = proPlan;
        pillText = 'Starter + Upgrades';
        break;
      case SubscriptionPackageType.PREMIUM:
        localNewPlan = premiumPlan;
        pillText = 'Pro + Upgrades';
        break;
      default:
        localNewPlan = starterPlan;
        pillText = '';
        break;
    }

    if (!isUpgrade) {
      localNewPlan.features = localNewPlan.features.map((each) => {
        return {
          text: each.text,
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
        };
      });
    }

    return (
      <PackageCard
        title={newPlan}
        subtitle={localNewPlan.subtitle}
        pillText={pillText}
        content={localNewPlan.features}
      />
    );
  }, [newPlan, starterPlan, proPlan, premiumPlan, isUpgrade]);

  return (
    <Modal
      centered
      visible={true}
      footer={false}
      onCancel={onClose}
      width="1280px"
      zIndex={1003}
      closeIcon={<RxCross2 size="1.25em" />}
    >
      <S.ModalBody>
        <S.ModalTitle>Your Current Plan</S.ModalTitle>

        <Row justify="center" gutter={[50, 24]}>
          <Col span={12}>{currentPlanCard}</Col>
          <Col span={12}>{newPlanCard}</Col>
        </Row>
      </S.ModalBody>
    </Modal>
  );
};
