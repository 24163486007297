import React, { useState } from 'react';
import Modal from 'antd/lib/modal';
import { useCallback } from 'react';
import styled from 'styled-components';
import Button from '../shared/Button';
import { Row } from 'antd';
import { themeConfig } from '../utils/theme';
import Spacer from '../shared/Spacer';

const useBetaFeaturesModal = () => {
  const [hasOpenModal, setHasOpenModal] = useState(false);

  const openModal = useCallback(() => {
    if (hasOpenModal) return;
    setHasOpenModal(true);

    const m = Modal.info({
      title: <Title data-cy="components-beta-features-modal">Beta Test Feature List</Title>,
      width: 800,
      closable: true,
      afterClose: () => setHasOpenModal(false),
      maskClosable: true,
      icon: null,
      zIndex: 1200,
      bodyStyle: {
        marginBottom: -12,
      },
      okButtonProps: {
        style: {
          display: 'none',
        },
      },
      content: (
        <>
          <Text>
            In the list below you will be able to understand what are the new features we are planning to release soon.
            <br />
            Always remember that this is a Work-In-Progress and you may experiences early bugs.
          </Text>

          <Title style={{ textAlign: 'center' }}>
            You already have the latest features of our app!{' '}
            <span   role="img" aria-label="rocket">
              🚀
            </span>
          </Title>

          {/* Change to true and add the info for the beta test features */}
          {false && (
            <>
              <Title style={{ textAlign: 'left' }}>
                <span   role="img" aria-label="rocket">
                  🚀
                </span>{' '}
                Improve "view more" classes and investigations.
              </Title>
              <Text style={{ textAlign: 'left' }}>
                <TextSpacer />
                For this initial feature we improved the dashboards when there were more than 2 classes or 3
                investigations.
                <TextSpacer />
                Now it should be easier to find your extra classes and investigations.
              </Text>
            </>
          )}
          <Spacer />
          <Row justify="center">
            <Button
              text="Give Feedback"
              theme={themeConfig.noColor}
              minWidth={120}
              minHeight={40}
              type="link"
              href="https://survey.hsforms.com/1zs2h1BfEQD-i-jqaPFZFdA5gbsq"
              target="_blank"
            />
            <Spacer axis="horizontal" />
            <Button
              text="Understood"
              minWidth={120}
              minHeight={40}
              onClick={() => m.destroy()}
              data-cy="components-beta-features-modal-dismiss-button"
            />
          </Row>
        </>
      ),
    });
  }, [hasOpenModal]);

  return { openModal };
};

export default useBetaFeaturesModal;

const Title = styled.h3`
  text-align: center;
  margin: 0;
`;

const Text = styled.p`
  color: #767676;
  text-align: center;
`;

const TextSpacer = styled.br`
  margin-bottom: 8px;
  content: '';
  display: block;
`;
