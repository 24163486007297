import { Filter } from './antd';

export const roles: Filter[] = [
  { text: 'ADI Super Admin', value: 'adi_super_admin', color: '#a91f3e' },
  { text: 'ADI Admin', value: 'adi_admin', color: '#45b2cc' },
  { text: 'Organization Admin', value: 'organization_admin', color: '#7B8CEA' },
  { text: 'Teacher', value: 'teacher', color: '#e9a644' },
  { text: 'Teacher Assistant', value: 'teacher_assistant', color: '#a94c1f' },
  { text: 'Student', value: 'student', color: '#45cc70' },
  { text: 'Writer', value: 'writer', color: '#8827ce' },
  { text: 'Facilitator', value: 'facilitator', color: '#094f50' },
  { text: 'Google Teacher', value: 'google_teacher', color: '#e9a644' },
  { text: 'Google Student', value: 'google_student', color: '#45cc70' },
  { text: 'Canvas Teacher', value: 'canvas_teacher', color: '#e9a644' },
  { text: 'Canvas Student', value: 'canvas_student', color: '#45cc70' },
  { text: 'Book User', value: 'book_user', color: '#094f50' },
];
