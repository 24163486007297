import { Col, Row } from 'antd';
import React from 'react';
import { FiMinusCircle, FiPlus } from 'react-icons/fi';
import Input from '../../../../../shared/Input';
import Spacer from '../../../../../shared/Spacer';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  ISortingBoardContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';

interface ISortingBoardBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const SortingBoardBlockEditor = (props: ISortingBoardBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const sortingBoardValue = editingBlock.values[0] as ISortingBoardContentValue;

  const handleEditSortingBoard = (payload: { title?: string; count?: number; index?: number }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              const targetCount = sortingBoardValue?.targets?.length ?? 0;

              if ((payload.count ?? targetCount) > targetCount) {
                return {
                  ...block,
                  values: [
                    {
                      targets: [...(sortingBoardValue.targets ?? []), { title: '', values: [] }],
                    },
                  ],
                };
              } else if ((payload.count ?? targetCount) < targetCount) {
                return {
                  ...block,
                  values: [
                    {
                      targets: sortingBoardValue.targets?.slice(0, -1) ?? [],
                    },
                  ],
                };
              }

              const newSBValue = {
                targets: sortingBoardValue.targets?.map((target, index) => {
                  if (index === payload.index) {
                    return {
                      ...target,
                      title: payload.title,
                    };
                  } else {
                    return target;
                  }
                }),
              };

              return {
                ...block,
                values: [{ ...newSBValue }],
              };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleAddRemoveValueToSortingBoard = (payload: { index: number; removeIndex?: number }) => {
    const selectedActivity = activities.find((activity) => activity.id === selectedActivityId);
    if (!selectedActivity) return;

    const newContent = (editingRemote ? selectedActivity.content : selectedActivity.contentInPerson).map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          title: content.title,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;

            return {
              ...block,
              values: [
                {
                  targets: sortingBoardValue?.targets?.map((t, i) => {
                    if (i === payload.index) {
                      const values = t.values || [];
                      return {
                        ...t,
                        values:
                          typeof payload.removeIndex === 'number'
                            ? [...values?.slice(0, payload.removeIndex), ...values?.slice(payload.removeIndex + 1)]
                            : [...values, ''],
                      };
                    } else {
                      return t;
                    }
                  }),
                },
              ],
            };
          }),
        };
      },
    );

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditValueToSortingBoard = (payload: { targetIndex: number; valueIndex: number; title: string }) => {
    const selectedActivity = activities.find((activity) => activity.id === selectedActivityId);
    if (!selectedActivity) return;

    const newContent = (editingRemote ? selectedActivity.content : selectedActivity.contentInPerson).map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          title: content.title,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;

            return {
              ...block,
              values: [
                {
                  targets: sortingBoardValue?.targets?.map((t, i) => {
                    if (i === payload.targetIndex) {
                      const values = t.values || [];
                      return {
                        ...t,
                        values: values.map((v, i) => {
                          if (i === payload.valueIndex) {
                            return payload.title;
                          } else {
                            return v;
                          }
                        }),
                      };
                    } else {
                      return t;
                    }
                  }),
                },
              ],
            };
          }),
        };
      },
    );

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  return (
    <>
      <h2>Target Amount</h2>
      <Input
        value={sortingBoardValue.targets?.length ?? 0}
        backgroundColor="white"
        max={3}
        min={1}
        type="number"
        onChange={(e) => handleEditSortingBoard({ count: Number(e.target.value) })}
      />
      <Spacer size={24} />
      {Array(sortingBoardValue.targets?.length ?? 0)
        .fill(1)
        .map((_, index, arr) => (
          <React.Fragment key={index}>
            <h2>Target {index + 1} Title</h2>
            <Input
              value={sortingBoardValue.targets && sortingBoardValue.targets[index].title}
              onChange={(e) => handleEditSortingBoard({ title: e.target.value, index })}
              backgroundColor="white"
              placeholder="Add target title"
            />
            <Spacer />
            <Row
              justify="space-between"
              align="middle"
              onClick={() => handleAddRemoveValueToSortingBoard({ index })}
              style={{ cursor: 'pointer' }}
            >
              <h2>Target {index + 1} Values</h2>
              <FiPlus size={18} color="#3EBC89" />
            </Row>
            <Row gutter={[8, 8]} align="middle" justify="center">
              {sortingBoardValue.targets &&
                sortingBoardValue.targets[index]?.values?.map((value, i) => (
                  <React.Fragment
                    key={`${i}${sortingBoardValue.targets && sortingBoardValue.targets[index]?.values?.length}`}
                  >
                    <Col span={22}>
                      <Input
                        value={value}
                        onChange={(e) =>
                          handleEditValueToSortingBoard({ title: e.target.value, valueIndex: i, targetIndex: index })
                        }
                        backgroundColor="white"
                        placeholder="Add value"
                      />
                    </Col>
                    <Col span={2}>
                      <FiMinusCircle
                        onClick={() => handleAddRemoveValueToSortingBoard({ index, removeIndex: i })}
                        style={{ cursor: 'pointer', verticalAlign: 'sub' }}
                        size={18}
                      />
                    </Col>
                  </React.Fragment>
                ))}
            </Row>
            {index !== arr.length - 1 && <S.HorizontalDivider />}
          </React.Fragment>
        ))}
    </>
  );
};

export default SortingBoardBlockEditor;
