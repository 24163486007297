import React, { useCallback, useMemo } from 'react';
import { Col, Row, message } from 'antd';
import { withRouter } from 'react-router-dom';
import * as S from './styles';
import { BillingSummary } from './BillingSummary';
import { PaymentMethod } from './PaymentMethod';
import { BillingInvoices } from './BillingInvoices';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_BillingInfo,
  GQL_Invoices,
  GQL_PaymentMethod,
  GQL_PaymentProduct,
  GQL_UserBillingData,
} from '../../types/payments';
import { useAuth } from '../../hooks/useAuth';
import { AiOutlineInfoCircle, AiOutlineWarning } from 'react-icons/ai';

const BillingInformationPage = () => {
  const { isUserDisabled } = useAuth();

  const { data: gqlBillingData, loading } = useQuery<{ getBillingInformationFromUser: GQL_UserBillingData }, any>(
    gqlSchema.PaymentsSchema.queries.LIST.getBillingInformationFromUser,
    {
      onError: (error) => {
        message.error(
          'There was an error loading your billing information, please check your connection and try again later',
        );
      },
    },
  );

  const data = gqlBillingData?.getBillingInformationFromUser;
  const { billingInfo, invoices, paymentMethods, product, planPending, noSubscription, isStarterPlan } = data || {};

  const showWarning = planPending || noSubscription || isUserDisabled;

  const messageLabel = useMemo(() => {
    if (planPending) {
      return 'Your account has a plan downgrade pending to be applied';
    }

    if (noSubscription) {
      return `You do not have a paid subscription`;
    }

    if (isUserDisabled) {
      return `Your account has been disabled since we found issues with your payment method - please update your
      subscription to continue`;
    }
  }, [planPending, noSubscription, isUserDisabled]);

  const inactivePlan = useMemo(() => {
    return isStarterPlan || noSubscription;
  }, [isStarterPlan, noSubscription]);

  const messageIcon = useMemo(() => {
    if (planPending || noSubscription) {
      return <AiOutlineWarning />;
    }

    if (isUserDisabled) {
      return <AiOutlineInfoCircle />;
    }
  }, [planPending, noSubscription, isUserDisabled]);

  const WarningMessage = useCallback(() => {
    return (
      <Col span={24}>
        <S.WarningCard $color="#ffc107">
          {messageLabel}
          {messageIcon}
        </S.WarningCard>
      </Col>
    );
  }, [messageLabel, messageIcon]);

  return (
    <S.Container>
      <Row gutter={[16, 16]}>
        <S.Title>Your Account Billing Information</S.Title>

        {showWarning && <WarningMessage />}

        <Col span={24}>
          <BillingSummary
            product={product || ({} as GQL_PaymentProduct)}
            billingData={billingInfo || ({} as GQL_BillingInfo)}
            loading={loading}
            isStarterPlan={Boolean(isStarterPlan)}
            planPending={Boolean(planPending)}
            noSubscription={Boolean(noSubscription)}
          />
        </Col>

        {!inactivePlan && (
          <Col span={12}>
            <PaymentMethod
              paymentMethodData={paymentMethods || ({} as GQL_PaymentMethod)}
              billingInfo={billingInfo || ({} as GQL_BillingInfo)}
              loading={loading}
            />
          </Col>
        )}

        {(Boolean(invoices?.length) || !inactivePlan) && (
          <Col span={inactivePlan ? 24 : 12} flex={'1'}>
            <BillingInvoices invoicesData={invoices || ([] as GQL_Invoices[])} loading={loading} />
          </Col>
        )}
      </Row>
    </S.Container>
  );
};

export default withRouter(BillingInformationPage);
