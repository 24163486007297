import React from 'react';
import * as S from './styles';
import { Col, message, Row, Tooltip } from 'antd';
import ExpiringSubscriptionItem from './ExpiringSubscriptionItem';
import { gqlSchema } from '../../../gql/schema';
import { useQuery } from '@apollo/client';
import { FiHelpCircle } from 'react-icons/fi';
import { GQL_ExpiringSubscriptionTypes, GQL_SubscriptionsExpiringChartResponse } from '../../../types/charts';

const ExpiringSubscription = () => {
  const { data, loading } = useQuery<{
    getExpiringSubscriptionsChart: GQL_SubscriptionsExpiringChartResponse;
  }>(gqlSchema.ChartSchema.queries.CHARTS.getExpiringSubscriptionsChart, {
    onError: (err) => {
      message.error('There was an error loading the expiring subscription: ' + err.message || 'Unexpected Error');
    },
  });

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Expiring Subscriptions</S.Title>
          <Tooltip title="Subscriptions that are about expire in the next 30 days.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col xs={24} lg={6}>
          <ExpiringSubscriptionItem
            type={GQL_ExpiringSubscriptionTypes.TrialSubscriptions}
            title="Trial Subscriptions"
            subscriptions={data?.getExpiringSubscriptionsChart.trialSubscriptions || 0}
            loading={loading}
          />
        </Col>
        <Col xs={24} lg={6}>
          <ExpiringSubscriptionItem
            type={GQL_ExpiringSubscriptionTypes.FreelanceTeachersSubscriptions}
            title="Freelance Teachers"
            subscriptions={data?.getExpiringSubscriptionsChart.freelanceTeachersSubscriptions || 0}
            loading={loading}
          />
        </Col>
        <Col xs={24} lg={6}>
          <ExpiringSubscriptionItem
            type={GQL_ExpiringSubscriptionTypes.OrganizationSubscriptions}
            title="Organizations"
            subscriptions={data?.getExpiringSubscriptionsChart.organizationSubscriptions || 0}
            loading={loading}
          />
        </Col>
        <Col xs={24} lg={6}>
          <ExpiringSubscriptionItem
            type={GQL_ExpiringSubscriptionTypes.FacilitatorSubscriptions}
            title="Facilitators"
            subscriptions={data?.getExpiringSubscriptionsChart.facilitatorSubscriptions || 0}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default ExpiringSubscription;
