import styled from 'styled-components';
import { Col, Divider } from 'antd';
import Input from '../../../shared/Input';

export const Status = styled.span<{
  $active: boolean;
}>`
  font-size: 0.95em;
  padding: 0 11px;
  font-weight: 800;
  color: ${(props) => (props.$active ? '#50c364' : '#EA3535')};
`;

export const Title = styled.h1`
  text-align: left;
  margin-bottom: 25px;
  font-weight: 600;
`;

export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 5px;
  min-height: 60vh;
`;

export const Line = styled(Col)`
  .ant-form-item {
    margin: 0;
  }
`;

export const CDivider = styled(Divider)`
  margin: 5px 0 10px 0;
`;

export const TitleInput = styled.div`
  font-weight: 600;
  padding: 0 11px;
  font-size: 1.2em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  align-items: center;
  .ant-progress {
    flex: 1;
    margin-left: 16px;
  }
`;

export const SInput = styled(Input)<{
  $bold?: boolean;
}>`
  font-size: 0.95em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  font-weight: ${(props) => (props.$bold ? '500' : 'unset')};
  .ant-input-suffix {
    display: none;
  }
`;

export const RenewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 33px;
`;

export const RoleText = styled.span<{ color?: string }>`
  font-size: 0.95em;
  padding: 0 11px;
  font-weight: 800;
  color: ${(props) => props.color};
`;
