import { Row } from 'antd';
import React from 'react';
import { FiArrowDown, FiArrowUp, FiEdit, FiTrash2 } from 'react-icons/fi';
import {
  GQL_InvestigationBlock,
  ICanvasContentValue,
  IMediaContentValue,
  ITextContentValue,
  ITextSubmitContentValue,
  IUploadContentValue,
} from '../../../types/investigation';
import InvestigationButton from '../../StudentInvestigation/InvestigationButton';
import { InvestigationCanvas } from '../../StudentInvestigation/InvestigationCanvas';
import InvestigationImage from '../../StudentInvestigation/InvestigationImage';
import InvestigationSimulation from '../../StudentInvestigation/InvestigationSimulation';
import InvestigationMultipleChoiceQuestion from '../../StudentInvestigation/InvestigationMultipleChoiceQuestion';
import InvestigationMultipleChoicePictureQuestion from '../../StudentInvestigation/InvestigationMultipleChoicePictureQuestion';
import InvestigationText from '../../StudentInvestigation/InvestigationText';
import InvestigationTextImage from '../../StudentInvestigation/InvestigationTextImage';
import InvestigationVideo from '../../StudentInvestigation/InvestigationVideo';
import * as S from './styles';
import TextSubmit from '../../StudentInvestigation/TextSubmit';
import { QuestionBlock, AnswersContainer } from '../../StudentInvestigation/InvestigationMultipleChoiceQuestion/styles';
import Editor from '../../../shared/Editor';
import Spacer from '../../../shared/Spacer';

interface IEditableBlockBuilder {
  block: GQL_InvestigationBlock;
  selected: boolean;
  selectedContentIndex?: number;
  setSelectedBlockIndex: () => void;
  handleDeleteBlock: () => void;
  handleMoveBlockUp?: () => void;
  handleMoveBlockDown?: () => void;
}

const EditableBlockBuilder = (props: IEditableBlockBuilder) => {
  const {
    block,
    selected,
    selectedContentIndex,
    handleDeleteBlock,
    setSelectedBlockIndex,
    handleMoveBlockUp,
    handleMoveBlockDown,
  } = props;

  const getEmptyText = () => {
    switch (block.type) {
      case 'Text':
        return 'Create a new paragraph on Sidebar to start editing';
      case 'Video':
        return 'Add video url on the sidebar to preview it';
      case 'Image':
        return 'Add images url on the sidebar to preview them';
      case 'Simulation':
        return 'Add a simulation url on the sidebar to preview it';
      case 'Button':
        return 'Add a button title on the sidebar to preview it';
      default:
        return 'Preview not available to block ' + block.type;
    }
  };

  const getValuePreview = () => {
    const span = block.columns && block.type !== 'Canvas' ? 24 / block.columns : 24;

    switch (block.type) {
      case 'Text':
        return block.values.map((value, i) => (
          <S.Column span={span} key={`${selectedContentIndex}${i}`}>
            {<InvestigationText value={value as ITextContentValue} isAlreadyHtml />}
          </S.Column>
        ));
      case 'Video':
        return block.values.map((value, i) => (
          <S.Column span={span} key={`${selectedContentIndex}${i}`}>
            {<InvestigationVideo value={value as IMediaContentValue} />}
          </S.Column>
        ));
      case 'Image':
        return block.values.map((value, i) => (
          <S.Column span={span} key={`${selectedContentIndex}${i}`}>
            {<InvestigationImage value={value as IMediaContentValue} />}
          </S.Column>
        ));
      case 'Button':
        return block.values.map((value, i) => (
          <S.Column span={span} key={`${selectedContentIndex}${i}`}>
            {<InvestigationButton value={value as ITextContentValue} />}
          </S.Column>
        ));
      case 'Simulation':
        return block.values.map((value, i) => (
          <S.Column span={span} key={`${selectedContentIndex}${i}`}>
            {<InvestigationSimulation value={value as IMediaContentValue} />}
          </S.Column>
        ));
      case 'Canvas':
        return block.values.map((value, i) => (
          <S.Column span={span} key={`${selectedContentIndex}${i}`} style={{ marginTop: 16 }}>
            {<InvestigationCanvas value={value as ICanvasContentValue} />}
          </S.Column>
        ));
      case 'TextImage':
        return block.values.map((value, i) => (
          <S.Column
            span={Math.round(((value as ITextContentValue).ratio || 0.5) * 24)}
            key={`${selectedContentIndex}${i}`}
            style={{ justifyContent: 'center' }}
          >
            {<InvestigationTextImage value={value as ITextContentValue | IMediaContentValue} />}
          </S.Column>
        ));
      case 'MultipleChoiceQuestion':
        return block.values.map((value, i) => (
          <S.Column span={span} key={`${selectedContentIndex}${i}`}>
            {<InvestigationMultipleChoiceQuestion index={i} value={value as ITextContentValue} isAlreadyHtml />}
          </S.Column>
        ));
      case 'MultipleChoicePictureQuestion': {
        /* 
          The first object is the Question so I'm extracting here
          to give different treatment to answers and question.
        */
        const question = (block.values[0] as ITextContentValue).text;
        const answers = block?.values.slice(1);

        return (
          <>
            <QuestionBlock>Question</QuestionBlock>
            <Editor editable={false} listenToValue value={question} key={question} />
            <Spacer />
            <AnswersContainer>
              {answers.map((value, i) => (
                <InvestigationMultipleChoicePictureQuestion key={i} index={i} value={value as ITextContentValue} />
              ))}
            </AnswersContainer>
          </>
        );
      }
      case 'TextSubmit':
        return block.values.map((value, i) => (
          <S.Column span={span} key={`${selectedContentIndex}${i}`}>
            {<TextSubmit value={value as ITextSubmitContentValue} />}
          </S.Column>
        ));
      default:
        return <p>No preview available</p>;
    }
  };

  return (
    <S.ContentContainer selected={selected}>
      <S.BlockTitle>
        {block.type === 'Upload' ? `Upload ${(block.values?.[0] as IUploadContentValue)?.type}` : block.type} Block
      </S.BlockTitle>
      <S.ContentActionsContainer>
        <FiEdit onClick={setSelectedBlockIndex} />
        <FiTrash2 onClick={handleDeleteBlock} />
      </S.ContentActionsContainer>
      <S.ContentOrderContainer>
        {handleMoveBlockUp && <FiArrowUp onClick={handleMoveBlockUp} />}
        {handleMoveBlockDown && <FiArrowDown onClick={handleMoveBlockDown} />}
      </S.ContentOrderContainer>
      {!block.values.length ? (
        <p>{getEmptyText()}</p>
      ) : (
        <S.Column span={24}>
          <Row gutter={[15, 15]} style={{ width: '100%' }}>
            {getValuePreview()}
          </Row>
        </S.Column>
      )}
    </S.ContentContainer>
  );
};

export default EditableBlockBuilder;
