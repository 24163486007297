import { Col, message, Pagination, Result, Row, Skeleton } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import PageWithTitle from '../../shared/PageWithTitle';
import InvestigationListHeader from '../AdiInvestigationListPage/InvestigationListHeader';
import AssessmentListHeader from '../AdiAssessmentListPage/AssessmentListHeader';
import { gqlSchema } from '../../gql/schema';
import { BANNED_FILTER_WORDS } from '../../utils/string';
import { GQL_InvestigationCatalogEntry } from '../../types/investigation';
import InvestigationCard from '../../shared/InvestigationCard';
import { CardContainer } from '../../shared/InvestigationCard/styles';
import Spacer from '../../shared/Spacer';
import { useCallback } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useInvestigationFilters } from '../../hooks/useInvestigationFilters';
import { GQL_InvestigationStandard } from '../../types/investigationStandard';
import { escapeRegExp } from 'lodash';

type InvestigationFilterFields =
  | 'subject'
  | 'status'
  | 'grade'
  | 'discipline'
  | 'coreIdea'
  | 'mode'
  | 'type'
  | 'trial'
  | 'teacherFavorite'
  | 'state';

type AssessmentFilterFields =
  | 'focus'
  | 'subject'
  | 'grade'
  | 'discipline'
  | 'coreIdea'
  | 'practice'
  | 'standard'
  | 'firstPublishedAt'
  | 'crossCuttingConcept';

export enum DataType {
  INVESTIGATIONS = 'investigations',
  ASSESSMENTS = 'assessments',
}

interface Variables {
  isAssessment: boolean;
  type: string;
  grade: string;
  version?: string;
}

const PAGE_SIZE = 50;

const GradeDataReviewListPage = () => {
  const history = useHistory();
  const { version, grade, type } = useParams<{ version: string; grade: string; type: DataType }>();
  const { user } = useAuth();

  const [investigationFilters, setInvestigationFilters] = useInvestigationFilters<InvestigationFilterFields>(history);
  const [assessmentFilters, setAssessmentFilters] = useInvestigationFilters<AssessmentFilterFields>(history);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>();

  const variables = useMemo(() => {
    let response: Variables = {
      isAssessment: type === DataType.ASSESSMENTS ? true : false,
      type: 'SCIENCE_TEXAS_EDITION',
      grade: grade,
    };

    if (version.slice(1) && parseInt(version.slice(1))) {
      response['version'] = version.slice(1);
    }

    return response;
  }, [type, grade, version]);

  const { data, loading: loadingPublished } = useQuery<
    {
      getInvestigationCatalog: GQL_InvestigationCatalogEntry[];
    },
    { isAssessment: boolean; type: string; grade: string; version?: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalog, {
    variables: variables,
    onError: (err) => {
      message.error(`There was an error loading published ${type} data: ` + err.message || 'Unexpected Error');
    },
  });

  const { data: stateStandardsData } = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: 'TX',
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const publishedData = useMemo(() => {
    let localData = data?.getInvestigationCatalog || [];
    return localData.slice()?.sort((a, b) => b.createdAt - a.createdAt);
  }, [data]);

  const filteredData = useMemo(() => {
    let filteredTemplates = publishedData;

    const words = searchText?.split(' ') ?? [];

    const localFilter = type === DataType.ASSESSMENTS ? assessmentFilters : investigationFilters;

    localFilter.forEach((filter: any) => {
      filteredTemplates = filteredTemplates.filter((data) => {
        switch (filter.field) {
          case 'focus':
            return data.focus?.name === filter.value;
          case 'format':
            return data.format?.name === filter.value;
          case 'grade':
            return data.discipline?.gradeBand === filter.value;
          case 'discipline':
            return data.discipline?.name === filter.value;
          case 'coreIdea':
            return data.coreIdeas?.some((ci) => ci.code === filter.value);
          case 'status':
            return filter.value === (data.isDraft ? 'unpublished' : data.markedForDeletion ? 'deleted' : 'published');
          case 'trial':
            return filter.value === 'available' ? data.availableForTrial : !data.availableForTrial;
          case 'teacherFavorite':
            return filter.value === 'favorite' ? data.teacherFavorite : true;
          case 'subject':
            return data.discipline?.subject === filter.value;
          case 'state':
            return data.standards?.some((s) => s.id === filter.value);
          default:
            return true;
        }
      });
    });

    words
      .filter((w) => BANNED_FILTER_WORDS.indexOf(w.toLowerCase()) === -1) // remove banned words from the word list
      .map((w) => {
        // escape text to avoid regex errors
        const word = escapeRegExp(w);
        return new RegExp(word, 'i');
      }) // create a list of regular expressions from the word list
      .forEach((wordRegExp) => {
        filteredTemplates = filteredTemplates.filter((data) => {
          return (
            data.discipline?.name?.match(wordRegExp) ||
            data.title.match(wordRegExp) ||
            data.coreIdeas?.some((ci) => ci.text.match(wordRegExp)) ||
            data.crosscuttingConcepts?.some((ci) => ci.text.match(wordRegExp)) ||
            data.practices?.some((ci) => ci.text.match(wordRegExp)) ||
            data.standards?.some((ci) => ci.code.match(wordRegExp) || ci.fullStatement.match(wordRegExp))
          );
        });
      });

    setCurrentPage(1);

    return filteredTemplates;
  }, [publishedData, searchText, assessmentFilters, investigationFilters, type]);

  const getSupportedStateStandard = useCallback(
    (data: GQL_InvestigationCatalogEntry) => {
      const standards = user?.organizationAllowedStates?.length
        ? user.organizationAllowedStates
        : user?.userAllowedStates;
      const normalizedStandards = standards?.map((s) => s.postalCode) as string[];

      const baseStandards = data.nextGenerationStandards || data.standards;

      const standardsInv = baseStandards?.filter((invStandard) => normalizedStandards?.includes(invStandard.state));

      const normalizedStandardsTooltip = standardsInv?.reduce(
        (acc: { state: string; statements: string[] }[], d: GQL_InvestigationStandard) => {
          const found = acc.find((a) => a.state === d.state);
          if (!found) {
            acc.push({ state: d.state, statements: [`${d.code}: ${d.fullStatement}`] });
          } else {
            found.statements.push(`${d.code}: ${d.fullStatement}`);
          }
          return acc;
        },
        [],
      );

      return normalizedStandardsTooltip;
    },
    [user],
  );

  const skeleton = useMemo(
    () => (
      <Row gutter={[24, 24]}>
        {Array(9)
          .fill(1)
          .map((_, i) => (
            <Col lg={8} md={12} xs={24} key={i}>
              <CardContainer>
                <span role="none" >
                  <Skeleton.Input active size="small" />
                </span>
                <div>
                  <Skeleton.Avatar active />
                </div>
                <h1>
                  <Skeleton.Input active size="large" />
                </h1>
                <p>
                  <Skeleton.Input active />
                </p>
                <h1>
                  <Skeleton.Button active />
                </h1>
              </CardContainer>
            </Col>
          ))}
      </Row>
    ),
    [],
  );

  const renderCards = useCallback(
    (data: GQL_InvestigationCatalogEntry[]) => (
      <>
        <Row gutter={[24, 24]}>
          {!loadingPublished &&
            data?.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)?.map((data) => (
              <Col md={8} xs={24} sm={12} key={data.id}>
                <InvestigationCard
                  action={() => {
                    history.push(`/advanced-search/${grade}/contentType/${type}/${data.id}`);
                  }}
                  supportedStates={getSupportedStateStandard(data)}
                  investigation={data}
                  loading={loadingPublished}
                  actionText="View"
                  isAssessment={type === DataType.ASSESSMENTS}
                  isAdvancedSearch={true}
                />
              </Col>
            ))}
        </Row>
        <Spacer />
        <Row justify="center">
          <Pagination
            total={data.length}
            pageSize={PAGE_SIZE}
            onChange={setCurrentPage}
            current={currentPage}
            showSizeChanger={false}
          />
        </Row>
      </>
    ),
    [loadingPublished, currentPage, getSupportedStateStandard, type, history, grade],
  );

  const notFound = useMemo(() => <Result status="warning" subTitle={`There are no ${type} listed.`} />, [type]);

  const dataList = useCallback(
    (data: GQL_InvestigationCatalogEntry[]) =>
      loadingPublished ? skeleton : data.length > 0 ? renderCards(data) : notFound,
    [loadingPublished, notFound, renderCards, skeleton],
  );

  const page = useMemo(() => {
    return (
      <PageWithTitle
        title={
          type === DataType.ASSESSMENTS ? (
            <AssessmentListHeader
              refreshMethod={() => {}}
              filters={assessmentFilters}
              setFilters={setAssessmentFilters}
              updateSearchText={setSearchText}
              stateStandards={stateStandardsData?.getStateStandards}
            />
          ) : (
            <InvestigationListHeader
              refreshMethod={() => {}}
              filters={investigationFilters}
              setFilters={setInvestigationFilters}
              updateSearchText={setSearchText}
              stateStandards={stateStandardsData?.getStateStandards}
            />
          )
        }
      >
        {dataList(filteredData)}
      </PageWithTitle>
    );
  }, [
    type,
    filteredData,
    assessmentFilters,
    investigationFilters,
    stateStandardsData,
    dataList,
    setAssessmentFilters,
    setInvestigationFilters,
  ]);

  return page;
};

export default GradeDataReviewListPage;
