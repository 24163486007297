import { gql } from '@apollo/client';

export const CLASSLINK_MUTATIONS = {
  ROSTER: {
    syncClasslinkRoster: gql`
      mutation syncClasslinkRoster($orgId: String) {
        syncClasslinkRoster(orgId: $orgId)
      }
    `,
    syncClasslinkClasses: gql`
      mutation syncClasslinkClasses {
        syncClasslinkClasses
      }
    `,
    syncClasslinkClassesV2: gql`
      mutation syncClasslinkClassesV2 {
        syncClasslinkClassesV2
      }
    `,
  },
};

export default CLASSLINK_MUTATIONS;
