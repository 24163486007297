import React, { useState, useEffect } from 'react';
import { Modal, Progress, Button, Input, message, Spin } from 'antd';
import { useMutation, useQuery, ApolloError  } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { GQL_StudentSubmission } from '../../../../types/googleClassroom';
import { getProgressColor } from '../../../../utils/theme';

interface GradePopupProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  studentName: string;
  className: string;
  section: string;
  submissionData: GQL_StudentSubmission;
  assignmentName: string;
  assignmentLinks: string[];
  progress: number;
  courseId: string;
  courseWorkId: string;
  updateGradedSubmission: (submissionId: string, gradedScore: number) => void;
  submission: string; // Add this line
  refetch: () => void;
}

const GradePopup: React.FC<GradePopupProps> = ({
  visible,
  setVisible,
  studentName,
  className,
  section,
  submissionData,
  assignmentName,
  assignmentLinks,
  progress,
  courseId,
  courseWorkId,
  updateGradedSubmission,
  submission, // Add this line
  refetch
}) => {
  const [score, setScore] = useState<string>('');
  const [maxPoints, setMaxPoints] = useState<string>('');
  const [percentage, setPercentage] = useState<number>(0);
  const [grades, setGrades] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);

  // Fetch grades data
  const { loading: gradesLoading, data: gradesData } = useQuery<{
    getCourseWorkSubmissionList: Array<{
      courseId: string;
      courseWorkId: string;
      studentSubmissions: Array<GQL_StudentSubmission>;
    }>;
  }>(gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionList, {
    variables: {
      data: [{ courseId, courseWorkId }], // Adjusted according to new query structure
    },
    onError: () => {
      message.error('Error fetching grades data');
    },
    onCompleted: (data) => {
      const gradedScores: { [key: string]: string } = {};
      data?.getCourseWorkSubmissionList.forEach((item) => {
        item.studentSubmissions.forEach((submission) => {
          gradedScores[submission.id] = (submission.draftGrade || '').toString(); // Convert to string
        });
      });
      setGrades(gradedScores);
    },
  });

  useEffect(() => {
    
    
    // Update maxPoints from submissionData when it changes
    if (submissionData && submissionData.submissionHistory) {
      const foundHistory = submissionData.submissionHistory.find(history => history.gradeHistory);
      if (foundHistory) {
        setMaxPoints(foundHistory.gradeHistory.maxPoints.toString());
      }
    }
  }, [submissionData]);


    // Fetch course work data to get maxPoints
    const { loading: courseWorkLoading, data: courseWorkData } = useQuery(
      gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
      {
        variables: {
          courseId,
          id: courseWorkId,
        },
        onError: () => {
          message.error('Error fetching course work data');
        },
        onCompleted: (data) => {
          setMaxPoints(data.getCourseWorkById.maxPoints.toString());
        },
      }
    );
  
    useEffect(() => {
      console.log('Submission Data:', submissionData);
      console.log('Grades Data:', gradesData);
      console.log('Course Work Data:', courseWorkData);
    }, [submissionData, gradesData, courseWorkData]);

    // Fetch individual submission data
    const { loading: submissionLoading, data: submissionDataById } = useQuery(
      gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionById,
      {
        variables: {
          courseId,
          courseWorkId,
          id: submissionData.id,
        },
        onError: () => {
          message.error('Error fetching submission data');
        },
      }
    );
  
    useEffect(() => {
      console.log('Submission Data:', submissionData);
      console.log('Grades Data:', gradesData);
      console.log('Course Work Data:', courseWorkData);
      console.log('Submission Data By ID:', submissionDataById);
      
      // Update maxPoints from submissionData when it changes
      if (submissionData && submissionData.submissionHistory) {
        const foundHistory = submissionData.submissionHistory.find(history => history.gradeHistory);
        if (foundHistory) {
          setMaxPoints(foundHistory.gradeHistory.maxPoints.toString());
        }
      }
    }, [submissionData, gradesData, courseWorkData, submissionDataById]);
  

  // Mutation to grade a submission
  const [gradeSubmission] = useMutation(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.GradeCourseWorkStudentSubmission,
    {
      onCompleted: (data) => {
        console.log('refetch started');
        refetch();
        const { draftGrade, id } = data.gradeAttachmentCourseWorkStudentSubmission;
        const updatedGrades = { ...grades, [id]: draftGrade.toString() };
        setGrades(updatedGrades);
        updateGradedSubmission(id, draftGrade);
        message.success('Submission graded successfully');
        setLoading(false);
        setVisible(false);
      },
      onError: (error: ApolloError) => {
        setLoading(false); // Stop loading in case of error
        message.success('Submission graded successfully'); 
        console.error('Error grading submission:', error);
      },
    }
  );

  // Update score state when input changes
  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScore(e.target.value);
    // Calculate percentage when score changes
    calculatePercentage(e.target.value, maxPoints);
  };

  // Update percentage when score or maxPoints changes
  useEffect(() => {
    calculatePercentage(score, maxPoints);
  }, [score, maxPoints]);

  const handleSubmission = () => {
    if (score.trim() === '') {
      message.error('Please provide a valid score');
      return;
    }

    const numerator = parseFloat(score);
    const denominator = parseFloat(maxPoints);


    if (isNaN(numerator) || isNaN(denominator)) {
      message.error('Invalid score or max points');
      return;
    }

    setLoading(true);
    setVisible(false);

    // const assignedGrade = Math.floor((numerator / denominator) * 100); // Calculate assignedGrade

    const data = {
      draftGrade: numerator,
      assignedGrade:numerator,
    };

    gradeSubmission({
      variables: {
        courseId,
        courseWorkId,
        id: submissionData.id,
        data,
      },
    });
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Set score if it's already graded
    if (grades[submissionData.id]) {
      setScore(grades[submissionData.id]);
    } else {
      // If score is not available, set it to an empty string
      setScore('');
    }
  }, [grades, submissionData.id]);

  const calculatePercentage = (score: string, maxPoints: string) => {
    const numerator = parseFloat(score);
    const maxPointsValue = parseFloat(maxPoints); // Use maxPointsValue here
    if (!isNaN(numerator) && !isNaN(maxPointsValue) && maxPointsValue !== 0) {
      let calculatedPercentage = (numerator / maxPointsValue) * 100;
      calculatedPercentage = Math.floor(calculatedPercentage); // Round down to nearest whole number
      setPercentage(calculatedPercentage); // Update percentage state
    } else {
      setPercentage(0); // Set percentage to 0 if score or maxPoints are invalid
    }
  };

  // let progressColor = '#f5222d';
  // if (percentage >= 80) {
  //   progressColor = '#52c41a';
  // } else if (percentage >= 50) {
  //   progressColor = '#faad14';
  // }

  const centeredLoaderStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1000,
  };
  
  

  if (gradesLoading || courseWorkLoading || submissionLoading || loading) {
    return (
      <div style={centeredLoaderStyle}>
        <Spin size="large" />
      </div>
    );
  }

  // Integrate gradesData into the component
  console.log(gradesData); // You can use gradesData here if needed
  
  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>Submission Summary</div>}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={600}
      bodyStyle={{ padding:isMobile? '30px': '30px 50px' }}
    >
      <div style={{ display: isMobile? 'block':  'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '20px', display: isMobile? 'block':  'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '60px', width: '180px',marginBottom:isMobile? '10px':'unset', }}>
            <b>Student Name:</b>
            <br />
            {studentName}
          </div>
          <div style={{ width: '180px', marginRight: '60px',marginBottom:isMobile? '10px':'unset', }}>
            <b>Submission:</b>
            <br />
            {submissionData.state === 'TURNED_IN' ? 'Graded' : 'Not Graded'}
          </div>
          <div style={{ width: '180px' }}>
            <b>Section:</b>
            <br />
            {section}
          </div>
        </div>
        <div style={{ marginBottom: '20px', display: isMobile? 'block':  'flex',alignItems: 'center' }}>
          <div style={{ marginRight: '60px', width: '180px', marginBottom:isMobile? '10px':'unset', }}>
            <b>Class Name:</b>
            <br />
            {className}
          </div>
          <div style={{ marginLeft: isMobile? '0px':'-60px',marginBottom:isMobile? '10px':'unset', }}>
            <b>Assignment Name:</b>
            <br />
            {assignmentName}
          </div>
          <div style={{ width: '180px', opacity: 0 }}>Placeholder</div>
        </div>
        <div style={{ marginBottom: '20px', marginTop: isMobile? '-30px' : 'unset' }}>
          <b>Teacher Assignment Link:</b>
        <br />
        {assignmentLinks.length > 0 ? (
         assignmentLinks.map((link, index) => (
        <div key={index}>
        <a href={link}>{link}</a>
        </div>
          ))
          ) : (
          'No links available'
          )}
        </div>
        <div style={{ marginTop: '20px' }}>
          <b>Student Assignment Link:</b>
        <br />
        {submissionDataById?.getCourseWorkSubmissionById?.assignmentSubmission?.attachments?.length > 0 ? (
          submissionDataById.getCourseWorkSubmissionById.assignmentSubmission.attachments.map(
         (attachment: { link: { url: string } }, index: number) => (
        <div key={index}>
          <a href={attachment.link.url}>{attachment.link.url}</a>
        </div>
      )
    )
  ) : (
    'No link available'
  )}
</div>

        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <b>Score:</b>{' '}
          <Input
            value={score}
            onChange={handleScoreChange}
            style={{ width: '70px', marginRight: '5px' }}
          />
          / {maxPoints}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Progress
            type="circle"
            percent={percentage}
            strokeWidth={9}
            strokeColor={getProgressColor(percentage || 0)}
            width={150}
            format={() => `${percentage}%`}
            // strokeColor={progressColor}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Button style={{ marginRight: '20px' }} onClick={() => setVisible(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmission}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default GradePopup;
