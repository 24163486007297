import React from 'react';
import { Row, Col, Empty } from 'antd';
import startCase from 'lodash/startCase';
import DashboardRow from '../../../shared/DashboardRow';
import Item from './Item';
import { formatDateTime } from '../../../utils/date';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg'; // Import the AssignmentIcon

interface IFinishedAssignments {
  assignments: any[];
  loading: boolean;
  courseId: string;
  courseData: any; // Assuming this is where you have course information including name
}

const FinishedAssignments: React.FC<IFinishedAssignments> = ({ assignments, loading, courseId, courseData }) => {
  const renderAssignments = () => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          {[1, 2, 3].map((index) => (
            <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      );
    } else if (!assignments?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Finished Assignments" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {assignments.slice(0, 99).map((item: any, index) => (
            <Col key={`${item.id}-${index}`} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <Item
                date={formatDateTime(item.creationTime)}
                assignmentName={`${startCase(item.title?.toLowerCase())}`}
                assignmentId={item.id}
                courseId={courseId}
                courseWorkId={item.courseWorkId}
                className={<strong>{courseData?.getCourseByCourseId.name}</strong>} // Display class name here
                courseName={courseId}
                icon={<img src={AssignmentIcon} alt="Assignment Icon" style={{ width: 24, height: 24 }} />} // Pass the AssignmentIcon here
                courseWorkType={item.courseWorkType} 
              />
            </Col>
          ))}
        </Row>
      );
    }
  };

  return <DashboardRow title="Finished Assignments" items={renderAssignments()} />;
};

export default FinishedAssignments;
