import { createReducer, createSagaAction } from '../../utils/redux';

// Action Types
export const constants = {
  PEER_COMMENT_LOAD: createSagaAction('PEERCOMMENT_LOAD'),
  PEER_COMMENT_SUBMIT: createSagaAction('PEERCOMMENT_SUBMIT'),
};

// Action Creators
export const actions = {
  peerCommentLoad: () => ({
    type: constants.PEER_COMMENT_LOAD.ACTION,
  }),
  peerCommentSubmit: (payload: { text: string }) => ({
    type: constants.PEER_COMMENT_SUBMIT.ACTION,
    payload,
  }),
};

interface IPeerComment {
  text: string;
  author?: string;
  date: string;
}

export interface IPeerCommentStore {
  activityId: number;
  comments: Array<IPeerComment>;
}

// Reducer
const initialState: IPeerCommentStore = {
  activityId: 0,
  comments: [],
};

export default createReducer(initialState, (state: IPeerCommentStore, action) => {
  switch (action.type) {
    case constants.PEER_COMMENT_LOAD.SUCCESS:
      return { ...state, ...action.payload };
    case constants.PEER_COMMENT_SUBMIT.SUCCESS:
      return {
        ...state,
        comments: [...state.comments, action.payload],
      };
    default:
      return state;
  }
});
