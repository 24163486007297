import React from 'react';

import { FiCheck } from 'react-icons/fi';
import Sider from '../Sider';
import * as S from './styles';
import { GQL_InvestigationStep } from '../../types/investigation';

import Steps, { Step } from '../InvestigationSteps';

interface IInvestigationSider {
  title: string;
  stepIndex?: number;
  activityId?: string;
  stepsLength?: number;
  highlighted?: boolean;
  setCurrentActivity?: (payload?: { stepIndex?: number; activityIndex?: number }) => void;
  resetContentIndex?: () => void;
  stepList?: GQL_InvestigationStep[];
  isAssessment?: boolean;
}

const InvestigationSider = (props: IInvestigationSider) => {
  const {
    title,
    stepIndex = 0,
    activityId,
    highlighted,
    stepsLength,
    stepList = [],
    setCurrentActivity = () => {},
    resetContentIndex = () => {},
    isAssessment,
  } = props;

  const findLastCompletedStepIndex = stepList
    .slice()
    .reverse()
    .findIndex((step) => step.completed);
  const lastCompletedStepIndex = findLastCompletedStepIndex === -1 ? 0 : stepList.length - findLastCompletedStepIndex;

  let lastNoPreRequisiteStepIndex = lastCompletedStepIndex;
  for (let i = lastCompletedStepIndex + 1; i < stepList.length; i++) {
    if (stepList[i]?.requiresPreviousStep) break;
    else lastNoPreRequisiteStepIndex = i;
  }

  const slicedStepList = stepList.slice(0, Math.max(lastNoPreRequisiteStepIndex, stepIndex) + 1);

  const descriptionBuilder = () => {
    return (
      <S.SubStepsContainer>
        {slicedStepList[stepIndex]?.activities?.map((activity, i, arr) => {
          const canClick = arr.slice(i > 0 ? i - 1 : i).some((a) => a.completed);

          return (
            <S.SubStepText
              key={activity.id}
              canClick={canClick}
              onClick={() => {
                if (canClick) {
                  setCurrentActivity({ activityIndex: i, stepIndex });
                  resetContentIndex();
                }
              }}
              done={activity.completed}
              bold={activity.id === activityId}
            >
              - {activity.name}
              {activity.completed && <FiCheck data-cy="shared-investigationsider-substeptext-check" />}
            </S.SubStepText>
          );
        })}
      </S.SubStepsContainer>
    );
  };

  return (
    <Sider title={title} highlighted={highlighted}>
      <Steps
        current={stepIndex}
        onChange={(current) => {
          setCurrentActivity({ stepIndex: current });
          resetContentIndex();
        }}
        finished={stepIndex === stepsLength}
      >
        {slicedStepList?.map((step, i) => (
          <Step
            title={isAssessment ? 'Assessment' : step.name}
            description={i === stepIndex ? descriptionBuilder() : <br />}
            key={step.name}
            status={step.completed ? 'finish' : 'process'}
          />
        ))}
      </Steps>
    </Sider>
  );
};

export default InvestigationSider;
