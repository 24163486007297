import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Row, Col, Button, Input, message, Spin } from 'antd'; // Import Spin for the loading indicator
import { StyledModal, Title, ButtonWrapper } from './styles';
import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';

interface GJoinClassProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const GJoinClass: React.FC<GJoinClassProps> = ({ visible, setVisible }) => {
  const [classCode, setClassCode] = useState<string>('');
  const [courseId, setCourseId] = useState<string>('');
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false); // State for loading indicator

  const [addStudentCourse, { loading: addClassLoading }] = useMutation(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.AddStudentCourse,
    {
      onError: (error) => {
        setLoading(false); // Turn off loading indicator on error
        message.error(`Failed to join class: ${error.message}`);
      },
    }
  );

  const closeModal = () => {
    setVisible(false);
  };

  const handleJoin = async () => {
    if (!classCode.trim() || !courseId.trim()) {
      message.error('Class code and course ID cannot be empty');
      return;
    }

    setLoading(true); // Show loading indicator
    try {
      await addStudentCourse({
        variables: {
          data: {
            courseId,
            userId: user?.email
          },
          code: classCode,
        },
      });
      message.success('Successfully joined the class');
      closeModal();
    } catch (error: any) {
      message.error(`Failed to join class: ${error.message}`);
    } finally {
      setLoading(false); // Hide loading indicator after mutation completes
    }
  };

  return (
    <StyledModal
      visible={visible}
      maskClosable={false}
      destroyOnClose
      onCancel={closeModal}
      footer={null}
      width={600}
    >
      <Row justify="center">
        <Col span={24}>
          <Title>Join Class</Title>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '24px' }}>
        <Col span={24}>
          <h3>Class Code</h3>
          <p>Ask your teacher for the class code, then enter here:</p>
          <Input type="text" value={classCode} onChange={(e) => setClassCode(e.target.value)} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <h3>Class ID</h3>
          <p>Ask your teacher for the class ID, then enter here:</p>
          <Input type="text" value={courseId} onChange={(e) => setCourseId(e.target.value)} />
        </Col>
      </Row>
      <ButtonWrapper>
        <Button onClick={closeModal} disabled={addClassLoading}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleJoin} loading={loading}>
          Join
        </Button>
      </ButtonWrapper>
      {/* Render Spin (loading indicator) centered */}
      {loading && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Spin size="large" />
        </div>
      )}
    </StyledModal>
  );
};

export default GJoinClass;
