import styled from 'styled-components';

import { Modal } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';

export const Title = styled.h1`
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  margin-top: 24px;
`;
export const Info = styled.p`
  font-weight: 400;
  font-size: 0.82em;
  margin: 0;
  text-align: center;
`;

export const BackButton = styled(FiArrowLeft)`
  position: absolute;
  cursor: pointer;
`;

export const CModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 5px;
    max-height: 75vh;
    overflow-y: auto;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;
