import React, { useCallback, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import StudentsPerformanceLegends from '../StudentsPerformanceLegends';
import { GQL_ComparisonByIdResponse } from '../../../types/gradeBooking';
import { studentPerformanceLevels } from '../../../types/studentPerformanceLevels';
import * as S from './styles';

interface IStudentsPerformanceBarMultiple {
  data: GQL_ComparisonByIdResponse[] | undefined;
  loading: boolean;
}

const StudentsPerformanceBarMultiple = (props: IStudentsPerformanceBarMultiple) => {
  const { data: propsData, loading = false } = props;

  const graphData = useCallback((propsData: GQL_ComparisonByIdResponse[]): GQL_ComparisonByIdResponse[] => {
    let resultData: any = {};

    // Use main assessments to create obj index and base object
    propsData
      ?.filter((ass) => ass.submissionVersion === 1)
      .forEach((ass: GQL_ComparisonByIdResponse) => {
        if (!resultData[ass.assessmentId]) {
          resultData[ass.assessmentId] = {
            ...ass,
            items: [
              {
                ...ass.items[0],
                aboveBarText: 'T1',
              },
            ],
          };
        }
      });

    // Use child assessments to push their `items` data into "father" assessment object
    propsData
      ?.filter((ass) => ass.submissionVersion && ass.submissionVersion > 1)
      .forEach((ass: GQL_ComparisonByIdResponse, key) => {
        if (resultData[ass.assessmentId]) {
          ass = {
            ...ass,
            items: [
              {
                ...ass.items[0],
                index: key + 2,
                aboveBarText: `T${key + 2}`,
              },
            ],
          };
          resultData[ass.assessmentId] = {
            ...resultData[ass.assessmentId],
            items: [...resultData[ass.assessmentId].items, ass.items[0]],
          };
        }
      });

    return Object.values(resultData);
  }, []);

  const data = useMemo(() => {
    if (propsData) {
      return graphData(propsData);
    }

    return [];
  }, [propsData, graphData]);

  return (
    <S.StudentsPerformanceBarContainer>
      <p>Percentage of Students at Each Performance Level</p>
      <p>{loading}</p>

      <S.GraphsContainer>
        {data.map((currData, index) => (
          <S.GraphContainer key={index}>
            <ResponsiveBar
              data={currData.items as any}
              layout="vertical"
              keys={Object.keys(studentPerformanceLevels)}
              colors={Object.values(studentPerformanceLevels).map((level) => level.color)}
              indexBy="index"
              enableGridY={false}
              enableGridX={false}
              tooltipLabel={(data) => studentPerformanceLevels[data.id as keyof typeof studentPerformanceLevels].name}
              axisTop={{
                format: (value) => {
                  const currentBarItem = data[index].items.find((item) => item.index === value);
                  return currentBarItem?.aboveBarText ?? '';
                },
                tickSize: 0,
                tickPadding: 12,
              }}
              labelTextColor={'#FFFFFF'}
              axisBottom={{
                ariaHidden: true,
                renderTick: () => <></>,
              }}
              axisLeft={{
                tickValues: 2,
                format: (value) => `${value}%`,
              }}
              padding={0.3}
              margin={{ left: 50, right: 50, top: 24, bottom: 8 }}
              label={(data) => (data.value ? `${data.value}%` : '')}
              theme={{
                fontSize: 16,
                labels: {
                  text: {
                    fontWeight: 600,
                  },
                },
              }}
            />

            <S.GraphLabel>{currData.typeTitle}</S.GraphLabel>
          </S.GraphContainer>
        ))}
      </S.GraphsContainer>

      <StudentsPerformanceLegends />
    </S.StudentsPerformanceBarContainer>
  );
};

export default StudentsPerformanceBarMultiple;
