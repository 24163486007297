import { Col, Empty, message, Row, Spin, Tabs, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as S from './styles';
import { GQL_SortingInput, SortOptions } from '../../../types/sorting';
import {
  GQL_InvAssignmentTrendResponse,
  GQL_InvAssignmentTrendInput,
  GQL_InvAssignmentChartResponse,
  GQL_InvAssignmentTrendChartInput,
  BarAssignmentTrend,
  GQL_TrendFilters,
  OrderField,
  GQL_InvAssignmentTrendTeacher,
} from '../../../types/charts';
import { Duration, FilterData } from '../../../types/investigation';
import FilterDoubleDates from '../FilterDoubleDates';
import { FiHelpCircle } from 'react-icons/fi';
import { formatDateTime } from '../../../utils/date';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { ResponsiveBar } from '@nivo/bar';
import TrendTable from './TrendTable';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../Button';
import { downloadCsv } from '../../../utils/files';
import Papa from 'papaparse';
import { palette } from '../../../utils/theme';

interface Props {
  title: string;
  workshopOnly?: boolean;
}

const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};
const { TabPane } = Tabs;

const InvestigationUseTrend = (props: Props) => {
  const [sorting1, setSorting1] = useState<GQL_SortingInput>({ field: 'rateDiff', order: SortOptions.ASC });
  const [chartData, setChartData] = useState<BarAssignmentTrend[]>([]);
  const [period1InvestigationAssignedRate, setPeriod1InvestigationAssignedRate] = useState<Duration>(defaultDateRange);
  const [period2InvestigationAssignedRate, setPeriod2InvestigationAssignedRate] = useState<Duration>(defaultDateRange);
  const [activeKey, setActiveKey] = useState('graph');
  const { isOrganizationAdiAdmin } = useAuth();

  const [filterData, setFilterData] = useState<FilterData>({ organizations: false, teachers: false });

  const { title, workshopOnly = false } = props;

  const buildChartData = useCallback((data: GQL_InvAssignmentChartResponse) => {
    const newData: BarAssignmentTrend[] = [];

    newData.push({
      status: 'Stopped Usage',
      quantity: data.stoppedUsing,
      color: palette[0],
      type: GQL_TrendFilters.StoppedUsing,
    });

    newData.push({
      status: 'Decrease In Usage',
      quantity: data.decreaseInUsage,
      color: palette[1],
      type: GQL_TrendFilters.DecreaseInUsage,
    });

    newData.push({
      status: 'Small Usage Change',
      quantity: data.smallToNoUsageChange,
      color: palette[2],
      type: GQL_TrendFilters.SmallToNoUsageChange,
    });

    newData.push({
      status: 'No Usage',
      quantity: data.stoppedUsing,
      color: palette[5],
      type: GQL_TrendFilters.DidNotUse,
    });

    newData.push({
      status: 'Started Usage',
      quantity: data.startedUsing,
      color: palette[3],
      type: GQL_TrendFilters.StartedUsing,
    });

    newData.push({
      status: 'Increase in Usage',
      quantity: data.increaseInUsage,
      color: palette[4],
      type: GQL_TrendFilters.IncreaseInUsage,
    });

    setChartData(newData);
  }, []);

  const [getInvestigationAssignmentTrendChart, { loading: loadingChart }] = useLazyQuery<
    { getInvestigationAssignmentTrendChart: GQL_InvAssignmentChartResponse },
    { data: GQL_InvAssignmentTrendChartInput }
  >(gqlSchema.ChartSchema.queries.CHARTS.getInvestigationAssignmentTrendChart, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      buildChartData(data.getInvestigationAssignmentTrendChart);
    },
    onError: (err) => {
      message.error('There was an error loading investigation assignment trend: ' + err.message || 'Unexpected Error');
    },
  });

  const [getInvestigationAssignmentTrendTable, { data: dataTable, loading: loadingTable }] = useLazyQuery<
    { getInvestigationAssignmentTrendTable: GQL_InvAssignmentTrendResponse },
    { data: GQL_InvAssignmentTrendInput }
  >(gqlSchema.ChartSchema.queries.CHARTS.getInvestigationAssignmentTrendTable, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      message.error('There was an error loading investigation assignment trend: ' + err.message || 'Unexpected Error');
    },
  });

  const fetchTable = useCallback(
    (bar?: { data: BarAssignmentTrend }) => {
      if (filterData.teachers || filterData.organizationId || isOrganizationAdiAdmin) {
        const filter: GQL_InvAssignmentTrendInput = {
          firstStartDate: period1InvestigationAssignedRate.startDate,
          firstEndDate: period1InvestigationAssignedRate.endDate,
          secondStartDate: period2InvestigationAssignedRate.startDate,
          secondEndDate: period2InvestigationAssignedRate.endDate,
          organizations: filterData.organizations,
          organizationId: filterData.organizationId,
          teachers: isOrganizationAdiAdmin ? true : filterData.teachers,
          tagIds: filterData.tagIds || [],
          workshopOnly,
        };
        if (bar) filter.filter = bar.data.type;
        if (sorting1) {
          filter.orderField = sorting1.field as OrderField | undefined;
          filter.order = sorting1.order;
        }
        getInvestigationAssignmentTrendTable({
          variables: {
            data: filter,
          },
        });
      }
    },
    [
      getInvestigationAssignmentTrendTable,
      period1InvestigationAssignedRate,
      period2InvestigationAssignedRate,
      filterData,
      workshopOnly,
      isOrganizationAdiAdmin,
      sorting1,
    ],
  );

  const fetchChart = useCallback(() => {
    if (filterData.teachers || filterData.organizationId || isOrganizationAdiAdmin) {
      const data: GQL_InvAssignmentTrendChartInput = {
        firstStartDate: period1InvestigationAssignedRate.startDate,
        firstEndDate: period1InvestigationAssignedRate.endDate,
        secondStartDate: period2InvestigationAssignedRate.startDate,
        secondEndDate: period2InvestigationAssignedRate.endDate,
        organizations: filterData.organizations,
        organizationId: filterData.organizationId,
        teachers: isOrganizationAdiAdmin ? true : filterData.teachers,
        tagIds: filterData.tagIds || [],
        workshopOnly,
      };
      getInvestigationAssignmentTrendChart({
        variables: {
          data,
        },
      });
    }
  }, [
    getInvestigationAssignmentTrendChart,
    period1InvestigationAssignedRate,
    period2InvestigationAssignedRate,
    filterData,
    workshopOnly,
    isOrganizationAdiAdmin,
  ]);

  useEffect(() => {
    fetchChart();
    fetchTable();
  }, [fetchChart, fetchTable]);

  const onChangeFilterData = useCallback((data: any) => {
    setChartData([]);
    setFilterData(data);
  }, []);

  const emptyFilter = useMemo(
    () => (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          {isOrganizationAdiAdmin ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <S.TitleError>Select an Organization or a Freelance Teacher</S.TitleError>
          )}
        </Col>
      </Row>
    ),
    [isOrganizationAdiAdmin],
  );

  const emptyResult = useMemo(
    () => (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
      </Row>
    ),
    [],
  );

  const loadingChartSpinner = useMemo(
    () => (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={2}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    [],
  );

  const erroMessage = useMemo(() => {
    if (loadingChart) return loadingChartSpinner;
    return filterData.teachers || filterData.organizationId ? emptyResult : emptyFilter;
  }, [emptyFilter, emptyResult, filterData, loadingChart, loadingChartSpinner]);

  const downloadDetails = useCallback(() => {
    const headers = [
      [
        'First Name',
        'Last Name',
        'Email',
        'No. of assignments 1',
        'No. of assignments 2',
        'Change (Number)',
        'Change (Percentage)',
      ],
    ];
    const csvData =
      dataTable?.getInvestigationAssignmentTrendTable.trends.map((row: GQL_InvAssignmentTrendTeacher) => {
        const change = parseFloat((((row.rateOnSecondPeriod || 1) * 100) / (row.rateOnFirstPeriod || 1)).toFixed(2));

        return [
          row.teacherFirstName,
          row.teacherLastName,
          row.teacherEmail,
          row.rateOnFirstPeriod,
          row.rateOnSecondPeriod,
          row.rateDiff,
          `${
            row.rateOnFirstPeriod === row.rateOnSecondPeriod
              ? ''
              : row.rateOnFirstPeriod < row.rateOnSecondPeriod
              ? '+'
              : '-'
          }${row.rateDiff === 0 ? 0 : change}%`,
        ];
      }) || [];
    downloadCsv(
      Papa.unparse([...headers, ...csvData]),
      `${workshopOnly ? 'Workshop' : 'Investigation'} Assignment Trend.csv`,
    );
  }, [dataTable, workshopOnly]);

  const existData = useMemo(() => chartData.length > 0 && chartData.reduce((r, c) => (r += c.quantity), 0), [
    chartData,
  ]);
  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 20 }}>
          <S.Title>{title}</S.Title>
          <Tooltip title="Compare the number of teachers who assigned an investigation between two date ranges.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <FilterDoubleDates
            setDaterange1={setPeriod1InvestigationAssignedRate}
            setDaterange2={setPeriod2InvestigationAssignedRate}
            onChangeFilterData={onChangeFilterData}
            hideTeacherFilter
            workshopOnly={workshopOnly}
          />
        </Col>
        <Col span={24}>
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={(activeKey: string) => setActiveKey(activeKey)}
            tabBarExtraContent={
              activeKey === 'table' ? <Button text="Download Details" onClick={downloadDetails} /> : null
            }
          >
            <TabPane tab={<S.TitleTab>Chart</S.TitleTab>} key="graph">
              <Row>
                <Col span={24}>
                  <S.Card $height={existData ? '55vh' : '25vh'}>
                    {existData ? (
                      <ResponsiveBar
                        data={chartData}
                        keys={['quantity']}
                        indexBy="status"
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        valueFormat=">-.1~r"
                        indexScale={{ type: 'band', round: true }}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 1.6]],
                        }}
                        colors={(d) => d.data.color}
                        onClick={(data) => {
                          fetchTable(data);
                          setActiveKey('table');
                        }}
                        axisBottom={{
                          tickSize: 6,
                          tickPadding: 0,
                          tickRotation: 0,
                          legend: 'Click on a bar to see details',
                          legendPosition: 'middle',
                          legendOffset: 38,
                        }}
                        axisLeft={{
                          tickSize: 6,
                          tickPadding: 0,
                          tickRotation: 0,
                          format: (e) => Math.floor(e) === e && e,
                          legend: 'No. Teachers',
                          legendPosition: 'middle',
                          legendOffset: -50,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={'#ffffff'}
                      />
                    ) : (
                      erroMessage
                    )}
                  </S.Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={<S.TitleTab>Details</S.TitleTab>} key="table">
              <TrendTable
                periodInvestigationAssignedRate={period1InvestigationAssignedRate}
                setSorting={setSorting1}
                loading={loadingTable}
                data={dataTable?.getInvestigationAssignmentTrendTable}
                title={title}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default InvestigationUseTrend;
