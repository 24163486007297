import { FiHelpCircle } from 'react-icons/fi';
import styled from 'styled-components';
import { themeConfig } from '../../utils/theme';

export const TitleInput = styled.div`
  font-weight: 600;
  padding: 0 11px;
  font-size: 1.2em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  align-items: center;
  .ant-progress {
    flex: 1;
    margin-left: 16px;
  }
`;

export const BetaHelpButton = styled(FiHelpCircle)`
  cursor: pointer;
  color: ${themeConfig.primaryColor.background};
  margin-left: 8px;
`;
