// styles.ts
import styled from 'styled-components';
import CButton from '../../../../shared/Button';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5em;
  border-radius: 4px;
  background: #ffffff; /* White background */
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  position: relative;
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 4px 8px;
  border-radius: 16px;
  white-space: nowrap; /* Prevent text from wrapping */
   @media (max-width: 768px) {
      top: 0px;
        position: relative;
    }
`;


export const DashboardButton = styled(CButton)`
  font-weight: 500;
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const InfoProgress = styled.p`
  font-size: 0.45em;
  margin: 10px 0 0 0;
`;

export const Title = styled.h3<{
  $align?: string;
}>`
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  font-weight: 500;
  margin-bottom: 0;
`;

export const SummarydButton = styled(CButton)`
  font-weight: 500;
`;

export const AssignmentLabel = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
`;

export const ClassCourseInfo = styled.div`
  text-align: left;
  margin-top: 1em;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e0f7fa; /* Light blue background color */
  img {
    width: 60%; /* Adjust size of the image */
    height: 60%; /* Adjust size of the image */
    object-fit: cover;
  }
`;

export const AssignmentName = styled.h4`
  font-weight: normal;
  font-size: 16px;
  margin-top: 8px;
  color: #333;
  text-align: left;
`;
