import { gql } from '@apollo/client';

// Created before so we can use multiple times on same object
const COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    id
    createdAt
    text
    userId
    avatar
    activityId
    author
    flags {
      count
      flagged
    }
    likes {
      count
      liked
    }
  }
`;

const CONTENT_FRAGMENT = gql`
  fragment ContentFragment on InvestigationActivityContent {
    title
    blocks {
      type
      columns
      values {
        ... on CBValueText {
          type
          text
          ratio
          optionLabel
          targetAnswer
          selectedAnswer
          fileName
          url
          alt    
          spans {
            start
            end
            type
            url
            meta
          }
        }
        ... on CBValueTextImage {
          type
          text
          ratio
          url
          alt
          size
          spans {
            start
            end
            type
            url
            meta
          }
        }
        ... on CBValueList {
          text
          spans {
            start
            end
            type
          }
        }
        ... on CBValueVideo {
          url
        }
        ... on CBValueSimulation {
          url
        }
        ... on CBValueButton {
          url
          text
          color
        }
        ... on CBValueImage {
          url
          alt
          size
        }
        ... on CBValueUpload {
          type
          url
          MIMEtype
          hidden
        }
        ... on CBValueUploadFinalReport {
          type
          url
          MIMEtype
          hidden
        }
        ... on CBValuePeerReview {
          studentId
          stepId
          completedAt
          readyToReview
        }
        ... on CBValueComment {
          label
        }
        ... on CBValueReflection {
          peerReviewId
          rating
          reflection
          reflectionCompletedAt
          review {
            id
            stepId
            studentId
            fileUrl
            fileMIMEtype
            reflection
            rating
            completedAt
            reflectionCompletedAt
            comments {
              ...CommentFragment
              coordinates {
                x
                y
                ts
                page
              }
            }
          }
        }
        ... on CBValueReflectionQuestionnaire {
          peerReviewId
          rating
          reflection
          reflectionCompletedAt
          questions {
            question
            answer
          }
          review {
            id
            fileUrl
            stepId
            fileMIMEtype
            studentId
            reflection
            rating
            questionnaire {
              id
              feedback
              questionIndex
              answers {
                id
                answerIndex
                answer
              }
            }
          }
        }
        ... on CBValuePeerReviewQuestionnaire {
          studentId
          stepId
          completedAt
          readyToReview
          questionnaire {
            title
            feedback
            questions {
              question
              answer
            }
          }
        }
        ... on CBValueTextSubmit {
          text
          title
          placeholder
          isUnlocked
        }
        ... on CBValueCanvas {
          titles
          count
          url
          MIMEtype
          hidden
          width
          imageUrls
          templateId
        }
        ... on CBValueSortingBoard {
          targets {
            title
            values
          }
        }
        ... on CBCategoryDrag {
          questionTitle
          targets {
            title
            values
          }
          answers {
            value
          }
        }
        ... on CBCategoryDragPicture {
          questionTitle
          targets {
            title
            values
          }
          answers {
            value
          }
        }
        ... on CBProcessDragDropAnswer {
          directions
          scenarios
          targets {
            title
            values
          }
          answers {
            value
          }
        }
        ... on CBProcessDragDropPictureAnswer {
          directions
          scenarios
          targets {
            title
            values
          }
          answers {
            value
          }
        }
        ... on CBValueBudgetCalculator {
          materials {
            name
            amount
            price
          }
        }
        ... on CBValueConceptEvaluation {
          studentId
          stepId
          completedAt
          readyToReview
          activityId
          question {
            title
            subquestions {
              text
            }
          }
        }
        ... on CBValueConceptRating {
          studentId
          stepId
          completedAt
          activityId
          readyToReview
          rating
          question {
            title
            subquestions {
              text
            }
          }
        }
        ... on CBValueGroupBestConcept {
          studentId
          stepId
          completedAt
          activityId
          readyToReview
          rating
        }
        ... on CBValueBestConceptPeerReview {
          studentId
          stepId
          completedAt
          activityId
          readyToReview
        }
        ... on CBValueBestConceptReflection {
          peerReviewId
          rating
          reflection
          review {
            id
            completedAt
            reflectionCompletedAt
            reflection
            rating
            comments {
              ...CommentFragment
              coordinates {
                x
                y
                ts
                page
              }
            }
          }
          completedAt
          reflectionCompletedAt
        }
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

const TEACHER_CONTENT_FRAGMENT = gql`
  fragment TeacherContentFragment on InvestigationActivityTeacherContent {
    title
    blocks {
      type
      columns
      userId
      userName
      values {
        ... on CBValueText {
          type
          text
          optionLabel
          targetAnswer
          selectedAnswer
          fileName
          url
          spans {
            start
            end
            type
            url
            meta
          }
        }
        ... on CBValueList {
          text
          spans {
            start
            end
            type
          }
        }
        ... on CBValueVideo {
          url
        }
        ... on CBValueSimulation {
          url
        }
        ... on CBValueImage {
          url
          alt
        }
        ... on CBValueTextImage {
          type
          text
          ratio
          url
          alt
          size
          spans {
            start
            end
            type
            url
            meta
          }
        }
        ... on CBValueUpload {
          type
          url
          MIMEtype
          hidden
        }
        ... on CBValueUploadFinalReport {
          type
          url
          MIMEtype
          hidden
        }
        ... on CBValuePeerReview {
          studentId
          stepId
          completedAt
          readyToReview
        }
        ... on CBValueComment {
          label
        }
        ... on CBValueReflection {
          peerReviewId
          rating
          reflection
          reflectionCompletedAt
          review {
            id
            stepId
            studentId
            fileUrl
            fileMIMEtype
            reflection
            rating
            completedAt
            reflectionCompletedAt
            comments {
              ...CommentFragment
              coordinates {
                x
                y
                ts
                page
              }
            }
          }
        }
        ... on CBValueReflectionQuestionnaire {
          peerReviewId
          rating
          reflection
          reflectionCompletedAt
          questions {
            question
            answer
          }
          review {
            id
            fileUrl
            stepId
            fileMIMEtype
            studentId
            reflection
            rating
            comments {
              ...CommentFragment
              coordinates {
                x
                y
                ts
                page
              }
            }
          }
        }
        ... on CBValuePeerReviewQuestionnaire {
          studentId
          stepId
          completedAt
          readyToReview
          questionnaire {
            title
            feedback
            questions {
              question
              answer
            }
          }
        }
        ... on CBValueTextSubmit {
          text
          title
          placeholder
          isUnlocked
        }
        ... on CBValueCanvas {
          titles
          count
          url
          MIMEtype
          hidden
          width
          imageUrls
          templateId
        }
        ... on CBValueSortingBoard {
          targets {
            title
            values
          }
        }
        ... on CBCategoryDrag {
          questionTitle
          targets {
            title
            values
          }
          answers {
            value
          }
        }
        ... on CBCategoryDragPicture {
          questionTitle
          targets {
            title
            values
          }
          answers {
            value
          }
        }
        ... on CBProcessDragDropAnswer {
          directions
          scenarios
          targets {
            title
            values
          }
          answers {
            value
          }
        }
        ... on CBProcessDragDropPictureAnswer {
          directions
          scenarios
          targets {
            title
            values
          }
          answers {
            value
          }
        }
        ... on CBValueBudgetCalculator {
          materials {
            name
            amount
            price
          }
        }
        ... on CBValueButton {
          url
          text
          color
        }
        ... on CBValueConceptEvaluation {
          studentId
          stepId
          completedAt
          activityId
          readyToReview
        }
        ... on CBValueConceptRating {
          studentId
          stepId
          completedAt
          activityId
          readyToReview
          rating
          question {
            title
            subquestions {
              text
            }
          }
        }
        ... on CBValueGroupBestConcept {
          studentId
          stepId
          completedAt
          activityId
          readyToReview
          rating
        }
        ... on CBValueBestConceptPeerReview {
          studentId
          stepId
          completedAt
          activityId
          readyToReview
        }
        ... on CBValueBestConceptReflection {
          peerReviewId
          rating
          reflection
          review {
            id
            completedAt
            reflectionCompletedAt
            reflection
            rating
            comments {
              ...CommentFragment
              coordinates {
                x
                y
                ts
                page
              }
            }
          }
          completedAt
          reflectionCompletedAt
        }
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

export const INVESTIGATION_FRAGMENTS = {
  COMMENTS: {
    commentFragment: COMMENT_FRAGMENT,
  },
  ACTIVITIES: {
    updateActivity: gql`
      fragment updateActivity on InvestigationActivityEntry {
        id
        stepId
        name
        dueDate
        completed
        content {
          ...ContentFragment
        }
      }
      ${CONTENT_FRAGMENT}
    `,
    teacherActivityEntry: gql`
      fragment activityTeacherEntry on InvestigationActivityTeacherEntry {
        id
        content {
          ...TeacherContentFragment
        }
        contentInPerson {
          ...TeacherContentFragment
        }
      }
      ${TEACHER_CONTENT_FRAGMENT}
    `,
  },
  CONTENT: {
    contentFragment: CONTENT_FRAGMENT,
    teacherContentFragment: TEACHER_CONTENT_FRAGMENT,
  },
  DISCIPLINE: {
    disciplineFragment: gql`
      fragment DisciplineFragment on Discipline {
        id
        name
        gradeBand
        subject
      }
    `,
  },
  MATERIAL: {
    materialFragment: gql`
      fragment MaterialFragment on InvestigationCatalogStepMaterial {
        id
        filename
        MIMEType
        url
        created
      }
    `,
  },
  CONCEPT: {
    evaluationAnswerFragment: gql`
      fragment EvaluationAnswerFragment on ConceptEvaluationAnswers {
        id
        feedback
        completedAt
        answers {
          answerValue
          questionIndex
        }
      }
    `,
  },
  DRAFT: {
    updateStepEntry: gql`
      fragment updateStepEntry on InvestigationCatalogStepEntry {
        materials {
          id
          filename
          MIMEType
          url
          created
        }
        activities {
          id
          name
        }
      }
    `,
  },
};

export default INVESTIGATION_FRAGMENTS;
