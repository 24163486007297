import React, { useMemo } from 'react';
import { Table, Tooltip, Empty } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { GQL_AssessmentsScoresResponse, InvestigationStandard } from '../../../types/gradeBooking';
import Spacer from '../../../shared/Spacer';
import { palette } from '../../../utils/theme';
import { formatDateTime } from '../../../utils/date';
import * as S from './styles';

interface IAssessmentsTableProps {
  data: GQL_AssessmentsScoresResponse[];
}

const columns: ColumnsType<GQL_AssessmentsScoresResponse> = [
  {
    title: 'Assessment ID',
    dataIndex: 'assessmentTitle',
    key: 'assessmentTitle',
    sorter: (a, b) => a.assessmentTitle.localeCompare(b.assessmentTitle),
    render: (text, _, index) => (
      <S.AssessmentsTitleContainer>
        <S.AssessmentTitleDot color={palette[index % palette.length]}>{index + 1}</S.AssessmentTitleDot>
        <Spacer axis="horizontal" />
        {text}
      </S.AssessmentsTitleContainer>
    ),
    fixed: 'left',
    width: 240,
  },
  {
    title: 'Open Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sorter: (a, b) => a.startDate - b.startDate,
    render: (text) => formatDateTime(text),
  },
  {
    title: 'Standard',
    dataIndex: 'standard',
    key: 'standard',
    render: (_text, record) => {
      const rootTexasStandards = record.standards || [];
      const rootNextStandards = record.nextStandards || [];
      const partNextStandards: InvestigationStandard[] = [];
      const partTexasStandards: InvestigationStandard[] = [];

      record.parts?.forEach((part) => {
        partTexasStandards.push(...(part.texasStandards || []));
        partNextStandards.push(...(part.nextGenerationStandards || []));
      });

      const standardsInv = [...rootTexasStandards, ...rootNextStandards, ...partNextStandards, ...partTexasStandards];
      const uniqueStandards = [...new Map(standardsInv.map((item) => [item['id'], item])).values()];
      return uniqueStandards.map((standard, key) => {
        return <S.TagButton text={standard?.code || ''} display="inline" shape="round" key={key} minHeight={14} />;
      });
    },
  },
  {
    title: 'Graded Students',
    dataIndex: 'gradedStudents',
    key: 'gradedStudents',
  },
  {
    title: 'Unfinished Students',
    dataIndex: 'unfinishedStudents',
    key: 'unfinishedStudents',
  },
  {
    title: 'Assessment Score',
    dataIndex: 'assessmentScore',
    key: 'assessmentScore',
    render: (text, item) => `${text || 0} / ${item.maxAssessmentScore || 0}`,
  },
  {
    title: 'Assessment Percent',
    dataIndex: 'assessmentPercent',
    key: 'assessmentPercent',
    render: (text) => `${text.toFixed(2)}%`,
  },
  {
    title: 'Performance Level',
    dataIndex: 'performanceLevel',
    key: 'performanceLevel',
  },
];

const CustomRow = (props: any) => {
  return (
    <Tooltip title={props.disabled ? 'This assessment is related to a previously selected one.' : ''}>
      <tr {...props} />
    </Tooltip>
  );
};

const AssessmentsTable = (props: IAssessmentsTableProps) => {
  const { data } = props;

  const emptyTable = useMemo(() => {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={'Please select a class to populate the table / graphs'}
      />
    );
  }, []);

  return (
    <S.AssessmentsTableContainer>
      <Table
        dataSource={data}
        locale={{ emptyText: emptyTable }}
        columns={columns}
        scroll={{ x: 'max-content' }}
        rowKey={(record) => record.assessmentId}
        components={{
          body: {
            row: (props: any) => CustomRow({ ...props }),
          },
        }}
      />
    </S.AssessmentsTableContainer>
  );
};

export default AssessmentsTable;
