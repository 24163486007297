import styled from 'styled-components';
import { DatePicker as Picker } from 'antd';

const { RangePicker: AntdRangePicker } = Picker;

export const RangePicker = styled(AntdRangePicker)<{
  $backgroundColor?: string;
}>`
  border-radius: 6px;
  background: ${(props) => props.$backgroundColor || '#f8f8f8'};

  input {
    background: ${(props) => props.$backgroundColor || '#f8f8f8'};
    padding: 4px 11px 4px;
    cursor: pointer;
  }
`;
