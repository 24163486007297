import { Col, Row } from 'antd';
import styled from 'styled-components';

export const DetailsContainer = styled(Row)`
  background: white;
  border-radius: 6px;
  padding: 16px 32px;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);

  & > div {
    height: 100%;
    overflow: auto;
  }
`;

export const LeftPanelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  max-height: calc(100% - 56px);

  text-align: center;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125.1%;

    text-align: center;

    color: #767676;
  }
`;

export const VerticalDivider = styled.div`
  min-height: 100%;
  width: 2px;
  border-radius: 6px;
  background: #ededed;
  margin: auto;
`;

export const RightPanelContainer = styled.div`
  max-width: 100%;
  max-height: calc(100% - 56px);
  overflow-y: scroll;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  img {
    max-height: 200px;
    margin-bottom: 32px;
    max-width: 100%;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125.1%;
    margin-bottom: 0;

    text-align: center;

    color: #767676;
    width: 100%;
  }
`;

export const StageSelectorContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #000000;
  user-select: none;

  &:hover {
    background: #f6f6f6;
  }
`;

export const HorizontalDivider = styled.div<{
  width?: number;
  selected?: boolean;
  margin?: string;
}>`
  width: ${(props) => props.width ?? 90}px;
  height: ${(props) => (props.selected ? 3 : 2)}px;
  min-height: ${(props) => (props.selected ? 3 : 2)}px;
  border-radius: 6px;
  background: ${(props) => (props.selected ? '#4367E9' : '#ededed')};
  ${(props) => props.margin && `margin: ${props.margin};`}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 56px;

  button:first-child,
  a:first-child {
    margin-right: 48px;

    @media (max-width: 1500px) {
      margin-right: 0;
    }
  }

  @media (max-width: 1500px) {
    flex-direction: column-reverse;

    button:last-child,
    a:last-child {
      margin-bottom: 16px;
      margin-top: 16px;
    }
  }
`;

export const PanelCol = styled(Col)`
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
