import React, { useRef, useState } from 'react';
import useFullscreenStatus from '../../../hooks/useFullscreen';
import CircularSpin from '../../../shared/CircularSpin';
import { IMediaContentValue } from '../../../types/investigation';
import * as S from './styles';

interface IInvestigationSimulation {
  value: IMediaContentValue;
}

const InvestigationSimulation = (props: IInvestigationSimulation) => {
  const { value } = props;
  const [status, setStatus] = useState<'loading' | 'error' | 'loaded'>(value?.file ? 'loaded' : 'loading');
  const contentRef = useRef<HTMLIFrameElement>(null);
  const { setFullscreen } = useFullscreenStatus(contentRef);

  return (
    <>
      {status === 'error' && <p>There was an error loading the simulation</p>}
      {status === 'loading' && (
        <S.LoadingBarrier>
          <CircularSpin size={32} />
        </S.LoadingBarrier>
      )}
      {value.file ? (
        'No Preview Supported For Files.'
      ) : (
        <>
          <S.FullscreenLink onClick={() => setFullscreen(true)}>
            Click here to make simulation fullscreen
          </S.FullscreenLink>
          <S.IframeComponent
            ref={contentRef}
            src={value?.url}
            allow="microphone https://adilearninghub.com/"
            onError={() => setStatus('error')}
            onLoad={() => setStatus('loaded')}
            allowFullScreen
          />
        </>
      )}
    </>
  );
};

export default InvestigationSimulation;
