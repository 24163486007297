import React, { useMemo } from 'react';
import { Col, Row, Skeleton } from 'antd';
import * as S from './styles';

interface IClassInfos {
  className?: string;
  classStart?: string;
  classStudents?: number;
  classStatus?: string;
  creationTime: string;
}

const ClassInfos: React.FC<IClassInfos> = ({ className, classStudents, classStatus, creationTime }) => {
  const loading = false;
  const numberOfStudents = useMemo(() => { return classStudents || 0 }, [classStudents]);

  const studentsLoading = false; // Replace with actual loading state or mock data

  // Function to determine status color, text, and fontWeight based on course state
  const getStatusColorAndText = (state: string | "UNKNOWN") => {
    switch (state) {
      case 'available':
        return { color: '#3EBC89', text: 'Ongoing', fontWeight: 'bold' as 'bold' };
      case 'ARCHIVED':
        return { color: 'black', text: 'Archived', fontWeight: 'normal' as 'normal' };
      case 'PROVISIONED':
        return { color: 'blue', text: 'Provisioned', fontWeight: 'normal' as 'normal' };
      case 'DECLINED':
        return { color: 'red', text: 'Declined', fontWeight: 'normal' as 'normal' };
      case 'SUSPENDED':
        return { color: 'orange', text: 'Suspended', fontWeight: 'normal' as 'normal' };
      default:
        return { color: 'black', text: state, fontWeight: 'normal' as 'normal' };
    }
  };

  const { color: statusColor, text: statusText, fontWeight } = getStatusColorAndText(classStatus || 'UNKNOWN');

  return (
    <S.ClassInfosContainer>
      <Row>
        {/* Class Name */}
        <Col span={8} lg={8} xl={8} xs={24}>
          <S.Title>Class Name</S.Title>
          {!loading ? (
            <p>{className || 'No Data'}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>

        {/* Class Duration */}
        <Col span={8} lg={8} xl={8} xs={24}>
          <S.Title>Class Start</S.Title>
          {!loading ? (
            <p>
              {creationTime 
                ? new Date(creationTime).toLocaleDateString()
                : 'Not provided'}
            </p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>

        {/* Class Students */}
        <Col span={8} lg={8} xl={8} xs={24}>
          <S.Title>Class Students</S.Title>
          {!loading && !studentsLoading ? (
            <p>{numberOfStudents !== null ? `${numberOfStudents} Enrolled Students` : '-'}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
      </Row>

      <Row>
        {/* Class Status */}
        <Col span={8} lg={8} xl={8} xs={24}>
          <S.Title>Class Status</S.Title>
          {!loading ? (
            <p style={{ color: statusColor, fontWeight }}>{statusText}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
      </Row>
    </S.ClassInfosContainer>
  );
};

export default ClassInfos;
