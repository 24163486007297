import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form as AntdForm, Col, Row, Tooltip, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FiFileText, FiMail } from 'react-icons/fi';
import { gqlSchema } from '../../../gql/schema';
import Button from '../../../shared/Button';
import Form from '../../../shared/Form';
import InviteUsers from '../../../shared/InviteUsers';
import { GQL_ClassResponse, GQL_ClassUserOrInvite } from '../../../types/class';
import { StudentDataProps } from '../../../types/students';
import { onDownloadStudentTemplate } from '../../../utils/class';
import * as S from './styles';
import { themeConfig } from '../../../utils/theme';

interface Props {
  classInfo?: GQL_ClassResponse;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  openGroupManagment: Dispatch<SetStateAction<boolean>>;
  updateClassInfo?: (e: GQL_ClassResponse) => void;
}

const initialInvite: StudentDataProps[] = [{ firstName: '', lastName: '', email: '' }];

const AddStudents: React.FC<Props> = (props) => {
  const { visible, setVisible, openGroupManagment, classInfo, updateClassInfo } = props;
  const [activeKey, setAtiveKey] = useState('manual');
  const [hasUploadedFile, setHasUploadedFile] = useState(false);
  const [viewStudentsDetails, setViewStudentsDetails] = useState(false);
  const [hasErrorFile, setHasErrorFile] = useState(false);
  const [form] = useForm();

  const onCloseModal = () => {
    setVisible(false);
    setAtiveKey('manual');
    setHasUploadedFile(false);
    setViewStudentsDetails(false);
    form.resetFields();
  };
  const [addStudentsToClass, { loading }] = useMutation(gqlSchema.ClassSchema.mutation.CLASS.EDIT.addStudentsToClass, {
    onCompleted: (user) => {
      if (updateClassInfo) {
        const students = user.addStudentsToClass.students as GQL_ClassUserOrInvite[];
        updateClassInfo({
          ...classInfo,
          students,
        } as GQL_ClassResponse);
      }
      setVisible(false);
      openGroupManagment(true);
    },
    onError: (e) => {
      message.error(`Error trying to add new students: ${e.message}`);
    },
  });

  const onFinish = useCallback(
    async ({ invites }: { invites: StudentDataProps[] }) => {
      if (classInfo) {
        addStudentsToClass({
          variables: {
            data: {
              classId: classInfo.id,
              invites,
              sendInvites: true,
            },
          },
        });
      }

      if(!classInfo) {
        message.warning('Please select a class before adding new students')
      }
    },
    [classInfo, addStudentsToClass],
  );

  const onReimport = useCallback(() => {
    setViewStudentsDetails(false);
    setHasUploadedFile(false);
  }, [setHasUploadedFile]);

  const onImportEmails = useCallback(() => {
    setViewStudentsDetails(true);
    setHasUploadedFile(false);
  }, [setHasUploadedFile]);

  const titleInfo = useMemo(
    () => (
      <Row style={{ marginTop: 26 }} justify="center">
        <Col span={1}>
          <FiMail color="#106cfa" size={32} />
        </Col>
        <Col span={24}>
          <S.Title>Invite by Email</S.Title>
        </Col>
        <Col span={24}>
          <S.Info>Students will receive a link on their email which</S.Info>
          <S.Info>will guide them to create an account</S.Info>
        </Col>
      </Row>
    ),
    [],
  );

  const saveButton = useMemo(
    () => (
      <AntdForm.Item>
        <Button
          data-cy="components-classdashboard-addstudents-send-button"
          block
          loading={loading}
          text="Send Invites"
          htmlType="submit"
        />
      </AntdForm.Item>
    ),
    [loading],
  );

  const renderButtons = useCallback(() => {
    let buttonBar = (
      <>
        <Col span={12} offset={6}>
          {saveButton}
        </Col>
      </>
    );
    if (activeKey === 'file') {
      if (viewStudentsDetails) {
        buttonBar = (
          <>
            <Col span={12}>
              <Button block text="Import Another File" htmlType="button" onClick={onReimport} />
            </Col>
            <Col span={12}>{saveButton}</Col>
          </>
        );
      } else {
        if (hasUploadedFile) {
          buttonBar = (
            <Col span={12} offset={6} style={{ marginBottom: 24 }}>
              <Button block disabled={hasErrorFile} text="Import Emails" htmlType="button" onClick={onImportEmails} />
            </Col>
          );
        }
      }
    }
    return <Row gutter={16}>{buttonBar}</Row>;
  }, [activeKey, hasErrorFile, hasUploadedFile, onImportEmails, onReimport, saveButton, viewStudentsDetails]);

  return (
    <S.CModal visible={visible} title={titleInfo} onCancel={onCloseModal} width={800} footer={null}>
      <Form onFinish={onFinish} form={form} initialValues={{ invites: initialInvite }}>
        <Row gutter={24}>
          <Col span={20} offset={2}>
            <InviteUsers
              form={form}
              activeKey={activeKey}
              setActiveKey={setAtiveKey}
              setHasUploadedFile={setHasUploadedFile}
              viewUsersDetails={viewStudentsDetails}
              setHasErrorFile={setHasErrorFile}
              hasErrorFile={hasErrorFile}
              initialInvite={initialInvite}
              userType="student"
            />
          </Col>
          {activeKey === 'file' && (
            <Col span={1}>
              <S.DownloadTemplate>
                <Tooltip title="Click here to download a template to import your class/data." placement="bottom">
                  <FiFileText
                    size={24}
                    onClick={onDownloadStudentTemplate}
                    style={{ cursor: 'pointer' }}
                    color={themeConfig.secondaryColor.background}
                  />
                </Tooltip>
              </S.DownloadTemplate>
            </Col>
          )}
          <Col span={16} offset={4}>
            {renderButtons()}
          </Col>
        </Row>
      </Form>
    </S.CModal>
  );
};

export default AddStudents;
