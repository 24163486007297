import styled from 'styled-components';

export const PTitle = styled.h1`
  font-size: 2.5em;
  color: #333;
  text-align: center;
  
  margin-top: 20px;

  @media (max-width: 1024px) {
    font-size: 1.5em;
  }
`;

export const SubTitle = styled.h2`
  font-size: 1.2em;
  font-weight: 200;
  color: #333;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 0.9em;
  }
`;

export const MainWrapper = styled.div`
  background-color: #f9f9f9;
  padding: 8rem;

  @media (max-width: 1024px) {
    padding: 4rem;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 30px;
  margin: 20px 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    padding: 15px;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8rem;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 50px;
  justify-items: center;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 15px;
  }
`;

export const GridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  height: 100%;
  gap: 10px;

  @media (max-width: 1024px) {
    gap: 8px;
  }
`;

export const RoundImage = styled.img`
  width: 90px;
  height: 90px;
  align-content: center;
  justify-content: center;
  padding: 18px;

  @media (max-width: 1024px) {
    width: 80px;
    height: 80px;
    padding: 10px;
  }
`;

export const ItemContentHead = styled.div`
  font-size: 20px;
  color: #333;
  font-weight: bold;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const ItemContentBody = styled.div`
  font-size: 15px;
  color: #333;
 

  @media (max-width: 1024px) {
    font-size: 11px;
  }
`;
