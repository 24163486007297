import styled from 'styled-components';

export const DraggableContainer = styled.div`
  position: absolute;
  z-index: 99;

  textarea {
    margin: 15px 0;
  }
`;

export const DraggableCommentBalloon = styled.div<{ $dragging?: boolean }>`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: #31cf2e;
  cursor: ${(props) => (props.$dragging ? 'grabbing' : 'grab')};
  margin-left: 15px;
  margin-bottom: 5px;
`;

export const DraggableCommentBoxContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  width: 350px;
  padding: 20px;
`;

export const CommentTriangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 18px 18px 18px;
  border-color: transparent transparent white transparent;
  margin-left: 15px;

  // For some reason I need to apply this filter so it gets in front of container box-shadow
  -webkit-filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
`;

export const ImageContainer = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #c6c6c6;
  margin-right: 10px;
`;

export const NameText = styled.p`
  margin: 0;
  font-weight: bold !important;
  color: black !important;
  font-size: 14px !important;
`;
