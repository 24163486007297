import React, { useEffect, useState } from 'react';
import { Row, Col, Empty, Input } from 'antd';
import Item from './Item';
import { formatDateTime } from '../../../utils/date';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg';
import AddCanvasAssignmentButton from './AddCanvasAssignmentButton';

const { Search } = Input;

interface IActiveAssignmentsRow {
  loading: boolean;
  assignmentData: any[];
  courses: any[];
}

const CActiveAssignmentsRow: React.FC<IActiveAssignmentsRow> = ({ loading, assignmentData, courses }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearch = (value: string) => {
    setSearchTerm(value.toLowerCase());
  };

  const filteredAssignments = assignmentData?.filter(assignment =>
    assignment && assignment.name && assignment.name.toLowerCase().includes(searchTerm)
  );
  

  const renderAssignments = () => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          {[1, 2, 3].map((index) => (
            <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      );
    } else if (filteredAssignments.length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Assignments" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {filteredAssignments.map((assignment) => {
            const course = courses.find((c) => c.name === assignment.courseName);
            const courseId = course?.id || "Unknown Course ID";
            const className = assignment.courseName || 'Unknown Class';
            const classColor = course ? course.color : '#000000';
            const dueDate = assignment.due_at ? new Date(assignment.due_at).getTime() : null;

            return (
              <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={assignment.id}>
                <Item
                  date={formatDateTime(assignment.created_at)}
                  assignmentName={assignment.name}
                  assignmentId={assignment.id}
                  courseId={courseId}
                  timeLeft={dueDate}
                  timeWarning={dueDate ? Date.now() > dueDate : false}
                  icon={<img src={AssignmentIcon} alt="Assignment Icon" />}
                  className={className}
                  classColor={classColor}
                  workType={assignment.submission_types?.[0]} // Assuming workType comes from submission_types
                  description={assignment.description}
                />
              </Col>
            );
          })}
        </Row>
      );
    }
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div style={{ display: isMobile? 'block' : 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>Active Assignments</h2>
        <AddCanvasAssignmentButton />
      </div>
      <Search 
        placeholder="Search assignments" 
        onSearch={handleSearch} 
        onChange={e => handleSearch(e.target.value)}
        style={{ marginBottom: 16, width: 200 }} 
      />
      {renderAssignments()}
    </div>
  );
};

export default CActiveAssignmentsRow;
