import styled from 'styled-components';

export const LoadingLibrary = styled.div`
  position: absolute;
  width: 100%;
  font-size 2.3em;
  font-weight: 600;
  z-index: 10;
  text-align: center;
  background-color: rgb(0 0 0 / 12%);
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
