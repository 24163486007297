import styled from 'styled-components';
import { Steps as AntdSteps } from 'antd';

const { Step: AntdStep } = AntdSteps;

export const Step = styled(AntdStep)`
  &.ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail:after {
    background-color: ${(props) => props.theme.success.color} !important;
  }

  .ant-steps-item-tail:after {
    background-color: ${(props) => props.theme.primaryOutlined.color} !important;
  }

  &.ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon {
    background-color: ${(props) => props.theme.success.color} !important;
    border-color: ${(props) => props.theme.success.color} !important;
    svg {
      color: white;
      margin-top: 8px;
    }
  }

  .ant-steps-item-icon {
    background-color: white !important;
    border-color ${(props) => props.theme.primaryOutlined.color} !important;
    border-width: 2px;
    margin-right: 0 !important;
    .ant-steps-icon {
      color: ${(props) => props.theme.primaryOutlined.color} !important;
      font-weight: bold;
    }
  }

  .ant-steps-item-title {
    font-size: 18px;
    margin-left: 12px;
  }
`;

export const Steps = styled(AntdSteps)<{ $finished: boolean }>`
  padding: 0 15px;
  &.ant-steps-vertical > div:nth-last-child(2) {
    .ant-steps-item-tail:after {
      ${(props) => !props.$finished && 'background-color: transparent !important;'}
      ${(props) => !props.$finished && 'border: 0.5px dashed #767676;'}
    }
  }

  .ant-steps-item-title {
    color: ${(props) => props.theme.primaryOutlined.color} !important;
  }

  .ant-steps-item-finish .ant-steps-item-title {
    color: ${(props) => props.theme.success.color} !important;
  }

  .ant-steps-item-active {
    font-weight: bold;
  }
`;
