import React from 'react';
import { GQL_InvestigationActivity, GQL_InvestigationBlock } from '../../../types/investigation';
import { ProcessCategoryDragDropSummary } from './components/ProcessCategoryDragDropSummary';
import { MultipleChoiceSummary } from './components/MultipleChoiceSummary';
import * as S from './styles';

interface Props {
  part: GQL_InvestigationActivity;
  assessmentId: string;
  partIndex: number;
}

const AssessmentModulePicker = ({ part, assessmentId, partIndex }: Props) => {
  // Remove duplicated Blocks
  const formStructure = part?.content[0]?.blocks?.filter(
    (value, index, self) => index === self.findIndex((t) => t.type === value.type),
  );

  const modulePicker = (item: GQL_InvestigationBlock) => {
    switch (item.type) {
      case 'MultipleChoiceQuestion':
      case 'MultipleChoicePictureQuestion':
        return <MultipleChoiceSummary part={part} item={item} />;

      case 'ProcessDragDropAnswer':
      case 'CategoryDrag':
        return (
          <ProcessCategoryDragDropSummary
            part={part}
            item={item}
            assessmentId={assessmentId}
            type={item.type}
            partIndex={partIndex}
          />
        );

      default:
        return null;
    }
  };

  return <S.QuestionBlock>{formStructure?.map((item) => modulePicker(item))}</S.QuestionBlock>;
};

export default AssessmentModulePicker;
