import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Text, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';

export interface ITextShapeProps {
  x: number;
  y: number;
  fontSize: number;
  text?: string;
  width?: number;
  id: string;
  color: string;
  initialEvent: KonvaEventObject<MouseEvent | TouchEvent>;
}

interface IText {
  shapeProps: ITextShapeProps;
  isSelected: boolean;
  onSelect: (e: KonvaEventObject<Event>) => void;
  onChange: (newAttributes: ITextShapeProps) => void;
  onEdit: (isEditing: boolean) => void;
  draggable: boolean;
}

// Most of the code is copied from: https://konvajs.org/docs/sandbox/Editable_Text.html
export const TextShape = (props: IText) => {
  const { shapeProps, isSelected, onSelect, onChange, onEdit, draggable } = props;
  const shapeRef = useRef<Konva.Text>(null);
  const trRef = useRef<Konva.Transformer>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [textareaStyles, setTextareaStyles] = useState<React.CSSProperties>();

  useEffect(() => {
    if (isSelected) {
      if (trRef.current && shapeRef?.current) {
        // we need to attach transformer manually
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer()?.batchDraw();
      }
    }
  }, [isSelected, trRef, shapeRef]);

  const removeTextarea = () => {
    setIsEditing(false);

    const textNode = shapeRef.current;
    const transformerNode = trRef.current;

    window.removeEventListener('mousedown', handleOutsideClick);

    textNode?.show();
    transformerNode?.show();
    transformerNode?.forceUpdate();
  };

  const handleOutsideClick = useCallback((e: MouseEvent) => {
    if (e.target !== textareaRef?.current) {
      removeTextarea();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartEditing = useCallback(
    (event: KonvaEventObject<MouseEvent | TouchEvent>) => {
      const textNode = shapeRef.current;
      const transformerNode = trRef.current;
      const stage = event.target.getStage();
      event.evt.preventDefault();
      event.evt.stopPropagation();

      if (!textNode || !stage) return;

      textNode.hide();
      transformerNode?.hide();

      setIsEditing(true);

      // at first lets find position of text node relative to the stage:
      const textPosition = textNode.absolutePosition();

      setTextareaStyles({
        position: 'absolute',
        top: textPosition.y,
        left: textPosition.x,
        width: textNode.width() - textNode.padding() * 2 + 'px',
        height: textNode.height() - textNode.padding() * 2 + 5 + 'px',
        fontSize: textNode.fontSize() + 'px',
        border: 'none',
        padding: '0px',
        margin: '0px',
        overflow: 'hidden',
        background: 'none',
        outline: 'none',
        resize: 'none',
        lineHeight: textNode.lineHeight(),
        fontFamily: textNode.fontFamily(),
        transformOrigin: 'left top',
        textAlign: textNode.align() as any,
        color: textNode.fill(),
      });

      window.addEventListener('mousedown', handleOutsideClick);
    },
    [handleOutsideClick],
  );

  useEffect(() => {
    if (shapeProps.initialEvent) {
      handleStartEditing(shapeProps.initialEvent);
    }
  }, [shapeProps.initialEvent, handleStartEditing]);

  useEffect(() => {
    if (isEditing) textareaRef.current?.focus();
    onEdit(isEditing);
  }, [isEditing, onEdit]);

  // This will make sure that the textarea height is updated when new line is added
  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, shapeProps.fontSize + 3)}px`;
    }
  }, [shapeProps.text, shapeProps.fontSize]);

  return (
    <>
      <Text
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        fill={shapeProps.color}
        draggable={draggable}
        onDragStart={onSelect}
        onDblClick={handleStartEditing}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransform={() => {
          const node = shapeRef.current;
          if (node) {
            const scaleX = node.scaleX();

            node.scaleX(1);
            onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              width: Math.max(30, node.width() * scaleX),
            });
          }
        }}
      />
      {
        <Html>
          <textarea
            ref={textareaRef}
            value={shapeProps.text}
            style={isEditing ? textareaStyles : { display: 'none' }}
            autoFocus
            onKeyDown={(e) => {
              if ((e.key === 'Enter' && !e.shiftKey) || e.key === 'Esc' || e.key === 'Escape') {
                removeTextarea();
              }
            }}
            onChange={(e) => {
              onChange({
                ...shapeProps,
                text: e.target.value,
              });
            }}
          />
        </Html>
      }
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={['middle-left', 'middle-right']}
          boundBoxFunc={(oldBox, newBox) => {
            newBox.width = Math.max(30, newBox.width);
            return newBox;
          }}
        />
      )}
    </>
  );
};
