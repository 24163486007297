import React, { useMemo } from 'react';
import { GQL_SubscriptionResponse } from '../../types/subscription';
import * as S from './styles';
import moment from 'moment';
import { StripeSubscription } from '../../types/login';

interface Props {
  subscription?: GQL_SubscriptionResponse;
  stripeSubscription?: StripeSubscription;
}

const SubscriptionStatus: React.FC<Props> = (props) => {
  const { subscription, stripeSubscription } = props;

  const endDate = useMemo(() => {
    if (stripeSubscription?.endDate) {
      return moment(stripeSubscription?.endDate, 'x').toDate();
    }

    return subscription?.endDate || '';
  }, [stripeSubscription, subscription]);

  const formattedDate = useMemo(() => {
    return moment(endDate).format('MM.DD.YYYY');
  }, [endDate]);

  const subscriptionActive = useMemo(() => {
    if (subscription && endDate) return new Date(endDate).getTime() > Date.now();
    return false;
  }, [subscription, endDate]);

  const renderStatus = useMemo(() => {
    if (!subscriptionActive) return 'Inactive';

    if (stripeSubscription) {
      const subscriptionType = stripeSubscription.type;
      
      if (subscriptionType === 'Starter') {
        return `${subscriptionType} - Resets ${formattedDate}`
      }

      return `${subscriptionType} - Expires ${formattedDate}`
    }

    return `Active - Expires ${formattedDate}`;
  }, [formattedDate, subscriptionActive, stripeSubscription]);

  return (
    <>
      <S.TitleInput>
        Subscription Status
      </S.TitleInput>
      <S.RenewContainer>
        <S.Status>
          {renderStatus}
        </S.Status>
      </S.RenewContainer>
    </>
  );
};

export default SubscriptionStatus;
