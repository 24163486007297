import { useLazyQuery, useQuery } from '@apollo/client';
import { Col, Dropdown, Menu, message, Row } from 'antd';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { gqlSchema } from '../../../gql/schema';
import { Duration, FilterData } from '../../../types/investigation';
import * as S from './styles';
import RangePicker from '../../RangePicker';
import { formatDateTime } from '../../../utils/date';
import Select from '../../Select';
import SelectOption from '../../Select/Option';
import { useAuth } from '../../../hooks/useAuth';
import { GQL_OrganizationResponse } from '../../../types/organization';
import { GQL_UserDetailsResponsePaginated, GQL_UsersListFilterInput } from '../../../types/user';
import { startCase } from 'lodash';
import { TagResponse } from '../../../types/tags';
import { FiChevronDown, FiFilter, FiX } from 'react-icons/fi';

interface DateRange {
  startDate: string;
  endDate: string;
}

interface Props {
  setDaterange1?: (duration: Duration) => void;
  setDaterange2?: (duration: Duration) => void;
  refreshFilter?: (filters: IKpiFilters[]) => void;
  onChangeFilterData?: (data: FilterData) => void;
  hideTeacherFilter?: boolean;
  workshopOnly?: boolean;
}
export interface IKpiFilters {
  field: 'subject' | 'grade' | 'type' | 'tag';
  value: string | string[];
}
const dateRangeBase = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};
const { SubMenu } = Menu;
const FilterDoubleDates = (props: Props) => {
  const [filters, setFilters] = useState<IKpiFilters[]>([]);
  const [currentOrganization, setCurrentOrganization] = useState<string | undefined>();
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [currentFreelanceTechaer, setCurrentFreelanceTechaer] = useState<string | undefined>();
  const [showFreelanceTechaerDropdown, setShowFreelanceTechaerDropdown] = useState(false);
  const { isAdiSuperAdmin, isAdiAdmin, user, isOrganizationAdiAdmin } = useAuth();
  const [duration1, setDuration1] = useState<DateRange>(dateRangeBase);
  const [duration2, setDuration2] = useState<DateRange>(dateRangeBase);
  const { setDaterange1, setDaterange2, onChangeFilterData, hideTeacherFilter, workshopOnly } = props;
  const builtTeacherTitle = useMemo(() => {
    if (isAdiAdmin || isAdiSuperAdmin) {
      if (workshopOnly) return 'Facilitators';
      else return 'Freelance Teachers';
    }
    return 'Teachers';
  }, [isAdiAdmin, isAdiSuperAdmin, workshopOnly]);
  const [fetchOrganizationData, { data: organizationsData, loading: loadingOrganizations }] = useLazyQuery<{
    getAllOrganizations: GQL_OrganizationResponse[];
  }>(gqlSchema.OrganizationSchema.queries.LIST.getAllOrganizations, {
    onError: (err) => {
      message.error('There was an error loading the organizations: ' + err.message || 'Unexpected Error');
    },
  });

  const [fetchTeachers, { data: teachersData, loading: loadingTeachers }] = useLazyQuery<
    { getAllUsersByFilter: GQL_UserDetailsResponsePaginated },
    { data: GQL_UsersListFilterInput }
  >(gqlSchema.UserSchema.queries.LIST.getAllUsersByFilter, {
    onError: (err) => {
      message.error('There was an error loading users: ' + err.message || 'Unexpected Error');
    },
  });

  const setNewFilter = useCallback(
    (filter: IKpiFilters) => {
      setFilters([...filters.filter((f) => f.field !== filter.field), filter]);
    },
    [filters],
  );

  const removeFilterChip = useCallback(
    (filter: IKpiFilters, id?: string) => {
      if (filter.field === 'tag') {
        const tags = filters.find((f) => f.field === 'tag')?.value as string[];
        setFilters([
          ...filters.filter((f) => f.field !== 'tag'),
          {
            field: 'tag',
            value: [...tags.filter((t) => t !== id)],
          },
        ]);
      } else {
        setFilters(filters.filter((f) => f.value !== filter.value));
      }
    },
    [filters],
  );

  const { data: tagsData, loading: loadingTags } = useQuery<{ getTags: TagResponse[] }>(
    gqlSchema.TagsSchema.queries.getTags,
  );

  useEffect(() => {
    const filterOrg = filters.some((f) => f.value === 'Organizations');
    if (filterOrg) {
      if (!organizationsData?.getAllOrganizations) fetchOrganizationData();
      setShowOrgDropdown(true);
    } else {
      setShowOrgDropdown(false);
      setCurrentOrganization(undefined);
    }

    const filterTeacher = filters.some((f) => f.value === builtTeacherTitle);
    if (filterTeacher && !hideTeacherFilter) {
      if (!teachersData?.getAllUsersByFilter) {
        const dataFilter: { roles: string[]; organizationId?: string } = {
          roles: ['teacher'],
        };
        if (user.subscription?.organizationId) dataFilter.organizationId = user.subscription?.organizationId;
        fetchTeachers({
          variables: {
            data: dataFilter,
          },
        });
      }
      setShowFreelanceTechaerDropdown(true);
    } else {
      setCurrentFreelanceTechaer(undefined);
      setShowFreelanceTechaerDropdown(false);
    }

    if (onChangeFilterData) {
      const newFilterData = {
        organizations: filterOrg,
        teachers: isOrganizationAdiAdmin ? false : workshopOnly ? true : filterTeacher,
        gradeBand: filters.find((f) => f.field === 'grade')?.value as string,
        investigationType: filters.find((f) => f.field === 'subject')?.value as string,
        teacherId: currentFreelanceTechaer,
        organizationId: !filterOrg || isOrganizationAdiAdmin ? undefined : currentOrganization,
        tagIds: (filters.find((f) => f.field === 'tag')?.value as string[]) || [],
      };
      onChangeFilterData(newFilterData);
    }
  }, [
    fetchOrganizationData,
    fetchTeachers,
    filters,
    organizationsData,
    teachersData,
    user,
    isOrganizationAdiAdmin,
    onChangeFilterData,
    currentFreelanceTechaer,
    currentOrganization,
    hideTeacherFilter,
    builtTeacherTitle,
    workshopOnly,
  ]);

  const buildFilterChipText = useCallback(
    (filter: IKpiFilters, key: string) => {
      const lengthStandardFilters = filters.filter((f) => f.field !== 'tag').length || 0;
      const lengthTagFilters = ((filters.find((f) => f.field === 'tag')?.value as string[]) || []).length || 0;
      const filtersCount = lengthStandardFilters + lengthTagFilters;
      const lengthTotal = filtersCount > 4 ? 4 : 24 / filtersCount;
      return (
        <Col sm={12} md={lengthTotal} key={key}>
          <S.FilterChip
            onClick={(e) => {
              e.stopPropagation();
              removeFilterChip(filter, key);
            }}
          >
            <span role="none" >{filter.field === 'grade' ? `${filter.value} Grade` : startCase(filter.value as string)}</span>
            <FiX />
          </S.FilterChip>
        </Col>
      );
    },
    [filters, removeFilterChip],
  );

  const buildFilterChip = useCallback(
    (filter: IKpiFilters) => {
      if (filter.field === 'tag') {
        return ((filter.value as string[]) || []).map((t) => {
          return buildFilterChipText(
            {
              ...filter,
              value: (tagsData?.getTags.find((i) => i.id === t)?.tag as string) || '',
            },
            t,
          );
        });
      } else {
        return buildFilterChipText(filter, filter.field);
      }
    },
    [buildFilterChipText, tagsData],
  );

  const filterByDropdown = useMemo(
    () => (
      <Dropdown
        overlay={
          <Menu>
            {(isAdiSuperAdmin || isAdiAdmin) && !workshopOnly && (
              <SubMenu title="User Type">
                <Menu.Item
                  disabled={filters?.some((filter) => filter.value === 'Organizations')}
                  onClick={() => {
                    setNewFilter({
                      field: 'type',
                      value: 'Organizations',
                    });
                  }}
                >
                  Organizations
                </Menu.Item>
                <Menu.Item
                  disabled={filters?.some((filter) => filter.value === builtTeacherTitle)}
                  onClick={() =>
                    setNewFilter({
                      field: 'type',
                      value: builtTeacherTitle,
                    })
                  }
                >
                  {builtTeacherTitle}
                </Menu.Item>
              </SubMenu>
            )}
            <SubMenu title="Tag">
              {!loadingTags &&
                tagsData?.getTags.map((i) => (
                  <Menu.Item
                    key={i.id}
                    onClick={() => {
                      const newTags = filters?.find((f) => f.field === 'tag');
                      const exist = ((newTags?.value as string[]) || []).find((t) => t === i.id);
                      const currentValues = (newTags?.value as string[]) || [];
                      setFilters([
                        ...filters.filter((f) => f.field !== 'tag'),
                        {
                          field: 'tag',
                          value: newTags
                            ? exist
                              ? [...currentValues.filter((t) => t !== i.id)]
                              : [...currentValues, i.id]
                            : [i.id],
                        },
                      ]);
                    }}
                  >
                    <S.TagButton
                      text={i.tag}
                      block
                      display="inline"
                      background={i.color}
                      shape="round"
                      minHeight={24}
                    />
                  </Menu.Item>
                ))}
            </SubMenu>
            <SubMenu title="Subject">
              <Menu.Item
                onClick={() => {
                  setNewFilter({
                    field: 'subject',
                    value: 'Math',
                  });
                }}
              >
                Math
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setNewFilter({
                    field: 'subject',
                    value: 'Science',
                  });
                }}
              >
                Science
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setNewFilter({
                    field: 'subject',
                    value: 'Engineering',
                  });
                }}
              >
                Engineering
              </Menu.Item>
            </SubMenu>
          </Menu>
        }
        placement="bottomRight"
        trigger={['click']}
      >
        <S.FilterContainer>
          <FiFilter />
          <span role="none" >Filter By</span>
          <Row>{filters.map((filter) => buildFilterChip(filter))}</Row>
          <FiChevronDown />
        </S.FilterContainer>
      </Dropdown>
    ),
    [
      isAdiSuperAdmin,
      isAdiAdmin,
      workshopOnly,
      filters,
      builtTeacherTitle,
      loadingTags,
      tagsData,
      setNewFilter,
      buildFilterChip,
    ],
  );

  return (
    <Row gutter={[16, 8]}>
      <Col xxl={6} xl={8} lg={12} md={24} sm={24}>
        <S.Title>Date Range 1</S.Title>
        <RangePicker
          value={duration1}
          onChange={(duration: Duration) => {
            setDuration1(duration);
            if (setDaterange1) setDaterange1(duration);
          }}
          allowClear={false}
        />
      </Col>
      <Col xxl={6} xl={8} lg={12} md={24} sm={24}>
        <S.Title>Date Range 2</S.Title>
        <RangePicker
          value={duration2}
          onChange={(duration: Duration) => {
            setDuration2(duration);
            if (setDaterange2) setDaterange2(duration);
          }}
          allowClear={false}
        />
      </Col>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24}>
        {filterByDropdown}
      </Col>
      {showOrgDropdown && (
        <Col xxl={6} xl={12} lg={12} md={24} sm={24}>
          <S.Title>Select Organization</S.Title>
          <Select
            placeholder="Select Organization"
            showSearch
            loading={loadingOrganizations}
            value={currentOrganization}
            onChange={(v) => setCurrentOrganization(v as string)}
          >
            {organizationsData?.getAllOrganizations?.map((organization) => (
              <SelectOption value={organization.id} key={organization.id}>
                {organization.name}
              </SelectOption>
            ))}
          </Select>
        </Col>
      )}
      {showFreelanceTechaerDropdown && (
        <Col xxl={6} xl={12} lg={12} md={24} sm={24}>
          <S.Title>Select {builtTeacherTitle}</S.Title>
          <Select
            placeholder="Select Teacher"
            showSearch
            loading={loadingTeachers}
            value={currentFreelanceTechaer}
            onChange={(v) => setCurrentFreelanceTechaer(v as string)}
          >
            {teachersData?.getAllUsersByFilter?.users.map((freelancer) => (
              <SelectOption value={freelancer.id} key={freelancer.id}>
                {freelancer.name}
              </SelectOption>
            ))}
          </Select>
        </Col>
      )}
    </Row>
  );
};

export default FilterDoubleDates;
