import styled from 'styled-components';

export const Card = styled.div`
  width: 100%; /* Reduced width */
  height: 36px; /* Reduced height */
  padding: 3px 8px 3px 4px; /* Reduced padding */
  border-radius: 3px; /* Border radius */
  background: #4367E9; /* Blue background */
  color: white; /* White text */
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.82em;
  margin: 0;
  text-align: center;
  color: white; /* White text color */
`;

export const Title = styled.h3`
  font-weight: 500;
  margin: 0 0 0 6px; /* Adjusted margin to separate the plus sign and text */
  white-space: nowrap; /* Prevent text from wrapping */
  color: white; /* White text color */
  font-size: 14px;
`;

export const Icon = styled.p`
  font-size: 1.5em; /* Reduced icon size */
  margin: 0;
  white-space: nowrap; /* Prevent text from wrapping */
  color: white; /* White text color */
`;
