import styled from 'styled-components';

export const Container = styled.div`
  padding: 4em;
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`;

export const HeaderMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.95em;
  text-align: left;
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const NewLabelContainer = styled.div<{ visible?: boolean }>`
  width: 100%;
  border-radius: 5px;
  background: white;
  border: 1px solid #e6e6e6;
  padding: 24px 32px;
  display: flex;
  ${(props) => !props.visible && 'display: none;'}
`;

export const SelectedColorContainer = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 4px;
  cursor: pointer;
`;

export const LabelInputTitle = styled.h3`
  font-size: 1.1em;
  font-weight: 500;
  margin: 0;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const SearchIcon = styled.span<{
  $searchVisible: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 10px 4px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => (props.$searchVisible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px' : 'unset')};
`;

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const SwitchContainer = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;
