import React, { useCallback, useMemo, useState } from 'react';
import * as S from './styles';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';
import VerifyCodeForm from './VerifyCodeForm';

const ForgotPasswordPage = () => {
  const [step, setStep] = useState(1);
  const history = useHistory();

  const successMessage = useMemo(
    () => (
      <S.ColSP $padding="0" span={12}>
        <Result
          status="success"
          title="You have successfully sent a request to change your password."
          subTitle="You will receive an email with instructions on how to change your password."
          extra={
            <Button type="primary" onClick={() => history.push('./login')}>
              Login
            </Button>
          }
        />
      </S.ColSP>
    ),
    [history],
  );

  const errorMessage = useMemo(
    () => (
      <S.ColSP $padding="0" span={10}>
        <Result
          status="error"
          title="Password Reset Request Failed."
          subTitle="The email you provided does not exist in our database."
          extra={
            <Button type="primary" onClick={() => setStep(1)}>
              Try again
            </Button>
          }
        />
      </S.ColSP>
    ),
    [setStep],
  );
  const formEmail = useMemo(
    () => (
      <S.ColSP xl={10} lg={16} md={18} sm={20} xs={24}>
        <VerifyCodeForm setStep={setStep} />
      </S.ColSP>
    ),
    [],
  );

  const generateFormFromStep = useCallback(() => {
    if (step === 1) {
      return formEmail;
    } else if (step === 2) {
      return successMessage;
    } else if (step === 3) {
      return errorMessage;
    }
  }, [step, successMessage, errorMessage, formEmail]);

  return (
    <S.Container data-cy="components-forgotpasswordpage-container">
      <S.RowSP align="middle" justify="center">
        {generateFormFromStep()}
      </S.RowSP>
    </S.Container>
  );
};

export default ForgotPasswordPage;
