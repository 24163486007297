import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Skeleton, message } from 'antd';

import { SubscriptionPackageType } from '../../../constants/enums/SubscriptionPackageType';
import { GQL_CheckoutProductInfo } from '../../../types/payments';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import { UpgradeModal } from '../UpgradeModal';
import * as S from './styles';

type ProductInfoProps = {
  productId: string;
  fullWidth?: boolean;
  handleOnLoad: () => void;
};

const starterDefault = {
  id: '',
  name: 'Starter',
  description: '',
  price: 0,
  interval: 'year',
};

export const ProductSummary: React.FC<ProductInfoProps> = (props) => {
  const { productId, fullWidth = false, handleOnLoad } = props;
  const { subscriptionPackageType } = useAuth();

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const { data: productData, loading } = useQuery<
    { getProductUpgradeDetails: GQL_CheckoutProductInfo },
    { id: string }
  >(gqlSchema.PaymentsSchema.queries.LIST.getProductUpgradeDetails, {
    variables: {
      id: productId,
    },
    onError: () => {
      // Ignore if starter (since started is hardCoded)
      if (productId !== SubscriptionPackageType.STARTER.toLocaleLowerCase()) {
        message.error('There was an error loading the product data, please check your connection and try again later');
      }
    },
  });

  const isDowngrade = useMemo(() => {
    if (subscriptionPackageType === SubscriptionPackageType.STARTER) {
      return false;
    }
    if (
      subscriptionPackageType === SubscriptionPackageType.PRO &&
      productId === SubscriptionPackageType.STARTER.toLowerCase()
    ) {
      return true;
    }
    if (
      subscriptionPackageType === SubscriptionPackageType.PREMIUM &&
      productId === SubscriptionPackageType.STARTER.toLowerCase()
    ) {
      return true;
    }
    if (
      subscriptionPackageType === SubscriptionPackageType.PREMIUM &&
      productData?.getProductUpgradeDetails.name.toLowerCase() === SubscriptionPackageType.PRO.toLowerCase()
    ) {
      return true;
    }
    return false;
  }, [subscriptionPackageType, productId, productData]);

  const isUpgradeToPremiumFromPro = useMemo(() => {
    return (
      subscriptionPackageType === SubscriptionPackageType.PRO &&
      productData?.getProductUpgradeDetails.name.toLowerCase() === SubscriptionPackageType.PREMIUM.toLowerCase()
    );
  }, [productData, subscriptionPackageType]);

  useEffect(() => {
    if (!loading) {
      handleOnLoad();
    }
  }, [loading, handleOnLoad]);

  const product = useMemo(() => {
    if (productId.toLocaleLowerCase() === SubscriptionPackageType.STARTER.toLocaleLowerCase()) {
      return starterDefault;
    }
    return productData?.getProductUpgradeDetails;
  }, [productData, productId]);

  function formatCurrency(price: number, interval: string) {
    const priceLabel = '$' + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return priceLabel;
  }

  const productInterval = useMemo(() => {
    const intervalLabel =
      product?.interval === 'month' ? 'monthly' : product?.interval === 'year' ? 'yearly' : product?.interval;
    return intervalLabel;
  }, [product]);

  const productPrice = useMemo(() => {
    return formatCurrency(product?.price || 0, product?.interval || '');
  }, [product]);

  const ProductData = useCallback(
    () => (
      <S.ProductSummaryContainer>
        <div className="titles-row">
          <S.ProductDescription>
            <span role="none"  className="title">Items</span>
            <span role="none"  className="title" style={{ marginRight: '5%' }}>
              Price
            </span>
          </S.ProductDescription>
        </div>
        <div className="items-row">
          <S.ProductDescription>
            <S.PlanDetails>
              <span role="none"  className="product-title">{product?.name} Plan</span>
              <S.PlanDetailsButton
                onClick={() => setIsUpgradeModalOpen(true)}
                data-cy="upgrade-view-modal"
                style={{ marginLeft: 20 }}
              >
                <S.SpanDetailButton>{`View ${isDowngrade ? 'downgrades' : 'upgrades'}`}</S.SpanDetailButton>
              </S.PlanDetailsButton>
            </S.PlanDetails>
            <span role="none"  className="product-price">
              {productPrice}
              <span role="none"  className="price-interval"> {productInterval} </span>
            </span>
          </S.ProductDescription>
        </div>

        <div className="total-row">
          {isDowngrade ? (
            <p>
              *Subscription downgrade will go into affect at the end of your current subscription. Price represents
              payment that will be applied when new subscription starts.
            </p>
          ) : null}

          {isUpgradeToPremiumFromPro ? (
            <p>
              *Price represents a prorated rate for the remainder of the year. Payment and and subscription upgrades
              will be applied immediately.
            </p>
          ) : null}

          <div style={{ marginLeft: 'auto', marginRight: '40px' }}>
            <span role="none"  className="product-title" style={{ marginRight: '15px' }}>
              Total
            </span>
            <span role="none"  className="total-price">{productPrice}</span>
          </div>
        </div>
      </S.ProductSummaryContainer>
    ),
    [product, isDowngrade, productPrice, productInterval, isUpgradeToPremiumFromPro],
  );

  return (
    <S.ProductContainer fullWidth={fullWidth}>
      {loading ? <Skeleton.Input active size="large" className="customSkeleton" /> : <ProductData />}
      {isUpgradeModalOpen && (
        <UpgradeModal
          currentPlan={subscriptionPackageType}
          newPlan={product?.name || 'Starter'}
          onClose={() => setIsUpgradeModalOpen(false)}
        />
      )}
    </S.ProductContainer>
  );
};
