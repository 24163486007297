// styles.ts

import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;

  a {
    color: #000;
    text-decoration: none;
    font-size: 12px;
    margin: 0 5px;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    justify-content: center;
    a {
      font-size: 14px;
      margin: 0 10px;
    }
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 3px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    a {
      margin: 0 10px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const CopyrightText = styled.div`
  font-size: 12px;
  color: #767676;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
  }
`;
