import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { message } from 'antd'
import PageWithTitle from '../../shared/PageWithTitle';
import ActiveAssignments from './ActiveAssignments';
import FinishedAssignments from './FinishedAssignments';
import StudentsTable from './StudentsTable';
import ClassInfos from './ClassInfos';
import DashboardRow from '../../shared/DashboardRow';

const CanvasStudentsClassDashboard: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
 
  console.log("Course ID from URL: ", courseId);
  
  const { data: courseData } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasCoursesById
    ,{
      variables: {  course_id:courseId },
      onError: (err) => {
        message.error('There was an error loading class: ' + (err.message || 'Unexpected Error'));
      },
    },
  );

 
  const { data: assignmentsData, loading: assignmentsLoading } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentListByTurnedIn,
    {
      variables: { course_id: [courseId] },
      onError: (err) => {
        message.error('There was an error loading assignments: ' + (err.message || 'Unexpected Error'));
      },
    },
  );
  //TODO: check if teacher and student needs seprate endpoint for this. if not then merge both
  const { data: studentsData, loading: studentLoading } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasStudentList,
    {
      variables: { course_id: courseId, "enrollment_type": "student" },
      onError: (err) => {
        message.error('There was an error loading assignments: ' + (err.message || 'Unexpected Error'));
      },
    },
  );

  const { data: teachersData, loading: teacherLoading } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasTeacherList,
    {
      variables: { course_id: courseId, "enrollment_type": "teacher" },
      onError: (err) => {
        message.error('There was an error loading assignments: ' + (err.message || 'Unexpected Error'));
      },
    },
  );

 
  return (
    //#TODO : Handle back Page URL
    <PageWithTitle title="Class Details" >
      <ClassInfos className={courseData?.getCanvasCoursesById?.name || ''} creationTime={courseData?.getCanvasCoursesById?.created_at} classStudents={studentsData?.getCanvasStudentList?.count || 0} classStatus={courseData?.getCanvasCoursesById?.workflow_state}  />
       
      {/* TODO: Can make Higer Order Component for following Code  */}
      <DashboardRow title="Active Assignments"
        items={<>
          <ActiveAssignments
            assignments={assignmentsData?.getCanvasAssignmentListByTurnedIn?.active || []}
            loading={assignmentsLoading}
            courseData={[courseData?.getCanvasCoursesById]}
          />
        </>}
      />
      <DashboardRow title="Finished Assignments"
        items={<>
          <FinishedAssignments
            assignments={assignmentsData?.getCanvasAssignmentListByTurnedIn?.expired || []}
            loading={assignmentsLoading}
            courseData={[courseData?.getCanvasCoursesById]}
          />
        </>}
      />
      
      <StudentsTable studentsData={studentsData?.getCanvasStudentList } teachersData={teachersData?.getCanvasTeacherList} loading={studentLoading || teacherLoading} />
    </PageWithTitle>
  );
};

export default CanvasStudentsClassDashboard;
