import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Progress, Menu, Dropdown, Input, Spin, message } from 'antd';
import { LinkOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import * as S from './styles';
import UploadInput from '../../../shared/UploadInput';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { RcFile } from 'antd/lib/upload';
import { getProgressColor } from '../../../utils/theme';
//TODO: Move this to appropriate folder
interface AssignmentSummaryProps {
  courseId: string;
  assignmentId: string;
  visible: boolean;
  onClose: () => void;
  isFinished: boolean;
  score?: number;
  assignedGrade?: number;
  courseWorkData: any;
  courseWorkError: any;
  courseWorkLoading: boolean;
  assignment: any;
}

interface Material {
  link?: {
    url: string;
  };
  form?: {
    formUrl: string;
  };
}

const AssignmentSummary: React.FC<AssignmentSummaryProps> = ({ courseId, assignmentId, visible, onClose, isFinished, score, courseWorkData, courseWorkError, courseWorkLoading, assignedGrade, assignment }) => {
  console.log("courseWorkData: ", courseWorkData,);
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [formLinks, setFormLinks] = useState<{ html: string, value: string }[]>([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [studentSubmissionLinks, ] = useState<string[]>([]);
  const [turnedIn,] = useState<boolean>(false);
  const [turnInLoading,] = useState<boolean>(false); // State to track Turn In loading
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleMenuClick = (e: any) => {
    if (e.key === '1') {
      setIsFormModalVisible(true);
    }
  };
  const handleLinkInHtml = (link: string) => {
    if (!link) return '';
    return `<p><a href="${link}">${link}</a></p>`
  }
  const handleFormLinkSave = () => {
    setFormLinks([...formLinks, { value: linkUrl, html: handleLinkInHtml(linkUrl) }]);
    setIsFormModalVisible(false);
    setLinkUrl('');
  };


  // const assignment = courseWorkData?.getCourseWorkById;
  const handleRemoveFormLink = (index: number) => {
    const updatedFormLinks = [...formLinks];
    updatedFormLinks.splice(index, 1);
    setFormLinks(updatedFormLinks);
  };


  const [uploadFileMutation] = useMutation(gqlSchema.GoogleClassroomSchema.mutations.GCLASS.UploadFile, {
    onError: (err) => {
      message.error('Error uploading file: ' + (err.message || 'Unexpected Error'));
    },
  });
  const handleFileUpload = async (payload: { file: File }) => {
    setIsFileUploading(true);
    try {

      const response = await uploadFileMutation({
        variables: {
          file: [payload.file],
          courseId
        },
      });

      if (response.data) {
        const urlLink = [...response.data.uploadFilesForGoogleClassroom]
        setFormLinks((prevLinks) => [...prevLinks, ...urlLink.map(_link => { return { value: _link, html: handleLinkInHtml(_link) } })]);
        message.success(`${payload.file.name} uploaded successfully`);
      } else {
        message.error(`Failed to upload ${payload.file.name}`);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error(`Error uploading ${payload.file.name}`);
    } finally {
      setIsFileUploading(false); // End file upload loader
    }
  };

  const [createAssignment] = useMutation(
    gqlSchema.CanvasSchema.mutations.CANVASCLASS.CreateCanvasSubmission,
    {
      onCompleted: async () => {
        message.success('Assignment submitted successfully');
        onClose();
        //TODO Refetch assignments to update the list of assignments
      },
      onError: (err) => {
        message.error('Error submitting assignment: ' + (err.message || 'Unexpected Error'));
        // setTurnInLoading(false); // Reset loading state on error
      },
    }
  );
  const handleSubmit = async () => {
    if (formLinks.length === 0) {
      message.error('Please add at least one link or file before turning in the assignment.');
      return;
    }
    await createAssignment({
      variables: {
        "course_id": courseId,
        "assignment_id": assignmentId,
        data: {
          "submission": {
            //TODO Handle submission type here
            "submission_type": "online_url",
            //  "url": formLinks.map(_link=> _link.html).join(''),
            "url": formLinks.map(_link => _link.value).join(','),
            body: assignment.assignment_name,
          }
        }
      }
    });
  }
  const handleDescriptionWithLinks = (description: string) => {
    // Regular expression to find URLs in the description
    const urlPattern = /(https?:\/\/[^\s]+)/g;
  
    // Replace plain URLs with clickable anchor tags
    const descriptionWithLinks = description.replace(urlPattern, (url) => {
      let newLink = url;
        const courseWorkParams = `?courseId=${courseId}&assignmentId=${assignmentId}`;
        newLink += courseWorkParams;
      return `<a href="${newLink}">${newLink}</a>`;
    });
  
    return descriptionWithLinks;
  };

  const shouldHideTurnInButton = (): boolean => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const descriptionLinks = assignment?.description?.match(urlPattern) || [];
    
    return descriptionLinks.some((link: string) =>
      link.includes('/teacher-dashboard/investigation-summary/') ||
      link.includes('/student-investigation/') ||
      link.includes('/teacher-dashboard/assessment-summary/') ||
      link.includes('/student-assessment/')
    );
  };
  
    
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon>
        <Button style={{ border: '2px solid #d9d9d9', borderRadius: '4px', width: '100%' }}>
          <LinkOutlined style={{ marginRight: '1px' }} />
          Link
        </Button>
      </Menu.Item>
      <Menu.Item key="2" icon>
        <UploadInput
          title="Upload File"
          showClear={false}
          onClear={() => {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          }}
          customRequest={(options) => {
            const file = options.file as RcFile;
            handleFileUpload({ file });
            return true;
          }}
        />
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <S.SummaryContainer>
        <Spin spinning={isFileUploading} tip="Uploading file..." style={{ display: isMobile? 'block' :'flex', justifyContent: 'center', alignItems: 'center' }}>
          <S.Title>Assignment Summary</S.Title>
          {assignment ? (
            <>
              <S.Row>
                <div>
                  <S.InfoLabel>Assignment Name:</S.InfoLabel>
                  <S.InfoValue>{assignment.assignment_name}</S.InfoValue>
                </div>
                <div>
                  <S.InfoLabel>Start Date: Filer date</S.InfoLabel>
                  <S.InfoValue>{assignment.created_at ? new Date(assignment.created_at).toLocaleDateString() : 'N/A'}</S.InfoValue>
                </div>
                <div>
                  <S.InfoLabel>End Date:</S.InfoLabel>
                  <S.InfoValue>{assignment.due_at ? new Date(assignment.due_at).toLocaleDateString() : 'N/A'}</S.InfoValue>
                </div>
              </S.Row>
              <S.Row>
                <div>
                  <S.InfoLabel>Points:</S.InfoLabel>
                  <S.InfoValue>{assignment.points_possible ?? "--"}</S.InfoValue>
                </div>
              </S.Row>
              <S.InfoRow>
                <S.InfoLabel>Description:</S.InfoLabel>
                <S.InfoValue dangerouslySetInnerHTML={{ __html: handleDescriptionWithLinks(assignment.description) }} />

              </S.InfoRow>
              <S.InfoRow>
                {/* <S.InfoLabel>Links:</S.InfoLabel> */}
                <S.AssignmentLinks>
                  {assignment.materials && assignment.materials.map((material: Material, index: number) => (
                    <div key={index}>
                      {material.link && <a href={material.link.url} target="_blank" rel="noopener noreferrer">{material.link.url}</a>}
                      {material.form && <a href={material.form.formUrl} target="_blank" rel="noopener noreferrer">{material.form.formUrl}</a>}
                    </div>
                  ))}
                </S.AssignmentLinks>
              </S.InfoRow>
              {isFinished && (
                <S.InfoRow>
                  <S.InfoLabel>Submission Links:</S.InfoLabel>
                  <S.AssignmentLinks>
                    {courseWorkData?.url && courseWorkData.url.split(',').map((link: string, index: number) => (
                      <div key={index}>
                        <LinkOutlined style={{ marginRight: '8px' }} />
                        <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                      </div>
                    ))}
                  </S.AssignmentLinks>
                </S.InfoRow>
              )}
              {isFinished && (
                <S.ProgressContainer>
                  <Progress
                    type="circle"
                    percent={score ?? 0}
                    width={145}
                    strokeWidth={9}
                    strokeLinecap="round"
                    style={{ marginTop: 20 }}
                    strokeColor={getProgressColor(score || 0)}
                    format={(percent) => (
                      <>
                        <S.Info $bold>{percent !== undefined ? Math.round(percent) : 0}%</S.Info>
                        <S.InfoProgress>Total Score: {assignment?.points_possible ?? "--"}</S.InfoProgress>
                      </>
                    )}
                  />
                </S.ProgressContainer>

              )}
              {!isFinished && (
                <>
                  <S.InfoRow>
                    <S.InfoLabel>Add Links & Files:</S.InfoLabel>
                    <Dropdown overlay={menu} trigger={['click']} disabled={isFileUploading || turnedIn}>
                      <Button type="primary" disabled={isFileUploading || turnedIn}>
                        Attach File <UploadOutlined />
                        {isFileUploading && <Spin size="small" style={{ marginLeft: 8 }} />}
                      </Button>
                    </Dropdown>
                  </S.InfoRow>
                  <ul>
                    {formLinks.map((formLink, index) => (
                      <li key={index}>
                        <a href={formLink.value} target="_blank" rel="noopener noreferrer">{formLink.value}</a>
                        <Button type="text" icon={<DeleteOutlined />} onClick={() => handleRemoveFormLink(index)} />
                      </li>
                    ))}
                  </ul>
                  <S.ButtonContainer>
                    <Button onClick={onClose}>Cancel</Button>
                    {!turnedIn && !shouldHideTurnInButton() && (
                      <Button type="primary" onClick={() => handleSubmit()} loading={turnInLoading}>
                        Turn In
                      </Button>
                    )}
                  </S.ButtonContainer>
                </>
              )}
              {isFinished && (
                <>
                  <ul>
                    {formLinks.map((formLink, index) => (
                      <li key={index}>
                        <a href={formLink.value} target="_blank" rel="noopener noreferrer">{formLink.value}</a>
                        <Button type="text" icon={<DeleteOutlined />} onClick={() => handleRemoveFormLink(index)} />
                      </li>
                    ))}
                  </ul>
                  <S.ButtonContainer>
                    <Button onClick={onClose}>Close</Button>
                    <Button type="primary" disabled>
                      Turned In
                    </Button>
                    {/* <Button type="primary" danger loading={turnInLoading} onClick={() => { }} disabled={assignedGrade !== null}>
                      Unsubmit
                    </Button> */}
                  </S.ButtonContainer>
                </>
              )}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </Spin>
      </S.SummaryContainer>
      <Modal
        title="Add Form Link"
        visible={isFormModalVisible}
        onOk={handleFormLinkSave}
        onCancel={() => setIsFormModalVisible(false)}
      >
        <Input
          placeholder="Form Link URL"
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default AssignmentSummary;
