import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const Container = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

export const UnexpectedErrorBox = styled.div`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background: #ffffff;
  width: 80%;
  max-width: 992px;

  @media (max-width: 992px) {
    padding: 40px;
    height: 100vh;
    width: 100%;
  }

  p {
    margin-bottom: 8px;
    font-size: 16px;
    max-width: 600px;
    color: #63625e;
    line-height: 21px;
    letter-spacing: 0.04em;
    font-style: normal;
    font-weight: 500;
    text-align: center;
  }

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: 0.04em;
    text-decoration-line: underline;
    text-transform: uppercase;
    margin-bottom: 25px;
    margin-top: -10px;

    color: #63625e;
  }
`;

export const LogoImage = styled.img`
  width: 100%;
  margin-bottom: 16px;
  max-width: 480px;
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: 800;
  font-size: 2em;
  @media (max-width: 1200px) {
    font-size: 1.8em;
  }
`;
