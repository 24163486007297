import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import Spacer from '../Spacer';
import * as S from './styles';

interface IDashboardLoadingCard {
  cardStyle?: 'class' | 'investigation';
}

const DashboardLoadingCard = (props: IDashboardLoadingCard) => {
  const { cardStyle = 'investigation' } = props;

  if (cardStyle === 'class') {
    return (
      <S.Card>
        <Row align="middle" justify="space-between" gutter={[0, 20]}>
          <Col span={5} style={{ textAlign: 'left' }}>
            <Skeleton.Avatar active size="large" style={{ width: 55, height: 55 }} />
          </Col>
          <Col span={10}>
            <Skeleton.Input active size="large" />
          </Col>
          <Col span={12}>
            <Skeleton.Input active />
          </Col>
        </Row>
        <Spacer axis="vertical" />
        <Row justify="center">
          <Col span={16}>
            <Skeleton.Input active size="small" />
          </Col>
        </Row>
        <Row style={{ marginTop: '2.5em' }} justify="center">
          <Col span={12} className="middle">
            <Skeleton.Input active />
          </Col>
        </Row>
      </S.Card>
    );
  } else {
    return (
      <S.Card>
        <Row align="middle" justify="space-between" gutter={[0, 20]}>
          <Col span={5} style={{ textAlign: 'left' }}>
            <Skeleton.Avatar active size="large" style={{ width: 55, height: 55 }} />
          </Col>
          <Col span={10}>
            <Skeleton.Input active />
          </Col>
          <Col span={5} style={{ textAlign: 'left' }}>
            <Skeleton.Avatar active style={{ width: 55, height: 55 }} />
          </Col>
          <Col span={24}>
            <Skeleton.Input active />
          </Col>
          <Col span={24}>
            <Skeleton.Input active size="small" />
          </Col>
        </Row>
        <Row style={{ marginTop: '2em' }}>
          <Col span={12}>
            <Skeleton.Button active />
          </Col>
          <Col span={12} className="middle">
            <Skeleton.Input active />
          </Col>
        </Row>
      </S.Card>
    );
  }
};

export default DashboardLoadingCard;
