export enum SUBJECT_TYPES {
  MATH = 'Math',
  SCIENCE = 'Science',
  ENGINEERING = 'Engineering',
  SCIENCE_TEXAS_EDITION = 'Science:_Texas_Edition_(2024_Adoption)',
  // NGSS_STANDARDS = 'NGSS_STANDARDS',
}

export enum SUBJECT_TYPES_ROUTES {
  MATH = 'math',
  SCIENCE = 'science',
  ENGINEERING = 'engineering',
  SCIENCE_TEXAS_EDITION = 'scienceTexasEdition',
}
