/* eslint-disable max-lines */
import { cloneDeep, last } from 'lodash';
import {
  IPeerReviewQuestionnaireContentValue,
  ITextContentValue,
  ITextContentSpan,
  GQL_InvestigationCatalogActivityEntry,
  GQL_InvestigationStep,
  GQL_PeerReviewQuestionnaire,
  GQL_InvestigationActivity,
  IUploadContentValue,
  ITextSubmitContentValue,
  IPeerReviewContentValue,
  IReflectionContentValue,
  GQL_InvestigationDisplay,
  GQL_InvestigationBlockTypes,
  IBudgetCalculatorContentValue,
  ICanvasContentValue,
  IConceptEvaluationContentValue,
  GQL_InvestigationContent,
  GQL_InvestigationDashboard,
} from '../types/investigation';

export const gradingFinalReportAnswers = [
  { value: 0, text: '0', tooltip: 'Missing' },
  { value: 1, text: '1', tooltip: 'Needs Improvement' },
  { value: 2, text: '2', tooltip: 'Meets Expectations' },
  { value: 3, text: '3', tooltip: 'Exceeds Expectations' },
];

export const gradingEngagementAnswers = [
  { value: 0, text: '1', tooltip: 'Never' },
  { value: 1, text: '2', tooltip: 'Rarely' },
  { value: 2, text: '3', tooltip: 'Sometimes' },
  { value: 3, text: '4', tooltip: 'Often' },
  { value: 4, text: '5', tooltip: 'Always' },
];

/**
 * This function will receive a content value from an investigation block,
 * insert all spans into the text, and return the text with corresponding
 * tags
 */
export const applySpanToContentValueText = (value: ITextContentValue): string => {
  let offset = 0;
  let text = value.text;

  // First, create an array with all needed replacements from spans array,
  // where if onStart is true, it means that it's an opening tag,
  // and it's a closing tag otherwise
  const replacements: {
    pos: number;
    onStart: boolean;
    type: string;
    url?: string;
    meta?: string;
    index: number;
  }[] = [];

  value.spans?.forEach((span, index) =>
    replacements.push(
      ...[
        { pos: span.start, onStart: true, type: span.type, url: span.url, meta: span.meta, index },
        { pos: span.end === -1 ? span.start : span.end, onStart: false, type: span.type, index },
      ],
    ),
  );

  // Sorts all replacements so we always write from left to write
  // into the string
  // eslint-disable-next-line complexity
  replacements.sort((a, b) => {
    if (a.pos > b.pos) return 1;
    else if (a.pos < b.pos) return -1;
    else if (a.index === b.index) {
      if (a.onStart && !b.onStart) return -1;
      else if (b.onStart && !a.onStart) return 1;
      else return 0;
    } else {
      if (a.type === 'alignment' && b.type === 'list-item' && a.onStart && b.onStart) return 1;
      else if (a.type === 'alignment' && b.type === 'list-item' && !b.onStart && !a.onStart) return -1;
      else if ((a.type === 'unordered-list' || a.type === 'ordered-list') && !a.onStart && !b.onStart) return 1;
      else if ((b.type === 'unordered-list' || b.type === 'ordered-list') && !b.onStart && !a.onStart) return -1;
      else if (a.type === 'formula' && b.type === 'alignment' && !b.onStart) return -1;
      else return 0;
    }
  });

  // Here we update the text with all tags needed, always from
  // left to right, so we can set an offset and update based on
  // that offset, for example:
  // Consider the string: 'abcd' that needs the tag <a> into position 0
  // and tag <b> into position 3. The final string must be '<a>abc<b>d'
  //
  // So first we will insert the <a> tag into position 0 with offset 0
  // and the result string will be '<a>abcd'. Since we added 3 characters
  // to the left, all subsequent insertions must have offset of 3.
  // Now, when inserting the b tag on position 3, we consider an offset of 3,
  // so we actually insert into position 6, which will give us the desired
  // string, giving now an offset of 6.
  replacements.forEach((replacement) => {
    let tag = '';

    if (replacement.type === 'strong') {
      tag = replacement.onStart ? '<strong>' : '</strong>';
    } else if (replacement.type === 'hyperlink') {
      tag = replacement.onStart ? `<a href=${replacement.url} target='_blank'>` : '</a>';
    } else if (replacement.type === 'italic') {
      tag = replacement.onStart ? '<i>' : '</i>';
    } else if (replacement.type === 'underline') {
      tag = replacement.onStart ? '<u>' : '</u>';
    } else if (replacement.type === 'alignment') {
      tag = replacement.onStart ? `<p class="editor-align-${replacement.meta}">` : '</p>';
    } else if (replacement.type === 'unordered-list') {
      tag = replacement.onStart ? `<ul>` : '</ul>';
    } else if (replacement.type === 'ordered-list') {
      tag = replacement.onStart ? `<ol>` : '</ol>';
    } else if (replacement.type === 'list-item') {
      tag = replacement.onStart ? `<li>` : '</li>';
    } else if (replacement.type === 'formula') {
      tag = replacement.onStart ? `<formula formula="${replacement.meta}">` : '</formula>';
    }

    text = text.slice(0, replacement.pos + offset) + tag + text.slice(replacement.pos + offset);

    offset += tag?.length;
  });

  return text;
};

/**
 * Auxiliar function that receives a HTML tag name and returns
 * corresponding type on content value type
 */
const tagToType = (tag: string) => {
  const sanitizedTag = tag.replace(/[</>]/g, '').split(' ')[0];
  if (sanitizedTag === 'strong') return 'strong';
  else if (sanitizedTag === 'em' || sanitizedTag === 'i') return 'italic';
  else if (sanitizedTag === 'u') return 'underline';
  else if (sanitizedTag === 'a') return 'hyperlink';
  else if (sanitizedTag === 'formula') return 'formula';
  else if (sanitizedTag === 'p') return 'alignment';
  else if (sanitizedTag === 'ul') return 'unordered-list';
  else if (sanitizedTag === 'ol') return 'ordered-list';
  else if (sanitizedTag === 'li') return 'list-item';
};

/**
 * This function will do the opposite of 'applySpanToContentValueText' functions,
 * so it takes an HTML formatted text and convert into investigation block value format
 */
export const convertHTMLIntoTextContentValue = (value: string): ITextContentValue => {
  // Adds an invisible char on empty tags
  const textWithInvisibleChars = value.replace(/(<formula[^/]*?>)(<\/formula>)/g, '$1a$2');

  // Removes all HTML tags
  const sanitizedText = textWithInvisibleChars.replace(/<.*?>/g, '');
  const spans: ITextContentSpan[] = [];

  const matches = textWithInvisibleChars.matchAll(/<.*?>/g);
  let match = matches.next();
  let offset = 0;

  while (!match.done) {
    const text = match.value[0];
    const index = match.value.index || 0;
    const type = tagToType(text);

    if (type) {
      if (text.slice(0, 2) === '</') {
        // If it's a closing tag, we go through the array backwards,
        // and the first span found is actually the last one added, so
        // the closing tag refers to that one
        for (let i = spans.length - 1; i >= 0; i--) {
          const span = spans[i];

          if (span.type === type && span.end === -1) {
            span.end = index - offset;
            break;
          }
        }
      } else {
        const href = text.match(/href="(.*?)"/i);
        const meta = type === 'alignment' ? text.match(/class="(.*?)"/i) : text.match(/formula="(.*?)"/i);

        spans.push({
          start: index - offset,
          type,
          url: href ? href[1] : undefined,
          meta: meta ? meta[1].replace('editor-align-', '') : undefined,
          end: -1,
        });
      }
    }

    offset += text.length;
    match = matches.next();
  }

  return {
    spans,
    text: sanitizedText,
  };
};

/**
 * From a graphql comment, format the timestamp if it has one
 * and return it with the comment text
 */
export const formatCommentText = (payload: { text: string; timestamp?: number }) => {
  const { text, timestamp } = payload;

  if (timestamp === undefined || timestamp === null) return text;

  const timeStampMinutes = Math.round(timestamp / 60);
  const timeStampSeconds = Math.round(timestamp % 60)
    .toString()
    .padStart(2, '0');

  return `[${timeStampMinutes}:${timeStampSeconds}] ${text}`;
};

/**
 * Transform the CBValuePeerReviewQuestionnaire GraphQL type into
 * the expected type for the Questionnaire component
 */
export const peerReviewQuestionToQuestionnaire = (payload: {
  values: IPeerReviewQuestionnaireContentValue[];
  questionnaire?: GQL_PeerReviewQuestionnaire[];
}) => {
  const { values, questionnaire } = payload;
  return (
    (values?.length &&
      values[0].questionnaire?.map((question, questionnaireIndex) => ({
        title: question?.title || '',
        needsFeedback: true,
        feedback: questionnaire
          ? questionnaire?.find((q) => q.questionIndex === questionnaireIndex)?.feedback
          : question?.feedback,
        subquestions: question?.questions?.map((q, answerIndex) => ({
          text: q.question || '',
          answer: questionnaire
            ? questionnaire
                ?.find((q) => q.questionIndex === questionnaireIndex)
                ?.answers?.find((a) => a.answerIndex === answerIndex)?.answer
            : q.answer,
          isValid: true,
        })),
      }))) ||
    []
  );
};

export const transformActivityValuesIntoHTML = (activity: GQL_InvestigationCatalogActivityEntry) => {
  const _parseValues = (content: GQL_InvestigationContent[]) => {
    return content.map((content) => ({
      ...content,
      blocks: content.blocks.map((block) => ({
        ...block,
        values: block.values.map((value) => {
          const textValue = value as ITextContentValue;
          if (
            textValue.text === null ||
            textValue.text === undefined ||
            !textValue.spans?.length ||
            textValue.type === 'button'
          )
            return value;
          else {
            return {
              ...value,
              spans: [],
              text: applySpanToContentValueText(textValue),
            };
          }
        }),
      })),
    }));
  };

  return {
    ...activity,
    content: _parseValues(activity.content),
    contentInPerson: _parseValues(activity.contentInPerson),
  };
};

export const transformActivityValuesIntoSpans = (activity: GQL_InvestigationCatalogActivityEntry) => {
  const _parseValues = (content: GQL_InvestigationContent[]) => {
    return content.map((content) => ({
      ...content,
      blocks: content.blocks.map((block) => ({
        ...block,
        values: block.values.map((value) => {
          const valueAsText = value as ITextContentValue;
          if (!valueAsText.text || valueAsText.type === 'button') return value;
          else {
            return {
              ...value,
              ...convertHTMLIntoTextContentValue(valueAsText.text),
            };
          }
        }),
      })),
    }));
  };

  return {
    ...activity,
    content: _parseValues(activity.content),
    contentInPerson: _parseValues(activity.contentInPerson),
  };
};

const recursiveCleanup = (cleanupArray: any[], toClear: string[], obj: any) => {
  if (typeof obj === 'object' && !!obj) {
    Object.keys(obj).forEach((k) => {
      if (toClear.some((c) => c === k)) {
        delete obj[k];
      } else if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
        recursiveCleanup(cleanupArray, toClear, obj[k]);
      } else if (Array.isArray(obj[k])) {
        for (const e of obj[k]) {
          recursiveCleanup(cleanupArray, toClear, e);
        }
      }
    });
  }
};

export const clearKeyFromObject = <T extends object>(obj: T, key = ['__typename']): T => {
  const _obj = cloneDeep(obj);
  const TO_CLEAR = key;
  const objectsToClear: any[] = [];
  recursiveCleanup(objectsToClear, TO_CLEAR, _obj);
  return _obj;
};

/**
 * Receives a stage and returns the first activity that has a submission block,
 * which can be Peer Review, Reflection, File Submission and others...
 *
 * If no activity with that blocks is found, return the first activity
 */
export const getActivityWithSubmission = (stage: GQL_InvestigationStep) => {
  const SUBMISSION_BLOCK_TYPES = [
    'Upload',
    'Reflection',
    'PeerReview',
    'Comment',
    'PeerReviewQuestionnaire',
    'ReflectionQuestionnaire',
    'Canvas',
    'BudgetCalculator',
    'ConceptEvaluation',
    'ConceptRating',
    'BestConceptPeerReview',
    'BestConceptReflection',
  ];

  let submissionBlockIndex;
  const submissionActivity = stage.activities.find((activity, i) => {
    const foundIndex = activity.content.findIndex((content) => {
      return content.blocks.some((block) => SUBMISSION_BLOCK_TYPES.includes(block.type));
    });

    if (foundIndex === -1) return false;
    else {
      submissionBlockIndex = activity.completed || stage.activities[i - 1]?.completed ? foundIndex : 0;
      return activity.completed || stage.activities[i - 1]?.completed;
    }
  });

  return { activity: submissionActivity || stage.activities[0], contentIndex: submissionBlockIndex || 0 };
};

/**
 * Auxiliar function to filter for finished/active investigations.
 * Investigation is considered finished in either of these 3 cases:
 * It's 100% completed, it's overdue or the last step is completed
 */
export const finishedInvestigationFilter = (investigation: {
  title: string;
  dueDate: number;
  completion: number;
  steps?: { completed: boolean }[];
  students?: Record<string, any>[];
}) => {
  // check if there is a student with a different end date, if date it's on the future
  // the assessment / investigation should remain open
  const studentOpen = (investigation?.students || [])?.find((student) => student.endDate >= Date.now());

  const hasSteps = investigation?.steps && investigation?.steps[(investigation?.steps?.length ?? 0) - 1]?.completed;
  return investigation.completion === 1 || investigation.dueDate < Date.now() || (hasSteps && !studentOpen);
};

/**
 * Auxiliary function to filter for closed assessments.
 */
export const isAssessmentClosed = (assessment: GQL_InvestigationDashboard) => {
  if (assessment.classStudent) {
    return Date.now() < assessment.classStudent?.startDate || Date.now() > assessment.classStudent?.endDate;
  }
  return false;
};

/**
 * Auxiliary function to filter for not yet opened assessments.
 */
export const isAssessmentNotYetOpened = (assessment: GQL_InvestigationDashboard) => {
  if (assessment.classStudent) {
    return Date.now() < assessment.classStudent?.startDate;
  }
  return false;
};

/**
 * Auxiliary function to filter for started assessments.
 * @param assessment
 */
export const isAssessmentStarted = (assessment: GQL_InvestigationDashboard) => {
  return assessment.completion === 0 && assessment?.steps?.some((step) => step.completed);
};

/**
 * Auxiliary function to filter for finished assessments.
 * @param assessment
 */
export const isAssessmentFinished = (assessment: GQL_InvestigationDashboard) => {
  return Boolean(assessment.completion === 1 && last(assessment?.steps)?.completed);
};

/**
 * Auxiliary function to filter for active assessments.
 * @param assessment
 */
export const finishedAssessmentFilter = (assessment: GQL_InvestigationDashboard) => {
  // If the assessment is 100% completed, it's overdue or the last step is completed, it's finished
  const completed = assessment.completion === 1 || (assessment?.steps && last(assessment?.steps)?.completed);
  const isOverdue = assessment.classStudent
    ? assessment.classStudent?.endDate < Date.now()
    : assessment.dueDate < Date.now();
  const isLocked = assessment.classStudent?.isLocked || false;

  // Will happen if teacher unlock the assessment for a student and the assessment has not reached the close date
  const completedButStillOnDate = completed && !isOverdue;
  if (completedButStillOnDate && !isLocked) return false;

  return isOverdue || completed || isLocked;
};

/**
 * Determines if student can proceed based on if he has a pending peer review or reflection on previous stages
 */
// eslint-disable-next-line complexity
export const hasPendingReviewableBlock = (payload: { investigation?: GQL_InvestigationDisplay }) => {
  const { investigation } = payload;
  let canProceed, cantProceedReason;
  let lastInProgressStepIndex = -1;
  investigation?.steps?.forEach((step, stepIndex) => {
    if (step.activities.some((a) => a.completed)) lastInProgressStepIndex = stepIndex;
  });

  let lastCompletedActivityIndex = -1;
  investigation?.steps[lastInProgressStepIndex]?.activities?.forEach((activity, activityIndex, arr) => {
    if (activity.completed) {
      if (activityIndex === arr.length - 1) {
        // last activity is completed, which means that you can go to next step
        lastCompletedActivityIndex = 0;
        lastInProgressStepIndex++;
      } else {
        lastCompletedActivityIndex = activityIndex;
      }
    }
  });

  if (lastCompletedActivityIndex === -1 || lastInProgressStepIndex === -1) {
    return { canProceed: true };
  }

  // Can't proceed if some old peer review or reflection is ready and undone
  const peerReviewStepIndex = investigation?.steps?.findIndex((step) =>
    step.activities?.some((activity) =>
      (step?.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.some((content) =>
        content?.blocks?.some((block) => {
          if (block.type === 'PeerReview' || block.type === 'PeerReviewQuestionnaire') {
            return block.values?.some((value) => {
              const peerReviewValue = value as IPeerReviewContentValue;
              return peerReviewValue?.readyToReview && !peerReviewValue?.completedAt;
            });
          } else {
            return false;
          }
        }),
      ),
    ),
  );

  const reflectionStepIndex = investigation?.steps?.findIndex((step) =>
    step.activities?.some((activity, activityIndex) =>
      (step?.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.some((content) =>
        content?.blocks?.some((block) => {
          // Checks if peer review step is completed, otherwise you can be stuck there
          if (!step.activities[activityIndex - 1]?.completed) {
            return false;
          } else if (block.type === 'Reflection' || block.type === 'ReflectionQuestionnaire') {
            return block.values?.some(
              (value) =>
                !(value as IReflectionContentValue).reflectionCompletedAt &&
                !(value as IReflectionContentValue).review?.reflectionCompletedAt &&
                (value as IReflectionContentValue).review?.completedAt,
            );
          } else {
            return false;
          }
        }),
      ),
    ),
  );

  const conceptEvaluationStepIndex = investigation?.steps?.findIndex((step) =>
    step.activities?.some((activity, activityIndex) =>
      (step?.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.some((content) =>
        content?.blocks?.some((block) => {
          // Checks if evaluation step is completed, otherwise you can be stuck there
          if (!step.activities[activityIndex - 1]?.completed) {
            return false;
          } else if (block.type === 'ConceptEvaluation') {
            return block.values?.some(
              (value) =>
                !(value as IConceptEvaluationContentValue).completedAt &&
                (value as IConceptEvaluationContentValue).readyToReview,
            );
          } else {
            return false;
          }
        }),
      ),
    ),
  );

  const conceptRatingStepIndex = investigation?.steps?.findIndex((step) =>
    step.activities?.some((activity, activityIndex) =>
      (step?.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.some((content) =>
        content?.blocks?.some((block) => {
          // Checks if rating step is completed, otherwise you can be stuck there
          if (!step.activities[activityIndex - 1]?.completed) {
            return false;
          } else if (block.type === 'ConceptRating') {
            return block.values?.some(
              (value) =>
                !(value as IConceptEvaluationContentValue).completedAt &&
                (value as IConceptEvaluationContentValue).readyToReview,
            );
          } else {
            return false;
          }
        }),
      ),
    ),
  );

  const conceptPeerReviewStepIndex = investigation?.steps?.findIndex((step, stepIndex) =>
    step.activities?.some((activity, activityIndex) =>
      (step?.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.some((content) =>
        content?.blocks?.some((block) => {
          if (
            lastInProgressStepIndex < stepIndex ||
            (lastInProgressStepIndex === stepIndex && lastCompletedActivityIndex < activityIndex - 1)
          ) {
            return false;
          } else if (step.activities[activityIndex - 1] && !step.activities[activityIndex - 1]?.completed) {
            return false;
          } else if (block.type === 'BestConceptPeerReview') {
            return (
              block.values?.length &&
              block.values?.some(
                (value) =>
                  !(value as IConceptEvaluationContentValue).completedAt &&
                  (value as IConceptEvaluationContentValue).readyToReview,
              )
            );
          } else {
            return false;
          }
        }),
      ),
    ),
  );

  const conceptReflectionStepIndex = investigation?.steps?.findIndex((step, stepIndex) =>
    step.activities?.some((activity, activityIndex) =>
      (step?.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.some((content) =>
        content?.blocks?.some((block) => {
          if (
            lastInProgressStepIndex < stepIndex ||
            (lastInProgressStepIndex === stepIndex && lastCompletedActivityIndex < activityIndex - 1)
          ) {
            return false;
          }
          // Checks if rating step is completed, otherwise you can be stuck there
          else if (step.activities[activityIndex - 1] && !step.activities[activityIndex - 1]?.completed) {
            return false;
          } else if (block.type === 'BestConceptReflection') {
            return (
              block.values?.length &&
              block.values?.some(
                (value) =>
                  !(value as IConceptEvaluationContentValue).completedAt &&
                  (value as IConceptEvaluationContentValue).readyToReview,
              )
            );
          } else {
            return false;
          }
        }),
      ),
    ),
  );

  const groupBestConceptStepIndex = investigation?.steps?.findIndex((step, stepIndex) =>
    step.activities?.some((activity, activityIndex) =>
      (step?.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.some((content) =>
        content?.blocks?.some((block) => {
          if (
            lastInProgressStepIndex < stepIndex ||
            (lastInProgressStepIndex === stepIndex && lastCompletedActivityIndex < activityIndex - 1)
          ) {
            return false;
          } else if (!investigation?.steps[stepIndex - 1]?.completed) {
            return false;
          } else if (block.type === 'GroupBestConcept') {
            return !block.values?.length;
          } else {
            return false;
          }
        }),
      ),
    ),
  );

  if (investigation?.steps && peerReviewStepIndex !== undefined && peerReviewStepIndex !== -1) {
    canProceed = false;
    cantProceedReason = `Complete pending peer review on ${investigation?.steps[peerReviewStepIndex]?.name} stage before proceeding`;
  } else if (investigation?.steps && reflectionStepIndex !== undefined && reflectionStepIndex !== -1) {
    canProceed = false;
    cantProceedReason = `Complete pending reflection on ${investigation?.steps[reflectionStepIndex]?.name} stage before proceeding`;
  } else if (investigation?.steps && conceptEvaluationStepIndex !== undefined && conceptEvaluationStepIndex !== -1) {
    canProceed = false;
    cantProceedReason = `Complete pending concept evaluation on ${investigation?.steps[conceptEvaluationStepIndex]?.name} stage before proceeding`;
  } else if (investigation?.steps && conceptRatingStepIndex !== undefined && conceptRatingStepIndex !== -1) {
    canProceed = false;
    cantProceedReason = `Complete pending concept rating on ${investigation?.steps[conceptRatingStepIndex]?.name} stage before proceeding`;
  } else if (investigation?.steps && conceptPeerReviewStepIndex !== undefined && conceptPeerReviewStepIndex !== -1) {
    canProceed = false;
    cantProceedReason = `Complete pending concept peer review on ${investigation?.steps[conceptPeerReviewStepIndex]?.name} stage before proceeding`;
  } else if (investigation?.steps && conceptReflectionStepIndex !== undefined && conceptReflectionStepIndex !== -1) {
    canProceed = false;
    cantProceedReason = `Complete pending concept reflection on ${investigation?.steps[conceptReflectionStepIndex]?.name} stage before proceeding`;
  } else if (investigation?.steps && groupBestConceptStepIndex !== undefined && groupBestConceptStepIndex !== -1) {
    canProceed = false;
    cantProceedReason = `Please, wait for all your group to define their best concept`;
  }

  return canProceed === false ? { canProceed, cantProceedReason } : undefined;
};

/**
 * Determines if student can proceed to the next activity or not
 */
// eslint-disable-next-line complexity, max-statements
export const canStudentGoToNextStep = (payload: {
  investigation?: GQL_InvestigationDisplay;
  currentActivity?: GQL_InvestigationActivity;
  currentContentIndex: number;
  hasWrittenComment: boolean;
  hasSortedBoard: boolean;
  hasSelected: boolean;
  isInPerson: boolean;
}) => {
  const {
    currentActivity,
    currentContentIndex,
    hasWrittenComment,
    investigation,
    isInPerson,
    hasSortedBoard,
    hasSelected,
  } = payload;
  const contentList = isInPerson ? currentActivity?.contentInPerson : currentActivity?.content;
  let canProceed = contentList?.length && currentContentIndex === contentList.length - 1;

  const pendingReviewableBlock = hasPendingReviewableBlock({ investigation });

  if (pendingReviewableBlock?.canProceed === false) return pendingReviewableBlock;

  if (canProceed) {
    // Comment Block Logic
    const hasCommentBlock = contentList?.some((content) => content.blocks.some((block) => block.type === 'Comment'));
    if (hasCommentBlock) {
      canProceed = hasWrittenComment;
      if (!canProceed) return { canProceed: false, cantProceedReason: 'Engage in a discussion before proceeding' };
    }

    // Upload Block Logic
    const uploadContent = contentList?.find((content) => content.blocks.some((block) => block.type === 'Upload'));
    if (uploadContent) {
      const uploadBlock = uploadContent.blocks.find((block) => block.type === 'Upload');
      const uploadValue = uploadBlock?.values?.length && (uploadBlock.values[0] as IUploadContentValue);

      // Can only proceed if has submmited file, aka has url for file
      canProceed = uploadValue && !!(uploadValue.url && uploadValue.url !== '/');
      if (!canProceed) return { canProceed: false, cantProceedReason: 'Submit your plan before proceeding' };
    }

    // Final Submission Block Logic
    const finalSubmissionContent = contentList?.find((content) =>
      content.blocks.some((block) => block.type === 'UploadFinalReport'),
    );
    if (finalSubmissionContent) {
      const uploadBlock = finalSubmissionContent.blocks.find((block) => block.type === 'UploadFinalReport');
      const uploadValue = uploadBlock?.values?.length && (uploadBlock.values[0] as IUploadContentValue);

      // Can only proceed if has submmited file, aka has url for file
      canProceed = uploadValue && !!(uploadValue.url && uploadValue.url !== '/');
      if (!canProceed)
        return { canProceed: false, cantProceedReason: 'Submit your final report before finishing the investigation' };
    }

    // Canvas Block Logic
    const canvasContents = contentList?.filter((content) => content.blocks.some((block) => block.type === 'Canvas'));
    if (canvasContents?.length) {
      for (const canvasContent of canvasContents) {
        const canvasBlock = canvasContent.blocks.find((block) => block.type === 'Canvas');
        const canvasValue = canvasBlock?.values?.length && (canvasBlock.values[0] as ICanvasContentValue);

        // Can only proceed if has submmited file, aka has url for file
        canProceed = canvasValue && !!(canvasValue.url && canvasValue.url !== '/');
        if (!canProceed) return { canProceed: false, cantProceedReason: 'Submit the drawing before proceeding' };
      }
    }

    // Text Submit Block Logic
    const textSubmitContent = contentList?.find((content) =>
      content.blocks.some((block) => block.type === 'TextSubmit'),
    );

    if (textSubmitContent) {
      const textSubmitBlock = textSubmitContent.blocks.find((block) => block.type === 'TextSubmit');
      const textSubmitValue = textSubmitBlock?.values?.length
        ? (textSubmitBlock.values[0] as ITextSubmitContentValue)
        : undefined;

      // Can only proceed if has a text
      canProceed = !!textSubmitValue?.text;
      if (!canProceed) return { canProceed: false, cantProceedReason: 'Submit your answer before proceeding' };
    }

    const budgetCalculatorContent = contentList?.find((content) =>
      content.blocks.some((block) => block.type === 'BudgetCalculator'),
    );
    if (budgetCalculatorContent) {
      const budgetCalculatorBlock = budgetCalculatorContent.blocks.find((block) => block.type === 'BudgetCalculator');
      const budgetCalculatorValue = budgetCalculatorBlock?.values?.length
        ? (budgetCalculatorBlock.values[0] as IBudgetCalculatorContentValue)
        : undefined;

      // Can only proceed if has a at least one material submitted
      canProceed = !!budgetCalculatorValue?.materials?.length;
      if (!canProceed)
        return { canProceed: false, cantProceedReason: 'Submit the budget estimation before proceeding' };
    }

    // Peer Review Block Logic
    const peerReviewContent = contentList?.find((content) =>
      content.blocks.some((block) => block.type === 'PeerReview' || block.type === 'PeerReviewQuestionnaire'),
    );
    if (peerReviewContent) {
      const peerReviewBlock = peerReviewContent.blocks.find(
        (block) => block.type === 'PeerReview' || block.type === 'PeerReviewQuestionnaire',
      );
      const peerReviewValue = peerReviewBlock?.values?.length
        ? (peerReviewBlock.values as IPeerReviewContentValue[])
        : undefined;

      // Can only proceed if has completed at least one peer review
      canProceed = !!peerReviewValue?.filter((p) => p.completedAt)?.length;
      if (!canProceed)
        return {
          canProceed: false,
          cantProceedReason: 'You need to complete at least one peer review before proceeding',
        };
    }

    // Reflection Block Logic
    const reflectionContent = contentList?.find((content) =>
      content.blocks.some((block) => block.type === 'Reflection' || block.type === 'ReflectionQuestionnaire'),
    );
    if (reflectionContent) {
      const reflectionBlock = reflectionContent.blocks.find(
        (block) => block.type === 'Reflection' || block.type === 'ReflectionQuestionnaire',
      );
      const reflectionValue = reflectionBlock?.values?.length
        ? (reflectionBlock.values as IReflectionContentValue[])
        : undefined;

      // Can only proceed if has completed at least one reflection
      canProceed = !!reflectionValue?.filter((p) => !!p.reflectionCompletedAt || !!p.review?.reflectionCompletedAt)
        ?.length;
      if (!canProceed)
        return {
          canProceed: false,
          cantProceedReason: 'You need to complete at least one reflection before proceeding',
        };
    }

    // Concept Evaluation Block Logic
    const conceptEvaluationContent = contentList?.find((content) =>
      content.blocks.some((block) => block.type === 'ConceptEvaluation'),
    );
    if (conceptEvaluationContent) {
      const conceptEvaluationBlock = conceptEvaluationContent.blocks.find(
        (block) => block.type === 'ConceptEvaluation',
      );
      const conceptEvaluationValue = conceptEvaluationBlock?.values?.length
        ? (conceptEvaluationBlock.values as IConceptEvaluationContentValue[])
        : undefined;

      // Can only proceed if has completed at least one concept evaluation
      canProceed = !!conceptEvaluationValue?.filter((p) => !!p.completedAt)?.length;
      if (!canProceed)
        return {
          canProceed: false,
          cantProceedReason: 'You need to complete at least one concept evaluation before proceeding',
        };
    }

    // Concept Rating Block Logic
    const conceptRatingContent = contentList?.find((content) =>
      content.blocks.some((block) => block.type === 'ConceptRating'),
    );
    if (conceptRatingContent) {
      const conceptRatingBlock = conceptRatingContent.blocks.find((block) => block.type === 'ConceptRating');
      const conceptRatingValue = conceptRatingBlock?.values?.length
        ? (conceptRatingBlock.values as IConceptEvaluationContentValue[])
        : undefined;

      // Can only proceed if has completed at least one concept evaluation
      canProceed = !!conceptRatingValue?.filter((p) => !!p.completedAt)?.length;
      if (!canProceed)
        return {
          canProceed: false,
          cantProceedReason: 'You need to complete at least one concept rating before proceeding',
        };
    }

    // Sorting Board Block Logic
    const hasSortingBoardBlock = contentList?.some((content) =>
      content.blocks.some((block) =>
        [
          'SortingBoard',
          'ProcessDragDropAnswer',
          'CategoryDrag',
          'ProcessDragDropPictureAnswer',
          'CategoryDragPicture',
        ].includes(block.type),
      ),
    );
    if (hasSortingBoardBlock) {
      canProceed = hasSortedBoard;
      if (!canProceed) {
        return { canProceed: false, cantProceedReason: 'Verify your answers on sorting board before proceeding' };
      }
    }

    // Multiple Choice and Multiple Choice with picture Block Logic
    const hasMultipleChoiceBlock = contentList?.some((content) =>
      content.blocks.some((block) => ['MultipleChoiceQuestion', 'MultipleChoicePictureQuestion'].includes(block.type)),
    );
    if (hasMultipleChoiceBlock) {
      canProceed = hasSelected;
      if (!canProceed) {
        return { canProceed: false, cantProceedReason: 'Select the 1 answer before proceeding' };
      }
    }
  }

  return { canProceed };
};

/**
 * Calculates and return the activity id in which the investigation should load for students.
 * If no stepId is provided, the order is the following:
 * 1- Go to activity with a peer review to be done
 * 2- Go to activity with a reflection to be done
 * 3- Go to activity with a concept evaluation to be done
 * 4- Go to stage with no prerequisite and not started
 * 5- Go to activity right after the last completed one
 */
// eslint-disable-next-line complexity
export const findInitialActivityId = (payload: {
  investigation: GQL_InvestigationDisplay;
  stepIdFromRoute?: string;
  goToComponentState?: GQL_InvestigationBlockTypes;
  setCurrentContentIndex: (index: number) => void;
}) => {
  const { investigation, stepIdFromRoute, goToComponentState, setCurrentContentIndex } = payload;
  let activityId: string | undefined;
  if (!stepIdFromRoute) {
    // First case, try to find an undone peer review
    investigation?.steps?.forEach((step) =>
      step.activities?.forEach((activity) =>
        (step.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.forEach((content) =>
          content?.blocks?.forEach((block) => {
            if (block.type === 'PeerReview' || block.type === 'PeerReviewQuestionnaire') {
              block.values?.forEach((value) => {
                const peerReviewValue = value as IPeerReviewContentValue;
                if (peerReviewValue?.readyToReview && !peerReviewValue?.completedAt && !activityId) {
                  activityId = activity.id;
                }
              });
            }
          }),
        ),
      ),
    );
    if (activityId) return activityId;

    // Second case, try to find an undone reflection
    investigation?.steps?.forEach((step) =>
      step.activities?.forEach((activity, activityIndex) =>
        (step.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.forEach((content, cI) =>
          content?.blocks?.forEach((block) => {
            // Checks if peer review step is completed, otherwise you can be stuck there
            if (!step.activities[activityIndex - 1]?.completed || activityId) {
              return;
            } else if (block.type === 'Reflection' || block.type === 'ReflectionQuestionnaire') {
              if (
                block.values?.some(
                  (value) =>
                    !(value as IReflectionContentValue).reflectionCompletedAt &&
                    (value as IReflectionContentValue).review?.completedAt,
                )
              ) {
                activityId = activity.id;
              }
            }
          }),
        ),
      ),
    );
    if (activityId) return activityId;

    // Third case, try to find an undone concept evaluation
    investigation?.steps?.forEach((step) =>
      step.activities?.forEach((activity, activityIndex) =>
        (step.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.forEach((content, cI) =>
          content?.blocks?.forEach((block) => {
            // Checks if peer review step is completed, otherwise you can be stuck there
            if (!step.activities[activityIndex - 1]?.completed || activityId) {
              return;
            } else if (block.type === 'ConceptEvaluation') {
              if (
                block.values?.some(
                  (value) =>
                    !(value as IConceptEvaluationContentValue).completedAt &&
                    (value as IConceptEvaluationContentValue).readyToReview,
                )
              ) {
                activityId = activity.id;
              }
            }
          }),
        ),
      ),
    );
    if (activityId) return activityId;

    // Forth, ty to find an unlocked text submission
    investigation?.steps?.forEach((step) =>
      step.activities?.forEach((activity, activityIndex) =>
        (step.mode === 'INPERSON' ? activity?.contentInPerson : activity?.content)?.forEach((content, cI) =>
          content?.blocks?.forEach((block) => {
            // Checks if text submit is unlocked
            if (block.type === 'TextSubmit') {
              if (block.values?.some((value) => !!(value as ITextSubmitContentValue).isUnlocked)) {
                activityId = activity.id;
              }
            }
          }),
        ),
      ),
    );
    if (activityId) return activityId;

    // Fifth Case
    let stepToReturn = investigation.steps[0];
    for (let i = 1; i < investigation.steps.length; i++) {
      const step = investigation.steps[i];
      const previousStep = investigation.steps[i - 1];
      const nextStep = investigation.steps[i + 1];
      if (!step.completed) {
        if (step.activities.some((a) => a.completed) || previousStep.completed) stepToReturn = step;
        if (nextStep?.requiresPreviousStep) break;
      } else {
        stepToReturn = step;
      }
    }
    return stepToReturn.activities.find((a) => !a.completed)?.id || stepToReturn?.activities[0]?.id;
  } else {
    const step = investigation.steps.find((step) => step.id === stepIdFromRoute);

    if (step) {
      const { activity, contentIndex } = step.completed
        ? getActivityWithSubmission(step)
        : { contentIndex: 0, activity: step.activities.find((a) => !a.completed) || step.activities[0] };
      let activityFromRoute = null;
      if (goToComponentState)
        activityFromRoute = step.activities.find((a) =>
          (step.mode === 'INPERSON' ? a?.contentInPerson : a?.content)?.some((c) =>
            c.blocks.some((b) => b.type === goToComponentState),
          ),
        );
      if (activity) setCurrentContentIndex(contentIndex);

      return activityFromRoute?.id || activity.id;
    }
  }
};
