import React, { useMemo } from 'react';
import { Col, Dropdown, Menu, Row } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { startCase } from 'lodash';
import { FiChevronDown, FiFilter, FiX } from 'react-icons/fi';

import { IInvestigationLibraryFilter } from '../../AssessmentLibraryPage';
import useStateStandardFilter from '../../../hooks/useStateStandardFilter';
import { cleanCoreIdeaCode } from '../../../utils/utils';
import { GQL_AssessmentsScoresResponse } from '../../../types/gradeBooking';
import * as S from './styles';
import useFetchStateStandards from '../../../hooks/useFetchStateStandards';
import { INVESTIGATION_STANDARDS } from '../../../types/standards';

interface IAssessmentInsightFilterBy {
  filters: IInvestigationLibraryFilter[];
  setFilters: (filters: IInvestigationLibraryFilter[]) => void;
  assessments?: GQL_AssessmentsScoresResponse[] | undefined;
}

const AssessmentInsightFilterBy = (props: IAssessmentInsightFilterBy) => {
  const { filters, setFilters, assessments = [] } = props;

  const { stateStandardsData } = useFetchStateStandards(INVESTIGATION_STANDARDS.TEXAS);

  const { stateStandardFilterData } = useStateStandardFilter({ stateStandards: stateStandardsData });

  const [disciplineMenuItems, focusesMenuItems, coreIdeasMenuItems, practiceMenuItems] = useMemo(() => {
    let disciplines: string[] = [];
    let coreIdeas: { code: string; text: string; gradeBand: string }[] = [];
    let practices: { code: string; text: string }[] = [];
    let formats: string[] = [];
    let focuses: string[] = [];

    assessments.forEach((assessment) => {
      if (!disciplines.some((discipline) => discipline === assessment.discipline?.name)) {
        if (assessment.discipline?.name) disciplines.push(assessment.discipline?.name);
      }
      if (!formats.some((format) => format === assessment.format?.name)) {
        if (assessment.format?.name) formats.push(assessment.format?.name);
      }
      if (!focuses.some((focus) => focus === assessment.focus?.name)) {
        if (assessment.focus?.name) focuses.push(assessment.focus?.name);
      }
      assessment.coreIdeas?.forEach((ci) => {
        if (!coreIdeas.some((c) => c.code === ci.code)) {
          if (assessment.discipline?.gradeBand) coreIdeas.push({ ...ci, gradeBand: assessment.discipline.gradeBand });
        }
      });
      assessment.practices?.forEach((ci) => {
        if (!practices.some((c) => c.code === ci.code)) {
          practices.push({ ...ci });
        }
      });
    });

    return [
      disciplines.sort(),
      focuses.sort(),
      coreIdeas.sort((a, b) => a.code.localeCompare(b.code)),
      practices.sort((a, b) => a.code.localeCompare(b.code)),
    ];
  }, [assessments]);

  const setNewFilter = (filter: IInvestigationLibraryFilter) => {
    setFilters([...filters.filter((f) => f.field !== filter.field), filter]);
  };

  const removeFilterChip = (filter: IInvestigationLibraryFilter) => {
    setFilters(filters.filter((f) => f.value !== filter.value));
  };

  const buildFilterChipText = (filter: IInvestigationLibraryFilter) => {
    if (filter.field === 'grade') {
      return 'Grade ' + filter.value;
    } else if (filter.field === 'standard') {
      return stateStandardsData?.find((state) => state.id === filter.value)?.code;
    } else if (filter.field === 'subject' && filter.value === 'Science:_Texas_Edition_(2024_Adoption)') {
      return 'Science: Texas Edition';
    } else {
      return startCase(filter.value);
    }
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <SubMenu title="Focus">
            {focusesMenuItems.map((focusItem) => (
              <Menu.Item
                key={focusItem}
                disabled={filters.some((filter) => filter.value === focusItem)}
                onClick={() =>
                  setNewFilter({
                    field: 'focus',
                    value: focusItem,
                  })
                }
              >
                {startCase(focusItem)}
              </Menu.Item>
            ))}
          </SubMenu>
          <SubMenu title="Discipline">
            {disciplineMenuItems.map((disciplineItems) => (
              <Menu.Item
                key={disciplineItems}
                disabled={filters.some((filter) => filter.value === disciplineItems)}
                onClick={() =>
                  setNewFilter({
                    field: 'discipline',
                    value: disciplineItems,
                  })
                }
              >
                {startCase(disciplineItems)}
              </Menu.Item>
            ))}
          </SubMenu>
          <SubMenu title="Core Ideas">
            {coreIdeasMenuItems.map((coreIdeaItem) => (
              <Menu.Item
                key={coreIdeaItem.code}
                disabled={filters.some((filter) => filter.value === coreIdeaItem.code)}
                onClick={() =>
                  setNewFilter({
                    field: 'coreIdea',
                    value: coreIdeaItem.code,
                  })
                }
              >
                {startCase(coreIdeaItem.text)} ({coreIdeaItem.code})
              </Menu.Item>
            ))}
          </SubMenu>
          <SubMenu title="Practice">
            {practiceMenuItems.map((practice) => (
              <Menu.Item
                key={practice.code}
                disabled={filters.some((filter) => filter.value === practice.code)}
                onClick={() =>
                  setNewFilter({
                    field: 'practice',
                    value: practice.code,
                  })
                }
              >
                {startCase(practice.text)} ({cleanCoreIdeaCode(practice.code)})
              </Menu.Item>
            ))}
          </SubMenu>
          <SubMenu title="Standard">
            <SubMenu title="Texas">
              {stateStandardFilterData.map((grade) => (
                <SubMenu
                  key={grade.key}
                  title={grade.key.replace('0', '')}
                  popupClassName="state-standard-filter-investigation-list-header"
                >
                  {grade.standards?.map((standard) => (
                    <Menu.Item
                      key={standard.id}
                      disabled={filters.some((filter) => filter.value === standard.id)}
                      onClick={() =>
                        setNewFilter({
                          field: 'standard',
                          value: standard.id,
                        })
                      }
                    >
                      {`${standard.code} - ${standard.abbreviatedStatement}`}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ))}
            </SubMenu>
          </SubMenu>
        </Menu>
      }
      placement="bottomRight"
      trigger={['click']}
    >
      <S.FilterContainer>
        <FiFilter />
        <span role="none" >Filter By</span>
        <Row>
          {filters.map((filter) => (
            <Col sm={12} md={24 / filters.length} key={filter.value}>
              <S.FilterChip
                onClick={(e) => {
                  e.stopPropagation();
                  removeFilterChip(filter);
                }}
              >
                <span role="none" >{buildFilterChipText(filter)}</span>
                <FiX />
              </S.FilterChip>
            </Col>
          ))}
        </Row>
        <FiChevronDown />
      </S.FilterContainer>
    </Dropdown>
  );
};

export default AssessmentInsightFilterBy;
