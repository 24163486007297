import styled from 'styled-components';
import { Row, Col, Button } from 'antd';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f0f2f5;
   
     @media (max-width: 480px) {    
    padding: 5px; /* Padding for smaller screens */
  }
`;

export const RowForm = styled(Row)`
  width: 100%;
  margin: 0; /* Ensure no extra margin */
  padding: 0; /* Ensure no extra padding */
  row-gap: 16px; /* Adjust gap between rows as needed */

  @media (max-width: 992px) {
    margin: 0 auto; /* Center content for smaller screens */
    width: 100%;
    padding: 0 10px; /* Padding for smaller screens */
    display:block;
  }


`;


export const Title = styled.h1`
  margin-bottom: 25px;
  text-align: center;
  margin-top: 15px;
  font-weight: 700;
  font-size: 40px;
  color: #3f3e38;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const RowSP = styled(Row)`
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin: 0; /* Remove negative margins */
  row-gap: 0;
  overflow: auto;
  margin-left: -10%;
    margin-right: -10%;
    row-gap: 0px;
    width: 100%;

  @media (max-width: 992px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
    @media (max-width: 480px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

export const RowSSO = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  overflow: visible;
  width: 100%;
  max-width: 1200px; /* Increase max-width for the main SSO container */

  @media (max-width: 1200px) {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin:auto;
  }
`;

export const ColSSO = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 80%;
  padding: 0 5%;

  @media (max-width: 992px) {
    max-width: 100%;
    padding: 0 4%;
  }

  @media (max-width: 768px) {
    max-width: 100%;

    padding: 0 2%;
    flex:0 0 100%;
  }
`;

export const ColSP = styled(Col)`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background: #ffffff;
  width: 100%;
  max-width: 1200px;
  word-wrap: break-word;
  word-break: break-word;
  overflow: visible;
 

  @media (max-width: 1278px) {
    padding: 50px;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 100%;

    img{
    height: 30px;
    margin: 0 2px;
    }
  }
`;

export const OrDivider = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: auto;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
export const  StyledButton = styled(Button)`
color: #000000;
padding: 10px 20px;
`;

const SSOButton = styled.div`
  cursor: pointer;
  border-radius: 6px;
  border-bottom-width: 3px;
  display: flex;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  background: white;
  width: 100%;
  white-space: nowrap;
  justify-content: center;
  align-items: center;

  img {
    height: 25px;
    margin: 0 2px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px;

    img {
      height: 20px;
    }
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 4px;

    img {
      height: 18px;
    }
  }
`;

export const CleverSSOButton = styled(SSOButton)`
  border: 2px solid #436cf2;
  color: #436cf2;
`;

export const ClassLinkSSOButton = styled(SSOButton)`
  border: 2px solid #0a4d7f;
  color: #0a4d7f;
`;

export const GoogleSSOButton = styled(SSOButton)`
  border: 2px solid #de5246;
  color: #000;

  img {
    padding-left: 8px;
  }
`;

export const CleverSSOButtonDivider = styled.span`
  margin: 0 8px;
  width: 1px;
  background: #436cf2;
`;

export const GoogleClassroomSSOButton = styled.div`
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid #de5246;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: 500;
  background: white;
  width: 100%;
  white-space: nowrap;

  img {
    height: 25px;
    margin-right: 8px;
  }

  span {
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px 8px;
    img {
      height: 20px;
      margin-right: 6px;
    }
    span {
      font-size: 14px;
      margin-left: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 4px 6px;
    img {
      height: 18px;
      margin-right: 4px;
    }
    span {
      font-size: 12px;
      margin-left: 4px;
    }
  }
`;

export const CanvasSSOButton = styled.div`
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid #de5246;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: 500;
  background: white;
  width: 100%;
  white-space: nowrap;

  img {
    height: 25px;
    margin-right: 8px;
  }

  span {
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px 8px;
    img {
      height: 20px;
      margin-right: 6px;
    }
    span {
      font-size: 14px;
      margin-left: 6px;
    }
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 4px 6px;
    img {
      height: 18px;
      margin-right: 4px;
    }
    span {
      font-size: 12px;
      margin-left: 4px;
    }
  }
`;

export const SSOButtonDivider = styled.span`
  margin: 0 8px;
  width: 1px;
  background: #ccc;
`;

export const VerticalDivider = styled.span`
  width: 2px;
  background: #ededed;
  margin: 0 auto; /* Center the divider horizontally */

  @media (max-width: 768px) {
    transform: rotate(90deg); /* Rotate divider for smaller screens */
    width: 100%; /* Make divider full width when rotated */
    height: 2px; /* Adjust height for horizontal divider */
    margin: 8px 0; /* Reduce margin when rotated for better spacing */
  }

  @media (max-width: 480px) {
    width: 100%; /* Full width for mobile screens */
    height: 2px; /* Adjust height for mobile screens */
    margin: 6px 0; /* Further reduce margin for mobile screens */
  }
`;

export const OrContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0; /* Increased margin for better separation */
  height: auto;
  padding: 0 16px; /* Added padding for better spacing */
  font-size: 16px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 12px 0;
    padding: 0 12px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    margin: 16px 0;
    width:100%;
    padding: 1 43px;
  }
`;

export const OrText = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  width: auto; /* Adjust width to auto for better alignment */

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;


// Styled component for the column
export const ColR = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  @media (max-width: 480px) {
  width: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;

}
`;

