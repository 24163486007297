import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout, message } from 'antd';

import InvestigationContent from '../../shared/InvestigationContent';
import FinishedInvestigation from './FinishedInvestigation';
import { investigationActions } from '../../redux/modules';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../redux/store';
import { GQL_InvestigationBlockTypes, GQL_InvestigationDisplay } from '../../types/investigation';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import InvestigationLoading from './InvestigationLoading';
import InvestigationContentBuilder from './InvestigationContentBuilder';
import StudentInvestigationSider from './StudentInvestigationSider';
import { canStudentGoToNextStep, findInitialActivityId } from '../../utils/investigation';
import InvestigationNotes from '../../shared/InvestigationNotes';
import FinishedAssessment from './FinishedAssessment';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';


type Props = RouteComponentProps<
  {
    id: string;
    submissionVersion?: string;
  },
  any,
  {
    contentIndex?: number;
    stepId?: string;
    goToComponent?: GQL_InvestigationBlockTypes;
  }
>;

const StudentInvestigation: React.FC<Props> = (props) => {
  const { id } = props.match.params;
  const location = useLocation();
  const queryStringParamsObj = new URLSearchParams(location.search);
  const code = queryStringParamsObj.get('courseId');
  const provider = queryStringParamsObj.get('courseWorkId') || queryStringParamsObj.get('assignmentId');
  const { contentIndex, stepId, goToComponent } = props.location.state || {};
  const [currentContentIndex, setCurrentContentIndex] = useState(contentIndex ?? 0);
  const [stepIdFromRoute] = useState(stepId);
  const [goToComponentState] = useState(goToComponent);
  const [hasWrittenComment, setHasWrittenComment] = useState(false);
  const [hasSortedBoard, setHasSortedBoard] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const dispatch = useDispatch();
  const [finished, setFinished] = useState(false);
  const history = useHistory();
  const { user } = useAuth();
  const submissionVersion = props.match.params.submissionVersion ? parseInt(props.match.params.submissionVersion) : 1;

  console.log('props: ',props);
  console.log('courseId',code);
  console.log('courseWorkId',provider);
  useEffect(() => {
    // Clears out state, otherwise current content would be kept when refresh
    history.replace(history.location.pathname + history.location.search, {});
  }, [history]);

  useEffect(() => {
    console.log('Location:', location);
    console.log('CourseId:', code);
    console.log('CourseWorkId:', provider);
  }, [location, code, provider]);

  const [submissions, setSubmissions] = useState<any[]>([]);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<string | null>(null);

  // Query to get course work submissions
  useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionList,
    {
      variables: { data: [{ courseId: code, courseWorkId: provider }] }, // Use the provided courseId and courseWorkId
      skip: !code || !provider, // Skip if courseId or courseWorkId is not provided
      onError: (error) => {
        message.error('Error fetching submission list data: ' + (error.message || 'Unexpected Error'));
      },
      onCompleted: (data) => {
        console.log('Course work submissions loaded:', data);
        if (data) {
          // Flatten the submissions list
          const allSubmissions = data.getCourseWorkSubmissionList.flatMap((item: any) => item.studentSubmissions);
          setSubmissions(allSubmissions);

          // Select the submission that matches the provided courseId and courseWorkId
          const matchingSubmission = allSubmissions.find((submission: any) =>
            submission.courseId === code && submission.courseWorkId === provider
          );

          // Set selectedSubmissionId based on the matching submission
          if (matchingSubmission) {
            setSelectedSubmissionId(matchingSubmission.id);
          } else if (allSubmissions.length > 0 && selectedSubmissionId === null) {
            // Fallback to setting the first available submission if no matching submission is found
            setSelectedSubmissionId(allSubmissions[0].id);
          }
        }
      }
    }
  );

  // Mutation to turn in course work submissions
  const [turnInAssignment] = useMutation<any>(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.TurnincourseWorkStudentSubmissions,
    {
      onError: (error) => {
        console.error('Error turning in assignment:', error);
        message.error('Error turning in assignment: ' + (error.message || 'Unexpected Error'));
      },
      onCompleted: () => {
        console.log('Assignment turned in successfully');
        message.success('Assignment successfully turned in.');
      },
    }
  );

  const handleTurnIn = (): void => {
    if (!selectedSubmissionId) {
      console.log('No submission ID selected');
      return; // Exit if no submission selected
    }

    const submission = submissions.find(sub => sub.id === selectedSubmissionId);

    if (submission && submission.courseId && submission.courseWorkId) {
      console.log('Turning in assignment with the following details:', {
        courseId: submission.courseId,
        courseWorkId: submission.courseWorkId,
        id: selectedSubmissionId,
      });

      turnInAssignment({
        variables: {
          courseId: submission.courseId,
          courseWorkId: submission.courseWorkId,
          id: selectedSubmissionId,
        },
      }).catch(error => {
        console.error('Error during assignment submission:', error);
      });
    } else {
      console.log('Submission not found or missing courseId/courseWorkId');
    }
  };

  const [createAssignment] = useMutation(
    gqlSchema.CanvasSchema.mutations.CANVASCLASS.CreateCanvasSubmission,
    {
      onCompleted: async () => {
        message.success('Assignment submitted successfully');
      },
      onError: (err) => {
        message.error('Error submitting assignment: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const handleCanvasSubmission = () => {
    createAssignment({
      variables: {
        course_id: code,
        assignment_id: provider,
        data: {
          submission: {
            submission_type: 'online_text_entry',
            body: 'ADI Investigation and Assessments',
          },
        },
      },
    }).catch(error => {
      console.error('Error during Canvas assignment submission:', error);
    });
  };
  
  const currentActivityId = useSelector((state: IStore) => state.investigation.currentActivityId);

  const { data, loading, error } = useQuery<{ getInvestigationById: GQL_InvestigationDisplay }, { id: string }>(
    gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById,
    {
      variables: {
        id,
      },
      onError: (err) => {
        message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
      },
      onCompleted: (data) => {
        const initialActivityId =
          findInitialActivityId({
            investigation: data.getInvestigationById,
            setCurrentContentIndex,
            goToComponentState,
            stepIdFromRoute,
          }) || '';

        dispatch(
          investigationActions.updateCurrentActivity({
            id: initialActivityId,
          }),
        );
      },
    },
  );

  const [submitAssessmentFinal, { loading: submittingAssessment }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.PLAN.submitAssessmentFinal,
    {
      variables: {
        classInvestigationId: id,
      },
      onCompleted: () => {
        setFinished(true);
      },
      onError: (err) => {
        message.error('There was an error submitting the assessment: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const investigation = useMemo(() => data?.getInvestigationById, [data]);
  const overDue = useMemo(() => {
    if (investigation?.classStudent?.endDate) {
      return investigation?.classStudent?.endDate < Date.now();
    }
    return false;
  }, [investigation]);

  const goToNextActivity = useCallback(() => {
    const currentStepIndex =
      investigation?.steps?.findIndex((s) => s.activities.some((a) => a.id === currentActivityId)) ?? 0;

    const currentActivityIndex =
      investigation?.steps[currentStepIndex].activities.findIndex((a) => a.id === currentActivityId) ?? 0;

    let nextActivityId = '';

    if (investigation?.steps[currentStepIndex].activities.length === currentActivityIndex + 1) {
      if (investigation?.steps?.length === currentStepIndex + 1) {
        if (investigation.isAssessment) {
          submitAssessmentFinal();
        } else {
          setFinished(true);
        }
    
        if (user?.preferredRole === 'google_student') {
          handleTurnIn();
        }else if (user?.preferredRole === 'canvas_student') {
          handleCanvasSubmission();
        }
      } else {
        nextActivityId = investigation.steps[currentStepIndex + 1].activities[0].id;
      }
    } else {
      nextActivityId = investigation?.steps[currentStepIndex].activities[currentActivityIndex + 1]?.id ?? '';
    }

    setCurrentContentIndex(0);
    setHasWrittenComment(false);
    setHasSortedBoard(false);
    setHasSelected(false);

    dispatch(
      investigationActions.updateCurrentActivity({
        id: nextActivityId,
      }),
    );
  }, [currentActivityId, dispatch, investigation, submitAssessmentFinal]);

  const [updateActivityStatus, { loading: loadingNextStep }] = useMutation<
    null,
    { stepId: string; activityId: string; completed?: boolean }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.activityCompleted, {
    onError: (err) => {
      message.error('There was an error saving progress: ' + err.message || 'Unexpected Error');
    },
    update: (cache) => {
      // Invalidates summary progress for students, so it always have updated data
      cache.modify({
        fields: {
          getMyProgressSummary(_, { DELETE }) {
            return DELETE;
          },
        },
      });

      cache.writeFragment({
        id: `InvestigationActivityEntry:${currentActivityId}`,
        fragmentName: 'updateActivity',
        fragment: gqlSchema.InvestigationSchema.fragments.ACTIVITIES.updateActivity,
        data: {
          completed: true,
        },
      });
    },
    onCompleted: goToNextActivity,
  });

  const submitActivity = useCallback(() => {
    const activity = investigation?.steps
      .find((s) => s.activities.some((a) => a.id === currentActivityId))
      ?.activities.find((a) => a.id === currentActivityId);
    const step = investigation?.steps?.find((s) => s.id === activity?.stepId);

    if (activity?.completed && step?.completed) {
      // If assessment check if should lock it.
      if (investigation?.isAssessment) {
        const currentStepIndex =
          investigation?.steps?.findIndex((s) => s.activities.some((a) => a.id === currentActivityId)) ?? 0;
        const currentActivityIndex =
          investigation?.steps[currentStepIndex].activities.findIndex((a) => a.id === currentActivityId) ?? 0;

        const lastActivity = investigation?.steps[currentStepIndex]?.activities?.length === currentActivityIndex + 1;
        const lastStep = investigation?.steps?.length === currentStepIndex + 1;

        if (lastActivity && lastStep) {
          if (currentActivityId && !investigation?.isLocked) {
            // Call function to trigger lock assessment if this is final step and it's not locked
            return updateActivityStatus({
              variables: { stepId: activity?.stepId || '', activityId: currentActivityId, completed: true },
            });
          }
        }
      }

      goToNextActivity();
    } else if (currentActivityId) {
      updateActivityStatus({
        variables: { stepId: activity?.stepId || '', activityId: currentActivityId, completed: true },
      });
    }
  }, [updateActivityStatus, currentActivityId, goToNextActivity, investigation]);

  const lastStepIndex = useMemo(
    () =>
      currentActivityId
        ? investigation?.steps.findIndex((step) =>
            step.activities.some((activity) => activity.id === currentActivityId),
          ) || 0
        : 0,
    [currentActivityId, investigation],
  );

  const stepList = useMemo(() => investigation?.steps.slice(0, lastStepIndex + 1) || [], [
    investigation,
    lastStepIndex,
  ]);

  const currentActivity = useMemo(() => stepList[lastStepIndex]?.activities?.find((a) => a.id === currentActivityId), [
    currentActivityId,
    lastStepIndex,
    stepList,
  ]);

  const previousSubmissionActivity = useMemo(
    () =>
      [...(stepList[lastStepIndex]?.activities || [])]
        ?.reverse()
        ?.find((a) => a.content?.some((c) => c.blocks?.some((b) => b.type === 'Upload' || b.type === 'Canvas'))),
    [lastStepIndex, stepList],
  );

  const canSeeStep = useMemo(() => (stepList[lastStepIndex]?.startDate || 0) < Date.now(), [lastStepIndex, stepList]);

  const isInPerson = stepList[lastStepIndex]?.mode === 'INPERSON';

  // Student can go to next activity if is in the last page of the contents
  const { canProceed, cantProceedReason } = useMemo(
    () =>
      canStudentGoToNextStep({
        currentActivity,
        currentContentIndex,
        hasWrittenComment,
        hasSortedBoard,
        hasSelected,
        investigation: investigation,
        isInPerson,
      }),
    [currentActivity, currentContentIndex, hasWrittenComment, investigation, isInPerson, hasSortedBoard, hasSelected],
  );

  useEffect(() => {
    // Ensure this only runs when necessary and doesn't affect query params
    history.replace(history.location.pathname + history.location.search);
  }, [history]);
  

  if (loading) return <InvestigationLoading />;
  if (!investigation || error) return <p>Error</p>;

  return (
    <>
      <Layout>
        <StudentInvestigationSider
          investigation={investigation}
          resetContentIndex={() => {
            setCurrentContentIndex(0);
            setHasSortedBoard(false);
            setHasSelected(false);
          }}
        />
        <InvestigationContent allowFullscreen={canSeeStep} investigationId={investigation.id}>
          {finished ? (
            investigation.isAssessment ? (
              <FinishedAssessment />
            ) : (
              <FinishedInvestigation />
            )
          ) : (
            <InvestigationContentBuilder
              submissionVersion={submissionVersion}
              onActivitySubmit={submitActivity}
              canProceed={currentActivity?.completed || !!canProceed}
              cantProceedReason={cantProceedReason}
              loading={loadingNextStep || submittingAssessment}
              onCommentWrite={setHasWrittenComment}
              onBoardSorted={setHasSortedBoard}
              onSelected={setHasSelected}
              currentActivity={currentActivity}
              currentActivityId={currentActivityId}
              classId={data?.getInvestigationById.classId || ''}
              currentStepId={stepList[lastStepIndex]?.id}
              currentStep={stepList[lastStepIndex]}
              currentContentIndex={currentContentIndex}
              setCurrentContentIndex={setCurrentContentIndex}
              investigationDuedate={investigation?.dueDate}
              investigationId={investigation.id}
              buildInPersonContent={isInPerson}
              previousSubmissionActivityId={previousSubmissionActivity?.id}
              isLocked={investigation.isLocked || overDue}
              isAssessment={investigation.isAssessment || false}
              courseId={code || ''} 
              courseWorkId={provider || ''}
            />
          )}
          {canSeeStep && (
            <InvestigationNotes
              helpVideoUrl={currentActivity?.helpVideoUrl}
              {...{
                help: currentActivity?.help,
                safety: currentActivity?.safety,
              }}
            />
          )}
        </InvestigationContent>
      </Layout>
    </>
  );
};

export default withRouter(StudentInvestigation);
