import styled from 'styled-components';

interface IClassNamePill {
  background: string;
}

export const ClassNamePill = styled.span<IClassNamePill>`
  font-size: 12px;
  display: inline-block;
  padding: 4px 15px;
  background-color: ${(props) => props.background};
  border-radius: 16px;
  box-sizing: border-box;
  color: white;
  font-weight: 600;
  margin-left: 4px;
`;

export const Title = styled.p`
  font-weight: 600;
  text-align: center;
`;

export const TitleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4367e9;
  height: 32px;
  width: 32px;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0 auto;
`;
