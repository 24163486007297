import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { message, Row, Col } from 'antd';
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import ClassInfos from './ClassInfos';
import StudentsTable from './StudentsTable';
import { useAuth } from '../../hooks/useAuth';
import { GQL_GoogleClassroomCourses } from '../../types/googleClassroom';
import GActiveAssignmentsRow from './GActiveAssignmentsRow';
import GFinishedAssignmentsRow from './GFinishedAssignmentsRow';

type Props = RouteComponentProps<{ id: string }, any, { className: string }> & {
  onUpdateNumberOfStudents?: (count: number) => void;
};

const GoogleClassDashboard: React.FC<Props> = (props) => {
  const { id } = props.match.params;
  const { isFacilitator } = useAuth();

  const [courseWorkList, setCourseWorkList] = useState<any[]>([]);
  const [finishedAssignments, setFinishedAssignments] = useState<any[]>([]);
  const [currentCourses, setCurrentCourses] = useState<GQL_GoogleClassroomCourses | undefined>(undefined);

  const { data, loading } = useQuery<{ getCourseByCourseId: GQL_GoogleClassroomCourses }, { courseId: string }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseByCourseId,
    {
      variables: { courseId: id },
      onError: (err) => {
        message.error('There was an error loading class: ' + err.message || 'Unexpected Error');
      },
      onCompleted: (data) => {
        setCurrentCourses(data.getCourseByCourseId);
      },
    }
  );

  const [getCourseWorkListByDueDate, { loading: cwLoading }] = useLazyQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkListByDueDate,
    {
      onError: (error) => {
        console.error('Error fetching course work list:', error);
      },
      onCompleted: (data) => {
        const activeCourseWork = data?.getCourseWorkListByDueDate?.activeCourseWork || [];
        const expiredCourseWork = data?.getCourseWorkListByDueDate?.expiredCourseWork || [];
        setCourseWorkList(activeCourseWork);
        setFinishedAssignments(expiredCourseWork);
      },
    }
  );

  useEffect(() => {
    if (!loading && data?.getCourseByCourseId) {
      const courseId = data.getCourseByCourseId.id;
      getCourseWorkListByDueDate({
        variables: {
          courseId: [courseId],
          data: "PUBLISHED",
        },
      });
    }
  }, [loading, data, getCourseWorkListByDueDate]);

  // Convert currentCourses to an array
  const courses = currentCourses ? [currentCourses] : [];

  return (
    <PageWithTitle title={`${isFacilitator ? 'Course or Event' : 'Class'} Details`} backPageUrl="/googleclassroom-teacher-dashboard">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}></div>
      <ClassInfos classInfo={data?.getCourseByCourseId} loading={loading} />
      {/* <ClassAssignmentsRow classId={id} className={data?.getCourseByCourseId?.name || ''} shouldLoad={!loading} /> */}

      <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
        <Col span={24}>
          <GActiveAssignmentsRow
            loading={cwLoading}
            assignmentData={courseWorkList}
            courses={courses} // Provide the 'courses' prop as an array
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]} style={{ marginTop: '48px' }}> {/* Increased marginTop here */}
        <Col span={24}>
          <GFinishedAssignmentsRow
            loading={loading || cwLoading}
            finishedAssignments={finishedAssignments}
            courses={courses} // Provide the 'courses' prop as an array
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]} style={{ marginTop: '24px' }}> {/* Added marginTop here */}
        <Col span={24}>
          <StudentsTable classId={id} loading={loading} onUpdateNumberOfStudents={props.onUpdateNumberOfStudents} />
        </Col>
      </Row>
    </PageWithTitle>
  );
};

export default withRouter(GoogleClassDashboard);
