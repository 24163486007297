import { useMutation } from '@apollo/client';
import { message, Popover, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';
import useBetaFeature from '../../hooks/useBetaFeature';
import useBetaFeaturesModal from '../../hooks/useBetaFeaturesModal';
import Button from '../../shared/Button';
import Spacer from '../../shared/Spacer';
import Switch from '../../shared/Switch';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';

const BetaToggle = () => {
  const [betaPopoverVisible, setBetaPopoverVisible] = useState(false);
  const { isBetaUser, BetaFeatureWrapper } = useBetaFeature();
  const [beta, setBeta] = useState(!!isBetaUser);
  const { openModal } = useBetaFeaturesModal();
  const { updateUser } = useAuth();

  const [updateEnrollment, { loading }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.updateBetaEnrollment,
    {
      onCompleted: () => {
        updateUser({
          betaEnrolled: beta,
        });
      },
      onError: (err) => {
        message.error(err.message);
        setBeta((b) => !b);
      },
    },
  );

  useEffect(() => {
    if (beta !== isBetaUser) {
      updateEnrollment({
        variables: {
          enroll: beta,
        },
      });
    }
  }, [beta, updateUser, isBetaUser, updateEnrollment]);

  const hideBetaPopover = useCallback(() => setBetaPopoverVisible(false), []);
  const toggleBetaPopoverVisibility = useCallback((visible: boolean) => {
    setBetaPopoverVisible(visible);
  }, []);
  const handleBetaTestingChangeConfig = useCallback(() => {
    setBeta((b) => !b);
    hideBetaPopover();
  }, [hideBetaPopover]);

  return (
    <>
      <S.TitleInput style={{ padding: '0 16px' }}>
        Beta Test Enrollment <S.BetaHelpButton onClick={openModal} data-cy="components-beta-toggle-features-button" />
      </S.TitleInput>
      <Popover
        trigger="click"
        visible={betaPopoverVisible}
        onVisibleChange={toggleBetaPopoverVisibility}
        placement="topLeft"
        content={
          <div style={{ color: '#767676', textAlign: 'center', maxWidth: 400 }}>
            By confirming your enrollment as a Beta Test User, all your students will also be included in the list and
            will receive early development features. If you or any of your students experience any instability or issues
            with new features you can opt-out at any time.
            <Spacer />
            <Row justify="space-around">
              <Button text="Cancel" theme={themeConfig.noColor} minWidth={120} onClick={hideBetaPopover} />
              <Button
                text="Confirm"
                minWidth={120}
                onClick={handleBetaTestingChangeConfig}
                data-cy="components-beta-toggle-confirm"
              />
            </Row>
          </div>
        }
      >
        <Switch title="Beta Tester" type="text" titleAlign="right" margin="8px 16px" checked={!!beta} value={!!beta} loading={loading} />
      </Popover>
      {/* Render an empty span so we can query on e2e tests and make sure that it's working properly */}
      <BetaFeatureWrapper>
        <span role="none"  data-cy="beta-test-active"></span>
      </BetaFeatureWrapper>
    </>
  );
};

export default BetaToggle;
