import { gql } from '@apollo/client';

const IMMERSIVE_READER = {
  getImmersiveReaderToken: gql`
    mutation getImmersiveReaderToken {
      getImmersiveReaderToken {
        token
        expiresAt
      }
    }
  `,
};

export default IMMERSIVE_READER;
