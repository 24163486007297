import React from 'react';

import { themeConfig } from '../../utils/theme';
import Button from '../Button';

const RequestAccount = () => {
  return (
    <>
      <Button
        data-cy="shared-requestaccount-button"
        text="Explore Plans"
        theme={themeConfig.primaryColor}
        shape="round"
        href="/pricing"
        padding="5px 15px 0px 15px"
      />
    </>
  );
};

export default RequestAccount;
