import { ReactComponent as FloridaIcon } from '../assets/state-icons/florida.svg';
import { ReactComponent as GeorgiaIcon } from '../assets/state-icons/georgia.svg';
import { ReactComponent as NGSSIcon } from '../assets/state-icons/ngss.svg';
import { ReactComponent as NyIcon } from '../assets/state-icons/ny.svg';
import { ReactComponent as TexasIcon } from '../assets/state-icons/texas.svg';

export const getStateIcon = (abbreviatedName: string) => {
  switch (abbreviatedName.toUpperCase()) {
    case 'TX':
      return TexasIcon;
    case 'GA':
      return GeorgiaIcon;
    case 'FL':
      return FloridaIcon;
    case 'NY':
      return NyIcon;
    case 'NGSS':
      return NGSSIcon;
    default:
      return TexasIcon;
  }
};
