import React, { ChangeEvent, useEffect, useState } from 'react';
import Input from '../../../../../shared/Input';
import Spacer from '../../../../../shared/Spacer';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  ITextSubmitContentValue,
} from '../../../../../types/investigation';

interface ITextSubmitBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const TextSubmitBlockEditor = (props: ITextSubmitBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;
  const [form, setForm] = useState(
    (editingBlock.values[0] as ITextSubmitContentValue) || { title: '', placeholder: '' },
  );

  const handleEditTextSubmit = () => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else
                return {
                  ...block,
                  values: [
                    {
                      title: form.title || '',
                      placeholder: form.placeholder || '',
                    },
                  ],
                };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  useEffect(() => {
    handleEditTextSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleForm = (e: ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <h2>Submission Question</h2>
      <Input
        value={form?.title}
        onChange={handleForm}
        backgroundColor="white"
        name="title"
        placeholder="Add submission title"
      />
      <Spacer />
      <Input
        value={form?.placeholder}
        onChange={handleForm}
        backgroundColor="white"
        name="placeholder"
        placeholder="Add the input placeholder"
      />
      <Spacer />
    </>
  );
};

export default TextSubmitBlockEditor;
