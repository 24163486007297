import styled from 'styled-components';
import { Button, Col, Row } from 'antd';

export const Container = styled.div`
  padding: 24px;
  padding-left: 40px;
  padding-right: 40px;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
`;

export const FilterRow = styled(Row)`
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  gap:60rem;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  word-wrap: break-word;
  white-space: pre-wrap;
`;
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const SearchInput = styled.input`
  width: 200px;
  height: 30px;
  border: 0.7px solid gray;
  box-shadow: none !important;
  flex-grow: 1; 
  padding-left: 30px;

  &:hover,
  &:focus {
    box-shadow: none !important;
    border: 0.5px solid lightblue;
    outline: none !important;
  }
`;

export const Price = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #767676;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RequestButton = styled.button`
  margin: 0px;
  padding: 0px;
  outline: none;
  border: 1px solid #4367e9;
  color: #4367e9;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
`;

export const SideImages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  img {
    width: 76px;
    height: 76px;
    border-radius: 4px;
    object-fit: cover;
  }
`;

export const MainImage = styled.img`
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  margin-left: 16px; 
`;

export const TabContent = styled.div`
  font-size: 14px;
  color: #666;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
`;

export const RecommendationsWrapper = styled.div`
  margin-top: 48px;
`;

export const RecommendationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const ArrowButton = styled(Button)`
  border: none;
  background: none;
  font-size: 18px;
  color: #3f51b5;
`;

export const BackButton = styled(Button)`
  color: black;
  padding: 0;
  font-size: 24px;
  border: none;
  background: none;
  margin-right: 20px;
`;

export const InvestigationCard = styled(Col)`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  width:80%
`;

export const KitCard = styled(Col)`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  width:80%
`;

export const KitTitle = styled.h3`
  font-size: 14px; 
  margin-bottom: 8px;
  margin-top:6px;
  font-weight: bold;''
  line-height: 1.4; 
  height: 4.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const ImageWrapper = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 90%; 
    height: auto;
    border-radius: 4px; 
  }
`;
export const Divider = styled.div`
  width: 100%;
  height: 3px;
  background-color: #4367E9;
  margin: 20px 0;

`;
export const InvestigationTitle = styled.h3`
  font-size: 14px; 
  margin-bottom: 8px;
  margin-top:6px;
  font-weight: bold;''
  line-height: 1.4; 
  height: 4.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;