import React, { useCallback } from 'react';
import { Row, Col, Badge, Tooltip } from 'antd';
import * as S from './styles';
import { BsClock } from 'react-icons/bs';
import { formatDistance } from '../../../../utils/date';
import { themeConfig } from '../../../../utils/theme';
import { useHistory } from 'react-router-dom';

interface Props {
  progress: number;
  timeLeft: number;
  assignmentName: string;
  assignmentId: string;
  timeWarning: boolean;
  description: string;
  icon: React.ReactNode | null;
  courseId: string;
  version?: number;
  workType: string;
  className?: string;
}

const Item: React.FC<Props> = ({
  progress,
  timeLeft,
  assignmentName,
  assignmentId,
  timeWarning,
  description,
  icon,
  courseId,
  version,
  workType,
  className,
}) => {
  const history = useHistory();

  const goToDashboard = useCallback(() => {
    // Log courseId and assignmentId before navigation
    console.log(`Navigating to edit assignment with courseId: ${courseId} and assignmentId: ${assignmentId}`);
    history.push(`/canvas-teacher-assignment/class/${courseId}/edit-assignment/${assignmentId}`);
  }, [courseId, assignmentId, history]);

  const truncateDescription = (text: string | null | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text || ''; 
  };

  return (
    <S.Card>
      <Row align="middle">
        <Col span={3} style={{ textAlign: 'left' }}>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </Col>
        <Col span={14}>
          <S.Title $align="left">{className}</S.Title>
        </Col>
        <Col span={24} style={{ textAlign: 'left', marginTop: '8px' }}>
        <Badge
              count={<div style={{ textAlign: 'center' }}>{'Assignment'}</div>}
              style={{ backgroundColor: '#E9A643', borderRadius: '40px', color: '#fff', fontWeight: 500, fontSize: '12px', padding: '8px 16px', textAlign: 'center',display: 'inline-block' }}
            />
        </Col>
        <Col span={24} style={{ marginTop: '8px' }}>
          <S.AssignmentName>
            <span style={{
              fontWeight: 500, 
              fontSize: '14px', 
              color: '#333', 
              margin: '0', 
              display: 'block', 
              textAlign: 'left', 
              textTransform: 'capitalize', 
            }}>
              {assignmentName} {version && version > 1 ? `v${version}` : null}
            </span>
          </S.AssignmentName>
        </Col>
        <Col span={24}>
        <Tooltip title={<div style={{ whiteSpace: 'normal', maxWidth: '100%', overflow: 'auto', maxHeight: '300px' }} dangerouslySetInnerHTML={{ __html: description }} />} placement="bottom" overlayStyle={{width: '500px' }}>
            <S.Description dangerouslySetInnerHTML={{ __html: truncateDescription(description, 50) }}></S.Description>
          </Tooltip>
        </Col>
        {timeLeft && (
          <Col span={24} style={{ marginTop: '6px' }}>
            <Row align="middle">
              <BsClock style={{ minWidth: 18, minHeight: 18, marginRight: 8 }} />
              <S.TimeLeft $fontSize="0.8em" $warning={timeWarning}>
                {Date.now() - timeLeft > 0 ? 'Finished' : formatDistance(timeLeft)}
              </S.TimeLeft>
            </Row>
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: '1em' }} gutter={16} justify="center">
        <Col span={16} style={{ textAlign: 'center' }}>
          <S.DashboardButton
            theme={themeConfig.primaryOutlined}
            text={'View Assignment'}
            htmlType="button"
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
