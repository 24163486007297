import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi';
import { Checkbox, Row as AntdRow } from 'antd';
import * as S from '../../Input/styles';
import { AntdSelect } from '../../Select/styles';

export const SubTitle = styled.p`
  font-weight: 600;
  font-size: 1.15em;
  margin: 0;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const TitleInput = styled.p`
  font-weight: 600;
  font-size: 0.95em;
  margin: 0 0 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const BackButton = styled(FiArrowLeft)`
  position: absolute;
  left: 52px;
  top: 52px;
  cursor: pointer;
`;

export const CCheckbox = styled(Checkbox)`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 30px;
`;

export const DisciplineSelectorContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ImportLink = styled.div`
  color: ${(p) => p.theme.primaryColor.background};
  cursor: pointer;
`;

export const Row = styled(AntdRow)<{ $invalid: boolean }>`
  margin-bottom: 8px;

  ${TitleInput} {
    color: ${(p) => (p.$invalid ? p.theme.error.background : 'inherit')};
  }

  ${S.StyledInput} {
    border-color: ${(p) => (p.$invalid ? p.theme.error.background : 'inherit')};

    &:hover {
      border-color: ${(p) => (p.$invalid ? p.theme.error.background : 'inherit')};
    }
  }

  ${AntdSelect} {
    .ant-select-selector {
      border-color: ${(p) => (p.$invalid ? p.theme.error.background : 'inherit')};
    }
  }
`;
