import React from 'react';
import * as S from './styles';

import Icon from '@ant-design/icons';
import { getInvestigationLogo } from '../../utils/investigationLogos';

interface Props {
  discipline: string;
  subject: string;
  size?: number;
  className?: string;
}

const InvestigationIcon: React.FC<Props> = (props) => {
  const { discipline, subject, size = 55, className } = props;

  return (
    <S.MainIconWrapper subject={subject} size={size} className={className}>
      <Icon component={getInvestigationLogo({ discipline, subject })} />
    </S.MainIconWrapper>
  );
};

export default InvestigationIcon;
