import { Popover } from 'antd';
import React, { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { themeConfig } from '../../utils/theme';
import Button from '../Button';
import Spacer from '../Spacer';

import * as S from './styles';

interface IDeletePopover {
  handleDelete: () => void;
  text: string;
  loading?: boolean;
  iconColor?: string;
}

const DeletePopover = (props: IDeletePopover) => {
  const { handleDelete, loading, text, iconColor } = props;
  const [popoverVisibility, setPopoverVisibily] = useState(false);

  return (
    <Popover
      trigger="click"
      destroyTooltipOnHide
      visible={popoverVisibility}
      onVisibleChange={setPopoverVisibily}
      content={
        <div onClick={(e) => e.stopPropagation()}>
          {text}
          <S.ButtonsContainer>
            <Button text="Cancel" onClick={() => setPopoverVisibily(false)} theme={themeConfig.secondaryColor} block />
            <Spacer axis="horizontal" />
            <Button text="Confirm" onClick={handleDelete} theme={themeConfig.primaryOutlined} loading={loading} block />
          </S.ButtonsContainer>
        </div>
      }
    >
      <FiTrash2 size={20} onClick={(e) => e.stopPropagation()} color={iconColor} style={{ cursor: 'pointer' }} />
    </Popover>
  );
};

export default DeletePopover;
