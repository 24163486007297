import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { GlobalStyle } from './styles';
import landingHeaderImage from '../../../assets/booklanding/landing_header.svg';
import Science from '../../../assets/booklanding/science/Science.png';
import Science1 from '../../../assets/booklanding/science/Science1.png';
import Science2 from '../../../assets/booklanding/science/Science2.png';
import Science3 from '../../../assets/booklanding/science/Science3.png';
import Science4 from '../../../assets/booklanding/science/Science4.png';
import Science5 from '../../../assets/booklanding/science/Science5.png';
import Science6 from '../../../assets/booklanding/science/Science6.png';
import Engg from '../../../assets/booklanding/engg/Engg.png';
import Engg1 from '../../../assets/booklanding/engg/Engg1.png';
import Engg2 from '../../../assets/booklanding/engg/Engg2.png';
import Engg3 from '../../../assets/booklanding/engg/Engg3.png';
import Engg4 from '../../../assets/booklanding/engg/Engg4.png';
import Engg5 from '../../../assets/booklanding/engg/Engg5.png';
import math from '../../../assets/booklanding/math/math.png';
import math1 from '../../../assets/booklanding/math/math1.png';
import math2 from '../../../assets/booklanding/math/math2.png';
import math3 from '../../../assets/booklanding/math/math3.png';
import math4 from '../../../assets/booklanding/math/math4.png';
import digiplat from '../../../assets/booklanding/digiplat/digiplat.png';
import digiplat1 from '../../../assets/booklanding/digiplat/digiplat1.png';
import digiplat2 from '../../../assets/booklanding/digiplat/digiplat2.png';
import digiplat3 from '../../../assets/booklanding/digiplat/digiplat3.png';
import digiplat4 from '../../../assets/booklanding/digiplat/digiplat4.png';
import digiplat5 from '../../../assets/booklanding/digiplat/digiplat5.png';
import book from '../../../assets/booklanding/book/book.png';
import book1 from '../../../assets/booklanding/book/book1.png';
import book2 from '../../../assets/booklanding/book/book2.png';
import book3 from '../../../assets/booklanding/book/book3.png';
import book4 from '../../../assets/booklanding/book/book4.png';
import plearn from '../../../assets/booklanding/plearn/plearn.png';
import plearn1 from '../../../assets/booklanding/plearn/plearn1.png';
import plearn2 from '../../../assets/booklanding/plearn/plearn2.png';

import HandsImg from '../../../assets/booklanding/hands.jpeg';
import ResearchImg from '../../../assets/booklanding/research.png';
import ReviewsImg from '../../../assets/booklanding/reviews.png';
import VideoImg from '../../../assets/booklanding/video.jpg';
import LandingFooter from './LandingFooter';
import ChatBot from './ChatBot';
import { FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from 'react-icons/fa';
import { Tooltip } from 'antd';
import UpComingEvent from './UpcomingEvent';
const LandingPage: React.FC = (): JSX.Element => {
  const history = useHistory();
  const [activeProgramTab, setActiveProgramTab] = useState('Science');
  const [activeTeacherResourceTab, setActiveTeacherResourceTab] = useState('OUR DIGITAL PLATFORM');

  function jumpToSection(id: string) {
    const aEl = document.createElement('a');
    aEl.style.visibility = 'none';
    aEl.href = id;
    aEl.click();
  }

  const goToTabLink = (tabName: string) => {
    switch (tabName) {
      case 'Science':
        jumpToSection('#programs-title');
        setActiveProgramTab('Science');
        break;
      case 'Engineering':
        jumpToSection('#programs-title');
        setActiveProgramTab('Engineering');
        break;
      case 'Math':
        jumpToSection('#programs-title');
        setActiveProgramTab('Math');
        break;
      case 'Books':
        jumpToSection('#teacher-resources-title');
        setActiveTeacherResourceTab('BOOKS');
        break;
      case 'Hands-on Materials':
        jumpToSection('#teacher-resources-title');
        setActiveTeacherResourceTab('HANDS ON MATERIAL');
        break;
      case 'Digital Platform':
        jumpToSection('#teacher-resources-title');
        setActiveTeacherResourceTab('OUR DIGITAL PLATFORM');
        break;
      case 'Professional Learning':
        jumpToSection('#teacher-resources-title');
        setActiveTeacherResourceTab('PROFESSIONAL LEARNING');
        break;
      case 'Research':
        jumpToSection('#learn-more-title');
        setActiveMoreWithAdi('RESEARCH');
        break;
      case 'Reviews':
        jumpToSection('#learn-more-title');
        setActiveMoreWithAdi('REVIEWS');
        break;
      case 'Events':
        jumpToSection('#learn-more-title');
        setActiveMoreWithAdi('UPCOMING EVENT');
        break;
      default:
        break;
    }
  };

  const [activeMoreWithAdi, setActiveMoreWithAdi] = useState('UPCOMING EVENT');
  interface ImageCarouselProps {
    images: string[];
  }

  const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    return (
      <S.ImageContainer>
        <S.PImage src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
        <S.ArrowContainer>
          <S.MoreKeyFeatures>More Key Features</S.MoreKeyFeatures>
          <FaRegArrowAltCircleLeft
            onClick={handlePrev}
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              color: 'rgb(0 0 0 / 50%)',
              cursor: 'pointer',
            }}
          />
          <FaRegArrowAltCircleRight
            onClick={handleNext}
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              color: 'rgb(0 0 0 / 50%)',
              cursor: 'pointer',
            }}
          />
        </S.ArrowContainer>
      </S.ImageContainer>
    );
  };
  const renderProgramTabContent = () => {
    switch (activeProgramTab) {
      case 'Science': {
        const images = [Science, Science1, Science2, Science3, Science4, Science5, Science6];
        return (
          <S.Card>
            <ImageCarousel images={images} />
            <S.HeaderContent>
              <S.Title>3D LEARNING AT ITS BEST</S.Title>
              <S.Subtitle>Science Investigation</S.Subtitle>
              <p>
              ADI in science empowers students to think and act like scientists. Through hands-on investigations, 
              exploration of real-world phenomena, and evidence-based argumentation, students delve into scientific concepts, 
              build critical thinking skills, and learn how to communicate their ideas effectively. With ADI, educators can transform science education into a dynamic, 
              student-centered experience that prepares learners for success both inside and outside the classroom.
              </p>
              <S.Button>
                {' '}
                <a href="/ADI Science One Pager (2).pdf" download rel="noopener noreferrer">
                  {' '}
                  Download a One Page Overview{' '}
                </a>
              </S.Button>
            </S.HeaderContent>
          </S.Card>
        );
      }
      case 'Engineering': {
        const images = [Engg, Engg1, Engg2, Engg3, Engg4, Engg5];
        return (
          <S.Card>
            <ImageCarousel images={images} />
            <S.HeaderContent>
              <S.Title>MOVE FROM TINKERING TO FIGURING OUT</S.Title>
              <S.Subtitle>Engineering Design Challenges</S.Subtitle>
              <p>
              ADI in engineering helps all students see how they can use the ideas and practices of science, engineering, 
              and mathematics to help other people and make the world a better place. Through hands-on design challenges, tackling real-world problems, and evidence-based argumentation,
              students not only learn content but also build critical thinking and communication skills. With ADI, 
              educators can help students become knowledgeable, flexible, and resourceful disciplinary thinkers.{' '}
              </p>
              <S.Button>
                {' '}
                <a href="/ADI Engineering One Pager (1).pdf" download rel="noopener noreferrer">
                  {' '}
                  Download a One Page Overview{' '}
                </a>
              </S.Button>
            </S.HeaderContent>
          </S.Card>
        );
      }
      case 'Math': {
        const images = [math, math1, math2, math3, math4];

        return (
          <S.Card>
            <ImageCarousel images={images} />
            <S.HeaderContent>
              <S.Title>USING MATH TO MAKE SENSE OF THE WORLD</S.Title>
              <S.Subtitle>Math Investigation</S.Subtitle>
              <p>
              ADI in math gives all students an opportunity to learn math by doing math. Through exploration of real-world phenomena,
              the use of mathematical practices, and math-based argumentation, students delve into complex and rich mathematical concepts, 
              build critical thinking skills, and learn how to communicate their ideas effectively. 
              With ADI, educators can help students become knowledgeable, flexible, and resourceful mathematical thinkers.
              </p>
              <S.Button>
                {' '}
                <a href="/ADI Math One Pager (1).pdf" download rel="noopener noreferrer">
                  {' '}
                  Download a One Page Overview{' '}
                </a>
              </S.Button>
            </S.HeaderContent>
          </S.Card>
        );
      }
      default:
        return null;
    }
  };

  const renderTeacherResourceTabContent = () => {
    switch (activeTeacherResourceTab) {
      case 'OUR DIGITAL PLATFORM': {
        const images = [digiplat, digiplat1, digiplat2, digiplat3, digiplat4, digiplat5];
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>OUR DIGITAL PLATFORM</S.Title>
              <S.Subtitle>The ADI Learning Hub</S.Subtitle>
              <p>
              When you purchase an ADI book, you gain access to all of your book’s investigations in one convenient place, 
              along with downloadable student handouts, videos, and links to simulations. For district organization accounts, 
              the Hub offers the ability for teachers to guide students through paperless or remote versions of our investigations. 
              The organization accounts include access to a growing library of investigations in science, math, and engineering, 
              as well as a comprehensive assessment system with reporting tools to track student progress. The Learning Hub also integrates with Canvas, 
              Google Classroom, Clever, and ClassLink for easy connectivity with your existing tools.
              </p>
              <S.ButtonWrapper>
                <S.Button onClick={() => history.push('/bookLogin')}>Create an Individual account</S.Button>
                <S.Button
                  onClick={() => history.push('/login')}
                  style={{
                    backgroundColor: 'transparent',
                    color: 'rgb(14, 24, 216)',
                    border: '1px solid rgb(14, 24, 216)',
                  }}
                >
                  Get an Organizational account{' '}
                </S.Button>
              </S.ButtonWrapper>
            </S.HeaderContent>
            <ImageCarousel images={images} />
          </S.Card>
        );
      }
      case 'BOOKS': {
        const images = [book, book1, book2, book3, book4];
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>LESSONS FOR GRADES K-12</S.Title>
              <S.Subtitle>ADI BOOKS</S.Subtitle>
              <p>
              All of our books include at least eight ADI investigations or design challenges. 
              Each investigation or design challenge includes a comprehensive lesson plan with learning targets for each stage, 
              cross-curricular connections, suggested pacing, safety considerations, and teaching tips. 
              Each investigation or design challenge in the book also includes reproducible student handouts. 
              An ADI book is your comprehensive guide to getting started with ADI because it provides 
              the expertise and resources needed for immediate implementation.
              </p>
              <S.ButtonWrapper>
                <a
                  href="https://shop.argumentdriveninquiry.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    backgroundColor: '#4367E9',
                    color: 'white',
                    border: '1px solid rgb(14, 24, 216)',
                    padding: '8px 16px',
                    borderRadius: '40px',
                    textDecoration: 'none',
                    display: 'inline-block',
                    textAlign: 'center',
                  }}
                >
                  Shop Now
                </a>

                <S.Button
                  style={{
                    backgroundColor: 'transparent',
                    color: 'rgb(14, 24, 216)',
                    border: '1px solid rgb(14, 24, 216)',
                  }}
                >
                  <Tooltip title="Coming Soon">Request a Digital Preview </Tooltip>
                </S.Button>
              </S.ButtonWrapper>
            </S.HeaderContent>
            <ImageCarousel images={images} />
          </S.Card>
        );
      }
      case 'HANDS ON MATERIAL': {
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>For Students And TEACHER</S.Title>
              <S.Subtitle>Hands-On Material</S.Subtitle>
              <p>
              Our materials make hands-on science, math, and engineering lessons easier for teachers. 
              With ready-to-use kits and intuitive resources, teachers save prep time without sacrificing student engagement. 
              These tools streamline setup and eliminate the need to track down supplies. Designed to support student discussions, 
              investigations, and design challenges, our products maximize learning in a fun, accessible way—even on a budget. 
              Perfect for busy educators, they bring STEM subjects to life, ensuring smooth, impactful lessons with minimal preparation.
              </p>
              <S.ButtonWrapper>
                <a
                  href="https://shop.argumentdriveninquiry.com/search?options%5Bprefix%5D=last&page=2&q=investigation%2A&type=product"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    backgroundColor: '#4367E9',
                    color: 'white',
                    border: '1px solid rgb(14, 24, 216)',
                    padding: '8px 16px',
                    borderRadius: '40px',
                    textDecoration: 'none',
                    display: 'inline-block',
                    textAlign: 'center',
                  }}
                >
                  Shop for Kits
                </a>

                <a
                  href="https://shop.argumentdriveninquiry.com/collections/classroom-supplies-new"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    backgroundColor: 'transparent',
                    color: 'rgb(14, 24, 216)',
                    border: '1px solid rgb(14, 24, 216)',
                    padding: '8px 16px',
                    borderRadius: '40px',
                    textDecoration: 'none',
                    display: 'inline-block',
                    textAlign: 'center',
                  }}
                >
                  Shop for Classroom Supplies{' '}
                </a>
              </S.ButtonWrapper>
            </S.HeaderContent>
            {/* <ImageCarousel images={images} /> */}
            <S.ImageContainer>
              <S.PImage src={HandsImg} alt="image" style={{ height: '300px' }} />
            </S.ImageContainer>
          </S.Card>
        );
      }
      case 'PROFESSIONAL LEARNING': {
        const images = [plearn, plearn1, plearn2];
        return (
          <S.Card>
            <S.HeaderContent>
              <S.Title>LEARN FROM AN EXPERT </S.Title>
              <S.Subtitle>Professional Learning</S.Subtitle>
              <p>
              Our professional learning experiences focus on the use of ADI instructional materials as a way to transform classrooms. 
              The learning experiences not only give participants an opportunity to engage in the same sort of rich inquiry-based learning 
              that we want students in grades K-12 to experience inside the classroom but also time to learn how to best guide 
              students throughout the year and host a facilitator institute for instructional coaches and other leaders in Austin, 
              TX before ADI Fest each year. ADI is a TEA-approved CPE provider, so Texas teachers can earn CPE credits for 
              attending one of the workshops.
              </p>
              <S.ButtonWrapper>
                <a
                  href="https://share.hsforms.com/1J4a-2U10SBeWxG4zHtmoWw5gbsq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <S.Button>Contact us to Schedule </S.Button>
                </a>
                <S.Button
                  style={{
                    backgroundColor: 'transparent',
                    color: 'rgb(14, 24, 216)',
                    border: '1px solid rgb(14, 24, 216)',
                  }}
                >
                  {' '}
                  <a
                    href="https://argumentdriveninquiry-my.sharepoint.com/:b:/p/victor/EUoQSFWViBNKkLMYKStALg8BM3fDUJOrsSN79DK3g_QURw?e=ZjtIH2"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'rgb(14, 24, 216)' }}
                  >
                    {' '}
                    Download a One Page Overview{' '}
                  </a>
                </S.Button>
              </S.ButtonWrapper>
            </S.HeaderContent>
            <ImageCarousel images={images} />
          </S.Card>
        );
      }
      default:
        return null;
    }
  };

  const renderMoreWthAdi = () => {
    switch (activeMoreWithAdi) {
      case 'UPCOMING EVENT':
        return <UpComingEvent />;
      case 'RESEARCH':
        return (
          <S.Card>
            <S.ImageContainer>
              <S.PImage src={ResearchImg} style={{ mixBlendMode: 'multiply' }} alt="Classroom Image" />
            </S.ImageContainer>
            <S.TDivider />
            <S.HeaderContent>
              <S.Title>RESEARCH</S.Title>
              <S.Subtitle>Research on Argument-Driven Inquiry</S.Subtitle>
              <p>
              The Argument-Driven Inquiry (ADI) instructional model was developed in 2007 to help make science, math, 
              and engineering education meaningful, rigorous, and equitable. This instructional model was then tested and 
              refined for the next ten years through research that was carried out by educational researchers, scientists, 
              mathematicians, and engineers at Florida State University and The University of Texas at Austin in collaboration with elementary,
              middle, and high school teachers. Other educational researchers are now examining how the use of the ADI instructional model 
              affects students learning in a wide range of other contexts to date. There have been over 50 peer-reviewed studies about the impact of ADI on student learning. 
              All of these studies reach the same conclusion: that student outcomes improve with the use of ADI.
              </p>
              <S.Button>
                {' '}
                <a
                  href="https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Overview%20of%20Research%20on%20ADI.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  Download a research findings overview{' '}
                </a>
              </S.Button>
            </S.HeaderContent>
          </S.Card>
        );
      case 'REVIEWS':
        return (
          <S.Card>
            <S.ImageContainer>
              <S.PImage src={ReviewsImg} alt="Classroom Image" style={{aspectRatio: '16/4', objectFit: 'cover', height: '300px'}
              }/>
              <S.ArrowContainer>
                <S.MoreKeyFeatures>More Reviews</S.MoreKeyFeatures>
                <FaRegArrowAltCircleLeft
                  style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'rgb(0 0 0 / 50%)' }}
                />
                <FaRegArrowAltCircleRight
                  style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'rgb(0 0 0 / 50%)' }}
                />
              </S.ArrowContainer>
            </S.ImageContainer>
            <S.TDivider />
            <S.HeaderContent>
              <S.Title>Hear from the people who know best</S.Title>
              <S.Subtitle>Teacher Reviews</S.Subtitle>
              <p>
              We’ve gathered a ton of feedback from educators who have attended one of our workshops or use our products in their classroom.
              We believe teachers play a vital role in shaping the learning experience, and their insights offer valuable 
              perspectives on how ADI tools can support students' engagement, foster critical thinking, and 
              enhance the overall learning process. Dive in to learn more about teachers' thoughts on the impact of ADI on 
              both teaching and learning.
              </p>
            </S.HeaderContent>
          </S.Card>
        );
      case 'VIDEOS':
        return (
          <S.Card>
            <S.ImageContainer>
              <S.PImage src={VideoImg} alt="Classroom Image" />
            </S.ImageContainer>
            <S.HeaderContent>
              <S.Title>VIDEO</S.Title>
              <S.Subtitle>Video Library</S.Subtitle>
              <p>
              Watch these videos to learn more about the Argument-Driven Inquiry model, as well as hints and 
              tricks for implementing ADI into your classroom.
              </p>
              <Tooltip title="Coming Soon">
                <a href="https://www.argumentdriveninquiry.com/videos">
                  <S.Button
                  // onClick={() => history.push('/videolib')}
                  >
                    View Library{' '}
                  </S.Button>{' '}
                </a>
              </Tooltip>
            </S.HeaderContent>
          </S.Card>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <GlobalStyle />
      <S.Container>
        <S.Section>
          <S.MainHeaderContent>
            <S.Title>Transform Your Classroom</S.Title>
            <S.HSubtitle>Argument-Driven Inquiry</S.HSubtitle>
            <S.Subtitle style={{ fontSize: '1rem', fontWeight: 'lighter', color: 'rgb(0 0 0 / 50%)' }}>
              ADI provides innovative and high-quality instructional materials designed to enhance learning and
              engagement in science, engineering, and mathematics, empowering educators and students with practical,
              cutting-edge resources and tools.
            </S.Subtitle>
            <S.MainHeadertitle>
              <S.Button onClick={() => history.push('/learnmore')}>Learn more about this way of teaching</S.Button>
            </S.MainHeadertitle>
          </S.MainHeaderContent>
          <S.Image src={landingHeaderImage} alt="Classroom Image" />
        </S.Section>

        {/* Programs  */}
        <S.PTitle id="programs-title">Programs</S.PTitle>
        <S.PSection>
          <S.Tabs>
            <S.Tab active={activeProgramTab === 'Science'} onClick={() => setActiveProgramTab('Science')}>
              Science
            </S.Tab>
            <S.Tab active={activeProgramTab === 'Engineering'} onClick={() => setActiveProgramTab('Engineering')}>
              Engineering
            </S.Tab>
            <S.Tab active={activeProgramTab === 'Math'} onClick={() => setActiveProgramTab('Math')}>
              Math
            </S.Tab>
          </S.Tabs>
          {renderProgramTabContent()}
        </S.PSection>

        {/* Teacher Resources Section */}
        <S.PTitle id="teacher-resources-title">Teacher Resources</S.PTitle>
        <S.PSection>
          <S.Tabs>
            <S.Tab
              active={activeTeacherResourceTab === 'OUR DIGITAL PLATFORM'}
              onClick={() => setActiveTeacherResourceTab('OUR DIGITAL PLATFORM')}
            >
              Our Digital Platform
            </S.Tab>
            <S.Tab active={activeTeacherResourceTab === 'BOOKS'} onClick={() => setActiveTeacherResourceTab('BOOKS')}>
              Books
            </S.Tab>
            <S.Tab
              active={activeTeacherResourceTab === 'HANDS ON MATERIAL'}
              onClick={() => setActiveTeacherResourceTab('HANDS ON MATERIAL')}
            >
              Hands-on Material
            </S.Tab>
            <S.Tab
              active={activeTeacherResourceTab === 'PROFESSIONAL LEARNING'}
              onClick={() => setActiveTeacherResourceTab('PROFESSIONAL LEARNING')}
            >
              Professional Learning
            </S.Tab>
          </S.Tabs>
          {renderTeacherResourceTabContent()}
        </S.PSection>

        <S.PTitle id="learn-more-title">Learn more with ADI</S.PTitle>
        <S.PSection>
          <S.Tabs>
            <S.Tab
              active={activeMoreWithAdi === 'UPCOMING EVENT'}
              onClick={() => setActiveMoreWithAdi('UPCOMING EVENT')}
            >
              Upcoming Events
            </S.Tab>
            <S.Tab active={activeMoreWithAdi === 'RESEARCH'} onClick={() => setActiveMoreWithAdi('RESEARCH')}>
              Research
            </S.Tab>
            <S.Tab active={activeMoreWithAdi === 'REVIEWS'} onClick={() => setActiveMoreWithAdi('REVIEWS')}>
              Reviews
            </S.Tab>
            <S.Tab active={activeMoreWithAdi === 'VIDEOS'} onClick={() => setActiveMoreWithAdi('VIDEOS')}>
              Videos
            </S.Tab>
          </S.Tabs>
          {renderMoreWthAdi()}
        </S.PSection>
        <S.PTitle>Ready to get started? We'll show you how...</S.PTitle>
        <S.SectionWrapper>
          <S.PSec>
            <S.ChatHeaderContent>
              <S.Title>SUPPORT</S.Title>
              <S.Maintitle>Please tell us a little about you</S.Maintitle>
              <p>
              Answer a few quick questions, and we'll provide you with a personalized roadmap that will tell 
              you exactly what you need to transform your classroom. You can also schedule an appointment 
              with one of our specialists if you would rather talk in person.
              </p>
              <a
                href="https://share.hsforms.com/1J4a-2U10SBeWxG4zHtmoWw5gbsq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.Button>Schedule an Appointment</S.Button>
              </a>
            </S.ChatHeaderContent>
          </S.PSec>
          <S.Divider />
          <S.PSecTwo>
            <S.ChatContainer>
              <ChatBot />
            </S.ChatContainer>
          </S.PSecTwo>
        </S.SectionWrapper>
      </S.Container>
      <LandingFooter goToTabLink={goToTabLink} />
    </>
  );
};

export default LandingPage;
