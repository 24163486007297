import { Col, message, Row, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import { GQL_OpenInvestigationsChartResponse } from '../../../types/charts';
import { FiHelpCircle } from 'react-icons/fi';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import DetailTeachers from './DetailTeachers';
import Button from '../../Button';
import { formatDateTime } from '../../../utils/date';
import { FilterData } from '../../../types/investigation';
import Filter from '../Filter';
import ExcelJS, { Borders } from 'exceljs';
import { saveAs } from 'file-saver';
const OpenInvestigationsByTeacher = () => {
  const [filterData, setFilterData] = useState<FilterData>({ organizations: false, teachers: false });

  const [getOpenInvestigationsChart, { data, loading }] = useLazyQuery<{
    getOpenInvestigationsChart: GQL_OpenInvestigationsChartResponse[];
  }>(gqlSchema.ChartSchema.queries.CHARTS.getOpenInvestigationsChart, {
    onError: (err) => {
      message.error('There was an error loading open investigations by teacher: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    const { organizations, disciplineIds, teachers, ...data } = filterData;
    getOpenInvestigationsChart({
      variables: {
        data,
      },
    });
  }, [getOpenInvestigationsChart, filterData]);
  const onChangeFilterData = useCallback((data: FilterData) => {
    setFilterData(data);
  }, []);

  const saveAsExcel = useCallback(async () => {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet();
    const borderStyle = { style: 'thin', color: 'black' };
    const bordersStyle = {
      top: borderStyle,
      left: borderStyle,
      right: borderStyle,
      bottom: borderStyle,
      diagonal: borderStyle,
    } as Borders;
    const headerStyle = { font: { bold: true }, border: bordersStyle };
    const bodyStyle = { font: { bold: false } };
    ws.columns = [
      { header: 'First Name', key: 'teacherFirstName', width: 20 },
      { header: 'Last Name', key: 'teacherLastName', width: 20 },
      { header: 'Email', key: 'teacherEmail', width: 35 },
      { header: 'Tags', key: 'tags', width: 40 },
      { header: 'Class', key: 'className', width: 40 },
      { header: 'Investigation', key: 'investigation', width: 45 },
      { header: 'Subject', key: 'subject', width: 15 },
      { header: 'Discipline', key: 'discipline', width: 35 },
      { header: 'Start Date', key: 'startDate', width: 25 },
      { header: 'End Date', key: 'endDate', width: 25 },
    ];
    ws.getCell(`A1`).style = headerStyle;
    ws.getCell(`B1`).style = headerStyle;
    ws.getCell(`C1`).style = headerStyle;
    ws.getCell(`D1`).style = headerStyle;
    ws.getCell(`E1`).style = headerStyle;
    ws.getCell(`F1`).style = headerStyle;
    ws.getCell(`G1`).style = headerStyle;
    ws.getCell(`H1`).style = headerStyle;
    ws.getCell(`I1`).style = headerStyle;
    ws.getCell(`J1`).style = headerStyle;

    ws.getCell(`A1`).alignment = { horizontal: 'center' };
    ws.autoFilter = {
      from: 'A1',
      to: 'C1',
    };
    let currentRow = 2;

    data?.getOpenInvestigationsChart.forEach((row) => {
      const excelRow = ws.addRow([
        row.teacherFirstName,
        row.teacherLastName,
        row.teacherEmail,
        row?.tags.map((t) => `${t.tag}`).join(', '),
        'Class',
        'Investigation',
        'Subject',
        'Discipline',
        'Start Date',
        'End Date',
      ]);

      excelRow.alignment = { vertical: 'middle', horizontal: 'left' };
      excelRow.font = bodyStyle.font;

      const mergeLength = currentRow + (row.openInvestigations?.length || 0) - 1;
      ws.mergeCells(`A${currentRow}:A${mergeLength}`);
      ws.mergeCells(`B${currentRow}:B${mergeLength}`);
      ws.mergeCells(`C${currentRow}:C${mergeLength}`);
      ws.mergeCells(`D${currentRow}:D${mergeLength}`);
      ws.getCell(`A${currentRow}`).border = bordersStyle;
      ws.getCell(`B${currentRow}`).border = bordersStyle;
      ws.getCell(`C${currentRow}`).border = bordersStyle;
      ws.getCell(`D${currentRow}`).border = bordersStyle;
      let subRow = currentRow;
      row.openInvestigations?.forEach((inv) => {
        const rowClassName = ws.getCell(`E${subRow}`);
        const rowInvestigationName = ws.getCell(`F${subRow}`);
        const rowSubject = ws.getCell(`G${subRow}`);
        const rowDiscipline = ws.getCell(`H${subRow}`);
        const rowStartDate = ws.getCell(`I${subRow}`);
        const rowEndDate = ws.getCell(`J${subRow}`);
        // Putting value to subrows
        rowClassName.value = inv.className;
        rowInvestigationName.value = inv.name;
        rowSubject.value = inv.subject;
        rowDiscipline.value = inv.discipline;
        rowStartDate.value = formatDateTime(inv?.startDate);
        rowEndDate.value = formatDateTime(inv?.endDate);
        // Putting borders to subrows
        rowClassName.border = bordersStyle;
        rowInvestigationName.border = bordersStyle;
        rowSubject.border = bordersStyle;
        rowDiscipline.border = bordersStyle;
        rowStartDate.border = bordersStyle;
        rowEndDate.border = bordersStyle;
        // Putting row font
        rowClassName.font = bodyStyle.font;
        rowInvestigationName.font = bodyStyle.font;
        rowSubject.font = bodyStyle.font;
        rowDiscipline.font = bodyStyle.font;
        rowStartDate.font = bodyStyle.font;
        rowEndDate.font = bodyStyle.font;
        // Putting row alignment
        rowStartDate.alignment = { horizontal: 'center' };
        rowEndDate.alignment = { horizontal: 'center' };
        subRow += 1;
      });
      currentRow = mergeLength + 1;
    });
    const buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'Open investigations (By Teachers).xlsx');
  }, [data]);

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Open Investigations</S.Title>
          <Tooltip title="Open Investigations by teacher.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={18}>
          <Filter
            showGradeFilter={false}
            showSubjectFilter={false}
            showDateRangeFilter={false}
            filterUsers={false}
            onChangeFilterData={onChangeFilterData}
          />
        </Col>
        <Col span={6}>
          <Button text="Download Details" block onClick={saveAsExcel} />
        </Col>
        <Col span={24}>
          <DetailTeachers loading={loading} data={data?.getOpenInvestigationsChart} />
        </Col>
      </Row>
    </>
  );
};

export default OpenInvestigationsByTeacher;
