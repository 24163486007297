import styled from 'styled-components';

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.95em;
  text-align: left;
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;
