import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { message, Rate, Row, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../../shared/Button';
import Editor from '../../../shared/Editor';
import ExpandableCard from '../../../shared/ExpandableCard';
import { IReflectionContentValue } from '../../../types/investigation';
import { GQL_InvestigationSummaryResponse } from '../../../types/teacher';
import { formatDateTime } from '../../../utils/date';
import { formatCommentText } from '../../../utils/investigation';
import { themeConfig } from '../../../utils/theme';
import { ReactComponent as StuckIcon } from '../../../assets/stuck-icon.svg';
import { ReactComponent as StuckIconDisabled } from '../../../assets/stuck-icon-disabled.svg';
import { useStuckStudentButton } from '../../../utils/useStuckStudentButton';
import { GQL_NotificationResponse } from '../../../types/notification';

import * as S from './styles';

interface IInvestigationReflections {
  values: IReflectionContentValue[];
  activityId: string;
  userId?: string;
  investigationId?: string;
  stepId?: string;
  classId?: string;
  type: 'reflection' | 'best-concept-reflection';
  previousSubmissionActivityId?: string;
  submissionVersion: number;
}

const InvestigationReflections: React.FC<IInvestigationReflections> = (props) => {
  const {
    values,
    investigationId,
    userId,
    type,
    stepId,
    classId,
    activityId,
    previousSubmissionActivityId,
    submissionVersion,
  } = props;
  const history = useHistory();
  const amountOfReflections = values.length;
  const amountOfDoneReflections = values.filter((f) => f.reflection).length;
  const isBestConceptReflection = type === 'best-concept-reflection';
  const { isStudent, user } = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';
  const [isStuck, setIsStuck] = useStuckStudentButton({
    userId: user.id,
    classId: classId!,
    investigationId: investigationId!,
    stepId: stepId!,
    activityId,
  });

  const [fetchNotifications, { loading: notificationsLoading }] = useLazyQuery<{
    getNotifications: GQL_NotificationResponse[];
  }>(gqlSchema.NotificationSchema.queries.NOTIFICATIONS.getNotifications, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const notificationFiltered = data.getNotifications.filter(
        (n) =>
          n.type === 'stuck_student_for_student_reflection_do_nothing' &&
          n.metadata.investigationId === investigationId &&
          n.metadata.classId === classId &&
          n.metadata.stepId === props.stepId &&
          n.metadata.activityId === previousSubmissionActivityId &&
          !!n.metadata.isValid,
      );
      notificationFiltered.length > 0 && setIsStuck(false);
    },
  });

  useSubscription<{ listenForNotifications: GQL_NotificationResponse }>(
    gqlSchema.NotificationSchema.subscriptions.listenForNotifications,
    {
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        if (data?.listenForNotifications?.metadata) {
          try {
            const parsedMetadata = JSON.parse((data.listenForNotifications.metadata as unknown) as string);
            const constIsDoNothingNotification =
              data?.listenForNotifications.type === 'stuck_student_for_student_reflection_do_nothing' &&
              parsedMetadata.investigationId === investigationId &&
              parsedMetadata.classId === props.classId &&
              parsedMetadata.stepId === props.stepId &&
              parsedMetadata.activityId === previousSubmissionActivityId &&
              !!parsedMetadata.isValid;

            constIsDoNothingNotification && setIsStuck(false);
          } catch (err) {
            console.error('Error parsing metadata', err);
          }
        }
      },
    },
  );

  const [reflections, setReflections] = useState<Array<{ id: string; text?: string; rate?: number }>>(
    values.map((value) => ({
      id: value.peerReviewId,
      text: value.reflection,
      rate: value.rating,
    })),
  );

  const { data: investigationData, refetch } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId || '',
    },
    fetchPolicy: 'cache-only',
  });

  const studentList = useMemo(() => investigationData?.getInvestigationProgressSummary?.perStudents || [], [
    investigationData,
  ]);
  const student = studentList?.find((student) => student.userId === userId);

  const findStudentName = useCallback(
    (id?: string) => {
      const student = studentList.find((student) => student.userId === id);

      if (!student) return 'Student 1';

      return student?.firstName + ' ' + student?.lastName;
    },
    [studentList],
  );

  const name = student ? `${student.firstName} ${student.lastName}` : undefined;

  const [submitReflection, { loading: loadingSubmitReflection }] = useMutation<
    {},
    {
      peerReviewId: string;
      text: string;
      rating: number;
      studentId?: string;
    }
  >(gqlSchema.InvestigationSchema.mutations.COMMENTS.submitReflection, {
    onError: (err) => {
      message.error('Error submitting your review: ' + err.message);
    },
  });

  const [submitBestConceptReflection, { loading: loadingSubmitBestConceptReflection }] = useMutation<
    {},
    {
      peerReviewId: string;
      text: string;
      rating: number;
      revieweeId?: string;
    }
  >(gqlSchema.InvestigationSchema.mutations.COMMENTS.submitBestConceptReflection, {
    onError: (err) => {
      message.error('Error submitting your review: ' + err.message);
    },
  });

  const [notifyTeacherAboutStuckUser, { loading: loadingNotify }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.REASSIGNMENT.notifyTeacherAboutStuckUser,
    {
      onError: (err) => message.error(err.message),
      onCompleted: () => {
        message.success('The notification was sent to the teacher.');
        setIsStuck(true);
      },
    },
  );

  const updateReflection = (payload: { id: string; text?: string; rate?: number }) => {
    const oldReflection = reflections.find((e) => e.id === payload.id);
    const newReflections = reflections.filter((e) => e.id !== payload.id);
    setReflections([...newReflections, { ...oldReflection, ...payload }]);
  };

  const addReflection = ({ id }: { id: string }) => {
    const ref = reflections.find((reflection) => reflection.id === id);

    if (ref && ref.text && ref.rate) {
      const submitFunction = isBestConceptReflection ? submitBestConceptReflection : submitReflection;

      submitFunction({
        variables: {
          peerReviewId: id,
          text: ref.text,
          rating: ref.rate,
          studentId: (isStudent || isGoogleStudent || isCanvasStudent) ? undefined : userId,
          revieweeId: (isStudent || isGoogleStudent || isCanvasStudent) ? undefined : userId,
        },
      });
    }
  };

  const handleStuckConfirm = (e: React.MouseEvent<HTMLElement> | undefined) => {
    notifyTeacherAboutStuckUser({
      variables: {
        classId: props.classId,
        activityId: previousSubmissionActivityId,
        studentId: user.id,
        activityType: 'REFLECTION',
      },
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <S.Column span={24}>
      <S.TitleRow align="middle" justify="space-between">
        <Row align="middle">
          <h1>{name || 'Your'} Feedback Reflections</h1>
        </Row>

        {amountOfDoneReflections > 0 ? (
          <p>
            {amountOfDoneReflections} / {amountOfReflections} Completed
          </p>
        ) : (
          <S.HelpPopConfirm
            title={
              <S.HelpPopConfirmParagraph>
                Your teacher will receive a notification. <br /> Do you <strong>confirm</strong> that you want to report
                that you are stuck?
              </S.HelpPopConfirmParagraph>
            }
            overlayClassName="help-stuck-popconfirm-overlay"
            trigger={isStuck ? '' : 'click'}
            onConfirm={handleStuckConfirm}
            okText="Confirm"
            cancelText="Cancel"
            placement="left"
            icon={<></>}
          >
            <Tooltip
              title={
                isStuck ? 'The notification was already sent to the teacher. Wait a little bit.' : "Help, I'm stuck."
              }
              placement="left"
            >
              <S.HelpButton
                loading={loadingNotify}
                $disabled={isStuck || notificationsLoading}
                shape="circle"
                icon={isStuck ? <StuckIconDisabled /> : <StuckIcon />}
              />
            </Tooltip>
          </S.HelpPopConfirm>
        )}
      </S.TitleRow>

      {values?.map((value, i) => (
        <ExpandableCard
          key={value.review?.id}
          disabled={!value.review?.completedAt}
          data-cy="components-investigation-reflections-card"
          title={
            <S.TitleContainer>
              Feedback {(isStudent || isGoogleStudent || isCanvasStudent)  ? i + 1 : 'By ' + findStudentName(value.review?.studentId)}
              {!!value.review?.rating && <Rate disabled value={value.review?.rating} />}
            </S.TitleContainer>
          }
          description={
            !value.review?.completedAt
              ? "The other student hasn't finished his/her peer review yet"
              : value.reflectionCompletedAt || value.review?.reflectionCompletedAt
              ? `${name || 'You'} completed this Feedback Reflection on ${formatDateTime(
                  value.reflectionCompletedAt ?? Date.now(),
                  'MM.dd.yyyy HH:mm aa',
                )}`
              : `${name || 'You'} ${name ? 'has' : 'have'} not completed this reflection.`
          }
          completed={!!value.reflectionCompletedAt || !!value.review?.reflectionCompletedAt}
          expandable={!!value.review?.completedAt}
          expandComponent={<Button text="Reflect" data-cy="pages-investigation-reflections-reflect" />}
          shrinkComponent={<Button text="Cancel" theme={themeConfig.noColor} />}
        >
          <div style={{ marginTop: 15 }} />
          {!value.reflectionCompletedAt ? (
            <>
              {value.review?.comments.map((comment, i) => (
                <div key={i}>
                  {formatCommentText({
                    text: comment.text,
                    timestamp: comment.coordinates?.ts,
                  })}
                  <br />
                  <br />
                </div>
              ))}
              <S.RateFeedbackContainer>
                <h4>Rate this Feedback</h4>
                <Rate allowClear={false} onChange={(rate) => updateReflection({ id: value.peerReviewId, rate })} />
              </S.RateFeedbackContainer>
              <Editor
                placeholder="Write your comment here"
                data-cy="page-investigation-reflections-editor"
                onChange={(data) =>
                  updateReflection({
                    id: value.peerReviewId,
                    text: data.value,
                  })
                }
              />
              <div style={{ marginTop: 15 }} />
              <Row>
                <Button
                  text="Add Reflection"
                  style={{ marginRight: 20 }}
                  loading={loadingSubmitReflection || loadingSubmitBestConceptReflection}
                  data-cy="pages-investigation-reflections-add-reflection"
                  disabled={
                    !reflections.find((r) => r.id === value.peerReviewId)?.text ||
                    !reflections.find((r) => r.id === value.peerReviewId)?.rate
                  }
                  onClick={() => addReflection({ id: value.peerReviewId ?? '' })}
                />
                <Button
                  text="See Comment"
                  disabled={loadingSubmitReflection || loadingSubmitBestConceptReflection}
                  data-cy="pages-investigation-reflections-see-comments"
                  onClick={() =>
                    !isBestConceptReflection
                      ? history.push(`/student-investigation/${investigationId}/comments/${props.activityId}`, {
                          comments: value.review?.comments,
                          fileUrl: value.review?.fileUrl,
                          fileMIMEtype: value.review?.fileMIMEtype,
                        })
                      : history.push(
                          `/student-investigation/${investigationId}/best-concept-comments/${props.activityId}/${submissionVersion}`,
                          {
                            peerReviewId: value.peerReviewId,
                            comments: value.review?.comments,
                          },
                        )
                  }
                />
              </Row>
            </>
          ) : (
            <Editor editable={false} value={value.review?.reflection} />
          )}
        </ExpandableCard>
      ))}

      <Row>
        <Button
          text={'Refresh'}
          theme={themeConfig.primaryOutlined}
          onClick={() => {
            refetch();
          }}
        />
      </Row>
    </S.Column>
  );
};

export default InvestigationReflections;
