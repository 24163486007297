import styled from 'styled-components';
import { Col, Divider } from 'antd';
import Button from '../../shared/Button';
import Input from '../../shared/Input';

export const Status = styled.span<{
  $status: boolean;
}>`
  font-size: 0.95em;
  padding: 0 11px;
  font-weight: 800;
  color: ${(props) => (props.$status ? '#50c364' : 'red')};
`;

export const Title = styled.h1`
  text-align: left;
  margin-bottom: 25px;
  font-weight: 600;
`;

export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 5px;
  min-height: 60vh;
`;

export const Line = styled(Col)`
  .ant-form-item {
    margin: 0;
  }
`;

export const CDivider = styled(Divider)`
  margin: 5px 0 10px 0;
`;

export const TitleInput = styled.p`
  font-weight: 600;
  padding: 0 11px;
  font-size: 1.2em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const SInput = styled(Input)`
  font-size: 0.95em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  .ant-input-suffix {
    display: none;
  }
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 2px;
`;

export const RoleText = styled.span<{ color?: string }>`
  font-size: 0.95em;
  padding: 0 11px;
  font-weight: 800;
  color: ${(props) => props.color};
`;
