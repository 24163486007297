import React, { useMemo } from 'react';
import { Checkbox, message, Table } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { centerAlign } from '../../utils/antd';
import {
  GQL_EmailPreference,
  GQL_ToggleEmailPreferenceInput,
  GQL_ToggleEmailPreferenceResponse,
} from '../../types/login';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { useCallback } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const MailPreferences = () => {
  const { user, updateUser } = useAuth();
  const allChecked = useMemo(() => !user.emailPreference.some((e) => !e.active), [user]);

  const [submitToggleEmailPreference] = useMutation<
    { toggleEmailPreference: GQL_ToggleEmailPreferenceResponse[] },
    { data: GQL_ToggleEmailPreferenceInput[] }
  >(gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.toggleEmailPreference, {
    onCompleted: (data) => {
      message.success('Configuration changed successfully');
    },
    onError: (err) => {
      message.error('There was an error changing Email Preference: ' + err.message);
    },
    update(cache, { data }) {
      data?.toggleEmailPreference.forEach((config) => {
        const emailPrefrence = {
          __typename: 'EmailPreference',
          id: config.id,
        };
        cache.modify({
          id: cache.identify(emailPrefrence),
          fields: {
            active() {
              return config.isActive;
            },
          },
        });
      });
      updateUser({
        ...user,
        emailPreference: user.emailPreference.map((p) => {
          const configChanged = data?.toggleEmailPreference.find((f) => f.id === p.id);
          if (configChanged)
            return {
              ...p,
              active: configChanged.isActive,
            };
          return p;
        }),
      });
    },
  });

  const toggleIsActive = useCallback(
    (e: CheckboxChangeEvent, id?: string) => {
      if (id)
        submitToggleEmailPreference({
          variables: { data: [{ mailPreferenceId: id, isActive: e.target.checked }] },
        });
      else
        submitToggleEmailPreference({
          variables: {
            data: user.emailPreference.map((eP) => ({ mailPreferenceId: eP.id, isActive: e.target.checked })),
          },
        });
    },
    [submitToggleEmailPreference, user],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Mail Notification',
        dataIndex: 'title',
        width: '90%',
      },
      {
        title: <Checkbox checked={allChecked} onChange={(e: CheckboxChangeEvent) => toggleIsActive(e)} />,
        align: centerAlign,
        render: (text: string, record: GQL_EmailPreference, index: number) => (
          <Checkbox
            value={record.id}
            checked={record.active}
            onChange={(e: CheckboxChangeEvent) => toggleIsActive(e, record.id)}
          />
        ),
        width: '10%',
      },
    ],
    [toggleIsActive, allChecked],
  );
  return (
    <Table
      data-cy="shared-mailpreferences-table"
      columns={columns}
      rowKey={(record: GQL_EmailPreference) => record.id}
      bordered
      pagination={{ hideOnSinglePage: true }}
      dataSource={user.emailPreference}
    />
  );
};
export default MailPreferences;
