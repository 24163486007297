import React from 'react';
import { Col, Layout, Row, Skeleton } from 'antd';
import Sider from '../../../shared/Sider';
import InvestigationContent from '../../../shared/InvestigationContent';
import Spacer from '../../../shared/Spacer';

export const InvestigationContentLoading = () => (
  <InvestigationContent>
    <Row justify="center">
      <Col span={14}>
        <Skeleton.Input active />
      </Col>
    </Row>
    <Spacer size={48} />
    <Row justify="center" gutter={[0, 30]}>
      <Col span={14}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
    <Row justify="center" gutter={[0, 30]}>
      <Col span={12}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
    <Row justify="center" gutter={[0, 30]}>
      <Col span={10}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
    <Row justify="center" gutter={[0, 30]}>
      <Col span={12}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
    <Row justify="center" align="middle">
      <Col span={16} offset={2}>
        <Skeleton.Input active size="small" />
      </Col>
      <Spacer size={32} axis="horizontal" />
      <Col span={2}>
        <Skeleton.Avatar active size="large" />
      </Col>
    </Row>
    <Row justify="center" gutter={[0, 30]}>
      <Col span={14}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
    <Row justify="center" gutter={[0, 30]}>
      <Col span={14}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
    <Row justify="center" gutter={[0, 30]}>
      <Col span={16}>
        <Skeleton.Input active size="small" />
      </Col>
    </Row>
    <Spacer size={32} />
    <Row justify="center">
      <Col span={6}>
        <Skeleton.Input active size="large" />
      </Col>
    </Row>
  </InvestigationContent>
);

const InvestigationLoading = () => {
  return (
    <Layout>
      <Sider loading />
      <InvestigationContentLoading />
    </Layout>
  );
};

export default InvestigationLoading;
