import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Form as AntdForm, Col, Row, Tooltip, Popconfirm, Popover, message } from 'antd';
import { useQuery } from '@apollo/client';
import { FiFileText } from 'react-icons/fi';
import { FormInstance } from 'antd/lib/form/Form';

import Form from '../../Form';
import Input from '../../Input';
import Button from '../../Button';
import { ClassProps } from '../../../types/class';
import * as S from './styles';
import RangePicker from '../../RangePicker';
import InviteStudents from '../../InviteUsers';
import { gqlSchema } from '../../../gql/schema';
import { GQL_UserAssignLimitsResponse } from '../../../types/user';
import { StudentDataProps } from '../../../types/students';
import { onDownloadStudentTemplate } from '../../../utils/class';
import { themeConfig } from '../../../utils/theme';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';

interface Props {
  form: FormInstance<ClassProps>;
  onFinishClass: (data: ClassProps) => void;
  classInfo?: ClassProps | null;
  initialInvite: StudentDataProps[];
  hasUploadedFile: boolean;
  setHasUploadedFile: Dispatch<SetStateAction<boolean>>;
  activeKey: string;
  setActiveKey: Dispatch<SetStateAction<string>>;
  'data-cy': string;
}

const ClassInfo: React.FC<Props> = (props) => {
  const [viewStudentsDetails, setViewStudentsDetails] = useState(false);
  const [hasErrorFile, setHasErrorFile] = useState(false);

  // Created only to force rerender when a new student is added/removed
  // This is needed because form is updated directly on student list, which doesn't trigger rerender by default
  // Then we need to force rerender to update the button text/behavior
  const [, updateState] = React.useState<{}>();
  const forceRerender = React.useCallback(() => updateState({}), []);
  const { isFacilitator } = useAuth();
  const [loading, setLoading] = useState(false);
  const createButtonText = useMemo(() => (isFacilitator ? 'Course or Event' : 'Class'), [isFacilitator]);

  const {
    onFinishClass,
    classInfo,
    form,
    initialInvite,
    hasUploadedFile,
    setHasUploadedFile,
    activeKey,
    setActiveKey,
  } = props;

  const onFinish = useCallback(
    async (values: ClassProps) => {
      setLoading(true);
      try {
        await onFinishClass(values);
      } catch (error) {
        console.error("Error during class creation", error);
      } finally {
        setLoading(false);
      }
    },
    [onFinishClass],
  );
  

  const onImportEmails = useCallback(() => {
    setViewStudentsDetails(true);
    setHasUploadedFile(false);
  }, [setHasUploadedFile]);

  const onReimport = useCallback(() => {
    setViewStudentsDetails(false);
    setHasUploadedFile(false);
  }, [setHasUploadedFile]);

  useEffect(() => {
    if (classInfo) form.setFieldsValue({ invites: classInfo.invites });
  }, [form, classInfo]);

  const history = useHistory();
  const { isSubscriptionUser } = useAuth();

  const { data: userAssignLimits } = useQuery<{
    getUserAssigmentsLimits: GQL_UserAssignLimitsResponse;
  }>(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.getUserAssigmentsLimits, {
    onError: (error) => {
      message.error(`There was an error in fetching user assignment limits: ${error.message || 'Unexpected Error'}`);
    },
  });

  const actionDisabled = useMemo(() => {
    if (isSubscriptionUser) {
      if (
        userAssignLimits?.getUserAssigmentsLimits.totalAssignes.classesAssigned! >=
        userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxClasses!
      ) {
        return true;
      }
    }

    return false;
  }, [userAssignLimits, isSubscriptionUser]);

  const subscriptionPage = useCallback(() => {
    history.push('/manage-subscription');
  }, [history]);

  const invitesLength = form.getFieldValue('invites')?.length;

  const saveButton = useMemo(
    () => (
      <AntdForm.Item>
        <Popover
          placement="top"
          trigger={actionDisabled ? 'hover' : ''}
          content={
            <>
              <p style={{ marginBottom: 10 }}>Upgrade your subscription to be able to create more classes</p>
              <Button text="Upgrade" onClick={subscriptionPage} theme={themeConfig.secondaryColor} block />
            </>
          }
        >
          <div style={{ width: '100%' }}>
            <Popconfirm
              title={
                <>
                  <span role="none" >Are you sure you want to create this class without students?</span>
                  <br />
                  <span role="none" >You can always add students later by going to the Class Dashboard.</span>
                </>
              }
              onConfirm={form.submit}
              disabled={invitesLength > 0}
            >
              <Button
                block
                text={invitesLength > 0 ? 'Setup Students' : `Create ${createButtonText}`}
                onClick={invitesLength > 0 ? form.submit : undefined}
                disabled={actionDisabled}
                loading={loading}
              />
            </Popconfirm>
          </div>
        </Popover>
      </AntdForm.Item>
    ),
    [createButtonText, form, invitesLength, actionDisabled, subscriptionPage],
  );

  const renderButtons = useCallback(() => {
    let buttonBar = (
      <>
        <Col span={12} offset={6} data-cy="components-classes-row-create-class-create-button">
          {saveButton}
        </Col>
      </>
    );
    if (activeKey === 'file') {
      if (viewStudentsDetails) {
        buttonBar = (
          <>
            <Col span={12}>
              <Button block text="Import Another File" htmlType="button" onClick={onReimport} />
            </Col>
            <Col span={12}>{saveButton}</Col>
          </>
        );
      } else {
        if (hasUploadedFile) {
          buttonBar = (
            <Col span={12} offset={6} style={{ marginBottom: 24 }}>
              <Button block disabled={hasErrorFile} text="Import Emails" htmlType="button" onClick={onImportEmails} />
            </Col>
          );
        }
      }
    }
    return <Row gutter={16}>{buttonBar}</Row>;
  }, [saveButton, activeKey, viewStudentsDetails, onReimport, hasUploadedFile, onImportEmails, hasErrorFile]);

  return (
    <Form onFinish={onFinish} form={form} initialValues={{ invites: initialInvite }} data-cy={props['data-cy']}>
      <Row gutter={24}>
        <Col span={10} offset={2}>
          <S.TitleInput>Class Name</S.TitleInput>
          <AntdForm.Item
            data-cy="components-classes-row-create-class-name"
            name="name"
            initialValue={classInfo?.name}
            rules={[
              {
                required: true,
                message: 'Please type the class name!',
              },
            ]}
          >
            <Input placeholder="Type the class name here..." />
          </AntdForm.Item>
        </Col>
        <Col span={10} offset={2}>
          <S.TitleInput>Description</S.TitleInput>
          <AntdForm.Item
            data-cy="components-classes-row-create-class-description"
            name="description"
            initialValue={classInfo?.description}
            rules={[
              {
                required: false,
                message: 'Please type the class description!',
              },
            ]}
          >
            <Input placeholder="Type the class description here..." />
          </AntdForm.Item>
        </Col>
        <Col span={10} offset={2}>
          <S.TitleInput>Class Duration</S.TitleInput>
          <AntdForm.Item
            name="duration"
            initialValue={
              classInfo
                ? {
                    startDate: classInfo.duration.startDate,
                    endDate: classInfo.duration.endDate,
                  }
                : null
            }
            rules={[
              {
                required: true,
                message: 'Please select the class duration!',
              },
            ]}
          >
            <RangePicker />
          </AntdForm.Item>
        </Col>
        <Col span={20} offset={2}>
          <InviteStudents
            form={form}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            setHasUploadedFile={setHasUploadedFile}
            viewUsersDetails={viewStudentsDetails}
            setHasErrorFile={setHasErrorFile}
            hasErrorFile={hasErrorFile}
            initialInvite={initialInvite}
            userType="canvas_student"
            onUserListUpdate={forceRerender}
          />
        </Col>
        {activeKey === 'file' && (
          <Col span={1}>
            <S.DownloadTemplate>
              <Tooltip title="Click here to download a template to import your class/data." placement="bottom">
                <FiFileText
                  size={24}
                  onClick={onDownloadStudentTemplate}
                  style={{ cursor: 'pointer' }}
                  color={themeConfig.secondaryColor.background}
                />
              </Tooltip>
            </S.DownloadTemplate>
          </Col>
        )}
        <Col span={16} offset={4}>
          {renderButtons()}
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(ClassInfo);
