import { gql } from '@apollo/client';
 
export const GCLASSROOM_MUTATIONS = {
  GCLASS: {
    AddCourse: gql`
      mutation AddCourse( $data: CreateClassInput!) {
        addCourse( data: $data) {
          id
          name
          section
          descriptionHeading
          description
          room
          ownerId
          creationTime
          updateTime
          enrollmentCode
          courseState
          alternateLink
          teacherGroupEmail
          courseGroupEmail
          guardiansEnabled
          calendarId
        }
      }
    `,
    EditCourse:gql`
    mutation EditCourse($courseId:String!,$data:CreateClassInput!) {
    editCourse(
        courseId: $courseId,
        data: $data
    ) {
        id
        name
        section
        descriptionHeading
        description
        room
        ownerId
        creationTime
        updateTime
        enrollmentCode
        courseState
        alternateLink
        teacherGroupEmail
        courseGroupEmail
        guardiansEnabled
        calendarId
        teacherFolder {
            id
            title
            alternateLink
        }
        gradebookSettings {
            calculationType
            displaySetting
            gradeCategories {
                id
                name
                weight
                defaultGradeDenominator
            }
        }
    }
}

    `,
    CreateInviatation: gql`
    mutation CreateInvitation($data:[InvitationData]) {
      createInviatation(
          data: $data
          
      ) {
          courseId
          id
          role
      }
  }
    `,
    CreateCourseWork: gql`
    mutation CreateCourseWork($courseId: String!, $data: courseWorkData!) {
        createCourseWork(courseId: $courseId, data: $data) {
            courseId
            id
            title
            description
            state
            alternateLink
            creationTime
            updateTime
            scheduledTime
            maxPoints
            workType
            associatedWithDeveloper
            assigneeMode
            submissionModificationMode
            creatorUserId
            topicId
            details {
            ... on Assignment {
                studentWorkFolder {
                    id
                    title
                    alternateLink
                    thumbnailUrl
                }
            }
  }
            materials {
                sharedDriveFile {
                    shareMode
                    driveFile {
                        id
                        title
                        alternateLink
                        thumbnailUrl
                    }
                }
                youtubeVideo {
                    id
                    title
                    alternateLink
                    thumbnailUrl
                }
                link {
                    url
                    title
                    thumbnailUrl
                }
                form {
                    formUrl
                    title
                    thumbnailUrl
                }
            }
            dueDate {
                year
                month
                day
            }
            dueTime {
                hours
                minutes
                seconds
                nanos
            }
            assignment {
                studentWorkFolder {
                    id
                }
            }
        }
    }
`,
    UpdateCourseWork:gql`
    mutation UpdateCourseWork($courseId: String!, $data: updateAssignment!, $id: String!, $updateMask: String!) {
        updateCourseWork(
            courseId: $courseId,
            updateMask: $updateMask,
            id: $id,
            data: $data
        ) {
            courseId
            id
            title
            state
            alternateLink
            creationTime
            updateTime
            scheduledTime
            maxPoints
            associatedWithDeveloper
            assigneeMode
            submissionModificationMode
            creatorUserId
            topicId
            dueTime {
                hours
                minutes
                seconds
                nanos
            }
            dueDate {
                year
                month
                day
            }
            materials {
                sharedDriveFile {
                    shareMode
                    driveFile {
                        id
                        title
                        alternateLink
                        thumbnailUrl
                    }
                }
                youtubeVideo {
                    id
                    title
                    alternateLink
                    thumbnailUrl
                }
                link {
                    url
                    title
                    thumbnailUrl
                }
                form {
                    formUrl
                    title
                    thumbnailUrl
                }
            }
            details {
                ... on Assignment {
                    studentWorkFolder {
                        id
                        title
                        alternateLink
                        thumbnailUrl
                    }
                }
            }
        }
    }
`,
    UploadFile:gql`
    mutation UploadFilesForGoogleClassroom($file: [Upload!]!, $courseId: String!) {
    uploadFilesForGoogleClassroom(file: { file: $file, courseId: $courseId }) 
}
 
    `,
    GradeCourseWorkStudentSubmission: gql`
    mutation GradeCourseWorkStudentSubmission($courseId:String,$courseWorkId:String,$id:String,$data:grade) {
      gradeCourseWorkStudentSubmission(
          id: $id
          courseId: $courseId
          courseWorkId: $courseWorkId
          data: $data
      ) {
          id
          courseWorkId
          courseId
          userId
          state
          alternateLink
          creationTime
          updateTime
          courseWorkType
          assignedGrade
          draftGrade
      }
  }
  
  
    `,
    AddStudentCourse:gql`
    mutation AddStudentCourse($code:String,$data:StudentData) {
    addStudentCourse(       
        data: $data
        code: $code
    ) {
        courseId
        userId
        driveFolder {
            id
            title
            alternateLink
        }
        profile {
            id
            emailAddress
            photoUrl
            name {
                givenName
                familyName
                fullName
            }
        }
    }
}
 
    `,
    DeleteCourseWork:gql`
    mutation DeleteCourseWork($id:String,$courseId:String) {
    deleteCourseWork(id: $id, courseId: $courseId)
    }
    `,
    ModifyAssigneeCourseWork:gql`
    mutation ModifyAssigneeCourseWork($courseId:String,$id:String,$data:Assignee) {
    modifyAssigneeCourseWork(
        courseId: $courseId
        data: $data
        id: $id
    ) {
       courseId
        id
        title
        updateTime
        maxPoints
        workType
        creationTime
        submissionModificationMode
         assignment {
            studentWorkFolder {
                id
            }
        }
        individualStudentsOptions {
            studentIds
        }
    }
}

    `,
    TurnincourseWorkStudentSubmissions:gql`
    mutation TurnincourseWorkStudentSubmissions($courseId:String,$courseWorkId:String,$id:String) {
    turnincourseWorkStudentSubmissions(
        courseId: $courseId
        courseWorkId: $courseWorkId
        id: $id
    )
}

    `,
    ModifyCourseWorkStudentSubmission:gql`
    mutation ModifyCourseWorkStudentSubmission($id:String,$courseId:String,$courseWorkId:String,$data:AttachSubmission) {
    modifyCourseWorkStudentSubmission(
        data: $data
        id: $id
        courseId: $courseId
        courseWorkId: $courseWorkId
    ) {
        id
        courseId
        courseWorkId
        courseId
         userId
        state
        alternateLink
        creationTime
        updateTime
        courseWorkType
    }
        
}
    `,
    ReclaimCourseWorkStudentSubmissions:gql`
    mutation ReclaimCourseWorkStudentSubmissions($courseId:String,$courseWorkId:String,$id:String) {
    reclaimcourseWorkStudentSubmissions(
        courseId: $courseId
        courseWorkId: $courseWorkId
        id: $id
    )
}

    `,
    Sync:gql`
    mutation CourseSync {
    CourseSync
}


    `,
  },
};

export default GCLASSROOM_MUTATIONS;


