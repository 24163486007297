/* eslint-disable max-statements */
import { Col, Layout, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import InvestigationContent from '../../shared/InvestigationContent';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import ActivitiesSettingsSider from './ActivitiesSettingsSider';
import { debounce, omit } from 'lodash';
import InvestigationNotes from '../../shared/InvestigationNotes';
import InvestigationContentBuilder from '../../components/StudentInvestigation/InvestigationContentBuilder';
import * as S from './styles';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import Spacer from '../../shared/Spacer';
import {
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationCatalogActivityEntry,
  InvestigationType,
} from '../../types/investigation';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import {
  clearKeyFromObject,
  transformActivityValuesIntoHTML,
  transformActivityValuesIntoSpans,
} from '../../utils/investigation';
import EditableBlockBuilder from './EditableBlockBuilder';
import { GQL_InvestigationStandard } from '../../types/investigationStandard';

type Props = RouteComponentProps<
  {
    investigationId: string;
    stageId: string;
    submissionVersion: string;
  },
  any,
  {
    initialActivityId?: string;
  }
>;

const AdiEditInvestigationActivitiesPage: React.FC<Props> = (props) => {
  const { investigationId, stageId, submissionVersion } = props.match.params;
  const { initialActivityId } = props.location.state || {};
  const history = useHistory();
  const [notes, setNotes] = useState<{
    remoteTeacher?: string;
    inPersonTeacher?: string;
    inPersonTeacherTips?: string;
    safety?: string;
    help?: string;
  }>();
  const [selectedContentIndex, setSelectedContentIndex] = useState<number>();
  const [selectedActivityId, setSelectedActivityId] = useState(initialActivityId);
  const [previewContentIndex, setPreviewContentIndex] = useState(0);
  const [editingTab, setEditingTab] = useState('remote');
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [selectedBlockIndex, setSelectedBlockIndex] = useState<number>();
  const [activitySiderTitle, setActivitySiderTitle] = useState<string>();
  const [activities, setActivities] = useState<GQL_InvestigationCatalogActivityEntry[]>([]);
  const [presentationMode, setPresentationMode] = useState(false);
  const [isAssessment, setIsAssessment] = useState(false);
  const [investigationType, setInvestigationType] = useState<InvestigationType>();
  const activityTitle = isAssessment ? 'Part' : 'Activity';

  useEffect(() => {
    setSelectedContentIndex(0);
  }, [selectedActivityId]);

  const { data: investigationDraftData } = useQuery<
    { getInvestigationDraftById: GQL_InvestigationCatalogEntry },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationDraftById, {
    variables: {
      id: investigationId,
    },
    onCompleted: ({ getInvestigationDraftById }) => {
      const investigationDraft = clearKeyFromObject(getInvestigationDraftById);
      const selectedStage = investigationDraft.steps.find((stage) => stage.id === stageId);
      if (!selectedStage?.activities) {
        message.error('This stage has no activities');
        history.goBack();
      }

      if (selectedActivityId) {
        const selectedActivity = selectedStage?.activities?.find((a) => a.id === selectedActivityId);
        setNgssStandardList(selectedActivity?.nextGenerationStandards)
        setTexasStandardList(selectedActivity?.texasStandards)
        setNotes({
          remoteTeacher: selectedActivity?.remoteTeacher ?? undefined,
          inPersonTeacher: selectedActivity?.inPersonTeacher ?? undefined,
          inPersonTeacherTips: selectedActivity?.inPersonTeacherTips ?? undefined,
          help: selectedActivity?.help ?? undefined,
          safety: selectedActivity?.safety ?? undefined,
        });
      }

      setIsAssessment(investigationDraft.isAssessment);
      setEditingTab(selectedStage?.mode === 'INPERSON' ? 'in-person' : 'remote');
      setActivitySiderTitle(selectedStage?.name);
      setActivities(selectedStage?.activities?.map((activity) => transformActivityValuesIntoHTML(activity)) || []);
      setSelectedContentIndex(0);
      setInvestigationType(getInvestigationDraftById.type);
    },
    onError: (err) => {
      message.error('There was an error getting the investigation: ' + err.message || 'Unexpected Error');
      history.goBack();
    },
  });

  const assessment = investigationDraftData?.getInvestigationDraftById || ({} as GQL_InvestigationCatalogEntry);
  const currentStage = investigationDraftData?.getInvestigationDraftById?.steps?.find((s) => s.id === stageId);
  const isRemoteContentSelected = editingTab === 'remote';
  const selectedActivity = activities?.find((activity) => activity.id === selectedActivityId);

  const [ngssStandardList, setNgssStandardList] = useState<GQL_InvestigationStandard[] | undefined>(
    selectedActivity?.nextGenerationStandards,
  );
  const [texasStandardList, setTexasStandardList] = useState<GQL_InvestigationStandard[] | undefined>(
    selectedActivity?.texasStandards,
  );

  const selectedActivityContentList = isRemoteContentSelected
    ? selectedActivity?.content
    : selectedActivity?.contentInPerson;
  const selectedContent =
    selectedContentIndex !== undefined && selectedActivityContentList
      ? selectedActivityContentList[selectedContentIndex]
      : undefined;

  const setNotesDebounced = debounce(setNotes, 200);

  const [editActivity, { loading: loadingEditActivity }] = useMutation<{
    editActivityDraft: GQL_InvestigationCatalogActivityEntry;
  }>(gqlSchema.InvestigationSchema.mutations.DRAFT.editActivityDraft, {
    onCompleted: ({ editActivityDraft }) => {
      message.success(`${activityTitle} updated successfully`);
      setActivities(
        activities.map((activity) => {
          return activity.id === editActivityDraft.id ? transformActivityValuesIntoHTML(editActivityDraft) : activity;
        }),
      );
    },
    onError: (err) => {
      message.error(
        `There was an error saving the ${activityTitle.toLowerCase()}: ` + err.message || 'Unexpected Error',
      );
    },
    refetchQueries: ['getInvestigationDraftById']
  });

  const handleUpdateCurrentContent = (index?: number) => {
    setSelectedContentIndex(index);
    setSelectedBlockIndex(undefined);
  };

  const handleRemoteInpersonTabChange = (v: string) => {
    setEditingTab(v);
    setSelectedBlockIndex(undefined);
    setSelectedContentIndex(selectedActivityContentList?.length ? 0 : undefined);
  };

  const updateSelectedActivityField = (fieldName: string, value: any) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        else
          return {
            ...activity,
            [fieldName]: value,
          };
      }),
    );
  };

  const handleAddContent = () => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;

        if (isRemoteContentSelected) {
          return {
            ...activity,
            content: [
              ...(activity.content || []),
              {
                title: 'New Content',
                blocks: [],
              },
            ],
          };
        } else {
          return {
            ...activity,
            contentInPerson: [
              ...(activity.contentInPerson || []),
              {
                title: 'New Content',
                blocks: [],
              },
            ],
          };
        }
      }),
    );

    setTimeout(() => {
      handleUpdateCurrentContent(
        isRemoteContentSelected ? selectedActivity?.content?.length : selectedActivity?.contentInPerson?.length,
      );
    });
  };

  const handleDeleteContent = () => {
    if (selectedContentIndex === undefined) return;
    handleUpdateCurrentContent(undefined);

    setActivities(
      activities?.map((activity) => {
        if (activity.id !== selectedActivity?.id) return activity;
        let content = isRemoteContentSelected ? [...activity.content] : [...(activity.contentInPerson || [])];
        content.splice(selectedContentIndex, 1);

        return isRemoteContentSelected ? { ...activity, content } : { ...activity, contentInPerson: content };
      }),
    );
  };

  const handleDeleteBlock = (index: number) => {
    if (selectedContentIndex === undefined || !selectedActivity) return;
    setSelectedBlockIndex(undefined);

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const contentList = isRemoteContentSelected ? activity.content : activity.contentInPerson;
        const newContent = contentList.map((content, i) => {
          const contentBlock = content.blocks ? [...content.blocks] : [];
          contentBlock.splice(index, 1);

          return i !== selectedContentIndex ? content : { ...content, blocks: contentBlock };
        });

        return isRemoteContentSelected
          ? { ...activity, content: newContent }
          : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleMoveBlockUp = (index: number, direction: 'up' | 'down') => {
    if (selectedContentIndex === undefined || !selectedActivity) return;
    setSelectedBlockIndex(undefined);

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const contentList = isRemoteContentSelected ? activity.content : activity.contentInPerson;
        const newContent = contentList.map((content, i) => {
          if (i !== selectedContentIndex) return content;
          const contentBlock = content.blocks ? [...content.blocks] : [];
          const swapIndex = direction === 'up' ? index - 1 : index + 1;
          contentBlock[index] = content.blocks[swapIndex];
          contentBlock[swapIndex] = content.blocks[index];

          return { ...content, blocks: contentBlock };
        });

        return isRemoteContentSelected
          ? { ...activity, content: newContent }
          : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handlePreviewingChange = () => {
    if(isAssessment) {
    const ngssStandardsEmpty = ((ngssStandardList || []).length ===0 );
    const texasStandardsEmpty = ((texasStandardList || []).length ===0);
    if ( ngssStandardsEmpty && texasStandardsEmpty) {
      message.error('Please select at least one standard (NGSS or Texas Standards).');
      return;
    }
  }
    if (!isPreviewing) {
      setPreviewContentIndex(0);
      handleSaveActivity();
    }
    setIsPreviewing(!isPreviewing);
  };

  const handleSaveActivity = async () => {
    if (selectedActivity) {
      const cleanSelectedActivity = clearKeyFromObject(selectedActivity, [
        '__typename',
        'stepId',
        'completedAt',
        'peerReviewId',
        'rating',
        'reflection',
        'readyToReview',
        'review',
        'answer',
        'feedback',
        'MIMEtype',
        'reflectionCompletedAt',
        'hidden',
        'materials',
        'activityId',
      ]);

      const activity: GQL_InvestigationCatalogActivityEntry = {
        ...transformActivityValuesIntoSpans(cleanSelectedActivity),
        ...notes,
        nextGenerationStandards: [],
        texasStandards: [],
      };

      if (isAssessment) {
        const nextGenerationStandards = (ngssStandardList || []).map((standard) => {
          const _standard = omit(standard, '__typename');
          return { ..._standard };
        });
  
        const texasStandards = (texasStandardList || []).map((standard) => {
          const _standard = omit(standard, '__typename');
          return { ..._standard };
        });

        activity.nextGenerationStandards = nextGenerationStandards;
        activity.texasStandards = texasStandards;
      }

      await editActivity({
        variables: {
          activityDraft: {
            ...activity,
          },
          investigationDraftId: investigationId,
          stepDraftId: stageId,
        },
      });
    }
  };

  const handleNgsStandards = (values: GQL_InvestigationStandard[]) => {
    setNgssStandardList(values);
  };

  const handleTexasStandards = (values: GQL_InvestigationStandard[]) => {
    setTexasStandardList(values);
  };

  const buildBlocks = () => {
    return selectedContent?.blocks?.map((block, i, arr) => (
      <EditableBlockBuilder
        key={`${selectedContentIndex}${i}`}
        block={block}
        handleDeleteBlock={() => handleDeleteBlock(i)}
        handleMoveBlockUp={i > 0 ? () => handleMoveBlockUp(i, 'up') : undefined}
        handleMoveBlockDown={i < arr.length - 1 ? () => handleMoveBlockUp(i, 'down') : undefined}
        selected={selectedBlockIndex === i}
        selectedContentIndex={selectedContentIndex}
        setSelectedBlockIndex={() => setSelectedBlockIndex(i)}
      />
    ));
  };

  return (
    <Layout>
      <ActivitiesSettingsSider
        investigationId={investigationId}
        stageId={stageId}
        title={activitySiderTitle}
        setSelectedActivityId={setSelectedActivityId}
        selectedActivityId={selectedActivityId}
        setNotes={setNotesDebounced}
        notes={notes}
        loading={loadingEditActivity}
        selectedContentIndex={selectedContentIndex}
        saveActivity={handleSaveActivity}
        setActivities={setActivities}
        updateSelectedActivityField={updateSelectedActivityField}
        selectedActivity={selectedActivity}
        activities={activities}
        setSelectedBlockIndex={setSelectedBlockIndex}
        selectedBlockIndex={selectedBlockIndex}
        handlePreviewingChange={handlePreviewingChange}
        isPreviewing={isPreviewing}
        editingTab={editingTab}
        setEditingTab={handleRemoteInpersonTabChange}
        stageMode={currentStage?.mode}
        presentationMode={presentationMode}
        setPresentationMode={setPresentationMode}
        isAssessment={isAssessment}
        assessment={assessment}
        setNgssStandards={handleNgsStandards}
        setTexasStandards={handleTexasStandards}
      />
      <InvestigationContent allowFullscreen={isPreviewing} presentationMode={presentationMode} fullScreenZIndex={1001}>
        {isPreviewing ? (
          <InvestigationContentBuilder
            submissionVersion={parseInt(submissionVersion)}
            currentContentIndex={previewContentIndex}
            currentStepId=""
            setCurrentContentIndex={setPreviewContentIndex}
            currentActivity={selectedActivity}
            investigationDuedate={Date.now()}
            buildInPersonContent={editingTab === 'in-person'}
            preview
            presentationMode={presentationMode}
            investigationType={investigationType}
          />
        ) : (
          <Row justify="center" gutter={[0, 32]}>
            <Col xs={23} md={23} lg={23} xxl={20}>
              <S.ContentPagesContainer>
                {selectedActivityContentList?.map((c, i) => (
                  <React.Fragment key={c.title + i}>
                    <S.ContentPage selected={i === selectedContentIndex} onClick={() => handleUpdateCurrentContent(i)}>
                      {i + 1}
                    </S.ContentPage>
                    <Spacer axis="horizontal" />
                  </React.Fragment>
                ))}
                <Spacer axis="horizontal" size={32} />
                <S.ManageContent onClick={handleAddContent}>
                  <FiPlus />
                </S.ManageContent>
                <Spacer axis="horizontal" />
                <S.ManageContent onClick={handleDeleteContent} disabled={selectedContentIndex === undefined}>
                  <FiTrash2 />
                </S.ManageContent>
              </S.ContentPagesContainer>
            </Col>
            <S.Column span={16}>
              <h1>{selectedContent?.title}</h1>

              {buildBlocks()}

              {!!selectedContent && (
                <S.ContentContainer>
                  <FiPlus />
                  <p>Select a New Block on the Sidebar</p>
                </S.ContentContainer>
              )}
            </S.Column>
          </Row>
        )}
        <InvestigationNotes
          {...(notes || {})}
          helpVideoUrl={selectedActivity?.helpVideoUrl}
          teacherHelpVideoUrl={selectedActivity?.teacherHelpVideoUrl}
          zIndex={1001}
        />
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(AdiEditInvestigationActivitiesPage);
