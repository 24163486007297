import { useCallback, useEffect, useState } from 'react';

interface TimerHookProps {
  duration: number;
  onEnd: () => void;
}

const useTimer = ({ duration, onEnd }: TimerHookProps) => {
  const [time, setTime] = useState(0);
  const [started, setStarted] = useState(false);
  const [stopped, setStopped] = useState(false);

  const start = useCallback(() => {
    setTime(0);
    setStarted(true);
    setStopped(false);
  }, []);

  const stop = useCallback(() => {
    onEnd();
    setTime(0);
    setStopped(true);
    setStarted(false);
  }, [onEnd]);

  const clearTimer = useCallback(() => {
    setTime(0);
  }, []);

  useEffect(() => {
    if (started) {
      if (time === duration) stop();
      else setTimeout(() => started && setTime(time + 1), 1000);
    }
  });

  return {
    started,
    start,
    stopped,
    stop,
    time,
    clearTimer,
  };
};

export default useTimer;
