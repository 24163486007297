import { Row, Dropdown, Menu, Modal, Col, message, Popover, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiChevronDown, FiDownload } from 'react-icons/fi';
import { useMutation, useQuery } from '@apollo/client';

import { GQL_GradedFinalReportQuestionnaire } from '../../../types/investigation';
import { convertToCSV, downloadCsv } from '../../../utils/files';
import Spacer from '../../../shared/Spacer';
import Button from '../../../shared/Button';
import { useAuth } from '../../../hooks/useAuth';
import { gqlSchema } from '../../../gql/schema';
import { themeConfig } from '../../../utils/theme';
import { GQL_AllMaterialsResponse } from '../../../types/teacher';
import * as S from './styles';
import { SubscriptionPackageType } from '../../../constants/enums/SubscriptionPackageType';
import { Role } from '../../../constants/enums/Role';

type IStudentRow = {
  firstName: string;
  lastName: string;
  overall: string;
  part1Score: string;
  part1Feedback: string;
};

type Props = {
  assessmentId: string;
  assessmentData: any;
  isTeacherOrFacilitator: boolean;
};

export const AssessmentActions: React.FC<Props> = (props) => {
  const { assessmentId, isTeacherOrFacilitator, assessmentData } = props;
  const [openMaterials, setOpenMaterials] = useState(false);
  const [isLoadingGrading, setLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const { isSubscriptionUser, subscriptionPackageType, activeRole, user } = useAuth();

  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';

  const history = useHistory();

  const [getScoreDate] = useMutation<{ getFinalReportGrading: GQL_GradedFinalReportQuestionnaire }>(
    gqlSchema.InvestigationSchema.mutations.GRADING.getFinalReportGrading,
    {
      onError: (err) => {
        message.error('There was an error fetching grades. Try again');
      },
    },
  );

  const [getAllMaterials] = useMutation<{ getAllInvestigationMaterials: GQL_AllMaterialsResponse }, { id: string }>(
    gqlSchema.InvestigationSchema.mutations.SETTINGS.getAllInvestigationMaterials,
    {
      onError: (error) => {
        message.error(error.message || 'There was an error downloading all materials, please check your connection and try again later');
      },
      fetchPolicy: 'network-only',
    },
  );

  const { data: initialCacheQuery, startPolling, stopPolling } = useQuery(
    gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationMaterialsDownloadCache,
    {
      variables: { id: assessmentId },
    },
  );

  const initialCacheStatus = initialCacheQuery?.getInvestigationMaterialsDownloadCache;
  const cacheUrl = initialCacheStatus?.fileUrl;

  const noMaterials = useMemo(() => {
    return assessmentData?.materials?.length === 0;
  }, [assessmentData]);

  const downloadAllDisabled = useMemo(() => {
    if (!isSubscriptionUser && activeRole !== Role.TEACHER_ASSISTANT) {
      return false;
    } else {
      // If STARTER plan disable feature
      if (subscriptionPackageType === SubscriptionPackageType.STARTER) {
        return true;
      }

      return false;
    }
  }, [isSubscriptionUser, subscriptionPackageType, activeRole]);

  const downloadAllMaterials = useCallback(() => {
    if (!cacheUrl) {
      startPolling(5000);
      getAllMaterials({ variables: { id: assessmentId } });
      setIsPolling(true);
    } else {
      window.location.href = cacheUrl;
    }
  }, [assessmentId, cacheUrl, startPolling, getAllMaterials]);

  const subscriptionPage = useCallback(() => {
    history.push('/change-subscription');
  }, [history]);

  useEffect(() => {
    if (isPolling && cacheUrl) {
      setIsPolling(false);
      stopPolling();
      // Downloads the file automatically
      window.location.href = cacheUrl;
    }
  }, [cacheUrl, isPolling, stopPolling]);

  const exportGradesToCsv = useCallback(async () => {
    setLoading(true);
    const fullObj: IStudentRow[] = await Promise.all(
      assessmentData?.perStudents.map(async (student: any) => {
        const { data } = await getScoreDate({
          variables: {
            investigationId: assessmentId,
            userId: student.userId,
          },
        });
        let dynamicParams: any = {};
        data?.getFinalReportGrading?.questionnaire.forEach((questionnaire, index) => {
          dynamicParams[`part${index + 1}Score`] =
            student.status === 'COMPLETED'
              ? questionnaire?.questions[0].answer
                ? 'Correct'
                : 'Incorrect'
              : student.status === 'READY_FOR_GRADING'
              ? 'Needs Grading'
              : 'Incomplete';
          dynamicParams[`part${index + 1}Feedback`] = questionnaire?.feedback?.replace(/<(?:.|\n)*?>/gm, '') || '-';
        });

        const stepCount = data?.getFinalReportGrading?.questionnaire?.length || 0;
        const maxScore = stepCount * 3;
        const score = data?.getFinalReportGrading?.grade || 0;
        const gradePercentage = (score / maxScore) * 100;

        const studentRowJson = {
          firstName: student.firstName,
          lastName: student.lastName,
          overall: `${gradePercentage.toFixed(2)}%`,
          rawOverall: `${score}`,
          ...dynamicParams,
        };
        return studentRowJson;
      }),
    );

    let dynamicHeaderParams: any = {};

    assessmentData?.perStudents?.[0]?.steps?.[0]?.activities.forEach((_: any, index: number) => {
      dynamicHeaderParams[`part${index + 1}Score`] = `Part ${index + 1} score`;
      dynamicHeaderParams[`part${index + 1}Feedback`] = `Part ${index + 1} feedback`;
    });

    const numActivities = assessmentData?.perStudents?.[0]?.steps?.[0]?.activities?.length || 0;
    const formattedData = convertToCSV(fullObj, {
      firstName: 'First Name',
      lastName: 'Last Name',
      overall: 'Percentage overall score',
      rawOverall: `Raw overall score (out of ${numActivities * 3})`,
      ...dynamicHeaderParams,
    });

    downloadCsv(formattedData, assessmentData?.title || 'Grade Data');
    setLoading(false);
  }, [assessmentData, assessmentId, getScoreDate]);

  const downloadMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={() => setOpenMaterials(true)} disabled={noMaterials}>
          <Tooltip title={noMaterials ? 'No materials related to this assessment' : undefined}>Materials</Tooltip>
        </Menu.Item>
        <Menu.Item key="2" onClick={exportGradesToCsv}>
          Grades
        </Menu.Item>
      </Menu>
    ),
    [exportGradesToCsv, noMaterials],
  );

  const exportMenu = useMemo(
    () => (
      <Menu>
        {(isGoogleTeacher || isCanvasTeacher || isTeacherOrFacilitator) && (
          <Menu.Item key="2" onClick={() => history.push(`/teacher-dashboard/assessment-presentation/${assessmentId}`)}>
            Presentation Mode
          </Menu.Item>
        )}
        {(isGoogleTeacher || isCanvasTeacher || isTeacherOrFacilitator) && (
          <Menu.Item
            key="3"
            onClick={() => history.push(`/teacher-dashboard/assessment-summary/${assessmentId}/results`)}
          >
            Summary
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => history.push(`/teacher-dashboard/assessment-details/${assessmentId}`)}>
          View Responses
        </Menu.Item>
      </Menu>
    ),
    [assessmentId, history, isTeacherOrFacilitator, isGoogleTeacher, isCanvasTeacher],
  );

  return (
    <>
      <S.ActionsContainer>
        <span role="none" >
          {((isGoogleTeacher || isCanvasTeacher || isTeacherOrFacilitator) || activeRole === Role.TEACHER_ASSISTANT) && (
            <Dropdown overlay={downloadMenu} arrow placement="bottomRight" trigger={['click']}>
              <Button
                text={isLoadingGrading ? 'Loading...' : 'Downloads'}
                data-cy="components-assessment-view-type"
                disabled={isLoadingGrading}
                suffix={<FiChevronDown style={{ marginLeft: 4, marginRight: 0 }} />}
              />
            </Dropdown>
          )}
        </span>
        <Row>
          <Spacer axis="horizontal" />
          <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
            <Button
              text={'View Assessment'}
              data-cy="components-assessment-view-type"
              suffix={<FiChevronDown style={{ marginLeft: 4, marginRight: 0 }} />}
            />
          </Dropdown>
        </Row>
      </S.ActionsContainer>
      <Modal visible={openMaterials} onCancel={() => setOpenMaterials(false)} footer={null} width={700}>
        <S.MaterialsModalContainer>
          <h1>Download Materials</h1>
          <Row>
            <Col span={12} offset={6}>
              Here you can find the downloadable materials for each stage
            </Col>
            <Col span={6}>
              <Popover
                placement="top"
                trigger={downloadAllDisabled ? 'hover' : ''}
                content={
                  <>
                    <p style={{ marginBottom: 10 }}>Upgrade your subscription to enable this feature</p>
                    <Button text="Upgrade" onClick={subscriptionPage} theme={themeConfig.secondaryColor} block />
                  </>
                }
              >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                  <Button
                    text="Download all"
                    theme={themeConfig.primaryColor}
                    icon={<FiDownload />}
                    onClick={downloadAllMaterials}
                    disabled={isPolling || downloadAllDisabled}
                  />
                </div>
              </Popover>
            </Col>
          </Row>
          <Spacer />
          <Row gutter={8}>
            <Col span={9}>
              <h2>Material Name</h2>
            </Col>
          </Row>
          {assessmentData?.materials?.map((material: any) => {
            return (
              <Row gutter={8} key={material.stepname}>
                <Col span={18}>
                  {material.materials?.map((m: any) => (
                    <React.Fragment key={m.id}>
                      <S.BorderedContainer>
                        <p>{m.filename}</p>
                      </S.BorderedContainer>
                      <Spacer size={8} />
                    </React.Fragment>
                  ))}
                </Col>
                <Col span={6}>
                  {material.materials?.map((m: any) => (
                    <React.Fragment key={m.id}>
                      <S.DownloadLink href={m.url} download>
                        Download
                      </S.DownloadLink>
                      <Spacer size={8} />
                    </React.Fragment>
                  ))}
                </Col>
              </Row>
            );
          })}
        </S.MaterialsModalContainer>
      </Modal>
    </>
  );
};
