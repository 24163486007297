import React, { useCallback, useState } from 'react';
import { useRemirror } from 'remirror/react';
import { FiAlignCenter, FiAlignLeft, FiAlignRight, FiBold, FiItalic, FiLink } from 'react-icons/fi';
import { AiOutlineFunction } from 'react-icons/ai';
import { Button, Col, Popover, Row, Tooltip } from 'antd';
import * as S from './styles';
import Input from '../../../Input';
import Spacer from '../../../Spacer';
import { BsListUl } from 'react-icons/bs';
import { GoListOrdered } from 'react-icons/go';
import { findChildren, NodeWithPosition, Selection } from 'remirror/core';

interface IMenuBar {
  title?: string;
  showAlign?: boolean;
  showLink?: boolean;
}

const findParagraphChildrenWithinSelection = (node: NodeWithPosition, selection: Selection) => {
  const nodeInitialPos = node.pos;
  const nodeFinalPos = node.pos + node.node.nodeSize;
  if (node.node.type.name !== 'paragraph') return false;

  /**
   * What this will do is account for every possible cursor position on a text selection, which are:
   * _1 2 3 4_ (first condition)
   * _1 2 3_4 (first condition)
   *  1_2 3 4_ (second condition)
   *  1_2 3_4 (third condition)
   */
  return (
    (selection.from <= nodeInitialPos && selection.to >= nodeInitialPos) ||
    (selection.from <= nodeFinalPos && selection.to >= nodeFinalPos) ||
    (selection.from >= nodeInitialPos && selection.to <= nodeFinalPos)
  );
};

const MenuBar = (props: IMenuBar) => {
  const { title, showAlign, showLink } = props;
  const { active, commands, view } = useRemirror({ autoUpdate: true });
  const [addingLink, setAddingLink] = useState(false);
  const [link, setLink] = useState<string>();

  const updateNodeAlignment = useCallback(
    (align: 'left' | 'right' | 'center') => {
      const { selection } = view.state;
      const alignmentClass = `editor-align-${align}`;

      findChildren({
        node: view.state.doc,
        predicate: (n) => findParagraphChildrenWithinSelection(n, selection),
        action: (n) => (n.node.attrs = { class: alignmentClass }),
      });

      commands.forceUpdate();
    },
    [view.state, commands],
  );

  const isAlignmentActive = useCallback(
    (align: 'left' | 'right' | 'center') => {
      const { selection } = view.state;
      const alignmentClass = `editor-align-${align}`;

      let active = true;

      findChildren({
        node: view.state.doc,
        predicate: (n) => findParagraphChildrenWithinSelection(n, selection),
        action: (n) => (active = n.node.attrs.class !== alignmentClass ? false : active),
      });

      return active;
    },
    [view.state],
  );

  return (
    <S.MenuWrapper>
      <S.MenuSection>
        <Row>
          {title && (
            <Col span={24}>
              {' '}
              <h2>{title}</h2>
            </Col>
          )}
          <Col span={24}>
            <div style={{ width: '100%' }}>
              {showAlign && (
                <>
                  <Tooltip title="Left Align">
                    <S.MiddleButton
                      $active={isAlignmentActive('left')}
                      onClick={() => {
                        updateNodeAlignment('left');
                        view.focus();
                      }}
                      size="small"
                    >
                      <FiAlignLeft size={15} />
                    </S.MiddleButton>
                  </Tooltip>
                  <Tooltip title="Center Align">
                    <S.MiddleButton
                      $border
                      $active={isAlignmentActive('center')}
                      onClick={() => {
                        updateNodeAlignment('center');
                        view.focus();
                      }}
                      size="small"
                    >
                      <FiAlignCenter size={15} />
                    </S.MiddleButton>
                  </Tooltip>
                  <Tooltip title="Right Align">
                    <S.MiddleButton
                      $border
                      $active={isAlignmentActive('right')}
                      onClick={() => {
                        updateNodeAlignment('right');
                        view.focus();
                      }}
                      size="small"
                    >
                      <FiAlignRight size={15} />
                    </S.MiddleButton>
                  </Tooltip>
                </>
              )}
              <Tooltip title="Bold">
                <S.MiddleButton
                  $border
                  $active={active.bold()}
                  onClick={() => {
                    commands.toggleBold();
                    view.focus();
                  }}
                  size="small"
                >
                  <FiBold size={15} />
                </S.MiddleButton>
              </Tooltip>
              <Tooltip title="Italic">
                <S.MiddleButton
                  $active={active.italic()}
                  $border
                  onClick={() => {
                    commands.toggleItalic();
                    view.focus();
                  }}
                  size="small"
                >
                  <FiItalic size={15} />
                </S.MiddleButton>
              </Tooltip>
              <Tooltip title="Bullet List">
                <S.MiddleButton
                  $border
                  onClick={() => {
                    commands.toggleBulletList();
                    view.focus();
                  }}
                  size="small"
                >
                  <BsListUl size={15} />
                </S.MiddleButton>
              </Tooltip>
              <Tooltip title="Order List">
                <S.MiddleButton
                  $border
                  onClick={() => {
                    commands.toggleOrderedList();
                    view.focus();
                  }}
                  size="small"
                >
                  <GoListOrdered size={15} />
                </S.MiddleButton>
              </Tooltip>
              {showLink && (
                <Tooltip title="Link">
                  <Popover
                    trigger="click"
                    content={
                      <S.PopconfirmContainer>
                        <h2>Add an url</h2>
                        <Input
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                          autoFocus
                          onPressEnter={() => {
                            commands.updateLink({ href: link || '' });
                            setAddingLink(false);
                          }}
                        />
                        <Spacer />
                        <span role="none" >
                          <Button
                            size="small"
                            danger={active.link()}
                            onClick={() => {
                              setAddingLink(false);
                              if (active.link()) commands.removeLink();
                            }}
                          >
                            {active.link() ? 'Remove Link' : 'Cancel'}
                          </Button>
                          <Spacer axis="horizontal" />
                          <Button
                            type="primary"
                            size="small"
                            disabled={!link}
                            onClick={() => {
                              commands.updateLink({ href: link || '' });
                              setAddingLink(false);
                            }}
                          >
                            {active.link() ? 'Update Link' : 'Add Link'}
                          </Button>
                        </span>
                      </S.PopconfirmContainer>
                    }
                    visible={addingLink}
                    onVisibleChange={setAddingLink}
                    destroyTooltipOnHide
                  >
                    <S.MiddleButton
                      $active={active.link()}
                      $border
                      onClick={() => {
                        setLink(undefined);
                        if (active.link()) {
                          const state = view.state;
                          let marks: any[] = [];
                          const { from, to } = state.selection;
                          if (state.doc.nodesBetween) {
                            state.doc.nodesBetween(from, to, (node: any) => {
                              marks = [...marks, ...node.marks];
                            });
                            const mark = marks.find((markItem) => markItem.type.name === 'link');
                            if (mark) {
                              setLink(mark.attrs.href);
                            }
                          }
                        }
                      }}
                      size="small"
                    >
                      <FiLink size={15} />
                    </S.MiddleButton>
                  </Popover>
                </Tooltip>
              )}
              <Popover
                title="Formula"
                arrowPointAtCenter
                overlayInnerStyle={{ maxWidth: 400 }}
                content={
                  <>
                    To use formulas, use the following notation:
                    <br />
                    <S.FormulaHighlight>{'sqrt'}</S.FormulaHighlight> Square Root
                    <br />
                    <S.FormulaHighlight>{'/'}</S.FormulaHighlight> Fraction
                    <br />
                    <S.FormulaHighlight>{'*'}</S.FormulaHighlight> Product
                    <br />
                    <S.FormulaHighlight>{'sum'}</S.FormulaHighlight> Summation
                    <br />
                    <S.FormulaHighlight>{'pm'}</S.FormulaHighlight> +- Signal
                    <br />
                    <S.FormulaHighlight>{'^'}</S.FormulaHighlight> Exponent/Superscript
                    <br />
                    <S.FormulaHighlight>{'_'}</S.FormulaHighlight> Subscript
                    <br />
                    <S.FormulaHighlight>{'pi'}</S.FormulaHighlight> PI Greek Letter
                    <br />
                    <S.FormulaHighlight>{'-->'}</S.FormulaHighlight> Right Arrow
                    <br />
                    <S.FormulaHighlight>{'<--'}</S.FormulaHighlight> Left Arrow
                    <br />
                    <S.FormulaHighlight>{'<->'}</S.FormulaHighlight> Left and Right Arrow
                    <br />
                    <br />
                    You can add other symbols by typing <S.FormulaHighlight>\symbolName</S.FormulaHighlight>.
                    <br />
                    <br />
                    For example: <S.FormulaHighlight>\ge</S.FormulaHighlight>,{' '}
                    <S.FormulaHighlight>\le</S.FormulaHighlight>, <S.FormulaHighlight>\Omega</S.FormulaHighlight>,{' '}
                    <S.FormulaHighlight>\Sigma</S.FormulaHighlight>, <S.FormulaHighlight>\alpha</S.FormulaHighlight>{' '}
                    <S.FormulaHighlight>\mu</S.FormulaHighlight>, <S.FormulaHighlight>\epsilon</S.FormulaHighlight>,{' '}
                    <S.FormulaHighlight>\sigma</S.FormulaHighlight>, <S.FormulaHighlight>\theta</S.FormulaHighlight>,{' '}
                    <S.FormulaHighlight>\omega</S.FormulaHighlight>, <S.FormulaHighlight>\Delta</S.FormulaHighlight>{' '}
                    <S.FormulaHighlight>\beta</S.FormulaHighlight>, <S.FormulaHighlight>\gamma</S.FormulaHighlight>,{' '}
                    <S.FormulaHighlight>\infty</S.FormulaHighlight>, <S.FormulaHighlight>\wedge</S.FormulaHighlight>,{' '}
                    <S.FormulaHighlight>\dollar</S.FormulaHighlight>, <S.FormulaHighlight>\neq</S.FormulaHighlight>{' '}
                    <S.FormulaHighlight>\integral</S.FormulaHighlight> and more...
                    <br />
                  </>
                }
              >
                <S.MiddleButton
                  onClick={() => {
                    commands.insertFormula({ formula: ' ' });
                  }}
                  size="small"
                >
                  <AiOutlineFunction size={15} />
                </S.MiddleButton>
              </Popover>
            </div>
          </Col>
        </Row>
      </S.MenuSection>
    </S.MenuWrapper>
  );
};

export default MenuBar;
