import { Table } from 'antd';
import styled from 'styled-components';

export const StyledInsightsTable = styled(Table)`
  .ant-table-thead {
    .ant-table-selection {
      display: none;
    }
  }
`;

export const InsightsTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InsightTitleDot = styled.div<{ color: string }>`
  border-radius: 50%;
  background-color: ${({ color }) => color};
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
