import { Row } from 'antd';
import styled from 'styled-components';

export const ActionsRow = styled(Row)`
  color: #767676;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  & > div {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-right: 24px;

    svg {
      margin-right: 5px;
    }
  }

  & > div:last-child {
    margin-right: 0px;
  }
`;

export const CommentContainer = styled.div<{ $selected?: boolean }>`
  ${(props) => props.$selected && 'color: #4367E9;'}
`;

export const LikeContainer = styled.div<{ $liked?: boolean; $ownComment?: boolean }>`
  ${(props) => props.$liked && 'color: #4367E9;'}
  ${(props) => props.$ownComment && 'cursor: not-allowed !important;'}
`;

export const FlagContainer = styled.div<{ $flagged?: boolean; $ownComment?: boolean }>`
  ${(props) => props.$flagged && 'color: #f57c00;'}
  ${(props) => props.$ownComment && 'cursor: not-allowed !important;'}
`;

export const FlagConfirmationContainer = styled.div`
  max-width: 264px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;

  p {
    margin-bottom: 16px;
  }

  & > div {
    display: flex;
    justify-content: space-around;
  }
`;
