import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Badge, Progress, message, Divider, Button, Modal } from 'antd'; // Updated to use Modal and Button
import { useAuth } from '../../../hooks/useAuth';
import * as S from './styles';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { getProgressColor } from '../../../utils/theme';
import { useHistory } from 'react-router-dom'; // Use useHistory instead of useNavigate

interface IClassInfosProps {
  classInfo?: any;
  assignmentInfo?: any;
  submissionCount: number;
  complete?: number;
  totalStudent?: number;
  assignmentName?: string;
  description?: string;
  studentNames: any[];
  onDeleteSuccess: () => void;
}

const ClassInfos: React.FC<IClassInfosProps> = ({ classInfo, studentNames, assignmentInfo, submissionCount, complete, assignmentName, onDeleteSuccess, totalStudent, description }) => {
  const { user } = useAuth();
  const history = useHistory();
  const [displayAssignmentName, setDisplayAssignmentName] = useState<string | undefined>(assignmentName);
  const progress = complete ?? 0;

  const [deleteCanvasAssignment, { loading }] = useMutation(gqlSchema.CanvasSchema.mutations.CANVASCLASS.DeleteCanvasAssignment);

  const formatDate = (date: string) => {
    return moment(date).format('DD MMM YYYY');
  };

  const extractLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = text.match(urlRegex);

    // Update links based on investigation or assessment type
    return links?.map((newLink) => {
      return newLink.includes('/student-investigation/')
        ? newLink.replace('/student-investigation/', '/teacher-dashboard/investigation-summary/')
        : newLink.includes('/student-assessment/')
          ? newLink.replace('/student-assessment/', '/teacher-dashboard/assessment-summary/')
          : newLink;
    });
  };

  useEffect(() => {
    if (assignmentInfo && assignmentName) {
      setDisplayAssignmentName(assignmentName);
    }
  }, [assignmentInfo, assignmentName]);


  const handleDelete = async () => {
    if (classInfo?.id && assignmentInfo?.id) {
      try {
        await deleteCanvasAssignment({
          variables: {
            course_id: classInfo.id,
            assignment_id: assignmentInfo.id,
          },
        });
        message.success('Assignment deleted successfully');
        onDeleteSuccess();
        history.push('/canvas-teacher-dashboard');
      } catch (error: any) {
        message.error(`Failed to delete assignment: ${error.message}`);
      }
    } else {
      message.error('Course ID or Assignment ID is missing');
    }
  };


  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this assignment?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete',
      okType: 'danger',
      cancelText: 'No, cancel',
      onOk: handleDelete,
    });
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const links = description ? extractLinks(description) : null;

  return (
    <S.ClassInfosContainer>
      <Card style={{ width: '100%' }}>
        <Row>
          <Col span={8} xl={8} lg={8} md={8} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: 0, marginTop: 0, marginBottom: isMobile ? '20px' : 'unset' }}>
            <Progress
              strokeLinecap="round"
              strokeWidth={9}
              style={{ marginTop: 30 }}
              strokeColor={getProgressColor(progress || 0)}
              percent={progress}
              format={(percent) => (
                <>
                  <S.Info $bold>{percent}%</S.Info>
                  <S.InfoProgress>Total Submissions</S.InfoProgress>
                </>
              )}
              width={145}
              type="circle"
            />
          </Col>
          <Col span={16} lg={16} md={16} xs={24} xl={16}>
            <Row gutter={[16, 16]}>
              <Col span={8} xl={8} lg={8} md={8} xs={24}>
                <S.Title>Class Name</S.Title>
                <p>{classInfo?.name || 'Unknown Class'}</p>
              </Col>
              <Col span={8} xl={8} lg={8} md={8} xs={24}>
                <S.Title>Assignment Name</S.Title>
                <Badge
                  count={
                    <div style={{ maxWidth: '100%', overflowWrap: 'break-word', padding: '4px 8px', color: 'white' }}>
                      {displayAssignmentName || "Assignment Name"}
                    </div>
                  }
                  style={{ backgroundColor: '#52c41a', borderRadius: '16px' }}
                />
              </Col>
              <Col span={8} xl={8} lg={8} md={8} xs={24}>
                <S.Title>Assignment Owner</S.Title>
                <p>{user ? `${user.name} - ${user.email}` : '-'}</p>
              </Col>
            </Row>
            <Divider style={{
              height: '1px',
              width: '100%',
              background: '#c6c6c6',
              display: 'block',
              borderRadius: '5px',
              marginTop: '16px',
              marginBottom: '24px',
            }} />
            <Row gutter={[16, 16]}>
              <Col span={8} xl={8} lg={8} md={8} xs={24}>
                <S.Title>No of Students</S.Title>
                <p>{totalStudent ? `${totalStudent} Students` : '-'}</p>
              </Col>
              <Col span={8} xl={8} lg={8} md={8} xs={24}>
                <S.Title>Start Date</S.Title>
                <p>{formatDate(classInfo?.created_at || '')}</p>
              </Col>
              <Col span={8} xl={8} lg={8} md={8} xs={24}>
                <S.Title>Assignment Links</S.Title>
                {links && links.length > 0 && links.some(link =>
                  link.includes('/teacher-dashboard/investigation-summary/') ||
                  link.includes('/teacher-dashboard/assessment-summary/')
                ) ? (
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {links.map((link, index) => (
                      <li key={index}>
                        <a href={link}>
                          Go To Assignment Link
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No links available</p>
                )}
              </Col>
            </Row>
            <Row justify="end">
              <Button
                type="primary"
                danger
                onClick={showDeleteConfirm} // Show confirmation before delete
                loading={loading} // Show loading state during mutation
              >
                Delete Assignment
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </S.ClassInfosContainer>
  );
};

export default ClassInfos;
