import React, { useMemo } from 'react';
import { GQL_ComparisonByStandardsResponse } from '../../types/gradeBooking';
import * as S from './styles';
import { ResponsiveBar } from '@nivo/bar';
import { studentPerformanceLevelsByStandards } from '../../types/studentPerformanceLevels';
import StudentsPerformanceLegends from '../TeacherInsights/StudentsPerformanceLegends';

interface IAssessmentComparisonByStandardChart {
  data: GQL_ComparisonByStandardsResponse[] | undefined;
  loading: boolean;
}

const AssessmentComparisonByStandardChart: React.FC<IAssessmentComparisonByStandardChart> = (props) => {
  const { data: propsData } = props;

  const data = useMemo(() => {
    if (!propsData) return [];

    // populate data for the graph
    let resultData: {
      [key: string]: {
        code: string;
        typeTitle: string;
        notSubmittedOrGraded: number;
        below: number;
        approaching: number;
        meets: number;
      };
    } = {};

    // Use main assessments to create obj index and base object
    propsData.forEach((standardComparisonData: GQL_ComparisonByStandardsResponse) => {
      if (!resultData[standardComparisonData.standardCode]) {
        const obj = {
          notSubmittedOrGraded: 0,
          below: 0,
          approaching: 0,
          meets: 0,
          code: standardComparisonData.standardCode,
          typeTitle: standardComparisonData.standardCode,
        };

        if (standardComparisonData.notAvailable) {
          obj.notSubmittedOrGraded = 100;
        } else {
          obj.meets = standardComparisonData.meets;
          obj.approaching = standardComparisonData.approaching;
          obj.below = standardComparisonData.needsImprovement;
        }

        resultData[standardComparisonData.standardCode] = obj;
      }
    });

    return Object.values(resultData);
  }, [propsData]);

  return (
    <S.StudentsPerformanceBarContainer>
      <p>Percentage of Students at Each Performance Level</p>
      <S.GraphsContainer>
        <S.GraphContainer>
          <ResponsiveBar
            data={data}
            layout="vertical"
            keys={Object.keys(studentPerformanceLevelsByStandards)}
            colors={Object.values(studentPerformanceLevelsByStandards).map((level) => level.color)}
            tooltipLabel={(data) =>
              studentPerformanceLevelsByStandards[data.id as keyof typeof studentPerformanceLevelsByStandards].name
            }
            indexBy="code"
            enableGridY={true}
            enableGridX={false}
            labelTextColor={'#FFFFFF'}
            axisLeft={{
              tickValues: 2,
              format: (value) => `${value}%`,
            }}
            padding={0.3}
            margin={{ left: 50, right: 50, top: 24, bottom: 32 }}
            label={(data) => (data.value ? `${data.value}%` : '')}
            theme={{
              fontSize: 16,
              labels: {
                text: {
                  fontWeight: 600,
                },
              },
            }}
          />
        </S.GraphContainer>
      </S.GraphsContainer>

      <StudentsPerformanceLegends standardsMode />
    </S.StudentsPerformanceBarContainer>
  );
};

export default AssessmentComparisonByStandardChart;
