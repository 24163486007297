import styled from 'styled-components';

export const ProcessDragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TaskContainerTitle = styled.div`
  font-size: 0.9rem;
`;

export const TasksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  font-size: 0.85rem;
`;
export const TasksCol = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 50%;
  text-align: center;
  cursor: pointer;
`;

export const TaskTarget = styled.div<{ isImage?: boolean }>`
  width: ${(props) => (props.isImage ? '150px' : '100px')};
  border: 1px solid black;
  border-radius: 5px;
  height: ${(props) => (props.isImage ? '140px' : '30px')};
  margin-bottom: 20px;
  position: relative;
  margin: 5px auto 20px auto;

  &:after {
    border-right: solid 10px transparent;
    border-left: solid 10px transparent;
    border-top: solid 10px grey;
    transform: translateX(-36%);
    content: '';
    top: ${(props) => (props.isImage ? '140px' : '33px')};
    left: 50%;
    height: 0;
    width: 0;
    position: absolute;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;
