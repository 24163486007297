import React, { ReactElement, useCallback } from 'react';
import { Row, Col, Progress } from 'antd';
import * as S from './styles';
import { BsClock } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { getProgressColor, themeConfig } from '../../../../utils/theme';

interface Props {
  description: string;
  progress: number;
  stages: string;
  stagesCompleted?: number;
  timeLeft: string;
  investigationName: string;
  investigationId: string;
  timeWarning: boolean;
  icon: ReactElement;
  type: 'Investigation' | 'Design Challenge';
}

const Item: React.FC<Props> = (props) => {
  const {
    progress,
    timeLeft,
    stages,
    stagesCompleted,
    investigationName,
    investigationId,
    timeWarning,
    description,
    icon,
    type,
  } = props;
  const history = useHistory();

  const goToDashboard = useCallback(() => {
    history.push('/student-investigation/' + investigationId);
  }, [investigationId, history]);

  return (
    <S.Card data-cy="components-active-investigations-row-item">
      <Row align="middle">
        <Col span={5} style={{ textAlign: 'left' }}>
          {icon}
        </Col>
        <Col span={14}>
          <S.Info
            $bold
            data-cy="shared-studentdashboardrows-activeinvestigationsrow-item-stages"
            data-stages-completed={stagesCompleted}
          >
            {stages} Stages Completed
          </S.Info>
        </Col>
        <Col span={5} style={{ textAlign: 'left' }}>
          <Progress
            strokeLinecap="round"
            strokeWidth={10}
            strokeColor={getProgressColor(progress * 100)}
            format={(percent) => {
              return (
                <S.Info
                  data-cy="shared-studentdashboardrows-activeinvestigationsrow-item-progress"
                  data-percentage={percent}
                  $bold
                >
                  {percent}%
                </S.Info>
              );
            }}
            type="circle"
            width={65}
            percent={Math.round(progress * 100)}
          />
        </Col>
        <Col span={24}>
          <S.Title $align="left">{investigationName}</S.Title>
        </Col>
        <Col span={24}>
          <S.Info $align="left" dangerouslySetInnerHTML={{ __html: description || '' }} />
        </Col>
      </Row>
      <Row style={{ marginTop: '3em' }}>
        <Col span={12}>
          <S.DashboardButton
            data-cy="shared-studentdashboardrows-activeinvestigation-resume-button"
            text={'Resume ' + type}
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
          />
        </Col>
        <Col span={12} className="end">
          <S.TimeLeft $fontSize="0.8em" $warning={timeWarning}>
            <BsClock />
            {timeLeft}
          </S.TimeLeft>
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
