import React from 'react';
import { useAuth } from './useAuth';

type Props = {
  children: React.ReactNode;
};

/**
 * Use this component as a wrapper to every beta feature possible.
 * The component inside the wrapper will show only when a user has beta enabled.
 * We want to wrap with this component because in the near future we might be inclined to
 * do logs and other manipulations with beta users.
 */
const BetaFeatureWrapper = ({ children }: Props) => {
  const { isBetaUser } = useBetaFeature();

  if (!isBetaUser) return null;

  return <>{children}</>;
};

const useBetaFeature = () => {
  const { user } = useAuth();
  return { isBetaUser: user ? user.betaEnrolled : false, BetaFeatureWrapper };
};

export default useBetaFeature;
