import React, { ChangeEvent, KeyboardEvent } from 'react';
import * as S from './styles';

interface IInput {
  placeholder?: string;
  icon?: React.ReactNode;
  password?: boolean;
  disabled?: boolean;
  bordered?: boolean;
  allowClear?: boolean;
  defaultValue?: string;
  name?: string;
  backgroundColor?: string;
  size?: 'large' | 'middle' | 'small';
  alignRight?: boolean;
  title?: string;
  type?: string;
  value?: string | number | undefined;
  max?: number;
  min?: number;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const Input = React.forwardRef((props: IInput, ref: any) => {
  const { password, backgroundColor = '#f8f8f8', ...rest } = props;

  return password ? (
    <S.InputPassword
      size="large"
      placeholder={props.placeholder}
      prefix={props.icon}
      $backgroundColor={backgroundColor}
      {...rest}
    />
  ) : (
    <S.StyledInput
      size="large"
      placeholder={props.placeholder}
      prefix={props.icon}
      $backgroundColor={backgroundColor}
      $alignRight={props.alignRight}
      $disabled={props.disabled}
      ref={ref}
      {...rest}
    />
  );
});

export default React.memo(Input);
