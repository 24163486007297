import { useQuery } from '@apollo/client';
import { Col, message, Row, Table, Tooltip } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { gqlSchema } from '../../../gql/schema';
import { formatDateTime } from '../../../utils/date';
import Select from '../../Select';
import SelectOption from '../../Select/Option';
import * as S from './styles';
import { GQL_OrganizationResponse } from '../../../types/organization';
import RangePicker from '../../RangePicker';
import { downloadCsv } from '../../../utils/files';
import Papa from 'papaparse';
import Button from '../../Button';

const dateRangeBase = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};

const SSOUsers = () => {
  const [durationSSOUsers, setDurationSSOUsers] = useState<any>(dateRangeBase);
  const [currentOrganization, setCurrentOrganization] = useState<string>('');
  const { data: usersClassLinkData, loading: loadingClassLinkUsers } = useQuery(
    gqlSchema.ChartSchema.queries.USER_REPORTS.getClasslinkExpiredUser,
    {
      variables: {
        from: durationSSOUsers.startDate,
        to: durationSSOUsers.endDate,
        orgId: currentOrganization,
      },
    },
  );
  const { data: usersCleverData, loading: loadingCleverUsers } = useQuery(
    gqlSchema.ChartSchema.queries.USER_REPORTS.getCleverExpiredUser,
    {
      variables: {
        from: durationSSOUsers.startDate,
        to: durationSSOUsers.endDate,
        orgId: currentOrganization,
      },
    },
  );

  const { data: organizationsData, loading: loadingOrganizations } = useQuery<{
    getAllOrganizations: GQL_OrganizationResponse[];
  }>(gqlSchema.OrganizationSchema.queries.LIST.getAllOrganizations, {
    onError: (err) => {
      message.error('There was an error loading the organizations: ' + err.message || 'Unexpected Error');
    },
  });

  const downloadClassLinkData = useCallback(() => {
    const headers = [['Organization Name', 'Provider', 'Subscription End Date', 'Admin Name', 'Admin Email']];
    const csvData = usersClassLinkData?.getClasslinkExpiredUser.reduce((acc: any[], user: any) => {
      if (user.org?.length) {
        user.org.forEach((org: any) => {
          acc.push([
            user.orgName,
            user.source,
            formatDateTime(Number(user.subscriptionEndDate)),
            org.orgAdminName,
            org.orgAdminEmail,
          ]);
        });
      } else {
        acc.push([user.orgName, user.source, formatDateTime(Number(user.subscriptionEndDate))]);
      }

      return acc;
    }, []);

    downloadCsv(Papa.unparse([...headers, ...csvData]), `Classlink Users.csv`);
  }, [usersClassLinkData]);

  const downloadCleverData = useCallback(() => {
    const headers = [['Organization Name', 'Provider', 'Subscription End Date', 'Admin Name', 'Admin Email']];
    const csvData = usersCleverData?.getCleverExpiredUser.reduce((acc: any[], user: any) => {
      if (user.org?.length) {
        user.org.forEach((org: any) => {
          acc.push([
            user.orgName,
            user.source,
            formatDateTime(Number(user.subscriptionEndDate)),
            org.orgAdminName,
            org.orgAdminEmail,
          ]);
        });
      } else {
        acc.push([user.orgName, user.source, formatDateTime(Number(user.subscriptionEndDate))]);
      }

      return acc;
    }, []);
    downloadCsv(Papa.unparse([...headers, ...csvData]), `Clever Users.csv`);
  }, [usersCleverData]);

  const expandedRowRender = useCallback((record) => {
    const columns = [
      { title: 'Admin Name', dataIndex: 'orgAdminName', key: 'orgAdminName' },
      { title: 'Admin Email', dataIndex: 'orgAdminEmail', key: 'orgAdminEmail' },
    ];

    const data = record.org?.length ? record.org : [];
    return <Table columns={columns} dataSource={data} pagination={false} />;
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'Organization Name',
        dataIndex: 'orgName',
        key: 'orgName',
      },
      {
        title: 'Provider',
        dataIndex: 'source',
        key: 'source',
        render: (text: any) => (text ? text.charAt(0).toUpperCase() + text.slice(1)?.toLowerCase() : ''),
      },
      {
        title: 'Subscription End Date',
        dataIndex: 'subscriptionEndDate',
        key: 'subscriptionEndDate',
        render: (text: any) => formatDateTime(Number(text)),
      },
      {
        title: 'Admin Name',
        dataIndex: 'orgAdminName',
        key: 'orgAdminName',
      },
      {
        title: 'Admin Email',
        dataIndex: 'orgAdminEmail',
        key: 'orgAdminEmail',
      },
    ],
    [],
  );

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>SSO New Users</S.Title>
          <Tooltip title="Users who have logged into the platform using SSO methods (Clever or ClassLink) but haven't got a new subscription yet.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Row align="bottom">
            <Col xxl={5} xl={7} lg={9} md={24}>
              Date Range:
              <RangePicker
                data-cy="shared-charts-ssousers-range-picker"
                value={durationSSOUsers}
                onChange={(duration: { startDate: string; endDate: string }) => {
                  setDurationSSOUsers(duration);
                }}
                allowClear={false}
              />
            </Col>
            <Col
              xxl={{ span: 7, offset: 12 }}
              xl={{ span: 12, offset: 5 }}
              lg={{ span: 15, offset: 0 }}
              md={24}
              sm={24}
            >
              <Select
                data-cy="shared-charts-ssousers-select-organization"
                placeholder="Select Organization"
                showSearch
                loading={loadingOrganizations}
                value={currentOrganization}
                onChange={(v) => setCurrentOrganization(v as string)}
              >
                <SelectOption value={''}>All Organizations</SelectOption>
                {organizationsData?.getAllOrganizations?.map((organization) => (
                  <SelectOption
                    data-cy={`shared-charts-ssousers-select-organization-option-${organization.id}`}
                    value={organization.id}
                    key={organization.id}
                  >
                    {organization.name}
                  </SelectOption>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <h2>Classlink Users</h2>
            <Button
              text="Download User List"
              onClick={downloadClassLinkData}
              disabled={!usersClassLinkData?.getClasslinkExpiredUser?.length}
            />
          </Row>
          <Table
            columns={columns}
            dataSource={usersClassLinkData?.getClasslinkExpiredUser || []}
            loading={loadingClassLinkUsers}
            expandable={{ expandedRowRender }}
          />
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <h2>Clever Users</h2>
            <Button
              text="Download User List"
              onClick={downloadCleverData}
              disabled={!usersCleverData?.getCleverExpiredUser?.length}
            />
          </Row>
          <Table
            columns={columns}
            dataSource={usersCleverData?.getCleverExpiredUser || []}
            loading={loadingCleverUsers}
            expandable={{ expandedRowRender }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SSOUsers;
