import React from 'react';
import Editor from '../../../shared/Editor';
import { ITextContentValue } from '../../../types/investigation';
import { applySpanToContentValueText } from '../../../utils/investigation';
import * as S from './styles';

interface IInvestigationText {
  value: ITextContentValue;
  isAlreadyHtml?: boolean;
}

const InvestigationText = (props: IInvestigationText) => {
  const { value, isAlreadyHtml } = props;
  const text = isAlreadyHtml ? value.text : applySpanToContentValueText(value);

  const RenderContent = () => {
    if (value.type === 'header1') return <h1 dangerouslySetInnerHTML={{ __html: text }} />;
    if (value.type === 'header2') return <h2 dangerouslySetInnerHTML={{ __html: text }} />;
    if (value.type === 'header3') return <h3 dangerouslySetInnerHTML={{ __html: text }} />;
    else return <Editor editable={false} listenToValue value={text} key={text} />;
  };
  return (
    <S.ContentWrapper>
      <RenderContent />
    </S.ContentWrapper>
  );
};

export default InvestigationText;
