import { Col, Row, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import Button from '../../../../shared/Button';
import { centerAlign, Breakpoint } from '../../../../utils/antd';
import * as S from './styles';
import { GQL_SortingInput, SortOptions } from '../../../../types/sorting';
import {
  GQL_InvestigationAssignmentRateChartPaginatedResponse,
  GQL_InvestigationAssignmentRateChartResponse,
} from '../../../../types/charts';
import { Duration } from '../../../../types/investigation';
import TeacherInvestigationRateDetail from '../Details';

const TableBreakPoint: Breakpoint[] = ['lg'];

interface Props {
  title: string;
  workshopOnly?: boolean;
  periodInvestigationAssignedRate: Duration;
  setSorting: Dispatch<SetStateAction<SortOptions>>;
  loading: boolean;
  pagination?: TablePaginationConfig;
  setPagination?: Dispatch<SetStateAction<TablePaginationConfig | undefined>>;
  data?: GQL_InvestigationAssignmentRateChartPaginatedResponse;
}

const RateTable = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const [currentTeacher, setCurrentTeacher] = useState<string | undefined>();

  const {
    setSorting,
    loading,
    data,
    workshopOnly,
    periodInvestigationAssignedRate,
    title,
    setPagination,
    pagination,
  } = props;
  const manageSortings = useCallback(
    (sort: GQL_SortingInput) => {
      setSorting(sort.order || SortOptions.DESC);
    },
    [setSorting],
  );

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      const order = sorter.order ? (sorter.order === 'ascend' ? SortOptions.ASC : SortOptions.DESC) : undefined;
      manageSortings({ field: sorter.columnKey, order });
      if (pagination && setPagination) setPagination(pagination);
    },
    [manageSortings, setPagination],
  );

  const columns = useMemo(() => {
    const col: ColumnsType<GQL_InvestigationAssignmentRateChartResponse> = [
      {
        title: 'First Name',
        align: centerAlign,
        dataIndex: 'teacherFirstName',
        width: '25%',
        responsive: TableBreakPoint,
      },
      {
        title: 'Last Name',
        align: centerAlign,
        dataIndex: 'teacherLastName',
        width: '25%',
        responsive: TableBreakPoint,
      },
      {
        title: 'Email',
        align: centerAlign,
        dataIndex: 'teacherEmail',
        width: '25%',
        responsive: TableBreakPoint,
      },
    ];
    if (!workshopOnly) {
      col.push({
        title: 'Organization',
        align: centerAlign,
        dataIndex: 'organizationName',
        width: '25%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_InvestigationAssignmentRateChartResponse) => {
          return record.organizationName || '-- FREELANCER --';
        },
      });
    }

    col.push({
      title: 'Assignments',
      dataIndex: 'rate',
      align: centerAlign,
      width: '10%',
      responsive: TableBreakPoint,
      sorter: true,
    });

    col.push({
      title: 'Actions',
      align: centerAlign,
      render: (text: string, record: GQL_InvestigationAssignmentRateChartResponse) => {
        return (
          <Button
            text="Details"
            block
            onClick={() => {
              setCurrentTeacher(record.teacherId);
              setVisible(true);
            }}
          />
        );
      },
      width: '15%',
    });

    return col;
  }, [workshopOnly]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_InvestigationAssignmentRateChartResponse) => record.teacherId}
              pagination={{ hideOnSinglePage: true, ...pagination }}
              bordered
              dataSource={data?.rates || []}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <TeacherInvestigationRateDetail
        visible={visible}
        workshopOnly={workshopOnly}
        teacherId={currentTeacher}
        setVisible={setVisible}
        endDate={periodInvestigationAssignedRate.endDate}
        startDate={periodInvestigationAssignedRate.startDate}
        title={title}
      />
    </>
  );
};

export default RateTable;
