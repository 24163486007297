import { Col, Row, message } from 'antd';
import React from 'react';
import { FiMinusCircle, FiPlus } from 'react-icons/fi';

import { IOnChageEditor } from '../../../../../types';
import Editor from '../../../../../shared/Editor';
import Input from '../../../../../shared/Input';
import Spacer from '../../../../../shared/Spacer';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  IProcessDragDropAnswer,
} from '../../../../../types/investigation';
import * as S from './styles';
import UploadInput from '../../../../../shared/UploadInput';
import { RcFile } from 'antd/lib/upload';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../../../gql/schema';

interface ICategoryDragBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
  isImageVersion?: boolean;
}

const ProcessDragDropAnswerBlockEditor = (props: ICategoryDragBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
    isImageVersion = false,
  } = props;

  const processDragDropValue = editingBlock.values[0] as IProcessDragDropAnswer;

  const [uploadFile, { loading }] = useMutation<{ uploadActivityFile: { url: string } }>(
    gqlSchema.InvestigationSchema.mutations.DRAFT.uploadActivityFile,
    {
      onError: (err) => {
        message.error('There was an error trying to upload your file, please try again later');
      },
      onCompleted: (data) => {
        return data?.uploadActivityFile;
      },
    },
  );

  const handleEditTextContent = (data: IOnChageEditor, targetKey: string) => {
    const selectedActivity = activities.find((activity) => activity.id === selectedActivityId);
    if (!selectedActivity) return;

    const newContent = (editingRemote ? selectedActivity.content : selectedActivity.contentInPerson).map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          title: content.title,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;

            return {
              ...block,
              values: block.values.map((value) => {
                return {
                  ...value,
                  [targetKey]: data.value,
                };
              }),
            };
          }),
        };
      },
    );

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleAddRemoveValueToProcessDrag = (payload: { index: number; removeIndex?: number }) => {
    if (loading) {
      return false;
    }

    const selectedActivity = activities.find((activity) => activity.id === selectedActivityId);
    if (!selectedActivity) return;

    const newContent = (editingRemote ? selectedActivity.content : selectedActivity.contentInPerson).map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          title: content.title,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;

            return {
              ...block,
              values: [
                {
                  ...block.values[0],
                  targets: processDragDropValue?.targets?.map((t, i) => {
                    if (i === payload.index) {
                      const values = t.values || [];
                      return {
                        ...t,
                        values:
                          typeof payload.removeIndex === 'number'
                            ? [...values?.slice(0, payload.removeIndex), ...values?.slice(payload.removeIndex + 1)]
                            : [...values, ''],
                      };
                    } else {
                      return t;
                    }
                  }),
                },
              ],
            };
          }),
        };
      },
    );

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditValueToProcessBoard = (payload: { targetIndex: number; valueIndex: number; title: string }) => {
    const selectedActivity = activities.find((activity) => activity.id === selectedActivityId);
    if (!selectedActivity) return;

    const newContent = (editingRemote ? selectedActivity.content : selectedActivity.contentInPerson).map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          title: content.title,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;

            return {
              ...block,
              values: [
                {
                  ...block.values[0],
                  targets: processDragDropValue?.targets?.map((t, i) => {
                    if (i === payload.targetIndex) {
                      const values = t.values || [];
                      return {
                        ...t,
                        values: values.map((v, i) => {
                          if (i === payload.valueIndex) {
                            return payload.title;
                          } else {
                            return v;
                          }
                        }),
                      };
                    } else {
                      return t;
                    }
                  }),
                },
              ],
            };
          }),
        };
      },
    );

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const beforeUploadCanvasImages = async (file: RcFile, indexPosition: number, targetIndex: number) => {
    if (!file.type.includes('image')) return false;
    try {
      const result = (await uploadFile({ variables: { indexPosition, activityFile: file } })) as any;
      const { url, index } = result.data?.UploadActivityFile;
      handleEditValueToProcessBoard({ title: url, valueIndex: index, targetIndex });
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <h2>Question</h2>
      <Editor
        fontSize={11}
        value={processDragDropValue.directions}
        placeholder="Start writing the directions content..."
        onChange={(data) => handleEditTextContent(data, 'directions')}
        showAlign
        showLink
      />

      <Spacer size={24} />
      {Array(processDragDropValue.targets?.length ?? 0)
        .fill(1)
        .map((_, index, arr) => (
          <React.Fragment key={index}>
            <Spacer />
            <Row
              justify="space-between"
              align="middle"
              onClick={() => handleAddRemoveValueToProcessDrag({ index })}
              style={{ cursor: 'pointer' }}
            >
              <h2>Steps </h2>
              <FiPlus size={18} color="#3EBC89" />
            </Row>
            <Row gutter={[8, 8]} align="middle" justify="center">
              {processDragDropValue.targets &&
                processDragDropValue.targets[index]?.values?.map((value: any, i) => (
                  <React.Fragment
                    key={`${i}${processDragDropValue.targets && processDragDropValue.targets[index]?.values?.length}`}
                  >
                    <Col span={22}>
                      {!isImageVersion && (
                        <Input
                          value={value}
                          onChange={(e) =>
                            handleEditValueToProcessBoard({ title: e.target.value, valueIndex: i, targetIndex: index })
                          }
                          backgroundColor="white"
                          placeholder="Step"
                        />
                      )}
                      {isImageVersion && (
                        <S.CustomImageHolder>
                          {value && (
                            <img
                              key={`image-drag-${index}`}
                              src={value}
                              alt={`Dragable Option ${index + 1}`}
                              style={{ marginBottom: '10px', height: '100%', width: '150px', objectFit: 'contain' }}
                            />
                          )}
                          <UploadInput
                            title={value || 'Upload Images from Computer'}
                            accept="image/*"
                            beforeUpload={(file) => {
                              beforeUploadCanvasImages(file, i, index);
                            }}
                            defaultFileList={[]}
                            fileList={[]}
                            disabled={loading}
                          />
                          <S.HorizontalDivider />
                        </S.CustomImageHolder>
                      )}
                    </Col>
                    <Col span={2}>
                      <FiMinusCircle
                        onClick={() => handleAddRemoveValueToProcessDrag({ index, removeIndex: i })}
                        style={{ cursor: 'pointer', verticalAlign: 'sub' }}
                        size={18}
                      />
                    </Col>
                  </React.Fragment>
                ))}
            </Row>
            {index !== arr.length - 1 && <S.HorizontalDivider />}
          </React.Fragment>
        ))}
    </>
  );
};

export default ProcessDragDropAnswerBlockEditor;
