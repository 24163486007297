import styled from 'styled-components';
import CButton from '../../../../shared/Button';

export const ClassCard = styled.div`
  width: 100%;
  height: 100%;
  padding: 5%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  


  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

`;

export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 125.1%;

  color: #767676;
  text-align: left;
  margin-top: 8px;
`;

export const MainIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: ${(props) => props.theme.secondaryColor.background};
  img {
    width: 60%; // Adjust size of the image
    height: 60%; // Adjust size of the image
    object-fit: cover;
  }
`;

export const Icon = styled.p`
  font-size: 3.5em;
  margin-bottom: 0;
  text-align: center;
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 5px 0;
@media (max-width: 768px) {
  margin: 10px 0;
  margin-left: -10px;
 
}

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }

  span {
    margin-left: 8px;
    vertical-align: text-top;
@media (max-width: 768px) {
    margin-left:0px;
 
}
  }
`;

export const Title = styled.h3<{
  $align?: string;
}>`
  text-align: ${(props) => (props.$align ? props.$align : 'left')};
  font-weight: 500;
  margin-bottom: 0;
  color: black; /* Set text color to blue */
`;

export const Text = styled.p`
  margin: 5px 0;
`;

export const DashboardButton = styled(CButton)`
  font-weight: 500;
  width: 100%;
  margin-top: auto;
  background-color: white; /* Set background color to white */
  color: blue; /* Set text color to blue */
`;
