import styled from 'styled-components';
import { Modal } from 'antd';
import { Button } from 'antd';

export const Title = styled.h1`
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
`;
export const CModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 5px;
    min-height: 55vh;
    overflow-y: auto;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;

export const TitleInput = styled.p`
  font-weight: 600;
  padding: 0;
  font-size: 0.95em;
  margin: 0 0 8px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Status = styled.span<{
  $emailSent: boolean;
}>`
  font-weight: 500;
  color: ${(props) => (props.$emailSent ? '#cda337' : '#767676')};
  text-align: center;
  border: 1px solid #767676 !important;
  border-radius: 3px;
  padding: 10px 20px;
  position: relative;
  top: 8px;
  svg {
    margin: 0 7px;
  }
`;

export const MenuIcon = styled(Button)`
  width: unset;
  height: unset;
  padding: 4px 4px;
  line-height: 0;
  border-radius: 4px;
  position: relative;
  top: 10%;
  &:hover,
  &:focus {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px 1px;
    color: black;
    border-color: #d9d9d9;
    background: ${(props) => `${props.theme.primaryColor.background || 'blue'}`};
  }
`;
