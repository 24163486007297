import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi';
import { Checkbox } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Input from '../../../shared/Input';

export const SubTitle = styled.p`
  font-weight: 600;
  font-size: 1.15em;
  margin: 0;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const TitleInput = styled.p`
  font-weight: 600;
  font-size: 0.95em;
  margin: 0 0 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const BackButton = styled(FiArrowLeft)`
  position: absolute;
  left: 52px;
  top: 52px;
  cursor: pointer;
`;

export const CCheckbox = styled(Checkbox)<{
  $top: string;
  $float: string;
}>`
  margin-top: ${(props) => props.$top};
  float: ${(props) => props.$float};
`;

export const CModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 5px;
    max-height: 75vh;
    overflow-y: auto;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  margin-top: 24px;
`;

export const SInput = styled(Input)<{
  $bold?: boolean;
}>`
  font-size: 1.1em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  font-weight: ${(props) => (props.$bold ? '500' : 'unset')};
  .ant-input-suffix {
    display: none;
  }
`;
