import React, { useState } from 'react';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import Editor from '../../../../../shared/Editor';
import Spacer from '../../../../../shared/Spacer';
import { IOnChageEditor } from '../../../../../types';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  ITextContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';

interface ITextBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const TextBlockEditor = (props: ITextBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const selectedActivity = activities.find((a) => a.id === selectedActivityId);

  const [paragraphKeys, setParagraphKeys] = useState<(number | undefined)[]>(
    selectedActivity?.content[selectedContentIndex]?.blocks[selectedBlockIndex]?.values?.map(() => Math.random()) || [],
  );

  const handleAddParagraph = () => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex) return content;

          const newBlocks = [...(content?.blocks || [])];
          newBlocks[selectedBlockIndex].values = [...(newBlocks[selectedBlockIndex]?.values || [])];
          const newParagraphKeys = [...paragraphKeys];
          newParagraphKeys[newBlocks[selectedBlockIndex].values.length] = Date.now();
          setParagraphKeys(newParagraphKeys);
          newBlocks[selectedBlockIndex].values.push({ text: '', type: 'paragraph' } as ITextContentValue);

          return {
            title: content.title,
            blocks: newBlocks,
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleRemoveParagraph = (paragraphIndex: number) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex) return content;
          const newBlocks = [...(content?.blocks || [])];
          newBlocks[selectedBlockIndex].values = newBlocks[selectedBlockIndex]?.values || [];
          newBlocks[selectedBlockIndex].values.splice(paragraphIndex, 1);

          const newParagraphKeys = [...paragraphKeys];
          newParagraphKeys.splice(paragraphIndex, 1);
          setParagraphKeys(newParagraphKeys);

          return {
            title: content.title,
            blocks: newBlocks,
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditParagraph = (data: IOnChageEditor, paragraphIndex: number) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
          selectedBlockValues[paragraphIndex] = { ...selectedBlockValues[paragraphIndex], text: data.value };

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  return (
    <>
      <S.AddParagraphContainer onClick={handleAddParagraph}>
        <h2>Paragraphs</h2>
        <FiPlus />
      </S.AddParagraphContainer>
      {editingBlock?.values?.map((value, paragraphIndex) => (
        <React.Fragment key={paragraphKeys[paragraphIndex]}>
          <Spacer />
          <S.DeleteParagraphContainer onClick={() => handleRemoveParagraph(paragraphIndex)}>
            <h2>Text Content {paragraphIndex + 1}</h2>
            <FiTrash2 />
          </S.DeleteParagraphContainer>
          <Editor
            fontSize={11}
            key={`bI:${selectedBlockIndex}pI:${paragraphIndex}`}
            value={(value as ITextContentValue).text}
            placeholder="Start writing the paragraph content..."
            onChange={(data) => handleEditParagraph(data, paragraphIndex)}
            showAlign
            showLink
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default TextBlockEditor;
