import styled from 'styled-components';
export const Title = styled.h3`
  @media only screen and (max-width: 1200px) {
    font-size: 1.2vw;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 1.7vw;
  }
  @media only screen and (max-width: 900px) {
    font-size: 2.5vw;
  }
`;

export const Number = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-size: 30px;
  line-height: 1;
  margin: 44px 0 !important;
  white-space: normal;
  text-align: center;
`;

export const Card = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 32px 40px;
  height: 30vh;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 129.1%;
    padding: 0 24px;
    /* or 26px */

    text-align: center;

    color: #767676;
  }
`;
