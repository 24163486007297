import React, { useState } from 'react';
import StudentProfile from './Profile';
import * as S from './styles';
import { Col, Row, Tabs } from 'antd';
import MailPreferences from '../../shared/MailPreferences';

const { TabPane } = Tabs;
const StudentProfilePage = () => {
  const [activeKey, setActiveKey] = useState('profile');

  return (
    <>
      <S.Container>
        <Row gutter={[24, 24]}>
          <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Tabs
              defaultActiveKey={activeKey}
              activeKey={activeKey}
              onChange={(activeKey: string) => setActiveKey(activeKey)}
            >
              <TabPane
                tab={<S.TitleTab data-cy="components-studentprofilepage-profile">Profile</S.TitleTab>}
                key="profile"
              >
                <StudentProfile />
              </TabPane>
              <TabPane
                tab={<S.TitleTab data-cy="components-studentprofilepage-mailpreferences">Mail Preferences</S.TitleTab>}
                key="mail_preferences"
              >
                <MailPreferences />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </S.Container>
    </>
  );
};

export default StudentProfilePage;
