import React, { useCallback, useMemo, useRef, useState } from 'react';
import * as S from './styles';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { Col, message, Popconfirm, Row, Table } from 'antd';

import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { centerAlign, Filter } from '../../utils/antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import TableSearchBox from '../TableSearchBox';
import { roles } from '../../utils/roles';
import { GQL_UserDetailsResponse } from '../../types/profile';
import Button from '../Button';
import { useHistory } from 'react-router-dom';
import {
  GQL_ConvertToPaidUserResponse,
  GQL_ConvertToPaidUserResponseChilds,
  GQL_SubscriptionResponse,
} from '../../types/subscription';
import { useAuth } from '../../hooks/useAuth';
import { themeConfig } from '../../utils/theme';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import BulkGenericUser from '../AddUser/BulkGenericUser';
import useTagColumn from '../../hooks/useTagColumn';

interface Props {
  subscription?: GQL_SubscriptionResponse;
  teachers?: GQL_UserDetailsResponse[];
  organizationId?: string;
}

const TableBreakPoint: Breakpoint[] = ['lg'];

const LinkedSubscriptions: React.FC<Props> = (props) => {
  const { subscription, teachers, organizationId } = props;
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const [addUserVisible, setAddUserVisible] = useState(false);
  const { columnField } = useTagColumn();
  const user = useAuth();
  const refName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);

  const history = useHistory();

  const [removeTeacher, { loading: loadingRemoveTeacher }] = useMutation(
    gqlSchema.OrganizationSchema.mutations.EDIT.removeTeacherFromOrganization,
    {
      refetchQueries: ['GetAllUsersByFilter'],
    },
  );

  const onViewMore = useCallback(
    (userRow: GQL_UserDetailsResponse) => {
      history.push(
        user.isAdiSuperAdmin || user.isAdiAdmin ? `/adi-users/${userRow.id}` : `/organization/user/${userRow.id}`,
        {
          name: userRow.name,
        },
      );
    },
    [history, user],
  );

  const handleSuccessResponse = (response: GQL_ConvertToPaidUserResponse, isOrg = false) => {
    if (
      response.metersReStarted &&
      response.subscriptionUpdated &&
      response.stripeEnrolled &&
      response.organizationRemoved
    ) {
      message.success(`user removed from organization and subscription updated successfully`);
    }
  };

  const [convertOrganizationUserToPaidSubscription, { loading: convertToPaidSubscriptionLoading }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.convertOrganizationUserToPaidSubscription,
    {
      awaitRefetchQueries: true,
      refetchQueries: ['GetAllUsersByFilter'],
      onCompleted: ({ convertOrganizationUserToPaidSubscription }: GQL_ConvertToPaidUserResponseChilds) => {
        handleSuccessResponse(convertOrganizationUserToPaidSubscription);
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const handleConvertToPaidSubscription = useCallback(
    (teacherId: string) => {
      if (teacherId && organizationId) {
        convertOrganizationUserToPaidSubscription({
          variables: {
            userId: teacherId,
            organizationId,
          },
        });

        return true;
      }
    },
    [convertOrganizationUserToPaidSubscription, organizationId],
  );

  const columns = useMemo(
    () => [
      {
        title: 'User Email',
        align: centerAlign,
        dataIndex: 'email',
        width: '20%',
        responsive: TableBreakPoint,
        sorter: (a: GQL_UserDetailsResponse, b: GQL_UserDetailsResponse) => a.email.localeCompare(b.email),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refEmail} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_UserDetailsResponse) => {
          if (!value) return true;
          return record.email.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Name',
        sorter: (a: GQL_UserDetailsResponse, b: GQL_UserDetailsResponse) => a.name.localeCompare(b.name),
        align: centerAlign,
        dataIndex: 'name',
        width: '20%',
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_UserDetailsResponse) => {
          if (!value) return true;
          return record.name.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Roles',
        dataIndex: 'roles',
        align: centerAlign,
        width: '20%',
        responsive: TableBreakPoint,
        render: (text: string | number | boolean, record: GQL_UserDetailsResponse) => {
          return record.roles.map((role: string) => {
            const roleItem = roles.find((r: Filter) => r.value === role);
            return (
              <S.TagButton
                text={roleItem?.text || ''}
                display="inline"
                background={roleItem?.color}
                shape="round"
                key={role}
                minHeight={24}
              />
            );
          });
        },
      },
      columnField,
      {
        title: 'User Details',
        align: centerAlign,
        width: '25%',
        render: (text: string, record: GQL_UserDetailsResponse) => {
          return (
            <Row justify="space-between" gutter={8}>
              <Col span={12}>
                <Popconfirm
                  title="Do you want to remove this teacher from your organization?"
                  onConfirm={() => removeTeacher({ variables: { teacherId: record.id } })}
                >
                  <Button
                    data-cy="shared-linkedsubscriptions-remove-button"
                    text="Remove"
                    block
                    theme={themeConfig.secondaryOutlined}
                    loading={loadingRemoveTeacher}
                  />
                </Popconfirm>
              </Col>
              <Col span={12}>
                <Popconfirm
                  title="This will remove the teacher from your organization and will delete all classes attached to this user. Do you want to proceed?"
                  onConfirm={() => handleConvertToPaidSubscription(record.id)}
                >
                  <Button
                    data-cy="shared-linkedsubscriptions-remove-button"
                    text="Convert to Paid"
                    block
                    theme={themeConfig.secondaryOutlined}
                    loading={convertToPaidSubscriptionLoading}
                  />
                </Popconfirm>
              </Col>
              <Col span={12} style={{ margin: '0 auto', marginTop: '10px' }}>
                <Button
                  data-cy="shared-linkedsubscriptions-details-button"
                  text="Details"
                  onClick={() => onViewMore(record)}
                  block
                  loading={loadingRemoveTeacher}
                />
              </Col>
            </Row>
          );
        },
      },
    ],
    [
      columnField,
      searchEmailVisible,
      searchNameVisible,
      loadingRemoveTeacher,
      convertToPaidSubscriptionLoading,
      removeTeacher,
      handleConvertToPaidSubscription,
      onViewMore,
    ],
  );

  return (
    <>
      <Row style={{ marginTop: 30 }}>
        <Col span={20}>
          <S.Title>
            Linked Users
            <S.TitleSmall>
              ({(subscription?.invitesAvailable || 0) - (teachers?.length || 0)} subscription slots remaining)
            </S.TitleSmall>
          </S.Title>
        </Col>
        <Col span={4}>
          <Button
            data-cy="shared-linkedsubscriptions-add-button"
            text={user.isAdiSuperAdmin || user.isAdiAdmin ? 'Add User' : 'Add Teachers'}
            onClick={() => setAddUserVisible(true)}
            block
            icon={<FiPlus />}
          />
        </Col>
        <Col md={24} lg={5}></Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={24}>
              <S.TableWrapper>
                <Table
                  columns={columns}
                  rowKey={(record: GQL_UserDetailsResponse) => record.id}
                  bordered
                  dataSource={teachers}
                  pagination={{ hideOnSinglePage: true }}
                />
              </S.TableWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
      <S.CModal
        visible={addUserVisible}
        maskClosable={false}
        title={<S.Title style={{ textAlign: 'center' }}>Add Teachers</S.Title>}
        onCancel={() => setAddUserVisible(false)}
        width={800}
        footer={null}
      >
        <BulkGenericUser
          setVisible={setAddUserVisible}
          role="teacher"
          buttonTitle="Teacher"
          organizationId={user.user.subscription?.organizationId || organizationId}
        />
      </S.CModal>
    </>
  );
};

export default LinkedSubscriptions;
