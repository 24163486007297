import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import * as S from './styles';
import { GQL_WalkthroughVideo, GQL_WalkthroughCategory } from '../../types/walkthrough';
import { Col, message, Result, Row, Tabs } from 'antd';
import Button from '../Button';
import { themeConfig } from '../../utils/theme';
import WalkthroughIcon from '../WalkthroughIcon';
import { useAuth } from '../../hooks/useAuth';
import Input from '../Input';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  walkthrough?: GQL_WalkthroughVideo;
  walkthroughs?: GQL_WalkthroughVideo[];
}
const { TabPane } = Tabs;

const WalkthroughVideo: React.FC<Props> = (props) => {
  const { visible, setVisible, walkthrough, walkthroughs } = props;
  const [activeKey, setActiveKey] = useState('video');
  const [searchTerm, setSearchTerm] = useState('');

  const [currentWalkthrough, setCurrentWalkthrough] = useState<GQL_WalkthroughVideo | undefined>();
  const [availableWalkthrough, setAvailableWalkthrough] = useState<GQL_WalkthroughVideo[]>([]);

  const { user, updateUser } = useAuth();

  const { data: walkthroughCategories } = useQuery<{
    getWalkthroughCategory: GQL_WalkthroughCategory[];
  }>(gqlSchema.WalkthroughSchema.queries.CATEGORY.getWalkthroughCategory, {
    onError: (err) => {
      message.error('There was an error loading walkthrough categories: ' + err.message || 'Unexpected Error');
    },
  });

  const onCloseModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const [submitMarkWalkthrough] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.WALKTRHOUGH.markWalkthroughsComplete,
    {
      onError: () => {},
    },
  );

  const onChangeTutorial = useCallback((w: GQL_WalkthroughVideo) => {
    setCurrentWalkthrough(w);
    setActiveKey('video');
    setSearchTerm('');
  }, []);

  const markOne = useCallback(
    (w: GQL_WalkthroughVideo) => {
      onChangeTutorial(w);
      if (!user.walkthroughs.some((e: string) => e === w.code)) {
        submitMarkWalkthrough({
          variables: { types: [w.code] },
          update(cache, { data }) {
            const me = {
              __typename: 'Me',
              id: user.id,
            };
            cache.modify({
              id: cache.identify(me),
              fields: {
                walkthrough(existingWalkthrough: string[] = []) {
                  return [...existingWalkthrough, w.code];
                },
              },
            });
            updateUser({ ...user, walkthroughs: [...user.walkthroughs, w.code] });
          },
        });
      }
    },
    [onChangeTutorial, submitMarkWalkthrough, updateUser, user],
  );

  const tutorialCategories = useMemo(
    () =>
      walkthroughCategories?.getWalkthroughCategory.filter(
        (wc: GQL_WalkthroughCategory) =>
          availableWalkthrough.filter(
            (w: GQL_WalkthroughVideo) =>
              w.category.id === wc.id && user.roles.some((t: string) => w.roles.some((d) => d === t)),
          ).length > 0,
      ) || [],
    [availableWalkthrough, walkthroughCategories, user],
  );

  const searchTutorial = useMemo(
    () => (
      <div style={{ width: 320 }}>
        <Input
          data-cy="shared-walkthroughvideo-tutorial-input"
          placeholder="Enter tutorial name and press Enter"
          onChange={(e) => {
            setActiveKey('tutorials');
            setSearchTerm(e.target.value);
          }}
          allowClear
          value={searchTerm}
        />
      </div>
    ),
    [searchTerm],
  );
  const contactUsButton = useMemo(
    () => (
      <Col span={5} style={{ margin: '15px 0' }}>
        <Button
          data-cy="shared-shared-walkthroughvideo-contact-us-button"
          text="Contact Us"
          block
          theme={themeConfig.primaryOutlined}
          type="link"
          target="_blank"
          href="https://help.adilearninghub.com/knowledge"
        />
      </Col>
    ),
    [],
  );
  useEffect(() => {
    if (walkthrough) setCurrentWalkthrough(walkthrough);
    else setActiveKey('tutorials');
  }, [walkthrough]);

  useEffect(() => {
    setAvailableWalkthrough(
      walkthroughs?.filter(
        (w: GQL_WalkthroughVideo) =>
          (searchTerm ? w.title.toLowerCase().includes(searchTerm.toLowerCase()) : true) &&
          user.roles.some((t: string) => w.roles.some((d) => d === t)),
      ) || [],
    );
  }, [user, searchTerm, walkthroughs]);

  return (
    <S.CModal
      visible={visible}
      title={
        <Row>
          <Col span={24}>
            <S.Title data-cy="shared-walkthrough-title-modal">
              {activeKey === 'video' ? currentWalkthrough?.title : 'Select Tutorial'}
            </S.Title>
          </Col>
        </Row>
      }
      onCancel={onCloseModal}
      width={820}
      destroyOnClose
      footer={null}
    >
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={(activeKey: string) => {
          if ((activeKey === 'video' && walkthrough) || activeKey === 'tutorials') setActiveKey(activeKey);
        }}
        tabBarExtraContent={searchTutorial}
      >
        <TabPane
          tab={<S.TitleTab data-cy="shared-walkthrough-tab-video">Video</S.TitleTab>}
          key="video"
          style={{ marginBottom: 15 }}
        >
          <Row justify="center" gutter={8}>
            <Col span={24} style={{ height: 440 }}>
              <video src={currentWalkthrough?.videoUrl} style={{ width: '100%' }} controls />
            </Col>
          </Row>
          <Row justify="center" gutter={8}>
            <Col span={24}>
              <Row justify="center" gutter={8}>
                <Col span={5} style={{ margin: '15px 0' }}>
                  <Button
                    data-cy="shared-walkthrough-close-button"
                    text="Close"
                    block
                    theme={themeConfig.primaryColor}
                    onClick={onCloseModal}
                  />
                </Col>
                {contactUsButton}
              </Row>
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={<S.TitleTab data-cy="shared-walkthrough-tab-tutorials">Tutorials</S.TitleTab>}
          key="tutorials"
          style={{ marginBottom: 15 }}
        >
          <Row style={{ height: 440, overflowY: 'auto' }}>
            <Col span={24} style={{ margin: '0 0 15px 0' }}>
              {tutorialCategories.length > 0 ? (
                <Row justify="center">
                  <Col span={24}>
                    {tutorialCategories.map((wc: GQL_WalkthroughCategory, i: number) => (
                      <Row key={`${wc.id}${i}`} gutter={[24, 16]} style={{ margin: '0 15px 20px 15px' }}>
                        <Col span={24}>
                          <S.CDivider orientation="left">{wc.title}</S.CDivider>
                        </Col>
                        {availableWalkthrough
                          .filter((w: GQL_WalkthroughVideo) => w.category.id === wc.id)
                          .map((w: GQL_WalkthroughVideo) => (
                            <Col key={w.code} span={8} onClick={() => markOne(w)}>
                              <S.Card>
                                <Row justify="center">
                                  <Col span={5}>
                                    <WalkthroughIcon tutorial={w.code} top="0px" right="0px" watchOnClick={false} />
                                  </Col>
                                  <Col span={24} style={{ marginTop: 35 }}>
                                    <S.Info>{w.title}</S.Info>
                                  </Col>
                                </Row>
                              </S.Card>
                            </Col>
                          ))}
                      </Row>
                    ))}
                  </Col>
                </Row>
              ) : (
                <Result status="error" subTitle="No tutorials found with this title." />
              )}
            </Col>
          </Row>
          <Row justify="center" gutter={8}>
            <Col span={24}>
              <Row justify="center" gutter={8}>
                <Col span={5} style={{ margin: '15px 0' }}>
                  <Button text="Close" block theme={themeConfig.primaryColor} onClick={onCloseModal} />
                </Col>
                {contactUsButton}
              </Row>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </S.CModal>
  );
};

export default React.memo(WalkthroughVideo);
