import { gql } from '@apollo/client';

const WALKTHROUGH_QUERIES = {
  CATEGORY: {
    getWalkthroughCategory: gql`
      query GetWalkthroughCategory {
        getWalkthroughCategory {
          id
          title
        }
      }
    `,
  },
  VIDEOS: {
    getWalkthroughVideos: gql`
      query GetWalkthroughVideos {
        getWalkthroughVideos {
          id
          title
          roles
          code
          videoUrl
          category {
            id
            title
          }
        }
      }
    `,
    getWalkthroughVideoById: gql`
      query GetWalkthroughVideoById($id: String!) {
        getWalkthroughVideoById(id: $id) {
          id
          title
          roles
          code
          videoUrl
          isVisible
          category {
            id
            title
          }
        }
      }
    `,
  },
};

export default WALKTHROUGH_QUERIES;
