export const packageFeatures = {
  starter: [
    {
      type: 'feature',
      bgColor: '#C5D1FF',
      color: '#006CFA',
      text: 'Assign up to 2 investigations or design challenges',
    },
    { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Assign up to 4 assessments' },
    { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Create up to 6 classes' },
    { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Enroll up to 40 students per class' },
    {
      type: 'feature',
      bgColor: '#C5D1FF',
      color: '#006CFA',
      text: 'Download student handouts for assigned investigations, design challenges and assessments',
    },
    { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Teacher report and assessment scoring system' },
    { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Student performance insight tool' },
    { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Tags for identifying students' },
    { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Presentation tool for teachers' },
    {
      type: 'feature',
      bgColor: '#C5D1FF',
      color: '#006CFA',
      text: 'Student peer review system available in remote mode',
    },
  ],
  teacher: {
    pro: {
      yearly: '$49',
      monthly: '$5/month',
      features: [
        {
          type: 'upgrade',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Assign up to 6 investigation or design challenges',
        },
        { type: 'upgrade', bgColor: '#C5D1FF', color: '#006CFA', text: 'Assign up to 24 Assessments' },
        { type: 'addition', bgColor: '#2ECC71', color: 'white', text: 'Add up to 2 TAs' },
        { type: 'addition', bgColor: '#2ECC71', color: 'white', text: 'Immersive reader for students' },
      ],
    },
    premium: {
      yearly: '$129',
      monthly: '$12/month',
      features: [
        {
          type: 'upgrade',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Assign up to 15 investigation or design challenges',
        },
        { type: 'upgrade', bgColor: '#C5D1FF', color: '#006CFA', text: 'Assign unlimited assessments' },
        { type: 'upgrade', bgColor: '#C5D1FF', color: '#006CFA', text: 'Create up to 8 classes' },
        { type: 'upgrade', bgColor: '#C5D1FF', color: '#006CFA', text: 'Add up to 8 TAs' },
        { type: 'addition', bgColor: '#2ECC71', color: 'white', text: 'TAs can grade' },
      ],
    },
  },
  enterprise: {
    pro: {
      yearly: '$179 per teacher account',
      features: [
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text:
            'Unlimited science investigations, math investigations or engineering design challenges for the organization',
        },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Unlimited assessments from science, math or engineering for the organization',
        },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Each teacher in the organization can create up to 8 classes',
        },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Each teacher in the organization can enroll up to 40 students per class',
        },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Organization administrators can reassign teacher accounts and transfer classes between teachers',
        },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Tags for organization administrators and teachers',
        },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Teacher report and assessment scoring system' },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Teacher usage insight for organization administrators',
        },
      ],
    },
    premium: {
      yearly: '$349 per teacher account',
      features: [
        {
          type: 'upgrade',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Each teacher can add 8 TAs',
        },
        {
          type: 'addition',
          bgColor: '#2ECC71',
          color: 'white',
          text: 'SSO (ClassLink, Clever, Google)',
        },
        {
          type: 'addition',
          bgColor: '#2ECC71',
          color: 'white',
          text: 'Rostering (ClassLink or Clever)',
        },
        {
          type: 'addition',
          bgColor: '#2ECC71',
          color: 'white',
          text: 'Custom investigation libraries',
        },
        { type: 'addition', bgColor: '#2ECC71', color: 'white', text: 'Custom assessment libraries' },
        { type: 'addition', bgColor: '#2ECC71', color: 'white', text: 'Investigation writing and editing tools' },
        { type: 'addition', bgColor: '#2ECC71', color: 'white', text: 'Assessment writing and editing tools' },
        { type: 'addition', bgColor: '#2ECC71', color: 'white', text: 'Onboarding for teachers' },
      ],
    },
  },
};

export const packageUpgradeFeatures = (from: string) => {
  return {
    starter: {
      subtitle: 'Free for educators, always',
      features: [
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Assign up to 2 investigations or design challenges',
        },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Assign up to 4 assessments' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Create up to 6 classes' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Enroll up to 40 students per class' },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Download student handouts for assigned investigations, design challenges and assessments',
        },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Teacher report and assessment scoring system' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Student performance insight tool' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Tags for identifying students' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Presentation tool for teachers' },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Student peer review system available in remote mode',
        },
      ],
    },
    pro: {
      yearly: '$49',
      monthly: '$5/month',
      subtitle: '$49 (1 Year Subscription)',
      features: [
        {
          type: 'upgrade',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Assign up to 6 investigation or design challenges',
        },
        { type: 'upgrade', bgColor: '#C5D1FF', color: '#006CFA', text: 'Assign up to 24 Assessments' },
        { type: 'addition', bgColor: '#2ECC71', color: 'white', text: 'Add up to 2 TAs' },
        { type: 'addition', bgColor: '#2ECC71', color: 'white', text: 'Immersive reader for students' },
      ],
    },
    premium: {
      yearly: '$129',
      monthly: '$12/month',
      subtitle: '$129 (1 Year Subscription)',
      features: [
        {
          type: 'upgrade',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Assign up to 15 investigation or design challenges',
        },
        { type: 'upgrade', bgColor: '#C5D1FF', color: '#006CFA', text: 'Assign unlimited assessments' },
        {
          type: `${from === 'starter' ? 'addition' : 'upgrade'}`,
          bgColor: `${from === 'starter' ? '#2ECC71' : '#C5D1FF'}`,
          color: `${from === 'starter' ? 'white' : '#006CFA'}`,
          text: 'Create up to 8 classes',
        },
        {
          type: `${from === 'starter' ? 'addition' : 'upgrade'}`,
          bgColor: `${from === 'starter' ? '#2ECC71' : '#C5D1FF'}`,
          color: `${from === 'starter' ? 'white' : '#006CFA'}`,
          text: 'Add up to 8 TAs',
        },
        {
          type: 'addition',
          bgColor: '#2ECC71',
          color: 'white',
          text: 'TAs can grade',
        },
      ],
    },
  };
};

export const packageDowngradeFeatures = (to: string) => {
  return {
    premium: {
      yearly: '$129',
      monthly: '$12/month',
      subtitle: '$129 (1 Year Subscription)',
      features: [
        {
          type: 'downgrade',
          bgColor: '#FFAE51',
          color: 'white',
          text: 'Assign up to 15 investigation or design challenges',
        },
        { type: 'downgrade', bgColor: '#FFAE51', color: 'white', text: 'Assign unlimited assessments' },
        {
          type: 'downgrade',
          bgColor: '#FFAE51',
          color: 'white',
          text: 'Create up to 8 classes',
        },
        {
          type: `${to === 'starter' ? 'redution' : 'downgrade'}`,
          bgColor: `${to === 'starter' ? '#EA3535' : '#FFAE51'}`,
          color: `${to === 'starter' ? 'white' : 'white'}`,
          text: 'Add up to 8 TAs',
        },
        {
          type: 'redution',
          bgColor: '#EA3535',
          color: 'white',
          text: 'TAs can grade',
        },
      ],
    },
    pro: {
      yearly: '$49',
      monthly: '$5/month',
      subtitle: '$49 (1 Year Subscription)',
      features: [
        {
          type: 'downgrade',
          bgColor: '#FFAE51',
          color: 'white',
          text: 'Assign up to 6 investigation or design challenges',
        },
        { type: 'downgrade', bgColor: '#FFAE51', color: 'white', text: 'Assign up to 24 assessments' },
        { type: 'redution', bgColor: '#EA3535', color: 'white', text: 'Add up to 2 TAs' },
        { type: 'redution', bgColor: '#EA3535', color: 'white', text: 'Immersive reader for students' },
      ],
    },
    starter: {
      subtitle: 'Free for educators, always',
      features: [
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Assign up to 2 investigations or design challenges',
        },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Assign up to 4 assessments' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Create up to 6 classes' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Enroll up to 40 students per class' },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Download student handouts for assigned investigations, design challenges and assessments',
        },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Teacher report and assessment scoring system' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Student performance insight tool' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Tags for identifying students' },
        { type: 'feature', bgColor: '#C5D1FF', color: '#006CFA', text: 'Presentation tool for teachers' },
        {
          type: 'feature',
          bgColor: '#C5D1FF',
          color: '#006CFA',
          text: 'Student peer review system available in remote mode',
        },
      ],
    },
  };
};
