import { Modal } from 'antd';
import styled from 'styled-components';

export const UserSelectionModal = styled(Modal)`
  min-width: 400px;
@media (max-width: 768px) {
  min-width: 350px;
  }
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    margin-bottom: 16px;
  }
  h2 {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 125.1%;
    width: 320px;
    margin: auto;
    color: #767676;
    margin-bottom: 40px;
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 125.1%;
    color: #767676;
  }

  .ant-modal-body {
    & > div {
      display: flex;
  @media (max-width: 768px) {
       display: block;
  }
      
    }
  }
`;
