import { useMemo } from 'react';
import { AssessmentTypes, AssessmentTypesLabel, StepMode, assessmentTypes } from '../types/assessments';

const useInvestigationMode = (investigationMode: string) => {
  const ModelType = useMemo(() => {
    if (investigationMode === StepMode.INPERSON) {
      return AssessmentTypes.PAPER;
    }

    if (investigationMode === StepMode.REMOTE) {
      return AssessmentTypes.PAPERLESS;
    }

    return AssessmentTypes.PAPERLESS;
  }, [investigationMode]);

  const ModelLabel = useMemo(() => {
    if (investigationMode === StepMode.INPERSON) {
      return AssessmentTypesLabel.PAPER;
    }

    if (investigationMode === StepMode.REMOTE) {
      return AssessmentTypesLabel.PAPERLESS;
    }

    const modeLabel =
      investigationMode === StepMode.ALL
        ? 'Both Paper and Paperless'
        : investigationMode === StepMode.REMOTE
        ? AssessmentTypesLabel.PAPERLESS
        : AssessmentTypesLabel.PAPER;

    return modeLabel;
  }, [investigationMode]);

  const AssessmentTypeOptions = useMemo(() => {
    const options = [];
    if (investigationMode === StepMode.INPERSON) {
      options.push(assessmentTypes[0]);

      return options;
    }

    if (investigationMode === StepMode.REMOTE) {
      options.push(assessmentTypes[1]);

      return options;
    }

    return assessmentTypes;
  }, [investigationMode]);

  return { AssessmentTypeOptions, ModelLabel, ModelType };
};

export default useInvestigationMode;
