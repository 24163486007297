import styled from 'styled-components';
import CButton from '../../shared/Button';
import { Row, Col } from 'antd';

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

export const RowSP = styled(Row)`
  margin-top: 10vh;
`;

export const ColSP = styled(Col)`
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 15px -3px;
  background: #ffffff;
  border-radius: 5px;
  padding: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ChangePasswordButton = styled(CButton)`
  font-weight: 500;
  width: 100%;
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: 800;
  font-size: 2em;
  @media (max-width: 1200px) {
    font-size: 1.8em;
  }
`;

export const Info = styled.p`
  text-align: center;
  margin-bottom: 24px;
  line-height: 1.2;
`;

export const LogoImage = styled.img`
  width: 75%;
  margin-bottom: 24px;
`;
