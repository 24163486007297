import React, { useMemo, useRef, useState } from 'react';
import { Col, Layout, message, Row } from 'antd';

import * as S from './styles';
import CommentsSider from './CommentsSider';
import InvestigationContent from '../../shared/InvestigationContent';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { GQL_Concept, IReflectionComment } from '../../types/investigation';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import InvestigationConcept from '../../shared/InvestigationConcept';
import Button from '../../shared/Button';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Spacer from '../../shared/Spacer';

type Props = RouteComponentProps<
  {
    activityId: string;
    investigationId: string;
    submissionVersion: string;
  },
  any,
  {
    comments: IReflectionComment[];
    peerReviewId: string;
  }
>;

const BestCommentsInvestigationPage: React.FC<Props> = (props) => {
  const history = useHistory();
  const { investigationId } = props.match.params;
  const [displayComments, setDisplayComments] = useState(true);
  const [finishedLoadingConceptFiles, setFinishedLoadingConceptFiles] = useState(false);
  const conceptContainerRef = useRef<HTMLDivElement>(null);
  const [selectedCommentId, setSelectedCommentId] = useState<string>();
  const submissionVersion = props.match.params.submissionVersion ? parseInt(props.match.params.submissionVersion) : 1;

  const { comments, peerReviewId } = props.location.state || {};

  if (!peerReviewId) {
    history.goBack();
  }

  if (!comments) history.push('/student-investigation/' + investigationId);

  const { data: conceptData, loading } = useQuery<
    { getConceptFromPeerReviewId: GQL_Concept },
    { peerReviewId: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getConceptFromPeerReviewId, {
    variables: {
      peerReviewId: peerReviewId,
    },
    onError: (err) => {
      message.error('There was an error loading the concept: ' + err.message || 'Unexpected Error');
      history.goBack();
    },
  });

  const height = conceptContainerRef.current?.clientHeight;
  const width = conceptContainerRef.current?.clientWidth;

  const commentList = useMemo(() => {
    if (displayComments && finishedLoadingConceptFiles) {
      return comments?.map((comment, i) => {
        return (
          <S.CommentBalloon
            key={comment.id}
            selected={comment.id === selectedCommentId}
            xPos={(comment?.coordinates?.x ?? 0) * (height ?? 0) + 17.5}
            yPos={(comment?.coordinates?.y ?? 0) * (width ?? 0)}
            onClick={() => setSelectedCommentId(comment.id === selectedCommentId ? '' : comment.id)}
          >
            {i + 1}
          </S.CommentBalloon>
        );
      });
    }
  }, [comments, displayComments, height, selectedCommentId, width, finishedLoadingConceptFiles]);

  return (
    <Layout>
      <CommentsSider
        submissionVersion={submissionVersion}
        comments={comments}
        highlightedCommentId={selectedCommentId}
        investigationId={investigationId}
      />
      <InvestigationContent>
        <Row justify="center">
          <S.Column xxl={12} lg={20} sm={23}>
            <h1>Peer Review Concept</h1>
            <h2>
              Give people in other group some ideas about what they can do to make their concepts better by adding a
              comment to the submission below. Be sure to post at least 2 comments. Comments can be suggestions or
              something you liked about the proposal.
            </h2>
          </S.Column>
        </Row>

        <Spacer />

        <Row justify="center">
          <Col xxl={12} lg={20} sm={23}>
            <Button
              style={{ float: 'right' }}
              text={displayComments ? 'Hide Comments' : 'Show Comments'}
              icon={displayComments ? <FiEyeOff /> : <FiEye />}
              onClick={() => setDisplayComments(!displayComments)}
              loading={loading}
            />
          </Col>
        </Row>

        <Row justify="center">
          <Col xxl={12} lg={20} sm={23} ref={conceptContainerRef}>
            {commentList}
            <InvestigationConcept
              concept={conceptData?.getConceptFromPeerReviewId}
              onLoadFinish={() => setFinishedLoadingConceptFiles(true)}
            />
          </Col>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(BestCommentsInvestigationPage);
