import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { createRef, useEffect } from 'react';
import { Image, Transformer } from 'react-konva';
import { useImage } from 'react-konva-utils';

export interface IImageShapeProps {
  x?: number;
  y?: number;
  src: string;
  width?: number;
  height?: number;
  id: string;
}

interface IImage {
  isSelected: boolean;
  draggable: boolean;
  onChange: (newAttributes: IImageShapeProps) => void;
  onSelect: (e: KonvaEventObject<Event>) => void;
  shapeProps: IImageShapeProps;
}

export const ImageShape = ({ isSelected, shapeProps, draggable, onChange, onSelect }: IImage) => {
  const [image, status] = useImage(shapeProps.src, 'Anonymous');
  const shapeRef = createRef<Konva.Image>();
  const trRef = createRef<Konva.Transformer>();

  useEffect(() => {
    if (status === 'loaded') {
      const imageHeight = image?.height ?? 0;
      const imageWidth = image?.width ?? 0;
      const ratio = imageWidth / 200;
      onChange({ ...shapeProps, height: imageHeight / ratio, width: imageWidth / ratio });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      if (shapeRef?.current && trRef?.current) {
        trRef?.current?.nodes([shapeRef?.current]);
        trRef.current.getLayer()?.batchDraw();
      }
    }
  }, [isSelected, trRef, shapeRef]);

  return (
    <>
      <Image
        onClick={onSelect}
        onTap={onSelect}
        image={image}
        height={shapeProps.height}
        width={shapeProps.width}
        x={shapeProps.x}
        y={shapeProps.y}
        draggable={draggable}
        ref={shapeRef}
        onDragStart={onSelect}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          if (node) {
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(30, node.width() * scaleX),
              height: Math.max(30, node.height() * scaleY),
            });
          }
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 30 || newBox.height < 30) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};
