import styled from 'styled-components';
import { Col as AntCol, Layout } from 'antd';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin: 0 1em 0 0;
  }
`;

export const Col = styled(AntCol)`
  & > ul {
    list-style-type: none;
    padding-inline-start: 0;
  }

  & > p,
  & > h1,
  & > h2 {
    text-align: center;
  }

  & > h3 {
    align-self: flex-start;
  }

  & > h1 {
    margin-bottom: 0;
  }

  & > div {
    width: 100%;
  }
`;

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled(Layout.Content)`
  padding: 40px !important;
  background-color: white;
  border-radius: 6px;

  p,
  ul {
    margin-bottom: 0px;
  }
`;

export const DataContent = styled(Layout.Content)`
  padding: 15px !important;
  background-color: white;
  border-radius: 6px;

  p,
  ul {
    margin-bottom: 0px;
  }
`;
