import React from 'react';
import * as S from './styles';
import contactSrc from '../../assets/landing/icons/contact.png';
import youtubeSrc from '../../assets/landing/icons/youtube.png';
import facebookSrc from '../../assets/landing/icons/facebook.png';
// import twitterSrc from '../../assets/landing/icons/twitter.png';
import xSrc from '../../assets/landing/icons/x.png';

const AdiFooter: React.FC = () => {
  return (
    <S.FooterContainer>
      <S.FooterLinks>
        <a
          data-cy="components-homepage-bottom-termscondition"
          href="/documents/terms-of-use.pdf"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Opens in a new tab: Terms & conditions"
        >
          Terms Of Use
        </a>
        <a
          data-cy="components-homepage-bottom-privacy"
          target="_blank"
          href="/documents/privacy-policy.pdf"
          rel="noopener noreferrer"
          aria-label="Opens in a new tab: Privacy policy"
        >
          Privacy Policy
        </a>
        <a
          data-cy="components-homepage-bottom-contactus"
          href="https://www.argumentdriveninquiry.com/connect-with-us"
        >
          Contact Us
        </a>
      </S.FooterLinks>
      <S.CopyrightText>
        ©{new Date().getFullYear()} Argument-Driven Inquiry Learning Hub
      </S.CopyrightText>
      <S.SocialIcons>
        <a
          href="https://www.argumentdriveninquiry.com/connect-with-us"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Connect with us"
        >
          <img
            src={contactSrc}
            alt="Two conversation balloons"
          />
        </a>
        <a
          href="https://www.youtube.com/c/ArgumentDrivenInquiry/featured"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open YouTube Channel"
        >
          <img
            data-cy="components-homepage-bottom-youtube"
            src={youtubeSrc}
            alt="Youtube Logo"
          />
        </a>
        <a
          href="https://www.facebook.com/ArgumentDriven/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open Facebook Page"
        >
          <img
            data-cy="components-homepage-bottom-facebook"
            src={facebookSrc}
            alt="Facebook Logo"
          />
        </a>
        <a
          href="https://twitter.com/ArgumentDriven"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open Twitter Profile"
        >
          <img
            data-cy="components-homepage-bottom-twitter"
            src={xSrc}
            alt="Twitter Logo"
          />
        </a>
      </S.SocialIcons>
    </S.FooterContainer>
  );
};

export default AdiFooter;
