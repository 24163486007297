import styled from 'styled-components';
import Input from '../../shared/Input';
import CButton from '../../shared/Button';

export const Title = styled.h1`
  text-align: center;
  margin: 20px;
  font-weight: 800;
  font-size: 2em;
  @media (max-width: 1200px) {
    font-size: 1.8em;
  }
`;

export const TitleInput = styled.p`
  text-align: start;
  font-weight: 600;
  font-size: 0.85em;
  margin: 0;
`;

export const CreateButton = styled(CButton)`
  font-weight: 500;
`;

export const SInput = styled(Input)`
  border-radius: 5px;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.85) !important;

  input {
    background-color: #fff !important;
  }
`;
