import { ReactComponent as BiologyLogo } from '../assets/discipline-icons/science/biology.svg';
import { ReactComponent as ChemistryLogo } from '../assets/discipline-icons/science/chemistry.svg';
import { ReactComponent as EarthSpaceScienceLogo } from '../assets/discipline-icons/science/earth-space-science.svg';
import { ReactComponent as LifeScienceLogo } from '../assets/discipline-icons/science/life-sciences.svg';
import { ReactComponent as PhysicalScienceLogo } from '../assets/discipline-icons/science/physical-science.svg';
import { ReactComponent as PhysicsLogo } from '../assets/discipline-icons/science/physics.svg';

import { ReactComponent as AlgebraLogo } from '../assets/discipline-icons/maths/algebra.svg';
import { ReactComponent as EquationsLogo } from '../assets/discipline-icons/maths/expressions-equations.svg';
import { ReactComponent as FractionsLogo } from '../assets/discipline-icons/maths/fractions.svg';
import { ReactComponent as FunctionsLogo } from '../assets/discipline-icons/maths/functions-modeling.svg';
import { ReactComponent as GeometryLogo } from '../assets/discipline-icons/maths/geometry.svg';
import { ReactComponent as DataLogo } from '../assets/discipline-icons/maths/measurement-data.svg';
import { ReactComponent as NumberQuantityLogo } from '../assets/discipline-icons/maths/number-quantity.svg';
import { ReactComponent as NumberSystemLogo } from '../assets/discipline-icons/maths/number-system.svg';
import { ReactComponent as NumberOperationLogo } from '../assets/discipline-icons/maths/numbers-operations-base-ten.svg';
import { ReactComponent as OperationAlgebraicLogo } from '../assets/discipline-icons/maths/operations-algebraic-thinking.svg';
import { ReactComponent as RatiosLogo } from '../assets/discipline-icons/maths/ratios-proportional-relationships.svg';
import { ReactComponent as StatisticLogo } from '../assets/discipline-icons/maths/statistics-probability.svg';

import { ReactComponent as EnvironmentaleLogo } from '../assets/discipline-icons/engineering/environmental1.svg';
import { ReactComponent as ChemicalLogo } from '../assets/discipline-icons/engineering/chemical.svg';
import { ReactComponent as CivilLogo } from '../assets/discipline-icons/engineering/civil.svg';
import { ReactComponent as ElectricalLogo } from '../assets/discipline-icons/engineering/electrical.svg';
import { ReactComponent as MechanicalLogo } from '../assets/discipline-icons/engineering/mechanical.svg';
import { ReactComponent as WorkshopLogo } from '../assets/discipline-icons/workshop/workshop.svg';

export const getMathsLogo = (payload: { discipline: string }) => {
  const { discipline } = payload;

  switch (discipline.toLowerCase().replace(/ /g, '-')) {
    case 'expressions-and-equations':
      return EquationsLogo;
    case 'fractions':
      return FractionsLogo;
    case 'functions':
    case 'functions-and-modeling':
      return FunctionsLogo;
    case 'geometry':
      return GeometryLogo;
    case 'measurement-and-data':
      return DataLogo;
    case 'number-and-quantity':
      return NumberQuantityLogo;
    case 'the-number-system':
      return NumberSystemLogo;
    case 'numbers-and-operations-in-base-ten':
      return NumberOperationLogo;
    case 'operations-and-algebraic-thinking':
      return OperationAlgebraicLogo;
    case 'ratios-and-proportional-relationships':
      return RatiosLogo;
    case 'statistics-and-probability':
      return StatisticLogo;
    default:
      return AlgebraLogo;
  }
};

export const getEngineeringLogo = (payload: { discipline: string }) => {
  const { discipline } = payload;

  switch (discipline.toLowerCase().replace(/ /g, '-')) {
    case 'chemical':
      return ChemicalLogo;
    case 'civil':
      return CivilLogo;
    case 'electrical':
      return ElectricalLogo;
    case 'mechanical':
      return MechanicalLogo;
    case 'environmental':
      return EnvironmentaleLogo;
    default:
      return MechanicalLogo;
  }
};

export const getScienceLogo = (payload: { discipline: string }) => {
  const { discipline } = payload;

  switch (discipline.toLowerCase().replace(/ /g, '-')) {
    case 'chemistry':
      return ChemistryLogo;
    case 'earth-space-science':
    case 'geology':
    case 'astronomy':
      return EarthSpaceScienceLogo;
    case 'life-science':
      return LifeScienceLogo;
    case 'physical-science':
      return PhysicalScienceLogo;
    case 'physics':
      return PhysicsLogo;
    default:
      return BiologyLogo;
  }
};

export const getInvestigationLogo = (payload: { discipline: string; subject: string }) => {
  const { discipline, subject } = payload;

  if (subject === 'Science' || subject === 'Science:_Texas_Edition_(2024_Adoption)')
    return getScienceLogo({ discipline });
  if (subject === 'Engineering') return getEngineeringLogo({ discipline });
  if (subject === 'Workshop') return WorkshopLogo;
  else return getMathsLogo({ discipline });
};

export const getInvestigationBackgroundColor = (payload: { subject?: string }) => {
  const { subject } = payload;

  switch (subject?.toLowerCase()) {
    case 'science':
      return '#d8eee6';
    case 'engineering':
      return '#ffd89f';
    case 'math':
      return '#ceecfc';
    default:
      return '#ceecfc';
  }
};
