import { gql } from '@apollo/client';

const INVITES_QUERIES = {
  INVITES: {
    getInvites: gql`
      query GetInvites($data: QueryInviteFilterInput!) {
        getInvites(data: $data) {
          invites {
            id
            firstName
            lastName
            role
            email
            emailSent
            createdAt
          }
          pagination {
            page
            size
            totalCount
          }
        }
      }
    `,
    exportInvites: gql`
      query ExportInvites($data: QueryInviteFilterInput!) {
        exportInvites(data: $data)
      }
    `,
  },
};

export default INVITES_QUERIES;
