import React, { useCallback } from 'react';
import { Form as AntdForm, message, Typography } from 'antd';
import { RouteComponentProps, useHistory, withRouter, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Input from '../../shared/Input';
import * as S from './styles';
import Button from '../../shared/Button';
import { ILoginProps } from '../../types';
import Form from '../../shared/Form';
import { gqlSchema } from '../../gql/schema';
import { GQL_LoginResponse } from '../../types/login';
import { useAuth } from '../../hooks/useAuth';
import client from '../../gql/api';

import adiLearningHubSrc from '../../assets/adi-learning-hub.svg';

type Props = RouteComponentProps<null, any, any>;

const AdvancedSearchLoginPage = (props: Props) => {
  const { version } = useParams<{ version: string }>();
  const history = useHistory();
  const { setSignedIn } = useAuth();

  const [submitLogin, { loading }] = useMutation(gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.login, {
    onCompleted: ({ login }: { login: GQL_LoginResponse }) => {
      const { accessToken, ...data } = login;
      // Should perform login just if the user Type is AdvancedSearch
      if (data.isAdvancedSearch) {
        setSignedIn({
          token: login.accessToken,
          user: { ...data, id: login.id },
        });
        client.resetStore();

        history.push(`/advanced-search/${version}/home`);
      }
    },
    onError: (error) => {
      console.error(error);
      message.error(<Typography.Text data-cy="components-loginpage-error-text">{error.message}</Typography.Text>);
    },
  });

  const onSubmitForm = useCallback(
    (values: ILoginProps) => {
      submitLogin({
        variables: {
          data: {
            ...values,
            username: process.env.REACT_APP_ADVANCED_SEARCH_EMAIL,
          },
        },
      });
    },
    [submitLogin],
  );

  return (
    <S.Container data-cy="components-loginpage-container">
      <S.RowSP justify="center" align="middle">
        <S.ColSP xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Login To Advanced Search Site</S.Title>
          <Form onFinish={onSubmitForm}>
            <p>Password</p>
            <AntdForm.Item name="password" rules={[{ required: true }]}>
              <Input data-cy="components-loginpage-password-field" placeholder="Type your password here..." password />
            </AntdForm.Item>

            <AntdForm.Item>
              <Button
                data-cy="components-loginpage-login-button"
                text="Login"
                htmlType="submit"
                loading={loading}
                block
                minHeight={40}
              />
            </AntdForm.Item>
          </Form>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );
};

export default withRouter(AdvancedSearchLoginPage);
