import styled from 'styled-components';
import { Button } from 'antd';

export const ClassInfosContainer = styled.div`
  width: 100%; // Ensure container takes the full width
  
  background: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1); // Optional: Add some shadow for better visibility
`;

export const Title = styled.h3`
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const InfoProgress = styled.p`
  font-size: 0.45em;
  margin: 10px 0 0 0;
`;

export const CircularButton = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 18px;
`;
