import { Rate } from 'antd';
import styled from 'styled-components';
import CButton from '../../../Button';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5em;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .middle {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.9em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const InfoProgress = styled.p`
  font-size: 0.4em;
  margin: 10px 0 0 0;
`;

export const Empty = styled.div`
  height: 25px;
`;

export const Title = styled.h3<{
  $align?: string;
}>`
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  font-weight: 500;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ReportButton = styled(CButton)`
  font-weight: 500;
  white-space: normal;
  font-size: 0.85em;
  line-height: 1.25em;
  padding: 1em 0;
`;

export const Stars = styled(Rate)`
  font-size: 0.85em;
`;

export const DebugRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
`;

export const DebugTitle = styled.div`
  font-weight: 700;
  margin-bottom: 0.5em;
`;

export const DebugInfo = styled.div`
  font-weight: 400;
  margin-bottom: 0.5em;
`;

export const Debug = styled.div`
  background-color: #f0f0f0;
  padding: 1em;
  border-radius: 4px;
  margin-top: 1em;
  font-size: 0.8em;
  font-weight: 500;
  color: #666;
  text-align: left;
  line-height: 1.5em;

  ${DebugRow} {
    font-weight: 700;
    width: 100%;
  }
`;
