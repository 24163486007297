import { message } from 'antd';
import { useLayoutEffect, useState } from 'react';

const useFullscreenStatus = (elRef: React.RefObject<HTMLElement>) => {
  const isFullscreen = document.fullscreenElement !== null ? 'enabled' : 'disabled';
  const [status, setStatus] = useState<'not-supported' | 'enabled' | 'disabled'>(
    !document.fullscreenEnabled ? 'not-supported' : isFullscreen,
  );

  const setFullscreen = async (fullscreen: boolean) => {
    if (elRef.current == null || status === 'not-supported') return;

    try {
      if (fullscreen) {
        await elRef.current.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (_) {
      message.error('Fullscreen not supported');
    }
  };

  useLayoutEffect(() => {
    document.onfullscreenchange = (event) => {
      if (status !== 'not-supported') {
        setStatus(document.fullscreenElement !== null ? 'enabled' : 'disabled');
      }
    };

    return () => {
      document.onfullscreenchange = null;
    };
  });

  return { status, setFullscreen };
};

export default useFullscreenStatus;
