import React, { useEffect, useRef } from 'react';
import * as S from './styles';

interface IDragTarget {
  style?: React.CSSProperties;
  onElementIsDragged?: (draggedObject: number, currentPosition: { x: number; y: number }) => void;
  className?: string;
}

const DragTarget: React.FC<IDragTarget> = (props) => {
  const { children, style, className, onElementIsDragged } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && onElementIsDragged) {
      // Clone node and replace it to remove old event listeners
      const newNode = ref.current.cloneNode(true);
      ref.current.parentNode?.replaceChild(newNode, ref.current);

      newNode.addEventListener('DraggableTargetDropped', (event) => {
        event.stopPropagation();
        const customEvent = event as CustomEvent;
        if (onElementIsDragged)
          onElementIsDragged(customEvent.detail.currentDragged, customEvent.detail.currentPosition);
      });
    }
  }, [onElementIsDragged]);

  return (
    <S.ContainerDragTarget style={style} className={className} ref={ref}>
      {children}
    </S.ContainerDragTarget>
  );
};

export default DragTarget;
