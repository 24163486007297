import { Col, Row, Button as AntdButton } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React from 'react';
import { FiMinusCircle } from 'react-icons/fi';
import Input from '../../../../../shared/Input';
import Select from '../../../../../shared/Select';
import SelectOption from '../../../../../shared/Select/Option';
import Spacer from '../../../../../shared/Spacer';
import UploadInput from '../../../../../shared/UploadInput';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  ICanvasContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';

interface ICanvasBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const CanvasBlockEditor = (props: ICanvasBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const canvasValue = editingBlock.values[0] as ICanvasContentValue;

  const beforeUploadCanvasImages = (file: RcFile, fileList: RcFile[]) => {
    if (!file.type.includes('image')) return false;

    return true;
  };

  const handleEditCanvas = (payload: {
    title?: string;
    count?: number;
    index?: number;
    width?: number;
    templateId?: number;
  }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              const newTitles = [...(canvasValue.titles || [])];
              if (payload.title) newTitles[payload.index ?? 0] = payload.title ?? '';

              return {
                ...block,
                values: [
                  {
                    ...canvasValue,
                    count: payload.count,
                    titles: newTitles?.slice(0, payload.count ?? 1),
                    width: payload.width,
                    templateId: payload.templateId,
                  },
                ],
              };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleAddRemoveCanvasImageUrl = (payload?: { index?: number; file?: File; fileId?: string }) => {
    const currentActivity = activities.find((activity) => activity.id === selectedActivityId);
    const newContent = (editingRemote ? currentActivity?.content : currentActivity?.contentInPerson)?.map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          ...content,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;
            const urls = canvasValue.imageUrls || [];
            if (payload?.file) {
              return {
                ...block,
                values: [
                  {
                    ...canvasValue,
                    files: [...(canvasValue.files || []), payload.file],
                  },
                ],
              };
            }

            if (payload?.fileId) {
              return {
                ...block,
                values: [
                  {
                    ...canvasValue,
                    files: canvasValue?.files?.filter((v) => v.uid !== payload?.fileId),
                  },
                ],
              };
            }

            return {
              ...block,
              values: [
                {
                  ...canvasValue,
                  imageUrls:
                    typeof payload?.index === 'number'
                      ? [...urls.slice(0, payload.index), ...urls.slice(payload.index + 1)]
                      : [...urls, ''],
                },
              ],
            };
          }),
        };
      },
    );
    if (newContent) {
      setActivities(
        activities.map((activity) => {
          if (activity.id !== selectedActivityId) return activity;

          if (editingRemote) {
            return { ...activity, content: newContent };
          } else {
            return { ...activity, contentInPerson: newContent };
          }
        }),
      );
    }
  };

  const handleEditCanvasImageUrl = (payload?: { index?: number; url?: string }) => {
    const currentActivity = activities.find((activity) => activity.id === selectedActivityId);
    const newContent = (editingRemote ? currentActivity?.content : currentActivity?.contentInPerson)?.map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          ...content,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;
            const urls = canvasValue.imageUrls || [];
            const newUrls = urls.map((url, i) => {
              if (i !== (payload?.index ?? 0)) return url;
              return payload?.url ?? '';
            });

            return {
              ...block,
              values: [
                {
                  ...canvasValue,
                  imageUrls: newUrls,
                },
              ],
            };
          }),
        };
      },
    );

    if (newContent) {
      setActivities(
        activities.map((activity) => {
          if (activity.id !== selectedActivityId) return activity;
          if (editingRemote) {
            return { ...activity, content: newContent };
          } else {
            return { ...activity, contentInPerson: newContent };
          }
        }),
      );
    }
  };

  return (
    <>
      <h2>Grid Size</h2>
      <Input
        value={canvasValue.count ?? 1}
        backgroundColor="white"
        max={3}
        min={1}
        type="number"
        onChange={(e) => handleEditCanvas({ ...canvasValue, count: Number(e.target.value) })}
      />
      <Spacer size={24} />
      {Array(canvasValue.count ?? 1)
        .fill(1)
        .map((_, index) => (
          <React.Fragment key={index}>
            <h2>Canvas Title {index + 1}</h2>
            <Input
              value={canvasValue.titles && canvasValue.titles[index]}
              onChange={(e) => handleEditCanvas({ ...canvasValue, title: e.target.value, index })}
              backgroundColor="white"
              placeholder="Add canvas title"
            />
            <Spacer />
          </React.Fragment>
        ))}
      <S.HorizontalDivider />
      <h2>Background Template</h2>
      <Select
        placeholder="Background Template"
        onChange={(v) => handleEditCanvas({ ...canvasValue, templateId: v as number })}
        value={canvasValue.templateId || 0}
      >
        <SelectOption value={0}>No Template</SelectOption>
        <SelectOption value={1}>Grid</SelectOption>
        <SelectOption value={2}>Grid with one quadrant</SelectOption>
        <SelectOption value={3}>Grid with four quadrants</SelectOption>
      </Select>
      <Spacer size={16} />
      <h2>Canvas Width</h2>
      <Input
        value={canvasValue.width}
        placeholder="Canvas Width (px)"
        backgroundColor="white"
        min={0}
        type="number"
        onChange={(e) => handleEditCanvas({ ...canvasValue, width: Number(e.target.value) })}
      />
      <Row justify="end">
        <AntdButton type="link" onClick={() => handleEditCanvas({ ...canvasValue, width: 1200 })}>
          Medium
        </AntdButton>
        <AntdButton type="link" onClick={() => handleEditCanvas({ ...canvasValue, width: 1600 })}>
          Large
        </AntdButton>
      </Row>
      <Spacer size={8} />
      <Row
        justify="space-between"
        align="middle"
        style={{ cursor: 'pointer' }}
        onClick={() => handleAddRemoveCanvasImageUrl()}
      >
        <h2>Images</h2>
      </Row>
      <Spacer size={8} />
      <UploadInput
        title="Upload Images from Computer"
        accept="image/*"
        beforeUpload={beforeUploadCanvasImages}
        defaultFileList={canvasValue?.files}
        fileList={canvasValue?.files}
        multiple
        onRemove={(m) => {
          handleAddRemoveCanvasImageUrl({ fileId: m.uid });
          return true;
        }}
        customRequest={(options) => {
          const file = options.file as RcFile;
          handleAddRemoveCanvasImageUrl({ file });
          return true;
        }}
      />
      <Spacer size={8} />
      {canvasValue?.imageUrls?.map((url, index) => (
        <Row justify="space-between" align="middle" gutter={[0, 24]}>
          <Col span={22}>
            <Input
              value={url}
              onChange={(e) => handleEditCanvasImageUrl({ url: e.target.value, index })}
              backgroundColor="white"
              placeholder="Add image url"
            />
          </Col>
          <FiMinusCircle
            size={20}
            onClick={() => handleAddRemoveCanvasImageUrl({ index })}
            style={{ cursor: 'pointer' }}
          />
        </Row>
      ))}
    </>
  );
};

export default CanvasBlockEditor;
