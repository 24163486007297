import styled from 'styled-components';
import CButton from '../../../shared/Button';

export const TitleInput = styled.p`
  font-weight: 400;
  font-size: 0.9em;
  margin: 5px 0;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.85em;
  margin: 0;
  margin-top: 16px;
  line-height: 1.2;
  text-align: center;
`;

export const ResetPassButton = styled(CButton)`
  font-weight: 500;
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: 800;
  font-size: 2em;
  @media (max-width: 1200px) {
    font-size: 1.8em;
  }
`;

export const LogoImage = styled.img`
  width: 100%;
  margin-bottom: 16px;
`;
