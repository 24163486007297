import styled from 'styled-components';
import Button from '../../shared/Button';

export const EditButton = styled(Button)`
  display: inline;
  line-height: 0;
  svg {
    font-size: 20px;
  }
`;
