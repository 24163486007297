import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../gql/schema';
import { launchAsync } from '@microsoft/immersive-reader-sdk';
import { useAuth } from './useAuth';

export interface ImmersiveReaderTokenResponse {
  token: string;
  expiresAt: string;
}

interface ImmersiveReaderContent {
  title?: string;
  chunks: {
    content: string;
    lang?: string;
    mimeType?: string;
  }[];
}

const useImmersiveReader = (payload: { investigationId: string }) => {
  const { investigationId } = payload;
  const { immersiveReaderToken, updateImmersiveReaderToken } = useAuth();
  const [getImmersiveReaderToken] = useMutation<{ getImmersiveReaderToken: ImmersiveReaderTokenResponse }>(
    gqlSchema.ImmersiveReaderSchema.mutations.getImmersiveReaderToken,
  );

  const { data } = useQuery(gqlSchema.ImmersiveReaderSchema.query.canAccessReader, {
    variables: {
      investigationId,
    },
    skip: !investigationId,
  });

  const launchImmersiveReader = async (content: ImmersiveReaderContent) => {
    if (!process.env.REACT_APP_AZURE_IMMERSIVE_READER_DOMAIN) return;

    let token = immersiveReaderToken?.token;
    const expiration = Number(immersiveReaderToken?.expiresAt ?? 0);

    if (!token || expiration * 1000 <= Date.now()) {
      const response = await getImmersiveReaderToken();
      if (response?.data?.getImmersiveReaderToken) {
        updateImmersiveReaderToken(response?.data?.getImmersiveReaderToken);
      }

      token = response?.data?.getImmersiveReaderToken?.token;
    }

    if (token)
      await launchAsync(token, "ImmersiveReaderADIProd-1", content, {
        uiZIndex: 9999999,
      });
  };

  return { launchImmersiveReader, canAccessReader: !!data?.canAccessReader };
};

export default useImmersiveReader;
