import styled from 'styled-components';
import { ContainerDragTarget } from './DragTarget/styles';

export const ContainerSortingBoard = styled.div`
  width: 100%;
  height: 622px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const DragTitle = styled.h1`
  text-align: center;
  font-size: 24px !important;
  font-weight: bold;
  margin-top: 16px;
  user-select: none;
`;

export const TitleInput = styled.p<{ alignRight?: boolean }>`
  font-weight: 800 !important;
  padding: 0;
  ${(props) => props.alignRight && 'text-align: right;'}
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const SubTitleContent = styled.p<{ alignRight?: boolean }>`
  font-size: 0.75rem;
  padding: 0;
  ${(props) => props.alignRight && 'text-align: right;'}
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const ContainerTargets = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;

  & > div:not(:last-child) {
    border-right: 1px solid #ccc;
  }
`;

export const ContainerDragObject = styled.div<{ beingDragged: boolean; $hasTarget: boolean }>`
  ${(props) =>
    props.$hasTarget &&
    `
    position: absolute;
    top: 0;
    left: 0;
  `}

  z-index: ${(props) => (props.beingDragged ? '1' : '0')};
  height: 36px;
  width: ${(props) => (props.$hasTarget ? 'calc(25% - 20px)' : '100%')};
  border-radius: 4px;
  background: white;
  margin-right: 8px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${(props) => (props.beingDragged ? 'grabbing' : 'grab')};
`;

export const ObjectContainers = styled.div<{ $minHeightOptions: number }>`
  min-height: ${(props) => props.$minHeightOptions}px;
  position: relative;
  margin-bottom: 8px;
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
`;

export const ProcessDragDropContainer = styled.div<{ $minHeightOptions: number }>`
  min-height: ${(props) => props.$minHeightOptions}px;
  display: flex;
  flex-direction: row;
  align-items: inherit;
`;

export const ProcessDragDropTaskTitle = styled.div`
  font-weight: 400 !important;
  padding: 0;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const ProcessDragDropContainerTitles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: inherit;
  justify-content: space-around;
  font-size: 0.75rem;
  padding: 0;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const ProcessTasksCol = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 50%;
  text-align: center;
  cursor: pointer;
`;

export const ProcessTasksTargetContainer = styled.div`
  position: relative;

  > ${ContainerDragTarget} {
    z-index: 0;
    height: 36px;
    width: 80%;
    border-radius: 4px;
    background: white;
    margin-right: 8px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    padding: 8px;
    white-space: nowrap;
    cursor: grab;
    margin: 5px auto;
  }

  &:after {
    border-right: solid 5px transparent;
    border-left: solid 5px transparent;
    border-top: solid 5px grey;
    transform: translateX(-36%);
    content: '';
    top: 45px;
    left: 50%;
    height: 0;
    width: 0;
    position: absolute;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;
