import Papa from 'papaparse';
import { GroupConfig } from '../types/class';
import { downloadCsv } from './files';

/**
 * Download template to import students
 */
export const onDownloadStudentTemplate = () => {
  downloadCsv(Papa.unparse([['First Name', 'Last Name', 'Email', 'Tags']]), 'template.csv');
};

/**
 * UI Config for class groups
 * TODO: REPLACE COLORS FOR THE REAL COLORS
 */
export const groupConfig: GroupConfig[] = [
  { group: 'A', color: '#3498DB' },
  { group: 'B', color: '#F1C40F' },
  { group: 'C', color: '#9B59B6' },
  { group: 'D', color: '#34495E' },
  { group: 'E', color: '#E67E22' },
  { group: 'F', color: '#1ABC9C' },
  { group: 'G', color: '#E74C3C' },
  { group: 'H', color: '#2ECC71' },
  { group: 'I', color: '#4834D4' },
  { group: 'J', color: '#30336B' },
];

/**
 * Get the gorups for a class based on the groups number
 * @param groups How many groups exist in a class
 */
export const getShrinkedGroups = (groups: number) => {
  return groupConfig.slice(0, groups > groupConfig.length ? groupConfig.length : groups);
};

/**
 * Get a GroupConfig based on the group name
 * @param group Group name
 */
export const getGroupConfig = (group: string) => {
  return groupConfig.find((gr: GroupConfig) => gr.group === group);
};
