import styled from 'styled-components';

export const SuccesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex-direction: column;
  text-align: center;

  button {
    margin-top: 30px;
  }

  img {
    margin-bottom: 30px;
    margin-top: 60px;
  }
`;
