import { useLazyQuery } from '@apollo/client';
import { Col, message, Modal, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useCallback } from 'react';
import { gqlSchema } from '../../../../gql/schema';
import { GQL_FacilitatorDetailsChartResponse, GQL_InvestigationAssignmentRateDetails } from '../../../../types/charts';
import { centerAlign } from '../../../../utils/antd';
import { formatDateTime } from '../../../../utils/date';
import { downloadCsv } from '../../../../utils/files';
import Button from '../../../Button';
import * as S from './styles';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  startDate: string;
  endDate: string;
  facilitatorId?: string;
}

const WorkshopParticipationDetail = (props: Props) => {
  const { visible, setVisible, startDate, endDate, facilitatorId } = props;
  const [getFacilitatorDetailsChart, { data, loading }] = useLazyQuery<{
    getFacilitatorDetailsChart: GQL_FacilitatorDetailsChartResponse;
  }>(gqlSchema.ChartSchema.queries.CHARTS.getFacilitatorDetailsChart, {
    onError: (err) => {
      message.error('There was an error loading workshop participation details: ' + err.message || 'Unexpected Error');
    },
  });

  const [fetchFacilitatorsStudents] = useLazyQuery<{ exportFacilitatorStudents: string }>(
    gqlSchema.ChartSchema.queries.EXPORT.exportFacilitatorStudents,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        downloadCsv(atob(data.exportFacilitatorStudents), "Facilitator's Participants.csv");
      },
      onError: (err) => {
        message.error('There was an error downloading facilitators info: ' + err.message);
      },
    },
  );

  const getFacilitatorsStudents = useCallback(() => {
    fetchFacilitatorsStudents({
      variables: { classIds: data?.getFacilitatorDetailsChart.classes.map((cls) => cls.id) },
    });
  }, [data, fetchFacilitatorsStudents]);

  useEffect(() => {
    if (facilitatorId) {
      getFacilitatorDetailsChart({
        variables: {
          data: {
            facilitatorId,
            startDate: startDate,
            endDate: endDate,
          },
        },
      });
    }
  }, [getFacilitatorDetailsChart, startDate, endDate, facilitatorId]);

  const columns: ColumnsType<GQL_InvestigationAssignmentRateDetails> = useMemo(() => {
    return [
      {
        title: 'Workshop',
        width: '60%',
        render: (text: string, record: GQL_InvestigationAssignmentRateDetails, index: number) => {
          return record.investigationTitle;
        },
      },
      {
        title: 'Start Date',
        width: '20%',
        align: centerAlign,
        render: (text: string, record: GQL_InvestigationAssignmentRateDetails, index: number) => {
          return formatDateTime(record.startDate);
        },
      },
      {
        title: 'End Date',
        width: '20%',
        align: centerAlign,
        render: (text: string, record: GQL_InvestigationAssignmentRateDetails, index: number) => {
          return formatDateTime(record.endDate);
        },
      },
    ];
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(!visible);
      }}
      footer={
        <Row justify="center">
          <Col span={8}>
            <Button text="Download Participants" onClick={() => getFacilitatorsStudents()} block />
          </Col>
        </Row>
      }
      width={900}
    >
      <S.Title>
        {data?.getFacilitatorDetailsChart
          ? `Participation Details for: ${data?.getFacilitatorDetailsChart.facilitatorName}`
          : 'Loading...'}
      </S.Title>
      <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        {data?.getFacilitatorDetailsChart.classes.map((cls) => (
          <S.Card
            key={cls.id}
            title={`Course: ${cls.name}`}
            extra={<S.TitleExtra>Participants: {cls.studentsCount}</S.TitleExtra>}
          >
            {cls.workshops.length > 0 ? (
              <Table
                columns={columns}
                bordered
                loading={loading}
                dataSource={cls.workshops}
                pagination={false}
                scroll={{ x: 'max-content' }}
                rowKey={(record) => record.investigationId}
                sticky
              />
            ) : (
              <S.TitleExtra>No Investigations Assigned</S.TitleExtra>
            )}
          </S.Card>
        ))}
      </div>
    </Modal>
  );
};

export default WorkshopParticipationDetail;
