import { Upload } from 'antd';
import styled from 'styled-components';

export const DeleteParagraphContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;

  svg {
    min-height: 20px;
    min-width: 20px;
    color: ${(props) => props.theme.error.background};
  }
`;

export const UploadOutsider = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`;

export const UploadContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  color: #3f3e38;
  font-size: 14px;
  align-items: center;
  position: relative;

  svg:nth-child(3) {
    position: absolute;
    right: 16px;
  }
`;

export const InputContainer = styled.div`
  max-width: 285px;
`;

export const LabelInputContainer = styled.div`
  font-size: 14px;
  max-width: 285px;
`;
