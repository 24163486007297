import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Col, Row, Form as FormAntd, Dropdown, Menu as AntdMenu, message } from 'antd';
import * as S from './styles';
import Form from '../../../shared/Form';
import { useForm } from 'antd/lib/form/Form';
import { GQL_ClassResponse, GQL_ClassUserOrInvite, GQL_StudentResponse } from '../../../types/class';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import { IoMdPaperPlane } from 'react-icons/io';
import { CgMoreVertical } from 'react-icons/cg';
import { BsClock } from 'react-icons/bs';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  classInfo?: GQL_ClassResponse;
  students?: GQL_StudentResponse[];
  onRemoveStudent?: (student: GQL_ClassUserOrInvite) => Promise<void>;
  onResendInvite: (tokens: string[]) => Promise<void>;
}

const ManageInvites: React.FC<Props> = (props) => {
  const { visible, setVisible, classInfo, students, onRemoveStudent, onResendInvite } = props;
  const [form] = useForm();

  const onCloseModal = () => {
    setVisible(false);
  };
  const [pendingInvites, setPendingInvites] = useState<GQL_ClassUserOrInvite[]>([]);

  useEffect(() => {
    if (classInfo) {
      const students = classInfo.students.filter((st: GQL_ClassUserOrInvite) => !st.userId);
      form.setFieldsValue({ invites: students });
      setPendingInvites(students);
    }
  }, [classInfo, form]);

  useEffect(() => {
    if (visible === true && pendingInvites.length === 0) {
      message.error('There are not pending invites');
      setVisible(false);
    }
  }, [visible, pendingInvites, setVisible]);

  useEffect(() => {
    if (students) {
      const stdts = students.reduce((result: GQL_ClassUserOrInvite[], st: GQL_StudentResponse) => {
        if (!result.some((s: GQL_ClassUserOrInvite) => s.email === st.email) && st.inviteId)
          result.push({
            userId: st.userId,
            inviteId: st.inviteId,
            email: st.email,
            firstName: st.firstName,
            lastName: st.lastName,
            group: '',
            emailSent: st.emailSent,
          });
        return result;
      }, []);
      form.setFieldsValue({ invites: stdts });
      setPendingInvites(stdts);
    }
  }, [students, form]);

  const onSendAllInvites = useCallback(() => {
    onResendInvite(
      pendingInvites?.reduce((result: string[], invite: GQL_ClassUserOrInvite) => {
        if (invite.inviteId) {
          result.push(invite.inviteId);
        }
        return result;
      }, []) || [],
    );
  }, [onResendInvite, pendingInvites]);

  const menu = useCallback(
    (student: GQL_ClassUserOrInvite) => {
      return (
        <AntdMenu>
          <AntdMenu.Item
            onClick={() => {
              onResendInvite([student?.inviteId || '']);
            }}
          >
            {student?.emailSent ? 'Resend Invite' : 'Send Invite'}
          </AntdMenu.Item>
          {onRemoveStudent && (
            <>
              <AntdMenu.Divider />
              <AntdMenu.Item
                onClick={() => {
                  onRemoveStudent(student);
                }}
              >
                Remove Invite
              </AntdMenu.Item>
            </>
          )}
        </AntdMenu>
      );
    },
    [onRemoveStudent, onResendInvite],
  );

  if (pendingInvites.length === 0) return null;

  return pendingInvites ? (
    <S.CModal
      visible={visible}
      title={
        <Row style={{ marginTop: 26 }} justify="center">
          <Col span={24}>
            <S.Title>Manage Your Pending Invites</S.Title>
          </Col>
        </Row>
      }
      onCancel={onCloseModal}
      width={900}
      footer={null}
    >
      <Row justify="center">
        <Col
          span={24}
          style={{
            minHeight: '25vh',
            maxHeight: '40vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Form form={form} style={{ marginTop: 30 }} initialValues={{ invites: pendingInvites }}>
            <FormAntd.List name="invites">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.length > 0 && (
                    <Row gutter={8}>
                      <Col span={5}>
                        <S.TitleInput>First Name</S.TitleInput>
                      </Col>
                      <Col span={5}>
                        <S.TitleInput>Last Name</S.TitleInput>
                      </Col>
                      <Col span={9}>
                        <S.TitleInput>Student Email</S.TitleInput>
                      </Col>
                      <Col span={4}>
                        <S.TitleInput>Status</S.TitleInput>
                      </Col>
                      <Col span={1}></Col>
                    </Row>
                  )}
                  {fields.map((field, index) => (
                    <Row gutter={8} key={index}>
                      <Col span={5}>
                        <FormAntd.Item
                          {...field}
                          name={[field.name, 'firstName']}
                          fieldKey={[field.fieldKey, 'firstName']}
                        >
                          <Input disabled />
                        </FormAntd.Item>
                      </Col>
                      <Col span={5}>
                        <FormAntd.Item
                          {...field}
                          name={[field.name, 'lastName']}
                          fieldKey={[field.fieldKey, 'lastName']}
                        >
                          <Input disabled />
                        </FormAntd.Item>
                      </Col>
                      <Col span={9}>
                        <FormAntd.Item {...field} name={[field.name, 'email']} fieldKey={[field.fieldKey, 'email']}>
                          <Input disabled />
                        </FormAntd.Item>
                      </Col>

                      <Col span={4}>
                        <S.Status $emailSent={pendingInvites[field.key]?.emailSent}>
                          {pendingInvites[field.key]?.emailSent ? <BsClock /> : <IoMdPaperPlane />}
                          {pendingInvites[field.key]?.emailSent ? 'Pending' : 'Not Sent'}
                        </S.Status>
                      </Col>
                      <Col span={1}>
                        <Dropdown overlay={menu(pendingInvites[field.key])} placement="bottomRight" arrow>
                          <S.MenuIcon
                            icon={<CgMoreVertical data-cy={`components-classdashboard-manageinvites-${field.name}`} />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </FormAntd.List>
          </Form>
        </Col>
        <Col span={7} style={{ margin: '25px 0' }}>
          <Button onClick={onSendAllInvites} text="Send All Invites" block />
        </Col>
      </Row>
    </S.CModal>
  ) : null;
};

export default ManageInvites;
