import { Col, Popover, Row } from 'antd';
import styled from 'styled-components';

export const Column = styled(Col)`
  padding: 25px 30px !important;
  background-color: white;
  border-radius: 6px;
  text-align: left;
  & > div:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const RowSubQuestion = styled(Row)<{
  $isValid?: boolean;
}>`
  cursor: pointer;
  border-bottom: ${(props) => (props.$isValid ? 'none' : `1px ${props.theme.error.background} solid`)};
  &:hover {
    border-bottom: 1px ${(props) => (props.$isValid ? '#eaeaea' : props.theme.error.background)} solid;
  }
`;

export const QuestionContainer = styled.div`
  .mb-20 {
    margin-bottom: 20px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  & > button {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const RadioColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-radio-wrapper {
    font-weight: 500;
    color: #767676;
    margin-top: 10px;
  }

  .ant-radio-wrapper-checked {
    color: ${(props) => props.theme.secondaryColor.background};
  }

  .ant-radio-inner {
    border-radius: 4px;
  }

  .ant-radio-checked > .ant-radio-inner {
    background-color: ${(props) => props.theme.secondaryColor.background};
    border-color: ${(props) => props.theme.secondaryColor.background};
    border-radius: 4px;

    &:after {
      border-radius: 0;
      background-color: transparent;
      border: 2px solid white;
      border-top: 0px;
      border-left: 0px;
      height: 70%;
      width: 40%;
      display: inline-block;
      -ms-transform: translateY(-3px) rotate(45deg); /* IE 9 */
      -webkit-transform: translateY(-3px) rotate(45deg); /* Chrome, Safari, Opera */
      transform: translateY(-2px) translateX(1px) rotate(45deg);
    }
  }
`;

export const QuestionTitle = styled.h1`
  font-weight: bold;
  font-size: 18px !important;
  margin-bottom: 0px;
  line-height: 1.2;
`;

export const SubQuestionText = styled.p`
  font-weight: 500;
  font-size: 15px !important;
  line-height: 1.2;
  padding: 5px;
  margin: 7px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const GradeSystemText = styled.p`
  margin-left: 4px !important;
  font-weight: 600;
  font-size: 0.5em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const GradeSystemPopover = styled(Popover)``;

export const GradeSystemUL = styled.ul`
  margin: 0;
  padding-left: 0;
`;

export const GradeSystemLI = styled.li`
  display: flex;
  align-items: center;
  gap: 4px !important;
  list-style: none;
  padding-left: 0;
  margin-bottom: 4px;
`;
