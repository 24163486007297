import styled from 'styled-components';

export const Container = styled.div`
  padding: 4em 12em;
`;

export const Title = styled.h1`
  text-align: left;
  margin-bottom: 15px;
  font-weight: 600;
`;

export const WarningCard = styled.div<{ $color?: string }>`
  widht: 100% !important;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.$color || '#c62d2d'};
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    font-size: 20px;
    height: 100%;
    width: 40px;
  }
`;
