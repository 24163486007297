import styled from 'styled-components';

export const Tag = styled.div<{
  $color: string;
  onClick?: () => void;
  $maxWidth?: number;
}>`
  background: ${(props) => props.$color};
  color: white;
  padding: 5px 25px;
  font-weight: 500;
  border-radius: 40px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth}px;`}
  ${(props) => props.onClick && 'cursor: pointer;'}
`;
