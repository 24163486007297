import { gql } from '@apollo/client';


const GCLASSROOM_COURSES = {
  GOOGLEDASHBOARD: {
    GetCoursesList: gql`
    query GetCoursesList{
    getCoursesList {
        id
        name
        section
        descriptionHeading
        description
        room
        ownerId
        creationTime
        updateTime
        enrollmentCode
        courseState
        alternateLink
        teacherGroupEmail
        courseGroupEmail
        guardiansEnabled
        calendarId
        teacherFolder {
            id
            title
            alternateLink
        }
        gradebookSettings {
            calculationType
            displaySetting
            gradeCategories {
                id
                name
                weight
                defaultGradeDenominator
            }
        }
    }
}   
  `,
  GetCourseWorkList: gql`
    query GetCourseWorkList($courseId:String!,$data:String!) {
    getCourseWorkList(
      courseId: $courseId
        data: $data
    ) {
        courseId
        id
        title
        description
        state
        alternateLink
        creationTime
        updateTime
        scheduledTime
        maxPoints
        workType
        associatedWithDeveloper
        assigneeMode
        submissionModificationMode
        creatorUserId
        topicId
        details {
            ... on Assignment {
                studentWorkFolder {
                    id
                    title
                    alternateLink
                    thumbnailUrl
                }
            }
            ... on MultipleChoiceQuestion {
                choices
            }
        }
        materials {
            form {
                formUrl
                title
                thumbnailUrl
            }
            link {
                url
                title
                thumbnailUrl
            }
            sharedDriveFile {
                shareMode
                driveFile {
                    id
                    title
                    alternateLink
                    thumbnailUrl
                }
            }
            youtubeVideo {
                id
                title
                alternateLink
                thumbnailUrl
            }
        }
        dueDate {
            year
            month
            day
        }
        dueTime {
            hours
            minutes
            seconds
            nanos
        }
        individualStudentsOptions {
            studentIds
        }
        gradeCategory {
            id
            name
            weight
            defaultGradeDenominator
        }
        assignment {
            studentWorkFolder {
                id
            }
        }
    }
}
    
    `,
    GetCourseWorkById: gql`
    query GetCourseWorkById($courseId:String!,$id:String!) {
        getCourseWorkById(
            courseId: $courseId,
            id: $id
        ) {
            courseId
            id
            title
            description
            state
            alternateLink
            creationTime
            updateTime
            scheduledTime
            maxPoints
            workType
            associatedWithDeveloper
            assigneeMode
            submissionModificationMode
            creatorUserId
            topicId
            details {
            ... on Assignment {
            studentWorkFolder {
            id
            title
            alternateLink
            thumbnailUrl
            }
            }
            ... on MultipleChoiceQuestion {
                choices
            }
        }
        materials {
            sharedDriveFile {
                shareMode
                driveFile {
                    id
                    title
                    alternateLink
                    thumbnailUrl
                }
            }
            youtubeVideo {
                id
                title
                alternateLink
                thumbnailUrl
            }
            link {
                url
                title
                thumbnailUrl
            }
            form {
                formUrl
                title
                thumbnailUrl
            }
        }
        dueDate {
            year
            month
            day
        }
        dueTime {
            hours
            minutes
            seconds
            nanos
        }
        individualStudentsOptions {
            studentIds
        }
        gradeCategory {
            id
            name
            weight
            defaultGradeDenominator
        }
        assignment {
            studentWorkFolder {
                id
            }
        }
    }
}

        
    `,
    GetCourseWorkSubmissionList: gql`
   query GetCourseWorkSubmissionList($data:[SubmissionArrayData]) {
    getCourseWorkSubmissionList(
       data:$data
    ) {
        courseId
        courseWorkId
        studentSubmissions {
            courseId
            id
            userId
            creationTime
            updateTime
            courseWorkId
            state
            late
            courseWorkType
            draftGrade
            assignedGrade
            rubricId
            alternateLink
            courseName
            AssignmentName
            userName
            submissionHistory {
                gradeHistory {
                    pointsEarned
                    maxPoints
                    gradeTimestamp
                    actorUserId
                    gradeChangeType
                }
                stateHistory {
                    state
                    stateTimestamp
                    actorUserId
                }
            }
            assignmentSubmission {
                attachments {
                    sharedDriveFile {
                        shareMode
                        driveFile {
                            id
                            title
                            alternateLink
                            thumbnailUrl
                        }
                    }
                    youtubeVideo {
                        id
                        title
                        alternateLink
                        thumbnailUrl
                    }
                    link {
                        url
                        title
                        thumbnailUrl
                    }
                    form {
                        formUrl
                        title
                        thumbnailUrl
                    }
                }
            }
        }
    }
}
    `,
    GetCourseByCourseId: gql`
    query GetCourseByCourseId($courseId:String!) {
        getCourseByCourseId(courseId: $courseId) {
            id
            name
            section
            descriptionHeading
            description
            room
            ownerId
            creationTime
            updateTime
            enrollmentCode
            courseState
            alternateLink
            teacherGroupEmail
            courseGroupEmail
            guardiansEnabled
            calendarId
            teacherFolder {
                id
                title
                alternateLink
            }
            gradebookSettings {
                calculationType
                displaySetting
                gradeCategories {
                    id
                    name
                    weight
                    defaultGradeDenominator
                }
            }
        }
    }
    
    `,
    GetCourseStudents: gql`
    query GetCourseStudents($courseId:String) {
        getCourseStudents(
            courseId: $courseId
        ) {
            numberOfStudents
            students {
                courseId
                userId
                profile {
                    id
                    emailAddress
                    photoUrl
                    permissions {
                        permission
                    }
                    name {
                        givenName
                        familyName
                        fullName
                    }
                }
                driveFolder {
                    id
                    title
                    alternateLink
                }
            }
        }
    }    
    `,
    GetCourseTeachers: gql`
    query GetCourseTeachers($courseId:String) {
        getCourseTeachers(       
            courseId: $courseId
        ) {
            teachers {
                courseId
                userId
                profile {
                    id
                    emailAddress
                    photoUrl
                    name {
                        givenName
                        familyName
                        fullName
                    }
                }
            }
            TotalTeacher
        }
    }    
    
    `,
    GetCourseWorkSubmissionById: gql`
    query GetCourseWorkSubmissionById($courseId:String,$courseWorkId:String,$id:String) {
        getCourseWorkSubmissionById(
            courseId: $courseId,        
            courseWorkId: $courseWorkId,
            id:$id
        ) {
            courseId
            id
            userId
            courseWorkId
            state
            late
            courseWorkType
            draftGrade
            assignedGrade
            rubricId
            alternateLink
            courseName
            AssignmentName
            shortAnswerSubmission {
                answer
            }
            multipleChoiceSubmission {
                choices
            }
            submissionHistory {
                stateHistory {
                    state
                    stateTimestamp
                    actorUserId
                }
                gradeHistory {
                    pointsEarned
                    maxPoints
                    gradeTimestamp
                    actorUserId
                    gradeChangeType
                }
            }
            assignmentSubmission {
                attachments {
                    link {
                        url
                        title
                        thumbnailUrl
                    }
                }
            }
        }
    }
    
    `,
    GetCoursesListWithCourseWork:gql`
    query GetCoursesListWithCourseWork {
    getCoursesListWithCourseWork {
        id
        name
        section
        descriptionHeading
        description
        room
        ownerId
        creationTime
        updateTime
        enrollmentCode
        courseState
        alternateLink
        teacherGroupEmail
        courseGroupEmail
        guardiansEnabled
        calendarId
        coursework {
            courseId
            id
            title
            state
            alternateLink
            creationTime
            updateTime
            scheduledTime
            maxPoints
            workType
            associatedWithDeveloper
            assigneeMode
            submissionModificationMode
            creatorUserId
            topicId
            materials {
                sharedDriveFile {
                    shareMode
                    driveFile {
                        id
                        title
                        alternateLink
                        thumbnailUrl
                    }
                }
                youtubeVideo {
                    id
                    title
                    alternateLink
                    thumbnailUrl
                }
                link {
                    url
                    title
                    thumbnailUrl
                }
                form {
                    formUrl
                    title
                    thumbnailUrl
                }
            }
            dueDate {
                year
                month
                day
            }
            dueTime {
                hours
                minutes
                seconds
                nanos
            }
            gradeCategory {
                id
                name
                weight
                defaultGradeDenominator
            }
            details {
                ... on Assignment {
                    studentWorkFolder {
                        id
                        title
                        alternateLink
                        thumbnailUrl
                    }
                }
                ... on MultipleChoiceQuestion {
                    choices
                }
            }
            assignment {
                studentWorkFolder {
                    id
                }
            }
        }
    }
}

    `,
    GetCourseWorkListByDueDate:gql`
    query GetCourseWorkListByDueDate($courseId:[String]!,$data:String!) {
    getCourseWorkListByDueDate(
      courseId: $courseId
        data: $data
    ) {
        activeCourseWork {
            courseId
            id
            title
            description
            state
            alternateLink
            creationTime
            updateTime
            scheduledTime
            maxPoints
            workType
            associatedWithDeveloper
            assigneeMode
            submissionModificationMode
            creatorUserId
            topicId
            dueDate {
                year
                month
                day
            }
            dueTime {
                hours
                minutes
                seconds
                nanos
            }
        }
        expiredCourseWork {
            courseId
            id
            title
            state
            description
            alternateLink
            creationTime
            updateTime
            scheduledTime
            maxPoints
            workType
            associatedWithDeveloper
            assigneeMode
            submissionModificationMode
            creatorUserId
            topicId
            dueDate {
                year
                month
                day
            }
            dueTime {
                hours
                minutes
                seconds
                nanos
            }
        }
    }
}

    `,
    GetCourseWorkSubmissionListByTurnedIn:gql`
    query GetCourseWorkSubmissionListByTurnedIn($courseId:[String]) {
    getCourseWorkSubmissionListByTurnedIn(
        courseId: $courseId
    ) {
        activeSubmissionDashboard {
            courseId
            courseWorkId
            id
            userId
            title
            state
            alternateLink
            courseWorkType
            userName
            assignedGrade
            late
        }
        expiredSubmissionDashboard {
            courseId
            courseWorkId
            id
            userId
            title
            state
            alternateLink
            courseWorkType
            userName
            assignedGrade
            late
        }
    }
}

    `,
    GetCourseStudentsTurnedIn:gql`
    query GetCourseStudentsTurnedIn($expiredCourseWork:[expiredCourseWorkData]) {
    getCourseStudentsTurnedIn(expiredCourseWork:$expiredCourseWork
        
    ) {
        id
        courseId
        complete
        totalStudent
    }
}

    `
  },
}
export default GCLASSROOM_COURSES;
