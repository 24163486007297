import { Col, message, Row, Tabs } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import SelectOption from '../../../shared/Select/Option';
import * as S from './styles';
import { useMutation } from '@apollo/client';
import { GQL_UserDetailsResponse } from '../../../types/profile';
import { GQL_OrganizationAdminList, GQL_OrganizationDetailsResponse } from '../../../types/organization';
import { gqlSchema } from '../../../gql/schema';
import GenericUser from '../../../shared/AddUser/GenericUser';

interface Props {
  admin?: GQL_OrganizationAdminList;
  organization?: GQL_OrganizationDetailsResponse;
  visibile?: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSetOrganizationAdmin: () => void;
  refetchOrganization: () => void;
  teachers?: GQL_UserDetailsResponse[];
}
const { TabPane } = Tabs;

const AddAdminModal = (props: Props) => {
  const { visibile, setVisible, organization, refetchOrganization, teachers } = props;
  const [activeKey, setActiveKey] = useState('new-user');
  const [selectedTeacher, setSelectedTeacher] = useState<GQL_UserDetailsResponse | undefined>();

  const [submitSetOrganizationAdmin] = useMutation(gqlSchema.OrganizationSchema.mutations.EDIT.setOrganizationAdmin, {
    onCompleted: ({ setOrganizationAdmin }: { setOrganizationAdmin: boolean }) => {
      if (setOrganizationAdmin) {
        message.success(`${selectedTeacher?.name} was settled as organization admin successfully`);
        refetchOrganization();
        setSelectedTeacher(undefined);
        setActiveKey('new-user');
        setVisible(false);
      } else message.error(`Error settling ${selectedTeacher?.name} as organization admin`);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <S.ChangeAdminModal
      title={null}
      visible={visibile}
      footer={null}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      closable
    >
      <S.Title>Add Organization Admin</S.Title>
      <S.Info>Add a new organization admin for {organization?.name}</S.Info>
      <S.Info>by selecting a teacher or creating a new user.</S.Info>
      <Row gutter={24} style={{ marginTop: 30 }} justify="center">
        <Col span={24}>
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={(activeKey: string) => setActiveKey(activeKey)}
          >
            <TabPane tab={<S.TitleTab>New User</S.TitleTab>} key="new-user">
              <Row justify="center" style={{ marginTop: 10 }}>
                <Col span={24}>
                  <GenericUser
                    showTitle={false}
                    role="organization_admin"
                    buttonTitle="Organization Admin"
                    onUserCreated={() => {
                      refetchOrganization();
                      setVisible(false);
                    }}
                    organizationId={organization?.id}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={<S.TitleTab>From a Teacher</S.TitleTab>} key="teachers">
              <Row justify="start" gutter={[0, 8]} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <h3 style={{ marginBottom: 0 }}>Find a Teacher</h3>
                </Col>

                <Col span={24}>
                  <Row gutter={8}>
                    <Col span={18}>
                      <S.ChangeAdminSelect
                        showSearch
                        style={{ width: '100%' }}
                        allowClear
                        value={selectedTeacher ? selectedTeacher.id : undefined}
                        suffixItemOnLeft
                        suffixIcon={<FiSearch />}
                        filterOption={(input, option) => {
                          if (option?.children)
                            return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          return false;
                        }}
                        onSelect={(v) => setSelectedTeacher(teachers?.find((teacher) => teacher.id === (v as string)))}
                        onClear={() => setSelectedTeacher(undefined)}
                        placeholder="Start typing to search..."
                      >
                        {teachers
                          ?.filter(
                            (teacher: GQL_UserDetailsResponse) =>
                              !organization?.organizationAdmins.find(
                                (admin: GQL_OrganizationAdminList) => admin.userId === teacher.id,
                              ),
                          )
                          .map((teacher: GQL_UserDetailsResponse) => (
                            <SelectOption value={teacher.id} key={teacher.id}>
                              {teacher.name}
                            </SelectOption>
                          ))}
                      </S.ChangeAdminSelect>
                    </Col>
                    <Col span={6}>
                      <S.SetTeacherButton
                        block
                        text="Set Teacher as Admin"
                        loading={false}
                        disabled={!selectedTeacher}
                        onClick={() => {
                          submitSetOrganizationAdmin({ variables: { userId: selectedTeacher?.id } });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </S.ChangeAdminModal>
  );
};

export default AddAdminModal;
