import { Col, Row } from 'antd';
import styled from 'styled-components';

export const Column = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  & > p,
  h1,
  h2 {
    text-align: center;
  }

  & > button {
    margin-top: 30px;
  }
`;

export const CommentBalloon = styled.div<{
  xPos: number;
  yPos: number;
  selected: boolean;
}>`
  z-index: 1;
  cursor: pointer;
  height: 35px;
  width: 35px;
  opacity: 75%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  background: ${(props) => (props.selected ? '#366BDF' : '#31CF2E')};
  position: absolute;
  left: ${(props) => props.xPos}px;
  top: ${(props) => props.yPos}px;
`;

export const BarRow = styled(Row)`
  position: relative;
  margin-top: 24px;
  z-index: 1;
`;
