import React from 'react';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Col, message } from 'antd';

import * as S from './styles';
import { gqlSchema } from '../../gql/schema';
import { GQL_InvestigationDisplay } from '../../types/investigation';
import InvestigationDetailsLoading from './InvestigationDetailsLoading';
import InvestigationBook from '../../shared/InvestigationBook';

type Props = RouteComponentProps<
  {
    classId: string;
    type: string;
    investigationId: string;
  },
  any,
  {
    completedPreview?: boolean;
  }
>;

const InvestigationDetailsPage: React.FC<Props> = (props) => {
  const { classId, type, investigationId } = props.match.params;

  const history = useHistory();
  const location = useLocation();

  const backUrl = classId
    ? `/teacher-dashboard/class/${classId}/assign-investigation/${type}`
    : `/teacher-investigation/${type}`;

  const { data, loading } = useQuery<
    { getInvestigationCatalogById: GQL_InvestigationDisplay },
    { investigationId: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalogById, {
    variables: {
      investigationId: investigationId,
    },
    onError: (err) => {
      message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
      history.push(backUrl);
    },
  });

  if (loading) return <InvestigationDetailsLoading />;

  const investigation = data?.getInvestigationCatalogById;

  const handleAssign = () => {
    history.push(location.pathname + '/assign');
  };

  const handlePreview = () => {
    history.push(`/investigation-presentation/${investigation?.id}`, {
      backUrl: location.pathname,
      bypassPresentationMode: true,
    });
  };

  const handleGoBack = () => {
    history.goBack();
  };

  if (!investigation) {
    history.push(backUrl);
    return <InvestigationDetailsLoading />;
  }

  return (
    <S.PageContainer justify="center">
      <Col xs={22} md={18} lg={16}>
        <InvestigationBook
          handlePreview={handlePreview}
          handleAssign={handleAssign}
          handleGoBack={handleGoBack}
          stages={investigation.steps}
          investigationId={investigationId}
          investigationName={investigation.title}
          investigationDescription={investigation.description}
          investigationExplanation={investigation.explanation}
          subject={investigation.discipline?.subject}
          gradeBand={investigation.discipline?.gradeBand}
          discipline={investigation.discipline?.name}
          disciplineId={investigation.discipline?.id}
          imageSrc={investigation.imageUrl}
          standardsUrl={investigation.standardsUrl}
          suppliesUrl={investigation.suppliesUrl}
          letterHomeUrl={investigation.letterHomeUrl}
          availableForTrial={investigation.availableForTrial}
        />
      </Col>
    </S.PageContainer>
  );
};

export default withRouter(InvestigationDetailsPage);
