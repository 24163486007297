import { Col, Dropdown, Menu, Row, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { FiChevronDown, FiDownload } from 'react-icons/fi';
import Papa from 'papaparse';

import { InsightsData } from '../InvestigationInsight';
import InsightsFilterTypes from '../InsightsType';
import Button from '../../../shared/Button';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import { GQL_ClassResponse, GQL_ClassUserOrInvite } from '../../../types/class';
import { downloadCsv } from '../../../utils/files';
import { themeConfig } from '../../../utils/theme';
import Filter from '../../../shared/Charts/Filter';
import { FilterData } from '../../../types/investigation';
import { InsightsType } from './../../../constants/enums/InsightsTypes';
import Spacer from '../../../shared/Spacer';
import * as S from './styles';

interface IInsightsFilters {
  insightType: InsightsType;
  loadingClasses?: boolean;
  loadingStudents?: boolean;
  classesData?: GQL_ClassResponse[];
  studentsData?: GQL_ClassUserOrInvite[];
  selectedClassId?: string;
  setSelectedClassId: (id: string) => void;
  selectedStudentId?: string;
  setSelectedStudentId: (id: string) => void;
  selectedInvestigationIds: string[];
  setSelectedInvestigationIds: (id: string[]) => void;
  insightsData: InsightsData[];
  comparingInvestigations?: boolean;
  setComparingInvestigations: (comparing: boolean) => void;
  canCompareInvestigations?: boolean;
  onChangeFilterData?: (data: FilterData) => void;
  onExportTempranoData?: () => void;
}

const InsightsFilters = (props: IInsightsFilters) => {
  const {
    insightType,
    classesData,
    loadingClasses,
    loadingStudents,
    setSelectedClassId,
    selectedClassId,
    studentsData,
    selectedStudentId,
    setSelectedStudentId,
    selectedInvestigationIds,
    setSelectedInvestigationIds,
    insightsData,
    comparingInvestigations,
    setComparingInvestigations,
    canCompareInvestigations,
    onChangeFilterData,
    onExportTempranoData,
  } = props;

  const exportTableData = useCallback(() => {
    const headers = [
      ['Investigation', 'Raw Report Score (out of 38)', 'Percentage Report Score', 'Engagement Score (out of 5)'],
    ];
    const data =
      insightsData?.map((investigation) => {
        return [
          investigation.investigationTitle,
          investigation.reportScore?.toFixed(2),
          Math.round(investigation.reportAverage * 100) + '%',
          (investigation.engagementAverage * 5)?.toFixed(1),
        ];
      }) || [];

    downloadCsv(Papa.unparse([...headers, ...data]), `InvestigationGradeBook.csv`);
  }, [insightsData]);

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 14 }}>
        <Col md={8} xl={4} xxl={3}>
          <InsightsFilterTypes insightType={insightType} />
        </Col>
        <Col md={10} xl={6} xxl={4}>
          <Select
            placeholder="Select Class"
            showSearch
            value={selectedClassId}
            filterOption={(input, option) =>
              option?.name?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
            }
            onChange={(v) => setSelectedClassId(v as string)}
            loading={loadingClasses}
          >
            {classesData?.map((classObj) => (
              <SelectOption value={classObj.id} key={classObj.id} name={classObj.name}>
                {classObj.name}
              </SelectOption>
            ))}
          </Select>
        </Col>
        <Col md={10} xl={6} xxl={4}>
          <Select
            placeholder={`Select ${insightType}`}
            showSearch
            mode="multiple"
            filterOption={(input, option) =>
              option?.name?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
            }
            onChange={(v) => setSelectedInvestigationIds(v as string[])}
            disabled={!selectedClassId}
          >
            {insightsData?.map((insightObject) => (
              <SelectOption
                value={insightObject.investigationId}
                key={insightObject.investigationId}
                name={insightObject.investigationTitle}
                disabled={
                  selectedInvestigationIds.length === 2
                    ? !selectedInvestigationIds.includes(insightObject.investigationId)
                    : false
                }
              >
                {insightObject.investigationTitle}
              </SelectOption>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={6} xl={4} xxl={4}>
          <Select
            placeholder="Select Student"
            showSearch
            filterOption={(input, option) =>
              option?.name?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
            }
            value={selectedStudentId}
            onChange={(v) => setSelectedStudentId(v as string)}
            loading={loadingStudents}
            disabled={!selectedClassId}
          >
            {studentsData?.map((studentObj) => (
              <SelectOption
                value={studentObj.userId || ''}
                key={studentObj.userId}
                name={studentObj.firstName + ' ' + studentObj.lastName}
              >
                {studentObj.firstName + ' ' + studentObj.lastName}
              </SelectOption>
            ))}
          </Select>
        </Col>
        <Col md={8} xl={12} xxl={12}>
          <Filter
            showDateRangeFilter={false}
            showGradeFilter={false}
            showSubjectFilter
            onChangeFilterData={onChangeFilterData}
          />
        </Col>
        <Col md={10} xl={8} xxl={8}>
          <Row justify="end" style={{ width: '100%' }}>
            <Dropdown
              overlay={
                <S.CMenu>
                  <Menu.Item disabled={!insightsData} onClick={exportTableData}>
                    Export Gradebook Data
                  </Menu.Item>
                  <Menu.Item disabled={!insightsData} onClick={onExportTempranoData}>
                    Export Class Data
                  </Menu.Item>
                </S.CMenu>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <S.DownloadContainer>
                <FiDownload />
                <span role="none" >Export Data</span>
                <FiChevronDown />
              </S.DownloadContainer>
            </Dropdown>

            <Spacer axis="horizontal" />
            <Tooltip
              title={
                canCompareInvestigations
                  ? undefined
                  : 'Please select 2 investigations in the investigations filter to enable comparing'
              }
            >
              <div>
                <Button
                  text={comparingInvestigations ? 'Stop Comparing' : 'Compare Investigations'}
                  onClick={() => setComparingInvestigations(!comparingInvestigations)}
                  minHeight={40}
                  disabled={!canCompareInvestigations}
                  theme={themeConfig.secondaryColor}
                />
              </div>
            </Tooltip>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InsightsFilters;
