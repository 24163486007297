import { PureQueryOptions, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { MdCancel } from 'react-icons/md';
import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';
import { Filter } from '../../utils/antd';
import { roles } from '../../utils/roles';
import Button from '../Button';
import RoundedTag from '../RoundedTag';
import Select from '../Select';
import SelectOption from '../Select/Option';
import Spacer from '../Spacer';
import * as S from './styles';

interface IRoleEditorInput {
  userId?: string;
  existingRoles?: Filter[];
  queriesToRefetch?: (string | PureQueryOptions)[];
  editable?: boolean;
  className?: string;
  style?: React.CSSProperties;
  maxTagCount?: number;
  alwaysEditable?: boolean;
  /** Default: 0 **/
  marginTop?: number;
  height?: number;
  backgroundColor?: string;
  onChange?: (roles: Filter[]) => void;
}

const RoleEditorInput = (props: IRoleEditorInput) => {
  const {
    userId,
    existingRoles,
    marginTop = 0,
    editable = true,
    className,
    style,
    maxTagCount,
    alwaysEditable,
    height,
    backgroundColor,
    onChange,
  } = props;
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [editingRoles, setEditingRoles] = useState(!!alwaysEditable);
  const { isAdiAdmin, isAdiSuperAdmin } = useAuth();

  useEffect(() => {
    setSelectedRoles(existingRoles?.map((role) => role.value) || []);
  }, [existingRoles]);

  const [editUserRoles] = useMutation<any, { data: { userId: string; roles: string[] } }>(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.editUserRoles,
    {
      refetchQueries: [
        {
          query: gqlSchema.ClassSchema.query.CLASS.CLASSES.getUserDetails,
          variables: { data: { id: userId } },
        },
      ],
    },
  );

  const handleChangeEditingMode = () => {
    if (editingRoles && userId) {
      editUserRoles({
        variables: {
          data: { roles: selectedRoles, userId },
        },
      });
    }

    setEditingRoles(!editingRoles);
  };

  const newRoles = existingRoles?.filter((e) => !e.value) || [];
  const hasEditablePermission = editable && (isAdiAdmin || isAdiSuperAdmin);
  const rolesThatCantBeEdited = ['student', 'organization_admin', 'writer', 'teacher_assistant'];

  return (
    <S.TagsContainer marginTop={marginTop} className={className} style={style}>
      <div>
        {editingRoles || alwaysEditable ? (
          <Select
            data-cy="shared-roleeditorinput-roles-select"
            placeholder="Tags"
            optionLabelProp="name"
            showSearch={true}
            dropdownMatchSelectWidth={false}
            maxTagTextLength={8}
            maxTagCount={maxTagCount}
            mode="multiple"
            showArrow
            value={selectedRoles}
            height={height}
            backgroundColor={backgroundColor}
            onChange={(v) => {
              setSelectedRoles(v as string[]);
              onChange?.(
                (v as string[]).map((t) => roles.find((e) => e.value === t))?.flatMap((v) => (v ? v : [])) || [],
              );
            }}
            filterOption={(input, option) =>
              option?.name?.toString()?.toLocaleLowerCase()?.includes(input?.toLocaleLowerCase()) ?? false
            }
          >
            {[...newRoles, ...(roles || [])]?.map((roleItem) => {
              return (
                <SelectOption
                  value={roleItem?.value}
                  name={roleItem?.text}
                  key={roleItem?.value}
                  disabled={rolesThatCantBeEdited.includes(roleItem?.value)}
                >
                  <S.ItemContainer>
                    <S.TagColor color={roleItem.color || '#7B8CEA'} />
                    <Spacer axis="horizontal" size={8} />
                    {roleItem?.text}
                  </S.ItemContainer>
                </SelectOption>
              );
            })}
          </Select>
        ) : existingRoles?.length ? (
          existingRoles?.map((role) => (
            <RoundedTag
              maxWidth={160}
              color={role.color || '#7B8CEA'}
              text={role.text}
              key={role.value}
              className="spaced"
            />
          ))
        ) : (
          <p>No Roles</p>
        )}
      </div>
      {hasEditablePermission && editable && !alwaysEditable && (
        <>
          <Spacer axis="horizontal" size={4} />
          <Button
            data-cy="shared-roleeditorinput-edit-button"
            type="primary"
            shape="circle"
            minHeight={32}
            minWidth={32}
            icon={editingRoles ? <BiCheck /> : <BiEdit />}
            onClick={handleChangeEditingMode}
          />

          {editingRoles && (
            <>
              <Spacer axis="horizontal" size={4} />
              <Button
                data-cy="shared-roleeditorinput-cancel-button"
                type="primary"
                shape="circle"
                minHeight={32}
                minWidth={32}
                icon={<MdCancel />}
                onClick={() => setEditingRoles(false)}
              />
            </>
          )}
        </>
      )}
    </S.TagsContainer>
  );
};

export default RoleEditorInput;
