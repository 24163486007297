import React, { useEffect, useRef, useState } from 'react';
import { Col, Layout, message, Pagination, Row } from 'antd';

import * as S from './styles';
import InvestigationContent from '../../shared/InvestigationContent';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import InvestigationQuestionnaire from '../StudentInvestigation/InvestigationQuestionnaire';
import Spacer from '../../shared/Spacer';
import { FiArrowLeft } from 'react-icons/fi';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GQL_InvestigationDisplay,
  GQL_PeerReview,
  IPeerReviewQuestionnaireContentValue,
  IReflectionQuestionnaireContentValue,
} from '../../types/investigation';
import { gqlSchema } from '../../gql/schema';
import Button from '../../shared/Button';
import { themeConfig } from '../../utils/theme';
import InvestigationFileDisplay from '../../shared/InvestigationFileDisplay';
import { peerReviewQuestionToQuestionnaire } from '../../utils/investigation';
import { useAuth } from '../../hooks/useAuth';

type Props = RouteComponentProps<
  {
    investigationId: string;
    stepId: string;
    studentId: string;
    activityId: string;
  },
  any,
  {
    userId?: string;
  }
>;

const ReflectReportInvestigationPage: React.FC<Props> = (props) => {
  const { stepId, investigationId, activityId, studentId } = props.match.params;
  const { userId } = props.location.state || {};
  const history = useHistory();
  const [onReview, setOnReview] = useState(false);
  const pdfRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const { isStudent , user} = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';

  const { data: peerReviewData, loading: loadingPeerReviewData } = useQuery<
    { getPeerReviewByStepIdAndStudentId: GQL_PeerReview },
    {
      stepId: string;
      reviewerId: string;
      studentId?: string;
    }
  >(gqlSchema.InvestigationSchema.queries.CORE.getPeerReviewByStepIdAndStudentId, {
    variables: {
      stepId,
      reviewerId: studentId,
      studentId: userId,
    },
    onError: (err) => {
      message.error('There was an error loading the peer-review: ' + err.message || 'Unexpected Error');
    },
  });

  const [fetchInvestigation, { data: investigationData }] = useLazyQuery<
    { getInvestigationById: GQL_InvestigationDisplay },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById, {
    variables: {
      id: investigationId,
    },
    onError: (err) => {
      message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
    },
  });

  const [fetchInvestigationAsTeacher, { data: investigationTeacherData }] = useLazyQuery<
    { getInvestigationByIdForTeacher: GQL_InvestigationDisplay },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationByIdForTeacher, {
    variables: {
      id: investigationId,
    },
    onError: (err) => {
      message.error('There was an error loading teacher data: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    if (isStudent|| isGoogleStudent || isCanvasStudent) {
      fetchInvestigation();
    } else {
      fetchInvestigationAsTeacher();
    }
  }, [isStudent, isGoogleStudent, isCanvasStudent, fetchInvestigation, fetchInvestigationAsTeacher]);

  const finishQuestionnaire = () => {
    history.goBack();
  };

  const onLoadSuccess = (pdf: any) => {
    if (pdf) setNumPages(pdf?.numPages);
  };

  const [saveReflection] = useMutation<
    { submitReflectionAnswer: { id: string } },
    { peerReviewId: string; answer: string; questionIndex: number; userId?: string }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.submitReflectionQuestionnaireAnswer, {
    onError: (err) => {
      message.error('There was an error saving reflection: ' + err.message || 'Unexpected Error');
    },
  });

  const [saveReflectionRating] = useMutation<
    { submitReflectionAnswer: { id: string } },
    { peerReviewId: string; text: string; rating: number; studentId?: string }
  >(gqlSchema.InvestigationSchema.mutations.COMMENTS.submitReflection, {
    onError: (err) => {
      message.error('There was an error saving rating: ' + err.message || 'Unexpected Error');
    },
  });

  const investigation =
    investigationData?.getInvestigationById || investigationTeacherData?.getInvestigationByIdForTeacher;
  const step = investigation?.steps?.find((step) => step.id === stepId);
  const activity = step?.activities?.find((activity) => activity.id === activityId);
  const content = activity?.content?.find((content) =>
    content.blocks.some((block) => block.type === 'ReflectionQuestionnaire'),
  );
  const block = content?.blocks?.find(
    (block) => block.type === 'ReflectionQuestionnaire' && (userId ? block.userId === userId : true),
  );
  const values = block?.values as IReflectionQuestionnaireContentValue[];
  const value = values?.find((v) => v.review.studentId === userId || v.review.studentId === studentId);

  const questions =
    value?.questions?.map((q, i, arr) => ({
      title: q.question,
      needsFeedback: true,
      subquestions: [],
      feedbackTitle: '',
      includeRating: i === arr.length - 1,
      feedback: q.answer,
    })) || [];

  const peerReviewActivity = step?.activities?.find((activity) =>
    activity.content.some((c) => c.blocks.some((b) => b.type === 'PeerReviewQuestionnaire')),
  );
  const peerReviewContent = peerReviewActivity?.content?.find((content) =>
    content.blocks.some((block) => block.type === 'PeerReviewQuestionnaire'),
  );
  const peerReviewBlock = peerReviewContent?.blocks?.find((block) => block.type === 'PeerReviewQuestionnaire');
  const peerReviewValues = peerReviewBlock?.values as IPeerReviewQuestionnaireContentValue[];
  const peerReviewQuestions = peerReviewQuestionToQuestionnaire({
    values: peerReviewValues,
    questionnaire: peerReviewData?.getPeerReviewByStepIdAndStudentId?.questionnaire,
  });

  const onSaveReflection = (questionIndex: number, feedbackList: string[], rating?: number) => {
    if (value?.peerReviewId) {
      saveReflection({
        variables: {
          answer: feedbackList[questionIndex],
          questionIndex,
          peerReviewId: value?.peerReviewId,
          userId,
        },
      });
    }

    if (value?.peerReviewId && typeof rating === 'number') {
      saveReflectionRating({
        variables: {
          peerReviewId: value?.peerReviewId,
          rating: rating,
          text: '',
          studentId: userId,
        },
      });
    }
  };

  return (
    <Layout>
      <InvestigationContent noMargin>
        <Row justify="center">
          <S.Column xl={20} md={23} onClick={() => history.goBack()}>
            <div>
              <FiArrowLeft />
              <h1>{content?.title}</h1>
            </div>
          </S.Column>
        </Row>

        <Row justify="center">
          <Col xl={20} md={23}>
            <Row gutter={24} justify="center">
              {onReview ? (
                <>
                  <Col span={12}>
                    <Spacer size={40} />
                    <InvestigationQuestionnaire
                      paginate
                      disableAnswers
                      editorKey={studentId + 'reflection'}
                      questionnaire={{
                        questions: peerReviewQuestions,
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <Button
                      text={'Continue Reflection'}
                      theme={themeConfig.secondaryColor}
                      style={{ marginLeft: 'auto' }}
                      onClick={() => setOnReview(false)}
                    />
                    <Row justify="center">
                      <Pagination
                        style={{
                          zIndex: 1,
                        }}
                        size="small"
                        total={numPages}
                        pageSize={1}
                        current={pageNumber}
                        onChange={setPageNumber}
                      />
                    </Row>

                    {value?.review?.fileUrl && (
                      <InvestigationFileDisplay
                        file={value?.review?.fileUrl || ''}
                        page={pageNumber}
                        mimeType={value?.review?.fileMIMEtype || ''}
                        onLoadSuccess={onLoadSuccess}
                        containerRef={pdfRef}
                        videoRef={videoRef}
                      />
                    )}
                    {!value?.review?.fileUrl && !loadingPeerReviewData && (
                      <>
                        <Spacer size={80} />
                        <p style={{ textAlign: 'center' }}>This file was hidden by your teacher and will be reviewed</p>
                      </>
                    )}
                  </Col>
                </>
              ) : (
                <Col xl={12} md={16} xs={20}>
                  <Button
                    text={'See Review'}
                    theme={themeConfig.secondaryColor}
                    style={{ marginLeft: 'auto' }}
                    onClick={() => setOnReview(true)}
                  />
                  <Spacer size={40} />
                  <InvestigationQuestionnaire
                    paginate
                    finishButtonText="Complete Reflection"
                    onLastQuestion={finishQuestionnaire}
                    saveFeedback={onSaveReflection}
                    disableAnswers={!!value?.rating}
                    questionnaire={{
                      rating: value?.rating,
                      questions,
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(ReflectReportInvestigationPage);
