import React, { useMemo } from 'react';
import { Progress, Row } from 'antd';
import { startCase } from 'lodash';

import * as S from './styles';

interface ITeacherAssignmentMeterProps {
  type: 'investigations' | 'assessments' | 'classes';
  currentValue: number;
  maxValue: number;
}

const TeacherAssignmentMeter = (props: ITeacherAssignmentMeterProps) => {
  const { type, currentValue, maxValue } = props;

  const valuePercentage = (currentValue / maxValue) * 100;
  const shouldShowTotal = useMemo(() => {
    if (type !== 'assessments') {
      return true;
    }

    if (maxValue === -1) {
      return false;
    }

    return true;
  }, [type, maxValue]);

  return (
    <S.Card data-cy="shared-teacher-assignment-meter-row-item">
      <Row justify="center">
        <h3 style={{ marginBottom: !shouldShowTotal ? 0 : 10 }}>
          {startCase(type)} {type === 'classes' ? 'Created' : 'Assigned'}
        </h3>
      </Row>
      {!shouldShowTotal && (
        <Row justify="center">
          <p style={{ marginTop: -5 }}>(Unlimited)</p>
        </Row>
      )}
      <Row align="middle" justify="center" style={{ marginBottom: -60 }}>
        <Progress
          strokeLinecap="butt"
          strokeWidth={22}
          status="active"
          type="dashboard"
          strokeColor="#8EA4F2"
          trailColor="#D0D5E480"
          format={() => {
            if (shouldShowTotal) {
              return <span role="none"  style={{ fontSize: 16 }}>{`${currentValue}/${maxValue}`}</span>;
            }
          }}
          width={170}
          gapDegree={122}
          percent={shouldShowTotal ? valuePercentage : 100}
        />
      </Row>
    </S.Card>
  );
};

export default TeacherAssignmentMeter;
