/* eslint-disable complexity */
import { Col, Dropdown, Menu, Popover, Skeleton, Tooltip, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiAlertCircle, FiLock } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import { useQuery } from '@apollo/client';

import { numberToSpelledOut, splitByParagraph } from '../../utils/string';
import { themeConfig } from '../../utils/theme';
import Button from '../Button';
import * as S from './styles';
import { gqlSchema } from '../../gql/schema';
import InvestigationIcon from '../InvestigationIcon';
import { GQL_UserAssignLimitsResponse } from '../../types/user';
import { GQL_Mode } from '../../types/investigation';
import Spacer from '../Spacer';
import { useAuth } from '../../hooks/useAuth';
import { compareGradeBands } from '../../utils/gradeBandCompare';

export interface IInvestigationBookStage {
  name?: string;
  mode?: GQL_Mode;
  inPersonPreviewText?: string;
  remotePreviewText?: string;
  previewText?: string;
  order?: number;
}

interface IInvestigationBook {
  handlePreview?: () => void;
  handleAssign?: () => void;
  handleGoBack?: () => void;
  subject?: string;
  discipline?: string;
  disciplineId?: string;
  gradeBand?: string;
  imageSrc?: string;
  standardsUrl?: string;
  suppliesUrl?: string;
  letterHomeUrl?: string;
  investigationId?: string;
  investigationName?: string;
  investigationDescription?: string;
  investigationExplanation?: string;
  stages: IInvestigationBookStage[];
  availableForTrial?: boolean;
}

export const InvestigationBookLoading = () => {
  return (
    <S.DetailsContainer justify="center" gutter={[0, 24]}>
      <Col xs={24} lg={11}>
        <S.LeftPanelContainer>
          <Skeleton.Image />
          <Skeleton.Input active size="large" />
          <Skeleton.Input active />
          <S.HorizontalDivider margin="0 0 16px 0" />
          <Skeleton.Input active size="small" />
          <S.HorizontalDivider margin="0 0 16px 0" />
          <Skeleton.Input active size="small" />
          <S.HorizontalDivider margin="0 0 16px 0" />
          <Skeleton.Input active size="small" />
          <S.HorizontalDivider margin="0 0 16px 0" />
          <Skeleton.Input active size="small" />
          <S.HorizontalDivider margin="0 0 16px 0" />
          <Skeleton.Input active size="small" />
          <S.ButtonsContainer>
            <Skeleton.Button active />
            <Skeleton.Button active />
          </S.ButtonsContainer>
        </S.LeftPanelContainer>
      </Col>
      <Col xs={0} lg={2}>
        <S.VerticalDivider />
      </Col>
      <Col xs={24} lg={11}>
        <S.RightPanelContainer>
          <Skeleton.Image />
          <Skeleton.Input active size="large" />
          <Skeleton.Input active />
          <Skeleton.Input active size="small" />
          <S.HorizontalDivider margin="16px" />
          <Skeleton.Input active />
          <Skeleton.Input active size="small" />
          <S.HorizontalDivider margin="16px" />
          <Skeleton.Input active />
          <Skeleton.Input active />
          <Skeleton.Input active size="small" />
        </S.RightPanelContainer>
      </Col>
    </S.DetailsContainer>
  );
};

const InvestigationBook = (props: IInvestigationBook) => {
  const {
    handlePreview,
    handleAssign,
    handleGoBack,
    subject = '',
    discipline = '',
    disciplineId = -1,
    gradeBand = '',
    imageSrc,
    investigationId = '',
    investigationName,
    investigationDescription,
    stages,
    investigationExplanation,
    standardsUrl,
    suppliesUrl,
    availableForTrial = true,
  } = props;
  const { user, isSubscriptionUser } = useAuth();
  const history = useHistory();

  const [selectedStepName, setSelectedStepName] = useState<string>('');
  const currentStage = stages.find((stage) => stage.name === selectedStepName);
  const typeTitle = `${startCase(subject)}: `;

  const { data: userAssignLimits } = useQuery<{
    getUserAssigmentsLimits: GQL_UserAssignLimitsResponse;
  }>(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.getUserAssigmentsLimits, {
    onError: (error) => {
      message.error(`There was an error in fetching user assignment limits: ${error.message || 'Unexpected Error'}`);
    },
  });

  const formattedStepName = (stepName: string) => {
    const stepIndex = stages.findIndex((step) => step.name === stepName);
    return `Stage ${numberToSpelledOut(stepIndex + 1)}: ${stepName}`;
  };
  const typeName = subject === 'Engineering' ? 'Design Challenge' : 'Investigation';

  const isActionDisabled = useMemo(() => {
    if (user.isEnterprise) return false;
    // If investigation is already assigned before (shouldn't count as a new one)
    if (isSubscriptionUser && userAssignLimits?.getUserAssigmentsLimits.investigationsIds.includes(investigationId)) {
      return false;
    }

    if (
      isSubscriptionUser &&
      userAssignLimits?.getUserAssigmentsLimits.totalAssignes.investigationsAssigned! >=
        userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxInvestigations!
    ) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscriptionUser, userAssignLimits, investigationId]);

  const exportMenu = useMemo(
    () => (
      <Menu>
        {suppliesUrl && (
          <Menu.Item key="1" onClick={() => window.open(suppliesUrl)}>
            Supplies
          </Menu.Item>
        )}
        {standardsUrl && (
          <Menu.Item key="2" onClick={() => window.open(standardsUrl)}>
            Standards
          </Menu.Item>
        )}
      </Menu>
    ),
    [suppliesUrl, standardsUrl],
  );
  const trialText = 'Purchase a full subscription to unlock this investigation.';
  const shouldDisableByTrial = user?.subscription?.isTrial && !availableForTrial;
  const shouldDisableBySubject =
    !!user?.subscription?.allowedSubjects?.length && !user?.subscription?.allowedSubjects.includes(subject);
  const shouldDisableByGradeBand =
    !!user?.subscription?.allowedGradeBands?.length &&
    !user?.subscription?.allowedGradeBands.some((grade) => compareGradeBands(grade, gradeBand));
  const shouldDisableByDiscipline =
    !!user?.subscription?.allowedDisciplines?.length &&
    !user?.subscription?.allowedDisciplines.includes(Number(disciplineId));

  const shouldDisable = (!user.isEnterprise) ?
    shouldDisableBySubject ||
    shouldDisableByTrial ||
    shouldDisableByGradeBand ||
    shouldDisableByDiscipline ||
    isActionDisabled : false;

  useEffect(() => {
    if (selectedStepName === '' && stages.length > 0) {
      const orderZeroSelected = stages.find((stage: IInvestigationBookStage) => stage.order === 0)?.name;
      const orderFirstSelected = stages.find((stage: IInvestigationBookStage) => stage.order === 1)?.name;
      const selectedStepName = orderZeroSelected ?? orderFirstSelected ?? stages[0].name;
      setSelectedStepName(selectedStepName!);
    }
  }, [selectedStepName, stages]);

  const openForm = () => {
    window.open('https://share.hsforms.com/1WIzZgPCwR7Snqj6opPDvTQ5gbsq', '_blank', 'noreferrer noopener');
  };

  const subscriptionPage = useCallback(() => {
    history.push('/manage-subscription');
  }, [history]);

  return (
    <S.DetailsContainer justify="center" gutter={[0, 24]}>
      <S.PanelCol xs={24} lg={11}>
        <S.LeftPanelContainer>
          <InvestigationIcon discipline={discipline} subject={subject} size={80} />
          <h1>{typeTitle + investigationName}</h1>
          <p dangerouslySetInnerHTML={{ __html: investigationDescription || '' }} />
          <S.HorizontalDivider margin="16px" />
          {stages.map((step, index) => (
            <React.Fragment key={step.name}>
              <S.StageSelectorContainer
                onClick={() => setSelectedStepName(step.name || '')}
                data-cy={`components-investigation-book-stage-selector-${index + 1}`}
              >
                {formattedStepName(step.name || '')}
                {step.mode !== 'ALL' && (
                  <>
                    <Spacer axis="horizontal" size={8} />
                    <Tooltip
                      title={`This stage can be conducted ${step.mode === 'REMOTE' ? 'remote' : 'in-person'} only`}
                    >
                      <FiAlertCircle size={20} />
                    </Tooltip>
                  </>
                )}
              </S.StageSelectorContainer>
              <S.HorizontalDivider width={150} selected={selectedStepName === step.name} />
            </React.Fragment>
          ))}
        </S.LeftPanelContainer>

        <S.ButtonsContainer>
          <Button
            data-cy="shared-investigationbook-go-back-button"
            text="Back to Library"
            onClick={handleGoBack}
            theme={themeConfig.noColor}
            minHeight={40}
            block
          />

          <Popover
            placement="top"
            trigger={shouldDisable ? 'hover' : ''}
            content={
              !isActionDisabled ? (
                <>
                  <p style={{ marginBottom: 10 }}>{trialText}</p>
                  <Button text="Unlock Now" onClick={openForm} theme={themeConfig.secondaryColor} block />
                </>
              ) : (
                <>
                  <p
                    style={{ marginBottom: 10 }}
                  >{`Upgrade your subscription to be able to assign more ${typeName}`}</p>
                  <Button text="Upgrade" onClick={subscriptionPage} theme={themeConfig.secondaryColor} block />
                </>
              )
            }
          >
            <div style={{ width: '100%' }}>
              <Button
                text={'Assign this ' + typeName}
                theme={themeConfig.primaryColor}
                minHeight={40}
                onClick={handleAssign}
                block
                disabled={shouldDisable}
                icon={shouldDisable ? <FiLock /> : <React.Fragment />}
                data-cy="components-investigation-book-assign-investigation"
              />
            </div>
          </Popover>
        </S.ButtonsContainer>
      </S.PanelCol>

      <Col xs={0} lg={2}>
        <S.VerticalDivider />
      </Col>

      <S.PanelCol xs={24} lg={11}>
        <S.RightPanelContainer>
          {imageSrc && <img src={imageSrc} alt="Representation of the Investigation" />}

          <h1 data-cy={`components-investigation-book-selected-title`}>
            {selectedStepName ? formattedStepName(selectedStepName) : investigationName}
          </h1>

          {selectedStepName ? (
            <>
              {splitByParagraph(currentStage?.previewText)?.map(
                (section, i) =>
                  section && (
                    <div key={i} style={{ width: '100%' }}>
                      <p dangerouslySetInnerHTML={{ __html: section }} />
                      <S.HorizontalDivider margin="24px auto" />
                    </div>
                  ),
              )}
              {currentStage?.inPersonPreviewText && (
                <>
                  <h1>In-Person Option</h1>
                  {splitByParagraph(currentStage?.inPersonPreviewText)?.map(
                    (section, i, arr) =>
                      section && (
                        <div key={i}>
                          <p dangerouslySetInnerHTML={{ __html: section }} />
                          {i + 1 !== arr.length && <S.HorizontalDivider margin="24px auto" />}
                        </div>
                      ),
                  )}
                </>
              )}
              {currentStage?.remotePreviewText && (
                <>
                  <h1>Remote Option</h1>
                  {splitByParagraph(currentStage?.remotePreviewText)?.map(
                    (section, i, arr) =>
                      section && (
                        <div key={i}>
                          <p dangerouslySetInnerHTML={{ __html: section }} />
                          {i + 1 !== arr.length && <S.HorizontalDivider margin="24px auto" />}
                        </div>
                      ),
                  )}
                </>
              )}
            </>
          ) : (
            splitByParagraph(investigationExplanation)?.map(
              (section, i, arr) =>
                section && (
                  <div key={i}>
                    <p dangerouslySetInnerHTML={{ __html: section }} />
                    {i + 1 !== arr.length && <S.HorizontalDivider margin="24px auto" />}
                  </div>
                ),
            )
          )}
        </S.RightPanelContainer>

        <S.ButtonsContainer>
          <Dropdown
            overlay={exportMenu}
            arrow
            placement="topCenter"
            trigger={['click']}
            disabled={!standardsUrl && !suppliesUrl}
          >
            <Button
              data-cy="components-investigation-book-preparation-docs-button"
              text="Preparation Documents"
              disabled={!standardsUrl && !suppliesUrl}
              theme={themeConfig.secondaryOutlined}
              minHeight={40}
              block
            />
          </Dropdown>

          <Popover
            placement="top"
            trigger={shouldDisable ? 'hover' : ''}
            content={
              !isActionDisabled ? (
                <>
                  <p style={{ marginBottom: 10 }}>{trialText}</p>
                  <Button text="Unlock Now" onClick={openForm} theme={themeConfig.secondaryColor} block />
                </>
              ) : (
                <>
                  <p
                    style={{ marginBottom: 10 }}
                  >{`Upgrade your subscription to be able to assign more ${typeName}`}</p>
                  <Button text="Upgrade" onClick={subscriptionPage} theme={themeConfig.secondaryColor} block />
                </>
              )
            }
          >
            <div style={{ width: '100%' }}>
              <Button
                data-cy="components-investigation-book-quick-preview"
                text={'Quick Preview'}
                theme={themeConfig.primaryColor}
                disabled={shouldDisable}
                icon={shouldDisable ? <FiLock /> : <React.Fragment />}
                minHeight={40}
                onClick={handlePreview}
                block
              />
            </div>
          </Popover>
        </S.ButtonsContainer>
      </S.PanelCol>
    </S.DetailsContainer>
  );
};

export default InvestigationBook;
