import { gql } from '@apollo/client';

export const USER_MUTATIONS = {
  EDIT: {
    editUser: gql`
      mutation EditUser($data: EditUserInput!) {
        editUser(data: $data)
      }
    `,
    activateUser: gql`
      mutation ActivateUser($userId: String!) {
        activateUser(userId: $userId)
      }
    `,
    deactivateUser: gql`
      mutation DeactivateUser($userId: String!) {
        deactivateUser(userId: $userId)
      }
    `,
    toggleWriter: gql`
      mutation ToggleWriter($userId: String!, $isWriter: Boolean!) {
        toggleWriter(userId: $userId, isWriter: $isWriter)
      }
    `,
    toggleTeacher: gql`
      mutation ToggleTeacher($userId: String!, $isTeacher: Boolean!) {
        toggleTeacher(userId: $userId, isTeacher: $isTeacher)
      }
    `,
    deleteUsers: gql`
      mutation DeleteUsers($userIds: [String!]!) {
        deleteUsers(userIds: $userIds)
      }
    `,
    deleteUsersInvites: gql`
      mutation DeleteUsersInvites($inviteIds: [String!]!) {
        deleteUsersInvites(inviteIds: $inviteIds)
      }
    `,
    changePresentationMessagePreferences: gql`
      mutation changePresentationMessagePreferences($dontShowAgain: Boolean!) {
        changePresentationMessagePreferences(dontShowAgain: $dontShowAgain) {
          id
          acknowledgedPresentationMessage
        }
      }
    `,
  },
  SUBSCRIPTION: {
    updateSubscription: gql`
      mutation UpdateSubscription($data: SubscriptionInput!) {
        updateSubscription(data: $data) {
          id
          userId
          organizationId
          invitesAvailable
          startDate
          endDate
          isTrial
          customLibrary
          writerQuantity
          investigationLibraryLimit
          taCustomPermissions
          readerPermissions
          reportPermission
          teachersCanDoReviews
          type
          allowedGradeBands
          allowedSubjects
          allowedDisciplines
        }
      }
    `,
    toggleAssistantChatPermission: gql`
      mutation ToggleTAChatPermission($data: ToggleTAChatPermissionInput!) {
        toggleTAChatPermission(data: $data)
      }
    `,
    updateSubscriptionGradeBands: gql`
      mutation UpdateSubscriptionGradeBands($subscriptionId: String!, $gradeBands: [String!]!) {
        updateSubscriptionGradeBands(subscriptionId: $subscriptionId, gradeBands: $gradeBands)
      }
    `,
    updateSubscriptionSubjects: gql`
      mutation updateSubscriptionSubjects($subscriptionId: String!, $subjects: [String!]!) {
        updateSubscriptionSubjects(subscriptionId: $subscriptionId, subjects: $subjects)
      }
    `,
    updateSubscriptionDisciplines: gql`
      mutation updateSubscriptionDisciplines($subscriptionId: String!, $disciplineIds: [Int!]!) {
        updateSubscriptionDisciplines(subscriptionId: $subscriptionId, disciplineIds: $disciplineIds)
      }
    `,
    updateSubscriptionMaxClasses: gql`
      mutation updateSubscriptionMaxClasses($subscriptionId: String!, $maxClasses: Int!) {
        updateSubscriptionMaxClasses(subscriptionId: $subscriptionId, maxClasses: $maxClasses)
      }
    `,
    convertToPaidSubscription: gql`
      mutation convertToPaidSubscription($userId: String) {
        convertToPaidSubscription(userId: $userId) {
          userId
          userEmail
          stripeEnrolled
          subscriptionUpdated
          metersReStarted
          hubspotEnrolled
          message
        }
      }
    `,
    selfConvertToPaidSubscription: gql`
      mutation selfConvertToPaidSubscription($userId: String) {
        selfConvertToPaidSubscription(userId: $userId) {
          userId
          userEmail
          stripeEnrolled
          subscriptionUpdated
          metersReStarted
          hubspotEnrolled
          message
        }
      }
    `,
    selfConvertOrganizationUserToPaidSubscription: gql`
      mutation selfConvertOrganizationUserToPaidSubscription($userId: String!, $organizationId: String!) {
        selfConvertOrganizationUserToPaidSubscription(userId: $userId, organizationId: $organizationId) {
          userId
          userEmail
          stripeEnrolled
          subscriptionUpdated
          metersReStarted
          hubspotEnrolled
          organizationRemoved
          message
        }
      }
    `,
    convertOrganizationUserToPaidSubscription: gql`
      mutation convertOrganizationUserToPaidSubscription($userId: String!, $organizationId: String!) {
        convertOrganizationUserToPaidSubscription(userId: $userId, organizationId: $organizationId) {
          userId
          userEmail
          stripeEnrolled
          subscriptionUpdated
          metersReStarted
          hubspotEnrolled
          organizationRemoved
          message
        }
      }
    `,
  },
  CLEVERSYNC: {
    syncCleverRoster: gql`
      mutation syncCleverRoster {
        syncCleverRoster
      }
    `,
  },
};

export default USER_MUTATIONS;
