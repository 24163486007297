import { Col, Dropdown, Form as AntdForm, Menu, message, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import Form from '../../shared/Form';
import PageWithTitle from '../../shared/PageWithTitle';
import Button from '../../shared/Button';
import * as S from './styles';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GQL_ChangeStudentGroupInput,
  GQL_ClassResponse,
  GQL_RemoveStudentFromClassInput,
  GroupConfig,
} from '../../types/class';
import { gqlSchema } from '../../gql/schema';
import { Filter } from '../../utils/antd';
import { GQL_UserDetailsResponse, ClassUser } from '../../types/profile';
import { useForm } from 'antd/lib/form/Form';
import { formatDateTime } from '../../utils/date';
import { getShrinkedGroups, getGroupConfig } from '../../utils/class';
import { useAuth } from '../../hooks/useAuth';
import TagInput from '../../shared/TagInput';

type Props = RouteComponentProps<{
  classId?: string;
  studentId: string;
}>;

const StudentsDetails: React.FC<Props> = (props) => {
  const { classId, studentId } = props.match.params;
  const [classObj, setClassObj] = useState<GQL_ClassResponse>();
  const [student, setStudent] = useState<GQL_UserDetailsResponse>();
  const [studentGroup, setStudentGroup] = useState('');
  const [groups, setGroups] = useState<Filter[]>([]);
  const { isTeacherOrFacilitator, user } = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const [form] = useForm();
  const history = useHistory();

  const [fetchClass] = useLazyQuery(gqlSchema.ClassSchema.query.CLASS.CLASSES.getClass, {
    onCompleted: ({ getClass }: { getClass: GQL_ClassResponse }) => {
      setClassObj(getClass);
      const shrinkedGroups = getShrinkedGroups(getClass.numberOfGroups);
      setGroups(shrinkedGroups.map((grp: GroupConfig) => ({ text: `Change to Group ${grp.group}`, value: grp.group })));
    },
    onError: (err) => {
      message.error('There was an error loading class: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    if (classId) fetchClass({ variables: { data: { classId } } });
  }, [classId, fetchClass]);

  const { data } = useQuery(gqlSchema.ClassSchema.query.CLASS.CLASSES.getUserDetails, {
    variables: { data: { id: studentId } },
    onCompleted: ({ getUserDetails }: { getUserDetails: GQL_UserDetailsResponse }) => {
      form.setFieldsValue({
        ...getUserDetails,
        organization: getUserDetails.organization || 'No Organization',
        enrollmentDate: formatDateTime(getUserDetails.enrollmentDate, 'MM.dd.yyyy HH:mm aa'),
        lastLogin: formatDateTime(getUserDetails.lastLogin, 'MM.dd.yyyy HH:mm aa'),
      });
      const currentClass = getUserDetails.classes.find((cls: ClassUser) => cls.classId === classId);

      setStudentGroup(currentClass?.group || '');
      setStudent(getUserDetails);
    },
    onError: (err) => {
      message.error('There was an error loading your details: ' + err.message || 'Unexpected Error');
    },
  });

  const [submitMoveStudent] = useMutation<{ changeStudentGroup: GQL_ClassResponse }>(
    gqlSchema.ClassSchema.mutation.CLASS.MANAGE_GROUP.changeStudentGroup,
    {
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const [submitRemoveStudent] = useMutation<{ removeStudentFromClass: GQL_ClassResponse }>(
    gqlSchema.ClassSchema.mutation.CLASS.MANAGE_GROUP.removeStudentFromClass,
    {
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const removeStudent = useCallback(async () => {
    if (classId) {
      const data: GQL_RemoveStudentFromClassInput = { classId, userId: studentId };
      const response = await submitRemoveStudent({ variables: { data } });
      if (response.data?.removeStudentFromClass) {
        message.success(`Student ${student?.name} removed from class ${classObj?.name}`);
        history.push(`/teacher-dashboard/class/${classId}`, {
          className: classObj?.name,
        });
      }
    }
  }, [classId, classObj, history, student, studentId, submitRemoveStudent]);

  const moveStudent = useCallback(
    async (newGroup: string) => {
      if (classId) {
        const data: GQL_ChangeStudentGroupInput = { classId, newGroup, userId: studentId };
        const response = await submitMoveStudent({ variables: { data } });
        if (response.data?.changeStudentGroup) {
          message.success(`Student ${student?.name} moved to group ${newGroup}`);
          setStudentGroup(newGroup);
        }
      }
    },
    [classId, student, studentId, submitMoveStudent],
  );

  const editMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="0" onClick={removeStudent}>
          Remove Student
        </Menu.Item>
      </Menu>
    ),
    [removeStudent],
  );

  const groupMenu = useCallback(
    (groups: Filter[]) => (
      <Menu>
        {groups
          .filter((group: Filter) => group.value !== studentGroup)
          .map((group: Filter) => {
            return (
              <Menu.Item key={group.value} onClick={() => moveStudent(group.value)}>
                {group.text}
              </Menu.Item>
            );
          })}
      </Menu>
    ),
    [studentGroup, moveStudent],
  );

  return (
    <PageWithTitle
      title={`${student?.name}'s Account Settings`}
      backPageUrl={
        classId
          ? `/teacher-dashboard/class/${classId}${history.location.search}`
          : `/teacher-users${history.location.search}`
      }
      locationState={{ className: classObj?.name }}
    >
      <Row>
        <S.Body span={24}>
          <Form form={form}>
            <Row style={{ padding: '10px 20px' }}>
              <S.Line md={24} lg={8}>
                <S.TitleInput>Name</S.TitleInput>
                <AntdForm.Item name="name">
                  <S.SInput disabled bordered={false} />
                </AntdForm.Item>
              </S.Line>
              <S.Line md={24} lg={8}>
                <S.TitleInput>Email</S.TitleInput>
                <AntdForm.Item name="email">
                  <S.SInput disabled bordered={false} />
                </AntdForm.Item>
              </S.Line>
              <S.Line md={24} lg={8}>
                <S.TitleInput>{student?.name}'s Role</S.TitleInput>
                <AntdForm.Item name="role">
                  <S.SInput disabled bordered={false} defaultValue="Student" />
                </AntdForm.Item>
              </S.Line>
              <S.CDivider />
            </Row>
            <Row
              style={{
                padding: '20px 20px 10px 20px',
              }}
            >
              <Col md={24} lg={8}>
                <S.TitleInput>Enrollment Date</S.TitleInput>
                <AntdForm.Item name="enrollmentDate">
                  <S.SInput disabled bordered={false} />
                </AntdForm.Item>
              </Col>
              <Col md={24} lg={8}>
                <S.TitleInput>Last Login</S.TitleInput>
                <AntdForm.Item name="lastLogin">
                  <S.SInput disabled bordered={false} />
                </AntdForm.Item>
              </Col>
              <S.Line md={24} lg={8}>
                <S.TitleInput>Organization</S.TitleInput>
                <AntdForm.Item name="organization">
                  <S.SInput disabled bordered={false} />
                </AntdForm.Item>
              </S.Line>

              <S.CDivider />
            </Row>
            <Row style={{ padding: '10px 20px' }}>
              <S.Line md={24} lg={8}>
                <Row>
                  {classId ? (
                    <>
                      <S.TitleInput>Current Class</S.TitleInput>
                      <Col span={20} style={{ marginTop: 5 }}>
                        <S.TagButton
                          text={classObj?.name}
                          background={classObj?.colorHex}
                          shape="round"
                          minHeight={24}
                        />
                      </Col>
                      <Col span={4}>
                        {(isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) && classObj?.source === 'ADI' && (
                          <Dropdown overlay={editMenu} placement="bottomRight" trigger={['click']}>
                            <S.EditButton type="primary" shape="circle" minHeight={24} icon={<BiEdit />} />
                          </Dropdown>
                        )}
                      </Col>
                    </>
                  ) : (
                    <>
                      <S.TitleInput>Current Classes</S.TitleInput>
                      <Col span={24} style={{ marginTop: 5 }}>
                        {student?.classes.map((cls: ClassUser) => (
                          <S.TagButton
                            text={cls.name}
                            background={classObj?.colorHex}
                            shape="round"
                            minHeight={24}
                            key={cls.classId}
                          />
                        ))}
                      </Col>
                    </>
                  )}
                </Row>
              </S.Line>
              {classId && (
                <Col md={24} lg={8}>
                  <S.TitleInput>Investigation Group</S.TitleInput>
                  <Row>
                    <Col span={12} style={{ marginTop: 5 }}>
                      <S.TagButton
                        text={`Group ${studentGroup}`}
                        background={getGroupConfig(studentGroup)?.color}
                        shape="round"
                        minHeight={24}
                      />
                    </Col>
                    <Col
                      span={12}
                      style={{
                        marginTop: 5,
                        justifyContent: 'end',
                        right: 10,
                        display: 'grid',
                      }}
                    >
                      {(isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) && (
                        <Dropdown overlay={groupMenu(groups)} placement="bottomRight" trigger={['click']}>
                          <Button text="Change Group" />
                        </Dropdown>
                      )}
                    </Col>
                  </Row>
                </Col>
              )}
              <Col md={24} lg={8}>
                <S.TitleInput>Tags</S.TitleInput>
                <TagInput
                  style={{ padding: '0 11px' }}
                  userId={student?.id}
                  existingUserTags={data?.getUserDetails?.tags}
                  marginTop={5}
                  queriesToRefetch={[
                    {
                      query: gqlSchema.ClassSchema.query.CLASS.CLASSES.getUserDetails,
                      variables: { data: { id: studentId } },
                    },
                  ]}
                />
              </Col>
            </Row>
          </Form>
        </S.Body>
      </Row>
    </PageWithTitle>
  );
};

export default withRouter(StudentsDetails);
