import { Col } from 'antd';
import styled from 'styled-components';

export const Container = styled.div<{ fullHeight?: boolean }>`
  padding: 4em;
  ${(props) => props.fullHeight && 'height: calc(100vh - 120px);'}
`;

export const Card = styled(Col)`
  width: 100%;
  min-height: 600px;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 15px 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
`;
