import { Col, Row } from 'antd';
import React from 'react';
import { FiEdit, FiFilePlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Button from '../../shared/Button';
import PageWithTitle from '../../shared/PageWithTitle';
import Spacer from '../../shared/Spacer';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';

const AdiInvestigationsPage = () => {
  const history = useHistory();
  const navigateToTemplates = () => {
    history.push('/adi-investigations/templates');
  };

  const navigateToExistingInvestigation = () => {
    history.push('/adi-investigations/investigation-list');
  };

  return (
    <PageWithTitle>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <S.Card>
            <S.IconContainer newInvestigation>
              <FiFilePlus />
            </S.IconContainer>
            <Spacer />
            <h1>Create a New Investigation Using a Template</h1>
            <h2>Create a Science or Math investigation using templates that will pre-fill stages and activities.</h2>
            <Spacer />
            <Button
              data-cy="components-adiinvestigationspage-select-button"
              text="Select Investigation Template"
              theme={themeConfig.primaryOutlined}
              minHeight={40}
              onClick={navigateToTemplates}
            />
          </S.Card>
        </Col>
        <Col xs={24} md={12}>
          <S.Card>
            <S.IconContainer>
              <FiEdit />
            </S.IconContainer>
            <Spacer />
            <h1>Manage Existing Investigations</h1>
            <h2>See and edit all unpublished, published and pending to delete investigation.</h2>
            <Spacer />
            <Button
              data-cy="components-adiinvestigationspage-manage-button"
              text="Manage Investigations"
              theme={themeConfig.primaryOutlined}
              minHeight={40}
              onClick={navigateToExistingInvestigation}
            />
          </S.Card>
        </Col>
      </Row>
    </PageWithTitle>
  );
};

export default AdiInvestigationsPage;
