import styled from 'styled-components';

export const Footer = styled.footer`
  background-color: #4367e9;
  color: white;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  align-items: flex-start;
  max-width: 1500px;
  width: 100%;
  padding: 30px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    padding: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    align-items: center;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;

  @media (max-width: 1024px) {
    gap: 8px;
  }

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

export const FooterTitle = styled.h4`
  margin-bottom: 10px;
  font-size: 1.0rem;
  font-weight: bold;
  color: white;

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

export const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 0.8rem;

  &:hover {
    text-decoration: none;
    color: white;
  }

  @media (max-width: 1024px) {
    font-size: 0.7rem;
  }
`;

export const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 21rem;
  margin-left: -5rem;

  @media (max-width: 1024px) {
    width: 18rem;
    margin-left: -3rem;
  }
`;

export const NewsletterInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 40px;
  border: 1px solid white;
  padding: 6px;
  overflow: hidden;
  width: 100%;
  max-width: 400px;

  @media (max-width: 1024px) {
    max-width: 190px;
  }
`;

export const NewsletterInput = styled.input`
  padding: 10px;
  font-size: 0.8rem;
  width: 18rem;
  color: white;
  outline: none;
  border: none;
  background-color: transparent;

  ::placeholder {
    color: rgb(255, 255, 255);
  }

  @media (max-width: 1024px) {
    width: 16rem;
  }
`;

export const NewsletterButton = styled.button`
  position: absolute;
  right: 0;
  padding: 10px 20px;
  background-color: rgb(250, 250, 250);
  color: #4367e9;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
  border-radius: 40px;
  margin-right: 7px;

  &:hover {
    background-color: rgb(0, 192, 209);
  }

  @media (max-width: 1024px) {
    padding: 8px 18px;
    font-size: 0.75rem;
  }
`;