import { gql } from '@apollo/client';

const TRANSFER_INVITE_QUERIES = {
  transferInvite: gql`
    query transferInvite($token: String!) {
      transferInvite(token: $token) {
        organizationName
        userName
      }
    }
  `,
  pendingTransferInvites: gql`
    query pendingTransferInvites {
      pendingTransferInvites {
        id
        userName
        sendDate
        userEmail
      }
    }
  `,
};

export default TRANSFER_INVITE_QUERIES;
