import { useQuery } from '@apollo/client';
import { Col, message, Row, Table } from 'antd';
import { ColumnsType, FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FiExternalLink, FiSearch } from 'react-icons/fi';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import Spacer from '../../shared/Spacer';
import TableSearchBox from '../../shared/TableSearchBox';
import {
  GQL_InvestigationCompletionStudents,
  GQL_InvestigationTeacherSummary_PerStudent_Steps,
} from '../../types/students';
import { centerAlign } from '../../utils/antd';
import { formatDateTime } from '../../utils/date';
import * as S from './styles';
import PROGRESS_SUMMARY_STATUS from '../../constants/enums/ProgressSummaryStatus';

type Props = RouteComponentProps<{
  studentId: string;
  classId: string;
}>;

const StudentDueDates: React.FC<Props> = (props) => {
  const { studentId, classId } = props.match.params;
  const ref = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const [searchVisible, setSearchVisible] = useState(false);
  const [investigationStages, setInvestigationStages] = useState<GQL_InvestigationTeacherSummary_PerStudent_Steps[]>();
  const [designChallengesStages, setDesignChallengesStages] = useState<
    GQL_InvestigationTeacherSummary_PerStudent_Steps[]
  >();
  const { data: studentData, loading } = useQuery<
    { getMyProgressSummary: GQL_InvestigationCompletionStudents[] },
    { studentId: string; classId: string }
  >(gqlSchema.StudentSchema.queries.INVESTIGATION.getMyProgressSummary, {
    fetchPolicy: 'cache-first',
    variables: {
      studentId,
      classId,
    },
    onCompleted: (data) => {
      if (data.getMyProgressSummary.length > 0) {
        const investigation = data.getMyProgressSummary?.find(
          (s) => s.discipline?.subject === 'Math' || s.discipline?.subject === 'Science',
        );
        const designChallenge = data.getMyProgressSummary?.find((s) => s.discipline?.subject === 'Engineering');
        setInvestigationStages(
          investigation?.steps?.map((step: GQL_InvestigationTeacherSummary_PerStudent_Steps) => {
            return step;
          }),
        );
        setDesignChallengesStages(
          designChallenge?.steps?.map((step: GQL_InvestigationTeacherSummary_PerStudent_Steps) => {
            return step;
          }),
        );
      }
    },
    onError: () => {
      message.error(
        'There was an error loading the student investigation summary, please check your connection and try again later',
      );
    },
  });

  const statusToText = (
    status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED',
    dueDate: number,
  ) => {
    if (status === 'COMPLETED') return 'Completed';
    else if (status === 'IN_PROGRESS') return 'In Progress';
    else if (status === 'NOT_STARTED') return formatDateTime(dueDate);
    else if (status === 'OVERDUE') return formatDateTime(dueDate);
    else if (status === 'READY_FOR_GRADING') return 'Ready for Grading';
  };

  const assessmentStatusText = (
    status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED',
  ) => {
    if (status === 'COMPLETED') return 'Completed';
    else if (status === 'IN_PROGRESS') return 'In Progress';
    else if (status === 'NOT_STARTED') return 'Take the Assessment';
    else if (status === 'OVERDUE') return 'Closed';
    else if (status === 'READY_FOR_GRADING') return 'Ready for Grading';
  };

  const statusToColor = (status: string | undefined, dueDate: number) => {
    const isOverdue = dueDate < Date.now();
    if (status === PROGRESS_SUMMARY_STATUS.COMPLETED) return '#20BC89';
    if (status === PROGRESS_SUMMARY_STATUS.IN_PROGRESS) return isOverdue ? '#E9A643' : '#4388e9';
    if (status === PROGRESS_SUMMARY_STATUS.NOT_STARTED) return isOverdue ? '#EA3535' : '#C4C4C4';
    if (status === PROGRESS_SUMMARY_STATUS.OVERDUE) return '#EA3535';
    return '#C4C4C4';
  };

  const navigateToInvestigation = useCallback(
    (id: String, stepId?: string) => {
      history.push('/student-investigation/' + id, { stepId: stepId });
    },
    [history],
  );

  const assessmentNavigation = useCallback(
    (id: String, status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED') => {
      if (status === 'IN_PROGRESS' || status === 'NOT_STARTED') {
        history.push('/student-assessment/' + id);
      } else if (status === 'OVERDUE' || status === 'COMPLETED') {
        history.push('/student-dashboard/#finished-assessments-row');
      }
    },
    [history],
  );

  const generateInvestigationColumns = useCallback(
    ({
      stages,
      type,
    }: {
      stages?: GQL_InvestigationTeacherSummary_PerStudent_Steps[];
      type: string;
    }): ColumnsType<GQL_InvestigationCompletionStudents> => {
      return [
        {
          title: `Your ${type}s`,
          dataIndex: 'title',
          width: 275,
          fixed: 'left',
          className: 'components-studentduedates-your-type-column',
          onCell: (record) => ({ onClick: () => navigateToInvestigation(record.id), style: { cursor: 'pointer' } }),
          sorter: (a: GQL_InvestigationCompletionStudents, b: GQL_InvestigationCompletionStudents) =>
            `${a.discipline?.name}: ${a.title}`.localeCompare(`${b.discipline?.name}: ${b.title}`),
          render: (text: string, record: GQL_InvestigationCompletionStudents) => {
            return (
              <S.TableTitleContainer data-id={text}>
                <S.StudentInvestigationStatus color={statusToColor(record.status, record.dueDate)} />
                <p>
                  {record.discipline?.name}: {record.title}
                </p>
              </S.TableTitleContainer>
            );
          },
          filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={ref} {...filterProps} />,
          filterIcon: (filtered: boolean) => (
            <S.SearchIcon $searchVisible={searchVisible}>
              <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
            </S.SearchIcon>
          ),
          onFilter: (value: string | number | boolean, record: GQL_InvestigationCompletionStudents) => {
            if (!value) return true;
            return (
              record.discipline?.name.toLowerCase().includes(value.toString().toLowerCase()) ||
              record.title.toLowerCase().includes(value.toString().toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible: boolean) => {
            setSearchVisible(visible);
            if (visible) {
              setTimeout(() => {
                if (ref && ref.current) {
                  ref.current.select();
                }
              }, 100);
            }
          },
        },
        {
          title: type + ' Status',
          onCell: (record) => ({ onClick: () => navigateToInvestigation(record.id), style: { cursor: 'pointer' } }),
          width: 200,
          render: (text: string, record: GQL_InvestigationCompletionStudents) => (
            <S.StatusChip clickable={true} status={record.status} color={statusToColor(record.status, record.dueDate)}>
              {statusToText(record.status, record?.dueDate || 0)}
            </S.StatusChip>
          ),
        },
        ...((stages?.length &&
          stages
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((step, i) => ({
              title: step.name,
              key: step.id + i,
              width: 150,
              render: (text: string, record: GQL_InvestigationCompletionStudents) => {
                const recordStep = record.steps.find((s) => s.order === step.order);
                const previousStep = record.steps.find((s) => s.order === step.order - 1);
                const canNavigate = !previousStep || previousStep.completed || recordStep?.completed;

                return (
                  <S.StatusChip
                    clickable={!!canNavigate}
                    status={recordStep?.status || 'COMPLETED'}
                    onClick={canNavigate ? () => navigateToInvestigation(record.id, recordStep?.id) : undefined}
                    color={statusToColor(recordStep?.status, recordStep?.dueDate || record.dueDate || 0)}
                  >
                    {statusToText(recordStep?.status || 'COMPLETED', recordStep?.dueDate || record.dueDate || 0)}
                    {canNavigate && (
                      <>
                        <Spacer axis="horizontal" size={8} />
                        <FiExternalLink />
                      </>
                    )}
                  </S.StatusChip>
                );
              },
            }))) ||
          []),
      ];
    },
    [navigateToInvestigation, searchVisible],
  );
  const generateAssessmentColumns: any = useMemo(() => {
    return [
      {
        title: 'Your Assessment',
        dataIndex: 'title',
        width: 275,
        fixed: 'left',
        className: 'components-studentduedates-your-type-column',
        onCell: (record: GQL_InvestigationCompletionStudents) => ({
          onClick: () => assessmentNavigation(record.id, record.status),
          style: { cursor: 'pointer' },
        }),
        sorter: (a: GQL_InvestigationCompletionStudents, b: GQL_InvestigationCompletionStudents) =>
          `${a.discipline?.name}: ${a.title}`.localeCompare(`${b.discipline?.name}: ${b.title}`),
        render: (text: string, record: GQL_InvestigationCompletionStudents) => {
          return (
            <S.TableTitleContainer data-id={text}>
              <S.StudentInvestigationStatus color={statusToColor(record.status, record.dueDate)} />
              <p>
                {record.discipline?.name}: {record.title}{' '}
                {record.submissionVersion && record.submissionVersion > 1 ? `v${record.submissionVersion}` : null}
              </p>
            </S.TableTitleContainer>
          );
        },
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={ref} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_InvestigationCompletionStudents) => {
          if (!value) return true;
          return (
            record.discipline?.name.toLowerCase().includes(value.toString().toLowerCase()) ||
            record.title.toLowerCase().includes(value.toString().toLowerCase())
          );
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (ref && ref.current) {
                ref.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Assessment Status',
        onCell: (record: any) => ({
          onClick: () => assessmentNavigation(record.id, record.status),
          style: { cursor: 'pointer' },
        }),
        width: 200,
        render: (text: string, record: GQL_InvestigationCompletionStudents) => (
          <S.AssessmentStatusChip clickable={true} status={record.status} dueDate={record?.dueDate}>
            {assessmentStatusText(record.status)}
          </S.AssessmentStatusChip>
        ),
      },
      {
        title: 'Open Date',
        dataIndex: 'openDate',
        fixed: 'center',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return <span role="none" >{formatDateTime(record?.startDate, 'MM.dd.yyyy')}</span>;
        },
      },
      {
        title: 'Open Time',
        dataIndex: 'openTime',
        fixed: 'center',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return <span role="none" >{formatDateTime(record?.startDate, 'hh:mm a')}</span>;
        },
      },
      {
        title: 'Close Date',
        dataIndex: 'closeDate',
        fixed: 'center',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return <span role="none" >{formatDateTime(record?.dueDate, 'MM.dd.yyyy')}</span>;
        },
      },
      {
        title: 'Close Time',
        dataIndex: 'closeTime',
        fixed: 'center',
        align: centerAlign,
        render: (text: string, record: any, index: number) => {
          return <span role="none" >{formatDateTime(record?.dueDate, 'hh:mm a')}</span>;
        },
      },
    ];
  }, [assessmentNavigation, searchVisible]);

  const investigationColumns: ColumnsType<GQL_InvestigationCompletionStudents> = useMemo(
    () => generateInvestigationColumns({ stages: investigationStages, type: 'Investigation' }),
    [investigationStages, generateInvestigationColumns],
  );

  const designChallengesColumns: ColumnsType<GQL_InvestigationCompletionStudents> = useMemo(
    () => generateInvestigationColumns({ stages: designChallengesStages, type: 'Design Challenge' }),
    [designChallengesStages, generateInvestigationColumns],
  );

  const investigationsData =
    studentData?.getMyProgressSummary?.filter(
      (s) => (s.discipline?.subject === 'Science' || s.discipline?.subject === 'Math') && !s.isAssessment,
    ) || [];

  const assessmentData =
    studentData?.getMyProgressSummary?.filter(
      (s) => (s.discipline?.subject === 'Science' || s.discipline?.subject === 'Math') && s.isAssessment,
    ) || [];
  const designChallengesData =
    studentData?.getMyProgressSummary?.filter((s) => s.discipline?.subject === 'Engineering' && !s.isAssessment) || [];

  const pageTitleLabel = loading
    ? ''
    : investigationsData?.length > 0
    ? 'Investigations'
    : assessmentData?.length > 0
    ? 'Assessments'
    : 'Design Challenges';

  return (
    <PageWithTitle title={<S.TitleContainer>My {pageTitleLabel} Due Dates</S.TitleContainer>}>
      {(investigationsData?.length > 0 || loading) && (
        <Row>
          <Col span={24}>
            <S.TableWrapper>
              <Table
                columns={investigationColumns}
                loading={loading}
                bordered
                dataSource={investigationsData}
                scroll={{ x: 'max-content' }}
                rowKey={(record) => record.id}
                sticky
              />
            </S.TableWrapper>
          </Col>
        </Row>
      )}
      <Spacer size={32} />
      {assessmentData?.length > 0 && (
        <>
          {investigationsData?.length > 0 && (
            <>
              <S.TitleContainer>My Assessments Due Dates</S.TitleContainer>
              <Spacer size={16} />
            </>
          )}

          <Row>
            <Col span={24}>
              <S.TableWrapper>
                <Table
                  columns={generateAssessmentColumns}
                  loading={loading}
                  bordered
                  dataSource={assessmentData}
                  scroll={{ x: 'max-content' }}
                  rowKey={(record) => record.id}
                  sticky
                />
              </S.TableWrapper>
            </Col>
          </Row>
        </>
      )}
      {designChallengesData?.length > 0 && (
        <>
          {(investigationsData?.length > 0 || assessmentData?.length > 0) && (
            <>
              <S.TitleContainer>My Design Challenges Due Dates</S.TitleContainer>
              <Spacer size={16} />
            </>
          )}

          <Row>
            <Col span={24}>
              <S.TableWrapper>
                <Table
                  columns={designChallengesColumns}
                  loading={loading}
                  bordered
                  dataSource={designChallengesData}
                  scroll={{ x: 'max-content' }}
                  rowKey={(record) => record.id}
                  sticky
                />
              </S.TableWrapper>
            </Col>
          </Row>
        </>
      )}
    </PageWithTitle>
  );
};

export default withRouter(StudentDueDates);
