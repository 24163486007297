import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Badge, Progress } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as S from './styles';
import { getProgressColor, themeConfig } from '../../../../utils/theme';
import { gqlSchema } from '../../../../gql/schema';

interface Props {
  date: string;
  assignmentName: string;
  assignmentId: string;
  courseId: string;
  className: string;
  classColor: string;
  icon: React.ReactNode;
  workType: string; // This prop will no longer be used
  description: string;
}

const Item: React.FC<Props> = ({
  date,
  assignmentName,
  assignmentId,
  courseId,
  className,
  classColor,
  icon,
  description,
}) => {
  const history = useHistory();

  // Logging courseId and assignmentId
  console.log('courseIdF:', courseId);
  console.log('assignmentIdF:', assignmentId);

  const { data } = useQuery(gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentListBySubmission, {
    variables: {
      data: [{ course_id: courseId, id: assignmentId }],
    },
  });

  const progress = data?.getCanvasAssignmentListBySubmission?.[0]?.complete || 0;

  const goToDashboard = useCallback(() => {
    history.push(`/canvas-teacher-dashboard/class/${courseId}/assignment-summary/${assignmentId}`);
  }, [assignmentId, history, courseId]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <S.Card style={{ borderColor: classColor }}>
      <Row align="middle" gutter={16}>
        <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </Col>
        <Col span={16} xxl={16} lg={16} xs={12}>
          <S.ClassCourseInfo>
            <S.Info style={{ fontSize: '1.2em', textAlign: 'left',marginTop:isMobile?'-10px':'unset',marginLeft:isMobile?'-10px':'unset', fontWeight: 'bold'}}>
              {className}
            </S.Info>
          </S.ClassCourseInfo>
        </Col>
        <Col span={4} style={{ position: 'relative' }}>
          <S.BadgeWrapper>
            <Badge
              count={<div style={{ textAlign: 'center' }}>{'Assignment'}</div>}
              style={{ backgroundColor: '#E9A643', borderRadius: '40px', color: '#fff', fontWeight: 500, fontSize: '12px', padding: '8px 16px', textAlign: 'center',display: 'inline-block' }}
            />
          </S.BadgeWrapper>
        </Col>
      </Row>
      <Row style={{ marginTop: '1em' }}>
        <Col span={24}>
          <S.AssignmentName>
            <span style={{
              fontWeight: 500,
              fontSize: '14px',
              color: '#333',
              margin: '0',
              display: 'block',
              textAlign: 'left',
              textTransform: 'capitalize',
            }}>
              {assignmentName}
            </span>
          </S.AssignmentName>
        </Col>
      </Row>
      <Row style={{ marginTop: '1em' }}>
        <Col span={24} className="middle" style={{ textAlign: 'center' }}>
          <Progress
            strokeLinecap="round"
            strokeWidth={9}
            style={{ marginTop: 30 }}
            strokeColor={getProgressColor(progress || 0)}
            percent={progress}
            format={(percent) => (
              <>
                <S.Info $bold>{percent}%</S.Info>
                <S.InfoProgress>Total Submissions</S.InfoProgress>
              </>
            )}
            width={145}
            type="circle"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '3em' }}>
        <Col span={14} offset={5}>
          <S.SummarydButton
            text="View Summary"
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
