import { gql } from '@apollo/client';

export const CHAT_MUTATIONS = {
  createChat: gql`
    mutation createChat($data: ChatCreateInput!) {
      createChat(data: $data) {
        chatId
        userId
        userName
        senderName
        message
        sent
      }
    }
  `,
  sendMessage: gql`
    mutation sendMessage($data: SendMessageInput!, $file: Upload) {
      sendMessage(data: $data, file: $file) {
        id
        date
      }
    }
  `,
  markMessagesAsRead: gql`
    mutation markMessagesAsRead($chatMessageIds: [String!]) {
      markMessagesAsRead(chatMessageIds: $chatMessageIds)
    }
  `,
};

export default CHAT_MUTATIONS;
