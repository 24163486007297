import styled from 'styled-components';

export const StudentsPerformanceBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  padding: 36px;

  p {
    text-align: center;
    font-size: 16px;
  }
`;

export const GraphsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GraphContainer = styled.div`
  height: 260px;
  width: 80%;
  margin-bottom: 32px;
`;

export const GraphLabel = styled.p``;
