import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import { GQL_UserDetailsResponse } from '../../types/profile';
import PageWithTitle from '../../shared/PageWithTitle';
import { message, Tabs } from 'antd';
import * as S from './styles';
import UserInfo from './UserInfo';
import Subscription from './Subscription';
import { useAuth } from '../../hooks/useAuth';
import Button from '../../shared/Button';
import { BiTransfer } from 'react-icons/bi';
import TransferWorkModal from '../../shared/TransferWorkModal';
import TeacherClasses from './TeacherClasses';

type RouterProps = RouteComponentProps<{
  userId: string;
}>;
const { TabPane } = Tabs;

const UserDetailsPage = (props: RouterProps) => {
  const { userId } = props.match.params;
  const [activeKey, setActiveKey] = useState('details');
  const [transferClassesVisible, setTransferClassesVisible] = useState(false);
  const { isOrganizationAdiAdmin, isAdiSuperAdmin, isAdiAdmin } = useAuth();

  const { data } = useQuery<{ getUserDetails: GQL_UserDetailsResponse }, { data: { id: string } }>(
    gqlSchema.UserSchema.queries.DETAILS.getUserDetails,
    {
      variables: { data: { id: userId } },
      fetchPolicy: 'network-only',
      onError: (err) => {
        message.error('There was an error loading user details: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const showSubscriptionTab = useMemo(() => {
    if (data?.getUserDetails)
      return (
        (isAdiSuperAdmin || isAdiAdmin) &&
        ((data.getUserDetails.roles.includes('teacher') && !data.getUserDetails.organization) ||
          data.getUserDetails.roles.includes('facilitator'))
      );
    return false;
  }, [data, isAdiSuperAdmin, isAdiAdmin]);

  const showClassesTab: boolean = useMemo(() => {
    if (data?.getUserDetails) return data?.getUserDetails.roles.includes('teacher');
    return false;
  }, [data]);

  const transferSubscriptionButton = useMemo(() => {
    if (showClassesTab && isOrganizationAdiAdmin) {
      return (
        <Button
          text="Transfer All Work"
          onClick={() => setTransferClassesVisible(true)}
          block
          style={{ padding: '0 35px' }}
          icon={<BiTransfer />}
        />
      );
    }
    return null;
  }, [showClassesTab, isOrganizationAdiAdmin]);

  return (
    <PageWithTitle title={data?.getUserDetails ? `${data.getUserDetails.name}'s User Details` : 'Loading...'}>
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={(activeKey: string) => setActiveKey(activeKey)}
        tabBarExtraContent={transferSubscriptionButton}
      >
        <TabPane
          tab={<S.TitleTab data-cy="components-userdetailspage-details-tab">Details</S.TitleTab>}
          key="details"
          style={{ marginBottom: 30 }}
        >
          <UserInfo user={data?.getUserDetails} />
        </TabPane>
        {showSubscriptionTab && (
          <TabPane
            tab={<S.TitleTab data-cy="components-userdetailspage-subscription-tab">Subscription</S.TitleTab>}
            key="subscription"
            style={{ marginBottom: 30 }}
          >
            <Subscription user={data?.getUserDetails} />
          </TabPane>
        )}
        {showClassesTab && (
          <TabPane
            tab={<S.TitleTab data-cy="components-userdetailspage-classes-tab">Classes</S.TitleTab>}
            key="classes"
            style={{ marginBottom: 30 }}
          >
            <TeacherClasses user={data?.getUserDetails} />
          </TabPane>
        )}
      </Tabs>
      <TransferWorkModal
        teacher={data?.getUserDetails}
        visible={transferClassesVisible}
        setVisible={setTransferClassesVisible}
      />
    </PageWithTitle>
  );
};

export default withRouter(UserDetailsPage);
