import { useQuery } from '@apollo/client';
import { Badge, Empty, message, Skeleton } from 'antd';
import React from 'react';
import { gqlSchema } from '../../../gql/schema';
import Avatar from '../../../shared/Avatar';
import { GQL_ChatListResponse } from '../../../types/chat';
import { formatDistance } from '../../../utils/date';
import NewChatButton from '../NewChatButton';
import * as S from './styles';

interface IChatRoomsSider {
  onSelectContact: (chatRoom: GQL_ChatListResponse) => void;
  selectedChatId?: string;
  chatRoomIdsWithNewMessages: string[];
  setChatRoomIdsWithNewMessages: (chats: string[]) => void;
}

const ChatRoomsSider = (props: IChatRoomsSider) => {
  const { onSelectContact, selectedChatId, chatRoomIdsWithNewMessages, setChatRoomIdsWithNewMessages } = props;

  const { data: chatRoomsData, loading: loadingChatRooms } = useQuery<{ getChatRooms: GQL_ChatListResponse[] }>(
    gqlSchema.ChatSchema.query.getChatRooms,
    {
      onCompleted: ({ getChatRooms }) => {
        setChatRoomIdsWithNewMessages(
          getChatRooms?.filter((room) => room.read === false)?.map((room) => room.chatId) || [],
        );
      },
      onError: (err) => {
        message.error('There was an error loading chat rooms: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const chatRooms = chatRoomsData?.getChatRooms || [];

  const renderLoadingChatRooms = () => {
    return (
      <>
        <S.ContactContainer>
          <Skeleton.Avatar active size="large" />
          <span role="none" >
            <Skeleton.Input active size="default" />
            <Skeleton.Input active size="small" />
          </span>
        </S.ContactContainer>
        <S.ContactsDivider />
        <S.ContactContainer>
          <Skeleton.Avatar active size="large" />
          <span role="none" >
            <Skeleton.Input active size="default" />
            <Skeleton.Input active size="small" />
          </span>
        </S.ContactContainer>
        <S.ContactsDivider />
        <S.ContactContainer>
          <Skeleton.Avatar active size="large" />
          <span role="none" >
            <Skeleton.Input active size="default" />
            <Skeleton.Input active size="small" />
          </span>
        </S.ContactContainer>
        <S.ContactsDivider />
      </>
    );
  };

  const emptyChatRooms = !loadingChatRooms && chatRooms.length === 0;

  return (
    <S.MessagesContactsContainer>
      <S.ContactsOutsideContainer centralize={emptyChatRooms}>
        {loadingChatRooms && renderLoadingChatRooms()}
        {emptyChatRooms && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No conversations yet" />}
        {chatRooms
          .slice()
          .sort((a, b) => Number(b.sent) - Number(a.sent))
          .map((contact) => (
            <React.Fragment key={contact.chatId}>
              <S.ContactContainer
                key={contact.chatId}
                selected={contact.chatId === selectedChatId}
                onClick={() => onSelectContact(contact)}
              >
                <Badge
                  count={chatRoomIdsWithNewMessages.find((id) => id === contact.chatId) ? 1 : 0}
                  dot
                  offset={[-20, 7]}
                >
                  <Avatar margin="0 16px 0 0" backgroundColor="transparent" size={40} src={contact.avatar} />
                </Badge>

                <span role="none" >
                  <h1>{contact.userName}</h1>
                  <h2>
                    {contact.sent && `Last Message: ${formatDistance(Number(contact.sent)).replace('about', '')} ago`}
                  </h2>
                </span>
              </S.ContactContainer>
              <S.ContactsDivider />
            </React.Fragment>
          ))}
      </S.ContactsOutsideContainer>
      <NewChatButton onSelectContact={onSelectContact} existingRooms={chatRooms} />
    </S.MessagesContactsContainer>
  );
};

export default ChatRoomsSider;
