import styled from 'styled-components';
import CButton from '../../../../shared/Button';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5em;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .middle {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 125.1%;

  color: #767676;
  text-align: left;
  margin-top: 8px;
`;


export const TimeLeft = styled.p<{
  $warning: boolean;
  $fontSize: string;
}>`
  font-size: 0.87em;
  color: ${(props) => (props.$warning ? '#c10000' : '#000000d9')};
  font-weight: 400;
  text-align: center;
  margin: 0;
  vertical-align: bottom;

  svg {
    margin-right: 8px;
    font-size: 1.35em;
    vertical-align: top;
  }
`;

export const ClassName = styled.span<{
  $color: string;
}>`
  background: ${(props) => props.$color};
  color: white;
  padding: 5px 10px;
  font-weight: 500;
  border-radius: 20px;
  margin-left: 8px; // Adds space between the icon and the class name
`;

export const Icon = styled.p`
  font-size: 3.5em;
  margin-bottom: 0;
  text-align: center;
`;

export const Title = styled.h3<{
  $align?: string;
}>`
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  font-weight: 500;
  margin-bottom: 0;
`;

export const Subtitle = styled.h4`
  font-weight: normal;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 8px;
  color: #888;
  text-align: left;
`;

export const AssignmentName = styled.h4`
  font-weight: normal;
  font-size: 16px;
  margin-top: 8px;
  color: #333;
  text-align: left;
`;

export const DashboardButton = styled(CButton)`
  font-weight: 500;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e0f7fa; // Light blue background color
  img {
    width: 60%; // Adjust size of the image
    height: 60%; // Adjust size of the image
    object-fit: cover;
  }
`;
