import React, { useState, useEffect, useCallback } from 'react';
import * as S from './styles';
import { Col, Empty, message, Row, Spin, Tooltip } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { GQL_InvestigationsResponse } from '../../../types/charts';
import Button from '../../Button';
import MostUsedInvestigationsDetail from './Details';
import { Duration, FilterData } from '../../../types/investigation';
import Filter from '../Filter';
import { formatDateTime } from '../../../utils/date';
import { FiHelpCircle } from 'react-icons/fi';
import { palette } from '../../../utils/theme';
import { ResponsiveBar } from '@nivo/bar';

const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};
const MostUsedInvestigations = () => {
  const [durationMostUsedInvestigation, setDurationMostUsedInvestigation] = useState<Duration>(defaultDateRange);
  const [filterData, setFilterData] = useState<FilterData>({ organizations: false, teachers: false, tagIds: [] });
  const [barData, setBarData] = useState<any[] | undefined>();
  const [visible, setVisible] = useState(false);
  const onChangeFilterData = useCallback((data: FilterData) => {
    setFilterData(data);
  }, []);

  const [fetchMostUsedInvestigations] = useLazyQuery<{ getMostUsedInvestigations: GQL_InvestigationsResponse[] }>(
    gqlSchema.ChartSchema.queries.CHARTS.getMostUsedInvestigations,
    {
      onCompleted: (data) => {
        setBarData(
          data.getMostUsedInvestigations
            .filter((item) => item.count !== 0)
            .map((item: GQL_InvestigationsResponse, i: number) => ({
              status: item.title,
              Quantity: item.count,
              color: palette[i],
              type: item.title,
            })),
        );
      },
      onError: (err) => {
        message.error('There was an error loading most used investigations: ' + err.message || 'Unexpected Error');
      },
    },
  );

  useEffect(() => {
    const { disciplineIds, ...data } = filterData;
    fetchMostUsedInvestigations({
      variables: {
        data: {
          startDate: durationMostUsedInvestigation.startDate,
          endDate: durationMostUsedInvestigation.endDate,

          ...data,
        },
      },
    });
  }, [fetchMostUsedInvestigations, durationMostUsedInvestigation, filterData]);

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Most Used Investigations</S.Title>
          <Tooltip title="Most assigned investigations.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Filter setDaterange={setDurationMostUsedInvestigation} onChangeFilterData={onChangeFilterData} />
        </Col>
        <Col xs={24}>
          <Row>
            <Col span={24}>
              {!barData ? (
                <Spin data-cy="shared-charts-mostusedinvestigations-spin" size="large" />
              ) : barData.length > 0 ? (
                <Row style={{ width: '100%' }} gutter={[12, 0]}>
                  <Col span={24}>
                    <S.Card $height="550px">
                      <Row justify="center" style={{ height: '100%' }}>
                        <Col span={24} style={{ height: '400px' }}>
                          <ResponsiveBar
                            data={barData}
                            keys={['Quantity']}
                            indexBy="status"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            valueFormat=">-.1~r"
                            indexScale={{ type: 'band', round: true }}
                            borderColor={{
                              from: 'color',
                              modifiers: [['darker', 1.6]],
                            }}
                            colors={(d) => d.data.color?.toString()}
                            axisBottom={{
                              tickSize: 4,
                              tickPadding: 8,
                              tickRotation: 15,
                              format: (v) =>
                                v.length > 20 ? (
                                  <tspan>
                                    {v.substring(0, 20) + '...'}
                                    <title>{v}</title>
                                  </tspan>
                                ) : (
                                  v
                                ),
                            }}
                            axisLeft={{
                              tickSize: 6,
                              tickPadding: 0,
                              tickRotation: 0,
                              format: (e) => Math.floor(e) === e && e,
                              legend: 'Times Assigned',
                              legendPosition: 'middle',
                              legendOffset: -50,
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={'#ffffff'}
                          />
                        </Col>
                        <Col span={6}>
                          <Button
                            data-cy="shared-charts-mostusedinvestigations-button-seedetails"
                            text="See Details"
                            minHeight={40}
                            style={{ marginTop: 10 }}
                            block
                            onClick={() => setVisible(true)}
                          />
                          <MostUsedInvestigationsDetail
                            visible={visible}
                            setVisible={setVisible}
                            endDate={durationMostUsedInvestigation.endDate}
                            filterData={filterData}
                            startDate={durationMostUsedInvestigation.startDate}
                          />
                        </Col>
                      </Row>
                    </S.Card>
                  </Col>
                </Row>
              ) : (
                <S.Card $height="20vh">
                  <Row justify="center" style={{ width: '100%' }}>
                    <Col span={24}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Col>
                  </Row>
                </S.Card>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MostUsedInvestigations;
