import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import GradePopup from './GradePopup';

interface AssignmentTableProps {
  courseId: string;
  courseWorkId: string;
  courseData: any;
  assignmentData: any;
  submissionData: any[];
  studentNames: any[];
  style?: React.CSSProperties;
  assignmentName: string;
}

const AssignmentTable: React.FC<AssignmentTableProps> = ({
  courseId,
  courseWorkId,
  courseData,
  assignmentData = {},
  submissionData = [],
  studentNames = [],
  style,
  assignmentName,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<any>(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

  const { name: className } = courseData || {};
  const { description: assignmentDescription } = assignmentData || {};

  useEffect(() => {
    console.log("Submission Data:", submissionData);
  }, [submissionData]);

  const extractLinks = (text: string): string[] => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = text.match(urlRegex);
  
    // Update links based on investigation or assessment type
    return links?.map((newLink: string) => {
      return newLink.includes('/student-investigation/')
        ? newLink.replace('/student-investigation/', '/teacher-dashboard/investigation-summary/')
        : newLink.includes('/student-assessment/')
        ? newLink.replace('/student-assessment/', '/teacher-dashboard/assessment-summary/')
        : newLink;
    }) || [];
  };

  const showModal = (id: number, name: string) => {
    const idAsString = id.toString();
    const submission = submissionData.find(_data => _data.user_id === idAsString) || {};
    setSelectedSubmission({ ...submission, name });
  
    // Extract links from the assignment description
    const links = extractLinks(assignmentData?.description || '');
  
    // Check if the links include 'student-investigation' or 'student-assessment'
    const investigationLink = links?.find((link) => link.includes('/teacher-dashboard/investigation-summary/'));
    const assessmentLink = links?.find((link) => link.includes('/teacher-dashboard/assessment-summary/'));
  
    if (investigationLink) {
      // Redirect to the investigation summary link
      window.location.href = investigationLink;
    } else if (assessmentLink) {
      // Redirect to the assessment summary link
      window.location.href = assessmentLink;
    } else {
      // Show the modal if no specific links are found
      setIsModalVisible(true);
    }
  };
  

  const refetchSubmissionData = () => {
    console.log("Refetching submission data...");
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleExpand = (expanded: boolean, record: any) => {
    const rowKey = record.id;
    if (expanded) {
      setExpandedRowKeys([rowKey]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'name',
      key: 'name',
      width: isMobile ? '80%' : '50%',
    },
    {
      title: 'Submitted On',
      dataIndex: 'id',
      key: 'submitted_at',
      width: '25%',
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      render: (id: string) => {
        const submission = submissionData.find(_data => _data.user_id === id.toString());
        const submittedAt = submission?.submitted_at 
        ? new Date(submission.submitted_at).toLocaleDateString() 
        : "--";
        return <>{submittedAt}</>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'id',
      key: 'workflow_state',
      width: '50%',
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      render: (id: string) => {
        const submission = submissionData.find(_data => _data.user_id === id.toString());
        let status = "Not Submitted";
        let color = "orange";

        if (submission) {
          const workflowState = submission.workflow_state?.toLowerCase();
          if (workflowState === 'submitted' || workflowState === 'graded') {
            status = "Submitted";
            color = "#3EBC89";
          }
        }

        return (
          <span style={{ color, fontWeight: 'bold' }}>
            {status}
          </span>
        );
      },
    },
    {
      title: 'Submission',
      dataIndex: 'id',
      key: 'submission',
      width: '50%',
      render: (id: string, record: any) => {
        const submission = submissionData.find(_data => _data.user_id === id.toString());
        const workflowState = submission?.workflow_state?.toLowerCase();
        const displayStatus = workflowState === "graded" ? "Graded" : "Not Graded";

        return (
          <Button
            type="primary"
            style={{ width: '120px', backgroundColor: '#4367E9' }}
            onClick={() => showModal(Number(id), record.name)}
          >
            {displayStatus}
          </Button>
        );
      },
    },
  ];

  return (
    <div style={{ width: '100%', ...style }}>
      {!isMobile && (
        <Table
          columns={columns}
          dataSource={studentNames}
          rowKey="studentName"
          pagination={false}
        />
      )}
      {isMobile && (
        <Table
          columns={columns}
          dataSource={studentNames}
          rowKey="id"
          pagination={false}
          expandable={{
            expandedRowRender: (record: any) => (
              <div>
                {columns.map((column) => {
                  if (column.key !== 'submission') {
                    return (
                      <div key={column.key} style={{ marginBottom: '8px' }}>
                        <strong>{column.title}:</strong>{' '}
                        {column.render
                          ? column.render(record[column.dataIndex], record)
                          : record[column.dataIndex]}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            ),
            expandedRowKeys,
            onExpand: handleExpand,
          }}
        />
      )}

      {selectedSubmission && (
        <GradePopup
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          studentName={selectedSubmission.name || 'Unknown Student'}
          className={className}
          assignmentDescription={assignmentDescription}
          submissionData={selectedSubmission}
          assignmentName={assignmentName}
          assignmentLinks={selectedSubmission.url || ""}
          max_score={assignmentData?.points_possible || 0}
          courseId={courseId}
          courseWorkId={courseWorkId}
          submission={selectedSubmission.workflow_state === 'graded' ? 'Graded' : 'Not Graded'}
          refetchSubmissionData={refetchSubmissionData}
        />
      )}
    </div>
  );
};

export default AssignmentTable;
