import styled from 'styled-components';
import { Picker } from '../../DatePicker/styles';

export const Title = styled.h3`
  font-size: 1.4em;
  display: inline;
`;

export const DatePicker = styled(Picker)<{
  $backgroundColor?: string;
}>`
  border-radius: 6px;
  background: ${(props) => props.$backgroundColor || '#f8f8f8'};

  input {
    background: ${(props) => props.$backgroundColor || '#f8f8f8'};
    padding: 4px 11px 4px;
    cursor: pointer;
  }
  height: auto;
`;

export const TitleTab = styled.p`
  font-weight: 700 !important;
  padding: 0;
  font-size: 1.2em !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
`;
