import styled from 'styled-components';
import { Layout, Menu as AntdMenu, Col as AntdCol, Row as AntdRow } from 'antd';
const { Header: AntdHeader } = Layout;

export const Header = styled(AntdHeader)`
  background-color: white;
  height: 80px;
  z-index: 1002;
  position: fixed;
  width: 100%;
  padding: 0;
  @media (max-width: 1200px) {
    padding: 0;
  }
`;

export const Menu = styled(AntdMenu)<{ $menuVisible?: boolean }>`
  color: #767676;
  border: 0;
  float: right;
  width: 100%;
  text-align: ${(props) => (props.$menuVisible ? 'start' : 'end')};
`;

export const Col = styled(AntdCol)<{
  $defaultAlign: string;
  $responsiveAlign: string;
}>`
  text-align: ${(props) => props.$defaultAlign};
  @media (max-width: 1200px) {
    text-align: ${(props) => props.$responsiveAlign};
    padding-right: 30px;
  }

  ul {
    width: auto;
  }
`;

export const Row = styled(AntdRow)<{ $menuVisible?: boolean }>`
  height: 100%;
  padding: 5px 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  ${(props) => props.$menuVisible && 'padding-top: 8px;'}
`;

export const MenuItem = styled(AntdMenu.Item)<{ $menuVisible?: boolean }>`
  border: 0 !important;
  font-size: 15px;
  margin: ${(props) => (props.$menuVisible ? '0' : '0 20px')} !important;
  ${(props) => props.$menuVisible && 'padding-left: 32px !important;'}
  text-align: start;
  letter-spacing: 0.5px;
  &.ant-menu-item-selected {
    font-weight: bold;
    color: ${(props) => props.theme.primaryOutlined.color} !important;
    svg > path {
      stroke: ${(props) => props.theme.primaryOutlined.color} !important;
    }
  }

  &:hover {
    color: ${(props) => props.theme.primaryOutlined.color} !important;
    svg > path {
      stroke: ${(props) => props.theme.primaryOutlined.color} !important;
    }
  }

  svg {
    font-size: 24px !important;
    vertical-align: middle;
  }
`;

export const LogoImage = styled.img`
  height: 100%;
  padding: 8px;
  cursor: pointer;
`;
