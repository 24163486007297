import React, { useEffect, useRef, useState } from 'react';
import { Col, Layout, message, Pagination, Row } from 'antd';

import * as S from './styles';
import InvestigationContent from '../../shared/InvestigationContent';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_InvestigationDisplay,
  GQL_PeerReview,
  IPeerReviewQuestionnaireContentValue,
} from '../../types/investigation';
import InvestigationFileDisplay from '../../shared/InvestigationFileDisplay';
import InvestigationQuestionnaire from '../StudentInvestigation/InvestigationQuestionnaire';
import Spacer from '../../shared/Spacer';
import { FiArrowLeft } from 'react-icons/fi';
import { peerReviewQuestionToQuestionnaire } from '../../utils/investigation';
import { useAuth } from '../../hooks/useAuth';

type Props = RouteComponentProps<
  {
    investigationId: string;
    stepId: string;
    studentId: string;
    activityId: string;
  },
  any,
  {
    userId?: string;
  }
>;

const PeerReviewInvestigationPage: React.FC<Props> = (props) => {
  const { stepId, studentId, investigationId, activityId } = props.match.params;
  const { userId } = props.location.state || {};
  const pdfRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [questionnaireExists, setQuestionnaireExists] = useState(false);
  const history = useHistory();
  const { isStudent, user } = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';

  const [fetchInvestigation, { data: investigationData }] = useLazyQuery<
    { getInvestigationById: GQL_InvestigationDisplay },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById, {
    variables: {
      id: investigationId,
    },
    onError: (err) => {
      message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
    },
  });

  const [fetchInvestigationAsTeacher, { data: investigationTeacherData }] = useLazyQuery<
    { getInvestigationByIdForTeacher: GQL_InvestigationDisplay },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationByIdForTeacher, {
    variables: {
      id: investigationId,
    },
    onError: (err) => {
      message.error('There was an error loading the teacher data: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    if (isStudent|| isGoogleStudent || isCanvasStudent) {
      fetchInvestigation();
    } else {
      fetchInvestigationAsTeacher();
    }
  }, [isStudent, isGoogleStudent, isCanvasStudent, fetchInvestigation, fetchInvestigationAsTeacher]);

  const { data: peerReviewData, loading: loadingPeerReviewData } = useQuery<
    { getPeerReviewByStepIdAndStudentId: GQL_PeerReview },
    {
      stepId: string;
      studentId: string;
      reviewerId?: string;
    }
  >(gqlSchema.InvestigationSchema.queries.CORE.getPeerReviewByStepIdAndStudentId, {
    variables: {
      stepId,
      studentId,
      reviewerId: userId,
    },
    onError: (err) => {
      message.error('There was an error loading the peer-review: ' + err.message || 'Unexpected Error');
    },
  });

  const onLoadSuccess = (pdf: any) => {
    if (pdf) setNumPages(pdf?.numPages);
  };

  const finishQuestionnaire = () => {
    history.goBack();
  };

  const peerReview = peerReviewData?.getPeerReviewByStepIdAndStudentId;
  const investigation =
    investigationData?.getInvestigationById || investigationTeacherData?.getInvestigationByIdForTeacher;
  const step = investigation?.steps?.find((step) => step.id === stepId);
  const activity = step?.activities?.find((activity) => activity.id === activityId);
  const content = activity?.content?.find((content) =>
    content.blocks.some((block) => block.type === 'PeerReviewQuestionnaire'),
  );
  const block = content?.blocks?.find(
    (block) => block.type === 'PeerReviewQuestionnaire' && (userId ? block.userId === userId : true),
  );
  const values = block?.values as IPeerReviewQuestionnaireContentValue[];
  const questions = peerReviewQuestionToQuestionnaire({ values: values, questionnaire: peerReview?.questionnaire });

  const [saveAnswer, { loading: loadingSaveAnswer }] = useMutation<
    { submitPeerReviewAnswer: GQL_PeerReview },
    { peerReviewId: string; questionIndex: number; answerIndex: number; answer: number; userId?: string }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.submitPeerReviewAnswer, {
    onError: (err) => {
      message.error('There was an error saving answer: ' + err.message || 'Unexpected Error');
    },
  });

  const [saveFeedback] = useMutation<
    { submitPeerReviewQuestionFeedback: { id: string } },
    { peerReviewId: string; questionIndex: number; feedback: string; userId?: string }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.submitPeerReviewQuestionFeedback, {
    onError: (err) => {
      message.error('There was an error saving feedback: ' + err.message || 'Unexpected Error');
    },
  });

  const onSelectAnswer = (questionIndex: number, subquestionIndex: number, answerIndex: number) => {
    if (peerReview) {
      const optimisticQuestionnaireAnswer = {
        id: Date.now().toString(),
        __typename: 'PeerReviewQuestionnaireAnswers',
        answer: answerIndex,
        answerIndex: subquestionIndex,
      };

      // Only allows optimistic responses for questionnaires that exists,
      // otherwise two questionnaires might be created
      const questionnaireExists = peerReview.questionnaire?.find((q) => q.questionIndex === questionIndex);
      setQuestionnaireExists(!!questionnaireExists);

      saveAnswer({
        variables: {
          answer: answerIndex,
          answerIndex: subquestionIndex,
          questionIndex,
          peerReviewId: peerReview.id,
          userId: userId,
        },
        optimisticResponse: questionnaireExists
          ? {
              submitPeerReviewAnswer: {
                ...peerReview,
                questionnaire: peerReview.questionnaire.map((q) => {
                  if (q.questionIndex !== questionIndex) return q;
                  const answerExists = q?.answers?.find((a) => a.answerIndex === subquestionIndex);

                  return {
                    ...q,
                    answers: answerExists
                      ? q.answers.map((a) => {
                          if (a.answerIndex !== subquestionIndex) return a;

                          return {
                            ...a,
                            answer: answerIndex,
                          };
                        })
                      : [...(q.answers || []), optimisticQuestionnaireAnswer],
                  };
                }),
              },
            }
          : undefined,
      });
    }
  };

  const onSaveFeedback = (questionIndex: number, feedbackList: string[]) => {
    if (peerReview) {
      saveFeedback({
        variables: {
          feedback: feedbackList[questionIndex],
          questionIndex,
          peerReviewId: peerReview.id,
          userId: userId,
        },
      });
    }
  };

  const peerReviewAlreadyReflected = !!peerReviewData?.getPeerReviewByStepIdAndStudentId?.reflectionCompletedAt;

  return (
    <Layout>
      <InvestigationContent noMargin>
        <Row justify="center">
          <S.Column xl={20} md={23} onClick={() => history.goBack()}>
            <div>
              <FiArrowLeft />
              <h1>{content?.title}</h1>
            </div>
          </S.Column>
        </Row>

        <Row justify="center">
          <Col xl={20} md={23}>
            <Row gutter={24}>
              <Col span={12}>
                <Spacer size={40} />
                <InvestigationQuestionnaire
                  paginate
                  editorKey={peerReview?.id + 'peerReview' + activityId + studentId}
                  onLastQuestion={finishQuestionnaire}
                  onSelectAnswer={onSelectAnswer}
                  saveFeedback={onSaveFeedback}
                  disableAnswers={peerReviewAlreadyReflected}
                  loading={(loadingSaveAnswer && !questionnaireExists) || loadingPeerReviewData}
                  questionnaire={{
                    questions,
                  }}
                />
              </Col>
              <Col span={12}>
                <Row justify="center">
                  <Pagination
                    style={{
                      zIndex: 1,
                    }}
                    size="small"
                    total={numPages}
                    pageSize={1}
                    current={pageNumber}
                    onChange={setPageNumber}
                  />
                </Row>

                {peerReview?.fileUrl && (
                  <InvestigationFileDisplay
                    file={peerReview?.fileUrl || ''}
                    page={pageNumber}
                    mimeType={peerReview?.fileMIMEtype || ''}
                    onLoadSuccess={onLoadSuccess}
                    containerRef={pdfRef}
                    videoRef={videoRef}
                  />
                )}
                {!peerReview?.fileUrl && !loadingPeerReviewData && (
                  <>
                    <Spacer size={80} />
                    <p style={{ textAlign: 'center' }}>This file was hidden by your teacher and will be reviewed</p>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(PeerReviewInvestigationPage);
