import styled from 'styled-components';

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  @media (max-width: 1024px) {
    padding: 1em;
  }
`;

export const Image = styled.img`
  width: auto;
  height: 50px;
  cursor: pointer;
  margin-left: 30px;

  @media (max-width: 1024px) {
    height: 40px;
    margin-left: 10px;
  }
`;

export const NavItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 10px;
  font-weight: 500;

  @media (max-width: 1024px) {
    gap: 5px;
  }
`;

export const NavItem = styled.div`
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 24px;
  color: black;
  font-size: 1rem;

  > button {
    background-color: #4367e9;
    color: white;
    height: 35px;
    width: 155px;
    border: 1px solid white;
    border-radius: 40px;
    cursor: pointer;
    margin-top: -20px;
  }

  @media (max-width: 1024px) {
    padding: 8px 16px;
    margin-right: 12px;
    font-size: 0.9rem;

    > button {
      height: 30px;
      width: 130px;
      font-size: 0.8rem;
    }
  }
`;

export const Modal = styled.div`
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: auto;
  height: auto;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 1024px) {
    min-width: 700px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  max-height: 80vh;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding: 15px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 1024px) {
    gap: 15px;
    margin-top: 10px;
  }
`;

export const ModalButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 35%;
  cursor: pointer;
  color: #333;
  background-color: white;
  border: none;
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  span {
    font-size: 1.2rem;
    font-weight: 500;
  }

  h6 {
    font-size: 12px;
    font-weight: 300;
  }

  svg {
    font-size: 2.5rem;
    font-weight: 300;
  }

  @media (max-width: 1024px) {
    width: 80%;
    padding: 8px;

    span {
      font-size: 1rem;
    }

    h6 {
      font-size: 10px;
    }

    svg {
      font-size: 2rem;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 25px;

  h1 {
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
    color: #333;
  }

  @media (max-width: 1024px) {
    margin-bottom: 10px;
    margin-top: 20px;

    h1 {
      font-size: 1.2rem;
    }
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #666;

  &:hover {
    color: red;
  }

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    padding: 15px;
  }
`;

export const LeftContent = styled.div`
  flex: 2;
  padding: 20px;
  min-width: 400px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  > ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  h1 {
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 900;
  }

  h2 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      font-size: 10px;
      font-weight: 100;
      margin-bottom: 5px;
      text-wrap: none;
    }
  }
`;

export const StackHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

export const HeaderCard = styled.div`
  box-shadow: 0px 0px 34px 2px #7b8cea1f;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;

  > h1 {
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: #333;
  }

  > img {
    width: 100%;
    height: auto;
    max-height: 160px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
`;

export const StackVertical = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  gap: 10px;
`;

export const Divider = styled.div`
  width: 3px;
  background-color: darkblue;
  margin: 0 20px;

  @media (max-width: 1024px) {
    width: 2px;
    margin: 0 10px;
  }
`;

export const OutlineButton = styled.button`
  height: min-content;
  outline: none;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.6rem 0.8rem;
  background-color: white;
  border: 1px solid #4367e9;
  color: #4367e9;
  border-radius: 40px;
  cursor: pointer;
`;
export const FilledButton = styled.button`
  height: min-content;
  outline: none;
  border: none;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.6rem 0.8rem;
  background-color: #4367e9;
  border: 1px solid white;
  border-radius: 40px;
  cursor: pointer;
  color: white;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;

  @media (max-width: 1024px) {
    gap: 10px;
  }
`;
export const GridWrapperProgram = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  ul {
    width: 100%;
  }

  @media (max-width: 1024px) {
    gap: 10px;
  }
`;
export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const ProfileImageWrapper = styled.div`
  flex-shrink: 0;
  align-self: start;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #f89d3e;
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  height: 200px;
  object-fit: cover;

  @media (max-width: 1024px) {
    width: 150px;
    height: 150px;
  }
`;

export const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 1024px) {
    gap: 8px;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  color: #000000;
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

export const ProfileName = styled.h2`
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  color: #000000;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

export const ProfileDescription = styled.p`
  margin: 0;
  font-size: 14px;
  color: #767676;
  text-align: justify;
  font-weight: 200;
  line-height: 1.5;
  overflow-y: auto;
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  color: #4367e9;

  @media (max-width: 1024px) {
    margin-top: 15px;
  }
`;

export const MoreIcon = styled.div`
  width: auto;
  height: auto;
  padding: 4px;
  border: 1px solid #4367e9;
  border-radius: 40px;
  display: flex;
  margin-left: 70%;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1024px) {
    margin-left: 50%;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 10px;
`;

export const IconButton = styled.button`
  height: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 1rem;
  background-color: white;
  border: 1px solid #767676;
  color: #767676;
  border-radius: 50%;
  cursor: pointer;

  > svg {
    margin: auto;
  }
`;

export const ProfileTitle = styled.h3`
  font-size: 16px;
  text-transform: uppercase;
  color: #4367e9;

  @media (max-width: 1024px) {
    font-size: 0.7rem;
  }
`;
