import React, { useMemo } from 'react';
import { Col, Row, Empty } from 'antd';
import Item from './Item';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg';  // Import the icon
import { GQL_GoogleClassroomCourse } from '../../../types/googleClassroom';

interface IActiveAssignments {
  assignments: GQL_GoogleClassroomCourse[];
  loading: boolean;
  courseId: string;
  courseName: string; // Add courseName prop
  className: string; // Add className prop
}

const ActiveAssignments: React.FC<IActiveAssignments> = ({ assignments, loading, courseId, courseName, className }) => {
  const sortedAssignments = useMemo(() => {
    return assignments.slice().sort((a, b) => {
      if (a.endDate && b.endDate) {
        return b.endDate.localeCompare(a.endDate);
      } else {
        return 0;
      }
    });
  }, [assignments]);

  const activeData = useMemo(() => {
    return (
      <>
        {loading ? (
          <Row gutter={[24, 24]}>
            {[1, 2, 3].map((index: number) => (
              <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
                <DashboardLoadingCard />
              </Col>
            ))}
          </Row>
        ) : sortedAssignments.length ? (
          <Row gutter={[24, 24]}>
            {sortedAssignments.map((item) => (
              <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
                <Item
                  description={item.description}
                  timeLeft={new Date(item.endDate).getTime()}
                  assignmentName={item.title}
                  assignmentId={item.courseWorkId}
                  timeWarning={false}
                  progress={0}
                  type={'Assignment'}
                  classId={courseId}
                  courseName={courseName} // Pass courseName here
                  className={className} // Pass className here
                  icon={<img src={AssignmentIcon} alt="Assignment Icon" />}  // Pass the icon
                  version={1}
                  courseWorkType={item.courseWorkType} // Pass courseWorkType
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Assignments" />
        )}
      </>
    );
  }, [loading, sortedAssignments, courseId, courseName, className]);

  return (
    <div>
      <h3>Active Assignments</h3>
      {activeData}
    </div>
  );
};

export default ActiveAssignments;
