import React, { useCallback, useMemo } from 'react';
import { Layer, ResponsiveLine } from '@nivo/line';
import { BackgroundRanges, LineChart } from '../../types/charts';
import * as S from './styles';
import { themeConfig } from '../../utils/theme';
import { Col, Row } from 'antd';
import { area, line } from 'd3-shape';
interface Props {
  title: string;
  data: LineChart[];
  legendAxisBottom: string;
  legendAxisLeft: string;
  maxY?: number;
  minY?: number;
  chartHeight?: string;
  lineColor?: string;
  xCircleColors?: string[];
  xTextColor?: string;
  extraLayerLine?: { percentage: number; color?: string; label: string };
  backgroundRanges?: BackgroundRanges[];
}

const LineChartWithBackground: React.FC<Props> = (props) => {
  const {
    data,
    maxY,
    minY = 0,
    legendAxisBottom,
    legendAxisLeft,
    extraLayerLine,
    title,
    chartHeight = '40vh',
    lineColor = themeConfig.primaryColor.background,
    xCircleColors,
    xTextColor = themeConfig.secondaryColor.color,
    backgroundRanges,
  } = props;
  const customSymbol = useCallback(
    ({ size, color, borderColor }) => (
      <g>
        <circle r={size} strokeWidth={6} stroke={borderColor} fill={color} fillOpacity={0.35} />
      </g>
    ),
    [],
  );

  const customAxisBottom = useCallback(
    (props) => (
      <g transform={`translate(${props.x},0)`}>
        <line x1="0" x2="0" y1="0" y2="5"></line>
        <circle
          r={12}
          fill={xCircleColors?.[props.tickIndex] || themeConfig.secondaryColor.background}
          transform="translate(0,20) rotate(0)"
          strokeWidth={13}
        ></circle>
        <text
          dominantBaseline="text-before-edge"
          textAnchor="middle"
          stroke={xTextColor}
          transform="translate(0,12) rotate(0)"
        >
          {props.value}
        </text>
      </g>
    ),
    [xCircleColors, xTextColor],
  );

  const legendItem = useCallback((item: BackgroundRanges, dataIndex: number) => {
    return (
      <Col
        key={dataIndex}
        xxl={8}
        lg={12}
        md={24}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <S.DotLegend $background={item.background}></S.DotLegend>
        <S.Title $fontSize="1.1em" $fontWeight="400" $color="#767676" $align="center" $marginLeft="5px">
          {item.title}
        </S.Title>
      </Col>
    );
  }, []);

  const getCoordinate = useCallback((yPercentage: number, innerHeight: number) => {
    if (yPercentage === 0) return innerHeight;

    return innerHeight - (yPercentage * innerHeight) / 100;
  }, []);

  const LineLayer = useCallback(
    ({ series, xScale, innerHeight, innerWidth }: any) => {
      const lineGenerator = line()
        .x((d: any) => xScale(d.data.x))
        .y((d: any) => getCoordinate(extraLayerLine?.percentage || 0, innerHeight));

      return (
        <>
          <path
            d={lineGenerator(series[0].data) || ''}
            fill="none"
            stroke={extraLayerLine?.color || '#000000'}
            style={{ pointerEvents: 'none' }}
          />
          <text
            dominantBaseline="text-before-edge"
            fontSize="12px"
            textAnchor="start"
            x={innerWidth - (extraLayerLine?.label.length || 0) * 6.5}
            y={getCoordinate((extraLayerLine?.percentage || 0) + 5.5, innerHeight)}
            stroke={extraLayerLine?.color}
          >
            {extraLayerLine?.label}
          </text>
        </>
      );
    },
    [extraLayerLine, getCoordinate],
  );

  const AreaLayer = useCallback(
    (range: BackgroundRanges, { series, xScale, innerHeight }: any) => {
      const areaGenerator = area()
        .x((d: any) => xScale(d.data.x))
        .y0((d: any) => getCoordinate(range.min, innerHeight))
        .y1((d: any) => getCoordinate(range.max, innerHeight));

      return (
        <>
          <path
            d={areaGenerator(series[0].data) || ''}
            fill={range.background}
            fillOpacity={0.3}
            stroke={range.background}
            strokeWidth={0}
          />
        </>
      );
    },
    [getCoordinate],
  );

  const withLegend = useMemo(() => (backgroundRanges && backgroundRanges.length > 0) || false, [backgroundRanges]);

  const backgroundLayers = useMemo(() => {
    const layers: Layer[] = ['grid', 'markers', 'axes', 'areas', 'crosshair'];
    if (extraLayerLine) layers.push(LineLayer);
    if (withLegend) {
      backgroundRanges?.forEach((d) => {
        layers.push((p: any) => AreaLayer(d, p));
      });
    }

    return [...layers, 'lines', 'points', 'slices', 'mesh', 'legends'] as Layer[];
  }, [AreaLayer, backgroundRanges, withLegend, extraLayerLine, LineLayer]);

  const tooltip = useCallback((point) => {
    return (
      <S.TootipWraper>
        <S.Title $fontSize="1em" $fontWeight="400" $color="#767676" $align="center">
          {`${point.point.data.tooltip} -> ${point.point.data.y}`}
        </S.Title>
      </S.TootipWraper>
    );
  }, []);

  return (
    <S.Card $chartHeight={chartHeight} $withLegend={withLegend}>
      <Row>
        <S.CardCol span={24}>
          <Row justify="start" gutter={[0, 8]}>
            <Col span={24}>
              <S.Title $marginLeft="20px">{title}</S.Title>
            </Col>
            <Col span={24} style={{ height: chartHeight }}>
              <ResponsiveLine
                data={data}
                margin={{ top: 10, right: 20, bottom: 60, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: minY, max: maxY || 'auto', stacked: true, reverse: false }}
                pointSize={3}
                colors={lineColor}
                pointSymbol={customSymbol}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                lineWidth={1}
                pointLabelYOffset={-12}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: legendAxisLeft,
                  legendOffset: -40,
                  legendPosition: 'middle',
                }}
                layers={backgroundLayers}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: legendAxisBottom,
                  renderTick: customAxisBottom,
                  legendOffset: 50,
                  legendPosition: 'middle',
                }}
                useMesh={true}
                tooltip={tooltip}
              />
            </Col>
          </Row>
        </S.CardCol>

        {withLegend ? (
          <Col span={24} style={{ borderTop: '1px solid #e3e3e3' }}>
            <Row gutter={[4, 8]} style={{ marginTop: 10 }}>
              <Col span={24}>
                <S.Title $fontSize="1.35em" $fontWeight="400" $color="#767676" $align="center">
                  LEGEND
                </S.Title>
              </Col>
              <Col span={24}>
                <Row justify="center" style={{ padding: '0 1em' }}>
                  {backgroundRanges?.map(legendItem)}
                </Row>
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
    </S.Card>
  );
};

export default LineChartWithBackground;
