import { useQuery } from '@apollo/client';
import { Layout, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter, useLocation } from 'react-router-dom';

import { gqlSchema } from '../../gql/schema';
import InvestigationContent from '../../shared/InvestigationContent';
import InvestigationNotes from '../../shared/InvestigationNotes';
import { GQL_InvestigationDisplay } from '../../types/investigation';
import InvestigationContentBuilder from '../StudentInvestigation/InvestigationContentBuilder';
import InvestigationDetailsSider from './InvestigationDetailsSider';

type Props = RouteComponentProps<
  {
    investigationId: string;
    studentId?: string;
    submissionVersion?: string;
  },
  any,
  {
    stepOrder?: number;
    stepName?: string;
    activityId?: string;
    commentId?: string;
  }
>;

const InvestigationTeacherDetailsPage: React.FC<Props> = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { investigationId, studentId } = props.match.params;
  const submissionVersion = props.match.params.submissionVersion ? parseInt(props.match.params.submissionVersion) : 1;
  const { stepName, stepOrder, activityId, commentId } = props.location.state || {};
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [isAssessment, setIsAssessment] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(studentId);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [currentActivityIndex, setCurrentActivityIndex] = useState<number>(0);

  const { data: investigationForTeacherData } = useQuery<
    { getInvestigationByIdForTeacher: GQL_InvestigationDisplay },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationByIdForTeacher, {
    variables: { id: investigationId },
    onCompleted: (data) => {
      if (data && data.getInvestigationByIdForTeacher) {
        const investigation = data.getInvestigationByIdForTeacher;
        setIsAssessment(investigation.isAssessment || false);

        let stepIndex: number | undefined;
        if (stepName) {
          stepIndex = investigation.steps.findIndex((s) => s.name === stepName);
        } else if (activityId) {
          stepIndex = investigation.steps.findIndex((s) => s.activities.some((a) => a.id === activityId));
        } else {
          stepIndex = 0;
        }

        if (stepIndex !== undefined && investigation.steps[stepIndex].activities?.length) {
          const activityIndex = activityId
            ? investigation.steps[stepIndex].activities.findIndex((a) => a.id === activityId)
            : 0;
          setCurrentActivityIndex(activityIndex);
          setCurrentStepIndex(stepIndex);
        }
      }
    },
    onError: (err) => {
      message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
    },
  });

  const investigation = investigationForTeacherData?.getInvestigationByIdForTeacher;

  const onFinishSettings = () => {
    history.push(`/teacher-dashboard/${isAssessment ? 'assessment' : 'investigation'}-summary/${investigationId}`);
  };

  const handleActivityIndexChange = (index: number) => {
    const existingParams = new URLSearchParams(history.location.search);
    existingParams.set('activity', (index ? index : 0).toString());
    history.replace({ pathname: location.pathname, search: existingParams.toString() });

    setCurrentActivityIndex(index);
    setCurrentContentIndex(0);
  };

  const handleStepIndexChange = (index: number) => {
    const existingParams = new URLSearchParams(history.location.search);
    existingParams.set('stage', (index ? index : 0).toString());
    history.replace({ pathname: location.pathname, search: existingParams.toString() });

    setCurrentStepIndex(index);
    handleActivityIndexChange(0);
  };

  const handleStudentChange = (value: string) => {
    const existingParams = new URLSearchParams(history.location.search);
    value ? existingParams.set('student', value) : existingParams.delete('student');
    history.replace({ pathname: location.pathname, search: existingParams.toString() });
    setSelectedStudentId(value);
  };

  useEffect(() => {
    if (!investigation) {
      return;
    }

    const stage = new URLSearchParams(history.location.search).get('stage');
    if (!!stage && setCurrentStepIndex) setCurrentStepIndex(parseInt(stage, 10));

    const activity = new URLSearchParams(history.location.search).get('activity');
    if (!!activity && setCurrentActivityIndex) setCurrentActivityIndex(parseInt(activity!, 10));

    const student = new URLSearchParams(history.location.search).get('student');
    if (!!student && setSelectedStudentId) setSelectedStudentId(student);

    if (!stage && !activity && stepOrder) {
      handleStepIndexChange(stepOrder - 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investigation]);

  const currentStep = investigation?.steps?.length ? investigation.steps[currentStepIndex ?? 0] : undefined;
  const currentActivity = currentStep ? currentStep.activities[currentActivityIndex ?? 0] : undefined;

  return (
    <Layout>
      <InvestigationDetailsSider
        showBackButton
        investigation={investigation}
        investigationId={investigationId}
        currentStepIndex={currentStepIndex}
        currentActivityIndex={currentActivityIndex}
        backUrl={
          !isAssessment ? `/teacher-dashboard/investigation-summary/${investigationId}${location.search}` : undefined
        }
        onFinishSettings={onFinishSettings}
        firstSetting={currentStepIndex === 0 && currentActivityIndex === 0}
        setCurrentStepByIndex={handleStepIndexChange}
        setCurrentActivityByIndex={handleActivityIndexChange}
        setSelectedStudentId={handleStudentChange}
        selectedStudentId={selectedStudentId}
      />
      <InvestigationContent allowFullscreen>
        <InvestigationContentBuilder
          submissionVersion={submissionVersion}
          currentContentIndex={currentContentIndex}
          buildInPersonContent={currentStep?.mode === 'INPERSON'}
          currentActivity={currentActivity}
          currentStepId={currentStep?.id || ''}
          setCurrentContentIndex={setCurrentContentIndex}
          investigationId={investigation?.id}
          selectedStudentId={selectedStudentId}
          defaultActiveComment={commentId}
          classId={investigationForTeacherData?.getInvestigationByIdForTeacher.classId}
        />
        <InvestigationNotes
          {...({
            safety: currentActivity?.safety,
            help: currentActivity?.help,
            inPersonTeacher: currentActivity?.inPersonTeacher,
            remoteTeacher: currentActivity?.remoteTeacher,
            inPersonTeacherTips: currentActivity?.inPersonTeacherTips,
          } || {})}
          mode={investigation?.steps[currentStepIndex ?? 0]?.mode}
        />
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(InvestigationTeacherDetailsPage);
