import { IQuestionnaire } from '../redux/modules/investigation';

/**
 * Function to generate a delay in code, should be awaited
 *
 * @param ms Time to wait in milliseconds
 */
export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const defaultOptions = [
  {
    text: 'No',
    value: 'No',
  },
  {
    text: 'Somewhat',
    value: 'Somewhat',
  },
  {
    text: 'Yes',
    value: 'Yes',
  },
];

export const questionnaireMock: IQuestionnaire = {
  title: 'Peer Review Questions for the Reports',
  questions: [
    {
      needsFeedback: true,
      title: 'Take a look at the introduction. Do you think:',
      subquestions: [
        {
          text: 'There is a good overview of the phenomenon?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The goal of the task and guiding question are clear?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'It is clear why it is useful or important to complete the task?',
          options: defaultOptions,
          isValid: true,
        },
      ],
    },
    {
      needsFeedback: true,
      title: 'Take a look at the method. Do you understand:',
      subquestions: [
        {
          text: 'What data or other information the author collected?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'How the author collected that data or other information?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'How the author analyzed the data or other information?',
          options: defaultOptions,
          isValid: true,
        },
      ],
    },
    {
      needsFeedback: true,
      title: 'Take a look at the argument. Do you think:',
      subquestions: [
        {
          text: 'The claim answers the guiding question?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The analysis of the data is appropriate and free from errors?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The interpretation of the analysis makes sense?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'There is enough evidence to support the claim?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The choice (or use) of evidence is well justified?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The concepts included in the argument are correct?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The claim is valid or acceptable?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'All the variables or symbols in the argument are defined?',
          options: defaultOptions,
          isValid: true,
        },
      ],
    },
    {
      needsFeedback: true,
      title: 'Look at the writing mechanics of the entire report. Did you think:',
      subquestions: [
        {
          text: 'It is easy to read and well organized?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The sentences are complete?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The subject and verb agree in each sentence?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The voice is active?',
          options: defaultOptions,
          isValid: true,
        },
        {
          text: 'The spelling, punctuation, and capitalization is accurate?',
          options: defaultOptions,
          isValid: true,
        },
      ],
    },
    {
      needsFeedback: true,
      title:
        'What other feedback you would like to give? Is there something that you really liked about their investigation report? Is the anything else that you noticed that could be improved?',
      feedbackTitle: '',
      subquestions: [],
    },
  ],
};
