import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi';

export const TitleInput = styled.p<{
  $color?: string;
}>`
  font-weight: 600;
  font-size: 0.95em;
  margin: 0 0 5px 0;
  color: ${(props) => props.$color || 'rgba(0, 0, 0, 0.85)'};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.95em;
  margin: 0;
  text-align: center;
`;

export const BackButton = styled(FiArrowLeft)`
  position: absolute;
  left: 52px;
  top: 52px;
  cursor: pointer;
`;
