import styled from 'styled-components';
import { Row, Col, Button, Modal } from 'antd';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
`;

export const RowForm = styled(Row)`
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const Title = styled.h1`
  margin-bottom: 25px;
  text-align: center;
  margin-top: 15px;
  font-weight: 700;
  font-size: 40px;
  color: #3f3e38;
`;

export const RowSP = styled(Row)`
  max-width: 100vw;
  max-height: 80vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 0;
  row-gap: 0;
  overflow: auto;
  row-gap: 0px;
  width: 100%;
`;

export const ColSP = styled(Col)`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background: #ffffff;
  width: 100%;
  max-width: 1200px;
  word-wrap: break-word;
  word-break: break-word;
  overflow: visible;
`;

export const StyledButton = styled(Button)`
  color: #000000;
  padding: 10px 20px;
`;

export const ColR = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    padding: 20px;
  }

  .ant-modal-header {
    border-bottom: none;
    text-align: center;
  }

  .ant-modal-title {
    font-size: 24px;
    font-weight: bold;
  }

  .ant-modal-body {
    padding: 20px;
  }

  .ant-modal-footer {
    border-top: none;
    text-align: center;
  }
`;