import styled from 'styled-components';
import { Button } from 'antd';
import { FiArrowLeft, FiTrash2 } from 'react-icons/fi';

export const Title = styled.p`
  font-weight: 600;
  font-size: 0.95em;
  margin: 0 0 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.95em;
  margin: 0;
  text-align: center;
`;

export const Group = styled.span<{
  $background?: string;
}>`
  font-weight: 500;
  font-size: 0.95em;
  text-align: center;
  color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  background: ${(props) => `${props.$background || props.theme.noColor.background}`};
`;

export const MenuIcon = styled(Button)`
  width: unset;
  height: unset;
  padding: 4px 4px;
  line-height: 0;
  border-radius: 4px;
  color: black;
  position: relative;
  top: 6px;
  &:hover,
  &:focus {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px 1px;
    color: black;
    border-color: #d9d9d9;
    background: ${(props) => `${props.theme.primaryColor.background || 'blue'}`};
  }
`;

export const EmptyDiv = styled.div`
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
`;

export const EmptyGroup = styled.p`
  font-weight: 500;
  font-size: 0.95em;
  margin: 0;
  text-align: center;
`;

export const DeleteIcon = styled(FiTrash2)`
  float: right;
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

export const BackButton = styled(FiArrowLeft)`
  position: absolute;
  left: 52px;
  top: 52px;
  cursor: pointer;
`;
