import styled from 'styled-components';
import { Button } from 'antd';

export const ViewMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.4em;
  margin: 0.7em 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #d9d9d9;
  transition: width 0.3s ease; // Smooth transition for line width change
`;

export const StyledButton = styled(Button)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  transition: transform 0.3s ease; // Smooth transition for button rotation
  &:hover {
    transform: scale(1.1); // Scale up button on hover for feedback
  }
`;
