import React, { useMemo } from 'react';
import * as S from './styles';
import { BarStageCompletion, GQL_InvestigationStageCompletion } from '../../../../types/charts';
import { Col, Empty, Row, Spin } from 'antd';
import { ResponsiveBar } from '@nivo/bar';
import { palette } from '../../../../utils/theme';

interface Props {
  loading: boolean;
  data?: GQL_InvestigationStageCompletion[];
}
const ChartStages = (props: Props) => {
  const { data = [], loading } = props;
  const existData = useMemo(() => data.length > 0, [data]);
  const chartData: BarStageCompletion[] = useMemo(() => {
    let index = -1;
    return data.map((record, i) => {
      index = i + 1 > palette.length ? 0 : index + 1;
      const completedUsers = record.completedUsers || 0;
      const totalUsers = data.length > 0 ? record.totalUsers : 0;
      const avg = (completedUsers * 100) / totalUsers || 0;

      return {
        stage: record.name,
        average: +avg.toFixed(2),
        color: palette[index],
      };
    });
  }, [data]);
  const emptyResult = useMemo(
    () => (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
      </Row>
    ),
    [],
  );

  const loadingChartSpinner = useMemo(
    () => (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={2}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    [],
  );

  const erroMessage = useMemo(() => {
    return loading ? loadingChartSpinner : emptyResult;
  }, [emptyResult, loading, loadingChartSpinner]);

  return (
    <Row>
      <Col span={24}>
        <S.Card $height={existData ? '55vh' : '25vh'}>
          {existData ? (
            <ResponsiveBar
              data={chartData}
              keys={['average']}
              indexBy="stage"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              colors={(d) => d.data.color}
              axisBottom={{
                tickSize: 6,
                tickPadding: 0,
                tickRotation: 0,
                legend: 'Stages',
                legendPosition: 'middle',
                legendOffset: 38,
              }}
              axisLeft={{
                tickSize: 6,
                tickPadding: 0,
                tickRotation: 0,
                legend: 'Average',
                legendPosition: 'middle',
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={'#ffffff'}
            />
          ) : (
            erroMessage
          )}
        </S.Card>
      </Col>
    </Row>
  );
};

export default ChartStages;
