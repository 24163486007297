import React, { useState } from 'react';
import { AssessmentDateOrTimePicker, DatePickerProps } from './DateOrtimePicker';
import { formatDateTime } from '../../../../utils/date';
import * as S from '../styles';
import { BiCheck, BiEdit } from 'react-icons/bi';

export const PickerElement: React.FC<DatePickerProps> = (props) => {
  const {
    isTimePicker = false,
    disabled = false,
    dateValue,
    format = 'MM.dd.yyyy',
    inputFormat = 'MM.DD.YYYY',
    handleChange,
    data = {},
  } = props;

  const [allowEdit, setAllowEdit] = useState(false);
  const [newDateValue, setNewDateValue] = useState(dateValue);

  return (
    <>
      <S.DateContainer>
        {allowEdit ? (
          <AssessmentDateOrTimePicker
            isTimePicker={isTimePicker}
            dateValue={dateValue}
            inputFormat={inputFormat}
            disabled={disabled}
            data={data}
            handleChange={(value: number) => setNewDateValue(value)}
          />
        ) : (
          <span role="none" >{formatDateTime(dateValue, format)}</span>
        )}

        <S.EditButton
          type="primary"
          shape="circle"
          icon={allowEdit ? <BiCheck /> : <BiEdit />}
          disabled={disabled}
          onClick={() => {
            setAllowEdit(!allowEdit);

            if (allowEdit) {
              handleChange(newDateValue);
            }
          }}
        />
      </S.DateContainer>
    </>
  );
};
