import { Col, Row, Table } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { centerAlign, Breakpoint } from '../../../../utils/antd';
import * as S from './styles';
import { GQL_OpenInvestigationsChartResponse, GQL_OpenInvestigation } from '../../../../types/charts';
import TableSearchBox from '../../../TableSearchBox';
import { FiSearch } from 'react-icons/fi';
import { formatDateTime } from '../../../../utils/date';
import Button from '../../../Button';
import { downloadCsv } from '../../../../utils/files';
import Papa from 'papaparse';

const TableBreakPoint: Breakpoint[] = ['lg'];

interface Props {
  loading: boolean;
  data?: GQL_OpenInvestigationsChartResponse;
}

const DetailInvestigations = (props: Props) => {
  const refInvestigationTitle = useRef<HTMLInputElement>(null);
  const [searchInvestigationVisible, setSearchInvestigationVisible] = useState(false);

  const refClassName = useRef<HTMLInputElement>(null);
  const [searchClassNameVisible, setSearchClassNameVisible] = useState(false);

  const { loading, data } = props;

  const columns = useMemo(() => {
    return [
      {
        title: 'Class',
        align: centerAlign,
        dataIndex: 'className',
        width: '20%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refClassName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchClassNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_OpenInvestigation) => {
          return record.className.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchClassNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refClassName && refClassName.current) {
                refClassName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Investigation',
        align: centerAlign,
        dataIndex: 'name',
        width: '25%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox ref={refInvestigationTitle} {...filterProps} />
        ),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchInvestigationVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_OpenInvestigation) => {
          return record.name.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchInvestigationVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refInvestigationTitle && refInvestigationTitle.current) {
                refInvestigationTitle.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Subject',
        align: centerAlign,
        dataIndex: 'subject',
        width: '10%',
        responsive: TableBreakPoint,
      },
      {
        title: 'Discipline',
        align: centerAlign,
        dataIndex: 'discipline',
        width: '15%',
        responsive: TableBreakPoint,
      },
      {
        title: 'Start Date',
        align: centerAlign,
        sorter: (a: GQL_OpenInvestigation, b: GQL_OpenInvestigation) => a.startDate - b.startDate,
        dataIndex: 'startDate',
        width: '10%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_OpenInvestigation) => {
          return formatDateTime(record.startDate);
        },
      },
      {
        title: 'End Date',
        align: centerAlign,
        sorter: (a: GQL_OpenInvestigation, b: GQL_OpenInvestigation) => a.endDate - b.endDate,
        dataIndex: 'endDate',
        width: '10%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_OpenInvestigation) => {
          return formatDateTime(record.endDate);
        },
      },
    ];
  }, [searchClassNameVisible, searchInvestigationVisible]);

  const downloadDetails = useCallback(() => {
    const headers = [['Class', 'Investigation', 'Subject', 'Discipline', 'Start Date', 'End Date']];
    const csvData =
      data?.openInvestigations?.map((row: GQL_OpenInvestigation) => {
        return [
          row.className,
          row.name,
          row.subject,
          row.discipline,
          formatDateTime(row.startDate),
          formatDateTime(row.endDate),
        ];
      }) || [];
    downloadCsv(
      Papa.unparse([...headers, ...csvData]),
      `Open investigations for ${data?.teacherFirstName} ${data?.teacherLastName}.csv`,
    );
  }, [data]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={3} offset={21}>
          <Button text="Download Details" block onClick={downloadDetails} />
        </Col>
        <Col span={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_OpenInvestigation) => record.id}
              pagination={false}
              bordered
              dataSource={data?.openInvestigations}
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default DetailInvestigations;
