import React, { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';
import { Form as AntdForm, Col, Row } from 'antd';
import Form from '../../Form';
import Input from '../../Input';
import Button from '../../Button';
import * as S from './styles';
import { CreateClassFlowStep } from '../../../types/class';
import { useForm } from 'antd/lib/form/Form';
import { useAuth } from '../../../hooks/useAuth';
import { themeConfig } from '../../../utils/theme';
interface Props {
  onFinishGroup: (groups: number) => void;
  students?: number;
  groups: number;
  loading: boolean;
  steps: CreateClassFlowStep[];
  setStep: Dispatch<SetStateAction<CreateClassFlowStep>>;
  'data-cy'?: string;
}

const GroupInfo: React.FC<Props> = (props) => {
  const { onFinishGroup, loading, students, setStep, steps, groups } = props;
  const onFinish = useCallback(
    async ({ groups }: { groups: number }) => {
      onFinishGroup(groups);
    },
    [onFinishGroup],
  );
  const [form] = useForm();
  const { isFacilitator } = useAuth();

  const onChangeGroups = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value && ((students && +value > students) || +value < 1)) {
        const stringValue = value.toString();
        form.setFieldsValue({ groups: stringValue.substring(0, stringValue.length - 1) });
      }
    },
    [form, students],
  );

  return (
    <Form onFinish={onFinish} form={form} data-cy={props['data-cy']}>
      <Row gutter={24}>
        {steps && steps.length > 0 && (
          <S.BackButton
            size={24}
            onClick={() => setStep && steps && setStep(steps[0])}
            data-cy="components-group-info-back-button"
          />
        )}
        <Col span={12} offset={6}>
          <S.Info>This will randomly create groups based off the number of</S.Info>
          <S.Info>students you have enrolled in this class</S.Info>
        </Col>
        <Col span={5} offset={7}>
          <S.TitleInput>Students Enrolled</S.TitleInput>
          <AntdForm.Item name="name" initialValue={props.students}>
            <Input disabled />
          </AntdForm.Item>
        </Col>
        <Col span={5}>
          <S.TitleInput>Number of Groups</S.TitleInput>
          <AntdForm.Item name="groups" initialValue={groups}>
            <Input type="number" onChange={onChangeGroups} min={0} max={students} />
          </AntdForm.Item>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          {!isFacilitator && (students || 0) > 40 && (
            <S.TitleInput $color={themeConfig.error.background}>The limit for students in a class is 40</S.TitleInput>
          )}
        </Col>
        <Col span={16} offset={4}>
          <Row gutter={16} justify="center">
            <Col span={12}>
              <AntdForm.Item>
                <Button
                  block
                  loading={loading}
                  text="Create Groups"
                  htmlType="submit"
                  data-cy="components-classes-row-setup-groups-button"
                />
              </AntdForm.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(GroupInfo);
