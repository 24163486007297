import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Form as AntdForm, Col, Row, message, Tooltip } from 'antd';
import * as S from './styles';
import Form from '../../Form';
import { useForm } from 'antd/lib/form/Form';
import { GQL_SendInviteResponse } from '../../../types/invites';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import InviteStudents from '../../InviteUsers';
import { UserDataProps } from '../../../types/user';
import Button from '../../Button';
import { onDownloadStudentTemplate } from '../../../utils/class';
import { themeConfig } from '../../../utils/theme';
import { FiFileText } from 'react-icons/fi';
import { startCase } from 'lodash';
interface Props {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  onUserCreated?: () => void;
  buttonTitle: string;
  organizationId?: string;
  role: string;
}
const initialInvite: UserDataProps[] = [{ firstName: '', lastName: '', email: '' }];

const BulkGenericUser: React.FC<Props> = (props) => {
  const [form] = useForm();
  const { setVisible, buttonTitle, onUserCreated, role, organizationId } = props;
  const [activeKey, setActiveKey] = useState('manual');
  const [hasUploadedFile, setHasUploadedFile] = useState(false);
  const [viewUsersDetails, setViewUsersDetails] = useState(false);
  const roleTitle = useMemo(() => startCase(buttonTitle.toLocaleLowerCase()), [buttonTitle]);
  const [hasErrorFile, setHasErrorFile] = useState(false);

  const [submitCreateAndSendInvites, { loading }] = useMutation<{ createAndSendInvites: GQL_SendInviteResponse[] }>(
    gqlSchema.InvitesSchema.mutations.SEND.createAndSendInvites,
    {
      onError: (err) => {
        message.error(err.message);
      },
      onCompleted: (data) => {
        message.success(`${roleTitle}s created successfully`);
        form.resetFields();
        setActiveKey('manual');
        setViewUsersDetails(false);
        setHasUploadedFile(false);
        if (onUserCreated) onUserCreated();
        if (setVisible) setVisible(false);
      },
      refetchQueries: ['GetInvites', 'pendingTransferInvites'],
      awaitRefetchQueries: true,
    },
  );

  const saveButton = useMemo(
    () => (
      <AntdForm.Item>
        <Button block text={`Add ${roleTitle}s`} htmlType="submit" loading={loading} />
      </AntdForm.Item>
    ),
    [roleTitle, loading],
  );

  const onImportEmails = useCallback(() => {
    setViewUsersDetails(true);
    setHasUploadedFile(false);
  }, [setHasUploadedFile]);

  const onReimport = useCallback(() => {
    setViewUsersDetails(false);
    setHasUploadedFile(false);
  }, [setHasUploadedFile]);

  const renderButtons = useCallback(() => {
    let buttonBar = (
      <>
        <Col span={12} offset={6}>
          {saveButton}
        </Col>
      </>
    );
    if (activeKey === 'file') {
      if (viewUsersDetails) {
        buttonBar = (
          <>
            <Col span={12}>
              <Button block text="Import Another File" htmlType="button" onClick={onReimport} />
            </Col>
            <Col span={12}>{saveButton}</Col>
          </>
        );
      } else {
        if (hasUploadedFile) {
          buttonBar = (
            <Col span={12} offset={6} style={{ marginBottom: 24 }}>
              <Button block disabled={hasErrorFile} text="Import Emails" htmlType="button" onClick={onImportEmails} />
            </Col>
          );
        }
      }
    }
    return <Row gutter={16}>{buttonBar}</Row>;
  }, [saveButton, activeKey, viewUsersDetails, onReimport, hasUploadedFile, onImportEmails, hasErrorFile]);

  const onFinish = useCallback(
    (data: { invites: UserDataProps[] }) => {
      const invites = data.invites.map((invite: UserDataProps) => {
        const newInvite = { ...invite, role };
        if (organizationId) newInvite.organizationId = organizationId;
        return newInvite;
      });
      submitCreateAndSendInvites({
        variables: {
          data: invites.map((invite) => ({
            ...invite,
            tags: invite.tags?.map((t) => ({ ...t, __typename: undefined })),
          })),
        },
      });
    },
    [submitCreateAndSendInvites, role, organizationId],
  );
  return (
    <Form onFinish={onFinish} form={form} initialValues={{ invites: initialInvite }}>
      <Row justify="center">
        <Col span={20}>
          <InviteStudents
            form={form}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            setHasUploadedFile={setHasUploadedFile}
            viewUsersDetails={viewUsersDetails}
            setHasErrorFile={setHasErrorFile}
            hasErrorFile={hasErrorFile}
            initialInvite={initialInvite}
            userType={role}
          />
        </Col>
        {activeKey === 'file' && (
          <Col span={1}>
            <S.DownloadTemplate>
              <Tooltip title="Click here to download a template to import your users data." placement="bottom">
                <FiFileText
                  size={24}
                  onClick={onDownloadStudentTemplate}
                  style={{ cursor: 'pointer' }}
                  color={themeConfig.secondaryColor.background}
                />
              </Tooltip>
            </S.DownloadTemplate>
          </Col>
        )}
        <Col span={16}>{renderButtons()}</Col>
      </Row>
    </Form>
  );
};

export default React.memo(BulkGenericUser);
