import React, { useState, useMemo } from 'react';
import { Row, Col, Empty, Input } from 'antd';
import startCase from 'lodash/startCase';
import Item from './Item';
import { formatDateTime } from '../../../utils/date';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg';

interface IFinishedAssignmentsRow {
  loading: boolean;
  finishedAssignments: any[];
  courses: any[];
}

const CFinishedAssignmentsRow: React.FC<IFinishedAssignmentsRow> = ({ loading, finishedAssignments, courses }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredAssignments = useMemo(() => {
    return finishedAssignments.filter((assignment) =>
      assignment.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [finishedAssignments, searchQuery]);

  const renderAssignments = () => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          {[1, 2, 3].map((index) => (
            <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      );
    } else if (filteredAssignments.length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Finished Assignments" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {filteredAssignments.map((assignment, index) => {
            const course = courses.find((c) => c.name === assignment.courseName);
            const courseId = course?.id || 'Unknown Course ID';
            const className = assignment.courseName || 'Unknown Class';
            const classColor = course ? course.color : '#000000';

            return (
              <Col key={`${assignment.id}-${index}`} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
                <Item
                  date={formatDateTime(assignment.created_at)}
                  assignmentName={startCase(assignment.name.toLowerCase())}
                  assignmentId={assignment.id}
                  courseId={courseId}
                  className={className}
                  classColor={classColor}
                  icon={<img src={AssignmentIcon} alt="Assignment Icon" style={{ width: 24, height: 24 }} />}
                  workType={assignment.submission_types?.[0]}
                  description={assignment.description}
                />
              </Col>
            );
          })}
        </Row>
      );
    }
  };

  return (
    <>
      <h2>Finished Assignments</h2>
      <Input.Search
        placeholder="Search assignments"
        value={searchQuery}
        onChange={handleSearch}
        style={{ width: 200, marginBottom: '16px' }}
      />
      {renderAssignments()}
    </>
  );
};

export default CFinishedAssignmentsRow;
