import type { FC } from 'react';
import React from 'react';
import { useDrag } from 'react-dnd';

import { ItemTypes } from '../types';
import { CategoryTargetContainer, ContainerDragTarget } from '../styles';

export interface CardProps {
  id: number;
  text: string;
  index: number;
  canDragElement: boolean;
  showSuccess: boolean;
  isCorrectAnswer: boolean;
  isImageVersion?: boolean;
}

export interface DragItem {
  index: number;
  id: number;
  type: string;
  text: string;
  isAnswer: boolean;
}

export const Card: FC<CardProps> = ({
  id,
  text,
  index,
  showSuccess,
  isCorrectAnswer,
  canDragElement,
  isImageVersion = false,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index, text, isAnswer: true };
    },
    canDrag: canDragElement,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  return (
    <CategoryTargetContainer showSuccess={showSuccess} isCorrectAnswer={isCorrectAnswer} isImage={isImageVersion}>
      <ContainerDragTarget ref={drag} style={{ opacity }} beingDragged={isDragging} isImage={isImageVersion}>
        {isImageVersion ? (
          <img key={`image-category-drag-${index}`} src={text} alt={`Category Draggable Option ${index + 1}`} />
        ) : (
          <>{text}</>
        )}
      </ContainerDragTarget>
    </CategoryTargetContainer>
  );
};
