import { gql } from '@apollo/client';

export const ACCOUNT_FRAGMENTS = {
  subscription: gql`
    fragment Subscription on SubscriptionResponse {
      id
      userId
      organizationId
      invitesAvailable
      startDate
      endDate
      isTrial
      customLibrary
      investigationLibraryLimit
      writerQuantity
      readerPermissions
      taCustomPermissions
      teachersCanDoReviews
      reportPermission
      type
      allowedGradeBands
      allowedSubjects
      allowedDisciplines
      allowedFeatures
      maxClasses
      maxAssessments
      maxInvestigations
    }
  `,
};

export default ACCOUNT_FRAGMENTS;
