/* eslint-disable max-lines */
import { gql } from '@apollo/client';

const STATES_QUERIES = {
  GET: {
    getStates: gql`
      query getStates {
        getStates {
          id
          name
          postalCode
        }
      }
    `,
  },
};

export default STATES_QUERIES;
