import { gql } from '@apollo/client';

const WHITELISTED_DOMAIN_QUERIES = {
  getWhitelistedDomains: gql`
    query GetWhitelistedDomains {
      getWhitelistedDomains {
        id
        domain
      }
    }
  `,
};

export default WHITELISTED_DOMAIN_QUERIES;
