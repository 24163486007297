import React, {  useState } from 'react';
import { Input, message } from 'antd';
import * as S from './styles';
import {  useMutation, useQuery, } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

interface ProfileData {
  name: string;
  email: string;
  phone: string;
  company: string;
  jobDescription: string;
  address: string;
  city: string;
  country: string;
  state: string;
  username: string;
}

const BookUserProfile: React.FC = () => {
  const [profileData, setProfileData] = useState<ProfileData>({
    name: '',
    email: '',
    username: '',
    phone: '',
    company: '',
    jobDescription: '',
    address: '',
    city: '',
    country: '',
    state: '',
  });
  const history = useHistory();
  
  const {  refetch} = useQuery(
    gqlSchema.ShopifySchema.queries.LIST.getUserMetaData,
    {
      onCompleted: (data) => {
        setProfileData({ ...data?.getUserMetaData });
      },
      onError: (error) => {
        message.error(`Error fetching profile data - ${error.message || 'Unexpected Error'}`);
      },
    }
  );
  
  const [updateProfile] = useMutation(gqlSchema.BookSchema.mutations.UpdateBookUser, {
    onCompleted: () => {
      message.success('Profile updated successfully');
      refetch(); // Refetch profile data after updating
    },
    onError: (e) => {
      message.error('Error updating profile: ' + e.message || 'Unexpected Error');
    },
  });
  
  const handleInputChange = (key: keyof ProfileData, value: string) => {
    setProfileData((prev) => ({ ...prev, [key]: value }));
  };
  
  const handleBlur = () => {
    updateProfileData(profileData);
  };
  
  const updateProfileData = (data: ProfileData) => {
    updateProfile({
      variables: {
        metadata: JSON.stringify(data),
        email: data.username,
      },
    });
  };
  
  return (
    <>
    <S.Header>
   <FiArrowLeft
          onClick={() => history.goBack()}
          style={{ cursor: 'pointer', color: 'black', fontSize: '24px', margin: '10px' }}
        />
    <S.Title>Your Personal Information</S.Title>
    </S.Header>
    <S.Container>
      <S.Section>
        <S.SectionTitle>Personal Details</S.SectionTitle>
        <S.DetailsGrid>
          <S.DetailItem>
            <S.Label>Name</S.Label>
            <Input
              value={profileData.name}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </S.DetailItem>
          <S.DetailItem>
            <S.Label>Email Address</S.Label>
            <Input
              value={profileData.email}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange('email', e.target.value)}
            />
          </S.DetailItem>
          <S.DetailItem>
            <S.Label>Phone</S.Label>
            <Input
              value={profileData.phone}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange('phone', e.target.value)}
            />
            <S.EditIcon />
          </S.DetailItem>
          <S.DetailItem>
            <S.Label>Company</S.Label>
            <Input
              value={profileData.company}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange('company', e.target.value)}
            />
          </S.DetailItem>
          <S.DetailItem>
            <S.Label>Job Description</S.Label>
            <Input
              value={profileData.jobDescription}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange('jobDescription', e.target.value)}
            />
            <S.EditIcon />
          </S.DetailItem>
        </S.DetailsGrid>
      </S.Section>

      <S.Section>
        <S.SectionTitle>Address Details</S.SectionTitle>
        <S.DetailsGrid>
          <S.DetailItem>
            <S.Label>Address</S.Label>
            <Input
              value={profileData.address}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange('address', e.target.value)}
            />
            <S.EditIcon />
          </S.DetailItem>
          <S.DetailItem>
            <S.Label>Country</S.Label>
            <Input
              value={profileData.city}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange('city', e.target.value)}
            />
            <S.EditIcon />
          </S.DetailItem>
          <S.DetailItem>
            <S.Label>State</S.Label>
            <Input
              value={profileData.country}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange('country', e.target.value)}
            />
            <S.EditIcon />
          </S.DetailItem>
          <S.DetailItem>
            <S.Label>City</S.Label>
            <Input
              value={profileData.state}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange('state', e.target.value)}
            />
            <S.EditIcon />
          </S.DetailItem>
        </S.DetailsGrid>
      </S.Section>
    </S.Container>
    </>
  );
};

export default BookUserProfile;