import styled from 'styled-components';

export const AnnouncementContainer = styled.div`
  max-width: 600px;
  margin: auto;

  & > h1 {
    text-align: center;
    font-size: 24px;
  }

  & > h2 {
    text-align: center;
    font-size: 16px;
    line-height: 1.2;
    max-width: 300px;
    margin: auto;

    color: #767676;
  }
`;
