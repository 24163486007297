import styled from 'styled-components';
import { getInvestigationBackgroundColor } from '../../utils/investigationLogos';

export const MainIconWrapper = styled.div<{ subject: string; size: number }>`
  border-radius: 50%;
  background: ${(props) => getInvestigationBackgroundColor({ subject: props.subject })};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 2em;
  padding: ${(props) => (props.size < 75 ? 8 : 16)}px;
  margin: 0.5em 0 0.9em 0;

  svg,
  span {
    height: 100%;
    width: 100%;
  }
`;
