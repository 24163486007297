import styled from 'styled-components';

export const TableWrapper = styled.div`
  margin: 15px 0;

  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const Title = styled.h3`
  font-size: 1.5em;
  display: inline;
`;
