import styled from 'styled-components';
import { Col as ColAntd, Badge } from 'antd';
import Button from '../../../shared/Button';

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.4em;
  margin: 0.2 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

   @media (max-width: 768px) {
    margin-top:20px;
    }
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 2px;
`;

export const Col = styled(ColAntd)`
  padding: 0;
  text-align: center;
`;

export const SearchIcon = styled.span<{
  $searchVisible: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 10px 4px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => (props.$searchVisible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px' : 'unset')};
`;

export const SendInviteDisclaimer = styled.div`
  display: inline;
`;

export const ResendInvite = styled.span`
  display: inline;
  margin: 0 4px;
  font-weight: 500;
  cursor: pointer;
`;

export const BadgeWrapper = styled(Badge)`
  width: 100%;
`;
