import { Popover, Switch } from 'antd';
import React, { useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import Input from '../../../../../shared/Input';
import Spacer from '../../../../../shared/Spacer';
import UploadInput from '../../../../../shared/UploadInput';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  IMediaContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';

interface ISimulationBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const SimulationBlockEditor = (props: ISimulationBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const mediaValue = editingBlock.values[0] as IMediaContentValue;
  const [isUrl, setIsUrl] = useState(true);

  const handleClearSimulation = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();

    handleEditSimulationAttributes({ file: undefined });
  };

  const beforeUploadSimulation = (file: File) => {
    if (!file.name.includes('.nlogo')) return false;

    handleEditSimulationAttributes({ file });
    return true;
  };

  const handleEditSimulationInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const urlRegex = /src="([^"]*)"/gi;
      const isIframe = e.target.value.slice(0, 7).toLocaleLowerCase() === '<iframe';
      const urlMatches = e.target.value.match(urlRegex);

      if (isIframe && urlMatches?.length) {
        handleEditSimulationAttributes({ url: urlMatches[0].replace(urlRegex, '$1') });
      } else {
        handleEditSimulationAttributes({ url: e.target.value });
      }
    } else {
      handleEditSimulationAttributes({ url: e.target.value });
    }
  };

  const handleEditSimulationAttributes = (payload: { url?: string; file?: File }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;

        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;

              return {
                ...block,
                values: [{ url: payload.file ? 'https://netlogoweb.org/web?' : payload.url, file: payload.file }],
              };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleChangeUploadMethod = () => {
    setIsUrl(!isUrl);

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;

              return { ...block, values: [] };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  return (
    <>
      <S.UploadFromComputerContainer onClick={handleChangeUploadMethod} pointer>
        <h2>Upload from NLOGO file</h2>
        <Switch checked={!isUrl} size="small" />
      </S.UploadFromComputerContainer>
      <Spacer />
      <S.UploadFromComputerContainer>
        <h2>Simulation</h2>
        <Popover
          placement="rightTop"
          content={
            isUrl ? (
              <div style={{ maxWidth: 600 }}>
                <p>
                  Supported simulations: <b>PhET</b>, <b>NetLogo</b>, <b>Concord</b>, <b>GeoGebra</b> and <b>Desmos</b>.
                </p>
                <p>
                  For all investigations, you can input either the URL or the embedded iFrame. If the iFrame is pasted,
                  the URL will be extracted automatically.
                </p>
                <p>
                  <b>PhET:</b> To add PhET simulations, go into the desired simulation, click on "{'</>'} EMBED" under
                  the simulation image and paste the text under "Embed a running copy of this simulation" title
                </p>
                <p>
                  <b>Concord:</b> To add Concord simulations, search for "Interactives" and select the desired
                  interactive. Select "Embeddable" on the top of the screen, copy the URL, and paste it in the
                  "Simulation URL" field.
                </p>
                <p>
                  <b>GeoGebra and Desmos:</b> On both GeoGebra and Desmos, navigate to the type of calculator you want
                  to include, copy the URL, and add "?embed" to the end of the URL. Example:
                  "https://www.geogebra.org/geometry" {'=>'} "https://www.geogebra.org/geometry?embed". Copy the URL,
                  and paste it in the "Simulation URL" field.
                </p>
              </div>
            ) : (
              <div style={{ maxWidth: 600 }}>
                <p>
                  Supported simulations: <b>PhET</b>, <b>NetLogo</b>, <b>Concord</b>, <b>GeoGebra</b> and <b>Desmos</b>.
                </p>
                <p>
                  <b>NetLogo:</b> To include a NetLogo simulation, navigate to the desired model. On the top right
                  corner, click on "Export: NetLogo" to download the file with the ".nlogo" extension. Upload that file
                  here.
                </p>
              </div>
            )
          }
        >
          <FiHelpCircle size={20} />
        </Popover>
      </S.UploadFromComputerContainer>
      {isUrl ? (
        <Input
          value={mediaValue?.url}
          backgroundColor="white"
          onChange={handleEditSimulationInput}
          placeholder="Simulation URL"
        />
      ) : (
        <>
          <UploadInput
            beforeUpload={(f) => beforeUploadSimulation(f)}
            title={mediaValue?.file?.name || 'Upload simulation (NetLogo)'}
            showClear={!!mediaValue?.file}
            onClear={(e) => handleClearSimulation(e)}
            accept=".nlogo"
            defaultFileList={[]}
            fileList={[]}
            customRequest={() => {}}
          />
          <Spacer />
          <h2>Current URL</h2>
          <Input
            disabled
            value={mediaValue?.file ? '' : mediaValue?.url}
            backgroundColor="white"
            placeholder="Simulation URL"
          />
        </>
      )}
    </>
  );
};

export default SimulationBlockEditor;
