import { Col, Row, Table, Tooltip, Typography } from 'antd';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { centerAlign, Breakpoint } from '../../../../utils/antd';
import * as S from './styles';
import { GQL_TeacherScores } from '../../../../types/charts';
import TableSearchBox from '../../../TableSearchBox';
import { FiSearch } from 'react-icons/fi';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const TableBreakPoint: Breakpoint[] = ['lg'];

interface Props {
  loading: boolean;
  pagination?: TablePaginationConfig;
  setPagination?: Dispatch<SetStateAction<TablePaginationConfig | undefined>>;
  data?: GQL_TeacherScores[];
}

const DetailTable = (props: Props) => {
  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchLastNameVisible, setSearchLastNameVisible] = useState(false);
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);

  const { loading, data, setPagination, pagination } = props;

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      if (pagination && setPagination) setPagination(pagination);
    },
    [setPagination],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'First Name',
        align: centerAlign,
        dataIndex: 'teacherFirstName',
        width: '15%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_TeacherScores) => {
          return record.teacherFirstName.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Last Name',
        align: centerAlign,
        dataIndex: 'teacherLastName',
        width: '15%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refLastName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchLastNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_TeacherScores) => {
          return record.teacherLastName.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchLastNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refLastName && refLastName.current) {
                refLastName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Email',
        align: centerAlign,
        dataIndex: 'teacherEmail',
        width: '15%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refEmail} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_TeacherScores) => {
          return record.teacherEmail.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Avg. Report Score',
        align: centerAlign,
        sorter: (a: GQL_TeacherScores, b: GQL_TeacherScores) => a.averageReportScores - b.averageReportScores,
        dataIndex: 'averageReportScores',
        width: '15%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_TeacherScores, index: number) => {
          return `${record?.averageReportScores?.toFixed(2) || 0} / ${record?.maxReportScore?.toFixed(2) || 0}`;
        },
      },
      {
        title: () => (
          <Tooltip title="Any score at the 100% mark indicates the student has met performance expectations. Any score past the 100% mark indicates the student has exceeded performance expectations.">
            <Row align="middle" gutter={[4, 0]}>
              <AiOutlineInfoCircle style={{ marginTop: -4, marginRight: 4 }} />
              <Typography.Text>% Avg. Report Score</Typography.Text>
            </Row>
          </Tooltip>
        ),
        align: centerAlign,
        showSorterTooltip: false,
        dataIndex: 'averageReportScoresPercentage',
        key: 'averageReportScoresPercentage',
        width: '20%',
        sorter: (a: any, b: any) => a.averageReportScoresPercentage - b.averageReportScoresPercentage,
        render: (text: any) => Math.round(text * 100) + '%',
      },
      {
        title: 'Avg. Engagement Score',
        sorter: (a: GQL_TeacherScores, b: GQL_TeacherScores) => a.averageReportScores - b.averageReportScores,
        align: centerAlign,
        dataIndex: 'teacherEmail',
        width: '20%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_TeacherScores, index: number) => {
          return record.averageEngagementScores.toFixed(2) || 0;
        },
      },
    ];
  }, [searchEmailVisible, searchLastNameVisible, searchNameVisible]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_TeacherScores) => record.teacherId}
              pagination={{ ...pagination, hideOnSinglePage: true }}
              bordered
              dataSource={data}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default DetailTable;
