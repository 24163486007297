import styled from 'styled-components';

export const HorizontalDivider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 24px 0;
`;

export const CustomImageHolder = styled.div`
  img {
    width: 50px;
    height: 50px;
  }
`;
