import React, { useState, useMemo } from 'react';
import { Row, Col, Empty, Input } from 'antd';
import Item from './Item';
import { formatDateTime, formatDistance } from '../../../utils/date';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg';
import * as S from './styles';

interface IActiveAssignmentsRow {
  loading: boolean;
  assignmentData: any[];
  courses: any[];
}

const GActiveAssignmentsRow: React.FC<IActiveAssignmentsRow> = ({ loading, assignmentData, courses }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredAssignments = useMemo(() => {
    return assignmentData.filter((assignment) =>
      assignment.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [assignmentData, searchQuery]);

  const activeAssignments = loading ? (
    <Row gutter={[24, 24]}>
      {[1, 2, 3].map((index) => (
        <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <DashboardLoadingCard />
        </Col>
      ))}
    </Row>
  ) : filteredAssignments.length ? (
    <Row gutter={[24, 24]}>
      {filteredAssignments.map((assignment) => {
        const course = courses.find((c) => c.id === assignment.courseId);
        const className = course ? course.name : '';
        const classColor = course ? course.color : ''; // Assuming courses have a color property

        return (
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={assignment.id}>
            <Item
              date={formatDateTime(assignment.creationTime)}
              assignmentName={assignment.title}
              assignmentId={assignment.courseWorkId} // courseWorkId instead of id
              courseId={assignment.courseId}
              timeLeft={`${formatDistance(assignment.dueDate)} left`}
              timeWarning={false}
              icon={<img src={AssignmentIcon} alt="Assignment Icon" />}
              className={className}
              classColor={classColor}
              courseWorkType={assignment.courseWorkType} // Added courseWorkType prop
            />
          </Col>
        );
      })}
    </Row>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Assignments" />
  );

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col>
        <S.Title>Active Assignments</S.Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <Input.Search
            placeholder="Search assignments"
            value={searchQuery}
            onChange={handleSearch}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      {activeAssignments}
    </>
  );
};

export default GActiveAssignmentsRow;
