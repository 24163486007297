import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f0f2f5;
`;

export const RowSP = styled(Row)`
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

export const ColSP = styled(Col)`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background: #ffffff;
  width: 100%;
  max-width: 1200px;
`;

export const Title = styled.h1`
  text-align: center;
  color: #3f3e38;
  margin: 10px 0;
  font-size: 32px;
  font-weight: 700;
`;

export const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-top: 20px;
`;

export const RowForm = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;

  .ant-form-item {
    width: 100%;
  }
`;