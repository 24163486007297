import { useQuery } from '@apollo/client';
import { Col, Row, Dropdown, Table, Menu, message } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import Papa from 'papaparse';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FiChevronDown, FiDownload, FiSearch } from 'react-icons/fi';
import { useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import AddUser from '../../shared/AddUser';
import Button from '../../shared/Button';
import { centerAlign, Breakpoint } from '../../utils/antd';
import { downloadCsv } from '../../utils/files';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';
import { GQL_OrganizationAdminList, GQL_OrganizationResponse } from '../../types/organization';
import TableSearchBox from '../../shared/TableSearchBox';

const TableBreakPoint: Breakpoint[] = ['lg'];

const OrganizationList = () => {
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [searchNameVisible, setSearchNameVisible] = useState(false);

  const refName = useRef<HTMLInputElement>(null);

  const { data, loading } = useQuery<{ getAllOrganizations: GQL_OrganizationResponse[] }>(
    gqlSchema.OrganizationSchema.queries.LIST.getAllOrganizations,
    {
      fetchPolicy: 'network-only',
      onError: (err) => {
        message.error('There was an error loading the organizations: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const history = useHistory();

  const onViewMore = useCallback(
    (organization: GQL_OrganizationResponse) => {
      history.push(`/adi-organizations/${organization.id}`, {
        name: organization.name,
      });
    },
    [history],
  );
  const columns = useMemo(
    () => [
      {
        title: 'Name',
        align: centerAlign,
        dataIndex: 'name',
        width: '50%',
        responsive: TableBreakPoint,
        sorter: (a: GQL_OrganizationResponse, b: GQL_OrganizationResponse) => a.name.localeCompare(b.name),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_OrganizationResponse) => {
          if (!value) return true;
          return record.name.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Admins',
        align: centerAlign,
        width: '35%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_OrganizationResponse) => (
          <>
            {record.organizationAdmins
              .slice(0, record.organizationAdmins.length > 1 ? 2 : 1)
              .map((admin: GQL_OrganizationAdminList) => (
                <S.TagButton
                  key={admin.userId}
                  text={admin.name}
                  theme={themeConfig.primaryOutlined}
                  shape="round"
                  minHeight={24}
                />
              ))}

            {record.organizationAdmins.length > 2 && (
              <S.TagButton
                text={`+ ${record.organizationAdmins.length - 2}`}
                background="#c4c4c4"
                shape="round"
                minHeight={24}
              />
            )}
          </>
        ),
      },
      {
        title: 'Organization Details',
        align: centerAlign,
        render: (text: string, record: GQL_OrganizationResponse) => {
          return (
            <Button
              text="View More Details"
              onClick={() => onViewMore(record)}
              block
              theme={themeConfig.primaryColor}
            />
          );
        },
        width: '15%',
      },
    ],
    [onViewMore, searchNameVisible],
  );

  const exportOrganizations = useCallback(() => {
    const headers = [['Name', 'First Admin', 'Second Admin']];
    const csvData =
      data?.getAllOrganizations.map((organization: GQL_OrganizationResponse) => {
        return [organization.name, organization.organizationAdmins[0]?.name, organization.organizationAdmins[1]?.name];
      }) || [];

    downloadCsv(Papa.unparse([...headers, ...csvData]), `Users.csv`);
  }, [data]);

  const exportMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={exportOrganizations}>
          .csv
        </Menu.Item>
      </Menu>
    ),
    [exportOrganizations],
  );

  return (
    <S.Container>
      <Row gutter={[24, 24]}>
        <Col xxl={{ span: 3, offset: 3 }} xl={4} lg={5} md={6} sm={12} xs={12}>
          <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
            <Button
              padding="4px 5px"
              text={
                <>
                  Export as
                  <FiChevronDown style={{ marginLeft: 3 }} />
                </>
              }
              theme={themeConfig.noColor}
              icon={<FiDownload />}
              block
            />
          </Dropdown>
        </Col>
        <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_OrganizationResponse) => record.id}
              bordered
              dataSource={data?.getAllOrganizations}
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <AddUser visible={addUserVisible} setVisible={setAddUserVisible} />
    </S.Container>
  );
};

export default withRouter(OrganizationList);
