import styled from 'styled-components';

export const GaugeContainer = styled.div<{ type?: 'report' | 'engagement'; meetsExpectation?: boolean }>`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 28px;
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 2px;
  }

  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;
  }

  .ant-progress-text {
    font-size: 0.75em;
  }

  ${(props) =>
    props.type === 'report' &&
    `
    .ant-progress-circle {
      .ant-progress-circle-path:nth-child(3) {
        ${props.meetsExpectation ? 'stroke: #00000099 !important;' : ''}
        stroke-dashoffset: calc((38 / 57) * (-219.6646px - -55.6646px) + -55.6646px) !important;
      }
      .ant-progress-circle-path:nth-child(2) {
        stroke-dashoffset: -55.6646px !important;
      }
    }
  `}
`;
