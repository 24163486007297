import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import CircularSpin from '../../shared/CircularSpin';
import Spacer from '../../shared/Spacer';
import * as S from './styles';

type Props = RouteComponentProps<null, any, any>;

const LoginSSOPage = (props: Props) => {
  const queryStringParams = props.location.search;
  const queryStringParamsObj = new URLSearchParams(queryStringParams);
  const code = queryStringParamsObj.get('code');
  const provider = queryStringParamsObj.get('provider');
  const history = useHistory();

  useEffect(() => {
    if (!provider && !code) {
      history.push('/login');
    } else {
      const url = `/login?provider=${provider}&code=${code}`;
      if (!window.opener) {
        history.push(url);
      } else {
        window.opener.location = url;
        window.close();
      }
    }
  }, [history, code, provider]);

  return (
    <S.LoginSSOPageContainer>
      <h1>Authenticating with SSO</h1>
      <Spacer size={48} />
      <CircularSpin />
    </S.LoginSSOPageContainer>
  );
};

export default withRouter(LoginSSOPage);
