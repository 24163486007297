import { Spin, Upload, Modal, message as antdMessage } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiPlus, FiSend } from 'react-icons/fi';
import Input from '../../shared/Input';
import PageWithTitle from '../../shared/PageWithTitle';
import emptyMessagesSrc from '../../assets/empty-messages.svg';
import * as S from './styles';
import { RcFile } from 'antd/lib/upload';
import { NetworkStatus, useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_ChatListResponse, GQL_GetMessages, GQL_MessagesSubscriptionResponse } from '../../types/chat';
import { formatDateTime } from '../../utils/date';
import { GQL_PaginationInput } from '../../types/pagination';
import InfiniteScroll from 'react-infinite-scroller';
import ChatRoomsSider from './ChatRoomsSider';
import { RiDownloadLine } from 'react-icons/ri';
import { useAuth } from '../../hooks/useAuth';
import uniqBy from 'lodash/uniqBy';

const MESSAGES_PAGE_SIZE = 10;

const MessageListPage = () => {
  const [selectedChatRoom, setSelectedChatRoom] = useState<GQL_ChatListResponse>();
  const [currentMessagesPage, setCurrentMessagesPage] = useState(1);
  const [file, setFile] = useState<RcFile>();
  const [message, setMessage] = useState<string>();
  const { user } = useAuth();
  const [chatRoomIdsWithNewMessages, setChatRoomIdsWithNewMessages] = useState<string[]>([]);

  const [
    getMessages,
    { data: messagesData, loading: loadingMessages, fetchMore, networkStatus, client },
  ] = useLazyQuery<{ getMessages: GQL_GetMessages }, { data: { chatId: string; pagination: GQL_PaginationInput } }>(
    gqlSchema.ChatSchema.query.getMessages,
    {
      notifyOnNetworkStatusChange: true,
      onError: (err) => {
        antdMessage.error('There was an error loading more messages: ' + err.message || 'Unexpected Error');
      },
      onCompleted: (payload) => {
        if (!payload?.getMessages) return;
        if (chatRoomIdsWithNewMessages.includes(selectedChatRoom?.chatId || '')) {
          const data = client?.readQuery<{ getUnreadMessagesCount: number }>({
            query: gqlSchema.ChatSchema.query.getUnreadMessagesCount,
          });

          const unreadMessages = payload.getMessages.chat.filter((c) => c.read === false);
          const newUnreadCount = (data?.getUnreadMessagesCount ?? 0) - unreadMessages.length;

          client?.writeQuery({
            query: gqlSchema.ChatSchema.query.getUnreadMessagesCount,
            data: { getUnreadMessagesCount: newUnreadCount < 0 ? 0 : newUnreadCount },
          });

          unreadMessages.forEach((message) => {
            client?.writeFragment({
              id: `MessagesListResponse:{"chatMessageId":"${message.chatMessageId}"}`,
              fragment: gqlSchema.ChatSchema.fragment.updateReadMessage,
              data: {
                read: true,
              },
            });
          });

          setChatRoomIdsWithNewMessages(chatRoomIdsWithNewMessages.filter((id) => id !== selectedChatRoom?.chatId));
        }
      },
    },
  );

  const messages = messagesData?.getMessages?.chat;

  useEffect(() => {
    const messages = messagesData?.getMessages?.chat || [];
    const extraMessages = messages.length > 10 ? messages.length % MESSAGES_PAGE_SIZE : 0;
    setCurrentMessagesPage(messages.length ? Math.ceil((messages.length - extraMessages) / MESSAGES_PAGE_SIZE) : 1);
  }, [messagesData]);

  const [markMessagesAsRead] = useMutation<{ markMessagesAsRead: boolean }, { chatMessageIds: string[] }>(
    gqlSchema.ChatSchema.mutation.markMessagesAsRead,
    {
      onError: (err) => {},
    },
  );

  const [sendMessage, { loading: loadingSendMessage }] = useMutation<
    { sendMessage: { id: string; date: string } },
    { data: { chatId: string; message?: string }; file?: File }
  >(gqlSchema.ChatSchema.mutation.sendMessage, {
    onCompleted: () => setFile(undefined),
    onError: (err) => {
      antdMessage.error('There was an error sending message: ' + err.message || 'Unexpected Error');
    },
  });

  useSubscription<{ listenForChatMessages: GQL_MessagesSubscriptionResponse }>(
    gqlSchema.ChatSchema.subscription.listenForChatMessages,
    {
      onSubscriptionData: ({ subscriptionData, client }) => {
        if (subscriptionData?.data?.listenForChatMessages) {
          const newMessageChatId = subscriptionData.data.listenForChatMessages.chatId;
          const isMessageFromCurrentUser = subscriptionData.data.listenForChatMessages?.senderId === user.id;
          if (newMessageChatId !== selectedChatRoom?.chatId) {
            setChatRoomIdsWithNewMessages([...chatRoomIdsWithNewMessages, newMessageChatId]);
          } else if (!isMessageFromCurrentUser) {
            markMessagesAsRead({
              variables: {
                chatMessageIds: [subscriptionData.data.listenForChatMessages?.chatMessageId],
              },
            });

            const existingData = client?.readQuery<{ getUnreadMessagesCount: number }>({
              query: gqlSchema.ChatSchema.query.getUnreadMessagesCount,
            });

            client.writeQuery({
              query: gqlSchema.ChatSchema.query.getUnreadMessagesCount,
              data: { getUnreadMessagesCount: (existingData?.getUnreadMessagesCount ?? 0) - 1 },
            });
          }

          const newMessage = {
            chatId: subscriptionData?.data?.listenForChatMessages?.chatId,
            recipientId: subscriptionData?.data?.listenForChatMessages?.recipientId,
            recipientUserName: subscriptionData?.data?.listenForChatMessages?.recipientUserName,
            senderId: subscriptionData?.data?.listenForChatMessages?.senderId,
            senderUserName: subscriptionData?.data?.listenForChatMessages?.senderUserName,
            message: subscriptionData?.data?.listenForChatMessages?.message,
            sent: subscriptionData?.data?.listenForChatMessages?.sent,
            chatMessageId: subscriptionData?.data?.listenForChatMessages?.chatMessageId,
            read: isMessageFromCurrentUser,
            fileUrl: subscriptionData?.data?.listenForChatMessages?.fileURL,
            MIMEType: subscriptionData?.data?.listenForChatMessages?.MIMEType,
            __typename: 'MessagesListResponse',
          };

          // Updates the getMessages query
          client.writeQuery({
            query: gqlSchema.ChatSchema.query.getMessages,
            variables: {
              data: {
                chatId: newMessageChatId,
              },
            },
            data: {
              getMessages: {
                chat: [newMessage],
              },
            },
          });

          const data = client.readQuery<{ getChatRooms: GQL_ChatListResponse[] }>({
            query: gqlSchema.ChatSchema.query.getChatRooms,
          });

          if (data) {
            client.writeQuery({
              query: gqlSchema.ChatSchema.query.getChatRooms,
              data: {
                getChatRooms: data.getChatRooms.map((room) => {
                  if (room.chatId === newMessageChatId) {
                    return {
                      ...room,
                      sent: subscriptionData?.data?.listenForChatMessages.sent,
                    };
                  } else {
                    return room;
                  }
                }),
              },
            });
          }
        }
      },
    },
  );

  const handleFetchMoreMessages = useCallback(() => {
    if (fetchMore && selectedChatRoom?.chatId && !loadingMessages) {
      fetchMore({
        variables: {
          data: {
            chatId: selectedChatRoom?.chatId,
            pagination: {
              page: currentMessagesPage + 1,
              size: MESSAGES_PAGE_SIZE,
            },
          },
        },
      });
    }
  }, [fetchMore, currentMessagesPage, selectedChatRoom, loadingMessages]);

  const renderMessages = useMemo(() => {
    const onFirstLoading = loadingMessages && networkStatus === NetworkStatus.loading;

    if (!selectedChatRoom) {
      return (
        <S.NoContactSelectedContainer>You can start a conversation on the left panel</S.NoContactSelectedContainer>
      );
    } else if (onFirstLoading) {
      return (
        <S.LoadingChatContainer>
          <Spin />
        </S.LoadingChatContainer>
      );
    } else if (!messages?.length) {
      return (
        <S.EmptyMessageContainer>
          <img src={emptyMessagesSrc} alt="Message Baloon with three dots on it" />
          <h1>
            This is the very beginning of your direct message history with <strong>{selectedChatRoom.userName}</strong>.
          </h1>
        </S.EmptyMessageContainer>
      );
    }

    const hasMoreMessages =
      !onFirstLoading &&
      currentMessagesPage < (messagesData?.getMessages?.pagination?.totalCount ?? 0) / MESSAGES_PAGE_SIZE;

    return (
      <InfiniteScroll
        initialLoad={false}
        isReverse
        loadMore={handleFetchMoreMessages}
        hasMore={hasMoreMessages}
        useWindow={false}
        threshold={50}
      >
        {!hasMoreMessages && <S.NoMoreMessagesComponent>No more messages to load</S.NoMoreMessagesComponent>}
        {loadingMessages && networkStatus === NetworkStatus.fetchMore && (
          <S.LoadingMoreChatContainer>
            <Spin />
          </S.LoadingMoreChatContainer>
        )}
        {uniqBy(messages, 'chatMessageId')
          ?.slice()
          ?.reverse()
          ?.map((message, index) => (
            <S.MessageBalloon
              sentByYou={message.recipientId === selectedChatRoom.userId}
              key={message.chatMessageId + index}
            >
              {message.message}
              {message.fileUrl && (
                <S.DownloadFileContainer href={message.fileUrl} download>
                  <RiDownloadLine />
                  {message.MIMEType?.includes('image') ? (
                    <S.ChatImage src={message.fileUrl} alt="Attachment" />
                  ) : (
                    <S.NotSupportedContainer>
                      File not supported for preview.
                      <br />
                      Click here to download
                    </S.NotSupportedContainer>
                  )}
                </S.DownloadFileContainer>
              )}
              <h2>{formatDateTime(Number(message.sent), "MM.dd.yyyy 'at' HH:mm aa")}</h2>
            </S.MessageBalloon>
          ))}
      </InfiniteScroll>
    );
  }, [
    selectedChatRoom,
    messages,
    loadingMessages,
    handleFetchMoreMessages,
    messagesData,
    currentMessagesPage,
    networkStatus,
  ]);

  const onSelectContact = (chatRoom: GQL_ChatListResponse) => {
    getMessages({
      variables: {
        data: {
          chatId: chatRoom.chatId,
          pagination: {
            page: 1,
            size: MESSAGES_PAGE_SIZE,
          },
        },
      },
    });
    setSelectedChatRoom(chatRoom);
    setMessage(undefined);
  };

  const handleSendMessage = () => {
    if (!message || !selectedChatRoom) return;

    sendMessage({
      variables: {
        data: {
          chatId: selectedChatRoom.chatId,
          message,
        },
      },
    });

    setMessage(undefined);
  };

  const handleSendAttachment = () => {
    if (!file || !selectedChatRoom) return;

    sendMessage({
      variables: {
        data: {
          chatId: selectedChatRoom.chatId,
          message: '',
        },
        file: file,
      },
    });

    setMessage(undefined);
  };

  return (
    <>
      <PageWithTitle title="Your Messages" fullHeight>
        <S.MessengerContainer>
          <ChatRoomsSider
            onSelectContact={onSelectContact}
            chatRoomIdsWithNewMessages={chatRoomIdsWithNewMessages}
            setChatRoomIdsWithNewMessages={setChatRoomIdsWithNewMessages}
            selectedChatId={selectedChatRoom?.chatId}
          />
          <S.MessagesOutsideContainer>
            <S.MessagesContainer>{renderMessages}</S.MessagesContainer>
            {selectedChatRoom?.chatId && (
              <S.MessageInputContainer>
                <Upload
                  beforeUpload={(file) => {
                    setFile(file);
                    return true;
                  }}
                  defaultFileList={[]}
                  fileList={[]}
                  customRequest={() => {}}
                >
                  <S.AttachFileButton>
                    <FiPlus />
                  </S.AttachFileButton>
                </Upload>
                <Input
                  data-cy="messagelistpage-sendmessage-input"
                  bordered={false}
                  backgroundColor="#FFFFFF"
                  placeholder="Type a message here..."
                  onChange={(e) => setMessage(e.target.value)}
                  onPressEnter={handleSendMessage}
                  value={message}
                />
                <S.SendMessageButton
                  data-cy="messagelistpage-sendmessage-button"
                  onClick={handleSendMessage}
                  disabled={!message}
                >
                  <FiSend />
                </S.SendMessageButton>
              </S.MessageInputContainer>
            )}
          </S.MessagesOutsideContainer>
        </S.MessengerContainer>
      </PageWithTitle>
      <Modal
        title="Confirm File Upload"
        visible={!!file}
        zIndex={1100}
        confirmLoading={loadingSendMessage}
        onCancel={() => setFile(undefined)}
        onOk={handleSendAttachment}
      >
        Are you sure you want to send the file: <strong>{file?.name}</strong>?
      </Modal>
    </>
  );
};

export default MessageListPage;
