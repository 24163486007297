/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import update from 'immutability-helper';
import Editor from '../../../shared/Editor';
import Spacer from '../../../shared/Spacer';
import { IProcessDragDropAnswer } from '../../../types/investigation';
import * as S from './styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableCard from './DraggableCard';
import { Card, DragItem } from './DroppableCard';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { message } from 'antd';
import { useAuth } from '../../../hooks/useAuth';

interface IInvestigationSortingBoardProps {
  value: IProcessDragDropAnswer;
  onBoardSorted: (value: boolean) => void;
  currentStepId?: string;
  investigationId?: string;
  activityId: string;
  userId?: string;
  isLocked?: boolean;
  presentationMode?: boolean;
  isImageVersion?: boolean;
}

export interface Item {
  id: number;
  text: string;
  isAnswer: boolean;
}

const AssessmentProcessBoard = ({
  value,
  activityId,
  currentStepId,
  onBoardSorted,
  isLocked = false,
  isImageVersion = false,
  presentationMode,
}: IInvestigationSortingBoardProps) => {
  const { isTeacher, isTeacherOrFacilitator, user,isBookUser } = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const showSuccess = !presentationMode && (isTeacherOrFacilitator || isTeacher || isGoogleTeacher || isCanvasTeacher || isBookUser);
  const [submitJsonSubmission] = useMutation<
    { submitJsonSubmission: { id: string } },
    { activityId: string; stepId: string; json: { data: { value: string[] }[] } }
  >(gqlSchema.InvestigationSchema.mutations.PROCESS_BOARD.submitProcessBoard, {
    onError: (err) => message.error('There was an error submitting the comment: ' + err.message),
  });

  const [options] = useState<Item[]>(() => {
    const targetValues = value.targets?.map((target, i) => target.values?.map((v, i) => ({ text: v })));
    if (!targetValues) return [];
    const filteredUndefined = targetValues.flatMap((v) => (v ? v : []));

    return filteredUndefined
      ?.sort(() => Math.random() - 0.5)
      .flat()
      .map((v, i) => ({ ...v, id: i, isAnswer: false }));
  });

  const [answers, setAnswers] = useState<Item[]>(() => {
    if (value.answers && value.answers.length)
      return value.answers[0]?.value?.map((a, i) => ({ text: a, id: i, isAnswer: true }));
    const targetValues = value.targets?.map((target, i) => target.values?.map((v, i) => ({ text: '' })));
    if (!targetValues) return [];
    const filteredUndefined = targetValues.flatMap((v) => (v ? v : []));

    return filteredUndefined.flat().map((v, i) => ({ ...v, id: i, isAnswer: true }));
  });

  const targetAnswers: string[] = useMemo(() => {
    return value.targets?.[0]?.values?.map((v) => v) || [];
  }, [value.targets]);

  const [areAnswersCorrect, setAreAnswersCorrect] = useState<boolean>(false);

  const optionRowsAmount = Math.ceil(options.length);
  const minHeightOptions = optionRowsAmount * 36 + optionRowsAmount * 8;

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setAnswers((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      }),
    );
  }, []);

  const onDropElement = useCallback((item: DragItem, index: number) => {
    if (!item.isAnswer) {
      setAnswers((oldAnswers) => {
        const newAnswers = [...oldAnswers];
        newAnswers[index].text = item?.text || item?.value;
        return newAnswers;
      });
    }
  }, []);

  const renderCard = useCallback(
    (card: { id: number; text: string }, index: number) => {
      return (
        <Card
          key={`process-board-card-${card.id}-${index}`}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
          onDropElement={onDropElement}
          canDropElement={() => card.text === ''}
          showSuccess={showSuccess}
          isCorrectAnswer={card.text === targetAnswers[index]}
          isImageVersion={isImageVersion}
        />
      );
    },
    [isImageVersion, moveCard, onDropElement, showSuccess, targetAnswers],
  );

  useEffect(() => {
    if (activityId && currentStepId && !isLocked) {
      submitJsonSubmission({
        variables: {
          activityId: activityId,
          json: { data: [{ value: answers.map((a) => a.text) }] },
          stepId: currentStepId,
        },
      });
    }
  }, [activityId, answers, currentStepId, isLocked, submitJsonSubmission]);

  useEffect(() => {
    if (activityId && currentStepId && !isLocked) {
      if (answers.every((a) => a.text !== '')) {
        onBoardSorted(true);
        setAreAnswersCorrect(
          answers.every((a, i) => a.text === targetAnswers[i]) && answers.length === targetAnswers.length,
        );
      }
    }
  }, [activityId, answers, currentStepId, isLocked, onBoardSorted, targetAnswers]);

  return (
    <S.ContainerSortingBoard id="process-drag-and-drop-board-wrapper">
      <S.TitleInput>Questions</S.TitleInput>
      <Editor
        editable={false}
        value={(value as IProcessDragDropAnswer).directions}
        data-cy="student-view-process-board-title-directions"
      />
      <Spacer />
      <DndProvider backend={HTML5Backend}>
        <S.Container>
          <S.ProcessDragDropContainerTitles>
            <b> Steps </b>
            <b> Process </b>
          </S.ProcessDragDropContainerTitles>
          <S.ProcessDragDropContainer $minHeightOptions={minHeightOptions}>
            <S.ProcessTasksCol>
              {options.map((option, idx) => (
                <DraggableCard
                  index={idx}
                  value={option.text}
                  key={`draggable-dnd-process-${idx}`}
                  isImageVersion={isImageVersion}
                  hasTarget={Boolean(answers.find((a) => a.text === option.text))}
                />
              ))}
            </S.ProcessTasksCol>
            <S.ProcessTasksCol showSuccess={showSuccess} success={areAnswersCorrect}>
              {answers.map((card, i) => renderCard(card, i))}
            </S.ProcessTasksCol>
          </S.ProcessDragDropContainer>
        </S.Container>
      </DndProvider>
      <Spacer />
    </S.ContainerSortingBoard>
  );
};

export default AssessmentProcessBoard;
