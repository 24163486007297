import { Col, message, Row, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import * as S from './styles';
import { GQL_InvestigationByTeacherResponse, FinishedInvestigationByTeacherResponse } from '../../../types/charts';
import { FiHelpCircle } from 'react-icons/fi';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import DetailTeachers from './DetailTeachers';
import Button from '../../Button';
import { formatDateTime } from '../../../utils/date';
import ExcelJS, { Borders } from 'exceljs';
import { saveAs } from 'file-saver';
const FinishedInvestigationsByTeacher = () => {
  const [dataset, setDataset] = useState<FinishedInvestigationByTeacherResponse[]>([]);
  const { loading } = useQuery<{ getFinishedInvestigationByTeacher: GQL_InvestigationByTeacherResponse[] }>(
    gqlSchema.ChartSchema.queries.CHARTS.getFinishedInvestigationByTeacher,
    {
      onCompleted: (data) => {
        const newDataset = data?.getFinishedInvestigationByTeacher.reduce(
          (ret: FinishedInvestigationByTeacherResponse[], cur: GQL_InvestigationByTeacherResponse) => {
            let newList = [...ret];

            const inv = {
              className: cur.className,
              investigationTitle: cur.investigationTitle,
              endDate: cur.endDate,
              startDate: cur.startDate,
              discipline: cur.discipline,
              subject: cur.subject,
            };
            if (!newList.some((t) => t.teacherId === cur.teacherId)) {
              newList.push({
                teacherId: cur.teacherId,
                teacherLastName: cur.teacherLastName,
                teacherFirstName: cur.teacherFirstName,
                teacherEmail: cur.teacherEmail,
                tags: cur.tags,
                investigations: [inv],
              });
            } else {
              newList = newList.map((t) => {
                if (t.teacherId === cur.teacherId) {
                  return {
                    ...t,
                    investigations: [...t.investigations, inv],
                  };
                }
                return t;
              });
            }

            return newList;
          },
          [],
        );

        setDataset(newDataset);
      },
      onError: (err) => {
        message.error(
          'There was an error loading investigations finished by teacher: ' + err.message || 'Unexpected Error',
        );
      },
    },
  );

  const saveAsExcel = useCallback(async () => {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet();
    const borderStyle = { style: 'thin', color: 'black' };
    const bordersStyle = {
      top: borderStyle,
      left: borderStyle,
      right: borderStyle,
      bottom: borderStyle,
      diagonal: borderStyle,
    } as Borders;
    const headerStyle = { font: { bold: true }, border: bordersStyle };
    const bodyStyle = { font: { bold: false } };
    ws.columns = [
      { header: 'First Name', key: 'teacherFirstName', width: 20 },
      { header: 'Last Name', key: 'teacherLastName', width: 20 },
      { header: 'Email', key: 'teacherEmail', width: 35 },
      { header: 'Tags', key: 'tags', width: 40 },
      { header: 'Class', key: 'className', width: 40 },
      { header: 'Investigation', key: 'investigation', width: 45 },
      { header: 'Subject', key: 'subject', width: 15 },
      { header: 'Discipline', key: 'discipline', width: 35 },
      { header: 'Start Date', key: 'startDate', width: 25 },
      { header: 'End Date', key: 'endDate', width: 25 },
    ];
    ws.getCell(`A1`).style = headerStyle;
    ws.getCell(`B1`).style = headerStyle;
    ws.getCell(`C1`).style = headerStyle;
    ws.getCell(`D1`).style = headerStyle;
    ws.getCell(`E1`).style = headerStyle;
    ws.getCell(`F1`).style = headerStyle;
    ws.getCell(`G1`).style = headerStyle;
    ws.getCell(`H1`).style = headerStyle;
    ws.getCell(`I1`).style = headerStyle;
    ws.getCell(`J1`).style = headerStyle;

    ws.getCell(`A1`).alignment = { horizontal: 'center' };
    ws.autoFilter = {
      from: 'A1',
      to: 'C1',
    };
    let currentRow = 2;

    dataset.forEach((row) => {
      const excelRow = ws.addRow([
        row.teacherFirstName,
        row.teacherLastName,
        row.teacherEmail,
        row?.tags.map((t) => `${t.tag}`).join(', '),
        'Class',
        'Investigation',
        'Subject',
        'Discipline',
        'Start Date',
        'End Date',
      ]);

      excelRow.alignment = { vertical: 'middle', horizontal: 'left' };
      excelRow.font = bodyStyle.font;

      const mergeLength = currentRow + (row.investigations?.length || 0) - 1;
      ws.mergeCells(`A${currentRow}:A${mergeLength}`);
      ws.mergeCells(`B${currentRow}:B${mergeLength}`);
      ws.mergeCells(`C${currentRow}:C${mergeLength}`);
      ws.mergeCells(`D${currentRow}:D${mergeLength}`);
      ws.getCell(`A${currentRow}`).border = bordersStyle;
      ws.getCell(`B${currentRow}`).border = bordersStyle;
      ws.getCell(`C${currentRow}`).border = bordersStyle;
      ws.getCell(`D${currentRow}`).border = bordersStyle;
      let subRow = currentRow;
      row.investigations?.forEach((inv) => {
        const rowClassName = ws.getCell(`E${subRow}`);
        const rowInvestigationName = ws.getCell(`F${subRow}`);
        const rowSubject = ws.getCell(`G${subRow}`);
        const rowDiscipline = ws.getCell(`H${subRow}`);
        const rowStartDate = ws.getCell(`I${subRow}`);
        const rowEndDate = ws.getCell(`J${subRow}`);
        // Putting value to subrows
        rowClassName.value = inv.className;
        rowInvestigationName.value = inv.investigationTitle;
        rowSubject.value = inv.subject;
        rowDiscipline.value = inv.discipline;
        rowStartDate.value = formatDateTime(inv?.startDate);
        rowEndDate.value = formatDateTime(inv?.endDate);
        // Putting borders to subrows
        rowClassName.border = bordersStyle;
        rowInvestigationName.border = bordersStyle;
        rowSubject.border = bordersStyle;
        rowDiscipline.border = bordersStyle;
        rowStartDate.border = bordersStyle;
        rowEndDate.border = bordersStyle;
        // Putting row font
        rowClassName.font = bodyStyle.font;
        rowInvestigationName.font = bodyStyle.font;
        rowSubject.font = bodyStyle.font;
        rowDiscipline.font = bodyStyle.font;
        rowStartDate.font = bodyStyle.font;
        rowEndDate.font = bodyStyle.font;
        // Putting row alignment
        rowStartDate.alignment = { horizontal: 'center' };
        rowEndDate.alignment = { horizontal: 'center' };
        subRow += 1;
      });
      currentRow = mergeLength + 1;
    });
    const buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'Finished investigations (By Teachers).xlsx');
  }, [dataset]);
  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Finished Investigations</S.Title>
          <Tooltip title="Finished Investigations by teacher.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={6} offset={18}>
          <Button text="Download Details" block onClick={saveAsExcel} />
        </Col>
        <Col span={24}>
          <DetailTeachers loading={loading} data={dataset} />
        </Col>
      </Row>
    </>
  );
};

export default FinishedInvestigationsByTeacher;
