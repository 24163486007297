import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h2 {
    margin: 0;
  }
`;

export const QuestionBlock = styled.div`
  .question-label {
    font-size: 16px;
    margin-top: 24px;
    font-weight: 600;
  }
  .question-title {
    font-size: 16px;
    margin: 24px 0;
  }

  .image-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .answers-row {
    display: flex;
    gap: 16px;
    margin-top: 32px;
    overflow-x: scroll;
    justify-content: space-between;
  }

  .answers-percentage {
    margin-top: 12px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }

  .answers-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 12px;
    border: 2px solid #ea3535;
    border-radius: 4px;
    min-width: 400px;
    max-width: 400px;
  }

  .category-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 12px;
    width: 50%;
    border: 2px solid #ea3535;
    border-radius: 4px;
  }

  .answers-reason {
    .student {
      color: #4367e9;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 4px;
    }
    border: 1px solid #e6e6e6;
    margin-top: 12px;
    padding: 12px;
    width: 100%;
    -webkit-box-shadow: 4px 13px 24px -14px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 4px 13px 24px -14px rgba(0, 0, 0, 0.44);
    box-shadow: 4px 13px 24px -14px rgba(0, 0, 0, 0.44);
  }

  .answer-image {
    height: 220px;
    width: 100%;
    object-fit: cover;
  }
`;

export const VideoComponent = styled.video<{ loaded: boolean }>`
  ${(props) => !props.loaded && 'display: none;'}
  max-width: 100%;
  height: 352px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
