import { Progress, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import Spacer from '../../../shared/Spacer';
import * as S from './styles';
import { getInsightsColors, scoresPercentagesColors, InsightsData } from '../InvestigationInsight';

interface AverageScoreGaugeInterface {
  loading?: boolean;
  type?: 'report' | 'engagement';
  data?: InsightsData[];
}

const AverageScoreGauge = (props: AverageScoreGaugeInterface) => {
  const { type = 'report', data } = props;
  const totalScore =
    data?.reduce((acc, curr) => {
      if (type === 'report') {
        return acc + curr.reportAverage * curr.gradedStudents;
      }
      return acc + curr.engagementAverage * curr.gradedStudents;
    }, 0) || 0;
  const numberOfStudents = data?.reduce((acc, curr) => acc + curr.gradedStudents, 0) || 1;
  const averageScore = Math.round((totalScore / numberOfStudents) * 100);

  const formatProgressText = useCallback(
    (percent?: number) => {
      const reportFormat = ((percent ?? 0) * 3) / 2 + '%';
      const engagementFormat = ((percent ?? 0) * 5) / 100;

      return type === 'report' ? reportFormat : engagementFormat;
    },
    [type],
  );

  return (
    <S.GaugeContainer type={type} meetsExpectation={averageScore >= 100}>
      <h1>Average {type.charAt(0).toLocaleUpperCase() + type.slice(1)} Score</h1>
      <h2>(Across All {type === 'report' ? 'Reports' : 'Engagements'})</h2>
      <Spacer />
      <Tooltip
        title={
          type === 'report' &&
          'The line represents 100% (total points). This means the student has met performance expectations. Any score past the line represents a grade that is greater than 100% (any number of points above the total points). This means the student has exceeded performance expectations.'
        }
      >
        <Progress
          type="dashboard"
          percent={type === 'report' ? (averageScore * 2) / 3 : averageScore}
          width={180}
          format={formatProgressText}
          success={
            type === 'report'
              ? {
                  percent: 1,
                  strokeColor: 'rgb(0, 0, 0)',
                }
              : undefined
          }
          gapDegree={110}
          strokeWidth={12}
          strokeColor={
            type === 'report'
              ? averageScore >= 100
                ? scoresPercentagesColors.exceedsExpectations
                : scoresPercentagesColors.meetsExpectations
              : getInsightsColors(averageScore)
          }
          strokeLinecap="butt"
        />
      </Tooltip>
    </S.GaugeContainer>
  );
};

export default AverageScoreGauge;
