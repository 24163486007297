import styled from 'styled-components';
import { Card as AntdCard } from 'antd';

export const TableWrapper = styled.div`
  margin: 15px 0;

  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const Title = styled.h3`
  font-size: 1.5em;
  display: inline;
`;

export const Card = styled(AntdCard)`
  margin: 15px 0;
`;

export const TitleExtra = styled.h3`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  text-align: center;
`;
