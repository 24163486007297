import peerCommentsReducer, { actions as peerCommentsActions } from './peerComments';
import investigationReducer, { actions as investigationActions } from './investigation';
import notificationReducer, { actions as notificationActions } from './notifications';
import menuReducer, { actions as menuActions } from './menu';

import errorsReducer from './errors';
import loadingsReducer from './loadings';

export { investigationActions, notificationActions, peerCommentsActions, menuActions };

export default {
  investigation: investigationReducer,
  errors: errorsReducer,
  loadings: loadingsReducer,
  notifications: notificationReducer,
  peerComments: peerCommentsReducer,
  menu: menuReducer,
};
