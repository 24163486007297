import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import * as S from './styles'
import { BsPersonFillLock } from 'react-icons/bs'
import { MdOutlineHomeWork } from 'react-icons/md'
import { FaCrown } from 'react-icons/fa'
const LoginModal = ({closeModal,isModalOpen,history,initailStage}:any) => {
  const [stage, setStage] = useState(initailStage ||'initial')
  useEffect(() => {
    // console.log('initailStage',initailStage)
    // if(initailStage){
      setStage(initailStage)
    // }
  }, [initailStage])
  console.log('stage',stage,initailStage)
  
  return (
    <Modal
        title={
          <S.Header>
            <h1>{stage==='initial'?'Select Login':'Do you have a Pro or Premium subscription? '}</h1>
            <S.CloseButton onClick={closeModal}></S.CloseButton>
          </S.Header>
        }
        visible={isModalOpen}
        onCancel={()=>{setStage('initial');closeModal()}}
        footer={null}
        width={1000}
        bodyStyle={{ height: '300px' }}
        centered
      >
        {stage === 'initial'?( <S.Content>
          <S.ModalButton onClick={() => setStage('secondary')}>
            <BsPersonFillLock style={{ color: '#20BC89' }} />
            <span>Log in as Individual</span>
            <h6>Personalized features for educators, including lesson planning and student tracking.</h6>
          </S.ModalButton>

          <S.ModalButton onClick={() => history.push('/login')}>
            <MdOutlineHomeWork style={{ color: '#2F0DFF' }} />
            <span>Log in as Organization</span>
            <h6>Centralized access to manage accounts, oversee classrooms, and track progress.</h6>
          </S.ModalButton>
        </S.Content>):(
           <S.Content>
          <S.ModalButton onClick={() => history.push('/login')}>
          <FaCrown color='#13A072'/>
          <span>Yes, I have a Pro/Premium subscription.</span>
        </S.ModalButton>

        <S.ModalButton onClick={() => history.push('/bookLogin')}>
         <FaCrown color='#EE891D'/>
          <span>No, I don't have a Pro/Premium subscription.</span>
         
        </S.ModalButton>
      </S.Content>
        )
        }
      </Modal>
  )
}

export default LoginModal
