import { Col, Row, Skeleton, Button, Modal, message } from 'antd';
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom'; // Use useHistory for v5
import * as S from './styles';

interface ICanvasClassInfos {
  classInfo?: {
    id?: string;
    name?: string;
    course_code?: string;
    workflow_state?: string;
    created_at?: string;
    default_view?: string;
    account_id?: string;
    calendar?: {
      ics?: string;
    };
  };
  loading: boolean;
  studentCount: number;
}

const CanvasClassInfos: React.FC<ICanvasClassInfos> = ({ classInfo, loading, studentCount }) => {
  const { user } = useAuth();
  const history = useHistory(); // Initialize history
  const classTitle = classInfo ? 'Class' : '';

  // Mutation to delete the class
  const [deleteClass, { loading: deletingClass }] = useMutation(gqlSchema.CanvasSchema.mutations.CANVASCLASS.DeleteCanvasCourse, {
    variables: { course_id: classInfo?.id },
    onCompleted: () => {
      message.success('Class deleted successfully');
      history.push('/canvas-teacher-dashboard'); // Navigate using history.push
    },
    onError: (error) => {
      message.error(`Error: ${error.message}`);
    }
  });

  // Delete confirmation modal
  const confirmDeleteClass = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this class?',
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => deleteClass(),
    });
  };

  const displayStatus = (status?: string) => {
    if (status === 'available') {
      return 'Ongoing';
    }
    return status || 'Not provided';
  };

  const StatusComponent = (status?: string) => {
    if (status === 'available') {
      return <S.StatusOngoing>{displayStatus(status)}</S.StatusOngoing>;
    }
    return <S.StatusDefault>{displayStatus(status)}</S.StatusDefault>;
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <S.ClassInfosContainer>
      <Row gutter={16}>
        <Col span={8} xl={8} lg={8} md={8} xs={24} style={{ paddingRight: '16px' }}>
          <S.Title>{classTitle} Name</S.Title>
          {!loading ? (
            <p>{classInfo?.name || 'Not provided'}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
        <Col span={8} xl={8} lg={8} md={8} xs={24} style={{ paddingRight: '16px' }}>
          <S.Title>{classTitle} Code</S.Title>
          {!loading ? (
            <p>{classInfo?.course_code || 'Not provided'}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
        <Col span={8} xl={8} lg={8} md={8} xs={24}>
          <S.Title>{classTitle} ID</S.Title>
          {!loading ? (
            <p>{classInfo?.id || 'Not provided'}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: isMobile? '0px': '24px' }}> {/* Adjust the marginTop here */}
        <Col span={8} xl={8} lg={8} md={8} xs={24} style={{ paddingRight: '16px' }}>
          <S.Title>{classTitle} Owner</S.Title>
          {!loading ? (
            <p>{user ? `${user.name} - ${user.email}` : '-'}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
        <Col span={8}xl={8} lg={8} md={8} xs={24} style={{ paddingRight: '16px' }}>
          <S.Title>{classTitle} Status</S.Title>
          {!loading ? (
            StatusComponent(classInfo?.workflow_state)
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
        <Col span={8} xl={8} lg={8} md={8} xs={24}>
          <S.Title>{classTitle} Students</S.Title>
          {!loading ? (
            <p>{studentCount ? `${studentCount} Enrolled Students` : '-'}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
      </Row>

      <Row justify="end" style={{ marginTop: '2px' }}>
        <Col>
          <Button
            type="primary"
            danger
            loading={deletingClass}
            onClick={confirmDeleteClass}
            style={{ backgroundColor: '#EA3535', borderColor: '#EA3535', fontWeight: 500 }}
          >
            Delete Class
          </Button>
        </Col>
      </Row>
    </S.ClassInfosContainer>
  );
};

export default CanvasClassInfos;
