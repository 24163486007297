import { GQL_PaginationInput, GQL_PaginationResponse } from './pagination';
import { TagResponse } from './tags';

export interface GQL_InvestigationsResponse {
  count: number;
  title: string;
}
export interface GQL_UsedInvestigationsResponse {
  count: number;
  title: string;
  type: string;
}

export interface PieChart {
  id: string;
  label: string;
  value: number;
  color?: string;
}

export interface GQL_ActiveUsersResponse {
  activeUsers: number;
  totalUsers: number;
  betaUsers: number;
}

export interface GQL_ActiveUsersInput {
  option: string;
  from: string;
  orgId?: string;
}

export interface GQL_SubscriptionInsightsResponse {
  totalSubscriptions: number;
  totalSubscriptionsFromDate: number;
}

export interface GQL_SubscriptionInsightsInput {
  option: string;
  from: string;
}

export interface GQL_AverageTTCIResponse {
  id: string;
  name: string;
  average: number;
}

export interface GQL_InvestigationCompletionTimeResponse {
  investigationId: string;
  investigationTitle: string;
  averageCompletionTime: number;
}

export interface GQL_InvestigationsCompletionTimeResponse {
  investigations: GQL_InvestigationCompletionTimeResponse[];
  totalAverage: number;
}

export interface ConfigColor {
  from: number;
  to: number;
  color: string;
}

export interface GQL_TeacherInvestigationRate {
  id: string;
  name: string;
  rate: number;
  organization: string;
}

export interface GQL_TeacherInvestigationRateDetail {
  className: string;
  investigationId: string;
  investigationTitle: string;
  startDate: number;
  endDate: number;
}

export interface GQL_InvestigationAssignmentRateChartPaginatedResponse {
  rates: GQL_InvestigationAssignmentRateChartResponse[];
  pagination: GQL_PaginationResponse;
}

export interface GQL_InvAssignmentRateInput {
  startDate?: string;
  endDate?: string;
  order?: 'ASC' | 'DESC';
  organizations: boolean;
  organizationId?: string;
  teachers: boolean;
  workshopOnly?: boolean;
  pagination?: GQL_PaginationInput;
}

export interface GQL_InvestigationAssignmentRateChartResponse {
  teacherId: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  rate: number;
  organizationName?: string;
}

export interface GQL_InvestigationAssignmentRateForTeacherResponse {
  teacherId: string;
  teacherName: string;
  organizationName?: string;
  investigationAssignmentRatePerClass: GQL_InvestigationAssignmentRatePerClass[];
}

export interface GQL_InvestigationAssignmentRatePerClass {
  className: string;
  rate: number;
  investigations: GQL_InvestigationAssignmentRateDetails[];
}

export interface GQL_InvestigationAssignmentRateDetails {
  investigationId: string;
  investigationTitle: string;
  startDate: number;
  endDate: number;
}

export type BarAssignmentTrend = {
  status: string;
  quantity: number;
  color: string;
  type: GQL_TrendFilters;
};

export type BarStageCompletion = {
  stage: string;
  average: number;
  color: string;
};

export type LineAssignmentTrend = {
  id: string;
  data: { x: string; y: number }[];
  color: string;
};

export interface GQL_FacilitatorsChartInput {
  startDate?: string;
  endDate?: string;
  pagination?: GQL_PaginationInput;
  order?: 'ASC' | 'DESC';
  email: string;
  name: string;
}

export interface GQL_WorkshopAssignmentRateForFacilitatorsResponse {
  facilitatorId: string;
  facilitatorFirstName: string;
  facilitatorLastName: string;
  facilitatorEmail: string;
  coursesCount: number;
  workshopsCount: number;
}

export interface GQL_FacilitatorDetailsClass {
  id: string;
  name: string;
  studentsCount: number;
  workshops: GQL_InvestigationAssignmentRateDetails[];
}

export interface GQL_FacilitatorDetailsChartResponse {
  facilitatorId: string;
  facilitatorName: string;
  classes: GQL_FacilitatorDetailsClass[];
}

export type OrderField = 'rate' | 'rateTwo' | 'rateDiff';

export interface GQL_InvAssignmentTrendInput {
  firstStartDate: string;
  firstEndDate: string;
  secondStartDate: string;
  secondEndDate: string;
  decrease?: boolean;
  orderField?: OrderField;
  order?: 'ASC' | 'DESC';
  tagIds: string[];
  organizations: boolean;
  organizationId?: string;
  teachers: boolean;
  workshopOnly: boolean;
  filter?: GQL_TrendFilters;
  pagination?: GQL_PaginationInput;
}

export interface GQL_InvAssignmentTrendChartInput {
  firstStartDate: string;
  firstEndDate: string;
  secondStartDate: string;
  secondEndDate: string;
  tagIds: string[];
  organizations: boolean;
  organizationId?: string;
  teachers: boolean;
  workshopOnly: boolean;
}

export interface GQL_InvAssignmentTrendTeacher {
  teacherId: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  rateOnFirstPeriod: number;
  rateOnSecondPeriod: number;
  rateDiff: number;
  rateDiffPercentage?: number;
}

export interface GQL_InvAssignmentTrendResponse {
  trends: GQL_InvAssignmentTrendTeacher[];
  pagination: GQL_PaginationResponse;
}

export interface GQL_InvAssignmentChartResponse {
  stoppedUsing: number;
  startedUsing: number;
  increaseInUsage: number;
  decreaseInUsage: number;
  didNotUse: number;
  smallToNoUsageChange: number;
}

export enum GQL_TrendFilters {
  StoppedUsing = 'StoppedUsing',
  StartedUsing = 'StartedUsing',
  IncreaseInUsage = 'IncreaseInUsage',
  DecreaseInUsage = 'DecreaseInUsage',
  DidNotUse = 'DidNotUse',
  SmallToNoUsageChange = 'SmallToNoUsageChange',
}

export interface GQL_SubscriptionsExpiringChartResponse {
  trialSubscriptions: number;
  freelanceTeachersSubscriptions: number;
  organizationSubscriptions: number;
  facilitatorSubscriptions: number;
}

export interface GQL_SubscriptionExpiringDetailsResponse {
  id: string;
  name: string;
  email: string;
  expiresAt: number;
}

export enum GQL_ExpiringSubscriptionTypes {
  TrialSubscriptions = 'TrialSubscriptions',
  FreelanceTeachersSubscriptions = 'FreelanceTeachersSubscriptions',
  OrganizationSubscriptions = 'OrganizationSubscriptions',
  FacilitatorSubscriptions = 'FacilitatorSubscriptions',
}

export interface GQL_ExpiringSubscriptionsDetailsTableInput {
  type: GQL_ExpiringSubscriptionTypes;
}

export interface GQL_TeacherScores {
  teacherId: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  maxReportScore: number;
  averageReportScores: number;
  averageReportScoresPercentage: number;
  averageEngagementScores: number;
}

export interface GQL_TeacherScoresPaginated {
  data: GQL_TeacherScores[];
  pagination: GQL_PaginationResponse;
}

export interface GQL_AverageScores {
  reportScore: number;
  engagementScore: number;
}

export interface GQL_AverageTeacherScoresChart {
  averageScores?: GQL_AverageScores;
  scoresByTeacher?: GQL_TeacherScoresPaginated;
}

export interface GQL_AverageReportGainScore {
  teacherId: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  gain: number;
}
export interface GQL_InvestigationCompletionPerTeacher {
  teacherId: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  tags: TagResponse[];
  completedInvestigations: number;
}
export interface GQL_AverageInvestigationCompletionPerTag {
  tagId: string;
  tagName: string;
  completedInvestigations: number;
  totalTeachers: number;
}
export interface GQL_InvestigationCompletionChartResponse {
  perTeacher?: GQL_InvestigationCompletionPerTeacher[];
  averagePerTag?: GQL_AverageInvestigationCompletionPerTag[];
}

export interface GQL_InvestigationStageCompletionPerTeacher {
  teacherId: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  tags: TagResponse[];
  stages: GQL_InvestigationStageCompletion[];
}
export interface GQL_InvestigationStageCompletion {
  name: string;
  order: number;
  totalUsers: number;
  completedUsers: number;
}
export interface GQL_InvestigationStageCompletionChartResponse {
  perTeacher?: GQL_InvestigationStageCompletionPerTeacher[];
  perStage?: GQL_InvestigationStageCompletion[];
}

export interface GQL_FetchAverageTeacherScoresInput {
  tagIds?: string[];
  pagination?: GQL_PaginationInput;
}
export interface GQL_AverageReportGainScoreInput {
  tagIds?: string[];
  startDate?: string;
  endDate?: string;
}
export interface GQL_InvestigationCompletionChartInput {
  tagIds?: string[];
  startDate?: string;
  endDate?: string;
  disciplineIds?: number[];
}
export enum GQL_InvestigationTypes {
  INVESTIGATION,
  DESIGN_CHALLENGE,
}

export interface GQL_InvestigationStagesCompletionChartInput {
  tagIds?: string[];
  startDate?: string;
  endDate?: string;
  type?: GQL_InvestigationTypes;
  disciplineIds?: number[];
}

export interface GQL_InvestigationByTeacherResponse {
  teacherId: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  tags: TagResponse[];
  className: string;
  investigationTitle: string;
  endDate: number;
  startDate: number;
  discipline: string;
  subject: string;
}

export interface FinishedInvestigationByTeacherResponse {
  teacherId: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  tags: TagResponse[];
  investigations: FinishedInvestigations[];
}
export interface FinishedInvestigations {
  className: string;
  investigationTitle: string;
  endDate: number;
  startDate: number;
  discipline: string;
  subject: string;
}

export interface GQL_OpenInvestigationsChartResponse {
  teacherId?: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  tags: TagResponse[];
  openInvestigations?: GQL_OpenInvestigation[];
}

export interface GQL_OpenInvestigation {
  id: string;
  name: string;
  subject: string;
  discipline: string;
  endDate: number;
  startDate: number;
  className: string;
}

export interface OpenInvestigationsChartResponse {
  teacherId?: string;
  teacherFirstName: string;
  teacherLastName: string;
  teacherEmail: string;
  tags: TagResponse[];
  id: string;
  name: string;
  subject: string;
  discipline: string;
  endDate: number;
  startDate: number;
  className: string;
}

export type LineChart = {
  id: string;
  data: { x: string; y: number; tooltip: string }[];
};

export interface BackgroundRanges {
  title: string;
  background: string;
  min: number; // Percentage
  max: number; // Percentage
}
