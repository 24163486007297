import { Card } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import Button from '../../../shared/Button';
import * as S from './styles';
import { RiVisaLine, MdPayment, RiMastercardFill, FaCcDinersClub, FaGooglePlay, FaApplePay } from 'react-icons/all';
import { GQL_BillingInfo, GQL_PaymentMethod } from '../../../types/payments';
import moment from 'moment';
import { PaymentMethodModal } from '../AddPaymentMethodModal';

type PaymentMethodProps = {
  loading: boolean;
  paymentMethodData: GQL_PaymentMethod;
  billingInfo: GQL_BillingInfo;
};

export const PaymentMethod: React.FC<PaymentMethodProps> = (props) => {
  const { loading = false, paymentMethodData, billingInfo } = props;
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);

  const CardTitle = useCallback(() => <S.CardTitle>Payment details</S.CardTitle>, []);

  const CardExtra = useCallback(
    () => (
      <Button
        size="small"
        text={'Edit'}
        onClick={() => {
          setShowAddPaymentMethod(true);
        }}
      />
    ),
    [],
  );

  const PaymentMethodIcon = useCallback(() => {
    const type = paymentMethodData?.card?.brand || 'master';
    let iconName = MdPayment;

    switch (type) {
      case 'visa':
        iconName = RiVisaLine;
        break;

      case 'master':
      case 'mastercard':
        iconName = RiMastercardFill;
        break;

      case 'diners':
        iconName = FaCcDinersClub;
        break;

      case 'google':
        iconName = FaGooglePlay;
        break;

      case 'apple':
        iconName = FaApplePay;
        break;
    }

    const icon = React.createElement(iconName);
    return <S.CardIcon>{icon}</S.CardIcon>;
  }, [paymentMethodData]);

  const getBilledDate = useMemo(() => {
    const date = moment(paymentMethodData?.created, 'X').format('MM/DD/YYYY');
    return date;
  }, [paymentMethodData]);

  const getNextBillingMonth = useMemo(() => {
    const date = moment(paymentMethodData?.created, 'X');
    const count = billingInfo?.interval_count;
    const interval = billingInfo?.interval as any;
    const nextBilling = date.add(count, interval).format('MM/DD/YYYY');

    return nextBilling;
  }, [paymentMethodData, billingInfo]);

  return (
    <>
      <Card
        title={<CardTitle />}
        extra={<CardExtra />}
        bordered={false}
        hoverable={!loading}
        loading={loading}
        headStyle={{ border: 0 }}
        bodyStyle={{ paddingTop: '10px' }}
        style={{ backgroundColor: '#e8ebed', borderRadius: 0 }}
      >
        <S.PaymentMethodCard gutter={[16, 16]}>
          <div className="cardData">
            <PaymentMethodIcon />
            <div className="cardInfo">
              <div className="lastFour">
                <span role="none" >****</span>
                <span role="none" >****</span>
                <span role="none" >****</span>
                {paymentMethodData?.card?.last4 || '****'}
              </div>
              <p className="cardInfoExpires">
                {' '}
                {paymentMethodData?.card?.brand} - Expires {paymentMethodData?.card?.exp_month || '00'}/
                {paymentMethodData?.card?.exp_year || '00'}{' '}
              </p>
            </div>
          </div>
          <div className="cardBillingCycle">
            <p>
              Billed on <span role="none" >{getBilledDate}</span>.
            </p>
            <p>
              Next billing on <span role="none" >{getNextBillingMonth}</span>
            </p>
          </div>
        </S.PaymentMethodCard>
      </Card>

      {showAddPaymentMethod && (
        <PaymentMethodModal visible={showAddPaymentMethod} setVisible={setShowAddPaymentMethod} />
      )}
    </>
  );
};
