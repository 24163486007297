import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Checkbox, Form as AntdForm, message } from 'antd';
import Form from '../../shared/Form';
import { useQuery, useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_TokenResponse, GQL_SignUpResponse, ISignUpProps } from '../../types/signUp';
import startCase from 'lodash/startCase';
import * as S from './styles';
import adiLearningHubSrc from '../../assets/adi-learning-hub.svg';

type Props = RouteComponentProps<{ token: string }>;

const SignUpPage: React.FC<Props> = (props) => {
  const [form] = AntdForm.useForm();
  const { token } = props.match.params;

  const { data: tokenResp } = useQuery(gqlSchema.AccountsSchema.query.ACCOUNT.SIGN_UP.getToken, {
    variables: { data: { token } },
    onCompleted: ({ invite }: { invite: GQL_TokenResponse }) => {
      if (!invite) {
        message.warning('Invalid invite. Have you already created an account?');
        return props.history.push('/login');
      }

      form.setFieldsValue({ email: invite.email });
      form.setFieldsValue({ firstName: invite.firstName });
      form.setFieldsValue({ lastName: invite.lastName });
    },
    onError: (e) => {
      message.error('Invalid invite.');
      props.history.push('/login');
    },
  });

  const [submitSignUp, { loading }] = useMutation(gqlSchema.AccountsSchema.mutation.ACCOUNT.SIGN_UP.signUp, {
    onCompleted: ({ signup }: { signup: GQL_SignUpResponse }) => {
      message.success('Your account was created successfully');
      props.history.push('/login');
    },
    onError: (e) => {
      message.error(`Error trying to create account: ${e.message}`);
    },
  });

  const onFinish = useCallback(
    async (values: ISignUpProps) => {
      const { firstName, lastName, password } = values;
      submitSignUp({
        variables: {
          data: { firstName, lastName, password, token },
        },
      });
    },
    [submitSignUp, token],
  );

  return (
    <S.Container>
      <S.RowSP justify="center" align="middle">
        <S.ColSP xxl={12} xl={16} lg={20} md={20} sm={24} xs={24}>
          <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Create {startCase(tokenResp?.invite?.role)} Account</S.Title>
          <Form onFinish={onFinish} form={form}>
            <S.TitleInput>EMAIL</S.TitleInput>
            <AntdForm.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Input email',
                },
                {
                  type: 'email',
                },
              ]}
            >
              <S.SInput disabled={true} />
            </AntdForm.Item>
            <S.TitleInput>FIRST NAME</S.TitleInput>
            <AntdForm.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Input your first name!',
                },
              ]}
            >
              <S.SInput placeholder="Enter your First Name" />
            </AntdForm.Item>
            <S.TitleInput>LAST NAME</S.TitleInput>
            <AntdForm.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Input your last name!',
                },
              ]}
            >
              <S.SInput placeholder="Enter your Last Name" />
            </AntdForm.Item>
            <S.TitleInput>PASSWORD</S.TitleInput>
            <AntdForm.Item
              extra="Your password should contain at least 1 uppercase letter, 1 lowercase letter and 1 special character (@, #, $, !)"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  min: 12,
                  message: '- The minimum password length is 12.',
                },
                {
                  pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[$@!#%.:,()])\\S{12,50}$'),
                  message:
                    '- Please include at least one uppercase letter, one lowercase letter, and one special character ($@!#%.:,)',
                },
              ]}
            >
              <S.SInput placeholder="Enter your password" password />
            </AntdForm.Item>
            <S.TitleInput>CONFIRM PASSWORD</S.TitleInput>
            <AntdForm.Item
              name="confirm"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <S.SInput placeholder="Confirm your password" password />
            </AntdForm.Item>
            <AntdForm.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Please, read and agree with the terms')),
                },
              ]}
            >
              <Checkbox>
                I have read and agree to the{' '}
                <a
                  href="https://www.argumentdriveninquiry.com/learning-hub/terms-and-conditions-for-the-adi-learning-hub"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>{' '}
                and understand the{' '}
                <a
                  href="https://www.argumentdriveninquiry.com/learning-hub/privacy-policy-for-the-adi-learning-hub"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </Checkbox>
            </AntdForm.Item>
            <S.LinkButton
              type="link"
              onClick={() => {
                props.history.push('/login');
              }}
            >
              ALREADY HAVE AN ACCOUNT?
            </S.LinkButton>
            <AntdForm.Item>
              <S.CreateButton text="Create Account" htmlType="submit" loading={loading} block />
            </AntdForm.Item>
          </Form>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );
};

export default withRouter(SignUpPage);
