import { Row } from 'antd';
import styled from 'styled-components';

export const CardTitle = styled.div`
  font-weight: bold;
  color: #9b9b9b;
  font-size: 12px;
`;

export const InvoiceRow = styled(Row)`
  width: 100%;
  margin-bottom: 6px;
  font-size: 13px;
  color: black;
  font-weight: 500;

  .price-element {
    color: #9b9b9b;
    text-align: right;
  }
`;
