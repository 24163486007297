// import { mutation } from './../../Class/mutation/index';
import { gql } from '@apollo/client';

export const CANVAS_MUTATIONS = {
    CANVASCLASS: {
        CreateCanvasCourse: gql`
        mutation CreateCanvasCourse($data:CreateClassInput!) {
    createCanvasCourse(
        data: $data
    ) {
        id
        account_id
        name
        uuid
        created_at
        default_view
        course_code
        license
        time_zone
        workflow_state
    }
}

        `,
        UpdateCanvasCourse: gql`
        mutation UpdateCanvasCourse($id:String,$data:CanvasCoursesType) {
    updateCanvasCourse(
        data: $data
        id: $id
    ) {
        id
        account_id
        name
        uuid
        created_at       
        default_view
        course_code
        license
        time_zone
        workflow_state
    }
}
        `,

        CreateCanvasAssignment: gql`
        mutation CreateCanvasAssignment($course_id:String,$data:CanvasAssignmentType) {
    createCanvasAssignment(
        course_id: $course_id
        data: $data
    )  {
        id
        name
        description
        due_at
        created_at
        points_possible
        submission_types
        group_category_id
        position
        grading_type
        assignment_group_id
        peer_reviews
        locked_for_user
        lock_at
        unlock_at
        updated_at
        use_rubric_for_grading
        grade_group_students_individually
        anonymous_peer_reviews
        group_category_name
        needs_grading_count
        all_override_needs_grading_count
        only_visible_to_overrides
        all_dates {
            id
            due_at
            unlock_at
            lock_at
            base
            title
        }
        rubric_settings {
            id
            points_possible
            hide_points
            url
        }
        overrides {
            id
            assignment_id
            student_ids
            group_id
            course_section_id
            title
            due_at
            all_day
            all_day_date
            unlock_at
            lock_at
        }
        section_due_dates {
            id
            due_at
            section_id
            base
        }
    }
}
        `,
        UpdateCanvasAssignment: gql`
        mutation UpdateCanvasAssignment($course_id:String,$id:String,$data:CanvasAssignmentType) {
    updateCanvasAssignment(
        course_id: $course_id
        data: $data
        id: $id
    ) {
        id
        name
        description
        due_at
        points_possible
        submission_types
        group_category_id
        position
        grading_type
        assignment_group_id
        peer_reviews
        locked_for_user
        lock_at
        unlock_at
        updated_at
        use_rubric_for_grading
        grade_group_students_individually
        anonymous_peer_reviews
        group_category_name
        needs_grading_count
        all_override_needs_grading_count
        only_visible_to_overrides
        allowed_extensions
    }
}

        `,
        CreateOverrideCanvasAssignment: gql`
        mutation CreateOverrideCanvasAssignment(
          $data: CanvasAssignmentOverrideType,
          $course_id: String,
          $id: String
        ) {
          createOverrideCanvasAssignment(
            data: $data,
            course_id: $course_id,
            id: $id
          ) {
            id
            assignment_id
            title
            unassign_item
            student_ids
          }
        }
      `,
        UpdateOverrideCanvasAssignment: gql`
      mutation UpdateOverrideCanvasAssignment(
  $course_id: String,
  $assignment_id: String,
  $id: String,
  $data: CanvasAssignmentOverrideType
) {
  updateOverrideCanvasAssignment(
    course_id: $course_id,
    assignment_id: $assignment_id,
    id: $id,
    data: $data
  ) {
    id
    assignment_id
    title
    unassign_item
    student_ids
  }
}
    `,
        CreateCanvasInvitation: gql`
        mutation CreateCanvasInvitation($course_id:String,$data:CanvasInvitationType) {
    createCanvasInvitation(
        course_id: $course_id
        data: $data
    ) {
        course_id
        course_section_id
        id
        user_id
        type
        created_at
        updated_at
        root_account_id
        role_id
        enrollment_state
    }
}

        `,
        UploadFile: gql`
    mutation UploadFilesForGoogleClassroom($file: [Upload!]!, $courseId: String!) {
    uploadFilesForGoogleClassroom(file: { file: $file, courseId: $courseId }) 
}
 
    `,
        CreateCanvasSubmission: gql`
        mutation CreateCanvasSubmission($course_id:String,$assignment_id:String,$data:SubmissionStudentData) {
    createCanvasSubmission(
        course_id: $course_id
        assignment_id: $assignment_id
        data: $data
    ) {
        id
        body
        url
        grade
        score
        submitted_at
        assignment_id
        user_id
        attempt
        preview_url
        submiission_type
        workflow_state
        grade_matches_current_submission
    }
}
        `,
        GradeCanvasSubmission: gql`
        mutation GradeCanvasSubmission($course_id: String, $assignment_id: String, $user_id: String, $data: GradeData) {
            gradeCanvasSubmission(
                course_id: $course_id
                assignment_id: $assignment_id
                user_id: $user_id
                data: $data
            ) {
                id
                url
                grade
                score
                submitted_at
                assignment_id
                user_id
                submission_type
                workflow_state
                grade_matches_current_submission
                graded_at
                graded_id
                posted_at
                preview_url
            }
        }
    `,

        DeleteCanvasCourse: gql`
    mutation DeleteCanvasCourse($course_id:String) {
    deleteCanvasCourse(
        course_id: $course_id
    )
}

    `,
        DeleteCanvasAssignment: gql`
  mutation DeleteCanvasAssignment($course_id: String, $assignment_id: String) {
    deleteCanvasAssignment(course_id: $course_id, assignment_id: $assignment_id) {
      id
        name
        description
        due_at
        created_at
        points_possible
        submission_types
        group_category_id
        position
        grading_type
        published
        assignment_group_id
        peer_reviews
        locked_for_user
        lock_at
        unlock_at
        updated_at
        use_rubric_for_grading
        grade_group_students_individually
        anonymous_peer_reviews
        group_category_name
        needs_grading_count
        all_override_needs_grading_count
        only_visible_to_overrides
        allowed_extensions
        turnitin_enabled
        submissions_download_url
        courseName
    }
  }
`,

        CanvasSync: gql`
        mutation CanvasCourseSync {
    canvasCourseSync
}

`,

    },



};
export default CANVAS_MUTATIONS;