import styled from 'styled-components';
import { Steps as AntdSteps } from 'antd';

const { Step: AntdStep } = AntdSteps;

export const Title = styled.h2`
  margin-bottom: 25px;
  font-weight: bold;
  text-align: center;
`;

export const SubStepsContainer = styled.div`
  padding-top: 15px;
`;

export const Step = styled(AntdStep)`
  &.ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail:after {
    background-color: ${(props) => props.theme.success.color} !important;
  }

  .ant-steps-item-tail:after {
    background-color: ${(props) => props.theme.primaryOutlined.color} !important;
  }

  &.ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon {
    background-color: ${(props) => props.theme.success.color} !important;
    border-color: ${(props) => props.theme.success.color} !important;
    svg {
      color: white;
      margin-top: 8px;
    }
  }

  .ant-steps-item-icon {
    background-color: white !important;
    border-color ${(props) => props.theme.primaryOutlined.color} !important;
    border-width: 2px;
    .ant-steps-icon {
      color: ${(props) => props.theme.primaryOutlined.color} !important;
      font-weight: bold;
    }
  }

  .ant-steps-item-title {
    font-size: 18px;
  }
`;

export const Steps = styled(AntdSteps)<{ $finished: boolean }>`
  padding: 0 15px;
  font-weight: bold;
  &.ant-steps-vertical > div:nth-last-child(2) {
    .ant-steps-item-tail:after {
      ${(props) => !props.$finished && 'background-color: transparent !important;'}
      ${(props) => !props.$finished && `border: 0.5px dashed ${props.theme.primaryOutlined.color};`}
    }
  }

  .ant-steps-item-title {
    color: ${(props) => props.theme.primaryOutlined.color} !important;
  }

  .ant-steps-item-finish .ant-steps-item-title {
    color: ${(props) => props.theme.success.color} !important;
  }
`;

export const SubStepText = styled.div<{
  done?: boolean;
  bold?: boolean;
  canClick?: boolean;
}>`
  color: ${(props) => (props.done ? props.theme.success.color : props.theme.primaryOutlined.color)};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 8px;
  cursor: ${(props) => (props.canClick ? 'pointer' : 'not-allowed')};

  svg {
    min-width: 18px;
    min-height: 18px;
    margin-left: 4px;
  }
`;
