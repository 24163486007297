import React, { useState } from 'react';
import Modal from 'antd/lib/modal';
import { useCallback } from 'react';
import styled from 'styled-components';
import GenericCardButton from '../components/GenericCardButton';
import Spacer from '../shared/Spacer';
import { BiMessageError } from 'react-icons/bi';

const useBetaFeedbackModal = () => {
  const [hasOpenModal, setHasOpenModal] = useState(false);

  const openModal = useCallback(() => {
    if (hasOpenModal) return;
    setHasOpenModal(true);

    Modal.info({
      title: <Title>Beta Test Feature Feedback</Title>,
      width: 800,
      closable: true,
      afterClose: () => setHasOpenModal(false),
      maskClosable: true,
      icon: null,
      zIndex: 1200,
      bodyStyle: {
        marginBottom: -12,
      },
      okButtonProps: {
        style: {
          display: 'none',
        },
      },
      content: (
        <>
          <Text>
            We value your feedback! As one of the first users of this feature, let us know how we could improve it and
            any troubles you had in the form below.
          </Text>
          <Spacer size={24} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <GenericCardButton
              onClick={() =>
                window.open('https://survey.hsforms.com/1zs2h1BfEQD-i-jqaPFZFdA5gbsq', '_blank', 'noopener noreferrer')
              }
              icon={<BiMessageError />}
              title="Leave feedback"
              iconColor="#20BC89"
              infoLineOne="Click here to access the survey form."
              width="350px"
            />
          </div>
        </>
      ),
    });
  }, [hasOpenModal]);

  return { openModal };
};

export default useBetaFeedbackModal;

const Title = styled.h3`
  text-align: center;
  margin: 0;
`;

const Text = styled.p`
  color: #767676;
  text-align: center;
`;
