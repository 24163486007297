import { gql } from '@apollo/client';

export const SHOPIFY_MUTATIONS = {
 SEND_QUOTE_EMAIL:gql`
 mutation SendQuoteEmail($pdfUrl:String,$metadata:String) {
    sendQuoteEmail(pdfUrl:$pdfUrl,metadata:$metadata)
}`,

UPLOAD_QUOTE:gql`
mutation SaveQuoteToS3($file:Upload!) {
    saveQuoteToS3(file:$file)
}
`,
ADD_TO_CART: gql`
    mutation AddToCart($productId: String!, $quantity: Int!) {
      addToCart(productId: $productId, quantity: $quantity) {
        id
        userId
        cartItem {
          productId
          quantity
          price
        }
      }
    }
  `,
  UPDATE_CART_ITEM: gql`
  mutation UpdateCartItem($productId: String!, $quantity: Int!) {
    updateCartItem(productId: $productId, quantity: $quantity) {
      id
      userId
      cartItem {
        productId
        quantity
        price
      }
    }
  }
`,
REMOVE_CART_ITEM: gql`
  mutation RemoveCartItem($productId: String!) {
    removeCartItem(productId: $productId) {
      id
      userId
      cartItem{
        productId
        quantity
        price
      }
    }
  }
`,

};
//updateCartItem(productId: String!, quantity: Int!): Cart
// removeCartItem(productId: String!): Boolean
export default SHOPIFY_MUTATIONS;
