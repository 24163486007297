import React, { useState } from 'react';
import { Row, Typography, PopoverProps, message } from 'antd';
import * as S from './styles';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';

type OnboardingTooltipProps = {
  text: string;
} & PopoverProps;

const OnboardingTooltip = ({ text, children, ...rest }: OnboardingTooltipProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  useQuery(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.me, {
    onCompleted: (data) => setVisible(!data.me.dismissedOnboarding),
    onError: (e) => message.error('Error fetching profile: ' + e.graphQLErrors[0]),
    fetchPolicy: 'cache-and-network',
  });

  const [dismissOnboarding] = useMutation(gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.dismissOnboarding, {
    refetchQueries: [gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.me],
    awaitRefetchQueries: true,
    onError: (e) => {
      message.error('Error while dismissing onboarding tooltips: ' + e.message);
      setVisible(true);
    },
  });

  const handleClose = () => {
    setVisible(false);
    dismissOnboarding();
  };

  return (
    <S.TooltipArea
      {...rest}
      arrowPointAtCenter
      arrowContent
      visible={visible}
      overlayClassName="onboarding-tooltip-overlay"
      overlayInnerStyle={{ backgroundColor: '#303030' }}
      content={
        <Row align="middle" data-cy="shared-onboardingtooltip-area">
          <Typography.Text style={{ color: 'white', maxWidth: 400, wordBreak: 'break-all' }}>{text}</Typography.Text>
          <S.TooltipCloseButton
            data-cy="shared-onboardingtooltip-button-close"
            onClick={handleClose}
            type="link"
            icon={<S.TooltipCloseButtonIcon />}
          />
        </Row>
      }
    >
      {children}
    </S.TooltipArea>
  );
};

export default OnboardingTooltip;
