import { gql } from '@apollo/client';
import TAGS_FRAGMENTS from '../fragments/Tags';

const TAGS_QUERIES = {
  getTags: gql`
    query getTags($data: GetTagsInput) {
      getTags(data: $data) {
        ...TagResponseFragment
      }
    }
    ${TAGS_FRAGMENTS.tagResponse}
  `,
  getTag: gql`
    query getTag($id: String!) {
      getTag(id: $id) {
        ...TagResponseFragment
      }
    }
    ${TAGS_FRAGMENTS.tagResponse}
  `,
  getUsersWithTags: gql`
    query getUsersWithTags($tagIds: [String]!) {
      getUsersWithTags(tagIds: $tagIds) {
        id
        firstName
        lastName
        roles
      }
    }
  `,
};

export default TAGS_QUERIES;
