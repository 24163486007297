import React, { useEffect, useState } from 'react'
import { Col, message, Pagination, Row } from 'antd'
import Button from '../../../../shared/Button'
import * as S from './styles'
import { SearchOutlined } from '@ant-design/icons';
import UpdateBookModal from './UpdateModal';
import { gqlSchema } from '../../../../gql/schema';
import { useLazyQuery, useMutation,  } from '@apollo/client';
import NoImageAvailble from '../../../../assets/no-image-available.png';
import LibrarySkeleton from '../../Library/LibrarySkeleton';

const BookKits = ({ selectedKits, refetchBook, bookId }: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [kitsData, setKitsData] = useState<any[]>([]);
  const [addedData, setAddedData] = useState<any | null>(selectedKits);
  const [removedData, setRemovedData] = useState<any | null>(null);

  const [fetchKits, { loading }] = useLazyQuery(gqlSchema.BookSchema.queries.GetAllShopifyProducts, {
    variables: { page: currentPage, pageSize: 12, filter: { type: 'kit' } },
    onCompleted: (data: any) => {
      if (data?.getAllShopifyProducts) {
        setKitsData(data.getAllShopifyProducts);
      }
    },
  });

  const [assignInvestigationKitBundle] = useMutation(gqlSchema.BookSchema.mutations.AssignInvestigationKitBundle, {
    onError: (error: any) => {
      message.error(`Error in assigning investigation kit bundle - ${error.message || 'Unexpected Error'}`);
    },
  });

  useEffect(() => {
    fetchKits({
      variables: { page: currentPage, pageSize: 12, filter: { type: 'Kit' } },
    });
  }, [currentPage, fetchKits]);

  const handleKitClick = (kit: any) => {
    if (addedData?.id === kit.id) {
      // Deselect the current kit and restore the original kit
      setAddedData(selectedKits);
      setRemovedData(null);
    } else {
      // Select a new kit and remove the original kit (if not already marked for removal)
      setAddedData(kit);
  
      if (selectedKits && selectedKits.id !== kit.id) {
        setRemovedData(selectedKits);
      } else {
        setRemovedData(null);
      }
    }
  };
  
  const onSubmit = async () => {
    try {
      const finalData = addedData?.id ? [addedData.id] : []; // Ensure the final selection
  
      if (removedData?.id && !finalData.includes(removedData.id)) {
        // Remove the original kit if it was marked for removal
        finalData.push(removedData.id);
      }
  
      const { data } = await assignInvestigationKitBundle({
        variables: {
          bookId,
          kitBundleId: finalData[0] || null, // Use null if no kit is selected
        },
      });
  
      if (data?.assignInvestigationKitBundle?.success) {
        refetchBook();
        message.success('Investigations assigned successfully');
      } else {
        message.error(data?.assignInvestigationKitBundle?.message || 'Error assigning kits');
      }
    } catch (error) {
      console.error('Error assigning investigation kit bundle:', error);
    }
  };
  
  const handleInv = (kitId: string) => {
    return addedData?.id === kitId ;
  };
  

  return (
    <S.Container>
      <S.FilterRow>
        <S.SearchContainer>
          <SearchOutlined
            style={{ position: 'absolute', backgroundColor: 'transparent', marginLeft: 10 }}
          />
          <S.SearchInput
            value={searchQuery}
            type="search"
            placeholder="Search Kit"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </S.SearchContainer>

        <Button
          text="Update"
          style={{
            backgroundColor: '#4367E9',
            color: '#fff',
            width: 'auto',
            fontSize: '0.8rem',
            textTransform: 'uppercase',
          }}
          onClick={() => setIsModalVisible(true)}
          block
        />
      </S.FilterRow>

      <Row gutter={[16, 16]}>
        {loading ? (
          <LibrarySkeleton row={2} />
        ) : (
          kitsData.map((kit: any, idx: number) => (
            <KitsCard
              key={idx}
              kit={kit}
              text={handleInv(kit.id) ? 'Remove' : 'Add'}
              handleKitClick={() => handleKitClick(kit)}
            />
          ))
        )}
      </Row>

      <UpdateBookModal
        addedData={addedData}
        removedData={removedData}
        setaddedData={setAddedData}
        setRemoveData={setRemovedData}
        isModalVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        type="Kit Update"
        onSubmit={onSubmit}
      />

      <S.PaginationContainer>
        <Pagination
          current={currentPage}
          total={kitsData.length || 0}
          pageSize={12}
          onChange={(val) => setCurrentPage(val)}
        />
      </S.PaginationContainer>
    </S.Container>
  );
};





const KitsCard = ({ kit, text, handleKitClick }: any) => {
  return (
    <Col key={kit.id} xs={24} sm={12} md={6}>
      <S.KitCard>
        <S.ImageWrapper>
          <img src={kit.coverImage || NoImageAvailble} alt={kit.title} />
        </S.ImageWrapper>
        <S.KitTitle>{kit.title}</S.KitTitle>
        <Button
          text={text}
          style={{
            backgroundColor: text === 'Add' ? '#4367E9' : '#df1818',
            color: text === 'Add' ? '#fff' : '#4367E9',
          }}
          onClick={handleKitClick}
          block
        />
      </S.KitCard>
    </Col>
  );
};


export default BookKits
