import React, { useEffect, useState } from 'react';
import { fromHtml, toHtml, isDocNodeEmpty } from 'remirror/core';
import { RemirrorProvider, useManager } from 'remirror/react';
import { MathExtension } from './EditableEditor/MathExtension';
import { BoldExtension } from 'remirror/extension/bold';
import { ItalicExtension } from 'remirror/extension/italic';
import { UnderlineExtension } from 'remirror/extension/underline';
import { ParagraphExtension } from 'remirror/extension/paragraph';
import { PlaceholderExtension } from 'remirror/extension/placeholder';
import { LinkExtension } from 'remirror/extension/link';
import { BulletListExtension, OrderedListExtension, ListItemExtension } from 'remirror/preset/list';
import { IOnChageEditor } from '../../types';
import EditableEditor from './EditableEditor';
import ReadOnlyEditor from './ReadOnlyEditor';

const extraAttributes = { class: { default: '' } };

const extensions = (placeholder?: string, autoLink?: boolean) => {
  const ext = [
    new BoldExtension(),
    new ItalicExtension(),
    new UnderlineExtension(),
    new MathExtension(),
    new OrderedListExtension({ extraAttributes }),
    new BulletListExtension({ extraAttributes }),
    new ListItemExtension({ extraAttributes }),
    new ParagraphExtension({ extraAttributes }),
    new PlaceholderExtension({ placeholder }),
    new LinkExtension({ extraAttributes: { target: '_blank' }, selectTextOnClick: true, autoLink }),
  ];

  return ext;
};

interface Props {
  onChange?: (data: IOnChageEditor) => void;
  value?: string | null;
  editable?: boolean;
  placeholder?: string;
  fontSize?: number;
  title?: string;
  showLink?: boolean;
  showAlign?: boolean;
  listenToValue?: boolean;
  'data-cy'?: string;
}

const Editor: React.FC<Props> = (props) => {
  const {
    onChange,
    value = '',
    editable = true,
    placeholder = '',
    fontSize,
    title,
    showLink,
    showAlign,
    listenToValue,
    'data-cy': dataCy,
  } = props;
  const manager = useManager(() => extensions(placeholder, showLink));
  const [isEditorEmpty, setIsEditorEmpty] = useState(false);

  // Create the initial value for the manager.
  const [initialValue, setInitialValue] = useState(
    manager.createState({
      content: value ?? '',
      stringHandler: fromHtml,
    }),
  );

  useEffect(() => {
    if (listenToValue) {
      setInitialValue(
        manager.createState({
          content: value ?? '',
          stringHandler: fromHtml,
        }),
      );
    }
  }, [value, manager, listenToValue]);

  const onChangeEditor = (value: any) => {
    setInitialValue(value.state);
    const htmlString = toHtml({
      node: value.state.doc,
      schema: value.state.schema,
    });
    const _isEditorEmpty = isDocNodeEmpty(value.state.doc);
    setIsEditorEmpty(_isEditorEmpty);
    if (onChange) onChange({ value: htmlString, isEditorEmpty: _isEditorEmpty });
  };

  const getEditor = () => {
    if (!editable) return <ReadOnlyEditor data-cy={dataCy} />;

    return (
      <EditableEditor
        isEditorEmpty={isEditorEmpty}
        title={title}
        showLink={showLink}
        showAlign={showAlign}
        data-cy={dataCy}
      />
    );
  };

  return (
    <RemirrorProvider manager={manager} value={initialValue} onChange={onChangeEditor} editable={editable}>
      <div style={{ fontSize }}>{getEditor()}</div>
    </RemirrorProvider>
  );
};

export default Editor;
