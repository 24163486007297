import { Filter } from '../../utils/antd';

export enum SubscriptionPackageType {
  STARTER = 'Starter',
  PRO = 'Pro',
  PREMIUM = 'Premium',
}

export const accTypePackages: Filter[] = [
  { text: 'Starter', value: 'Starter', color: '#a91f3e' },
  { text: 'Pro', value: 'Pro', color: '#45b2cc' },
  { text: 'Premium', value: 'Premium', color: '#7B8CEA' },
];
