import React, { useCallback } from 'react';
import { Col, Row, Dropdown, Menu, Tooltip } from 'antd';
import { FiChevronDown, FiDownload } from 'react-icons/fi';
import Papa from 'papaparse';

import InsightsFilterTypes from '../InsightsType';
import AssessmentInsightFilterBy from '../AssessmentsFiltersBy';
import RangePicker from '../../../shared/RangePicker';
import Button from '../../../shared/Button';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import { GQL_ClassResponse, GQL_ClassUserOrInvite } from '../../../types/class';
import { downloadCsv } from '../../../utils/files';
import { themeConfig } from '../../../utils/theme';
import { GQL_AssessmentsScoresResponse } from '../../../types/gradeBooking';
import { IInvestigationLibraryFilter } from '../../AssessmentLibraryPage';
import { InsightsType } from './../../../constants/enums/InsightsTypes';
import Spacer from '../../../shared/Spacer';
import { formatDateTime } from '../../../utils/date';
import * as S from './styles';
import { GQL_InvestigationStandard } from '../../../types/investigationStandard';

interface Duration {
  startDate: string;
  endDate: string;
}

interface IAssessmentsInsightsFilters {
  insightType: InsightsType;
  loadingClasses?: boolean;
  loadingStudents?: boolean;
  classesData?: GQL_ClassResponse[];
  studentsData?: GQL_ClassUserOrInvite[];
  selectedClassId?: string;
  filters: IInvestigationLibraryFilter[];
  assessments: GQL_AssessmentsScoresResponse[] | undefined;
  duration: Duration | undefined;
  setDuration?: (duration: Duration) => void;
  setFilters: (filters: IInvestigationLibraryFilter[]) => void;
  setSelectedClassId: (id: string) => void;
  selectedStudentId?: string;
  setSelectedStudentId: (id: string) => void;
  insightsData?: GQL_AssessmentsScoresResponse[];
  comparingAssessments?: boolean;
  setComparingAssessments: (comparing: boolean) => void;
  canCompareAssessments?: boolean;
  onExportTempranoData?: () => void;
  selectedAssessmentIds: string[];
  setSelectedAssessmentIds: (ids: string[]) => void;
  stateStandardsData: GQL_InvestigationStandard[];
  stateStandardsLoading: boolean;
  setSelectedStateStandards: (ids: string[]) => void;
  canCompareByStandards: boolean;
}

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
const todaysDate = formatDateTime(tomorrow.getTime());

const AssessmentsInsightsFilters = (props: IAssessmentsInsightsFilters) => {
  const {
    insightType,
    classesData,
    loadingClasses,
    loadingStudents,
    setSelectedClassId,
    filters,
    setFilters,
    duration,
    setDuration,
    selectedClassId,
    studentsData,
    selectedStudentId,
    assessments,
    setSelectedStudentId,
    insightsData,
    comparingAssessments,
    setComparingAssessments,
    canCompareAssessments,
    onExportTempranoData,
    selectedAssessmentIds,
    setSelectedAssessmentIds,
    stateStandardsData,
    stateStandardsLoading,
    setSelectedStateStandards,
    canCompareByStandards,
  } = props;

  const exportTableData = useCallback(() => {
    const headers = [
      [
        'Assessment',
        'Open Date',
        'Standards',
        'Assessment Score (out of 9 per student)',
        'Assessment Percent',
        'Performance Level',
      ],
    ];
    const data =
      insightsData?.map((assessment) => {
        return [
          assessment.assessmentTitle,
          formatDateTime(assessment.startDate),
          assessment.standards.map((standard) => standard?.code),
          `${assessment.assessmentScore} / ${assessment.maxAssessmentScore}`,
          assessment.assessmentPercent.toFixed(2) + '%',
          assessment.performanceLevel,
        ];
      }) || [];

    downloadCsv(Papa.unparse([...headers, ...data]), `AssessmentGradeBook.csv`);
  }, [insightsData]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={6} xl={4} xxl={3}>
          <InsightsFilterTypes insightType={insightType} />
        </Col>
        <Col md={8} xl={6} xxl={4}>
          <Select
            placeholder="Select Class"
            showSearch
            value={selectedClassId}
            filterOption={(input, option) =>
              option?.name?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
            }
            onChange={(v) => setSelectedClassId(v as string)}
            loading={loadingClasses}
            data-cy="components-teacher-insights-select-class-dropdown"
          >
            {classesData?.map((classObj) => (
              <SelectOption value={classObj.id} key={classObj.id} name={classObj.name}>
                {classObj.name}
              </SelectOption>
            ))}
          </Select>
        </Col>
        <Col md={8} xl={6} xxl={4}>
          <Select
            showSearch
            showArrow
            allowClear
            placeholder="Select Assessments"
            mode="multiple"
            filterOption={(input, option) =>
              option?.name?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
            }
            onChange={(v) => setSelectedAssessmentIds(v as string[])}
            disabled={!selectedClassId}
            data-cy="components-assessment-insights-assessment-dropdown"
          >
            {insightsData?.map((insightsObject) => (
              <SelectOption
                value={insightsObject.assessmentId}
                key={insightsObject.assessmentId}
                name={insightsObject.assessmentTitle}
                disabled={
                  selectedAssessmentIds.length === 2
                    ? !selectedAssessmentIds.includes(insightsObject.assessmentId)
                    : false
                }
              >
                {insightsObject.assessmentTitle}
              </SelectOption>
            ))}
          </Select>
        </Col>
        {stateStandardsData?.length >= 1 && (
          <Col md={8} xl={6} xxl={4}>
            <Select
              showArrow
              showSearch
              allowClear
              placeholder="Select Standards"
              mode="multiple"
              filterOption={(input, option) =>
                option?.name?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
              }
              onChange={(v) => setSelectedStateStandards(v as string[])}
              disabled={!selectedClassId || stateStandardsLoading}
              data-cy="components-assessment-insights-standard-dropdown"
            >
              {stateStandardsData?.map((stateStandard) => {
                const name = `${stateStandard.code} - ${stateStandard.fullStatement}`;
                return (
                  <SelectOption value={stateStandard.id} key={stateStandard.id} name={name} title={name}>
                    {name}
                  </SelectOption>
                );
              })}
            </Select>
          </Col>
        )}
        <Col md={6} xl={4} xxl={4}>
          <Select
            placeholder="Select Student"
            showSearch
            filterOption={(input, option) =>
              option?.name?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
            }
            value={selectedStudentId}
            onChange={(v) => setSelectedStudentId(v as string)}
            loading={loadingStudents}
            disabled={!selectedClassId}
          >
            {studentsData?.map((studentObj) => (
              <SelectOption
                value={studentObj.userId || ''}
                key={studentObj.userId}
                name={studentObj.firstName + ' ' + studentObj.lastName}
              >
                {studentObj.firstName + ' ' + studentObj.lastName}
              </SelectOption>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'end' }}>
        <Col md={7} xl={6} xxl={4}>
          <small style={{ fontWeight: 500 }}>Open date filter:</small>
          <RangePicker
            value={duration}
            onChange={setDuration}
            disabledDate={(d) => d.isAfter(todaysDate)}
            allowClear={true}
          />
        </Col>
        <Col md={8} xl={9} xxl={12}>
          <AssessmentInsightFilterBy assessments={assessments} filters={filters} setFilters={setFilters} />
        </Col>
        <Col md={9} xl={9} xxl={8}>
          <Row justify="end" style={{ width: '100%' }}>
            <Dropdown
              overlay={
                <S.CMenu>
                  <Menu.Item disabled={!insightsData} onClick={exportTableData}>
                    Export Gradebook Data
                  </Menu.Item>
                  <Menu.Item disabled={!insightsData} onClick={onExportTempranoData}>
                    Export Class Data
                  </Menu.Item>
                </S.CMenu>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <S.DownloadContainer>
                <FiDownload />
                <span role="none" >Export Data</span>
                <FiChevronDown />
              </S.DownloadContainer>
            </Dropdown>
            <Spacer axis="horizontal" />
            {comparingAssessments ? (
              <Button
                onClick={() => setComparingAssessments(!comparingAssessments)}
                theme={themeConfig.secondaryColor}
                text={'Stop Comparing'}
                minHeight={40}
                data-cy="components-assessment-insights-stop-comparison-button"
              />
            ) : (
              <Dropdown
                overlay={
                  <S.CMenu>
                    <S.CMenuItemWrapper
                      disabled={!canCompareAssessments}
                      onClick={() => setComparingAssessments(!comparingAssessments)}
                      data-cy="components-assessment-insights-compare-by-assessments-button"
                    >
                      <Tooltip
                        placement="left"
                        title={
                          canCompareAssessments
                            ? undefined
                            : 'Please select 2 assessments in the assessments filter to enable comparing'
                        }
                      >
                        <S.CMenuItemLabel>Compare By Assessments</S.CMenuItemLabel>
                      </Tooltip>
                    </S.CMenuItemWrapper>
                    {stateStandardsData?.length >= 1 && (
                      <S.CMenuItemWrapper
                        disabled={!canCompareByStandards}
                        onClick={() => setComparingAssessments(!comparingAssessments)}
                        data-cy="components-assessment-insights-compare-by-standards-button"
                      >
                        <Tooltip
                          placement="left"
                          title={
                            canCompareByStandards
                              ? undefined
                              : 'Please select at least 2 state standards in the standards filter to enable comparing'
                          }
                        >
                          <S.CMenuItemLabel>Compare By Standards</S.CMenuItemLabel>
                        </Tooltip>
                      </S.CMenuItemWrapper>
                    )}
                  </S.CMenu>
                }
                placement="bottomRight"
                trigger={['click']}
              >
                <S.CompareContainer data-cy="components-assessment-insights-compare-dropdown">
                  <span role="none" >Compare</span>
                  <FiChevronDown />
                </S.CompareContainer>
              </Dropdown>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AssessmentsInsightsFilters;
