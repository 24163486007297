import styled from 'styled-components';

export const Container = styled.div<{
  completed?: boolean;
  hasAction?: boolean;
  expanded?: boolean;
  disabled?: boolean;
}>`
  background: ${(props) => (props.disabled ? '#e4e4e4' : '#ffffff')};
  border: 1px solid #c4c4c4;
  ${(props) => props.expanded && 'border-color: #000000;'}
  ${(props) => props.completed && 'border-color: #2ecc71;'}
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  padding: 20px 24px;

  transition-property: border-color;
  transition-duration: 0.7s;
  transition-timing-function: ease;
  transition-delay: 0s;

  & > .ant-row {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : props.hasAction ? 'pointer' : 'auto')};
  }
`;

export const Wrapper = styled.span`
  & > p {
    margin: 0;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 125.1% !important;
    color: #767676 !important;
  }

  & > h1 {
    margin: 0;
    font-weight: bold !important;
    font-size: 18px !important;
  }

  max-width: 80%;
  text-align: start !important;
`;

export const IconContainer = styled.div<{ completed?: boolean }>`
  background-color: ${(props) => (props.completed ? '#2ecc71' : '#c4c4c4')};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const AnimatedDiv = styled.div<{ expanded: boolean }>`
  height: ${(props) => (props.expanded ? '100%' : 0)};
  transition-property: max-height;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  transition-delay: 0s;
  overflow: hidden;
`;
