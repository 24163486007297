import React, { useState, useRef, useEffect } from 'react';
import { Layout, Card, Input, Button, Dropdown, Menu, Modal, Tooltip, Spin, message } from 'antd';
import { DownOutlined, LinkOutlined, DeleteOutlined } from '@ant-design/icons';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema'; 
import CoreSettingsSider from './CoreSettingsSider'; 
import { RemirrorProvider } from 'remirror/react'; 
import Editor from '../../shared/Editor';
import UploadInput from '../../shared/UploadInput';
import moment from 'moment';
import { FiArrowLeft } from 'react-icons/fi';
import { MdFilterList } from 'react-icons/md';

const { Content} = Layout;

interface Params {
  courseId: string;
  assignmentId: string;
}

const CanvasAssignmentCorePage: React.FC = () => {
  const { courseId, assignmentId } = useParams<Params>();
  const [assignmentName, setAssignmentName] = useState<string>('');
  const [assignmentDescription, setAssignmentDescription] = useState<string>('');
  const [dueDate, setDueDate] = useState<moment.Moment | null>(null);
  const [maxPoints, setMaxPoints] = useState<number | undefined>(undefined);
  const [isFormModalVisible, setIsFormModalVisible] = useState<boolean>(false);
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [formLinks, setFormLinks] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dueTime, setDueTime] = useState<moment.Moment | null>(null);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const editorRef = useRef<any>(null);
  const [newAssignmentId, setNewAssignmentId] = useState<string>('');
  const history = useHistory();
  const [urlData, setUrlData] = useState<string>('');
  const [showSider, setShowSider] = useState<boolean>(true);

  
  const { refetch } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentById,
    {
      variables: {
        course_id: courseId,
        id: assignmentId,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const assignment = data?.getCanvasAssignmentById;
  
        if (assignment) {
          setAssignmentName(assignment.name || '');
          setAssignmentDescription(assignment.description || '');
          setMaxPoints(assignment.points_possible || undefined);
  
          if (assignment.due_at) {
            const dueDateTime = moment.utc(assignment.due_at);
            setDueDate(dueDateTime.clone().startOf('day'));
            const timePart = dueDateTime.clone();
            setDueTime(timePart);
          }
        }
      },
    }
  );

  const [createCanvasAssignment, { loading: mutationLoading }] = useMutation(
    gqlSchema.CanvasSchema.mutations.CANVASCLASS.CreateCanvasAssignment, {
      onCompleted: (data) => {
        console.log("Mutation response data:", data);
        const newAssignmentId = data?.createCanvasAssignment?.id;
  
        if (newAssignmentId) {
          console.log("New Assignment ID from createCanvasAssignment:", newAssignmentId);
          setNewAssignmentId(newAssignmentId);  // Sets the state for further use, if needed
  
          message.success('Assignment created successfully!');
  
          // Refetch using the new assignment ID
          refetch({
            course_id: courseId,
            id: newAssignmentId,
          });
  
          // Redirect to the new assignment summary page using the newly obtained assignment ID
          history.push(`/canvas-teacher-dashboard/class/${courseId}/assignment-summary/${newAssignmentId}`);
        } else {
          console.error("Failed to retrieve new assignment ID");
        }
      },
      onError: (error) => {
        console.error('Error creating assignment:', error);
      }
    }
  );
  
  

  const [updateCanvasAssignment, { loading: updateLoading }] = useMutation(
    gqlSchema.CanvasSchema.mutations.CANVASCLASS.UpdateCanvasAssignment,
    {
      onCompleted: () => {
        message.success('Assignment updated successfully!');
        refetch({
          course_id: courseId,
          id: assignmentId,
        });
        history.push(`/canvas-teacher-dashboard/class/${courseId}/assignment-summary/${assignmentId}`);
      },
      onError: (error) => {
        console.error('Error updating assignment:', error);
      }
    }
  );

  const [uploadFileMutation] = useMutation(gqlSchema.CanvasSchema.mutations.CANVASCLASS.UploadFile);
  // const history = useHistory();

  const handleSaveAssignment = () => {
    let combinedDueDateTime = null;

    if (dueDate && dueTime) {
      combinedDueDateTime = moment.utc(dueDate).set({
        hour: dueTime.hour(),
        minute: dueTime.minute(),
        second: dueTime.second(),
        millisecond: 0 
      }).toISOString();
    }

    const assignmentData = {
      due_at: combinedDueDateTime,
      points_possible: maxPoints ?? null,
      published: true,
      name: assignmentName || null,
      description: assignmentDescription || null,
      position: 102,
      turnitin_enabled: true,
      submission_types: ["online_url","online_text_entry"]
    };

    if (assignmentId) {
      updateCanvasAssignment({
        variables: {
          course_id: courseId,
          id: assignmentId,
          data: {
            assignment: assignmentData
          }
        }
      });
    } else {
      createCanvasAssignment({
        variables: {
          course_id: courseId,
          data: {
            assignment: assignmentData
          }
        }
      });
    }
  };

  useEffect(() => {
    if (dueDate) {
      if (dueTime) {
        setDueTime(dueTime); // Keep existing dueTime if it's already set
      } else {
        setDueTime(dueDate.clone().startOf('day')); // Default to start of the day if dueTime is not set
      }
    }
  }, [dueDate, dueTime]);
   

  const handleEditorChange = (event: any) => {
    const newValue = event?.value || '';
    setAssignmentDescription(newValue);
  };

  useEffect(() => {
  }, [assignmentDescription]);

  const handleMenuClick = (key: React.Key) => {
    if (key === '1') { 
      setIsFormModalVisible(true);
    } else if (key === '2' && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };  


  

  const handleFileUpload = async ({ file }: { file: File }) => {
    setFileUploading(true);
    try {
      const response = await uploadFileMutation({
        variables: {
          file: [file],
          courseId: courseId
        }
      });
  
      const uploadedFileUrl = response.data.uploadFilesForGoogleClassroom; 
      const newFileLinkHtml = `<a href="${uploadedFileUrl}" target="_blank" rel="noopener noreferrer">${uploadedFileUrl}</a>`;
      
      // Update the links state
      setUrlData(prev => prev + newFileLinkHtml + '<br/>');

      setFileList([...fileList, file]);
    } catch (error) {
      console.error('File upload failed:', error);
    } finally {
      setFileUploading(false);
    }
  };

  const handleFormLinkSave = () => {
    if (linkUrl) {
      const newLinkHtml = `<a href="${linkUrl}" target="_blank" rel="noopener noreferrer">${linkUrl}</a>`;
      
      // Update the links state
      setUrlData(prev => prev + newLinkHtml + '<br/>');
      
      setLinkUrl('');
      setIsFormModalVisible(false);
    }
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    console.log('Resize detected:', window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Effect to update the editor with combined content
  useEffect(() => {
    if (editorRef.current) {
      // Combine assignmentDescription and urlData to avoid duplication
      const combinedContent = assignmentDescription + (urlData ? `<br/>${urlData}` : '');
      editorRef.current.setContent(combinedContent);
    }
  }, [assignmentDescription, urlData]);
  

  // Clear urlData after it has been used
  useEffect(() => {
    if (urlData) {
      setAssignmentDescription(prev => prev + urlData);
      setUrlData(''); // Clear urlData to prevent duplication
    }
  }, [urlData]);
  
  

  const handleRemoveFormLink = (index: number) => {
    setFormLinks(formLinks.filter((_, i) => i !== index));
  };

  const menu = (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      <Menu.Item key="1">
        <Button style={{ border: '2px solid #d9d9d9', borderRadius: '4px', width: '100%' }}>
          <LinkOutlined style={{ marginRight: '1px' }} />
          Link
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <UploadInput
          title="Upload File"
          showClear={false}
          onClear={() => {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          }}
          customRequest={(options) => {
            const file = options.file as File; // Cast file to File type
            handleFileUpload({ file });
            return true;
          }}
        />
      </Menu.Item>
    </Menu>
  );  
  const handleShowSider = () => {
    if (setShowSider)
      setShowSider(!showSider);
  }
  const goBack = () => {
    window.history.back();
  };
  return (
    <Layout style={{ minHeight: isMobile ? '85%' : '100vh', marginTop: isMobile ? '80px' : 'unset' }}>
      {isMobile && (<><h1
        style={{ fontSize: '1.2rem', display: isMobile ? 'flex' : 'none', alignItems: 'center' }}>
        <FiArrowLeft style={{ marginRight: '8px', cursor:'pointer' }} onClick={goBack} />
        Assignments
      </h1></>)} 
      {isMobile && (<><Button onClick={handleShowSider} style={{
        marginLeft: '87%', borderRadius: '100%', backgroundColor: '#4367E9', fontSize: '24px', cursor: 'pointer',
        width: '40px', height: '40px', color: 'white', padding: '4px', marginTop: '0%', // You can adjust this as needed
      }} >{<MdFilterList />}
      </Button></>)}
      <Spin spinning={fileUploading} tip="Uploading file..." style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <div style={{ display: isMobile? 'block': 'flex', flexDirection: 'row', height: '100%' }}>
          <div style={{ flex: 1 }}>
          {showSider &&   <Card style={{ height: isMobile?'62vh': '100%',position: isMobile ? 'absolute' : 'unset', zIndex: isMobile ? 200 : 'unset', width: isMobile ? '90%' : 'unset', boxShadow: '7px 0px 34px 10px rgba(0, 0, 0, 0.1)', marginLeft: isMobile ? '10%' : 'unset', borderRadius:isMobile?'10px':'unset' }}>
              <CoreSettingsSider
                assignmentName={assignmentName}
                assignmentDescription={assignmentDescription}
                assignmentType={undefined} 
                maxPoints={maxPoints}
                dueDate={dueDate}
                dueTime={dueTime}           
                onSaveAssignment={handleSaveAssignment}
                setAssignmentType={() => {}}
                setMaxPoints={setMaxPoints}
                setDueDate={setDueDate}
                setDueTime={setDueTime}
                loading={mutationLoading || updateLoading}
                isEditMode={!!assignmentId}
                individualStudents={undefined}
                setIndividualStudents={() => {}}
                setIsEditMode={() => {}}
                courseId={courseId}
                assignmentId={assignmentId}
                newAssignmentId={newAssignmentId || ''}
              />
            </Card>}
          </div>
          <div style={{ flex:isMobile? 'auto': 3, padding: isMobile? '0px': '0 24px' }}>
            <Card style={{ height: isMobile? 'auto' : '100%' }}>
              <Content style={{ padding: isMobile? '16px' :'24px' }}>
                <h2>Assignment Name</h2>
                <Input
                  placeholder="Enter Assignment Name"
                  value={assignmentName}
                  onChange={(e) => setAssignmentName(e.target.value)}
                />
                <br />
                <br />
                <h2>Description</h2>
                <div dangerouslySetInnerHTML={{ __html: assignmentDescription }} />
                <br />
                <RemirrorProvider>
                  <Editor
                    value={assignmentDescription}
                    onChange={handleEditorChange}
                    placeholder="Enter Assignment Description"
                    showAlign={true}
                    editable={true}
                    listenToValue={false}
                    key={urlData}
                  />
                </RemirrorProvider>
                <br />
                <Tooltip title={mutationLoading ? "Saving..." : ""}>
                  <Dropdown overlay={menu} trigger={['click']} disabled={mutationLoading}>
                    <Button>
                      Attach File <DownOutlined />
                    </Button>
                  </Dropdown>
                </Tooltip>
                <br />
                <ul>
                  {formLinks.map((formLink, index) => (
                    <li key={index}>
                      <a href={formLink} target="_blank" rel="noopener noreferrer">{formLink}</a>
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveFormLink(index)}
                      />
                    </li>
                  ))}
                </ul>
              </Content>
            </Card>
          </div>
        </div>
        <Modal
          title="Add Link"
          visible={isFormModalVisible}
          onOk={handleFormLinkSave}
          onCancel={() => setIsFormModalVisible(false)}
        >
          <Input
            placeholder="Link URL"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
          />
        </Modal>
      </Spin>
    </Layout>
  );
};
  
  export default withRouter(CanvasAssignmentCorePage);
  
