import styled from 'styled-components';

export const ScoresDifferenceContainer = styled.div`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 28px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 2px;
  }
`;

export const ScoreDiffText = styled.h2<{ color: string }>`
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: ${(props) => props.color};
  margin: 0;
`;

export const ScoreDiffTextSmall = styled.h2`
  font-style: normal;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: grey;
  margin: 0;
  cursor: default;
`;
