import { Col, Row, Pagination, message, Result } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { escapeRegExp } from 'lodash';

import * as S from './styles';
import AssessmentListHeader from '../AdiAssessmentListPage/AssessmentListHeader';
import { FilterFields, IInvestigationListFilters } from '../AdiAssessmentListPage';
import InvestigationLibraryHeader from './InvestigationLibraryHeader';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_InvestigationCatalog,
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationCatalogEntryPaginated,
  InvestigationCatalogPaginatedParams,
} from '../../types/investigation';
import InvestigationCard from '../../shared/InvestigationCard';
import Spacer from '../../shared/Spacer';
import { useAuth } from '../../hooks/useAuth';
import { useInvestigationFilters } from '../../hooks/useInvestigationFilters';
import { GQL_UserAssignLimitsResponse } from '../../types/user';
import { GQL_SubscriptionResponse } from '../../types/subscription';
import { GQL_InvestigationStandard } from '../../types/investigationStandard';
import {
  teacherInvestigationLibraryPossibleParams,
  teacherInvestigationLibraryPossibleParamsList,
} from '../../utils/constants';
import CustomInvestigationLoading from '../../shared/CustomInvestigationLoading';
import { INVESTIGATION_STANDARDS } from '../../types/standards';

type Props = RouteComponentProps<{
  classId: string;
  type: string;
}>;

const PAGE_SIZE = 9;

type FilterInvestigationFields = 'discipline' | 'grade' | 'coreIdea' | 'mode' | 'trial' | 'state';

export interface IInvestigationLibraryFilter {
  field: FilterInvestigationFields | FilterFields;
  value: string;
}

const InvestigationLibraryPage: React.FC<Props> = (props) => {
  const { classId, type } = props.match.params;
  const history = useHistory();
  const location = useLocation();
  const { user, isAdiAdmin, isAdiSuperAdmin, isScienceTexasEditionEnabledWithGrades, isSubscriptionUser } = useAuth();
  const [searchText, setSearchText] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useInvestigationFilters<FilterInvestigationFields | FilterFields>(history);

  const createPayload = () => {
    const payload = {
      isAssessment: isAssessment,
      organizationId: user?.subscription?.organizationId,
      page: currentPage,
      pageSize: PAGE_SIZE,
      type:
        type === 'science-texas-edition' ? 'SCIENCE_TEXAS_EDITION' : teacherInvestigationLibraryPossibleParams[type],
    } as InvestigationCatalogPaginatedParams | any;

    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index] as any;

      if (filter.field === 'tab') {
        continue;
      }

      const key = filter.field as any;
      payload[key] = filter.value;
    }

    if (searchText) {
      payload['searchText'] = searchText;
    }

    return payload;
  };

  const isAssessment = useMemo(() => history.location.pathname.includes('assign-assessment'), [history]);
  const label = isAssessment ? 'Assessment' : 'Investigation';

  const { data: userAssignLimits } = useQuery<{
    getUserAssigmentsLimits: GQL_UserAssignLimitsResponse;
  }>(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.getUserAssigmentsLimits, {
    onError: (error) => {
      message.error(`There was an error in fetching user assignment limits: ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: stateStandardsData } = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: 'TX',
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: ngssStandardsData} = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: INVESTIGATION_STANDARDS.NGSS,
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const investigationInvalidReason = useCallback(
    (a: GQL_InvestigationCatalog, subscription?: GQL_SubscriptionResponse) => {
      if (!subscription) return '';
      if (subscription.isTrial && !a.availableForTrial) return 'trial';
      if (
        subscription.allowedDisciplines?.length &&
        a.discipline?.id &&
        !subscription.allowedDisciplines.includes(Number(a.discipline.id))
      )
        return 'disciplines';
      if (
        subscription.allowedGradeBands?.length &&
        a.discipline?.gradeBand &&
        !subscription.allowedGradeBands.includes(a.discipline.gradeBand)
      )
        return 'gradeBands';
      if (
        subscription.allowedSubjects?.length &&
        a.discipline?.subject &&
        !subscription.allowedSubjects.includes(a.discipline.subject)
      )
        return 'subjects';

      return '';
    },
    [],
  );

  const backUrl = classId
    ? `/teacher-dashboard/class/${classId}/assign-${isAssessment ? 'assessment' : 'investigation'}`
    : '/teacher-investigation';

  // Ensures that a valid url param was passed
  if (
    !teacherInvestigationLibraryPossibleParamsList.includes(type) ||
    (type === 'science-texas-edition' && !isScienceTexasEditionEnabledWithGrades)
  ) {
    history.push(backUrl);
  }

  const { data, loading, refetch } = useQuery<
    {
      getInvestigationCatalogPaginated: GQL_InvestigationCatalogEntryPaginated;
    },
    InvestigationCatalogPaginatedParams
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalogPaginated, {
    variables: createPayload(),
    onError: (err) => {
      message.error(`There was an error loading the ${label} catalog: ` + err.message || 'Unexpected Error');
    },
    fetchPolicy: 'network-only',
  });

  const investigationFilters = useCallback(
    (investigations: GQL_InvestigationCatalogEntry[]) => {
      filters.forEach((filter) => {
        investigations = investigations.filter((investigation) => {
          switch (filter.field) {
            case 'trial':
              return filter.value === 'available' ? investigation.availableForTrial : !investigation.availableForTrial;
            case 'grade':
              return investigation.discipline?.gradeBand === filter.value;
            case 'discipline':
              return investigation.discipline?.name === filter.value;
            case 'coreIdea':
              return investigation.coreIdeas?.some((ci) => ci.code === filter.value);
            case 'mode':
              return filter.value === 'ALL'
                ? investigation.steps?.every((s) => s.mode === filter.value)
                : investigation.steps?.some((s) => s.mode === filter.value);
            case 'state':
              return investigation.standards?.some((s) => s.id === filter.value);
            default:
              return true;
          }
        });
      });

      return investigations;
    },
    [filters],
  );

  const assessmentFilters = useCallback(
    (assessments: GQL_InvestigationCatalogEntry[]) => {
      filters.forEach((filter) => {
        assessments = assessments.filter((assessment) => {
          switch (filter.field) {
            case 'focus':
              return assessment.focus?.name === filter.value;
            case 'subject':
              return assessment.discipline?.subject === filter.value;
            case 'grade':
              return assessment.discipline?.gradeBand === filter.value;
            case 'discipline':
              return assessment.discipline?.name === filter.value;
            case 'coreIdea':
              return assessment.coreIdeas?.some((ci) => ci.code === filter.value);
            case 'practice':
              return assessment.practices?.some((p) => p.code === filter.value);
            case 'standard':
              return assessment.standards?.some((s) => s.id === filter.value);
            default:
              return true;
          }
        });
      });

      return assessments;
    },
    [filters],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filteredInvestigation = useMemo(() => {
    if (!data) return [];

    let investigationList = data?.getInvestigationCatalogPaginated?.list?.slice()?.sort((a, b) => {
      const isAValid = !investigationInvalidReason(a, user?.subscription);
      const isBValid = !investigationInvalidReason(b, user?.subscription);
      if (isAValid && !isBValid) return -1;
      if (!isAValid && isBValid) return 1;
      return (b.firstPublishedAt ?? 0) - (a.firstPublishedAt ?? 0);
    });

    if (!searchText && !filters) return investigationList;

    const words = searchText?.split(' ') ?? [];

    if (isAssessment) {
      investigationList = assessmentFilters(investigationList);
    } else {
      investigationList = investigationFilters(investigationList);
    }

    words
      .map((w) => {
        // escape text to avoid regex errors
        const word = escapeRegExp(w);
        return new RegExp(word, 'i');
      }) // create a list of regular expressions from the word list
      .forEach((wordRegExp) => {
        investigationList = investigationList.filter((investigation) => {
          return (
            investigation.discipline?.name?.match(wordRegExp) ||
            investigation.title.match(wordRegExp) ||
            investigation.coreIdeas?.some((ci) => ci.text.match(wordRegExp)) ||
            investigation.crosscuttingConcepts?.some((ci) => ci.text.match(wordRegExp)) ||
            investigation.practices?.some((ci) => ci.text.match(wordRegExp)) ||
            investigation.standards?.some((ci) => ci.code.match(wordRegExp) || ci.fullStatement.match(wordRegExp))
          );
        });
      });

    return investigationList;
  }, [
    searchText,
    data,
    filters,
    isAssessment,
    user.subscription,
    investigationInvalidReason,
    assessmentFilters,
    investigationFilters,
  ]);

  const typeName = type === 'engineering' ? 'Design Challenge' : label;

  const getSupportedStateStandard = useCallback(
    (investigation: GQL_InvestigationCatalog) => {
      const hasOrgStandards = !!user?.organizationAllowedStates && user.organizationAllowedStates.length > 0;

      const standards = hasOrgStandards ? user?.organizationAllowedStates : user?.userAllowedStates;

      const normalizedStandards = standards?.map((s) => s.postalCode) as string[];
      const baseStandards = [...(investigation.nextGenerationStandards || []), ...(investigation.standards || [])];

      const standardsInv =
        isAdiAdmin || isAdiSuperAdmin
          ? baseStandards
          : baseStandards?.filter((invStandard) => normalizedStandards?.includes(invStandard.state));

      const normalizedStandardsTooltip = standardsInv?.reduce(
        (acc: { state: string; statements: string[] }[], d: GQL_InvestigationStandard) => {
          const found = acc.find((a) => a.state === d.state);
          if (!found) {
            acc.push({ state: d.state, statements: [`${d.code}: ${d.fullStatement}`] });
          } else {
            found.statements.push(`${d.code}: ${d.fullStatement}`);
          }
          return acc;
        },
        [],
      );

      return normalizedStandardsTooltip;
    },
    [isAdiAdmin, isAdiSuperAdmin, user],
  );

  const notFound = useMemo(
    () => (
      <Col offset={8} lg={8} md={12} xs={24}>
        <Result status="warning" subTitle={`There are no ${label}s listed.`} />
      </Col>
    ),
    [label],
  );

  const isActionDisabled = useCallback(
    (investigationId: string) => {
      if (isSubscriptionUser) {
        if (isAssessment) {
          // If is an assessment check if it's already assigned before (shouldn't count as a new one)
          if (userAssignLimits?.getUserAssigmentsLimits.assessmentsIds.includes(investigationId)) {
            return false;
          }
        } else {
          // If is an investigation check if it's already assigned before (shouldn't count as a new one)
          if (userAssignLimits?.getUserAssigmentsLimits.investigationsIds.includes(investigationId)) {
            return false;
          }
        }
        // Or if user has unlimited Assessment
        if (userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxAssessments === -1) {
          return false;
        }
      }

      if (
        isSubscriptionUser &&
        userAssignLimits?.getUserAssigmentsLimits.totalAssignes.assessmentsAssigned! >=
          userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxAssessments!
      ) {
        return true;
      }

      return false;
    },
    [isAssessment, isSubscriptionUser, userAssignLimits],
  );

  const skeleton = useMemo(() => <CustomInvestigationLoading />, []);

  const assessHeader = () => (
    <>
      <AssessmentListHeader
        refreshMethod={refetch}
        backUrl={backUrl}
        filters={filters as IInvestigationListFilters[]}
        setFilters={setFilters}
        updateSearchText={setSearchText}
        stateStandards={stateStandardsData?.getStateStandards}
        ngssStandards={ngssStandardsData?.getStateStandards}
        type={type}
      />
      <Spacer size={24} />
    </>
  );

  const investHeader = () => (
    <InvestigationLibraryHeader
      refreshMethod={refetch}
      backUrl={backUrl}
      updateSearchText={setSearchText}
      filters={filters}
      setFilters={setFilters}
      stateStandards={stateStandardsData?.getStateStandards}
      ngssStandards={ngssStandardsData?.getStateStandards}
      type={type}
    />
  );

  const header = () => {
    return isAssessment ? assessHeader() : investHeader();
  };

  return (
    <S.PageContainer>
      <Row gutter={[24, 24]}>
        <Col style={{ paddingTop: 0 }} xxl={{ span: 18, offset: 3 }} sm={24}>
          {header()}
          <Row gutter={[20, 20]}>
            {data?.getInvestigationCatalogPaginated?.list?.length === 0 && !loading && notFound}
            {data?.getInvestigationCatalogPaginated?.list?.map((investigation, i) => (
              <Col lg={8} md={12} xs={24} key={investigation.id}>
                {isAssessment ? (
                  <InvestigationCard
                    key={`card-${i}`}
                    supportedStates={getSupportedStateStandard(investigation)}
                    investigation={investigation}
                    action={() => history.push(location.pathname + `/${investigation.id}/assign`)}
                    actionText="Assign"
                    actionDisabled={isActionDisabled(investigation.id)}
                    available={!investigationInvalidReason(investigation, user?.subscription)}
                    isAssessment={true}
                    showPreview={true}
                  />
                ) : (
                  <InvestigationCard
                    key={`card-${i}`}
                    supportedStates={getSupportedStateStandard(investigation)}
                    investigation={investigation}
                    action={() => history.push(location.pathname + `/${investigation.id}`)}
                    actionText={'View ' + typeName}
                    available={!investigationInvalidReason(investigation, user?.subscription)}
                  />
                )}
              </Col>
            ))}
            {loading && skeleton}
          </Row>
          <Spacer />
          <Row justify="center">
            <Pagination
              total={data?.getInvestigationCatalogPaginated?.total}
              pageSize={PAGE_SIZE}
              onChange={(page) => {
                setCurrentPage(page);
                setTimeout(() => {
                  refetch();
                }, 0);
              }}
              current={currentPage}
              showSizeChanger={false}
            />
          </Row>
        </Col>
      </Row>
    </S.PageContainer>
  );
};

export default withRouter(InvestigationLibraryPage);
