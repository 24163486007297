import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'antd';

import Spacer from '../../shared/Spacer';
import PageWithTitle from '../../shared/PageWithTitle';
import Button from '../../shared/Button';
import { themeConfig } from '../../utils/theme';
import * as S from './styles';
import logo from '../../assets/logo.svg';

const AdvancedSearchHome: React.FC = () => {
  const { version } = useParams<{ version: string }>();
  const history = useHistory();

  const redirectToAdvancedSearchGradePage = (grade: number) => {
    history.push(`/advanced-search/${version}/${grade}/grade`);
  };

  return (
    <PageWithTitle>
      <Row gutter={[24, 24]} justify="center">
        <Col xs={24} md={12}>
          <S.Card>
            <S.LogoImage data-cy="components-gradereview-logo" src={logo} alt="ADI Logo" />
            <Spacer />
            <h1>2024 Texas Adoption Review</h1>
            <Spacer />
            <Row gutter={[12, 12]} style={{ width: '100%' }}>
              <Col flex={1}>
                <Button
                  data-cy="components-advancedsearch-select-grade-3"
                  text="Grade 3"
                  theme={themeConfig.primaryOutlined}
                  minHeight={40}
                  style={{ width: '100%' }}
                  onClick={() => redirectToAdvancedSearchGradePage(3)}
                />
              </Col>
              <Col flex={1}>
                <Button
                  data-cy="components-advancedsearch-select-grade-4"
                  text="Grade 4"
                  theme={themeConfig.primaryOutlined}
                  minHeight={40}
                  style={{ width: '100%' }}
                  onClick={() => redirectToAdvancedSearchGradePage(4)}
                />
              </Col>
              <Col flex={1}>
                <Button
                  data-cy="components-advancedsearch-select-grade-5"
                  text="Grade 5"
                  theme={themeConfig.primaryOutlined}
                  minHeight={40}
                  style={{ width: '100%' }}
                  onClick={() => redirectToAdvancedSearchGradePage(5)}
                />
              </Col>
            </Row>
          </S.Card>
        </Col>
      </Row>
    </PageWithTitle>
  );
};

export default AdvancedSearchHome;
