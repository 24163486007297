import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { createRef, useEffect } from 'react';
import { Ellipse, Transformer } from 'react-konva';
import { ArrowShape } from '../ArrowShape';

export interface IZoomInShapeProps {
  x1: number;
  y1: number;
  x2?: number;
  y2?: number;
  height: number;
  width: number;
  stroke: string;
  id: string;
  fillShape?: boolean;
}

interface IZoomIn {
  shapeProps: IZoomInShapeProps;
  isSelected: boolean;
  onSelect: (e: KonvaEventObject<Event>) => void;
  onChange: (newAttributes: IZoomInShapeProps) => void;
  draggable: boolean;
}

export const ZoomInShape = (props: IZoomIn) => {
  const { shapeProps, isSelected, onSelect, onChange, draggable } = props;
  const shapeRef = createRef<Konva.Ellipse>();
  const trRef = createRef<Konva.Transformer>();

  useEffect(() => {
    if (isSelected) {
      if (trRef.current && shapeRef?.current) {
        // we need to attach transformer manually
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer()?.batchDraw();
      }
    }
  }, [isSelected, trRef, shapeRef]);

  const xDistance = (shapeProps.x2 ?? 0) - shapeProps.x1;
  const yDistance = (shapeProps.y2 ?? 0) - shapeProps.y1;
  const sqrtDistance = Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));

  return (
    <>
      <Ellipse
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        x={shapeProps.x1}
        y={shapeProps.y1}
        height={5}
        width={5}
        fill={shapeProps.fillShape ? shapeProps.stroke : undefined}
        strokeWidth={2.5}
        radiusX={5}
        radiusY={5}
        draggable={draggable}
        onDragStart={onSelect}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x1: e.target.x(),
            y1: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          if (node) {
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              ...shapeProps,
              x1: node.x(),
              y1: node.y(),
              // set minimal value
              width: Math.max(10, node.width() * scaleX) / 2,
              height: Math.max(10, node.height() * scaleY) / 2,
            });
          }
        }}
      />
      <Ellipse
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        x={shapeProps.x2}
        y={shapeProps.y2}
        fill={shapeProps.fillShape ? shapeProps.stroke : undefined}
        strokeWidth={2.5}
        radiusX={shapeProps.height}
        radiusY={shapeProps.width}
        draggable={draggable}
        onDragStart={onSelect}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x2: e.target.x(),
            y2: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          if (node) {
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              ...shapeProps,
              x2: node.x(),
              y2: node.y(),
              // set minimal value
              width: Math.max(10, node.width() * scaleX) / 2,
              height: Math.max(10, node.height() * scaleY) / 2,
            });
          }
        }}
      />
      {shapeProps.x2 && shapeProps.y2 && (
        <ArrowShape
          shapeProps={{
            id: shapeProps.id,
            points: [
              shapeProps.x1 + (5 * xDistance) / sqrtDistance,
              shapeProps.y1 + (5 * yDistance) / sqrtDistance,
              shapeProps.x2 - (shapeProps.width * xDistance) / sqrtDistance,
              shapeProps.y2 - (shapeProps.height * yDistance) / sqrtDistance,
            ],
            color: shapeProps.stroke,
            strokeWidth: 2.5,
          }}
        />
      )}
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};
