import React, { useCallback, useMemo } from 'react';
import { BsPlus } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import * as S from './styles';

interface IAddInvestigationButton {
  isAssessment?: boolean;
}

const AddInvestigationButton = (props: IAddInvestigationButton) => {
  const { isAssessment } = props;
  const history = useHistory();
  const location = useLocation();
  const { isFacilitator } = useAuth();

  const navigateToNewInvestigationPage = useCallback(() => {
    const path = isAssessment ? '/assign-assessment' : '/assign-investigation';
    history.push(location.pathname + path);
  }, [history, location.pathname, isAssessment]);

  const label = useMemo(() => {
    return isAssessment ? 'Assessment' : 'Investigation';
  }, [isAssessment]);

  const libraryLabel = useMemo(() => {
    return isAssessment ? 'assessments' : 'investigations';
  }, [isAssessment]);

  return (
    <S.Card onClick={navigateToNewInvestigationPage} data-cy="components-add-investigation-button">
      <S.Icon>
        <BsPlus />
      </S.Icon>
      <S.Title>{`Assign a New ${label}`}</S.Title>
      <S.Info>
        Choose from our library of
        {isFacilitator ? ` workshop ${libraryLabel}` : ` ${libraryLabel} for Science and Math ${libraryLabel}`}
      </S.Info>
    </S.Card>
  );
};

export default AddInvestigationButton;
