import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Row, Dropdown, Table, Menu, message } from 'antd';
import { FilterDropdownProps, TablePaginationConfig, ColumnsType } from 'antd/lib/table/interface';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronDown, FiDownload, FiFilter, FiSearch, FiTrash2 } from 'react-icons/fi';
import { withRouter } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import Button from '../../../shared/Button';
import TableSearchBox from '../../../shared/TableSearchBox';
import { centerAlign, Filter, Breakpoint } from '../../../utils/antd';
import { themeConfig } from '../../../utils/theme';
import { roles } from '../../../utils/roles';
import * as S from './styles';
import { pageSizeBase } from '../../../types/pagination';
import { useAuth } from '../../../hooks/useAuth';
import {
  GQL_QueryInviteFilterInput,
  GQL_QueryInviteResponse,
  GQL_QueryInviteResponsePaginated,
  GQL_SendInviteResponse,
} from '../../../types/invites';
import { GQL_SortingInput, SortOptions } from '../../../types/sorting';
import ModalConfirm from '../../../shared/ModalConfirm';
import { formatDistance } from 'date-fns';
import { formatDateTime } from '../../../utils/date';
const TableBreakPoint: Breakpoint[] = ['lg'];

const PendingInvites = () => {
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const [nameToFilter, setNameToFilter] = useState('');
  const [emailToFilter, setEmailToFilter] = useState('');
  const [rolesToFilter, setRolesToFilter] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.ReactText[]>([]);
  const [deleteInvitesVisible, setDeleteInvitesVisible] = useState(false);
  const user = useAuth();
  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const [sorting, setSorting] = useState<GQL_SortingInput[]>([]);
  const [rolesVisible, setRolesVisible] = useState(false);
  const [rolesFilters, setRolesFilters] = useState(roles);
  const refName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (user.isOrganizationAdiAdmin) {
      setRolesFilters(roles.filter((a) => a.value !== 'adi_super_admin'));
    }
  }, [user]);

  const [fetchInvites, { data, loading }] = useLazyQuery<
    { getInvites: GQL_QueryInviteResponsePaginated },
    { data: GQL_QueryInviteFilterInput }
  >(gqlSchema.InvitesSchema.queries.INVITES.getInvites, {
    onCompleted: (data) => {
      setPagination({
        current: data.getInvites.pagination.page,
        pageSize: data.getInvites.pagination.size,
        total: data.getInvites.pagination.totalCount,
      });
    },
    onError: (err) => {
      message.error('There was an error loading invites: ' + err.message || 'Unexpected Error');
    },
  });

  const [exportInvitesByFilter, { loading: loadingInvitesFile }] = useLazyQuery<
    { exportInvites: string },
    { data: GQL_QueryInviteFilterInput }
  >(gqlSchema.InvitesSchema.queries.INVITES.exportInvites, {
    onCompleted: (data) => {
      if (data.exportInvites) window.open(data.exportInvites, '_blank');
    },
    onError: (err) => {
      message.error('There was an error loading invites: ' + err.message || 'Unexpected Error');
    },
    fetchPolicy: 'network-only',
  });

  const [deleteInvites, { loading: loadingDeleteInvites }] = useMutation(
    gqlSchema.UserSchema.mutations.EDIT.deleteUsersInvites,
    {
      refetchQueries: ['GetInvites'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        message.success('Invites deleted successfully');
        setDeleteInvitesVisible(false);
        setSelectedRowKeys([]);
      },
      onError: (err) => {
        message.error('Error deleting invites: ' + err.message);
      },
    },
  );

  const handleDeleteUsers = () => {
    deleteInvites({
      variables: {
        inviteIds: selectedRowKeys,
      },
    });
  };

  useEffect(() => {
    fetchInvites({
      variables: {
        data: {
          pagination: {
            page: 1,
            size: pageSizeBase,
          },
        },
      },
    });
  }, [fetchInvites]);

  const getFilterParams = useMemo(() => {
    const data: GQL_QueryInviteFilterInput = {
      pagination: {
        page: pagination?.current || 1,
        size: pagination?.pageSize || pageSizeBase,
      },
      partOfName: nameToFilter,
      partOfEmail: emailToFilter,
    };

    if (rolesToFilter.length > 0) {
      data.roles = rolesToFilter;
    }

    if (user.isOrganizationAdiAdmin) {
      data.organizationId = user.user.subscription?.organizationId;
      data.orgPage = true;
    }
    if (sorting) data.sorting = sorting;

    return data;
  }, [nameToFilter, emailToFilter, rolesToFilter, pagination, user, sorting]);

  useEffect(() => {
    fetchInvites({
      variables: {
        data: getFilterParams,
      },
    });
  }, [getFilterParams, fetchInvites]);

  const manageSortings = useCallback(
    (sort: GQL_SortingInput) => {
      const sorts = sorting.filter((s) => s.field !== sort.field) || [];
      sorts.push(sort);
      setSorting(sorts);
    },
    [sorting],
  );

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      const order = sorter.order ? (sorter.order === 'ascend' ? SortOptions.ASC : SortOptions.DESC) : undefined;
      manageSortings({ field: sorter.columnKey, order });
      setPagination(pagination);
      setRolesToFilter(filters.roles || []);
    },
    [manageSortings],
  );

  const [sendInvites] = useMutation<{ sendInvites: GQL_SendInviteResponse[] }>(
    gqlSchema.InvitesSchema.mutations.SEND.sendInvites,
    {
      onCompleted: (data) => {
        message.success('Invite sent successfully');
      },
      onError: (err) => {
        message.error('There was an error trying to send the invite, please try again later');
      },
    },
  );

  const onResendInvite = useCallback(
    (userRow: GQL_QueryInviteResponse) => {
      sendInvites({
        variables: {
          data: {
            tokens: [userRow.id],
          },
        },
      });
    },
    [sendInvites],
  );

  const columns: ColumnsType<GQL_QueryInviteResponse> = useMemo(
    () => [
      {
        title: 'User Email',
        className: 'components-userlist-pendinginvites-useremail-column',
        align: centerAlign,
        dataIndex: 'email',
        key: 'inv.email',
        width: '20%',
        responsive: TableBreakPoint,
        sorter: true,
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox onClearFilters={() => setEmailToFilter('')} ref={refEmail} {...filterProps} />
        ),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean) => {
          if (!value) setEmailToFilter('');
          else setEmailToFilter(value.toString());
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Name',
        className: 'components-userlist-pendinginvites-username-column',
        sorter: true,
        align: centerAlign,
        dataIndex: 'name',
        key: 'inv.firstName',
        width: '20%',
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox ref={refName} onClearFilters={() => setNameToFilter('')} {...filterProps} />
        ),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        render: (text: string, record: GQL_QueryInviteResponse) => {
          return !record.firstName || !record.lastName ? '-NOT SPECIFIED-' : `${record.firstName} ${record.lastName}`;
        },
        onFilter: (value: string | number | boolean) => {
          if (!value) setNameToFilter('');
          else setNameToFilter(value.toString());
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Role',
        dataIndex: 'roles',
        align: centerAlign,
        width: '20%',
        responsive: TableBreakPoint,
        onFilter: (value: string | number | boolean, record: GQL_QueryInviteResponse) => {
          return true;
        },
        filters: rolesFilters,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={rolesVisible}>
            <FiFilter size={18} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setRolesVisible(visible);
        },
        render: (text: string, record: GQL_QueryInviteResponse) => {
          const roleItem = roles.find((r: Filter) => r.value === record.role);
          return (
            <S.TagButton
              text={roleItem?.text || ''}
              display="inline"
              background={roleItem?.color}
              shape="round"
              key={record.role}
              minHeight={24}
            />
          );
        },
      },
      {
        title: 'Invite Sent Date',
        className: 'components-userlist-pendinginvites-invitesentdate-column',
        align: centerAlign,
        width: '20%',
        responsive: TableBreakPoint,
        sorter: true,
        key: 'inv.createdAt',
        dataIndex: 'createdAt',
        render: (text: string, record: GQL_QueryInviteResponse) => {
          if (!record?.createdAt) return <p style={{ marginBottom: 0 }}>No Invitation was sent</p>;
          const originalDate = formatDateTime(record?.createdAt, 'MM.dd.yyyy');
          const distanceDate = formatDistance(record?.createdAt, new Date(), { addSuffix: true });
          return (
            <>
              <p style={{ marginBottom: 0 }}>{originalDate}</p>
              <p style={{ marginBottom: 0 }}>{distanceDate}</p>
            </>
          );
        },
      },
      {
        title: 'Actions',
        align: centerAlign,
        fixed: 'right',
        render: (text: string, record: GQL_QueryInviteResponse) => {
          return (
            <Button
              data-cy={`components-userlist-pendinginvites-resend-invite-button-${record.email.split('@')?.[0]}`}
              text="Resend Invite"
              onClick={() => onResendInvite(record)}
              block
            />
          );
        },
        width: '20%',
      },
    ],
    [onResendInvite, rolesVisible, searchEmailVisible, searchNameVisible, rolesFilters],
  );

  const exportUsers = useCallback(() => {
    exportInvitesByFilter({
      variables: {
        data: { ...getFilterParams, pagination: undefined },
      },
    });
  }, [getFilterParams, exportInvitesByFilter]);

  const exportMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={exportUsers}>
          .csv
        </Menu.Item>
      </Menu>
    ),
    [exportUsers],
  );

  const canDeleteInvites = user.isAdiSuperAdmin || user.isOrganizationAdiAdmin;

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={3} xl={4} lg={5} md={24} sm={24}>
          <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
            <Button
              data-cy="components-userlist-pendinginvites-exportas-button"
              padding="4px 5px"
              loading={loadingInvitesFile}
              text={
                <>
                  Export as
                  <FiChevronDown style={{ marginLeft: 3 }} />
                </>
              }
              theme={themeConfig.noColor}
              icon={<FiDownload />}
              block
            />
          </Dropdown>
        </Col>
        <Col xxl={3} xl={4} lg={5} md={24} sm={24}>
          {canDeleteInvites && !!selectedRowKeys?.length && (
            <Button
              text={'Delete Invites'}
              onClick={() => setDeleteInvitesVisible(true)}
              theme={themeConfig.primaryOutlined}
              block
              icon={<FiTrash2 />}
            />
          )}
        </Col>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_QueryInviteResponse) => record.id}
              rowSelection={
                canDeleteInvites
                  ? {
                      selectedRowKeys,
                      onChange: (k) => setSelectedRowKeys(k),
                    }
                  : undefined
              }
              pagination={{ ...pagination, hideOnSinglePage: true }}
              bordered
              dataSource={data?.getInvites.invites}
              onChange={handleTableChange}
              scroll={{ x: 'max-content' }}
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <ModalConfirm
        visible={deleteInvitesVisible}
        title={`Delete selected invites`}
        lineInfo1={`Are you sure you want to delete ${selectedRowKeys.length} invites? All users data`}
        lineInfo2={`will be permanently deleted. This action is irreversible`}
        deleteButtonTitle="Delete invites"
        nameToCheck={`delete ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? 'invite' : 'invites'}`}
        errorMessage="Provided text is wrong"
        setVisible={setDeleteInvitesVisible}
        loading={loadingDeleteInvites}
        deleteFn={handleDeleteUsers}
      ></ModalConfirm>
    </>
  );
};

export default withRouter(PendingInvites);
