import React, { useCallback } from 'react';
import { Form as AntdForm, message } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import * as S from './styles';
import { useQuery, useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import Form from '../../shared/Form';
import Input from '../../shared/Input';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { GQL_PasswordResetTokenCheckResponse } from '../../types/login';
import adiLearningHubSrc from '../../assets/adi-learning-hub.svg';

type Props = RouteComponentProps<{ token: string }>;

const ChangePassword: React.FC<Props> = (props) => {
  const { token } = props.match.params;
  const history = useHistory();

  useQuery(gqlSchema.AccountsSchema.query.ACCOUNT.AUTH.passwordResetTokenCheck, {
    variables: { data: { token } },
    onCompleted: ({ passwordResetTokenCheck }: { passwordResetTokenCheck: GQL_PasswordResetTokenCheckResponse }) => {
      if (!passwordResetTokenCheck.valid) handleTokenError('Unexpected Error');
    },
    onError: (err) => {
      handleTokenError(err.message);
    },
  });

  const [submitPassword, { loading }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.changePasswordFromToken,
    {
      onCompleted: (response: { setNewPasswordFromToken: boolean }) => {
        const success = response && response.setNewPasswordFromToken;
        if (!success) {
          handleTokenError('Unexpected Error');
        } else {
          message.success('Password changed successfully');
          history.push('/login');
        }
      },
      onError: (e) => {
        handleTokenError(e.message);
      },
    },
  );

  const handleTokenError = useCallback(
    (errorMessage: string) => {
      if (errorMessage.includes('Expir')) {
        message.error('Expired Token, please generate a new one and try again');
        history.push('/login');
      } else {
        message.error(errorMessage);
      }
    },
    [history],
  );

  const handleChangePassword = useCallback(
    (values: { password: string; confirmPassword: string }) => {
      submitPassword({
        variables: {
          token,
          newPassword: values.password,
        },
      });
    },
    [submitPassword, token],
  );

  return (
    <S.Container>
      <S.RowSP align="middle" justify="center">
        <S.ColSP xl={10} lg={16} md={18} sm={20} xs={24}>
          <S.LogoImage src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Reset Password</S.Title>
          <Form onFinish={handleChangePassword}>
            <S.Info>
              You're almost done! Now you just need to
              <br />
              provide your new password
            </S.Info>
            <AntdForm.Item
              style={{ marginTop: 15 }}
              extra="Your password should contain at least 1 uppercase letter, 1 lowercase letter and 1 special character (@, #, $, !)"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  min: 12,
                  message: '- The minimum password length is 12.',
                },
                {
                  pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[$@!#%.:,()])\\S{12,50}$'),
                  message:
                    '- Please include at least one uppercase letter, one lowercase letter, and one special character ($@!#%.:,)',
                },
              ]}
            >
              <Input icon={<KeyOutlined />} placeholder="Enter your new password" password />
            </AntdForm.Item>
            <AntdForm.Item
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input icon={<KeyOutlined />} placeholder="Confirm your new password" password />
            </AntdForm.Item>
            <AntdForm.Item>
              <S.ChangePasswordButton text="Change Password" htmlType="submit" loading={loading} />
            </AntdForm.Item>
          </Form>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );
};

export default withRouter(ChangePassword);
