import styled from 'styled-components';

export const Container = styled.div<{
  $length: number;
}>`
  background: white;
  padding: 24px;
  border-radius: 6px;
  min-height: 20vh;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.$length > 0 ? 'block' : 'flex')};
`;

export const Message = styled.h2`
  text-align: center;
`;
