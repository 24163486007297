import React, { useState } from 'react';
import { Typography, Form, Checkbox, message, Spin } from 'antd';
import * as S from './styles';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../../gql/schema';
import Button from '../../../../../shared/Button';

interface BookDetailsProps {
  bookCode: string;
  onClose?: (refetch?: Boolean) => void;
}
const BookDetails: React.FC<BookDetailsProps> = ({ bookCode, onClose }) => {
  const { loading, error, data } = useQuery(gqlSchema.BookSchema.queries.FetchBookByBookCode, {
    variables: {
      id: bookCode,
    },
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });
  const [isAgree, setIsAgree] = useState(false);

  const [addBookUser] = useMutation(gqlSchema.BookSchema.mutations.AddBookUser, {
    onCompleted: () => {
      message.success('Book has been added successfully!');
      if (onClose) {
        onClose(true);
      }
    },
    onError: (error) => {
      console.error(error);
      message.error(<Typography.Text data-cy="components-booksignup-error-text">{error.message}</Typography.Text>);
    },
  });
  const onProceed = () => {
    if (!data.fetchBookByBookCode.id) return;
    addBookUser({
      variables: {
        bookCode: bookCode,
      },
    });
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <div>Error loading book details.</div>;
  }

  const book = data.fetchBookByBookCode;

  return (
    <S.Container>
      <S.ModalWrapper>
        <S.ContentRow>
          <S.LeftSection>
            <img src={book.imageUrl[0]} alt="Book" />
          </S.LeftSection>
          <S.RightSection>
            <S.BookDteailsWrapper>
              <S.DetailsTitle>Book Name</S.DetailsTitle>
              <S.DetailsText>{book.title}</S.DetailsText>
            </S.BookDteailsWrapper>
            <S.BookDteailsWrapper>
              <S.DetailsTitle>Grade</S.DetailsTitle>
              <S.DetailsText>{book.standard}</S.DetailsText>
            </S.BookDteailsWrapper>
            <S.BookDteailsWrapper>
              <S.DetailsTitle>Investigation</S.DetailsTitle>
              <S.DetailsText>{book.investigation}</S.DetailsText>
            </S.BookDteailsWrapper>
            <S.BookDteailsWrapper>
              <S.DetailsTitle>Publisher</S.DetailsTitle>
              <S.DetailsText>
                {book.publisher} | Published: {book.publishedDate}
              </S.DetailsText>
            </S.BookDteailsWrapper>
            <S.BookDteailsWrapper>
              <S.DetailsTitle>Subject</S.DetailsTitle>
              <S.DetailsText>{book.subject}</S.DetailsText>
            </S.BookDteailsWrapper>
          </S.RightSection>
        </S.ContentRow>

        <Form>
          <Form.Item>
            <Checkbox checked={isAgree} onChange={(e) => setIsAgree(e.target.checked)}>
              Once the code is entered, the order cannot be canceled.
            </Checkbox>
          </Form.Item>
          <S.Footer>
            <Button text="Proceed" onClick={onProceed} disabled={!isAgree} block />
          </S.Footer>
        </Form>
      </S.ModalWrapper>
    </S.Container>
  );
};

export default BookDetails;
