import { useMutation } from '@apollo/client';
import { message, Typography } from 'antd';
import React, { useCallback } from 'react';
import { FiDownloadCloud } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../../gql/schema';
import { useAuth } from '../../../../hooks/useAuth';
import * as S from './styles';
const { Text, Link } = Typography;

const SyncClassButton = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [submitSyncClasslinkClassesV2] = useMutation<{ syncClasslinkClassesV2: boolean }>(
    gqlSchema.ClassLinkSchema.mutations.ROSTER.syncClasslinkClassesV2,
    {
      onCompleted: (data) => {
        message.success(
          <Text>
            Synchronization started,{' '}
            <Link onClick={() => history.push(`/sync/${data.syncClasslinkClassesV2}`)}>see details here</Link>, you'll
            be notified once the process finishes
          </Text>,
          5,
        );
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitSyncCleverClasses] = useMutation<{ syncCleverClasses: boolean }>(
    gqlSchema.CleverSchema.mutations.ROSTER.syncCleverClasses,
    {
      onCompleted: (data) => {
        message.success(
          <Text>
            Synchronization started,{' '}
            <Link onClick={() => history.push(`/sync/${data.syncCleverClasses}`)}>see details here</Link>, you'll be
            notified once the process finishes
          </Text>,
          5,
        );
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const syncClassesV2 = useCallback(() => {
    switch (user.source) {
      case 'Classlink':
        submitSyncClasslinkClassesV2();
        break;
      case 'Clever':
        submitSyncCleverClasses();
        break;
    }
  }, [submitSyncClasslinkClassesV2, submitSyncCleverClasses, user.source]);

  return (
    <>
      <S.Card onClick={syncClassesV2}>
        <S.Icon>
          <FiDownloadCloud size={40} />
        </S.Icon>
        <S.Title>Import and Sync Classes</S.Title>
        <S.Info>Synchronize Classes from {user.source}</S.Info>
      </S.Card>
    </>
  );
};

export default SyncClassButton;
