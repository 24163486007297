import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import Button from '../../../shared/Button';
import { IButtonContentValue } from '../../../types/investigation';
import { themeConfig } from '../../../utils/theme';

interface IInvestigationButton {
  value: IButtonContentValue;
}

const InvestigationButton = (props: IInvestigationButton) => {
  const { value } = props;

  return (
    <Button
      text={value?.text}
      onClick={() => {
        window.open(value?.url, '_blank', 'noopener noreferrer');
      }}
      theme={value?.color === 'secondary' ? themeConfig.secondaryColor : themeConfig.primaryColor}
      suffix={<FiExternalLink style={{ minHeight: 16, minWidth: 16, color: 'white', margin: '0 0 3 10' }} />}
    />
  );
};

export default InvestigationButton;
