import { History } from 'history';

export const upsertFieldToQueryString = ({
  field,
  value,
  history,
}: {
  field: string;
  value: string;
  history: History;
}) => {
  const existingParams = new URLSearchParams(history.location.search);
  existingParams.has(field) ? existingParams.set(field, value) : existingParams.append(field, value);

  history.push({ search: existingParams.toString() });
};
