import { useEffect, useState } from 'react';

interface IUseStuckStudentButton {
  userId: string;
  classId: string;
  investigationId: string;
  stepId: string;
  activityId: string;
}

function getSessionStorageOrDefault(key: string, defaultValue: boolean) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function useStuckStudentButton({
  userId,
  classId,
  investigationId,
  stepId,
  activityId,
}: IUseStuckStudentButton) {
  const SESSION_KEY = `@ADI:confirmed-button:${userId}:${classId}:${investigationId}:${stepId}:${activityId}`;
  const [isStuck, setIsStuck] = useState(getSessionStorageOrDefault(SESSION_KEY, false));

  useEffect(() => {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(isStuck));
  }, [SESSION_KEY, isStuck]);

  return [isStuck, setIsStuck];
}
