import { gql } from '@apollo/client';
import ACCOUNT_FRAGMENTS from '../../Accounts/fragment/Accounts';
import TAGS_FRAGMENTS from '../../Tags/fragments/Tags';

const TEACHER = {
  STUDENTS: {
    getStudents: gql`
      query GetStudents {
        getStudents {
          userId
          inviteId
          avatar
          firstName
          lastName
          email
          emailSent
          classes {
            classId
            className
            classColorHex
            investigations {
              id
              title
              isAssessment
            }
          }
          tags {
            ...TagResponseFragment
          }
        }
      }
      ${TAGS_FRAGMENTS.tagResponse}
    `,
  },
  CORE: {
    getTeachers: gql`
      query GetTeachers($partOfName: String!) {
        getTeachers(partOfName: $partOfName) {
          id
          name
        }
      }
    `,
  },
  ASSISTANT: {
    getMyAssistants: gql`
      query GetMyAssistants {
        getMyAssistants {
          id
          firstName
          lastName
          name
          email
          lastLogin
          isAllowedGrading
          isAllowedChat
        }
      }
    `,
    getSubscriptionFromTeacherByAssistant: gql`
      query GetSubscriptionFromTeacherByAssistant($investigationId: String!) {
        getSubscriptionFromTeacherByAssistant(investigationId: $investigationId) {
          ...Subscription
        }
      }
      ${ACCOUNT_FRAGMENTS.subscription}
    `,
  },
};

export default TEACHER;
