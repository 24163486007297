import React, { useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { message, Row, Col, Divider } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import CanvasClassInfos from './CanvasClassInfos';
// import CanvasClassAssignmentsRow from './CanvasClassAssignmentsRow';
import CanvasStudentsTable from './CanvasStudentsTable';
import CActiveAssignmentsRow from './CActiveAssignmentsRow';
import CFinishedAssignmentsRow from './CFinishedAssignmentsRow';

type Props = RouteComponentProps<{ courseId: string }, any, { className: string }> & {
  onUpdateNumberOfStudents?: (count: number) => void;
};

const CanvasTeachersClassDashboard: React.FC<Props> = (props) => {
  const { courseId } = props.match.params;
  const { isFacilitator } = useAuth();
  const [activeAssignments, setActiveAssignments] = useState<any[]>([]);
  const [finishedAssignments, setFinishedAssignments] = useState<any[]>([]);
  const selectedCourseIds = useMemo(() => [courseId], [courseId]);

  // Fetch class details
  const { data: classData, loading: classLoading } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasCoursesById,
    {
      variables: { course_id: courseId },
      onError: (error) => {
        message.error(
          error.message ||
          'There was an error loading the class details, please try again later.'
        );
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  // Fetch assignments list
  // const { data: assignmentData } = useQuery<any>(
  //   gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentList,
  //   {
  //     variables: { course_id: courseId },
  //     onError: (error) => {
  //       message.error(
  //         error.message ||
  //         'There was an error loading the assignments, please try again later.'
  //       );
  //     },
  //     fetchPolicy: 'cache-and-network',
  //   }
  // );

  // Fetch students list
  const { data: studentData, loading: studentLoading } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasStudentList,
    {
      variables: { course_id: courseId, enrollment_type: 'student' },
      onError: (error) => {
        message.error(
          error.message ||
          'There was an error loading the students, please try again later.'
        );
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  // Fetch teachers list
  const { data: teacherData, loading: teacherLoading } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasTeacherList,
    {
      variables: { course_id: courseId, enrollment_type: 'teacher' },
      onError: (error) => {
        message.error(
          error.message ||
          'There was an error loading the teachers, please try again later.'
        );
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  // Query to fetch assignments using selectedCourseIds
  const { loading: assignmentsLoading } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentListByDueDate,
    {
      variables: { course_id: selectedCourseIds },
      onError: (error) => {
        message.error(
          error.message ||
          'There was an error loading your assignments, check your connection and try again later.'
        );
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const activeAssignmentsData = data?.getCanvasAssignmentListByDueDate?.active || [];
        const expiredAssignmentsData = data?.getCanvasAssignmentListByDueDate?.expired || [];
        setActiveAssignments(activeAssignmentsData);
        setFinishedAssignments(expiredAssignmentsData);
      },
    }
  );

  const classInfo = classData?.getCanvasCoursesById;
  console.log('classInfo: ',classInfo);
  // const assignments = assignmentData?.getCanvasAssignmentList || [];
  const students = studentData?.getCanvasStudentList?.students || [];
  const studentCount = studentData?.getCanvasStudentList?.count || 0; // Get student count
  const teachers = teacherData?.getCanvasTeacherList?.teachers || [];

  const onUpdateNumberOfStudents = (count: number) => {
    // Handle update number of students
  };

  return (
    <PageWithTitle title={`${isFacilitator ? 'Course or Event' : 'Class'} Details`} backPageUrl="/canvas-teacher-dashboard">
      <CanvasClassInfos
        classInfo={classInfo}
        loading={classLoading}
        studentCount={studentCount} // Pass student count
      />
      {/* <CanvasClassAssignmentsRow
        classId={courseId}
        className={classInfo?.name || ''}  // Pass className here
        shouldLoad={false}
        loading={assignmentLoading}
        assignments={assignments}
      /> */}
       <Divider style={{ margin: '24px 0' }} />

      {/* Active Assignments Row */}
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <CActiveAssignmentsRow
            loading={assignmentsLoading}
            assignmentData={activeAssignments}
            courses={[classInfo]} // Pass course data
          />
        </Col>
      </Row>
      
      {/* Divider and space between active and finished assignments */}
      <Divider style={{ margin: '24px 0' }} />

      {/* Finished Assignments Row */}
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <CFinishedAssignmentsRow
            loading={assignmentsLoading}
            finishedAssignments={finishedAssignments}
            courses={[classInfo]} // Pass course data
          />
        </Col>
      </Row>

      <CanvasStudentsTable
        classId={courseId}
        loading={studentLoading || teacherLoading}
        students={students}
        teachers={teachers}
        onUpdateNumberOfStudents={onUpdateNumberOfStudents}
      />
    </PageWithTitle>
  );
};

export default withRouter(CanvasTeachersClassDashboard);
