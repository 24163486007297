import { gql } from '@apollo/client';
import TAGS_FRAGMENTS from '../../Tags/fragments/Tags';

const CHART_QUERIES = {
  CHARTS: {
    getMostUsedInvestigations: gql`
      query getMostUsedInvestigations($data: FetchMostUsedInvestigationsInput) {
        getMostUsedInvestigations(data: $data) {
          count
          title
        }
      }
    `,
    getUsedInvestigations: gql`
      query getUsedInvestigations($data: FetchMostUsedInvestigationsInput) {
        getUsedInvestigations(data: $data) {
          count
          title
          type
        }
      }
    `,
    getAverageTimeToCompleteInvestigations: gql`
      query getAverageTimeToCompleteInvestigations($data: AvgTCIInput) {
        getAverageTimeToCompleteInvestigations(data: $data) {
          id
          name
          average
        }
      }
    `,
    getInvestigationsCompletionTimeChart: gql`
      query getInvestigationsCompletionTimeChart($data: AvgTCIInput) {
        getInvestigationsCompletionTimeChart(data: $data) {
          investigations {
            investigationId
            investigationTitle
            averageCompletionTime
            sampleSize
          }
          totalAverage
        }
      }
    `,
    getActiveUserChart: gql`
      query getActiveUserChart($option: String!, $from: String, $orgId: String) {
        getActiveUserChart(option: $option, from: $from, orgId: $orgId) {
          activeUsers
          totalUsers
          betaUsers
        }
      }
    `,
    getSubscriptionInsightsChart: gql`
      query getSubscriptionInsightsChart($option: SubscriptionInsightsOptions!, $from: String) {
        getSubscriptionInsightsChart(option: $option, from: $from) {
          totalSubscriptions
          totalSubscriptionsFromDate
        }
      }
    `,
    getInvestigationAssignmentRateChart: gql`
      query getInvestigationAssignmentRateChart($data: InvAssignmentRateInput) {
        getInvestigationAssignmentRateChart(data: $data) {
          rates {
            teacherId
            teacherFirstName
            teacherLastName
            teacherEmail
            rate
            organizationName
          }
          pagination {
            page
            size
            totalCount
          }
        }
      }
    `,
    getInvestigationAssignmentPerTeacherChart: gql`
      query getInvestigationAssignmentPerTeacherChart($data: InvAssignmentRateForTeacherInput!) {
        getInvestigationAssignmentPerTeacherChart(data: $data) {
          teacherId
          teacherName
          organizationName
          investigationAssignmentRatePerClass {
            className
            rate
            investigations {
              investigationId
              investigationTitle
              startDate
              endDate
            }
          }
        }
      }
    `,
    getFacilitatorsChart: gql`
      query getFacilitatorsChart($data: FacilitatorsChartInput!) {
        getFacilitatorsChart(data: $data) {
          facilitatorId
          facilitatorFirstName
          facilitatorLastName
          facilitatorEmail
          coursesCount
          workshopsCount
        }
      }
    `,
    getFacilitatorDetailsChart: gql`
      query getFacilitatorDetailsChart($data: FacilitatorDetailsChartInput!) {
        getFacilitatorDetailsChart(data: $data) {
          facilitatorId
          facilitatorName
          classes {
            id
            name
            studentsCount
            workshops {
              investigationId
              investigationTitle
              startDate
              endDate
            }
          }
        }
      }
    `,
    getInvestigationAssignmentTrendTable: gql`
      query getInvestigationAssignmentTrendTable($data: InvAssignmentTrendInput!) {
        getInvestigationAssignmentTrendTable(data: $data) {
          trends {
            teacherId
            teacherFirstName
            teacherLastName
            teacherEmail
            rateOnFirstPeriod
            rateOnSecondPeriod
            rateDiff
          }
          pagination {
            page
            size
            totalCount
          }
        }
      }
    `,
    getInvestigationAssignmentTrendChart: gql`
      query getInvestigationAssignmentTrendChart($data: InvAssignmentTrendChartInput!) {
        getInvestigationAssignmentTrendChart(data: $data) {
          stoppedUsing
          startedUsing
          increaseInUsage
          decreaseInUsage
          didNotUse
          smallToNoUsageChange
        }
      }
    `,
    getExpiringSubscriptionsChart: gql`
      query getExpiringSubscriptionsChart {
        getExpiringSubscriptionsChart {
          trialSubscriptions
          freelanceTeachersSubscriptions
          organizationSubscriptions
          facilitatorSubscriptions
        }
      }
    `,
    getExpiringSubscriptionsDetailsTable: gql`
      query getExpiringSubscriptionsDetailsTable($data: ExpiringSubscriptionsDetailsTableInput) {
        getExpiringSubscriptionsDetailsTable(data: $data) {
          id
          name
          email
          expiresAt
        }
      }
    `,
    getAverageTeacherScoresChart: gql`
      query getAverageTeacherScoresChart($data: FetchAverageTeacherScoresInput) {
        getAverageTeacherScoresChart(data: $data) {
          averageScores {
            reportScore
            engagementScore
          }
          scoresByTeacher {
            data {
              teacherId
              teacherFirstName
              teacherLastName
              teacherEmail
              maxReportScore
              averageReportScores
              averageReportScoresPercentage
              averageEngagementScores
            }
            pagination {
              page
              size
              totalCount
            }
          }
        }
      }
    `,
    getAverageTeacherScoresChartByTeacher: gql`
      query getAverageTeacherScoresChart($data: FetchAverageTeacherScoresInput) {
        getAverageTeacherScoresChart(data: $data) {
          scoresByTeacher {
            data {
              teacherId
              teacherFirstName
              teacherLastName
              teacherEmail
              maxReportScore
              averageReportScores
              averageReportScoresPercentage
              averageEngagementScores
            }
            pagination {
              page
              size
              totalCount
            }
          }
        }
      }
    `,
    getAverageReportGainScoreChart: gql`
      query getAverageReportGainScoreChart($data: AverageReportGainScoreInput) {
        getAverageReportGainScoreChart(data: $data) {
          teacherId
          teacherFirstName
          teacherLastName
          teacherEmail
          gain
        }
      }
    `,
    getInvestigationCompletionChart: gql`
      query getInvestigationCompletionChart($data: InvestigationCompletionChartInput) {
        getInvestigationCompletionChart(data: $data) {
          perTeacher {
            teacherId
            teacherFirstName
            teacherLastName
            teacherEmail
            tags {
              ...TagResponseFragment
            }
            completedInvestigations
          }
          averagePerTag {
            tagId
            tagName
            completedInvestigations
            totalTeachers
          }
        }
      }
      ${TAGS_FRAGMENTS.tagResponse}
    `,
    getInvestigationStageCompletionChart: gql`
      query getInvestigationStageCompletionChart($data: InvestigationStagesCompletionChartInput) {
        getInvestigationStageCompletionChart(data: $data) {
          perTeacher {
            teacherId
            teacherFirstName
            teacherLastName
            teacherEmail
            tags {
              ...TagResponseFragment
            }
            stages {
              name
              order
              totalUsers
              completedUsers
            }
          }
          perStage {
            name
            order
            totalUsers
            completedUsers
          }
        }
      }
      ${TAGS_FRAGMENTS.tagResponse}
    `,
    getFinishedInvestigationByTeacher: gql`
      query {
        getFinishedInvestigationByTeacher {
          teacherId
          teacherFirstName
          teacherLastName
          teacherEmail
          className
          subject
          discipline
          tags {
            ...TagResponseFragment
          }
          investigationTitle
          endDate
          startDate
        }
      }
      ${TAGS_FRAGMENTS.tagResponse}
    `,
    getOpenInvestigationsChart: gql`
      query getOpenInvestigationsChart($data: OpenInvestigationsChartInput) {
        getOpenInvestigationsChart(data: $data) {
          teacherId
          teacherFirstName
          teacherLastName
          teacherEmail
          tags {
            ...TagResponseFragment
          }
          openInvestigations {
            id
            name
            subject
            discipline
            startDate
            endDate
            className
          }
        }
      }
      ${TAGS_FRAGMENTS.tagResponse}
    `,
  },
  EXPORT: {
    exportFacilitatorStudents: gql`
      query exportFacilitatorStudents($classIds: [String]!) {
        exportFacilitatorStudents(classIds: $classIds)
      }
    `,
  },
  USER_REPORTS: {
    getClasslinkExpiredUser: gql`
      query getClasslinkExpiredUser($to: String, $from: String, $orgId: String) {
        getClasslinkExpiredUser(to: $to, from: $from, orgId: $orgId) {
          orgId
          orgName
          subscriptionEndDate
          org {
            orgAdminId
            orgAdminName
            orgAdminEmail
          }
          source
        }
      }
    `,
    getCleverExpiredUser: gql`
      query getCleverExpiredUser($to: String, $from: String, $orgId: String) {
        getCleverExpiredUser(to: $to, from: $from, orgId: $orgId) {
          orgId
          orgName
          subscriptionEndDate
          org {
            orgAdminId
            orgAdminName
            orgAdminEmail
          }
          source
        }
      }
    `,
  },
};

export default CHART_QUERIES;
