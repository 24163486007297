import { Col, message, Row, Spin } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import debounce from 'lodash/debounce';
import Button from '../Button';
import SelectOption from '../Select/Option';

import * as S from './styles';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_TeacherSearchResponse } from '../../types/teacher';
import { SelectValue } from 'antd/lib/select';
import { ClassUser, GQL_UserDetailsResponse } from '../../types/profile';
import { GQL_UserDetailsResponsePaginated, GQL_UsersListFilterInput } from '../../types/user';

interface ITransferModal {
  class?: ClassUser;
  teacher?: GQL_UserDetailsResponse;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const TransferWorkModal = (props: ITransferModal) => {
  const { teacher, visible, setVisible, class: classObj } = props;
  const [selectedTeacher, setSelectedTeacher] = useState<GQL_TeacherSearchResponse | undefined>();

  const [fetchTeachers, { data: organizationTeachers, loading: loadingTeachers }] = useLazyQuery<
    { getAllUsersByFilter: GQL_UserDetailsResponsePaginated },
    { data: GQL_UsersListFilterInput }
  >(gqlSchema.UserSchema.queries.LIST.getAllUsersByFilter, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      message.error('There was an error loading teacher data: ' + err.message || 'Unexpected Error');
    },
  });

  const [transferAllClasses, { loading: loadingTransferClasses }] = useMutation<
    { transferAllClasses: boolean },
    { teacherId: string; newTeacherId: string }
  >(gqlSchema.ClassSchema.mutation.CLASS.EDIT.transferAllClasses, {
    onCompleted: (data) => {
      if (data.transferAllClasses) {
        message.success(`Classes transferred to ${selectedTeacher?.name} successfully.`);
        setVisible(false);
        setSelectedTeacher(undefined);
      } else {
        message.error('There was an error transfering the classes, try again later');
      }
    },
    onError: (error) => {
      message.error('There was an error transfering the classes: ' + error.message);
    },
    update(cache, { data }) {
      if (data?.transferAllClasses) {
        const classResponse = {
          __typename: 'UserDetailsResponse',
          id: teacher?.id,
        };
        cache.modify({
          id: cache.identify(classResponse),
          fields: {
            classes() {
              return [];
            },
          },
        });
      }
    },
  });

  const [transferClass, { loading: loadingTransferClass }] = useMutation<
    { transferClass: boolean },
    { classId: string; newTeacherId: string }
  >(gqlSchema.ClassSchema.mutation.CLASS.EDIT.transferClass, {
    onCompleted: (data) => {
      if (data.transferClass) {
        message.success(`Class transferred to ${selectedTeacher?.name} successfully.`);
        setVisible(false);
        setSelectedTeacher(undefined);
      } else {
        message.error('There was an error transfering the class, try again later');
      }
    },
    onError: (error) => {
      message.error('There was an error transfering the class: ' + error.message);
    },
    update(cache, { data }) {
      if (data?.transferClass) {
        const classResponse = {
          __typename: 'UserDetailsResponse',
          id: teacher?.id,
        };
        cache.modify({
          id: cache.identify(classResponse),
          fields: {
            classes(cachedClasses: ClassUser[]) {
              return cachedClasses.filter((cls: ClassUser) => {
                return cls.classId !== classObj?.classId;
              });
            },
          },
        });
      }
    },
  });

  const fetchTeacherDebounced = debounce((text?: string) => {
    setSelectedTeacher(undefined);
    if (text) {
      fetchTeachers({
        variables: {
          data: {
            partOfName: text,
            roles: ['teacher'],
            organizationId: teacher?.subscription.organizationId,
          },
        },
      });
    }
  }, 600);

  const onTransfer = useCallback(() => {
    if (selectedTeacher) {
      if (classObj)
        transferClass({
          variables: {
            classId: classObj?.classId || '',
            newTeacherId: selectedTeacher.id,
          },
        });
      else
        transferAllClasses({
          variables: {
            teacherId: teacher?.id || '',
            newTeacherId: selectedTeacher.id,
          },
        });
    }
  }, [classObj, selectedTeacher, teacher, transferAllClasses, transferClass]);

  return (
    <S.TransferModal title={null} visible={visible} footer={null} onCancel={() => setVisible(false)}>
      <S.Title>Transfer {!classObj ? `${teacher?.name}'s classes` : `class: ${classObj.name}`} </S.Title>
      <S.Info>
        This will transfer {!classObj ? `all of ${teacher?.name}'s classes, students` : `the class: ${classObj.name}`}
      </S.Info>
      <S.Info>and ongoing investigations to another teacher.</S.Info>
      <Row justify="center" gutter={8} style={{ marginTop: 30 }}>
        <Col span={20}>
          <h3>Find a Teacher</h3>
          <Row gutter={8}>
            <Col span={16}>
              <S.TransferModalSelect
                showSearch
                notFoundContent={loadingTeachers ? <Spin size="small" /> : undefined}
                allowClear
                value={selectedTeacher ? selectedTeacher.id : undefined}
                suffixItemOnLeft
                suffixIcon={<FiSearch />}
                onSearch={(v: SelectValue) => fetchTeacherDebounced(v as string)}
                filterOption={false}
                onSelect={(v: SelectValue) =>
                  setSelectedTeacher(
                    organizationTeachers?.getAllUsersByFilter.users.find((teacher) => teacher.id === (v as string)),
                  )
                }
                onClear={() => setSelectedTeacher(undefined)}
                placeholder="Start typing to search..."
              >
                {organizationTeachers?.getAllUsersByFilter.users
                  .filter((teacherRow) => teacher?.id !== teacherRow.id)
                  .map((teacher) => (
                    <SelectOption value={teacher.id} key={teacher.id}>
                      {teacher.name}
                    </SelectOption>
                  ))}
              </S.TransferModalSelect>
            </Col>
            <Col span={8}>
              <Button
                text="Transfer"
                minHeight={40}
                block
                loading={classObj ? loadingTransferClass : loadingTransferClasses}
                disabled={!selectedTeacher}
                onClick={() => onTransfer()}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </S.TransferModal>
  );
};

export default TransferWorkModal;
