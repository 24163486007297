import { chain, isArray } from 'lodash';
import { useMemo } from 'react';
import { SUBJECT_TYPES } from '../types/subjects';
import { subjects } from '../utils/constants';
import { useAuth } from './useAuth';
import { STATES } from '../types/state';

interface EnabledAssessmentsInterface {
  allowedSubjects: string[];
  allowedStates?: string[] | string;
}

export const useEnabledAssessments = (props?: EnabledAssessmentsInterface) => {
  const { user, isActiveAsAdmin: roleEnabled } = useAuth();

  const currentSubjects = useMemo(() => {
    return props?.allowedSubjects || user?.subscription?.allowedSubjects || [];
  }, [props, user]);

  const currentStates = useMemo(() => {
    return user?.organizationAllowedStates || user?.userAllowedStates || [];
  }, [user]);

  const featureList = useMemo(() => {
    return user?.subscription?.allowedFeatures || [];
  }, [user]);

  const gradeBandList = useMemo(() => {
    return user?.subscription?.allowedGradeBands || [];
  }, [user]);

  const subjectsList = useMemo(() => {
    const filteredSubjectList = chain(subjects)
      .map((subject) => {
        let stateStandardEdition = true;

        const subjectEditions = [SUBJECT_TYPES.SCIENCE_TEXAS_EDITION];

        // Check if the Subject belongs to the User's states standards
        if (props?.allowedStates && subjectEditions.includes(subject as SUBJECT_TYPES)) {
          const isAllowedState = isArray(props.allowedStates)
            ? props.allowedStates.some((item: string) => subject.toLowerCase().includes(item.toLowerCase()))
            : subject.toLowerCase()?.includes(props.allowedStates?.toLowerCase());

          stateStandardEdition = roleEnabled || isAllowedState;
        }

        return {
          key: subject,
          label: subject.replaceAll('_', ' '),
          allowed: roleEnabled ? true : Boolean(currentSubjects.includes(subject)),
          enabled: roleEnabled ? true : Boolean(currentSubjects.includes(subject)),
          showOption: stateStandardEdition,
        };
      })
      .value();

    return filteredSubjectList.filter((item) => item?.showOption);
  }, [roleEnabled, currentSubjects, props]);

  const texasEditionEnabled = useMemo(() => {
    const stateEnabled = currentStates?.find((state) => state?.name === STATES.TEXAS);
    const texasLabel = SUBJECT_TYPES.SCIENCE_TEXAS_EDITION;
    const enabled = roleEnabled ? true : Boolean(currentSubjects.includes(texasLabel));
    return enabled || Boolean(stateEnabled);
  }, [roleEnabled, currentSubjects, currentStates]);

  const ngssStandardsEnabled = useMemo(() => {
    const stateEnabled = currentStates?.find((state) => state?.name === STATES.NGSS);
    const enabled = roleEnabled ? true : Boolean(stateEnabled);
    return enabled;
  }, [currentStates, roleEnabled]);

  const haveAssessments: boolean = useMemo(() => {
    return Boolean(currentSubjects.length);
  }, [currentSubjects]);

  const isSubjectEnabled = (subject: SUBJECT_TYPES): boolean => {
    // if texas edition is not enabled and any other toggles are off
    if (!texasEditionEnabled && !currentSubjects.length) {
      return true;
    }

    // if texas edition is enabled, and other subjects are toggled on
    if (texasEditionEnabled && currentSubjects.length) {
      const enabled = currentSubjects.includes(subject);
      return enabled;
    }

    const enabled = currentSubjects.includes(subject);
    return enabled;
  };

  return {
    subjectsList,
    texasEditionEnabled,
    ngssStandardsEnabled,
    haveAssessments,
    isSubjectEnabled,
    featureList,
    gradeBandList,
  };
};
