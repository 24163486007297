import { Row, Divider } from 'antd';
import styled from 'styled-components';

export const CardTitle = styled.div`
  font-weight: bold;
  color: #9b9b9b;
  font-size: 12px;
`;

export const PlanTitle = styled.h4`
  font-size: 16px;
  margin: 0;
`;

export const PlanRow = styled(Row)`
  width: 100%;
  font-size: 14px;
  color: black;
  font-weight: 500;

  .price-element {
    color: #9b9b9b;
    text-align: right;
  }
`;
export const Division = styled(Divider)`
  margin: 15px 0;
`;
