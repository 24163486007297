/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ItemTypes } from '../types';
import { ContainerDragTarget, ProcessTasksTargetContainer } from '../styles';

export interface CardProps {
  id: number;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDropElement: (item: DragItem, targetIndex: number) => void;
  canDropElement: () => boolean;
  showSuccess: boolean;
  isCorrectAnswer: boolean;
  isImageVersion?: boolean;
}

export interface DragItem {
  index: number;
  id: number;
  type: string;
  text: string;
  value: string;
  isAnswer: boolean;
}

export const Card: FC<CardProps> = ({
  id,
  text,
  index,
  moveCard,
  onDropElement,
  canDropElement,
  showSuccess,
  isCorrectAnswer,
  isImageVersion = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId, isOver }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null; isOver: boolean }>({
    accept: ItemTypes.CARD,
    drop: (item) => onDropElement(item, index),
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!item.isAnswer) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index, text, isAnswer: true };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  return (
    <ProcessTasksTargetContainer showSuccess={showSuccess} isCorrectAnswer={isCorrectAnswer} isImage={isImageVersion}>
      <ContainerDragTarget
        ref={drop}
        style={{ opacity }}
        data-handler-id={handlerId}
        beingDragged={isDragging}
        isOver={isOver}
        $hasTarget={isOver}
        isImage={isImageVersion}
      >
        {isImageVersion ? (
          text && <img key={`image-drop-${index}`} src={text} alt={`Draggable Option ${index + 1}`} />
        ) : (
          <>{text}</>
        )}
      </ContainerDragTarget>
    </ProcessTasksTargetContainer>
  );
};
