import { gql } from '@apollo/client';
import TAGS_FRAGMENTS from '../fragments/Tags';

const TAGS_MUTATIONS = {
  createTag: gql`
    mutation createTag($data: TagInput!) {
      createTag(data: $data) {
        ...TagResponseFragment
      }
    }
    ${TAGS_FRAGMENTS.tagResponse}
  `,
  removeTag: gql`
    mutation removeTag($tagId: String!) {
      removeTag(tagId: $tagId)
    }
  `,
  toggleTagToUser: gql`
    mutation toggleTagToUser($data: [TagInput!]!, $userId: String!) {
      toggleTagToUser(data: $data, userId: $userId) {
        id
        userId
        action
      }
    }
  `,
  addTagsToUsers: gql`
    mutation addTagsToUsers($tagIds: [String!]!, $userIds: [String!]!) {
      addTagsToUsers(tagIds: $tagIds, userIds: $userIds)
    }
  `,
  removeTagsFromUsers: gql`
    mutation removeTagsFromUsers($tagIds: [String!]!, $userIds: [String!]!) {
      removeTagsFromUsers(tagIds: $tagIds, userIds: $userIds)
    }
  `,
};

export default TAGS_MUTATIONS;
