import { Col, message, Row, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import Button from '../../Button';
import ImgCrop from 'antd-img-crop';
import * as S from './styles';
import { BsImage } from 'react-icons/bs';
import { themeConfig } from '../../../utils/theme';
import { useMutation } from '@apollo/client';
import { GQL_UploadAvatarResponse } from '../../../types/login';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import Avatar from '../../Avatar';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}
const ModalUpdateAvatar: React.FC<Props> = (props) => {
  const { visible, setVisible } = props;
  const [file, setFile] = useState<RcFile | undefined>();
  const { user, updateUser } = useAuth();

  const beforeUpload = useCallback((file: RcFile) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) message.error('Image must smaller than 10MB!');
    else setFile(file);
    return false;
  }, []);

  const [submitFile, { loading: loadingFile }] = useMutation<
    { uploadAvatar: GQL_UploadAvatarResponse },
    { file: File }
  >(gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.uploadAvatar, {
    onCompleted: (data) => {
      onCloseModal();
    },
    onError: (err) => {
      message.error('There was an error uploading your avatar: ' + err.message);
      onCloseModal();
    },
    update(cache, { data }) {
      const me = {
        __typename: 'Me',
        id: data?.uploadAvatar.userId,
      };
      cache.modify({
        id: cache.identify(me),
        fields: {
          avatar() {
            return data?.uploadAvatar.url;
          },
        },
      });
      updateUser({ ...user, avatar: data?.uploadAvatar.url });
    },
  });

  const onUploadAvatar = () => {
    if (file) submitFile({ variables: { file } });
  };

  const onCloseModal = () => {
    setFile(undefined);
    setVisible(false);
  };

  return (
    <S.CModal
      visible={visible}
      title={
        <Row style={{ marginTop: 26 }} justify="center">
          <Col span={24}>
            <S.Title data-cy="shared-updateavatar-title">Update Your Picture</S.Title>
          </Col>
        </Row>
      }
      onCancel={onCloseModal}
      width={750}
      footer={null}
    >
      <Row>
        <Col span={24} style={{ minHeight: '35vh' }}>
          <Row justify="center">
            <Col span={11} style={{ textAlign: 'center' }}>
              <Avatar margin="25px" size={120} src={file ? URL.createObjectURL(file) : user.avatar} />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24} style={{ marginTop: 15 }}>
              <Row justify="center">
                <Col span={5} style={{ textAlign: 'center' }}>
                  <ImgCrop shape="round" rotate>
                    <Upload
                      data-cy="shared-updateavatar-modalupdateavatar-upload-input"
                      beforeUpload={beforeUpload}
                      onRemove={() => setFile(undefined)}
                      accept="image/*"
                      listType="text"
                    >
                      <Button theme={themeConfig.secondaryColor} text="Pick Image" block icon={<BsImage />} />
                    </Upload>
                  </ImgCrop>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 40 }} justify="center">
            <Col span={24}>
              <S.Info>Upload Your Picture Here.</S.Info>
              <S.Info>It must be JPEG, PNG, or GIF and cannot exceed 10MB.</S.Info>
            </Col>
            <Col span={5} style={{ margin: '20px 0' }}>
              <Button
                data-cy="shared-updateavatar-button-save"
                text="Save Changes"
                block
                onClick={onUploadAvatar}
                loading={loadingFile}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </S.CModal>
  );
};

export default ModalUpdateAvatar;
