import { Col, Row, Table } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ClassUser, GQL_UserDetailsResponse } from '../../../types/profile';
import * as S from './styles';
import { centerAlign } from '../../../utils/antd';
import { ColumnsType, FilterDropdownProps } from 'antd/lib/table/interface';
import TableSearchBox from '../../../shared/TableSearchBox';
import { FiSearch } from 'react-icons/fi';
import Button from '../../../shared/Button';
import TransferWorkModal from '../../../shared/TransferWorkModal';
import { BiTransfer } from 'react-icons/bi';
import { useAuth } from '../../../hooks/useAuth';

interface Props {
  user?: GQL_UserDetailsResponse;
}

const TeacherClasses: React.FC<Props> = (props) => {
  const { user } = props;
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [currentClass, setCurrentClass] = useState<ClassUser | undefined>();
  const [transferClassesVisible, setTransferClassesVisible] = useState(false);
  const refName = useRef<HTMLInputElement>(null);
  const { isOrganizationAdiAdmin } = useAuth();
  useEffect(() => {
    if (!searchNameVisible) {
      setCurrentClass(undefined);
    }
  }, [searchNameVisible]);

  const columns = useMemo(() => {
    const cols: ColumnsType<ClassUser> = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '50%',
        sorter: (a: ClassUser, b: ClassUser) => a.name.localeCompare(b.name),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: ClassUser) => {
          if (!value) return true;
          return record.name.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
    ];
    if (isOrganizationAdiAdmin) {
      cols.push({
        title: 'Actions',
        align: centerAlign,
        render: (text: string, record: ClassUser) => {
          return (
            <Button
              text="Transfer Class"
              onClick={() => {
                setCurrentClass(record);
                setTransferClassesVisible(true);
              }}
              block
              icon={<BiTransfer />}
            />
          );
        },
        width: '10%',
      });
    }
    return cols;
  }, [isOrganizationAdiAdmin, searchNameVisible]);

  return (
    <S.Body span={24}>
      <Row justify="center" style={{ padding: 20 }}>
        <Col span={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              rowKey={(record: ClassUser) => record.classId}
              bordered
              pagination={{ hideOnSinglePage: true }}
              dataSource={user?.classes}
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <TransferWorkModal
        class={currentClass}
        teacher={user}
        visible={transferClassesVisible}
        setVisible={setTransferClassesVisible}
      />
    </S.Body>
  );
};

export default TeacherClasses;
