import React, { useEffect, useRef, useState } from 'react';
import { Typography, Button,  message } from 'antd';
import * as S from './styles';
import { useLazyQuery, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { FaUser, FaRobot } from 'react-icons/fa';
//import { IoSend } from 'react-icons/io5';

export interface ChatOption {
    value: string;
    nextNode: string;
  }
  
  export interface ChatNode {
    id: string;
    name: string;
    type: 'static' | 'prompt';
    question: string;
    prompt?: string | string[];
    options?: ChatOption[];
  }

const { Text } = Typography;

const Chatbot = () => {
  const [currentNode, setCurrentNode] = useState<ChatNode | null>(null);
  const [history, setHistory] = useState<{ sender: 'bot' | 'user'; message: string }[]>([]);
  const [canUsePrompt, setCanUsePrompt] = useState(false);
  const [nodeJson, setNodeJson] = useState<any>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth",block: "nearest"});
  };

  useEffect(() => {

if(history.length > 1) {
scrollToBottom()
}

  }, [history]);

  const { data:nodeData,loading } = useQuery(gqlSchema.BookSchema.queries.getNode, {
    onError: (error) => {
      message.error(`Error in fetching chat bot data - ${error.message || 'Unexpected Error'}`);
    },
  });

  const [fetchPrompt,{ data: promptData,loading:promptLoading  }] = useLazyQuery(gqlSchema.BookSchema.queries.chatGptPrompt,{
    
    onError: (error) => {
      message.error(`Error in fetching chat bot data - ${error.message || 'Unexpected Error'}`);
    },
  });

  
  const getPromptMessage =  (prompt: string | string[]) => {
      fetchPrompt({variables:{prompt:prompt}});
      setCanUsePrompt(true)
      
     
  }
  const handlePromotData = (data:string) => {
    setHistory((prev) => [
      ...prev,
      { sender: 'bot', message: data },
     
    ]);
    //@ts-ignore
    setCurrentNode(nodeJson.data['end']);
  }
  useEffect(() => {
    if(promptData && canUsePrompt){
      handlePromotData(promptData.chatGptPrompt)
      setCanUsePrompt(false)
    }
  }, [promptData]);

 

  useEffect(() => {
    if(nodeData){
      const node = {...nodeData.getNode,data:JSON.parse(nodeData.getNode.data)}
      setNodeJson(node)
      // node.data = JSON.parse(node.data)
      // TODO change logic fetch data based on api.node
      console.log("Node2",node,nodeData)
      setCurrentNode(node.data[node.node])
      setHistory((prev) => [
        ...prev,
        { sender: 'bot', message: node.data[node.node].question },
      ]);
    }
  }, [nodeData])
  

  const handleOptionClick = async(option: ChatOption) => {
    //@ts-ignore
    const nextNode = nodeJson.data[option.nextNode];
    // console.log("NextNode",nodeJson,option.nextNode)
    if(nextNode.type==='static'){
    setHistory((prev) => [
      ...prev,
      { sender: 'user', message: option.value },
     
    ]);
    setTimeout(() => {
        setHistory((prev) => [
            ...prev,
            { sender: 'bot', message: nextNode.question },
           
          ]);
          setCurrentNode(nextNode);
    }, 500);
    
  }else{
    setHistory((prev) => [
      ...prev,
      { sender: 'user', message: option.value },
     
    ]);
    const prompt = nextNode.prompt;
     getPromptMessage(Array.isArray(prompt)?prompt[0]:prompt)

  }
  };
  if(loading)return <>Loading...</>

  return (
    <S.Container>
      <S.ChatBox>
        <S.Messages>
          {history.map((chat, index) => (
            <S.Message key={index} ref={(el) => {
              if (index === history.length - 1) {
                if (el) {
                  console.log("Updated ref with el",el)
                  messagesEndRef.current = el;
                }
              }
            }} sender={chat.sender}>
              {chat.sender === 'user' && (
                <S.MessageIcon sender={chat.sender}>
                  <FaUser />
                </S.MessageIcon>
              )}
              <S.MessageContent sender={chat.sender}>
                <Text>{chat.message}</Text>
              </S.MessageContent>
              {chat.sender === 'bot' && (
                <S.MessageIcon sender={chat.sender}>
                  <FaRobot />
                </S.MessageIcon>
              )}
            </S.Message>
          ))}
        </S.Messages>
        <S.Options>
          {!promptLoading && currentNode?.options?.map((option:any) => (
            <Button key={option.value} onClick={() => handleOptionClick(option)}>
              {option.value}
            </Button>
          ))}
        </S.Options>
        
      </S.ChatBox>
    </S.Container>
  );
};
export default Chatbot;