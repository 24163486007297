import styled from 'styled-components';
import { Button } from 'antd';

export const MenuWrapper = styled.div`
  margin-bottom: 1em;
`;

export const MenuSection = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h2 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
    /* identical to box height, or 23px */

    color: #767676;
  }

  button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }

  button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid #d9d9d9;
  }
`;

export const MiddleButton = styled(Button)<{
  $active?: boolean;
  $border?: boolean;
}>`
  line-height: 0;
  height: 30px;
  padding: 6px;
  border-radius: 0;
  border-left: none;
  border-right: ${(props) => (props.$border ? '1px solid #d9d9d9' : 'none')};
  background-color: ${(props) => (props.$active ? '#f0f0f0' : '#ffffff')};

  &:hover,
  &:focus {
    border-color: #d9d9d9;
    color: #525252;
  }
`;

export const PopconfirmContainer = styled.div`
  h2 {
    color: #767676;
    font-size: 14px;
  }

  & > span {
    display: flex;
    justify-content: flex-end;
  }
`;

export const FormulaHighlight = styled.span`
  background-color: #e4e4e4;
  padding: 2px 4px;
  border-radius: 1px;
  margin-right: 3px;
`;
