import React from 'react';
import styled from 'styled-components';

const SkeletonLoader: React.FC = () => {
  return (
    <SkeletonContainer>
      <SkeletonElement />
      <SkeletonElement />
      <SkeletonElement />
    </SkeletonContainer>
  );
};

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SkeletonElement = styled.div`
  background-color: #e0e0e0;
  height: 20px;
  width: 100%;
  border-radius: 4px;
  animation: shimmer 1.5s infinite linear;

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;

export default SkeletonLoader;