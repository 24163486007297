import { UploadProps } from 'antd/lib/upload/interface';
import React from 'react';
import { FiTrash2, FiUpload } from 'react-icons/fi';
import CircularSpin from '../CircularSpin';
import Spacer from '../Spacer';
import * as S from './styles';

interface IUploadInput extends UploadProps {
  title: string;
  showClear?: boolean;
  onClear?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
  maxCount?: number;
  loading?: boolean;
}

const UploadInput = (props: IUploadInput) => {
  const { title, onClear, showClear, loading, ...rest } = props;
  return (
    <S.UploadOutsider customRequest={() => {}} {...rest} disabled={loading}>
      <S.UploadContainer>
        {loading ? <CircularSpin size={18} /> : <FiUpload />}
        <Spacer axis="horizontal" />
        <p>{title}</p>
        {showClear && <FiTrash2 onClick={onClear} />}
      </S.UploadContainer>
    </S.UploadOutsider>
  );
};

export default UploadInput;
