import styled from 'styled-components';

export const Divider = styled.span`
  height: 1px;
  width: 50%;
  background: #c6c6c6;
  border-radius: 6px;
  display: block;
  margin: auto;
  margin-bottom: 24px;
`;

export const SettingTitle = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;

  color: #000000;
`;

export const DatePickerText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  margin-top: 2px;

  color: #3f3e38;

  & > span {
    font-weight: 700;
  }
`;

export const DropdownSelect = styled.div<{ open: boolean }>`
  width: 100%;
  border-radius: 6px;
  border: 1px solid #c4c4c4;
  padding: 14px 16px;

  & > div:first-child {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${(props) => (props.open ? 33.3 : 100)}%;
  }
`;

export const OptionsContainer = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;

  p {
    margin: 12px 0;
  }

  & > div {
    width: 100%;
    cursor: pointer;
  }

  & > div:first-child {
    border-bottom: 1px solid #c6c6c6;
  }
`;

export const PrerequisiteContainer = styled.div<{ activated: boolean }>`
  width: 100%;
  border-radius: 6px;
  border: 1px solid #c4c4c4;
  padding: 14px 16px;

  & > p {
    color: ${(props) => (props.activated ? '#2ecc71' : '#ea3535')};
  }
`;

export const PrerequisiteTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 16px;
  cursor: pointer;

  h2 {
    margin-bottom: 0;
  }
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 160px);

  .ant-select-selector {
    font-size: 14px;
    color: #3f3e38;
    font-weight: 500;
  }
`;

export const ActivityButton = styled.button<{ selected?: boolean }>`
  background: #fff;
  border: ${(props) => (props.selected ? 2 : 1)}px solid
    ${(props) => (props.selected ? props.theme.primaryColor.background : '#c4c4c4')};
  ${(props) => props.selected && 'box-shadow: 0 0 18px 2px rgba(123,140,234,0.2)'};
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 16px;
  outline: none;
  text-align: left;
  line-height: 1.3;

  svg {
    margin-left: 8px;
  }
`;
