import { Row } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import Draggable from 'react-draggable';

import Button from '../Button';
import * as S from './styles';
import { Input } from 'antd';
import { themeConfig } from '../../utils/theme';
import Avatar from '../Avatar';
import { useAuth } from '../../hooks/useAuth';

const { TextArea } = Input;

interface IDraggableComment {
  loading?: boolean;
  disabledOnBlankText?: boolean;
  onSubmit?: (
    comment: string,
    position: {
      x: number;
      y: number;
    },
  ) => void;
  onCancel?: VoidFunction;
  bounds?: string | { left: number; top: number; right: number; bottom: number };
  initialPosition: {
    x: number;
    y: number;
  };
}

const DraggableComment: React.FC<IDraggableComment> = (props) => {
  const [comment, setComment] = useState('');
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState(props.initialPosition);
  const { user } = useAuth();

  const setDraggingTrue = useCallback(() => setDragging(true), []);
  const updateDragging = useCallback((e, data: { x: number; y: number }) => {
    setDragging(false);
    setPosition(data);
  }, []);

  const userIcon = useMemo(() => {
    return <Avatar margin="5px" size={40} src={user.avatar} />;
  }, [user]);

  const submitComment = useCallback(() => {
    if (props.onSubmit) props.onSubmit(comment, position);
  }, [props, comment, position]);

  return (
    <Draggable
      handle=".md-panel--control"
      defaultPosition={props.initialPosition}
      onStart={setDraggingTrue}
      bounds={props.bounds}
      onStop={updateDragging}
    >
      <S.DraggableContainer>
        <S.DraggableCommentBalloon className="md-panel--control" $dragging={dragging} />
        <S.CommentTriangle />
        <S.DraggableCommentBoxContainer>
          <Row align="middle">
            {userIcon}
            <S.NameText>Me</S.NameText>
          </Row>
          <TextArea
            rows={4}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            data-cy="components-draggable-comment-text-area"
          />
          <Row>
            <Button
              text="Add"
              onClick={submitComment}
              minWidth={70}
              loading={props.loading}
              disabled={props.disabledOnBlankText && !comment}
              data-cy="components-draggable-comment-add-button"
            />
            <div style={{ width: 15 }} />
            <Button
              text="Cancel"
              onClick={props.onCancel}
              minWidth={70}
              theme={themeConfig.noColor}
              loading={props.loading}
              data-cy="components-draggable-comment-cancel-button"
            />
          </Row>
        </S.DraggableCommentBoxContainer>
      </S.DraggableContainer>
    </Draggable>
  );
};

export default DraggableComment;
