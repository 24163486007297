import { useLazyQuery } from '@apollo/client';
import { Col, Empty, message, Row, Spin, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { gqlSchema } from '../../../gql/schema';
import { ConfigColor, GQL_AverageTTCIResponse, GQL_InvestigationsCompletionTimeResponse } from '../../../types/charts';
import { Duration, FilterData } from '../../../types/investigation';
import { formatDateTime } from '../../../utils/date';
import Filter from '../Filter';
import StatusBar from '../StatusBar';
import * as S from './styles';

const configColor: ConfigColor[] = [
  {
    from: 1,
    to: 8.99,
    color: '#00c22d',
  },
  {
    from: 9,
    to: 16,
    color: '#fffa00',
  },
  {
    from: 16.1,
    to: 31,
    color: '#ff2b00',
  },
];
const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};
const InvestigationsAverageTime = () => {
  const [investigationDurationAverage, setInvestigationDurationAverage] = useState<Duration>(defaultDateRange);
  const [filterData, setFilterData] = useState<FilterData>({ organizations: false, teachers: false, tagIds: [] });

  const onChangeFilterData = useCallback((data: FilterData) => {
    setFilterData(data);
  }, []);

  const [fetchMostUsedInvestigations, { data, loading }] = useLazyQuery<{
    getAverageTimeToCompleteInvestigations: GQL_AverageTTCIResponse[];
  }>(gqlSchema.ChartSchema.queries.CHARTS.getAverageTimeToCompleteInvestigations, {
    onError: (err) => {
      message.error(
        'There was an error loading most average time to complete investigations: ' + err.message || 'Unexpected Error',
      );
    },
  });

  const [
    fetchInvestigationsCompletionTimeChart,
    { data: investigationsCompletionTime, loading: loadingInvestigationsCompletionTime },
  ] = useLazyQuery<{
    getInvestigationsCompletionTimeChart: GQL_InvestigationsCompletionTimeResponse;
  }>(gqlSchema.ChartSchema.queries.CHARTS.getInvestigationsCompletionTimeChart, {
    onError: (err) => {
      message.error(
        'There was an error loading most average time to complete investigations: ' + err.message || 'Unexpected Error',
      );
    },
  });

  useEffect(() => {
    const { disciplineIds, ...data } = filterData;
    fetchMostUsedInvestigations({
      variables: {
        data: {
          startDate: investigationDurationAverage.startDate,
          endDate: investigationDurationAverage.endDate,
          ...data,
        },
      },
    });

    fetchInvestigationsCompletionTimeChart({
      variables: {
        data: {
          startDate: investigationDurationAverage.startDate,
          endDate: investigationDurationAverage.endDate,
          ...data,
        },
      },
    });
  }, [fetchMostUsedInvestigations, fetchInvestigationsCompletionTimeChart, investigationDurationAverage, filterData]);

  const spinner = useMemo(
    () => (
      <Row justify="center" style={{ marginTop: 15 }}>
        <Col span={1}>
          <Spin size="large" />
        </Col>
      </Row>
    ),
    [],
  );

  const emptyList = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <S.Card>
            <Row justify="center" style={{ width: '100%' }}>
              <Col span={24}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            </Row>
          </S.Card>
        </Col>
      </Row>
    ),
    [],
  );
  const renderStudentsCompletionAverage = useCallback(
    (investigation: GQL_AverageTTCIResponse) => {
      const studentCompletion = investigationsCompletionTime?.getInvestigationsCompletionTimeChart.investigations.find(
        (inv) => inv.investigationId === investigation.id,
      );
      return (
        <StatusBar
          maxValue={31}
          value={
            studentCompletion
              ? Math.round(studentCompletion?.averageCompletionTime / (24 * 60 * 60 * 1000))
              : investigation.average
          }
          title={'Average Completion Time'}
          configColor={configColor}
        />
      );
    },
    [investigationsCompletionTime],
  );
  const cards = useMemo(
    () =>
      data?.getAverageTimeToCompleteInvestigations &&
      data.getAverageTimeToCompleteInvestigations.length > 0 &&
      !loadingInvestigationsCompletionTime ? (
        <Row gutter={[16, 16]} style={{ marginTop: 5, marginBottom: 20 }}>
          {data?.getAverageTimeToCompleteInvestigations.map((investigation) => (
            <Col lg={8} sm={24}>
              <S.Card style={{ padding: 15 }}>
                <S.Title style={{ fontSize: '1.2em' }}>{investigation.name}</S.Title>
                <Row style={{ marginTop: 5 }} gutter={[0, 8]}>
                  <Col span={24}>
                    <StatusBar
                      maxValue={31}
                      value={investigation.average}
                      title="Average Assigned Length"
                      configColor={configColor}
                    />
                  </Col>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {renderStudentsCompletionAverage(investigation)}
                  </Col>
                </Row>
              </S.Card>
            </Col>
          ))}
        </Row>
      ) : (
        emptyList
      ),
    [data, emptyList, loadingInvestigationsCompletionTime, renderStudentsCompletionAverage],
  );

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Investigations Completion Average</S.Title>
          <Tooltip title="Average number of days given to complete an investigation compared to the average number of days it takes students to complete the investigation.">
            <FiHelpCircle style={{ margin: '10px 0 0 5px', position: 'absolute' }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Filter setDaterange={setInvestigationDurationAverage} onChangeFilterData={onChangeFilterData} />
        </Col>
        {loading ? spinner : <Col span={24}>{cards}</Col>}
      </Row>
    </>
  );
};

export default InvestigationsAverageTime;
