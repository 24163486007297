import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Row, Col, message, Empty } from 'antd';
import Item from './Item';
import DashboardRow from '../../../shared/DashboardRow';
import { gqlSchema } from '../../../gql/schema';
import { GQL_ClassResponse } from '../../../types/class';
import * as S from './styles';
import board from '../../../assets/board.svg';
import { useQuery } from '@apollo/client';
import DashboardLoadingCard from '../../DashboardLoadingCard';

const ClassesRow = () => {
  const [classesList, setClassesList] = useState<GQL_ClassResponse[]>([]);
  const [extraClassesList, setExtraClassesList] = useState<GQL_ClassResponse[]>([]);

  const { loading } = useQuery(gqlSchema.ClassSchema.query.CLASS.CLASSES.getClasses, {
    onCompleted: ({ getClasses }: { getClasses?: GQL_ClassResponse[] }) => {
      const classLength = getClasses?.length || 0;
      const classes = getClasses || [];
      const sortedClasses = [...classes].sort((a, b) => b.endDate - a.endDate);
      if (classLength > 2) {
        setClassesList(sortedClasses.slice(0, 3));
        setExtraClassesList(sortedClasses.slice(3, sortedClasses?.length));
      } else {
        setClassesList(sortedClasses);
      }
    },
    onError: (error) => {
      message.error(
        error.message || 'There was an error loading your classes, check you connection and try again later.',
      );
    },
  });

  const classes = useMemo(() => {
    if (loading)
      return (
        <Row gutter={[24, 24]}>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard cardStyle="class" />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard cardStyle="class" />
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <DashboardLoadingCard cardStyle="class" />
          </Col>
        </Row>
      );

    if (!classesList?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No classes yet" />;
    }

    return (
      <Row gutter={[24, 24]}>
        {classesList.map((item: GQL_ClassResponse) => {
          return (
            <Col
              xxl={8}
              xl={8}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              key={item.id}
              data-cy={`shared-studentdashboardrows-ClassRowItem-${item.id}`}
            >
              <Item classObj={item} icon={<S.LogoImage src={board} alt="Class Icon" />} />
            </Col>
          );
        })}
      </Row>
    );
  }, [classesList, loading]);
  const [extraClasses, setExtraClasses] = useState<ReactElement | null>(null);

  const onViewMoreClasses = useCallback(
    (opened: boolean) => {
      if (opened)
        setExtraClasses(
          <Row gutter={[24, 24]}>
            {extraClassesList.map((item: GQL_ClassResponse) => {
              return (
                <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
                  <Item classObj={item} icon={<S.LogoImage src={board} alt="Class Icon" />} />
                </Col>
              );
            })}
          </Row>,
        );
      else setExtraClasses(null);
    },
    [extraClassesList],
  );

  return (
    <DashboardRow
      title="My Classes"
      items={classes}
      extraItems={extraClasses}
      onViewMore={onViewMoreClasses}
      showViewMore={extraClassesList.length > 0}
      extraCyTitle="student-classes"
    />
  );
};

export default ClassesRow;
