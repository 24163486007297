import { Col } from 'antd';
import styled from 'styled-components';
import Button from '../../../shared/Button';

export const Column = styled(Col)<{ $presentationMode?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  & > p,
  & > h1,
  & > h2 {
    text-align: center;
  }

  & > h1 {
    margin-bottom ${(props) => (props.$presentationMode ? 0 : 30)}px;
  }

  & > div {
    width: 100%;
  }
`;

export const RoundButton = styled(Button)<{ $left?: boolean }>`
  height: 40px;
  width: 40px;
  padding-${(props) => (props.$left ? 'left' : 'right')}: 2px;
  margin-${(props) => (props.$left ? 'left' : 'right')}: 25px;
  z-index: 1001;
`;

export const Container = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  z-index: 99;
  width: 104%;
  height: 102%;
  position: absolute;
  top: -1%;
  left: -2%;
  background: #00000033;
`;

export const RoundButtonPlaceholder = styled.div<{ $left?: boolean }>`
  height: 40px;
  width: 40px;
  padding-${(props) => (props.$left ? 'left' : 'right')}: 2px;
  margin-${(props) => (props.$left ? 'left' : 'right')}: 25px;
  background: transparent;

  @media (max-width: 768px) {
    display:none;
  }
`;

export const PreviewBarrier = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  background: transparent;
  z-index: 1000;
  position: absolute;
`;
