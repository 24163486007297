import React, { useEffect, useState } from 'react';
import { Button, Switch, Layout, Card, InputNumber, DatePicker, TimePicker, Spin, Select } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
// import SelectStudentsPopup from './SelectStudentsPopup';

const { Content } = Layout;
const { Option } = Select;

interface Props {
  assignmentName: string;
  assignmentDescription: string;
  assignmentType?: string | undefined;
  maxPoints: number | undefined;
  dueDate: moment.Moment | null;
  dueTime: moment.Moment | null;
  onSaveAssignment: () => void;
  setMaxPoints: (points: number | undefined) => void;
  setDueDate: (date: moment.Moment | null) => void;
  setDueTime: (time: moment.Moment | null) => void;
  setAssignmentType: (type: string | undefined) => void; 
  loading: boolean;
  isEditMode: boolean;
  individualStudents: string[] | undefined;
  setIndividualStudents: (students: string[] | undefined) => void;
  setIsEditMode: (isEditMode: boolean) => void;
  courseId: string;
  assignmentId: string;
  newAssignmentId: string;
}

const CoreSettingsSider: React.FC<Props> = ({
  assignmentName,
  maxPoints,
  dueDate,
  dueTime,
  onSaveAssignment,
  setMaxPoints,
  setDueDate,
  setDueTime,
  loading,
  isEditMode,
  setIsEditMode,
  individualStudents,
  setIndividualStudents,
  courseId,
  assignmentId,
  newAssignmentId
}) => {
  const history = useHistory();
  // const [isSelectStudentsPopupVisible, setIsSelectStudentsPopupVisible] = useState<boolean>(false);

  const handleCancel = () => {
    setIsEditMode(false);
    history.push(`/canvas-teacher-dashboard/class/${courseId}`);
  };

  const handleSaveAssignment = () => {
    console.log('Saving Assignment...');
    onSaveAssignment(); // Perform the save logic
    // if (!isEditMode) {
    //   setIsSelectStudentsPopupVisible(true); // Show the popup if not in edit mode
    // }
  };

  // const handleSelectStudentsPopupSave = (selectedStudents: string[]) => {
  //   console.log('Selected Students:', selectedStudents);
  //   setIndividualStudents(selectedStudents);
  //   setIsSelectStudentsPopupVisible(false); // Close the popup
  // };

  // const handleSelectStudentsPopupCancel = () => {
  //   console.log('Cancelling SelectStudentsPopup...');
  //   setIsSelectStudentsPopupVisible(false);
  // };

  // const handleAssignToClick = () => {
  //   // setIsSelectStudentsPopupVisible(true); // Show the popup when "Assign To" is clicked
  // };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    console.log('Resize detected:', window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Layout style={{ width: '100%', height: isMobile? '90%' : '100vh' }}>
      {loading && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
          <Spin size="large" />
        </div>
      )}
      <Card style={{ height: '100%' }}>
        <Content>
          <h1 style={{ fontSize: '1.5rem',display: isMobile? 'none': 'unset' }}>Assignments</h1>
          <Select
            style={{ width: '100%' }}
            value="Assignment"
            disabled // Disable interaction
          >
            <Option value="Assignment">Assignment</Option>
          </Select>
          <InputNumber
            min={0}
            style={{ width: '100%', marginTop: '10px' }}
            placeholder="Enter Points"
            onChange={(value) => setMaxPoints(value || 0)}
            value={maxPoints}
          />
          <DatePicker
            style={{ width: '100%', marginTop: '10px' }}
            placeholder="Select Due Date"
            onChange={(date) => setDueDate(date ? date : null)}
            value={dueDate}
          />
          <TimePicker
            style={{ width: '100%', marginTop: '10px' }}
            placeholder="Select Due Time"
            onChange={(time) => setDueTime(time ? moment(time) : null)} // Ensure it's a Moment object
            value={dueTime ? dueTime : null} // Ensure dueTime is a Moment object
          />
          {/* "Assign To" button */}
          {/* {isEditMode && (
            <Button
              type="default"
              onClick={handleAssignToClick}
              style={{ marginTop: '10px' }}
            >
              Assign To
            </Button>
          )} */}
          <h3 style={{ fontSize: '1rem', marginTop: '10px' }}>Published Assignments</h3>
          <Switch checked={true} disabled={true} />
        </Content>
        <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
          <Button type="primary" onClick={handleSaveAssignment} style={{ backgroundColor: '#4367E9', borderColor: '#4367E9', flex: 1 }}>{isEditMode ? 'Edit' : 'Save'}</Button>
          <Button type="default" onClick={handleCancel} style={{ marginLeft: '10px', flex: 1 }}>Cancel</Button>
        </div>
      </Card>
      {/* <SelectStudentsPopup
        assignmentName={assignmentName}
        visible={isSelectStudentsPopupVisible}
        onCancel={handleSelectStudentsPopupCancel}
        onSave={handleSelectStudentsPopupSave}
        courseId={courseId}
        individualStudents={individualStudents}
        setIndividualStudents={setIndividualStudents}
        assignmentId={assignmentId}
        newAssignmentId={newAssignmentId}
      /> */}
    </Layout>
  );
};

export default CoreSettingsSider;
