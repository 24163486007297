export interface GQL_State {
  id: string;
  name: string;
  postalCode: string;
}

export enum STATES {
  TEXAS = 'Texas',
  NGSS = 'NGSS',
}
