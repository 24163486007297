import { SelectValue } from 'antd/lib/select';
import React, { useEffect, useMemo, useState } from 'react';
import { FiArrowLeft, FiLayers, FiSettings } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import Sider from '../../../shared/Sider';
import Spacer from '../../../shared/Spacer';
import Switch from '../../../shared/Switch';
import {
  GQL_InvestigationCatalogActivityEntry,
  GQL_InvestigationCatalogEntry,
  GQL_Mode,
} from '../../../types/investigation';
import { themeConfig } from '../../../utils/theme';
import BlockEditingTab from './BlockEditingTab';
import BlocksTab from './BlocksTab';
import SettingsTab from './SettingsTab';
import * as S from './styles';
import { GQL_InvestigationStandard } from '../../../types/investigationStandard';

interface IActivitiesSettingsSider {
  investigationId: string;
  stageId: string;
  title?: string;
  setNotes: (v?: {
    remoteTeacher?: string;
    inPersonTeacher?: string;
    inPersonTeacherTips?: string;
    safety?: string;
    help?: string;
  }) => void;
  notes?: {
    remoteTeacher?: string;
    inPersonTeacher?: string;
    inPersonTeacherTips?: string;
    safety?: string;
    help?: string;
  };
  loading?: boolean;
  selectedActivityId?: string;
  selectedContentIndex?: number;
  saveActivity?: () => void;
  updateSelectedActivityField: (fieldName: string, value: any) => void;
  setSelectedActivityId: (id: string | undefined) => void;
  selectedActivity?: GQL_InvestigationCatalogActivityEntry;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedBlockIndex?: number;
  setSelectedBlockIndex: (index?: number) => void;
  handlePreviewingChange: () => void;
  isPreviewing: boolean;
  editingTab: string;
  setEditingTab: (v: string) => void;
  stageMode?: GQL_Mode;
  presentationMode?: boolean;
  isAssessment: boolean;
  setPresentationMode: (v: boolean) => void;
  assessment?: GQL_InvestigationCatalogEntry;
  setNgssStandards?: (standards: GQL_InvestigationStandard[]) => void;
  setTexasStandards?: (standards: GQL_InvestigationStandard[]) => void;
}

const ActivitiesSettingsSider = (props: IActivitiesSettingsSider) => {
  const {
    investigationId,
    stageId,
    notes,
    setNotes,
    saveActivity,
    selectedActivityId,
    setSelectedActivityId,
    setActivities,
    loading,
    selectedContentIndex,
    activities,
    setSelectedBlockIndex,
    selectedBlockIndex,
    updateSelectedActivityField,
    selectedActivity,
    title,
    isPreviewing,
    handlePreviewingChange,
    editingTab,
    setEditingTab,
    stageMode,
    setPresentationMode,
    presentationMode,
    isAssessment,
    assessment,
    setNgssStandards,
    setTexasStandards,
  } = props;

  const history = useHistory();
  const [activeTab, setActiveTab] = useState('1');
  const settingsSiderTitle = useMemo(() => {
    return isAssessment ? selectedActivity?.name : title;
  }, [title, isAssessment, selectedActivity]);

  const isRemoteInvestigation = editingTab === 'remote';
  const selectedActivityContentList = isRemoteInvestigation
    ? selectedActivity?.content
    : selectedActivity?.contentInPerson;

  const selectedContent =
    selectedActivityContentList && selectedContentIndex !== undefined
      ? selectedActivityContentList[selectedContentIndex]
      : undefined;
  const editingBlock =
    selectedContent?.blocks && selectedBlockIndex !== undefined
      ? selectedContent?.blocks[selectedBlockIndex]
      : undefined;

  const inPersonModeTitle = isAssessment ? 'Paper' : 'In-Person';
  const remoteModeTitle = isAssessment ? 'Paperless' : 'Remote';

  const handleGoBack = (e: React.MouseEvent) => {
    e.stopPropagation();
    saveActivity && saveActivity();
    history.push(
      isAssessment
        ? `/adi-assessments/edit/${investigationId}/settings`
        : `/adi-investigations/edit/${investigationId}/stages`,
      {
        initialStageId: stageId,
      },
    );
  };

  const handleChangeActivity = (value: SelectValue) => {
    saveActivity && saveActivity();
    const newActivity = activities.find((a) => a.id === value);
    if (newActivity) {
      setNotes({
        help: newActivity.help ?? undefined,
        inPersonTeacher: newActivity.inPersonTeacher ?? undefined,
        inPersonTeacherTips: newActivity.inPersonTeacherTips ?? undefined,
        remoteTeacher: newActivity.remoteTeacher ?? undefined,
        safety: newActivity.safety ?? undefined,
      });
    }

    setSelectedBlockIndex(undefined);
    setSelectedActivityId(value as string);
  };

  const updateContentTitle = (value: string) => {
    if (selectedContentIndex === undefined) return;
    const customContent = isRemoteInvestigation
      ? [...(selectedActivity?.content || [])]
      : [...(selectedActivity?.contentInPerson || [])];
    if (customContent[selectedContentIndex]) {
      customContent[selectedContentIndex].title = value;
    }

    updateSelectedActivityField(isRemoteInvestigation ? 'content' : 'contentInPerson', customContent);
  };

  useEffect(() => {
    setPresentationMode(false);
  }, [isPreviewing, setPresentationMode]);

  return (
    <Sider
      title={
        <S.SiderTitle onClick={handleGoBack}>
          <FiArrowLeft size={22} />
          <Spacer axis="horizontal" />
          {settingsSiderTitle}
        </S.SiderTitle>
      }
    >
      <S.SiderContainer>
        <S.SettingsContainer>
          {isPreviewing ? (
            <S.PreviewModeDisclaimer>
              <Switch
                title="Presentation Mode"
                checked={presentationMode ?? false}
                value={presentationMode ?? false}
                onChange={setPresentationMode}
                type="text"
                titleAlign="right"
                margin="0 24px"
                removeWrapperHeight
              />
            </S.PreviewModeDisclaimer>
          ) : (
            <>
              {!isAssessment ? (
                <>
                  <Select placeholder="Select An Activity" value={selectedActivityId} onChange={handleChangeActivity}>
                    {activities.map((activity) => (
                      <SelectOption value={activity.id} key={activity.id}>
                        {activity.name || 'No Name'}
                      </SelectOption>
                    ))}
                  </Select>
                  <Spacer />
                </>
              ) : null}
              <S.StyledTabs defaultActiveKey="1" onChange={setActiveTab} activeKey={activeTab}>
                <S.StyledTabPane
                  tab={
                    <>
                      <FiLayers />
                      <h2>Build</h2>
                    </>
                  }
                  key="1"
                >
                  <Spacer />

                  <h2>Content Title</h2>
                  <Input
                    placeholder="Content Title"
                    backgroundColor="white"
                    value={selectedContent?.title}
                    onChange={(e) => updateContentTitle(e.target.value)}
                    disabled={selectedContentIndex === undefined}
                  />
                  <Spacer />
                  <S.StyledTabs defaultActiveKey="remote" onChange={setEditingTab} activeKey={editingTab}>
                    <S.StyledTabPane
                      tab={<h2>{`${remoteModeTitle}`}</h2>}
                      key="remote"
                      disabled={stageMode === 'INPERSON'}
                    />
                    <S.StyledTabPane
                      tab={<h2>{`${inPersonModeTitle}`}</h2>}
                      key="in-person"
                      disabled={stageMode === 'REMOTE'}
                    />
                  </S.StyledTabs>
                  {editingBlock ? (
                    <BlockEditingTab
                      loading={loading}
                      saveActivity={saveActivity}
                      activities={activities || []}
                      editingBlock={editingBlock}
                      selectedActivityId={selectedActivityId || ''}
                      selectedBlockIndex={selectedBlockIndex || 0}
                      selectedContentIndex={selectedContentIndex || 0}
                      setActivities={setActivities as any}
                      setSelectedBlockIndex={setSelectedBlockIndex}
                      editingRemote={editingTab === 'remote'}
                    />
                  ) : (
                    <BlocksTab
                      activities={activities}
                      setSelectedBlockIndex={setSelectedBlockIndex}
                      updateSelectedActivityField={updateSelectedActivityField}
                      selectedActivity={selectedActivity}
                      selectedActivityId={selectedActivityId}
                      selectedContent={selectedContent}
                      selectedContentIndex={selectedContentIndex}
                      investigationId={investigationId}
                      stageId={stageId}
                      isRemoteInvestigation={isRemoteInvestigation}
                    />
                  )}
                </S.StyledTabPane>
                <S.StyledTabPane
                  tab={
                    <>
                      <FiSettings />
                      <h2>Settings</h2>
                    </>
                  }
                  key="2"
                >
                  <SettingsTab
                    handleGoBack={handleGoBack}
                    setNotes={setNotes}
                    updateSelectedActivityField={updateSelectedActivityField}
                    loading={loading}
                    notes={notes}
                    saveActivity={saveActivity}
                    selectedActivity={selectedActivity}
                    selectedActivityId={selectedActivityId}
                    assessment={assessment}
                    isAssessment={isAssessment}
                    setNgssStandards={setNgssStandards}
                    setTexasStandards={setTexasStandards}
                  />
                </S.StyledTabPane>
              </S.StyledTabs>
            </>
          )}
        </S.SettingsContainer>
        <Spacer />
        <Button
          text={isPreviewing ? 'Back to Edit' : `Save and Preview ${isAssessment ? 'Part' : 'Activity'}`}
          block
          theme={themeConfig.secondaryColor}
          onClick={isPreviewing ? handleGoBack : handlePreviewingChange}
        />
      </S.SiderContainer>
    </Sider>
  );
};

export default ActivitiesSettingsSider;
