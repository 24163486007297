import React, { useState } from 'react';
import * as S from './styles';
import { Col, Modal, Row } from 'antd';
import { RxArrowLeft, RxCross2 } from 'react-icons/rx';
import { themeConfig } from '../../utils/theme';
import PackageCard from './PackageCard';
import { packageFeatures } from './packageFeatures';
import Button from '../../shared/Button';
import { useHistory } from 'react-router-dom';
import { FreeUserModal } from '../../shared/FreeTeacher/freeUserModal';

const SubscriptionPackageSelectionPublicPage = () => {
  const history = useHistory();
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);
  const [isFreeUserSignupModalOpen, setIsFreeUserSignupModalOpen] = useState(false);

  const closeOrganizationModal = () => {
    setIsOrganizationModalOpen(false);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <S.Container size="full">
      <S.TitleFlex>
        <Button
          theme={themeConfig.secondaryOutlined}
          text="Back to Home"
          onClick={goBack}
          icon={<RxArrowLeft strokeWidth="1.2" />}
        />
        <S.Title noMargin>Choose the best plan for you</S.Title>
        {/* This button is added just to space the items properly */}
        <S.HiddenButton
          theme={themeConfig.secondaryOutlined}
          text="Back to Home"
          onClick={goBack}
          icon={<RxArrowLeft strokeWidth="1.2" />}
        />
      </S.TitleFlex>

      <Row justify="center" style={{ marginBottom: '40px' }}>
        <Button
          text="Looking for an Organization Plan?"
          theme={themeConfig.primaryOutlined}
          onClick={() => setIsOrganizationModalOpen(true)}
          style={{
            borderRadius: '34px',
            background: 'transparent',
            padding: '8px 16px',
            fontSize: '14px',
            height: 'fit-content',
          }}
        />
      </Row>

      <Row justify="center" gutter={[60, 24]}>
        {/* Free plan package card */}
        <Col span={16} style={{ maxWidth: '440px' }}>
          <PackageCard
            title="Starter"
            subtitle="Free for educators, always."
            ctaText="Create Starter Account"
            action={() => setIsFreeUserSignupModalOpen(true)}
            content={packageFeatures.starter}
          />
        </Col>

        {/* Individual pro plan package card */}
        <Col span={8} style={{ maxWidth: '440px' }}>
          <PackageCard
          
            title="Pro"
            pillText="Starter + Upgrades"
            popOverPillText="Create a starter account to upgrade to pro level subscription"
            subtitle={`${packageFeatures.teacher.pro.yearly} (1 Year Subscription)`}
            ctaText="Request A Quote"
            action={() => console.log('Hello')}
            content={packageFeatures.teacher.pro.features}
          />
        </Col>

        {/* Individual premium plan package card */}
        <Col span={8} style={{ maxWidth: '440px' }}>
          <PackageCard
          
            title="Premium"
            pillText="Pro + Upgrades"
            popOverPillText="Create a starter account to upgrade to premium level subscription"
            subtitle={`${packageFeatures.teacher.premium.yearly} (1 Year Subscription)`}
            ctaText="Request A Quote"
            action={() => console.log('Hello')}
            content={packageFeatures.teacher.premium.features}
          />
        </Col>
      </Row>

      <Modal
        centered
        footer={false}
        visible={isOrganizationModalOpen}
        onCancel={closeOrganizationModal}
        width="1280px"
        zIndex={1003}
        closeIcon={<RxCross2 size="1.25em" />}
      >
        <S.ModalBody>
          <S.ModalTitle>Organization Plans</S.ModalTitle>
          <S.ModalSubtitle>Choose the option that works best for your district or school</S.ModalSubtitle>

          <Row justify="center" gutter={[50, 24]}>
            <Col span={12} style={{ maxWidth: '530px' }}>
              <PackageCard
                title="Org"
                titleSuffix="(1 Year Subscription)"
                subtitle="Includes Administrator and Teacher Accounts"
                ctaText="Request A Quote"
                action={() => window.open('https://share.hsforms.com/1-fMew_3gTyu6xehdKOSrLg5gbsq')}
                content={packageFeatures.enterprise.pro.features}
              />
            </Col>
            <Col span={12} style={{ maxWidth: '530px' }}>
              <PackageCard
                title="Org Plus"
                titleSuffix="(1 Year Subscription)"
                pillText="Org + Upgrades"
                subtitle="Includes Administrator and Teacher Accounts"
                ctaText="Request A Quote"
                action={() => window.open('https://share.hsforms.com/1-fMew_3gTyu6xehdKOSrLg5gbsq')}
                content={packageFeatures.enterprise.premium.features}
              />
            </Col>
          </Row>
        </S.ModalBody>
      </Modal>

      <FreeUserModal isOpen={isFreeUserSignupModalOpen} onClose={() => setIsFreeUserSignupModalOpen(false)} />
    </S.Container>
  );
};

export default SubscriptionPackageSelectionPublicPage;
