import styled from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NewItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 12px;
  font-weight: 500;
  cursor: pointer;
`;

export const TagColor = styled.div<{ color: string }>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

export const TagsContainer = styled.div<{ marginTop: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.marginTop}px;

  & > div {
    width: 100%;

    p {
      margin-top: 4px;
      margin-bottom: 0;
    }
  }

  .spaced {
    margin-right: 8px;
    margin-bottom: 0px;
  }
`;
