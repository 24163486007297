import React from 'react';
import { Tooltip } from 'antd';
import { BiEdit } from 'react-icons/bi';
import { FiHelpCircle } from 'react-icons/fi';
import * as S from './styles';

interface IChangePasswordButtonProps {
  userSource: string;
  handleChangePassword: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void> | void;
}

const ChangePasswordButton = ({ userSource, handleChangePassword }: IChangePasswordButtonProps) => {
  if (userSource !== 'ADI') {
    return (
      <Tooltip title="Password editing is disabled for Clever, Classlink and Google Classroom users.">
        <FiHelpCircle style={{ marginLeft: 8 }} />
      </Tooltip>
    );
  }

  return (
    <S.EditButton
      data-cy="shared-changepassword-button-icon"
      type="primary"
      shape="circle"
      minHeight={24}
      icon={<BiEdit />}
      onClick={handleChangePassword}
    />
  );
};

export default ChangePasswordButton;
