import React from 'react';
import { Line } from 'react-konva';

export interface IDrawingShapeProps {
  points: number[];
  color: string;
  strokeWidth: number;
}

interface IDrawingShape {
  shapeProps: IDrawingShapeProps;
}

export const DrawingShape = (props: IDrawingShape) => {
  const { shapeProps } = props;

  return (
    <Line
      points={shapeProps.points}
      stroke={shapeProps.color}
      strokeWidth={shapeProps.strokeWidth}
      tension={0.5}
      lineCap="round"
      globalCompositeOperation={'source-over'}
    />
  );
};
