import styled from 'styled-components';

export const Container = styled.div`
  padding: 35px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 6px;

  & > span {
    margin-bottom: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > div {
    display: flex;
    align-items: center;

    a {
      height: 26px;
    }
  }

  & > svg:last-child {
    color: ${(props) => props.theme.primaryColor.background};
    cursor: pointer;
  }
`;

export const SelectScreenContainer = styled.div`
  min-height: 280px;
  width: 100%;
  background-color: #efefef;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #767676;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  svg {
    margin-bottom: 10px;
    font-size: 30px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  p {
    margin: 0;
    margin-left: 25px;
  }
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

export const UploadTitle = styled.a`
  font-size: 12px;
  margin: 0px;
  float: right;
`;
