import { Col, Row as AntdRow, Popconfirm, Button as ButtonAntd } from 'antd';
import styled from 'styled-components';

export const Column = styled(Col)`
  padding: 40px !important;
  background-color: white;
  border-radius: 6px;
  margin-top: 40px;
  & > div {
    margin-bottom: 16px;
  }
`;

export const TitleRow = styled(AntdRow)`
  p {
    margin: 0;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;

    color: #767676 !important;
  }
  h1 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
    margin-right: 20px;
  }

  font-family: Rubik !important;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-rate {
    margin-left: 25px;
    font-size: 16px;
    margin-bottom: 3px;
    color: #fadb14 !important;
  }
`;

export const HelpButton = styled(ButtonAntd)<{
  $disabled?: boolean;
}>`
  border-color: ${(props) => (props.$disabled ? '#e0e7ff' : '#4367e9')} !important;
  background-color: ${(props) => (props.$disabled ? '#e0e7ff' : '#4367e9')} !important;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')} !important;

  & svg {
    transform: scale(0.75) !important;

    & path {
      fill: white;
    }
  }
`;

export const HelpPopConfirm = styled(Popconfirm)`
  & .ant-popover-buttons {
    text-align: center !important;
  }
`;

export const HelpPopConfirmParagraph = styled.p`
  margin: 0;
  text-align: center !important;
`;
