import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Papa from 'papaparse';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Dropdown, Menu, message, Row, Table } from 'antd';
import { gqlSchema } from '../../../gql/schema';
import { GQL_SendInviteResponse } from '../../../types/invites';

import Button from '../../../shared/Button';
import { centerAlign } from '../../../utils/antd';
import { themeConfig } from '../../../utils/theme';
import { GQL_ClassUserOrInvite, GQL_StudentResponse } from '../../../types/class';

import { FiChevronDown, FiDownload, FiSearch } from 'react-icons/fi';
import { downloadCsv } from '../../../utils/files';
import * as S from './styles';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import TableSearchBox from '../../../shared/TableSearchBox';

const PendingInvites = () => {
  // states
  const [pendingInvites, setPendingInvites] = useState<GQL_ClassUserOrInvite[]>([]);

  const [firstNameVisible, setFirstNameVisible] = useState<boolean>(false);
  const firstNameRef = useRef<HTMLInputElement>(null);

  const [lastNameVisible, setLastNameVisible] = useState<boolean>(false);
  const lastNameRef = useRef<HTMLInputElement>(null);

  const [emailVisible, setEmailVisible] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);

  // queries
  const { data, loading } = useQuery<{ getStudents: GQL_StudentResponse[] }>(
    gqlSchema.TeacherSchema.queries.STUDENTS.getStudents,
    {
      fetchPolicy: 'network-only',
      onError: (err) => message.error('There was an error loading your students: ' + err.message || 'Unexpected Error'),
    },
  );

  const [sendInvites] = useMutation<{ sendInvites: GQL_SendInviteResponse[] }>(
    gqlSchema.InvitesSchema.mutations.SEND.sendInvites,
    {
      onError: (err) => {
        message.error('There was an error trying to send the invite, please try again later');
      },
      update(cache, { data }) {
        if (data?.sendInvites) {
          const detailedNotification = data.sendInvites.length < 3;
          if (!detailedNotification) message.success(`Invites sent successfully`);

          data.sendInvites.forEach((std: GQL_SendInviteResponse) => {
            const student = {
              __typename: 'StudentResponse',
              inviteId: std.token,
              userId: null,
            };

            cache.modify({
              id: cache.identify(student),
              fields: {
                emailSent(cachedEmailSent, { readField }) {
                  if (std.emailSent && detailedNotification)
                    message.success(`Invite sent to ${readField('firstName')} ${readField('lastName')}`);
                  return std.emailSent;
                },
              },
            });
          });
        }
      },
    },
  );

  // callbacks
  const onResendInvite = useCallback(
    async (tokens: string[]) => {
      await sendInvites({
        variables: {
          data: {
            tokens,
          },
        },
      });
    },
    [sendInvites],
  );

  const onSendAllInvites = useCallback(() => {
    onResendInvite(
      pendingInvites?.reduce((result: string[], invite: GQL_ClassUserOrInvite) => {
        if (invite.inviteId) {
          result.push(invite.inviteId);
        }
        return result;
      }, []) || [],
    );
  }, [onResendInvite, pendingInvites]);

  const onExportInvites = useCallback(() => {
    const headers = [['First Name', 'Last Name', 'Email']];
    const csvData =
      pendingInvites.map((invite: GQL_ClassUserOrInvite) => {
        return [invite.firstName, invite.lastName, invite.email];
      }) || [];

    downloadCsv(Papa.unparse([...headers, ...csvData]), `Pending Invites.csv`);
  }, [pendingInvites]);

  // variables
  const columns = useMemo(
    () => [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        align: centerAlign,
        width: '25%',
        sorter: (a: GQL_ClassUserOrInvite, b: GQL_ClassUserOrInvite) => a.firstName.localeCompare(b.firstName),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={lastNameRef} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={lastNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_ClassUserOrInvite) => {
          if (!value) return true;
          return record.firstName.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setLastNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (lastNameRef && lastNameRef.current) {
                lastNameRef.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        align: centerAlign,
        width: '25%',
        sorter: (a: GQL_ClassUserOrInvite, b: GQL_ClassUserOrInvite) => a.lastName.localeCompare(b.lastName),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={firstNameRef} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={firstNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_ClassUserOrInvite) => {
          if (!value) return true;
          return record.lastName.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setFirstNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (firstNameRef && firstNameRef.current) {
                firstNameRef.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        align: centerAlign,
        width: '25%',
        sorter: (a: GQL_ClassUserOrInvite, b: GQL_ClassUserOrInvite) => a.email.localeCompare(b.email),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={emailRef} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={emailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_ClassUserOrInvite) => {
          if (!value) return true;
          return record.email.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (emailRef && emailRef.current) {
                emailRef.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Action',
        align: centerAlign,
        width: '25%',
        render: (text: string, record: GQL_ClassUserOrInvite) => {
          return <Button text="Resend Invite" onClick={() => onResendInvite([record?.inviteId || ''])} block />;
        },
      },
    ],
    [emailVisible, firstNameVisible, lastNameVisible, onResendInvite],
  );

  // render
  useEffect(() => {
    if (!data?.getStudents) {
      return;
    }

    const students = data?.getStudents.reduce((result: GQL_ClassUserOrInvite[], st: GQL_StudentResponse) => {
      if (!result.some((s: GQL_ClassUserOrInvite) => s.email === st.email) && st.inviteId)
        result.push({
          userId: st.userId,
          inviteId: st.inviteId,
          email: st.email,
          firstName: st.firstName,
          lastName: st.lastName,
          group: '',
          emailSent: st.emailSent,
        });
      return result;
    }, []);
    setPendingInvites(students);
  }, [data]);

  // render
  return (
    <Row gutter={[24, 24]}>
      <Col xxl={{ span: 3 }} xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 12 }}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={onExportInvites}>
                .csv
              </Menu.Item>
            </Menu>
          }
          arrow
          placement="bottomRight"
          trigger={['click']}
        >
          <Button
            padding="4px 5px"
            style={{ minWidth: '200px' }}
            disabled={pendingInvites.length === 0}
            text={
              <>
                Export as
                <FiChevronDown style={{ marginLeft: 3 }} />
              </>
            }
            theme={themeConfig.noColor}
            icon={<FiDownload />}
            block
          />
        </Dropdown>
      </Col>
      <Col
        xxl={{ span: 4, offset: 17 }}
        xl={{ span: 4, offset: 16 }}
        lg={{ span: 4, offset: 15 }}
        md={{ span: 5, offset: 13 }}
        sm={{ span: 5, offset: 7 }}
      >
        <Button
          text="Send All Invites"
          disabled={pendingInvites.length === 0}
          style={{ float: 'right', minWidth: '200px' }}
          theme={themeConfig.primaryColor}
          onClick={onSendAllInvites}
        />
      </Col>
      <Col style={{ paddingTop: 0 }} span={24}>
        <S.TableWrapper>
          <Table
            columns={columns}
            loading={loading}
            rowKey={(record: GQL_ClassUserOrInvite) => record.inviteId || ''}
            bordered
            dataSource={pendingInvites}
            scroll={{ x: 'max-content' }}
            sticky
          />
        </S.TableWrapper>
      </Col>
    </Row>
  );
};

export default PendingInvites;
