import React, { useEffect, useState } from 'react';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import Editor from '../../../../../shared/Editor';
import Spacer from '../../../../../shared/Spacer';
import { IOnChageEditor } from '../../../../../types';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  ITextContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';
import Radio from 'antd/lib/radio';

interface IMultipleChoiceQuestionEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const MultipleChoiceQuestionEditor = (props: IMultipleChoiceQuestionEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const selectedActivity = activities.find((a) => a.id === selectedActivityId);

  const [paragraphKeys, setParagraphKeys] = useState<(number | undefined)[]>(
    selectedActivity?.content[selectedContentIndex]?.blocks[selectedBlockIndex]?.values?.map(() => Math.random()) || [],
  );
  const [targetAnswerIndex, setTargetAnswerIndex] = useState<number>();

  useEffect(() => {
    // Loads the Target answer
    activities.forEach((activity) => {
      if (activity.id !== selectedActivityId) return activity;
      (editingRemote ? activity.content : activity.contentInPerson).forEach((content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;
        const newBlocks = [...content.blocks];
        const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
        selectedBlockValues.forEach((answer, answIdx) => {
          if (answer.targetAnswer) {
            setTargetAnswerIndex(answIdx);
          }
        });
      });
    });
  }, [activities, editingRemote, selectedActivityId, selectedBlockIndex, selectedContentIndex]);

  const handleAddAnswer = () => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex) return content;

          const newBlocks = [...(content?.blocks || [])];
          newBlocks[selectedBlockIndex].values = [...(newBlocks[selectedBlockIndex]?.values || [])];
          const newParagraphKeys = [...paragraphKeys];
          newParagraphKeys[newBlocks[selectedBlockIndex].values.length] = Date.now();
          setParagraphKeys(newParagraphKeys);
          newBlocks[selectedBlockIndex].values.push({ text: '', type: 'paragraph' } as ITextContentValue);

          return {
            title: content.title,
            blocks: newBlocks,
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleRemoveParagraph = (answerIndex: number) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex) return content;
          const newBlocks = [...(content?.blocks || [])];
          newBlocks[selectedBlockIndex].values = newBlocks[selectedBlockIndex]?.values || [];
          newBlocks[selectedBlockIndex].values.splice(answerIndex, 1);

          const newParagraphKeys = [...paragraphKeys];
          newParagraphKeys.splice(answerIndex, 1);
          setParagraphKeys(newParagraphKeys);

          return {
            title: content.title,
            blocks: newBlocks,
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditParagraph = (data: IOnChageEditor, paragraphIndex: number) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          const selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
          selectedBlockValues[paragraphIndex] = {
            ...selectedBlockValues[paragraphIndex],
            text: data.value,
            targetAnswer: paragraphIndex === targetAnswerIndex,
          };

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const onCheckAnswer = (paragraphIndex: number) => {
    setTargetAnswerIndex(paragraphIndex);
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;
          const newBlocks = [...content.blocks];
          let selectedBlockValues = [...(newBlocks[selectedBlockIndex].values as ITextContentValue[])];
          selectedBlockValues = selectedBlockValues.map((answer, answIdx) => ({
            ...answer,
            targetAnswer: answIdx === paragraphIndex,
          }));

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              else return { ...block, values: selectedBlockValues };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  return (
    <>
      <Radio.Group value={targetAnswerIndex} onChange={(e) => onCheckAnswer?.(e.target.value)}>
        {editingBlock?.values?.map((value, index) => (
          <React.Fragment key={paragraphKeys[index]}>
            <Spacer />
            {index && editingBlock?.values.length > 4 ? (
              <S.DeleteParagraphContainer onClick={() => handleRemoveParagraph(index)}>
                <div>
                  <h2>Answer {index}</h2>
                </div>
                <FiTrash2 />
              </S.DeleteParagraphContainer>
            ) : (
              <h2>{index ? `Answer ${index}` : 'Question'}</h2>
            )}
            {!!index && <Radio value={index}> Target answer</Radio>}
            <Editor
              fontSize={11}
              key={`bI:${selectedBlockIndex}pI:${index}`}
              value={(value as ITextContentValue).text}
              placeholder="Start writing the paragraph content..."
              onChange={(data) => handleEditParagraph(data, index)}
              showAlign
              showLink
            />
          </React.Fragment>
        ))}
      </Radio.Group>
      <Spacer />
      {editingBlock?.values.length < 7 && (
        <S.AddParagraphContainer onClick={handleAddAnswer}>
          <h2>Answers</h2>
          <FiPlus />
        </S.AddParagraphContainer>
      )}
    </>
  );
};

export default MultipleChoiceQuestionEditor;
