import { gql } from '@apollo/client';

export const CLASS = {
  investigationDashboard: gql`
    fragment NewInvestigation on InvestigationTeacherEntry {
      id
      title
      investigationId
      description
      dueDate
      grade
      completion
    }
  `,
  announcement: gql`
    fragment Announcement on ClassAnnouncementResponse {
      id
      createdAt
      classId
      text
    }
  `,
};

export default CLASS;
