import { useMutation } from '@apollo/client';
import { message } from 'antd';
import React, { useState } from 'react'
import { gqlSchema } from '../../../../gql/schema';
import Button from '../../../../shared/Button'


const AddBookCodes = ({refetchBook,shopifyProductId}:any) => {
  const [codes, setCodes] = useState<any>([])

  const [assignCodeToBooks,] = useMutation(gqlSchema.BookSchema.mutations.AssignCodeToBooks,{
    onError: (error) => {
      message.error(`Error in assigning codes to books - ${error.message || 'Unexpected Error'}`);
    }
  });

  const handleChange = (codes:string)=>{
    const newCodes = codes.split(/[\n,]+/)
    setCodes(newCodes)
  }
  const handleSubmit = async () => {
    try {
      const { data } = await assignCodeToBooks({
        variables: {
          codes: codes.join(','),
          shopifyProductId,
        },
      });
      if (data?.assignCodeToBooks?.message ==="Codes assigned successfully") {
        message.success('Codes assigned successfully');
        refetchBook();
      } 
    } catch (error) {
      console.error('Error assigning codes to books:', error);
    }
  };
  return (
    <div style={{ display:'flex', flexDirection:'column', gap:'20px',  alignItems:'center' }}>
     <textarea onChange={(e)=>handleChange(e.target.value)}placeholder="Enter your code here" style={{ width: '100%', height: '200px', border: '1px solid #ccc', padding: '8px', fontSize: '1rem', borderRadius: '4px' }} />
     <Button
          text={`Add Codes ${codes?.length?` :- ${codes.length}`:''}`}
          disabled={codes?.length?false:true}
          style={{ backgroundColor: '#4367E9', color: '#fff', width: "300px" }}
          onClick={() => handleSubmit()}
          block
        />
    </div>
  )
}

export default AddBookCodes
