/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import Spacer from '../../../../shared/Spacer';
import Select from '../../../../shared/Select';
import SelectOption from '../../../../shared/Select/Option';
import { GQL_InvestigationStandard } from '../../../../types/investigationStandard';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { INVESTIGATION_STANDARDS } from '../../../../types/standards';
import { message } from 'antd';
import { GQL_InvestigationCatalogEntry } from '../../../../types/investigation';
import { SelectValue } from 'antd/lib/select';
import { SUBJECT_TYPES } from '../../../../types/subjects';
import { sortTexasStandardsByCode } from '../../../../utils/utils';

type TexasStandardsSelectPartSettingProps = {
  currentStandards: GQL_InvestigationStandard[];
  assessment: GQL_InvestigationCatalogEntry;
  onStandardsChange: (values: GQL_InvestigationStandard[]) => void;
};

export const TexasStandardsSelectPartSetting = (props: TexasStandardsSelectPartSettingProps) => {
  const { assessment, onStandardsChange, currentStandards } = props;

  const [texasStandardListField, setTexasStandardList] = useState<GQL_InvestigationStandard[] | undefined>(
    currentStandards || [],
  );

  const { data: texasStandardsData, loading: loadingTexasStateStandards } = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: INVESTIGATION_STANDARDS.TEXAS,
    },
    onError: (error) => {
      message.error(`There was an error in fetching the Texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const compareSelectedGradeBandTexasStandardGradeAndDiscipline = useCallback(
    (
      standard: GQL_InvestigationStandard,
      selectedGradeBand: string | undefined,
      disciplineId: string | undefined,
    ): boolean => {
      const currentDiscipline = assessment?.discipline?.name;

      if (!currentDiscipline || !selectedGradeBand || !disciplineId) return false;

      // console.log('LLL', currentDiscipline, standard.discipline);
      // if (standard.discipline?.toLocaleLowerCase() !== currentDiscipline?.toLocaleLowerCase()) return false;
      const investigationType = assessment?.discipline?.subject;

      if (
        (investigationType === SUBJECT_TYPES.SCIENCE || investigationType === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION) &&
        selectedGradeBand === '9-12' &&
        !!currentDiscipline
      ) {
        return standard.grade === '9-12' && standard.discipline === currentDiscipline;
      }

      const pattern = /\b(?:Grade|School)\b/gi;
      const sanitizeBand = standard.grade.replace(pattern, '');
       
      let parsedStandardGrade = parseInt(sanitizeBand);
      if(isNaN(parsedStandardGrade)) parsedStandardGrade = 0;
      if (selectedGradeBand.includes('-')) {
        const [lowGrade, highGrade] = selectedGradeBand.split('-').map((value) => {
          const val = parseInt(value);
          if(isNaN(val)) {
            return 0;
          } else {
            return val;
          }
        });
        return lowGrade <= parsedStandardGrade && parsedStandardGrade <= highGrade;
      }

      let parsedSelectedGradeBand = parseInt(selectedGradeBand);
      if(isNaN(parsedSelectedGradeBand)) parsedSelectedGradeBand = 0;

      return parsedStandardGrade === parsedSelectedGradeBand;
    },
    [assessment],
  );

  const texasStandardList = useMemo(
    () =>
      texasStandardsData?.getStateStandards
        .filter((standard) =>
          compareSelectedGradeBandTexasStandardGradeAndDiscipline(
            standard,
            assessment?.discipline?.gradeBand,
            assessment?.discipline?.id,
          ),
        )
        .sort(sortTexasStandardsByCode),
    [texasStandardsData, compareSelectedGradeBandTexasStandardGradeAndDiscipline, assessment],
  );

  const handleStandardsChange = useCallback(
    (v: SelectValue) => {
      const source = texasStandardsData?.getStateStandards;

      const standards = (v as string[]).map((code) => {
        const standard = (source || []).find((standard) => standard.code === code);
        return {
          id: standard?.id,
          code: standard?.code,
          grade: standard?.grade,
        };
      });

      setTexasStandardList(standards as GQL_InvestigationStandard[]);

      if (onStandardsChange) {
        onStandardsChange(standards as GQL_InvestigationStandard[]);
      }
    },
    [texasStandardsData, onStandardsChange],
  );

  return (
    <>
      <Spacer />
      <h2>Texas Standards</h2>
      <Select
        placeholder="Texas Standards"
        optionLabelProp="label"
        showSearch={false}
        mode="multiple"
        dropdownMatchSelectWidth={false}
        showArrow
        loading={loadingTexasStateStandards}
        value={texasStandardListField?.map((standard) => standard.code)}
        onChange={(change) => handleStandardsChange(change)}
      >
        {texasStandardList?.map((standard) => (
          <SelectOption
            value={standard.code}
            label={standard.code}
            key={standard.id}
            style={{
              maxWidth: '400px',
            }}
          >
            {standard.code} - {standard.fullStatement}
          </SelectOption>
        ))}
      </Select>
    </>
  );
};
