/* eslint-disable max-lines */
/* eslint-disable complexity */
import { useQuery } from '@apollo/client';
import { Col, Row, Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { CgCalculator } from 'react-icons/cg';
import {
  FiAirplay,
  FiClipboard,
  FiColumns,
  FiCopy,
  FiEdit,
  FiHardDrive,
  FiImage,
  FiMessageSquare,
  FiServer,
  FiType,
  FiUploadCloud,
  FiUsers,
  FiVideo,
  FiYoutube,
  FiList,
  FiSlack,
} from 'react-icons/fi';
import { gqlSchema } from '../../../../gql/schema';
import Spacer from '../../../../shared/Spacer';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationBlockTypes,
  GQL_InvestigationBlockValues,
  GQL_InvestigationCatalogActivityEntry,
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationContent,
} from '../../../../types/investigation';
import * as S from '../styles';

interface IBlocksTab {
  selectedActivityId?: string;
  selectedContentIndex?: number;
  updateSelectedActivityField: (fieldName: string, value: any) => void;
  selectedActivity?: GQL_InvestigationCatalogActivityEntry;
  activities: GQL_InvestigationCatalogActivityEntry[];
  setSelectedBlockIndex: (index?: number) => void;
  selectedContent?: GQL_InvestigationContent;
  investigationId: string;
  stageId?: string;
  isRemoteInvestigation: boolean;
}

const BlocksTab = (props: IBlocksTab) => {
  const {
    selectedContentIndex,
    selectedActivity,
    updateSelectedActivityField,
    setSelectedBlockIndex,
    selectedContent,
    activities,
    selectedActivityId,
    investigationId,
    stageId,
    isRemoteInvestigation,
  } = props;

  const { data: investigationDraftData } = useQuery<
    { getInvestigationDraftById: GQL_InvestigationCatalogEntry },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationDraftById, {
    variables: {
      id: investigationId,
    },
    fetchPolicy: 'cache-only',
  });

  const investigationPublished = !investigationDraftData?.getInvestigationDraftById?.isDraft;
  const isAssessment = investigationDraftData?.getInvestigationDraftById.isAssessment || false;
  const blockDisabled = selectedContentIndex === undefined;
  const selectedActivityContentList = isRemoteInvestigation
    ? selectedActivity?.content
    : selectedActivity?.contentInPerson;

  const addBlockToContent = useCallback(
    (payload: { type: GQL_InvestigationBlockTypes; defaultValue?: GQL_InvestigationBlockValues[] }) => {
      if (selectedContentIndex === undefined || !selectedActivity || blockDisabled) return;

      const newBlock: GQL_InvestigationBlock = {
        type: payload.type,
        values: payload.defaultValue || [],
      };

      const newBlocks = selectedContent?.blocks ? [...selectedContent.blocks, newBlock] : [newBlock];

      if (isRemoteInvestigation) {
        updateSelectedActivityField(
          'content',
          selectedActivity.content.map((content, i) => {
            return i !== selectedContentIndex ? content : { ...content, blocks: newBlocks };
          }),
        );
      } else {
        updateSelectedActivityField(
          'contentInPerson',
          selectedActivity.contentInPerson.map((content, i) => {
            return i !== selectedContentIndex ? content : { ...content, blocks: newBlocks };
          }),
        );
      }

      setSelectedBlockIndex(newBlocks.length - 1);
    },
    [
      blockDisabled,
      isRemoteInvestigation,
      selectedActivity,
      selectedContent,
      selectedContentIndex,
      setSelectedBlockIndex,
      updateSelectedActivityField,
    ],
  );

  const canAddBlock = (payload: {
    type: 'PeerReview' | 'Reflection' | 'PeerReviewQuestionnaire' | 'ReflectionQuestionnaire' | 'BestConceptReflection';
    requirement: 'Upload' | 'PeerReview' | 'PeerReviewQuestionnaire' | 'BestConceptPeerReview';
  }): { canAdd: boolean; reason?: string } => {
    if (investigationPublished)
      return { canAdd: false, reason: "Can't add this block after investigation is already published" };

    if (selectedContent?.blocks?.some((b) => b.type === payload.type))
      return { canAdd: false, reason: 'You can only have 1 block of this type per stage' };
    const selectedActivityIndex = activities?.findIndex((a) => a.id === selectedActivityId);
    if (selectedActivityIndex === -1) return { canAdd: false };

    const slicedActivities = activities.slice(0, selectedActivityIndex);

    if (!slicedActivities.length) return { canAdd: false };
    if (
      slicedActivities.some((a) =>
        (isRemoteInvestigation ? a.content : a.contentInPerson)?.some((c) =>
          c.blocks?.some((b) => b.type === payload.type),
        ),
      )
    )
      return { canAdd: false, reason: 'You can only have 1 block of this type per activity' };

    return {
      canAdd: slicedActivities.some((a) =>
        (isRemoteInvestigation ? a.content : a.contentInPerson)?.some((c) =>
          c.blocks?.some((b) => b.type === payload.requirement),
        ),
      ),
    };
  };

  const canAddFinalSubmit = () => {
    const selectedStage =
      investigationDraftData?.getInvestigationDraftById?.steps
        ?.slice()
        ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)) || [];
    if (selectedStage[selectedStage.length - 1]?.id !== stageId) return { canAdd: false };

    return {
      canAdd: !activities?.some((a) =>
        (isRemoteInvestigation ? a.content : a.contentInPerson)?.some((c) =>
          c.blocks?.some((b) => b.type === 'UploadFinalReport'),
        ),
      ),
      reason: 'You can only have 1 block of this type per stage',
    };
  };

  const canAddConceptBlockFunc = ({ type }: { type: 'ConceptEvaluation' | 'ConceptRating' }) => {
    if (selectedContent?.blocks?.some((b) => b.type === type))
      return { canAdd: false, reason: 'You can only have 1 block of this type per stage' };

    const selectedActivityIndex = activities?.findIndex((a) => a.id === selectedActivityId) ?? 0;
    const previousActivity = activities[selectedActivityIndex - 1];
    if (!previousActivity)
      return { canAdd: false, reason: "You can't add this block on the first activity of the stage" };

    const previousActivities =
      type === 'ConceptEvaluation' ? [previousActivity] : activities.slice(0, selectedActivityIndex);

    for (const prevActivity of previousActivities) {
      const previousActivityContent = isRemoteInvestigation ? prevActivity.content : prevActivity.contentInPerson;
      const hasBudgetCalculator = previousActivityContent?.some((c) =>
        c.blocks?.some((b) => b.type === 'BudgetCalculator'),
      );
      const hasCanvas = previousActivityContent?.some((c) => c.blocks?.some((b) => b.type === 'Canvas'));

      if (hasCanvas && hasBudgetCalculator) return { canAdd: true };
    }

    return { canAdd: false, reason: 'You need to add a concept on a previous activity to add this block' };
  };

  const canAddGroupBestConcept = () => {
    const selectedStageIndex = investigationDraftData?.getInvestigationDraftById?.steps?.findIndex(
      (s) => s.id === stageId,
    );

    if (selectedStageIndex === -1 || !selectedStageIndex) return { canAdd: false, reason: "Can't add to first stage" };

    const previousStage = investigationDraftData?.getInvestigationDraftById?.steps[selectedStageIndex - 1];
    const hasConceptRating = previousStage?.activities?.some((a) =>
      (isRemoteInvestigation ? a.content : a.contentInPerson)?.some((c) =>
        c.blocks?.some((b) => b.type === 'ConceptRating'),
      ),
    );

    if (!hasConceptRating) {
      return {
        canAdd: false,
        reason: 'You need to add a ConceptRating block on previous stage before adding this block',
      };
    }

    return { canAdd: true };
  };

  const { canAdd: canAddReflectionBlock, reason: cantAddReflectionReason } = canAddBlock({
    type: 'Reflection',
    requirement: 'PeerReview',
  });
  const { canAdd: canAddPeerReviewBlock, reason: cantAddPeerReviewReason } = canAddBlock({
    type: 'PeerReview',
    requirement: 'Upload',
  });
  const { canAdd: canAddReflectionQuestionnaireBlock, reason: cantAddReflectionQuestionnaireReason } = canAddBlock({
    type: 'ReflectionQuestionnaire',
    requirement: 'PeerReviewQuestionnaire',
  });
  const { canAdd: canAddPeerReviewQuestionnaireBlock, reason: cantAddPeerReviewQuestionnaireReason } = canAddBlock({
    type: 'PeerReviewQuestionnaire',
    requirement: 'Upload',
  });
  const { canAdd: canAddFinalSubmitBlock, reason: cantAddFinalSubmitBlockReason } = canAddFinalSubmit();
  const { canAdd: canAddConceptEvaluationBlock, reason: cantAddConceptEvaluationBlockReason } = canAddConceptBlockFunc({
    type: 'ConceptEvaluation',
  });
  const { canAdd: canAddConceptRatingBlock, reason: cantAddConceptRatingBlockReason } = canAddConceptBlockFunc({
    type: 'ConceptRating',
  });
  const { canAdd: canAddGroupBestConceptBlock, reason: cantAddGroupBestConceptBlockReason } = canAddGroupBestConcept();
  const { canAdd: canAddBestConceptReflectionBlock, reason: cantAddBestConceptReflectionBlockReason } = canAddBlock({
    type: 'BestConceptReflection',
    requirement: 'BestConceptPeerReview',
  });
  const canAddCommentBlock = !selectedActivityContentList?.some((c) => c?.blocks?.some((b) => b.type === 'Comment'));
  const canAddUploadBlock = !selectedActivityContentList?.some((c) =>
    c?.blocks?.some((b) => b.type === 'Upload' || b.type === 'Canvas'),
  );
  const canAddBudgetBlock = !selectedActivityContentList?.some((c) =>
    c?.blocks?.some((b) => b.type === 'BudgetCalculator'),
  );
  const canAddTextSubmitBlock = !selectedActivityContentList?.some((c) =>
    c?.blocks?.some((b) => b.type === 'TextSubmit'),
  );
  const canAddSortingBoardBlock = !selectedActivityContentList?.some((c) =>
    c?.blocks?.some((b) => b.type === 'SortingBoard'),
  );

  const submissionDisabledLabel = 'You can add only 1 submission block plus 1 text submission type per activity';
  const submitBlocks = [
    'MultipleChoicePictureQuestion',
    'MultipleChoiceQuestion',
    'CategoryDrag',
    'ProcessDragDropAnswer',
    'ProcessDragDropPictureAnswer',
    'CategoryDragPicture',
  ];
  const hasSubmitBlock = !selectedActivityContentList?.some((c) =>
    c?.blocks?.some((b) => submitBlocks.includes(b.type)),
  );

  const textImageBlock = useMemo(() => {
    return (
      <S.InvestigationComponent
        onClick={() =>
          addBlockToContent({
            type: 'TextImage',
            defaultValue: [{}, {}],
          })
        }
        disabled={blockDisabled}
      >
        <FiColumns />
        <Spacer />
        <h1>Text & Image</h1>
      </S.InvestigationComponent>
    );
  }, [addBlockToContent, blockDisabled]);

  const multipleChoiceBlock = useMemo(() => {
    return (
      <Tooltip title={!hasSubmitBlock ? submissionDisabledLabel : undefined}>
        <S.InvestigationComponent
          onClick={() =>
            hasSubmitBlock &&
            addBlockToContent({
              type: 'MultipleChoiceQuestion',
              defaultValue: [{}, {}, {}, {}, {}],
            })
          }
          disabled={blockDisabled || !hasSubmitBlock}
        >
          <FiList />
          <Spacer />
          <h1>Multiple Choice Question</h1>
        </S.InvestigationComponent>
      </Tooltip>
    );
  }, [addBlockToContent, hasSubmitBlock, blockDisabled]);

  const textSubmitionBlock = useMemo(() => {
    return (
      <Tooltip title={!canAddTextSubmitBlock ? 'You can add one block of this type per activity' : undefined}>
        <S.InvestigationComponent
          onClick={() =>
            canAddTextSubmitBlock &&
            addBlockToContent({
              type: 'TextSubmit',
              defaultValue: [
                {
                  text: '',
                },
              ],
            })
          }
          disabled={blockDisabled || !canAddTextSubmitBlock}
        >
          <FiHardDrive />
          <Spacer />
          <h1>Text Submission</h1>
        </S.InvestigationComponent>
      </Tooltip>
    );
  }, [addBlockToContent, canAddTextSubmitBlock, blockDisabled]);

  const multipleChoicePictureBlock = useMemo(() => {
    return (
      <Tooltip title={!hasSubmitBlock ? submissionDisabledLabel : undefined}>
        <S.InvestigationComponent
          onClick={() =>
            hasSubmitBlock &&
            addBlockToContent({
              type: 'MultipleChoicePictureQuestion',
              defaultValue: [{}, {}, {}, {}, {}],
            })
          }
          disabled={blockDisabled || !hasSubmitBlock}
        >
          <FiSlack />
          <Spacer />
          <h1>Multiple Choice Pictures</h1>
        </S.InvestigationComponent>
      </Tooltip>
    );
  }, [addBlockToContent, blockDisabled, hasSubmitBlock]);

  const simpleBlockIcon = (type: GQL_InvestigationBlockTypes) => {
    switch (type) {
      case 'Text':
        return <FiType />;
      case 'Image':
        return <FiImage />;
      case 'Video':
        return <FiYoutube />;
      case 'Simulation':
        return <FiAirplay />;
      case 'Button':
        return <FiServer />;
      default:
        break;
    }
  };

  const simpleBlockContent = (type: GQL_InvestigationBlockTypes) => {
    return (
      <S.InvestigationComponent onClick={() => addBlockToContent({ type })} disabled={blockDisabled}>
        {simpleBlockIcon(type)}
        <Spacer />
        <h1>{type}</h1>
      </S.InvestigationComponent>
    );
  };

  return !isAssessment ? (
    <Row gutter={[0, 16]} style={{ paddingBottom: 8 }}>
      <Col span={10} offset={1}>
        {simpleBlockContent('Text')}
      </Col>
      <Col span={10} offset={2}>
        {simpleBlockContent('Image')}
      </Col>
      <Col span={10} offset={1}>
        {simpleBlockContent('Video')}
      </Col>
      <Col span={10} offset={2}>
        <Tooltip title={!canAddCommentBlock ? 'You can add one block of this type per activity' : undefined}>
          <S.InvestigationComponent
            onClick={() => canAddCommentBlock && addBlockToContent({ type: 'Comment' })}
            disabled={blockDisabled || !canAddCommentBlock}
          >
            <FiMessageSquare />
            <Spacer />
            <h1>Comment Section</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        <Tooltip
          title={
            !canAddPeerReviewBlock
              ? cantAddPeerReviewReason ||
                'To add this block a previous activity in the same stage must have a File Upload or Screen Recording.'
              : undefined
          }
        >
          <S.InvestigationComponent
            onClick={() => canAddPeerReviewBlock && addBlockToContent({ type: 'PeerReview' })}
            disabled={blockDisabled || !canAddPeerReviewBlock}
          >
            <FiUsers />
            <Spacer />
            <h1>Peer Review</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={2}>
        <Tooltip
          title={
            !canAddReflectionBlock
              ? cantAddReflectionReason ||
                'To add this block a previous activity in the same stage must have a Peer Review.'
              : undefined
          }
        >
          <S.InvestigationComponent
            onClick={() => canAddReflectionBlock && addBlockToContent({ type: 'Reflection' })}
            disabled={blockDisabled || !canAddReflectionBlock}
          >
            <FiUsers />
            <Spacer />
            <h1>Reflection</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        <Tooltip title={!canAddUploadBlock ? 'You can add one block of this type per activity' : undefined}>
          <S.InvestigationComponent
            onClick={() => canAddUploadBlock && addBlockToContent({ type: 'Upload', defaultValue: [{ type: 'File' }] })}
            disabled={blockDisabled || !canAddUploadBlock}
          >
            <FiUploadCloud />
            <Spacer />
            <h1>File Submission</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={2}>
        <Tooltip title={!canAddUploadBlock ? 'You can add one block of this type per activity' : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              canAddUploadBlock && addBlockToContent({ type: 'Upload', defaultValue: [{ type: 'Recording' }] })
            }
            disabled={blockDisabled || !canAddUploadBlock}
          >
            <FiVideo />
            <Spacer />
            <h1>Screen Recording</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        <Tooltip
          title={
            !canAddPeerReviewQuestionnaireBlock
              ? cantAddPeerReviewQuestionnaireReason ||
                'To add this block a previous activity in the same stage must have a File Upload or Screen Recording.'
              : undefined
          }
        >
          <S.InvestigationComponent
            onClick={() => canAddPeerReviewQuestionnaireBlock && addBlockToContent({ type: 'PeerReviewQuestionnaire' })}
            disabled={blockDisabled || !canAddPeerReviewQuestionnaireBlock}
          >
            <FiClipboard />
            <Spacer />
            <h1>Peer Review Report</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={2}>
        <Tooltip
          title={
            !canAddReflectionQuestionnaireBlock
              ? cantAddReflectionQuestionnaireReason ||
                'To add this block a previous activity in the same stage must have a Peer Review Report.'
              : undefined
          }
        >
          <S.InvestigationComponent
            onClick={() => canAddReflectionQuestionnaireBlock && addBlockToContent({ type: 'ReflectionQuestionnaire' })}
            disabled={blockDisabled || !canAddReflectionQuestionnaireBlock}
          >
            <FiClipboard />
            <Spacer />
            <h1>Reflection Report</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        {simpleBlockContent('Simulation')}
      </Col>
      <Col span={10} offset={2}>
        {simpleBlockContent('Button')}
      </Col>
      <Col span={10} offset={1}>
        {textSubmitionBlock}
      </Col>
      <Col span={10} offset={2}>
        <Tooltip
          title={
            !canAddFinalSubmitBlock
              ? cantAddFinalSubmitBlockReason || 'You can only add a Final Report Submission on the last stage'
              : undefined
          }
        >
          <S.InvestigationComponent
            onClick={() =>
              canAddFinalSubmitBlock &&
              addBlockToContent({ type: 'UploadFinalReport', defaultValue: [{ type: 'File' }] })
            }
            disabled={blockDisabled || !canAddFinalSubmitBlock}
          >
            <FiUploadCloud />
            <Spacer />
            <h1>Final Report Submission</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        <Tooltip title={undefined}>
          <S.InvestigationComponent
            onClick={() => addBlockToContent({ type: 'Canvas', defaultValue: [{ count: 1, titles: [] }] })}
            disabled={blockDisabled}
          >
            <FiEdit />
            <Spacer />
            <h1>Canvas</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={2}>
        <Tooltip title={!canAddBudgetBlock ? 'You can add one block of this type per activity' : undefined}>
          <S.InvestigationComponent
            onClick={() => canAddBudgetBlock && addBlockToContent({ type: 'BudgetCalculator', defaultValue: [{}] })}
            disabled={blockDisabled || !canAddBudgetBlock}
          >
            <CgCalculator />
            <Spacer />
            <h1>Budget Calculator</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        <Tooltip title={!canAddSortingBoardBlock ? 'You can add one block of this type per activity' : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              canAddSortingBoardBlock &&
              addBlockToContent({
                type: 'SortingBoard',
                defaultValue: [
                  {
                    targets: [
                      { title: '', values: [] },
                      { title: '', values: [] },
                    ],
                  },
                ],
              })
            }
            disabled={blockDisabled || !canAddSortingBoardBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Sorting Board</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={2}>
        <Tooltip title={!canAddConceptEvaluationBlock ? cantAddConceptEvaluationBlockReason : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              canAddConceptEvaluationBlock &&
              addBlockToContent({
                type: 'ConceptEvaluation',
                defaultValue: [
                  {
                    question: {
                      subquestions: [],
                      title: 'Concept Evaluation',
                    },
                  },
                ],
              })
            }
            disabled={blockDisabled || !canAddConceptEvaluationBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Concept Evaluation</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        <Tooltip title={!canAddConceptRatingBlock ? cantAddConceptRatingBlockReason : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              canAddConceptRatingBlock &&
              addBlockToContent({
                type: 'ConceptRating',
                defaultValue: [
                  {
                    question: {
                      subquestions: [],
                      title: 'Rate concepts',
                    },
                  },
                ],
              })
            }
            disabled={blockDisabled || !canAddConceptRatingBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Concept Rating</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={2}>
        <Tooltip title={!canAddGroupBestConceptBlock ? cantAddGroupBestConceptBlockReason : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              canAddGroupBestConceptBlock &&
              addBlockToContent({
                type: 'GroupBestConcept',
                defaultValue: [],
              })
            }
            disabled={blockDisabled || !canAddGroupBestConceptBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Group Best Concept</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        <Tooltip title={!canAddGroupBestConceptBlock ? cantAddGroupBestConceptBlockReason : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              canAddGroupBestConceptBlock &&
              addBlockToContent({
                type: 'BestConceptPeerReview',
                defaultValue: [],
              })
            }
            disabled={blockDisabled || !canAddGroupBestConceptBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Best Concept Peer Review</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={2}>
        <Tooltip title={!canAddBestConceptReflectionBlock ? cantAddBestConceptReflectionBlockReason : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              canAddBestConceptReflectionBlock &&
              addBlockToContent({
                type: 'BestConceptReflection',
                defaultValue: [],
              })
            }
            disabled={blockDisabled || !canAddBestConceptReflectionBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Best Concept Reflection</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        {textImageBlock}
      </Col>
      <Col span={10} offset={2}>
        {multipleChoiceBlock}
      </Col>
      <Col span={10} offset={1}>
        {multipleChoicePictureBlock}
      </Col>
    </Row>
  ) : (
    <Row gutter={[0, 16]} style={{ paddingBottom: 8 }}>
      <Col span={10} offset={1}>
        {simpleBlockContent('Text')}
      </Col>
      <Col span={10} offset={2}>
        {simpleBlockContent('Image')}
      </Col>
      <Col span={10} offset={1}>
        {simpleBlockContent('Video')}
      </Col>
      <Col span={10} offset={2}>
        {textImageBlock}
      </Col>
      <Col span={10} offset={1}>
        {textSubmitionBlock}
      </Col>
      <Col span={10} offset={2}>
        {multipleChoiceBlock}
      </Col>
      <Col span={10} offset={1}>
        {multipleChoicePictureBlock}
      </Col>
      <Col span={10} offset={2}>
        <Tooltip title={!hasSubmitBlock ? submissionDisabledLabel : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              hasSubmitBlock &&
              addBlockToContent({
                type: 'CategoryDrag',
                defaultValue: [
                  {
                    targets: [
                      { title: '', values: [] },
                      { title: '', values: [] },
                    ],
                  },
                ],
              })
            }
            disabled={blockDisabled || !hasSubmitBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Category Drag & Drop</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        <Tooltip title={!hasSubmitBlock ? submissionDisabledLabel : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              hasSubmitBlock &&
              addBlockToContent({
                type: 'CategoryDragPicture',
                defaultValue: [
                  {
                    targets: [
                      { title: '', values: [] },
                      { title: '', values: [] },
                    ],
                  },
                ],
              })
            }
            disabled={blockDisabled || !hasSubmitBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Category Drag & Drop Picture</h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={2}>
        <Tooltip title={!hasSubmitBlock ? submissionDisabledLabel : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              hasSubmitBlock &&
              addBlockToContent({
                type: 'ProcessDragDropAnswer',
                defaultValue: [
                  {
                    targets: [{ title: '', values: [] }],
                  },
                ],
              })
            }
            disabled={blockDisabled || !hasSubmitBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Process Drag & Drop Answer </h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
      <Col span={10} offset={1}>
        <Tooltip title={!hasSubmitBlock ? submissionDisabledLabel : undefined}>
          <S.InvestigationComponent
            onClick={() =>
              hasSubmitBlock &&
              addBlockToContent({
                type: 'ProcessDragDropPictureAnswer',
                defaultValue: [
                  {
                    targets: [{ title: '', values: [] }],
                  },
                ],
              })
            }
            disabled={blockDisabled || !hasSubmitBlock}
          >
            <FiCopy />
            <Spacer />
            <h1>Process Drag & Drop Picture Answer </h1>
          </S.InvestigationComponent>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default BlocksTab;
