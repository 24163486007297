import React, { ReactElement } from 'react';
import { NativeButtonProps } from 'antd/lib/button/button';

import * as S from './styles';
import { Combination } from '../../types/theme';
import { themeConfig } from '../../utils/theme';
import { Popover } from 'antd';
import { RxQuestionMarkCircled } from 'react-icons/rx';

interface IButton extends NativeButtonProps {
  text?: string | ReactElement;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void> | void;
  loading?: boolean;
  minWidth?: number;
  padding?: string;
  minHeight?: number;
  background?: string;
  display?: string;
  fontColor?: string;
  suffix?: ReactElement;
  theme?: Combination;
  href?: string;
  target?: string;
  size?: 'large' | 'middle' | 'small';
  disabledReason?: React.ReactChild;
}

const Button = React.forwardRef((props: IButton, ref?: any) => {
  const {
    minWidth,
    minHeight,
    onClick,
    loading,
    shape,
    padding,
    display,
    theme,
    background,
    fontColor,
    suffix,
    disabled,
    ...rest
  } = props;
  const type = props.type || 'primary';

  const showDisabledReason = React.useMemo(() => props.disabled && !!props.disabledReason, [
    props.disabled,
    props.disabledReason,
  ]);

  const Wrapper = React.useMemo(() => {
    if (showDisabledReason) {
      return S.ButtonWrapper;
    } else {
      return React.Fragment;
    }
  }, [showDisabledReason]);

  return (
    <Wrapper key={`wrapped-button-${props.text}-${shape}`}>
      <S.StyledButton
        type={type}
        $hasText={!!props.text}
        $theme={theme || themeConfig.primaryColor}
        $minWidth={minWidth}
        $minHeight={minHeight}
        $background={background}
        $shape={shape}
        $display={display}
        $padding={padding}
        $disabled={disabled}
        onClick={!disabled ? onClick : () => {}}
        loading={loading}
        ref={ref}
        {...rest}
      >
        {suffix ? (
          <>
            {props.text}
            {suffix}
          </>
        ) : (
          props.text
        )}
      </S.StyledButton>

      {showDisabledReason ? (
        <>
          <Popover placement="topLeft" content={props.disabledReason}>
            <S.ReasonIcon>
              <RxQuestionMarkCircled style={{ height: '100%', width: '100%' }} />
            </S.ReasonIcon>
          </Popover>
        </>
      ) : null}
    </Wrapper>
  );
});

export default Button;
