import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { Combination } from '../../types/theme';

export const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  height: max-content;
`;

export const ReasonIcon = styled.div`
  display: inline-block;
  cursor: pointer;
  color: #4367e9;
  height: 24px;
  width: 24px;
`;

export const StyledButton = styled(AntdButton)<{
  $theme: Combination;
  $hasText?: boolean;
  $minWidth?: number;
  $minHeight?: number;
  $shape?: string;
  $display?: string;
  $padding?: string;
  $background?: string;
  $disabled?: boolean;
}>`
  color: ${(props) => props.$theme?.color} !important;
  background: ${(props) => props.$background || props.$theme?.background};
  border-color: ${(props) => props.$background || props.$theme?.border || props.$theme?.background} !important;
  ${(props) => props.$theme?.boxShadow && `box-shadow: ${props.$theme?.boxShadow} !important;`}
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: ${(props) => props.$padding || '2px 15px 3px 15px'};
  display: ${(props) => props.$display || 'flex'};
  border-radius: ${(props) => getBorderRadius(props.$shape || '')};
  ${(props) => props.$minWidth && 'min-width: ' + props.$minWidth + 'px;'}
  min-height: ${(props) => props.$minHeight || 35}px;
  ${(props) => props.$shape === 'circle' && 'padding: 0;'}
  ${({ $disabled }) => ($disabled ? 'cursor: default; opacity: 0.6;' : '')}

  &:hover, &:active {
    background: ${(props) => props.$background || props.$theme?.hover} !important;
    filter: brightness(92%);
  }

  &:focus {
    background: ${(props) => props.$background || props.$theme?.background || props.$theme?.hover} !important;
  }

  &:disabled {
    background: ${(props) => props.$background || props.$theme?.disabled} !important;
    border-color: ${(props) => props.$background || props.$theme?.border || props.$theme?.disabled} !important;
    pointer-events: none;
  }

  svg {
    ${(props) => props.$hasText && 'margin-right: 10px;'}
  }

  & > span {
    margin-top: 3px;
  }
`;

const getBorderRadius = (shape: string) => {
  switch (shape) {
    case 'circle':
      return '50%';
    case 'round':
      return '40px';
    default:
      return '3px';
  }
};
