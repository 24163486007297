import styled from 'styled-components';
import CButton from '../../../Button';

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const HoverCard = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5em;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Info} {
    font-size: 2rem;
    background-color: rgba(255, 255, 255, 1);
  }
`;

export const Card = styled.div<{ disabled: boolean }>`
  width: 100%;
  height: 100%;
  padding: 1.5em;
  border-radius: 4px;
  background: white;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .middle {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .end {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }

  .ant-skeleton-element {
    width: 100%;
  }

  ${HoverCard} {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    ${HoverCard} {
      opacity: 1;
    }
  }
`;

export const TimeLeft = styled.p<{
  $warning: boolean;
  $fontSize: string;
}>`
  font-size: 0.87em;
  color: ${(props) => (props.$warning ? '#c10000' : '#000000d9')};
  font-weight: 400;
  text-align: center;
  margin: 0;
  vertical-align: bottom;

  svg {
    margin: 0 15px;
    font-size: 1.35em;
    vertical-align: top;
  }
`;

export const ClassName = styled.span<{
  $color: string;
}>`
  background: ${(props) => props.$color};
  color: white;
  padding: 5px 25px;
  font-weight: 500;
  border-radius: 40px;
`;

export const Icon = styled.p`
  font-size: 3.5em;
  margin-bottom: 0;
  text-align: center;
`;

export const Title = styled.h3<{
  $align?: string;
}>`
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  font-weight: 500;
  margin-bottom: 0;
`;

export const Subtitle = styled.h4`
  text-align: left;
  font-weight: normal;
  color: #888;
  font-size: 12px;
  margin-top: 8px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
`;

export const DashboardButton = styled(CButton)`
  font-weight: 500;
`;
