import styled from 'styled-components';
import { getInvestigationBackgroundColor } from '../../utils/investigationLogos';

export const CardContainer = styled.div<{ subject: string }>`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;
  padding: 32px 40px;
  text-align center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  height: 100%;
  justify-content: space-between;

  & > div:not(.button-row) {
    background: ${(props) => getInvestigationBackgroundColor({ subject: props.subject })};
    border-radius: 50%;
    padding: 16px;
    height: 122px;
    width: 122px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    svg {
      height: 80px;
      width: 80px;
    }
  }

  & > h2 {
    margin-bottom: 12px;
    font-size: 24px;
  }

  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 129.1%;

    color: #767676;
    margin-bottom: 28px;
  }
`;
