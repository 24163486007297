import { AccountsSchema } from './Accounts';
import { InvestigationSchema } from './Investigation';
import { ClassSchema } from './Class';
import { InvitesSchema } from './Invites';
import { TeacherSchema } from './Teacher';
import { NotificationSchema } from './Notification';
import { StudentSchema } from './Student';
import { UserSchema } from './User';
import { OrganizationSchema } from './Organization';
import { ChatSchema } from './Chat';
import { WalkthroughSchema } from './Walkthrough';
import { TransferInviteSchema } from './TransferInvite';
import { HomeSchema } from './Home';
import { TagsSchema } from './Tags';
import { ImmersiveReaderSchema } from './ImmersiveReader';
import { ChartSchema } from './Chart';
import { ClassLinkSchema } from './ClassLink';
import { CleverSchema } from './Clever';
import { SyncSchema } from './Sync';
import { InvestigationStandardSchema } from './InvestigationStandard';
import { StatesSchema } from './States';
import { InvestigationFocusSchema } from './InvestigationFocus';
import { InvestigationFormatSchema } from './InvestigationFormat';
import { AdvancedSearchCorrelationSchema } from './AdvancedSearch.Correlation';
import { PaymentsSchema } from './Payments';
import { WhitelistedDomainSchema } from './WhitelistedDomain';
import { GoogleClassroomSchema } from './GoogleClassroom';
import { CanvasSchema } from './Canvas'
import { BookSchema } from './Book';
import {ShopifySchema} from './Shopify';

export const gqlSchema = {
  AccountsSchema,
  InvestigationSchema,
  ClassSchema,
  InvitesSchema,
  TeacherSchema,
  NotificationSchema,
  StudentSchema,
  UserSchema,
  OrganizationSchema,
  ChatSchema,
  WalkthroughSchema,
  TransferInviteSchema,
  HomeSchema,
  TagsSchema,
  ImmersiveReaderSchema,
  ChartSchema,
  ClassLinkSchema,
  CleverSchema,
  SyncSchema,
  InvestigationStandardSchema,
  StatesSchema,
  InvestigationFocusSchema,
  InvestigationFormatSchema,
  AdvancedSearchCorrelationSchema,
  PaymentsSchema,
  WhitelistedDomainSchema,
  GoogleClassroomSchema,
  CanvasSchema,
  BookSchema,
  ShopifySchema,
};
