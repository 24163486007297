/* eslint-disable complexity */
import { useMutation } from '@apollo/client';
import { message, Tooltip, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useCallback, useRef, useState } from 'react';
import { FiEye, FiEyeOff, FiVideo } from 'react-icons/fi';
import { gqlSchema } from '../../../../gql/schema';
import useTimer from '../../../../hooks/useTimer';
import Button from '../../../../shared/Button';

import { useReactMediaRecorder } from '../../../../shared/ReactMediaRecorder';
import Spacer from '../../../../shared/Spacer';
import { GQL_InvestigationActivity } from '../../../../types/investigation';
import { GQL_investigationTeacherSummary_PerStudent } from '../../../../types/teacher';
import * as S from './styles';

interface IInvestigationRecordScreen {
  stepId: string;
  activityId: string;
  fileUrl?: string;
  student?: GQL_investigationTeacherSummary_PerStudent;
  hidden?: boolean;
}

const InvestigationRecordScreen = (props: IInvestigationRecordScreen) => {
  const { stepId, activityId, fileUrl, student, hidden } = props;
  const {
    isScreenRecordingDisabled,
    disabledReason,
    mediaBlobUrl,
    mediaBlob,
    getMediaStream,
    previewStream,
    startRecording,
    stopRecording,
    clearBlobUrl,
    clearBlob,
  } = useReactMediaRecorder({
    screen: true,
    audio: true,
    onStopSharing: () => {
      message.warn('Lost connection to shared screen');
      stop();
    },
  });
  const studentName = student ? ` by ${student.firstName} ${student.lastName}` : '';
  const [file, setFile] = useState<RcFile | undefined>();

  const { start, time, stop, started, clearTimer } = useTimer({
    duration: 120,
    onEnd: stopRecording,
  });

  const video = useRef<HTMLVideoElement>(null);

  const [submitFile, { loading }] = useMutation<
    { submitInvestigationPlanFile: GQL_InvestigationActivity },
    { file: File; stepId: string; activityId: string }
  >(gqlSchema.InvestigationSchema.mutations.PLAN.submitFile, {
    onError: (err) => {
      message.error('There was an error uploading your file: ' + err.message);
    },
  });

  const [changeSubmissionVisibility] = useMutation<
    { changeVisibilityOfStudentSubmission: boolean },
    { stepId: string; studentId: string; activityId: string; hidden: boolean }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.changeVisibilityOfStudentSubmission, {
    onError: (err) => message.error(err.message),
  });

  const handleVisibilityChange = () => {
    if (student) {
      changeSubmissionVisibility({
        variables: {
          activityId,
          stepId,
          studentId: student?.userId,
          hidden: !hidden,
        },
      });
    }
  };

  const discardRecording = useCallback(() => {
    clearBlobUrl();
    clearBlob();
    setFile(undefined);
    clearTimer();
  }, [clearBlobUrl, clearBlob, clearTimer]);

  const startRecordAndTimer = useCallback(async () => {
    try {
      await startRecording();
      start();
    } catch (err) {
      message.error(
        'Cannot start recording screen, please make sure the browser has access to your microphone and a screen was correctly selected',
      );
    }
  }, [start, startRecording]);

  const startScreenShare = useCallback(async () => {
    try {
      await getMediaStream();
    } catch (err) {
      message.error(
        'Cannot start recording screen, please make sure the browser has access to your microphone and a screen was correctly selected',
        5,
      );
    }
  }, [getMediaStream]);

  if (video.current && !(video.current.srcObject as MediaStream)?.active) video.current.srcObject = previewStream;

  const formatTime = () => {
    return Math.floor(time / 60) + ':' + (time % 60).toString().padStart(2, '0');
  };

  const handleSubmitRecord = () => {
    let videoFile;
    if (mediaBlob) {
      videoFile = new File([mediaBlob], `recording${Date.now()}.mp4`, {
        type: 'video/mp4',
        lastModified: Date.now(),
      });
    } else if (file) videoFile = file;

    if (videoFile)
      submitFile({
        variables: {
          file: videoFile,
          stepId: stepId,
          activityId,
        },
      });
  };

  const beforeUpload = useCallback((file: RcFile) => {
    setFile(file);
    return false;
  }, []);

  const hasFileUrl = fileUrl && fileUrl !== '/';

  const buildFile = () => {
    if (mediaBlobUrl || hasFileUrl || file) {
      return (
        <div>
          <video
            src={(hasFileUrl && fileUrl) || (file && URL.createObjectURL(file)) || mediaBlobUrl || ''}
            controls
            width="100%"
          />
          {!hasFileUrl && (
            <S.SpaceBetweenContainer>
              <Button onClick={discardRecording} text="Delete Recording" disabled={loading} />
              <Button text="Submit Recording" loading={loading} onClick={handleSubmitRecord} />
            </S.SpaceBetweenContainer>
          )}
        </div>
      );
    } else if (!hidden) {
      return (
        <>
          <S.SelectScreenContainer onClick={startScreenShare}>
            <video
              ref={video}
              autoPlay
              loop
              width="100%"
              style={{ display: previewStream?.active ? undefined : 'none' }}
            />
            {!previewStream?.active && (
              <>
                <FiVideo />
                Click here to select the screen you want to record
              </>
            )}
          </S.SelectScreenContainer>
          <S.ButtonContainer>
            {started ? (
              <Button onClick={stop} text="Stop Recording" />
            ) : isScreenRecordingDisabled ? (
              <Tooltip title={disabledReason}>
                <div>
                  <Button onClick={startRecordAndTimer} disabled={isScreenRecordingDisabled} text="Start Recording" />
                </div>
              </Tooltip>
            ) : (
              <Button onClick={startRecordAndTimer} text="Start Recording" />
            )}
            <p>{formatTime()} / 2:00</p>
          </S.ButtonContainer>
        </>
      );
    } else {
      return (
        <>
          <Spacer size={48} />
          <p>This submission was hidden by your teacher and will be reviewed</p>
          <Spacer size={48} />
        </>
      );
    }
  };

  return (
    <S.Container>
      <S.SpaceBetweenContainer>
        <S.Title>
          <div>{hasFileUrl ? `Screen Recorded${studentName}` : 'Record Your Screen'} </div>
          {hasFileUrl && student && (
            <>
              <Tooltip
                title={
                  hidden ? 'Unhide this submission' : 'Hide this submission and remove peer reviews and reflections'
                }
              >
                {hidden ? <FiEye onClick={handleVisibilityChange} /> : <FiEyeOff onClick={handleVisibilityChange} />}
              </Tooltip>
            </>
          )}
        </S.Title>
        {!hasFileUrl && (
          <Upload beforeUpload={beforeUpload} accept="video/*" showUploadList={false}>
            <S.UploadTitle>Upload Video File</S.UploadTitle>
          </Upload>
        )}
      </S.SpaceBetweenContainer>
      {buildFile()}
    </S.Container>
  );
};

export default InvestigationRecordScreen;
