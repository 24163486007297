import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Empty } from 'antd';
import Item from './Item';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import * as S from './styles';
import Search from 'antd/lib/input/Search';

interface IClassAssignmentsRow {
  classId: string;
  shouldLoad?: boolean;
  loaded?: () => void;
}

const ClassAssignmentsRow: React.FC<IClassAssignmentsRow> = ({ classId, shouldLoad, loaded }) => {
  const [sortedAssignments, setSortedAssignments] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const { data, loading: assignmentsLoading } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasCoursesListByUserWithAssignment,
    {
      variables: { page: String(currentPage) },
    }
  );

  useEffect(() => {
    if (!assignmentsLoading && data && data.getCanvasCoursesListByUserWithAssignment) {
      const assignments = data.getCanvasCoursesListByUserWithAssignment.flatMap((courseWithAssignments: any) => {
        const courseId = courseWithAssignments.canvasCourses?.id;
        const courseName = courseWithAssignments.canvasCourses?.name;
        return (courseWithAssignments.canvasAssignment || []).map((assignment: any) => ({
          ...assignment,
          courseId,
          courseName,
        }));
      });

      const sortedList = assignments.sort((a: any, b: any) => {
        if (a.due_at && b.due_at) {
          return new Date(b.due_at).getTime() - new Date(a.due_at).getTime();
        } else {
          return 0;
        }
      });

      setSortedAssignments(sortedList);
      loaded && loaded();
    }
  }, [assignmentsLoading, data, loaded, currentPage]);

  const filteredAssignments = useMemo(() => {
    if (!searchText) {
      return sortedAssignments;
    }
    return sortedAssignments.filter((item: any) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [sortedAssignments, searchText]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    console.log('Resize detected:', window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const activeAndExtraData = useMemo(() => {
    return filteredAssignments.map((item: any) => (
      <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24} key={item.id} style={{ marginBottom: '16px' }}>
        <Item
          description={item.description}
          timeLeft={new Date(item.due_at).getTime()}
          assignmentName={item.name}
          assignmentId={item.id}
          progress={0}
          timeWarning={false}
          courseId={item.courseId}
          icon={<img src={AssignmentIcon} alt="Assignment Icon" />}
          version={1}
          workType={item.submission_types?.join(', ') || 'N/A'}
          className={item.courseName || 'No course name'}
        />
      </Col>
    ));
  }, [filteredAssignments]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <Search
          type="text"
          placeholder="Search Assignments"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: isMobile ? 'auto' : 'calc(30% - 100px)', marginRight: '10px' }}
        />
      </div>
      <S.PaginationWrapper>
        <S.ArrowButton
          icon={<LeftOutlined />}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        />
        <S.ArrowButton
          icon={<RightOutlined />}
          onClick={handleNextPage}
        />
      </S.PaginationWrapper>
      {assignmentsLoading ? (
        <Row gutter={[24, 24]}>
          {[...Array(3)].map((_, index) => (
            <Col key={index} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      ) : filteredAssignments.length ? (
        <Row gutter={[24, 24]}>
          {activeAndExtraData}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Assignments" />
      )}
    </div>
  );
};

export default ClassAssignmentsRow;
