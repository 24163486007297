import { Col, Popover, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';
import Button from '../../../shared/Button';

import * as S from './styles';

import { themeConfig } from '../../../utils/theme';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onDelete: () => void;
  onSetTeacher?: () => void;
  confirmMessageVisible: boolean;
  setConfirmMessageVisible: () => void;
}

const ConfirmDeleteAdminMessage = (props: Props) => {
  const { onDelete, onSetTeacher, confirmMessageVisible, setConfirmMessageVisible, children } = props;

  const handleVisibleChange = useCallback(() => {
    if (confirmMessageVisible) {
      setConfirmMessageVisible();
    }
  }, [confirmMessageVisible, setConfirmMessageVisible]);

  const renderConfirmMessage = useMemo(
    () => (
      <S.ConfirmMessageBody>
        <Row gutter={8}>
          <Col span={24}>
            <S.Info>This user is not a teacher, do you want to set the teacher role or delete the user?</S.Info>
          </Col>
        </Row>{' '}
        <Row gutter={8} justify="center" style={{ marginTop: 15 }}>
          <Col span={8}>
            <Button text="Cancel" theme={themeConfig.noColor} block onClick={setConfirmMessageVisible} />
          </Col>
          <Col span={8}>
            <Button
              text="Delete User"
              theme={themeConfig.error}
              block
              onClick={() => {
                onDelete();
              }}
            />
          </Col>
          {onSetTeacher && (
            <Col span={8}>
              <Button
                text="Set Teacher Role"
                theme={themeConfig.primaryColor}
                block
                onClick={() => {
                  onSetTeacher();
                }}
              />
            </Col>
          )}
        </Row>
      </S.ConfirmMessageBody>
    ),
    [onDelete, onSetTeacher, setConfirmMessageVisible],
  );

  return (
    <Popover
      content={renderConfirmMessage}
      trigger="click"
      visible={confirmMessageVisible}
      onVisibleChange={handleVisibleChange}
    >
      {children}
    </Popover>
  );
};

export default ConfirmDeleteAdminMessage;
