import { Popover, Row } from 'antd';
import React from 'react';
import ExpandableCard from '../../../shared/ExpandableCard';
import { formatDateTime } from '../../../utils/date';
import * as S from './styles';

interface IPeerReviewWalkthrough {
  currentTip: number;
  popoverContent?: React.ReactNode;
}

const PeerReviewWalkthrough = (props: IPeerReviewWalkthrough) => {
  const { currentTip, popoverContent } = props;

  return (
    <>
      <h1>Peer review the investigation plans</h1>
      <p>
        Read two plans by people in your group and give them some ideas about what they can do to make their plans
        better.
      </p>

      <S.Column span={24}>
        <S.TitleRow align="middle" justify="space-between">
          <Row align="middle">
            <h1>Your Peer Reviews</h1>
            <p>Due by {formatDateTime(Date.now())}</p>
          </Row>
          <p>1 / 3 Completed</p>
        </S.TitleRow>
        <ExpandableCard
          title="Peer Review 1"
          description={`You have completed this peer review on ${formatDateTime(Date.now() - 24 * 60 * 60 * 1000)}`}
          completed
        />
        <Popover content={popoverContent} visible={currentTip === 3} placement="left">
          <ExpandableCard title="Peer Review 2" description="You have not completed this peer review." />
        </Popover>
        <ExpandableCard title="Peer Review 3" description="You have not completed this peer review." />
      </S.Column>
    </>
  );
};

export default PeerReviewWalkthrough;
