import React from 'react';
import { Row, Col, Empty } from 'antd';
import startCase from 'lodash/startCase';
import Item from './Item';
import { formatDateTime } from '../../../utils/date';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg'; // Import the AssignmentIcon

interface IFinishedAssignments {
  assignments: any[];
  loading: boolean;
  courseData: any[]; // Placeholder for course information
}

const FinishedAssignments: React.FC<IFinishedAssignments> = ({ assignments, loading, courseData }) => {
  // Function to map courseId to courseName

  const renderAssignments = () => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          {[1, 2, 3].map((index) => (
            <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      );
    } else if (!assignments?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Finished Assignments" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {assignments.slice(0, 99).map((item: any, index) => {
            const courseName = item.courseName || 'Course Name';

            return (
              <Col key={`${item.id}-${index}`} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
                <Item
                  date={formatDateTime(item.creationTime)}
                  assignmentName={`${startCase(item.assignment_name?.toLowerCase())}`}
                  assignmentId={item.assignment_id}
                  assignment={item}
                  courseId={item.course_id || 'Class ID'} // Show courseId or fallback
                  className={<strong>{courseName}</strong>} // Show courseName
                  courseWorkId={item.courseWorkId}
                  icon={<img src={AssignmentIcon} alt="Assignment Icon" style={{ width: 24, height: 24 }} />} // Pass the AssignmentIcon here
                  courseWorkType={item.courseWorkType ?? 'Assignment'}
                  courseData={courseData}
                />
              </Col>
            );
          })}
        </Row>
      );
    }
  };

  return renderAssignments();
};

export default FinishedAssignments;
