import { SelectValue } from 'antd/lib/select';
import { useQuery } from '@apollo/client';
import { message } from 'antd';
import React, { useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import PageWithTitle from '../../../shared/PageWithTitle';
import * as S from './styles';
import AssessmentModulePicker from './AssessmentModulePicker';
import { GQL_InvestigationDisplay, GQL_InvestigationTeacherEntry } from '../../../types/investigation';

type Props = RouteComponentProps<{
  assessmentId: string;
}>;

const AssessmentResultsSummary: React.FC<Props> = (props) => {
  const { assessmentId } = props.match.params;
  const { isTeacherOrFacilitator, user} = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const [step, setStep] = useState(0);

  const { data: assessmentForTeacher } = useQuery<
    { getInvestigationByIdForTeacher: GQL_InvestigationDisplay & GQL_InvestigationTeacherEntry },
    { investigationId: string; classId: string } | { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationByIdForTeacher, {
    variables: { id: assessmentId },
    fetchPolicy: 'network-only',
    onError: (err) => {
      message.error('There was an error loading the assessment: ' + err.message || 'Unexpected Error');
    },
  });

  const teacherAssessmentSummary = assessmentForTeacher?.getInvestigationByIdForTeacher;

  const selectedPart = useMemo(() => {
    return teacherAssessmentSummary?.steps[0].activities[step];
  }, [teacherAssessmentSummary, step]);

  const changeStep = (value: SelectValue) => {
    setStep(value as number);
  };

  return (
    <>
      <PageWithTitle
        title={
          <S.TitleContainer>{teacherAssessmentSummary ? teacherAssessmentSummary.title : 'Results'}</S.TitleContainer>
        }
        extra={
          (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) ? (
            <Select placeholder="Select An Activity" value={step} onChange={changeStep}>
              {teacherAssessmentSummary?.steps[0]?.activities?.map((_, index) => (
                <SelectOption value={index} key={index}>
                  {`Part ${index + 1}`}
                </SelectOption>
              ))}
            </Select>
          ) : null
        }
        backPageUrl={`/teacher-dashboard/assessment-summary/${assessmentId}`}
      >
        {selectedPart && <AssessmentModulePicker part={selectedPart} assessmentId={assessmentId} partIndex={step} />}
      </PageWithTitle>
    </>
  );
};

export default withRouter(AssessmentResultsSummary);
