/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { Col, Form as AntdForm, message, Row, Popconfirm, Tooltip, Divider } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { GQL_UserDetailsResponse } from '../../../types/profile';
import Form from '../../../shared/Form';
import * as S from './styles';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { useForm } from 'antd/lib/form/Form';
import RangePicker from '../../../shared/RangePicker';
import { formatDateTime, toDateFormat } from '../../../utils/date';
import { GQL_SubscriptionResponse } from '../../../types/subscription';
import Input from '../../../shared/Input';
import LinkedSubscriptions from '../../../shared/LinkedSubscriptions';
import { GQL_UserDetailsResponsePaginated, GQL_UsersListFilterInput } from '../../../types/user';
import Switch from '../../../shared/Switch';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import { FiHelpCircle } from 'react-icons/fi';
import { GQL_Discipline, GQL_InvestigationMetadataCodebook } from '../../../types/investigation';
import Button from '../../../shared/Button';
import Spacer from '../../../shared/Spacer';
import { MdCancel } from 'react-icons/md';
import { useAuth } from '../../../hooks/useAuth';
import { GQL_MeResponse } from '../../../types/login';
import { SUBJECT_TYPES } from '../../../types/subjects';
import useFetchStatesData from '../../../hooks/useFetchStatesData';
import { GQL_State } from '../../../types/state';
import { SelectValue } from 'antd/lib/select';
import { subjects, grades, gradeBands } from '../../../utils/constants';
import { useEnabledAssessments } from '../../../hooks/useEnabledAssessments';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { themeConfig } from '../../../utils/theme';
import { isArray } from 'lodash';
import { gradeCleanupRegex } from '../../../utils/gradeBandCompare';

interface Props {
  user?: GQL_UserDetailsResponse;
}

const Subscription: React.FC<Props> = (props) => {
  const { user } = props;
  const { user: ownUser, updateUser } = useAuth();
  const [changeSubscriptionVisible, setChangeSubscriptionVisible] = useState(false);
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(
    user?.subscription ? 'standard' : 'facilitator',
  );
  const [changeLicensesVisible, setChangeLicensesVisible] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [readerPermissions, setReaderPermissions] = useState(false);
  const [teachersCanDoReviews, setTeachersCanDoReviews] = useState(false);
  const [taCustomPermissions, setTaCustomPermissions] = useState(false);
  const [editingDisciplines, setEditingDisciplines] = useState(false);
  const [allowedDisciplines, setAllowedDisciplines] = useState<number[]>([]);
  const [editingStateStandards, setEditingStateStandards] = useState(false);
  const [allowedStates, setAllowedStates] = useState<GQL_State[]>([]);
  const [editingMaxClasses, setEditingMaxClasses] = useState(false);
  const [maxClasses, setMaxClasses] = useState<number>(0);
  const [form] = useForm();
  const standardSubscription = user?.subscription;
  const facilitatorSubscription = user?.facilitatorSubscription;
  const activeSubscription = selectedSubscriptionType === 'standard' ? standardSubscription : facilitatorSubscription;
  const shouldShowExtraFields = selectedSubscriptionType === 'standard' && !standardSubscription?.organizationId;

  const [subscriptionDurationStandard, setSubscriptionDurationStandard] = useState<{
    startDate: string;
    endDate: string;
  }>();
  const [subscriptionDurationFacilitator, setSubscriptionDurationFacilitator] = useState<{
    startDate: string;
    endDate: string;
  }>();

  useEffect(() => {
    if (!activeSubscription) {
      message.error('This user does not have an active subscription');
      Sentry.captureException(`User does not have a valid subscription - ${user?.id}`);
    }
  }, [activeSubscription, user]);

  useEffect(() => {
    if (!standardSubscription && selectedSubscriptionType === 'standard') {
      setSelectedSubscriptionType('facilitator');
    } else if (!facilitatorSubscription && selectedSubscriptionType === 'facilitator') {
      setSelectedSubscriptionType('standard');
    }
  }, [selectedSubscriptionType, standardSubscription, facilitatorSubscription]);

  const { data: codebookData } = useQuery<{ getInvestigationMetadataCodebook: GQL_InvestigationMetadataCodebook }>(
    gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationMetadataCodebook,
  );
  const disciplineResponse = useMemo(() => codebookData?.getInvestigationMetadataCodebook?.disciplines || [], [
    codebookData,
  ]);
  const disciplines = useMemo(() => {
    const possibleSubjects = activeSubscription?.allowedSubjects?.length
      ? activeSubscription.allowedSubjects
      : subjects;
    const possibleGradeBands = activeSubscription?.allowedGradeBands?.length
      ? activeSubscription.allowedGradeBands
      : gradeBands;
    return disciplineResponse.filter(
      (d) => possibleSubjects.includes(d.subject) && possibleGradeBands.includes(d.gradeBand),
    );
  }, [disciplineResponse, activeSubscription]);

  const disciplineNamesDisplay = useMemo(() => {
    return (
      disciplines
        .filter((d) => allowedDisciplines?.includes(Number(d.id)))
        .map((d) => d.name)
        .join(', ') || 'No Disciplines Selected'
    );
  }, [allowedDisciplines, disciplines]);

  const subscriptionTitle = useMemo(() => {
    if (selectedSubscriptionType === 'facilitator') {
      return 'Facilitator';
    }
    if (activeSubscription?.organizationId) {
      return 'Organization';
    }
    return 'Teacher';
  }, [activeSubscription, selectedSubscriptionType]);

  const [fetchProfile] = useLazyQuery(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.me, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ me }: { me: GQL_MeResponse }) => {
      updateUser(me);
    },
    onError: (err) => {
      message.error('There was an error loading your data: ' + err.message || 'Unexpected Error');
    },
  });

  const [submitEditSubscription, { loading: loadingEditingSubscription }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.updateSubscription,
    {
      refetchQueries: [{ query: gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.me }],

      onCompleted: ({ updateSubscription }: { updateSubscription: boolean }) => {
        if (ownUser.id === user?.id) fetchProfile();
        if (updateSubscription) message.success(`${user?.name}'s subscription updated successfully`);
        else message.error(`Error updating subscription for ${user?.name}`);
      },
      onError: (error) => {
        message.error(error.message);
      },
      update(cache, { data }) {
        if (data?.updateSubscription) {
          const userResponse = {
            __typename: 'UserDetailsResponse',
            id: user?.id,
          };
          cache.modify({
            id: cache.identify(userResponse),
            fields: {
              subscription(cachedSubscription: GQL_SubscriptionResponse) {
                return {
                  ...cachedSubscription,
                  isTrial,
                  taCustomPermissions,
                };
              },
            },
          });
        }
      },
    },
  );

  const [submitEditGradeBand, { loading: loadingGradeBandSubmission }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.updateSubscriptionGradeBands,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: gqlSchema.UserSchema.queries.DETAILS.getUserDetails, variables: { data: { id: user?.id } } },
      ],
      onCompleted: ({ updateSubscriptionGradeBands }) => {
        // If you're editing yourself, refetch your own info, so you don't have to refresh the page
        if (ownUser.id === user?.id) fetchProfile();
        if (updateSubscriptionGradeBands) message.success(`${user?.name}'s subscription updated successfully`);
        else message.error(`Error updating subscription for ${user?.name}`);
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitEditSubjects, { loading: loadingSubjectsSubmission }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.updateSubscriptionSubjects,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: gqlSchema.UserSchema.queries.DETAILS.getUserDetails, variables: { data: { id: user?.id } } },
      ],
      onCompleted: ({ updateSubscriptionSubjects }) => {
        // If you're editing yourself, refetch your own info, so you don't have to refresh the page
        if (ownUser.id === user?.id) fetchProfile();
        if (updateSubscriptionSubjects) message.success(`${user?.name}'s subscription updated successfully`);
        else message.error(`Error updating subscription for ${user?.name}`);
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitEditDisciplines, { loading: loadingDisciplineSubmission }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.updateSubscriptionDisciplines,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: gqlSchema.UserSchema.queries.DETAILS.getUserDetails, variables: { data: { id: user?.id } } },
      ],
      onCompleted: ({ updateSubscriptionDisciplines }) => {
        // If you're editing yourself, refetch your own info, so you don't have to refresh the page
        if (ownUser.id === user?.id) fetchProfile();
        if (updateSubscriptionDisciplines) message.success(`${user?.name}'s subscription updated successfully`);
        else message.error(`Error updating subscription for ${user?.name}`);
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitEditMaxClasses, { loading: loadingMaxClassesSubmission }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.updateSubscriptionMaxClasses, 
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: gqlSchema.UserSchema.queries.DETAILS.getUserDetails, variables: { data: { id: user?.id } } }, 
      ],
      onCompleted: ({ updateSubscriptionMaxClasses }) => {
        if (ownUser.id === user?.id) fetchProfile()
        if (updateSubscriptionMaxClasses) message.success(`${user?.name}'s subscription updated successfully`);
        else message.error(`Error updating subscription for ${user?.name}`)
      },
      onError: (error) => {
        message.error(error.message)
      }
    }
  )

  // mutation for saving updated allowedStates data
  const [editUserAllowedStates] = useMutation(gqlSchema.UserSchema.mutations.EDIT.editUser, {
    onCompleted: ({ editUser }) => {
      // If you're editing yourself, refetch your own info, so you don't have to refresh the page
      if (ownUser.id === user?.id) fetchProfile();
      if (editUser) {
        message.success(`${user?.name}'s allowed states updated successfully.`);
      } else {
        message.error(`Error updating allowed states for ${user?.name}.`);
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  useEffect(() => {
    setAllowedDisciplines(activeSubscription?.allowedDisciplines || []);
    setAllowedStates(user?.userAllowedStates || []);
    setMaxClasses(activeSubscription?.maxClasses || 0)
  }, [activeSubscription, user]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        subscriptionData: {
          startDate: formatDateTime(activeSubscription?.startDate),
          endDate: formatDateTime(activeSubscription?.endDate),
        },
        invitesAvailable: activeSubscription?.invitesAvailable || 0,
      });
      setSubscriptionDurationStandard({
        startDate: formatDateTime(standardSubscription?.startDate),
        endDate: formatDateTime(standardSubscription?.endDate),
      });
      setSubscriptionDurationFacilitator({
        startDate: formatDateTime(facilitatorSubscription?.startDate),
        endDate: formatDateTime(facilitatorSubscription?.endDate),
      });
      setIsTrial(activeSubscription?.isTrial || false);
      setReaderPermissions(!!activeSubscription?.readerPermissions);
      setTeachersCanDoReviews(!!activeSubscription?.teachersCanDoReviews);
      setTaCustomPermissions(activeSubscription?.taCustomPermissions || false);
    }
  }, [form, user, activeSubscription, standardSubscription, facilitatorSubscription]);

  const editStandardSubscription = useCallback(
    (payload: {
      sendInvitesAvailable?: boolean;
      isTrial?: boolean;
      readerPermissions?: boolean;
      taCustomPermissions?: boolean;
      teachersCanDoReviews?: boolean;
    }) => {
      const values = form.getFieldsValue();

      submitEditSubscription({
        variables: {
          data: {
            id: activeSubscription?.id,
            userId: user?.id,
            startDate: new Date(toDateFormat(values.subscriptionData.startDate)).getTime(),
            endDate: new Date(toDateFormat(values.subscriptionData.endDate)).getTime(),
            invitesAvailable: payload.sendInvitesAvailable ? +values.invitesAvailable : 0,
            isTrial: payload.isTrial ?? isTrial,
            taCustomPermissions: payload.taCustomPermissions ?? taCustomPermissions,
            readerPermissions: payload.readerPermissions ?? readerPermissions,
            teachersCanDoReviews: payload.teachersCanDoReviews ?? teachersCanDoReviews,
          },
        },
      });
    },
    [
      form,
      isTrial,
      submitEditSubscription,
      user,
      taCustomPermissions,
      readerPermissions,
      teachersCanDoReviews,
      activeSubscription,
    ],
  );

  const handleCancelEditingDatesButton = useCallback(() => {
    if (selectedSubscriptionType === 'standard') {
      setSubscriptionDurationStandard({
        startDate: formatDateTime(standardSubscription?.startDate),
        endDate: formatDateTime(standardSubscription?.endDate),
      });
    } else {
      setSubscriptionDurationFacilitator({
        startDate: formatDateTime(facilitatorSubscription?.startDate),
        endDate: formatDateTime(facilitatorSubscription?.endDate),
      });
    }
    setChangeSubscriptionVisible(!changeSubscriptionVisible);
  }, [changeSubscriptionVisible, facilitatorSubscription, selectedSubscriptionType, standardSubscription]);

  const handleEditingDatesButton = useCallback(() => {
    if (changeSubscriptionVisible) {
      editStandardSubscription({});
    }
    setChangeSubscriptionVisible(!changeSubscriptionVisible);
  }, [changeSubscriptionVisible, editStandardSubscription]);

  const handleEditingLicensesButton = useCallback(() => {
    if (changeLicensesVisible) {
      editStandardSubscription({ readerPermissions: true });
    }
    setChangeLicensesVisible(!changeLicensesVisible);
  }, [changeLicensesVisible, editStandardSubscription]);

  const handleOkIsTrial = useCallback(() => {
    setIsTrial(!isTrial);
    editStandardSubscription({ isTrial: !isTrial });
  }, [isTrial, editStandardSubscription]);

  const handleOkChangeGradeBands = useCallback(
    (gradeBand: string) => {
      if (activeSubscription?.id) {
        const gradeBandExists = !!activeSubscription.allowedGradeBands?.find((gb) => gb === gradeBand);
        const newGradeBands = [...(activeSubscription.allowedGradeBands || [])];
        if (gradeBandExists) {
          newGradeBands.splice(newGradeBands.indexOf(gradeBand), 1);
        } else {
          newGradeBands.push(gradeBand);
        }
        submitEditGradeBand({ variables: { subscriptionId: activeSubscription?.id, gradeBands: newGradeBands } });
      }
    },
    [activeSubscription, submitEditGradeBand],
  );

  const handleOkChangeMaxClasses = useCallback(() => {
    if (activeSubscription?.id) {
      submitEditMaxClasses({
        variables: { subscriptionId: activeSubscription?.id, maxClasses }
      })
    }
  }, [activeSubscription, submitEditMaxClasses, maxClasses])

  const resetCustomGrades = useCallback(
    (subject, subjectList) => {
      if (
        subject === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION &&
        !subjectList.includes(SUBJECT_TYPES.SCIENCE_TEXAS_EDITION)
      ) {
        const removedCustomGradeBands =
          activeSubscription?.allowedGradeBands?.filter((grade) => {
            return !grades.includes(grade);
          }) || [];
        submitEditGradeBand({
          variables: { subscriptionId: activeSubscription?.id, gradeBands: removedCustomGradeBands },
        });
      }
    },
    [activeSubscription, submitEditGradeBand],
  );

  const handleOkChangeSubjects = useCallback(
    async (subject: string) => {
      if (activeSubscription?.id) {
        const subjectExists = !!activeSubscription.allowedSubjects?.find((sbj) => sbj === subject);
        const newSubjects = [...(activeSubscription.allowedSubjects || [])];
        if (subjectExists) {
          newSubjects.splice(newSubjects.indexOf(subject), 1);
        } else {
          newSubjects.push(subject);
        }
        await submitEditSubjects({ variables: { subscriptionId: activeSubscription?.id, subjects: newSubjects } });

        resetCustomGrades(subject, newSubjects);
      }
    },
    [activeSubscription, submitEditSubjects, resetCustomGrades],
  );

  const handleOkChangeDisciplines = useCallback(() => {
    if (activeSubscription?.id) {
      submitEditDisciplines({
        variables: { subscriptionId: activeSubscription?.id, disciplineIds: allowedDisciplines },
      });
    }
  }, [activeSubscription, submitEditDisciplines, allowedDisciplines]);

  const handleOkReaderPermissions = useCallback(() => {
    setReaderPermissions(!readerPermissions);
    editStandardSubscription({ readerPermissions: !readerPermissions });
  }, [readerPermissions, editStandardSubscription]);

  const handleOkTeacherReviews = useCallback(() => {
    setTeachersCanDoReviews(!teachersCanDoReviews);
    editStandardSubscription({ teachersCanDoReviews: !teachersCanDoReviews });
  }, [teachersCanDoReviews, editStandardSubscription]);

  const handleOkTaCustomPermissions = useCallback(() => {
    setTaCustomPermissions(!taCustomPermissions);
    editStandardSubscription({ taCustomPermissions: !taCustomPermissions });
  }, [taCustomPermissions, editStandardSubscription]);

  // save the selected allowed states in backend
  const handleOkAllowedStates = useCallback(() => {
    editUserAllowedStates({
      variables: {
        data: {
          allowedStates,
          id: user?.id,
        },
      },
    });
  }, [allowedStates, editUserAllowedStates, user]);

  // update local value of selected allowed states for teacher
  const handleAllowedStatesDropdownChange = (values: SelectValue) => {
    const selection = isArray(values) ? values : [values];
    const selectedAllowedStates = (selection as string[]).map((stateName) => {
      const state = statesData.find((state) => state.name === stateName);
      return {
        id: state?.id,
        name: state?.name,
        postalCode: state?.postalCode,
      } as GQL_State;
    });

    setAllowedStates(selectedAllowedStates);
  };

  const [submitFetchTeachers, { data: organizationTeachers }] = useLazyQuery<
    { getAllUsersByFilter: GQL_UserDetailsResponsePaginated },
    { data: GQL_UsersListFilterInput }
  >(gqlSchema.UserSchema.queries.LIST.getAllUsersByFilter, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      message.error('There was an error loading teachers: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    if (activeSubscription) {
      const organizationId = activeSubscription?.organizationId ?? activeSubscription?.organizationId;
      submitFetchTeachers({
        variables: {
          data: {
            organizationId,
            roles: ['teacher'],
          },
        },
      });
    }
  }, [submitFetchTeachers, activeSubscription]);

  const subscriptionEndDate = useMemo(() => {
    if (user?.stripeSubscription?.endDate) {
      return moment(user?.stripeSubscription?.endDate, 'x').toISOString();
    }

    return user?.subscription?.endDate || '';
  }, [user]);

  const RangeDate = useCallback(() => {
    const startDate =
      selectedSubscriptionType === 'standard'
        ? subscriptionDurationStandard?.startDate
        : subscriptionDurationFacilitator?.startDate;
    const endDate =
      selectedSubscriptionType === 'standard' ? subscriptionEndDate : subscriptionDurationFacilitator?.endDate;

    return (
      <S.SubText>
        {formatDateTime(startDate)}&nbsp;-&nbsp;{formatDateTime(endDate)}
      </S.SubText>
    );
  }, [selectedSubscriptionType, subscriptionDurationFacilitator, subscriptionDurationStandard, subscriptionEndDate]);

  // fetch list of states from backend
  const { statesData } = useFetchStatesData();

  const { subjectsList } = useEnabledAssessments({
    allowedSubjects: activeSubscription?.allowedSubjects || [],
  });
  
  const disciplinesList: Record<string, GQL_Discipline[]> = useMemo(
    () =>
      disciplines.reduce((prev, acc) => {
        prev[acc.subject] = prev[acc.subject] || [];
        prev[acc.subject].push(acc);
        return prev;
      }, Object.create(null)),
    [disciplines],
  );

  const showConvertToPaidUser = useMemo(() => {
    return !user?.organization && !user?.stripeSubscription;
  }, [user]);

  const handleConvertToPaidSubscription = () => {
    const userId = user?.id as string;

    if (userId) {
      convertToPaidSubscription({
        variables: {
          userId,
        },
      });
    }
  };

  const [convertToPaidSubscription, { loading: convertToPaidSubscriptionLoading }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.convertToPaidSubscription,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: gqlSchema.UserSchema.queries.DETAILS.getUserDetails, variables: { data: { id: user?.id } } },
      ],
      onCompleted: ({ convertToPaidSubscription }) => {
        if (convertToPaidSubscription) {
          message.success(`${user?.name}'s subscription updated successfully`);
        } else {
          message.error(`Error updating subscription for ${user?.name}`);
        }
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  return (
    <>
      <S.Body span={24}>
        <Row gutter={24} style={{ padding: '0 20px' }}>
          <S.Col span={24}>
            {user?.subscription && user?.facilitatorSubscription && (
              <div>
                <S.TitleInput>Select Subscription</S.TitleInput>

                <div style={{ width: 280, marginBottom: 16 }}>
                  <Select onChange={(t) => setSelectedSubscriptionType(t as string)} value={selectedSubscriptionType}>
                    <SelectOption value="standard">Standard Subscription</SelectOption>
                    <SelectOption value="facilitator">Facilitator Subscription</SelectOption>
                  </Select>
                </div>
              </div>
            )}
          </S.Col>

          <Form form={form}>
            <Row>
              <S.Col md={24} lg={8} style={{ padding: 12 }}>
                <S.TitleInput>{`${subscriptionTitle} Subscription Duration`}</S.TitleInput>
                <Row gutter={8}>
                  <Col span={17}>
                    <AntdForm.Item
                      name="subscriptionData"
                      rules={[
                        {
                          required: true,
                          message: 'Please select the subscription duration!',
                        },
                      ]}
                    >
                      {changeSubscriptionVisible ? (
                        <RangePicker
                          defaultValue={
                            selectedSubscriptionType === 'standard'
                              ? subscriptionDurationStandard
                              : subscriptionDurationFacilitator
                          }
                          onChange={(value) => {
                            if (selectedSubscriptionType === 'standard') {
                              setSubscriptionDurationStandard(value);
                            } else {
                              setSubscriptionDurationFacilitator(value);
                            }
                          }}
                          allowClear={false}
                        />
                      ) : (
                        <RangeDate />
                      )}
                    </AntdForm.Item>
                  </Col>

                  {showConvertToPaidUser && (
                    <Col span={17} style={{ marginTop: '150px' }}>
                      <Popconfirm
                        placement="topLeft"
                        title="To update this user account to a starter account, all of the user data (classes, students and investigations) will be deleted, Are you sure you want to move forward with converting this account? "
                        onConfirm={() => handleConvertToPaidSubscription()}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          theme={themeConfig.orangePrimary}
                          text={'Convert user to starter account'}
                          minHeight={40}
                          loading={convertToPaidSubscriptionLoading}
                        />
                      </Popconfirm>
                    </Col>
                  )}

                  <Col span={changeLicensesVisible ? 3 : 6} style={{ textAlign: 'right' }}>
                    <S.EditButton
                      type="primary"
                      shape="circle"
                      icon={changeSubscriptionVisible ? <BiCheck /> : <BiEdit />}
                      onClick={handleEditingDatesButton}
                    />
                  </Col>

                  {changeLicensesVisible && (
                    <Col span={changeLicensesVisible ? 3 : 0} style={{ textAlign: 'right' }}>
                      <S.CancelButton
                        type="ghost"
                        shape="circle"
                        icon={<MdCancel />}
                        onClick={handleCancelEditingDatesButton}
                      />
                    </Col>
                  )}
                </Row>
              </S.Col>
              {activeSubscription?.organizationId && (
                <S.Col md={24} lg={8}>
                  <S.TitleInput>Licenses</S.TitleInput>
                  <Row gutter={8}>
                    <Col span={18}>
                      <AntdForm.Item
                        name="invitesAvailable"
                        initialValue={1}
                        rules={[
                          {
                            required: true,
                            message: 'Please add licenses!',
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          min={1}
                          bordered={changeLicensesVisible}
                          disabled={!changeLicensesVisible}
                        />
                      </AntdForm.Item>
                    </Col>
                    <Col span={6} style={{ textAlign: 'center' }}>
                      <S.EditButton
                        type="primary"
                        shape="circle"
                        icon={changeLicensesVisible ? <BiCheck /> : <BiEdit />}
                        onClick={handleEditingLicensesButton}
                      />
                    </Col>
                  </Row>
                </S.Col>
              )}
              <S.Col md={24} lg={8}>
                <Row gutter={[0, 8]} style={{ padding: 8 }}>
                  <S.TitleInput style={{ marginLeft: 4 }}>{`${subscriptionTitle} Subscription Options`}</S.TitleInput>

                  <S.Col span={24}>
                    <Popconfirm
                      placement="topLeft"
                      title="Are you sure to change is Trial configuration?"
                      onConfirm={handleOkIsTrial}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Switch
                        loading={loadingEditingSubscription}
                        checked={isTrial}
                        value={isTrial}
                        type="text"
                        titleAlign="right"
                        title="Trial Subscription Permissions"
                      />
                    </Popconfirm>
                  </S.Col>

                  <S.Col span={24}>
                    <Popconfirm
                      placement="topLeft"
                      title="Are you sure to change Enhanced TA configurations?"
                      onConfirm={handleOkTaCustomPermissions}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Switch
                        loading={loadingEditingSubscription}
                        checked={taCustomPermissions}
                        value={taCustomPermissions}
                        type="text"
                        titleAlign="right"
                        title="Super TA Permissions"
                      />
                    </Popconfirm>
                  </S.Col>

                  <S.Col span={24}>
                    <Popconfirm
                      placement="topLeft"
                      title="Are you sure to change immersive reader permissions?"
                      onConfirm={handleOkReaderPermissions}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Switch
                        loading={loadingEditingSubscription}
                        checked={readerPermissions}
                        value={readerPermissions}
                        type="text"
                        titleAlign="right"
                        title="Immersive Reader Permissions"
                      />
                    </Popconfirm>
                  </S.Col>

                  <S.Col span={24}>
                    <Popconfirm
                      placement="topLeft"
                      title="Are you sure to change permission for teachers to complete reviews?"
                      onConfirm={handleOkTeacherReviews}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Switch
                        loading={loadingEditingSubscription}
                        checked={teachersCanDoReviews}
                        value={teachersCanDoReviews}
                        type="text"
                        titleAlign="right"
                        title="Super Teacher Permissions"
                      />
                    </Popconfirm>
                  </S.Col>
                </Row>
              </S.Col>
              {shouldShowExtraFields && (
                <>
                  <S.Col md={24} lg={8}>
                    <Row gutter={[0, 8]} style={{ padding: 8 }} align="middle">
                      <S.TitleInput style={{ marginLeft: 4, marginRight: 4 }}>Subscription Disciplines</S.TitleInput>
                      <Tooltip title="User under this subscription can only assign investigations of disciplines set below">
                        <FiHelpCircle size={18} color="#767676" />
                      </Tooltip>
                      <S.Col span={24}>
                        <S.DisciplineSelectorContainer>
                          {editingDisciplines ? (
                            <Select
                              placeholder="No Discipline"
                              showSearch
                              mode="multiple"
                              value={allowedDisciplines}
                              filterOption={(input, option) =>
                                option?.children?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
                              }
                              loading={loadingDisciplineSubmission}
                              allowClear
                              onChange={(v) => {
                                setAllowedDisciplines(v as number[]);
                              }}
                            >
                              {Object.keys(disciplinesList)?.map((subject, index) => (
                                <>
                                  <SelectOption value={subject} key={index} disabled>
                                    <strong>{subject}</strong>
                                  </SelectOption>
                                  {sortBy(disciplinesList[subject], ['name', 'gradeBand'])?.map((d) => (
                                    <SelectOption value={d.id} key={d.id}>
                                      {d.name} {d.gradeBand.replaceAll(gradeCleanupRegex, '')}
                                    </SelectOption>
                                  ))}
                                </>
                              ))}
                            </Select>
                          ) : (
                            disciplineNamesDisplay
                          )}
                          <Button
                            type="primary"
                            shape="circle"
                            minHeight={32}
                            minWidth={32}
                            style={{ marginLeft: 8 }}
                            icon={editingDisciplines ? <BiCheck /> : <BiEdit />}
                            onClick={() => {
                              if (editingDisciplines) {
                                handleOkChangeDisciplines();
                              }
                              setEditingDisciplines(!editingDisciplines);
                            }}
                          />

                          {editingDisciplines && (
                            <>
                              <Spacer axis="horizontal" size={4} />
                              <Button
                                type="primary"
                                shape="circle"
                                minHeight={32}
                                minWidth={32}
                                icon={<MdCancel />}
                                onClick={() => {
                                  setAllowedDisciplines(activeSubscription?.allowedDisciplines ?? []);
                                  setEditingDisciplines(false);
                                }}
                              />
                            </>
                          )}
                        </S.DisciplineSelectorContainer>
                      </S.Col>
                    </Row>
                  </S.Col>
                  <S.Col md={24} lg={8}>
                    <Row gutter={[0, 8]} style={{ padding: 8 }} align="middle">
                      <S.TitleInput style={{ marginLeft: 4, marginRight: 4 }}>Subscription Subjects</S.TitleInput>
                      <Tooltip title="User under this subscription can only assign investigations of subjects set below">
                        <FiHelpCircle size={18} color="#767676" />
                      </Tooltip>
                      {subjectsList.map((subject, index) => (
                        <S.Col span={24} key={subject.key}>
                          {subject.key === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION && (
                            <Divider style={{ margin: '34px 0 12px 0' }} />
                          )}

                          <Popconfirm
                            placement="topLeft"
                            title="Confirm changing subject configuration?"
                            onConfirm={() => handleOkChangeSubjects(subject.key)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Row align="middle">
                              <Switch
                                loading={loadingSubjectsSubmission}
                                checked={!!activeSubscription?.allowedSubjects?.includes(subject.key)}
                                value={!!activeSubscription?.allowedSubjects?.includes(subject.key)}
                                type="text"
                                titleAlign="right"
                                title={subject.label}
                                disabled={!subject.allowed}
                              />
                              {!subject.allowed && (
                                <Tooltip title="Upgrade your subscription to enable this assessment">
                                  <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                                </Tooltip>
                              )}
                            </Row>
                          </Popconfirm>
                        </S.Col>
                      ))}
                    </Row>
                  </S.Col>
                  <S.Col md={24} lg={8}>
                    <Row gutter={[0, 8]} style={{ padding: 8 }} align="middle">
                      <S.TitleInput style={{ marginLeft: 4, marginRight: 4 }}>Subscription Grade Levels</S.TitleInput>
                      <Tooltip title="User under this subscription can only assign investigations of grades set below">
                        <FiHelpCircle size={18} color="#767676" />
                      </Tooltip>
                      {gradeBands.map((gradeBand) => (
                        <S.Col span={24} key={gradeBand}>
                          <Popconfirm
                            placement="topLeft"
                            title="Confirm changing grade band configuration?"
                            onConfirm={() => handleOkChangeGradeBands(gradeBand)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Switch
                              loading={loadingGradeBandSubmission}
                              checked={!!activeSubscription?.allowedGradeBands?.includes(gradeBand)}
                              value={!!activeSubscription?.allowedGradeBands?.includes(gradeBand)}
                              type="text"
                              titleAlign="right"
                              title={`Grade ${gradeBand}`}
                            />
                          </Popconfirm>
                        </S.Col>
                      ))}
                    </Row>

                    <>
                      <Divider style={{ margin: '4px 0 4px 0' }} />

                      <Row gutter={[0, 8]} style={{ padding: 8 }} align="middle">
                        {grades.map((gradeBand) => (
                          <S.Col span={24} key={gradeBand}>
                            <Popconfirm
                              placement="topLeft"
                              title="Confirm changing grade band configuration?"
                              onConfirm={() => handleOkChangeGradeBands(gradeBand)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Switch
                                // disabled={texasEditionEnabled}
                                loading={loadingGradeBandSubmission}
                                checked={!!activeSubscription?.allowedGradeBands?.includes(gradeBand)}
                                value={!!activeSubscription?.allowedGradeBands?.includes(gradeBand)}
                                type="text"
                                titleAlign="right"
                                title={`Grade ${gradeBand.replace('tx.', '')}`}
                              />
                            </Popconfirm>
                          </S.Col>
                        ))}
                      </Row>
                    </>
                  </S.Col>

                  {/* Dropdown for selecting states for freelance teacher */}
                  <S.Col md={24} lg={8}>
                    <Row gutter={[0, 8]} style={{ padding: 8 }} align="middle">
                      <S.TitleInput style={{ marginLeft: 4, marginRight: 4 }}>State Standards</S.TitleInput>
                      <S.Col span={24}>
                        <S.DisciplineSelectorContainer>
                          <Select
                            placeholder="Select states"
                            disabled={!editingStateStandards}
                            showArrow={editingStateStandards}
                            bordered={editingStateStandards}
                            value={allowedStates.map((state) => state.name)}
                            onChange={handleAllowedStatesDropdownChange}
                          >
                            <SelectOption key={'empty-state-id'} value={''}>
                              {''}
                            </SelectOption>
                            {statesData.map((state) => (
                              <SelectOption value={state.name} key={state.name}>
                                {state.name}
                              </SelectOption>
                            ))}
                          </Select>

                          <Button
                            type="primary"
                            shape="circle"
                            minHeight={32}
                            minWidth={32}
                            style={{ marginLeft: 8 }}
                            icon={editingStateStandards ? <BiCheck /> : <BiEdit />}
                            onClick={() => {
                              if (editingStateStandards) {
                                handleOkAllowedStates();
                              }
                              setEditingStateStandards((previousValue) => !previousValue);
                            }}
                          />

                          {editingStateStandards && (
                            <>
                              <Spacer axis="horizontal" size={4} />
                              <Button
                                type="primary"
                                shape="circle"
                                minHeight={32}
                                minWidth={32}
                                icon={<MdCancel />}
                                onClick={() => {
                                  setAllowedStates(user?.userAllowedStates || []);
                                  setEditingStateStandards(false);
                                }}
                              />
                            </>
                          )}
                        </S.DisciplineSelectorContainer>
                      </S.Col>
                      <>
                        <Divider style={{ margin: '77px 0px -12px 0px' }} />
                        <Row gutter={[0, 8]} style={{ padding: 8, width: '100%' }} align="middle">
                          <S.TitleInput style={{ marginLeft: 4, marginRight: 4 }}>Maximum No. of Classes</S.TitleInput>
                          <S.Col span={24}>
                            <S.InputContainer>
                              {editingMaxClasses ? (
                                <Input 
                                  placeholder='Max No. of Classes'
                                  value={maxClasses}
                                  type='number'
                                  onChange={(v) => {
                                    setMaxClasses(v.target.valueAsNumber)
                                  }}
                                  disabled={loadingMaxClassesSubmission}
                                />
                              ): (
                                maxClasses
                              )}
                              
                              <Button
                                type="primary"
                                shape="circle"
                                minHeight={32}
                                minWidth={32}
                                style={{ marginLeft: 8 }}
                                icon={editingMaxClasses ? <BiCheck /> : <BiEdit />}
                                onClick={() => {
                                  if (editingMaxClasses) {
                                    handleOkChangeMaxClasses();
                                  }
                                  setEditingMaxClasses(!editingMaxClasses);
                                }}
                              />

                              {editingMaxClasses && (
                                <>
                                  <Spacer axis="horizontal" size={4} />
                                  <Button
                                    type="primary"
                                    shape="circle"
                                    minHeight={32}
                                    minWidth={32}
                                    icon={<MdCancel />}
                                    onClick={() => {
                                      setMaxClasses(activeSubscription?.maxClasses ?? 0);
                                      setEditingMaxClasses(false);
                                    }}
                                  />
                                </>
                              )}
                            </S.InputContainer>
                          </S.Col>
                        </Row>
                      </>
                    </Row>
                  </S.Col>
                </>
              )}
            </Row>
          </Form>
        </Row>
      </S.Body>

      {activeSubscription?.organizationId && (
        <Row>
          <Col span={24}>
            <LinkedSubscriptions
              subscription={activeSubscription ?? activeSubscription}
              teachers={organizationTeachers?.getAllUsersByFilter.users}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Subscription;
