import React, { useEffect, useState } from 'react';
import { Col, message, Pagination, Row } from 'antd';
import Button from '../../../../shared/Button';
import * as S from './styles';
import { SearchOutlined } from '@ant-design/icons';
import UpdateBookModal from './UpdateModal';
import { gqlSchema } from '../../../../gql/schema';
import { useLazyQuery, useMutation } from '@apollo/client';
import NoImageAvailble from '../../../../assets/no-image-available.png';
import LibrarySkeleton from '../../Library/LibrarySkeleton';



const BookInvestigations = ({ selectedInvestigations, refetchBook, bookId }: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchquery, setSearchQuery] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [investigationsData, setInvestigationData] = useState<any>([]);
  const [addedData, setAddedData] = useState<any>([]);
  const [removedData, setRemovedData] = useState<any>([]);
  const [totalInvestigations, setTotalInvestigations] = useState(0);

  const [fetchInvestigation,{loading: InvestigationLoading }] = useLazyQuery(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalogPaginated, {
    onCompleted: (data) => {
      setInvestigationData(data?.getInvestigationCatalogPaginated?.list);
      setTotalInvestigations(data?.getInvestigationCatalogPaginated?.total);
    },
    variables: {
      isAssessment: false,
      isTemplate: false,
      page: currentPage,
      pageSize: 12,
      searchText: searchquery
    },
  });
  const [assignInvestigationKitBundle] = useMutation(gqlSchema.BookSchema.mutations.AssignInvestigationKitBundle, {
    onCompleted:()=>{
      setIsModalVisible(false);
    },
    onError: (error: any) => {
      message.error(`Error in assigning investigation kit bundle - ${error.message || 'Unexpected Error'}`);
    },
  });

  useEffect(() => {
    fetchInvestigation(
      {
        variables: {
          isAssessment: false,
          isTemplate: false,
          page: currentPage,
          pageSize: 12,
          searchText: searchquery
        }
      }
    );

  }, [fetchInvestigation,currentPage, searchquery]);

  const handleInvestigationClick = (investigation: any) => {
    if (selectedInvestigations.find((i: any) => i.id === investigation.id)) {
      if(removedData.find((i:any)=>i.id === investigation.id)){
        setRemovedData(removedData.filter((i:any)=>i.id !== investigation.id));
        return
      }
      setRemovedData([...removedData, investigation]);
      setAddedData(addedData.filter((i: any) => i.id !== investigation.id));
    } else {
      if(addedData.find((i:any)=>i.id===investigation.id)){
        if (selectedInvestigations.find((i: any) => i.id === investigation.id)) {
        setRemovedData([...removedData, investigation]);}
        setAddedData(addedData.filter((i: any) => i.id !== investigation.id));
        return
      }
      setAddedData([...addedData, investigation]);
      setRemovedData(removedData.filter((i: any) => i.id !== investigation.id));
    }
  }
  const onSubmit = async () => {
    try {
      let finalData = selectedInvestigations.map((i:any)=>i.id);
      finalData = finalData.concat(addedData.map((i:any)=>i.id));
      finalData = finalData.filter((i:any)=>!removedData.find((j:any)=>j.id === i));

      const { data } = await assignInvestigationKitBundle({
        variables: {
          bookId,
          investigationIds: finalData,
        },
      });
      if (data.assignInvestigationKitBundle.success) {
        refetchBook();
        message.success('Investigations assigned successfully');
      } else {
        message.error(data.assignInvestigationKitBundle.message);
      }
    } catch (error) {
      console.error('Error assigning investigation kit bundle:', error);
    }
  };
  const handleInv = (investigationId: string) => { 
    if (selectedInvestigations.find((i: any) => i.id === investigationId)) {
      if(removedData.find((i:any)=>i.id === investigationId)){
        return false;
      }
      else{
        return true;
      }
     }
     else{
      if(addedData.find((i:any)=>i.id === investigationId)){
        if(addedData.find((i:any)=>i.id === investigationId)){
          return true
        }
        else{
          return false
        }
     }
  }}
  



  return (
    <S.Container>

      <S.FilterRow>
        <S.SearchContainer>
          <SearchOutlined
           style={{ position: 'absolute', backgroundColor: 'transparent', marginLeft: 10 }}
          />
          <S.SearchInput
            value={searchquery}
            type="search"
            placeholder="Search Investigation"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </S.SearchContainer>

        <Button
          text="Update"
          style={{ backgroundColor: '#4367E9', color: '#fff', width: 'auto', fontSize: '0.8rem', textTransform: 'uppercase' }}
          onClick={() => setIsModalVisible(true)}
          block
        />
      </S.FilterRow>

      <Row gutter={[16, 16]}>
        {InvestigationLoading?<LibrarySkeleton row={2}/>:investigationsData?.map((investigation: any, idx: number) => (
          <InvestigationCard key={idx} Investigation={investigation} text={handleInv(investigation.id) ? 'Remove' : 'Add'} handleInvestigationClick={() => handleInvestigationClick(investigation)} />
        ))}
      </Row>
      <UpdateBookModal addedData={addedData} removedData={removedData} isModalVisible={isModalVisible} setAddedData={setAddedData} setRemoveData={setRemovedData} closeModal={() => setIsModalVisible(false)} type="Investigation Update" onSubmit={onSubmit} />

      <S.PaginationContainer>
        <Pagination
          current={currentPage}
          total={totalInvestigations || 0}
          pageSize={12}
          onChange={(val) => setCurrentPage(val)}
        />
      </S.PaginationContainer>
    </S.Container>
  );
};

const InvestigationCard = ({ Investigation, text, handleInvestigationClick }: any) => {
  return (
    <Col key={Investigation.id} xs={24} sm={12} md={6}>
      <S.InvestigationCard>
        <S.ImageWrapper>
          <img src={Investigation?.imageUrl || NoImageAvailble} alt={Investigation.title} />
        </S.ImageWrapper>
        <S.InvestigationTitle>{Investigation.title}</S.InvestigationTitle>
        <Button
          text={text}
          style={{ backgroundColor: `${text === 'Add' ? '#4367E9' : '#df1818'}`, color: `${text === 'Add' ? '#fff' : '#4367E9'}` }}
          onClick={() => handleInvestigationClick()}
          block
        />
      </S.InvestigationCard>
    </Col>
  );
};

export default BookInvestigations;
