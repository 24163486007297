import { Col, message, Row, TablePaginationConfig, Tabs, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import {
  GQL_AverageInvestigationCompletionPerTag,
  GQL_InvestigationCompletionChartInput,
  GQL_InvestigationCompletionChartResponse,
  GQL_InvestigationCompletionPerTeacher,
} from '../../../types/charts';
import { Duration, FilterData } from '../../../types/investigation';
import Filter from '../Filter';
import { FiHelpCircle } from 'react-icons/fi';
import { formatDateTime } from '../../../utils/date';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import DetailTeachers from './DetailTeachers';
import Button from '../../Button';
import { downloadCsv } from '../../../utils/files';
import Papa from 'papaparse';
import { startCase } from 'lodash';
import DetailTags from './DetailTags';

const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};
const { TabPane } = Tabs;

const InvestigationCompletion = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const [period, setPeriod] = useState<Duration>(defaultDateRange);
  const [activeKey, setActiveKey] = useState('tags');
  const [filterData, setFilterData] = useState<FilterData>({ organizations: false, teachers: false });

  const [getInvestigationCompletionChart, { data, loading }] = useLazyQuery<
    { getInvestigationCompletionChart: GQL_InvestigationCompletionChartResponse },
    { data: GQL_InvestigationCompletionChartInput }
  >(gqlSchema.ChartSchema.queries.CHARTS.getInvestigationCompletionChart, {
    onError: (err) => {
      message.error('There was an error loading investigation completion: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    const { organizations, teachers, ...data } = filterData;
    getInvestigationCompletionChart({
      variables: {
        data: { ...data, startDate: period.startDate, endDate: period.endDate },
      },
    });
  }, [getInvestigationCompletionChart, filterData, period]);

  const onChangeFilterData = useCallback((data: FilterData) => {
    setFilterData(data);
  }, []);

  const downloadTeacherDetails = useCallback(() => {
    const headers = [['First Name', 'Last Name', 'Email', 'Tags', 'Completed Investigations']];
    const csvData =
      data?.getInvestigationCompletionChart?.perTeacher?.map((row: GQL_InvestigationCompletionPerTeacher) => {
        return [
          row.teacherFirstName,
          row.teacherLastName,
          row.teacherEmail,
          row.tags.map((t) => `${t.tag}`),
          row.completedInvestigations || 0,
        ];
      }) || [];
    downloadCsv(Papa.unparse([...headers, ...csvData]), `Completed Investigations (By Teachers).csv`);
  }, [data]);

  const downloadTagDetails = useCallback(() => {
    const headers = [['Tag', 'Total Teachers', 'Completed Investigations']];
    const csvData =
      data?.getInvestigationCompletionChart?.averagePerTag?.map((row: GQL_AverageInvestigationCompletionPerTag) => {
        return [row.tagName, row.totalTeachers || 0, row.completedInvestigations || 0];
      }) || [];
    downloadCsv(Papa.unparse([...headers, ...csvData]), `Completed Investigations (By Tags).csv`);
  }, [data]);

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Investigations Completed</S.Title>
          <Tooltip title="Investigations completed from the selected date range.">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Filter
            showGradeFilter={false}
            showSubjectFilter={false}
            showDateRangeFilter={true}
            showDisciplineFilter={true}
            filterUsers={false}
            onChangeFilterData={onChangeFilterData}
            setDaterange={setPeriod}
          />
        </Col>
        <Col span={24}>
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={(activeKey: string) => setActiveKey(activeKey)}
            tabBarExtraContent={
              <Button
                text={`Download ${startCase(activeKey)} Details`}
                block
                disabled={
                  activeKey === 'teachers'
                    ? (data?.getInvestigationCompletionChart?.perTeacher?.length || 0) === 0
                    : (data?.getInvestigationCompletionChart?.averagePerTag?.length || 0) === 0
                }
                onClick={activeKey === 'teachers' ? downloadTeacherDetails : downloadTagDetails}
              />
            }
          >
            <TabPane tab={<S.TitleTab>Tags Details</S.TitleTab>} key="tags">
              <DetailTags
                loading={loading}
                pagination={pagination}
                setPagination={setPagination}
                data={data?.getInvestigationCompletionChart?.averagePerTag || []}
              />
            </TabPane>
            <TabPane tab={<S.TitleTab>Teachers Details</S.TitleTab>} key="teachers">
              <DetailTeachers
                loading={loading}
                pagination={pagination}
                setPagination={setPagination}
                data={data?.getInvestigationCompletionChart?.perTeacher || []}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default InvestigationCompletion;
