import { gql } from '@apollo/client';

const INVESTIGATION_FORMAT_QUERIES = {
  GET: {
    getInvestigationFormats: gql`
      query GetInvestigationFormats {
        getInvestigationFormats {
          id
          name
        }
      }
    `,
    getInvestigationFormatById: gql`
      query GetInvestigationFormatById($id: String) {
        getInvestigationFormatById(id: $id) {
          id
          name
        }
      }
    `,
  },
};

export default INVESTIGATION_FORMAT_QUERIES;
