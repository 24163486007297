import styled from 'styled-components';

export const TitleInput = styled.p<{ alignRight?: boolean }>`
  font-weight: 600 !important;
  padding: 0;
  font-size: 15px !important;
  ${(props) => props.alignRight && 'text-align: right;'}
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;
