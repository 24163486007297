import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { gqlSchema } from '../gql/schema';
import { GQL_InvestigationMetadataCodebook, GQL_CodeTextPair, GQL_Discipline } from '../types/investigation';
import { gradeCleanupRegex } from '../utils/gradeBandCompare';

export const useLibraryFilters = () => {
  const { data: codebookData } = useQuery<{ getInvestigationMetadataCodebook: GQL_InvestigationMetadataCodebook }>(
    gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationMetadataCodebook,
  );

  const [disciplines, coreIdeas, crosscuttingConcepts, practices, publishedStatuses] = useMemo(() => {
    const disciplines = codebookData?.getInvestigationMetadataCodebook?.disciplines || [];

    const coreIdeas: {
      [subject: string]: {
        [grade: string]: GQL_CodeTextPair[];
      };
    } = {};
    const disciplinesFormatted: {
      [subject: string]: {
        [grade: string]: GQL_Discipline[];
      };
    } = {};
    const crosscuttingConcepts: GQL_CodeTextPair[] =
      codebookData?.getInvestigationMetadataCodebook.crosscuttingConcepts || [];
    const practices: GQL_CodeTextPair[] = codebookData?.getInvestigationMetadataCodebook.practices || [];

    disciplines.forEach((discipline) => {
      // if (discipline.coreIdeas) coreIdeas.push(...discipline.coreIdeas);

      const { subject, gradeBand } = discipline;
      const cleanGradeBand = gradeBand.replaceAll(gradeCleanupRegex, '');

      // populate the discipline map
      if (disciplinesFormatted[subject] && disciplinesFormatted[subject][cleanGradeBand]) {
        disciplinesFormatted[subject][cleanGradeBand].push(discipline);
      } else if (disciplinesFormatted[subject]) {
        disciplinesFormatted[subject] = {
          ...disciplinesFormatted[subject],
          [cleanGradeBand]: [discipline],
        };
      } else {
        disciplinesFormatted[subject] = {
          [cleanGradeBand]: [discipline],
        };
      }

      // populate the gradeBand map
      if (discipline.coreIdeas && discipline.coreIdeas.length > 0) {
        if (coreIdeas[subject] && coreIdeas[subject][cleanGradeBand]) {
          coreIdeas[subject][cleanGradeBand].push(...discipline.coreIdeas);
        } else if (coreIdeas[subject]) {
          coreIdeas[subject] = {
            ...coreIdeas[subject],
            [cleanGradeBand]: [...discipline.coreIdeas],
          };
        } else {
          coreIdeas[subject] = {
            [cleanGradeBand]: [...discipline.coreIdeas],
          };
        }
      }
    });

    const publishedStatuses = [
      {
        code: 'published',
        text: 'Published',
      },
      {
        code: 'not-published',
        text: 'Not Published',
      },
    ];

    return [disciplinesFormatted, coreIdeas, crosscuttingConcepts, practices, publishedStatuses];
  }, [codebookData]);

  return { disciplines, coreIdeas, crosscuttingConcepts, practices, publishedStatuses };
};
