import React, { useCallback } from 'react';
import { Row, Col, Progress } from 'antd';
import { getProgressColor, themeConfig } from '../../../../utils/theme';
import * as S from './styles';
import { useHistory } from 'react-router-dom';

interface Props {
  progress?: number | null;
  date: string;
  assessmentName: string;
  assessmentId: string;
}

const Item: React.FC<Props> = (props) => {
  const { progress, date, assessmentName, assessmentId } = props;
  const history = useHistory();

  const goToDashboard = useCallback(() => {
    history.push(`/teacher-dashboard/assessment-summary/${assessmentId}`);
  }, [assessmentId, history]);

  return (
    <S.Card>
      <Row align="middle">
        <Col span={18}>
          <S.Title $align="left">{assessmentName}</S.Title>
        </Col>
        <Col span={6}>
          <S.Info>{date}</S.Info>
        </Col>
        <Col span={24} className="middle">
          <Progress
            strokeLinecap="round"
            strokeWidth={9}
            style={{ marginTop: 30 }}
            strokeColor={getProgressColor(progress || 0)}
            format={(percent) => {
              return (
                <>
                  <S.Info $bold>{percent?.toFixed(2)}%</S.Info>
                  <S.InfoProgress>Average Score</S.InfoProgress>
                </>
              );
            }}
            width={145}
            type="circle"
            percent={progress || 0}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '3em' }}>
        <Col span={14} offset={5}>
          <S.SummarydButton
            text="View Summary"
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
