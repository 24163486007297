import styled from 'styled-components';

export const MessagesContactsContainer = styled.div`
  width: 320px;
  min-width: 320px;
  background: #f0f2ff;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    margin: 24px 32px;
  }
`;

export const ContactsOutsideContainer = styled.div<{ centralize?: boolean }>`
  height: 100%;
  overflow-y: auto;

  ${(props) =>
    props.centralize &&
    `
      align-items: center;
      justify-content: center;
      display: flex;
  `}
`;

export const ContactContainer = styled.div<{ selected?: boolean }>`
  width: 100%;
  padding: 24px 32px;
  cursor: pointer;
  ${(props) => props.selected && 'background: white;'}
  position: relative;
  display: flex;

  ${(props) =>
    !props.selected &&
    `
    &:hover {
      background: rgba(0, 0, 0, 0.01);
      filter: brightness(90%);
    }
  `}

  ${(props) =>
    props.selected &&
    `
    &::before {
      content: '';
      position: absolute;

      background-color: transparent;
      bottom: -50px;
      height: 50px;
      width: 25px;
      right: 0;
      border-top-right-radius: 12px;
      box-shadow: 0 -25px 0 0 #fff;
    }

    &::after {
      content: '';
      position: absolute;

      background-color: transparent;
      top: -50px;
      height: 50px;
      width: 25px;
      right: 0;
      border-bottom-right-radius: 12px;
      box-shadow: 0 25px 0 0 white;
    }
  `}

  h1 {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125.1%;
  }

  h2 {
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 1.3;
    /* identical to box height, or 18px */

    color: #868686;
  }

  & > span {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ant-skeleton-input {
      width: 150px;
      margin-bottom: 8px;
      border-radius: 4px;
      height: 18px;
    }

    .ant-skeleton-input-sm {
      width: 120px;
      height: 12px;
      margin-bottom: 0;
    }
  }

  .ant-skeleton-avatar {
    margin-right: 16px;
  }

  sup {
    height: 10px;
    width: 10px;
  }
`;

export const ContactsDivider = styled.div`
  max-width: 100%;
  height: 1px;
  border-radius: 6px;
  margin: 0 32px;
  background: #c6c6c655;
`;

export const AvatarContainer = styled.div`
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  border: ${(props) => props.theme.primaryColor.background} 2px solid;
  border-radius: 50%;
  margin-right: 16px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.primaryColor.background};
  }
`;
