import styled from 'styled-components';
import { Switch, Typography } from 'antd';
const { Text } = Typography;

export const Wrapper = styled.div<{ removeWrapperHeight?: boolean }>`
  ${(props) => !props.removeWrapperHeight && 'height: 100%;'}
`;

export const CText = styled(Text)`
  display: table-cell;
  vertical-align: middle;
`;

export const CSwitch = styled(Switch)<{ $margin: string }>`
  margin: ${(props) => props.$margin};
`;
