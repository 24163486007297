import styled from 'styled-components';
import { themeConfig } from '../../utils/theme';

export const Card = styled.div<{ $primary: boolean; $width?: string }>`
  width: ${(p) => (p.$width ? p.$width : '100%')};
  height: 100%;
  padding: 2em;
  border-radius: 4px;
  background: ${(props) => (props.$primary ? themeConfig.primaryColor.background : themeConfig.noColor.background)};
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  cursor: pointer;
`;

export const Info = styled.p<{ $primary: boolean; $disabled: boolean }>`
  font-weight: 400;
  font-size: 0.82em;
  margin: 0;
  text-align: center;
  color: ${(props) =>
    props.$primary
      ? themeConfig.noColor.background
      : props.$disabled
      ? themeConfig.noColor.disabled
      : themeConfig.noColor.color};
`;

export const Icon = styled.p<{ $primary: boolean; $disabled: boolean; $color?: string }>`
  font-size: 2.5em;
  margin-bottom: -0.3rem;
  text-align: center;
  color: ${(props) =>
    props.$primary
      ? themeConfig.noColor.background
      : props.$disabled
      ? themeConfig.noColor.disabled
      : props.$color || themeConfig.success.color};
`;

export const Title = styled.h3<{ $primary: boolean; $disabled: boolean }>`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.3em;
  color: ${(props) =>
    props.$primary
      ? themeConfig.noColor.background
      : props.$disabled
      ? themeConfig.noColor.disabled
      : themeConfig.noColor.color};
`;
