import { Col, Row, Table } from 'antd';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { centerAlign, Breakpoint } from '../../../../utils/antd';
import * as S from './styles';
import { GQL_InvestigationStageCompletion } from '../../../../types/charts';
import TableSearchBox from '../../../TableSearchBox';
import { FiSearch } from 'react-icons/fi';

const TableBreakPoint: Breakpoint[] = ['lg'];

interface Props {
  loading: boolean;
  pagination?: TablePaginationConfig;
  setPagination?: Dispatch<SetStateAction<TablePaginationConfig | undefined>>;
  data?: GQL_InvestigationStageCompletion[];
}

const DetailStages = (props: Props) => {
  const refName = useRef<HTMLInputElement>(null);
  const [searchNameVisible, setSearchNameVisible] = useState(false);

  const { loading, data, setPagination, pagination } = props;

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      if (pagination && setPagination) setPagination(pagination);
    },
    [setPagination],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Order',
        align: centerAlign,
        sorter: (a: GQL_InvestigationStageCompletion, b: GQL_InvestigationStageCompletion) => a.order - b.order,
        dataIndex: 'order',
        width: '10%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_InvestigationStageCompletion) => {
          return record.order | 0;
        },
      },
      {
        title: 'Stage',
        align: centerAlign,
        dataIndex: 'name',
        width: '30%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_InvestigationStageCompletion) => {
          return record.name.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Total Students',
        align: centerAlign,
        sorter: (a: GQL_InvestigationStageCompletion, b: GQL_InvestigationStageCompletion) =>
          a.totalUsers - b.totalUsers,
        dataIndex: 'totalUsers',
        width: '20%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_InvestigationStageCompletion) => {
          return record.totalUsers | 0;
        },
      },
      {
        title: 'Total Students Completed',
        align: centerAlign,
        sorter: (a: GQL_InvestigationStageCompletion, b: GQL_InvestigationStageCompletion) =>
          a.completedUsers - b.completedUsers,
        dataIndex: 'completedUsers',
        width: '20%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_InvestigationStageCompletion) => {
          return record.completedUsers | 0;
        },
      },
      {
        title: 'Completion Average',
        align: centerAlign,
        width: '20%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_InvestigationStageCompletion) => {
          return `${((record.completedUsers * 100) / record.totalUsers).toFixed(2)} %`;
        },
      },
    ];
  }, [searchNameVisible]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_InvestigationStageCompletion) => `${record.order}-${record.name}`}
              pagination={pagination}
              bordered
              dataSource={data?.filter((d) => d.order > 0)}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default DetailStages;
