import { Switch } from 'antd';
import React, { useState } from 'react';
import Input from '../../../../../shared/Input';
import UploadInput from '../../../../../shared/UploadInput';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  IMediaContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';

interface IVideoBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const VideoBlockEditor = (props: IVideoBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const mediaValue = editingBlock.values[0] as IMediaContentValue;
  const [isUrl, setIsUrl] = useState(true);

  const handleClearVideo = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();

    handleEditVideoAttributes({ file: undefined });
  };

  const beforeUploadVideo = (file: File) => {
    if (!file.type.includes('video')) return false;
    handleEditVideoAttributes({ file });
    return true;
  };

  const handleEditVideoAttributes = (payload: { url?: string; file?: File }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;

              return { ...block, values: [{ url: payload.url, file: payload.file }] };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleChangeUploadMethod = () => {
    setIsUrl(!isUrl);

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;

              return { ...block, values: [] };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  return (
    <>
      <S.UploadFromComputerContainer onClick={handleChangeUploadMethod}>
        <h2>Upload from computer</h2>
        <Switch checked={!isUrl} size="small" />
      </S.UploadFromComputerContainer>
      <h2>Video URL</h2>
      {isUrl ? (
        <Input
          value={mediaValue?.url}
          backgroundColor="white"
          onChange={(e) => handleEditVideoAttributes({ url: e.target.value })}
          placeholder="Video URL"
        />
      ) : (
        <UploadInput
          beforeUpload={(f) => beforeUploadVideo(f)}
          title={mediaValue?.file?.name || 'Upload video'}
          showClear={!!mediaValue?.file}
          onClear={(e) => handleClearVideo(e)}
          accept="video/*"
          defaultFileList={[]}
          fileList={[]}
          customRequest={() => {}}
        />
      )}
    </>
  );
};

export default VideoBlockEditor;
