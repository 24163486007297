import { message } from 'antd';
import React from 'react';
import { handleHSFormSubmit } from './HSForm';
import * as S from './styles';

const LandingFooter: React.FC<{ goToTabLink?: (tabName: string) => void }> = ({ goToTabLink }): JSX.Element => {
  const handleSubmission = async(event:any) => {
    try {
      event.preventDefault();
      const form = event.target as HTMLFormElement;
      const formData = new FormData(form);
      const email = formData.get('email') as string ?? null;
      if (email !== null) {
        const response:any = await handleHSFormSubmit(email);
        console.log(response);
        if(response?.inlineMessage === "<p>Thank you!</p>"){
          message.success("Thank you for subscribing to our newsletter!");
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  }
  return (
    <S.Footer>
      <S.FooterContainer>
        <S.FooterSection>
          <S.FooterTitle>Overview</S.FooterTitle>
          <S.FooterLink href="https://www.argumentdriveninquiry.com/our-story">About</S.FooterLink>
          <S.FooterLink href="#">Our Leadership</S.FooterLink>
          {/* <S.FooterLink href="#">Accessibility</S.FooterLink> */}
          <S.FooterLink href="https://www.argumentdriveninquiry.com/careers">Careers</S.FooterLink>
          <S.FooterLink href="https://www.argumentdriveninquiry.com/blog">Blogs</S.FooterLink>
        </S.FooterSection>
        <S.FooterSection>
          <S.FooterTitle>Programs</S.FooterTitle>
          <S.FooterLink onClick={() => goToTabLink?.('Science')}>Science</S.FooterLink>
          <S.FooterLink onClick={() => goToTabLink?.('Engineering')}>Engineering</S.FooterLink>
          <S.FooterLink onClick={() => goToTabLink?.('Math')}>Math</S.FooterLink>
        </S.FooterSection>
        <S.FooterSection>
          <S.FooterTitle>Teacher Resources</S.FooterTitle>
          <S.FooterLink onClick={() => goToTabLink?.('Books')}>Books</S.FooterLink>
          <S.FooterLink onClick={() => goToTabLink?.('Hands-on Materials')}>Hands-on Materials</S.FooterLink>
          <S.FooterLink onClick={() => goToTabLink?.('Digital Platform')}>Digital Platform</S.FooterLink>
          <S.FooterLink onClick={() => goToTabLink?.('Professional Learning')}>Professional Learning</S.FooterLink>
        </S.FooterSection>
        <S.FooterSection>
          <S.FooterTitle>Learn More</S.FooterTitle>
          <S.FooterLink onClick={() => goToTabLink?.('Events')}>Events</S.FooterLink>
          <S.FooterLink onClick={() => goToTabLink?.('Research')}>Research</S.FooterLink>
          <S.FooterLink onClick={() => goToTabLink?.('Reviews')}>Reviews</S.FooterLink>
          <S.FooterLink href="https://www.argumentdriveninquiry.com/videos">Videos</S.FooterLink>
        </S.FooterSection>
        <S.FooterSection>
          <S.FooterTitle>Purchasing</S.FooterTitle>
          {/* <S.FooterLink href="#">Contact Sales</S.FooterLink> */}
          <S.FooterLink href="https://www.argumentdriveninquiry.com/inquiries/purchase-order-information">
            Purchase Orders
          </S.FooterLink>
          {/* <S.FooterLink href="#">Vendor Forms</S.FooterLink> */}
          <S.FooterLink href="https://share.hsforms.com/1G7nKIY5TT1iX6InbAMZmXw5gbsq">Tax Exempt</S.FooterLink>
          {/* <S.FooterLink href="#">Buying Co-Ops</S.FooterLink> */}
        </S.FooterSection>
        <S.FooterSection>
          <S.FooterTitle>
            Enter your email for updates, freebies,
            <br /> and special offers from ADI!
          </S.FooterTitle>
          <S.NewsletterForm 
                onSubmit={handleSubmission}
          >
            <S.NewsletterInputWrapper>
              <S.NewsletterInput name="email" type="email" placeholder="Your email" />
              <S.NewsletterButton
                type="submit"
              >
                Submit
              </S.NewsletterButton>
            </S.NewsletterInputWrapper>
          </S.NewsletterForm>
        </S.FooterSection>
      </S.FooterContainer>
    </S.Footer>
  );
};

export default LandingFooter;
