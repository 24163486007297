import { gql } from '@apollo/client';

export const PAYMENTS_QUERIES = {
  LIST: {
    getProductById: gql`
      query getProductById($id: String) {
        getProductById(id: $id) {
          id
          name
          description
          price
          interval
        }
      }
    `,
    getProductUpgradeDetails: gql`
      query getProductUpgradeDetails($id: String) {
        getProductUpgradeDetails(id: $id) {
          id
          name
          description
          price
          interval
        }
      }
    `,
    getProducts: gql`
      query getAllProducts {
        getAllProducts {
          id
          name
          stripeId
          type
          prices {
            id
            stripeId
            nickname
          }
        }
      }
    `,
    getBillingInformationFromUser: gql`
      query getBillingInformationFromUser {
        getBillingInformationFromUser {
          planPending
          noSubscription
          isStarterPlan
          billingInfo {
            id
            created
            description
            currency
            email
            invoice_prefix
            invoice_settings {
              default_payment_method
            }
            next_invoice_sequence
            name
            amount
            interval
            interval_count
          }
          paymentMethods {
            id
            object
            created
            type
            customer
            card {
              brand
              exp_month
              exp_year
              last4
              country
            }
          }
          invoices {
            id
            amount_due
            amount_paid
            amount_remaining
            created
            currency
            invoice_pdf
            hosted_invoice_url
            number
            paid
            period_end
            period_start
            status
            total
          }
          product {
            id
            name
            description
            metadata {
              allowedFeatures
              maxAssessments
              maxClasses
              maxStudentsPerClass
              maxInvestigations
              subjects
              maxTAs
            }
          }
        }
      }
    `,
    getProductCheckoutLinks: gql`
      query getProductCheckoutLinks {
        getProductCheckoutLinks {
          id
          stripeId
          type
        }
      }
    `,
    getAllInvoices: gql`
      query getAllInvoices {
        getAllInvoices {
          id
          amount_due
          amount_paid
          amount_remaining
          created
          currency
          invoice_pdf
          hosted_invoice_url
          number
          paid
          period_end
          period_start
          status
          total
        }
      }
    `,
    getStripeCustomerPortal: gql`
      query getStripeCustomerPortal {
        getStripeCustomerPortal {
          url
        }
      }
    `,
  },
};

export default PAYMENTS_QUERIES;
