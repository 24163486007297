import styled from 'styled-components';

export const RenewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 33px;
`;

export const TitleInput = styled.div`
  font-weight: 600;
  padding: 0 11px;
  font-size: 1.2em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  align-items: center;
  .ant-progress {
    flex: 1;
    margin-left: 16px;
  }
`;
export const Status = styled.span`
  font-size: 0.95em;
  padding: 0 11px;
  font-weight: 400;
  color: '#50c364';
`;
