import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Container = styled.div`
  min-height: 100vh;
`;

export const Title = styled.h1`
  margin-bottom: 25px;
  text-align: center;
  margin-top: 15px;
  font-weight: 700;
  font-size: 40px;
  color: #3f3e38;
`;

export const RowSP = styled(Row)`
  height: 100%;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const ColSP = styled(Col)`
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  padding: 80px 80px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 6px;
  background: #ffffff;
  @media (max-width: 992px) {
    padding: 40px;
    height: 100%;
  }

  p {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: 14px;
    color: #63625e;
    line-height: 21px;
    letter-spacing: 0.04em;
    font-style: normal;
    font-weight: 500;
  }

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: 0.04em;
    text-decoration-line: underline;
    text-transform: uppercase;
    margin-bottom: 25px;
    margin-top: -10px;

    color: #63625e;
  }
`;

export const ImageCol = styled(Col)`
  padding: 80px;
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    display: none;
  }

  img {
    width: 100%;
  }
`;
