import { Col, Row, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { getInsightsColors, InsightsData } from '../InvestigationInsight';
import Spacer from '../../../shared/Spacer';
import * as S from './styles';

interface IScoreDifference {
  data?: InsightsData[];
  loading?: boolean;
  type?: 'report' | 'engagement';
}

const ScoreDifference = (props: IScoreDifference) => {
  const { data, type = 'report' } = props;

  const firstInvestigation = data?.[0];

  const lastInvestigation = data?.[data.length - 1];

  const firstScore =
    (type === 'report' ? firstInvestigation?.reportScore : (firstInvestigation?.engagementAverage ?? 0) * 5) ?? 0;
  const lastScore =
    (type === 'report' ? lastInvestigation?.reportScore : (lastInvestigation?.engagementAverage ?? 0) * 5) ?? 0;
  const scoreDiff = type === 'report' ? Math.round(lastScore - firstScore) : lastScore - firstScore;
  const percentageChange = useMemo(() => {
    if (firstScore === 0) {
      return 0;
    }
    return Math.round((scoreDiff / firstScore) * 100);
  }, [firstScore, scoreDiff]);

  // Formula: [g = (average last report score - average first report score) / (100 - average first report score)]
  const firstAverage =
    (type === 'report' ? firstInvestigation?.reportAverage : firstInvestigation?.engagementAverage) ?? 0;
  const lastAverage =
    (type === 'report' ? lastInvestigation?.reportAverage : lastInvestigation?.engagementAverage) ?? 0;
  const gainPercentage = Math.round(((lastAverage - firstAverage) / (1.5 - firstAverage || 1.5)) * 100);

  return (
    <S.ScoresDifferenceContainer>
      <h1>Difference Between First & Last {type === 'report' ? 'Reports' : 'Engagements'} Score</h1>
      <Spacer size={24} />
      <Row style={{ width: '100%' }} justify="center" align="middle">
        <Col span={8}>
          <S.ScoreDiffText color={getInsightsColors(lastAverage * 100)}>
            {type === 'report' ? scoreDiff : scoreDiff.toFixed(1)}
          </S.ScoreDiffText>
          <Tooltip title={`Difference in points between last and first ${type} scores`}>
            <S.ScoreDiffTextSmall>points</S.ScoreDiffTextSmall>
          </Tooltip>
        </Col>
        <Col span={8}>
          <S.ScoreDiffText color={getInsightsColors(lastAverage * 100)}>{percentageChange}%</S.ScoreDiffText>
          <Tooltip title={`Percentage change between last and first ${type} scores`}>
            <S.ScoreDiffTextSmall>change</S.ScoreDiffTextSmall>
          </Tooltip>
        </Col>
        <Col span={8}>
          <S.ScoreDiffText color={getInsightsColors(lastAverage * 100)}>{gainPercentage}%</S.ScoreDiffText>
          <Tooltip
            title={`Difference between last and first ${type} scores divided by the maximum possible score gain (100% - average first ${type} score). This loosely represents how much the class learned, based on how much they could've learned after the first investigation.`}
          >
            <S.ScoreDiffTextSmall>normalized gain</S.ScoreDiffTextSmall>
          </Tooltip>
        </Col>
      </Row>
    </S.ScoresDifferenceContainer>
  );
};

export default ScoreDifference;
