// styles.ts
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1200px;
  margin: auto;
`;

export const BackButton = styled.button`
  font-size: 18px;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const OrderInfo = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const OrderId = styled.h2`
  font-size: 18px;
  margin: 0;
`;

export const Status = styled.p`
  font-weight: bold;
  margin: 10px 0;
`;

export const StatusNote = styled.p`
  color: #888;
`;

export const DetailsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

export const ShippingInfo = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  flex: 1;

  h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  p {
    color: #555;
    margin: 5px 0;
  }
`;

export const Summary = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  flex: 1;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  background: #f7f7f7;
`;

export const ItemImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

export const ItemDetails = styled.div`
  flex: 1;
  p {
    margin: 0;
    font-size: 14px;
    color: #333;
  }
`;

export const ItemPrice = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const Pricing = styled.div`
  margin-top: 10px;
`;

export const PricingRow = styled.div<{ bold?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  margin-bottom: 5px;
`;

export const DownloadButton = styled.button`
  background-color: #1890ff;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #007acc;
  }
`;