import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px; /* increased border-radius for a rounded appearance */
  }

  .ant-modal-header {
    text-align: center;
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 24px; /* increased padding for spacing */
  }

  .ant-btn {
    width: 120px; /* fixed width for buttons */
    height: 40px; /* fixed height for buttons */
    margin-right: 8px; /* added right margin for spacing between buttons */
    border-radius: 5px; /* added border-radius for rounded corners */
  }

  .ant-btn-primary {
    background-color: #1890ff; /* set background color to blue for primary button */
    border-color: #1890ff;
  }

  input[type="text"] {
    background-color: #f5f5f5; /* set background color to a light shade */
    border: 1px solid #d9d9d9; /* set border to create a thin line */
    width: 100%; /* increased width to fill the container */
    height: 40px; /* set the height of the input field */
    padding: 8px 12px; /* added padding for better appearance */
    font-size: 14px; /* set font size */
    border-radius: 5px; /* added border-radius for rounded corners */
    color: #333; /* set text color */
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 1.5em; /* increased font size for title */
  font-weight: 500;
  margin-bottom: 16px; /* added bottom margin for spacing */
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 24px; /* added top margin for spacing */
`;
