import styled from 'styled-components';

import CButton from '../../shared/Button';
import { Modal } from 'antd';

export const Title = styled.h1`
  text-align: center;
  margin-top: 25px;
  font-size: 1.5em;
  font-weight: 500;
`;

export const CModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 5px;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;

export const TitleInput = styled.p`
  font-weight: 600;
  font-size: 0.95em;
  margin: 0 0 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const SubmitButton = styled(CButton)`
  font-weight: 500;
`;
