import React, { useRef, useState } from 'react';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import logo from '../../../../assets/adi-learning-hub.png';
import progmod from '../../../../assets/booklanding/programmodal.png';
// import newsImg from '../../../../assets/booklanding/newsmodal.png';
import kristaImg from '../../../../assets/booklanding/krista.png';
import joeImg from '../../../../assets/booklanding/joe.png';
// import storyImg from '../../../../assets/booklanding/story.png';
// import careerImg from '../../../../assets/booklanding/company.png';
import LoginModal from './LoginModal';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// import { IoMdMail } from 'react-icons/io';

const Navbar: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indiAccountState, setIndiAccountState] = useState('initial');
  const history = useHistory();
  const refProgram = useRef<HTMLDivElement>(null);
  const refResources = useRef<HTMLDivElement>(null);
  const refCompany = useRef<HTMLDivElement>(null);

  const handleOnclick = (value: string) => {
    setSelectedItem(value);
  };
  const handleOnClickOutside = () => {
    setSelectedItem(null);
  };
  useClickOutside(refProgram, handleOnClickOutside);
  useClickOutside(refResources, handleOnClickOutside);
  useClickOutside(refCompany, handleOnClickOutside);

  const handleIndiClick = () => {
    setSelectedItem(null);
    setIsModalOpen(true);
    setIndiAccountState('secondary');
  };
  const handleorgClick = () => {
    setSelectedItem(null);
    setIsModalOpen(true);
    setIndiAccountState('initial');
  };

  return (
    <S.NavContainer>
      <S.Image onClick={() => history.push('/test')} src={logo} alt="ADI Logo" />
      <S.NavItems>
        <S.NavItem
          onClick={(e) => {
            handleOnclick('Programs');
          }}
        >
          Programs
          {selectedItem === 'Programs' && <ProgramModal ref={refProgram} />}
        </S.NavItem>
        <S.NavItem
          onClick={(e) => {
            handleOnclick('Resources');
          }}
        >
          Teacher Resources
          {selectedItem === 'Resources' && (
            <ResourcesModal ref={refResources} handleIndiClick={handleIndiClick} handleorgClick={handleorgClick} />
          )}
        </S.NavItem>
        <S.NavItem
          onClick={(e) => {
            handleOnclick('Our Company');
          }}
        >
          Our Company
          {selectedItem === 'Our Company' && <CompanyModal ref={refCompany} />}
        </S.NavItem>
        <a href="https://shop.argumentdriveninquiry.com/" target="_blank" rel="noopener noreferrer">
          <S.NavItem>Shop</S.NavItem>
        </a>
        <S.NavItem>
          <button onClick={() => setIsModalOpen(true)}>LOGIN</button>
        </S.NavItem>
      </S.NavItems>

      <LoginModal
        closeModal={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        history={history}
        initailStage={indiAccountState}
      />
    </S.NavContainer>
  );
};

const ProgramModal = React.forwardRef((props: any, ref: any) => {
  const [downloadUrl, setDownloadUrl] = useState<string>(
    'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Report%20Peer%20Review%20Guide%20Elementary%20Math%20and%20Science.pdf',
  );
  const [selectedTitle, setSelectedTitle] = useState<string>('Elementary Peer Review Guide');
  const data = {
    'Peer Review Guides for Math & Science': [
      {
        title: 'Elementary Peer Review Guide',
        url:
          'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Report%20Peer%20Review%20Guide%20Elementary%20Math%20and%20Science.pdf',
      },
      {
        title: 'Secondary Peer Review Guide',
        url:
          'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Report%20Peer%20Review%20Guide%20Secondary%20Math%20and%20Science.pdf',
      },
    ],
    'Investigation Proposals': [
      {
        title: 'Investigation Proposal A',
        url: 'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Investigation%20Proposal%20A.pdf',
      },
      {
        title: 'Investigation Proposal B',
        url: 'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Investigation%20Proposal%20B.pdf',
      },
      {
        title: 'Investigation Proposal C',
        url: 'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Investigation%20Proposal%20C.pdf',
      },
      {
        title: 'Investigation Proposal D',
        url: 'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Investigation%20Proposal%20D.pdf',
      },
      {
        title: 'Investigation Proposal F',
        url: 'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Investigation%20Proposal%20E.pdf',
      },
    ],
    'Scaffolding Materials': [
      {
        title: 'Draft Report with Sentence Starters, Version A',
        url:
          'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Draft%20Report%20with%20Sentence%20Starters%20A.pdf',
      },
      {
        title: 'Draft Report with Sentence Starters, Version B',
        url:
          'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Draft%20Report%20with%20Sentence%20Starters%20B.pdf',
      },
    ],
    'ADI Investigations': [
      {
        title: 'Elementary Science Example Investigation',
        url:
          'https://9159866.fs1.hubspotusercontent-na1.net/hubfs/9159866/Website%20Downloads/Elementary%20Science%20Example%20-%20Wolf%20Pack%20(updated).pdf',
      },
      {
        title: 'Secondary Science Example Investigation',
        url:
          'https://9159866.fs1.hubspotusercontent-na1.net/hubfs/9159866/Website%20Downloads/High%20School%20Science%20Example%20-%20Big%20Horn%20Sheep%20and%20Wolves%20in%20Nevada%20(updated).pdf',
      },
      {
        title: 'Elementary Math Example Investigation',
        url:
          'https://9159866.fs1.hubspotusercontent-na1.net/hubfs/9159866/Website%20Downloads/Elementary%20Math%20Example%20-%20The%20Biggest%20Number.pdf',
      },
      {
        title: 'Secondary Math Example Investigation',
        url:
          'https://9159866.fs1.hubspotusercontent-na1.net/hubfs/9159866/Website%20Downloads/High%20School%20Math%20Example%20-%20Congressional%20Districts%20in%20Colorado%20(updated).pdf',
      },
      {
        title: 'Engineering Example Investigation',
        url:
          'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Middle%20School%20Engineering%20Example%20-%20Insulin%20Storage%20Container.pdf',
      },
    ],
    'Standards by Stage': [
      {
        title: 'Math & Literacy Practices Table',
        url:
          'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Math%20and%20Literacy%20Practices%20Table.pdf',
      },
      {
        title: 'Science & Literacy Practices Table',
        url:
          'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Science%20and%20Literacy%20Practices%20Table.pdf',
      },
      {
        title: 'Engineering & Literacy Practices Table',
        url:
          'https://f.hubspotusercontent10.net/hubfs/9159866/Website%20Downloads/Engineering%20and%20Literacy%20Practices%20Table.pdf',
      },
    ],
  };

  function chunkArray(arr: any[], chunkSize: number) {
    const result = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      result.push(chunk);
    }

    return result;
  }

  const chunkedData: { items: any[]; title: string }[][] = chunkArray(
    Object.keys(data).map((subject: string) => {
      const items = data[subject as keyof typeof data].map((item) => {
        return item;
      });
      return {
        items,
        title: subject,
      };
    }),
    3,
  );
  const handleSelected = (item: any) => {
    setSelectedTitle(item.title);
    setDownloadUrl(item.url);
  };

  return (
    <S.Modal ref={ref}>
      <S.ModalContent>
        <S.LeftContent>
          <h1>Instructional Materials</h1>
          <S.GridWrapperProgram>
            {chunkedData.map((chunkDataItem) => {
              return (
                <S.GridItem>
                  {chunkDataItem.map(({ items, title }) => {
                    console.log({ items, title });
                    return (
                      <ul>
                        <h2>{title}</h2>
                        {items.map((item) => (
                          <li onClick={() => handleSelected(item)}>
                            {/* <a style={{ color: 'black' }} href={item.url} target="_blank" rel="noopener noreferrer"> */}
                            {item.title}
                            {/* </a> */}
                          </li>
                        ))}
                      </ul>
                    );
                  })}
                </S.GridItem>
              );
            })}
          </S.GridWrapperProgram>
          <br />
        </S.LeftContent>
        <S.Divider />
        <S.StackVertical>
          <h1>{selectedTitle || 'Elementary Peer Review Guide'}</h1>
          <S.RightContent>
            <img src={progmod} alt="Programs" />
            <S.ButtonWrapper>
              <a
                href={
                  downloadUrl || 'https://app-na1.hubspotdocuments.com/documents/9159866/view/981748091?accessId=b064ea'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.FilledButton>Download a Sample</S.FilledButton>
              </a>
            </S.ButtonWrapper>
          </S.RightContent>
        </S.StackVertical>
      </S.ModalContent>
    </S.Modal>
  );
});

const ResourcesModal = React.forwardRef((props: any, ref: any) => {
  const [selectedItem, setSelectedItem] = useState<any>({
    title: 'K-2 NGSS',
    book:'https://books.nuttybrownpress.com/view/1027368350/',
    url:
      'https://shop.argumentdriveninquiry.com/collections/adi-books-ngss-version?filter.v.price.gte=&filter.v.price.lte=&filter.p.tag=Grades+K+-+2&filter.p.tag=science',
    products: [
      {
        image:
          'https://shop.argumentdriveninquiry.com/cdn/shop/files/1_3084b398-4c8c-4452-8966-a492a5283dc0_1800x1800.png?v=1733254324',
        title: 'Argument-Driven Inquiry for Kindergarten Book (NGSS Edition)',
      },
    ],
  });
  interface ResourceItem {
    title: string;
    url: string;
    redirectLink?: string;
    products?: any[];
    book?:string;
  }

  interface ResourceData {
    [section: string]: {
      [subject: string]: ResourceItem[];
    };
  }
  const resourceData: ResourceData = {
    Books: {
      Science: [
        {
          title: 'K-2 NGSS',
          book:'https://books.nuttybrownpress.com/view/1027368350/',
          url:
            'https://shop.argumentdriveninquiry.com/collections/adi-books-ngss-version?filter.v.price.gte=&filter.v.price.lte=&filter.p.tag=Grades+K+-+2&filter.p.tag=science',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/1_3084b398-4c8c-4452-8966-a492a5283dc0_1800x1800.png?v=1733254324',
              title: 'Argument-Driven Inquiry for Kindergarten Book (NGSS Edition)',
            },
          ],
        },
        {
          title: 'K-2 Texas',
          book:'https://books.nuttybrownpress.com/view/686800938/',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/1_6101d3bb-d02e-4e12-8e23-9739a6baf244_1800x1800.png?v=1733273713',
              title: 'Argument-Driven Inquiry for Kindergarten Book (Texas Edition)',
            },
          ],
        },
        {
          title: '3-5 NGSS',
          book:'https://books.nuttybrownpress.com/view/1026974495/',
          url:
            'https://shop.argumentdriveninquiry.com/collections/adi-books-ngss-version?filter.v.price.gte=&filter.v.price.lte=&filter.p.tag=grades+3-5',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/4_6f2df02d-dc3e-4689-844d-b4436f1bdb1f_1800x1800.png?v=1733254324',
              title:
                'Argument-Driven Inquiry for 3rd Grade Book (NGSS Edition)                                                ',
            },
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/4_6f2df02d-dc3e-4689-844d-b4436f1bdb1f_1800x1800.png?v=1733254324',
              title:
                'Argument-Driven Inquiry for 4th Grade (NGSS Edition)                                               ',
            },
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/6_9741df18-6ee6-4c06-a442-23ac3025dcac_1800x1800.png?v=1733254324',
              title:
                'Argument-Driven Inquiry for 5th Grade (NGSS Edition)                                               ',
            },
          ],
        },
        {
          title: '3-5 Texas',
          book:'https://books.nuttybrownpress.com/view/686800938/',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/4_21cceff4-992e-4b77-b503-0db4a7bff0a9_1800x1800.png?v=1733278351',
              title: 'Argument-Driven Inquiry for 3rd Grade Book (Texas Edition)',
            },
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/5_3634cac5-de2e-48cd-b664-3c47120feb25_1800x1800.png?v=1733273713',
              title: 'Argument-Driven Inquiry for 4th Grade (Texas Edition)',
            },
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/6_fa9217d0-b9f5-4ea1-932f-e7d779b5f8f1_1800x1800.png?v=1733273713',
              title: 'Argument-Driven Inquiry for 5th Grade (Texas Edition)',
            },
          ],
        },
        {
          title: '6-8 NGSS',
          book:'https://books.nuttybrownpress.com/view/844426059/',
          url:
            'https://shop.argumentdriveninquiry.com/collections/adi-books-ngss-version?filter.v.price.gte=&filter.v.price.lte=&filter.p.tag=grades+6-8',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/collections/adi-books/products/argument-driven-inquiry-for-middle-school-earth-and-space-science-new-2024-ngss-edition-copy?_pos=1&_fid=e239a9b0b&_ss=c',
              title: 'Argument-Driven Inquiry for Middle School Life Science (NGSS Edition)',
            },
          ],
        },
        {
          title: '6-8 Texas',
          book:'https://books.nuttybrownpress.com/view/686073587/',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/7_398ab03f-497b-40ea-afc7-bfb74b1cdfbc_1800x1800.png?v=1733273713',
              title: 'Argument-Driven Inquiry for 6th Grade (Texas Edition)',
            },
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/Untitled_design_31_1800x1800.png?v=1733009696',
              title: 'Argument-Driven Inquiry for 7th Grade (Texas Edition)',
            },
          ],
        },
        {
          title: '9-12 NGSS',
          
          url: 'https://shop.argumentdriveninquiry.com/collections/adi-books-ngss-version?filter.p.tag=grades+9-12',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/10_5b179a31-1fbe-4a86-95eb-aea202e943d2_1800x1800.png?v=1733254324',
              title: 'Argument-Driven Inquiry for Earth and Space Science Book, Grades 9 - 12 (NGSS Edition)',
            },
          ],
        },
        {
          title: '9-12 Texas',
          book:'https://books.nuttybrownpress.com/view/686453062/',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
      ],
      Engineering: [
        {
          title: '3-5 NGSS',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
        {
          title: '3-5 Texas',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
        {
          title: '6-8 NGSS',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
        {
          title: '6-8 Texas',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
      ],
      Math: [
        {
          title: '3-5 Common Core',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
        {
          title: '6-8 Common Core',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
      ],
    },
    'Hands-on Materials': {
      Science: [
        {
          title: 'K-2 Investigation Kits',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/MockUpsforShopify-ElemandMS_kinderngsskit_1800x1800.png?v=1720196991',
              title: 'Kindergarten NGSS Book Investigation Kits',
            },
          ],
        },
        {
          title: '3-5 Investigation Kits',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/MockUpsforShopify-ElemandMS_4thGradetexasKit_1800x1800.png?v=1720198012',
              title: '4th Grade Texas Book Investigation Kits',
            },
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/MockUpsforShopify-ElemandMS_3rdgradengsskit_1800x1800.png?v=1720196095',
              title: '3rd Grade NGSS Book Investigation Kits',
            },
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/MockUpsforShopify-ElemandMS_3rdgradetexaskit_1800x1800.png?v=1720197946',
              title: '3rd Grade Texas Book Investigation Kits',
            },
          ],
        },
        {
          title: '6-8 Investigation Kits',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
          products: [
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/MockUpsforShopify-ElemandMS_6thgradekittexas_1800x1800.png?v=1720201100',
              title: '6th Grade Texas Book Investigation Kits',
            },
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/MockUpsforShopify-ElemandMS_7thgradekittexas_1800x1800.png?v=1720201237',
              title: '7th Grade Texas Book Investigation Kits',
            },
            {
              image:
                'https://shop.argumentdriveninquiry.com/cdn/shop/files/MockUpsforShopify-ElemandMS_8thgradekittexas_1800x1800.png?v=1720201331',
              title: '8th Grade Texas Book Investigation Kits',
            },
          ],
        },
        {
          title: 'Investigation Kit Bundles',
          redirectLink:
            'https://shop.argumentdriveninquiry.com/collections/investigation-kit-bundles?filter.v.price.gte=&filter.v.price.lte=&filter.p.tag=science',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
        {
          title: 'Classroom Supplies',
          redirectLink:
            'https://shop.argumentdriveninquiry.com/collections/classroom-supplies-new?filter.v.price.gte=&filter.v.price.lte=&filter.p.tag=science',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
      ],
      Engineering: [
        {
          title: 'K-2 Design Challenge Kits',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
        {
          title: '3-5 Design Challenge Kits',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
        {
          title: '6-8 Design Challenge Kits',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
        {
          title: 'Design Challenge Kit Bundles',

          redirectLink: ' https://shop.argumentdriveninquiry.com/collections/design-challenge',
          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
        {
          title: 'Classroom Supplies',
          redirectLink:
            'https://shop.argumentdriveninquiry.com/collections/classroom-supplies-new?filter.v.price.gte=&filter.v.price.lte=&filter.p.tag=engineering',

          url:
            'https://www.figma.com/proto/zCm4w2DtmF86ieaTxUINX2/book-module?node-id=316-8425&t=btMNZv5RKnWsEljp-0&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=282%3A8873&hide-ui=1',
        },
      ],
    },
  };

  return (
    <S.Modal style={{ overflowY: 'scroll' }} ref={ref}>
      <S.ModalContent onClick={(e) => e.stopPropagation()}>
        <S.LeftContent>
          {Object.keys(resourceData).map((section) => {
            return (
              <>
                <h1>{section}</h1>
                <S.GridWrapper>
                  {Object.keys(resourceData[section as keyof typeof resourceData]).map((subject: string) => {
                    return (
                      <>
                        <ul>
                          <h2>{subject}</h2>
                          {resourceData[section][subject].map((i: any) => {
                            return (
                              <li onClick={() => setSelectedItem(i)}>
                                {i.redirectLink ? (
                                  <a href={i.redirectLink} style={{ color: 'black', display: 'block' }}>
                                    {i.title}
                                  </a>
                                ) : (
                                  i.title
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    );
                  })}
                </S.GridWrapper>
                <br />
              </>
            );
          })}
          <h1>Digital Platform</h1>
          <S.GridWrapper>
            <ul>
              <li onClick={() => props.handleIndiClick()}>Individual Accounts</li>
              <li onClick={() => props.handleorgClick()}>Organization Accounts</li>
              {/* <li>Organization Accounts</li> */}
            </ul>
          </S.GridWrapper>{' '}
          <br />
          <h1>Professional Learning</h1>
          <S.GridWrapper>
            <ul>
              {/* <li>Workshops</li> */}
              <li>
                <a
                  href="https://www.argumentdriveninquiry.com/professional-learning/district-or-school-hosted-professional-learning"
                  style={{ color: 'black', display: 'block' }}
                >
                  Workshops
                </a>
              </li>
              <li>
                <a
                  href="https://www.argumentdriveninquiry.com/professional-learning/facilitator-institute"
                  style={{ color: 'black', display: 'block' }}
                >
                  Facilitator Institute
                </a>
              </li>
              <li>
                <a
                  href="https://www.argumentdriveninquiry.com/professional-learning/adi-fest-2025"
                  style={{ color: 'black', display: 'block' }}
                >
                  ADI Fest
                </a>
              </li>
              {/* <li>Facilitator Institute</li>
              <li>ADI Fest</li> */}
            </ul>
          </S.GridWrapper>
        </S.LeftContent>
        <S.Divider />
        <S.StackVertical>
          <h1>{selectedItem.title || 'K-2 Texas'}</h1>
          <S.RightContent>
            <S.StackHorizontal>
              {selectedItem &&
                selectedItem.products &&
                selectedItem.products.map((i: any) => {
                  return (
                    <S.HeaderCard>
                      <img src={i.image} />
                      <h1 style={{}} >{i.title}</h1>
                    </S.HeaderCard>
                  );
                })}
              {/* <S.HeaderCard>
                <img src="https://shop.argumentdriveninquiry.com/cdn/shop/files/Mock_Ups_for_Shopify_-_Elem_and_MS_5th_grade_ngss_kit_360x.png?v=1720195539" />
                <h1>Argument-Driven Inquiry for Kindergarten Book (Texas Edition)</h1>
              </S.HeaderCard>
              <S.HeaderCard>
                <img src="https://shop.argumentdriveninquiry.com/cdn/shop/files/Mock_Ups_for_Shopify_-_Elem_and_MS_5th_grade_ngss_kit_360x.png?v=1720195539" />
                <h1>Argument-Driven Inquiry for Kindergarten Book (Texas Edition)</h1>
              </S.HeaderCard> */}
            </S.StackHorizontal>
            <h6>{`Our ${selectedItem.title || 'K-2 Texas'} program... `}</h6>
            <S.ButtonWrapper>
            {/* <S.ButtonWrapper> */}
              <a
                href={
                   selectedItem?.book ||'https://app-na1.hubspotdocuments.com/documents/9159866/view/981748091?accessId=b064ea'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.FilledButton>Download an Example</S.FilledButton>
              </a>
            {/* </S.ButtonWrapper> */}
              {/* <S.FilledButton onClick={() => console.log('Learn More')}>Download an Example</S.FilledButton> */}
              <S.OutlineButton onClick={() => console.log('Sign Up')}>Look Inside One</S.OutlineButton>
            </S.ButtonWrapper>
          </S.RightContent>
        </S.StackVertical>
      </S.ModalContent>
    </S.Modal>
  );
});

const CompanyModal = React.forwardRef((props: any, ref: any) => {
  const [selectedSection, setSelectedSection] = useState<keyof typeof section>('About');

  const section = {
    About: <AboutSection />,
    'Our Founders': <FounderSection />,
  };

  return (
    <S.Modal style={{ left: '-6rem' }} ref={ref}>
      <S.ModalContent onClick={(e) => e.stopPropagation()}>
        <S.LeftContent style={{ minWidth: 'min-content' }}>
          <ul>
            <h1 style={{ fontSize: '1.5rem' }}>Our Company</h1>
            {Object.keys(section).map((item) => (
              <li
                key={item}
                style={{
                  cursor: 'pointer',
                  fontSize: '0.9rem',
                  fontWeight: selectedSection === item ? 'bold' : 'normal',
                }}
                onClick={() => setSelectedSection(item as keyof typeof section)}
              >
                {item}
              </li>
            ))}
            <li>
              <a
                href="https://www.argumentdriveninquiry.com/our-story"
                style={{ color: 'black', display: 'block', fontSize: '0.9rem', fontWeight: 'normal' }}
              >
                Our Story
              </a>
            </li>
            <li>
              <a
                href="https://www.argumentdriveninquiry.com/careers"
                style={{ color: 'black', display: 'block', fontSize: '0.9rem', fontWeight: 'normal' }}
              >
                Careers
              </a>
            </li>
            <li>
              <a
                href="https://www.argumentdriveninquiry.com/blog"
                style={{ color: 'black', display: 'block', fontSize: '0.9rem', fontWeight: 'normal' }}
              >
                Blog
              </a>
            </li>
          </ul>
        </S.LeftContent>
        <S.Divider />
        <S.RightContent style={{ minWidth: '892px' }}>{section[selectedSection]}</S.RightContent>
      </S.ModalContent>
    </S.Modal>
  );
});

const AboutSection = (props: any) => {
  return (
    <>
      <S.GridWrapper style={{ gap: '1.5rem' }}>
        <ul style={{ width: '125px' }}>
          <li>Mailing Address</li>
          <li style={{ fontWeight: 'lighter' }}>12707 Nutty Brown Road Suite G-100 Austin, TX, 78738</li>
        </ul>
        <ul style={{ width: '250px' }}>
          <li>Email</li>
          <li style={{ fontWeight: 'lighter' }}>howdy@argumentdriveninquiry.com</li>
        </ul>
        <ul style={{ width: '190px' }}>
          <li>Phone</li>
          <li style={{ fontWeight: 'lighter' }}>(512) 777-4099</li>
        </ul>
        <ul style={{ width: '190px' }}>
          <li>Fax</li>
          <li style={{ fontWeight: 'lighter' }}>(512) 777-4099</li>
        </ul>
      </S.GridWrapper>
      <S.ButtonWrapper style={{ marginTop: '4rem', marginLeft: '10rem', padding: '1.2rem' }}>
        <a
          href="https://app-na1.hubspotdocuments.com/documents/9159866/view/981746659?accessId=84ee11"
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.FilledButton>Download our Vender Package</S.FilledButton>
        </a>
        <S.OutlineButton>
          <a href="https://shop.argumentdriveninquiry.com/" target="_blank" rel="noopener noreferrer">
            Ways to Purchase from Us
          </a>
        </S.OutlineButton>
      </S.ButtonWrapper>
    </>
  );
};

// const OurStorySection = (props: any) => {
//   return (
//     <>
//       <S.Title> Our Story</S.Title>
//       <S.ProfileContainer>
//         <S.ProfileImageWrapper style={{ marginTop: '0rem', borderRadius: '20%' }}>
//           <S.ProfileImage
//             src={storyImg}
//             alt="Profile"
//             style={{ height: '300px', width: '300px', objectFit: 'contain' }}
//           />
//         </S.ProfileImageWrapper>
//         <S.ProfileContent>
//           <S.ProfileName>An idea for new way of teaching</S.ProfileName>
//           <S.ProfileDescription>
//             The idea for the ADI instructional model that we use to create all our instructional materials for math and
//             science started out in a classroom in Phoenix, Arizona. Victor Sampson decided to create a new way of
//             teaching because the way people taught science at that time was too prescriptive, misrepresented how
//             scientists do science, and did not give enough students a chance to learn how to figure things out. He then
//             started using this brand-new approach in his own classroom and saw how it can completely transform what
//             teaching and learning looks like.
//           </S.ProfileDescription>
//         </S.ProfileContent>
//       </S.ProfileContainer>
//       <S.ArrowContainer style={{ marginLeft: '50%' }}>
//         <FaRegArrowAltCircleLeft style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'rgb(0 0 0 / 50%) ' }} />
//         <FaRegArrowAltCircleRight style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'rgb(0 0 0 / 50%)' }} />
//       </S.ArrowContainer>
//     </>
//   );
// };
// const CareersSection = (props: any) => {
//   return (
//     <>
//       <S.Title> Careers</S.Title>
//       <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
//         <S.ProfileImageWrapper style={{ marginTop: '0rem', borderRadius: '0' }}>
//           <S.ProfileImage
//             src={careerImg}
//             alt="Profile"
//             style={{ height: '300px', width: '300px', objectFit: 'contain' }}
//           />
//         </S.ProfileImageWrapper>
//         <S.ProfileContainer style={{ marginTop: '2rem', flexDirection: 'column' }}>
//           <S.ProfileContent>
//             <S.ProfileName>We figure things out</S.ProfileName>
//             <S.ProfileDescription>
//               We do things that have never done before, and we create things that are different from what everyone else
//               is doing. That means we don’t have a roadmap or set of directions to follow. That also means that we can’t
//               stop or give up when we don’t know how to do something yet.
//             </S.ProfileDescription>
//           </S.ProfileContent>
//           <S.ArrowContainer>
//             <FaRegArrowAltCircleLeft style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'rgb(0 0 0 / 50%) ' }} />
//             <FaRegArrowAltCircleRight style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'rgb(0 0 0 / 50%)' }} />
//           </S.ArrowContainer>
//         </S.ProfileContainer>
//       </div>
//     </>
//   );
// };
// const BlogSection = (props: any) => {
//   return (
//     <>
//       <S.ProfileName style={{ marginLeft: '50%' }}>Blog</S.ProfileName>
//       <S.ProfileContainer style={{ border: '2px solid gray', borderRadius: '10px', padding: '1rem' }}>
//         <S.ProfileImageWrapper style={{ marginTop: '-2rem', borderRadius: '0px', width: '30%', height: 'auto' }}>
//           <S.ProfileImage src={newsImg} alt="Profile" />
//         </S.ProfileImageWrapper>
//         <S.ProfileContent>
//           <S.ProfileDescription>
//             <h1 style={{ fontSize: '1rem' }}>
//               Evaluating Science Instructional <br /> Materials for Grades K-5
//             </h1>
//             <span>September 27, 2023</span> <br />
//             <span style={{ fontWeight: 'bold' }}>ADI Team</span>
//             <h2 style={{ fontSize: '1rem' }}>
//               Creating a Vibrant Science Classroom with Argument <br /> -Driven Inquiry
//             </h2>
//             <p>
//               In the quest to provide quality science education for students in grades K-5, educators rely on
//               instructional more.......
//             </p>
//             <S.SocialWrapper>
//               <FaFacebook />
//               <FaTwitter />
//               <IoMdMail />
//             </S.SocialWrapper>
//             <a href="https://www.argumentdriveninquiry.com/blog" target="_blank" rel="noopener noreferrer">
//               <S.MoreIcon>
//                 View More
//                 <FaRegArrowAltCircleRight
//                   style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'rgb(0 0 0 / 50%) ' }}
//                 />
//               </S.MoreIcon>
//             </a>
//           </S.ProfileDescription>
//         </S.ProfileContent>
//       </S.ProfileContainer>
//       <S.ArrowContainer style={{ marginLeft: '50%' }}>
//         <FaRegArrowAltCircleLeft style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'rgb(0 0 0 / 50%) ' }} />
//         <FaRegArrowAltCircleRight style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'rgb(0 0 0 / 50%)' }} />
//       </S.ArrowContainer>
//     </>
//   );
// };
const FounderSection = (props: any) => {
  const founders = [
    {
      name: 'Krista Sampson',
      description:
        'Krista Sampson is the founder, majority owner, and CEO of Argument-Driven Inquiry (ADI), a STEM education company she launched in 2015. With a mission to transform science, engineering, and mathematics education, ADI provides innovative, rigorous, and equitable instructional materials alongside high-quality professional development for teachers. Since its inception, ADI has impacted over 100,000 teachers across 800+ school districts in 40 + states, supporting educators in delivering accessible and meaningful STEM learning experiences. Krista began her career as an elementary teacher, bringing hands-on learning to high-need schools in Seattle, Washington, and Phoenix, Arizona. Her dedication to equitable education fueled her transition into educational business management, where she now drives ADI’s growth and commitment to supporting teachers. Under her leadership, ADI has become a recognized leader in STEM education, and her entrepreneurial achievements have been celebrated, including recognition on the Forbes Next 1000 list in 2021 and the 2022 Austin Woman’s Way Business Award in STEM. A graduate of Washington State University, Krista’s journey from classroom teacher to CEO reflects her unwavering commitment to improving STEM education and empowering teachers nationwide.',
      image: kristaImg,
      title: 'CEO of Argument-Driven Inquiry (ADI)',
    },
    {
      name: 'Victor Sampson',
      description:
        'Victor Sampson is an Associate Professor of STEM (science, technology, engineering, and mathematics) education at The University of Texas at Austin (UT-Austin). He received a BA in zoology from the University of Washington, an MIT from Seattle University, and a PhD in curriculum and instruction with a specialization in science education from Arizona State University. Victor also taught high school biology and chemistry for nine years in public schools. He is an expert in argumentation and three-dimensional instruction in science education, teacher learning, and assessment. He is also an expert in the design and development of new educational apps. Victor has published 55 research articles in the field of STEM education. While at Florida State University (2007-2014) and University of Texas (2014-current) he served as the PI or a Co-PI on 6 large research projects that were funded by either the National Science Foundation (NSF) or the Institute of Education Sciences (IES). Victor has received several awards for his scholarship, including the 2008 National Association for Research in Science Teaching (NARST) Outstanding Dissertation award and the 2012 NARST Early Career award. He is also a National Science Teaching Association (NSTA) Fellow.',
      image: joeImg,
      title: 'Creator of ADI and Associate Professor, University of Texas at Austin',
    },
  ];
  const [currentFounderIndex, setCurrentFounderIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentFounderIndex((prevIndex) => (prevIndex === 0 ? founders.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentFounderIndex((prevIndex) => (prevIndex === founders.length - 1 ? 0 : prevIndex + 1));
  };

  const { name, description, image, title } = founders[currentFounderIndex];
  return (
    <div style={{ overflowY: 'auto' }}>
      <S.Title>Our Founder</S.Title>
      <S.ProfileContainer>
        <S.ProfileImageWrapper>
          <S.ProfileImage src={image} alt="Profile" />
        </S.ProfileImageWrapper>
        <S.ProfileContent>
          <S.ProfileName>{name}</S.ProfileName>
          <S.ProfileTitle>{title}</S.ProfileTitle>
          <S.ProfileDescription>{description}</S.ProfileDescription>
        </S.ProfileContent>
      </S.ProfileContainer>
      <S.ArrowContainer>
        <S.IconButton>
          <FaArrowLeft size={20} onClick={handlePrevious} />
        </S.IconButton>
        <S.IconButton>
          <FaArrowRight size={20} onClick={handleNext} />
        </S.IconButton>
      </S.ArrowContainer>
    </div>
  );
};

export default Navbar;
