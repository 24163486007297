import React from 'react';
import Input from '../../../../../shared/Input';
import Select from '../../../../../shared/Select';
import SelectOption from '../../../../../shared/Select/Option';
import Spacer from '../../../../../shared/Spacer';
import UploadInput from '../../../../../shared/UploadInput';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  IButtonContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';

interface IButtonBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
}

const ButtonBlockEditor = (props: IButtonBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
  } = props;

  const buttonValues = editingBlock.values?.map((v) => v as IButtonContentValue);

  const beforeUploadVideo = (file: File, index: number) => {
    handleEditButton({
      title: buttonValues[index]?.text,
      link: buttonValues[index]?.url,
      color: buttonValues[index]?.color,
      file,
      index,
    });
    return true;
  };

  const handleClearVideo = (e: React.MouseEvent<SVGElement, MouseEvent>, index: number) => {
    e.stopPropagation();

    handleEditButton({
      title: buttonValues[index]?.text,
      link: buttonValues[index]?.url,
      color: buttonValues[index]?.color,
      file: undefined,
      index,
    });
  };

  const handleEditButton = (payload: {
    title?: string;
    link?: string;
    color?: string;
    file?: File;
    columns?: number;
    index?: number;
  }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;

              if (payload.columns) {
                return {
                  ...block,
                  columns: (payload.columns ?? 0) > 3 ? 3 : payload.columns,
                  values: block.values?.slice(0, payload.columns),
                };
              }

              const valuesCopy = [...block.values];
              valuesCopy[payload.index || 0] = {
                text: payload.title || '',
                url: payload.link,
                file: payload.file,
                color: payload.color,
              };

              return { ...block, values: valuesCopy };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  return (
    <>
      <h2>Grid Size</h2>
      <Input
        value={editingBlock.columns}
        backgroundColor="white"
        max={3}
        min={1}
        type="number"
        onChange={(e) =>
          handleEditButton({
            columns: Number(e.target.value),
          })
        }
      />
      {Array(editingBlock?.columns || 1)
        .fill(0)
        .map((_, index) => (
          <>
            <S.HorizontalDivider />
            <Spacer />
            <h2>Button {index + 1} Color</h2>
            <Select
              value={buttonValues[index]?.color || 'primary'}
              onChange={(v) =>
                handleEditButton({
                  title: buttonValues[index]?.text,
                  link: buttonValues[index]?.url,
                  color: v as string,
                  file: buttonValues[index]?.file,
                  index: index,
                })
              }
            >
              <SelectOption value="primary">Primary</SelectOption>
              <SelectOption value="secondary">Secondary</SelectOption>
            </Select>
            <Spacer />
            <h2>Button {index + 1} Text</h2>
            <Input
              value={buttonValues[index]?.text}
              onChange={(e) =>
                handleEditButton({
                  title: e.target.value,
                  link: buttonValues[index]?.url,
                  color: buttonValues[index]?.color,
                  file: buttonValues[index]?.file,
                  index: index,
                })
              }
              backgroundColor="white"
              placeholder="Add button title"
            />
            <Spacer />
            <h2>Button {index + 1} File</h2>
            <UploadInput
              beforeUpload={(f) => beforeUploadVideo(f, index)}
              title={buttonValues[index]?.file?.name || 'Upload File'}
              showClear={!!buttonValues[index]?.file}
              onClear={(e) => handleClearVideo(e, index)}
              defaultFileList={[]}
              fileList={[]}
              customRequest={() => {}}
            />
            <Spacer />
            <h2>Button {index + 1} Link</h2>
            <Input
              value={buttonValues[index]?.url}
              onChange={(e) =>
                handleEditButton({
                  link: e.target.value,
                  title: buttonValues[index]?.text,
                  color: buttonValues[index]?.color,
                  file: buttonValues[index]?.file,
                  index: index,
                })
              }
              backgroundColor="white"
              placeholder="Add button link"
            />
          </>
        ))}
      <Spacer />
    </>
  );
};

export default ButtonBlockEditor;
