import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: 32px 40px;
  height: 100%;

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 129.1%;
    padding: 0 24px;
    /* or 26px */

    text-align: center;

    color: #767676;
  }
`;

export const IconContainer = styled.div<{ newAssessment?: boolean }>`
  background: ${(props) => (props.newAssessment ? '#D8EEE6' : '#ceecfc')};
  border-radius: 50%;
  padding: 24px;

  height: 96px;
  width: 96px;

  svg {
    height: 100%;
    width: 100%;
    color: ${(props) => (props.newAssessment ? '#49A986' : '#3F95BC')};
  }
`;
