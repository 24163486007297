import { Modal } from 'antd';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Select from '../../../shared/Select';

export const Title = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  margin-bottom: 5px;
`;

export const Info = styled.p`
  font-weight: 400;
  margin: 0;
  text-align: center;
`;

export const ConfirmMessageBody = styled.div`
  width: 500px;
`;

export const ChangeAdminSelect = styled(Select)`
  background: transparent !important;
`;

export const ChangeAdminModal = styled(Modal)`
  width: 95% !important;
  max-width: 800px;
  .ant-modal-body {
    margin: auto;
    width: 90%;
    padding-bottom: 80px;
    padding-top: 40px;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 125.1%;
      color: #767676;
    }
  }
`;

export const OkButton = styled(Button)`
  max-height: 40px;
  position: relative;
  top: 28px;
`;

export const SetTeacherButton = styled(Button)`
  height: 40px;
`;

export const TitleTab = styled.p`
  font-weight: 700;
  padding: 0;
  font-size: 1.1em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;
