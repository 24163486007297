import { Row, Tooltip, Typography } from 'antd';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { InsightsData } from '../InvestigationInsight';
import Spacer from '../../../shared/Spacer';
import { GQL_InvestigationTeacherEntry } from '../../../types/investigation';
import { palette } from '../../../utils/theme';
import * as S from './styles';

interface IInsightsTableProps {
  loading?: boolean;
  data?: InsightsData[];
}

const columns = [
  {
    title: 'Investigation ID',
    dataIndex: 'investigationTitle',
    key: 'investigationTitle',
    render: (text: any, _: any, index: number) => (
      <S.InsightsTitleContainer>
        <S.InsightTitleDot color={palette[index % palette.length]}>{index + 1}</S.InsightTitleDot>
        <Spacer axis="horizontal" />
        {text}
      </S.InsightsTitleContainer>
    ),
    fixed: 'left',
    width: 240,
  },
  {
    title: 'Graded Students',
    dataIndex: 'gradedStudents',
    key: 'gradedStudents',
    sorter: (a: any, b: any) => a.gradedStudents - b.gradedStudents,
  },
  {
    title: 'Report Score',
    dataIndex: 'reportScore',
    key: 'reportScore',
    sorter: (a: any, b: any) => a.reportScore - b.reportScore,
    render: (text: any, item: any) => `${text?.toFixed(2) || 0} / ${item.maxReportScore?.toFixed(2) || 0}`,
  },
  {
    title: () => (
      <Tooltip title="Any score at the 100% mark indicates the student has met performance expectations. Any score past the 100% mark indicates the student has exceeded performance expectations.">
        <Row align="middle" gutter={[4, 0]}>
          <AiOutlineInfoCircle style={{ marginTop: -4, marginRight: 4 }} />
          <Typography.Text>% Report Score</Typography.Text>
        </Row>
      </Tooltip>
    ),
    showSorterTooltip: false,
    dataIndex: 'reportAverage',
    key: 'reportAverage',
    sorter: (a: any, b: any) => a.reportAverage - b.reportAverage,
    render: (text: any) => Math.round(text * 100) + '%',
  },
  {
    title: 'Engagement Score',
    dataIndex: 'engagementAverage',
    key: 'engagementAverage',
    sorter: (a: any, b: any) => a.engagementAverage - b.engagementAverage,
    render: (text: any) => (text * 5).toFixed(1),
  },
];

const InsightsTable = (props: IInsightsTableProps) => {
  const { data, loading } = props;

  return (
    <S.StyledInsightsTable
      dataSource={data}
      columns={columns as any}
      scroll={{ x: 'max-content' }}
      loading={loading}
      rowKey={(record) => (record as GQL_InvestigationTeacherEntry).investigationId}
    />
  );
};

export default InsightsTable;
