import { createReducer } from '../../utils/redux';

// Constants
export const constants = {
  LOADINGS_CLEANUP: 'LOADINGS_CLEANUP',
};

// Reducer
const initialState = {};

export default createReducer(initialState, (state, action) => {
  const module = action.type.split('_')[0].toLowerCase();

  if (action.type.endsWith('.LOADING')) {
    return {
      ...state,
      [module]: true,
    };
  } else if (action.type.endsWith('.FAILED') || action.type.endsWith('.SUCCESS')) {
    return {
      ...state,
      [module]: false,
    };
  }
  switch (action.type) {
    case constants.LOADINGS_CLEANUP:
      return initialState;
    default:
      return state;
  }
});
