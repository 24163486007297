import { Col, message, Row, Table, TablePaginationConfig, Tooltip } from 'antd';
import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import * as S from './styles';
import { SortOptions } from '../../../types/sorting';
import { GQL_FacilitatorsChartInput, GQL_WorkshopAssignmentRateForFacilitatorsResponse } from '../../../types/charts';
import { Duration } from '../../../types/investigation';
import { FiHelpCircle, FiSearch } from 'react-icons/fi';
import { formatDateTime } from '../../../utils/date';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import RangePicker from '../../RangePicker';
import { centerAlign } from '../../../utils/antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import TableSearchBox from '../../TableSearchBox';
import Button from '../../Button';
import WorkshopParticipationDetail from './Details';

const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
  endDate: formatDateTime(new Date().getTime()),
};

const WorkshopParticipation = () => {
  const [visible, setVisible] = useState(false);
  const [sorting, setSorting] = useState<SortOptions>(SortOptions.DESC);
  const [period, setPeriod] = useState<Duration>(defaultDateRange);
  const [nameToFilter, setNameToFilter] = useState('');
  const [lastNameToFilter, setLastNameToFilter] = useState('');
  const [emailToFilter, setEmailToFilter] = useState('');
  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchLastNameVisible, setSearchLastNameVisible] = useState(false);
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const [currentFacilitator, setCurrentFacilitator] = useState('');

  const [getFacilitatorsChart, { data, loading }] = useLazyQuery<
    { getFacilitatorsChart: GQL_WorkshopAssignmentRateForFacilitatorsResponse[] },
    { data: GQL_FacilitatorsChartInput }
  >(gqlSchema.ChartSchema.queries.CHARTS.getFacilitatorsChart, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      message.error('There was an error loading workshop participation: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    const data: GQL_FacilitatorsChartInput = {
      startDate: period.startDate,
      endDate: period.endDate,
      email: emailToFilter,
      name: `${nameToFilter} ${lastNameToFilter}`,
    };
    if (sorting) data.order = sorting;
    getFacilitatorsChart({
      variables: {
        data,
      },
    });
  }, [emailToFilter, getFacilitatorsChart, nameToFilter, lastNameToFilter, period, sorting]);

  const columns = useMemo(
    () => [
      {
        title: 'First Name',
        align: centerAlign,
        dataIndex: 'facilitatorFirstName',
        key: 'u.facilitatorFirstName',
        width: '20%',
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox ref={refName} onClearFilters={() => setNameToFilter('')} {...filterProps} />
        ),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean) => {
          if (!value) setNameToFilter('');
          else setNameToFilter(value.toString());
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Last Name',
        align: centerAlign,
        dataIndex: 'facilitatorLastName',
        key: 'u.facilitatorLastName',
        width: '20%',
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox ref={refLastName} onClearFilters={() => setLastNameToFilter('')} {...filterProps} />
        ),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchLastNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean) => {
          if (!value) setLastNameToFilter('');
          else setLastNameToFilter(value.toString());
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchLastNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refLastName && refLastName.current) {
                refLastName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Email',
        align: centerAlign,
        dataIndex: 'facilitatorEmail',
        key: 'u.facilitatorEmail',
        width: '20%',
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox onClearFilters={() => setEmailToFilter('')} ref={refEmail} {...filterProps} />
        ),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean) => {
          if (!value) setEmailToFilter('');
          else setEmailToFilter(value.toString());
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },

      {
        title: 'Courses',
        align: centerAlign,
        sorter: true,
        width: '12%',
        render: (text: string, record: GQL_WorkshopAssignmentRateForFacilitatorsResponse) => {
          return record.coursesCount;
        },
      },
      {
        title: 'Workshops',
        align: centerAlign,
        width: '12%',
        render: (text: string, record: GQL_WorkshopAssignmentRateForFacilitatorsResponse) => {
          return record.workshopsCount;
        },
      },
      {
        title: 'Details',
        align: centerAlign,
        render: (text: string, record: GQL_WorkshopAssignmentRateForFacilitatorsResponse) => {
          return (
            <Button
              text="Details"
              onClick={() => {
                setCurrentFacilitator(record.facilitatorId);
                setVisible(true);
              }}
              block
              disabled={record.coursesCount === 0}
            />
          );
        },
        width: '15%',
      },
    ],
    [searchEmailVisible, searchNameVisible, searchLastNameVisible],
  );

  const handleTableChange = useCallback((pagination: TablePaginationConfig, filters, sorter) => {
    const order = sorter.order ? (sorter.order === 'ascend' ? SortOptions.ASC : SortOptions.DESC) : SortOptions.ASC;
    setSorting(order);
  }, []);

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Workshop Participation</S.Title>
          <Tooltip title="Number of students enrolled in a course/event within a given time period">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Row gutter={[16, 24]}>
            <Col xxl={6} xl={6} lg={7} md={24} sm={24}>
              <RangePicker
                value={period}
                onChange={(duration: Duration) => {
                  setPeriod(duration);
                }}
                allowClear={false}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_WorkshopAssignmentRateForFacilitatorsResponse) => record.facilitatorId}
              bordered
              pagination={{ hideOnSinglePage: true }}
              dataSource={data?.getFacilitatorsChart}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <WorkshopParticipationDetail
        visible={visible}
        setVisible={setVisible}
        endDate={period.endDate}
        startDate={period.startDate}
        facilitatorId={currentFacilitator}
      />
    </>
  );
};

export default WorkshopParticipation;
