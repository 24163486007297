import { Col, Row, Table } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useMemo, useRef, useState } from 'react';
import { centerAlign, Breakpoint } from '../../../../utils/antd';
import * as S from './styles';
import { GQL_OpenInvestigationsChartResponse } from '../../../../types/charts';
import TableSearchBox from '../../../TableSearchBox';
import { FiSearch } from 'react-icons/fi';
import DetailInvestigations from '../DetailInvestigations';
import useTagColumn from '../../../../hooks/useTagColumn';

const TableBreakPoint: Breakpoint[] = ['lg'];

interface Props {
  loading: boolean;

  data?: GQL_OpenInvestigationsChartResponse[];
}

const DetailTeachers = (props: Props) => {
  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchLastNameVisible, setSearchLastNameVisible] = useState(false);
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const { columnField } = useTagColumn({ filterOnBackend: true });

  const { loading, data } = props;

  const columns = useMemo(() => {
    return [
      {
        title: 'First Name',
        align: centerAlign,
        dataIndex: 'teacherFirstName',
        width: '15%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_OpenInvestigationsChartResponse) => {
          return record.teacherFirstName.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Last Name',
        align: centerAlign,
        dataIndex: 'teacherLastName',
        width: '15%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refLastName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchLastNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_OpenInvestigationsChartResponse) => {
          return record.teacherLastName.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchLastNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refLastName && refLastName.current) {
                refLastName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Email',
        align: centerAlign,
        dataIndex: 'teacherEmail',
        width: '20%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refEmail} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_OpenInvestigationsChartResponse) => {
          return record.teacherEmail.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      columnField,
      {
        title: 'Open Investigations',
        align: centerAlign,
        width: '15%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_OpenInvestigationsChartResponse) => {
          return record.openInvestigations?.length || 0;
        },
      },
    ];
  }, [searchEmailVisible, searchLastNameVisible, searchNameVisible, columnField]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_OpenInvestigationsChartResponse) => record.teacherId || ''}
              bordered
              dataSource={data}
              expandable={{
                expandedRowRender: (record) => <DetailInvestigations loading={loading} data={record} />,
                columnWidth: 50,
              }}
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default DetailTeachers;
