import { createReducer, createSagaAction } from '../../utils/redux';
import { INotification } from '../../types';

export const constants = {
  NOTIFICATION_LOAD: createSagaAction('NOTIFICATION_LOAD'),
};

export const actions = {
  notificationLoad: () => ({
    type: constants.NOTIFICATION_LOAD.ACTION,
  }),
};

export interface INotificationStore {
  notifications: Array<INotification>;
}

// Reducer
const initialState: INotificationStore = {
  notifications: [],
};

export default createReducer(initialState, (state: INotificationStore, action) => {
  switch (action.type) {
    case constants.NOTIFICATION_LOAD.SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
});
