import styled from 'styled-components';
export const TitleError = styled.h3`
  font-size: 1.4em;
`;

export const LegendTitle = styled.span`
  font-size: 13px;
`;

export const Card = styled.div<{
  $height: string;
}>`
  width: 100%;
  background: white;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: ${(props) => props.$height};
  justify-content: center;
  align-items: center;
  text-align: center;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 129.1%;
    padding: 0 24px;
    text-align: center;
    color: #767676;
  }

  g > g > g > text,
  g > g > text {
    font-size: 16px !important;
  }

  g > g > text[dominant-baseline='central'] {
    font-size: 18px !important;
  }
  rect {
    cursor: pointer;
  }
`;
