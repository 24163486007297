import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5em;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  .middle {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .ant-skeleton-element {
    width: 100%;
  }
`;
