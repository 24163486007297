import { Button, Popover } from 'antd';
import { GrClose } from 'react-icons/gr';
import styled from 'styled-components';

export const TooltipArea = styled(Popover)``;

export const TooltipCloseButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }
`;

export const TooltipCloseButtonIcon = styled(GrClose)`
  path {
    stroke: white;
  }
`;
