import React, { useCallback } from 'react';

import { themeConfig } from '../../utils/theme';
import Button from '../Button';
import { FreeUserModal } from './freeUserModal';

const FreeTeacher = () => {
  const [freeUserVisible, setfreeUserVisible] = React.useState(false);

  const handleCloseFreeUserModal = useCallback(() => {
    setfreeUserVisible(false);
  }, [setfreeUserVisible]);

  return (
    <>
      <Button
        data-cy="shared-requestaccount-button"
        text="Create a Free Teacher Account"
        theme={themeConfig.primaryColor}
        shape="round"
        padding="0px 15px"
        onClick={() => setfreeUserVisible(true)}
      />

      <FreeUserModal isOpen={freeUserVisible} onClose={handleCloseFreeUserModal} />
    </>
  );
};

export default FreeTeacher;
