import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 214px;
  padding: 54px 27px;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  cursor: pointer;
`;

export const Title = styled.h1`
  text-align: left;
  font-weight: 600;
  margin-bottom: 0.3em;
  font-size: 30px;
`;

export const Info = styled.h2`
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  text-align: left;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
