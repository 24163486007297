import { Row, Col } from 'antd';
import styled from 'styled-components';

export const Container = styled(Row)`
  marging: 4em;
`;

export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 6px;
  padding: 40px;
  display: flex;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  font-size: 1.5rem;

  h2 {
    margin: 0;
  }
`;

export const SearchIcon = styled.span<{
  $searchVisible: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 10px 4px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => (props.$searchVisible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px' : 'unset')};
`;

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const TableTitleContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 13px;
  }
`;

export const StudentInvestigationStatus = styled.div<{
  color: string;
}>`
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${({ color }) => color};
`;

export const StatusChip = styled.div<{
  status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED';
  clickable: boolean;
  color: string;
}>`
  width: 100%;
  border-radius: 35px;
  text-align: center;
  padding: 4px 8px;
  color: ${({ status }) => (status === 'NOT_STARTED' ? '#3F3E38' : '#fff')};
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'not-allowed')};
  background: ${({ color }) => color};

  & > svg {
    margin-bottom: 2px;
  }
`;

export const AssessmentStatusChip = styled.div<{
  status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED';
  clickable: boolean;
  dueDate: number;
}>`
  width: 100%;
  border-radius: 35px;
  text-align: center;
  padding: 4px 8px;
  color: ${({ status, dueDate }) => (status === 'NOT_STARTED' && dueDate < new Date().getDate() ? '#3F3E38' : '#fff')};
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'not-allowed')};
  background: ${({ status, dueDate }) => {
    if (status === 'COMPLETED') return '#20BC89';
    else if (dueDate < new Date().getDate()) return '#E9A643';
    else if (status === 'IN_PROGRESS') return '#E9A643';
    else if (status === 'NOT_STARTED') return '#4367e9';
    else if (status === 'READY_FOR_GRADING') return '#4367E9';
    else if (status === 'OVERDUE') return '#EA3535';
  }};
`;
