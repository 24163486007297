import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f0f2f5;
   
     @media (max-width: 480px) {    
    padding: 5px;  
  }
`;

export const RowForm = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .ant-form {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
    .ant-form-item-control {
    width: 100%;
  }
    input {
    width: 100%;
    
  }
`;

export const Title = styled.h1`
  text-align: center; 
  color: #3f3e38;
  
  font-size: 32px; 
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;
// export const Title = styled.h1`
//   margin-bottom: 25px;
//   text-align: center;
//   margin-top: 15px;
//   font-weight: 700;
//   font-size: 40px;
//   color: #3f3e38;

//   @media (max-width: 768px) {
//     font-size: 24px;
//   }

//   @media (max-width: 480px) {
//     font-size: 24px;
//   }
// `;

export const RowSP = styled(Row)`
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  justify-content: center; 
  align-items: center; 
  margin: 0; 
  row-gap: 0;
  overflow: auto;
  margin-left: -10%;
    margin-right: -10%;
    row-gap: 0px;
    width: 100%;

  @media (max-width: 992px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
    @media (max-width: 480px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

export const ColSP = styled(Col)`
   box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  background: #ffffff;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  img {
    height: 80px; 
    margin-bottom: 20px; 
    display: block; 
  }
     @media (max-width: 1278px) {
    padding: 50px;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 100%;
  }
  
`;
// export const ColSP = styled(Col)`
//   box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
//   padding: 60px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   border-radius: 6px;
//   background: #ffffff;
//   width: 100%;
//   max-width: 1200px;
//   word-wrap: break-word;
//   word-break: break-word;
//   overflow: visible;
 

//   @media (max-width: 1278px) {
//     padding: 50px;
//     max-width: 100%;
//   }

//   @media (max-width: 768px) {
//     padding: 20px;
//     max-width: 100%;

//     img{
//     height: 30px;
//     margin: 0 2px;
//     }
//   }
// `;

export const OrDivider = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: auto;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;


export const VerticalDivider = styled.span`
  width: 2px;
  background: #ededed;
  margin: 0 auto; 

  @media (max-width: 768px) {
    transform: rotate(90deg); 
    width: 100%; 
    height: 2px; 
    margin: 8px 0; 
  }

  @media (max-width: 480px) {
    width: 100%; 
    height: 2px; 
    margin: 6px 0; 
  }
`;

export const OrContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0; 
  height: auto;
  padding: 0 16px; 
  font-size: 16px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 12px 0;
    padding: 0 12px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    margin: 16px 0;
    width:100%;
    padding: 1 43px;
  }
`;

export const OrText = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  width: auto; 

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;


// Styled component for the column
export const ColR = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  @media (max-width: 480px) {
  width: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;

}
`;

