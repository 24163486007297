import { Col, Row, Table } from 'antd';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { centerAlign, Breakpoint } from '../../../../utils/antd';
import * as S from './styles';
import { GQL_InvestigationStageCompletionPerTeacher } from '../../../../types/charts';
import TableSearchBox from '../../../TableSearchBox';
import { FiSearch } from 'react-icons/fi';
import DetailStages from '../DetailStages';
import useTagColumn from '../../../../hooks/useTagColumn';

const TableBreakPoint: Breakpoint[] = ['lg'];

interface Props {
  loading: boolean;
  pagination?: TablePaginationConfig;
  setPagination?: Dispatch<SetStateAction<TablePaginationConfig | undefined>>;
  data?: GQL_InvestigationStageCompletionPerTeacher[];
}

const DetailTeachers = (props: Props) => {
  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchLastNameVisible, setSearchLastNameVisible] = useState(false);
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const { columnField } = useTagColumn({ filterOnBackend: true });

  const { loading, data, setPagination, pagination } = props;

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      if (pagination && setPagination) setPagination(pagination);
    },
    [setPagination],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'First Name',
        align: centerAlign,
        dataIndex: 'teacherFirstName',
        width: '23%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_InvestigationStageCompletionPerTeacher) => {
          return record.teacherFirstName.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Last Name',
        align: centerAlign,
        dataIndex: 'teacherLastName',
        width: '23%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refLastName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchLastNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_InvestigationStageCompletionPerTeacher) => {
          return record.teacherLastName.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchLastNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refLastName && refLastName.current) {
                refLastName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Email',
        align: centerAlign,
        dataIndex: 'teacherEmail',
        width: '29%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refEmail} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_InvestigationStageCompletionPerTeacher) => {
          return record.teacherEmail.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      columnField,
      {
        title: 'Average Stage Completion',
        align: centerAlign,
        width: '20%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_InvestigationStageCompletionPerTeacher) => {
          const completedUsers = record.stages.reduce((ret, cur) => (ret = cur.completedUsers), 0) || 0;
          const totalUsers = record.stages.length > 0 ? record.stages[0].totalUsers : 0;
          const avg = (completedUsers * 100) / totalUsers || 0;
          return `${avg.toFixed(2)} %`;
        },
      },
    ];
  }, [searchEmailVisible, searchLastNameVisible, searchNameVisible, columnField]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_InvestigationStageCompletionPerTeacher) => record.teacherId}
              pagination={pagination}
              bordered
              dataSource={data?.map((c) => ({ ...c, stages: c.stages.filter((d) => d.order > 0) }))}
              expandable={{
                expandedRowRender: (record) => (
                  <DetailStages
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    data={record.stages || []}
                  />
                ),
                columnWidth: 50,
              }}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default DetailTeachers;
