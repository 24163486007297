import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
`;

export const MainContainer = styled.div`
  background: white;
  border-radius: 6px;
  padding: 32px 40px;
  max-width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-picker {
    background: white;
    width: 100%;

    input {
      background: white;
    }
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    color: #000000;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 135%;
    /* or 24px */

    color: #767676;
    margin-bottom: 24px;
  }
`;

export const CenterContainer = styled.div`
  text-align: left;
  width: 380px;
  margin-top: 36px;
  margin-bottom: 80px;

  h3 {
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125.1%;
    /* identical to box height, or 23px */

    color: #767676;
  }
`;

export const Divider = styled.span`
  display: block;
  height: 1px;
  width: 100%;
  background: #c6c6c6aa;
  border-radius: 6px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;

  button:first-child {
    margin-right: 32px;
  }
`;

export const SelectOptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  svg {
    margin-top: 1px;
  }
`;

export const DisabledText = styled.span`
  color: #ddd;
  display: inline-block;
  width: max-content;
  font-style: italic;
`;
