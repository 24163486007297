import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { message, Progress } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'; // Import the DeleteOutlined icon
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import Button from '../../shared/Button';
import ModalConfirm from '../../shared/ModalConfirm';
import * as S from './styles';
import { GQL_classWork, GQL_GoogleClassroomStudents, GQL_GoogleClassroomCourses, GQL_StudentSubmission } from '../../types/googleClassroom'; // Import the correct type for students, course, and student submission
import { useAuth } from '../../hooks/useAuth';
import StudentSummaryTable from './StudentSummaryTable'; // Import the StudentSummaryTable component

interface Props {
  nameToCheck: string;
}

const GoogleAssignmentSummaryDashboard: React.FC<Props> = (props) => {
  const { courseId } = useParams<{ courseId: string }>();
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [numberOfStudents, setNumberOfStudents] = useState(0);
  const [className, setClassName] = useState('');
  const { user } = useAuth(); // Fetch the authenticated user

  const token = 'ya29.a0AXooCguL2D9XRSSd-cyVAsheen_q9jJzwccCBI0MshtgnFNdLkqd_5GPgSLvCsF3ZAGhsgRIRtRIbvFqa-TYvNXwuU_RgLNKdSV5dhxizyIxEjFx_FcT85W8eWPqADSqXiGrgcHOEjDI7YVLTRCIeMq-b-RMyFHI-27SP2z-ix3jno8HcRHKMjEeOUdpeUveiL16HwQaCgYKAVYSARISFQHGX2MiffDwQh775YOtmtH4dSVxWA0206'; // Replace with your actual token

  // Fetch class name query
  useQuery<{ getCourseByCourseId: GQL_GoogleClassroomCourses }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseByCourseId,
    {
      variables: {
        token,
        id: courseId,
      },
      onError: () => {
        message.error('Error fetching class data');
      },
      onCompleted: (data) => {
        if (data && data.getCourseByCourseId) {
          setClassName(data.getCourseByCourseId.name || 'Unknown');
        } else {
          setClassName('Unknown');
        }
      },
    }
  );

  // Fetching user name and email
  const assignmentOwnerName = user ? `${user.name} - ${user.email}` : 'Unknown';

  const { loading: courseWorkLoading, data: courseWorkData } = useQuery<{ getCourseWorkById: GQL_classWork }, { courseId: string, token: string, id: string }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
    {
      variables: {
        token,
        courseId,
        id: '68', // Replace with your actual ID
      },
      onError: () => {
        message.error('Error fetching assignment data');
      },
    }
  );

  const { loading: studentsLoading, data: studentsData } = useQuery<{ getCourseStudents: { students: GQL_GoogleClassroomStudents[] } }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudents,
    {
      variables: {
        token,
        courseId,
      },
      onError: () => {
        message.error('Error fetching students data');
      },
    }
  );

  const {  data: submissionData } = useQuery<{ getCourseWorkSubmissionList: GQL_StudentSubmission[] }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionList,
    {
      variables: {
        token,
        courseId,
        courseWorkId: '682', // Replace with your actual course work ID
      },
      onError: () => {
        message.error('Error fetching student submission data');
      },
    }
  );

  useEffect(() => {
    if (studentsData && studentsData.getCourseStudents && studentsData.getCourseStudents.students) {
      setNumberOfStudents(studentsData.getCourseStudents.students.length);
    }
  }, [studentsData]);

  const assignment = courseWorkData?.getCourseWorkById;

  // Calculate percentage of assignments submitted
  const submittedAssignments = submissionData?.getCourseWorkSubmissionList?.length || 0;
  const percentageSubmitted = numberOfStudents !== 0 ? (submittedAssignments / numberOfStudents) * 100 : 0;

  return (
    <>
      <PageWithTitle
        title={<S.TitleContainer>{assignment?.title || 'Assignment Title'}</S.TitleContainer>}
        backPageUrl={`/googleclassroom-teacher-dashboard/class/${courseId}`}
      >
        {assignment ? (
          <div className="assignment-details-card" style={{ width: '100%', padding: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Progress
                type="circle"
                percent={percentageSubmitted} // Show percentage of assignments submitted
                width={100}
              />
              <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 'bold', marginRight: '10px' }}>Class Name:</div>
                    <div>{className}</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 'bold', marginRight: '10px' }}>Assignment Name:</div>
                    <div>{assignment.title}</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 'bold', marginRight: '10px' }}>Assignment Owner:</div>
                    <div>{assignmentOwnerName}</div> {/* Display assignment owner name */}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 'bold', marginRight: '10px' }}>Number of Students:</div>
                    <div>{numberOfStudents}</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 'bold', marginRight: '10px' }}>Assignment Link:</div>
                    <div>{assignment?.materials[0].link?.url}</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 'bold', marginRight: '10px' }}>Subject Name:</div>
                    <div>{assignment.topicId}</div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 'bold', marginRight: '10px' }}>Open & Close Dates:</div>
                    <div>{`${assignment.creationTime} - ${assignment.dueDate || 'N/A'}`}</div>
                    <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                      <Button
                        data-cy="components-googleassignmentsummary-delete-button"
                        style={{ backgroundColor: 'red', borderRadius: '50%', width: '40px', height: '40px' }}
                        icon={<DeleteOutlined style={{ fontSize: '20px', color: 'white' }} />}
                        onClick={() => setDeleteConfirmVisible(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </PageWithTitle>

      <div className="student-summary-table-container" style={{ width: '100%', padding: '20px' }}>
  <StudentSummaryTable courseId={courseId} token={token} /> {/* Render the StudentSummaryTable component here */}
</div>

      <ModalConfirm
        visible={deleteConfirmVisible}
        setVisible={setDeleteConfirmVisible}
        title={`You are deleting ${assignment?.title || '[Assignment Title]'}`}
        lineInfo1={`Are you sure you want to delete ${assignment?.title || '[Assignment Title]'} for ${className}?`}
        lineInfo2={`All the progress and grades will be permanently deleted`}
        deleteButtonTitle={`Delete Assignment`}
        loading={courseWorkLoading || studentsLoading}
        deleteFn={() => {}} 
        errorMessage={`The assignment name is not the same`}
        nameToCheck={props.nameToCheck}
      />
    </>
  );
};

export default GoogleAssignmentSummaryDashboard;