import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Form as AntdForm, Col, Row, message, Divider } from 'antd';
import Form from '../../Form';
import Input from '../../Input';
import Button from '../../Button';
import * as S from './styles';
import { useForm } from 'antd/lib/form/Form';
import { GQL_CreateInviteInput, GQL_SendInviteResponse } from '../../../types/invites';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { startCase } from 'lodash';
interface Props {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  showTitle?: boolean;
  columnWith?: number | string;
  organizationId?: string;
  onUserCreated?: () => void;
  buttonTitle: string;
  role: string;
}

const GenericUser: React.FC<Props> = (props) => {
  const [form] = useForm();
  const { setVisible, buttonTitle, role, organizationId, onUserCreated, showTitle = true, columnWith = 24 } = props;
  const [createAdiAdmin, { loading }] = useMutation<{ createAndSendInvite: GQL_SendInviteResponse }>(
    gqlSchema.InvitesSchema.mutations.SEND.createAndSendInvite,
    {
      onError: (err) => {
        message.error(err.message);
      },
      onCompleted: (data) => {
        if (data?.createAndSendInvite.emailSent) {
          message.success(`${startCase(buttonTitle)} created successfully`);
          if (onUserCreated) onUserCreated();
          if (setVisible) setVisible(false);
          form.resetFields();
        }
      },
    },
  );

  const onFinish = useCallback(
    async (data: GQL_CreateInviteInput) => {
      const payload = { ...data, role };
      if (organizationId) payload.organizationId = organizationId;
      createAdiAdmin({ variables: { data: payload } });
    },
    [createAdiAdmin, organizationId, role],
  );

  return (
    <Row justify="center">
      <Col span={columnWith}>
        <Form onFinish={onFinish} form={form}>
          <Row gutter={[16, 16]} justify="center" style={{ marginTop: 10 }}>
            {showTitle && (
              <Col span={24}>
                <S.SubTitle>User Details</S.SubTitle>
                <Divider style={{ margin: '5px 0' }} />
              </Col>
            )}
            <Col span={7}>
              <S.TitleInput>First Name</S.TitleInput>
              <AntdForm.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Input the First Name!',
                  },
                ]}
              >
                <Input />
              </AntdForm.Item>
            </Col>
            <Col span={7}>
              <S.TitleInput>Last Name</S.TitleInput>
              <AntdForm.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Input the Last Name!',
                  },
                ]}
              >
                <Input />
              </AntdForm.Item>
            </Col>
            <Col span={10}>
              <S.TitleInput>Email</S.TitleInput>
              <AntdForm.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Input the Email!',
                  },
                  {
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </AntdForm.Item>
            </Col>
            <Col span={8}>
              <AntdForm.Item>
                <Button
                  data-cy="shared-adduser-genericuser-modal-submit-button"
                  block
                  loading={loading}
                  text={`Add ${buttonTitle}`}
                  htmlType="submit"
                />
              </AntdForm.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default React.memo(GenericUser);
