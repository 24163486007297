import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import { InsightsType } from './../../../constants/enums/InsightsTypes';

interface IInsightsFilterTypesProps {
  insightType: InsightsType;
}

const InsightsFilterTypes = (props: IInsightsFilterTypesProps) => {
  const { insightType } = props;
  const history = useHistory();

  const [selectedType, setSelectedType] = useState<string | undefined>(insightType);

  const handleTypeChange = useCallback(
    (value: string) => {
      setSelectedType(value);
      history.push(`/teacher-insights/${value.toLowerCase()}`);
    },
    [setSelectedType, history],
  );

  return (
    <Select
      data-cy="components-assessment-insights-type-dropdown"
      placeholder="Select a Type"
      showSearch
      value={selectedType}
      onChange={(v) => handleTypeChange(v as string)}
    >
      {Object.values(InsightsType).map((type, key) => {
        return (
          <SelectOption
            value={type}
            key={key}
            name={type}
            data-cy={`components-assessment-insights-type-dropdown-value-${type.toLowerCase()}`}
          >
            {type}
          </SelectOption>
        );
      })}
    </Select>
  );
};

export default InsightsFilterTypes;
