import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Row, Dropdown, Table, Menu, message } from 'antd';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import Papa from 'papaparse';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronDown, FiDownload, FiSearch } from 'react-icons/fi';
import { withRouter } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import Button from '../../../shared/Button';
import TableSearchBox from '../../../shared/TableSearchBox';
import { centerAlign, Breakpoint } from '../../../utils/antd';
import { downloadCsv } from '../../../utils/files';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';
import { pageSizeBase } from '../../../types/pagination';
import { useAuth } from '../../../hooks/useAuth';
import {
  GQL_QueryInviteFilterInput,
  GQL_QueryInviteResponse,
  GQL_QueryInviteResponsePaginated,
  GQL_SendInviteResponse,
} from '../../../types/invites';

const TableBreakPoint: Breakpoint[] = ['lg'];

const PendingInvites = () => {
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const [emailToFilter, setEmailToFilter] = useState('');
  const [rolesToFilter, setRolesToFilter] = useState<string[]>([]);
  const user = useAuth();
  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const refEmail = useRef<HTMLInputElement>(null);

  const [fetchInvites, { data, loading }] = useLazyQuery<
    { getInvites: GQL_QueryInviteResponsePaginated },
    { data: GQL_QueryInviteFilterInput }
  >(gqlSchema.InvitesSchema.queries.INVITES.getInvites, {
    onCompleted: (data) => {
      setPagination({
        current: data.getInvites.pagination.page,
        pageSize: data.getInvites.pagination.size,
        total: data.getInvites.pagination.totalCount,
      });
    },
    onError: (err) => {
      message.error('There was an error loading invites: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    fetchInvites({
      variables: {
        data: {
          pagination: {
            page: 1,
            size: pageSizeBase,
          },
        },
      },
    });
  }, [fetchInvites]);

  useEffect(() => {
    const data: GQL_QueryInviteFilterInput = {
      pagination: {
        page: pagination?.current || 1,
        size: pagination?.pageSize || pageSizeBase,
      },
      partOfEmail: emailToFilter,
    };

    if (rolesToFilter.length > 0) {
      data.roles = rolesToFilter;
    }

    if (user.isOrganizationAdiAdmin) {
      data.organizationId = user.user.subscription?.organizationId;
    }

    fetchInvites({
      variables: {
        data,
      },
    });
  }, [emailToFilter, rolesToFilter, fetchInvites, pagination, user]);

  const handleTableChange = useCallback((pagination: TablePaginationConfig, filters) => {
    setPagination(pagination);
    setRolesToFilter(filters.roles || []);
  }, []);

  const [sendInvites] = useMutation<{ sendInvites: GQL_SendInviteResponse[] }>(
    gqlSchema.InvitesSchema.mutations.SEND.sendInvites,
    {
      onCompleted: (data) => {
        message.success('Invite sent successfully');
      },
      onError: (err) => {
        message.error('There was an error trying to send the invite, please try again later');
      },
    },
  );

  const onResendInvite = useCallback(
    (userRow: GQL_QueryInviteResponse) => {
      sendInvites({
        variables: {
          data: {
            tokens: [userRow.id],
          },
        },
      });
    },
    [sendInvites],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Assistant Email',
        dataIndex: 'email',
        width: '20%',
        responsive: TableBreakPoint,
        sorter: (a: GQL_QueryInviteResponse, b: GQL_QueryInviteResponse) => a.email.localeCompare(b.email),
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox onClearFilters={() => setEmailToFilter('')} ref={refEmail} {...filterProps} />
        ),
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean) => {
          if (!value) setEmailToFilter('');
          else setEmailToFilter(value.toString());
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Actions',
        align: centerAlign,
        render: (text: string, record: GQL_QueryInviteResponse) => {
          return <Button text="Resend Invite" onClick={() => onResendInvite(record)} block />;
        },
        width: '5%',
      },
    ],
    [onResendInvite, searchEmailVisible],
  );

  const exportUsers = useCallback(() => {
    const headers = [['Name', 'Email']];
    const csvData =
      data?.getInvites.invites.map((invite: GQL_QueryInviteResponse) => {
        return [`${invite.firstName} ${invite.lastName}`, invite.email];
      }) || [];

    downloadCsv(Papa.unparse([...headers, ...csvData]), `Pending Invites.csv`);
  }, [data]);

  const exportMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={exportUsers}>
          .csv
        </Menu.Item>
      </Menu>
    ),
    [exportUsers],
  );

  return (
    <Row gutter={[24, 24]}>
      <Col xxl={3} xl={4} lg={5} md={24} sm={24}>
        <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
          <Button
            padding="4px 5px"
            style={{ minWidth: '200px' }}
            text={
              <>
                Export as
                <FiChevronDown style={{ marginLeft: 3 }} />
              </>
            }
            theme={themeConfig.noColor}
            icon={<FiDownload />}
            block
          />
        </Dropdown>
      </Col>
      <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
        <S.TableWrapper>
          <Table
            columns={columns}
            loading={loading}
            rowKey={(record: GQL_QueryInviteResponse) => record.id}
            pagination={pagination}
            bordered
            dataSource={data?.getInvites.invites}
            onChange={handleTableChange}
          />
        </S.TableWrapper>
      </Col>
    </Row>
  );
};

export default withRouter(PendingInvites);
