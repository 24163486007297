import React from 'react';

const TermsOfServicePage = () => {
  // This simply redirect to argument driven inquiry main website that has the actual privacy policy
  // so we don't need to verify the DNS on Google Search Console for their main website, only for adilearninghub.com
  window.location.href =
    'https://www.argumentdriveninquiry.com/learning-hub/terms-and-conditions-for-the-adi-learning-hub';

  return <></>;
};

export default TermsOfServicePage;
