import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import * as S from './styles';
import { useAuth } from '../../hooks/useAuth';
import Avatar from '../Avatar';

interface ICommentBox extends React.HTMLAttributes<HTMLDivElement> {
  actions?: React.ReactNode;
  author?: string;
  avatar?: string;
  highlighted?: boolean;
  highlightReport?: boolean;
  actionsColumns?: number;
  'data-cy'?: string;
}

const CommentBox: React.FC<ICommentBox> = (props) => {
  const { user } = useAuth();
  const { author, avatar, actionsColumns = 14 } = props;

  const userIcon = useMemo(() => {
    const basicIcon = <Avatar src={avatar} margin="5px" size={40} />;
    if (!author) return user.avatar ? <Avatar margin="5px" size={40} src={user.avatar} /> : basicIcon;

    return basicIcon;
  }, [author, avatar, user]);

  return (
    <S.Container {...props}>
      <S.HeaderContainer align="middle" justify="space-between">
        <Col span={24 - actionsColumns}>
          <Row align="middle">
            {userIcon}
            <S.NameText>{props.author ?? 'Me'}</S.NameText>
          </Row>
        </Col>
        <Col span={actionsColumns}>
          <Row align="middle" justify="end">
            {props.actions}
          </Row>
        </Col>
      </S.HeaderContainer>
      <>{props.children}</>
    </S.Container>
  );
};

export default CommentBox;
