import styled from 'styled-components';
import { Menu } from 'antd';

export const FilterContainer = styled.div<{
  $maxWidth?: string;
}>`
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  max-width: ${(props) => props.$maxWidth || 'fit-content'};
  color: #3f3e38;

  & > span {
    padding-left: 10px;
    padding-top: 2px;
    margin-right: 8px;
    font-size: 15px;
    font-weight: normal;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  & > svg {
    min-width: 18px;
    min-height: 18px;
    @media only screen and (max-width: 1200px) {
      margin-right: 8px;
    }
  }
`;

export const CMenu = styled(Menu)`
  & > .subMenuDiscipline {
    max-height: 300px;
    overflow-y: auto;
  }
`;

export const FilterChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7b8cea;
  color: white;
  border-radius: 6px;
  padding: 2px 8px;
  margin-right: 8px;
  max-width: 100%;

  svg {
    margin-left: 8px;
    min-width: 16px;
    min-height: 16px;
  }

  span {
    font-size: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
