import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, message, Badge, Spin, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';
import * as S from './styles';
import { themeConfig } from '../../../../utils/theme';
import AssignmentSummaryPopup from '../AssignmentSummaryPopup';
import { gqlSchema } from '../../../../gql/schema';
import { BsClock } from 'react-icons/bs';

interface Props {
  className: string;
  classColor: string;
  timeLeft: string;
  assignmentName: string;
  assignmentId: string;
  courseId: string;
  date?: string;
  timeWarning: boolean;
  icon: React.ReactElement;
  courseWorkType: string;
  type?: 'Assessment';
  version?: number | string;
}

const Item: React.FC<Props> = (props) => {
  const {
    className,
    assignmentName,
    assignmentId,
    courseId,
    icon,
    courseWorkType,
  } = props;

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState<string>('');

  const { data: courseWorkListData } = useQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkList,
    {
      variables: { courseId, data: "PUBLISHED" },
      onError: (err) => {
        message.error('Error fetching coursework list data: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const { data: courseWorkData, error: courseWorkError, loading: courseWorkLoading } = useQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
    {
      variables: { courseId, id: assignmentId },
      onError: (err) => {
        message.error('Error fetching assignment data: ' + (err.message || 'Unexpected Error'));
      },
      skip: !isPopupVisible, // Skip query execution if isPopupVisible is false
    }
  );

  useEffect(() => {
    const calculateTimeLeft = () => {
      const assignment = courseWorkListData?.getCourseWorkList.find((cw: any) => cw.id === assignmentId);
      if (assignment?.dueDate) {
        const dueDate = new Date(assignment.dueDate.year, assignment.dueDate.month - 1, assignment.dueDate.day).getTime();
        const currentTime = Date.now();
        const timeLeftValue = dueDate - currentTime;

        if (timeLeftValue <= 0) {
          setTimeLeft('Finished');
        } else {
          const seconds = Math.floor((timeLeftValue / 1000) % 60);
          const minutes = Math.floor((timeLeftValue / (1000 * 60)) % 60);
          const hours = Math.floor((timeLeftValue / (1000 * 60 * 60)) % 24);
          const days = Math.floor(timeLeftValue / (1000 * 60 * 60 * 24));

          if (days > 0) {
            setTimeLeft(`${days} day${days > 1 ? 's' : ''}`);
          } else if (hours > 0) {
            setTimeLeft(`${hours} hour${hours > 1 ? 's' : ''}`);
          } else if (minutes > 0) {
            setTimeLeft(`${minutes} minute${minutes > 1 ? 's' : ''}`);
          } else {
            setTimeLeft(`${seconds} second${seconds !== 1 ? 's' : ''}`);
          }
        }
      } else {
        setTimeLeft('');
      }
    };

    calculateTimeLeft();
  }, [assignmentId, courseWorkListData]);

  const handleResumeClick = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const handleClosePopup = useCallback(() => {
    setPopupVisible(false);
  }, []);

  const truncateDescription = (text: string | null | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text || '';
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  // Find the assignment description from the fetched course work data
  const assignment = courseWorkListData?.getCourseWorkList.find((cw: any) => cw.id === assignmentId);
  const description = assignment?.description;

  return (
    <S.Card>
      <Row align="middle">
        <Col span={3} style={{ textAlign: 'left' }}>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </Col>
        <Col span={14}>
          <S.Title $align="left">{className}</S.Title>
        </Col>
        <Col span={24} style={{ textAlign: 'left', marginTop: '8px' }}>
        <Badge
            style={{ backgroundColor: '#E9A643', borderRadius: '40px', color: '#fff', fontWeight: 500, fontSize: '12px', padding: '8px 16px', textAlign: 'center',display: 'inline-block'  }}
            count={<div style={{ textAlign: 'center' }}>{capitalizeFirstLetter(courseWorkType)}</div>}
          />
        </Col>
        <Col span={24} style={{ marginTop: '8px' }}>
          <S.AssignmentName>
            <span style={{
                fontWeight: 500, // Bold for title appearance
                fontSize: '14px', // Slightly larger font size for title
                color: '#333', // Dark color for readability
                margin: '0', // No extra margin
                display: 'block', // Display as block for full width
                textAlign: 'left', // Align text to the left
                textTransform: 'capitalize', // Capitalize first letter of each word
            }}>
              {assignmentName}
            </span>
          </S.AssignmentName>
        </Col>
        <Col span={24}>
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: description }} />}>
            <S.Description dangerouslySetInnerHTML={{ __html: truncateDescription(description, 50) }}></S.Description>
          </Tooltip>
        </Col>
      </Row>
      {timeLeft && (
        <Row style={{ marginTop: '0.5em' }} align="middle">
          <Col span={24}>
            <Row align="middle">
              <BsClock style={{ minWidth: 18, minHeight: 18, marginRight: 8 }} />
              <S.TimeLeft $fontSize="0.8em" $warning={timeLeft === 'Finished'}>
                {timeLeft}
              </S.TimeLeft>
            </Row>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: '1em' }} gutter={16} justify="center">
        <Col span={16} style={{ textAlign: 'center' }}>
          <S.DashboardButton
            text={'Resume Assignment'}
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={handleResumeClick}
            block
          />
        </Col>
      </Row>
      {isPopupVisible && (
        <Spin spinning={courseWorkLoading}>
          <AssignmentSummaryPopup
            courseWorkData={courseWorkData}
            courseWorkError={courseWorkError}
            courseWorkLoading={courseWorkLoading}
            courseId={courseId}
            assignmentId={assignmentId}
            visible={isPopupVisible}
            onClose={handleClosePopup}
            isFinished={false}
          />
        </Spin>
      )}
    </S.Card>
  );
};

export default Item;
