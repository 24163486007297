import { gql } from '@apollo/client';

export const CHAT_SUBSCRIPTIONS = {
  listenForChatMessages: gql`
    subscription listenForChatMessages {
      listenForChatMessages {
        chatId
        recipientId
        recipientUserName
        senderId
        senderUserName
        message
        sent
        MIMEType
        fileURL
        unreadMessages
        chatMessageId
      }
    }
  `,
};

export default CHAT_SUBSCRIPTIONS;
