import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import * as S from './styles';
import StudentsPerformanceLegends from '../StudentsPerformanceLegends';
import { studentPerformanceLevels } from '../../../types/studentPerformanceLevels';
import { GQL_AssessmentsScoresResponse } from '../../../types/gradeBooking';

interface IStudentsPerformanceBar {
  data: GQL_AssessmentsScoresResponse[];
}

const getGraphData = (data: GQL_AssessmentsScoresResponse[]) => {
  const getSum = (accValue: number, currValue: number) => accValue + Number((currValue / data.length).toFixed(2));

  return data.reduce(
    (acc, { graphData: currGraphData }) => ({
      notSubmittedOrGraded: getSum(acc.notSubmittedOrGraded, currGraphData.notSubmittedOrGraded),
      below: getSum(acc.below, currGraphData.below),
      approaching: getSum(acc.approaching, currGraphData.approaching),
      meets: getSum(acc.meets, currGraphData.meets),
    }),
    {
      notSubmittedOrGraded: 0,
      below: 0,
      approaching: 0,
      meets: 0,
    },
  );
};

const StudentsPerformanceBar = (props: IStudentsPerformanceBar) => {
  const { data } = props;
  const graphData = getGraphData(data);

  return (
    <S.StudentsPerformanceBarContainer>
      <p>Percentage of Students at Each Performance Level (across selected assessments)</p>

      <S.GraphContainer>
        {graphData && (
          <ResponsiveBar
            data={[graphData]}
            layout="horizontal"
            keys={Object.keys(studentPerformanceLevels)}
            colors={Object.values(studentPerformanceLevels).map((level) => level.color)}
            labelTextColor={'#FFFFFF'}
            tooltipLabel={(data) => studentPerformanceLevels[data.id as keyof typeof studentPerformanceLevels].name}
            label={(data) => (data.value ? `${data.value}%` : '')}
            theme={{
              fontSize: 16,
              labels: {
                text: {
                  fontWeight: 600,
                },
              },
            }}
          />
        )}
      </S.GraphContainer>

      <StudentsPerformanceLegends />
    </S.StudentsPerformanceBarContainer>
  );
};

export default StudentsPerformanceBar;
