import React from 'react';
import Button from '../../../../shared/Button';
import Spacer from '../../../../shared/Spacer';
import { GQL_InvestigationBlock, GQL_InvestigationCatalogActivityEntry } from '../../../../types/investigation';
import { themeConfig } from '../../../../utils/theme';
import ButtonBlockEditor from './ButtonBlockEditor';
import CanvasBlockEditor from './CanvasBlockEditor';
import ConceptEvaluationBlockEditor from './ConceptEvaluationBlockEditor';
import ImagesBlockEditor from './ImagesBlockEditor';
import MultipleChoiceQuestionEditor from './MultipleChoiceQuestionEditor';
import MultipleChoicePictureQuestionEditor from './MultipleChoicePictureQuestionEditor';
import SimulationBlockEditor from './SimulationBlockEditor';
import SortingBoardBlockEditor from './SortingBoardBlockEditor';
import CategoryDragBlockEditor from './CategoryDragBlockEditor';
import * as S from './styles';
import TextBlockEditor from './TextBlockEditor';
import TextImageBlockEditor from './TextImageBlockEditor';
import TextSubmitBlockEditor from './TextSubmitBlockEditor';
import VideoBlockEditor from './VideoBlockEditor';
import ProcessDragDropAnswerBlockEditor from './ProcessDragDropAnswerBlockEditor';

interface IBlockEditingTab {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  setSelectedBlockIndex: (index?: number) => void;
  saveActivity?: () => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
  loading?: boolean;
}

const BlockEditingTab = (props: IBlockEditingTab) => {
  const {
    editingBlock,
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    setSelectedBlockIndex,
    saveActivity,
    editingRemote,
    loading,
  } = props;

  const clearSelectedEditingBlocks = () => {
    setSelectedBlockIndex(undefined);
  };

  const getEditingBlockComponent = () => {
    switch (editingBlock.type) {
      case 'Text':
        return (
          <TextBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'Video':
        return (
          <VideoBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'Simulation':
        return (
          <SimulationBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'Image':
        return (
          <ImagesBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'Button':
        return (
          <ButtonBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'TextSubmit':
        return (
          <TextSubmitBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'Canvas':
        return (
          <CanvasBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'SortingBoard':
        return (
          <SortingBoardBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'ConceptEvaluation':
      case 'ConceptRating':
        return (
          <ConceptEvaluationBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
            isConceptRating={editingBlock.type === 'ConceptRating'}
          />
        );
      case 'TextImage':
        return (
          <TextImageBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'MultipleChoiceQuestion':
        return (
          <MultipleChoiceQuestionEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'CategoryDrag':
        return (
          <CategoryDragBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'CategoryDragPicture':
        return (
          <CategoryDragBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
            isImageVersion={true}
          />
        );
      case 'ProcessDragDropAnswer':
        return (
          <ProcessDragDropAnswerBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      case 'ProcessDragDropPictureAnswer':
        return (
          <ProcessDragDropAnswerBlockEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
            isImageVersion={true}
          />
        );
      case 'MultipleChoicePictureQuestion':
        return (
          <MultipleChoicePictureQuestionEditor
            activities={activities}
            editingBlock={editingBlock}
            selectedActivityId={selectedActivityId}
            selectedBlockIndex={selectedBlockIndex}
            selectedContentIndex={selectedContentIndex}
            setActivities={setActivities}
            editingRemote={editingRemote}
          />
        );
      default:
        return <S.NotEditableBlock>Block not editable</S.NotEditableBlock>;
    }
  };

  return (
    <>
      {getEditingBlockComponent()}
      <S.ButtonsContainer>
        <Button text="Add More Blocks" theme={themeConfig.noColor} block onClick={clearSelectedEditingBlocks} />
        <Spacer axis="horizontal" />
        <Button text="Save Settings" block onClick={saveActivity} loading={loading} />
      </S.ButtonsContainer>
    </>
  );
};

export default BlockEditingTab;
