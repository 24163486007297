import styled from 'styled-components';

export const UploadFromComputerContainer = styled.div<{ pointer?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.pointer && 'cursor: pointer;'}
  margin-bottom: 8px;
  svg {
    color: #767676;
  }

  h2 {
    margin-bottom: 0;
  }
`;
