import styled from 'styled-components';
import Button from '../../../shared/Button';
import { DatePicker as Picker, TimePicker } from 'antd';

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const TableNameContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 14px;
  }
`;

export const StudentInvestigationStatus = styled.div<{
  status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED';
}>`
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${({ status }) => {
    if (status === 'COMPLETED') return '#20BC89';
    else if (status === 'IN_PROGRESS') return '#E9A643';
    else if (status === 'NOT_STARTED') return '#C4C4C4';
    else if (status === 'READY_FOR_GRADING') return '#4367E9';
    else if (status === 'OVERDUE') return '#EA3535';
  }};
`;

export const TableRating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-rate {
    font-size: 12px;
  }
`;

export const SearchIcon = styled.span<{
  $searchVisible: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 10px 4px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => (props.$searchVisible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px' : 'unset')};
`;

export const GradeRating = styled.span<{ grade: number }>`
  font-size: 18px;

  color: ${(props) => {
    if (props.grade >= 0.8) {
      return '#20BC89';
    } else if (props.grade >= 0.6) {
      return '#FFAE51';
    } else {
      return '#EA3535';
    }
  }};
`;

export const ClassTag = styled.span<{ $backgroundColor: string }>`
  background: ${(props) => props.$backgroundColor};
  color: white;
  border-radius: 20px;
  padding: 6px 12px;
`;

export const StatusChip = styled.div<{
  status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED';
}>`
  width: 100%;
  border-radius: 4px;
  text-align: center;
  padding: 6px 10px;
  color: ${({ status }) => (status === 'NOT_STARTED' ? '#3F3E38' : '#fff')};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
  background: ${({ status }) => {
    if (status === 'COMPLETED') return '#20BC89';
    else if (status === 'IN_PROGRESS') return '#E9A643';
    else if (status === 'NOT_STARTED') return '#C4C4C4';
    else if (status === 'READY_FOR_GRADING') return '#4367E9';
    else if (status === 'OVERDUE') return '#EA3535';
  }};

  &:hover {
    transform: scale(1.05);
    opacity: 0.7;
  }

  &:active {
    transform: scale(1);
  }

  svg {
    margin-bottom: 2px;
  }
`;

export const EditButton = styled(Button)`
  background: ${(props) => props.theme.primaryColor.background};
  border-color: ${(props) => props.theme.primaryColor.background};
  line-height: 0;
  display: inline;
  margin-left: 5px;
  height: 25px;
  width: 25px;
  min-height: 25px;
  &:hover,
  &:active,
  &:focus {
    background: ${(props) => props.theme.primaryColor.background};
    border-color: ${(props) => props.theme.primaryColor.background};
  }
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DatePicker = styled(Picker)<{
  $backgroundColor?: string;
}>`
  width: 100%;
  border-radius: 6px;
  background: ${(props) => props.$backgroundColor || '#f8f8f8'};

  input {
    background: ${(props) => props.$backgroundColor || '#f8f8f8'};
    padding: 4px 11px 4px;
    cursor: pointer;
  }
`;

export const TimePickerContainer = styled(TimePicker)<{
  $backgroundColor?: string;
}>`
  width: 100%;
  border-radius: 6px;
  background: ${(props) => props.$backgroundColor || '#f8f8f8'};

  input {
    background: ${(props) => props.$backgroundColor || '#f8f8f8'};
    padding: 4px 11px 4px;
    cursor: pointer;
  }
`;
