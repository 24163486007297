import styled from 'styled-components';
import { Layout, Col as AntdCol, Row as AntdRow } from 'antd';
const { Header: AntdHeader } = Layout;

export const Header = styled(AntdHeader)`
  background-color: #faf8f9;
  height: 80px;
  z-index: 1002;
  position: fixed;
  width: 100%;
  padding: 0;
  @media (max-width: 1200px) {
    padding: 0;
  }
`;

export const Col = styled(AntdCol)<{
  $responsiveAlign: string;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1200px) {
    text-align: ${(props) => props.$responsiveAlign};
    padding-right: 30px;
  }
`;

export const Row = styled(AntdRow)`
  height: 100%;
  padding: 5px 64px;

  @media (max-width: 1200px) {
    padding: 5px 16px;
  }
`;

export const LogoImage = styled.img`
  height: 90%;
  float: left;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
