import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Form as AntdForm, Input, Col, Row, message, Divider, Tooltip, Checkbox } from 'antd';
import Form from '../../Form';
import Button from '../../Button';
import * as S from './styles';
import { useForm } from 'antd/lib/form/Form';
import { GQL_SendInviteResponse } from '../../../types/invites';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import RangePicker from '../../RangePicker';
import { toDateFormat } from '../../../utils/date';
import Select from '../../Select';
import SelectOption from '../../Select/Option';
import { GQL_State } from '../../../types/state';
import useFetchStatesData from '../../../hooks/useFetchStatesData';
import Switch from '../../../shared/Switch';
import { FiHelpCircle } from 'react-icons/fi';
import { SUBJECT_TYPES } from '../../../types/subjects';
import { grades } from '../../../utils/constants';
import { useEnabledAssessments } from '../../../hooks/useEnabledAssessments';
import { GQL_OrganizationDetailsResponse } from '../../../types/organization';
import { isArray } from 'lodash';


interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
  organization?: GQL_OrganizationDetailsResponse;
}

const OrganizationAdmin: React.FC<Props> = (props) => {
  const [form] = useForm();
  const formValues = form.getFieldsValue(['gradeBands', 'subjects', 'customGradeBands','notes','poNumber','invoiceNumber','districtContactName','districtContactEmail']);
  const [, forceRerender] = useState(-1);
  const [disableCustomGradeBands, setDisableCustomGradeBands] = useState(true);
  const [texasSubjectEnabled, setTexasSubjectEnabled] = useState(false);
  const [isAnySubjectSelected, setIsAnySubjectSelected] = useState(false);

  const [inviteOrganization, { loading }] = useMutation<{ inviteOrganization: GQL_SendInviteResponse }>(
    gqlSchema.InvitesSchema.mutations.SEND.inviteOrganization,
    {
      onError: () => {
        message.error('There was an error trying to create Organization Admin, please try again later.');
      },
      onCompleted: (data) => {
        if (data?.inviteOrganization.emailSent) {
          form.resetFields();
          message.success(`Organization Admin created successfully`);
          props.setVisible(false);
        }
      },
    },
  );

  // query the states from backend for selecting state standards
  const { statesData, statesLoading } = useFetchStatesData();
  const gradeBands = ['K-2', '3-5', '6-8', '9-12'];
  const [selectedTexasGradeBands, setSelectedTexasGradeBands] = useState<string[]>(
    []
  );

  useEffect(() => {
    const selectedSubjects = formValues?.subjects || [];

    const texasInvestigationActive = selectedSubjects.includes(SUBJECT_TYPES.SCIENCE_TEXAS_EDITION);
    const otherSelected = Boolean(selectedSubjects.length > 1);
    const disabled = texasInvestigationActive ?? (!texasInvestigationActive || otherSelected);
    setDisableCustomGradeBands(!disabled);

  }, [formValues]);
  useEffect(() => {
    // Check if any subject is selected
    const selectedSubjects = formValues?.subjects || [];
    setIsAnySubjectSelected(selectedSubjects.length > 0);
  }, [formValues.subjects]);

console.log("adasda",formValues);

  const [, setAllGradesSelected] = useState(false);


  const handleToggleChange = useCallback(
    (key: string, selected: string, checked: boolean) => {
      const source = form.getFieldValue(key) || [];
      let modified;

      if (checked) {
        modified = [...new Set([...source, selected])];
      } else {
        modified = source.filter((item: string) => item !== selected);
      }
      form.setFieldsValue({ [key]: modified });

      // Update 'Select All' based on individual selections
      if (key === 'gradeBands') {
        setAllGradesSelected(modified.length === gradeBands.length);
      }
      if (key === 'subjects' && selected === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION) {
        setTexasSubjectEnabled(checked);

        if (!checked) {
          setSelectedTexasGradeBands([]);
          form.setFieldsValue({ customGradeBands: [] });
          forceRerender(Math.random());
        }
      }
      forceRerender(Math.random());
    },
    [form, gradeBands.length, setSelectedTexasGradeBands, setTexasSubjectEnabled]
  );
  useEffect(() => {
    if (disableCustomGradeBands) {
      form.resetFields(['customGradeBands']);
      forceRerender(Math.random());
    }
  }, [disableCustomGradeBands, form]);


  const [selectedGradeBands, setSelectedGradeBands] = useState<string[]>([]);

  const handleGradeBandChange = useCallback((gradeBand: string, checked: boolean) => {
    setSelectedGradeBands((prevSelected) =>
      checked
        ? [...new Set([...prevSelected, gradeBand])]
        : prevSelected.filter((item) => item !== gradeBand)
    );
  }, []);

  const handleSelectAllChange = useCallback(
    (checked: boolean) => {
      setSelectedGradeBands(checked ? gradeBands : []);
    },
    [gradeBands],
  );

  // --- Texas Grade Bands ---


  const handleSelectAllTexasChange = useCallback(() => {
    setSelectedTexasGradeBands(prevSelected => {
      const allSelected = prevSelected.length === grades.length;
      const newSelectedGrades = allSelected ? [] : [...grades];

      form.setFieldsValue({ customGradeBands: newSelectedGrades });
      forceRerender(Math.random());
      return newSelectedGrades;
    });
  }, [grades, form]);

  const handleTexasGradeBandChange = useCallback((gradeBand: string, checked: boolean) => {

    setSelectedTexasGradeBands((prevSelected) => {
      const updatedSelected = checked
        ? [...new Set([...prevSelected, gradeBand])]
        : prevSelected.filter((item) => item !== gradeBand);

      form.setFieldsValue({ customGradeBands: updatedSelected });

      forceRerender(Math.random());

      return updatedSelected;
    });
  }, [form]);



  useEffect(() => {
    form.setFieldsValue({
      gradeBands: selectedGradeBands,
      customGradeBands: selectedTexasGradeBands,

    });
  }, [selectedGradeBands, selectedTexasGradeBands, form]);

  // Sync state changes to form values
  useEffect(() => {
    form.setFieldsValue({
      gradeBands: selectedGradeBands,
      customGradeBands: selectedTexasGradeBands, // Sync selectedTexasGradeBands with form fields
    });
  }, [selectedGradeBands, selectedTexasGradeBands, form]);

  const onFinish = useCallback(
    async (
      data: {
        firstName: string;
        lastName: string;
        email: string;
        subscriptionData: {
          startDate: string;
          endDate: string;
        };
        defaultTAQuantity: number;
        invitesAvailable: number;
        writers: number;
        isTrial: boolean;
        taCustomPermissions: boolean;
        readerPermissions: boolean;
        teachersCanDoReviews: boolean;
        customLibrary: boolean;
        invLibLimit: number;
        reportPermission: boolean;
        allowedStates: string[];
        subjects: string[];
        gradeBands: string[];
        customGradeBands: string[];
        customGradeBandsStandards: string[];
      },
      listOfStates: GQL_State[] | undefined,
    ) => {
      const {
        subscriptionData,
        invitesAvailable,
        writers,
        invLibLimit,
        isTrial,
        taCustomPermissions,
        readerPermissions,
        teachersCanDoReviews,
        customLibrary,
        reportPermission,
        allowedStates,
        subjects,
        gradeBands,
        customGradeBands,
        customGradeBandsStandards,
        ...info
      } = data;

      const gradeBandsValues = [
        ...(gradeBands || []),
        ...(customGradeBands || []),
        ...(customGradeBandsStandards || []),
      ];

      const allowedStatesList = isArray(allowedStates) ? allowedStates : [allowedStates];
      // create a list of states to save as allowedStates for this organization
      const allowedStatesToSave = (allowedStatesList || []).map((allowedStateName) => {
        const state = listOfStates?.find(({ name }) => name === allowedStateName);
        return {
          id: state?.id,
          name: state?.name,
          postalCode: state?.postalCode,
        };
      });

      inviteOrganization({
        variables: {
          data: {
            ...info,
            defaultTAQuantity: +info.defaultTAQuantity,
            subscriptionData: {
              subjects,
              gradeBands: gradeBandsValues,
              startDate: new Date(toDateFormat(subscriptionData.startDate)).getTime(),
              endDate: new Date(toDateFormat(subscriptionData.endDate)).getTime(),
              invitesAvailable: +invitesAvailable,
              writerQuantity: +writers,
              investigationLibraryLimit: +invLibLimit,
              isTrial: isTrial || false,
              taCustomPermissions: taCustomPermissions || false,
              readerPermissions: readerPermissions || false,
              teachersCanDoReviews: teachersCanDoReviews || false,
              customLibrary: customLibrary || false,
              reportPermission: reportPermission || false,
            },
            allowedStates: allowedStatesToSave,
          },
        },
      });
    },
    [inviteOrganization],
  );

  const { subjectsList, } = useEnabledAssessments({
    allowedSubjects: formValues?.subjects || [],
  });

  return (
    <Form onFinish={(data) => onFinish(data, statesData)} form={form}>
      <Row gutter={[24, 16]} justify="center" style={{ marginTop: 10 }}>
        <Col span={20}>
          <S.SubTitle>Organization Details</S.SubTitle>
          <Divider style={{ margin: '5px 0' }} />
        </Col>
        <Col span={20}>
          <S.TitleInput>Organization Name</S.TitleInput>
          <AntdForm.Item
            name="organizationName"
            rules={[
              {
                required: true,
                message: 'Input the Organization Name!',
              },
            ]}
          >
            <Input />
          </AntdForm.Item>
        </Col>
        <Col span={20}>
          <S.SubTitle>Subscription Details</S.SubTitle>
          <Divider style={{ margin: '5px 0' }} />
        </Col>
        <Col span={20}>
          <Row gutter={24}>
            <Col span={12}>
              <S.TitleInput>Duration</S.TitleInput>
              <AntdForm.Item
                name="subscriptionData"
                rules={[
                  {
                    required: true,
                    message: 'Please select the subscription duration!',
                  },
                ]}
              >
                <RangePicker />
              </AntdForm.Item>
            </Col>
            <Col span={6}>
              <S.TitleInput>Licenses</S.TitleInput>
              <AntdForm.Item
                name="invitesAvailable"
                initialValue={1}
                rules={[
                  {
                    required: true,
                    message: 'Please add licenses!',
                  },
                ]}
              >
                <Input type="number" min={1} />
              </AntdForm.Item>
            </Col>
            <Col span={6}>
              <S.TitleInput>Teacher Assistants</S.TitleInput>
              <AntdForm.Item
                name="defaultTAQuantity"
                initialValue={3}
                rules={[
                  {
                    required: true,
                    message: 'Please add Teacher Assistants quantity!',
                  },
                ]}
              >
                <Input type="number" min={1} />
              </AntdForm.Item>
            </Col>

            <Col span={12}>
              <S.TitleInput>Writers Quantity</S.TitleInput>
              <AntdForm.Item
                name="writers"
                initialValue={5}
                rules={[
                  {
                    required: true,
                    message: 'Please add Writers Quantity!',
                  },
                ]}
              >
                <Input type="number" min={1} />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <S.TitleInput>Library Investigations Limit</S.TitleInput>
              <AntdForm.Item
                name="invLibLimit"
                initialValue={10}
                rules={[
                  {
                    required: true,
                    message: 'Please add Library Investigations Limit!',
                  },
                ]}
              >
                <Input type="number" min={1} />
              </AntdForm.Item>
            </Col>
          </Row>
        </Col>
        <Col span={20}>
          <S.SubTitle>Permissions</S.SubTitle>
          <Divider style={{ margin: '5px 0' }} />
        </Col>
        <Col span={20}>
          <Row>
            <Col span={8}>
              <AntdForm.Item name="isTrial" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>
                  <Tooltip title="Limits access to resources.">
                    <span role="none" >Trial Subscription</span>
                  </Tooltip>
                </Checkbox>
              </AntdForm.Item>
            </Col>

            <Col span={8}>
              <AntdForm.Item name="taCustomPermissions" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>
                  <Tooltip title="Allows teacher assistants to grade final reports and message with students.">
                    <span role="none" >Super TA</span>
                  </Tooltip>
                </Checkbox>
              </AntdForm.Item>
            </Col>

            <Col span={8}>
              <AntdForm.Item name="readerPermissions" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>
                  <Tooltip title="Allows students to use the immersive reader on investigations.">
                    <span role="none" >Immersive Reader</span>
                  </Tooltip>
                </Checkbox>
              </AntdForm.Item>
            </Col>

            <Col span={8}>
              <AntdForm.Item name="teachersCanDoReviews" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>
                  <Tooltip title="Allows teachers and teacher assistants to complete peer reviews and reflections for students.">
                    <span role="none" >Super Teacher</span>
                  </Tooltip>
                </Checkbox>
              </AntdForm.Item>
            </Col>

            <Col span={8}>
              <AntdForm.Item name="customLibrary" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>
                  <Tooltip title="Allows organization administrators to create custom investigations that are only available to the organization.">
                    <span role="none" >Custom Investigations</span>
                  </Tooltip>
                </Checkbox>
              </AntdForm.Item>
            </Col>

            <Col span={8}>
              <AntdForm.Item name="reportPermission" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>
                  <Tooltip title="Allows organization administrators to view Report module and allows teachers to view Insights module.">
                    <span role="none" >Reports & Insights</span>
                  </Tooltip>
                </Checkbox>
              </AntdForm.Item>
            </Col>
          </Row>
        </Col>

        {/* Organization Other Field */}

        <Col span={20}>
          <S.SubTitle>Organization Additional Details</S.SubTitle>
          <Divider style={{ margin: '5px 0' }} />
        </Col>
        <Col span={20}>
          <Row gutter={[16, 16]}>

            {/* First Row */}
            <Col span={12}>
              <AntdForm.Item
                name="poNumber"
                label="PO Number"
                style={{ marginBottom: 16 }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Enter PO Number" />
              </AntdForm.Item>
            </Col>

            <Col span={12}>
              <AntdForm.Item
                name="invoiceNumber"
                label="Invoice Number"
                style={{ marginBottom: 16 }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Enter Invoice Number" />
              </AntdForm.Item>
            </Col>

            {/* Second Row */}
            <Col span={12}>
              <AntdForm.Item
                name="districtContactName"
                label="District Contact Name"
                style={{ marginBottom: 16 }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Enter District Contact Name" />
              </AntdForm.Item>
            </Col>

            <Col span={12}>
              <AntdForm.Item
                name="districtContactEmail"
                label="District Contact Email"
                style={{ marginBottom: 16 }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Enter District Contact Email" type="email" />
              </AntdForm.Item>
            </Col>

            {/* Third Row */}
            <Col span={12}>
              <AntdForm.Item
                name="notes"
                label="Notes"
                style={{ marginBottom: 16 }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.TextArea placeholder="Enter Notes" rows={4} />
              </AntdForm.Item>
            </Col>

          </Row>
        </Col>


        {/*  
        {/* Subscription Bands */}
        <Divider />
        <Col span={20}>
          <Row>
            <Col span={8}>
              <Row gutter={[0, 4]} align="middle">
                <S.TitleInput style={{ margin: 0 }}>Subscription Subjects</S.TitleInput>
                <Tooltip title="User  under this subscription can only assign investigations of subjects set below">
                  <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                </Tooltip>

                <AntdForm.Item name="subjects" noStyle>
                  {subjectsList.map(
                    (subject) =>
                      subject.key !== SUBJECT_TYPES.SCIENCE_TEXAS_EDITION && (
                        <Col span={24} key={subject.key}>
                          <Row align="middle">
                            <Switch
                              type="text"
                              titleAlign="right"
                              title={subject.label}
                              disabled={!subject.allowed}
                              onChange={(checked: boolean) =>
                                handleToggleChange('subjects', subject.key, checked)
                              }
                            />

                            {!subject.allowed && (
                              <Tooltip title="Upgrade your subscription to enable this assessment">
                                <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                              </Tooltip>
                            )}
                          </Row>
                        </Col>
                      )
                  )}
                </AntdForm.Item>
              </Row>
            </Col>

            <Col span={8}>
              <Row gutter={[0, 4]} align="middle">
                <S.TitleInput style={{ margin: 0 }}>Subscription Grade Levels</S.TitleInput>
                <Tooltip title="User  under this subscription can only assign investigations of grades set below">
                  <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                </Tooltip>

                <AntdForm.Item name="gradeBands" noStyle>
                  {gradeBands.map((gradeBand) => (
                    <Col span={24} key={gradeBand}>
                      <Switch
                        type="text"
                        titleAlign="right"
                        title={`Grade ${gradeBand}`}
                        checked={selectedGradeBands.includes(gradeBand)}
                        disabled={!isAnySubjectSelected} // Disable if no subject is selected
                        onChange={(checked: boolean) => handleGradeBandChange(gradeBand, checked)}
                      />
                    </Col>
                  ))}
                </AntdForm.Item>
              </Row>
            </Col>

            <Col span={8}>
              <Row align="middle">
                <AntdForm.Item noStyle>
                  <Switch
                    type="text"
                    titleAlign="right"
                    title="Select All Grades"
                    checked={selectedGradeBands.length === gradeBands.length}
                    disabled={!isAnySubjectSelected} // Disable if no subject is selected
                    onChange={handleSelectAllChange}
                  />
                </AntdForm.Item>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* Texas Grade Band */}
        <Divider />
        <Col span={20}>
          <Row>
            <Col span={8}>
              <Row gutter={[0, 4]} align="middle">
                <S.TitleInput style={{ margin: 0 }}>Texas Subscription Subjects</S.TitleInput>
                <Tooltip title="User under this subscription can only assign investigations of subjects set below">
                  <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                </Tooltip>

                <AntdForm.Item name="subjects" noStyle>
                  {subjectsList.map(
                    (subject) =>
                      subject.key === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION && (
                        <Col span={24} key={subject.key}>
                          <Row align="middle">
                            <Switch
                              type="text"
                              titleAlign="right"
                              title={subject.label}
                              onChange={(checked: boolean) =>
                                handleToggleChange('subjects', subject.key, checked)
                              }
                            />

                            {!subject.allowed && (
                              <Tooltip title="Upgrade your subscription to enable this assessment">
                                <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                              </Tooltip>
                            )}
                          </Row>
                        </Col>
                      )
                  )}
                </AntdForm.Item>
              </Row>
            </Col>

            <Col span={8}>
              <Row gutter={[0, 4]} align="middle">
                <S.TitleInput style={{ margin: 0 }}>Texas Subscription Grade Levels</S.TitleInput>
                <Tooltip title="User under this subscription can only assign investigations of grades set below">
                  {/* <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} /> */}
                </Tooltip>

                <AntdForm.Item name="customGradeBands" noStyle>
                  <Row gutter={[0, 4]} align="middle">
                    {grades.map((gradeBand) => (
                      <Col span={24} key={gradeBand}>
                        <Switch
                          disabled={!texasSubjectEnabled}
                          type="text"
                          titleAlign="right"
                          title={`Grade ${gradeBand.replace('tx.', '')}`}
                          checked={selectedTexasGradeBands.includes(gradeBand)} // Controlled by state
                          onChange={(checked: boolean) => handleTexasGradeBandChange(gradeBand, checked)}
                        />
                      </Col>
                    ))}
                  </Row>
                </AntdForm.Item>

              </Row>
            </Col>

            <Col span={8}>
              <Row gutter={[0, 8]} align="middle" justify="center">
                <AntdForm.Item noStyle>
                  <Switch
                    type="text"
                    titleAlign="right"
                    title={`Select All Texas Grade`}
                    disabled={!texasSubjectEnabled}
                    checked={selectedTexasGradeBands.length === grades.length}
                    onChange={() => handleSelectAllTexasChange()}
                  />
                </AntdForm.Item>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* State standards */}
        <Col span={20}>
          <S.SubTitle>Standards</S.SubTitle>
          <Divider style={{ margin: '5px 0' }} />
        </Col>
        <Col span={20}>
          <AntdForm.Item name="allowedStates">
            <Select
              placeholder="Select States"
              optionLabelProp="label"
              showSearch={false}
              dropdownMatchSelectWidth={false}
              showArrow
              loading={statesLoading}
            >
              {statesData.map((state) => (
                <SelectOption value={state.name} label={state.name} key={state.name}>
                  {state.name}
                </SelectOption>
              ))}
            </Select>
          </AntdForm.Item>
        </Col>

        <Col span={20}>
          <S.SubTitle>Organization Admin</S.SubTitle>
          <Divider style={{ margin: '5px 0' }} />
        </Col>
        <Col span={6}>
          <S.TitleInput>First Name</S.TitleInput>
          <AntdForm.Item
            name="adminFirstName"
            rules={[
              {
                required: true,
                message: 'Input the First Name!',
              },
            ]}
          >
            <Input />
          </AntdForm.Item>
        </Col>
        <Col span={6}>
          <S.TitleInput>Last Name</S.TitleInput>
          <AntdForm.Item
            name="adminLastName"
            rules={[
              {
                required: true,
                message: 'Input the Last Name!',
              },
            ]}
          >
            <Input />
          </AntdForm.Item>
        </Col>
        <Col span={8}>
          <S.TitleInput>Email</S.TitleInput>
          <AntdForm.Item
            name="adminEmail"
            rules={[
              {
                required: true,
                message: 'Input the Email!',
              },
              {
                type: 'email',
              },
            ]}
          >
            <Input />
          </AntdForm.Item>
        </Col>
        <Col span={8}>
          <AntdForm.Item>
            <Button
              data-cy="shared-adduser-organizationadmin-submit-button"
              block
              loading={loading}
              text="Create Organization"
              htmlType="submit"
            />
          </AntdForm.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(OrganizationAdmin);