import React, { useRef } from 'react';
import { Carousel as AntdCarousel, Row } from 'antd';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';

import * as S from './styles';
import { CarouselRef } from 'antd/lib/carousel';
import { themeConfig } from '../../utils/theme';
import { Combination } from '../../types/theme';

interface ICarousel {
  justify?: 'center' | 'start' | 'end' | 'space-around' | 'space-between';
  height?: number;
  width?: number;
  smWidth?: number;
  backgroundColor?: string;
  showDots?: boolean;
  showButtons?: boolean;
  autoPlay?: boolean;
  buttonsTheme?: Combination;
}

const Carousel: React.FC<ICarousel> = (props) => {
  const slider = useRef<CarouselRef | null>();
  const {
    height,
    width = 12,
    smWidth = 24,
    backgroundColor = 'white',
    showButtons = true,
    showDots = true,
    buttonsTheme = themeConfig.secondaryColor,
    autoPlay,
  } = props;

  return (
    <Row align="middle" justify={props.justify}>
      <S.CarouselCol lg={{ span: width, offset: (24 - width) / 2 }} sm={{ span: smWidth, offset: (24 - smWidth) / 2 }}>
        {showButtons && (
          <S.RoundButton
            onClick={() => slider?.current?.prev()}
            shape="circle"
            icon={<FiChevronLeft size={22} />}
            theme={buttonsTheme}
          />
        )}
        <S.CarouselContainer $backgroundColor={backgroundColor}>
          <AntdCarousel ref={(ref) => (slider.current = ref)} dots={showDots} autoplay={autoPlay} autoplaySpeed={6000}>
            {React.Children.map(props.children, (child) => (
              <S.CarouselContent $height={height}>{child}</S.CarouselContent>
            ))}
          </AntdCarousel>
        </S.CarouselContainer>
        {showButtons && (
          <S.RoundButton
            onClick={() => slider?.current?.next()}
            $left
            shape="circle"
            icon={<FiChevronRight size={22} />}
            theme={buttonsTheme}
          />
        )}
      </S.CarouselCol>
    </Row>
  );
};

export default Carousel;
