import { Row, Col } from 'antd';
import styled from 'styled-components';

export const Container = styled(Row)`
  marging: 4em;
`;

export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 6px;
  padding: 40px;
  display: flex;
  align-items: center;
  .ant-skeleton-element {
    display: block !important;
  }
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const InfoProgress = styled.p`
  font-size: 0.45em;
  margin: 10px 0 0 0;
`;

export const InfosContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 40px;
  margin-right: 40px;

  p {
    color: #3f3e38;
    font-size: 14px;
  }
`;

export const Divider = styled.span`
  height: 1px;
  width: 100%;
  background: #c6c6c6;
  display: block;
  border-radius: 5px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const ClassChip = styled.span<{ background: string }>`
  background: ${(props) => props.background};
  color: white;
  border-radius: 40px;
  padding: 6px 16px;
`;

export const ColouredSummaryText = styled.p<{ color: string }>`
  font-weight: bold;
  color: ${(props) => props.color} !important;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h2 {
    margin: 0;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;

  & > span {
    display: flex;
    button {
      margin-right: 16px;
    }
  }
`;

export const SearchIcon = styled.span<{
  $searchVisible: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px 10px 4px 10px;
  border-radius: 5px;
  box-shadow: ${(props) => (props.$searchVisible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px' : 'unset')};
`;

export const TableWrapper = styled.div`
  & .ant-dropdown-trigger {
    width: 5em !important;
  }
  & .ant-table-filter-trigger-container:hover,
  & .ant-table-filter-trigger-container-open {
    background: transparent !important;
  }
`;

export const TableNameContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 13px;
  }
`;

export const StudentInvestigationStatus = styled.div<{
  status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED';
}>`
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${({ status }) => {
    if (status === 'COMPLETED') return '#20BC89';
    else if (status === 'IN_PROGRESS') return '#E9A643';
    else if (status === 'NOT_STARTED') return '#C4C4C4';
    else if (status === 'READY_FOR_GRADING') return '#4367E9';
    else if (status === 'OVERDUE') return '#EA3535';
  }};
`;

export const StatusChip = styled.div<{
  status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED';
}>`
  width: 100%;
  border-radius: 4px;
  text-align: center;
  padding: 6px 10px;
  color: ${({ status }) => (status === 'NOT_STARTED' ? '#3F3E38' : '#fff')};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
  background: ${({ status }) => {
    if (status === 'COMPLETED') return '#20BC89';
    else if (status === 'IN_PROGRESS') return '#E9A643';
    else if (status === 'NOT_STARTED') return '#C4C4C4';
    else if (status === 'READY_FOR_GRADING') return '#4367E9';
    else if (status === 'OVERDUE') return '#EA3535';
  }};

  &:hover {
    transform: scale(1.05);
    opacity: 0.7;
  }

  &:active {
    transform: scale(1);
  }

  svg {
    margin-bottom: 2px;
  }
`;

export const TableRating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-rate {
    font-size: 12px;
  }
`;

export const GradeRating = styled.span<{ grade: number }>`
  font-size: 18px;

  color: ${(props) => {
    if (props.grade >= 0.8) {
      return '#20BC89';
    } else if (props.grade >= 0.6) {
      return '#FFAE51';
    } else {
      return '#EA3535';
    }
  }};
`;

export const ClassTag = styled.span<{ $backgroundColor: string }>`
  background: ${(props) => props.$backgroundColor};
  color: white;
  border-radius: 20px;
  padding: 6px 12px;
`;

export const BorderedContainer = styled.div`
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 35px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  text-align: left;
  p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    margin: 0;
  }
`;

export const MaterialsModalContainer = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125.1%;
    text-align: left;

    color: #767676;
  }

  & > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;

    text-align: center;
  }

  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125.1%;
    width: 280px;
    margin: auto;

    text-align: center;

    color: #767676;
  }
`;

export const DownloadLink = styled.a`
  background-color: ${(props) => props.theme.primaryColor.background};
  color: white;
  border-radius: 3px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
