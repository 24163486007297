import { message } from 'antd';
import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';

const LogoutPage = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    message.error('Your session expired, please login again');
    signOut();
  }, [signOut]);

  return <>Logging out...</>;
};

export default LogoutPage;
