import { FiCalendar } from 'react-icons/fi';
import styled from 'styled-components';

export const ActionText = styled.p`
  margin: 0;
  font-size: 12px;
  color: #767676;
  max-width: calc(100% - 25px);
  line-height: 1;
`;

export const CalendarIcon = styled(FiCalendar)`
  margin-right: 5px;
  color: #767676;
`;
