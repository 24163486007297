import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 4em 2em 4em;
`;

export const TitleTab = styled.p`
  font-weight: 700;
  padding: 0;
  font-size: 1.2em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;
