// styles.ts
import { Table as AntTable } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem;
  background-color: #f5f5f5;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Table = styled(AntTable)`
  .ant-table-thead > tr > th {
    background-color: #4367E9;
    color: #fff;
    text-align: center;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
     .ant-btn-primary {
        background: none;
        border-color: rgba(27, 24, 228, 0.97);
        border-radius: 2px;
        color: rgba(27, 24, 228, 0.97);
  }

  .ant-table-row:hover > td {
    background-color: #f0f8ff;
  }
    .ant-table-cell> button
    {
    background-color: none;
    }

  .ant-pagination-item-active {
    background-color: rgb(28, 24, 228);
    border-color: rgb(28, 24, 228);
    a {
      color: #fff;
    }
  }

  .ant-pagination-item {
    border-radius: 50%;
    &:hover {
      border-color: rgb(28, 24, 228);
    }
  }
`;

export const BackButton = styled.button`
  font-size: 40px;
  font-weight: bold;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;

  &:hover {
    color: #1890ff;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
