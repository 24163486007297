import styled from 'styled-components';

export const FilePlaceholder = styled.div`
  background: white;
  text-align: center;
  align-text: center;
  position: relative;
  margin: auto;
  z-index: 0;
  width: 100%;
  margin-top: 16px;

  user-select: none;
  max-width: 900px;

  canvas {
    height: 100% !important;
    width: 100% !important;
  }

  & > img {
    height: 100% !important;
    width: 100% !important;
  }

  & > video {
    height: 100% !important;
    width: 100% !important;
  }
`;
