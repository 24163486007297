import { Form, Col, Row, Empty, Tooltip } from 'antd';
import Input from '../../Input';
import Button from '../../Button';
import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { UserDataProps } from '../../../types/user';
import { TagInput as TagInputType } from '../../../types/tags';
import { themeConfig } from '../../../utils/theme';
import { startCase } from 'lodash';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiMinusCircle } from 'react-icons/fi';
import TagInput from '../../TagInput';

interface Props {
  userType: string;
  onTagUpdate?: (tags: TagInputType[], userIndex: number) => void;
  onUserListUpdate?: () => void;
  currentUsers?: any;
}

const UsersData: React.FC<Props> = (props) => {
  const { userType, onTagUpdate, currentUsers, onUserListUpdate } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    console.log('Resize detected:', window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Row style={{ height: '40vh', overflowX: 'hidden', overflowY: 'auto' }}>
      <Col span={24}>
        <Form.List
          name="invites"
          rules={[
            {
              validator: async (_, invites = []) => {
                const emails = invites.map((user: UserDataProps) => user?.email);
                let invalidEmails = '';
                emails.forEach((userEmail: string) => {
                  const existEmail = emails.filter((email: string) => userEmail && email === userEmail);
                  if (existEmail.length > 1) invalidEmails = `Email ${userEmail} is repeated`;
                });
                if (invalidEmails) {
                  return Promise.reject(new Error(invalidEmails));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {/* Header Row for Titles */}
              {!isMobile && fields.length > 0 && (
                <Row gutter={24}>
                  <Col xs={24} sm={6}>
                    <S.TitleInput>First Name</S.TitleInput>
                  </Col>
                  <Col xs={24} sm={6}>
                    <S.TitleInput>Last Name</S.TitleInput>
                  </Col>
                  <Col xs={24} sm={6}>
                    <S.TitleInput>Email/Username</S.TitleInput>
                  </Col>
                  <Col xs={24} sm={6}>
                    <S.TitleInput>Tags</S.TitleInput>
                  </Col>
                </Row>
              )}
  
              {/* Form Input Fields */}
              {fields.map((field, index) => (
                <Row
                  gutter={[16, 16]}
                  key={`${index}${field.key}${currentUsers?.length}`}
                  data-cy="component-user-data-student-row"
                >
                  <Col xs={24} sm={6}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'firstName']}
                      fieldKey={[field.fieldKey, 'firstName']}
                      rules={[{ required: true, message: 'Missing first name' }]}
                    >
                      <Input
                        data-cy="shared-inviteusers-userdata-firstName-input"
                        placeholder="Type first name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'lastName']}
                      fieldKey={[field.fieldKey, 'lastName']}
                      rules={[{ required: true, message: 'Missing last name' }]}
                    >
                      <Input
                        data-cy="shared-inviteusers-userdata-lastName-input"
                        placeholder="Type last name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'email']}
                      fieldKey={[field.fieldKey, 'email']}
                      rules={[{ required: true, message: 'Missing email or username' }]}
                    >
                      <Input
                        data-cy="shared-inviteusers-userdata-email-input"
                        placeholder="Type the email or username"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={5}>
                    <Form.Item {...field} name={[field.name, 'tags']} fieldKey={[field.fieldKey, 'tags']}>
                      <TagInput
                        alwaysEditable
                        maxTagCount={0}
                        backgroundColor="#f8f8f8"
                        height={37}
                        existingUserTags={currentUsers?.[index]?.tags}
                        onChange={(tags) => onTagUpdate?.(tags, index)}
                      />
                    </Form.Item>
                  </Col>
                  {
                    <Col span={1} style={{ padding: 0 }}>
                      <Tooltip title={`Remove ${userType}`}>
                        <FiMinusCircle
                          data-cy="component-user-data-student-remove"
                          onClick={() => {
                            remove(field.name);
                            onUserListUpdate?.();
                          }}
                          style={{ position: 'relative', top: 12, cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </Col>
                  }
                </Row>
              ))}
              
              {/* Empty State */}
              {!fields.length && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={`Click below to add ${userType}s`}
                />
              )}
              
              {/* Error and Add User Button */}
              <Row>
                <Col span={24}>
                  <Form.ErrorList errors={errors} />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={7}>
                  <Button
                    onClick={() => {
                      add();
                      onUserListUpdate?.();
                    }}
                    block
                    theme={themeConfig.noColor}
                    icon={<AiOutlinePlus />}
                    data-cy="component-user-data-add-user-button"
                    text={fields?.length ? 'Add More' : 'Add ' + startCase(userType)}
                  />
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};

export default React.memo(UsersData);
