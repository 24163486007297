import React, { ReactElement, useCallback } from 'react';
import { Row, Col, Progress } from 'antd';
import * as S from './styles';
import { BsClock } from 'react-icons/bs';
import { formatDistance } from '../../../../utils/date';
import { getProgressColor, themeConfig } from '../../../../utils/theme';
import { useHistory } from 'react-router-dom';

interface Props {
  timeLeft: number;
  investigationName: string;
  investigationId: string;
  timeWarning: boolean;
  icon: ReactElement;
  description: string;
  classId: string;
  progress: number;
  type: 'Assessment' | 'Investigation' | 'Design Challenge';
  isAssessment?: boolean;
  version?: number;
}

const Item: React.FC<Props> = (props) => {
  const {
    progress,
    timeLeft,
    investigationName,
    investigationId,
    timeWarning,
    description,
    icon,
    classId,
    type,
    isAssessment = false,
    version,
  } = props;
  const history = useHistory();

  const summaryType = isAssessment ? 'assessment-summary' : 'investigation-summary';

  const goToDashboard = useCallback(() => {
    history.push(`/teacher-dashboard/${summaryType}/${investigationId}`, {
      classId,
    });
  }, [investigationId, history, classId, summaryType]);

  return (
    <S.Card>
      <Row align="middle">
        <Col span={6} style={{ textAlign: 'left' }}>
          {icon}
        </Col>
        <Col span={13}>
          <S.Info $bold>Average Student Progress</S.Info>
        </Col>
        <Col span={5} style={{ textAlign: 'left' }}>
          <Progress
            strokeLinecap="round"
            strokeWidth={10}
            strokeColor={getProgressColor(progress)}
            format={(percent) => {
              return <S.Info $bold>{percent}%</S.Info>;
            }}
            type="circle"
            width={65}
            percent={Math.round(progress)}
          />
        </Col>
        <Col span={24} style={{ height: 50, display: 'table' }}>
          <S.Title $align="left">
            {investigationName} {version && version > 1 ? `v${version}` : null}
          </S.Title>
        </Col>
        <Col span={24}>
          <S.Description dangerouslySetInnerHTML={{ __html: description || '' }}></S.Description>
        </Col>
      </Row>
      <Row style={description ? { marginTop: '1em' } : { marginTop: '3em' }} gutter={16}>
        <Col span={16}>
          <S.DashboardButton
            theme={themeConfig.primaryOutlined}
            text={type + ' Dashboard'}
            htmlType="button"
            onClick={goToDashboard}
            block
          />
        </Col>
        <Col span={8} className="middle">
          <BsClock style={{ minWidth: 18, minHeight: 18, marginRight: 8 }} />
          <S.TimeLeft $fontSize="0.8em" $warning={timeWarning}>
            {Date.now() - timeLeft > 0 ? 'Finished' : formatDistance(timeLeft)}
          </S.TimeLeft>
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
