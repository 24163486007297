import { Col, Row, Table } from 'antd';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { centerAlign, Breakpoint } from '../../../../utils/antd';
import * as S from './styles';
import { GQL_AverageReportGainScore } from '../../../../types/charts';
import TableSearchBox from '../../../TableSearchBox';
import { FiSearch } from 'react-icons/fi';

const TableBreakPoint: Breakpoint[] = ['lg'];

interface Props {
  loading: boolean;
  pagination?: TablePaginationConfig;
  setPagination?: Dispatch<SetStateAction<TablePaginationConfig | undefined>>;
  data?: GQL_AverageReportGainScore[];
}

const Details = (props: Props) => {
  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchLastNameVisible, setSearchLastNameVisible] = useState(false);
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);

  const { loading, data, setPagination, pagination } = props;

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      if (pagination && setPagination) setPagination(pagination);
    },
    [setPagination],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'First Name',
        align: centerAlign,
        dataIndex: 'teacherFirstName',
        width: '20%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_AverageReportGainScore) => {
          return record.teacherFirstName.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Last Name',
        align: centerAlign,
        dataIndex: 'teacherLastName',
        width: '20%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refLastName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchLastNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_AverageReportGainScore) => {
          return record.teacherLastName.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchLastNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refLastName && refLastName.current) {
                refLastName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Email',
        align: centerAlign,
        dataIndex: 'teacherEmail',
        width: '20%',
        responsive: TableBreakPoint,
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refEmail} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_AverageReportGainScore) => {
          return record.teacherEmail.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Gain',
        align: centerAlign,
        sorter: (a: GQL_AverageReportGainScore, b: GQL_AverageReportGainScore) => a.gain - b.gain,
        dataIndex: 'gain',
        width: '20%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_AverageReportGainScore, index: number) => {
          return `${(record.gain * 100 || 0).toFixed(2)}%`;
        },
      },
    ];
  }, [searchEmailVisible, searchLastNameVisible, searchNameVisible]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_AverageReportGainScore) => record.teacherId}
              pagination={pagination}
              bordered
              dataSource={data}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Details;
