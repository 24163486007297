import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Form as AntdForm } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import * as S from './styles';
import Form from '../../../shared/Form';
import Input from '../../../shared/Input';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { GQL_ForgotPasswordResponse } from '../../../types/login';
import adiLearningHubSrc from '../../../assets/adi-learning-hub.svg';

const VerifyCodeForm = (props: { setStep: Dispatch<SetStateAction<number>> }) => {
  const [clickCount, setClickCount] = React.useState(0);

  const [submitEmail, { loading }] = useMutation(gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.forgotPassword, {
    onCompleted: ({ forgotPassword }: { forgotPassword: GQL_ForgotPasswordResponse }) => {
      if (forgotPassword.emailSent) props.setStep(2);
      else props.setStep(3);
    },
    onError: (e) => {
      props.setStep(3);
    },
  });

  const onFinish = useCallback(
    async (values: { email: string }) => {
      submitEmail({
        variables: {
          data: { email: values.email },
        },
      });
    },
    [submitEmail],
  );

  // Generate an error on purpose if it's clicked enough times
  useEffect(() => {
    if (clickCount === 1) {
      setTimeout(() => {
        setClickCount(0);
      }, 3000);
    }

    if (clickCount === 10) {
      throw new Error('Test Error ' + Date.now());
    }
  }, [clickCount]);

  return (
    <Form onFinish={onFinish}>
      <S.LogoImage src={adiLearningHubSrc} alt="Adi Learning Hub Logo" onClick={() => setClickCount(clickCount + 1)} />
      <S.Title>Recover Your Password</S.Title>
      <S.TitleInput> EMAIL </S.TitleInput>
      <AntdForm.Item
        data-cy="components-forgotpasswordpage-verifycodeform-email-field"
        style={{ marginBottom: 12 }}
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input icon={<MailOutlined />} placeholder="Enter your e-mail" />
      </AntdForm.Item>
      <S.Info>
        If this email exists in our database we will send a<br />
        recovery link for you to reset your password
      </S.Info>
      <AntdForm.Item style={{ marginBottom: 0, marginTop: 30 }}>
        <S.ResetPassButton
          data-cy="components-forgotpasswordpage-verifycodeform-reset-button"
          text="Reset Password"
          htmlType="submit"
          loading={loading}
          block
        />
      </AntdForm.Item>
    </Form>
  );
};

export default VerifyCodeForm;
