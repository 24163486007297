import { useQuery } from '@apollo/client';
import { Col, message, Modal, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Papa from 'papaparse';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { gqlSchema } from '../../../../gql/schema';
import { GQL_ExpiringSubscriptionTypes, GQL_SubscriptionExpiringDetailsResponse } from '../../../../types/charts';
import { centerAlign } from '../../../../utils/antd';
import { formatDateTime } from '../../../../utils/date';
import { downloadCsv } from '../../../../utils/files';
import Button from '../../../Button';
import * as S from './styles';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  type: GQL_ExpiringSubscriptionTypes;
  title: string;
}

const TeacherInvestigationRateDetail = (props: Props) => {
  const { visible, setVisible, type, title } = props;

  const { data, loading } = useQuery<{
    getExpiringSubscriptionsDetailsTable: GQL_SubscriptionExpiringDetailsResponse[];
  }>(gqlSchema.ChartSchema.queries.CHARTS.getExpiringSubscriptionsDetailsTable, {
    variables: { data: { type } },
    onError: (err) => {
      message.error(
        'There was an error loading the expiring subscription details: ' + err.message || 'Unexpected Error',
      );
    },
  });

  const columns: ColumnsType<GQL_SubscriptionExpiringDetailsResponse> = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: GQL_SubscriptionExpiringDetailsResponse, b: GQL_SubscriptionExpiringDetailsResponse) =>
          a.name.localeCompare(b.name),
        width: '30%',
        render: (text: string, record: GQL_SubscriptionExpiringDetailsResponse, index: number) => {
          return record.name;
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a: GQL_SubscriptionExpiringDetailsResponse, b: GQL_SubscriptionExpiringDetailsResponse) =>
          a.name.localeCompare(b.name),
        width: '40%',
        align: centerAlign,
        render: (text: string, record: GQL_SubscriptionExpiringDetailsResponse, index: number) => {
          return record.email;
        },
      },
      {
        title: 'Expires At',
        dataIndex: 'expiresAt',
        width: '15%',
        align: centerAlign,
        render: (text: string, record: GQL_SubscriptionExpiringDetailsResponse, index: number) => {
          return formatDateTime(record.expiresAt);
        },
      },
    ];
  }, []);

  const exportUsers = useCallback(() => {
    const headers = [['Name', 'Email', 'Expires At']];
    const csvData =
      data?.getExpiringSubscriptionsDetailsTable.map((user: GQL_SubscriptionExpiringDetailsResponse) => {
        return [user.name, user.email, formatDateTime(user.expiresAt)];
      }) || [];

    downloadCsv(Papa.unparse([...headers, ...csvData]), `${title} Details.csv`);
  }, [data, title]);

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(!visible);
      }}
      footer={
        <Row justify="center">
          <Col span={8}>
            <Button
              data-cy="shared-charts-expiringsubscription-details-download-button"
              text="Download"
              onClick={exportUsers}
              block
            />
          </Col>
        </Row>
      }
      width={900}
    >
      <S.Title>{title} Details</S.Title>
      <S.TableWrapper>
        <Table
          columns={columns}
          bordered
          loading={loading}
          dataSource={data?.getExpiringSubscriptionsDetailsTable}
          scroll={{ x: 'max-content' }}
          rowKey={(record) => record.id}
          sticky
        />
      </S.TableWrapper>
    </Modal>
  );
};

export default TeacherInvestigationRateDetail;
