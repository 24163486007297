import { GQL_InvestigationStep } from '../../types/investigation';

export const mockedStepList: GQL_InvestigationStep[] = [
  {
    activities: [
      {
        completed: false,
        id: 'a1',
        content: [
          {
            title: 'What do you notice and wonder?',
            blocks: [],
          },
        ],
        stepId: 's1',
        name: 'What do you notice?',
      },
    ],
    id: 's1',
    completed: false,
    name: 'Task',
  },
  {
    activities: [],
    id: 's2',
    completed: false,
    name: 'Ideas',
  },
  {
    activities: [
      {
        id: 'a1',
        name: 'Plan Your Investigation',
        completed: true,
        stepId: 's3',
        content: [],
      },
      {
        id: 'a2',
        name: 'Peer Review',
        completed: true,
        stepId: 's3',
        content: [],
      },
      {
        id: 'a3',
        name: 'Reflect',
        completed: false,
        stepId: 's3',
        content: [],
      },
    ],
    id: 's3',
    completed: false,
    name: 'Plan',
  },
  {
    activities: [],
    id: 's4',
    completed: false,
    name: 'Do',
  },
  {
    activities: [],
    id: 's5',
    completed: false,
    name: 'Share',
  },
  {
    activities: [],
    id: 's6',
    completed: false,
    name: 'Reflect',
  },
  {
    activities: [],
    id: 's7',
    completed: false,
    name: 'Report',
  },
];
