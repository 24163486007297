import { Row } from 'antd';
import styled from 'styled-components';

export const CardTitle = styled.div`
  font-weight: bold;
  color: #9b9b9b;
  font-size: 12px;
`;

export const PaymentMethodCard = styled(Row)`
  .cardData {
    display: flex;
    padding: 0 12px;
  }

  p {
    margin: 0;
  }

  .lastFour {
    font-weight: bold;
    display: flex;
    align-items: center;

    span {
      margin-right: 12px;
      margin-top: 8px;
    }
  }

  .cardInfoExpires {
    color: #9b9b9b;
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .cardBillingCycle {
    width: 100%;
    padding: 0 12px;
    font-size: 13px;

    p {
      color: #9b9b9b;
      margin: 0;
      font-weight: bold;
    }
    span {
      color: black;
    }
  }
`;
export const CardIcon = styled.div`
  font-size: 14px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
