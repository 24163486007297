import styled from 'styled-components';

export const ModalBody = styled.div`
  padding: 0px 76px 36px;
`;

export const ModalTitle = styled.h2`
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
`;
