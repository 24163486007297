import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Form as AntdForm, Col, Row, message, Divider, Tooltip, Checkbox, Upload } from 'antd';
import Form from '../../Form';
import Input from '../../Input';
import Button from '../../Button';
import * as S from './styles';
import Papa from 'papaparse';
import { useForm } from 'antd/lib/form/Form';
import { GQL_SendInviteResponse } from '../../../types/invites';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import RangePicker from '../../RangePicker';
import { toDateFormat } from '../../../utils/date';
import TagInput from '../../TagInput';
import { TagResponse } from '../../../types/tags';
import { FiHelpCircle, FiMinusCircle } from 'react-icons/fi';
import Switch from '../../Switch';
import Select from '../../Select';
import SelectOption from '../../Select/Option';
import { GQL_InvestigationMetadataCodebook } from '../../../types/investigation';
import { RcFile } from 'antd/lib/upload/interface';
import { SUBJECT_TYPES } from '../../../types/subjects';
import useFetchStatesData from '../../../hooks/useFetchStatesData';
import { subjects, grades } from '../../../utils/constants';
import { generateRandomColor } from '../../../components/ManageTagsPage';
import { themeConfig } from '../../../utils/theme';
import { useEnabledAssessments } from '../../../hooks/useEnabledAssessments';

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const FreelanceTeacher: React.FC<Props> = (props) => {
  const [acceptExistingUsers, setAcceptExistingUsers] = useState(false);
  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([]);
  const [importUserList, setImportUserList] = useState<
    { email: string; lastName: string; firstName: string; tags?: TagResponse[]; invalid?: boolean; reason?: string }[]
  >([]);
  const [, forceRerender] = useState(-1);

  const { data: codebookData } = useQuery<{ getInvestigationMetadataCodebook: GQL_InvestigationMetadataCodebook }>(
    gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationMetadataCodebook,
  );
  const disciplineResponse = useMemo(() => codebookData?.getInvestigationMetadataCodebook?.disciplines || [], [
    codebookData,
  ]);
  const { data: tagsData } = useQuery<{ getTags: TagResponse[] }>(gqlSchema.TagsSchema.queries.getTags);
  const existingTags = useMemo(() => tagsData?.getTags?.map((t) => ({ ...t, __typename: undefined })) || [], [
    tagsData,
  ]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const { statesData } = useFetchStatesData();

  const [form] = useForm();
  const formValues = form.getFieldsValue(['gradeBands', 'subjects', 'customGradeBands']);

  const [disableCustomGradeBands, setDisableCustomGradeBands] = useState(true);
  const [texasSubjectEnabled, setTexasSubjectEnabled] = useState(false);
  const [isAnySubjectSelected, setIsAnySubjectSelected] = useState(false);
  const gradeBands = ['K-2', '3-5', '6-8', '9-12'];
  const [selectedTexasGradeBands, setSelectedTexasGradeBands] = useState<string[]>(
    []
  );

  useEffect(() => {
    const selectedSubjects = formValues?.subjects || [];
    const texasInvestigationActive = selectedSubjects.includes(SUBJECT_TYPES.SCIENCE_TEXAS_EDITION);
    const otherSelected = Boolean(selectedSubjects.length > 1);
    const disabled = texasInvestigationActive ?? (!texasInvestigationActive || otherSelected);
    setDisableCustomGradeBands(!disabled);

  }, [formValues]);

  useEffect(() => {
    if (disableCustomGradeBands) {
      form.resetFields(['customGradeBands']);
      forceRerender(Math.random());
    }
  }, [disableCustomGradeBands, form]);

  useEffect(() => {
    // Check if any subject is selected
    const selectedSubjects = formValues?.subjects || [];
    setIsAnySubjectSelected(selectedSubjects.length > 0);
  }, [formValues.subjects]);
  const [, setAllGradesSelected] = useState(false);


  const disciplines = useMemo(() => {
    const possibleSubjects = formValues?.subjects?.length ? formValues.subjects : subjects;
    const possibleGradeBands = formValues?.gradeBands?.length ? formValues?.gradeBands : gradeBands;
    return disciplineResponse.filter(
      (d) => possibleSubjects.includes(d.subject) && possibleGradeBands.includes(d.gradeBand),
    );
  }, [disciplineResponse, formValues]);

  const [inviteFreelanceTeacher, { loading }] = useMutation<{ inviteFreelanceTeacher: GQL_SendInviteResponse }>(
    gqlSchema.InvitesSchema.mutations.SEND.inviteFreelanceTeacher,
    {
      onError: (err) => {
        message.error(err?.message || 'There was an error trying to create Freelance Teacher, please try again later');
      },
      onCompleted: (data) => {
        if (data?.inviteFreelanceTeacher.emailSent) {
          form.resetFields();
          message.success(`Freelance Teacher created successfully`);
          props.setVisible(false);
        }
      },
    },
  );

  const [inviteMultipleFreelanceTeachers, { loading: loadingMultiple }] = useMutation<{
    inviteMultipleFreelanceTeachers: GQL_SendInviteResponse[];
  }>(gqlSchema.InvitesSchema.mutations.SEND.inviteMultipleFreelanceTeachers, {
    onError: (err) => {
      message.error(err?.message || 'There was an error trying to create Freelance Teacher, please try again later');
    },
    onCompleted: (data) => {
      if (data?.inviteMultipleFreelanceTeachers) {
        if (data?.inviteMultipleFreelanceTeachers.every((d) => !d.emailSent)) {
          setAcceptExistingUsers(true);
          setImportUserList((currentImportUserList) => {
            const usersToImport = currentImportUserList.map((user, idx) => {
              const { invalid, reason } = data?.inviteMultipleFreelanceTeachers[idx];
              return { ...user, invalid, reason };
            });

            if (usersToImport.some(user => 'invalid' in user)) {
              setAcceptExistingUsers(false)
              message.error('Some users cannot be invited, please check again to add only the valid users')
            }

            return usersToImport
          });
        } else {
          form.resetFields();
          setImportUserList([]);
          message.success(`Freelance Teacher created successfully`);
          props.setVisible(false);
        }
      }
    },
  });

  const handleUserDetailsChangeFromImport = useCallback(
    (field, value, index) => {
      const updatedUserList = importUserList.map((user, i) => {
        if (i === index) {
          delete user.invalid
          delete user.reason
          return {
            ...user,
            [field]: value
          }
        } else {
          return user
        }
      })
      setImportUserList(updatedUserList)
    }, [importUserList]
  )

  const handleTagChange = useCallback(
    (tags: TagResponse[]) => {
      form.setFieldsValue({ tags: tags?.map((t) => ({ ...t, __typename: undefined })) });
    },
    [form],
  );

  const handleToggleChange = useCallback(
    (key: string, selected: string, checked: boolean) => {
      const source = form.getFieldValue(key) || [];
      let modified;

      if (checked) {
        modified = [...new Set([...source, selected])];
      } else {
        modified = source.filter((item: string) => item !== selected);
      }
      form.setFieldsValue({ [key]: modified });

      // Update 'Select All' based on individual selections
      if (key === 'gradeBands') {
        setAllGradesSelected(modified.length === gradeBands.length);
      }
      if (key === 'subjects' && selected === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION) {
        setTexasSubjectEnabled(checked);

        if (!checked) {
          setSelectedTexasGradeBands([]);
          form.setFieldsValue({ customGradeBands: [] });
          forceRerender(Math.random());
        }
      }
      forceRerender(Math.random());
    },
    [form, gradeBands.length, setSelectedTexasGradeBands, setTexasSubjectEnabled]
  );

  const [selectedGradeBands, setSelectedGradeBands] = useState<string[]>([]);

  const handleGradeBandChange = useCallback((gradeBand: string, checked: boolean) => {
    setSelectedGradeBands((prevSelected) =>
      checked
        ? [...new Set([...prevSelected, gradeBand])]
        : prevSelected.filter((item) => item !== gradeBand)
    );
  }, []);

  const handleSelectAllChange = useCallback(
    (checked: boolean) => {
      setSelectedGradeBands(checked ? gradeBands : []);
    },
    [gradeBands],
  );

  const handleSelectAllTexasChange = useCallback(() => {

    setSelectedTexasGradeBands(prevSelected => {
      const allSelected = prevSelected.length === grades.length;
      const newSelectedGrades = allSelected ? [] : [...grades];

      form.setFieldsValue({ customGradeBands: newSelectedGrades });
      forceRerender(Math.random());
      return newSelectedGrades;
    });
  }, [grades, form]);

  const handleTexasGradeBandChange = useCallback((gradeBand: string, checked: boolean) => {
    setSelectedTexasGradeBands((prevSelected) => {
      const updatedSelected = checked
        ? [...new Set([...prevSelected, gradeBand])]
        : prevSelected.filter((item) => item !== gradeBand);

      form.setFieldsValue({ customGradeBands: updatedSelected });
      forceRerender(Math.random());
      return updatedSelected;
    });
  }, [form]);
  useEffect(() => {
  }, [selectedTexasGradeBands]);


  useEffect(() => {
    form.setFieldsValue({
      gradeBands: selectedGradeBands,
      customGradeBands: selectedTexasGradeBands,
    });
  }, [selectedGradeBands, selectedTexasGradeBands, form]);

  // Sync state changes to form values
  useEffect(() => {
    form.setFieldsValue({
      gradeBands: selectedGradeBands,
      customGradeBands: selectedTexasGradeBands, // Sync selectedTexasGradeBands with form fields
    });
  }, [selectedGradeBands, selectedTexasGradeBands, form]);
  const { subjectsList, } = useEnabledAssessments({
    allowedSubjects: formValues?.subjects || [],
  });


  const onFinish = useCallback(
    async (data: {
      firstName: string;
      lastName: string;
      email: string;
      defaultTAQuantity: number;
      gradeBands: string[];
      customGradeBands: string[];
      customGradeBandsStandards: string[];
      subjects: string[];
      subscriptionData: {
        startDate: string;
        endDate: string;
      };
      taCustomPermissions: boolean;
      isTrial: boolean;
      readerPermissions: boolean;
      teachersCanDoReviews: boolean;
    }) => {
      const {
        subscriptionData,
        isTrial,
        taCustomPermissions,
        readerPermissions,
        teachersCanDoReviews,
        gradeBands,
        customGradeBands,
        customGradeBandsStandards,
        subjects,
        ...info
      } = data;

      const gradeBandsValues = [
        ...(gradeBands || []),
        ...(customGradeBands || []),
        ...(customGradeBandsStandards || []),
      ];

      if (importUserList?.length) {
        inviteMultipleFreelanceTeachers({
          variables: {
            acceptExistingUsers,
            data: importUserList.map(({ invalid, reason, ...user }) => {
              return {
                ...user,
                defaultTAQuantity: +info.defaultTAQuantity,
                subscriptionData: {
                  startDate: new Date(toDateFormat(subscriptionData.startDate)).getTime(),
                  endDate: new Date(toDateFormat(subscriptionData.endDate)).getTime(),
                  isTrial: isTrial || false,
                  taCustomPermissions: taCustomPermissions || false,
                  readerPermissions: readerPermissions || false,
                  teachersCanDoReviews: teachersCanDoReviews || false,
                  gradeBands: gradeBandsValues,
                  subjects: subjects || [],
                  disciplineIds: selectedDisciplines,
                },
              };
            }),
          },
        });
      } else {
        inviteFreelanceTeacher({
          variables: {
            data: {
              ...info,
              defaultTAQuantity: +info.defaultTAQuantity,
              subscriptionData: {
                startDate: new Date(toDateFormat(subscriptionData.startDate)).getTime(),
                endDate: new Date(toDateFormat(subscriptionData.endDate)).getTime(),
                isTrial: isTrial || false,
                taCustomPermissions: taCustomPermissions || false,
                readerPermissions: readerPermissions || false,
                teachersCanDoReviews: teachersCanDoReviews || false,
                gradeBands: gradeBandsValues,
                subjects: subjects || [],
                disciplineIds: selectedDisciplines,
              },
              allowedStates: selectedStates,
            },
          },
        });
      }
    },
    [
      inviteFreelanceTeacher,
      selectedDisciplines,
      importUserList,
      inviteMultipleFreelanceTeachers,
      selectedStates,
      acceptExistingUsers,
    ],
  );

  const onUpload = useCallback(
    (file: RcFile) => {
      Papa.parse(file, {
        complete: (results: any) => {
          const { data } = results;
          const firstNameIndex = data[0].findIndex((d: string) => d.toLowerCase().includes('first'));
          const lastNameIndex = data[0].findIndex((d: string) => d.toLowerCase().includes('last'));
          const emailIndex = data[0].findIndex((d: string) => d.toLowerCase().includes('email'));
          const tagsIndex = data[0].findIndex((d: string) => d.toLowerCase().includes('tag'));

          if (firstNameIndex === -1) {
            message.error('First Name not found in csv');
            return;
          }
          if (lastNameIndex === -1) {
            message.error('Last Name not found in csv');
            return;
          }
          if (emailIndex === -1) {
            message.error('Email not found in csv');
            return;
          }

          const filteredData = data.slice(1).filter((d: any) => d[firstNameIndex] && d[lastNameIndex] && d[emailIndex]);

          setImportUserList(
            filteredData.map((d: any[]) => ({
              firstName: d[firstNameIndex],
              lastName: d[lastNameIndex],
              email: d[emailIndex],
              tags:
                tagsIndex === -1
                  ? undefined
                  : d[tagsIndex]
                    .split(',')
                    ?.filter((t: string) => t)
                    ?.map(
                      (t: string) =>
                        existingTags.find(
                          (e) => e.tag.trim().toLocaleLowerCase() === t.trim().toLocaleLowerCase(),
                        ) || { tag: t.trim(), color: generateRandomColor() },
                    )
                    .filter((t: TagResponse) => t),
            })),
          );
        },
      });
    },
    [existingTags],
  );

  const handleEditImportedUsersTags = useCallback((tags: TagResponse[], index: number) => {
    setImportUserList((userList) =>
      userList.map((user, i) => {
        if (i !== index) return user;
        return { ...user, tags };
      }),
    );
  }, []);

  return (
    <Form onFinish={onFinish} form={form}>
      <Row gutter={[16, 16]} justify="center" style={{ marginTop: 10 }}>
        <Col span={24}>
          <Row justify="space-between" align="bottom">
            <S.SubTitle>User Details</S.SubTitle>
            <Upload
              accept="application/vnd.ms-excel, .csv"
              beforeUpload={onUpload}
              showUploadList={false}
              fileList={[]}
              customRequest={() => { }}
            >
              <S.ImportLink>Import Users</S.ImportLink>
            </Upload>
          </Row>
          <Divider style={{ margin: '5px 0' }} />
        </Col>
        {importUserList?.length ? (
          <Col span={20} style={{ maxHeight: 135, overflowY: 'scroll', overflowX: 'hidden' }}>
            {importUserList.map((user, i) => (
              <Tooltip
                title={user.reason}
                key={i}
                overlayInnerStyle={{ width: '33vw', textAlign: 'center' }}
                overlayStyle={{ textAlign: 'center' }}
              >
                <S.Row theme={themeConfig} justify="center" $invalid={!!user.invalid}>
                  <Col span={24}>
                    <Row gutter={[24, 16]} justify="start">
                      <Col span={5}>
                        <S.TitleInput>First Name</S.TitleInput>
                        <Input value={user.firstName}
                          onChange={(v) => handleUserDetailsChangeFromImport('firstName', v.target.value, i)}
                        />
                      </Col>
                      <Col span={5}>
                        <S.TitleInput>Last Name</S.TitleInput>
                        <Input value={user.lastName}
                          onChange={(v) => handleUserDetailsChangeFromImport('lastName', v.target.value, i)}
                        />
                      </Col>
                      <Col span={6}>
                        <S.TitleInput>Email</S.TitleInput>
                        <Input value={user.email} type='email'
                          onChange={(v) => handleUserDetailsChangeFromImport('email', v.target.value, i)}
                        />
                      </Col>
                      <Col span={6}>
                        <S.TitleInput>Tags</S.TitleInput>
                        <TagInput
                          alwaysEditable
                          backgroundColor="#f8f8f8"
                          existingUserTags={user.tags}
                          maxTagCount={0}
                          onChange={(tags) => handleEditImportedUsersTags(tags, i)}
                        />
                      </Col>
                      {/* if user is invalid, show a delete icon*/}
                      {user.invalid ? (
                        <Col
                          span={2}
                          style={{
                            width: '100%',
                            display: 'flex',
                            padding: 0,
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                          }}
                        >
                          <FiMinusCircle
                            style={{ color: '#ff4d4f', fontSize: 20, cursor: 'pointer', marginBottom: '12px' }}
                            onClick={() => {
                              setImportUserList((userList) => userList.filter((u) => u.email !== user.email));
                            }}
                          />
                        </Col>
                      ) : (
                        <Col span={2} />
                      )}
                    </Row>
                  </Col>
                </S.Row>
              </Tooltip>
            ))}
          </Col>
        ) : (
          <>
            <Col span={4} style={{ marginLeft: 1 }}>
              <S.TitleInput>First Name</S.TitleInput>
              <AntdForm.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Input the First Name!',
                  },
                ]}
              >
                <Input />
              </AntdForm.Item>
            </Col>
            <Col span={4}>
              <S.TitleInput>Last Name</S.TitleInput>
              <AntdForm.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Input the Last Name!',
                  },
                ]}
              >
                <Input />
              </AntdForm.Item>
            </Col>
            <Col span={6}>
              <S.TitleInput>Email</S.TitleInput>
              <AntdForm.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Input the Email!',
                  },
                  {
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </AntdForm.Item>
            </Col>
            <Col span={6}>
              <S.TitleInput>Tags</S.TitleInput>
              <AntdForm.Item name="tags">
                <TagInput alwaysEditable backgroundColor="#f8f8f8" maxTagCount={0} onChange={handleTagChange} />
              </AntdForm.Item>
            </Col>
          </>
        )}

        <Col span={24}>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={20}>
              <S.SubTitle>Subscription Details</S.SubTitle>
              <Divider style={{ margin: '5px 0' }} />
            </Col>

            <Col span={24}>
              <Row gutter={16} align="middle" justify="center">
                <Col span={12}>
                  <S.TitleInput>Duration</S.TitleInput>
                  <AntdForm.Item
                    name="subscriptionData"
                    rules={[
                      {
                        required: true,
                        message: 'Please select the subscription duration!',
                      },
                    ]}
                  >
                    <RangePicker />
                  </AntdForm.Item>
                </Col>

                <Col span={12}>
                  <S.TitleInput>Teacher Assistants</S.TitleInput>
                  <AntdForm.Item
                    name="defaultTAQuantity"
                    initialValue={3}
                    rules={[
                      {
                        required: true,
                        message: 'Please add Teacher Assistants quantity!',
                      },
                    ]}
                  >
                    <Input type="number" min={0} />
                  </AntdForm.Item>
                </Col>
              </Row>
              {/* Subscription Bands */}

              <Divider />
              <Col span={20}>
                <Row>
                  <Col span={8}>
                    <Row gutter={[0, 4]} align="middle">
                      <S.TitleInput style={{ margin: 0 }}>Subscription Subjects</S.TitleInput>
                      <Tooltip title="User  under this subscription can only assign investigations of subjects set below">
                        <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                      </Tooltip>

                      <AntdForm.Item name="subjects" noStyle>
                        {subjectsList.map(
                          (subject) =>
                            subject.key !== SUBJECT_TYPES.SCIENCE_TEXAS_EDITION && (
                              <Col span={24} key={subject.key}>
                                <Row align="middle">
                                  <Switch
                                    type="text"
                                    titleAlign="right"
                                    title={subject.label}
                                    disabled={!subject.allowed}
                                    onChange={(checked: boolean) =>
                                      handleToggleChange('subjects', subject.key, checked)
                                    }
                                  />

                                  {!subject.allowed && (
                                    <Tooltip title="Upgrade your subscription to enable this assessment">
                                      <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                                    </Tooltip>
                                  )}
                                </Row>
                              </Col>
                            )
                        )}
                      </AntdForm.Item>
                    </Row>
                  </Col>


                  <Col span={8}>
                    <Row gutter={[0, 4]} align="middle">
                      <S.TitleInput style={{ margin: 0 }}>Subscription Grade Levels</S.TitleInput>
                      <Tooltip title="User  under this subscription can only assign investigations of grades set below">
                        <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                      </Tooltip>

                      <AntdForm.Item name="gradeBands" noStyle>
                        {gradeBands.map((gradeBand) => (
                          <Col span={24} key={gradeBand}>
                            <Switch
                              type="text"
                              titleAlign="right"
                              title={`Grade ${gradeBand}`}
                              checked={selectedGradeBands.includes(gradeBand)}
                              disabled={!isAnySubjectSelected} // Disable if no subject is selected
                              onChange={(checked: boolean) => handleGradeBandChange(gradeBand, checked)}
                            />
                          </Col>
                        ))}
                      </AntdForm.Item>
                    </Row>
                  </Col>

                  <Col span={8}>
                    <Row align="middle">
                      <AntdForm.Item noStyle>
                        <Switch
                          type="text"
                          titleAlign="right"
                          title="Select All Grades"
                          checked={selectedGradeBands.length === gradeBands.length}
                          disabled={!isAnySubjectSelected} // Disable if no subject is selected
                          onChange={handleSelectAllChange}
                        />
                      </AntdForm.Item>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {/* Texas Grade Band */}
              <Divider />
              <Col span={20}>
                <Row>
                  <Col span={8}>
                    <Row gutter={[0, 4]} align="middle">
                      <S.TitleInput style={{ margin: 0 }}>Texas Subscription Subjects</S.TitleInput>
                      <Tooltip title="User under this subscription can only assign investigations of subjects set below">
                        <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                      </Tooltip>

                      <AntdForm.Item name="subjects" noStyle>
                        {subjectsList.map(
                          (subject) =>
                            subject.key === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION && (
                              <Col span={24} key={subject.key}>
                                <Row align="middle">
                                  <Switch
                                    type="text"
                                    titleAlign="right"
                                    title={subject.label}
                                    onChange={(checked: boolean) =>
                                      handleToggleChange('subjects', subject.key, checked)
                                    }
                                  />

                                  {!subject.allowed && (
                                    <Tooltip title="Upgrade your subscription to enable this assessment">
                                      <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                                    </Tooltip>
                                  )}
                                </Row>
                              </Col>
                            )
                        )}
                      </AntdForm.Item>
                    </Row>
                  </Col>

                  <Col span={8}>
                    <Row gutter={[0, 4]} align="middle">
                      <S.TitleInput style={{ margin: 0 }}>Texas Subscription Grade Levels</S.TitleInput>
                      <Tooltip title="User under this subscription can only assign investigations of grades set below">
                        {/* <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} /> */}
                      </Tooltip>

                      <AntdForm.Item name="customGradeBands" noStyle>
                        <Row gutter={[0, 4]} align="middle">
                          {grades.map((gradeBand) => (
                            <Col span={24} key={gradeBand}>
                              <Switch
                                disabled={!texasSubjectEnabled}
                                type="text"
                                titleAlign="right"
                                title={`Grade ${gradeBand.replace('tx.', '')}`}
                                checked={selectedTexasGradeBands.includes(gradeBand)} // Controlled by state
                                onChange={(checked: boolean) => handleTexasGradeBandChange(gradeBand, checked)}
                              />
                            </Col>
                          ))}
                        </Row>
                      </AntdForm.Item>

                    </Row>
                  </Col>

                  <Col span={8}>
                    <Row gutter={[0, 8]} align="middle" justify="center">
                      <AntdForm.Item noStyle>
                        <Switch
                          type="text"
                          titleAlign="right"
                          title={`Select All Texas Grade`}
                          disabled={!texasSubjectEnabled}
                          checked={selectedTexasGradeBands.length === grades.length}
                          onChange={() => handleSelectAllTexasChange()}
                        />
                      </AntdForm.Item>
                    </Row>
                  </Col>
                </Row>
              </Col>

              
              <Row gutter={24} align="middle" justify="center">

                <Col span={24} style={{ marginTop: 30, marginBottom: 10 }}>
                  <Row gutter={[0, 8]} align="middle">
                    <S.TitleInput style={{ margin: 0 }}>Subscription Disciplines</S.TitleInput>
                    <Tooltip title="User under this subscription can only assign investigations of disciplines set below">
                      <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                    </Tooltip>

                    <Col span={24}>
                      <S.DisciplineSelectorContainer>
                        <Select
                          placeholder="No Disciplines Selected"
                          showSearch
                          mode="multiple"
                          value={selectedDisciplines}
                          filterOption={(input, option) =>
                            option?.children?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
                          }
                          loading={false}
                          allowClear
                          onChange={(v) => setSelectedDisciplines(v as number[])}
                        >
                          {disciplines?.map((d) => (
                            <SelectOption value={d.id} key={d.id}>
                              {d.name}
                            </SelectOption>
                          ))}
                        </Select>
                      </S.DisciplineSelectorContainer>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={20}>
              <S.SubTitle>Permissions</S.SubTitle>
              <Divider style={{ margin: '5px 0' }} />
            </Col>

            <Col span={20}>
              <Row>
                <Col span={12}>
                  <AntdForm.Item name="isTrial" valuePropName="checked" style={{ marginBottom: 0 }}>
                    <Checkbox>
                      <Tooltip title="Limits access to resources.">
                        <span role="none" >Trial Subscription</span>
                      </Tooltip>
                    </Checkbox>
                  </AntdForm.Item>
                </Col>

                <Col span={12}>
                  <AntdForm.Item name="taCustomPermissions" valuePropName="checked" style={{ marginBottom: 0 }}>
                    <Checkbox>
                      <Tooltip title="This allows assistants to grade students' final reports and allow them to chat with students.">
                        <span role="none" >Super TA</span>
                      </Tooltip>
                    </Checkbox>
                  </AntdForm.Item>
                </Col>

                <Col span={12}>
                  <AntdForm.Item name="readerPermissions" valuePropName="checked" style={{ marginBottom: 0 }}>
                    <Checkbox>
                      <Tooltip title="Allows students to use the immersive reader on investigations.">
                        <span role="none" >Immersive Reader</span>
                      </Tooltip>
                    </Checkbox>
                  </AntdForm.Item>
                </Col>

                <Col span={12}>
                  <AntdForm.Item name="teachersCanDoReviews" valuePropName="checked" style={{ marginBottom: 0 }}>
                    <Checkbox>
                      <Tooltip title="Allows teachers and teacher assistants to complete peer reviews and reflections for students.">
                        <span role="none" >Super Teacher</span>
                      </Tooltip>
                    </Checkbox>
                  </AntdForm.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={20}>
          <S.SubTitle>State Standards</S.SubTitle>
          <Divider style={{ margin: '5px 0' }} />
        </Col>

        <Col span={20}>
          <S.DisciplineSelectorContainer>
            <Select
              allowClear
              showSearch
              placeholder="No States Selected"
              value={selectedStates}
              onChange={(v) => setSelectedStates([v as string])}
            >
              {statesData?.map((state) => (
                <SelectOption value={state.id} key={state.id}>
                  {state.name}
                </SelectOption>
              ))}
            </Select>
          </S.DisciplineSelectorContainer>
        </Col>

        <Col span={8}>
          <Row justify="center">
            <AntdForm.Item>
              <Button
                data-cy="shared-adduser-freelanceteacher-submit-button"
                block
                loading={loading || loadingMultiple}
                text="Add Freelance Teacher"
                htmlType="submit"
              />
            </AntdForm.Item>
            {importUserList.some((user) => user.invalid) && !importUserList.every((user) => user.invalid) && (
              <Tooltip title="Some users cannot be invited, click again to add only the valid users">
                <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
              </Tooltip>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(FreelanceTeacher);
