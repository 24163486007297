import { gql } from '@apollo/client';

const TAGS_FRAGMENTS = {
  tagResponse: gql`
    fragment TagResponseFragment on TagResponse {
      id
      tag
      color
      isPrivate
    }
  `,
};

export default TAGS_FRAGMENTS;
