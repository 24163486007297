import React from 'react';
import { useQuery } from '@apollo/client';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import ClassInfos from './ClassInfos';
import { GQL_GoogleClassroomCourses } from '../../types/googleClassroom';
import ActiveAssignments from './ActiveAssignments';
import FinishedAssignments from './FinishedAssignments';
import StudentsTable from './StudentsTable';

const GoogleStudentsClassDashboard: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  // Fetch course details
  const { data: courseData, loading: courseLoading } = useQuery<{ getCourseByCourseId: GQL_GoogleClassroomCourses }, { courseId: string }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseByCourseId,
    {
      variables: { courseId: id },
      onError: (err) => {
        message.error('There was an error loading class: ' + (err.message || 'Unexpected Error'));
      },
    },
  );

  // Fetch assignments using GetCourseWorkSubmissionListByTurnedIn
  const { data: assignmentsData, loading: assignmentsLoading } = useQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionListByTurnedIn,
    {
      variables: { courseId: [id] },
      onError: (err) => {
        message.error('There was an error loading assignments: ' + (err.message || 'Unexpected Error'));
      },
    },
  );

  const activeAssignments = assignmentsData?.getCourseWorkSubmissionListByTurnedIn?.activeSubmissionDashboard || [];
  const finishedAssignments = assignmentsData?.getCourseWorkSubmissionListByTurnedIn?.expiredSubmissionDashboard || [];

  console.log('Course ID:', id);

  return (
    <PageWithTitle title="Class Details" backPageUrl="/googleclassroom-students-dashboard">
      <ClassInfos classInfo={courseData?.getCourseByCourseId} loading={courseLoading} />
      <ActiveAssignments
        assignments={activeAssignments}
        loading={assignmentsLoading}
        courseId={id}
        courseName={courseData?.getCourseByCourseId?.name || ''}
        className={courseData?.getCourseByCourseId?.name || ''} // Ensure className is passed
      />
      <FinishedAssignments
        assignments={finishedAssignments}
        loading={assignmentsLoading}
        courseId={id}
        courseData={courseData}
      />
      <StudentsTable classId={id} loading={courseLoading} />
    </PageWithTitle>
  );
};

export default GoogleStudentsClassDashboard;
