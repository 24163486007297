/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import * as S from '../styles';
import moment from 'moment';

export type DatePickerProps = {
  isTimePicker?: boolean;
  dateValue?: number | string;
  format?: string;
  inputFormat?: string;
  data: any;
  handleChange: any;
  disabled: boolean;
};

export const AssessmentDateOrTimePicker: React.FC<DatePickerProps> = (props) => {
  const {
    isTimePicker = false,
    disabled = false,
    dateValue,
    inputFormat = 'MM.DD.YYYY',
    handleChange,
    data = {},
  } = props;

  const [elementValue, setElementValue] = useState(moment(dateValue));

  const handleTimeUpdate = (value: moment.Moment | null) => {
    setElementValue(moment(value));
    handleChange(Number(value?.format('x')));
  };

  return (
    <>
      {isTimePicker ? (
        <S.TimePickerContainer
          data-cy={`timepicker-assessment-summary-table-${data?.index}`}
          format={inputFormat}
          disabled={disabled}
          onSelect={handleTimeUpdate}
          value={elementValue}
        />
      ) : (
        <S.DatePicker
          data-cy={`datepicker-assessment-summary-table-${data?.index}`}
          format={inputFormat}
          disabled={disabled}
          onSelect={(date: moment.Moment | null) => handleChange(Number(date?.format('x')))}
        />
      )}
    </>
  );
};
