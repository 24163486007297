import styled from 'styled-components';
import { Col, Row } from 'antd';

export const Container = styled.div`
  text-align: center;
`;

export const StyledCol = styled(Col)`
  display: flex;
 min-height: auto;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  img {
    height: 80px;
    margin: 20px 0;
    display: block;
  }

`;

export const StyledRow = styled(Row)`
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 60vh;
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  color: #3f3e38;
  font-size: 32px;
  font-weight: 700;
  }
  Input{
    width: 100%;
    }
`;