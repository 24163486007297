import React from 'react';
import { IOListContentValue } from '../../../types/investigation';
import { applySpanToContentValueText } from '../../../utils/investigation';
import * as S from './styles';

interface IInvestigationOList {
  values: IOListContentValue[];
}

const InvestigationOList = (props: IInvestigationOList) => {
  const { values } = props;

  return (
    <S.OList>
      {values.map((value, i) => (
        <li
          key={i}
          dangerouslySetInnerHTML={{
            __html: applySpanToContentValueText(value),
          }}
        />
      ))}
    </S.OList>
  );
};

export default InvestigationOList;
