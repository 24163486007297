import { Col as AntdCol, Divider } from 'antd';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';

export const Body = styled(AntdCol)`
  background-color: #fff;
  border-radius: 5px;
  padding: 24px;
`;

export const Col = styled(AntdCol)`
  .ant-form-item {
    margin: 0;
  }
`;

export const CDivider = styled(Divider)`
  margin: 0;
`;

export const TitleInput = styled.div`
  font-weight: 600;
  font-size: 1.1em;
  margin: 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  align-items: center;
  .ant-progress {
    flex: 1;
    margin-left: 16px;
  }
`;

export const SInput = styled(Input)<{
  $bold?: boolean;
}>`
  font-size: 0.95em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  font-weight: ${(props) => (props.$bold ? '500' : 'unset')};
  .ant-input-suffix {
    display: none;
  }
`;

export const EditButton = styled(Button)`
  background: ${(props) => props.theme.primaryColor.background};
  border-color: ${(props) => props.theme.primaryColor.background};
  line-height: 0;
  min-height: 33px;
  svg {
    font-size: 20px;
  }
  &:hover,
  &:active,
  &:focus {
    background: ${(props) => props.theme.primaryColor.background};
    border-color: ${(props) => props.theme.primaryColor.background};
  }
`;

export const CancelButton = styled(EditButton)``;

export const SubText = styled.p`
  margin: 0;
  font-size: 15px;
  color: #767676 !important;
  height: 31px;
  display: table-cell;
  vertical-align: middle;
`;

export const CentralizedDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DisciplineSelectorContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;