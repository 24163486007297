import { Select } from 'antd';
import styled from 'styled-components';

export const AntdSelect = styled(Select)<{ $suffixItemOnLeft?: boolean; $height?: number; $backgroundColor?: string }>`
  width: 100%;
  .ant-select-selector {
    border-radius: 6px !important;
    align-items: center;
    ${(props) => props.$height && `height: ${props.$height}px !important;`}
    ${(props) => props.$backgroundColor && `background-color: ${props.$backgroundColor} !important;`}
  }

  .ant-select-selection-item:after {
    display: none !important;
  }

  ${(props) =>
    props.$suffixItemOnLeft &&
    `
      .ant-select-selector {
        padding-left: 29px !important;
      }

      .ant-select-selection-placeholder {
        padding-right: 0 !important;
      }

      .ant-select-selection-search {
        margin-left: 18px;
      }

      .ant-select-arrow {
        left: 9px;
        height: 16px;
        width: 16px;
        top: 44%;
        svg {
          color: #3F3E38;
          height: 16px;
          width: 16px;
        }
      }
  `}

  ${(props) =>
    props.mode === 'multiple' &&
    `
    .ant-select-selection-item {
      color: white;
      background: #7B8CEA !important;
      border-radius: 6px;

      svg {
        color: white;
        min-width: 16px;
        min-height: 16px;
        margin-top: 8px;
      }
    }
  `}
`;
