import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import message from 'antd/lib/message';
import { union } from 'lodash';

import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import { GQL_ClassResponse } from '../../types/class';
import ClassInfos from './ClassInfos';
import ActiveInvestigationsRow from '../../shared/StudentDashboardRows/ActiveInvestigationsRow';
import { GQL_InvestigationDashboard } from '../../types/investigation';
import FinishedInvestigationsRow from '../../shared/StudentDashboardRows/FinishedInvestigationsRow';
import TeacherAnnouncementsRow from '../../shared/StudentDashboardRows/TeacherAnnouncementsRow';
import { finishedInvestigationFilter } from '../../utils/investigation';
import { GQL_ClassesSubjects } from '../../types/class';
import FinishedAssessmentsRow from '../../shared/StudentDashboardRows/FinishedAssessmentsRow';
import ActiveAssessmentsRow from '../../shared/StudentDashboardRows/ActiveAssessmentsRow';
import { SUBJECT_TYPES } from '../../types/subjects';

type Props = RouteComponentProps<{ id: string }, any, { className: string }>;

const StudentClassDetail: React.FC<Props> = (props) => {
  const { id } = props.match.params;
  const className = props.location.state?.className;
  const [hasNonEnterpriseTeacher, setHasNonEnterpriseTeacher] = useState(true);

  const { data, loading } = useQuery<{ getClass: GQL_ClassResponse }, { data: { classId: string } }>(
    gqlSchema.ClassSchema.query.CLASS.CLASSES.getClass,
    {
      variables: { data: { classId: id } },
      onError: (err) => {
        message.error('There was an error loading the class: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const { data: investigations, loading: loadingInvestigations, error: errorInvestigations } = useQuery<{
    getInvestigationsByUser: GQL_InvestigationDashboard[];
  }>(gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationsByUser, {
    onError: (error) => {
      message.error(
        error.message || 'There was an error loading your investigations, check you connection and try again later.',
      );
    },
  });

  const { data: classesSubjects } = useQuery<{
    getClassesSubjects: GQL_ClassesSubjects[];
  }>(gqlSchema.ClassSchema.query.CLASS.CLASSES.getClassesSubjects, {
    onCompleted: (classesSubjects) => {
      if (classesSubjects?.getClassesSubjects) {
        const hasNonEnterpriseTeacher = classesSubjects?.getClassesSubjects.some(
          (each: GQL_ClassesSubjects) => each.isEnterpriseTeacher === false,
        );
        setHasNonEnterpriseTeacher(hasNonEnterpriseTeacher);
      }
    },
    onError: (error) => {
      message.error(
        error.message || 'There was an error loading your classes subjects, check you connection and try again later.',
      );
    },
  });

  const hasTexasSubjectEnabled = useMemo(() => {
    let hasTexasSubjectEnabled = false;

    const allSubjects = classesSubjects?.getClassesSubjects.map(
      (each: GQL_ClassesSubjects) => each.teacherAllowedSubjects || [],
    );

    if (allSubjects?.length) {
      const allSubjectsMerged = union(...allSubjects);
      hasTexasSubjectEnabled = allSubjectsMerged.some((each) => each === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION);
    }

    return hasTexasSubjectEnabled;
  }, [classesSubjects]);

  const classInvestigations = investigations?.getInvestigationsByUser.filter(
    (investigation: GQL_InvestigationDashboard) => investigation.classId === id,
  );

  const finishedInvestigations = classInvestigations
    ?.filter((investigation) => !investigation.isAssessment)
    ?.filter(finishedInvestigationFilter);
  const activeInvestigations =
    classInvestigations
      ?.filter((investigation) => !investigation.isAssessment)
      ?.filter((i) => !finishedInvestigationFilter(i)) || [];

  const activeAssessments = useMemo(() => {
    return classInvestigations
      ?.filter((investigation) => investigation.isAssessment)
      ?.filter((i) => !finishedInvestigationFilter(i));
  }, [classInvestigations]);

  const finishedAssessments = useMemo(() => {
    return classInvestigations
      ?.filter((investigation) => investigation.isAssessment)
      ?.filter(finishedInvestigationFilter);
  }, [classInvestigations]);

  return (
    <>
      <PageWithTitle title={`${data?.getClass?.name || className} Information`} backPageUrl="/teacher-dashboard">
        <ClassInfos classInfo={data?.getClass} loading={loading} />
        <TeacherAnnouncementsRow classId={id} />

        <ActiveInvestigationsRow
          loading={loadingInvestigations}
          investigations={activeInvestigations?.sort((a, b) => a.dueDate - b.dueDate)}
          error={errorInvestigations?.message}
        />

        {(hasTexasSubjectEnabled || hasNonEnterpriseTeacher) && (
          <ActiveAssessmentsRow loading={loading} assessments={activeAssessments} />
        )}
        <FinishedInvestigationsRow
          loading={loadingInvestigations}
          investigations={finishedInvestigations?.sort((a, b) => b.dueDate - a.dueDate)}
          error={errorInvestigations?.message}
        />
        {(hasTexasSubjectEnabled || hasNonEnterpriseTeacher) && (
          <FinishedAssessmentsRow assessments={finishedAssessments} loading={loading} />
        )}
      </PageWithTitle>
    </>
  );
};

export default withRouter(StudentClassDetail);
