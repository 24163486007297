import { Col, Layout, Row } from 'antd';
import React from 'react';
import InvestigationContent from '../../shared/InvestigationContent';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import StagesSettingsSider from './StagesSettingsSider';

type Props = RouteComponentProps<
  {
    investigationId: string;
  },
  any,
  {
    initialStageId?: string;
  }
>;

const AdiEditInvestigationStagesPage: React.FC<Props> = (props) => {
  const { investigationId } = props.match.params;
  const { initialStageId } = props.location.state || {};

  return (
    <Layout>
      <StagesSettingsSider investigationId={investigationId} initialStageId={initialStageId} />
      <InvestigationContent>
        <Row justify="center" style={{ height: 'calc(100vh - 80px - 8em)' }}>
          <Col xs={23} md={23} lg={23} xxl={20} />
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(AdiEditInvestigationStagesPage);
