import { Tabs, Upload } from 'antd';
import styled from 'styled-components';

export const SiderContainer = styled.div`
  height: calc(100% - 58px);
  display: flex;
  flex-direction: column;
`;

export const SettingsContainer = styled.div`
  height: 100%;
  overflow: auto;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125.1%;
    /* identical to box height, or 23px */

    color: #767676;
  }
`;

export const UploadContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  color: #3f3e38;
  font-size: 14px;
  align-items: center;
  position: relative;

  svg:nth-child(3) {
    position: absolute;
    right: 16px;
  }
`;

export const UploadOutsider = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
`;

export const SiderTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;

export const ActivityContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  padding: 8px 16px;

  cursor: pointer;

  & > span {
    display: flex;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.primaryColor.background};
  }

  .ant-tabs-nav-list,
  .ant-tabs-tab {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
  }

  .ant-tabs-tab {
    display: flex;
    flex-direction: column;
    justfiy-content: center;
    align-items: center;
    text-align: center;

    margin-right: 0;

    padding-bottom: 0;

    svg {
      color: #2d2d2d33;
      min-height: 22px;
      min-width: 22px;
    }

    h2 {
      color: #2d2d2d33;
      font-size: 15px;
    }
  }

  .ant-tabs-tab-active {
    svg {
      color: #2d2d2d;
    }

    h2 {
      color: #2d2d2d;
    }
  }
`;

export const StyledTabPane = styled(Tabs.TabPane)``;

export const InvestigationComponent = styled.div<{ disabled?: boolean }>`
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.199956);
  border-radius: 4px;
  background: white;
  height: 110px;
  padding: 16px 16px;
  text-align: center;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
    background: #e5e5e5;
    cursor: not-allowed;
  `}

  h1 {
    margin-bottom: 0;
  }

  svg {
    min-height: 28px;
    min-width: 28px;
  }
`;

export const PreviewModeDisclaimer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  align-text: center;
  color: #767676;
  font-weight: 500;
  font-size: 15px;
`;
