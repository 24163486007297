import { Skeleton } from 'antd';
import styled from 'styled-components';
import { InvestigationType } from '../../../types/investigation';

const contentSizeMultiplier = (contentSize?: string) => {
  if (contentSize === 'large') return 1.2;
  else if (contentSize === 'small') return 0.8;
  else return 1;
};

const getImageSizeMultiplier = (size?: 'SMALL' | 'LARGE' | 'EXTRALARGE') => {
  if (size === 'SMALL') return 0.4;
  else if (size === 'EXTRALARGE') return 1.4;
  else return 1;
};

export const InvestigationImage = styled.div<{
  loaded: boolean;
  investigationType?: InvestigationType;
  contentSize?: string;
  size?: 'SMALL' | 'LARGE' | 'EXTRALARGE';
  imageURL?: string;
}>`
  background: url(${(p) => p.imageURL});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  ${(props) => !props.loaded && 'display: none;'}
  max-width: 100%;
  border-radius: 6px;
  max-height: ${(props) =>
    (props.investigationType === 'WORKSHOP' ? 60 : 40) *
    getImageSizeMultiplier(props.size) *
    contentSizeMultiplier(props.contentSize)}vh;
  height: ${(props) =>
    (props.investigationType === 'WORKSHOP' ? 60 : 40) *
    getImageSizeMultiplier(props.size) *
    contentSizeMultiplier(props.contentSize)}vh;
  cursor: zoom-in;
  @media (max-width: 768px) {
    max-height: 500px;
  }
`;

export const ZoomedImage = styled.div<{ imageURL: string }>`
  background: url(${(p) => p.imageURL});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  background-color: rgb(245, 245, 251);
`;

export const InvestigationImagePlaceholder = styled(Skeleton.Image)`
  width: 80% !important;
  height: 350px !important;
  margin: auto;
`;
