import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Col, message, Row } from 'antd';
import Button from '../Button';
import * as S from './styles';
import Input from '../Input';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  title: string;
  lineInfo1: string;
  lineInfo2: string;
  deleteButtonTitle: string;
  nameToCheck: string;
  errorMessage: string;
  loading: boolean;
  deleteFn: () => void;
}

const ModalConfirm: React.FC<Props> = (props) => {
  const {
    visible,
    setVisible,
    title,
    lineInfo1,
    lineInfo2,
    deleteButtonTitle,
    loading,
    nameToCheck,
    deleteFn,
    errorMessage,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const onCloseModal = () => {
    setVisible(false);
  };

  const onDelete = useCallback(() => {
    if (nameToCheck.toLocaleLowerCase().trim() === inputValue.toLocaleLowerCase().trim()) deleteFn();
    else message.error(errorMessage);
  }, [deleteFn, inputValue, nameToCheck, errorMessage]);

  return (
    <S.CModal
      visible={visible}
      maskClosable={false}
      title={
        <Row>
          <Col span={24}>
            <S.Title>{title} </S.Title>
          </Col>
        </Row>
      }
      onCancel={onCloseModal}
      width={800}
      footer={null}
    >
      <Row justify="center">
        <Col span={16}>
          <S.Info>{lineInfo1}</S.Info>
          <S.Info>{lineInfo2}</S.Info>
        </Col>
      </Row>
      <Row gutter={16} justify="center" style={{ margin: '50px 0' }}>
        <Col span={11}>
          <S.TitleInput>
            Type <S.TitleInputBold>{nameToCheck}</S.TitleInputBold> to Confirm
          </S.TitleInput>
          <Input
            data-cy="shared-modalconfirm-input-delete"
            value={inputValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
          />
        </Col>
        <Col span={5}>
          <Button
            data-cy="shared-modalconfirm-button-delete"
            block
            loading={loading}
            text={deleteButtonTitle}
            disabled={!inputValue}
            onClick={onDelete}
            style={{ bottom: 1, position: 'absolute' }}
          />
        </Col>
      </Row>
    </S.CModal>
  );
};

export default ModalConfirm;
