import { Col } from 'antd';
import React from 'react';
import { InvestigationBookLoading } from '../../../shared/InvestigationBook';
import * as S from '../styles';

const InvestigationDetailsLoading = () => {
  return (
    <S.PageContainer justify="center">
      <Col xs={22} md={18} lg={16}>
        <InvestigationBookLoading />
      </Col>
    </S.PageContainer>
  );
};

export default InvestigationDetailsLoading;
