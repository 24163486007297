import { gql } from '@apollo/client';

const INVESTIGATION_FOCUS_QUERIES = {
  GET: {
    getInvestigationFocuses: gql`
      query GetInvestigationFocuses($state: String) {
        getInvestigationFocuses(state: $state) {
          id
          name
          state {
            id
            postalCode
          }
        }
      }
    `,
    getInvestigationFocusById: gql`
      query GetInvestigationFocusById($id: String) {
        getInvestigationFocusById(id: $id) {
          id
          name
          state {
            id
            postalCode
          }
        }
      }
    `,
  },
};

export default INVESTIGATION_FOCUS_QUERIES;
