import React, { useEffect, useState } from 'react';
import { Select, Row, Col, Pagination, Typography, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import * as S from './styles';
import Button from '../../../shared/Button';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { useHistory } from 'react-router-dom';
import { IoCloseCircle } from 'react-icons/io5';

const { Title } = Typography;
const { Option } = Select;

interface Book {
  id: string;
  title: string;
  grade: string;
  coverImage: string;
}

const BookAdmin: React.FC = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGrade, setSelectedGrade] = useState<string | undefined>(undefined);
  const [selectedSubject, setSelectedSubject] = useState<string | undefined>(undefined);
  const booksPerPage = 8;
  const subjectOptions = [
    { key: 'K', value: 'Grade K' },
    { key: '1', value: 'Grade 1' },
    { key: '2', value: 'Grade 2' },
    { key: '3', value: 'Grade 3' },
    { key: '4', value: 'Grade 4' },
    { key: '5', value: 'Grade 5' },
    { key: '6', value: 'Grade 6' },
    { key: '7', value: 'Grade 7' },
    { key: '8', value: 'Grade 8' },
    { key: 'K-2', value: 'Grade K-2' },
    { key: '3-5', value: 'Grade 3-5' },
    { key: '5-8', value: 'Grade 5-8' },
    { key: '9-12', value: 'Grade 9-12' },
  ];

  const { data, error, refetch } = useQuery(gqlSchema.BookSchema.queries.FetchAllBooks, {
    variables: {
      page: currentPage,
      search: searchQuery,
      filter: { standard: selectedGrade, subject: selectedSubject },
    },
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });
  useEffect(() => {
    const query:{page:number, search:string,filter:{standard?:string,subject?:string}} = { page: currentPage, search: searchQuery, filter: {} };
    if (selectedGrade) {
      query.filter.standard = selectedGrade;
    }
    if (selectedSubject) {
      query.filter.subject = selectedSubject;
    }
    //@ts-ignore
    refetch(query);
  }, [searchQuery, selectedGrade, selectedSubject, currentPage, refetch]);

  if (error) return <p>Error: {error.message}</p>;

  const booksData: Book[] = data?.fetchAllBooks?.bookResponse.map((book: any) => ({
    id: book.id,
    title: book.title,
    grade: book.state,
    coverImage: book.imageUrl[0] || '/path/to/default/image.jpg',
    description: book.description || 'No description available', 
    investigations: book.investigations || 0,
  }));

  const handlePageChange = (page: number) => setCurrentPage(page);

  return (
    <S.Container>
      <Title level={2}>All Books</Title>

      <S.FilterRow>
        <S.SearchContainer>
          <SearchOutlined
            onClick={() => console.log('Wow')}
            style={{ position: 'absolute', backgroundColor: 'transparent', marginLeft: 10 }}
          />
          <S.SearchInput type="search" placeholder="Search Book" onChange={(e) => setSearchQuery(e.target.value)} />
        </S.SearchContainer>
        <Select
          placeholder="Select Grade"
          value={selectedGrade}
          onChange={setSelectedGrade}
          style={{ width: 150, marginLeft: '10px' }}
        >
          {subjectOptions.map((option) => (
            <Option key={option.key} value={option.key}>
              {option.value} 
            </Option>
          ))}
        </Select>
        {selectedGrade && <IoCloseCircle size={20} onClick={() => setSelectedGrade('')} style={{ color: 'red' }} />}

        <Select
          placeholder="Select Subject"
          value={selectedSubject}
          onChange={setSelectedSubject}
          style={{ width: 150, marginLeft: '10px' }}
        >
          <Option value="SCIENCE">Science</Option>
          <Option value="MATHS">Maths</Option>
          <Option value="ENGINEERING">Engineering</Option>
          <Option value="CHEMISTRY">Chemistry</Option>
          <Option value="PHYSICS">Physics</Option>
          <Option value="BIOLOGY">Biology</Option>
         
        </Select>
        {selectedSubject && <IoCloseCircle size={20} onClick={() => setSelectedSubject('')} style={{ color: 'red' }} />}
      </S.FilterRow>

      <CardSection booksData={booksData} onView={(id: string) => history.push(`/bookedit/${id}`)} />

      <S.PaginationContainer>
        <Pagination
          current={currentPage}
          //TODO change later
          total={data?.fetchAllBooks?.totalBooks || 0}
          pageSize={booksPerPage}
          onChange={handlePageChange}
        />
      </S.PaginationContainer>
    </S.Container>
  );
};

interface CardSectionProps {
  booksData: Book[];
  onView: (id: string) => void;
}
interface BookCardProps {
  book: Book;
  onView: () => void;
}
const CardSection: React.FC<CardSectionProps> = ({ booksData, onView }) => {
  return (
    <Row gutter={[16, 16]}>
      {booksData?.map((book) => (
        <BookCard key={book.id} book={book} onView={() => onView(book.id)} />
      ))}
    </Row>
  );
};

const BookCard: React.FC<BookCardProps> = ({ book, onView }) => {
  return (
    <Col key={book.id} xs={24} sm={12} md={6}>
      <S.BookCard>
        <S.ImageWrapper>
          <img src={book.coverImage} alt={book.title} />
        </S.ImageWrapper>
        <S.BookTitle>{book.title}</S.BookTitle>
        <Button text="View" style={{ backgroundColor: '#4367E9', color: '#fff' }} onClick={() => onView()} block />
      </S.BookCard>
    </Col>
  );
};
export default BookAdmin;
