import { useMutation, useQuery } from '@apollo/client';
import { message, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { gqlSchema } from '../../../gql/schema';
import Button from '../../../shared/Button';
import * as S from './styles';

interface IDownloadSubmissionsModal {
  visible?: boolean;
  setVisible: (v: boolean) => void;
  investigationId?: string;
  classId?: string;
}

const DownloadSubmissionsModal = (props: IDownloadSubmissionsModal) => {
  const { setVisible, visible, investigationId, classId } = props;
  const [isPolling, setIsPolling] = useState(false);

  const handleCloseModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const { data: initialCacheQuery, loading: loadingInitialCacheQuery, startPolling, stopPolling } = useQuery(
    gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationSubmissionDownloadCache,
    {
      variables: { classInvestigationId: investigationId, classId: classId },
    },
  );
  const initialCacheStatus = initialCacheQuery?.getInvestigationSubmissionDownloadCache;

  const [downloadSubmissions] = useMutation(
    gqlSchema.InvestigationSchema.mutations.GRADING.downloadInvestigationSubmissions,
    {
      variables: { classInvestigationId: investigationId, classId: classId },
      onError: (err) => {
        message.error(err.message);
        setIsPolling(false);
        stopPolling();
      },
    },
  );

  const cacheUrl = initialCacheStatus?.fileUrl;

  useEffect(() => {
    if (isPolling && cacheUrl) {
      setIsPolling(false);
      stopPolling();
      // Downloads the file automatically
      window.location.href = cacheUrl;
    }
  }, [cacheUrl, isPolling, stopPolling]);

  const handleCacheDownload = useCallback(() => {
    if (!cacheUrl) {
      startPolling(5000);
      downloadSubmissions();
      setIsPolling(true);
    }
  }, [cacheUrl, downloadSubmissions, startPolling]);

  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null} width={500}>
      <S.ModalTitle>Download Investigation Data</S.ModalTitle>
      <S.ModalText>Click below to download all your students submissions</S.ModalText>
      {isPolling && <S.ModalText>We are zipping all submissions, this might take a few seconds</S.ModalText>}
      <Button
        text={'Download Student Submissions'}
        loading={loadingInitialCacheQuery || isPolling}
        href={cacheUrl}
        onClick={handleCacheDownload}
        block
      />
    </Modal>
  );
};

export default DownloadSubmissionsModal;
