import { message } from 'antd';
import React, { useCallback } from 'react';
import { Document, Page } from 'react-pdf';
import * as S from './styles';

interface IFileDisplay {
  file: File | string;
  mimeType: string;
  onLoadSuccess?: (pdf: any) => void;
  onLoadError?: () => void;
  containerRef?: React.RefObject<HTMLDivElement> | ((instance: HTMLDivElement | null) => void) | null;
  videoRef?: React.RefObject<HTMLVideoElement> | ((instance: HTMLVideoElement | null) => void) | null;
  page: number;
}

const InvestigationFileDisplay: React.FC<IFileDisplay> = (props) => {
  const { mimeType, file, onLoadSuccess, onLoadError, containerRef, page, videoRef } = props;
  const fileUrl = typeof file === 'string' ? file : URL.createObjectURL(file);

  const buildFile = useCallback(() => {
    if (mimeType?.includes('image')) {
      return <img onLoad={onLoadSuccess} src={fileUrl} alt="Peer review submmited by student" />;
    } else if (mimeType?.includes('pdf')) {
      return (
        <Document
          file={file}
          onLoadSuccess={onLoadSuccess}
          onLoadError={() => {
            message.error('Error loading PDF, try again later');
            onLoadError?.();
          }}
        >
          <Page pageNumber={page} />
        </Document>
      );
    } else {
      return <video src={fileUrl} onLoadStart={onLoadSuccess} controls ref={videoRef} />;
    }
  }, [file, fileUrl, mimeType, onLoadSuccess, page, videoRef, onLoadError]);

  return (
    <S.FilePlaceholder ref={containerRef}>
      {props.children}
      {buildFile()}
    </S.FilePlaceholder>
  );
};

export default InvestigationFileDisplay;
