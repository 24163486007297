import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../types';
import * as S from '../styles';

interface IDraggableComponent {
  value?: string;
  hasTarget?: boolean;
  index: number;
  isImageVersion?: boolean;
}

const DraggableComponent = ({ value, hasTarget, index, isImageVersion = false }: IDraggableComponent) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    item: { value, index, isAnswer: false, id: index },
    options: {
      dropEffect: 'copy',
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <S.ContainerDragObject ref={drag} beingDragged={isDragging} $hasTarget={!!hasTarget} isImage={isImageVersion}>
      {isImageVersion ? (
        <img key={`image-drag-${index}`} src={value} alt={`Dragable Option ${index + 1}`} />
      ) : (
        <span role="none" >{value}</span>
      )}
    </S.ContainerDragObject>
  );
};

export default DraggableComponent;
