import { Col, Row, message } from 'antd';
import React from 'react';
import { FiMinusCircle, FiPlus } from 'react-icons/fi';

import { IOnChageEditor } from '../../../../../types';
import Editor from '../../../../../shared/Editor';
import Input from '../../../../../shared/Input';
import Spacer from '../../../../../shared/Spacer';
import {
  GQL_InvestigationBlock,
  GQL_InvestigationCatalogActivityEntry,
  ICategoryDragContentValue,
} from '../../../../../types/investigation';
import * as S from './styles';
import UploadInput from '../../../../../shared/UploadInput';
import { RcFile } from 'antd/lib/upload';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../../../gql/schema';

interface ICategoryDragBlockEditor {
  editingBlock: GQL_InvestigationBlock;
  setActivities: (activities: GQL_InvestigationCatalogActivityEntry[]) => void;
  activities: GQL_InvestigationCatalogActivityEntry[];
  selectedActivityId: string;
  selectedContentIndex: number;
  selectedBlockIndex: number;
  editingRemote?: boolean;
  isImageVersion?: boolean;
}

const CategoryDragBlockEditor = (props: ICategoryDragBlockEditor) => {
  const {
    activities,
    setActivities,
    selectedActivityId,
    selectedBlockIndex,
    selectedContentIndex,
    editingBlock,
    editingRemote,
    isImageVersion = false,
  } = props;

  const categoryDragValue = editingBlock.values[0] as ICategoryDragContentValue;

  const handleEditCategoryDrag = (payload: { title?: string; count?: number; index?: number }) => {
    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        const newContent = (editingRemote ? activity.content : activity.contentInPerson).map((content, i) => {
          if (i !== selectedContentIndex || !content?.blocks) return content;

          return {
            title: content.title,
            blocks: content.blocks.map((block, index) => {
              if (index !== selectedBlockIndex) return block;
              const targetCount = categoryDragValue?.targets?.length ?? 0;

              if ((payload.count ?? targetCount) > targetCount) {
                return {
                  ...block,
                  values: [
                    {
                      ...block.values[0],
                      targets: [...(categoryDragValue.targets ?? []), { title: '', values: [] }],
                    },
                  ],
                };
              } else if ((payload.count ?? targetCount) < targetCount) {
                return {
                  ...block,
                  values: [
                    {
                      ...block.values[0],
                      targets: categoryDragValue.targets?.slice(0, -1) ?? [],
                    },
                  ],
                };
              }

              const newSBValue = {
                targets: categoryDragValue.targets?.map((target, index) => {
                  if (index === payload.index) {
                    return {
                      ...target,
                      title: payload.title,
                    };
                  } else {
                    return target;
                  }
                }),
              };

              return {
                ...block,
                values: [{ ...block.values[0], ...newSBValue }],
              };
            }),
          };
        });

        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditQuestion = (data: IOnChageEditor) => {
    const selectedActivity = activities.find((activity) => activity.id === selectedActivityId);
    if (!selectedActivity) return;

    const newContent = (editingRemote ? selectedActivity.content : selectedActivity.contentInPerson).map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          title: content.title,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;

            return {
              ...block,
              values: block.values.map((value) => {
                return {
                  ...value,
                  questionTitle: data.value,
                };
              }),
            };
          }),
        };
      },
    );

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleAddRemoveValueToCategoryDrag = (payload: { index: number; removeIndex?: number }) => {
    const selectedActivity = activities.find((activity) => activity.id === selectedActivityId);
    if (!selectedActivity) return;

    const newContent = (editingRemote ? selectedActivity.content : selectedActivity.contentInPerson).map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          title: content.title,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;

            return {
              ...block,
              values: [
                {
                  ...block.values[0],
                  targets: categoryDragValue?.targets?.map((t, i) => {
                    if (i === payload.index) {
                      const values = t.values || [];
                      return {
                        ...t,
                        values:
                          typeof payload.removeIndex === 'number'
                            ? [...values?.slice(0, payload.removeIndex), ...values?.slice(payload.removeIndex + 1)]
                            : [...values, ''],
                      };
                    } else {
                      return t;
                    }
                  }),
                },
              ],
            };
          }),
        };
      },
    );

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const handleEditValueToDragingBoard = (payload: { targetIndex: number; valueIndex: number; title: string }) => {
    const selectedActivity = activities.find((activity) => activity.id === selectedActivityId);
    if (!selectedActivity) return;

    const newContent = (editingRemote ? selectedActivity.content : selectedActivity.contentInPerson).map(
      (content, i) => {
        if (i !== selectedContentIndex || !content?.blocks) return content;

        return {
          title: content.title,
          blocks: content.blocks.map((block, index) => {
            if (index !== selectedBlockIndex) return block;

            return {
              ...block,
              values: [
                {
                  ...block.values[0],
                  targets: categoryDragValue?.targets?.map((t, i) => {
                    if (i === payload.targetIndex) {
                      const values = t.values || [];
                      return {
                        ...t,
                        values: values.map((v, i) => {
                          if (i === payload.valueIndex) {
                            return payload.title;
                          } else {
                            return v;
                          }
                        }),
                      };
                    } else {
                      return t;
                    }
                  }),
                },
              ],
            };
          }),
        };
      },
    );

    setActivities(
      activities.map((activity) => {
        if (activity.id !== selectedActivityId) return activity;
        return editingRemote ? { ...activity, content: newContent } : { ...activity, contentInPerson: newContent };
      }),
    );
  };

  const [uploadFile, { loading }] = useMutation<{ uploadActivityFile: { url: string } }>(
    gqlSchema.InvestigationSchema.mutations.DRAFT.uploadActivityFile,
    {
      onError: (err) => {
        message.error('There was an error trying to upload your file, please try again later');
      },
      onCompleted: (data) => {
        return data?.uploadActivityFile;
      },
    },
  );

  const beforeUploadCanvasImages = async (file: RcFile, indexPosition: number, targetIndex: number) => {
    if (!file.type.includes('image')) return false;
    try {
      const result = (await uploadFile({ variables: { indexPosition, activityFile: file } })) as any;
      const { url } = result.data?.UploadActivityFile;
      handleEditValueToDragingBoard({ title: url, valueIndex: indexPosition, targetIndex: targetIndex });

      return true;
    } catch (error) {
      return false;
    }
  };
  return (
    <>
      <h2>Question</h2>
      <Editor
        fontSize={11}
        value={categoryDragValue.questionTitle}
        placeholder="Start writing the question content..."
        onChange={(data) => handleEditQuestion(data)}
        showAlign
        showLink
      />
      <Spacer />
      <h2>Target Amount</h2>
      <Input
        value={categoryDragValue.targets?.length ?? 0}
        backgroundColor="white"
        max={4}
        min={2}
        type="number"
        onChange={(e) => handleEditCategoryDrag({ count: Number(e.target.value) })}
      />
      <Spacer size={24} />
      {Array(categoryDragValue.targets?.length ?? 0)
        .fill(1)
        .map((_, index, arr) => (
          <React.Fragment key={`fragment-${index}`}>
            <h2>Target {index + 1} Title</h2>
            <Input
              value={categoryDragValue.targets && categoryDragValue.targets[index].title}
              onChange={(e) => handleEditCategoryDrag({ title: e.target.value, index })}
              backgroundColor="white"
              placeholder="Add target title"
            />
            <Spacer />
            <Row
              justify="space-between"
              align="middle"
              onClick={() => handleAddRemoveValueToCategoryDrag({ index })}
              style={{ cursor: 'pointer' }}
            >
              <h2>Target {index + 1} Values</h2>
              <FiPlus size={18} color="#3EBC89" />
            </Row>
            <Row gutter={[8, 8]} align="middle" justify="center">
              {categoryDragValue.targets &&
                categoryDragValue.targets[index]?.values?.map((value, i) => (
                  <React.Fragment
                    key={`${i}${categoryDragValue.targets && categoryDragValue.targets[index]?.values?.length}`}
                  >
                    <Col span={22}>
                      {!isImageVersion && (
                        <Input
                          value={value}
                          onChange={(e) =>
                            handleEditValueToDragingBoard({ title: e.target.value, valueIndex: i, targetIndex: index })
                          }
                          backgroundColor="white"
                          placeholder="Add value"
                          key={`category-drag-input-${i}-${index}`}
                        />
                      )}
                      {isImageVersion && (
                        <S.CustomImageHolder>
                          {value && (
                            <img
                              key={`image-drag-${index}`}
                              src={value}
                              alt={`Dragable Option ${index + 1}`}
                              style={{ marginBottom: '10px', height: '100%', width: '150px', objectFit: 'contain' }}
                            />
                          )}
                          <UploadInput
                            title={value || 'Upload Images from Computer'}
                            accept="image/*"
                            beforeUpload={(file) => {
                              beforeUploadCanvasImages(file, i, index);
                            }}
                            defaultFileList={[]}
                            fileList={[]}
                            disabled={loading}
                            key={`upload-category-drag-image-${i}-${index}`}
                          />

                          {value && <S.HorizontalDivider />}
                        </S.CustomImageHolder>
                      )}
                    </Col>
                    <Col span={2}>
                      <FiMinusCircle
                        onClick={() => handleAddRemoveValueToCategoryDrag({ index, removeIndex: i })}
                        style={{ cursor: 'pointer', verticalAlign: 'sub' }}
                        size={18}
                      />
                    </Col>
                  </React.Fragment>
                ))}
            </Row>
            {index !== arr.length - 1 && <S.HorizontalDivider />}
          </React.Fragment>
        ))}
    </>
  );
};

export default CategoryDragBlockEditor;
