import { Row, Col, Divider } from 'antd';
import styled from 'styled-components';
import Button from '../../shared/Button';
import Input from '../../shared/Input';

export const Container = styled(Row)`
  marging: 4em;
`;

export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 5px;
  padding-top: 20px;
  min-height: 60vh;
`;

export const Line = styled(Col)`
  .ant-form-item {
    margin: 0;
  }
`;

export const CDivider = styled(Divider)`
  margin: 5px 0 10px 0;
`;

export const TitleInput = styled.p`
  font-weight: 600;
  padding: 0 11px;
  font-size: 1.2em;
  margin: 0;
`;

export const SInput = styled(Input)`
  font-size: 0.95em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  margin-top: 10px;
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 3px 2px;
`;

export const EditButton = styled(Button)`
  display: inline;
  line-height: 0;
  svg {
    font-size: 20px;
  }
`;

export const Status = styled.span<{
  $active: boolean;
}>`
  font-size: 0.95em;
  padding: 0 11px;
  font-weight: 800;
  color: ${(props) => (props.$active ? '#50c364' : '#EA3535')};
`;
