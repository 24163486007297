import { useQuery } from '@apollo/client';
import React, { useCallback } from 'react';
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import * as S from './styles';
import { GQL_NotificationResponse } from '../../types/notification';
import Item from '../../shared/NotificationItem';
import { List, message, Skeleton } from 'antd';

const NotificationList = () => {
  const { data, loading } = useQuery<{ getNotifications: GQL_NotificationResponse[] }>(
    gqlSchema.NotificationSchema.queries.NOTIFICATIONS.getNotifications,
    {
      onError: (err) => {
        message.error('There was an error loading your notifications: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const renderItems = useCallback(() => {
    return [...(data?.getNotifications || [])]
      ?.sort((a, b) => (b.priority || 0) - (a.priority || 0))
      .map((notification, index) => (
        <Item
          key={notification.id}
          notification={notification}
          width="100%"
          fontSize="1.15em"
          showDetail={true}
          lastItem={index === (data?.getNotifications?.length || 0) - 1}
        />
      ));
  }, [data]);

  return (
    <>
      <PageWithTitle title="Your Notifications">
        <S.Container $length={data?.getNotifications.length || 0}>
          {loading ? (
            <Skeleton loading={loading} active>
              <List.Item.Meta />
            </Skeleton>
          ) : data && data?.getNotifications.length > 0 ? (
            renderItems()
          ) : (
            <S.Message>There are no notifications.</S.Message>
          )}
        </S.Container>
      </PageWithTitle>
    </>
  );
};

export default NotificationList;
