import styled from 'styled-components';

export const PTitle = styled.h1`
  font-size: 2em;
  color: #333;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 1.5em;
  }
`;

export const PSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  background-color: #f9f9f9;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  margin-right: 12rem;
  margin-left: 12rem;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    margin-right: 2rem;
    margin-left: 2rem;
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 4em;

  @media (max-width: 1024px) {
    gap: 2em;
  }
`;

export const Tab = styled.div<{ active?: boolean }>`
  cursor: pointer;
  margin-top: 20px;
  color: ${({ active }) => (active ? '#007BFF' : 'rgba(58, 58, 60, 0.8)')};
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ active }) => (active ? '#007BFF' : 'transparent')};
  }

  @media (max-width: 1024px) {
    font-size: 13px;
  }
`;

export const Card = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

export const ImageContainer = styled.div`
  min-width: 600px;
  display: flex;
  padding: 30px;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;

  @media (max-width: 1024px) {
    min-width: 400px;
    padding: 15px;
    justify-content: center;
  }
`;

export const PImage = styled.img`
  max-width: 100%;
  object-fit: full;
  aspect-ratio: 16/9;
  height: 20rem;
  border-radius: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);

  @media (max-width: 1024px) {
    height: 15rem;
  }
`;

export const HeaderContent = styled.div`
  text-align: left;
  padding: 3rem;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;

  span {
    font-size: 2em;
    margin-bottom: 10px;
    font-weight: 500;
  }

  @media (max-width: 1024px) {
    padding: 1.5rem;
    max-width: 100%;

    span {
      font-size: 1.5em;
    }
  }
`;

export const Title = styled.h2`
  font-size: 1em;
  color: #007bff;

  @media (max-width: 1024px) {
    font-size: 0.9em;
  }
`;

export const Subtitle = styled.h3`
  font-size: 1.2em;
  color: #555;
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    font-size: 1em;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 1024px) {
    padding: 8px 16px;
    font-size: 0.9em;
  }
`;