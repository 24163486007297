import { Col } from 'antd';
import styled from 'styled-components';
import Button from '../../shared/Button';
import InvestigationContent from '../../shared/InvestigationContent';

export const SubStepText = styled.div`
  color: #767676;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  cursor: not-allowed;
  margin-left: 15px;
`;

export const Dimmer = styled.div<{ visible: boolean }>`
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;

  background: ${(props) => (props.visible ? '#00000066' : '#00000000')};
`;

export const PreviewContent = styled(InvestigationContent)`
  position: relative;
  min-height: 100%;
`;

export const PopoverChild = styled.span`
  height: 10px;
  width: 10px;
  position: absolute;
  top: 200px;
  left: 20px;
`;

export const PopoverContentContainer = styled.div`
  max-width: 280px;
  text-align: center;

  & > div {
    display: flex;
    justify-content: space-around;
  }

  button {
    margin-top: 24px;
  }

  .ant-checkbox-wrapper {
    margin-top: 16px;
    font-size: 13px;
  }
`;

export const ProceedPreviewButton = styled(Button)<{ $highlighted: boolean; $visible: boolean }>`
  ${(props) => props.$highlighted && 'filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.45));'}
  ${(props) => !props.$visible && 'display: none;'}
`;

export const PreviewContentCol = styled(Col)`
  text-align: center;
  .ant-skeleton-image {
    width: 100%;
    height: 400px;
    margin-top: 16px;
    margin-bottom: 48px;
    border-radius: 6px;

    svg {
      height: 128px;
      width: 128px;
    }
  }
`;

export const RoundButtonPlaceholder = styled.div<{ $left?: boolean }>`
  height: 40px;
  width: 40px;
  padding-${(props) => (props.$left ? 'left' : 'right')}: 2px;
  margin-${(props) => (props.$left ? 'left' : 'right')}: 25px;
  background: transparent;

  @media (max-width: 768px) {
    display:none;
  }
`;

export const RoundButton = styled(Button)<{ $left?: boolean }>`
  height: 40px;
  width: 40px;
  padding-${(props) => (props.$left ? 'left' : 'right')}: 2px;
  margin-${(props) => (props.$left ? 'left' : 'right')}: 25px;

  @media (max-width: 768px) {
    display:none;
  }
`;
