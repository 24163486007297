import React, { useEffect, useState } from 'react';
import { Checkbox, Dropdown, Menu } from 'antd';
import Button from '../../../../shared/Button';
import { themeConfig } from '../../../../utils/theme';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ASSESSMENT_OPTIONS_TIMES } from '../../../../types/assessments';

type SelectallColHeaderProps = {
  disabled?: boolean;
  selectedAll: boolean;
  handleSelectedAll: (event: CheckboxChangeEvent) => void;
  triggerEditElements: (selection: any) => void;
};

export const SelectallColHeader: React.FC<SelectallColHeaderProps> = (props) => {
  const { disabled, selectedAll, handleSelectedAll, triggerEditElements } = props;
  const [open, setOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const editOptions = new Map([
    [ASSESSMENT_OPTIONS_TIMES.OPEN_DATE, true],
    [ASSESSMENT_OPTIONS_TIMES.CLOSE_DATE, false],
    [ASSESSMENT_OPTIONS_TIMES.OPEN_TIME, false],
    [ASSESSMENT_OPTIONS_TIMES.CLOSE_TIME, false],
  ]);
  const [selectedOptions, setSelectedOptions]: any = useState(editOptions);

  const allAreFalsy = (arr: boolean[]) => {
    return arr.every((element: boolean) => element === false);
  };

  useEffect(() => {
    const allDisabled = allAreFalsy([...selectedOptions.values()]);
    setSubmitDisabled(allDisabled);

    if (allDisabled) {
      triggerEditElements(null);
    }
  }, [selectedOptions, setSubmitDisabled, triggerEditElements]);

  const handleEditOptionsChange = (e: CheckboxChangeEvent, type: string) => {
    const checked = e.target.checked;

    const newRef = new Map(selectedOptions.set(type, checked));
    setSelectedOptions(newRef);
  };

  const handleMenuClick = (e: any) => {
    if (e.key === '5') {
      setOpen(false);
    }
  };

  const handleOpenChange = (flag: any) => {
    setOpen(flag);
  };

  const handleEditSelection = () => {
    const selection: any = {};
    selectedOptions.forEach(function (value: boolean, key: string) {
      if (value) {
        selection[key] = value;
      }
    });

    triggerEditElements(selection);
  };

  const OverlayMenu = () => {
    return (
      <Menu mode="vertical">
        <Menu.Item key="1" onClick={handleMenuClick}>
          <Checkbox
            key={'select-all-edit-open-date'}
            checked={selectedOptions.get(ASSESSMENT_OPTIONS_TIMES.OPEN_DATE)}
            disabled={disabled}
            onChange={(e) => handleEditOptionsChange(e, ASSESSMENT_OPTIONS_TIMES.OPEN_DATE)}
          >
            Edit Open Date
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="2" onClick={handleMenuClick}>
          <Checkbox
            key={'select-all-edit-close-date'}
            checked={selectedOptions.get(ASSESSMENT_OPTIONS_TIMES.CLOSE_DATE)}
            disabled={disabled}
            onChange={(e) => handleEditOptionsChange(e, ASSESSMENT_OPTIONS_TIMES.CLOSE_DATE)}
          >
            Edit Close Date
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="3" onClick={handleMenuClick}>
          <Checkbox
            key={'select-all-edit-open-time'}
            checked={selectedOptions.get(ASSESSMENT_OPTIONS_TIMES.OPEN_TIME)}
            disabled={disabled}
            onChange={(e) => handleEditOptionsChange(e, ASSESSMENT_OPTIONS_TIMES.OPEN_TIME)}
          >
            Edit Open Time
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="4" onClick={handleMenuClick}>
          <Checkbox
            key={'select-all-edit-close-time'}
            checked={selectedOptions.get(ASSESSMENT_OPTIONS_TIMES.CLOSE_TIME)}
            disabled={disabled}
            onChange={(e) => handleEditOptionsChange(e, ASSESSMENT_OPTIONS_TIMES.CLOSE_TIME)}
          >
            Edit Close Time
          </Checkbox>
        </Menu.Item>

        <Menu.Item key="5" onClick={handleMenuClick}>
          <Button
            text={'Edit Selection'}
            theme={themeConfig.secondaryColor}
            onClick={handleEditSelection}
            disabled={disabled || submitDisabled}
          />
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div style={{ width: '100%', whiteSpace: 'pre' }}>
      <Dropdown overlay={OverlayMenu} arrow placement="bottomCenter" onVisibleChange={handleOpenChange} visible={open}>
        <Checkbox
          key={'select-all-edit-students'}
          checked={selectedAll}
          disabled={disabled}
          onChange={handleSelectedAll}
        >
          Select All
        </Checkbox>
      </Dropdown>
    </div>
  );
};
