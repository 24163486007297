import React, { useCallback } from 'react';
import * as S from './styles';
import {  ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import adiLearningHubSrc from '../../../assets/adi-learning-hub.svg';
import { MdEditDocument, MdFavoriteBorder } from 'react-icons/md';
import { CgMicrosoft } from 'react-icons/cg';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { RiAdminFill } from 'react-icons/ri';


const BookHeader = () => {

  const { signOut,isAdiSuperAdmin } = useAuth();  
  const history = useHistory();
  const handleLogout = useCallback(() => {
    signOut();
    history.push('/bookLogin')
  }, [signOut]);
  return (
    <S.HeaderContainer>
      <S.Logo><img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" /></S.Logo>
      <S.NavMenu>
        <Link to="/booklib" className="dashboard-link"> <CgMicrosoft size={30} style={{ marginRight: '8px', verticalAlign: 'middle' }} />Dashboard</Link>
        {/* <BellOutlined className="icon" /> */}
        {/* <ShoppingCartOutlined className="icon" /> */}
        <S.AccountDropdown>
          <UserOutlined className="icon" />
          My Account
          <S.DropdownContent className="dropdown-content">
            <Link to="/bookprofile">
              <UserOutlined className="icon" />
              My Profile
            </Link>
            {isAdiSuperAdmin &&<Link to="/bookadmin">
              <RiAdminFill className="icon"/>
              Admin
            </Link>}
            
            <Link to="/bookorderlist">
              <ShoppingCartOutlined className="icon" />
              My Orders
            </Link>
            <Link to="/bookquoteList">
              <MdEditDocument size={20} style={{ marginRight: '8px' }} />
              My Quote
            </Link>
            <Link to="/bookWishList">

              <MdFavoriteBorder size={20} style={{ marginRight: '8px' }} />
              My Favourite
            </Link>
            <button className="logout-button" onClick={handleLogout}>
              <UserOutlined className="icon" />
              Logout
            </button>

          </S.DropdownContent>
        </S.AccountDropdown>
      </S.NavMenu>
    </S.HeaderContainer>
  );
};

export default BookHeader;