export const pageSizeBase = 10;

export interface GQL_PaginationResponse {
  page: number;
  size: number;
  totalCount: number;
}
export interface GQL_PaginationInput {
  page: number;
  size: number;
}
