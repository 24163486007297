import { SelectValue } from 'antd/lib/select';
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import * as S from './styles';

interface ISelect {
  placeholder?: string;
  showSearch?: boolean;
  defaultValue?: string | number | React.ReactText[];
  value?: string | number | React.ReactText[];
  loading?: boolean;
  dropdownMatchSelectWidth?: boolean;
  menuItemSelectedIcon?: React.ReactNode;
  onChange?: (value: SelectValue) => void;
  onSelect?: (value: SelectValue) => void;
  onDropdownVisibleChange?: (value: boolean) => void;
  onSearch?: (value: SelectValue) => void;
  onClear?: () => void;
  showArrow?: boolean;
  bordered?: boolean;
  allowClear?: boolean;
  labelInValue?: boolean;
  suffixIcon?: React.ReactNode;
  notFoundContent?: React.ReactNode;
  mode?: 'multiple' | 'tags';
  style?: React.CSSProperties;
  suffixItemOnLeft?: boolean;
  disabled?: boolean;
  optionLabelProp?: string;
  height?: number;
  backgroundColor?: string;
  maxTagTextLength?: number;
  dropdownRender?: (menu: any) => React.ReactElement;
  maxTagCount?: number;
  filterOption?:
    | boolean
    | ((
        inputValue: string,
        option?: {
          children?: React.ReactNode;
          value?: React.ReactText;
          key?: string | number;
          name?: string | number;
        },
      ) => boolean);
}

const Select: React.FC<ISelect> = (props) => {
  const {
    placeholder,
    showSearch,
    defaultValue,
    loading,
    height,
    dropdownMatchSelectWidth,
    value,
    allowClear,
    filterOption,
    labelInValue,
    suffixIcon,
    onChange,
    onSearch,
    onSelect,
    onDropdownVisibleChange,
    onClear,
    dropdownRender,
    suffixItemOnLeft,
    mode,
    menuItemSelectedIcon,
    notFoundContent,
    style,
    disabled,
    showArrow,
    bordered,
    optionLabelProp,
    maxTagTextLength,
    maxTagCount,
    backgroundColor,
  } = props;

  return (
    <S.AntdSelect
      mode={mode}
      labelInValue={labelInValue}
      allowClear={allowClear}
      suffixIcon={suffixIcon || suffixIcon === null ? suffixIcon : <FiChevronDown />}
      placeholder={placeholder}
      size="large"
      style={style}
      maxTagCount={maxTagCount}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      value={value}
      dropdownRender={dropdownRender}
      onSearch={onSearch}
      onChange={onChange}
      onSelect={onSelect}
      onDropdownVisibleChange={onDropdownVisibleChange}
      showArrow={showArrow}
      onClear={onClear}
      showSearch={showSearch}
      bordered={bordered}
      defaultValue={defaultValue}
      filterOption={filterOption}
      menuItemSelectedIcon={menuItemSelectedIcon}
      notFoundContent={notFoundContent}
      loading={loading}
      disabled={disabled}
      $suffixItemOnLeft={suffixItemOnLeft}
      $height={height}
      $backgroundColor={backgroundColor}
      optionLabelProp={optionLabelProp}
      maxTagTextLength={maxTagTextLength}
      {...props}
    >
      {props.children}
    </S.AntdSelect>
  );
};

export default Select;
