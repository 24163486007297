import { Divider, Modal } from 'antd';
import styled from 'styled-components';

export const CModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 5px;
    height: 640px;
    overflow-y: hidden;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  margin-top: 24px;
`;

export const TitleTab = styled.p`
  font-weight: 700;
  padding: 0;
  font-size: 1.1em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2em;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  cursor: pointer;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 0.9em;
  margin: 0;
  text-align: center;
`;

export const Icon = styled.p`
  font-size: 2.5em;
  margin-bottom: 0;
  text-align: center;
  color: ${(props) => props.theme.success.color};
`;

export const CDivider = styled(Divider)`
  margin: 0 !important;
`;
