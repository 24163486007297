import styled from 'styled-components';

export const TitleInput = styled.p`
  font-weight: 600;
  padding: 0;
  font-size: 0.95em;
  margin: 0 0 8px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;
