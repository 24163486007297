import { Progress } from 'antd';
import React, { useCallback } from 'react';
import Spacer from '../../../shared/Spacer';
import * as S from './styles';
import { GQL_AssessmentsScoresResponse } from '../../../types/gradeBooking';
import { studentPerformanceLevels } from '../../../types/studentPerformanceLevels';

interface AverageAssessmentPercentInterface {
  data: GQL_AssessmentsScoresResponse[];
}

const AverageAssessmentPercent = (props: AverageAssessmentPercentInterface) => {
  const { data } = props;

  const { totalPercent, numberOfStudents } = data.reduce(
    (acc, curr) => {
      return {
        totalPercent: acc.totalPercent + curr.assessmentPercent * curr.gradedStudents,
        numberOfStudents: acc.numberOfStudents + curr.gradedStudents,
      };
    },
    { totalPercent: 0, numberOfStudents: 0 },
  );
  const averagePercent = totalPercent / (numberOfStudents ?? 1);
  const formatProgressText = useCallback((percent?: number) => {
    return Math.round(percent ?? 0) + '%';
  }, []);
  const strokeColorIndex = averagePercent >= 65 ? (averagePercent > 99 ? 3 : 2) : 1;
  const strokeColor = data.length > 0 ? Object.values(studentPerformanceLevels)[strokeColorIndex].color : undefined;

  return (
    <S.GaugeContainer>
      <h1>Average Assessment Percent</h1>
      <h2>(Across selected assessments)</h2>
      <Spacer />
      <Progress
        type="dashboard"
        percent={averagePercent}
        width={180}
        format={formatProgressText}
        gapDegree={110}
        strokeWidth={12}
        strokeColor={strokeColor}
        strokeLinecap="butt"
        status="normal"
      />
    </S.GaugeContainer>
  );
};

export default AverageAssessmentPercent;
