import { Col, message, Popconfirm, Row, Switch } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { FiArrowRight, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Button from '../../../shared/Button';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import Sider from '../../../shared/Sider';
import Spacer from '../../../shared/Spacer';
import { GQL_InvestigationDisplay } from '../../../types/investigation';
import { themeConfig } from '../../../utils/theme';
import moment from 'moment';
import * as S from './styles';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import RangePicker from '../../../shared/RangePicker';
import { formatDateTime, toDateFormat } from '../../../utils/date';

interface IInvestigationSettingsSider {
  investigation?: GQL_InvestigationDisplay;
  currentStepIndex?: number;
  currentActivityIndex?: number;
  classId?: string;
  onFinishSettings?: () => void;
  setCurrentStepByIndex?: (index: number) => void;
  setCurrentActivityByIndex?: (index: number) => void;
  firstSetting?: boolean;
  backUrl?: string;
}

const InvestigationSettingsSider = (props: IInvestigationSettingsSider) => {
  const {
    investigation,
    currentStepIndex,
    currentActivityIndex,
    setCurrentStepByIndex,
    setCurrentActivityByIndex,
    classId,
    firstSetting,
    onFinishSettings,
    backUrl,
  } = props;

  const [submitDueDate] = useMutation<
    { assignStepDueDate: { id: string; startDate: number; dueDate: number } },
    {
      classId?: string;
      stepId: string;
      startDate: number;
      dueDate: number;
    }
  >(gqlSchema.InvestigationSchema.mutations.SETTINGS.assignStepDueDate, {
    update(cache, { data }) {
      const investigationStepTeacherEntry = {
        __typename: 'InvestigationStepTeacherEntry',
        id: investigation?.steps[currentStepIndex || 0].id,
      };
      cache.modify({
        id: cache.identify(investigationStepTeacherEntry),
        fields: {
          dueDate() {
            return data?.assignStepDueDate.dueDate;
          },
          startDate() {
            return data?.assignStepDueDate.startDate;
          },
        },
      });
    },
    onError: (err) => {
      message.error('There was an error updating due date: ' + err.message || 'Unexpected Error');
    },
  });

  const [duration, setDuration] = useState<{
    startDate: string;
    endDate: string;
  }>();

  const [submitStepMode, { loading: loadingStepMode }] = useMutation<
    { assignStepDueDate: { id: string } },
    {
      classId?: string;
      stepId: string;
      mode: string;
    }
  >(gqlSchema.InvestigationSchema.mutations.SETTINGS.assignStepMode, {
    onError: (err) => {
      message.error('There was an error updating stage state: ' + err.message || 'Unexpected Error');
    },
    refetchQueries: ['getInvestigationById'],
  });

  const [submitAllStepsMode, { loading: loadingAllStepModes }] = useMutation<
    { assignStepModesToAllSteps: boolean },
    {
      investigationId: string;
      classId: string;
      mode: string;
    }
  >(gqlSchema.InvestigationSchema.mutations.SETTINGS.assignStepModeToAllSteps, {
    onCompleted: () => {
      message.success('States updated successfully.');
    },
    onError: (err) => {
      message.error('There was an error while updating all states. Please try again.');
    },
    refetchQueries: ['GetInvestigationByIdForTeacher'],
  });

  const [submitPreRequisite, { loading: loadingPreRequisite }] = useMutation<
    { assignPreRequisite: { id: string } },
    {
      classId?: string;
      stepId: string;
      requiresPreviousStep: boolean;
    }
  >(gqlSchema.InvestigationSchema.mutations.SETTINGS.assignPreRequisite, {
    onError: (err) => {
      message.error('There was an error updating stage pre-requisite: ' + err.message || 'Unexpected Error');
    },
  });

  const currentStep = investigation?.steps[currentStepIndex || 0];
  const canChangeMode = !!currentStep?.modesAvailable?.some((mode) => mode === 'ALL');

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [prerequisite, setPrerequisite] = useState(false);
  const [stepState, setStepState] = useState((currentStep?.mode as string) || 'INPERSON');
  const lastSetting = (currentStepIndex ?? 0) + 1 === investigation?.steps?.length;

  useEffect(() => {
    if (investigation && currentStepIndex !== undefined) {
      setDuration({
        startDate: formatDateTime(investigation.steps[currentStepIndex || 0]?.startDate || investigation.startDate),
        endDate: formatDateTime(investigation.steps[currentStepIndex || 0]?.dueDate || investigation.dueDate),
      });
      setStepState(investigation.steps[currentStepIndex]?.mode || 'INPERSON');
      setPrerequisite(!!investigation.steps[currentStepIndex]?.requiresPreviousStep);
    }
  }, [currentStepIndex, investigation]);

  const updateDueDate = () => {
    const currentStepId = investigation?.steps[currentStepIndex ?? 0]?.id;
    if (currentStepId && duration) {
      submitDueDate({
        variables: {
          classId: classId ? classId : undefined,
          startDate: new Date(toDateFormat(duration?.startDate)).getTime(),
          dueDate: new Date(toDateFormat(duration?.endDate)).getTime(),
          stepId: currentStepId,
        },
      });
    }
  };

  const updatePreRequisite = (requiresPreviousStep: boolean) => {
    setPrerequisite(requiresPreviousStep);
    const currentStepId = investigation?.steps[currentStepIndex ?? 0]?.id;

    if (currentStepId) {
      submitPreRequisite({
        variables: {
          classId: classId ? classId : undefined,
          stepId: currentStepId,
          requiresPreviousStep,
        },
      });
    }
  };

  const handleStepStateChange = (state: string) => {
    setDropdownOpen(false);
    setStepState(state);
    const currentStepId = investigation?.steps[currentStepIndex ?? 0]?.id;

    if (currentStepId) {
      submitStepMode({
        variables: {
          classId: classId ? classId : undefined,
          stepId: currentStepId,
          mode: state,
        },
      });
    }
  };

  const handleSelectStage = (value: string) => {
    updateDueDate();
    setStepState(investigation?.steps[currentStepIndex ?? 0]?.mode || 'ALL');

    if (setCurrentStepByIndex) {
      const stepIndex = investigation?.steps?.findIndex((step) => step.id === value) ?? 0;
      setCurrentStepByIndex(stepIndex);
    }
  };

  const handleNextStage = () => {
    updateDueDate();
    setDropdownOpen(false);

    if (lastSetting && onFinishSettings) {
      onFinishSettings();
    } else if (setCurrentStepByIndex) {
      setCurrentStepByIndex((currentStepIndex ?? 0) + 1);
    }
  };

  const handlePreviousStage = () => {
    updateDueDate();
    setStepState(investigation?.steps[currentStepIndex ?? 0]?.mode || 'ALL');
    setDropdownOpen(false);

    if (setCurrentStepByIndex) {
      setCurrentStepByIndex((currentStepIndex ?? 1) - 1);
    }
  };

  const disabledDates = (current: moment.Moment) => {
    return (
      current &&
      (current < moment(investigation?.startDate).endOf('day').subtract(1, 'day') ||
        current > moment(moment(investigation?.dueDate)))
    );
  };

  const handleOpenDropdown = () => {
    if (canChangeMode) {
      setDropdownOpen(!dropdownOpen);
    }
  };

  const bulkStepModeUpdateButton = useMemo(() => {
    if (!investigation) return null;

    return (
      <Popconfirm
        title={
          <>
            <p>
              By confirming, you will apply <b>{stepState === 'INPERSON' ? 'In-Person' : 'Remote'}</b> state to{' '}
              <b>all steps</b> in the current investigation.
            </p>
            <p>
              <b>Note:</b> No changes will be made to steps that do not support{' '}
              <b>{stepState === 'INPERSON' ? 'In-Person' : 'Remote'}</b> state.
            </p>
          </>
        }
        okText="Confirm"
        cancelText="Cancel"
        onConfirm={() => {
          submitAllStepsMode({
            variables: {
              investigationId: investigation.id,
              classId: investigation.classId,
              mode: stepState,
            },
          });
        }}
        placement="topRight"
        overlayStyle={{ maxWidth: '400px' }}
      >
        <Button theme={themeConfig.primaryNoColor} text="Apply State to All Steps" />
      </Popconfirm>
    );
  }, [investigation, stepState, submitAllStepsMode]);

  const loading = loadingPreRequisite || loadingStepMode || loadingAllStepModes;

  return (
    <Sider
      title={investigation ? `${investigation?.discipline?.name}: ${investigation?.title}` : 'Loading...'}
      backUrl={backUrl}
    >
      <S.SidebarContainer>
        <div>
          <S.Divider />
          <Select
            onChange={(v) => handleSelectStage(v as string)}
            placeholder="Loading..."
            value={investigation?.steps?.length && investigation?.steps[currentStepIndex ?? 0]?.id}
          >
            {investigation?.steps?.map((step, index) => (
              <SelectOption value={step.id} key={step.id}>
                Stage {index + 1}: {step.name}
              </SelectOption>
            ))}
          </Select>
          <Spacer size={32} />

          <S.SettingTitle>Set a Deadline for this Stage</S.SettingTitle>
          <RangePicker backgroundColor="#FFFFFF" onChange={setDuration} disabledDate={disabledDates} value={duration} />
          <Spacer size={32} />

          {!!currentStepIndex && currentStepIndex !== -1 && (
            <>
              <S.PrerequisiteTitleContainer onClick={() => updatePreRequisite(!prerequisite)}>
                <S.SettingTitle>Set Prerequisites for this Stage</S.SettingTitle>
                <Switch onChange={updatePreRequisite} checked={prerequisite} size="small" />
              </S.PrerequisiteTitleContainer>
              <S.PrerequisiteContainer activated={prerequisite}>
                <S.DatePickerText>
                  Stage {currentStepIndex}: {investigation?.steps[currentStepIndex - 1]?.name}
                </S.DatePickerText>
              </S.PrerequisiteContainer>
              <Spacer size={32} />
            </>
          )}

          <S.SettingTitle>Set State for this Stage</S.SettingTitle>
          <S.DropdownSelect open={canChangeMode && dropdownOpen}>
            <div onClick={handleOpenDropdown}>
              <S.DatePickerText>
                Stage will be conducted <span role="none" >{stepState === 'INPERSON' ? 'In-Person' : 'Remote'}</span>
              </S.DatePickerText>
              {canChangeMode ? dropdownOpen ? <FiChevronUp /> : <FiChevronDown /> : <></>}
            </div>
            <S.OptionsContainer open={canChangeMode && dropdownOpen}>
              <div onClick={() => handleStepStateChange('INPERSON')}>
                <p>In-Person</p>
              </div>
              <div onClick={() => handleStepStateChange('REMOTE')}>
                <p>Remote</p>
              </div>
            </S.OptionsContainer>
          </S.DropdownSelect>
          {bulkStepModeUpdateButton}

          <Spacer size={32} />

          <S.SettingTitle>Stage Activities</S.SettingTitle>
          {investigation?.steps[currentStepIndex ?? 0]?.activities?.map((activity, index) => (
            <S.ActivityButton
              key={activity.id}
              selected={currentActivityIndex === index}
              onClick={() => setCurrentActivityByIndex && setCurrentActivityByIndex(index)}
            >
              {activity.name}
              <FiArrowRight size={22} />
            </S.ActivityButton>
          ))}
        </div>
        <Row gutter={20}>
          <Col span={12}>
            <Button
              text="Previous Stage"
              theme={themeConfig.primaryOutlined}
              disabled={firstSetting}
              minHeight={40}
              loading={loading}
              block
              onClick={handlePreviousStage}
            />
          </Col>

          <Col span={12}>
            <Button
              text={lastSetting ? 'Finish' : 'Next Stage'}
              minHeight={40}
              block
              onClick={handleNextStage}
              loading={loading}
            />
          </Col>
        </Row>
      </S.SidebarContainer>
    </Sider>
  );
};

export default InvestigationSettingsSider;
