import { Row } from 'antd';
import styled from 'styled-components';

export const PageContainer = styled(Row)`
  margin: 4em 0;
  height: calc(100vh - 80px - 8em);

  & > div {
    height: 100%;
  }

  .ant-skeleton-input {
    width: 250px;
    margin-bottom: 16px;
    border-radius: 4px;
  }

  .ant-skeleton-input-sm {
    width: 150px;
  }

  .ant-skeleton-input-lg {
    width: 200px;
    margin-top: 16px;
  }

  .ant-skeleton-button {
    margin: 0 32px;
    width: 128px;
    height: 40px;
  }
`;
