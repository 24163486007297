import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { Col, message, Progress, Row, Spin } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { GQL_ActiveUsersResponse, GQL_ActiveUsersInput } from '../../../../types/charts';
import moment from 'moment';
import { useAuth } from '../../../../hooks/useAuth';

interface Props {
  type: 'teacher' | 'student' | 'organization_admin' | 'teacher_assistant' | 'clever' | 'classlink' | 'facilitator';
  title: string;
  startDate?: Date | null;
  organizationId: string;
}

const ActiveUserItem = (props: Props) => {
  const { type, title, startDate, organizationId } = props;
  const [percentage, setPercentage] = useState(0);
  const { isAdiAdmin, isAdiSuperAdmin } = useAuth();
  const isAdiAdminOrSuperAdmin = isAdiAdmin || isAdiSuperAdmin;

  const [fetchActiveUserChart, { data }] = useLazyQuery<
    { getActiveUserChart: GQL_ActiveUsersResponse },
    GQL_ActiveUsersInput
  >(gqlSchema.ChartSchema.queries.CHARTS.getActiveUserChart, {
    onCompleted: (data) => {
      const percent = ((data.getActiveUserChart.activeUsers * 100) / data.getActiveUserChart.totalUsers) | 0;
      setPercentage(percent);
    },
    onError: (err) => {
      message.error('There was an error loading active users: ' + err.message || 'Unexpected Error');
    },
  });

  useEffect(() => {
    const variables: GQL_ActiveUsersInput = { option: type, from: moment(startDate).format('MM.DD.YYYY') };
    if (organizationId) variables.orgId = organizationId;
    fetchActiveUserChart({ variables });
  }, [fetchActiveUserChart, organizationId, startDate, type]);

  return (
    <Row>
      <Col span={24}>
        <S.Card>
          {!data ? (
            <Spin data-cy="shared-charts-activeusers-item-spin" size="large" />
          ) : (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <S.Title style={{ marginTop: 10 }}>Active {title}</S.Title>
              </Col>
              <Col span={24}>
                <Progress
                  data-cy={`shared-charts-activeusers-activeuseritem-${type}`}
                  data-percent={parseFloat(percentage.toFixed(2))}
                  type="dashboard"
                  percent={parseFloat(percentage.toFixed(2))}
                  width={130}
                  format={(percent) => `${percent}%`}
                />
              </Col>
              <Col span={24}>
                <S.Title>
                  {data.getActiveUserChart.activeUsers} of {data.getActiveUserChart.totalUsers}
                </S.Title>
                {isAdiAdminOrSuperAdmin && <S.Title>({data.getActiveUserChart.betaUsers} Beta Users)</S.Title>}
              </Col>
            </Row>
          )}
        </S.Card>
      </Col>
    </Row>
  );
};

export default ActiveUserItem;
