import { gql } from '@apollo/client';
import ORGANIZATION_FRAGMENTS from '../../Organization/fragment/Organization';

const CLEVER_QUERIES = {
  LIST: {
    getCleverOrganizations: gql`
      query getCleverOrganizations {
        getCleverOrganizations {
          id
          name
          organizationAdmins {
            ...NewOrganizationAdmin
          }
          autoSync
          scheduleConfig {
            daily
            weekly
            monthly
          }
        }
      }
      ${ORGANIZATION_FRAGMENTS.newOrganizationAdmin}
    `,
  },
};

export default CLEVER_QUERIES;
