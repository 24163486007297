import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm, Row, Col } from 'antd';
import Form from '../../shared/Form';
import { useAuth } from '../../hooks/useAuth';
import * as S from './styles';
import ChangePassword from '../../shared/ChangePassword';
import { formatDateTime } from '../../utils/date';
import { Filter } from '../../utils/antd';
import { roles } from '../../utils/roles';
import SubscriptionStatus from '../../shared/SubscriptionStatus';
import UpdateAvatar from '../../shared/UpdateAvatar';
import ChangePasswordButton from '../../shared/ChangePasswordButton';
import { useMemo } from 'react';
import BetaToggle from '../BetaToggle';

const GenericProfilePage = () => {
  const { user, isOrganizationAdiAdmin, isAdiAdmin, isAdiSuperAdmin } = useAuth();
  const canToggleBeta = isOrganizationAdiAdmin || isAdiAdmin || isAdiSuperAdmin;
  const [changePassVisible, setChangePassVisible] = useState(false);
  const renderRoles = useCallback(() => {
    if (user) {
      return user.roles.map((role: string) => {
        const roleItem = roles.find((r: Filter) => r.value === role);
        return (
          <S.RoleText color={roleItem?.color} key={role}>
            {roleItem?.text || ''}
          </S.RoleText>
        );
      });
    }
    return null;
  }, [user]);

  const orgInfo = useMemo(
    () => (
      <S.Line md={24} lg={9}>
        <S.TitleInput>Organization</S.TitleInput>
        <AntdForm.Item name="organization">
          <S.SInput disabled bordered={false} defaultValue={user?.organizationName || '-- NO ORGANIZATION --'} />
        </AntdForm.Item>
      </S.Line>
    ),
    [user],
  );

  const subscriptionInfo = useMemo(
    () =>
      user?.subscription ? (
        <Col md={24} lg={7}>
          <SubscriptionStatus subscription={user.subscription} stripeSubscription={user?.stripeSubscription} />
        </Col>
      ) : (
        <Col md={24} lg={8}>
          <S.TitleInput>Account Status</S.TitleInput>
          <AntdForm.Item name="status">
            <S.Status $status={user.active}> {user.active ? 'Active' : 'Inactive'}</S.Status>
          </AntdForm.Item>
        </Col>
      ),
    [user],
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    console.log('Resize detected:', window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <Row style={{ marginTop: isMobile? 80:  60 }}>
        <Col span={22} offset={1}>
          <S.Title>Your Account Information</S.Title>
        </Col>
        <S.Body span={22} offset={1}>
          <Form>
            <Row>
              <Col xxl={4} xl={4} lg={4} md={8} sm={24} xs={24}>
                <UpdateAvatar />
              </Col>
              <Col xxl={20} xl={20} lg={20} md={16} sm={24}>
                <Row style={{ padding: 20 }}>
                  <S.Line md={24} lg={7}>
                    <S.TitleInput>Name</S.TitleInput>
                    <AntdForm.Item name="name">
                      <S.SInput disabled bordered={false} defaultValue={user?.name} />
                    </AntdForm.Item>
                  </S.Line>
                  <S.Line md={24} lg={8}>
                    <S.TitleInput>Email</S.TitleInput>
                    <AntdForm.Item name="email">
                      <S.SInput disabled bordered={false} defaultValue={user?.username} />
                    </AntdForm.Item>
                  </S.Line>
                  <S.Line md={24} lg={9}>
                    <S.TitleInput>Your Role(s)</S.TitleInput>
                    {renderRoles()}
                  </S.Line>
                  <S.CDivider />
                </Row>
                <Row style={{ padding: 20 }}>
                  <Col md={24} lg={7}>
                    <S.TitleInput>Password</S.TitleInput>
                    <Row>
                      <Col span={18}>
                        <AntdForm.Item name="password">
                          <S.SInput password disabled bordered={false} defaultValue="password" />
                        </AntdForm.Item>
                      </Col>
                      <Col span={6} style={{ textAlign: 'center' }}>
                        <ChangePasswordButton
                          userSource={user.source}
                          handleChangePassword={() => setChangePassVisible(true)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24} lg={8}>
                    <S.TitleInput>Enrollment Date</S.TitleInput>
                    <AntdForm.Item name="enrollment-date">
                      <S.SInput
                        disabled
                        bordered={false}
                        defaultValue={
                          user && formatDateTime(user.enrollmentDate, "MM.dd.yyyy, hh:mm aaaaa'm'").toUpperCase()
                        }
                      />
                    </AntdForm.Item>
                  </Col>
                  {user?.organizationName ? orgInfo : subscriptionInfo}

                  {user?.organizationName && <S.CDivider />}
                </Row>
              </Col>
            </Row>
            {(user?.organizationName || canToggleBeta) && (
              <Row style={{ padding: 20 }}>
                <Col lg={{ span: 20, offset: 4 }} md={{ span: 16, offset: 8 }} sm={{ span: 24, offset: 24 }}>
                  <Row>
                    {user?.organizationName && subscriptionInfo}
                    {canToggleBeta && (
                      <S.Line md={24} sm={24} lg={8}>
                        <BetaToggle />
                      </S.Line>
                    )}
                  </Row>
                </Col>
              </Row>
            )}
          </Form>
        </S.Body>
      </Row>
      <ChangePassword visible={changePassVisible} setVisible={setChangePassVisible} />
    </>
  );
};

export default GenericProfilePage;
