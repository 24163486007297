import { Col, message, Row, Table, Typography } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FiDownloadCloud, FiSearch } from 'react-icons/fi';
import Button from '../../../shared/Button';
import { centerAlign, Breakpoint } from '../../../utils/antd';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';
import {
  GQL_OrganizationAdminList,
  GQL_OrganizationScheduledInput,
  GQL_OrganizationScheduledResponse,
  GQL_SSOOrganizationDetailsResponse,
} from '../../../types/organization';
import TableSearchBox from '../../../shared/TableSearchBox';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import { SelectValue } from 'antd/lib/select';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { useHistory } from 'react-router-dom';

const TableBreakPoint: Breakpoint[] = ['lg'];
const { Text, Link } = Typography;
interface Props {
  data?: GQL_SSOOrganizationDetailsResponse[];
  loading: boolean;
  type: 'classlink' | 'clever';
  setDataCLasslinkList: (data: GQL_SSOOrganizationDetailsResponse[]) => void;
  setDataCleverList: (data: GQL_SSOOrganizationDetailsResponse[]) => void;
}

const OrganizationSyncListTable: React.FC<Props> = (props) => {
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [rowSelected, setRowSelected] = useState('');
  const { data, loading, setDataCLasslinkList, setDataCleverList, type } = props;
  const history = useHistory();
  const refName = useRef<HTMLInputElement>(null);

  const updateData = useCallback(
    (row: GQL_OrganizationScheduledResponse) => {
      if (data) {
        const newData = data?.map((r) => {
          if (r.id === row.id) {
            const { daily, weekly, monthly } = row;
            return { ...r, autoSync: row.autoSync, scheduleConfig: { daily, weekly, monthly } };
          }
          return r;
        });
        if (type === 'classlink') setDataCLasslinkList(newData);
        else setDataCleverList(newData);
      }
    },
    [data, setDataCLasslinkList, setDataCleverList, type],
  );

  const [submitEditRosterOrganization, { loading: loadingEditRosterOrganization }] = useMutation<
    { editRosterOrganization: GQL_OrganizationScheduledResponse },
    { data: GQL_OrganizationScheduledInput }
  >(gqlSchema.OrganizationSchema.mutations.EDIT.editRosterOrganization, {
    onCompleted: (data) => {
      message.success(`${data.editRosterOrganization.name} updated successfully`);
    },
    onError: (error) => {
      message.error(error.message);
    },
    update(cache, { data }) {
      if (data?.editRosterOrganization) {
        const organizationResponse = {
          __typename: 'SSOOrganizationDetailsResponse',
          id: data.editRosterOrganization.id,
        };
        updateData(data?.editRosterOrganization);
        cache.modify({
          id: cache.identify(organizationResponse),
          broadcast: true,
          fields: {
            autoSync() {
              return data.editRosterOrganization.autoSync;
            },
            scheduleConfig() {
              const { daily, weekly, monthly } = data.editRosterOrganization;
              return { daily, weekly, monthly };
            },
          },
        });
      }
    },
  });

  const [submitSyncClasslinkRoster] = useMutation<{ syncClasslinkRoster: string }, { orgId: string }>(
    gqlSchema.ClassLinkSchema.mutations.ROSTER.syncClasslinkRoster,
    {
      onCompleted: (data) => {
        message.success(
          <Text>
            Synchronization started,{' '}
            <Link onClick={() => history.push(`/sync/${data.syncClasslinkRoster}`)}>see details here</Link>, you'll be
            notified once the process finishes
          </Text>,
          5,
        );
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitSyncCleverRoster] = useMutation<{ syncCleverRoster: string }, { orgId: string }>(
    gqlSchema.CleverSchema.mutations.ROSTER.syncCleverRoster,
    {
      onCompleted: (data) => {
        message.success(
          <Text>
            Synchronization started,{' '}
            <Link onClick={() => history.push(`/sync/${data.syncCleverRoster}`)}>see details here</Link>, you'll be
            notified once the process finishes
          </Text>,
          5,
        );
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const onSynchronize = useCallback(
    (organization: GQL_SSOOrganizationDetailsResponse) => {
      if (type === 'classlink') submitSyncClasslinkRoster({ variables: { orgId: organization.id } });
      else submitSyncCleverRoster({ variables: { orgId: organization.id } });
    },
    [submitSyncClasslinkRoster, submitSyncCleverRoster, type],
  );

  const handleChangeValue = useCallback(
    (value: SelectValue, organization: GQL_SSOOrganizationDetailsResponse) => {
      setRowSelected(organization.id);
      submitEditRosterOrganization({
        variables: {
          data: {
            organizationId: organization.id,
            daily: value?.toString() === 'daily',
            weekly: value?.toString() === 'weekly',
            monthly: value?.toString() === 'monthly',
          },
        },
      });
    },
    [submitEditRosterOrganization],
  );

  const getConfigvalue = useCallback(
    (organization: GQL_SSOOrganizationDetailsResponse) => {
      const row = data?.find((f) => f.id === organization.id);
      if (row?.scheduleConfig.daily) return 'daily';
      if (row?.scheduleConfig.weekly) return 'weekly';
      if (row?.scheduleConfig.monthly) return 'monthly';

      return 'onDemand';
    },
    [data],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        align: centerAlign,
        dataIndex: 'name',
        width: '50%',
        responsive: TableBreakPoint,
        sorter: (a: GQL_SSOOrganizationDetailsResponse, b: GQL_SSOOrganizationDetailsResponse) =>
          a.name.localeCompare(b.name),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_SSOOrganizationDetailsResponse) => {
          if (!value) return true;
          return record.name.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Admins',
        align: centerAlign,
        width: '35%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_SSOOrganizationDetailsResponse) => (
          <>
            {record.organizationAdmins
              .slice(0, record.organizationAdmins.length > 1 ? 2 : 1)
              .map((admin: GQL_OrganizationAdminList) => (
                <S.TagButton
                  key={admin.userId}
                  text={admin.name}
                  theme={themeConfig.primaryOutlined}
                  shape="round"
                  minHeight={24}
                />
              ))}

            {record.organizationAdmins.length > 2 && (
              <S.TagButton
                text={`+ ${record.organizationAdmins.length - 2}`}
                background="#c4c4c4"
                shape="round"
                minHeight={24}
              />
            )}
          </>
        ),
      },
      {
        title: 'Sync Config',
        align: centerAlign,
        width: '35%',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_SSOOrganizationDetailsResponse) => (
          <Select
            placeholder="Select An Activity"
            value={getConfigvalue(record)}
            onChange={(value: SelectValue) => handleChangeValue(value, record)}
            loading={loadingEditRosterOrganization && rowSelected === record.id}
            disabled={loadingEditRosterOrganization && rowSelected === record.id}
          >
            <SelectOption value={'onDemand'}>On Demand</SelectOption>
            <SelectOption value={'daily'}>Daily</SelectOption>
            <SelectOption value={'weekly'}>Weekly</SelectOption>
            <SelectOption value={'monthly'}>Monthly</SelectOption>
          </Select>
        ),
      },
      {
        title: 'Actions',
        align: centerAlign,
        render: (text: string, record: GQL_SSOOrganizationDetailsResponse) => {
          return (
            <Button
              text="Sync Now"
              icon={FiDownloadCloud}
              onClick={() => onSynchronize(record)}
              block
              theme={themeConfig.primaryColor}
            />
          );
        },
        width: '15%',
      },
    ],
    [getConfigvalue, handleChangeValue, onSynchronize, searchNameVisible, loadingEditRosterOrganization, rowSelected],
  );

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <S.TableWrapper>
          <Table
            columns={columns}
            loading={loading}
            rowKey={(record: GQL_SSOOrganizationDetailsResponse) => record.id}
            bordered
            dataSource={data}
          />
        </S.TableWrapper>
      </Col>
    </Row>
  );
};

export default OrganizationSyncListTable;
