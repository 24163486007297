import { Layout, Popover, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../shared/Button';
import InvestigationSider from '../../shared/InvestigationSider';
import * as S from './styles';
import TaskWalkthrough from './TaskWalkthrough';
import PeerReviewWalkthrough from './PeerReviewWalkthrough';
import ReflectWalkthrough from './ReflectWalkthrough';
import ReportWalkthrough from './ReportWalkthrough';
import { mockedStepList } from './stepListMock';

const tipsText = [
  'Progress summary, as you progress new stages and activities will be shown and you can navigate to see the previous stages.',
  'To proceed, students must navigate through all content.',
  'After completing the activity, a button to proceed to the next step will appear.',
  'After a file submission, students will anonymously peer review the work from other students from their own group.',
  'After peer review, students will have to reflect on what people wrote about their own work.',
  'In the end of an investigation, students will have to report what they learnt.',
];

const InvestigationWalkthroughPage = () => {
  const [currentTip, setCurrentTip] = useState(0);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (currentTip >= tipsText.length) {
      // Split the current location and remove last argument,
      // so we can go to last page
      const pathParts = location.pathname.split('/');
      pathParts.pop();

      history.push(pathParts.join('/'), {
        completedPreview: true,
      });
    }
  }, [currentTip, history, location]);

  const handleSkipTip = () => {
    setCurrentTip(tipsText.length);
  };

  const getProceedButtons = () => {
    // Is on the last tip
    if (currentTip === tipsText.length - 1) {
      return (
        <div>
          <Button
            text="Finish Walkthrough"
            minHeight={40}
            minWidth={110}
            onClick={() => setCurrentTip(currentTip + 1)}
            block
          />
        </div>
      );
    } else {
      return (
        <div>
          <Button text="Skip Tips" minHeight={40} minWidth={110} onClick={handleSkipTip} />
          <Button text="Next Tip" minHeight={40} minWidth={110} onClick={() => setCurrentTip(currentTip + 1)} />
        </div>
      );
    }
  };

  const getPopoverContent = () => {
    return (
      <S.PopoverContentContainer>
        {tipsText[currentTip]}
        {getProceedButtons()}
      </S.PopoverContentContainer>
    );
  };

  const getCurrentStepIndex = () => {
    if (currentTip < 3) return 0;
    else if (currentTip < 5) return 2;
    else return 7;
  };

  const getWalkthroughComponent = () => {
    if (currentTip < 3) return <TaskWalkthrough currentTip={currentTip} popoverContent={getPopoverContent} />;
    else if (currentTip === 3)
      return <PeerReviewWalkthrough currentTip={currentTip} popoverContent={getPopoverContent} />;
    else if (currentTip === 4) return <ReflectWalkthrough currentTip={currentTip} popoverContent={getPopoverContent} />;
    else return <ReportWalkthrough currentTip={currentTip} popoverContent={getPopoverContent} />;
  };

  return (
    <Layout style={{ overflow: 'hidden', minHeight: 'calc(100vh - 80px)' }}>
      <InvestigationSider
        title="Science: Differences in Traits"
        highlighted={currentTip === 0}
        stepIndex={getCurrentStepIndex()}
        stepList={mockedStepList}
        stepsLength={3}
      />
      <S.PreviewContent>
        <Row justify="center" align="middle">
          {currentTip === 2 ? (
            <S.RoundButton shape="circle" icon={<FiChevronLeft size={22} />} />
          ) : (
            <S.RoundButtonPlaceholder />
          )}
          <S.PreviewContentCol span={16}>{getWalkthroughComponent()}</S.PreviewContentCol>
          {currentTip === 1 ? (
            <Popover
              content={getPopoverContent}
              visible={currentTip === 1}
              placement="right"
              getPopupContainer={(node) => (node.parentElement ? node.parentElement : document.body)}
            >
              <S.RoundButton shape="circle" $left icon={<FiChevronRight size={22} />} />
            </Popover>
          ) : (
            <S.RoundButtonPlaceholder />
          )}
        </Row>
        <S.Dimmer visible={currentTip === 0}>
          <Popover
            content={getPopoverContent}
            visible={currentTip === 0}
            placement="right"
            getPopupContainer={(node) => (node.parentElement ? node.parentElement : document.body)}
          >
            <S.PopoverChild />
          </Popover>
        </S.Dimmer>
      </S.PreviewContent>
    </Layout>
  );
};

export default InvestigationWalkthroughPage;
