import React, { useState, useEffect } from 'react';
import { Table, Input, Button, message, Spin } from 'antd';
import { useQuery, useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import AssignmentSummaryPopup from '../GoogleClassroomStudentsDashboardPage/GActiveAssignmentsRow/AssignmentSummaryPopup';

const { Search } = Input;

interface Course {
  id: string;
  name: string;
}

interface Assignment {
  id: string;
  courseId: string;
  title: string;
  dueDate: { year: number; month: number; day: number } | null;
  state: string;
}

const GoogleStudentsAssignmentDueDate: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [courseIds, setCourseIds] = useState<string[]>([]);
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [filteredAssignments, setFilteredAssignments] = useState<Assignment[]>([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { loading: coursesLoading } = useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCoursesList,
    {
      onError: (error) => {
        message.error(
          error.message ||
            'There was an error loading your classes, check your connection and try again later.'
        );
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        console.log('Courses data:', data);
        const fetchedCourses = data?.getCoursesList || [];
        setCourses(fetchedCourses);
        if (fetchedCourses.length > 0) {
          const ids = fetchedCourses.map((course: any) => course.id);
          setCourseIds(ids);
        }
      },
    }
  );

  const [getCourseWorkListByDueDate, { loading: cwLoading }] = useLazyQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkListByDueDate,
    {
      onError: (error) => {
        console.error('Error fetching course work list:', error);
      },
      onCompleted: (data) => {
        console.log('Course work list data:', data); 
        handleCourseWorkListCompletion(data);
      },
    }
  );

  const [getCourseWorkSubmissions] = useLazyQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionList,
    {
      onError: (error) => {
        console.error('Error fetching course work submissions:', error);
      },
      onCompleted: (data) => {
        handleCourseWorkSubmissionsCompletion(data);
      },
    }
  );

  const { loading: courseWorkLoading, data: courseWorkData, error: courseWorkError, refetch: refetchCourseWork } = useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
    {
      variables: { courseId: selectedAssignment?.courseId, id: selectedAssignment?.id },
      skip: !selectedAssignment,
      onError: (error) => {
        console.error('Error fetching course work details:', error);
        message.error('Failed to fetch course work details');
      },
      onCompleted: (data) => {
        if (data && data.getCourseWorkById) {
          console.log('Course work data:', data);
        }
      },
    }
  );

  useEffect(() => {
    if (!coursesLoading && courseIds.length > 0) {
      getCourseWorkListByDueDate({
        variables: {
          courseId: courseIds,
          data: 'PUBLISHED',
        },
      });
      console.log('Courses:', courses);
    }
  }, [coursesLoading, courseIds, getCourseWorkListByDueDate, courses]);

  useEffect(() => {
    if (!coursesLoading && courseIds.length > 0) {
      courseIds.forEach((courseId) => {
        assignments.forEach((assignment) => {
          console.log(`Fetching submissions for CourseWork ID ${assignment.id} in Course ID ${courseId}`);
          getCourseWorkSubmissions({
            variables: {
              data: [
                { courseId: courseId, courseWorkId: assignment.id }
              ],
            },
          });
        });
      });
    }
  }, [coursesLoading, courseIds, assignments, getCourseWorkSubmissions]);  

  useEffect(() => {
    const filtered = assignments.filter((assignment) =>
      assignment.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredAssignments(filtered);
  }, [searchTerm, assignments]);

  const handleCourseWorkListCompletion = (data: any) => {
    console.log('Course work list data:', data);
    const activeCourseWork = [...data?.getCourseWorkListByDueDate?.activeCourseWork || []];
    const sortedAssignments = activeCourseWork.sort((a: Assignment, b: Assignment) => {
      if (a.dueDate && b.dueDate) {
        const dateA = new Date(a.dueDate.year, a.dueDate.month - 1, a.dueDate.day);
        const dateB = new Date(b.dueDate.year, b.dueDate.month - 1, b.dueDate.day);
        return dateA.getTime() - dateB.getTime();
      } else if (a.dueDate && !b.dueDate) {
        return -1;
      } else if (!a.dueDate && b.dueDate) {
        return 1;
      } else {
        return 0;
      }
    });
    setAssignments(sortedAssignments);
    setFilteredAssignments(sortedAssignments);

    // Fetch submissions for each assignment
    sortedAssignments.forEach((assignment) => {
      console.log(`Fetching submissions for CourseWork ID ${assignment.id} in Course ID ${assignment.courseId}`);
      getCourseWorkSubmissions({
        variables: {
          data: [
            { courseId: assignment.courseId, courseWorkId: assignment.id }
          ],
        },
      });
    });
  };

  const handleCourseWorkSubmissionsCompletion = (data: any) => {
    console.log('Course work submission data:', data);
    
    // Extract submissions data from the response
    const submissionsData = data?.getCourseWorkSubmissionList || [];
    console.log('Extracted submissions data:', submissionsData);
    // Flatten the submissions data
    const flattenedSubmissions = submissionsData.flatMap((course: any) =>
      course.studentSubmissions.map((submission: any) => ({
        ...submission,
        courseId: course.courseId,
        courseWorkId: course.courseWorkId,
      }))
    );
    console.log('Flattened submissions:', flattenedSubmissions);

    // Map through assignments and update them with submission status
    const updatedAssignments = assignments.map((assignment) => {
      const submission = flattenedSubmissions.find(
        (sub: any) => sub.courseWorkId === assignment.id
      );

      const newAssignment = { ...assignment };
      if (submission) {
        console.log(`Assignment ID: ${assignment.id}, Submission State: ${submission.state}`);
        newAssignment.state = submission.state === 'TURNED_IN' ? 'COMPLETED' : 'NOT_COMPLETED';
      } else {
        newAssignment.state = 'NOT_COMPLETED'; // If no submission found
      }
      return newAssignment;
    });

    console.log('Updated Assignments:', updatedAssignments);
    setAssignments(updatedAssignments);
    setFilteredAssignments(updatedAssignments);
  };

  const handleViewSummaryClick = (assignment: Assignment) => {
    setSelectedAssignment(assignment);
    setPopupVisible(true);
    refetchCourseWork();
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setSelectedAssignment(null);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const columns = [
    {
      title: 'Assignment Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      render: (dueDate: { year: number; month: number; day: number } | null) =>
        dueDate ? `${dueDate.year}-${dueDate.month}-${dueDate.day}` : '',
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: (text: string, record: Assignment) => (
        <Button type={text === 'COMPLETED' ? 'primary' : 'default'} onClick={() => handleViewSummaryClick(record)}>
          {text === 'COMPLETED' ? 'Completed' : 'Not Completed'}
        </Button>
      ),
    },
  ];

  if (coursesLoading || cwLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{padding: isMobile ? 0 : 20, marginTop: isMobile ? '80px' : 'unset'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <Search
          placeholder="Search Assignment Name"
          style={{ width: 200 }}
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      {!isMobile && (<><Table columns={columns} dataSource={filteredAssignments} rowKey="id" /></>)}
      {isMobile && (
  <Table
    columns={columns}
    dataSource={filteredAssignments}


    rowKey="id"
    expandable={{
      expandedRowRender: (record: any) => {
        return (
          <div>
            {columns.map((column) => {
              if (column.key !== 'state') { // Exclude the "Status" column
                return (
                  <div key={column.key} style={{ marginBottom: '8px' }}>
                    <strong>{column.title}:</strong>{' '}
                    {column.render
                      ? column.render(record[column.dataIndex], record)
                      : record[column.dataIndex]}
                  </div>
                );
              }
              return null;
            })}
          </div>
        );
      },
    }}
  />
)}
      {selectedAssignment && (
        <AssignmentSummaryPopup
          courseId={selectedAssignment.courseId}
          assignmentId={selectedAssignment.id}
          visible={isPopupVisible}
          onClose={handleClosePopup}
          isFinished={selectedAssignment.state === 'COMPLETED'}
          score={selectedAssignment.state === 'COMPLETED' ? 100 : 0} // Assuming score for completed is 100
          courseWorkData={courseWorkData}
          courseWorkError={courseWorkError}
          courseWorkLoading={courseWorkLoading}
        />
      )}
    </div>
  );
};

export default GoogleStudentsAssignmentDueDate;
