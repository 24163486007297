import { useLazyQuery, useQuery } from '@apollo/client';
import { Col, message, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ExcelJS, { Borders } from 'exceljs';
import { saveAs } from 'file-saver';

import { gqlSchema } from '../../../gql/schema';
import LineChartWithBackground from '../../../shared/LineChartWithBackground';
import { GQL_ClassDataResponse, GQL_ClassResponse, GQL_QueryClassDataInput } from '../../../types/class';
import { InsightsType } from './../../../constants/enums/InsightsTypes';
import { FilterData } from '../../../types/investigation';
import { palette } from '../../../utils/theme';
import AverageScoreGauge from '../AverageScoreGauge';
import InsightsFilters from '../InsightsFilters';
import InsightsTable from '../InsightsTable';
import ScoreDifference from '../ScoreDifference';
import * as S from '../styles';

export interface InsightsData {
  investigationId: string;
  investigationTitle: string;
  reportScore: number;
  reportAverage: number;
  maxReportScore: number;
  engagementScore: number;
  engagementAverage: number;
  maxEngagementScore: number;
  startDate: number;
  gradedStudents: number;
}

interface ITeacherScores {
  classId?: string;
  studentIds?: string[];
  tagIds?: string[];
  disciplineId?: number;
  subject?: string;
  gradeBand?: string;
}

const scoresPercentages = {
  needsImprovement: 0.4,
  meetsExpectations: 0.8,
  exceedsExpectations: 1,
};

export const scoresPercentagesColors = {
  needsImprovement: '#EA3535',
  meetsExpectations: '#E9A643',
  exceedsExpectations: '#2ECC71',
};

export const getInsightsColors = (score: number) => {
  if (score <= scoresPercentages.needsImprovement * 100) {
    return scoresPercentagesColors.needsImprovement;
  } else if (score <= scoresPercentages.meetsExpectations * 100) {
    return scoresPercentagesColors.meetsExpectations;
  } else {
    return scoresPercentagesColors.exceedsExpectations;
  }
};

const TeacherInvestigationInsights = () => {
  const [filterData, setFilterData] = useState<FilterData>();
  const [selectedInvestigationIds, setSelectedInvestigationIds] = useState<string[]>([]);
  const [selectedClassId, setSelectedClassId] = useState<string | undefined>();
  const [selectedStudentId, setSelectedStudentId] = useState<string | undefined>();
  const [comparingInvestigations, setComparingInvestigations] = useState<boolean>(false);
  const insightType = InsightsType.INVESTIGATIONS;

  const { data: classesData, loading: loadingClasses } = useQuery<{ getClasses: GQL_ClassResponse[] }>(
    gqlSchema.ClassSchema.query.CLASS.CLASSES.getClasses,
  );

  const [getTeacherScores, { data: scoresData, loading }] = useLazyQuery<
    { getTeacherScores: any[] },
    { data: ITeacherScores }
  >(gqlSchema.InvestigationSchema.queries.GRADE_BOOK.getTeacherScores, {
    fetchPolicy: 'cache-and-network',
  });

  const [getClass, { data: classStudentsData, loading: loadingStudents }] = useLazyQuery<{
    getClass: GQL_ClassResponse;
  }>(gqlSchema.ClassSchema.query.CLASS.CLASSES.getClass);

  useEffect(() => {
    if (comparingInvestigations && selectedInvestigationIds?.length < 2) {
      setComparingInvestigations(false);
    }
  }, [selectedInvestigationIds, comparingInvestigations]);

  useEffect(() => {
    if (selectedClassId) {
      getClass({ variables: { data: { classId: selectedClassId } } });
      getTeacherScores({
        variables: {
          data: {
            classId: selectedClassId,
            studentIds: selectedStudentId ? [selectedStudentId] : undefined,
            tagIds: filterData?.tagIds?.length ? filterData?.tagIds : undefined,
            subject: filterData?.investigationType,
          },
        },
      });
    }
  }, [selectedClassId, getClass, getTeacherScores, selectedStudentId, filterData]);

  const insightsData = useMemo(
    () => [...(scoresData?.getTeacherScores || [])]?.sort((a, b) => a.startDate - b.startDate),
    [scoresData],
  );

  const comparingInsightsData = useMemo(
    () =>
      insightsData?.filter((i) =>
        comparingInvestigations ? selectedInvestigationIds.includes(i.investigationId) : true,
      ),
    [insightsData, comparingInvestigations, selectedInvestigationIds],
  );

  const studentsData = useMemo(() => classStudentsData?.getClass?.students?.filter((s) => s.userId), [
    classStudentsData,
  ]);

  const handleClassChange = useCallback((id?: string) => {
    setSelectedClassId(id);
    setSelectedStudentId(undefined);
    setSelectedInvestigationIds([]);
  }, []);

  const [getClassData] = useLazyQuery<{ getClassData: GQL_ClassDataResponse[] }, { data: GQL_QueryClassDataInput }>(
    gqlSchema.InvestigationSchema.queries.CLASS.getClassData,
    {
      onCompleted: (data) => {
        saveAsExcel(data.getClassData);
      },
      onError: (err) => {
        message.error('There was an error loading the class data: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const saveAsExcel = useCallback(
    async (dataset: GQL_ClassDataResponse[]) => {
      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet();
      const borderStyle = { style: 'thin', color: 'black' };
      const bordersStyle = {
        top: borderStyle,
        left: borderStyle,
        right: borderStyle,
        bottom: borderStyle,
        diagonal: borderStyle,
      } as Borders;
      const headerStyle = { font: { bold: true }, border: bordersStyle };
      const bodyStyle = { font: { bold: false } };
      ws.columns = [
        { header: 'First Name', key: 'firstName', width: 20 },
        { header: 'Last Name', key: 'lastName', width: 20 },
        { header: 'Email', key: 'email', width: 35 },
        { header: 'Investigation', key: 'investigation', width: 45 },
        { header: 'Raw Report Score (out of 38)', key: 'reportScore', width: 15 },
        { header: 'Percentage Report Score', key: 'reportAverage', width: 15 },
        { header: 'Engagement Score (out of 5)', key: 'engagementScore', width: 20 },
      ];
      ws.getCell(`A1`).style = headerStyle;
      ws.getCell(`B1`).style = headerStyle;
      ws.getCell(`C1`).style = headerStyle;
      ws.getCell(`D1`).style = headerStyle;
      ws.getCell(`E1`).style = headerStyle;
      ws.getCell(`F1`).style = headerStyle;
      ws.getCell(`G1`).style = headerStyle;

      let currentRow = 2;

      dataset.forEach((row) => {
        const excelRow = ws.addRow([
          row.firstName,
          row.lastName,
          row.email,
          'Investigation',
          'Raw Report Score (out of 38)',
          'Percentage Report Score',
          'Engagement Score (out of 5)',
        ]);

        excelRow.alignment = { vertical: 'middle', horizontal: 'left' };
        excelRow.font = bodyStyle.font;

        const mergeLength = currentRow + (row.scores?.length || 0) - 1;
        ws.mergeCells(`A${currentRow}:A${mergeLength}`);
        ws.mergeCells(`B${currentRow}:B${mergeLength}`);
        ws.mergeCells(`C${currentRow}:C${mergeLength}`);
        ws.getCell(`A${currentRow}`).border = bordersStyle;
        ws.getCell(`B${currentRow}`).border = bordersStyle;
        ws.getCell(`C${currentRow}`).border = bordersStyle;
        let subRow = currentRow;
        row.scores?.forEach((inv) => {
          const rowInvestigationName = ws.getCell(`D${subRow}`);
          const rowReportScore = ws.getCell(`E${subRow}`);
          const rowReportAverage = ws.getCell(`F${subRow}`);
          const rowEngagementScore = ws.getCell(`G${subRow}`);
          // Putting value to subrows
          rowInvestigationName.value = inv.title;
          rowReportScore.value = inv.reportScore?.toFixed(2);
          rowReportAverage.value = Math.round(inv.reportAverage * 100) + '%';
          rowEngagementScore.value = inv.engagementScore?.toFixed(1);
          // Putting borders to subrows
          rowInvestigationName.border = bordersStyle;
          rowReportScore.border = bordersStyle;
          rowReportAverage.border = bordersStyle;
          rowEngagementScore.border = bordersStyle;
          // Putting row font
          rowInvestigationName.font = bodyStyle.font;
          rowReportScore.font = bodyStyle.font;
          rowReportAverage.font = bodyStyle.font;
          rowEngagementScore.font = bodyStyle.font;
          subRow += 1;
        });
        currentRow = mergeLength + 1;
      });
      const buf = await wb.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${classStudentsData?.getClass.name} InvestigationData.xlsx`);
    },
    [classStudentsData],
  );

  const onExportClassData = useCallback(() => {
    const data: GQL_QueryClassDataInput = { classId: classStudentsData?.getClass.id || '' };
    if (selectedStudentId) data.studentId = selectedStudentId;
    getClassData({ variables: { data } });
  }, [classStudentsData, getClassData, selectedStudentId]);

  const handleInvestigationSelection = useCallback(
    (ids: string[]) => {
      setSelectedInvestigationIds(ids);
    },
    [setSelectedInvestigationIds],
  );

  return (
    <S.Container>
      <Row gutter={[32, 32]} style={{ width: '100%' }}>
        <Col span={24}>
          <InsightsFilters
            insightType={insightType}
            classesData={classesData?.getClasses}
            loadingClasses={loadingClasses}
            loadingStudents={loadingStudents}
            studentsData={studentsData}
            setSelectedClassId={handleClassChange}
            selectedClassId={selectedClassId}
            setSelectedStudentId={setSelectedStudentId}
            selectedStudentId={selectedStudentId}
            insightsData={insightsData}
            selectedInvestigationIds={selectedInvestigationIds}
            setSelectedInvestigationIds={handleInvestigationSelection}
            comparingInvestigations={comparingInvestigations}
            setComparingInvestigations={setComparingInvestigations}
            canCompareInvestigations={selectedInvestigationIds?.length > 1}
            onChangeFilterData={setFilterData}
            onExportTempranoData={onExportClassData}
          />
        </Col>
        <Col lg={18} md={24}>
          <InsightsTable loading={loadingClasses || loadingStudents || loading} data={insightsData} />
        </Col>
        <Col lg={6} md={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <AverageScoreGauge loading={loading} type="report" data={comparingInsightsData} />
            </Col>
            <Col span={24}>
              <AverageScoreGauge loading={loading} type="engagement" data={comparingInsightsData} />
            </Col>
          </Row>
        </Col>
        <Row style={{ width: '100%' }} gutter={[24, 24]}>
          <Col md={24} lg={12}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <ScoreDifference data={comparingInsightsData} loading={loading} type="report" />
              </Col>
              <Col span={24}>
                <LineChartWithBackground
                  title="Report Score Over Time"
                  maxY={57}
                  data={[
                    {
                      id: 'scores',
                      data:
                        comparingInsightsData?.map((data, index) => ({
                          x: (index + 1).toString(),
                          y: Math.round(data.reportScore),
                          tooltip: data.investigationTitle,
                        })) || [],
                    },
                  ]}
                  legendAxisBottom="Investigation"
                  legendAxisLeft="Report Score"
                  extraLayerLine={{
                    percentage: 67,
                    label: 'Meets Expectations',
                  }}
                  backgroundRanges={[
                    {
                      background: scoresPercentagesColors.meetsExpectations,
                      min: 0,
                      max: (38 / 57) * 100,
                      title: 'Approaching Expectations',
                    },
                    {
                      background: scoresPercentagesColors.exceedsExpectations,
                      min: (38 / 57) * 100,
                      max: 100,
                      title: 'Exceeding Expectations',
                    },
                  ]}
                  xCircleColors={palette}
                />
              </Col>
            </Row>
          </Col>
          <Col md={24} lg={12}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <ScoreDifference data={comparingInsightsData} loading={loading} type="engagement" />
              </Col>
              <Col span={24}>
                <LineChartWithBackground
                  title="Engagement Score Over Time"
                  maxY={5}
                  data={[
                    {
                      id: 'scores',
                      data:
                        comparingInsightsData?.map((data, index) => ({
                          x: (index + 1).toString(),
                          y: Math.round(data.engagementAverage * 5),
                          tooltip: data.investigationTitle,
                        })) || [],
                    },
                  ]}
                  legendAxisBottom="Investigation"
                  legendAxisLeft="Engagement Score"
                  backgroundRanges={[
                    {
                      background: scoresPercentagesColors.needsImprovement,
                      min: 0,
                      max: (2 / 5) * 100,
                      title: 'Limited Engagement',
                    },
                    {
                      background: scoresPercentagesColors.meetsExpectations,
                      min: (2 / 5) * 100,
                      max: (4 / 5) * 100,
                      title: 'Moderate Engagement',
                    },
                    {
                      background: scoresPercentagesColors.exceedsExpectations,
                      min: (4 / 5) * 100,
                      max: (5 / 5) * 100,
                      title: 'High Engagement',
                    },
                  ]}
                  xCircleColors={palette}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </S.Container>
  );
};

export default withRouter(TeacherInvestigationInsights);
