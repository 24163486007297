import React, { useCallback, useState } from 'react';
import { Row, Col, Tooltip, message } from 'antd';
import * as S from './styles';
import { FiHelpCircle } from 'react-icons/fi';
import moment from 'moment/moment';
import Card from './Card';
import { formatDateTime } from '../../../utils/date';
import { useQuery } from '@apollo/client';
import { GQL_SubscriptionInsightsInput, GQL_SubscriptionInsightsResponse } from '../../../types/charts';
import { gqlSchema } from '../../../gql/schema';

const defaultDateRange = {
  startDate: formatDateTime(new Date().setDate(new Date().getDate() - 90)),
};

enum SubscriptionInsightOption {
  STARTER = 'Starter',
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise',
  PREMIUM = 'Premium',
}

const useSubscriptionInsights = (option: SubscriptionInsightOption, from: string) => {
  const { data, loading } = useQuery<
    { getSubscriptionInsightsChart: GQL_SubscriptionInsightsResponse },
    GQL_SubscriptionInsightsInput
  >(gqlSchema.ChartSchema.queries.CHARTS.getSubscriptionInsightsChart, {
    variables: {
      option: option,
      from: moment(from).format('MM.DD.YYYY'),
    },
    onError: (err) => {
      message.error('There was an error loading subscription insights: ' + err.message || 'Unexpected Error');
    },
  });

  return { data: data?.getSubscriptionInsightsChart, loading };
};

const SubscriptionInsights = () => {
  const [durationActiveUsers, setDurationActiveUsers] = useState<Date | null>(new Date(defaultDateRange.startDate));
  const disabledDate = useCallback((current: moment.Moment) => {
    return current >= moment().endOf('day');
  }, []);

  const { data: starterData, loading: starterLoading } = useSubscriptionInsights(
    SubscriptionInsightOption.STARTER,
    durationActiveUsers?.toString() || '',
  );
  const { data: enterpriseData, loading: enterpriseLoading } = useSubscriptionInsights(
    SubscriptionInsightOption.ENTERPRISE,
    durationActiveUsers?.toString() || '',
  );
  const { data: proData, loading: proLoading } = useSubscriptionInsights(
    SubscriptionInsightOption.PRO,
    durationActiveUsers?.toString() || '',
  );

  const { data: premiumData, loading: premiumLoading } = useSubscriptionInsights(
    SubscriptionInsightOption.PREMIUM,
    durationActiveUsers?.toString() || '',
  );

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <S.Title>Subscriptions</S.Title>
          <Tooltip title="Subscriptions based on type">
            <FiHelpCircle style={{ marginLeft: 5 }} />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Row>
            <Col xxl={5} xl={7} lg={9} md={24}>
              From:{' '}
              <S.DatePicker
                data-cy="shared-charts-activeusers-datepicker-activeusers"
                onChange={(moment: moment.Moment | null, dateString: string) =>
                  setDurationActiveUsers(moment?.toDate() || null)
                }
                inputReadOnly
                disabledDate={disabledDate}
                defaultValue={moment(durationActiveUsers)}
                format={'MM.DD.YYYY'}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <Card
            title={'Starter Plans'}
            total={starterData?.totalSubscriptions || 0}
            change={starterData?.totalSubscriptionsFromDate || 0}
            loading={starterLoading}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Card
            title={'Organization Plans'}
            total={enterpriseData?.totalSubscriptions || 0}
            change={enterpriseData?.totalSubscriptionsFromDate || 0}
            loading={enterpriseLoading}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Card
            title={'Pro Plans'}
            total={proData?.totalSubscriptions || 0}
            change={proData?.totalSubscriptionsFromDate || 0}
            loading={proLoading}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Card
            title={'Premium Plans'}
            total={premiumData?.totalSubscriptions || 0}
            change={premiumData?.totalSubscriptionsFromDate || 0}
            loading={premiumLoading}
          />
        </Col>
      </Row>
    </>
  );
};

export default SubscriptionInsights;
