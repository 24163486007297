import React from 'react';
import * as S from './styles';

const BookCardSkeleton: React.FC = () => {
  return (
    <S.BookCard>
      <S.ImageWrapper />

      <div style={{ height: '18%',display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div
          style={{
            width: '90%',
            height: '33.33%',
            backgroundColor: '#e0e0e0',
            margin: '6px auto',
            borderRadius: '4px',
          }}
        />
        <div
          style={{
            width: '85%',
            height: '33.33%',
            backgroundColor: '#e0e0e0',
            margin: '6px auto',
            borderRadius: '4px',
          }}
        />
        <div
          style={{
            width: '75%',
            height: '33.33%',
            backgroundColor: '#e0e0e0',
            margin: '6px auto 10px',
            borderRadius: '4px',
          }}
        />
      </div>

      <div
        style={{
          width: '100%',
          height: '12%',
          backgroundColor: '#e0e0e0',
          borderRadius: '4px',
        }}
      />
    </S.BookCard>
  );
};
interface LibrarySkeletonProps {
  row:number
} 
const LibrarySkeleton: React.FC<LibrarySkeletonProps> = ({row}) => {
  return (
    <S.Container>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', columnGap: '64px', rowGap: '48px',justifyItems: 'center',height: '100vh' }}>
        {Array.from({ length: 4 *(row) }).map((_, index) => (
          <React.Fragment key={index}>
            <BookCardSkeleton />
          </React.Fragment>
        ))}
      </div>

      
    </S.Container>
  );
};

export default LibrarySkeleton;
