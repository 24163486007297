import styled from 'styled-components';

export const ModalTitle = styled.h1`
  text-align: left;
  margin-bottom: 15px;
  font-weight: 600;
`;

export const ModalText = styled.p`
  text-align: left;
  font-weight: 500;
  font-size: 14px;
`;

export const ModalLoading = styled.div`
  width: 100%;
  display: inline-grid;
  height: 50px;
`;
