enum StudentPerformanceLevels {
  NOT_SUBMITTED_OR_GRADED = 'notSubmittedOrGraded',
  BELOW = 'below',
  APPROACHING = 'approaching',
  MEETS = 'meets',
}

interface StudentPerformanceLevelInfo {
  key: StudentPerformanceLevels;
  name: string;
  color: string;
}

export const studentPerformanceLevels: Record<StudentPerformanceLevels, StudentPerformanceLevelInfo> = {
  [StudentPerformanceLevels.NOT_SUBMITTED_OR_GRADED]: {
    key: StudentPerformanceLevels.NOT_SUBMITTED_OR_GRADED,
    name: 'Not Submitted or Graded',
    color: '#A5A5A5',
  },
  [StudentPerformanceLevels.BELOW]: {
    key: StudentPerformanceLevels.BELOW,
    name: 'Below Expectations',
    color: '#FF0000',
  },
  [StudentPerformanceLevels.APPROACHING]: {
    key: StudentPerformanceLevels.APPROACHING,
    name: 'Approaching Expectations',
    color: '#FFBE00',
  },
  [StudentPerformanceLevels.MEETS]: {
    key: StudentPerformanceLevels.MEETS,
    name: 'Meets Expectations',
    color: '#71AD47',
  },
};

export const studentPerformanceLevelsByStandards: Record<StudentPerformanceLevels, StudentPerformanceLevelInfo> = {
  [StudentPerformanceLevels.NOT_SUBMITTED_OR_GRADED]: {
    key: StudentPerformanceLevels.NOT_SUBMITTED_OR_GRADED,
    name: 'Standard Not Yet Assessed',
    color: '#A5A5A5',
  },
  [StudentPerformanceLevels.BELOW]: {
    key: StudentPerformanceLevels.BELOW,
    name: 'Below Expectations',
    color: '#FF0000',
  },
  [StudentPerformanceLevels.APPROACHING]: {
    key: StudentPerformanceLevels.APPROACHING,
    name: 'Approaching Expectations',
    color: '#FFBE00',
  },
  [StudentPerformanceLevels.MEETS]: {
    key: StudentPerformanceLevels.MEETS,
    name: 'Meets Expectations',
    color: '#71AD47',
  },
};
