import React, { ReactElement, useCallback } from 'react';
import { Row, Col } from 'antd';
import * as S from './styles';
import { FiUsers, FiBook } from 'react-icons/fi';
import { GQL_ClassResponse } from '../../../../types/class';
import { useHistory } from 'react-router-dom';
import { formatDateTime } from '../../../../utils/date';
import RoundedTag from '../../../../shared/RoundedTag';
import { themeConfig } from '../../../../utils/theme';
interface Props {
  classObj: GQL_ClassResponse;
  icon: ReactElement;
}

const Item: React.FC<Props> = (props) => {
  const { classObj, icon } = props;
  const history = useHistory();

  const goToDashboard = useCallback(() => {
    history.push('/student-dashboard/class/' + classObj.id, {
      className: classObj.name,
    });
  }, [classObj, history]);

  const assessmentLength = classObj.investigations?.filter((investigation) => investigation.isAssessment).length;
  const investigationLength = classObj.investigations?.filter((investigation) => !investigation.isAssessment).length;

  return (
    <S.Card>
      <Row gutter={[0, 16]}>
        <Col span={10}>
          <S.MainIconWrapper>{icon}</S.MainIconWrapper>
        </Col>
        <Col span={14}>
          <Row>
            <Col span={24}>
              <S.Info $bold $align="right" $fontSize="0.88em">
                {classObj.status}
              </S.Info>
            </Col>
            <Col span={24}>
              <S.Info $align="right">{`${formatDateTime(classObj.startDate)} - ${formatDateTime(
                classObj.endDate,
              )}`}</S.Info>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'left', marginBottom: 10 }}>
          <RoundedTag color={classObj.colorHex} text={classObj.name} />
        </Col>
      </Row>
      <Row justify="center">
        <Col xl={6} lg={12} md={24} sm={24}>
          <S.Info $fontSize="0.85em">
            <FiUsers className="icon" />
            <span role="none" >{classObj.numberOfStudents} Students</span>
          </S.Info>
        </Col>
        <Col xl={9} lg={12} md={24} sm={24}>
          <S.Info $fontSize="0.85em">
            <FiBook className="icon" />
            <span role="none" >{`${investigationLength || 0} ${
              investigationLength === 1 ? 'Investigation' : 'Investigations'
            }`}</span>
          </S.Info>
        </Col>
        <Col xl={9} lg={12} md={24} sm={24}>
          <S.Info $fontSize="0.85em">
            <FiBook className="icon" />
            <span role="none" >{`${assessmentLength || 0} ${assessmentLength === 1 ? 'Assessment' : 'Assessments'}`}</span>
          </S.Info>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={16} style={{ marginTop: '2em' }}>
          <S.DashboardButton
            data-cy="shared-studentdashboardrows-class-dashboard"
            text="Class Dashboard"
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
