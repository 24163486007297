import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

export const RowSP = styled(Row)`
  height: 100%;
`;

export const ColSP = styled(Col)<{
  $padding?: string;
}>`
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 15px -3px;
  background: #ffffff;
  border-radius: 5px;
  padding: ${(props) => (props.$padding ? props.$padding : '5em')};
`;
