import { Row } from 'antd';
import styled from 'styled-components';

export const ImageContainer = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #c6c6c6;
  margin-right: 10px;
`;

export const NameText = styled.p`
  margin: 0;
  font-weight: bold;
  color: black;
  font-size: 14px;
  max-width: calc(100% - 50px);
`;

export const Container = styled.div<{ highlighted?: boolean; highlightReport?: boolean }>`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;

  ${(props) =>
    props.highlighted
      ? 'box-shadow: 0px 0px 12px rgba(54, 107, 223, 0.79)'
      : 'box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07)'};
  ${(props) => props.highlightReport && 'box-shadow: 0px 0px 12px rgba(234, 53, 53, 0.3);'}
  border-radius: 6px;
  padding: 20px;
  width: 100%;

  & > p {
    font-size: 13px;
    line-height: 1.3;
    margin: 0;
  }
`;

export const HeaderContainer = styled(Row)`
  margin-bottom: 15px;
  width: 100%;
`;
