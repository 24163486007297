/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Skeleton, Spin } from 'antd';
import { CardContainer } from '../InvestigationCard/styles';
import * as S from './styles';

interface CustomInvestigationLoadingProps {
  isAssessment?: boolean;
}

const InvestigationMessages = [
  'Loading investigation list...',
  'Investigation list is still loading...',
  'Almost there!',
];

const AssessmentMessages = ['Loading assessment list...', 'Assessment list is still loading...', 'Almost there!'];

const CustomInvestigationLoading: React.FC<CustomInvestigationLoadingProps> = (props) => {
  const { isAssessment = false } = props;
  const messagesList = useMemo(() => {
    return isAssessment ? AssessmentMessages : InvestigationMessages;
  }, [isAssessment]);

  const [loadingMessage, setLoadingMessage] = useState(messagesList[0]);
  const [loadingTime, setLoadingTime] = useState(0);

  useEffect(() => {
    const firstInterval = setTimeout(() => {
      setLoadingMessage(messagesList[1]);
      setLoadingTime(10);
    }, 10000); // 10 seconds

    const secondInterval = setTimeout(() => {
      setLoadingMessage(messagesList[2]);
      setLoadingTime(35);
    }, 35000); // 35 seconds (10 + 25)

    return () => {
      clearTimeout(firstInterval);
      clearTimeout(secondInterval);
    };
  }, [messagesList]);

  useEffect(() => {
    const thirdInterval = setTimeout(() => {
      setLoadingMessage(messagesList[2]);

      setLoadingTime(50);
    }, 50000); // 50 seconds (10 + 25 + 15)

    return () => {
      clearTimeout(thirdInterval);
    };
  }, [loadingTime, messagesList]);

  return (
    <Row gutter={[24, 24]} style={{ position: 'relative' }}>
      <S.LoadingLibrary>
        <div>
          <Spin size="large" />
        </div>
        <div>{loadingMessage}</div>
      </S.LoadingLibrary>
      {Array(9)
        .fill(1)
        .map((_, i) => (
          <Col lg={8} md={12} xs={24} key={i}>
            <CardContainer>
              <span role="none" >
                <Skeleton.Input active size="small" />
              </span>
              <div>
                <Skeleton.Avatar active />
              </div>
              <h1>
                <Skeleton.Input active size="large" />
              </h1>

              <Skeleton.Input active />
              <br></br>
              <h1>
                <Skeleton.Button active />
              </h1>
            </CardContainer>
          </Col>
        ))}
    </Row>
  );
};

export default CustomInvestigationLoading;
