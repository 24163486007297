import { Radio } from 'antd';
import React, { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import Editor from '../../../shared/Editor';
import Spacer from '../../../shared/Spacer';
import * as S from './styles';
import { IQuestionnaire } from '../../../redux/modules/investigation';
import { assessmentScoreTextMap, assessmentReasonTextMap } from '../../AssessmentGradingPage';
import { GQL_InvestigationBlock, GQL_ScoreType } from '../../../types/investigation';
import { useAuth } from '../../../hooks/useAuth';

export interface QuestionnaireActivity {
  assessmentScore?: number;
  reason?: number;
  feedback: string;
}

export interface QuestionnaireForm {
  scoreFeedback?: GQL_ScoreType;
  activities: QuestionnaireActivity[];
}

interface IAssessmentQuestionnaire {
  activityBlocks?: GQL_InvestigationBlock[];
  values?: QuestionnaireForm;
  onChangeForm: (e: any) => void;
  activityIndex: number;
  formValidation: boolean;
  questionnaire?: IQuestionnaire;
  listenToValueChange?: boolean;
  studentId?: string;
}

const blocksCheck = [
  'MultipleChoiceQuestion',
  'MultipleChoicePictureQuestion',
  'CategoryDrag',
  'ProcessDragDropAnswer',
];

const AssessmentQuestionnaire = (props: IAssessmentQuestionnaire) => {
  const { values, onChangeForm, activityIndex, formValidation, listenToValueChange, activityBlocks, studentId } = props;
  const { isStudent, user } = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';

  const onChangeActivity = (v: string | number, key: string) => {
    if (isStudent|| isGoogleStudent || isCanvasStudent) return;
    const activities = values?.activities || [];
    activities[activityIndex] = {
      ...activities?.[activityIndex],
      [key]: v,
    };

    const formValues = {
      ...values,
      activities,
    };
    onChangeForm(formValues);
  };

  useEffect(() => {
    const blocks = activityBlocks?.filter((item) => blocksCheck.includes(item.type) && studentId === item.userId);

    if (!values?.scoreFeedback) {
      blocks?.forEach((block: any) => {
        let rightAnswer = false;
        if (block.type === 'MultipleChoiceQuestion' || block.type === 'MultipleChoicePictureQuestion') {
          rightAnswer = !!block?.values?.filter((option: any) => option.selectedAnswer && option.targetAnswer)[0];
        } else if (block.type === 'ProcessDragDropAnswer') {
          rightAnswer = isEqual(block?.values?.[0]?.answers[0]?.value, block?.values?.[0]?.targets?.[0]?.values);
        } else if (block.type === 'CategoryDrag') {
          rightAnswer = block?.values?.[0]?.answers?.every((answer: any, answerIndex: number) =>
            isEqual(sortBy(answer?.value), sortBy(block.values?.[0]?.targets?.[answerIndex]?.values)),
          );
        }
        onChangeActivity(rightAnswer ? 1 : 0, 'assessmentScore');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityBlocks, studentId]);

  return (
    <>
      <S.Column span={24}>
        {!(isStudent || isGoogleStudent || isCanvasStudent) ? (
          <>
            <S.QuestionTitle>Information for Student</S.QuestionTitle>
            <Spacer size={10} />
          </>
        ) : null}
        <S.QuestionContainer>
          {!(isStudent || isGoogleStudent || isCanvasStudent) ? (
            <S.RowSubQuestion $invalid={!values?.scoreFeedback && formValidation}>
              <S.RadioColumn span={24}>
                <Radio.Group
                  value={values?.scoreFeedback}
                  onChange={(e) =>
                    onChangeForm({
                      ...values,
                      scoreFeedback: e.target.value,
                    })
                  }
                >
                  <Radio value={GQL_ScoreType.SCORE_FEEDBACK}>Score with Feedback</Radio>
                  <Radio value={GQL_ScoreType.SCORE_ONLY}>Score only</Radio>
                  <Radio value={GQL_ScoreType.FEEDBACK_ONLY}>Feedback Only</Radio>
                </Radio.Group>
              </S.RadioColumn>
            </S.RowSubQuestion>
          ) : null}

          <Spacer size={20} />
          {(isStudent || isGoogleStudent || isCanvasStudent) && values?.scoreFeedback === GQL_ScoreType.FEEDBACK_ONLY ? null : (
            <>
              <S.QuestionTitle>Assessment score</S.QuestionTitle>
              <Spacer size={10} />
              <S.RowSubQuestion
                $invalid={
                  values?.activities?.[activityIndex]?.assessmentScore === null &&
                  formValidation &&
                  (values?.scoreFeedback === GQL_ScoreType.SCORE_FEEDBACK ||
                    values?.scoreFeedback === GQL_ScoreType.SCORE_ONLY)
                }
              >
                Answer
                <S.RadioColumn span={24}>
                  <Radio.Group
                    disabled={isStudent || isGoogleStudent || isCanvasStudent}
                    value={values?.activities?.[activityIndex]?.assessmentScore}
                    onChange={(e) => onChangeActivity(e.target.value, 'assessmentScore')}
                  >
                    {Object.entries(assessmentScoreTextMap).map(([key, value]) => (
                      <Radio key={key} value={value}>
                        {key}
                      </Radio>
                    ))}
                  </Radio.Group>
                </S.RadioColumn>
              </S.RowSubQuestion>
              <Spacer size={15} />
              <S.RowSubQuestion
                $invalid={
                  values?.activities?.[activityIndex]?.reason === null &&
                  formValidation &&
                  (values?.scoreFeedback === GQL_ScoreType.SCORE_FEEDBACK ||
                    values?.scoreFeedback === GQL_ScoreType.SCORE_ONLY)
                }
              >
                Reason
                <S.RadioColumn span={24}>
                  <Radio.Group
                    disabled={isStudent || isGoogleStudent || isCanvasStudent}
                    value={values?.activities?.[activityIndex]?.reason}
                    onChange={(e) => onChangeActivity(e.target.value, 'reason')}
                  >
                    {Object.entries(assessmentReasonTextMap).map(([key, value]) => (
                      <Radio key={key} value={value}>
                        {key}
                      </Radio>
                    ))}
                  </Radio.Group>
                </S.RadioColumn>
              </S.RowSubQuestion>
              <Spacer size={20} />
            </>
          )}
          <S.QuestionTitle>Assessment Feedback</S.QuestionTitle>
          <Spacer size={15} />
          <S.FeedbackContainer $editable={(!isStudent && !isGoogleStudent && !isCanvasStudent)}>
            <Editor
              placeholder="Write Your Comment Here..."
              listenToValue={listenToValueChange}
              value={values?.activities?.[activityIndex]?.feedback || ''}
              onChange={(v) => onChangeActivity(v.value, 'feedback')}
              editable={(!isStudent && !isGoogleStudent && !isCanvasStudent)}
            />
          </S.FeedbackContainer>
        </S.QuestionContainer>
        <S.RowSubQuestion $invalid={!values?.activities?.[activityIndex]?.feedback && formValidation} />
      </S.Column>
    </>
  );
};

export default AssessmentQuestionnaire;
