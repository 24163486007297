import { useLazyQuery } from '@apollo/client';
import { Col, message, Modal, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Papa from 'papaparse';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { gqlSchema } from '../../../../gql/schema';
import { GQL_UsedInvestigationsResponse } from '../../../../types/charts';
import { FilterData } from '../../../../types/investigation';
import { formatDateTime } from '../../../../utils/date';
import { downloadCsv } from '../../../../utils/files';
import Button from '../../../Button';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  startDate: string;
  endDate: string;
  filterData: FilterData;
}
const MostUsedInvestigationsDetail = (props: Props) => {
  const { visible, setVisible, startDate, endDate, filterData } = props;
  const [fetchMostUsedInvestigations, { data, loading }] = useLazyQuery<{
    getUsedInvestigations: GQL_UsedInvestigationsResponse[];
  }>(gqlSchema.ChartSchema.queries.CHARTS.getUsedInvestigations, {
    onError: (err) => {
      message.error('There was an error loading most used investigations: ' + err.message || 'Unexpected Error');
    },
  });
  useEffect(() => {
    const { tagIds, disciplineIds, ...data } = filterData;
    fetchMostUsedInvestigations({
      variables: { data: { startDate, endDate, ...data } },
    });
  }, [fetchMostUsedInvestigations, startDate, endDate, filterData]);

  const columns: ColumnsType<GQL_UsedInvestigationsResponse> = useMemo(() => {
    return [
      {
        title: 'Type',
        dataIndex: 'type',
        fixed: 'left',
        render: (text: string, record: GQL_UsedInvestigationsResponse, index: number) => {
          return record.type;
        },
      },
      {
        title: 'Investigation',
        dataIndex: 'title',
        fixed: 'left',
        render: (text: string, record: GQL_UsedInvestigationsResponse, index: number) => {
          return record.title;
        },
      },
      {
        title: 'Usage',
        width: 90,
        render: (text: string, record: GQL_UsedInvestigationsResponse) => record.count,
      },
    ];
  }, []);

  const exportUsers = useCallback(() => {
    const headers = [['Type', 'Investigations', 'Usage']];
    const csvData =
      data?.getUsedInvestigations.map((inv: GQL_UsedInvestigationsResponse) => {
        return [inv.type, inv.title, inv.count];
      }) || [];

    downloadCsv(
      Papa.unparse([...headers, ...csvData]),
      `Most Used Investigations from ${formatDateTime(startDate)} to ${endDate}.csv`,
    );
  }, [data, startDate, endDate]);

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(!visible)}
      footer={
        <Row justify="center">
          <Col span={8}>
            <Button
              data-cy="shared-charts-mostusedinvestigations-button-download"
              text="Download"
              onClick={exportUsers}
              block
            />
          </Col>
        </Row>
      }
      width={800}
    >
      <h2>Most Used Investigations</h2>
      <Space />
      <Table
        data-cy="shared-charts-mostusedinvestigations-details-table"
        columns={columns}
        bordered
        loading={loading}
        dataSource={data?.getUsedInvestigations}
        scroll={{ x: 'max-content' }}
        rowKey={(record) => record.title}
        sticky
      />
    </Modal>
  );
};

export default MostUsedInvestigationsDetail;
