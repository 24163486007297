import { Menu } from 'antd';
import styled from 'styled-components';
import Button from '../../../shared/Button';

export const CMenu = styled(Menu)`
  & > .subMenuDiscipline {
    max-height: 300px;
    overflow-y: auto;
  }
`;

export const CMenuItemWrapper = styled(Menu.Item)<{ disabled: boolean }>`
  padding: 0px;
  opacity: ${({ disabled }) => (disabled ? '.8' : '1')};
`;

export const CMenuItemLabel = styled.p`
  padding: 5px 12px;
  margin: 0px;
`;

export const TagButton = styled(Button)`
  display: inline-flex;
  margin: 2px;
`;

export const DownloadContainer = styled.div`
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  max-width: fit-content;
  color: #3f3e38;

  & > span {
    padding-left: 10px;
    padding-top: 2px;
    margin-right: 8px;
    font-size: 15px;
    font-weight: normal;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  & > svg {
    min-width: 18px;
    min-height: 18px;
    @media only screen and (max-width: 1200px) {
      margin-right: 8px;
    }
  }
`;

export const CompareContainer = styled.div`
  cursor: pointer;
  background: #7b8cea;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  max-width: fit-content;
  color: white;
  font-weight: 500;

  & > span {
    padding-left: 10px;
    padding-top: 2px;
    margin-right: 8px;
    font-size: 15px;
    font-weight: normal;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  & > svg {
    min-width: 18px;
    min-height: 18px;
    @media only screen and (max-width: 1200px) {
      margin-right: 8px;
    }
  }
`;
