import styled from 'styled-components';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export const StyledPanel = styled(Panel)`
  h1 {
    font-size: 16px;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125.1%;
    /* identical to box height, or 23px */

    color: #767676;
  }
`;
