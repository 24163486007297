import { Col, Row, Skeleton, Divider } from 'antd';
import React from 'react';
import { GQL_ClassResponse } from '../../../types/class';
import { formatDateTime } from '../../../utils/date';
import * as S from './styles';

interface IClassInfos {
  classInfo?: GQL_ClassResponse;
  loading: boolean;
}

const ClassInfos = (props: IClassInfos) => {
  const { classInfo } = props;
  const classFinished = new Date(classInfo?.endDate || '').getTime() > Date.now();
  return (
    <S.ClassInfosContainer data-cy="studentclassdetails-classinfos-container">
      <Row>
        <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <Row align="middle">
            <Col span={18}>
              <S.Title>Class Name</S.Title>
              {!classInfo ? (
                <Skeleton.Input active size="small" />
              ) : (
                <S.SInput disabled={true} bordered={false} defaultValue={classInfo.name} />
              )}
            </Col>
          </Row>
        </Col>
        <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <Row align="middle">
            <Col span={16}>
              <S.Title>Class Duration</S.Title>
              <S.SubText>
                {formatDateTime(classInfo?.startDate)} - {formatDateTime(classInfo?.endDate)}
              </S.SubText>
            </Col>
          </Row>
        </Col>
        <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <Col>
            <S.Title>Class Investigations</S.Title>
          </Col>
          <Col>
            {!classInfo ? (
              <Skeleton.Input active size="small" />
            ) : (
              <S.SubText>
                {classInfo.investigations.length}
                {classInfo.investigations.length === 1 ? ' Investigation' : ' Investigations'}
              </S.SubText>
            )}
          </Col>
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <Col>
            <S.Title>Class Owner</S.Title>
          </Col>
          <Col>
            <S.SubText>{`${classInfo?.teacherName} - ${classInfo?.teacherEmail}`}</S.SubText>
          </Col>
        </Col>
        <Col xxl={5} xl={5} lg={12} md={20} sm={20} xs={20}>
          <Col>
            <S.Title>Class Status</S.Title>
          </Col>
          <Col>
            <S.StatusText finished={classFinished}>{classFinished ? 'Ongoing' : 'Finished'}</S.StatusText>
          </Col>
        </Col>
      </Row>
    </S.ClassInfosContainer>
  );
};

export default ClassInfos;
