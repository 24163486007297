import { GQL_ScoreType } from '../../types/investigation';
import { QuestionnaireActivity, QuestionnaireForm } from '../StudentInvestigation/AssessmentQuestionnaire';

export const questionnaireFormValidation = (questionnaireForm?: QuestionnaireForm) => {
  if (!questionnaireForm) {
    return 0;
  }

  const { scoreFeedback, activities } = questionnaireForm;

  if (!scoreFeedback || !activities) {
    return 0;
  }

  return activities.findIndex((activity) => isActivityInvalid(scoreFeedback, activity));
};

export const isCurrentActivityValid = (
  scoreFeedback: GQL_ScoreType | undefined | null,
  activity: QuestionnaireActivity,
) => {
  if (scoreFeedback === undefined || scoreFeedback === null) {
    return false;
  }

  return !isActivityInvalid(scoreFeedback, activity);
};

const isActivityInvalid = (scoreFeedback: GQL_ScoreType, activity: QuestionnaireActivity) => {
  // If the scoreFeedback is SCORE_ONLY, then the feedback is optional
  const isFeedbackInvalid =
    scoreFeedback === GQL_ScoreType.SCORE_ONLY
      ? false
      : isNullOrUndefined(activity.feedback) || activity.feedback === '';

  return isNullOrUndefined(activity.assessmentScore) || isNullOrUndefined(activity.reason) || isFeedbackInvalid;
};

const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
};
