import React, { useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { useHistory } from 'react-router-dom';
import {
  GQL_InvestigationActivity,
  GQL_InvestigationBlock,
  ICategoryDragContentValue,
  IProcessDragDropAnswer,
  ITextContentValue,
} from '../../../../types/investigation';
import { Button } from 'antd';

interface AnswerBlock {
  userId?: string;
  userName?: string;
  answers?: string[];
}

interface Props {
  part: GQL_InvestigationActivity;
  item: GQL_InvestigationBlock;
  assessmentId: string;
  type: 'CategoryDrag' | 'ProcessDragDropAnswer';
  partIndex: number;
}
const GREEN_COLOR = '#20BC89';
const WRONG_COLOR = '#EA3535';

export const ProcessCategoryDragDropSummary = ({ part, item, assessmentId, type, partIndex }: Props) => {
  const history = useHistory();
  const textSubmissions = part.content[0].blocks.filter((item) => item.type === 'TextSubmit');
  const categoryDragDropBlocks = part.content[0].blocks.filter((item) => item.type === type);
  const allAnswers = categoryDragDropBlocks.map((student) => ({
    answers: (student?.values[0] as ICategoryDragContentValue).answers,
    userId: student.userId,
    userName: student.userName,
  }));
  const correctTargetAnswers = (item.values[0] as ICategoryDragContentValue)?.targets;

  const compareArrays = useCallback(
    (arr1?: string[], arr2?: string[]) => {
      if (type === 'ProcessDragDropAnswer') {
        return isEqual(arr1, arr2);
      }
      return isEqual(sortBy(arr1), sortBy(arr2));
    },
    [type],
  );

  const answers = useMemo(() => {
    const correct: AnswerBlock[] = [];
    const wrong: AnswerBlock[] = [];
    allAnswers.forEach((studentAnswers) => {
      if (
        studentAnswers.answers?.every((answer, answerIndex) =>
          compareArrays(answer.value, correctTargetAnswers?.[answerIndex].values),
        )
      ) {
        correct.push({ userId: studentAnswers.userId, userName: studentAnswers.userName });
      } else {
        wrong.push({
          userId: studentAnswers.userId,
          userName: studentAnswers.userName,
        });
      }
    });
    return { correct, wrong };
  }, [correctTargetAnswers, allAnswers, compareArrays]);

  const percentageFormatter = (value: number) => (value * 100).toFixed(2);

  const redirectToAnswer = (userId?: string) =>
    history.push(`/teacher-dashboard/assessment-details/${assessmentId}?student=${userId}&activity=${partIndex}`);

  const getUserReason = (userId?: string) => {
    if (userId) {
      const reason = textSubmissions?.find((item) => item.userId === userId);
      return (reason?.values?.[0] as ITextContentValue)?.text || null;
    }
    return null;
  };

  const scenarios = (item?.values?.[0] as IProcessDragDropAnswer)?.scenarios;

  return (
    <>
      <p className="question-label">{type === 'CategoryDrag' ? 'Question' : 'Directions'}</p>
      <p
        className="question-title"
        dangerouslySetInnerHTML={{
          __html:
            (item.values[0] as ICategoryDragContentValue)?.questionTitle ||
            (item.values[0] as IProcessDragDropAnswer)?.directions ||
            '',
        }}
      />
      {scenarios && <p className="question-label">Scenarios</p>}
      {scenarios && <p className="question-title" dangerouslySetInnerHTML={{ __html: scenarios }} />}
      <div className="answers-row">
        <div className="category-block" style={{ borderColor: GREEN_COLOR }}>
          <p style={{ color: GREEN_COLOR, fontWeight: 500 }}>Students who got all the answers correct</p>
          <p className="answers-percentage" style={{ color: GREEN_COLOR }}>
            Selected by {percentageFormatter(answers.correct.length / allAnswers.length)}%
          </p>
          {answers.correct.map(
            (item) =>
              item.userId && (
                <div className="answers-reason">
                  <p className="student">{item.userName}</p>
                  <p dangerouslySetInnerHTML={{ __html: getUserReason(item.userId) || '' }} />
                  <Button onClick={() => redirectToAnswer(item.userId)}>View Answer</Button>
                </div>
              ),
          )}
        </div>
        <div className="category-block" style={{ borderColor: WRONG_COLOR }}>
          <p style={{ color: WRONG_COLOR, fontWeight: 500 }}>Students who got wrong answers</p>
          <p className="answers-percentage" style={{ color: WRONG_COLOR }}>
            Selected by {percentageFormatter(answers.wrong.length / allAnswers.length)}%
          </p>
          {answers.wrong.map(
            (item) =>
              item.userId && (
                <div className="answers-reason">
                  <p className="student">{item.userName}</p>
                  <p dangerouslySetInnerHTML={{ __html: getUserReason(item.userId) || '' }} />
                  <Button onClick={() => redirectToAnswer(item.userId)}>View Answer</Button>
                </div>
              ),
          )}
        </div>
      </div>
    </>
  );
};
