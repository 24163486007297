import { useMutation } from '@apollo/client';
import React, { useState, useMemo } from 'react';
import { Col, message, Row, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import UploadInput from '../../../shared/UploadInput';
import Button from '../../../shared/Button';
import { gqlSchema } from '../../../gql/schema';
import { SUBJECT_TYPES } from '../../../types/subjects';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { GQL_AddCorrelationInput, GQL_Correlation } from '../../../types/advancedSearch.correlation';
import useFetchCorrelationFileUrl from '../../../hooks/useFetchCorrelationFileUrl';
import * as S from './styles';

interface ICSVFileUploadField {
  grade: string;
  currentVersion?: string;
}

const CSVFileUploadField: React.FC<ICSVFileUploadField> = (props) => {
  const [version, setVersion] = useState<string | null>(null);
  const { grade, currentVersion } = props;
  const reviewSiteVersion = currentVersion || '0';
  const versions = new Array(parseInt(reviewSiteVersion) + 1).fill(0);

  const { fileUrl, teacherManualUrl } = useFetchCorrelationFileUrl({
    gradeBand: grade,
    subject: SUBJECT_TYPES.SCIENCE_TEXAS_EDITION,
    version: version,
  });

  const [uploadCorrelation, { loading }] = useMutation<{
    uploadCorrelation: GQL_Correlation;
    data: GQL_AddCorrelationInput;
  }>(gqlSchema.AdvancedSearchCorrelationSchema.mutations.POST.addCorrelationFile, {
    onError: (error) => {
      message.error(error?.message || 'Failed to upload correlation file, please try again later.').then(() => {
        /* Empty function to suppress warning */
      });
    },
    refetchQueries: ['GetCorrelation'],
  });

  const [uploadManual, { loading: manualLoading }] = useMutation<{
    uploadManual: GQL_Correlation;
    data: GQL_AddCorrelationInput;
  }>(gqlSchema.AdvancedSearchCorrelationSchema.mutations.POST.addManualFile, {
    onError: (error) => {
      message.error(error?.message || 'Failed to upload teacher manual file, please try again later.').then(() => {
        /* Empty function to suppress warning */
      });
    },
    refetchQueries: ['GetCorrelation'],
  });

  const handleFileViewButtonClick = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleCorrelationFileUpload = (data: UploadRequestOption) => {
    uploadCorrelation({
      variables: {
        data: {
          gradeBand: grade,
          subject: SUBJECT_TYPES.SCIENCE_TEXAS_EDITION,
          file: data.file,
          version: version,
        },
      },
    }).then(() => {
      /* Empty function to suppress warning */
    });
  };

  const handleManualFileUpload = (data: UploadRequestOption) => {
    uploadManual({
      variables: {
        data: {
          gradeBand: grade,
          subject: SUBJECT_TYPES.SCIENCE_TEXAS_EDITION,
          file: data.file,
          version: version,
        },
      },
    }).then(() => {
      /* Empty function to suppress warning */
    });
  };

  const menuOverlay = useMemo(
    () => (
      <Menu>
        {versions.map((_, index) => (
          <Menu.Item onClick={() => setVersion(index === 0 ? null : index.toString())}>Version {index}</Menu.Item>
        ))}
      </Menu>
    ),
    [versions],
  );

  return (
    <Col span={24}>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <h3 style={{ marginBottom: '0px' }}>Grade {grade}</h3>
        </Col>

        <Col span={4}>
          <Row>
            <Dropdown overlay={menuOverlay} placement="bottomRight" trigger={['click']}>
              <S.DropVersion>
                Choose a version
                <DownOutlined />
              </S.DropVersion>
            </Dropdown>
          </Row>
          <Row>Version: {version || 0}</Row>
        </Col>

        <Col span={6}>
          <UploadInput
            fileList={[]}
            loading={loading}
            title="Upload correlation file"
            customRequest={handleCorrelationFileUpload}
          />
        </Col>

        <Col span={3}>
          {fileUrl.length > 0 ? <Button text="View file" onClick={() => handleFileViewButtonClick(fileUrl)} /> : null}
        </Col>

        <Col span={11}>{/* To push the content to new line */}</Col>
        <Col span={4}>{/* To line up content with other fields */}</Col>

        <Col span={6}>
          <UploadInput
            fileList={[]}
            loading={manualLoading}
            title="Upload teacher manual file"
            customRequest={handleManualFileUpload}
          />
        </Col>
        <Col span={3}>
          {teacherManualUrl.length > 0 ? (
            <Button text="View file" onClick={() => handleFileViewButtonClick(teacherManualUrl)} />
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

export default CSVFileUploadField;
