import { useQuery } from '@apollo/client';
import { Layout, message } from 'antd';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';
import InvestigationContent from '../../shared/InvestigationContent';
import InvestigationNotes from '../../shared/InvestigationNotes';
import { GQL_InvestigationActivity, GQL_InvestigationDisplay, GQL_InvestigationStep } from '../../types/investigation';
import InvestigationContentBuild from '../StudentInvestigation/InvestigationContentBuilder';
import InvestigationSettingsSider from './InvestigationSettingsSider';

type Props = RouteComponentProps<
  {
    classId?: string;
    type?: string;
    investigationId: string;
    submissionVersion?: string;
  },
  any,
  {
    backUrl?: string;
  }
>;

const InvestigationSettingsPage: React.FC<Props> = (props) => {
  const { investigationId, classId } = props.match.params;
  const { backUrl } = props.location.state || {};
  const history = useHistory();
  const { user } = useAuth();
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState<GQL_InvestigationStep>();
  const [currentActivity, setCurrentActivity] = useState<GQL_InvestigationActivity>();
  const submissionVersion = props.match.params.submissionVersion ? parseInt(props.match.params.submissionVersion) : 1;

  const { data } = useQuery<
    { getInvestigationByIdForTeacher: GQL_InvestigationDisplay },
    { investigationId: string; classId: string } | { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationByIdForTeacher, {
    variables: classId
      ? {
          investigationId: investigationId,
          classId: classId || '',
        }
      : { id: investigationId },
    onCompleted: (data) => {
      if (data && data.getInvestigationByIdForTeacher) {
        const investigation = data.getInvestigationByIdForTeacher;
        if (investigation.steps?.length && investigation.steps[0].activities?.length) {
          setCurrentActivity(investigation.steps[0].activities[0]);
          setCurrentStep(investigation.steps[0]);
        }
      }
    },
    onError: (err) => {
      message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
    },
  });

  const setCurrentStepByIndex = (index: number) => {
    if (investigation) {
      setCurrentActivity(investigation.steps[index]?.activities[0]);
      setCurrentStep(investigation.steps[index]);
    }
    setCurrentContentIndex(0);
  };

  const investigation = data?.getInvestigationByIdForTeacher;

  const currentStepIndex =
    investigation?.steps.findIndex((step) => step.activities.some((activity) => activity.id === currentActivity?.id)) ||
    0;

  const currentActivityIndex =
    investigation?.steps[currentStepIndex || 0]?.activities?.findIndex(
      (activity) => activity.id === currentActivity?.id,
    ) || 0;

  const onFinishSettings = () => {
    message.success('Investigation configured successfully');
    if (user.preferredRole === 'google_teacher') {
      history.push('/googleclassroom-teacher-dashboard');
    } else if (user.preferredRole === 'canvas_teacher') {
      history.push('/canvas-teacher-dashboard');
    } else if (backUrl) {
      history.push(backUrl);
    } else if (classId) {
      history.push('/teacher-dashboard/class/' + classId);
    } else {
      history.push('/teacher-dashboard');
    }
  };

  const setCurrentActivityByIndex = (index: number) => {
    if (investigation) {
      setCurrentActivity(investigation.steps[currentStepIndex].activities[index]);
    }
    setCurrentContentIndex(0);
  };

  return (
    <Layout>
      <InvestigationSettingsSider
        investigation={investigation}
        currentStepIndex={currentStepIndex}
        currentActivityIndex={currentActivityIndex}
        onFinishSettings={onFinishSettings}
        classId={classId}
        backUrl={backUrl}
        firstSetting={currentStepIndex === 0 && currentActivityIndex === 0}
        setCurrentStepByIndex={setCurrentStepByIndex}
        setCurrentActivityByIndex={setCurrentActivityByIndex}
      />
      <InvestigationContent>
        <InvestigationContentBuild
          submissionVersion={submissionVersion}
          currentContentIndex={currentContentIndex}
          currentActivity={currentActivity}
          currentStepId={currentStep?.id || ''}
          preview
          setCurrentContentIndex={setCurrentContentIndex}
          investigationId={investigation?.id}
          buildInPersonContent={investigation?.steps[currentStepIndex ?? 0]?.mode === 'INPERSON'}
        />
        <InvestigationNotes
          zIndex={1001}
          helpVideoUrl={currentActivity?.helpVideoUrl}
          teacherHelpVideoUrl={currentActivity?.teacherHelpVideoUrl}
          mode={investigation?.steps[currentStepIndex ?? 0]?.mode}
          {...({
            safety: currentActivity?.safety,
            help: currentActivity?.help,
            inPersonTeacher: currentActivity?.inPersonTeacher,
            remoteTeacher: currentActivity?.remoteTeacher,
            inPersonTeacherTips: currentActivity?.inPersonTeacherTips,
          } || {})}
        />
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(InvestigationSettingsPage);
