import React from 'react';
import * as S from './styles';
import MyLibrary from './MyLibrary';
import OtherBooks from './OtherBooks';

const Library: React.FC = () => {
  return (
    <S.Container>
      <MyLibrary />
      <OtherBooks />
    </S.Container>
  );
};

export default Library;
