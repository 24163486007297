import React, { useEffect, useMemo, useState } from 'react';
import * as S from './styles';
import PackageCard from './PackageCard';
import { Col, Modal, Row, message } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_PaymentProductCheckoutLink } from '../../types/payments';
import { SubscriptionPackageType } from '../../constants/enums/SubscriptionPackageType';
import { packageFeatures } from './packageFeatures';
import { useHistory } from 'react-router-dom';
import Button from '../../shared/Button';
import { themeConfig } from '../../utils/theme';
import { RxCross2 } from 'react-icons/rx';

const SubscriptionPackageSelectionPage = () => {
  const history = useHistory();
  const { subscriptionPackageType, isDowngradePending, fetchProfile } = useAuth();
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);

  // poll the API every 8 seconds
  // not using `pollInterval` as it doesn't work as intended for `useLazyQuery`
  useEffect(() => {
    const intervalId = fetchProfile ? window.setInterval(() => fetchProfile(), 8000) : null;

    return () => {
      if (intervalId) window.clearInterval(intervalId);
    };
  }, [fetchProfile]);

  const productCheckoutLinksList = useQuery<{
    getProductCheckoutLinks: GQL_PaymentProductCheckoutLink[];
  }>(gqlSchema.PaymentsSchema.queries.LIST.getProductCheckoutLinks, {
    onError: (error) => {
      message.error(error.message || 'There was an error while fetching the product details.');
    },
  });

  const checkoutLinks: {
    starter: string;
    pro: string;
    premium: string;
  } = useMemo(() => {
    const { data, loading } = productCheckoutLinksList;

    if (!loading && data?.getProductCheckoutLinks) {
      const starter = '/checkout-confirmation/starter';
      const pro = `/checkout-confirmation/${
        data?.getProductCheckoutLinks.find((p) => p.type === SubscriptionPackageType.PRO)?.id || ''
      }`;
      const premium = `/checkout-confirmation/${
        data?.getProductCheckoutLinks.find((p) => p.type === SubscriptionPackageType.PREMIUM)?.id || ''
      }`;

      return {
        starter,
        pro,
        premium,
      };
    } else {
      return {
        starter: '',
        pro: '',
        premium: '',
      };
    }
  }, [productCheckoutLinksList]);

  const closeOrganizationModal = () => {
    setIsOrganizationModalOpen(false);
  };

  return (
    <S.Container>
      <S.Title>Choose the best plan for you</S.Title>

      <Row justify="center" style={{ marginBottom: '40px' }}>
        <Button
          text="Looking for an Organization Plan?"
          theme={themeConfig.primaryOutlined}
          onClick={() => setIsOrganizationModalOpen(true)}
          style={{
            borderRadius: '34px',
            background: 'transparent',
            padding: '8px 16px',
            fontSize: '14px',
            height: 'fit-content',
          }}
        />
      </Row>

      <Row justify="center" gutter={[50, 24]}>
        {/* Free plan package card */}
        <Col span={8} style={{ maxWidth: '530px' }}>
          <PackageCard
            activePlan={subscriptionPackageType === SubscriptionPackageType.STARTER}
            title="Starter"
            subtitle="Free for educators, always."
            ctaText="Downgrade to Starter"
            action={() => history.push(checkoutLinks.starter)}
            content={packageFeatures.starter}
            downgradePending={isDowngradePending}
          />
        </Col>

        {/* Individual pro plan package card */}
        <Col span={8} style={{ maxWidth: '530px' }}>
          <PackageCard
            title="Pro"
            pillText="Starter + Upgrades"
            activePlan={subscriptionPackageType === SubscriptionPackageType.PRO}
            subtitle={`${packageFeatures.teacher.pro.yearly} (1 Year Subscription)`}
            ctaText={
              subscriptionPackageType === SubscriptionPackageType.PREMIUM ? 'Downgrade To Pro' : 'Upgrade To Pro'
            }
            action={() => history.push(checkoutLinks.pro)}
            content={packageFeatures.teacher.pro.features}
            downgradePending={isDowngradePending}
          />
        </Col>

        {/* Individual premium plan package card */}
        <Col span={8} style={{ maxWidth: '530px' }}>
          <PackageCard
            title="Premium"
            pillText="Pro + Upgrades"
            activePlan={subscriptionPackageType === SubscriptionPackageType.PREMIUM}
            subtitle={`${packageFeatures.teacher.premium.yearly} (1 Year Subscription)`}
            ctaText="Upgrade to Premium"
            action={() => history.push(checkoutLinks.premium)}
            content={packageFeatures.teacher.premium.features}
          />
        </Col>
      </Row>
      <Row>
        <S.Note>*Changes to your subscription may take 1 hour to update. The data will automatically refresh.</S.Note>
      </Row>

      <Modal
        centered
        footer={false}
        visible={isOrganizationModalOpen}
        onCancel={closeOrganizationModal}
        width="1280px"
        zIndex={1003}
        closeIcon={<RxCross2 size="1.25em" />}
      >
        <S.ModalBody>
          <S.ModalTitle>Organization Plans</S.ModalTitle>
          <S.ModalSubtitle>Choose the option that works best for your district or school</S.ModalSubtitle>

          <Row justify="center" gutter={[50, 24]}>
            <Col span={12}>
              <PackageCard
                title="Org"
                titleSuffix="(1 Year Subscription)"
                subtitle="Includes Administrator and Teacher Accounts"
                ctaText="Contact Us"
                action={() => window.open('https://share.hsforms.com/1-fMew_3gTyu6xehdKOSrLg5gbsq')}
                content={packageFeatures.enterprise.pro.features}
              />
            </Col>
            <Col span={12}>
              <PackageCard
                title="Org Plus"
                titleSuffix="(1 Year Subscription)"
                pillText="Org + Upgrades"
                subtitle="Includes Administrator and Teacher Accounts"
                ctaText="Contact Us"
                action={() => window.open('https://share.hsforms.com/1-fMew_3gTyu6xehdKOSrLg5gbsq')}
                content={packageFeatures.enterprise.premium.features}
              />
            </Col>
          </Row>
        </S.ModalBody>
      </Modal>
    </S.Container>
  );
};

export default SubscriptionPackageSelectionPage;
