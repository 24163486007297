import styled from 'styled-components';
import CButton from '../../../../shared/Button';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5em;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
`;

export const MainIconWrapper = styled.div`
  border-radius: 50%;
  background: ${(props) => props.theme.primaryColor.background};
  width: 55px;
  height: 55px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 2em;
  margin: 0.5em 0 0.9em 0;
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 5px 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const Icon = styled.p`
  font-size: 3.5em;
  margin-bottom: 0;
  text-align: center;
`;

export const Title = styled.h3<{
  $align?: string;
}>`
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  font-weight: 500;
  margin-bottom: 0;
`;

export const DashboardButton = styled(CButton)`
  font-weight: 500;
`;
