import { differenceInDays, differenceInHours, differenceInMinutes, format, formatDistanceToNow } from 'date-fns';

/**
 * Expects two epoch times and return an object with
 * days, hours and minutes of difference between first and second
 */
export const getDateDiff = (
  firstDate: number,
  secondDate: number,
): {
  days: number;
  hours: number;
  minutes: number;
} => {
  return {
    days: differenceInDays(firstDate, secondDate),
    hours: differenceInHours(firstDate, secondDate),
    minutes: differenceInMinutes(firstDate, secondDate),
  };
};

/**
 * Expects a string in format MM.DD.YYYY
 * Returns a string with format YYYY-MM-DD
 */
export const toDateFormat = (date?: string) => {
  if (!date) return '';
  const parts = date.split('.');

  if (parts.length !== 3) return date;

  return `${parts[0]}/${parts[1]}/${parts[2]}`;
};

/**
 * Expects a string in format YYYY-MM-DD
 * Returns a string with format MM.DD.YYYY
 */
export const formatDate = (date?: string) => {
  if (!date) return '';
  const parts = date.split('-');

  if (parts.length !== 3) return date;

  return `${parts[1]}.${parts[2]}.${parts[0]}`;
};

/**
 * Expects a date in string format YYYY-MM-DDTHH:mm:SSZ or a number date and optionaly a date format to return
 * Returns a string with format MM.dd.yyyy by default or the date  format provided
 */
export const formatDateTime = (date?: string | number, formatDate = 'MM.dd.yyyy') => {
  if (!date) return '';

  try {
    return format(new Date(date), formatDate);
  } catch (err) {
    return '';
  }
};

/**
 * Expects a date in string format YYYY-MM-DDTHH:mm:SSZ or a number date
 * Returns the distance between the given date and now in words.
 */
export const formatDistance = (date?: string | number) => {
  if (!date) return '';
  try {
    return formatDistanceToNow(new Date(date));
  } catch {
    return '';
  }
};

export const calculateTimeLeft = (due_date: string) => {
  //TODO: handle diffrent due_date formats
  if (!due_date) return ''// const assignment = courseWorkListData?.getCourseWorkList.find((cw: any) => cw.id === assignmentId);

  const dueDate = Date.parse(due_date);
  const currentTime = Date.now();
  const timeLeftValue = dueDate - currentTime;

  if (timeLeftValue <= 0) {
    return 'Finished';
  } else {
    const seconds = Math.floor((timeLeftValue / 1000) % 60);
    const minutes = Math.floor((timeLeftValue / (1000 * 60)) % 60);
    const hours = Math.floor((timeLeftValue / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeLeftValue / (1000 * 60 * 60 * 24));

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  }
}

export const formatTimestampToDateTime = (timestamp: string) => {

  const numericTimestamp = Number(timestamp);
  // Convert the timestamp to a Date object
  const date = new Date(numericTimestamp);

  // Extract parts of the date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format the date as dd-mm-yyyy HH:MM:SS
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};
