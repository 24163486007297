import { Col, Row, Layout, Menu } from 'antd';
import React, { useState, useCallback, useMemo } from 'react';
import ActiveUsers from '../../shared/Charts/ActiveUsers';
import MostUsedInvestigations from '../../shared/Charts/MostUsedInvestigations';
import InvestigationsAverageTime from '../../shared/Charts/InvestigationsAverageTime';
import * as S from './styles';
import Sider from '../../shared/Sider';
import InvestigationContent from '../../shared/InvestigationContent';
import { useAuth } from '../../hooks/useAuth';
import InvestigationAssignedRate from '../../shared/Charts/InvestigationAssignedRate';
import InvestigationUseTrend from '../../shared/Charts/InvestigationUseTrend';
import WorkshopParticipation from '../../shared/Charts/WorkshopParticipation';
import ExpiringSubscription from '../../shared/Charts/ExpiringSubscription';
import RoundedSidebarIcon from '../../shared/RoundedSidebarIcon';
import {
  FiArchive,
  FiAward,
  FiBarChart,
  FiBook,
  FiBookOpen,
  FiBriefcase,
  FiClipboard,
  FiLayout,
  FiTrendingUp,
  FiUsers,
} from 'react-icons/fi';
import { IoIosCalendar } from 'react-icons/io';
import StudentsScores from '../../shared/Charts/StudentsScores';
import InvestigationCompletion from '../../shared/Charts/InvestigationCompletion';
import ReportGainScore from '../../shared/Charts/ReportGainScore';
import StageCompletion from '../../shared/Charts/StageCompletion';
import SSOUsers from '../../shared/Charts/SSOUsers';
import FinishedInvestigationsByTeacher from '../../shared/Charts/FinishedInvestigationsByTeacher';
import OpenInvestigationsByTeacher from '../../shared/Charts/OpenInvestigationsByTeacher';
import SubscriptionInsights from '../../shared/Charts/SubscriptionInsights';

interface Props {
  title?: string;
}
export interface IKpiFilters {
  field: 'subject' | 'grade' | 'type';
  value: string;
}

const AdiDashboardPage = (props: Props) => {
  const [currentMenu, setCurrentMenu] = useState('1');

  const { isOrganizationAdiAdmin } = useAuth();
  

  const clickMenuHandler = useCallback((event) => {
    setCurrentMenu(event.key);
  }, []);

  const menuAdiAdminReports = useMemo(
    () => (
      <>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-activeusers"
          $disabled={currentMenu !== '1'}
          key="1"
          icon={<RoundedSidebarIcon selected={currentMenu === '1'} icon={<FiUsers />} />}
        >
          Active Users
        </S.MItem>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-ssousers"
          $disabled={currentMenu !== '2'}
          key="2"
          icon={<RoundedSidebarIcon selected={currentMenu === '2'} icon={<FiUsers />} />}
        >
          SSO New Users
        </S.MItem>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-subscriptions"
          $disabled={currentMenu !== '3'}
          key="3"
          icon={<RoundedSidebarIcon selected={currentMenu === '3'} icon={<IoIosCalendar />} />}
        >
          Expiring Subscriptions
        </S.MItem>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-usage"
          $disabled={currentMenu !== '4'}
          key="4"
          icon={<RoundedSidebarIcon selected={currentMenu === '4'} icon={<FiBook />} />}
        >
          Investigation Usage
        </S.MItem>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-investigation-rate"
          $disabled={currentMenu !== '5'}
          key="5"
          icon={<RoundedSidebarIcon selected={currentMenu === '5'} icon={<FiBookOpen />} />}
        >
          Investigation Assignment Rate
        </S.MItem>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-investigation-trend"
          $disabled={currentMenu !== '6'}
          key="6"
          icon={<RoundedSidebarIcon selected={currentMenu === '6'} icon={<FiTrendingUp />} />}
        >
          Investigation Assignment Trend
        </S.MItem>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-workshop-rate"
          $disabled={currentMenu !== '7'}
          key="7"
          icon={<RoundedSidebarIcon selected={currentMenu === '7'} icon={<FiClipboard />} />}
        >
          Workshop Assignment Rate
        </S.MItem>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-workshop-trend"
          $disabled={currentMenu !== '8'}
          key="8"
          icon={<RoundedSidebarIcon selected={currentMenu === '8'} icon={<FiBarChart />} />}
        >
          Workshop Assignment Trend
        </S.MItem>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-workshop-participation"
          $disabled={currentMenu !== '9'}
          key="9"
          icon={<RoundedSidebarIcon selected={currentMenu === '9'} icon={<FiBriefcase />} />}
        >
          Workshop Participation
        </S.MItem>
        <S.MItem
          data-cy="components-adidashboardpage-menuadi-sidebar-subscriptions"
          $disabled={currentMenu !== '10'}
          key="10"
          icon={<RoundedSidebarIcon selected={currentMenu === '10'} icon={<FiUsers />} />}
        >
          Subscriptions
        </S.MItem>
      </>
    ),
    [currentMenu],
  );

  const menuOrgAdminReports = useMemo(
    () => (
      <>
        <S.MItem
          $disabled={currentMenu !== '1'}
          key="1"
          icon={<RoundedSidebarIcon selected={currentMenu === '1'} icon={<FiUsers />} />}
        >
          Active Users
        </S.MItem>
        <S.MItem
          $disabled={currentMenu !== '2'}
          key="2"
          icon={<RoundedSidebarIcon selected={currentMenu === '2'} icon={<FiBook />} />}
        >
          Investigation Usage
        </S.MItem>
        <S.MItem
          $disabled={currentMenu !== '3'}
          key="3"
          icon={<RoundedSidebarIcon selected={currentMenu === '3'} icon={<FiBookOpen />} />}
        >
          Investigation Assignment Rate
        </S.MItem>
        <S.MItem
          $disabled={currentMenu !== '4'}
          key="4"
          icon={<RoundedSidebarIcon selected={currentMenu === '4'} icon={<FiTrendingUp />} />}
        >
          Investigation Assignment Trend
        </S.MItem>
        <S.MItem
          $disabled={currentMenu !== '5'}
          key="5"
          icon={<RoundedSidebarIcon selected={currentMenu === '5'} icon={<FiBarChart />} />}
        >
          Teacher Score Average
        </S.MItem>
        <S.MItem
          $disabled={currentMenu !== '6'}
          key="6"
          icon={<RoundedSidebarIcon selected={currentMenu === '6'} icon={<FiBriefcase />} />}
        >
          Investigation Completion
        </S.MItem>
        <S.MItem
          $disabled={currentMenu !== '7'}
          key="7"
          icon={<RoundedSidebarIcon selected={currentMenu === '7'} icon={<FiAward />} />}
        >
          Stage Completion Rate
        </S.MItem>
        <S.MItem
          $disabled={currentMenu !== '8'}
          key="8"
          icon={<RoundedSidebarIcon selected={currentMenu === '8'} icon={<FiClipboard />} />}
        >
          Report Gain Score
        </S.MItem>
        <S.MItem
          $disabled={currentMenu !== '9'}
          key="9"
          icon={<RoundedSidebarIcon selected={currentMenu === '9'} icon={<FiLayout />} />}
        >
          Current Investigations
        </S.MItem>
        <S.MItem
          $disabled={currentMenu !== '10'}
          key="10"
          icon={<RoundedSidebarIcon selected={currentMenu === '10'} icon={<FiArchive />} />}
        >
          Completed Investigations
        </S.MItem>
      </>
    ),
    [currentMenu],
  );

  const investigationUsageKpi = useMemo(
    () => (
      <Row gutter={[16, 56]}>
        <Col span={24}>
          <MostUsedInvestigations />
        </Col>
        <Col span={24}>
          <InvestigationsAverageTime />
        </Col>
      </Row>
    ),
    [],
  );

  const investigationAssignedRate = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <InvestigationAssignedRate title="Teacher Investigations Assignment Rate" />
        </Col>
      </Row>
    ),
    [],
  );

  const investigationAssignedTrend = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <InvestigationUseTrend title="Investigations Assignment Trend" />
        </Col>
      </Row>
    ),
    [],
  );

  const workshopAssignedRate = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <InvestigationAssignedRate title="Facilitator Workshops Assignment Rate" workshopOnly />
        </Col>
      </Row>
    ),
    [],
  );

  const workshopAssignedTrend = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <InvestigationUseTrend title="Workshop Assignment Trend" workshopOnly />
        </Col>
      </Row>
    ),
    [],
  );

  const studentsScore = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <StudentsScores />
        </Col>
      </Row>
    ),
    [],
  );

  const investigationCompletion = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <InvestigationCompletion />
        </Col>
      </Row>
    ),
    [],
  );

  const reportGainScore = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <ReportGainScore />
        </Col>
      </Row>
    ),
    [],
  );
  const stageCompletion = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <StageCompletion />
        </Col>
      </Row>
    ),
    [],
  );

  const finishedInvestigationsByTeacher = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <FinishedInvestigationsByTeacher />
        </Col>
      </Row>
    ),
    [],
  );
  const activenvestigationsByTeacher = useMemo(
    () => (
      <Row>
        <Col span={24}>
          <OpenInvestigationsByTeacher />
        </Col>
      </Row>
    ),
    [],
  );

  const adiAdminReports = useMemo(() => {
    switch (currentMenu) {
      case '1':
        return <ActiveUsers />;
      case '2':
        return <SSOUsers />;
      case '3':
        return <ExpiringSubscription />;
      case '4':
        return investigationUsageKpi;
      case '5':
        return investigationAssignedRate;
      case '6':
        return investigationAssignedTrend;
      case '7':
        return workshopAssignedRate;
      case '8':
        return workshopAssignedTrend;
      case '9':
        return <WorkshopParticipation />;
      case '10':
        return <SubscriptionInsights />;
    }
  }, [
    currentMenu,
    investigationUsageKpi,
    workshopAssignedRate,
    investigationAssignedRate,
    investigationAssignedTrend,
    workshopAssignedTrend,
  ]);

  const orgAdminReports = useMemo(() => {
    switch (currentMenu) {
      case '1':
        return <ActiveUsers />;
      case '2':
        return investigationUsageKpi;
      case '3':
        return investigationAssignedRate;
      case '4':
        return investigationAssignedTrend;
      case '5':
        return studentsScore;
      case '6':
        return investigationCompletion;
      case '7':
        return stageCompletion;
      case '8':
        return reportGainScore;
      case '9':
        return activenvestigationsByTeacher;
      case '10':
        return finishedInvestigationsByTeacher;
      default:
        return null;
    }
  }, [
    currentMenu,
    investigationAssignedRate,
    investigationAssignedTrend,
    investigationUsageKpi,
    studentsScore,
    reportGainScore,
    investigationCompletion,
    stageCompletion,
    finishedInvestigationsByTeacher,
    activenvestigationsByTeacher,
  ]);

  return (
    <Layout>
      <S.SliderWrapper className="slider-wrapper" style={{maxWidth:'340px'}}>
      <Sider title={<S.SidebarTitle>Reports</S.SidebarTitle>}>
        <div className='sidebar-wrapper'></div>
          
          <S.SidebarContainer>
          <Menu
            mode="inline"
            style={{ padding: '0 10px',height:'100%' }}
            defaultSelectedKeys={[currentMenu]}
            onClick={clickMenuHandler}
          >
            {isOrganizationAdiAdmin ? menuOrgAdminReports : menuAdiAdminReports}
          </Menu>
        </S.SidebarContainer>
      </Sider>
      </S.SliderWrapper>
      <InvestigationContent>
        <Row>
          <Col span={24}>{isOrganizationAdiAdmin ? orgAdminReports : adiAdminReports}</Col>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default AdiDashboardPage;
