import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as S from './styles';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import Button from '../../../shared/Button';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { Col, Row, Table, message, Popconfirm } from 'antd';
import { GQL_OrganizationAdminList, GQL_OrganizationDetailsResponse } from '../../../types/organization';
import ConfirmDeleteAdminMessage from '../ConfirmDeleteAdminMessage';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { centerAlign, Filter } from '../../../utils/antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import TableSearchBox from '../../../shared/TableSearchBox';
import AddAdminModal from '../AddAdminModal';
import { roles } from '../../../utils/roles';
import { GQL_UserDetailsResponse } from '../../../types/profile';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router';

interface Props {
  organization?: GQL_OrganizationDetailsResponse;
  refetchOrganization: () => void;
  teachers?: GQL_UserDetailsResponse[];
  refetchTeachers: () => void;
}

interface DeletionAdminConfirmation {
  admin: GQL_OrganizationAdminList;
  visible: boolean;
}

const TableBreakPoint: Breakpoint[] = ['lg'];

const OrganizationAdminList: React.FC<Props> = (props) => {
  const { organization, refetchOrganization, teachers, refetchTeachers } = props;
  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const [addAdminVisible, setAddAdminVisible] = useState(false);
  const [organizationAdmins, setOrganizationAdmins] = useState<GQL_OrganizationAdminList[]>([]);
  const user = useAuth();
  const refName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);
  const [deletionAdminConfirmationStatus, setDeletionAdminConfirmationStatus] = useState<DeletionAdminConfirmation[]>(
    [],
  );

  useEffect(() => {
    if (organization) {
      setDeletionAdminConfirmationStatus(
        organization.organizationAdmins.map((admin: GQL_OrganizationAdminList) => {
          return {
            admin,
            visible: false,
          };
        }),
      );
      setOrganizationAdmins(organization.organizationAdmins);
    }
  }, [organization]);

  const [submitunSetOrganizationAdmin] = useMutation(
    gqlSchema.OrganizationSchema.mutations.EDIT.unsetOrganizationAdmin,
    {
      onCompleted: ({ unsetOrganizationAdmin }: { unsetOrganizationAdmin: boolean }) => {
        if (unsetOrganizationAdmin) {
          refetchOrganization();
          refetchTeachers();
          message.success(`Organization Admin removed successfully`);
        } else message.error(`Error removing Organization Admin`);
      },
      onError: (err) => {
        message.error('There was an error setting organization admin: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const renderDeleteAdminButton = useCallback(
    (record: GQL_OrganizationAdminList, index: number) => {
      if (record.roles.length === 1 && record.roles[0] === 'organization_admin') {
        return (
          <ConfirmDeleteAdminMessage
            confirmMessageVisible={deletionAdminConfirmationStatus[index]?.visible || false}
            setConfirmMessageVisible={() => {
              if (organization) {
                setDeletionAdminConfirmationStatus(
                  organization.organizationAdmins.map((admin: GQL_OrganizationAdminList, i: number) => {
                    return {
                      admin,
                      visible: false,
                    };
                  }),
                );
              }
            }}
            onDelete={() => {
              submitunSetOrganizationAdmin({ variables: { userId: record.userId, deleteIfLastRole: true } });
            }}
            onSetTeacher={() => {
              if ((organization?.subscription.invitesAvailable || 0) >= (teachers?.length || 0) + 1)
                submitunSetOrganizationAdmin({ variables: { userId: record.userId, deleteIfLastRole: false } });
              else message.error('There are not more subscription slots available!');
            }}
          >
            <S.OkButton
              text="Remove Admin"
              block
              disabled={organization?.organizationAdmins.length === 1}
              onClick={() => {
                if (organization) {
                  setDeletionAdminConfirmationStatus(
                    organization.organizationAdmins.map((admin: GQL_OrganizationAdminList, i: number) => {
                      return {
                        admin,
                        visible: record.userId === admin.userId,
                      };
                    }),
                  );
                }
              }}
            />
          </ConfirmDeleteAdminMessage>
        );
      }
      return (
        <Popconfirm
          placement="topRight"
          title={
            <div style={{ textAlign: 'center' }}>
              <S.Info>You will unset this user’s as Organization Admin. Do you </S.Info>
              <S.Info>confirm that you want to perform this action?</S.Info>
            </div>
          }
          onConfirm={() =>
            submitunSetOrganizationAdmin({ variables: { userId: record.userId, deleteIfLastRole: false } })
          }
          okText="Confirm"
          cancelText="Cancel"
        >
          <S.OkButton text="Remove Admin" block disabled={organization?.organizationAdmins.length === 1} />
        </Popconfirm>
      );
    },
    [deletionAdminConfirmationStatus, organization, submitunSetOrganizationAdmin, teachers],
  );
  const history = useHistory();
  const renderViewMoreButton = useCallback(
    (record: GQL_OrganizationAdminList) => (
      <S.OkButton text="View  More Details" block onClick={() => history.push(`organization/user/${record.userId}`)} />
    ),
    [history],
  );
  const columns = useMemo(
    () => [
      {
        title: 'User Email',
        align: centerAlign,
        dataIndex: 'email',
        width: '20%',
        responsive: TableBreakPoint,
        sorter: (a: GQL_OrganizationAdminList, b: GQL_OrganizationAdminList) => a.email.localeCompare(b.email),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refEmail} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchEmailVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_OrganizationAdminList) => {
          if (!value) return true;
          return record.email.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Name',
        sorter: (a: GQL_OrganizationAdminList, b: GQL_OrganizationAdminList) => a.name.localeCompare(b.name),
        align: centerAlign,
        dataIndex: 'name',
        width: '20%',
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={refName} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchNameVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_OrganizationAdminList) => {
          if (!value) return true;
          return record.name.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Roles',
        dataIndex: 'roles',
        align: centerAlign,
        width: '25%',
        responsive: TableBreakPoint,
        render: (text: string | number | boolean, record: GQL_OrganizationAdminList) => {
          return record.roles.map((role: string) => {
            const roleItem = roles.find((r: Filter) => r.value === role);
            return (
              <S.TagButton
                text={roleItem?.text || ''}
                display="inline"
                background={roleItem?.color}
                shape="round"
                key={role}
                minHeight={24}
              />
            );
          });
        },
      },
      {
        title: 'Actions',
        align: centerAlign,
        render: (text: string, record: GQL_OrganizationAdminList, index: number) => {
          return user.isAdiSuperAdmin || user.isAdiAdmin
            ? renderDeleteAdminButton(record, index)
            : renderViewMoreButton(record);
        },
        width: '15%',
      },
    ],
    [searchEmailVisible, searchNameVisible, renderDeleteAdminButton, renderViewMoreButton, user],
  );

  const onSetOrganizationAdmin = useCallback(() => {
    refetchOrganization();
    refetchTeachers();
  }, [refetchOrganization, refetchTeachers]);

  return (
    <>
      <Row style={{ marginTop: 30 }}>
        <Col span={20}>
          <S.Title>Organization Admins</S.Title>
        </Col>
        {(user.isAdiSuperAdmin || user.isAdiAdmin) && (
          <Col md={24} lg={4}>
            <Button text="Add Organization Admin" onClick={() => setAddAdminVisible(true)} block icon={<FiPlus />} />
          </Col>
        )}
        <Col span={24}>
          <Row gutter={24}>
            <Col span={24}>
              <S.TableWrapper>
                <Table
                  columns={columns}
                  rowKey={(record: GQL_OrganizationAdminList) => record.userId}
                  bordered
                  dataSource={organizationAdmins}
                  pagination={{ hideOnSinglePage: true }}
                />
              </S.TableWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
      <AddAdminModal
        organization={organization}
        visibile={addAdminVisible}
        setVisible={setAddAdminVisible}
        onSetOrganizationAdmin={onSetOrganizationAdmin}
        refetchOrganization={refetchOrganization}
        teachers={teachers}
      />
    </>
  );
};

export default React.memo(OrganizationAdminList);
