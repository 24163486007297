import React from 'react';
import * as S from './styles';

interface Props {
  icon: React.ReactNode;
  selected: boolean;
}
const RoundedSidebarIcon: React.FC<Props> = (props) => {
  const { icon, selected } = props;
  return <S.Wrapper $selected={selected}>{icon}</S.Wrapper>;
};

export default RoundedSidebarIcon;
