import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { message, Modal, Button, Progress, Menu, Dropdown, Input, Spin } from 'antd';
import { LinkOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import UploadInput from '../../../../shared/UploadInput';
import * as S from './styles';
import { RcFile } from 'antd/lib/upload';
import { getProgressColor } from '../../../../utils/theme';
// import { useAuth } from '../../../../hooks/useAuth';

interface AssignmentSummaryPopupProps {
  courseId: string;
  assignmentId: string;
  visible: boolean;
  onClose: () => void;
  isFinished: boolean;
  score?: number;
  assignedGrade?: number;
  courseWorkData: any;
  courseWorkError: any;
  courseWorkLoading: boolean;
}

interface Material {
  link?: {
    url: string;
  };
  form?: {
    formUrl: string;
  };
}

const AssignmentSummaryPopup: React.FC<AssignmentSummaryPopupProps> = ({ courseId, assignmentId, visible, onClose, isFinished, score, courseWorkData, courseWorkError, courseWorkLoading, assignedGrade }) => {
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [formLinks, setFormLinks] = useState<string[]>([]);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [studentSubmissionLinks, setStudentSubmissionLinks] = useState<string[]>([]);
  const [turnedIn, setTurnedIn] = useState<boolean>(false);
  const [showSubmissionLinks, setShowSubmissionLinks] = useState<boolean>(false);
  const [turnInLoading, setTurnInLoading] = useState<boolean>(false); // State to track Turn In loading
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // const { user } = useAuth();
  // console.log(user);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    console.log('Resize detected:', window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (studentSubmissionLinks.length > 0 && !isFinished) {
      setShowSubmissionLinks(true);
    }
  }, [studentSubmissionLinks, isFinished]);


  console.log('isFinished:', isFinished, 'showSubmissionLinks:', showSubmissionLinks);

  // const { data: courseWorkData, error: courseWorkError, loading: courseWorkLoading } = useQuery(
  //   gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
  //   {
  //     variables: { courseId, id: assignmentId },
  //     onError: (err) => {
  //       message.error('Error fetching assignment data: ' + (err.message || 'Unexpected Error'));
  //     },
  //   }
  // );

  const { data: submissionListData, error: submissionListError, loading: submissionListLoading } = useQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionList,
    {
      variables: { data: [{ courseId: courseId, courseWorkId: assignmentId }] },
      onError: (err) => {
        message.error('Error fetching submission list: ' + (err.message || 'Unexpected Error'));
      },
    }
  );
  // const assignedGrade = submissionListData?.getCourseWorkSubmissionList?.[0]?.assignedGrade;
  console.log('assignedGrade of popup: ', assignedGrade)
  const { loading: submissionLoading, refetch: refetchSubmission } = useQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionById,
    {
      variables: { courseId, courseWorkId: assignmentId, id: submissionListData?.getCourseWorkSubmissionList?.[0]?.studentSubmissions?.[0]?.id || '' },
      onCompleted: (data) => {
        if (data && data.getCourseWorkSubmissionById) {
          const attachments = data.getCourseWorkSubmissionById.assignmentSubmission?.attachments || [];
          const links = attachments.map((attachment: any) => attachment.link?.url || '');
          setStudentSubmissionLinks(links);
          console.log('Student Submission Links:', links);

          if (data.getCourseWorkSubmissionById.state === 'TURNED_IN') {
            setTurnedIn(true);
            setShowSubmissionLinks(true);
          }
        } else {
          console.warn('No submission details found');
        }
      },

      onError: (err) => {
        message.error('Error fetching submission details: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const [turnInAssignment] = useMutation(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.TurnincourseWorkStudentSubmissions,
    {
      onCompleted: async () => {
        message.success('Assignment submitted successfully');
        await refetchSubmission();
        onClose();
        setTurnInLoading(false);
      },
      onError: (err) => {
        message.error('Error submitting assignment: ' + (err.message || 'Unexpected Error'));
        setTurnInLoading(false); // Reset loading state on error
      },
    }
  );

  const [modifySubmission] = useMutation(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.ModifyCourseWorkStudentSubmission,
    {
      onError: (err) => {
        message.error('Error modifying submission: ' + (err.message || 'Unexpected Error'));
      },
    }
  );

  const [uploadFileMutation] = useMutation(gqlSchema.GoogleClassroomSchema.mutations.GCLASS.UploadFile, {
    onError: (err) => {
      message.error('Error uploading file: ' + (err.message || 'Unexpected Error'));
    },
  });

  const [reclaimSubmission, { loading: reclaimLoading }] = useMutation(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.ReclaimCourseWorkStudentSubmissions,
    {
      onCompleted: async () => {
        message.success('Assignment reclaimed successfully');
        await refetchSubmission();
        onClose();
        setTurnInLoading(false);
      },
      onError: (err) => {
        message.error('Error reclaiming assignment: ' + (err.message || 'Unexpected Error'));
        setTurnInLoading(false); // Reset loading state on error
      },
    }
  );

  const handleSubmit = async () => {
    const submissionId = submissionListData?.getCourseWorkSubmissionList?.[0]?.studentSubmissions?.[0]?.id;
    if (!submissionId) {
      message.error('Submission ID not found');
      return;
    }

    try {
      // Prepare links to add to the submission
      const addAttachments = formLinks.map(link => ({
        link: { url: link }
      }));

      setTurnInLoading(true); // Set loading state when turning in assignment

      // Modify submission to add links
      await modifySubmission({
        variables: {
          id: submissionId,
          courseId,
          courseWorkId: assignmentId,
          data: {
            addAttachments
          }
        }
      });

      // Turn in the assignment
      await turnInAssignment({
        variables: {
          courseId,
          courseWorkId: assignmentId,
          id: submissionId
        }
      });

      message.success('Assignment submitted successfully');
      setTurnedIn(true);
      onClose();
    } catch (error: any) {
      message.error('Error submitting assignment: ' + (error.message || 'Unexpected Error'));
    } finally {
      setTurnInLoading(false); // Reset loading state on completion or error
    }
  };

  // New handler for reclaiming the assignment
  const handleReclaim = async () => {
    const submissionId = submissionListData?.getCourseWorkSubmissionList?.[0]?.studentSubmissions?.[0]?.id;
    if (!submissionId) {
      message.error('Submission ID not found');
      return;
    }

    setTurnInLoading(true); // Set loading state when reclaiming assignment

    try {
      await reclaimSubmission({
        variables: {
          courseId,
          courseWorkId: assignmentId,
          id: submissionId
        }
      });

      message.success('Assignment reclaimed successfully');
      setTurnedIn(false);
      if (studentSubmissionLinks.length > 0) {
        console.log('Student Submission Links:', studentSubmissionLinks)
        setShowSubmissionLinks(true);
      }
      onClose();
    } catch (error: any) {
      message.error('Error reclaiming assignment: ' + (error.message || 'Unexpected Error'));
    } finally {
      setTurnInLoading(false); // Reset loading state on completion or error
    }
  };

  const handleMenuClick = (e: any) => {
    if (e.key === '1') {
      setIsFormModalVisible(true);
    }
  };

  const handleFormLinkSave = () => {
    setFormLinks([...formLinks, linkUrl]);
    setIsFormModalVisible(false);
    setLinkUrl('');
  };

  const handleFileUpload = async (payload: { file: File }) => {
    setFileUploading(true);
    try {
      console.log('Uploading file:', payload.file);

      const response = await uploadFileMutation({
        variables: {
          file: [payload.file],
          courseId
        },
      });

      if (response.data) {
        setFormLinks((prevLinks) => [...prevLinks, ...response.data.uploadFilesForGoogleClassroom]);
        console.log('response: ', response.data);
        message.success(`${payload.file.name} uploaded successfully`);
      } else {
        message.error(`Failed to upload ${payload.file.name}`);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error(`Error uploading ${payload.file.name}`);
    } finally {
      setFileUploading(false); // End file upload loader
    }
  };

  if (courseWorkError || submissionListError) {
    return <div>Error fetching data</div>;
  }

  if (courseWorkLoading || submissionListLoading || submissionLoading) {
    return <div>Loading...</div>;
  }

  const assignment = courseWorkData?.getCourseWorkById;
  console.log('assignment:', assignment);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon>
        <Button style={{ border: '2px solid #d9d9d9', borderRadius: '4px', width: '100%' }}>
          <LinkOutlined style={{ marginRight: '1px' }} />
          Link
        </Button>
      </Menu.Item>
      <Menu.Item key="2" icon>
        <UploadInput
          title="Upload File"
          showClear={false}
          onClear={() => {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          }}
          customRequest={(options) => {
            const file = options.file as RcFile;
            handleFileUpload({ file });
            return true;
          }}
        />
      </Menu.Item>
    </Menu>
  );

  const handleRemoveFormLink = (index: number) => {
    const updatedFormLinks = [...formLinks];
    updatedFormLinks.splice(index, 1);
    setFormLinks(updatedFormLinks);
  };

  const isAssessmentOrInvestigation = assignment?.materials?.some((material: Material) => {
    const link = material.link?.url || '';
    // Check for both teacher and student investigation/assessment paths
    return link.includes('/teacher-dashboard/investigation-summary/') || 
           link.includes('/student-investigation/') ||
           link.includes('/teacher-dashboard/assessment-summary/') || 
           link.includes('/student-assessment/');
  });  

  return (
    <Modal visible={visible} onCancel={onClose} footer={null} width={800}>
      <S.SummaryContainer>
        <Spin spinning={fileUploading} tip="Uploading file..." style={{ display: isMobile ? 'block' : 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <S.Title>Assignment Summary</S.Title>
          {assignment ? (
            <>
              <S.Row>
                <div>
                  <S.InfoLabel>Assignment Name:</S.InfoLabel>
                  <S.InfoValue>{assignment.title}</S.InfoValue>
                </div>
                <div>
                  <S.InfoLabel>Start Date:</S.InfoLabel>
                  <S.InfoValue>{new Date(assignment.creationTime).toLocaleDateString()}</S.InfoValue>
                </div>
                <div>
                  <S.InfoLabel>End Date:</S.InfoLabel>
                  <S.InfoValue>{assignment.dueDate ? `${assignment.dueDate.month}/${assignment.dueDate.day}/${assignment.dueDate.year}` : 'N/A'}</S.InfoValue>
                </div>
              </S.Row>
              <S.Row>
                <div>
                  <S.InfoLabel>Points:</S.InfoLabel>
                  <S.InfoValue>{assignment.maxPoints}</S.InfoValue>
                </div>
              </S.Row>
              <S.InfoRow>
                <S.InfoLabel>Description:</S.InfoLabel>
                <S.InfoValue dangerouslySetInnerHTML={{ __html: assignment.description }} />
              </S.InfoRow>
              <S.InfoRow>
                <S.InfoLabel>Links:</S.InfoLabel>
                <S.AssignmentLinks>
                  {assignment.materials && assignment.materials.map((material: Material, index: number) => {
                    let newLink = material.link?.url || '';
                      const courseWorkParams = `?courseId=${courseId}&courseWorkId=${assignmentId}`;
                      newLink += courseWorkParams;
                    
                    return (
                      <div key={index}>
                        {material.link && <a href={newLink}>{newLink}</a>}
                        {material.form && <a href={material.form.formUrl}>{material.form.formUrl}</a>}
                      </div>
                    );
                  })}
                </S.AssignmentLinks>
              </S.InfoRow>

              {(isFinished || showSubmissionLinks) && (
                <>
                  {console.log('Student Submission Links:', studentSubmissionLinks)}  {/* Add this line here */}
                  <S.InfoRow>
                    <S.InfoLabel>Submission Links:</S.InfoLabel>
                    <S.AssignmentLinks>
                      {studentSubmissionLinks.map((link, index) => (
                        <div key={index}>
                          <LinkOutlined style={{ marginRight: '8px' }} />
                          <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                        </div>
                      ))}
                    </S.AssignmentLinks>
                  </S.InfoRow>
                </>
              )}

              {isFinished && (
                <S.ProgressContainer>
                  <Progress
                    type="circle"
                    percent={score}
                    width={145}
                    strokeWidth={9}
                    strokeLinecap="round"
                    style={{ marginTop: 20 }}
                    strokeColor={getProgressColor(score || 0)}
                    format={(percent) => (
                      <>
                        <S.Info $bold>{percent !== undefined ? Math.round(percent) : 0}%</S.Info>
                        <S.InfoProgress>Total Marks: {assignment?.maxPoints}</S.InfoProgress>
                      </>
                    )}
                  />
                </S.ProgressContainer>

              )}
              {!isFinished && (
                <>
                  <S.InfoRow>
                    <S.InfoLabel>Add Links & Files:</S.InfoLabel>
                    <Dropdown overlay={menu} trigger={['click']} disabled={fileUploading || turnedIn}>
                      <Button type="primary" disabled={fileUploading || turnedIn}>
                        Attach File <UploadOutlined />
                        {fileUploading && <Spin size="small" style={{ marginLeft: 8 }} />}
                      </Button>
                    </Dropdown>
                  </S.InfoRow>
                  <ul>
                    {formLinks.map((formLink, index) => (
                      <li key={index}>
                        <a href={formLink} target="_blank" rel="noopener noreferrer">{formLink}</a>
                        <Button type="text" icon={<DeleteOutlined />} onClick={() => handleRemoveFormLink(index)} />
                      </li>
                    ))}
                  </ul>
                  <S.ButtonContainer>
                    <Button onClick={onClose}>Cancel</Button>
                    {!turnedIn && !isAssessmentOrInvestigation && (
                      <Button type="primary" onClick={handleSubmit} loading={turnInLoading}>
                        Turn In
                      </Button>
                    )}
                  </S.ButtonContainer>
                </>
              )}
              {isFinished && (
                <>
                  <ul>
                    {formLinks.map((formLink, index) => (
                      <li key={index}>
                        <a href={formLink} target="_blank" rel="noopener noreferrer">{formLink}</a>
                        <Button type="text" icon={<DeleteOutlined />} onClick={() => handleRemoveFormLink(index)} />
                      </li>
                    ))}
                  </ul>
                  <S.ButtonContainer>
                    <Button onClick={onClose}>Close</Button>
                    <Button type="primary" disabled>
                      Turned In
                    </Button>
                    {!isAssessmentOrInvestigation && (
                    <Button type="primary" danger loading={turnInLoading || reclaimLoading} onClick={handleReclaim} disabled={assignedGrade !== null}>
                      Unsubmit
                    </Button>
                    )}
                  </S.ButtonContainer>
                </>
              )}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </Spin>
      </S.SummaryContainer>
      <Modal
        title="Add Form Link"
        visible={isFormModalVisible}
        onOk={handleFormLinkSave}
        onCancel={() => setIsFormModalVisible(false)}
      >
        <Input
          placeholder="Form Link URL"
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
        />
      </Modal>
    </Modal>
  );
};

export default AssignmentSummaryPopup;
