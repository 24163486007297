import styled from 'styled-components';
import { Layout } from 'antd';
const { Sider: AntdSider } = Layout;

export const StyledSider = styled(AntdSider) <{ $highlighted?: boolean; $padding?: string, $height?: string }>`
  box-shadow:${(props) =>
    props.$highlighted ? '0px 0px 23px 20px rgba(0, 0, 0, 0.25);' : '7px 0px 34px 10px rgba(0, 0, 0, 0.1);'}
  background-color: white;
  padding: ${(props) => props.$padding || '25px 16px'};
  overflow: auto;
  bottom: 0px;
  height:${(props) => props.$height || 'unset'};

  /* height: calc(100% - 114px); */
    /* position: fixed; */
    /* z-index: 3000; */

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

  }

  .ant-skeleton-element {
    width: 100%;
  }

  .ant-skeleton-input {
    border-radius: 2px;
    height: 28px;
  }

  .ant-skeleton-input-sm {
    height: 18px;
  }

  @media (max-width: 768px) {
    max-width: 240px !important;
    min-width: 240px !important;
  }
`;

export const Title = styled.h1<{ clickable?: boolean; $mBottom?: string }>`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: ${(props) => props.$mBottom || '30px'};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.clickable && 'cursor: pointer;'}

  svg {
    margin-right: 16px;
    min-height: 22px;
    min-width: 22px;
  }
`;
