import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { RcFile } from 'antd/lib/upload/interface';
import { GrDocumentText } from 'react-icons/gr';
import { AiFillCloseCircle } from 'react-icons/ai';
import Papa from 'papaparse';
import * as S from './styles';
import UsersData from '../UsersData';
import { FormInstance } from 'antd/lib/form';
import Upload from '../../Upload';
import { ClassProps } from '../../../types/class';
import { themeConfig } from '../../../utils/theme';
import { UserDataProps } from '../../../types/user';
import { TagInput, TagResponse } from '../../../types/tags';
import { generateRandomColor } from '../../../components/ManageTagsPage';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
interface Props {
  viewUsersDetails: boolean;
  setHasUploadedFile: Dispatch<SetStateAction<boolean>>;
  form: FormInstance<ClassProps>;
  initialInvite: UserDataProps[];
  hasErrorFile: boolean;
  setHasErrorFile: Dispatch<SetStateAction<boolean>>;
  userType: string;
  onUserListUpdate?: () => void;
}
const findHeader = (headers: string[], fieldName: string) =>
  headers.findIndex((header: string) => {
    return header.replace(/ /g, '').toLocaleLowerCase() === fieldName;
  });
const mainTitle = 'Drag and Drop your CSV file here...';

const UsersCsv: React.FC<Props> = (props) => {
  const {
    viewUsersDetails,
    setHasUploadedFile,
    form,
    setHasErrorFile,
    hasErrorFile,
    initialInvite,
    userType,
    onUserListUpdate,
  } = props;
  const [title, setTitle] = useState<string | ReactElement>(mainTitle);
  const [users, setUsers] = useState<UserDataProps[]>([]);
  const [openOnclick, setOpenOnclick] = useState(true);
  const { data: tagsData } = useQuery<{ getTags: TagResponse[] }>(gqlSchema.TagsSchema.queries.getTags);
  const existingTags = useMemo(() => tagsData?.getTags?.map((t) => ({ ...t, __typename: undefined })) || [], [
    tagsData,
  ]);

  useEffect(() => {
    if (viewUsersDetails) {
      const currentUsers = form.getFieldValue('invites');

      if (initialInvite?.length === currentUsers?.length) {
        form.setFieldsValue({ invites: [...users] });
      } else {
        form.setFieldsValue({ invites: [...currentUsers, ...users] });
      }

      setTitle(mainTitle);
      setOpenOnclick(true);
      if (onUserListUpdate) onUserListUpdate();
    }
  }, [viewUsersDetails, form, initialInvite, users, onUserListUpdate]);

  const onRemoveFile = useCallback(() => {
    setTitle(mainTitle);
    setOpenOnclick(true);
    setHasUploadedFile(false);
  }, [setHasUploadedFile]);

  const onTagUpdate = useCallback(
    (tags: TagInput[], userIndex) => {
      const currentUsers = form.getFieldValue('invites');
      currentUsers[userIndex].tags = tags;
      form.setFieldsValue({ invites: currentUsers });
    },
    [form],
  );

  const checkHeaders = useCallback((headers: string[]) => {
    const firstNameIndex = findHeader(headers, 'firstname');
    const lastNameIndex = findHeader(headers, 'lastname');
    const emailIndex = findHeader(headers, 'email');
    const tagsIndex = findHeader(headers, 'tags');
    return { firstNameIndex, lastNameIndex, emailIndex, tagsIndex };
  }, []);

  const onLoad = useCallback(
    (file: RcFile) => {
      Papa.parse(file, {
        complete: (results: any, file: RcFile) => {
          const { data } = results;
          const { firstNameIndex, lastNameIndex, emailIndex, tagsIndex } = checkHeaders(data[0]);

          if (firstNameIndex < 0 || lastNameIndex < 0 || emailIndex < 0) {
            setTitle(
              <>
                <S.TitleDragger $color={themeConfig.error.background}>
                  <AiFillCloseCircle size={30} />
                </S.TitleDragger>
                <S.TitleDragger $color={themeConfig.error.background}>
                  You have imported a wrong file format.
                </S.TitleDragger>
                <S.TitleDragger $color={themeConfig.error.background}>
                  Please try uploading another file.
                </S.TitleDragger>
              </>,
            );
            setHasUploadedFile(true);
            setHasErrorFile(true);
            return;
          }

          const hasTags = tagsIndex >= 0;
          const usersData: UserDataProps[] = [];
          data.forEach((item: string[], index: number) => {
            if (index > 0 && item.length > 1) {
              usersData.push({
                firstName: item[firstNameIndex],
                lastName: item[lastNameIndex],
                email: item[emailIndex]?.toLocaleLowerCase(),
                tags: hasTags
                  ? item[tagsIndex]
                      ?.split(',')
                      ?.filter((t) => t)
                      ?.map(
                        (t) =>
                          existingTags.find(
                            (e) => e.tag.trim().toLocaleLowerCase() === t.trim().toLocaleLowerCase(),
                          ) || { tag: t.trim(), color: generateRandomColor() },
                      )
                  : [],
              });
            }
          });
          setTitle(
            <>
              <S.TitleDragger>
                <GrDocumentText size={24} color={themeConfig.primaryOutlined.color} />
              </S.TitleDragger>
              <S.TitleDragger>
                We have found <strong>{usersData.length} emails</strong> in your file,
              </S.TitleDragger>
              <S.TitleDragger>would you like to import them?</S.TitleDragger>
            </>,
          );
          setHasUploadedFile(true);
          setOpenOnclick(false);
          setUsers(usersData);
          setHasErrorFile(false);
        },
      });
    },
    [checkHeaders, setHasUploadedFile, setHasErrorFile, existingTags],
  );

  return viewUsersDetails ? (
    <UsersData
      userType={userType}
      onTagUpdate={onTagUpdate}
      currentUsers={form.getFieldValue('invites')}
      key={form.getFieldValue('invites')?.length}
    />
  ) : (
    <Upload
      title={title}
      onLoad={onLoad}
      accept="application/vnd.ms-excel, .csv"
      error={hasErrorFile}
      onRemoveFile={onRemoveFile}
      openFileDialogOnClick={openOnclick}
      customTitle
    />
  );
};

export default React.memo(UsersCsv);
