import { gql } from '@apollo/client';

export const ORGANIZATION_MUTATIONS = {
  EDIT: {
    editOrganization: gql`
      mutation EditOrganization($data: EditOrganizationInput!) {
        editOrganization(data: $data) {
          id
          name
          defaultTAQuantity
          blockAdiInvestigations
          classLinkOrgId
          cleverOrgId
        }
      }
    `,
    editBlockAdiInvestigation: gql`
      mutation EditBlockAdiInvestigation($data: EditBlockAdiInvestigationInput!) {
        editBlockAdiInvestigation(data: $data) {
          id
          name
          defaultTAQuantity
          blockAdiInvestigations
        }
      }
    `,
    setOrganizationAdmin: gql`
      mutation SetOrganizationAdmin($userId: String!) {
        setOrganizationAdmin(userId: $userId)
      }
    `,
    unsetOrganizationAdmin: gql`
      mutation UnsetOrganizationAdmin($userId: String!, $deleteIfLastRole: Boolean) {
        unsetOrganizationAdmin(userId: $userId, deleteIfLastRole: $deleteIfLastRole)
      }
    `,
    removeTeacherFromOrganization: gql`
      mutation removeTeacherFromOrganization($teacherId: String!) {
        removeTeacherFromOrganization(teacherId: $teacherId)
      }
    `,
    addTeacherToOrganization: gql`
      mutation addTeacherToOrganization($teacherId: String!, $organizationId: String!) {
        addTeacherToOrganization(teacherId: $teacherId, organizationId: $organizationId)
      }
    `,
    toogleTACustomPermission: gql`
      mutation toggleTACustomPermissions($data: ToggleTACustomPermissionInput!) {
        toggleTACustomPermissions(data: $data)
      }
    `,
    toogleReportPermission: gql`
      mutation toogleReportPermission($data: ToggleReportPermissionInput!) {
        toogleReportPermission(data: $data)
      }
    `,
    editRosterOrganization: gql`
      mutation editRosterOrganization($data: OrganizationScheduledInput!) {
        editRosterOrganization(data: $data) {
          id
          name
          autoSync
          daily
          weekly
          monthly
        }
      }
    `,
  },
};

export default ORGANIZATION_MUTATIONS;
