import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { createRef, useEffect } from 'react';
import { Rect, Transformer } from 'react-konva';

export interface IRectShapeProps {
  x: number;
  y: number;
  width: number;
  height: number;
  stroke: string;
  id: string;
  fillShape?: boolean;
}

interface IRectangle {
  shapeProps: IRectShapeProps;
  isSelected: boolean;
  onSelect: (e: KonvaEventObject<Event>) => void;
  onChange: (newAttributes: IRectShapeProps) => void;
  draggable: boolean;
}

export const RectangleShape = (props: IRectangle) => {
  const { shapeProps, isSelected, onSelect, onChange, draggable } = props;
  const shapeRef = createRef<Konva.Rect>();
  const trRef = createRef<Konva.Transformer>();

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      if (shapeRef?.current && trRef?.current) {
        trRef?.current?.nodes([shapeRef?.current]);
        trRef.current.getLayer()?.batchDraw();
      }
    }
  }, [isSelected, trRef, shapeRef]);

  return (
    <>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        fill={shapeProps.fillShape ? shapeProps.stroke : undefined}
        strokeWidth={2.5}
        draggable={draggable}
        onDragStart={onSelect}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          if (node) {
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            });
          }
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};
