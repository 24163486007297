import { useLazyQuery } from '@apollo/client';
import { GQL_InvestigationStandard } from '../types/investigationStandard';
import { gqlSchema } from '../gql/schema';
import { INVESTIGATION_STANDARDS } from '../types/standards';
import { useEnabledAssessments } from './useEnabledAssessments';
import { sortTexasStandardsByCode } from '../utils/utils';
import { useEffect, useMemo } from 'react';

type TUseFetchStateStandards = (
  state: INVESTIGATION_STANDARDS | null,
  validateFromSession?: boolean,
) => {
  stateStandardsData: GQL_InvestigationStandard[];
  stateStandardsLoading: boolean;
};

const useFetchStateStandards: TUseFetchStateStandards = (state, validateFromSession = false) => {
  const [fetchData, { data, loading }] = useLazyQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards);
  const { texasEditionEnabled, ngssStandardsEnabled } = useEnabledAssessments();

  const activeStandardState = useMemo(() => {
    if (texasEditionEnabled) return INVESTIGATION_STANDARDS.TEXAS;

    if (ngssStandardsEnabled) return INVESTIGATION_STANDARDS.NGSS;

    return null;
  }, [ngssStandardsEnabled, texasEditionEnabled]);

  const sortedStateStandards = useMemo(() => {
    if (!data?.getStateStandards) {
      return [];
    }

    // as data is read-only, copy it so it can by modified by `sort`
    const stateStandards = [...data.getStateStandards];

    return stateStandards.sort(sortTexasStandardsByCode);
  }, [data]);

  useEffect(() => {
    const _state = validateFromSession ? activeStandardState : state;

    if (_state) {
      fetchData({ variables: { state: _state } });
    }
  }, [activeStandardState, fetchData, state, validateFromSession]);

  return {
    stateStandardsData: sortedStateStandards || [],
    stateStandardsLoading: loading,
  };
};

export default useFetchStateStandards;
