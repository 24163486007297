import { useLazyQuery, useMutation } from '@apollo/client';
import { message, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../../shared/Button';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import Spacer from '../../../shared/Spacer';
import { GQL_ChatListResponse, GQL_ChatListSuggestions } from '../../../types/chat';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';

interface INewChatButton {
  onSelectContact: (chatId: GQL_ChatListResponse) => void;
  existingRooms: GQL_ChatListResponse[];
}

const NewChatButton = (props: INewChatButton) => {
  const { onSelectContact, existingRooms } = props;
  const { isTeacherOrFacilitator, isStudent, isOrganizationAdiAdmin, user } = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';
  const [searchedTeacherId, setSearchedTeacherId] = useState<string>();
  const [searchUsersModalVisible, setSearchUsersModalVisible] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<GQL_ChatListSuggestions[]>([]);

  const [createChat, { loading: loadingCreateChat }] = useMutation<
    { createChat: GQL_ChatListResponse },
    {
      data: {
        senderId: string;
        recipientId: string;
      };
    }
  >(gqlSchema.ChatSchema.mutation.createChat, {
    onCompleted: ({ createChat }) => {
      setSearchUsersModalVisible(false);
      onSelectContact(createChat);
    },
    onError: (err) => {
      message.error('There was an error creating chat: ' + err.message || 'Unexpected Error');
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          getChatRooms(existingRooms: GQL_ChatListResponse[] = []) {
            if (!data?.createChat) return existingRooms;

            const newClassRoomData = cache.writeFragment({
              data: data.createChat,
              fragment: gqlSchema.ChatSchema.fragment.newChatListResponse,
            });

            return [...existingRooms, newClassRoomData];
          },
        },
      });
    },
  });

  const searchUserToChat = (v: SelectValue) => {
    if (typeof v !== 'string' || loadingSearchUsers) return;

    const userList = searchedUsersData?.getChatSuggestions ?? [];

    setFilteredUsers(userList.filter((user) => user.userName.toLocaleLowerCase().includes(v.toLocaleLowerCase())));
  };

  const handleChatCreation = () => {
    if (searchedTeacherId) {
      const existingChat = existingRooms.find((room) => room.userId === searchedTeacherId);
      if (existingChat) {
        setSearchUsersModalVisible(false);
        onSelectContact(existingChat);
      } else {
        createChat({
          variables: {
            data: {
              recipientId: searchedTeacherId,
              senderId: user.id,
            },
          },
        });
      }
    }
  };

  const handleWriteNewMessage = () => {
    setSearchUsersModalVisible(true);
    searchUsers();
  };

  const [searchUsers, { loading: loadingSearchUsers, data: searchedUsersData }] = useLazyQuery<{
    getChatSuggestions: GQL_ChatListSuggestions[];
  }>(gqlSchema.ChatSchema.query.searchUsers, {
    onCompleted: (data) => {
      setFilteredUsers(data.getChatSuggestions || []);
    },
    onError: (err) => {
      message.error('There was an error loading users: ' + err.message || 'Unexpected Error');
    },
  });

  const getModalDescription = () => {
    if (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) {
      return 'Send a message to a student or TA so you can discuss any subjects';
    } else if (isStudent || isGoogleStudent || isCanvasStudent) {
      return 'Send a message to a teacher to get help if you are stuck on an investigation';
    } else if (isOrganizationAdiAdmin) {
      return 'Send a message to one of your teachers';
    } else {
      return 'Send a message to a user in the system';
    }
  };

  const getInputTitle = () => {
    if (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) {
      return 'Find a Student or TA';
    } else if (isStudent || isGoogleStudent || isCanvasStudent) {
      return 'Find a Teacher';
    } else if (isOrganizationAdiAdmin) {
      return 'Find a Teacher';
    } else {
      return 'Find a User';
    }
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <Button
        data-cy="messagelistpage-newchatbutton-write-button"
        minHeight={40}
        theme={themeConfig.secondaryColor}
        onClick={handleWriteNewMessage}
        text={
          <>
            <FiPlus style={{ minHeight: 20, minWidth: 20 }} />
            Write a New Message
          </>
        }
      />
      <S.UserSelectionModal
        title={null}
        footer={null}
        width="60%"
        visible={searchUsersModalVisible}
        onCancel={() => setSearchUsersModalVisible(false)}
      >
        <h1>Send a New Message</h1>
        <h2>{getModalDescription()}</h2>

        <h3>{getInputTitle()}</h3>
        <div>
          <Select
            data-cy="messagelistpage-newchatbutton-teachers-select"
            showSearch
            placeholder="Start typing to search..."
            onSearch={searchUserToChat}
            filterOption={false}
            onSelect={(v) => setSearchedTeacherId(v as string)}
            onClear={() => setSearchedTeacherId(undefined)}
            notFoundContent={loadingSearchUsers ? <Spin size="small" /> : undefined}
          >
            {filteredUsers?.map((user) => (
              <SelectOption value={user.userId} key={user.userId}>
                {user.userName}
              </SelectOption>
            ))}
          </Select>
          <Spacer axis="horizontal" />
        {isMobile && (<><Button
            data-cy="messagelistpage-newchatbutton-start-button"
            text="Start Conversation"
            minHeight={40}
            style={{marginTop:'20px', marginLeft:'20%'}}
            disabled={!searchedTeacherId || loadingSearchUsers}
            loading={loadingCreateChat}
            onClick={handleChatCreation}
          /></>)}  
           {!isMobile && (<><Button
            data-cy="messagelistpage-newchatbutton-start-button"
            text="Start Conversation"
            minHeight={40}
            disabled={!searchedTeacherId || loadingSearchUsers}
            loading={loadingCreateChat}
            onClick={handleChatCreation}
          /></>)}  
        </div>
      </S.UserSelectionModal>
    </>
  );
};

export default NewChatButton;
