interface FormField {
  name: string;
  value: string;
}

interface HSContext {
  hutk: string | undefined;
  pageUri: string;
  pageName: string;
}

interface HSFormSubmission {
  submittedAt: number;
  fields: FormField[];
  context: HSContext;
}

function formFieldsToHSJSON(formData: [string, string][] | FormData): FormField[] {
  let fieldArray: FormField[] = [];
  for (let field of formData) {
    let values: FormField = {
      name: field[0],
      // @ts-ignore
      value: field[1],
    };
    fieldArray.push(values);
  }
  return fieldArray;
}

function getCookie(name: string): string | undefined {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length == 2) {
    return parts.pop()?.split(';').shift();
  }
}

function buildHSContext(): HSContext {
  let hsContext: HSContext = {
    hutk: getCookie('hubspotutk'),
    pageUri: window.location.href,
    pageName: document.title,
  };
  return hsContext;
}

function prepareHSFormSubmission(form: [string, string][] | FormData): HSFormSubmission {
  let submissionData: HSFormSubmission = {
    submittedAt: Date.now(),
    fields: formFieldsToHSJSON(form),
    context: buildHSContext(),
  };
  return submissionData;
}

async function submitHSForm(hsFormURL: string, data: HSFormSubmission) {
  return await postData(hsFormURL, data);
}

async function postData(url: string = '', data: HSFormSubmission): Promise<Response> {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Failed to submit form');
  }
}

export async function handleHSFormSubmit(email: string) {
  const form = new FormData();
  form.append('email', email);
  var baseSubmitURL = 'https://api.hsforms.com/submissions/v3/integration/submit';
  // Add the HubSpot portalID where the form should submit
  var portalId = '9159866';
  // Add the HubSpot form GUID from your HubSpot portal
  var formGuid = '11f6e395-8ad3-4d96-99d3-c4333979657c'; //replace with the formGUID copied from the form created inside HubSpot Forms
  var submitURL = `${baseSubmitURL}/${portalId}/${formGuid}`;
  var formData = prepareHSFormSubmission(form);
  return await submitHSForm(submitURL, formData);
}
