import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col, Empty, Button, Input } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Item from './Item';
import AddClassButton from './AddClassButton';
import { GQL_GoogleClassroomCourse } from '../../../types/googleClassroom';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import Subtract from '../../../assets/Subtract.svg';
import * as S from './styles'; 
interface Props {
  courses: GQL_GoogleClassroomCourse[];
  loading: boolean;
  onPageChange: (currentCourses: GQL_GoogleClassroomCourse[]) => void;
}

const GClassRow: React.FC<Props> = ({ courses, loading, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [inputValue, setInputValue] = useState('');
  const pageSize = 3;
  const maxPagesToShow = 3; // Number of page numbers to show at a time

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const filteredCourses = courses.filter((course) =>
      course.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const currentCourses = filteredCourses.slice(startIndex, endIndex);
    onPageChange(currentCourses);
  }, [courses, currentPage, pageSize, onPageChange, searchQuery]);

  const handleNextPage = () => {
    if ((currentPage * pageSize) < filteredCourses.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageNumberClick = (page: number) => {
    setCurrentPage(page);
  };

  const filteredCourses = useMemo(() => {
    return courses.filter((course) =>
      course.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [courses, searchQuery]);

  const totalPages = Math.ceil(filteredCourses.length / pageSize);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, Math.floor((currentPage - 1) / maxPagesToShow) * maxPagesToShow + 1);
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('...');
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const paginatedCourses = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredCourses.slice(startIndex, endIndex);
  }, [filteredCourses, currentPage, pageSize]);

  const classes = useMemo(() => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          {[...Array(pageSize)].map((_, index) => (
            <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      );
    }

    if (filteredCourses.length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Classes" />;
    }

    return (
      <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
        {paginatedCourses.map((course) => (
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={course.id}>
            <Item classObj={course} icon={<img src={Subtract} alt="Assignment Icon" />} />
          </Col>
        ))}
      </Row>
    );
  }, [filteredCourses, loading, paginatedCourses]);

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col>
        <S.Title>My Classes</S.Title>
        </Col>
        <Col>
          <AddClassButton />
        </Col>
        <Col span={24}>
          <Input.Search
            placeholder="Search classes"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ marginBottom: '16px' }}>
        <Button
          style={{ marginRight: '4px', padding: '4px 8px', fontSize: '0.8em' }}
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          icon={<LeftOutlined />}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {getPageNumbers().map((page, index) => (
            <Button
              key={index}
              style={{ margin: '0 2px', padding: '4px 8px', fontSize: '0.8em' }}
              onClick={() => typeof page === 'number' && handlePageNumberClick(page)}
              disabled={typeof page === 'number' && page === currentPage}
            >
              {page}
            </Button>
          ))}
        </div>
        <Button
          style={{ marginLeft: '4px', padding: '4px 8px', fontSize: '0.8em' }}
          onClick={handleNextPage}
          disabled={(currentPage * pageSize) >= filteredCourses.length}
          icon={<RightOutlined />}
        />
      </Row>
      {classes}
    </>
  );
};

export default GClassRow;
