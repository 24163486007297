import { Modal, Checkbox, Typography, Select, message, Form as AntdForm, Row, Col } from 'antd';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { ISubscriptionUserProps } from '../../types/signUp';
import { gqlSchema } from '../../gql/schema';
import Form from '../Form';
import * as S from './styles';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { isEmailWhiteListed } from './emailValidationConstants';
import useFetchWhitelistedDomains from '../../hooks/useFetchWhitelistedDomains';

const Roles = [
  'Teacher',
  'Instructional Coach',
  'Curriculum Specialist',
  'Building Administrator',
  'District/Organization Administrator',
  'Other',
];

type FreeUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const FreeUserModal = memo(({ isOpen, onClose }: FreeUserModalProps) => {
  const history = useHistory();
  const [form] = AntdForm.useForm();
  const { whitelistedDomains } = useFetchWhitelistedDomains();

  const [createSubscriptionUser, { loading }] = useMutation<{ createSubscriptionUser: boolean }>(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.CREATE_SUBSCRIPTION_USER.createSubscriptionUser,
    {
      onError: (err) => {
        message.error(err?.message || 'There was an error trying to create your user, please try again later');
      },
      onCompleted: (data) => {
        if (data?.createSubscriptionUser) {
          message.success(`Account successfully created. Please login with your email and password.`, 5);
          history.push('/login');
        }
      },
    },
  );

  const onSubmit = useCallback(
    async (values: ISubscriptionUserProps) => {
      const { name, lastName, password, email, role, customizeRole, schoolName, schoolCity, schoolState } = values;
      const finalRole = role === 'Other' ? customizeRole : role;

      createSubscriptionUser({
        variables: {
          data: {
            name,
            lastName,
            email,
            role: finalRole,
            password,
            schoolName,
            schoolCity,
            schoolState,
          },
        },
      });
    },
    [createSubscriptionUser],
  );

  const roleMenu = useMemo(
    () => (
      <AntdForm.Item name="role" rules={[{ required: true, message: '"Role" is a required field' }]}>
        <Select placeholder="Select a Role">
          {Roles.map((role, index) => (
            <Select.Option value={role} data-cy={`trial-request-account-menu-option-${role}`} key={index}>
              {role}
            </Select.Option>
          ))}
        </Select>
      </AntdForm.Item>
    ),
    [],
  );

  const [emailCustomMessage, setEmailCustomMessage] = useState('');

  const CustomErrorMessage = (props: any) => {
    return <div dangerouslySetInnerHTML={{ __html: emailCustomMessage }} />;
  };

  const emailFieldValidator = (
    _: RuleObject,
    value: StoreValue,
    callback: (error?: string) => void,
  ): Promise<void | any> | void => {
    const emailValidationRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);

    if (!!value && value.length === 0) {
      setEmailCustomMessage('"Email" is a required field');
      callback('"Email" is a required field');
    }
    if (!emailValidationRegex.test(value)) {
      setEmailCustomMessage('The email you entered is invalid. Please enter a valid email address');
      callback('The email you entered is invalid. Please enter a valid email address');
    }

    if (isEmailWhiteListed(value, whitelistedDomains)) {
      callback();
    }

    setEmailCustomMessage(
      'Email domain not allowed, please contact us on: <a href="mailto:howdy@argumentdriveninquiry.com">howdy@argumentdriveninquiry.com</a> for assistance',
    );
    callback('Email domain not allowed, please contact us on: howdy@argumentdriveninquiry.com for assistance');
  };

  return (
    <Modal width={700} visible={isOpen} onCancel={onClose} footer={null}>
      <h1 style={{ textAlign: 'center' }}>Please introduce yourself</h1>
      <Form onFinish={onSubmit} form={form}>
        <Row gutter={8}>
          <Col span={12}>
            <S.TitleInput>First Name</S.TitleInput>
            <AntdForm.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: '"First Name" is a required field',
                },
              ]}
            >
              <S.SInput placeholder="Enter your First Name" />
            </AntdForm.Item>
          </Col>
          <Col span={12}>
            <S.TitleInput>Last Name</S.TitleInput>
            <AntdForm.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: '"Last Name" is a required field',
                },
              ]}
            >
              <S.SInput placeholder="Enter your Last Name" />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <S.TitleInput>School, District or Organization Name</S.TitleInput>
            <AntdForm.Item
              name="schoolName"
              rules={[
                {
                  required: true,
                  message: '"School District or Organization Name" is a required field',
                },
              ]}
            >
              <S.SInput placeholder="School, District or Organization Name" />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <S.TitleInput>School, District or Organization City</S.TitleInput>
            <AntdForm.Item
              name="schoolCity"
              rules={[
                {
                  required: true,
                  message: '"School District or Organization City" is a required field',
                },
              ]}
            >
              <S.SInput placeholder="School, District or Organization Name" />
            </AntdForm.Item>
          </Col>
          <Col span={12}>
            <S.TitleInput>School, District or Organization State</S.TitleInput>
            <AntdForm.Item
              name="schoolState"
              rules={[
                {
                  required: true,
                  message: 'School District or Organization State" is a required field',
                },
              ]}
            >
              <S.SInput placeholder="School, District or Organization State" />
            </AntdForm.Item>
          </Col>
        </Row>
        <S.TitleInput>Email</S.TitleInput>
        <AntdForm.Item
          name="email"
          rules={[
            {
              validator: emailFieldValidator,
              message: <CustomErrorMessage />,
            },
          ]}
        >
          <S.SInput />
        </AntdForm.Item>

        <S.TitleInput>Role</S.TitleInput>
        <Row gutter={4}>
          <Col flex="270px">{roleMenu}</Col>
          <Col flex="auto">
            <AntdForm.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}>
              {({ getFieldValue }) =>
                getFieldValue('role') === 'Other' ? (
                  <>
                    <AntdForm.Item
                      name="customizeRole"
                      rules={[{ required: true, message: '"Role" is a required field' }]}
                    >
                      <S.SInput style={{ height: '32px' }} />
                    </AntdForm.Item>
                  </>
                ) : null
              }
            </AntdForm.Item>
          </Col>
        </Row>

        <Row gutter={4}>
          <Col span={12}>
            <S.TitleInput>Password</S.TitleInput>
            <AntdForm.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: '"Password" is a required field',
                },
                {
                  min: 12,
                  message: '- The minimum password length is 12.',
                },
                {
                  pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[$@!#%.:,()])\\S{12,50}$'),
                  message:
                    '- Please include at least one uppercase letter, one lowercase letter, and one special character ($@!#%.:,)',
                },
              ]}
            >
              <S.SInput placeholder="Enter your password" password />
            </AntdForm.Item>
          </Col>
          <Col span={12}>
            <S.TitleInput>Confirm password</S.TitleInput>
            <AntdForm.Item
              name="confirm"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: '"Confirm Password" is a required field',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <S.SInput placeholder="Confirm your password" password />
            </AntdForm.Item>
          </Col>
          <Typography.Text type="secondary" style={{ marginTop: '-5px', marginBottom: '15px' }}>
            Your password should contain at least 1 uppercase letter, 1 lowercase letter and 1 special character (@, #,
            $, !)
          </Typography.Text>
        </Row>
        <Row>
          <AntdForm.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Please, read and agree with the terms')),
              },
            ]}
          >
            <Checkbox>
              I have read and agree to the{' '}
              <a href="/documents/terms-of-use.pdf" target="_blank" rel="noreferrer">
                Terms & Conditions
              </a>{' '}
              and understand the{' '}
              <a href="/documents/privacy-policy.pdf" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </Checkbox>
          </AntdForm.Item>
        </Row>
        <Row>
          <Col flex="100%">
            <AntdForm.Item>
              <S.CreateButton text="Create Account" htmlType="submit" disabled={loading} block />
            </AntdForm.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
