import React, { useState, useEffect } from 'react';
import { message, Row, Col, Divider } from 'antd';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import CanvasClassRow from './CanvasClassRow';
import CActiveAssignmentsRow from './CActiveAssignmentsRow';
import CFinishedAssignmentsRow from './CFinishedAssignmentsRow';
import * as S from './styles';

const CanvasTeachersDashboard: React.FC = () => {
  const [courses, setCourses] = useState<any[]>([]);
  const [finishedAssignments, setFinishedAssignments] = useState<any[]>([]);
  const [courseWorkList, setCourseWorkList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [currentApiPage, setCurrentApiPage] = useState(-1);
  const [selectedCourseIds, setSelectedCourseIds] = useState<string[]>([]);

  const itemsPerPage = 3;

  // Query to fetch courses
  const { loading: coursesLoading, refetch: refetchCourses } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasCoursesListByUser,
    {
      variables: { page: String(currentPage) },
      onError: (error) => {
        message.error(
          error.message ||
            'There was an error loading your classes, check your connection and try again later.'
        );
      },
      fetchPolicy: 'network-only',
      skip: currentApiPage === currentPage,
      onCompleted: (data) => {
        const fetchedCourses = data?.getCanvasCoursesListByUser || [];
        if (fetchedCourses.length) {
          if (fetchedCourses.length === itemsPerPage) setHasMorePages(true);
          setCourses(fetchedCourses);
          setCurrentApiPage(currentPage);
          // Extract course IDs and set them
          const courseIds = fetchedCourses.map((course: any) => course.id);
          setSelectedCourseIds(courseIds);
          // Trigger assignments fetch with the selected course IDs
          // refetchAssignments({ course_id: courseIds });
        } else {
          message.info(
            'No more pages'
          );
          setHasMorePages(false);
          setCurrentPage(currentApiPage);
        }
      },
    }
  );

  // Query to fetch assignments using selectedCourseIds
  const { loading: assignmentsLoading, refetch: refetchAssignments } = useQuery<any>(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasAssignmentListByDueDate,
    {
      variables: { course_id: selectedCourseIds },
      onError: (error) => {
        message.error(
          error.message ||
            'There was an error loading your assignments, check your connection and try again later.'
        );
      },
      skip: !selectedCourseIds.length || currentApiPage !== currentPage, 
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const activeAssignments = data?.getCanvasAssignmentListByDueDate?.active || [];
        const expiredAssignments = data?.getCanvasAssignmentListByDueDate?.expired || [];
        setCourseWorkList(activeAssignments);
        setFinishedAssignments(expiredAssignments);
      },
    }
  );

  useEffect(() => {
    if (selectedCourseIds.length) {
      refetchAssignments({ course_id: selectedCourseIds });
    }
  }, [selectedCourseIds, refetchAssignments]);

  // Re-fetch courses whenever the page changes
  useEffect(() => {
    if (currentPage !== currentApiPage) {
      refetchCourses({ page: String(currentPage) });
    }
  }, [currentPage, refetchCourses, currentApiPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <S.Container padding={2}>
      <Row gutter={[24, 24]}>
        <Col xxl={{ span: 18, offset: 3 }} xl={24} lg={24} md={24} sm={24}>
          <CanvasClassRow
            courses={courses}
            loading={coursesLoading}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            hasMorePages={hasMorePages}
          />
          <Divider style={{ margin: '24px 0' }} />
          <CActiveAssignmentsRow
            loading={assignmentsLoading}
            assignmentData={courseWorkList}
            courses={courses}
          />
          <Divider style={{ margin: '24px 0' }} />
          <CFinishedAssignmentsRow
            loading={assignmentsLoading}
            finishedAssignments={finishedAssignments}
            courses={courses}
          />
        </Col>
      </Row>
    </S.Container>
  );
};

export default CanvasTeachersDashboard;
