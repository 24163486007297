import styled from 'styled-components';
import CButton from '../../../../shared/Button';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5em;
  border-radius: 4px;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  .middle {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

export const Info = styled.p<{
  $bold?: boolean;
  $align?: string;
  $fontSize?: string;
}>`
  font-weight: ${(props) => (props.$bold ? '700' : '400')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '0.82em')};
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  margin: 0;

  .icon {
    vertical-align: middle;
    font-size: 1.3em;
  }
  span {
    margin-left: 8px;
    vertical-align: text-top;
  }
`;

export const InfoProgress = styled.p`
  font-size: 0.45em;
  margin: 10px 0 0 0;
`;

export const Title = styled.h3<{
  $align?: string;
}>`
  text-align: ${(props) => (props.$align ? props.$align : 'center')};
  font-weight: 500;
  margin-bottom: 0;
`;

export const SummarydButton = styled(CButton)`
  font-weight: 500;
`;
