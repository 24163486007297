import { Modal } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useMemo } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../../shared/Button';
import Editor from '../../../shared/Editor';
import Spacer from '../../../shared/Spacer';

import * as S from './styles';

interface IAnnouncementModal {
  visible: boolean;
  onClose: () => void;
  text?: string;
  onTextChange: (text?: string) => void;
  onCreate: () => void;
  loading: boolean;
  shouldSendEmail?: boolean;
  setShouldSendEmail?: (v: boolean) => void;
}

const AnnouncementModal = (props: IAnnouncementModal) => {
  const { visible, onClose, text, onTextChange, onCreate, loading, setShouldSendEmail, shouldSendEmail } = props;
  const { isFacilitator } = useAuth();
  const classTitle = useMemo(() => (isFacilitator ? 'Course or Event' : 'Class'), [isFacilitator]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      destroyOnClose
      title={null}
      footer={null}
      width="100%"
      style={{ maxWidth: 800 }}
    >
      <S.AnnouncementContainer>
        <Spacer />
        <h1>Post {classTitle} Announcement</h1>
        <h2>Send a message that will appear to your students on their dashboard</h2>
        <Spacer size={32} />
        <Editor
          title="Your Announcement"
          value={text}
          onChange={(v) => onTextChange(v.isEditorEmpty ? undefined : v.value)}
          data-cy="components-announcement-modal-editor"
        />
        <Spacer />
        {setShouldSendEmail && (
          <Checkbox checked={shouldSendEmail} onChange={(e) => setShouldSendEmail(e.target.checked)}>
            Send this announcement as a notification on your student's emails
          </Checkbox>
        )}
        <Spacer size={32} />
        <Button
          text="Post Announcement"
          block
          minHeight={40}
          disabled={!text}
          loading={loading}
          onClick={onCreate}
          data-cy="components-announcement-modal-post"
        />
        <Spacer />
      </S.AnnouncementContainer>
    </Modal>
  );
};

export default AnnouncementModal;
