import { PureQueryOptions, useMutation, useQuery } from '@apollo/client';
import { Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { MdCancel } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';
import { TagResponse } from '../../types/tags';
import Button from '../Button';
import RoundedTag from '../RoundedTag';
import Select from '../Select';
import SelectOption from '../Select/Option';
import Spacer from '../Spacer';
import * as S from './styles';

interface ITagInput {
  userId?: string;
  existingUserTags?: TagResponse[];
  queriesToRefetch?: (string | PureQueryOptions)[];
  editable?: boolean;
  className?: string;
  style?: React.CSSProperties;
  maxTagCount?: number;
  alwaysEditable?: boolean;
  /** Default: 0 **/
  marginTop?: number;
  height?: number;
  backgroundColor?: string;
  onChange?: (tags: TagResponse[]) => void;
}

const TagInput = (props: ITagInput) => {
  const {
    userId,
    existingUserTags,
    queriesToRefetch = [],
    marginTop = 0,
    editable = true,
    className,
    style,
    maxTagCount,
    alwaysEditable,
    height,
    backgroundColor,
    onChange,
  } = props;
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [editingTag, setEditingTag] = useState(!!alwaysEditable);
  const history = useHistory();
  const { isTeacherAssistant, isStudent, user } = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';

  useEffect(() => {
    setSelectedTags(existingUserTags?.map((tag) => tag.id || tag.tag) || []);
  }, [existingUserTags]);

  const { data: tagsData, loading: loadingTags } = useQuery<{ getTags: TagResponse[] }>(
    gqlSchema.TagsSchema.queries.getTags,
  );

  const [toggleTagToUser] = useMutation<{ toggleTagToUser: { id: string; action: 'ADDED' | 'REMOVED' }[] }>(
    gqlSchema.TagsSchema.mutations.toggleTagToUser,
    {
      refetchQueries: [...queriesToRefetch, 'getUsersWithTags'],
      awaitRefetchQueries: true,
    },
  );

  const handleChangeEditingMode = () => {
    const existingUserTagsIds = existingUserTags?.map((e) => e.id) || [];
    const newTags = selectedTags.filter((s) => !existingUserTagsIds.includes(s)) || [];
    const deletedTags = existingUserTagsIds.filter((s) => !selectedTags.includes(s)) || [];
    if (editingTag && userId) {
      const changedTags = [...newTags, ...deletedTags];
      toggleTagToUser({
        variables: {
          data: changedTags.map((t) => ({ id: t })),
          userId: userId,
        },
      });
    }

    setEditingTag(!editingTag);
  };

  const newTags = existingUserTags?.filter((e) => !e.id) || [];
  const hasEditablePermission = editable && !isTeacherAssistant && !(isStudent || isGoogleStudent || isCanvasStudent);
  const tags = tagsData?.getTags?.map((r) => ({ ...r, __typename: undefined })) || [];

  return (
    <S.TagsContainer marginTop={marginTop} className={className} style={style}>
      <div>
        {editingTag || alwaysEditable ? (
          <Select
            data-cy="shared-taginput-edit-select"
            placeholder="Tags"
            optionLabelProp="name"
            showSearch={true}
            dropdownMatchSelectWidth={false}
            maxTagTextLength={8}
            maxTagCount={maxTagCount}
            mode="multiple"
            showArrow
            value={selectedTags}
            height={height}
            backgroundColor={backgroundColor}
            onChange={(v) => {
              setSelectedTags(v as string[]);
              onChange?.((v as string[]).map((t) => tags.find((e) => e.id === t))?.flatMap((v) => (v ? v : [])) || []);
            }}
            filterOption={(input, option) =>
              option?.name?.toString()?.toLocaleLowerCase()?.includes(input?.toLocaleLowerCase()) ?? false
            }
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Popconfirm
                  title="You'll leave this page and unsaved data will be lost"
                  onConfirm={() => hasEditablePermission && history.push('/manage-tags', { creating: true })}
                >
                  <S.NewItemContainer data-cy="shared-taginput-newitemcontainer-newtag">
                    <FiPlus size={16} />
                    <Spacer axis="horizontal" size={8} />
                    Create a new tag
                  </S.NewItemContainer>
                </Popconfirm>
              </div>
            )}
            loading={loadingTags}
          >
            {[...newTags, ...(tags || [])]?.map((roleItem) => {
              return (
                <SelectOption
                  value={roleItem?.id || roleItem?.tag}
                  name={roleItem?.tag}
                  key={roleItem?.id || roleItem?.tag}
                >
                  <S.ItemContainer>
                    <S.TagColor color={roleItem.color} />
                    <Spacer axis="horizontal" size={8} />
                    {roleItem?.tag}
                  </S.ItemContainer>
                </SelectOption>
              );
            })}
          </Select>
        ) : existingUserTags?.length ? (
          existingUserTags?.map((tag) => (
            <RoundedTag
              maxWidth={160}
              color={tag.color}
              text={tag.tag}
              key={tag.id}
              onClick={() => hasEditablePermission && history.push('/manage-tags/' + tag.id)}
              className="spaced"
            />
          ))
        ) : (
          <p>No Tags</p>
        )}
      </div>
      {hasEditablePermission && editable && !alwaysEditable && (
        <>
          <Spacer axis="horizontal" size={4} />
          <Button
            data-cy="shared-taginput-edit-button"
            type="primary"
            shape="circle"
            minHeight={32}
            minWidth={32}
            icon={editingTag ? <BiCheck /> : <BiEdit />}
            onClick={handleChangeEditingMode}
          />

          {editingTag && (
            <>
              <Spacer axis="horizontal" size={4} />
              <Button
                data-cy="shared-taginput-cancel-button"
                type="primary"
                shape="circle"
                minHeight={32}
                minWidth={32}
                icon={<MdCancel />}
                onClick={() => setEditingTag(false)}
              />
            </>
          )}
        </>
      )}
    </S.TagsContainer>
  );
};

export default TagInput;
