import styled from 'styled-components';
export const EditableEditor = styled.div<{
  $height: number;
}>`
  padding: 5px;
  min-height: ${(props) => `${props.$height}vh`};
  max-height: ${(props) => `${props.$height}vh`};
  color: #000;
  overflow: auto;
  border-radius: 10px;
  border: 2px solid #d9d9d9;
  background-color: white;

  .remirror-editor {
    min-height: ${(props) => `${props.$height - 2}vh`};
    padding: 5px !important;
    position: relative;
    word-wrap: break-word;
    white-space: pre-wrap;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    line-height: 1.2;
    outline: none;
    font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    color: #767676;
    p {
      margin-bottom: 0.2em;
      font-size: 1.3em;
      margin-top: 0;
    }

    .remirror-is-empty::before {
      position: absolute;
      color: #aaa;
      pointer-events: none;
      height: 0;
      font-style: italic;
      content: attr(data-placeholder);
    }
  }
`;
