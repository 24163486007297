import styled from 'styled-components';

import { Modal as ModalAntd, Divider as DividerAntd, InputNumber as InputNumberAntd, Select as SelectAntd } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
`;

export const SubTitle = styled.h2`
  text-align: left;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 12px;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 1rem;
  margin: 8px 0;
  text-align: left;
  color: #767676;
`;

export const Divider = styled(DividerAntd)`
  margin: 0;
`;

export const Modal = styled(ModalAntd)`
  .ant-modal-content {
    border-radius: 5px;
    max-height: 75vh;
    overflow-y: auto;
    .ant-modal-header {
      border-radius: 5px;
      padding: 16px 24px 0 24px;
      border-bottom: none;
    }
    .ant-modal-body {
      padding: 0 24px;
    }
  }

  .ant-modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(224 224 224 / 99%) !important;
  }
`;

export const InputNumber = styled(InputNumberAntd)`
  width: 60px;
  margin: 0 4px;
`;

export const Select = styled(SelectAntd)`
  width: 200px;
  margin: 0 4px;
`;
