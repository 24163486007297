import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as S from './styles';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { ShopifyOrderData } from '../../../types/shopify';
import history from '../../../history';
import { FiArrowLeft } from 'react-icons/fi';

const OrderListPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getAllOrders, {
    variables: { page: currentPage },
    onError: (error) => {
      message.error(`Error in fetching order details - ${error.message || 'Unexpected Error'}`);
    },
  });

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${day}/${month}/${year} at ${hours}.${minutes} ${ampm}`;
  };

  const orderData: ShopifyOrderData[] = data?.getAllOrders?.order?.map((order: any, index: number) => ({
    index: index + 1, // Add index for the row
    id: order.id,
    shopifyOrderId: order.shopifyOrderId,
    userId: order.userId,
    totalAmount: order.totalAmount,
    totalItems: order.totalItems,
    fulfillmentStatus: order.fulfillmentStatus,
    paymentStatus: order.paymentStatus,
    createdAt: formatDate(Number(order.createdAt)),
  }));

  const columns: ColumnsType<ShopifyOrderData> = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (text) => <strong>{text}</strong>, // Display the row index in bold
    },
    {
      title: 'Orders',
      dataIndex: 'shopifyOrderId',
      key: 'shopifyOrderId',
    },
    {
      title: 'Total Items',
      dataIndex: 'totalItems',
      key: 'totalItems',
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" onClick={() => handleViewOrder(record.id)}>
          View Order
        </Button>
      ),
    },
  ];

  const handleViewOrder = (orderId: string) => {
    history.push(`/bookorderdetail/${orderId}`);
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
  };

  return (
    <S.Container>
       <FiArrowLeft
              onClick={() => history.goBack()}
              style={{ cursor: 'pointer', color: 'black', fontSize: '24px', margin: '10px' }}
            />
      <S.Header>
        <Input placeholder="Search Order" style={{ width: 200 }} />
        <Button type="default">Filter By</Button>
      </S.Header>
      <S.Table
        columns={columns as ColumnsType<object>} // Explicit cast to ColumnsType<object>
        dataSource={orderData}
        rowKey="id"
        pagination={{
          current: currentPage,
          pageSize: 10,
          total: data?.getAllOrders?.total,
        }}
        onChange={handleTableChange}
      />
    </S.Container>
  );
};

export default OrderListPage;
