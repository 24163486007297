import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { FiCreditCard } from 'react-icons/fi';
import { Divider, Row, message } from 'antd';
import { useMutation } from '@apollo/client';

import * as S from './styles';
import Button from '../../../shared/Button';
import { GQL_PaymentIntent, GQL_PaymentIntentInput, GQL_PaymentCancelUserSubscription } from '../../../types/payments';
import { gqlSchema } from '../../../gql/schema';
import { ProductSummary } from '../Product';
import { themeConfig } from '../../../utils/theme';
import { useAuth } from '../../../hooks/useAuth';
import { SubscriptionPackageType } from '../../../constants/enums/SubscriptionPackageType';

type Props = RouteComponentProps<{
  priceId: string;
}>;

const CreatePaymentIntent: React.FC<Props> = (props) => {
  const { subscriptionPackageType, fetchProfile } = useAuth();
  const { priceId } = props.match.params;

  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const history = useHistory();

  const [createPaymentIntent, { loading: paymentInProgress }] = useMutation<
    { createCheckout: GQL_PaymentIntent },
    GQL_PaymentIntentInput
  >(gqlSchema.PaymentsSchema.mutations.CHECKOUT.paymentIntent, {
    onCompleted: (data: { createCheckout: GQL_PaymentIntent }) => {
      if (data?.createCheckout?.updated) {
        message.success('Your subscription was updated successfully!');
        history.push('/manage-subscription');
      }

      if (data?.createCheckout?.pendingDowngrade) {
        message.warning('Your subscription is pending to be udpated, please contact support');
        return;
      }

      const url = data?.createCheckout?.url;
      if (url) {
        window.location.replace(url);
      }
    },
    onError: (err) => {
      console.log('error', err);
    },
  });

  const [cancelUserSubscription, { loading: cancelSubscriptionLoading }] = useMutation<{
    cancelUserSubscription: GQL_PaymentCancelUserSubscription;
  }>(gqlSchema.PaymentsSchema.mutations.CHECKOUT.cancelUserSubscription, {
    onError: (error) => {
      message.error(error.message || 'There was an error while cancelling your subscription.');
    },
    onCompleted: (data) => {
      if (data.cancelUserSubscription.success) {
        message.success('Your subscription was updated successfully.');
        history.push('/manage-subscription');
      }

      if (data.cancelUserSubscription.pendingDowngrade) {
        message.warning('Your subscription is pending to be updated, please contact support for futher information');
      }
    },
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      message.success('Suscription updated! You will receive an email confirmation.');
      if (fetchProfile) {
        fetchProfile();
      }
      history.push('/manage-subscription');
    }

    if (query.get('canceled')) {
      message.error('Payment error - please try again or contact us');
    }
  }, [history, fetchProfile]);

  const handlePaymentIntent = useCallback(() => {
    // Handle starter by simpli cancelling subscription
    if (priceId === SubscriptionPackageType.STARTER.toLocaleLowerCase()) {
      cancelUserSubscription();
    } else {
      createPaymentIntent({
        variables: {
          priceId,
        },
      });
    }
  }, [createPaymentIntent, cancelUserSubscription, priceId]);

  return (
    <S.Container>
      <S.Card>
        <Row>
          <h2 className="title"> Confirm your order </h2>
          <Divider style={{ margin: '10px 0' }} />
        </Row>

        <ProductSummary productId={priceId} fullWidth={true} handleOnLoad={() => setPaymentEnabled(true)} />

        {paymentEnabled && (
          <Row style={{ width: '100%', display: 'flex', padding: '10px' }}>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <Button
                theme={themeConfig.secondaryOutlined}
                onClick={() => {
                  history.goBack();
                }}
                text={'Cancel'}
                disabled={paymentInProgress || cancelSubscriptionLoading}
                data-cy="checkout-proceed-to-cancel"
                style={{ marginRight: '20px' }}
              />
              <Button
                onClick={handlePaymentIntent}
                text={
                  subscriptionPackageType === SubscriptionPackageType.STARTER
                    ? 'Proceed to Checkout'
                    : 'Change Subscription'
                }
                disabled={paymentInProgress || cancelSubscriptionLoading}
                data-cy="checkout-proceed-to-payment"
                suffix={<FiCreditCard style={{ marginLeft: 4, marginRight: 0 }} />}
              />
            </div>
          </Row>
        )}
      </S.Card>
    </S.Container>
  );
};
export default withRouter(CreatePaymentIntent);
