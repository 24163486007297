import styled from 'styled-components';

export const SiderContainer = styled.div`
  height: calc(100% - 58px);
  display: flex;
  flex-direction: column;
`;

export const SettingsContainer = styled.div`
  height: 100%;
  overflow: auto;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125.1%;
    /* identical to box height, or 23px */

    color: #767676;
  }
`;

export const SiderTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;

export const ActivityContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  padding: 8px 16px;

  cursor: pointer;

  & > span {
    display: flex;
  }
`;
