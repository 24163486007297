import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Menu, Row, Table, Tooltip, Dropdown, message } from 'antd';
import { GQL_ClassResponse, GQL_ClassUserOrInvite, GQL_RemoveStudentFromClassInput } from '../../../types/class';
import Button from '../../../shared/Button';
import * as S from './styles';
import { Breakpoint, centerAlign, Filter } from '../../../utils/antd';
import { BsExclamationCircle, BsExclamationCircleFill } from 'react-icons/bs';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import TableSearchBox from '../../../shared/TableSearchBox';
import { FiSearch, FiFilter, FiDownload, FiChevronDown, FiPlus } from 'react-icons/fi';
import AddStudents from '../AddStudents';
import ManageGroups from '../Groups';
import { useHistory } from 'react-router-dom';
import { downloadCsv } from '../../../utils/files';
import Papa from 'papaparse';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { GQL_SendInviteResponse } from '../../../types/invites';
import ManageInvites from '../ManageInvites';
import { groupConfig } from '../../../utils/class';
import { themeConfig } from '../../../utils/theme';
import { useAuth } from '../../../hooks/useAuth';
import Avatar from '../../../shared/Avatar';
import { GQL_InvestigationTeacherEntryWithoutGrade } from '../../../types/investigation';
import useTagColumn from '../../../hooks/useTagColumn';

interface Props {
  classInfo?: GQL_ClassResponse;
  className?: string;
  loading: boolean;
  shouldLoad?: boolean;
}

const TableBreakPoint: Breakpoint[] = ['lg'];
const StudentsTable: React.FC<Props> = (props) => {
  const { classInfo, loading, className } = props;
  const [searchVisible, setSearchVisible] = useState(false);
  const [pendingInvitesVisible, setPendingInvitesVisible] = useState(false);
  const [addStudentsVisible, setAddStudentsVisible] = useState(false);
  const [manageGroupsVisible, setManageGroupsVisible] = useState(false);
  const [manageInvitesButtonOver, setManageInvitesButtonOver] = useState(false);
  const [addStudentsButtonOver, setAddStudentsButtonOver] = useState(false);
  const [disclaimerHover, setDisclaimerHover] = useState(-1);
  const [groupFilterVisible, setGroupFilterVisible] = useState(false);
  const [groups, setGroups] = useState<Filter[]>([]);
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);
  const { isTeacherOrFacilitator, user} = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const { columnField: tagColumnField } = useTagColumn<GQL_ClassUserOrInvite>();
  const classInfoSource = classInfo?.source;

  useEffect(() => {
    if (classInfo) {
      setGroups(
        classInfo.students
          .reduce((result: Filter[], student: GQL_ClassUserOrInvite) => {
            if (!result.some((r) => r.value === student.group))
              result.push({ text: `Group ${student.group}`, value: student.group });
            return result;
          }, [])
          .sort((a: Filter, b: Filter) => (a.value > b.value ? 1 : -1)),
      );
    }
  }, [classInfo]);

  const [getInvestigationsByTeacher, { data: inv }] = useLazyQuery<{
    getInvestigationsByTeacher: GQL_InvestigationTeacherEntryWithoutGrade[];
  }>(gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationsByTeacherWithoutGrade, {
    onError: (error) => {
      message.error(
        error.message ||
          'There was an error loading your active investigations, check you connection and try again later.',
      );
    },
  });

  useEffect(() => {
    if (props.shouldLoad) {
      getInvestigationsByTeacher();
    }
  }, [props.shouldLoad, getInvestigationsByTeacher]);

  const onViewStudent = useCallback(
    (student: GQL_ClassUserOrInvite) => {
      if (classInfo) {
        history.push(`/teacher-dashboard/class/${classInfo.id}/student/${student.userId}/summary`);
      }
    },
    [classInfo, history],
  );

  const [sendInvites] = useMutation<{ sendInvites: GQL_SendInviteResponse[] }>(
    gqlSchema.InvitesSchema.mutations.SEND.sendInvites,
    {
      onError: (err) => {
        message.error('There was an error trying to send the invite, please try again later');
      },
      update(cache, { data }) {
        if (data?.sendInvites) {
          const detailedNotification = data.sendInvites.length < 3;
          if (!detailedNotification) message.success(`Invites sent successfully`);

          const classResponse = {
            __typename: 'ClassResponse',
            id: classInfo?.id,
          };

          cache.modify({
            id: cache.identify(classResponse),
            fields: {
              students(cachedStudents: GQL_ClassUserOrInvite[], { readField }) {
                return cachedStudents.map((std: GQL_ClassUserOrInvite) => {
                  const invite = data?.sendInvites.find((inv: GQL_SendInviteResponse) => inv.token === std.inviteId);
                  if (invite?.emailSent && detailedNotification) {
                    message.success(`Invite sent to ${std.firstName} ${std.lastName}`);
                    return { ...std, emailSent: invite?.emailSent };
                  }
                  return std;
                });
              },
            },
          });
        }
      },
    },
  );

  const onResendInvite = useCallback(
    async (tokens: string[]) => {
      await sendInvites({
        variables: {
          data: {
            tokens,
          },
        },
      });
    },
    [sendInvites],
  );

  const activeInvestigations = useMemo(
    () =>
      inv?.getInvestigationsByTeacher?.filter(
        (f) => f.classId === classInfo?.id && Date.now() - f.dueDate < 0 && !f.isAssessment,
      ) || [],
    [classInfo, inv],
  );

  const activeAssessments = useMemo(
    () =>
      inv?.getInvestigationsByTeacher?.filter(
        (f) => f.classId === classInfo?.id && Date.now() - f.dueDate < 0 && f.isAssessment,
      ) || [],
    [classInfo, inv],
  );

  const resendInviteTooltip = useCallback(
    (record: GQL_ClassUserOrInvite, index: number) =>
      !record.userId ? (
        <S.SendInviteDisclaimer
          onMouseEnter={() => setDisclaimerHover(index)}
          onMouseLeave={() => setDisclaimerHover(-1)}
        >
          <Tooltip
            title={
              <>
                {record.emailSent
                  ? `${record.firstName} ${record.lastName} hasn’t accepted the invite yet.`
                  : `The invitation has not been sent to ${record.firstName} ${record.lastName} yet.`}
                <S.ResendInvite onClick={() => onResendInvite([record.inviteId || ''])}>
                  {record.emailSent ? 'Resend Invite' : 'Send Invite'}
                </S.ResendInvite>
              </>
            }
            placement="bottom"
          >
            {disclaimerHover === index ? (
              <BsExclamationCircleFill size={24} style={{ cursor: 'pointer', float: 'right' }} />
            ) : (
              <BsExclamationCircle size={24} style={{ cursor: 'pointer', float: 'right' }} />
            )}
          </Tooltip>
        </S.SendInviteDisclaimer>
      ) : null,
    [disclaimerHover, onResendInvite],
  );

  const [submitRemoveStudent] = useMutation(gqlSchema.ClassSchema.mutation.CLASS.MANAGE_GROUP.removeStudentFromClass, {
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onRemoveStudent = useCallback(
    async (student: GQL_ClassUserOrInvite) => {
      if (classInfo) {
        const data: GQL_RemoveStudentFromClassInput = {
          classId: classInfo.id,
          inviteId: student.inviteId,
        };
        const response = await submitRemoveStudent({ variables: { data } });
        if (response.data?.removeStudentFromClass) {
          message.success(`Student ${student.firstName} ${student.lastName} removed from class ${classInfo?.name}`);
        }
      }
    },
    [classInfo, submitRemoveStudent],
  );

  const investigationColumn = useCallback(
    (
      type: 'Investigations' | 'Assessments',
      data: GQL_InvestigationTeacherEntryWithoutGrade[],
      color: string = '#4367E9',
    ) => {
      return {
        title: `Active ${type}`,
        responsive: TableBreakPoint,
        align: centerAlign,
        width: '10%',
        hidden: type === 'Assessments',
        render: (text: string, record: GQL_ClassUserOrInvite) => {
          const firstInvestigation = data[0];
          const totalInvestigations = data.length;
          return totalInvestigations > 0 ? (
            <>
              <S.TagButton
                text={`${firstInvestigation?.title}`}
                display="inline"
                background={color}
                shape="round"
                key={`${record.userId || record.inviteId}${firstInvestigation?.id}`}
                minHeight={24}
              />
              {totalInvestigations > 1 && (
                <Tooltip
                  title={data
                    .filter((i) => i.id !== firstInvestigation.id)
                    .map((i) => (
                      <p style={{ marginBottom: 5 }} key={i.id}>
                        {i.title}
                      </p>
                    ))}
                >
                  <S.TagButton text={`+${totalInvestigations - 1}`} shape="round" minHeight={24} background={color} />
                </Tooltip>
              )}
            </>
          ) : (
            <S.TagButton text={`No active ${type}`} background="#c4c4c4" shape="round" minHeight={24} />
          );
        },
      };
    },
    [],
  );

  const columns = useMemo(
    () =>
      [
        {
          title: 'Student Name',
          sorter: (a: GQL_ClassUserOrInvite, b: GQL_ClassUserOrInvite) =>
            `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
          render: (text: string, record: GQL_ClassUserOrInvite, index: number) => {
            return (
              <Row align="middle" style={{ gap: 8 }}>
                <Avatar backgroundColor="#FFFFFF" size={30} src={record?.avatar} />
                <span role="none" >
                  {record.firstName} {record.lastName}
                </span>
                {resendInviteTooltip(record, index)}
              </Row>
            );
          },
          width: '20%',
          filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={ref} {...filterProps} />,
          filterIcon: (filtered: boolean) => (
            <S.SearchIcon $searchVisible={searchVisible}>
              <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
            </S.SearchIcon>
          ),
          onFilter: (value: string | number | boolean, record: GQL_ClassUserOrInvite) => {
            if (!value) return true;
            return (
              record.firstName.toLowerCase().includes(value.toString().toLowerCase()) ||
              record.lastName.toLowerCase().includes(value.toString().toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible: boolean) => {
            setSearchVisible(visible);
            if (visible) {
              setTimeout(() => {
                if (ref && ref.current) {
                  ref.current.select();
                }
              }, 100);
            }
          },
        },
        {
          title: 'Student Email',
          align: centerAlign,
          responsive: TableBreakPoint,
          dataIndex: 'email',
          width: '20%',
          sorter: (a: GQL_ClassUserOrInvite, b: GQL_ClassUserOrInvite) => a.email.localeCompare(b.email),
          render: (text: string) => (
            <span role="none" 
              style={{
                width: '150px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {text}
            </span>
          ),
        },
        {
          title: 'Student Group',
          responsive: TableBreakPoint,
          align: centerAlign,
          sorter: (a: GQL_ClassUserOrInvite, b: GQL_ClassUserOrInvite) => a.group.localeCompare(b.group),
          filters: groups,
          onFilter: (value: string | number | boolean, record: GQL_ClassUserOrInvite) =>
            record.group.includes(value.toString()),
          filterIcon: (filtered: boolean) => (
            <S.SearchIcon $searchVisible={groupFilterVisible}>
              <FiFilter size={18} style={{ color: filtered ? '#1890ff' : undefined }} />
            </S.SearchIcon>
          ),
          onFilterDropdownVisibleChange: (visible: boolean) => {
            setGroupFilterVisible(visible);
          },
          render: (text: string, record: GQL_ClassUserOrInvite) => {
            return (
              <>
                {record.group ? (
                  <S.TagButton
                    text={`Group ${record.group}`}
                    background={groupConfig.find((group) => group.group === record.group)?.color || 'blue'}
                    shape="round"
                    minHeight={24}
                  />
                ) : (
                  <S.TagButton text="No group assigned" background="#c4c4c4" shape="round" minHeight={24} />
                )}
              </>
            );
          },
          width: '20%',
        },
        investigationColumn('Investigations', activeInvestigations),
        investigationColumn('Assessments', activeAssessments, '#e06500'),
        tagColumnField,
        {
          title: 'Student Details',
          align: centerAlign,
          render: (text: string, record: GQL_ClassUserOrInvite) => {
            return (
              <>
                {record.userId ? (
                  <Button text="View More" onClick={() => onViewStudent(record)} block />
                ) : (
                  <S.TagButton text="No Details Available" shape="round" minHeight={24} />
                )}
              </>
            );
          },
          width: '15%',
        },
      ].filter((item: any) => !item['hidden']),
    [
      groups,
      resendInviteTooltip,
      searchVisible,
      groupFilterVisible,
      onViewStudent,
      activeInvestigations,
      tagColumnField,
      activeAssessments,
      investigationColumn,
    ],
  );

  const getKey = useCallback((record: GQL_ClassUserOrInvite) => {
    return record.userId || record.inviteId || Date.now().toString();
  }, []);

  const exportStudents = useCallback(() => {
    if (classInfo) {
      const headers = [['First Name', 'Last Name', 'Email', 'Tags', 'Group']];
      const data = classInfo.students.map((student: GQL_ClassUserOrInvite) => {
        return [
          student.firstName,
          student.lastName,
          student.email,
          student.tags?.map((t) => t.tag),
          student.group || 'Group not assigned',
        ];
      });

      downloadCsv(Papa.unparse([...headers, ...data]), `Students from ${classInfo.name}.csv`);
    }
  }, [classInfo]);

  const exportMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={exportStudents}>
          .csv
        </Menu.Item>
      </Menu>
    ),
    [exportStudents],
  );

  const buttonsBar = useMemo(
    () => (
      <Row gutter={[12, 12]} style={{ padding: '10px 0' }}>
        {(isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) && (
          <>
            <Col xl={4} lg={5} xs={12}>
              <Button
                data-cy="components-classdashboard-studentstable-managegroups-button"
                text="Manage Groups"
                onClick={() => setManageGroupsVisible(true)}
                block
              />
            </Col>

            <Col xl={4} lg={5} xs={12}>
              <Tooltip
                visible={manageInvitesButtonOver}
                title="Please make any edits to the roster via Classlink/Clever and run the sync to update roster in the Learning Hub"
              >
                <Button
                  data-cy="components-classdashboard-studentstable-manageinvites-button"
                  text="Manage Invites"
                  theme={themeConfig.secondaryColor}
                  onMouseOver={() => setManageInvitesButtonOver(!!classInfoSource && classInfoSource !== 'ADI')}
                  onMouseLeave={() => setManageInvitesButtonOver(false)}
                  onClick={() => {
                    if (classInfoSource === 'ADI') setPendingInvitesVisible(true);
                  }}
                  background={classInfoSource !== 'ADI' ? '#5A67AF' : themeConfig.secondaryColor.background}
                  block
                />
              </Tooltip>
            </Col>
          </>
        )}

        <Col xxl={{ span: 4 }} xl={{ span: 4 }} lg={{ span: 5 }} xs={{ span: 12 }}>
          <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
            <Button
              theme={themeConfig.noColor}
              text={
                <>
                  Export as
                  <FiChevronDown style={{ marginLeft: 15 }} />
                </>
              }
              padding="4px 5px"
              icon={<FiDownload />}
              block
            />
          </Dropdown>
        </Col>

        <Col xxl={{ span: 4, offset: 8 }} xl={{ span: 4, offset: 8 }} lg={{ span: 5, offset: 4 }} xs={12}>
          {(isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) && (
            <Tooltip
              visible={addStudentsButtonOver}
              title="Please make any edits to the roster via Classlink/Clever and run the sync to update roster in the Learning Hub"
            >
              <Button
                data-cy="components-classdashboard-studentstable-addstudents-button"
                text="Add Students"
                theme={themeConfig.noColor}
                padding="4px 5px"
                icon={<FiPlus />}
                onMouseOver={() => setAddStudentsButtonOver(!!classInfoSource && classInfoSource !== 'ADI')}
                onMouseLeave={() => setAddStudentsButtonOver(false)}
                onClick={() => {
                  if (classInfoSource === 'ADI') setAddStudentsVisible(true);
                }}
                background={classInfoSource !== 'ADI' ? '#E6E6E6' : '#FFFFFF'}
                block
              />
            </Tooltip>
          )}
        </Col>
      </Row>
    ),
    [addStudentsButtonOver, classInfoSource, exportMenu, isTeacherOrFacilitator, manageInvitesButtonOver],
  );

  return (
    <>
      <S.Title>{className} Students</S.Title>
      {buttonsBar}
      <S.TableWrapper>
        <Table
          columns={columns}
          loading={loading}
          rowKey={getKey}
          bordered
          dataSource={classInfo?.students}
          pagination={{
            hideOnSinglePage: false,
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </S.TableWrapper>
      <AddStudents
        visible={addStudentsVisible}
        setVisible={setAddStudentsVisible}
        openGroupManagment={setManageGroupsVisible}
        classInfo={classInfo}
      />
      <ManageGroups visible={manageGroupsVisible} setVisible={setManageGroupsVisible} classInfo={classInfo} />
      <ManageInvites
        visible={pendingInvitesVisible}
        setVisible={setPendingInvitesVisible}
        onRemoveStudent={onRemoveStudent}
        onResendInvite={onResendInvite}
        classInfo={classInfo}
      />
    </>
  );
};

export default StudentsTable;
