import { Row } from 'antd';
import Layout from 'antd/lib/layout/layout';
import React from 'react';
import cancelSrc from '../../assets/cancel.svg';
import { themeConfig } from '../../utils/theme';
import Button from '../../shared/Button';
import Spacer from '../../shared/Spacer';
import * as S from './styles';

const UnexpectedErrorPage = () => {
  return (
    <Layout>
      <S.Container>
        <S.UnexpectedErrorBox>
          <S.LogoImage src={cancelSrc} alt="Drawing of a big X with 4 trees on the background" />
          <Spacer />
          <S.Title>An unexpected error occurred</S.Title>
          <Spacer />
          <p>Don't worry, our team is automatically notified when errors like these occur.</p>
          <p>
            We will be investigating this occurrence and fix it as soon as possible to make sure that it never happens
            again.
          </p>
          <Spacer size={24} />
          <Row>
            <Button
              text="Refresh"
              minWidth={150}
              onClick={() => window.location.reload()}
              theme={themeConfig.secondaryColor}
            />
            <Spacer axis="horizontal" />
            <Button
              text="Home"
              minWidth={150}
              onClick={() => {
                window.location.href = '/';
              }}
            />
          </Row>
        </S.UnexpectedErrorBox>
      </S.Container>
    </Layout>
  );
};

export default UnexpectedErrorPage;
