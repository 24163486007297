import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi';
import { Checkbox } from 'antd';

export const SubTitle = styled.p`
  font-weight: 600;
  font-size: 1.15em;
  margin: 0;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const TitleInput = styled.p`
  font-weight: 600;
  font-size: 0.95em;
  margin: 0 0 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const BackButton = styled(FiArrowLeft)`
  position: absolute;
  left: 52px;
  top: 52px;
  cursor: pointer;
`;

export const CCheckbox = styled(Checkbox)<{
  $top: string;
  $float: string;
}>`
  margin-top: ${(props) => props.$top};
  float: ${(props) => props.$float};
`;
