import { DatePicker } from 'antd';
import styled from 'styled-components';

export const Picker = styled(DatePicker)`
  height: 48px;

  .ant-picker-input > input {
    cursor: pointer;
    caret-color: transparent;
    color: #3f3e38;
  }
`;

export const PickerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  align-items: center;
  padding-left: 12px;
`;
